<script>
import BookLoader from 'components/common/loader/BookLoader'
import BookDatetime from 'components/common/BookDatetime'
import Production from 'components/production/Production'
import EquipmentWithUsage from 'components/equipment/EquipmentWithUsage'
import Feed from 'components/feed/Feed'
import Entity from 'components/entity/Entity'
import BookSelectbox from 'components/common/BookSelectbox'

export default {
    components: {
        BookLoader,
        BookDatetime,
        BookSelectbox
    },
    props: {
        equipment: {
            type: Object
        },
        date: {
            type: String
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            child: {
                entities: {
                    leagueEntity: 'league_entity_id',
                }
            },

            leagueEntity: null,

            formData: this.defaultData(),
            loadingItems: false,
            items: [],

            loaded: false,
        }
    },
    created() {
        this.loadEntities(this.child.entities).then(() => {
            this.formData.startDate.value = this.date

            if (this.type) {
                this.formData.type.value = this.type
            }

            this.setLoaded()
        })
    },
    watch: {
        /**
         * Whenever type changes, we must load items for that type.
         */
        'formData.type.value': function (newValue) {
            this.items = []
            this.formData.item.value = ''

            if (newValue) {
                this.loadItems()
            }
        },
    },
    computed: {
        /**
         * Form is processing if the form is being submitted.
         *
         * @returns boolean
         */
        processing() {
            return this.$loader.has('equipmentAddToExistingForm_submit')
        },

        /**
         * Countries.
         */
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },

        /**
         * Filters items.
         */
        filteredItems() {
            return [...this.items].filter(item => {
                if (this.formData.country.value && !item.country.includes(this.formData.country.value)) {
                    return false
                }

                if (this.formData.league.value && item.league !== this.formData.league.value) {
                    return false
                }

                return true
            })
        },

        setting() {
            return this.$settings.get.productions
        },

        /**
         * Checks if the form is valid.
         */
        isFormValid() {
            if (!this.formData.item.value) return false
            if (!this.formData.type.value) return false

            return this.formFields && Object.keys(this.formFields).every(k => {
                return this.formFields[k].valid
            })
        },
    },
    methods: {
        /**
         * Load, transform and sets items depending on type.
         */
        loadItems() {
            this.loadingItems = true

            const type = this.formData.type.value

            let loader;
            if (type === 'feed') {
                loader = this.loadFeedItems()
            } else if (['eng', 'edit', 'production', 'car'].includes(type)) {
                loader = this.loadProductionItems()
            } else {
                console.error("Invalid type")
            }

            loader.then(items => {
                this.items = items
                this.loadingItems = false
            }).catch(error => {
                this.$error.set(error, 'It was not possible to load the items.')
                this.loadingItems = false
            })
        },
        /**
         * Feeds loader, called by loadItems.
         */
        loadFeedItems() {
            const feed = new Feed()

            const params = {
                from: this.formData.startDate.value,
                to: this.formData.startDate.value,
            }

            return feed.all(params).then(response => {
                return response.data.map(item => {
                    const start = moment(item.feedStart)

                    return {
                        id: item.id,
                        title: item.title,
                        periodStart: item.feedStart,
                        periodEnd: item.feedEnd,
                        usageType: 'feed',
                        country: item.mcr,
                        league: item.league,
                        label: `${start.format('DD MMM, HH:mm')} - ${item.title} (${item.id})`
                    }
                })
            })
        },
        /**
         * Productions loader, called by loadItems.
         */
        loadProductionItems() {
            const production = new Production()

            const params = {
                from: this.formData.startDate.value,
                to: this.formData.startDate.value,
                productionType: this.formData.type.value,
                fields: 'channel,equipmentList'
            }

            return production.all(params).then(response => {
                return response.data.map(item => {
                    const start = moment(item.productionStart)

                    let countries = new Set()

                    // Since country field is not reliable to use yet, we first check if its set, otherwise we use
                    // channel's country if we have one and finally fallback to equipment's countries.
                    if (item.country) {
                        countries.add(item.country)
                    } else if (item.channel && item.channelDetails) {
                        countries.add(item.channelDetails.country)
                    } else if (item.equipment) {
                        for (const equipment of item.equipment) {
                            countries.add(equipment.country)
                        }
                    }

                    return {
                        id: item.id,
                        title: item.title,
                        type: item.productionType,
                        periodStart: item.productionStart,
                        periodEnd: item.productionEnd,
                        usageType: 'production',
                        country: [...countries],
                        league: item.league,
                        label: `${start.format('DD MMM, HH:mm')} - ${item.title} (${item.id})`
                    }
                })
            })
        },
        /**
         * Create a new EquipmentWithUsage instance.
         *
         * Here we assume that the API endpoints follows the same structure for each item type (production, feed).
         */
        createEquipmentWithUsageInstance(usageType, id) {
            const apiUrl = 'api/' + usageType + '/' + id + '/' + 'equipment'

            return new EquipmentWithUsage(apiUrl, 0)
        },

        /**
         * Submit handler.
         */
        submit() {
            this.$loader.add('equipmentAddToExistingForm_submit')

            // Get the selected item.
            const item = this.items.find(item => item.id === this.formData.item.value)

            const equipmentWithUsage = this.createEquipmentWithUsageInstance(item.usageType, item.id)

            equipmentWithUsage.set('id', 0)
            equipmentWithUsage.set('equipmentId', this.equipment.equipmentId)
            equipmentWithUsage.set('periodStart', item.periodStart)
            equipmentWithUsage.set('periodEnd', item.periodEnd)

            equipmentWithUsage.store().then(response => {
                // We need to deep clone the data since we commit the original response to the store.
                const data = _.cloneDeep(response.data)

                this.$alert.set(`Equipment ${data.label} has been added to ${item.title}!`, 'success', 3)

                this.$loader.remove('equipmentAddToExistingForm_submit')

                this.$emit('success', data)
            }).catch(error => {
                this.$error.set(error, 'It was not possible to add the equipment.')

                this.$loader.remove('equipmentAddToExistingForm_submit')
            })
        },

        /**
         * Default data for form.
         */
        defaultData() {
            return {
                type: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                },
                startDate: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                },
                item: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                },
                country: {
                    value: '',
                },
                league: {
                    value: '',
                },
            }
        },

        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                    .each((v, k) => {
                        entityIds.push(context.setting[v])
                    })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                            .each((v, k) => {
                                context[k] = new Entity(context.setting[v])
                            })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        setLoaded(loaded = true) {
            this.loaded = loaded
        },
    }
}
</script>

<template>
    <div>
        <book-loader v-if="!loaded"></book-loader>
        <form v-else @submit.prevent="submit">
            <div class="form-horizontal">
                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('type')}">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Type
                    </label>
                    <div class="form-control-100">
                        <select tabindex="3" class="form-control" name="type" v-validate="formData.type.validator" :disabled="loadingItems" v-model="formData.type.value">
                            <option value="">- Select a type -</option>
                            <option value="production">Production</option>
                            <option value="eng">ENG</option>
                            <option value="edit">Edit</option>
                            <option value="feed">Feed</option>
                            <option value="car">Car</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Start date
                    </label>
                    <book-datetime
                        small
                        class="form-control-100"
                        component-id="date"
                        :required="{date: true}"
                        :hide-time="true"
                        :default-value="formData.startDate.value"
                        @newValue="value => formData.startDate.value = value"
                        disabled
                        :tabindex="4">
                    </book-datetime>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        League
                    </label>
                    <div class="form-control-100">
                        <book-selectbox
                            componentId="league-id"
                            :items="leagueEntity.get.items"
                            :selected="formData.league.value"
                            @selectedItem="id => formData.league.value = id"
                            :tabindex="5">
                        </book-selectbox>
                    </div>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        Country
                    </label>
                    <div class="form-control-100">
                        <select tabindex="6" class="form-control" name="country" v-model="formData.country.value">
                            <option value="">None</option>
                            <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('item')}">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Item
                    </label>
                    <div class="form-control-100">
                        <select tabindex="7" class="form-control" name="item" v-validate="formData.item.validator" v-model="formData.item.value" :disabled="loadingItems">
                            <option value="">None</option>
                            <option v-for="item in filteredItems" :value="item.id">{{ item.label }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-end mt-8">
                    <button type="submit" tabindex="8" class="btn btn-success btn-placement-end" :disabled="!isFormValid || processing">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
</style>