import {app} from 'app'
import router from 'router'
import System from 'components/system/System'
import Entity from 'components/entity/Entity'
import Feed from 'components/feed/Feed'
import BookLoader from 'components/common/loader/BookLoader'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookFiberEquipment from 'components/equipment/EquipmentSelection/BookFiberEquipment'
import BookTimeline from 'components/common/BookTimeline'
import BookTable from 'components/common/BookTable'
import BookFilter from 'components/common/filter/BookFilter'
import BookDateNavigation from 'components/common/BookDateNavigation'
import Item from 'components/common/item/Item'
import Datetime from 'components/common/helpers/Datetime'
import ItemsMixin from 'components/common/item/ItemsMixin'
import Ingestion from 'components/ingestion/Ingestion'
import BookSidebarFeed from 'components/feed/BookSidebarFeed'
import BookExitPrint from  'components/common/BookExitPrint'
import JsonExcel from 'vue-json-excel'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import ForkPlaceholder from 'components/forkplaceholder/ForkPlaceholder'
import BookModal from 'components/common/BookModal'
import BookFeedAudioLayout from 'components/feed/BookFeedAudioLayout'
import BookLocalNotes from 'components/common/BookLocalNotes'
import BookNentCalendarNotes from 'components/common/BookNentCalendarNotes'
import Equipment from 'components/equipment/Equipment'
import BookForkPlaceholder from 'components/forkplaceholder/BookForkPlaceholder'

export default {
    mixins: [
        ItemsMixin,
    ],
    components: {
        BookLoader,
        BookTimeline,
        BookTable,
        BookModal,
        BookFilter,
        BookDateNavigation,
        BookSidebarFeed,
        BookExitPrint,
        BookEquipmentSelection,
        JsonExcel,
        BookFeedAudioLayout,
        BookFiberEquipment,
        BookLocalNotes,
        BookNentCalendarNotes,
        BookForkPlaceholder
    },

    data() {
        return {

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'feed',
                items: 'feeds',
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    deliveryTypeEntity: 'deliverytype_entity_id',
                    resolutionEntity: 'resolution_entity_id',
                    frameRateEntity: 'framerate_entity_id',
                    channelEntity: 'channel_entity_id',
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                    equipmentStatusEntity: 'equipmentstatus_entity_id',
                    streamingTypeEntity: 'streamingtype_entity_id',
                    polarizationEntity: 'polarization_entity_id',
                    modulationEntity: 'modulation_entity_id',
                    fecEntity: 'fec_entity_id',
                    aspectRatioEntity: 'aspectratio_entity_id',
                    systemFormatEntity: 'systemformat_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    equipmentFeedFiberType: 'fiber_source_entityitem_id',
                    satelliteEntity: 'satellite_entity_id',
                    fiberEntity: 'fiber_source_entityitem_id',
                    externalMCRsEntity: 'externalmcrs_entity_id'
                }
            },
            applyTemplateForm: {
                show: false,
                selection: '',
                item: '',
                deliveryType: '',
                league: ''
            },
            templateForm: {
                show: false,
                id: '',
                selection: '',
                deliveryType: '',
                league: ''
            },
            allEquipment: null,

            availableFilters: {

                // "country" corresponds to the MCR field, but there is special
                // conditions for it on Item.isFiltered()
                // Here we need a common field with production in order to make
                // the "preserve filter" feature working when navigating between
                // them.
                search: {
                    label: 'Search',
                    type: 'text'
                },
                country: {
                    label: 'Countries',
                    items: []
                },

                sport: {
                    label: 'Sports',
                    items: [],
                    class: 'sportEntity'
                },
                league: {
                    label: 'Leagues',
                    items: [],
                    class: 'leagueEntity'
                },
                deliveryType: {
                    label: 'Del. Types',
                    items: [],
                    class: 'deliveryTypeEntity'
                },
            },
            json_fields: {
                'Week': "Week",
                'Day': "Day",
                'Date': "Date",
                'Live': "Live",
                'Start': "Start",
                'End': "End",
                'Channel': "Channel",
                'Sport': "Sport",
                'Event': "Event",
                'Teams': "Teams",
            },
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            placeholderForm: null,
            showPlaceholderForm: false,
            saveTemplateForm: {
                show: false,
                loading : false,
                saveItem: null,
                name: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                id: {
                    value: 0
                },
                template: null
            },
            template: {
                user: {
                    value: ''
                },
                title: {
                    value: ''
                },
                global: {
                    value: false
                },
                data: {
                    league: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    mcr: {
                        data: true,
                        value: ''
                    },
                    mcrExternal: {
                        data: true,
                        value:''
                    },
                    deliveryType: {
                        data: true,
                        value:''
                    },
                    contentType: {
                        data: true,
                        value: ''
                    },
                    deliveryTypeData: {
                        downloadInfo: {
                            value: '',
                        },
                        fiberInfo: {
                            value: '',
                        },
                        streamingType: {
                            value: '',
                        },
                        streamingInfo: {
                            value: '',
                        },
                        satInfo: {
                            value: '',
                        },
                        satSatelliteId: {
                            value: '',
                        },
                        satFrq: {
                            value: '',
                        },
                        satPolarization: {
                            value: '',
                        },
                        satModulation: {
                            value: '',
                        },
                        satTransponderChannel: {
                            value: '',
                        },
                        satService: {
                            value: '',
                        },
                        satSymbolRate: {
                            value: '',
                        },
                        satFec: {
                            value: '',
                        },
                        satEncryption: {
                            value: '',
                        },
                        satEncryptionBissCodeShared: {
                            value: '',
                        },
                        satEncryptionByCountry: {
                            value: '',
                        },
                    },
                    resolution: {
                        data: true,
                        value: '',
                    },
                    frameRate: {
                        data: true,
                        value: '',
                    },
                    aspectRatio: {
                        data: true,
                        value: '',
                    },
                    systemFormat: {
                        data: true,
                        value: '',
                    },
                    audioLayout: {
                        data: true,
                        value: ''
                    },
                    notes: {
                        data: true,
                        value: ''
                    },
                    localNotes: {
                        data: true,
                        value: ''
                    },
                    comments: {
                        data: true,
                        value: ''
                    },
                    contacts: {
                        data: true,
                        value: ''
                    },

                },
                itemType: {
                    value: 'feed'
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                }
            },
        }

    },
    mounted() {
        
            this.setLoaded(false)
            const equipment = new Equipment()
            const dataToLoad = [
                equipment.all()
            ]

            Promise.all(dataToLoad.map(p => {
                // Return "undefined" for all rejected promises which allows
                // us to handle entities even if equipment failed.
                return p.catch(() => undefined)
            }))
                .then(response => {
                    this.allEquipment = this.$store.state.data.equipment
                    this.setLoaded()
                })
                .catch(error => {
                    this.$error.set(error)
                    this.setLoaded()
                })
        
    },
    computed: {
        feeds() {
            const context = this
            if (!context.loaded || !this.$store.state.data.feed || !context.filterReady) return []
            return this.$store.state.data.feed.filter(v => {
                let leagueEntity = v.league !== null && context.getEntityItem('league', v.league) ? context.getEntityItem('league', v.league) : null;
                let sportEntity = leagueEntity !== null && context.getEntityItem('sport', leagueEntity.reference) ? context.getEntityItem('sport', leagueEntity.reference) : null;

                v['sportLabel'] = leagueEntity && sportEntity ? sportEntity.label : ''
                v['leagueLabel'] = leagueEntity ? leagueEntity.label : ''

                // On timeline view, only feeds with start/end inside the current
                // "from" day should be visible.
                if (context.$route.params.view == 'timeline') {
                    if (v.startTime > context.timeline.endTimestamp || v.endTime < context.timeline.startTimestamp) return false
                }

                return Item.isFiltered(context.filters, v, {league: context.leagueEntity, sport: context.sportEntity})
            })
        },

        json_data(){
            const context = this
            var output = []

            this.feeds.forEach(function(item, index ) {
                var outputitem =
                    {
                        "Week" : Datetime.getWeekNumber(item.feedStart),
                        "Day" : Datetime.getWeekDay(item.feedStart),
                        "Date" : Datetime.getDate(item.feedStart),
                        "Live" : 'live',
                        "Start" : moment(item.feedStart).format('HH:mm'),
                        "End" : moment(item.feedEnd).format('HH:mm'),
                        "Channel" : 'channel',
                        "Sport" : context.getEntityItem('sport', context.getEntityItem('league', item.league).reference).label,
                        "Event" : context.getEntityItem('league', item.league).label,
                        "Teams" : 'teams',
                    }
                    
                output.push(outputitem)

            })

            return output
        },

        feedsListData() {
            const context = this
            const output = {}
            if (!context.loaded) return output

            // Build options.
            // ingestion qualification list from left to right, also adds the ingestion type over the table.
            output.options = {
                 actions: ['edit', 'preview', 'template', 'apply', 'clone', 'delete'],
                //actions: ['edit', 'preview', 'delete'],
                ingestions: ['notrecord', 'repeat', 'share'],
                ingestionActions: ['empty', 'true', 'false'],
            }

            // Build the columns.
            output.columns = [
                {
                    value: 'status',
                    label: 'Status',
                    sortable: false,
                    searchable: false
                },
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                    searchable: false
                },
                {
                    value: 'week',
                    label: 'Week',
                    sortable: true,
                    sortWith: ['date', 'time'],
                    searchable: false
                },
                {
                    value: 'day',
                    label: 'Day',
                    sortable: false,
                    searchable: false
                },
                {
                    value: 'date',
                    label: 'Date',
                    sortable: true,
                    sortWith: ['time'],
                    searchable: false
                },
                {
                    value: 'time',
                    label: 'Time',
                    sortable: false,
                    searchable: false,
                },
                {
                    value: 'sport',
                    label: 'Sport',
                    sortable: false,
                    searchable: false,
                    visible: false
                },
                {
                    value: 'league',
                    label: 'League',
                    sortable: false,
                    searchable: false,
                    visible: false
                },
                {
                    value: 'mcr',
                    label: 'MCR',
                    sortable: true,
                    searchable: false,
                },
                {
                    value: 'event',
                    label: 'Event',
                    sortable: true,
                    sortWith: ['date', 'time'],
                    searchable: false,
                    marker: true,
                },
                {
                    value: 'delivery',
                    label: 'Delivery',
                    sortable: true,
                    searchable: false
                },
            ]
            output.rows = []
            context.feeds.forEach(v => {
                const deliveryType = context.getDeliveryType(v.deliveryType)
                const league = context.getEntityItem('league', v.league)
                let sport = null
                if (league && league.reference) {
                    sport = context.sportEntity.getItem(league.reference)
                }
                const row = {
                    rowOptions: {
                        updated: moment(v.updated).unix(),
                        new: moment(v.created).unix(),
                        type: 'feed',
                        id: v.id,
                        ingestions: v.ingestions,
                        country: v.mcr,
                    },
                    status: {
                        value: '<span class="book-square"></span>',
                        classes: ['book-item-status-' + v.status]
                    },
                    id: {
                        value: v.id
                    },
                    week: {
                        value: Datetime.getWeekNumber(v.feedStart)
                    },
                    day: {
                        value: Datetime.getWeekDay(v.feedStart)
                    },
                    date: {
                        value: Datetime.getDate(v.feedStart)
                    },
                    time: {
                        value: Datetime.getTime(v.feedStart, v.feedEnd)
                    },
                    sport: {
                        rawValue: sport ? sport.id : '',
                        value: sport ? sport.id : ''
                    },
                    league: {
                        rawValue: v.league ? v.league : '',
                        value: v.league ? v.league : ''
                    },
                    mcr: {
                        value: context.getMcrList(v.mcr),
                        classes: ['book-mcr-column']
                    },
                    event: {
                        value: context.getEventName(v)
                    },
                    delivery: {
                        value: deliveryType ? deliveryType.label : ''
                    },
                    houseNumber: {
                        value: this.showHouseNumberField(v, 'value'),
                        rawValue: this.showHouseNumberField(v, 'rawValue')
                    },
                    recordRequested: {
                        value: this.getRecordRequest(v.recordRequested),
                        rawValue: this.getRecordRequest(v.recordRequested)
                    }
                }
                output.rows.push(row)
            })
            //console.log(output.rows)
            return output
        },

        getRequestParams() {
            const output = {
                from: this.$route.query.from,
                to: this.$route.query.to
            }
            if (this.requestFields) {
                output.fields = this.requestFields.join(',')
            }
            return output
        },
        requestFields() {
            switch (this.$route.params.view) {
                case 'list':
                    return [
                        'ingestions',
                        'signoffs',
                        'deliveryType',
                        //'equipmentList'
                    ]
                case 'timeline':
                    return [
                        'signoffs',
                        'deliveryType'
                    ]
            }
            return null
        },
        getEntitiesForSidebar() {
            const output = {}
            Lazy(this.child.entities)
                .each((v, k) => {
                    output[k] = this[k]
                })
            return output
        },
        getCloneLink(){
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }

            link.name = 'production.create'

            return link;
        },
        templates() {
            if(this.$store.state.data.productionTemplate)
                return Lazy(this.$store.state.data.productionTemplate)
                    .sortBy(item => item.title, false)
                    .toArray()
            else return null
        },
        getFeedsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.templateForm.deliveryType || this.templateForm.league) {
                        if(this.templateForm.deliveryType && this.templateForm.deliveryType != item.data.deliveryType)
                            return false
                        if(this.templateForm.league && this.templateForm.league != item.data.league)
                            return false
                    }
                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },
        getApplyFeedsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.applyTemplateForm.deliveryType || this.applyTemplateForm.league) {
                        if(this.applyTemplateForm.deliveryType && this.applyTemplateForm.deliveryType != item.data.deliveryType)
                            return false
                        if(this.applyTemplateForm.league && this.applyTemplateForm.league != item.data.league)
                            return false
                    }
                    
                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },
        
        
    },
    methods: {
        getTabRoute() {
            return this.$route.query.newBar;
        },
        getEditLink(id) {
            const link = {
                params: {
                    id: id
                },
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    newBar: id,
                }
            }

            link.name = 'feed.edit'

            return link
        },
        getAllEquipment(item) {
            let allEq = null
            if(this.getDeliveryTypeLabel(item.deliveryType) == 'fiber') {
                allEq = item.equipment.filter(e => !e.hasOwnProperty('type') || e.type !== this.fiberEntity.get.id)
            }
            else {
                allEq = item.equipment
            }

            if(item.equipment_type_usages && item.equipment_type_usages.length > 0) {
                item.equipment_type_usages.forEach((el) => {
                        let newArray = el["equipment_type"];
                        newArray["equipmentUsage"] = [];
                        newArray["equipmentUsage"]["id"] = el["id"];
                        newArray["equipmentUsage"]["entityItemId"] = el["entity_item_id"];
                        newArray["equipmentUsage"]["usageId"] = el["equipment_type_usage_id"];
                        newArray["equipmentUsage"]["usageType"] = el["equipment_type_usage_type"];
                        allEq.push(newArray);
                    })
                
            }

            return allEq
        },
        getFiberEquipment(item) {
            let allEq = null
            if(this.getDeliveryTypeLabel(item.deliveryType) == 'fiber') {
                allEq = item.equipment.filter(e => e.type === this.fiberEntity.get.id)
            }
            return allEq
        },
        getFeedWeek(varstartdate){
            if(varstartdate !== undefined){
                let startdate = moment(varstartdate)
                return startdate.format('W')
            } else
                return null
        },
        getFeedTime(startend){
            let startendFeed = moment(startend)
            return startendFeed.format('HH:mm')
        },
        getDeliveryType(id) {
            const deliveryType = this.deliveryTypeEntity.getItem(id)
            return deliveryType ? deliveryType : null
        },
        getDeliveryTypeLabel(id) {
            const deliveryType = this.deliveryTypeEntity.getItem(id)
            return deliveryType ? deliveryType.label.toLowerCase() : ''
        },
        getRecordRequest(codes) {
            if (!codes || !Array.isArray(codes)) return false
            return !!codes.find(v => v === app.$data.user.get.country)
        },
        getSatSatellite(id) {
            const satSatellite = id.satSatellite.value
            return satSatellite ? satSatellite : ''
        },
        getMcrList(mcr) {
            const output = []
            if (mcr) {
                // I need to order them with the right country order. WN
                let ordering = {}, // map for efficient lookup of sortIndex
                    sortOrder = ['se','no','dk','fi','pl','is','nl','uk']
                for (let i = 0; i < sortOrder.length; i++)
                    ordering[sortOrder[i]] = i;

                mcr.sort(function (a, b) {
                    return (ordering[a] - ordering[b]) || a.localeCompare(b);
                });
                Lazy(mcr)
                    .each(v => {
                        // output.push(this.getMcrLabel(v))
                        output.push(v)
                    })

                return output.join(', ')
            }
            else {
                console.log('ERROR - NO MCR')
                return output
            }
        },
        isDeliveryType(name) {
            return this.getEntityItemLabel('deliveryType', this.selectedItem.deliveryType).toLowerCase() === name
        },
        print(printtype) {
            this.$router.replace({query: {print: 'list'}})
            //this.$router.push('listprint')
            this.$store.commit('setAppClass', 'book-print')
        },

        setTarget() {
            return (this.enabled ? "_blank" : "_self");
        },
        /**
         * Redirect the user from Feed to Production Cloning
         * WN
         */
        cloneProductionFromFeed(id) {
            const context = this
            console.log("production cloning");
            swal({
                title: 'Create from Template?',
                text: `You can create from Template by clicking yes or you can just create normally with Feed details `,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
                .then(function () {
                    // open from Template
                    context.openTemplateForm(id)

                }, function (dismiss) {
                    // open Normally
                    const feed = new Feed(id)
                    const destination = context.getCloneLink
                    destination.query.clone = feed.get.id
                    destination.query.feed = 1
                    destination.query.insertData = 1
                    context.$router.push(destination)
                })
                .catch(() => {

                })


        },
        /**
         * We need to use template list from Feeds as well.
         */
        getCreateLink(id) {
            const link = {
                name: 'feed.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            
            if (this.templateForm.show) {
                link.query.template = this.templateForm.selection
            }

            return link
        },
        openTemplateForm(id) {
            this.$loader.add('createFromTemplateLoading')
            this.templateForm.show = true
            this.templateForm.id = id
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: 'feed'}, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Production Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },

        getTemplateTitleToFormList(template) {
            const label = []
            label.push(template.title)
            label.push(`(${template.id})`)
            if (template.data && template.data.league) {
                const league = this.leagueEntity.getItem(template.data.league)
                const sport = this.sportEntity.getItem(league.reference)
                label.push(`(${sport.label}, ${league.label})`)
            }
            return label.join(' ')
        },
        getTemplateDeliveryTypeLabel(type) {
            let t = _.find(this.deliveryTypeEntity.get.items, i => i.id == type)
            return t ? t.label : ''
        },
        getTemplateLeagueLabel(league) {
            let t = _.find(this.leagueEntity.get.items, i => i.id == league)
            return t ? t.label : ''
        },
        templateDeliveryTypes() {
            // let tmpls = this.$store.state.data.productionTemplate && this.$store.state.data.productionTemplate.length > 0 ? 
            //             this.$store.state.data.productionTemplate.map(i => i.data.deliveryType ? i.data.deliveryType : 0) : []
            // tmpls = _.uniq(tmpls)
            return Lazy(this.deliveryTypeEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.deliveryType == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        templateLeagues() {
            return Lazy(this.leagueEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.league == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        // getFeedsTemplates() {
        //     return Lazy(this.$store.state.data.productionTemplate)
        //         .filter(item => {
        //             if (this.isEditProductions && (item.data.runType != this.setting.edit_entityitem_id)) {
        //                 return false
        //             }
        //             return true
        //         })
        //         .sortBy(item => item.title, false)
        //         .toArray()
        // },
        /**
         * Default form data to be used in this form.
         * @return object
         */
        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                resolution: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                frameRate: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                aspectRatio: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                systemFormat: {
                    value: '',
                    onlyEdit: true
                },
                contentType: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                houseNumber: {
                    value: '',
                    rawValue: ''
                },
                sport: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                league: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                mcr: {
                    value: []
                },
                mcrExternal: {
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                bookingNo: {
                    value: '',
                },
                encryption: {
                    value: '',
                },
                channels: { // @TODO Remove this?
                    value: []
                },
                feedStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                feedEnd: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                pgmStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: false,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                audioLayout: {
                    value: '',
                    onlyEdit: true
                },
                audios: {
                    value: [],
                    onlyEdit: true
                },
                audioCountries: {
                    value: [],
                    local: true
                },
                documents: {
                    value: [],
                    progress: 0,
                    onlyEdit: true
                },
                equipment: {
                    value: [],
                    onlyEdit: true
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
                notes: {
                    value: '',
                    onlyEdit: true
                },
                localNotes: {
                    value: [],
                    onlyEdit: true
                },
                localNoteCountries: {
                    value: [],
                    local: true
                },
                contacts: {
                    value: '',
                    onlyEdit: true
                },
                signoffs: {
                    value: [],
                    onlyEdit: true
                },
                ingestions: {
                    value: [],
                    onlyEdit: true
                },
                cloneOf: {
                    value: null
                },

                // Delivery type values.
                deliveryType: {
                    value: '',
                    validator: {}
                },
                deliveryTypeData: {
                    downloadInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    fiberInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    streamingType: {
                        value: '',
                        onlyEdit: true
                    },
                    streamingInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    satInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    satSatellite: {
                        value: '',
                        onlyEdit: true
                    },
                    satSatelliteId: {
                        value: '',
                        onlyEdit: true
                    },
                    satFrq: {
                        value: '',
                        onlyEdit: true
                    },
                    satPolarization: {
                        value: '',
                        onlyEdit: true
                    },
                    satModulation: {
                        value: '',
                        onlyEdit: true
                    },
                    satTransponderChannel: {
                        value: '',
                        onlyEdit: true
                    },
                    satService: {
                        value: '',
                        onlyEdit: true
                    },
                    satSymbolRate: {
                        value: '',
                        onlyEdit: true
                    },
                    satFec: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryption: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryptionBissCodeShared: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryptionByCountry: {
                        value: [],
                        onlyEdit: true
                    },
                }
            }
        },

        defaultPlaceholderForm() {
            return {
                placeholders: null,
                itemId: '',
                itemType: 'feed',
                itemTitle: '',
                itemStart: '',
                itemEnd: '',
                selected: ''
            }
        },

        saveFeedAsTemplate(item, needGetItem) {
            const context = this
            context.saveTemplateForm.show = true
            context.saveTemplateForm.loading = true
            context.saveTemplateForm.template = Object.assign({}, context.template)
            // context.saveTemplateForm.template.user.value = {
            //     id: app.$data.user.get.id
            // }
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, type: 'feed'}, true)
                .then(response => {
                    if(needGetItem) {
                        context.getCurrentFeed(item.id.value)
                    }
                    else {
                        console.log(context.$store.state.data.productionTemplate)
                        //Item.parse(item, 'production', now)
                        context.saveTemplateForm.saveItem = item
                        context.saveTemplateForm.loading = false
                    }
                })
                .catch(error => {
                    if (error.response.status != 404) {
                        context.$error.set(error)
                        
                    }
                    else if(needGetItem) {
                        context.getCurrentFeed(item.id.value)
                    }
                    else {
                        context.saveTemplateForm.loading = false
                    }
                })
        },

        getCurrentFeed(id) {
            const context = this
            const currentFeed = new Feed(id)
            currentFeed.load(id)
            .then(response => {
                const now = moment().unix()
                Item.parse(response.data, 'feed', now)
                context.saveTemplateForm.saveItem = response.data//currentProduction.get
                context.saveTemplateForm.loading = false
            })
            .catch(error => {
                context.$error.set(error)
            })
        },

        saveTemplateAs() {
            const context = this
            let id = parseInt(context.saveTemplateForm.id.value)
            context.$loader.add('productionTemplateEdit_submit')
            const productionTemplate = new ProductionTemplate(id)
            Lazy(context.saveTemplateForm.template)
                .each(function(v, k) {
                    switch (k) {
                        case 'user':
                            let user = {
                                id: app.$data.user.get.id
                            }
                            productionTemplate.set(k, user)
                            break
                        case 'title':
                            let value = ''
                            if(id === 0) {
                                value = context.saveTemplateForm.name.value
                            }
                            else {
                                let currentTemplate = context.templates.find(i => i.id === id)
                                if(currentTemplate) value = currentTemplate.title
                            }
                            productionTemplate.set(k, value)
                            break
                        case 'data':
                            const data = {}
                            Lazy(v)
                                .each(function(v2, k2) {
                                    // if(k2 === 'equipment') {
                                    //     data[k2] = context.formData[k2].value.map(e => {
                                    //         return {
                                    //             elementType: e.elementType,
                                    //             equipmentId: e.id,
                                    //             type: e.type,
                                    //             equipmentType: e.type,
                                    //             id: e.equipmentUsage.id,
                                    //             equipmentUsage: {
                                    //                 isNotUpdatePeriod: e.equipmentUsage.isNotUpdatePeriod,
                                    //                 isPrimary: e.equipmentUsage.isPrimary
                                    //             }
                                    //         }
                                    //     })
                                    // }
                                    if(k2 === 'mcr') {
                                        // let mcrs = []
                                        // context.saveTemplateForm.saveItem[k2].forEach(i => mcrs.push(i.code))
                                        data[k2] = context.saveTemplateForm.saveItem[k2]
                                    }
                                    else if(k2 === 'mcrExternal') {
                                        // let exts = []
                                        // if(context.saveTemplateForm.saveItem[k2] && context.saveTemplateForm.saveItem[k2].length > 0) {
                                        //     context.saveTemplateForm.saveItem[k2].forEach(i => exts.push(i.id))
                                        // }
                                        data[k2] = context.saveTemplateForm.saveItem[k2]//exts.join(',')
                                    }
                                    else if(k2 === 'deliveryTypeData') {
                                        let deliveryData = {}
                                        Lazy(v2)
                                        .each(function(v3, k3) {
                                            if(k3 === 'satEncryptionByCountry') {
                                                if(context.saveTemplateForm.saveItem.deliveryTypeData.satEncryption == 'biss_code')
                                                {
                                                    let enc = []
                                                    if(context.saveTemplateForm.saveItem.deliveryTypeData[k3] && context.saveTemplateForm.saveItem.deliveryTypeData[k3].length > 0) {
                                                        context.saveTemplateForm.saveItem.deliveryTypeData[k3].forEach(i => {
                                                            if(i.valueType == 'encryption') {
                                                                enc.push({
                                                                    country: i.country,
                                                                    value: i.value,
                                                                    valueType: i.valueType
                                                                    })
                                                            }
                                                        })
                                                    }
                                                    deliveryData[k3] = enc
                                                }
                                                
                                            }
                                            else {
                                                deliveryData[k3] = context.saveTemplateForm.saveItem.deliveryTypeData[k3]
                                            }
                                            
                                        })
                                        data[k2] = deliveryData
                                    }
                                    else if(k2 === 'localNotes') {
                                        let locNotes = []
                                        if(context.saveTemplateForm.saveItem[k2] && context.saveTemplateForm.saveItem[k2].length > 0) {
                                            context.saveTemplateForm.saveItem[k2].forEach(i => locNotes.push({
                                                valueType: i.valueType,
                                                country: i.country,
                                                value: i.value
                                            }))
                                        }
                                        data[k2] = locNotes
                                    }
                                    else {
                                        data[k2] = context.saveTemplateForm.saveItem[k2]
                                    }
                                    
                                }) 
                            productionTemplate.set(k, data)
                            break
                        case 'itemType':
                            productionTemplate.set(k, 'feed')
                            break
                        case 'filterType':
                            productionTemplate.set(k, context.saveTemplateForm.saveItem.deliveryType)
                            break
                        case 'filterLeague':
                            productionTemplate.set(k, context.saveTemplateForm.saveItem.league)
                            break
                        default:
                            productionTemplate.set(k, v.value)
                            break
                    }

                })

                if(id === 0) {
                    productionTemplate.store()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Feed template ${response.data.id} has been created!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the feed.')
                            context.resetSaveTemplateForms()
                        })
                }
                else {
                    productionTemplate.save()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Feed template ${response.data.id} has been updated!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the feed.')
                            context.resetSaveTemplateForms()
                        })
                }
        },
        resetSaveTemplateForms() {
            this.saveTemplateForm.show = false
            this.saveTemplateForm.loading = false
            this.saveTemplateForm.template = null
            this.saveTemplateForm.id.value = 0
            this.saveTemplateForm.name.value = ''
        },
        openApplyTemplateForm(item) {
            this.$loader.add('createFromTemplateLoading')
            this.applyTemplateForm.show = true
            this.applyTemplateForm.item = item
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: 'feed'}, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Feed Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },
        applyTemplateToFeed(templateId) {
            this.applyTemplateToItem(templateId, this.applyTemplateForm.item)
                .then(response => {
                    if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] == this.applyTemplateForm.item) {
                        this.$alert.set(`Feed has been updated with template!`, 'success', 3)
                        let updatedFeed = JSON.parse(response.data.applied[0])
                        let currentProd = this.feeds.find(i => i.id == this.applyTemplateForm.item)
                        if(currentProd != undefined) {
                            currentProd.audioLayout = updatedFeed.audioLayout
                            currentProd.contacts = updatedFeed.contacts
                            currentProd.notes = updatedFeed.notes
                            currentProd.deliveryType = updatedFeed.deliveryType
                            currentProd.deliveryTypeData = updatedFeed.deliveryTypeData
                            currentProd.localNotes = updatedFeed.localNotes
                            currentProd.resolution = updatedFeed.resolution
                            currentProd.frameRate = updatedFeed.frameRate
                            currentProd.aspectRatio = updatedFeed.aspectRatio
                            currentProd.systemFormat = updatedFeed.systemFormat
                            currentProd.mcrExternal = updatedFeed.mcrExternal
                            currentProd.contentType = updatedFeed.contentType
                            currentProd.mcr = updatedFeed.mcr
                        }

                        //Vue.set(this.$data, 'formData', this.defaultData())
                        //this.redirect(true, this.id)
                        //this.setFormValues()
                    }
                    else {
                        this.$error.set('Something went wromg with applying template.')
                    }
                })
                .catch(error => {
                    this.$error.set(error, 'Something went wromg with applying template.')
                })
        }

    }
}
