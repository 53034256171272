<script>

import {app} from 'app'
import BookHeader from 'components/app/BookHeader'
import BookAlert from 'components/common/alert/BookAlert'
import User from 'components/user/User'
import System from 'components/system/System'
import BookHeaderLoader from 'components/common/loader/BookHeaderLoader'

export default {
    components: {
        BookHeader,
        BookAlert,
        BookHeaderLoader
    },
    mounted() {

        // Set "now" and update it each 10th second.
        this.$store.commit('setAppNow', moment().unix())

        this.$on('formSaved', () => {
            if (this.$route.query.destination !== undefined) {
                let redirect = {
                    name: this.$route.query.destination
                }
                if (this.$route.query.change !== undefined) {
                    redirect.query = {
                        change: this.$route.query.change
                    }
                }
                this.$router.push(redirect)
            }
        })

    },
    computed: {
        User() {
            return User
        },
        app() {
            return app
        },
        isVisible() {
            return User.isAuthenticated() && !this.$route.query.hasOwnProperty('print')
        },
        isPrint() {
            return this.$route.query.print
        }
    },
    methods: {
        hasApproved() {
            return app.$data.user && app.$data.user.get && app.$data.user.get.approval
        },
    }
}
</script>

<template>
<div id="main-content" class="container-fluid clearfix" :class="$store.state.app.classes">
    <book-header v-if="isVisible"></book-header>
    <book-header-loader></book-header-loader>
    <book-alert></book-alert>
    <transition name="fade" mode="out-in">
        <router-view name="content"></router-view>
    </transition>
    <div class="book-approval-box" v-if="app.$data.user && isVisible && !hasApproved() && ($route.name != 'user.id.edit')">
        To use VIAPLAY Sport Booking you must accept our terms and conditions - <router-link :to="{name: 'user.id.edit', params: {id: app.$data.user.get.id}}">click here</router-link> to continue
    </div>

    <portal-target name="modal" multiple></portal-target>
</div>
</template>