<script>

import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import Production from 'components/production/Production'
import BookSelectbox from 'components/common/BookSelectbox'
import BookDatetime from 'components/common/BookDatetime'
import BookLock from  'components/common/lock/BookLock'
import Calendar from 'components/calendar/Calendar'
import User from 'components/user/User'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'
import Datetime from 'components/common/helpers/Datetime'

import datepicker from 'vue-datepicker/vue-datepicker-es6'

export default {
    components: {
        BookLoader,
        BookSelectbox,
        BookDatetime,
        BookLock
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            child: {
                item: 'production',
                items: 'productions',
                settings: 'productions',
                entities: {
                    calendarActivityTypesEntity: 'calendaractivitytypes_entity_id',
                }
            },
            formData: this.defaultData(),
            loaded: false,
            calendar: null,
            activeTab: 'overview',
        }
    },

    computed: {
        setting() {
            return this.$settings.get.calendar
        },
         productionStartDateLimit() {
            let now = moment(this.now)
            return [{
                type: 'fromto',
                from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
            }]
        },
        productionEndDateLimit() {
            let from = moment(this.formData.productionStart.value, 'YYYY-MM-DD HH:mm')
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },
        isEditForm() {
            return this.$route.name == 'placeholder.edit'
        },
        isFormClean() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        },
        isFormValid() {
            // hate to do that but, need it WN.
            
            if (!this.formData.productionStart.value) return false
            if (!this.formData.productionEnd.value) return false
            if (!this.formData.productionLeader.value) return false
            
            return this.formFields && Object.keys(this.formFields).every(k => {
                console.log('isFormValid: '+ k, this.formFields[k].valid)
                return this.formFields[k].valid
            })
        },
        isChanged() {
            const context = this
            let changed = false
            if ((!this.calendar || !this.calendar.get)) return changed

            Lazy(context.formData)
                .filter((v, k) => {
                    return !v.local && k != 'country'
                })
                .each(function(v, k) {
                    if (changed) return true

                    // The form value is an array and its length is not equal to
                    // the length of the original value, then it has been changed.f
                    if (Array.isArray(v.value) && Array.isArray(context.calendar.get[k]) && (v.value.length != context.calendar.get[k].length)) {
                        changed = true
                    }
                    else {
                        switch (k) {
                            case 'productionLeader':
                                const productionLeader = context.calendar.get.productionLeader ? context.calendar.get.productionLeader.id : ''
                                if (!changed && (productionLeader != v.value)) {
                                    changed = true
                                }
                                break

                            case 'runType':
                                if (!context.isEditForm) {
                                    if (context.formFields[k] && context.formFields[k].touched) {
                                        changed = v.value != context.calendar.get[k]
                                    }
                                }
                                else {
                                    changed = v.value != context.calendar.get[k]
                                }
                                break
                            default:

                                // If the form value and feed value are "false/null/empty"
                                // then "changed" should be false.
                                if (context.calendar.get && !context.calendar.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = v.value != context.calendar.get[k]
                                }

                                break
                        }

                    }

                })

            return changed
        },
        activityTypes() {
            if(!this.calendarActivityTypesEntity || !this.calendarActivityTypesEntity.get || !this.calendarActivityTypesEntity.get.items || this.calendarActivityTypesEntity.get.items.length === 0) return null

            const context = this
            let filteredActivityTypes = null

            if(context.formData.country.value) {
                
                filteredActivityTypes = context.calendarActivityTypesEntity.get.items.filter(f => f.country === context.formData.country.value)
                
            }

            return filteredActivityTypes
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        hasHistory() {
            return this.isEditForm && this.calendar && this.calendar.get.history && this.calendar.get.history.length > 0
        },
        processing() {
            return this.$loader.has('placeholderEdit_submit')
        },
        availableUsers() {
            return this.$store.state.user.users
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
    },

    mounted() {

        const context = this

        this.loadForm()
            .then(response => {
                    User.getUsers()
                        .then(response => {
                            context.setLoaded(true)
                        })
                        .catch(err => {
                            context.$error.set(err, `It was not possible to get user list`)
                            context.loaded = true
                        })
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the entities.')
                context.loaded = true
            })

    },
    watch: {
            'formData.productionStart.value': function(newValue) {
                let format = 'YYYY-MM-DD HH:mm'
                if (!Datetime.isAfter(newValue, this.formData.productionEnd.value, format)) {
                    this.formData.productionEnd.value = this.formData.productionStart.value
                }
                
                Vue.nextTick(() => {
                    this.$validator.validate('productionStart', newValue)
                })
            },
            'formData.productionEnd.value': function(newValue) {
                let format = 'YYYY-MM-DD HH:mm'
                if (!Datetime.isAfter(this.formData.productionStart.value, newValue, format)) {
                    Vue.nextTick(() => {
                        this.formData.productionEnd.value = this.formData.productionStart.value
                    })
                }

                Vue.nextTick(() => {
                    this.$validator.validate('productionEnd', this.formData.productionEnd.value)
                })
            }
        },

    methods: {
        getTabRoutes() {
            return this.$route.query.newBar;
        },
        /**
         * Get the destination to be used when redirecting after form save.
         * @param object
         */
        getDestination() {
            const context = this
            const output = {
                name: context.$route.query.destination ? context.$route.query.destination : 'calendar',
            }
            if (context.$route.query.from) {
                output.query = {
                    from: context.$route.query.from
                }
            }
            if (context.$route.query.to) output.query.to = context.$route.query.to

            if (context.$route.query.crewId) output.query.crewId = context.$route.query.crewId

            if (context.$route.query.date) output.query.date = context.$route.query.date

            
            //output.name = 'task.view'
            //output.params.view = 'overview'
            
            return output
        },
        closeNewTab() {
            window.top.close();
        },
        /**
         * Emit a action button event.
         * @param eventName string
         * @param object row
         */
        emitButtonEvent(eventName) {
            const context = this
            switch (eventName) {
                case 'delete':
                    context.deleteItem(context.calendar.options.id)
                    break
            }

            return
        },

        getButtonClasses(button) {
            let classes = []
            switch (button) {
                case 'delete':
                    classes.push('btn-danger')
                    break
            }
            return classes
        },
        getButtonTitle(button, byPass = false) {

        },
        getButtonLabel(button) {
                switch (button) {
                    case 'delete':
                        return ''
                }
        },
        getButtonIcon(button){
            switch (button) {
                case 'delete':
                    return 'trash'
            }
        },
        userHasAccess(button) {
            //create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete_calendar_placeholder', 'Productions')
                    break
                
            }
            return _access
        },
        getFormattedProductionDate(field) {
            // let value = this.production.get[field].split(' ')
            let value = this.formData[field].value.split(' ')
            return {
                date: value[0],
                time: value[1]
            }
        },
        isActiveTab(name) {
            return this.activeTab == name
        },
        lock(id) {
            if (id == this.id) this.formData.locked.value = true
            else this.formData.locked.value = false
        },
        /**
         * Load the form.
         */
        loadForm() {
            const context = this
            let itemId = context.id
            context.calendar = new Calendar(itemId)

            return new Promise((resolve, reject) => {
                context.loadEntities(context.child.entities)
                    .then(response => {
                        if (context.isEditForm) {
                            context.calendar.load(itemId)
                                .then(response => {
                                    resolve()
                                })
                                .catch(error => {
                                    context.$error.set(error, `It was not possible to load placeholder event ${context.id}.`)

                                    // @TODO Old error handling
                                    // let msg
                                    // if (error.response.data.message) msg = error.response.data.message
                                    // else if (typeof error.response.data == 'string') msg = error.response.data
                                    // else msg = `It was not possible to load ${context.child.item} ${context.id}.`
                                    // this.$alert.set(msg, 'danger', 10)

                                    if (error.response.status === 404) context.redirect(false)

                                    reject()
                                })
                        }
                        else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the form dependencies.')
                        reject()
                    })
            })
            
            
            
        },
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        redirect(open = false, id = null) {
            let location = {}
            if (open && id) {
                
                location.name = 'placeholder.edit'

                location.params = {id: id}
                location.query = Object.assign({}, this.$route.query)

                // Do not include "clone" in location to redirect to.
                if (location.query.clone) {
                    delete location.query.clone
                }

                // If the page is redirected to "edit route", then we should set
                // the form values once more, after the DOM has been updated once.
                Vue.nextTick(() => {
                    this.setFormValues()
                    this.submitting = false
                })
            }
            else if (this.$route.query.destination) {
                location = this.getDestination()
            }
            else {
                    location.name = 'calendar'
            }
            return new Promise((resolve, reject) => {
                this.$router.push(location, () => {
                    resolve()
                }, () => {
                    reject()
                })

            })
        },

        /**
         * Set the equipment list to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
            this.setFormValues()
        },

        setFormValues() {
            const context = this
            if (context.isEditForm) {
                Lazy(context.formData)
                .each(function(v, k) {
                    //let value = null;
                    switch (k) {
                        case 'productionLeader':
                            if (context.calendar.get.productionLeader) {
                                v.value = context.calendar.get.productionLeader.id
                            }
                            break
                        case 'country':
                            let _channel = context.formData.runType.value ? _.find(context.calendarActivityTypesEntity.get.items, a => a.id === context.formData.runType.value) : null
                            if (_channel) {
                                v.value = _channel.country
                            }
                            break
                        default:
                            v.value = context.calendar.get[k]
                            break
                    }
                    // if (value) {
                    //     v.value = value
                    // }
                })
            }
            else {

                const context = this
                let defaultValue = null

                context.formData.productionLeader.value = app.$data.user.get.id
                context.formData.country.value = app.$data.user.get.country
            }

        },

        /**
         * Submit the form.
         */
        submit(open = false, redirect = true) {
            const context = this
            context.$loader.add('placeholderEdit_submit')

            Lazy(context.formData)
                .filter((v, k) => !v.local)
                .each(function(v, k) {
                    let value;
                    switch (k) {
                        case 'title':
                            value = ''
                            let currentActivityType = _.find(context.activityTypes, i => i.id === context.formData.runType.value)
                            if(currentActivityType) {
                                value = currentActivityType.label
                            }
                            break
                        default:
                            value = v.value
                            break
                    }
                    context.calendar.set(k, value)
                })

            if (context.isEditForm) {
                context.calendar.save()
                    .then(response => {
                        context.$alert.set(`Placeholder ${context.id} was saved!`, 'success', 3)
                        Vue.set(context.$data, 'formData', context.defaultData())

                        if (redirect) {
                            context.submitted = true
                            // Close the tab if its clicked to "save & close the tab"
                            if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                            context.redirect(open)
                        }

                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }

                        context.setLoaded()
                        context.$loader.remove('placeholderEdit_submit')
                                
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the placeholder.')
                        context.$loader.remove('placeholderEdit_submit')
                    })
            }
            else {
                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('placeholderEdit_submit')
                }

                context.calendar.store()
                .then(response => {
                    context.submitted = true
                    context.$alert.set(`Placeholder ${response.data.id} has been created!`, 'success', 3)


                    // close the tab if its clicked to "save & close the tab"
                    //if (!open && getTabRoutes()) { return closeNewTab(); }

                    context.calendar = new Calendar(response.data.id)

                    if (redirect) {
                        context.redirect(open, response.data.id)
                            .then(response => {
                                if (open) {
                                    context.setLoaded()
                                }
                            })
                            .catch(error => {
                                if (open) {
                                    context.setLoaded()
                                }
                            })
                    }
                    
                    // Reset formData if "Save & Close" button has been clicked.
                    if (!open) Vue.set(context.$data, 'formData', context.defaultData())

                    // Close the tab if its clicked to "save & close the tab"
                    // Idea is using the same identifiers as save and close for redirect and open options,
                    // which makes smart use in tabs as welll
                    // WN
                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                    context.$loader.remove('placeholderEdit_submit')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to save the placeholder.')
                    context.$loader.remove('placeholderEdit_submit')
                    context.setLoaded()
                })
                

            }
        },
        /**
         * Delete placeholder
         */
        deleteItem(id) {
            const context = this
            const itemClass = new Calendar(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            context.$alert.set(`The placeholder was deleted!`)
                            //if we are in the NewTab just, close the tab or, redirect.
                            if (context.$route.query.newBar) {
                                return context.closeNewTab()
                            }
                            else {
                                console.log('redirecting')
                                return context.redirect(true)
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the placeholder!`)
                        })
                })
        },

        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                productionType: {
                    value: 'placeholder'
                },
                title: {
                    value: '',
                    
                },
                productionStart: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                    
                },
                productionEnd: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionLeader: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                runType: {
                    value: '',
                    //locked: false,
                    validator: {
                        rules: 'required'
                    }
                },
                country: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                notes: {
                    value: ''
                },
                actions: {
                    value: ['delete']
                },
            }
        }

    }
}
</script>

<style src="../production/ProductionEdit/ProductionEdit.scss" lang="scss"></style>
<template>
<div id="production-edit" class="admin book-top-sticky-wrapper col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 container">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title text-center" v-if="!isEditForm">Create calendar event</h1>
        <h1 class="page-title text-center" v-if="isEditForm">Edit calendar event {{id}}</h1>
        <div class="secondary-menu">
            <book-lock
                v-if="loaded && isEditForm"
                :item="calendar.get.lock"
                @isLocked="id => lock(id)">
            </book-lock>
        </div>
        <div class="secondary-menu btn-group">
            <router-link :to="getDestination()" class="btn btn-default" title="Back to tasks" v-if="!getTabRoutes()"><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></router-link>
            <div class="btn btn-default" title="Back to tasks" @click="closeNewTab()" v-else><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></div>
            <!-- <button class="btn btn-default" title="Print this production"><span class="fa fa-print"></span><span class="item-label">&nbsp;&nbsp;Print</span></button>
            <button @click="openInsertForm()" class="btn btn-default" title="Copy data from other production" :disabled="formData.locked.value"><span class="far fa-copy"></span><span class="item-label">&nbsp;&nbsp;Insert data</span></button> -->
        </div>
    </div>
    <book-loader class="panel-body" v-if="!loaded"></book-loader>
    <div class="form-horizontal row" v-else>
       <div class="book-creation-form">
           <ul class="nav nav-tabs book-form-navigation">
                <li role="presentation" :class="{'active': isActiveTab('overview')}"><a @click="activeTab = 'overview'">Overview</a></li>
                <!-- <li role="presentation" :class="{'active': isActiveTab('equipment')}" v-if="isEditForm || isTemplateBased"><a @click="activeTab = 'equipment'">Equipment</a></li> -->
                <!-- <li role="presentation" :class="{'active': isActiveTab('feedback')}" v-if="isEditForm"><a @click="activeTab = 'feedback'">Feedback</a></li> -->
            </ul>
           <div class="panel panel-info"  v-show="isActiveTab('overview')">
                <div class="panel-body">
                    <div class="book-action-buttons">
                        <button
                                
                                v-if="isEditForm && userHasAccess('delete')"
                                @click="emitButtonEvent('delete')"
                                class="btn btn-xs"
                                :class="getButtonClasses('delete')"
                                :title="getButtonTitle('delete')">
                            <font-awesome-icon :icon="getButtonIcon('delete')"/>
                        </button>
                    </div>
                    
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-start"
                                :required="{date: true, time: false}"
                                :default-value="formData.productionStart.value ? formData.productionStart.value : ''"
                                @newValue="value => formData.productionStart.value = value"
                                :small="true"
                                :limit="productionStartDateLimit"
                                :disabled="formData.locked.value"
                                :dateTabindex="4"
                                :btnOpenTabindex="5"
                                :timeTabindex="6">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            End
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-end"
                                :required="{date: true, time: false}"
                                :default-value="formData.productionEnd.value"
                                @newValue="value => formData.productionEnd.value = value"
                                :small="true"
                                :limit="productionEndDateLimit"
                                :disabled="formData.locked.value"
                                :dateTabindex="7"
                                :btnOpenTabindex="8"
                                :timeTabindex="9">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('productionLeader')}">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Assigned to
                        </label>
                        <div class="form-control-100">
                            <book-selectbox
                                componentId="production-leader"
                                :items="getUsersList"
                                :selected="formData.productionLeader.value"
                                @selectedItem="id => formData.productionLeader.value = id"
                                :disabled="formData.locked.value"
                                :tabindex="10">
                            </book-selectbox>
                        </div>
                    </div>
                    <!-- Country -->
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('country')}" >
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Country
                        </label>
                        <div class="form-control-100">

                            <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" v-if="countries" :disabled="formData.locked.value" tabindex="12">
                                <option value="">
                                    None
                                </option>
                                <option v-for="country in countries" :value="country.code">
                                    {{country.label}}
                                </option>
                            </select>

                        </div>
                    </div>

                    

                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('runType')}">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Activity type
                        </label>
                        <div class="form-control-100">
                            
                            <select class="form-control" name="runType" v-validate="formData.runType.validator" v-model="formData.runType.value" v-if="calendarActivityTypesEntity" :disabled="formData.locked.value" tabindex="13">
                                <option value="">None</option>
                                <option v-for="activityType in activityTypes" :value="activityType.id" v-if="activityTypes" >{{activityType.label}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"></span>
                            Note
                        </label>
                        <div class="form-control-100">
                            <textarea rows="10" class="form-control" name="notes" v-model="formData.notes.value" :disabled="formData.locked.value"></textarea>
                        </div>
                    </div>

                </div>
            </div>
       </div>
    </div>
    <div class="book-form-actions book-actions-flex book-bottom-sticky" v-if="loaded">
        <!-- <button class="btn btn-primary" @click="submit()" :disabled="isFormClean || formErrors.any()">Save</button>
        <router-link exact :to="{name: 'calendar'}" class="btn btn-danger">Cancel</router-link> -->
        <div class="book-form-actions-label">
            <span class="book-form-actions-label-text book-form-actions-label-last-change" v-if="hasHistory" title="Last change">
                <font-awesome-icon icon="clock"/> 
                <div class="book-form-actions-label-last-change-inner">
                    <span>{{calendar.get.history[0].date}}</span>
                    <span>by <router-link :to="{name: 'user.id', params: {id: calendar.get.history[0].user.id}}">{{calendar.get.history[0].user.name}}</router-link></span>
                </div>
            </span>
            <span class="book-form-actions-label-text book-form-actions-label-datetimes" v-if="isEditForm" title="Production start & end">
                <div>
                    <span>{{getFormattedProductionDate('productionStart').date}}</span><span class="book-form-actions-label-time">{{getFormattedProductionDate('productionStart').time}}</span><br />
                    <span>{{getFormattedProductionDate('productionEnd').date}}</span><span class="book-form-actions-label-time">{{getFormattedProductionDate('productionEnd').time}}</span>
                </div>
            </span>
            <span class="book-form-actions-label-text" v-if="isEditForm ">
                ID: {{id}}
            </span>
            <span class="book-form-actions-label-text" v-if="isChanged">
                <span class="label label-danger">unsaved</span>
            </span>
        </div>

        <button tabindex="15" class="btn btn-info" @click="submit(true)" v-if="!isEditForm" :disabled="!isFormValid || processing">Save</button>
        <button tabindex="16" class="btn btn-info" @click="submit(true, false)" v-if="isEditForm" :disabled="!isFormValid || processing || !isChanged">Save</button>
        <button tabindex="17" class="btn btn-primary" @click="submit()" :disabled="!isFormValid || processing || !isChanged" v-if="!getTabRoutes()">Save & close</button>
        <button tabindex="17" class="btn btn-primary" @click="submit(false, false)" :disabled="!isFormValid || processing || !isChanged" v-else>Save & Close</button>
        <router-link tabindex="18" :to="getDestination()" class="btn btn-danger" v-if="!getTabRoutes()">
            <span v-if="isChanged">Cancel</span>
            <span v-else>Close</span>
        </router-link>
        <div tabindex="18" class="btn btn-danger" @click="closeNewTab()" v-else>
            <span v-if="isChanged">Cancel and Close</span>
            <span v-else>Close</span>
        </div>
    </div>
</div>
</template>
