<script>
import draggable from 'vuedraggable'
import BookModal from 'components/common/BookModal'
import BookLoader from 'components/common/loader/BookLoader'
import Entity from 'components/entity/Entity'
import BookIngestion from 'components/ingestion/BookIngestion'
import EntityItem from 'components/entity/EntityItem'
import System from 'components/system/System'
import store from 'store'
import _ from 'lodash';
import {app} from 'app'

export default {
  props: {
    id: {
      type: Number,
    }
  },
  data() {
    return {
      formData: this.defaultData(),
      entity: null,
      referencedEntity: null,
      loaded: false,
      selected: [],
      interested: [],
      satCountries: [],
      usage: [],
      issueTypes: [],
      channelsItemInDM: false,
      channels: [],
      runTypes: [],
      offtubeLocationsInDM: false,
      offtubeLocations: [],
      showAllLeagues: false,
      clickedSave: false,
      expandedRows: [],
      //deliveryTypeEntity: null,
      runTypeEntity: null,
      skillEntity: null,
      locationEntity: null,
      newCommentatorSkill: Object.assign({}, this.addSkill()),
      fecEntity: null,
      modulationEntity: null,
      polarizationEntity: null,
      resolutionEntity: null,
      framerateEntity: null,
      satelliteEntity: null,
      systemformatEntity: null
    }
  },
  components: {
    draggable,
    BookModal,
    BookLoader,
    BookIngestion
  },
  mounted() {
    const context = this
    context.entity = new Entity(this.id)
    //console.log('context.entity')
    //console.log(context.entity)
    context.entity.load(context.id)
        .then(response => {
          if(context.entity.get.id === 27) {
            //const deliveryTypeEntityId = 5 //hard coded
            //context.deliveryTypeEntity = new Entity(deliveryTypeEntityId)

            const runTypeEntityId = 14 //hard coded
            context.runTypeEntity = new Entity(runTypeEntityId)

            const skillEntityId = 16
            context.skillEntity = new Entity(skillEntityId)

            const locationEntityId = 22
            context.locationEntity = new Entity(locationEntityId)

            const referencedEntityId = context.entity.get.fields.settings.reference.id
            context.referencedEntity = new Entity(referencedEntityId)

            const entity = new Entity()
            entity.all({ids: [/*deliveryTypeEntityId, */runTypeEntityId, skillEntityId, locationEntityId, referencedEntityId ].join(',')})
                .then(response => {
                  context.resetEditStatus()
                  context.loaded = true
                })

          }
          else if(context.entity.get.id === 37) {
            const fecEntityId = 9
            context.fecEntity = new Entity(fecEntityId)

            const modulationEntityId = 12
            context.modulationEntity = new Entity(modulationEntityId)

            const polarizationEntityId = 13
            context.polarizationEntity = new Entity(polarizationEntityId)

            const resolutionEntityId = 15
            context.resolutionEntity = new Entity(resolutionEntityId)

            const framerateEntityId = 10
            context.framerateEntity = new Entity(framerateEntityId)

            const satelliteEntityId = 26
            context.satelliteEntity = new Entity(satelliteEntityId)

            const systemformatEntityId = 19
            context.systemformatEntity = new Entity(systemformatEntityId)

            const entity = new Entity()
            entity.all({ids: [fecEntityId, modulationEntityId, polarizationEntityId, resolutionEntityId, framerateEntityId, satelliteEntityId, systemformatEntityId].join(',')})
                .then(response => {
                  context.resetEditStatus()
                  context.loaded = true
                })

          }
          else if (context.showField('reference')) {
            context.referencedEntity = new Entity(context.entity.get.fields.settings.reference.id)
            context.referencedEntity.load(context.entity.get.fields.settings.reference.id)
                .then(response => {
                  context.resetEditStatus()

                  // Set default value for reference field.
                  context.referencedEntity.get.items.forEach(v => {
                    if (v.default) {
                      context.formData.newItem.reference = v.id
                      context.formData.newItem.custom = v.label
                    }
                  })

                  context.loaded = true
                })
          }
          else {
            context.resetEditStatus()
            context.loaded = true
          }
        })
        .catch(error => {
          context.$error.set(error, 'It was not possible to load the entity.')
          context.loaded = true
        })

  },

  computed: {
    mainAdmin() {
      return app.$data.user && app.$data.user.get ? app.$data.user.get.id : null
    },
    system() {
      return System
    },
    entityItem() {
      return EntityItem
    },
    entityDataIsReady() {
      return this.entity && this.entity.get && this.formData.draggedItems > 0
    },
    items: {
      get() {

        // Since update to VueJS 2.4.4, get() is not triggered when
        // data inside Vuex, through a class, is changed. This happens
        // for unknown reason.
        // The workaround is to set a "dummy" variable ad modify it
        // every time an item is dragged and dropped. It triggers get().
        this.formData.draggedItems
        //return this.entity.get.items

        // orderBy function added cause of the true order list. WN
        return _.orderBy(this.entity.get.items, 'weight')
      },
      set(items) {
        // Look at the long explanation above about why we increase
        // this variable.
        this.formData.draggedItems++

        items.forEach(function(v, k) {
          //console.log(v.weight, '<->', k)
          v.weight = k
        })
        this.entity.set('items', items)
      }
    },
    isSportPage() {
      return this.entity && this.entity.get && this.entity.get.id === 11 ? true : (app.$route.params.id === 11 || window.location.pathname.indexOf('entity/11') > -1)
    },
    isLeaguePage() {
      return this.entity && this.entity.get && this.entity.get.id === 21 ? true : (app.$route.params.id === 21 || window.location.pathname.indexOf('entity/21') > -1)
    },
    /**
     *
     * Ordering the ingest preview items alphabetical and
     * some functionality about of selections
     * wn
     *
     */
    selecteds: function() { return _.sortBy(this.selected) },
    /**
     * OrderedItems for to show true order in the list
     * it was not showing the true orders.
     * WN
     */
    orderedItems: function () {
      // have better order for league
      if (app.$route.params.id === 21 || window.location.pathname.indexOf('entity/21') > -1) {

        let newOrderItems = this.entity.get.items

        Lazy(newOrderItems).each((v,k) => {
          v.custom = this.getReferencedValue(v.reference)
        })

        // filter items if showAllLeagues false
        newOrderItems = this.showAllLeagues ? newOrderItems : newOrderItems.filter(i => i.active)

        return _.orderBy(newOrderItems, ['custom'])
      }

      return _.orderBy(this.entity.get.items, ['country', 'weight'])
    },
    groupedByReferenceItems: function () {
      const context = this
      let groupedItems = _(context.items)
          .map((value, key) => {
            if(!_.isEmpty(context.formData.oldValues) && !context.clickedSave && context.formData.oldValues[value.id]) {
              value.groupReference = context.formData.oldValues[value.id].reference
            }
            else {
              value.groupReference = value.reference
            }

            return value
          })
          .groupBy(x => x.groupReference)
          .map((value, key) =>
              ({type: key,
                label: context.getReferencedValue(key),
                groupcount: Object.values(_.countBy(value, key))[0],
                expanded: _.includes(context.expandedRows, key),
                groups: value})).value();


      return _.orderBy(groupedItems, 'label')
    },
    countries() {
      return Object.keys(store.state.system.countries).length > 0 ? store.state.system.countries : null
    },
    forkCountries() {
      return Object.keys(store.state.system.countries).length > 0 ? store.state.system.countries.filter(i => i.code === 'se' || i.code === 'dk') : null
    },
    listOrderIsChanged() {
      if (!this.entityDataIsReady) {
        return false
      }
      // let newList = store.state.entity.data[this.entity.id].items
      let newList = this.entity.get.items
      let changed = false
      this.formData.oldListOrder.forEach((v, k) => {
        if (v.id != newList[k].id) {
          changed = true
          return false // what is that ? WN
        }
      })
      return changed
    },
    getUsageTypes() {
      let types = []

      if(this.entity.get.userfield1_values) {
        types = this.entity.get.userfield1_values.indexOf(',') > -1 ? this.entity.get.userfield1_values.split(',') : [this.entity.get.userfield1_values]
      }

      return types
    },
    getChannelsList() {
      return this.referencedEntity && this.referencedEntity.get && this.referencedEntity.get.items ? this.referencedEntity.get.items : []
    },
    getRunTypeList() {
      return this.runTypeEntity && this.runTypeEntity.get && this.runTypeEntity.get.items ? this.runTypeEntity.get.items : []
    },
    getSkillsList() {
      let skillItems = this.skillEntity && this.skillEntity.get && this.skillEntity.get.items ? this.skillEntity.get.items : []
      return this.newCommentatorSkill.country.value ? skillItems.filter(f => f.country === this.newCommentatorSkill.country.value) : skillItems
    },
    getLocationsList() {
      return this.locationEntity && this.locationEntity.get && this.locationEntity.get.items ? this.locationEntity.get.items : []
    },


  },


  watch: {
   // only for Ingestor entity == 23
    selected: {
      handler: function (newValue, oldValue) {
        if (this.entity.get.id === 23) {
          // if you select record and not record together, remove one of them, not possible to choose both
          // WN
          if (_.indexOf(this.selected, 'record') > -1 && _.indexOf(this.selected, 'notrecord') > -1) {
            if (_.last(newValue) === 'record') _.pull(this.selected, 'notrecord')
            if (_.last(newValue) === 'notrecord') _.pull(this.selected, 'record')
          }
          // 3rd stance blank also, can not be selected with record and notrecord
          if (_.indexOf(this.selected, 'blank') > -1 && _.indexOf(this.selected, 'record') > -1) {
            if (_.last(newValue) === 'blank') _.pull(this.selected, 'record')
            if (_.last(newValue) === 'record') _.pull(this.selected, 'blank')
          }
          if (_.indexOf(this.selected, 'blank') > -1 && _.indexOf(this.selected, 'notrecord') > -1) {
            if (_.last(newValue) === 'blank') _.pull(this.selected, 'notrecord')
            if (_.last(newValue) === 'notrecord') _.pull(this.selected, 'blank')
          }
        }
      },
      deep: true,

    },
    'newCommentatorSkill.country.value': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.newCommentatorSkill.skill.value = ''
      }
    },
  },

  methods: {

    /**
     * Save a copy of the list first time an item is moved.
     */
    onStart({relatedContext, draggedContext}) {
      if (this.formData.oldListOrder.length == 0) {
        // this.oldListOrder = store.state.entity.data[this.entity.id].items
        this.formData.oldListOrder = _.orderBy(this.entity.get.items, ['country', 'weight']) // Right version WN
      }
    },
    abbrValidation(abbr, item = null) {
      if (!abbr) return true
      if (item) {
        return this.orderedItems.find(v => v.shortlabel.toLowerCase() === abbr.toLowerCase() && item.shortlabel.toLowerCase() !== abbr.toLowerCase());
      } else return this.orderedItems.find(v => v.shortlabel.toLowerCase() === abbr.toLowerCase());
    },
    getUserfieldValues(field_value){
      let fieldvals_obj = {}
      let fieldvals = (field_value) ? field_value.split(",") : ''

      if(field_value !== null){
        for( var key in fieldvals){
          let fieldValue = fieldvals[key].trim()
          let f = fieldValue.charAt(0).toUpperCase()
          let value = f + fieldValue.substr(1)
          fieldvals_obj[key] = { 'id': fieldValue, 'label': value}
        }

        return fieldvals_obj
      }
      return null

    },

    ucFirst(lc_str){
      let f = lc_str.charAt(0).toUpperCase()
      let value = f + lc_str.substr(1)
      return value
    },
    /**
     * Add background color to moved items..
     */
    onEnd(context) {
      context.item.classList.add('bg-warning')
    },

    /**
     * Save the current list order.
     */
    saveListOrder() {
      const context = this
      context.entity.save()
          .then(response => {
            context.resetItemBackgrounds()
            context.$alert.set('The list order was saved!', 'success', 3)
            context.formData.oldListOrder = []
          })
          .catch(error => {
            context.$error.set(error, 'It was not possible to save the list order')
          })
    },
    resetListOrder() {
      this.resetItemBackgrounds()
      // Now we need to reset items to old order with their weights, since we are using weights now WN
      this.formData.oldListOrder.forEach(function(v, k) {
        v.weight = k
      })
      // set entities now with the old order for resetting WN
      this.entity.set('items', this.formData.oldListOrder)
      this.formData.oldListOrder = []
    },

    /**
     * Remove the "move/changed" background classes applied to moved items.
     */
    resetItemBackgrounds() {
      const elements = document.querySelectorAll("table tr.bg-warning")
      elements.forEach(function(el) {
        el.classList.remove('bg-warning')
      })
    },

    /**
     * Add/create a new item into the current entity.
     */
    addItem() {
      const context = this

      // if it's league abbr check if exists
      if (context.entity.get.id === 21) {
        if (!context.formData.newItem.label || !context.formData.newItem.reference) {
          context.$alert.set('Do not leave important fields empty!', 'danger', 5)
          return
        }
        if (context.abbrValidation(context.formData.newItem.shortlabel)) {
          context.$alert.set('Abbreviation already exists, please use unique word / letter combination.', 'danger', 5)
          return
        }
      }
      // if its ingestors , check housenumber and label WN
      if (context.entity.get.id === 23) {
        let permission = true
        if (!context.formData.newItem.country || !context.formData.newItem.label) {
          context.$alert.set('You cant select none for country and house number should be filled.', 'danger', 5)
          permission = false
        }
        // lets check for duplicates
        Lazy(this.orderedItems)
            .each(function(v, k) {
              if (v.label === context.formData.newItem.label.trim() && v.country === context.formData.newItem.country.trim()) {
                context.$alert.set('This housenumber already added to this country, please re-consider.', 'danger', 5)
                permission = false
              }
            })
        // if not allowed to go further, stop process WN.
        if (!permission) return
      }

      if((context.entity.get.id === 26 || context.entity.get.id === 31) && context.formData.newItem.country) {
        context.formData.newItem.description = context.formData.newItem.country;
        context.formData.newItem.country = null;
        context.formData.newItem.userfield2_value = '';
      }

      context.$loader.add('entityItemsSaveItem')

      // Create an new entity item and set its field.
      const entityItem = new EntityItem(0)
      context.formData.newItem.entity = context.entity.get.id
      context.formData.newItem.weight = context.entity.get.items.length
      entityItem.add(context.formData.newItem)

      entityItem.store()
          .then(response => {
            context.$alert.set('<b>' + response.data.label + '</b> has been added to <b>' + context.entity.get.label + '</b>', 'success', 3)
            context.resetForm()
            context.resetEditStatus()
            context.entity.setItem(response.data)
            context.$loader.remove('entityItemsSaveItem')
          })
          .catch(error => {
            context.$error.set(error, 'It was not possible to update this entity item.')
            context.$loader.remove('entityItemsSaveItem')
          })

    },
    /**
     * Save an entity item.
     * @param id
     */
    saveItem(id) {
      const context = this
      const entityItem = new EntityItem(id)
      context.clickedSave = true

      let item = context.entity.getItem(id)
      if(item.userfield1_value === null) {
        item.userfield1_value = 'no'
      }

      if(context.entity.get.id === 33 && item.userfield2_value === null) {
        item.userfield2_value = 'no'
      }

      // if it's league abbr check if exists
      if (context.entity.get.id === 21) {
        if (!item.label || !item.reference) {
          context.$alert.set('Do not leave important fields empty!', 'danger', 5)
          return
        }
        if (context.abbrValidation(item.shortlabel, item)) {
          context.$alert.set('Abbreviation already exists, please use unique word / letter combination.', 'danger', 5)
          return
        }
      }

      // if its ingestors , check housenumber and label WN
      if (context.entity.get.id === 23) {
        // if no added to selected, please remove it, dont save it with NO WN.
        if (_.includes(this.selected, 'no')) { _.pull(this.selected, 'no') }

        let permission = true
        // lets check for duplicates
        Lazy(this.orderedItems)
            .each(function(v, k) {
              // change false without render
              if (v.default && item.country !== v.country) {
                v.default = false
              }
              if (v.id !== id) {
                if (v.userfield1_value === context.selected[0] && v.userfield1_value !== 'repeat' && v.userfield1_value !== 'share' && item.country === v.country) {
                  //console.log(v.userfield1_value, item.userfield1_value, v.id , '???')
                  context.$alert.set('This ingest action has been already added, please edit other item or change ingest', 'danger', 5)
                  permission = false
                }
                // house numbers are unique
                if (v.label === item.label && item.country === v.country) {
                  context.$alert.set('This House Number already added to this country ingests, please re-consider.', 'danger', 5)
                  permission = false
                }
                // shortlabels are unique
                if (v.shortlabel !== null && v.shortlabel === item.shortlabel && item.country === v.country) {
                  context.$alert.set('This short label already added to this country ingests, please re-consider.', 'danger', 5)
                  permission = false
                }
                // reachers are unique (clicked)
                if (v.userfield2_value !== null && v.userfield2_value === item.userfield2_value && item.country === v.country) {
                  context.$alert.set('This house number already added to this country ingests, please re-consider.', 'danger', 5)
                  permission = false
                }
              }
            })

        if (!item.country) {
          context.$alert.set('You cant save it without country. Please select the country instead of none.', 'danger', 5)
          permission = false
        }


        // if not allowed to go further, stop process WN.
        if (!permission) return

        if (this.selected.length > 0) {
          item.userfield1_value = `${this.selected}`
        } else {
          context.$alert.set('You have to select atleast one ingestion', 'danger', 5)
          return
        }
      }

      // channel ownership
      if (context.entity.get.id === 3) {
        if (this.interested.length > 0) {
          item.description = `${this.interested}`
        }
        else item.description = null
      }

      //satellite available in
      if (context.entity.get.id === 26 || context.entity.get.id === 31) {
        if (this.satCountries.length > 0) {
          item.description = `${this.satCountries}`
        }
        else item.description = null
      }

      //audiolayouts feed/production usage
      if (context.entity.get.id === 24) {
        if (this.usage.length > 0) {
          item.userfield1_value = `${this.usage}`
        }
        else item.userfield1_value = null
      }

      if (context.entity.get.id === 30) {
        if (this.issueTypes.length > 0) {
          item.userfield1_value = `${this.issueTypes}`
        }
        else item.userfield1_value = null
      }

      //dataminer settings channels
      if (context.entity.get.id === 27 &&  context.channelsItemInDM) {
        if(item.label === 'Channels') {
          if (this.channels.length > 0) {
            item.description = `${this.channels}`
          }
          else item.description = null
        }
        else if(item.label === 'Offtube') {
          if(this.runTypes.length > 0) {
            item.description = `${this.runTypes}`
          }
          else item.description = null
        }
        else {
          if (this.channels.length > 0) {
            item.userfield1_value = `${this.channels}`
          }
          else item.userfield1_value = null

          if(this.runTypes.length > 0) {
            item.userfield2_value = `${this.runTypes}`
          }
          else item.userfield2_value = null
        }

      }

      if(context.entity.get.id === 27 && context.offtubeLocationsInDM) {
        if(this.offtubeLocations.length > 0) {
          item.description = `${this.offtubeLocations}`
        }
        else item.description = null
      }

      context.$loader.add('entityItemsSaveItem')

      entityItem.add(item)

      entityItem.save()
          .then(response => {
            context.$alert.set('<strong>' + response.data.label + '</strong> (id ' + response.data.id  + ') was saved!', 'success', 3)
            context.editItem(id, false, true)
            // when you save item twice, doesnt update the front end with new value, this should fix it WN.
            if (context.entity.get.id === 23) context.entity.deleteItem(id)
            if (context.entity.get.id === 3) context.entity.deleteItem(id)
            // -- end
            if(context.entity.get.has_multiple_defaults === 'No') {
              context.entity.setItem(response.data)
            }
            context.$loader.remove('entityItemsSaveItem')
            context.clickedSave = false
          })
          .catch(error => {
            context.$error.set(error, 'It was not possible to update this entity item.')
            context.$loader.remove('entityItemsSaveItem')
            context.clickedSave = false
          })
    },

    /**
     * Delete an entity item.
     */
    deleteItem(id) {
      const context = this
      swal({
        title: 'Delete?',
        text: 'Are you sure you want to delete entity item ' + id + '?',
        type: 'warning',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      })
          .then(function () {
            context.$loader.add('entityItemsDeleteItem')
            const entityItem = new EntityItem(id)
            entityItem.delete()
                .then(response => {
                  context.entity.deleteItem(id)
                  context.$alert.set('Item deleted!', 'success', 3)
                  context.$loader.remove('entityItemsDeleteItem')
                })
                .catch(error => {
                  context.$error.set(error, 'It was not possible to delete the Entity Item!')
                  context.$loader.remove('entityItemsDeleteItem')
                })
          })
    },

    /**
     * Determine if a given field/column should be visible or not on the
     * current entity, since different entities may have different available
     * fileds/columns.
     * @param string field The name of the field.
     */
    showField(field)  {
      return this.entity && this.entity.get.fields ? this.entity.get.fields.available.includes(field) : null
    },

    /**
     * Edit a current entity item enabling the input fields in the form.
     *
     * @param id integer
     * @param status boolean The status to set the edit form to. "False" means
     *                       that the form should be hidden.
     * @param reset boolean (optional) If "true" the old value won't be set
     *                      back to the items model, when the edit form is
     *                      disabled.
     */
    editItem(id, status, reset = false) {
      const context = this
      //console.log(this, 'gg', context.formData, id)

      context.resetEditStatus()
      context.formData.edit[id] = status

      // Make a copy of the original value in order to set it back in case
      // the user cancel the editing.
      if (status) {
        context.formData.oldValues[id] = {}
        Vue.util.extend(context.formData.oldValues[id], context.entity.getItem(id))
        // change selected as a values of id for selecting checkbox of saved / old values WN
        if (this.entity.get.id === 23 && context.formData.oldValues[id].userfield1_value && context.formData.oldValues[id].userfield1_value !== 'No') {
          this.selected = context.formData.oldValues[id].userfield1_value
          this.selected = this.selected.split(",")
        }
        // channel ownership
        if (this.entity.get.id === 3 && context.formData.oldValues[id].description) {
          this.interested = context.formData.oldValues[id].description
          this.interested = this.interested.split(",")
        }

        if ((this.entity.get.id === 26 || this.entity.get.id === 31) && context.formData.oldValues[id].description) {
          this.satCountries = context.formData.oldValues[id].description
          this.satCountries = this.satCountries.split(",")
        }

        if (this.entity.get.id === 24 && context.formData.oldValues[id].userfield1_value) {
          this.usage = context.formData.oldValues[id].userfield1_value
          this.usage = this.usage.split(",")
        }

        if (this.entity.get.id === 30 && context.formData.oldValues[id].userfield1_value) {
          this.issueTypes = context.formData.oldValues[id].userfield1_value
          this.issueTypes = this.issueTypes.split(",")
        }

        if (this.entity.get.id === 27 && context.formData.oldValues[id].label === 'Channels') {
          this.channelsItemInDM = true
          if(context.formData.oldValues[id].description) {
            this.channels = context.formData.oldValues[id].description
            this.channels = this.channels.split(",")
          }
        }

        if (this.entity.get.id === 27 && context.formData.oldValues[id].label === 'Offtube') {
          this.channelsItemInDM = true
          if(context.formData.oldValues[id].description) {
            this.runTypes = context.formData.oldValues[id].description
            this.runTypes = this.runTypes.split(",")
          }
        }

        if (this.entity.get.id === 27 && (context.formData.oldValues[id].label === 'Change Run Type' || context.formData.oldValues[id].label === 'Change Delivery Type')) {
          this.channelsItemInDM = true
          if(context.formData.oldValues[id].userfield1_value) {
            this.channels = context.formData.oldValues[id].userfield1_value
            this.channels = this.channels.split(",")
          }

          if(context.formData.oldValues[id].userfield2_value) {
            this.runTypes = context.formData.oldValues[id].userfield2_value
            this.runTypes = this.runTypes.split(",")
          }
        }

        if(this.entity.get.id === 27 && _.includes(['Inhouse','Remote','Onsite','Baltic'], context.formData.oldValues[id].label)) {
          this.offtubeLocationsInDM = true
          if(context.formData.oldValues[id].description) {
            this.offtubeLocations = context.formData.oldValues[id].description
            this.offtubeLocations = this.offtubeLocations.split(",")
          }
        }


      }
      else if (!reset) {
        context.formErrors.clear()
      }

    },

    /**
     * Reset the edit status of the items.
     */
    resetEditStatus() {
      const context = this
      this.selected = []
      this.interested = []
      this.satCountries = []
      this.usage = []
      this.issueTypes = []
      this.channels = []
      this.runTypes = []
      this.channelsItemInDM = false
      this.offtubeLocations = []
      this.offtubeLocationsInDM = false
      //console.log(context.entity.get.has_multiple_defaults, 'Multiple Defaults')
      context.entity.get.items.forEach(function(v, k) {
        Vue.set(context.formData.edit, v.id, false)
        if (context.formData.oldValues[v.id]) {
          if(context.entity.get.has_multiple_defaults === 'No'){
            context.entity.setItem(context.formData.oldValues[v.id], false)
            delete context.formData.oldValues[v.id]
          }
        }
      })
    },

    /**
     *
     */
    resetForm() {
      Object.assign(this.$data.formData, this.defaultData())
    },

    /**
     * Get the referenced value.
     * @param integer id The id of the entity item.
     * @return string
     */
    getReferencedValue(id) {
      let label = ''
      this.referencedEntity.get.items.forEach(function(v, k) {
        if (v.id == id) label = v.label
      })
      return label
    },
    getRunTypeValue(id) {
      let label = ''
      this.runTypeEntity.get.items.forEach(function(v, k) {
        if (v.id == id) label = v.label
      })
      return label
    },
    getSkillValue(id) {
      let label = ''
      this.skillEntity.get.items.forEach(function(v, k) {
        if (v.id == id) label = v.label
      })
      return label
    },
    getLocationValue(id) {
      let label = ''
      this.locationEntity.get.items.forEach(function(v, k) {
        if (v.id == id) label = v.label
      })
      return label
    },
    // getDeliveryTypeValue(id) {
    //     let label = ''
    //     this.deliveryTypeEntity.get.items.forEach(function(v, k) {
    //         if (v.id == id) label = v.label
    //     })
    //     return label
    // },
    isFormClean(scope) {
      return this.formFields[scope] &&  Object.keys(this.formFields[scope]).every(k => {
        return this.formFields[scope][k].pristine
      });
    },

    getFieldLabel(label, defaultValue) {
      if (!this.entity.get.fields || !this.entity.get.fields.settings[label]) return defaultValue
      return this.entity.get.fields.settings[label].label || defaultValue
    },
    countryInterests(c) {
      if (c && c.indexOf(',') > -1) {
        let _c = c.split(',')
        let _n
        Lazy(_c).each(item => {
          Lazy(this.countries).each(v => {
            if (v.code === item) _n = _n ? _n + ', ' + v.label : v.label
          })
        })
        return _n
      }
      Lazy(this.countries).each(v => { if (v.code === c) c = v.label })
      return c
    },
    dataminerChannels(c) {
      const context = this
      if (c && c.indexOf(',') > -1) {
        let _c = c.split(',')
        let _n
        Lazy(_c).each(item => {
          _n = _n ? _n + ', ' + context.getReferencedValue(item) : context.getReferencedValue(item)
        })
        return _n
      }

      c = context.getReferencedValue(c)
      return c
    },
    dataminerRunTypes(c) {
      const context = this
      if (c && c.indexOf(',') > -1) {
        let _c = c.split(',')
        let _n
        Lazy(_c).each(item => {
          _n = _n ? _n + ', ' + context.getRunTypeValue(item) : context.getRunTypeValue(item)
        })
        return _n
      }

      c = context.getRunTypeValue(c)
      return c
    },
    displayingUsageTypes(value) {
      let types = []

      if(value) {
        types = value.split(',').filter(v => this.getUsageTypes.includes(v));
      }

      return types.join(',');
    },
    dataminerCommentators(c) {
      const context = this
      if (c && c.indexOf(',') > -1) {
        let _c = c.split(',')
        let _n
        Lazy(_c).each(item => {
          _n = _n ? _n + ', ' + context.getSkillValue(item) : context.getSkillValue(item)
        })
        return _n
      }

      c = context.getSkillValue(c)
      return c
    },
    dataminerLocations(c) {
      const context = this
      if (c && c.indexOf(',') > -1) {
        let _c = c.split(',')
        let _n
        Lazy(_c).each(item => {
          _n = _n ? _n + ', ' + context.getLocationValue(item) : context.getLocationValue(item)
        })
        return _n
      }

      c = context.getLocationValue(c)
      return c
    },
    dataminerSkillCountry(c) {
      let lbl = ''
      let skill = this.skillEntity.get.items.find(s => s.id == c)
      if(skill && skill.country) {
        let country = this.countries.find(c => c.code === skill.country)
        lbl = country.label
      }
      return lbl
    },
    addCommentator(item) {
      console.log(item)
      let currentDesc = item.description ? item.description.split(',') : []
      currentDesc.push(this.newCommentatorSkill.skill.value)
      item.description = currentDesc.join(',')
      this.newCommentatorSkill = Object.assign({}, this.addSkill())
      console.log(item)
    },
    deleteCommentator(item, c) {
      console.log(item)
      console.log(c)
      let currentDesc = item.description.split(',')
      currentDesc.splice(currentDesc.indexOf(c), 1)
      item.description = currentDesc.join(',')
      console.log(item)
    },
    getCommentators(value) {
      return value ? value.split(',') : []
    },

    toggleType(id) {

      let currentFlag = _.includes(this.expandedRows, id)
      let revExpandedRows = []
      if(currentFlag) {
        this.expandedRows.forEach(i => {
          if(i !== id ) {
            revExpandedRows.push(i)
          }
        })
      }
      else {
        this.expandedRows.forEach(i => {
          revExpandedRows.push(i)
        })
        revExpandedRows.push(id)
      }

      this.expandedRows = revExpandedRows
      // const context = this
      // context.expandedRows = []
      // context.groupedByReferenceItems.forEach(i => {
      //     if(i.expanded && i.type !== id) {
      //         context.expandedRows.push(i.type)
      //     }
      //     if(i.type === id) {
      //         i.expanded = !currentFlag
      //         if(i.expanded) {
      //             context.expandedRows.push(i.type)
      //         }
      //     }
      // })

    },

    toggleExpansion(expandall) {
      if(expandall) {
        this.expandedRows = []
        this.groupedByReferenceItems.forEach(i => {
          this.expandedRows.push(i.type)
          i.expanded = true
        });
      }
      else {
        this.expandedRows = []
        this.groupedByReferenceItems.forEach(i => {
          i.expanded = false
        });
      }
    },

    /**
     * Return true if the curren user country match the item country
     */
    isAllowedToDrag(country) {
      return !country || country === app.$data.user.get.country
    },

    /**
     * Return default form data.
     */
    defaultData() {
      return {
        edit: {},
        oldValues: {},
        oldListOrder: [],
        newItem: {
          label: '',
          shortlabel: '',
          text: '',
          description: '',
          reference: 0,
          active: true,
          default: false,
          fake: false,
          country: '',
          custom: ''
        },
        showAddItemForm: false,
        draggedItems: 0,
        selected: [],
        interested: [],
        satCountries: [],
        usage: [],
        issueType: [],
        channels: [],
        runTypes: [],
        offtubeLocations: []
      }
    },
    addSkill() {
      return {
        country: {
          value: ''
        },
        skill: {
          value: ''
        }
      }
    },
    dataminerFI(val, entityName) {
      let item = null
      switch(entityName) {
        case 'FEC':
          item = this.fecEntity && this.fecEntity.get && this.fecEntity.get.items && this.fecEntity.get.items.length > 0 ? _.find(this.fecEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'Modulation':
          item = this.modulationEntity && this.modulationEntity.get && this.modulationEntity.get.items && this.modulationEntity.get.items.length > 0 ? _.find(this.modulationEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'Polarization':
          item = this.polarizationEntity && this.polarizationEntity.get && this.polarizationEntity.get.items && this.polarizationEntity.get.items.length > 0 ? _.find(this.polarizationEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'Resolution':
          item = this.resolutionEntity && this.resolutionEntity.get && this.resolutionEntity.get.items && this.resolutionEntity.get.items.length > 0 ? _.find(this.resolutionEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'Frame Rate':
          item = this.framerateEntity && this.framerateEntity.get && this.framerateEntity.get.items && this.framerateEntity.get.items.length > 0 ? _.find(this.framerateEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'Satellite':
          item = this.satelliteEntity && this.satelliteEntity.get && this.satelliteEntity.get.items && this.satelliteEntity.get.items.length > 0 ? _.find(this.satelliteEntity.get.items, i => i.id === parseInt(val)) : null
          break
        case 'System Format':
          item = this.systemformatEntity && this.systemformatEntity.get && this.systemformatEntity.get.items && this.systemformatEntity.get.items.length > 0 ? _.find(this.systemformatEntity.get.items, i => i.id === parseInt(val)) : null
          break
        default:
          item = null
          break
      }

      return item !== null ? item.label : ''
    },

    getEntityList(entityName) {
      let entity = null
      switch(entityName) {
        case 'FEC':
          entity = this.fecEntity && this.fecEntity.get ? this.fecEntity.get.items : null
          break
        case 'Modulation':
          entity = this.modulationEntity && this.modulationEntity.get ? this.modulationEntity.get.items : null
          break
        case 'Polarization':
          entity = this.polarizationEntity && this.polarizationEntity.get ? this.polarizationEntity.get.items : null
          break
        case 'Resolution':
          entity = this.resolutionEntity && this.resolutionEntity.get ? this.resolutionEntity.get.items : null
          break
        case 'Frame Rate':
          entity = this.framerateEntity && this.framerateEntity.get ? this.framerateEntity.get.items : null
          break
        case 'Satellite':
          entity = this.satelliteEntity && this.satelliteEntity.get ? this.satelliteEntity.get.items : null
          break
        case 'System Format':
          entity = this.systemformatEntity && this.systemformatEntity.get ? this.systemformatEntity.get.items : null
          break
        default:
          entity = null
          break
      }

      return entity !== null ? entity : []
    }

  }

}

</script>

<template>
  <div id="entity-items" class="admin book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
      <h2 class="page-title"><span v-if="loaded">Entity: {{entity.get.label}}</span></h2>
      <div class="secondary-menu btn-group" role="group">
        <router-link exact :to="{name: 'entity'}" class="btn btn-default"><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back to entities</span></router-link>
        <button v-if="(isSportPage || isLeaguePage) && mainAdmin === 1" class="btn btn-default" @click="formData.showAddItemForm = true"><font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Add</span></button>
        <button v-if="(!isSportPage && !isLeaguePage)" class="btn btn-default" @click="formData.showAddItemForm = true"><font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Add</span></button>
      </div>
      <div v-if="isLeaguePage" class="secondary-menu btn-group" role="group">
        <button v-if="!showAllLeagues" class="btn btn-default" @click="showAllLeagues = true">Show All Leagues</button>
        <button v-else class="btn btn-default" @click="showAllLeagues = false">Show Active Leagues</button>
      </div>
      <transition name="fade">
        <div v-if="listOrderIsChanged" class="secondary-menu btn-group" role="group">
          <button class="btn btn-primary" @click="saveListOrder">Save list order</button>
          <button class="btn btn-danger" @click="resetListOrder">Cancel</button>
        </div>
      </transition>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div class="panel panel-info" v-else>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="text-center"><font-awesome-icon icon="sort"/></th>
            <th>ID</th>
            <!-- <th>Revision</th> -->
            <th v-if="showField('revision_id')">{{getFieldLabel('revision_id', 'VSC ID')}}</th>
            <th v-if="entity.get.id === 28">{{getFieldLabel('reference', 'Reference')}}</th>
            <th v-if="showField('label')" v-html="entity.get.id === 23 ? 'House Number' : getFieldLabel('label', 'Label')"></th>
            <th v-if="entity.get.id === 16">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id === 16">{{entity.get.userfield2_description}}</th>
            <th v-if="entity.get.id === 23">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id === 23">{{entity.get.userfield2_description}}</th>
            <th v-if="entity.get.id === 24">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id === 26">{{entity.get.userfield2_description}}</th>
            <th v-if="entity.get.id === 30">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id !== 3 && showField('shortlabel')">{{getFieldLabel('shortlabel', 'Short Label')}}</th>
            <th v-if="showField('description')">{{getFieldLabel('description', 'Description')}}</th>
            <th v-if="entity.get.id !== 21 && showField('text')">{{getFieldLabel('text', 'Text')}}</th>
            <th v-if="entity.get.id === 3">Fork name</th>
            <th v-if="entity.get.id === 26 || entity.get.id === 31 || entity.get.id === 33">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id === 31 || entity.get.id === 33">{{entity.get.userfield2_description}}</th>
            <th v-if="showField('reference') && entity.get.id !== 28">{{getFieldLabel('reference', 'Reference')}}</th>
            <th v-if="entity.get.id === 27">Run Type</th>
            <th v-if="showField('active') && entity.get.id !== 27">Active</th>
            <th v-if="showField('default') && entity.get.id !== 27 && entity.get.id !== 33">Default</th>
            <th v-if="showField('fake') && entity.get.id !== 27">Mock {{entity.get.label}}</th>
            <th v-if="entity.get.id === 3 || entity.get.id === 37">{{entity.get.userfield1_description}}</th>
            <th v-if="entity.get.id === 37">{{entity.get.userfield2_description}}</th>
            <th v-if="entity.get.id !== 26 && entity.get.id !== 27 && entity.get.id !== 28 && entity.get.id !== 31 && entity.get.id !== 33 && entity.get.id !== 36 && entity.get.id !== 37 && entity.get.id !== 21">Country</th>
            <th>Created</th>
            <th>Updated</th>
            <th v-if="entity.get.id !== 28"></th>
            <th v-else class="action">
              <button class="btn btn-primary btn-xs" @click="toggleExpansion(true)">
                <font-awesome-icon icon="plus" aria-hidden="true"/>
              </button>
              <button class="btn btn-default btn-xs" @click="toggleExpansion(false)">
                <font-awesome-icon icon="minus" aria-hidden="true"/>
              </button>
            </th>
          </tr>
          </thead>
          <draggable v-model="items"  tag="tbody" @start="onStart" @end="onEnd" v-if="entity.get.id !== 28" draggable=".draggable-item">
            <tr v-for="(item, index) in orderedItems" :key="item.id" v-if="formData.edit[item.id] || entity.get.id === 23 || entity.get.id === 3 ? entityItem.isVisible(item.id, 'Yes') : entityItem.isVisible(item, entity.get.has_multiple_defaults)" class="cell-vertical-align-middle" :class="isAllowedToDrag(item.country) ? 'draggable-item' : 'undraggable-item'">
              <td class="grab text-center"><span class="grab-item"><font-awesome-icon icon="align-justify"/></span></td>
              <td>{{item.id}}</td>
              <!-- VSC ID for leagues -->
              <td v-if="showField('revision_id') && item.entity === 21">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.revision_id')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.revision_id}}</p>
                  <!-- revision id, aka VSC ID should not be editable / addable -->
                </div>
              </td>
              <td v-if="showField('label') && item.entity !== 36">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.label')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.label}}</p>
                  <input v-validate="'required'" :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.label" name="label" type="text" class="form-control">
                </div>
              </td>
              <td v-if="showField('label') && item.entity === 36">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.label')}" class="book-label-capitilize">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.label}}</p>
                  <select class="form-control" name="reference" :data-vv-scope="'item_' + item.id" v-model="item.label" v-if="formData.edit[item.id]">
                    <option value="feed">Feed</option>
                    <option value="production">Production</option>
                  </select>
                </div>
              </td>
              <td v-if="item.entity === 23">
                <book-ingestion
                    v-if="!formData.edit[item.id] && item.userfield1_value"
                    :ingestionTypes="item.userfield1_value.split(',')"
                    :items="item.userfield1_value.split(',')"
                    :itemId="1"
                    itemType="production"
                    :readonly="true"
                    :active="true"
                >
                </book-ingestion>
                <div v-if="formData.edit[item.id]">
                  <div v-for="values in getUserfieldValues(entity.get.userfield1_values)">
                    <input name="userfield1_value"
                           :data-vv-scope="'item_' + values.id"
                           :id="values.id"
                           type="checkbox"
                           :value="values.id"
                           v-model="selected"
                    >
                    <label
                        class="custom-control-label"
                        :data-vv-scope="'item_' + values.id"
                        :for="values.id"
                        v-html="values.label">
                    </label>
                  </div>
                  <book-ingestion
                      v-if="selected"
                      :ingestionTypes="selecteds"
                      :items="selected"
                      :itemId="1"
                      itemType="production"
                      :readonly="true"
                      :active="true"
                  >
                  </book-ingestion>
                </div>
              </td>
              <td v-if="item.entity === 16">
                <p class="form-control-static" v-if="!formData.edit[item.id] && item.userfield1_value">{{ucFirst(item.userfield1_value)}}</p>
                <select class="form-control" name="reference" :data-vv-scope="'item_' + item.id" v-model="item.userfield1_value" v-if="formData.edit[item.id]">
                  <option value="">None</option>
                  <option v-for="item in getUserfieldValues(entity.get.userfield1_values)" :value="item.id">{{item.label}}</option>
                </select>
              </td>
              <td v-if="item.entity === 16">
                <p class="form-control-static" v-if="!formData.edit[item.id] && item.userfield2_value">{{ucFirst(item.userfield2_value)}}</p>
                <select class="form-control" name="reference" :data-vv-scope="'item_' + item.id" v-model="item.userfield2_value" v-if="formData.edit[item.id]">
                  <option value="">None</option>
                  <option v-for="item in getUserfieldValues(entity.get.userfield2_values)" :value="item.id">{{item.label}}</option>
                </select>
              </td>
              <td v-if="item.entity === 23 && entity.get.userfield2_description">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.userfield2_value')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.userfield2_value}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.userfield2_value" name="clicked" type="text" class="form-control">
                </div>
              </td>
              <td v-if="item.entity === 24">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  {{displayingUsageTypes(item.userfield1_value)}}
                </p>
                <div v-if="formData.edit[item.id]">
                  <div v-for="type in getUsageTypes">
                    <input name="userfield1_value"
                           :data-vv-scope="'item_' + type"
                           :id="type"
                           type="checkbox"
                           :value="type"
                           v-model="usage"
                    >
                    <label
                        class="custom-control-label"
                        :data-vv-scope="'item_' + type"
                        :for="type"
                        v-html="type">
                    </label>
                  </div>
                </div>
              </td>
              <td v-if="item.entity === 26">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.userfield2_value')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.userfield2_value}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.userfield2_value" name="clicked" type="text" class="form-control">
                </div>
              </td>
              <td v-if="item.entity === 26 || item.entity === 31">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  {{countryInterests(item.description)}}
                </p>
                <div v-if="formData.edit[item.id]">
                  <div v-for="country in countries">
                    <input name="userfield1_value"
                           :data-vv-scope="'item_' + country.code"
                           :id="country.code"
                           type="checkbox"
                           :value="country.code"
                           v-model="satCountries"
                    >
                    <label
                        class="custom-control-label"
                        :data-vv-scope="'item_' + country.code"
                        :for="country.code"
                        v-html="country.label">
                    </label>
                  </div>
                </div>
              </td>
              <td v-if="item.entity === 30">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  {{displayingUsageTypes(item.userfield1_value)}}
                </p>
                <div v-if="formData.edit[item.id]">
                  <div v-for="type in getUsageTypes">
                    <input name="userfield1_value"
                           :data-vv-scope="'item_' + type"
                           :id="type"
                           type="checkbox"
                           :value="type"
                           v-model="issueTypes"
                    >
                    <label
                        class="custom-control-label"
                        :data-vv-scope="'item_' + type"
                        :for="type"
                        v-html="type">
                    </label>
                  </div>
                </div>
              </td>
              <td v-if="item.entity !== 3 && showField('shortlabel')">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.shortlabel')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.shortlabel}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.shortlabel" name="shortlabel" type="text" class="form-control">
                </div>
              </td>
              <td v-if="showField('description')">
                <div v-if="item.entity === 27 && item.label === 'Channels'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerChannels(item.description)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <div v-for="channel in getChannelsList">
                      <input name="description"
                             :data-vv-scope="'item_' + channel.id"
                             :id="channel.id"
                             type="checkbox"
                             :value="channel.id"
                             v-model="channels"
                      >
                      <label
                          class="custom-control-label"
                          :data-vv-scope="'item_' + channel.id"
                          :for="channel.id"
                          v-html="channel.label">
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else-if="item.entity === 27 && item.label === 'Change Run Type'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{getRunTypeValue(item.description)}}</p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="item in runTypeEntity.get.items" :value="item.id">{{item.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 27 && item.label === 'Offtube'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerRunTypes(item.description)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <div v-for="runType in getRunTypeList">
                      <input name="description"
                             :data-vv-scope="'item_' + runType.id"
                             :id="runType.id"
                             type="checkbox"
                             :value="runType.id"
                             v-model="runTypes"
                      >
                      <label
                          class="custom-control-label"
                          :data-vv-scope="'item_' + runType.id"
                          :for="runType.id"
                          v-html="runType.label">
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else-if="item.entity === 27 && item.label === 'Commentators'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerCommentators(item.description)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <table class="table" id="commentators" >
                      <thead>
                      <tr>
                        <th style="width: 126px;">Country</th>
                        <th style="width: 217px;">Skill</th>
                        <th></th>
                      </tr>
                      </thead>
                      <template v-if="item.description">
                        <tr v-for="d in getCommentators(item.description)" :key="d">
                          <td style="width: 126px;">
                            <span>{{dataminerSkillCountry(d)}}</span>
                          </td>
                          <td style="width: 217px;">
                            <span>{{getSkillValue(d)}}</span>
                          </td>
                          <td>
                            <button class="btn btn-xs"
                                    @click="deleteCommentator(item, d)">
                              <font-awesome-icon icon="trash"/>
                            </button>
                          </td>
                        </tr>
                      </template>

                      <tr>
                        <td style="width: 126px;">
                          <select v-model="newCommentatorSkill.country.value">
                            <option value=""></option>
                            <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                          </select>
                        </td>
                        <td style="width: 217px;">
                          <select v-model="newCommentatorSkill.skill.value" :disabled="!newCommentatorSkill.country.value">
                            <option value=""></option>
                            <option v-for="skill in getSkillsList" :value="skill.id">{{skill.label}}</option>
                          </select>
                        </td>
                        <td>
                          <button class="btn btn-xs"
                                  @click="addCommentator(item)" :disabled="!newCommentatorSkill.skill.value">
                            <font-awesome-icon icon="plus"/>&nbsp;Add
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-else-if="item.entity === 27 && (item.label === 'Inhouse' || item.label === 'Remote' || item.label === 'Onsite' || item.label === 'Baltic')">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerLocations(item.description)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <div v-for="location in getLocationsList" :key="location.id">
                      <input name="description"
                             :data-vv-scope="'item_' + location.id"
                             :id="location.id"
                             type="checkbox"
                             :value="location.id"
                             v-model="offtubeLocations"
                      >
                      <label
                          class="custom-control-label"
                          :data-vv-scope="'item_' + location.id"
                          :for="location.id"
                          v-html="location.label">
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else-if="item.entity === 35 || item.entity === 36">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{system.getCountryById(item.description)}}</p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 23">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    <font-awesome-icon icon="check" v-if="item.description === 'yes'"/>
                  </p>
                  <input name="active" :data-vv-scope="'item_' + item.id"  v-if="formData.edit[item.id]" type="checkbox" v-model="item.description" true-value="yes" false-value="no">
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'FEC'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'FEC')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('FEC')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'Modulation'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'Modulation')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('Modulation')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'Polarization'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'Polarization')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('Polarization')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'Resolution'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'Resolution')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('Resolution')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'Satellite'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'Satellite')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('Satellite')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else-if="item.entity === 37 && item.label === 'System Format'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.description, 'System Format')}}
                  </p>
                  <select class="form-control" name="description" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.description" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="fec in getEntityList('System Format')" :value="fec.id">{{fec.label}}</option>
                  </select>
                </div>
                <div v-else :class="{'has-error': formErrors.has('item_' + item.id + '.description')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.description}}</p>
                  <input v-validate :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.description" name="description" type="text" class="form-control">
                </div>
              </td>
              <!-- normal text field area -->
              <td v-if="showField('text') && item.entity !== 36 && !(item.entity === 37 && item.shortlabel === 'Frame Rate') && item.entity !== 3 && item.entity !== 21">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.text')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.text}}</p>
                  <input v-validate :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.text" name="text" type="text" class="form-control">
                </div>
              </td>
              <td v-else-if="showField('text') && item.entity === 36">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.text')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{system.getCountryById(item.text)}}</p>
                  <select class="form-control" name="text" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.text" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="country in forkCountries" :value="country.code">{{country.label}}</option>
                  </select>
                </div>
              </td>
              <td v-else-if="showField('text') && item.entity === 3">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.text')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.text}}</p>
                  <select class="form-control" name="text" v-validate :data-vv-scope="'item_' + item.id" v-model="item.text" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option value="SE">SE</option>
                    <option value="DK">DK</option>
                    <option value="NO">NO</option>
                    <option value="POL">POL</option>
                    <option value="BALT">BALT</option>
                    <option value="FIN">FIN</option>
                    <option value="NL">NL</option>
                    <option value="ICE">ICE</option>
                    <option value="SHARED">SHARED</option>
                  </select>
                </div>
              </td>
              <td v-else-if="showField('text') && item.entity === 37 && item.shortlabel === 'Frame Rate'">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.text')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerFI(item.text, 'Frame Rate')}}
                  </p>
                  <select class="form-control" name="text" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.text" v-if="formData.edit[item.id]">
                    <option value="">None</option>
                    <option v-for="formatRate in getEntityList('Frame Rate')" :value="formatRate.id">{{formatRate.label}}</option>
                  </select>
                </div>
              </td>
              <td v-if="item.entity === 3 && showField('shortlabel')">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.shortlabel')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.shortlabel}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.shortlabel" name="shortlabel" type="text" class="form-control">
                </div>
              </td>
              <td v-if="showField('reference') && item.entity !== 27">
                <p class="form-control-static" v-if="!formData.edit[item.id]">{{getReferencedValue(item.reference)}}</p>
                <select class="form-control" name="reference" v-validate="'required'" :data-vv-scope="'item_' + item.id" v-model="item.reference" v-if="formData.edit[item.id]">
                  <option value="">None</option>
                  <option v-for="item in referencedEntity.get.items" :value="item.id">{{item.label}}</option>
                </select>
              </td>
              <td v-if="item.entity === 27">
                <div v-show="item.label === 'Change Run Type' || item.label === 'Change Delivery Type'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerChannels(item.userfield1_value)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <div v-for="channel in getChannelsList">
                      <input name="userfield1_value"
                             :data-vv-scope="'item_' + channel.id"
                             :id="channel.id"
                             type="checkbox"
                             :value="channel.id"
                             v-model="channels"
                      >
                      <label
                          class="custom-control-label"
                          :data-vv-scope="'item_' + channel.id"
                          :for="channel.id"
                          v-html="channel.label">
                      </label>
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="item.entity === 27">
                <div v-show="item.label === 'Change Run Type' || item.label === 'Change Delivery Type'">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">
                    {{dataminerRunTypes(item.userfield2_value)}}
                  </p>
                  <div v-if="formData.edit[item.id]">
                    <div v-for="runType in getRunTypeList">
                      <input name="userfield2_value"
                             :data-vv-scope="'item_' + runType.id"
                             :id="runType.id"
                             type="checkbox"
                             :value="runType.id"
                             v-model="runTypes"
                      >
                      <label
                          class="custom-control-label"
                          :data-vv-scope="'item_' + runType.id"
                          :for="runType.id"
                          v-html="runType.label">
                      </label>
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="item.entity === 31 || item.entity === 33">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  <font-awesome-icon icon="check" v-if="item.userfield1_value === 'yes'"/>
                </p>
                <input name="active" :data-vv-scope="'item_' + item.id"  v-if="formData.edit[item.id]" type="checkbox" v-model="item.userfield1_value" true-value="yes" false-value="no">
              </td>
              <td v-if="item.entity === 33">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  <font-awesome-icon icon="check" v-if="item.userfield2_value === 'yes'"/>
                </p>
                <input name="active" :data-vv-scope="'item_' + item.id"  v-if="formData.edit[item.id]" type="checkbox" v-model="item.userfield2_value" true-value="yes" false-value="no">
              </td>
              <td v-if="item.entity === 37">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.userfield1_value')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.userfield1_value}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.userfield1_value" name="userfield1_value" type="text" class="form-control">
                </div>
              </td>
              <td v-if="item.entity === 37">
                <div :class="{'has-error': formErrors.has('item_' + item.id + '.userfield2_value')}">
                  <p class="form-control-static" v-if="!formData.edit[item.id]">{{item.userfield2_value}}</p>
                  <input :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" v-model:trim="item.userfield2_value" name="userfield2_value" type="text" class="form-control">
                </div>
              </td>
              <td v-if="showField('active') && item.entity !== 27">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  <font-awesome-icon icon="check" v-if="item.active"/>
                </p>
                <input name="active" :data-vv-scope="'item_' + item.id"  v-if="formData.edit[item.id]" type="checkbox" v-model="item.active">
              </td>
              <td v-if="showField('default') && item.entity !== 27 && item.entity !== 31 && item.entity !== 33">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  <font-awesome-icon icon="check" v-if="item.default"/>
                </p>
                <input name="default" :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" type="checkbox" v-model="item.default">
              </td>
              <td v-if="showField('fake') && item.entity !== 27 && item.entity !== 31 && item.entity !== 33">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  <font-awesome-icon icon="check" v-if="item.fake"/>
                </p>
                <input name="fake" :data-vv-scope="'item_' + item.id" v-if="formData.edit[item.id]" type="checkbox" v-model="item.fake">
              </td>
              <td v-if="item.entity === 3">
                <p class="form-control-static" v-if="!formData.edit[item.id]">
                  {{countryInterests(item.description)}}
                </p>
                <div v-if="formData.edit[item.id]">
                  <div v-for="country in countries">
                    <input name="userfield1_value"
                           v-if="country.code !== item.country"
                           :data-vv-scope="'item_' + country.code"
                           :id="country.code"
                           type="checkbox"
                           :value="country.code"
                           v-model="interested"
                    >
                    <label
                        class="custom-control-label"
                        v-if="country.code !== item.country"
                        :data-vv-scope="'item_' + country.code"
                        :for="country.code"
                        v-html="country.label">
                    </label>
                  </div>
                </div>
              </td>


              <!-- normal country area excluded from some entities, for ex: leagues -->
              <td v-if="item.entity !== 26 && item.entity !== 27 && item.entity !== 31 && item.entity !== 33 && item.entity !== 36 && item.entity !== 37 && item.entity !== 21">
                <p class="form-control-static" v-if="!formData.edit[item.id]">{{system.getCountryById(item.country)}}</p>
                <select class="form-control" name="country" v-model="item.country" v-if="formData.edit[item.id]" :data-vv-scope="'item_' + item.id">
                  <option value="">None</option>
                  <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                </select>
              </td>
              <td>{{item.created}}</td>
              <td>{{item.updated}}</td>
              <td class="action" v-if="(!isSportPage && !isLeaguePage)">
                <button class="btn btn-success btn-xs" v-if="formData.edit[item.id]" @click="saveItem(item.id)" :disabled="formErrors.any('item_' + item.id) || isFormClean('item_' + item.id)"><font-awesome-icon icon="save"/></button>
                <button class="btn btn-default btn-xs" v-if="formData.edit[item.id]" @click="editItem(item.id, false)"><font-awesome-icon icon="times"/></button>
                <button class="btn btn-primary btn-xs" v-if="!formData.edit[item.id]" @click="editItem(item.id, true)"><font-awesome-icon icon="pencil-alt"/></button>
                <button class="btn btn-danger btn-xs" v-if="!formData.edit[item.id]" @click="deleteItem(item.id)"><font-awesome-icon icon="trash"/></button>
              </td>
              <td class="action" v-if="(isLeaguePage || isSportPage) && mainAdmin === 1">
                <button class="btn btn-success btn-xs" v-if="formData.edit[item.id]" @click="saveItem(item.id)" :disabled="formErrors.any('item_' + item.id) || isFormClean('item_' + item.id)"><font-awesome-icon icon="save"/></button>
                <button class="btn btn-default btn-xs" v-if="formData.edit[item.id]" @click="editItem(item.id, false)"><font-awesome-icon icon="times"/></button>
                <button class="btn btn-primary btn-xs" v-if="!formData.edit[item.id]" @click="editItem(item.id, true)"><font-awesome-icon icon="pencil-alt"/></button>
                <button class="btn btn-danger btn-xs" v-if="!formData.edit[item.id]" @click="deleteItem(item.id)"><font-awesome-icon icon="trash"/></button>
              </td>
            </tr>
          </draggable>
          <!-- DIFFERENT ENTITY LAYOUT -->
          <tbody v-if="entity.get.id === 28">
          <template v-for="type in groupedByReferenceItems">
            <tr :key="type.type" :class="type.expanded ? 'book-type-group-label-row-expanded' : 'book-type-group-label-row'">
              <td></td>
              <td></td>
              <td class="book-type-group-label">
                <p class="form-control-static">
                  <button class="btn btn-xs" :class="[{'btn-default': type.expanded}, {'btn-primary': !type.expanded}]" @click="toggleType(type.type)">
                    <font-awesome-icon v-if="!type.expanded" icon="plus" aria-hidden="true"/>
                    <font-awesome-icon v-else icon="minus" aria-hidden="true"/>
                    <span>{{type.groupcount}}</span>
                  </button>
                  {{type.label}}
                </p>
              </td>
              <td>

              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <template v-if="type.expanded">
              <tr v-for="group in type.groups" :key="group.id">
                <td class="grab text-center"><span class="grab-item"><font-awesome-icon icon="align-justify"/></span></td>
                <td>{{group.id}}</td>
                <td>
                  <select v-show="formData.edit[group.id]" class="form-control" name="reference" v-validate="'required'" :data-vv-scope="'item_' + group.id" v-model="group.reference">
                    <option value="">None</option>
                    <option v-for="item in referencedEntity.get.items" :value="item.id">{{item.label}}</option>
                  </select>
                </td>
                <td v-if="showField('label')">
                  <div :class="{'has-error': formErrors.has('item_' + group.id + '.label')}">
                    <p class="form-control-static" v-if="!formData.edit[group.id]">{{group.label}}</p>
                    <input v-validate="'required'" :data-vv-scope="'item_' + group.id" v-if="formData.edit[group.id]" v-model:trim="group.label" name="label" type="text" class="form-control">
                  </div>
                </td>
                <td v-if="showField('description')">
                  <div :class="{'has-error': formErrors.has('item_' + group.id + '.description')}">
                    <p class="form-control-static" v-if="!formData.edit[group.id]">{{group.description}}</p>
                    <input v-validate :data-vv-scope="'item_' + group.id" v-if="formData.edit[group.id]" v-model:trim="group.description" name="description" type="number" class="form-control">
                  </div>
                </td>
                <td v-if="showField('active')">
                  <p class="form-control-static" v-if="!formData.edit[group.id]">
                    <font-awesome-icon icon="check" v-if="group.active"/>
                  </p>
                  <input name="active" :data-vv-scope="'item_' + group.id"  v-if="formData.edit[group.id]" type="checkbox" v-model="group.active">
                </td>
                <td v-if="showField('default')">
                  <p class="form-control-static" v-if="!formData.edit[group.id]">
                    <font-awesome-icon icon="check" v-if="group.default"/>
                  </p>
                  <input name="default" :data-vv-scope="'item_' + group.id" v-if="formData.edit[group.id]" type="checkbox" v-model="group.default">
                </td>
                <td v-if="showField('fake')">
                  <p class="form-control-static" v-if="!formData.edit[group.id]">
                    <font-awesome-icon icon="check" v-if="group.fake"/>
                  </p>
                  <input name="fake" :data-vv-scope="'item_' + group.id" v-if="formData.edit[group.id]" type="checkbox" v-model="group.fake">
                </td>
                <td>{{group.created}}</td>
                <td>{{group.updated}}</td>
                <td class="action">
                  <button class="btn btn-success btn-xs" v-if="formData.edit[group.id]" @click="saveItem(group.id)" :disabled="formErrors.any('item_' + group.id) || isFormClean('item_' + group.id)"><font-awesome-icon icon="save"/></button>
                  <button class="btn btn-default btn-xs" v-if="formData.edit[group.id]" @click="editItem(group.id, false)"><font-awesome-icon icon="times"/></button>
                  <button class="btn btn-primary btn-xs" v-if="!formData.edit[group.id]" @click="editItem(group.id, true)"><font-awesome-icon icon="pencil-alt"/></button>
                  <button class="btn btn-danger btn-xs" v-if="!formData.edit[group.id]" @click="deleteItem(group.id)"><font-awesome-icon icon="trash"/></button>
                </td>
              </tr>
            </template>

          </template>

          </tbody>
        </table>
      </div>
    </div>
    <book-modal maxWidth="400px" @close="formData.showAddItemForm = false" v-if="formData.showAddItemForm">
      <h4 slot="header" class="modal-title">Add item to <strong>{{entity.get.label}}</strong></h4>
      <div slot="body">
        <div class="form-horizontal">
          <div class="form-group" v-if="showField('label') && entity.get.id !== 36" :class="{'has-error': formErrors.has('item_new.label')}">
            <label class="control-label book-width-100" v-html="entity.get.id === 23 ? 'Housenumber' : 'Label'"></label>
            <div class="form-control-100">
              <input v-validate="'required'" data-vv-scope="item_new" v-model:trim="formData.newItem.label" name="label" type="text" class="form-control" >
            </div>
          </div>
          <div class="form-group" v-if="showField('label') && entity.get.id === 36" :class="{'has-error': formErrors.has('item_new.label')}">
            <label class="control-label book-width-100" v-html="'Item type'"></label>
            <div class="form-control-100">
              <select  v-validate="'required'" data-vv-scope="item_new" v-model="formData.newItem.label" name="label" type="text" class="form-control">
                <option value="feed">Feed</option>
                <option value="production">Production</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="showField('description') && entity.get.id !== 36" :class="{'has-error': formErrors.has('item_new.description')}">
            <label class="control-label book-width-100">{{getFieldLabel('description', 'Description')}}</label>
            <div class="form-control-100">
              <input v-validate data-vv-scope="item_new" type="text" class="form-control" v-model:trim="formData.newItem.description" name="description">
            </div>
          </div>
          <div class="form-group" v-if="showField('description') && entity.get.id === 36" :class="{'has-error': formErrors.has('item_new.description')}">
            <label class="control-label book-width-100">Country</label>
            <div class="form-control-100">
              <select class="form-control" data-vv-scope="item_new" name="country" v-model="formData.newItem.description">
                <option value="">None</option>
                <option v-for="country in countries" :value="country.code">{{country.label}}</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="showField('text') && entity.get.id === 36" :class="{'has-error': formErrors.has('item_new.text')}">
            <label class="control-label book-width-100">Fork export country</label>
            <div class="form-control-100">
              <select class="form-control" data-vv-scope="item_new" name="country" v-model="formData.newItem.text">
                <option value="">None</option>
                <option v-for="country in forkCountries" :value="country.code">{{country.label}}</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="showField('reference')">
            <label class="control-label book-width-100">{{entity.get.fields.settings.reference.label}}</label>
            <div class="form-control-100">
              <select class="form-control" v-validate data-vv-scope="item_new" name="reference" v-model="formData.newItem.reference">
                <option value="">None</option>
                <option v-for="item in referencedEntity.get.items" :value="item.id">{{item.label}}</option>
              </select>
            </div>
          </div>
          <!-- Leagues abbreviation / shortlabel -->
          <div class="form-group" v-if="entity.get.id === 21 && showField('shortlabel')">
            <label class="control-label book-width-100">{{entity.get.fields.settings.shortlabel.label}}</label>
            <div class="form-control-100">
              <input v-validate="'required'" data-vv-scope="item_new" v-model:trim="formData.newItem.shortlabel" name="shortlabel" type="text" class="form-control" >
            </div>
          </div>
          <div v-if="(entity.get.id !== 28 && entity.get.id !== 36 && entity.get.id !== 21)" class="form-group">
            <label class="control-label book-width-100">Country</label>
            <div class="form-control-100">
              <select class="form-control" data-vv-scope="item_new" name="country" v-model="formData.newItem.country">
                <option value="">None</option>
                <option v-for="country in countries" :value="country.code">{{country.label}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control-100">
              <div class="checkbox">
                <label class="control-label checkbox-inline" v-if="showField('active')">
                  <input name="active" data-vv-scope="item_new" type="checkbox" v-model="formData.newItem.active">
                  active
                </label>
                <label class="control-label checkbox-inline" v-if="showField('fake')">
                  <input name="fake" data-vv-scope="item_new" type="checkbox" v-model="formData.newItem.fake">
                  mock
                </label>
                <label class="control-label checkbox-inline" v-if="showField('default')">
                  <input name="default" data-vv-scope="item_new" type="checkbox" v-model="formData.newItem.default">
                  default
                </label>
              </div>
            </div>
          </div>
          <p v-if="entity.get.id === 23"><b>NOTES:</b>
            <br>- If you want to add as a <b><i>BLANK</i></b>, write <b><i>null</i></b> as a label
            <br>- If you want to add <b><i>NUMBERS only</i></b>, write <b><i>int</i></b> as a label
          </p>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-success" @click="addItem()" :disabled="isFormClean('item_new') || formErrors.any('item_new')">Save</button>
      </div>
    </book-modal>

  </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-type-group-label {
  // box-shadow: 0 3px 20px -8px rgba($color-shadow, 0.4);
  font-weight: bold;
  font-size: 1.1em;
  // margin-bottom: 5px;
  padding: 4px 0 4px 25px;

}
.book-type-group-label-row {
  background-color: #f6f6f6;
}
.book-type-group-label-row-expanded {
  background-color: #eceaea;
}
th.action {
  text-align: right;
  white-space: nowrap;
}
#commentators {
  td {

    white-space: nowrap;
  }
}

.book-label-capitilize {
  p:first-letter {
    text-transform:capitalize;
  }
}
</style>