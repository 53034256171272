<script>
import productionTypes from '../productionTypes'

export default {
    props: {
        productionType: {
            type: String,
            required: true,
        }
    },
    computed: {
        getBaseRouteName() {
            return this.productionType === 'production' ? 'productions' : this.productionType
        },
        getViewRoute() {
            return this.getBaseRouteName + '.view'
        }
    },
    methods: {
        hasView(view) {
            return productionTypes[this.productionType]?.views?.includes(view)
        }
    }
}
</script>
<template>
    <div class="btn-group" role="group">
        <router-link v-if="hasView('overview')" :to="{name: getViewRoute, params: {view: 'overview'}, query: $route.query}" class="btn btn-default" title="Overview"><font-awesome-icon icon="columns"/></router-link>
        <router-link v-if="hasView('list')" :to="{name: getViewRoute, params: {view: 'list'}, query: $route.query}" class="btn btn-default" title="List"><font-awesome-icon icon="bars"/></router-link>
        <router-link v-if="hasView('timeline')" :to="{name: getViewRoute, params: {view: 'timeline'}, query: $route.query}" class="btn btn-default" title="Timeline"><font-awesome-icon icon="list"/></router-link>
        <router-link v-if="hasView('equipmentOverview')" :to="{name: `${getBaseRouteName}.equipmentOverview`, query: $route.query}" class="btn btn-default" title="Equipment Overview"><font-awesome-icon icon="calendar-week"/></router-link>
    </div>
</template>