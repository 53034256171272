<script>

import Datetime from 'components/common/helpers/Datetime'
import datepicker from 'vue-datepicker/vue-datepicker-es6'

export default {
    components: {
        datepicker
    },
    beforeCreate() {
        const context = this
        if (!context.$route.query.from || !context.$route.query.to) {

            // Note that we can't access context.defaultInterval here, because
            // the component itself hasn't been created yet, when beforeCreate()
            // is called.
            let defaultInterval = context.$options.propsData.hasOwnProperty('defaultInterval') ? context.$options.propsData.defaultInterval : null

            const dateFrom = context.$route.query.from ? context.$route.query.from : moment().format('YYYY-MM-DD')
            const dateTo = context.$route.query.to ? context.$route.query.to : Datetime.getEndDate(dateFrom, 'YYYY-MM-DD', defaultInterval)
            const routeTo = {
                query: Object.assign({
                    from: dateFrom,
                    to: dateTo,
                }, context.$route.query)
            }
            context.$router.replace(routeTo)
        }
    },
    props: {
        from: {
            type: String
        },
        to: {
            type: String
        },
        showToday: {
            default: true,
            type: Boolean
        },
        showYesterday: {
            default: false,
            type: Boolean
        },
        showArrows: {
            default: true,
            type: Boolean
        },
        hideEndDate: {
            default: false,
            type: Boolean
        },
        useDefaultInterval: {
            default: false,
            type: Boolean
        },
        defaultInterval: {
            default: null
        },
        keepRouteAsIs: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            dateFrom: {
                time: ''
            },
            dateTo: {
                time: ''
            }
        }
    },
    watch: {
        'dateFrom.time': function(newValue, oldValue) {
            if (newValue == this.$route.query.from && !this.keepRouteAsIs) return
            if(this.keepRouteAsIs && (newValue == oldValue || oldValue == '')) return
            this.updateValues(newValue, this.dateTo.time)
        },
        'dateTo.time': function(newValue, oldValue) {
            if (newValue == this.$route.query.to && !this.keepRouteAsIs) return
            if(this.keepRouteAsIs && (newValue == oldValue || oldValue == '')) return
            this.updateValues(this.dateFrom.time, newValue)
        },
        defaultInterval: function(newInterval, oldInterval) {
            if(this.useDefaultInterval) {
                //this.dateFrom.time = this.from ? this.from : (this.$route.query.from ? this.$route.query.from : Datetime.today())
                this.dateTo.time = Datetime.getEndDate(moment(this.dateFrom.time).add(newInterval, 'd').format('YYYY-MM-DD'), 'YYYY-MM-DD', newInterval) 
            }
        }
    },
    computed: {
        datetime() {
            return Datetime
        },
        dateToLimit() {
            let from = moment(this.dateFrom.time)
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },
    },
    mounted() {
        this.setValues()
    },
    methods: {

        /**
         * Set current date values based on the URL parameters.
         */
        setValues() {
            if (this.from) return this.updateValues(this.from, this.to, true)
            this.dateFrom.time = this.from ? this.from : (this.$route.query.from ? this.$route.query.from : Datetime.today())
            this.dateTo.time = this.to ? this.to : (this.$route.query.to ? this.$route.query.to : Datetime.getEndDate(this.dateFrom.time))
        },

        /**
         * Change the current date range and emit an event with the new values.
         * @param integer delta A delta number in days, ex "-1" for "change 1 day backward".
         */
        changeDate(delta) {
            if (!Number.isInteger(delta)) return
            if (delta > 0) this.$emit('next')
            else this.$emit('prev')
            let from = this.useDefaultInterval ? Datetime.getRelativeDate(delta * (this.defaultInterval + 1), this.dateFrom.time) : Datetime.getRelativeDate(delta, this.dateFrom.time)
            let to = this.useDefaultInterval ? Datetime.getRelativeDate(delta * (this.defaultInterval + 1), this.dateTo.time) : Datetime.getRelativeDate(delta, this.dateTo.time)
            this.updateValues(from, to, true)
        },

        /**
         * Update the dates calulating from "today".
         */
        today() {
            this.updateValues(Datetime.today(), Datetime.todayPlusInterval(this.defaultInterval), true)
        },

        yesterday() {
            this.updateValues(Datetime.yesterday(), Datetime.yesterdayPlusInterval(this.defaultInterval), true)
        },

        /**
         * Update the current date range based on the passed data.
         * @param string from A date string in the following format: YYYY-MM-DD
         * @param string to A date string in the following format: YYYY-MM-DD
         * @param boolean update (optional) Telles the function to update the
         *                       values directly, with any check of the passed dates.
         */
        updateValues(from, to, update = false) {
            let replace = false
            if (!update) {
                update = true
                from = moment(from)
                let fromTimestamp = from.unix()
                to = moment(to)
                let toTimestamp = to.unix()

                if (isNaN(from) || isNaN(to)) return

                // "from" is greater than "to", update the "to" value to the
                // default DAYS_INTERVAL, calculated from "from".
                if (fromTimestamp > toTimestamp || this.useDefaultInterval) {
                    to = Datetime.getEndDate(from.format('YYYY-MM-DD'), 'YYYY-MM-DD', this.defaultInterval)
                    replace = true
                }

                // "to" is less than "from", which is not allowed. Change "to"
                // to the same value as "from".
                // Note that this logic is triggered when ItemsMixin is used,
                // since it ensures that "to" is never less than "from".
                else if (toTimestamp < fromTimestamp) {
                    to = from.clone()
                    replace = true
                }

            }

            if (update) {
                this.dateFrom.time = (typeof from == 'string') ? from : from.format('YYYY-MM-DD')

                // Update the route values.
                this.dateTo.time = (typeof to == 'string') ? to : to.format('YYYY-MM-DD')
                const routeTo = {
                    query: Object.assign({}, this.$route.query)
                }
                if (this.$route.name != 'calendar' && !this.keepRouteAsIs) {
                    routeTo.query.from = this.dateFrom.time
                    // if (!this.hideEndDate) routeTo.query.to = this.dateTo.time
                    routeTo.query.to = this.dateTo.time

                    if (replace) this.$router.replace(routeTo)
                    else this.$router.push(routeTo)
                }

                // Emit the changed values.
                this.$emit('dateChanged', {
                    from: this.dateFrom.time,
                    to: this.dateTo.time,
                })

            }
        },

    }
}
</script>

<template>
<div class="book-date-navigation">
    <div class="btn-group" role="group" v-if="showYesterday">
        <button @click="yesterday()" class="btn btn-primary">Yesterday</button>
    </div>
    <div class="btn-group" role="group" v-if="showToday">
        <button @click="today()" class="btn btn-primary">Today</button>
    </div>
    <div class="btn-group" role="group">
        <button class="btn btn-default" @click="changeDate(-1)" v-if="showArrows"><font-awesome-icon icon="chevron-left"/></button>
        <datepicker :date="dateFrom" :option="$store.state.config.DATEPICKER_OPTION" class="date-navigation"></datepicker>
        <datepicker :date="dateTo" :option="$store.state.config.DATEPICKER_OPTION" :limit="dateToLimit" class="date-navigation" v-if="!hideEndDate"></datepicker>
        <button class="btn btn-default" @click="changeDate(1)" v-if="showArrows"><font-awesome-icon icon="chevron-right"/></button>
    </div>
</div>
</template>

<style lang="scss">
.book-date-navigation {
    display: inline-block;
}
</style>
