import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'bootstrap-sass'

import { app } from 'app'

window.Lazy = require('lazy.js')
window.moment = require('moment')
window.momentTimezone = require('moment-timezone')
// moment.locale('sv') // @TODO Set locale according to country?
// @TODO Set timezone for MomentJS

app.$mount('#app')
