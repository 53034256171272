<script>
import {app} from 'app'
import Datetime from 'components/common/helpers/Datetime'
import BookLoader from 'components/common/loader/BookLoader'
import BookDatetime from 'components/common/BookDatetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import ProductionPlan from 'components/production/ProductionPlan/ProductionPlan'
import EntityItem from 'components/entity/EntityItem'
import BookAutocomplete from 'components/common/BookAutocomplete'
import { getPermissionSubjectFromProductionType } from 'components/production/productionTypes'

// Vendors.
import maskedInput from 'vue-masked-input'

export default {
    components: {
        BookAutocomplete,
        BookDatetime,
        BookTable,
        BookModal,
        BookLoader,
        maskedInput
    },
    props: {
      production: Object,
      planTypeEntity: Object,
      items: Array,
      local: Boolean,
      disabled: Boolean,
      disabledMessage: String,
      print: {
        type: Boolean,
        default: false,
      }
    },
    data() {
        return {
            loaded: false,
            duplicatePlanItem: false,
            planItemForm: {
                show: false,
                loaded: false,
                formData: this.defaultData()
            },

        }
    },
    watch: {
        'planItemForm.formData.typeOption.value': function(newValue) {
            if (!this.planItemForm.show) return
            switch (newValue) {
                case 'list':
                    this.planItemForm.formData.typeCustom.value = ''
                    break
                case 'custom':
                    this.planItemForm.formData.type.value = ''
                    break
            }
        },
        'planItemForm.formData.offsetFormatted.value': function(newValue) {
            if (!this.planItemForm.show || (newValue == '__:__')) return
            this.$validator.validate('offset_formatted', newValue)
                .then(validated => {
                    if (validated || newValue == '') this.setOffset()
                    else this.planItemForm.formData.offset.value = 0
                })
        },
    },
    mounted() {
        Vue.nextTick(() => {
            this.loaded = true
        })
    },
    computed: {
        getPermissionSubject() {
          return getPermissionSubjectFromProductionType(this.production.get.productionType)
        },
        isFormValid() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].valid
            })
        },
        processing() {
            return this.$loader.has('bookProductionPlanSubmit') || this.$loader.has('bookProductionPlanDeleteItem')
        },
        getPlanItems() {
            if (!this.production || !this.production.get) return []
            return Lazy(this.items)
                .sortBy(item => {
                    if (this.production.get[this.getDateField('start')]) {
                        const columns = []
                        columns.push(this.getStartValue(item))
                        const planType = this.planTypeEntity.getItem(item.type)
                        if (planType) columns.push(planType.label.trim())
                        return columns
                    }
                    else {
                        return item.offset
                    }
                }, false)
                .toArray()
        },
        getStartWithOffset() {
            let dateField = this.production.get[this.getDateField(this.planItemForm.formData.relativeTo.value)]
            if (!dateField) return null
            const start = moment(dateField)
            const output = start.add(this.planItemForm.formData.offset.value, 's').format('YYYY-MM-DD HH:mm').split(' ')
            return output
        },
        entityItem() {
            return EntityItem
        },
        productionPlanData() {
            const context = this
            const output = {}
            output.options = {
                actions: [],
                actionSettings: {
                    edit: {
                        label: 'Open',
                        classes: ['btn', 'btn-primary', 'btn-xs']
                    }
                },
            }

            // Add actions buttons if the user has permissions to perform these.
            if (this.$ability.can('update_production_plan', this.getPermissionSubject)) {
                output.options.actions.push('edit')
            }
            if (this.$ability.can('delete_production_plan', this.getPermissionSubject)) {
                output.options.actions.push('delete')
            }

            // Build the columns.
            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                    visible: false,
                },
                {
                    value: 'start',
                    label: 'Start',
                    sortable: false,
                },
                {
                    value: 'type',
                    label: 'Type',
                    sortable: false,
                },
                {
                    value: 'description',
                    label: 'Description',
                    sortable: false,
                },
                {
                    value: 'isDefault',
                    label: 'Default',
                    sortable: false,
                    icon: true
                },
            ]

            output.rows = []

            context.getPlanItems.forEach(v => {
                let start = context.getStartValue(v)
                start = start.split(' ')
                const planType = context.planTypeEntity.getItem(v.type)
                const row = {
                    rowOptions: {
                        // highlighted: (currentItem && (currentItem.updated != v.updated)) ? true : false
                    },
                    id: {
                        value: v.id
                    },
                    start: {
                        rawValue: v.start,
                        value: start[1]
                    },
                    type: {
                        rawValue: v.type ? v.type : v.typeCustom,
                        value: context.getPlanTypeLabel(planType, v.relativeTo, v.typeCustom)
                    },
                    description: {
                        value: v.description
                    },
                    isDefault: {
                        rawValue: v.hasOwnProperty('isDefault') ? v.isDefault : false,
                        value: '',
                        icon: v.hasOwnProperty('isDefault') && v.isDefault ? 'check' : ''
                    }
                }
                output.rows.push(row)
            })

            if (output.rows.length > 0) return output
            return {
                rows: []
            }
        },
        getStartPlans() {
            return Lazy(this.items)
                    .filter(i => i.relativeTo == 'start')
        },
        getEndPlans() {
            return Lazy(this.items)
                    .filter(i => i.relativeTo == 'end')
        }

    },
    methods: {

        /**
         * Get a production class, based the current form data.
         * @return class|null
         */
        getClass(id = null) {
            id = id || this.planItemForm.formData.id.value
            const productionPlan = new ProductionPlan(id, this.production.get.id)
            const item = this.items.find(v => v.id == id)
            if (item) {
                productionPlan.add(item)
            }
            return productionPlan
        },

        getStartValue(item) {
            if (!this.production.get) return ''
            let start = this.production.get[this.getDateField(item.relativeTo)]
            if (start) {
                start = moment(start).add(item.offset, 's')
                return start.format('YYYY-MM-DD HH:mm')
            }
            const offset = Datetime.getOffsetFormatted(item.offset)
            return `offset ${offset.join('')}`
        },

        getPlanTypeLabel(planType, relativeTo, custom) {
            const output = []
            if (planType) output.push(planType.label)
            else if (custom) output.push(custom)
            return output.join(' ')
        },
        getDateField(relativeTo) {
            return 'production' + relativeTo.capitalize()
        },

        showPlanItemForm(open = true, id = null) {
            const context = this
            if (!context.production) return
            if (open) {
                context.resetForm()
                if (id && (context.items.length > 0)) {
                    const item = context.getPlanItems.find(v => v.id == id)
                    if (item) {


                        context.planItemForm.formData.id.value = item.id
                        context.planItemForm.formData.typeOption.value = item.type ? 'list' : 'custom'
                        context.planItemForm.formData.type.value = item.type
                        context.planItemForm.formData.typeCustom.value = item.typeCustom
                        context.planItemForm.formData.relativeTo.value = item.relativeTo
                        context.planItemForm.formData.offset.value = item.offset
                        context.planItemForm.formData.description.value = item.description
                        context.planItemForm.formData.isDefault.value = item.hasOwnProperty('isDefault') ? item.isDefault : false
                        let formattedOffset = Datetime.getOffsetFormatted(item.offset)
                        this.planItemForm.formData.offsetSign.value = formattedOffset[0]
                        this.planItemForm.formData.offsetFormatted.value = formattedOffset[1]
                        Vue.nextTick(() => {
                            context.planItemForm.show = true
                        })
                    }
                }
                else {
                    Vue.nextTick(() => {
                        context.planItemForm.show = true
                    })
                }
            }
            else {
                Vue.nextTick(() => {
                    context.resetForm()
                    context.planItemForm.show = false
                })
            }
        },

        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this plan item? Note that this action will remove every crew member that is "connected" to this plan item.',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                context.$loader.add('bookProductionPlanDeleteItem')
                const productionPlan = context.getClass(id)
                let successMsg = 'The plan item has been removed.'
                if (context.local) {
                    context.removePlan(id)
                    context.$alert.set(successMsg, 'success', 3)
                    context.$loader.remove('bookProductionPlanDeleteItem')
                }
                else {
                    productionPlan.delete()
                    .then(response => {
                        //context.removePlan(id)
                        context.updatePlans(response.data)
                        context.$alert.set(successMsg, 'success', 3)
                        context.$loader.remove('bookProductionPlanDeleteItem')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to remove the plan item.')
                        context.$loader.remove('bookProductionPlanDeleteItem')
                    })
                }
            })
        },

        /**
         * Submit the crew item form.
         */
        submit() {
            const context = this
            if (!context.isFormValid || context.submitting) return
            let id = (context.local && !context.planItemForm.formData.id.value) ? moment().unix() : null
            const productionPlan = context.getClass(id)
            if (!productionPlan) return
            context.$loader.add('bookProductionPlanSubmit')
            Lazy(context.planItemForm.formData)
            .filter(v => !v.local)
            .each((v, k) => {
                if (id && (k == 'id')) productionPlan.set('id', id)
                else productionPlan.set(k, v.value)
            })
            if (context.local) {
                context.updatePlan(productionPlan.get)
                context.showPlanItemForm(false)
                context.$loader.remove('bookProductionPlanSubmit')
            }
            else {
                if (!productionPlan.options.id) {
                    productionPlan.store()
                    .then(response => {
                        context.$alert.set('The production plan item has been added.', 'success', 5)
                        context.updatePlans(response.data)
                        context.showPlanItemForm(false)
                        if (context.production.get.crew.length > 0) app.$emit('itemUpdated', {type: 'production', id: response.data.production})
                        context.$loader.remove('bookProductionPlanSubmit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production plan item.')
                        context.$loader.remove('bookProductionPlanSubmit')
                    })
                }
                else {
                    productionPlan.save()
                    .then(response => {
                        context.$alert.set('The production plan item has been updated.', 'success', 5)
                        context.updatePlans(response.data)
                        context.showPlanItemForm(false)
                        if (context.production.get.crew.length > 0) app.$emit('itemUpdated', {type: 'production', id: response.data.production})
                        context.$loader.remove('bookProductionPlanSubmit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production plan item.')
                        context.$loader.remove('bookProductionPlanSubmit')
                    })
                }
            }
        },

        /**
         * Reset the plan item form.
         */
        resetForm() {
            this.planItemForm.formData = this.defaultData()
        },

        /**
         * Update the production plan in the current production.
         * @param object item
         */
        updatePlan(item) {
            const plan = this.items.slice(0)
            let i = plan.findIndex(v => v.id == item.id)
            if (i > -1) plan.splice(i, 1)
            plan.push(item)
            if(item.hasOwnProperty('isDefault') && item.isDefault) {
                Lazy(plan)
                .each(i => {
                    if(i.id != item.id && i.isDefault && i.relativeTo == item.relativeTo) {
                        i.isDefault = false
                    }
                })
            }
            else {
                this.setDefaultPlans(plan)
            }
            this.$emit('changedPlan', plan)
            if (!this.local) this.production.set('plan', plan)
        },

        setDefaultPlans(items) {
            let startPlans = _.filter(items, i => i.relativeTo == 'start')
            let endPlans = _.filter(items, i => i.relativeTo == 'end')

            if(startPlans && startPlans.length == 1 && ((startPlans[0].hasOwnProperty('isDefault') && startPlans[0].isDefault == false) || !startPlans[0].hasOwnProperty('isDefault'))) {
                let startPlan = items.find(v => v.id == startPlans[0].id)
                startPlan.isDefault = true
            }

            if(endPlans && endPlans.length == 1 && ((endPlans[0].hasOwnProperty('isDefault') && endPlans[0].isDefault == false) || !endPlans[0].hasOwnProperty('isDefault'))) {
                let endPlan = items.find(v => v.id == endPlans[0].id)
                endPlan.isDefault = true
            }

            let startPlanId = null;
            let endPlanId = null;

            if(startPlans && startPlans.length > 1) {
                let defaultStartPlans = _.filter(startPlans, v => v.hasOwnProperty('isDefault') && v.isDefault)
                if(defaultStartPlans && defaultStartPlans.length != 1) {
                    let zeroOffsetStartPlan = _.find(startPlans, i => i.offset === 0)
                    if(zeroOffsetStartPlan !== undefined) {
                        startPlanId = zeroOffsetStartPlan.id
                    }
                    else {
                        let sortedStartPlans = _.orderBy(startPlans, ['offset'], ['asc'])
                        startPlanId = sortedStartPlans[0].id
                    }
                }
            }

            if(endPlans && endPlans.length > 1) {
                let defaultEndPlans = _.filter(endPlans, v => v.hasOwnProperty('isDefault') && v.isDefault)
                if(defaultEndPlans && defaultEndPlans.length != 1) {
                    let zeroOffsetEndPlan = _.find(endPlans, i => i.offset === 0)
                    if(zeroOffsetEndPlan !== undefined) {
                        endPlanId = zeroOffsetEndPlan.id
                    }
                    else {
                        let sortedendPlans = _.orderBy(endPlans, ['offset'], ['desc'])
                        endPlanId = sortedendPlans[0].id
                    }
                }
            }

            if(startPlanId !== null || endPlanId !== null) {
                Lazy(items)
                .each(i => {
                    if(startPlanId !== null && i.relativeTo == 'start') {
                        if(i.id === startPlanId && ((i.hasOwnProperty('isDefault') && i.isDefault === false) || !i.hasOwnProperty('isDefault'))) i.isDefault = true
                        if(i.id !== startPlanId && ((i.hasOwnProperty('isDefault') && i.isDefault === true) || !i.hasOwnProperty('isDefault'))) i.isDefault = false
                    }

                    if(endPlanId !== null && i.relativeTo == 'end') {
                        if(i.id === endPlanId && ((i.hasOwnProperty('isDefault') && i.isDefault === false) || !i.hasOwnProperty('isDefault'))) i.isDefault = true
                        if(i.id !== endPlanId && ((i.hasOwnProperty('isDefault') && i.isDefault === true) || !i.hasOwnProperty('isDefault'))) i.isDefault = false
                    }
                })
            }
        },

        /**
         * Remove a plan item from the plan list.
         * @param integer id The plan item id
         */
        removePlan(id) {
            const plan = this.items.slice(0)
            let i = plan.findIndex(v => v.id == id)
            if (i > -1) {
                plan.splice(i, 1)
                this.setDefaultPlans(plan)
                this.$emit('changedPlan', plan)
                if (!this.local) this.production.set('plan', plan)
            }
        },

        updatePlans(items) {
           this.$emit('changedPlan', items)
            if (!this.local) this.production.set('plan', items)
        },

        /**
         * Set the start value considering the set offset number.
         */
        setStartValue() {
            // const item = {
            //     relativeTo: this.planItemForm.formData.relativeTo.value,
            //     // startOption: this.planItemForm.formData.startOption.value,
            //     start: this.planItemForm.formData.start.value,
            //     offset: this.planItemForm.formData.offset.value,
            //     offsetUnit: this.planItemForm.formData.offsetUnit.value
            // }
            // Vue.nextTick(() => {
            //     this.planItemForm.formData.startWithOffset.value = this.getStartValue(item)
            // })
        },

        setOffset() {
            if (!this.planItemForm.formData.offsetFormatted.value) return
            const offset = this.planItemForm.formData.offsetFormatted.value.split(':')
            offset[0] = parseInt(offset[0]) * 3600 // Convert hours to seconds (60 * 60)
            offset[1] = parseInt(offset[1]) * 60 // Convert minuts to seconds
            this.planItemForm.formData.offset.value = (offset[0] + offset[1]) * parseInt(this.planItemForm.formData.offsetSign.value + 1)
        },

        /**
         * Get a formatted offset, based on passed timestamp.
         * @param integer offset A timestamp, negative or positive.
         * @return array ["sign", "offset converted t0 HH:mm"]
         */
        // getOffsetFormatted(offset) {
        //     const output = []
        //     output.push(offset < 0 ? '-' : '+')
        //     let offsetAbs = Math.abs(offset)
        //     output.push(moment.unix(offsetAbs).utc().format('YYYY-MM-DD HH:mm').split(' ')[1])
        //     return output
        // },

        setSign(sign) {
            this.planItemForm.formData.offsetSign.value = sign
            this.setOffset()
        },

        /**
         * Add a given number of hours to the offset.
         */
        setOffsetFormatted(hours = 0) {
            const output = []
            const offset = this.planItemForm.formData.offsetFormatted.value.split(':')
            let newHourValue = parseInt(offset[0])
            let newMinuteValue = parseInt(offset[1])
            if (isNaN(newHourValue)) {
                output.push('00')
            }
            else {
                let newValue = newHourValue + hours
                if (newValue < 0) {
                    output.push('00')
                }
                else {
                    output.push((newValue + '').padStart(2, 0))
                }
            }
            if (isNaN(newMinuteValue)) {
                output.push('00')
            }
            else {
                output.push(offset[1].padStart(2, 0))
            }
            this.planItemForm.formData.offsetFormatted.value = output.join(':')
        },

        offsetArrowUp(e) {
            this.setOffsetFormatted(1)
        },

        offsetArrowDown(e) {
            this.setOffsetFormatted(-1)
        },

        /**
         * Default form data.
         * @return object
         */
        defaultData() {
            return {
                id: {
                    value: 0
                },
                typeOption: {
                    local: true,
                    value: 'list'
                },
                type: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                typeCustom: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                offset: {
                    value: 0,
                },
                offsetSign: {
                    local: true,
                    value: '+'
                },
                offsetFormatted: {
                    local: true,
                    value: '',
                    validator: {
                        date_format: 'HH:mm',
                    },
                },
                relativeTo: {
                    value: 'start',
                },
                description: {
                    value: ''
                },
                isDefault: {
                    value: false
                }
            }
        }

    }
}
</script>

<template>
<div class="book-production-plan">
    <button
        v-if="loaded && $ability.can('create_production_plan', getPermissionSubject) && !print"
        class="btn btn-success btn-xs book-button-open-plan-item" title="Add item"
        @click="showPlanItemForm(true)"
        :disabled="disabled">
        <font-awesome-icon icon="plus"/>&nbsp;Add
    </button>
    <book-loader v-if="!loaded"></book-loader>
    <book-table
        v-else
        :print="print"
        :items="productionPlanData"
        :component="'productionPlan'"
        itemsType='production'
        :notFoundMessage="!disabled || !disabledMessage ? 'No plan item has been added' : ''"
        @edit="item => showPlanItemForm(true, item.id.value)"
        @delete="item => deleteItem(item.id.value)">
    </book-table>
    <div class="book-box-overlay" v-if="disabled && loaded">
        <span class="label label-danger" v-html="disabledMessage"></span>
    </div>
    <div class="book-box-overlay" v-if="processing">
        <book-loader :small="true"></book-loader>
    </div>

    <book-modal maxWidth="400px" @close="showPlanItemForm(false)" v-if="planItemForm.show">
        <h4 slot="header" class="modal-title">
            <span v-if="!planItemForm.formData.id.value">Add</span><span v-else>Edit</span> plan item
        </h4>
        <div slot="body">
            <div class="form-horizontal" @keyup.enter="submit()">
                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('plantype')}">
                    <label for="name" class="control-label control-label-100">
                        Type
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                    </label>
                    <div class="form-control-100">
                        <div class="radio">
                            <label class="radio-inline">
                                <input tabindex="1" @click="" type="radio" name="type_option" v-model="planItemForm.formData.typeOption.value" value="list">
                                List
                            </label>
                            <label class="radio-inline">
                                <input tabindex="1" @click="" type="radio" name="type_option" v-model="planItemForm.formData.typeOption.value" value="custom">
                                Custom
                            </label>
                        </div>
                        <select tabindex="1" class="form-control" name="plantype" v-model="planItemForm.formData.type.value" v-if="planTypeEntity && planItemForm.formData.typeOption.value == 'list'" v-validate="planItemForm.formData.type.validator">
                            <option value="">None</option>
                            <option v-for="type in planTypeEntity.get.items" v-if="entityItem.isVisible(type, planItemForm.formData.type.value == type.id)" :value="type.id">{{type.label}}</option>
                        </select>
                        <input tabindex="1" v-model:trim="planItemForm.formData.typeCustom.value" v-validate="planItemForm.formData.typeCustom.validator" v-if="planItemForm.formData.typeOption.value == 'custom'" name="type_custom" type="text" class="form-control" />
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label for="name" class="control-label control-label-100">
                        Relative to
                    </label>
                    <div class="form-control-100">
                        <select v-focus tabindex="1" class="form-control" name="relative_to" v-model="planItemForm.formData.relativeTo.value">
                            <option value="start">Production start</option>
                            <option value="end">Production end</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('offset_formatted')}">
                    <label class="control-label control-label-100">
                        Offset
                    </label>
                    <div class="form-control-100">
                        <div class="book-production-plan-offset-wrapper" @keyup.arrow-up="offsetArrowUp" @keyup.arrow-down="offsetArrowDown">
                            <div class="book-production-plan-offset">
                                <div class="btn-group">
                                    <button class="btn btn-sm" :class="planItemForm.formData.offsetSign.value == '+' ? 'btn-warning' : 'btn-default'" @click="setSign('+')"><font-awesome-icon icon="plus"/></button>
                                    <button class="btn btn-sm" :class="planItemForm.formData.offsetSign.value == '-' ? 'btn-warning' : 'btn-default'" @click="setSign('-')"><font-awesome-icon icon="minus"/></button>
                                </div>
                                <masked-input class="form-control" mask="11:11" placeholder="__:__" v-validate="planItemForm.formData.offsetFormatted.validator" v-model:trim="planItemForm.formData.offsetFormatted.value" name="offset_formatted" type="text" tabindex="1" />
                            </div>
                            <div class="form-control-static" v-if="getStartWithOffset">
                                <span class="book-production-plan-offset-date">{{getStartWithOffset[0]}}</span>
                                <span class="book-production-plan-offset-time">{{getStartWithOffset[1]}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label for="name" class="control-label control-label-100">
                        Description
                    </label>
                    <div class="form-control-100">
                        <input tabindex="1" v-model:trim="planItemForm.formData.description.value" v-validate name="description" type="text" class="form-control" />
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="is_default" v-validate v-model="planItemForm.formData.isDefault.value">
                            Default
                        </label>
                    </div>
                </div>
                <div class="book-help-message bg-danger text-danger" v-if="duplicatePlanItem">
                    There is another plan item with the same date and time
                </div>
            </div>
        </div>
        <div slot="footer">
            <button tabindex="1" class="btn btn-success" @click="submit()" :disabled="!isFormValid || processing">Save</button>
        </div>
    </book-modal>

</div>
</template>

<style lang="scss">
.book-production-plan {
    min-height: 40px;
    position: relative;
    .book-button-open-plan-item {
        position: absolute;
        right: 3px;
        top: -25px;
    }
    .book-production-plan-offset-wrapper {
        display: flex;
        flex-flow: row wrap;
        .form-control-static {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-end;
            line-height: 1.2;
            padding: 6px 0 7px !important;
            text-align: right;
            > * {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .book-production-plan-offset-date {
            font-size: 14px;
        }
        .book-production-plan-offset-time {
            font-size: 18px;
            font-weight: 600;
            margin-left: 4px;
        }
        .book-production-plan-offset {
            display: flex;
            .btn-group {
                .btn {
                    z-index: 1;
                    &:last-child {
                        border-radius: 0;
                    }
                    &:focus {
                        z-index: 2;
                    }
                }
            }
            .form-control {
                border-radius: 0 3px 3px 0;
                margin-left: -1px;
                width: 52px !important;
                z-index: 1;
                &:focus {
                    z-index: 2;
                }
            }
        }
    }
}
</style>
