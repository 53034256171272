// -----------------------------------------------------------------------------
// Dependencies

import {app} from 'app'
import router from 'router'
import store from 'store'
import axios from 'axios'
import Auth from 'components/auth/Auth'
import errorHandler from 'components/system/ErrorHandler'
import System from 'components/system/System'
import Alert from 'components/common/alert/Alert'

// -----------------------------------------------------------------------------
// Interceptor.

axios.interceptors.request.use(config => {
      if (config.url.indexOf('http') == -1) config.url = `${store.state.config.API_URL}/${config.url}`
      app.$loader.add(`${config.method}_${config.url}`)
      return config;
  }, error => {
      app.$loader.remove(`${config.method}_${config.url}`)
      return Promise.reject(error);
  })

axios.interceptors.response.use(response => {
      app.$loader.remove(`${response.config.method}_${response.config.url}`)
      return response;
  }, error => {
      if (error.response) {
          switch (error.response.status) {
              case 400:
              case 401:
                  if (Auth.getToken()) {

                      Alert.set('Your session has expired. Trying to refresh the session...', 'danger', 10)

                      // @TODO Should we place this token refresh somewhere else?
                      Auth.refreshToken()
                          .then(response => {
                              document.location = '/'
                          })
                          .catch(error => {
                              Auth.reset()
                              router.push('/login')
                          })

                  }
                  else {
                      Auth.reset()
                      console.log(error.response.statusText)
                      router.push('/login')
                  }
                  break
          }
      }
      console.log(error, 'axios error interceptor')
      app.$loader.remove(`${error.config.method}_${error.config.url}`)
      return Promise.reject(error)
  })
