<script>
    export default {
    }
</script>
<template>
    <transition-group name="slide-up-fade" tag="div" mode="out-in" id="alert">
        <div
            :key="id"
            v-for="(message, id) in $store.getters['alert/getMessages']"
            v-html="message.text"
            class="alert" v-bind:class="['alert-' + message.type]"
            role="alert">
        </div>
    </transition-group>
</template>
<style scoped>
#main-content:not(.book-authenticated) {
    #alert {
        top: 50px;
    }
}
#alert {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100000;
}
#alert .alert {
    box-shadow: 0 10px 19px -10px rgba(0,0,0,.5);
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    position: relative;
}
</style>
