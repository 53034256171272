<script>
import {app} from 'app'
import BookModal from 'components/common/BookModal'
import BookLoader from 'components/common/loader/BookLoader'
import Filter from 'components/common/filter/Filter'
import BookSelectDropdown from 'components/common/BookSelectDropdown'
import BookSelectboxMain from 'components/common/BookSelectboxMain'
import BookRadioboxMain from 'components/common/BookRadioboxMain'
// required API callers
import Updates from 'components/mcr/Updates'
import Item from 'components/common/item/Item'
import Mcrdata from 'components/mcr/Mcrdata'
import Ingestion from 'components/ingestion/Ingestion'
import McrStatus from 'components/mcr/McrStatus/McrStatus'
import Feedback from 'components/feedback/Feedback'
import EquipmentWithUsage from 'components/equipment/EquipmentWithUsage'
import EquipmentTypeWithUsage from 'components/equipment/EquipmentTypeWithUsage'
import ForkPlaceholder from 'components/forkplaceholder/ForkPlaceholder'

export default {
    components: {
        BookModal,
        BookLoader,
        BookSelectDropdown,
        BookSelectboxMain,
        BookRadioboxMain
    },
    props: {
        loading: {
            default: false,
            type: Boolean
        },
        options: {
            default: () => {
                return {
                    save: false
                }
            },
            type: Object
        },
        filters: {
            default: () => {
                return {}
            },
            type: Object
        },
        info: {
            default: () => {
                return {leftVisible: 0, leftTotal: 0, rightVisible: 0, rightTotal: 0}
            },
            type: Object
        },
        isSplitPage:{
            default: false,
            type: Boolean
        },
        isProblemPage:{
            default: false,
            type: Boolean
        },
        waiting: {
          default: () => {
            return {}
          },
          type: Object
        },
        darkMode: {
          default: false
        }
    },
    data() {
        return {
            loaded: false,
            processing: false,
            filter: null,
            filterItems: this.filters,
            leftSideFilters: {},
            rightSideFilters: {},
            freeText: {},      
            advancedFilters: {},
            textFiltersDelay: {},
            isShowMegaView: false,
            modalForm: {
                save: {
                    show: false,
                    label: {
                        value: '',
                        validator: {
                            rules: {
                                required: true,
                                min: 2,
                                max: 50
                            }
                        }
                    }
                },
                share: {
                    show: false,
                    label: {
                        value: '',
                        validator: {
                            rules: {
                                required: true,
                                min: 2,
                                max: 50
                            }
                        }
                    }
                },
                advanced: {
                    show: false,
                }
            },
            timer: null,
        }
    },
    computed: {
        myFiltersSelected: {
            set(value) {
                this.$store.commit('filter/setMyFiltersSelected', value)
            },
            get() {
                return this.$store.state.filter.myFiltersSelected
            }
        },
        myFilters() {
            return this.$store.state.data.filter ? this.$store.state.data.filter : null
        },
        hasFilter() {
            return Lazy(this.filterItems).filter(v => {
                if (v.type == 'text') return v.value != ''
                return v.items.filter(z => z.selected).length > 0
            }).toArray().length > 0
        },
        isFormClean() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        },
        preserveFilters() {
            return !!this.$store.state.filter.preserve
        },

        // leftSideFilters() {
        //     let val = Lazy(this.filterItems).filter(v => {
        //         return (!this.isSplitPage && v.singlePage) || (this.isSplitPage && v.multiplePage && v.side == 'left')
        //     }).toArray()
        //     return val
        // },
        // rightSideFilters() {
        //     let val = Lazy(this.filterItems).filter(v => {
        //         return this.isSplitPage && v.multiplePage && v.side == 'right'
        //     }).toArray()
        //     return val
        // },

        /**
         * Checks if the current filters set matches any of the custom filters.
         * @return boolean | integer
         */
        matchesCustomFilter() {
            const context = this
            if (!context.myFilters) return false
            let output = false
            const currentFilters = context.$store.state.filter.preserve
            context.myFilters.forEach(myFilter => {
              if (output) {
                return
              }
              if (!currentFilters) {
                return
              }
              if (Object.keys(currentFilters).length != Object.keys(myFilter.value).length) {
                    return
                }

                // Loop through all items in the current "myFilter" and check
                // if they all match all the items in the current filter selection.
                let isEqual = Lazy(myFilter.value).every((v, k) => {
                    if (currentFilters[k]) {

                        // Check if each single item in current selection is equal
                        // to the items in the current "myFilter".
                        if (Array.isArray(currentFilters[k])) {

                            // The length of the current filter selection is diferent
                            // from the current "myFilter", then they aren't equal.
                            if (currentFilters[k].length != v.length) {
                                return false
                            }

                            return currentFilters[k].every(z => {
                                return v.indexOf(z) > -1
                            })
                        }

                        // Check the advanced filters, if the matches the
                        // current "myFilter".
                        else {

                            // The length of the current filter selection is diferent
                            // from the current "myFilter", then they aren't equal.
                            if (Object.keys(currentFilters[k]).length != Object.keys(v).length) {
                                return false
                            }

                            let hasSameKeys = Object.keys(currentFilters[k]).every(a => v[a])
                            if (!hasSameKeys) {
                                return false
                            }
                            else {
                                return Lazy(currentFilters[k])
                                    .every((advancedFilter, advancedType) => {
                                        let hasSameKeys = Object.keys(currentFilters[k][advancedType]).every(a => v[advancedType][a])
                                        if (!hasSameKeys) {
                                            return false
                                        }
                                        return Lazy(advancedFilter)
                                            .every((advancedFilterItem, filterName) => {
                                                let hasSameKeys = Object.keys(currentFilters[k][advancedType][filterName]).every(a => v[advancedType][filterName][a])
                                                if (!hasSameKeys) {
                                                    return false
                                                }
                                                return Lazy(advancedFilterItem)
                                                    .every((elements, advancedFilterName) => {
                                                        let hasSameKeys = Object.keys(currentFilters[k][advancedType][filterName][advancedFilterName]).every(a => v[advancedType][filterName][advancedFilterName][a])
                                                        if (!hasSameKeys) {
                                                            return false
                                                        }
                                                        return Lazy(elements)
                                                            .every((values, element) => {
                                                                let hasSameKeys = Object.keys(currentFilters[k][advancedType][filterName][advancedFilterName][element]).every(a => v[advancedType][filterName][advancedFilterName][element][a])
                                                                if (!hasSameKeys) {
                                                                    return false
                                                                }
                                                                else if (v[advancedType][filterName][advancedFilterName][element].length != values.length) {
                                                                    return false
                                                                }
                                                                return Lazy(values)
                                                                    .every(a => {
                                                                        return v[advancedType][filterName][advancedFilterName][element].indexOf(a) > -1
                                                                    })
                                                            })
                                                    })
                                            })
                                    })
                            }
                        }

                    }
                    return false
                })

                if (isEqual) {
                    output = myFilter.id
                }
            })
            return output
        },

        /**
         * Get the current filters set.
         */
        getCurrentFiltersSet() {
            const output = {}
            Lazy(this.filterItems)
                .each((v, k) => {
                    switch (v.type) {
                        case 'text':
                            if (v.value != '') {
                                Vue.set(output, k, v.value)
                            }
                            break
                        default:
                            Lazy(v.items)
                                .filter(z => z.selected)
                                .each(z => {
                                    if (!output.hasOwnProperty(k)) {
                                        Vue.set(output, k, [])
                                    }
                                    output[k].push(z.id)
                                })
                            break
                    }
                })
            if (this.hasAdvancedFilters) {
                Vue.set(output, 'advanced', this.getAdvancedFilters)
            }

            return output
        },

        hasAdvancedFilters() {
            return Lazy(this.filterItems).some(v => v.advanced && Object.keys(v.advanced).length > 0)
        },

        /**
         * Return the current selected advanced filters in order to return to
         * parent components.
         */
        getAdvancedFilters() {
            const output = {}
            Lazy(this.advancedFilters)
                .each((advancedFilter, advancedType) => {
                    if (!output.hasOwnProperty('advancedType')) {
                        output[advancedType] = {}
                    }
                    Lazy(advancedFilter)
                        .each((advancedFilterItem, filterName) => {
                            if (!output[advancedType].hasOwnProperty(filterName)) {
                                output[advancedType][filterName] = {}
                            }
                            Lazy(advancedFilterItem)
                                .each((elements, advancedFilterName) => {
                                    if (!output[advancedType][filterName].hasOwnProperty(advancedFilterName)) {
                                        output[advancedType][filterName][advancedFilterName] = {}
                                    }
                                    Lazy(elements.items)
                                        .each(field => {
                                            output[advancedType][filterName][advancedFilterName][field.id] = []
                                            Lazy(field.items)
                                                .filter(item => item.selected)
                                                .each(item => {
                                                    output[advancedType][filterName][advancedFilterName][field.id].push(item.id)
                                                })
                                        })
                                })
                        })
                })
            return output
        },
    },
    watch: {
        filters: {
            handler: function(newValue) {
                console.log("I am BookFilter rendering..")
                if (this.loading || this.processing) return
                this.processData()
                this.createAdvancedFilters()
                if (!this.preserveFilters) {
                    Vue.nextTick(() => {
                        this.filterBy()
                    })
                }
            },
            deep: true
        },
        isSplitPage: {
            handler: function(newValue, oldValue) {
                console.log("I am BookFilter rendering..")
                if (this.loading || this.processing) return
                this.processData()
                this.createAdvancedFilters()
                if (!this.preserveFilters) {
                    Vue.nextTick(() => {
                        this.filterBy()
                    })
                }
            },
            deep: true
        },
        isProblemPage: {
            handler: function(newValue, oldValue) {
                console.log("I am BookFilter rendering..")
                if (this.loading || this.processing) return
                this.processData()
                this.createAdvancedFilters()
                if (!this.preserveFilters) {
                    Vue.nextTick(() => {
                        this.filterBy()
                    })
                }
            },
            deep: true
        },
        loading: function(newValue) {
            if (!newValue) {
                this.setLoaded()
                if (this.preserveFilters) {
                    Vue.nextTick(() => {
                        this.setPreservedFilters()
                    })
                }
            }
        },
        waiting: {
          handler: function(newValue, oldValue) {
            if (newValue.updates === true || oldValue.updates === true) {
              jQuery('div.book-crew-filter.book-bottom-sticky').css('flex-direction', 'row-reverse')
            } else {
              jQuery('div.book-crew-filter.book-bottom-sticky').css('flex-direction', '')
            }
          },
          deep: true
        },
    },
    created() {
      if (this.$route.name === 'mcr') {
        app.$on('saveMyChanges', (data) => {
          this.getTraffic()
        })
      }
    },
    beforeDestroy() {
      app.$off('saveMyChanges')
    },
    mounted() {
        const context = this
        if (this.$route.name === 'mcr' && app._events.saveMyChanges === null) {
          app.$on('saveMyChanges', (data) => {
            context.getTraffic()
          })
        }

        if (this.waiting.updates) {
          jQuery('div.book-crew-filter.book-bottom-sticky').css('flex-direction', 'row-reverse')
        }
        // Load the current user's custom filters if any available.
        this.filter = new Filter()
        this.setLoaded()
        this.filter.all()
            .catch(error => {
                if (error.response && error.response.status != 404) {
                    this.$error.set(error, 'It was not possible to load your custom filters.')
                }
            })
    },
    methods: {
        getEquipmentApiUrl(usageType, usageId) {
          return 'api/' + usageType + '/' + usageId + '/' + 'equipment'
        },
        getPlaceholderApiUrl(usageType, usageId) {
          return 'api/' + usageType + '/' + usageId + '/' + 'placeholder'
        },
        getEquipmentApiUrlType() {
          return this.getEquipmentApiUrl + '-type'
        },
        /**
         * Get a new class instance, based the passed Equipment "type".
         */
        getEquipmentClass(type, usageType, usageId, id = null) {
          switch (type) {
            case 'equipmentPiece':
              return new EquipmentWithUsage(this.getEquipmentApiUrl(usageType, usageId), id)
            case 'equipmentType':
              return new EquipmentTypeWithUsage(this.getEquipmentApiUrlType, id)
          }
        },
        getForkPlaceholderClass(usageType, usageId, id = null) {
            return new ForkPlaceholder(this.getPlaceholderApiUrl(usageType, usageId), id)
        },
        isCloneUpdate(id) {
          return id.toString().length >= 12
        },
        /**
         * Set the component to loaded/unloaded
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
        },

        /**
         * Process the passed filter data.
         */
        processData() {
            if (this.processing) return
            this.processing = true
            this.leftSideFilters = new Object()
            this.rightSideFilters = new Object()
            Lazy(this.filterItems)
                .each((v, k) => {
                    if (!v.hasOwnProperty('label')) {
                        Vue.set(v, 'label', '')
                    }
                    if (!v.hasOwnProperty('type')) {
                        Vue.set(v, 'type', 'select')
                    }
                    if (!v.hasOwnProperty('display')){
                        Vue.set(v, 'display', true)
                    }
                    switch (v.type) {
                        case 'text':
                            if (!v.hasOwnProperty('value')) {
                                Vue.set(v, 'value', '')
                            }
                            else {
                                this.freeText[k] = v.value
                            }
                            break
                        case 'radio':
                            if (!v.hasOwnProperty('value')) {
                                Vue.set(v, 'value', '')
                            }
                            Lazy(v.items)
                                .each(z => {
                                    if (z.id == v.value) {
                                        Vue.set(z, 'selected', true)
                                    }
                                    else {
                                        Vue.set(z, 'selected', false)
                                    }
                                })
                        default:
                            Lazy(v.items)
                                .each(z => {
                                    if (!z.hasOwnProperty('selected')) {
                                        Vue.set(z, 'selected', false)
                                    }
                                })
                    }

                    if(this.isSplitPage) {
                        if(v.multiplePage) {
                            if(v.side == 'left') {
                                this.leftSideFilters[k] = v
                            }
                            else {
                                this.rightSideFilters[k] = v
                            }
                        }
                    }
                    else if(this.isProblemPage) {
                        if(v.problemPage) {
                            if(v.side == 'left') {
                                this.leftSideFilters[k] = v
                            }
                            else {
                                this.rightSideFilters[k] = v
                            }
                        }
                    }
                    else {
                        if(v.singlePage) {
                            this.leftSideFilters[k] = v
                        }
                    }
                })
            Vue.nextTick(() => {
                this.processing = false
            })
        },
        
        /**
         * Create the advanced filters based on the passed settings.
         */
        createAdvancedFilters() {
            const output = {}
            let isAutoSelected = false
            if (this.filterItems && this.filterItems.country && this.filterItems.country.items && 
                !this.filterItems.country.items.find(t => t.selected)){
                Lazy(this.filterItems.country.items)
                    .forEach(element => {
                        element.selected = true;
                    });
                isAutoSelected = true
            }
            Lazy(this.filterItems)
                .filter(v => v.hasOwnProperty('advanced'))
                .each((filterItem, filterName) =>{

                    // Loop through all the available "advanced filter types".
                    Lazy(filterItem.advanced)
                        .each((advancedFilter, advancedType) => {

                            // Build the advanced filter type.
                            if (!output.hasOwnProperty(advancedType)) {
                                output[advancedType] = {}
                            }
                            output[advancedType][filterName] = {}

                            // Loop through available filters inside the current
                            // filter type.
                            Lazy(advancedFilter)
                                .filter(advancedFilterItem => this.filterItems.hasOwnProperty(advancedFilterItem))
                                .each(advancedFilterItem => {
                                    output[advancedType][filterName][advancedFilterItem] = {
                                        label: filterItem.label,
                                        label2: this.filterItems[advancedFilterItem].label,
                                        items: []
                                    }

                                    // Retrive all the "selected" items for the
                                    // current filter item in order to build
                                    // "filter groups" just for selected items.
                                    const selectedItems = this.filterItems[filterName].items.filter(v => v.selected)

                                    Lazy(selectedItems)
                                        .each(v => {

                                            // If the current selected already
                                            // exists inside the advanced filter,
                                            // retrive it to keep the selection.
                                            let item = this.advancedFilters[advancedType] && this.advancedFilters[advancedType][filterName]
                                             && this.advancedFilters[advancedType][filterName][advancedFilterItem].items.find(z => z.id == v.id)

                                            // If no item is available, create it.
                                            if (!item) {
                                                item = {
                                                    id: v.id,
                                                    label: v.label,
                                                    items: []
                                                }

                                                // Retrive the list options (items)
                                                // from the original filter to
                                                // connect to.
                                                Lazy(this.filterItems[advancedFilterItem].items)
                                                    .each(v => {
                                                        if (v.country && v.country == item.id || !v.country){
                                                            const newItem = Object.assign({}, v)
                                                            newItem.selected = false
                                                            item.items.push(newItem)
                                                        }
                                                    })

                                            }

                                            output[advancedType][filterName][advancedFilterItem].items.push(item)
                                        })
                                })

                        })

                })
            this.advancedFilters = output

            if (isAutoSelected){
                Lazy(this.filterItems.country.items)
                    .forEach(element => {
                        element.selected = false;
                    });
                isAutoSelected = false
            }
        },

        /**
         * Filter by the current filter set.
         */
        filterBy(preserve = true) {
            const context = this
            if (!context.loaded || context.loading) return

            const output = {}

            // Now, find all selected filter items and emit them to parent component.
            Lazy(context.filterItems)
                .each((v, k) => {
                    let selectedItems
                    switch (v.type) {
                        case 'select':
                            selectedItems = Lazy(v.items)
                                .filter(v => {
                                    return v.selected
                                })
                                .map(v => {
                                    return v.id
                                })
                                .toArray()
                            break
                        case 'text':
                            selectedItems = {
                                type: 'text',
                                value: v.value
                            }
                            break
                        case 'radio':
                            selectedItems = {
                                type: 'radio',
                                value: v.value
                            }
                            break
                    }
                    Vue.set(output, k, selectedItems)
                })

            if (this.hasAdvancedFilters) {
                output.advanced = this.getAdvancedFilters
            }

            this.$emit('filteredBy', output)

            if (preserve) {
                this.$store.commit('filter/setPreserve', { currentFilters: this.getCurrentFiltersSet, availableFilters: Object.keys(this.filters) })
            }

        },

        applyMyFilter() {
            if (this.myFiltersSelected === '') {
                this.clearMyFilter()
            } else {
                let i = this.myFilters.findIndex(v => v.id === this.myFiltersSelected)

                if (i > -1) {
                    let myFilter = this.myFilters[i].value

                    this.clearFilters(false)
                    this.$store.commit('filter/replacePreserve', myFilter)
                    this.setPreservedFilters()
                } else {
                    this.clearMyFilter()
                }
            }
        },

        clearMyFilter() {
            this.myFiltersSelected = ''
            this.$store.commit('filter/removePreserve')
            this.clearFilters()
        },

        /**
         * Update the passed filter based on the passed items.
         */
        updateFilterSet(filterName, items) {
            if (!this.filterItems.hasOwnProperty(filterName)) return
            this.filterItems[filterName].items = items.slice(0)
            Vue.nextTick(() => {
                this.filterBy()
            })
        },

        /**
         * Update the passed text filter based on the passed value.
         */
        updateFilterSetText(filterName) {
            this.filterItems[filterName].value = this.freeText[filterName]
            clearTimeout(this.textFiltersDelay[filterName])
            Vue.set(this.textFiltersDelay, filterName, setTimeout(() => {
                Vue.nextTick(() => {
                    this.filterBy()
                    Vue.delete(this.textFiltersDelay, filterName)
                })
            }, 300))
        },

        updateFilterSetRadio(filterName, item) {
            if (!this.filterItems.hasOwnProperty(filterName)) return
            this.filterItems[filterName].value = item
            Vue.nextTick(() => {
                this.filterBy()
            })
        },

        /**
         * Update the current advanced filter set based on the passed items.
         */
        updateAdvancedFilterSet({advancedType, filterName, id, advancedFilterName}, items) {
            Lazy(this.advancedFilters[advancedType][filterName][advancedFilterName].items)
                .filter(v => {
                    return v.id === id
                })
                .each(v => {
                    v.items = items.slice(0)
                })
            Vue.nextTick(() => {
                this.filterBy()
            })
        },
       /**
        * Clears the waiting update statements
        *
        * */
    //    clearWaiting(data) {
    //      const context = this
    //      if (this.waiting.items.length > 0) {
    //        this.waiting.items.slice(0)
    //        let i = this.waiting.items.findIndex(v => v.item.id === data.item.id)
    //        if (i > -1) this.waiting.items.splice(i, 1)
    //        this.waiting.total = this.waiting.items.length
    //      }
    //      clearTimeout(context.timer)
    //      context.timer = setTimeout((timer) => {
    //        if (this.waiting.items.length === 1) {
    //          this.waiting.updates = false
    //          this.waiting.items = []
    //          this.waiting.total = 0
    //          // save fast and store at once
    //          //this.revertBack()
    //          this.$loader.remove(this.processingId)
    //        }
    //      }, 1000)
    //    },
    //     /**
    //      * Saves all waiting houseNumbers (included ingestion changes)
    //      * */
    //     saveHouseNumbers(i) {
    //       const ingest = new Mcrdata(i.itemId, i.itemType)
    //       ingest.set('house_number_changing', 'yes')
    //       ingest.set('houseNumber', i.item.houseNumber)
    //       ingest.set('no_broadcast', true)
    //       //ingest.set('contributions', null)
    //       //ingest.set('associations', null)

    //       console.log(ingest, ingest.get.elementType, 'houseNumber save?', i.item.houseNumber)

    //       ingest.saveIn().then(response => {
    //         Item.parse(response.data, i.itemType, moment().unix())
    //         ingest.add(response.data)
    //         this.clearWaiting(i)
    //       })
    //           .catch(e => { console.log('ERROR!', e)})
    //     },
    //     /**
    //      * Aligns the ingestions with their new Ids.
    //      * */
    //     alignIngestions(data, old) {
    //       const ingest = new Mcrdata(old.itemId, old.itemType)
    //       console.log(ingest, 'align-ingestions')
    //       const items = ingest.get.ingestions.slice(0)
    //       let i = items.findIndex(v => v.id === old.item.id)
    //       if (i > -1) items.splice(i, 1)
    //       if (old.action.type !== 'delete') items.push(data)
    //       app.$emit('changedIngestions', {
    //         itemId: old.itemId,
    //         itemType: old.itemType,
    //         item: old.item, // { country, id, ingestionType }
    //         items: items,
    //         action: {
    //           module: old.action.module,
    //           type: old.action.type,
    //         },
    //       })
    //       console.log(data, 'align', old)
    //     },
    //    /**
    //     * All Ingestion Save operations. (part of saveUpdates)
    //     * */
    //     saveIngestions(i) {

    //      if (i.action.type === 'store' || i.action.type === 'save' && this.isCloneUpdate(i.item.id)) {
    //        const ingest = new Ingestion(0, i.itemId, i.itemType)
    //        ingest.set('country', i.item.country)
    //        ingest.set('ingestionType', i.item.ingestionType)

    //        console.log(ingest.get.ingestionType, 'ingest store?', i.item.ingestionType)

    //        ingest.set('no_broadcast', true)
    //        ingest.store().then(response => {
    //          this.alignIngestions(response.data, i)
    //          this.clearWaiting(i)
    //        })
    //            .catch(e => { console.log('ERROR!', e)})
    //      }
    //      if (i.action.type === 'save' && !this.isCloneUpdate(i.item.id)) {
    //        const ingest = new Ingestion(i.item.id, i.itemId, i.itemType)
    //        ingest.set('country', i.item.country)
    //        ingest.set('ingestionType', i.item.ingestionType)

    //        console.log(ingest.get.ingestionType, 'ingest save?', i.item.ingestionType)

    //        ingest.set('no_broadcast', true)
    //        ingest.save().then(response => {
    //          this.alignIngestions(response.data, i)
    //          this.clearWaiting(i)
    //        })
    //            .catch(e => { console.log('ERROR!', e)})
    //      }
    //      if (i.action.type === 'delete') {
    //        const ingest = new Ingestion(i.item.id, i.itemId, i.itemType)
    //        ingest.set('country', i.item.country)
    //        ingest.set('ingestionType', i.item.ingestionType)

    //        console.log(ingest.get.id, 'ingest delete?', i.item.id)

    //        ingest.set('no_broadcast', true)
    //        ingest.deleteSilent().then(response => {
    //          this.alignIngestions(response.data, i)
    //          this.clearWaiting(i)
    //        })
    //            .catch(e => { console.log('ERROR!', e)})
    //      }


    //     },

    //     /**
    //      * Aligns the MCRStatuses with their new Ids.
    //      * */
    //     alignMcrStatuses(data, old) {

    //       switch(old.itemType) {
    //         case 'production':
    //         case 'feed':
    //           const item1 = new Mcrdata(old.itemId, old.itemType)
    //           const items1 = item1.get.mcrStatus && true ? item1.get.mcrStatus.slice(0) : old.items.slice(0)

    //           let i1 = items1.findIndex(v => v.id === old.item.id)
    //           if (i1 > -1) items1.splice(i1, 1)
    //           if (old.action.type !== 'delete') items1.push(data)
    //           app.$emit('changedMcrStatus', {
    //             itemId: old.itemId,
    //             itemType: old.itemType,
    //             extraData: old.extraData,
    //             item: old.item, // { country, id, ingestionType }
    //             items: items1,
    //             action: {
    //               module: old.action.module,
    //               type: old.action.type,
    //             },
    //           })
    //           console.log(data, 'align MCR Statuses', old)
    //           break
    //         case 'equipmentUsage':
    //           let extraDataId = old.extraData.itemId ? old.extraData.itemId : (old.extraData.item_id ? old.extraData.item_id : null)
    //           let extraDataType = old.extraData.itemType ? old.extraData.itemType : (old.extraData.item_type ? old.extraData.item_type : null)
    //           const item = new Mcrdata(extraDataId, extraDataType)
    //           const equipment = item.get.equipment.find(v => v.equipmentUsage.id == old.itemId)
    //           const items = equipment.equipmentUsage.mcrStatus && true
    //               ? equipment.equipmentUsage.mcrStatus.slice(0) : old.items.slice(0)

    //           let i = items.findIndex(v => v.id === old.item.id)
    //           if (i > -1) items.splice(i, 1)
    //           if (old.action.type !== 'delete') items.push(data)
    //           app.$emit('changedMcrStatus', {
    //             itemId: old.itemId,
    //             itemType: old.itemType,
    //             extraData: old.extraData,
    //             item: old.item, // { country, id, ingestionType }
    //             items: items,
    //             action: {
    //               module: old.action.module,
    //               type: old.action.type,
    //             },
    //           })
    //           console.log(data, 'align for EquipmentUsage', old)
    //           break

    //       }

    //     },

    //     /**
    //      * Saves all waiting MCR Status operations
    //      * */
    //     saveMcrStatuses(i) {
    //       if (i.action.type === 'store' || i.action.type === 'save' && this.isCloneUpdate(i.item.id)) {
    //         const ingest = new McrStatus()
    //         ingest.add({
    //           status: i.item.status,
    //           country: i.item.country,
    //           itemId: i.itemId,
    //           itemType: i.itemType,
    //           extraData: i.extraData
    //         })

    //         console.log(ingest.get.status, 'MCR Status store?', i.item.status)

    //         ingest.set('no_broadcast', true)
    //         ingest.store().then(response => {
    //           this.alignMcrStatuses(response.data, i)
    //           this.clearWaiting(i)
    //         })
    //             .catch(e => { console.log('ERROR!', e)})
    //       }
    //       if (i.action.type === 'save' && !this.isCloneUpdate(i.item.id)) {
    //         const ingest = new McrStatus(i.item.id)
    //         ingest.add(Object.assign({}, i.item))
    //         ingest.set('status', i.item.status)
    //         ingest.set('extraData', i.extraData)

    //         console.log(ingest.get.status, 'MCR Status save?', i.item.status)

    //         ingest.set('no_broadcast', true)
    //         ingest.save().then(response => {
    //           this.alignMcrStatuses(response.data, i)
    //           this.clearWaiting(i)
    //         })
    //             .catch(e => { console.log('ERROR!', e)})

    //       }

    //       if (i.action.type === 'delete') {
    //         const ingest = new McrStatus(i.item.id)

    //         console.log(ingest.get.id, 'MCR Status delete?', i.item.id)
    //         ingest.set('no_broadcast', true)
    //         ingest.deleteSilent(true).then(response => {
    //           this.alignMcrStatuses(response.data, i)
    //           this.clearWaiting(i)
    //         })
    //             .catch(e => { console.log('ERROR!', e)})
    //       }


    //     },
    //     /**
    //      * Aligns all saved Equipments
    //      * */
    //     alignEquipments(data, old) {
    //       const item = new Mcrdata(old.itemId, old.itemType)
    //       const items = item.get.equipment && true ? item.get.equipment.slice(0) : old.items.slice(0)

    //       let i = items.findIndex(v => v.equipmentUsage.id === old.item.id)
    //       if (i > -1) items.splice(i, 1)
    //       if (old.action.type !== 'delete') items.push(data)
    //       app.$emit('changeOfEquipments', {
    //         itemId: old.itemId,
    //         itemType: old.itemType,
    //         extraData: old.extraData,
    //         item: old.item, // { country, id, ingestionType }
    //         items: items,
    //         action: {
    //           module: old.action.module,
    //           type: old.action.type,
    //         },
    //       })
    //       console.log(data, 'align Equipments', old)
    //     },
    //     /**
    //      * Saves all waiting Equipments
    //      * */
    //     saveEquipments(i) {
    //       // item.id => 17638 (equipment usage id)
    //       // equipmentId => 238
    //       if (i.action.type === 'store' || i.action.type === 'save' && this.isCloneUpdate(i.item.id)) {
    //         const ingest = this.getEquipmentClass(i.equipment.elementType, i.itemType, i.itemId, 0)
    //         if (ingest.get && !ingest.get.equipmentId) {
    //           i.item.id = 0
    //           ingest.add(i.item)
    //           //console.log(ingest, 'STORE FAIL NEEDS TO BE CHECKED', i)
    //         }
    //         console.log(ingest, 'Equipment store', i.item.id, i)
    //         ingest.set('no_broadcast', true)
    //         ingest.store()
    //             .then(response => {
    //               this.alignEquipments(response.data, i)
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})
    //       }
    //       if (i.action.type === 'save') {
    //         const ingest = this.getEquipmentClass(i.equipment.elementType, i.itemType, i.itemId, i.item.id)
    //         if (ingest.get && !ingest.get.equipmentId) { ingest.add(i.item) }
    //         if (ingest.get.equipmentId !== i.item.equipmentId ) { ingest.set('equipmentId', i.item.equipmentId) }
    //         console.log(ingest.get.id, 'Equipment save', i.item.id, i)
    //         ingest.set('no_broadcast', true)
    //         ingest.save()
    //             .then(response => {
    //               this.alignEquipments(response.data, i)
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})

    //       }
    //       if (i.action.type === 'delete') {
    //         const ingest = this.getEquipmentClass(i.equipment.elementType, i.itemType, i.itemId, i.item.id)
    //         if (ingest.get && !ingest.get.equipmentId) { ingest.add(i.item) }
    //         if (ingest.get.equipmentId !== i.item.equipmentId ) { ingest.set('equipmentId', i.item.equipmentId) }

    //         console.log(ingest.get.id, 'Equipment delete', i.item.id)
    //         ingest.set('no_broadcast', true)
    //         ingest.deleteSilent()
    //             .then(response => {
    //               this.alignEquipments(response.data, i)
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})

    //       }
    //     },
    //     saveFeedback(i) {
    //       if (i.action.type === 'store') {
    //         const ingest = new Feedback(0)
    //         _(i.item).each((e,k) => {
    //           if (k === 'id') ingest.set(k, 0)
    //           else ingest.set(k, e)
    //         })

    //         ingest.store()
    //             .then(response => {
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})
    //       }

    //     },
    //     /**
    //      * Aligns all saved Equipments
    //      * */
    //     alignPlaceholders(data, old) {
    //       const item = new Mcrdata(old.itemId, old.itemType)
    //       const items = item.get.placeholders && true ? item.get.placeholders.slice(0) : old.items.slice(0)

    //       let i = items.findIndex(v => v.itemId === old.item.id)
    //       if (i > -1) items.splice(i, 1)
    //       if (old.action.type !== 'delete') items.push(data)
    //       app.$emit('changeOfPlaceholders', {
    //         itemId: old.itemId,
    //         itemType: old.itemType,
    //         extraData: old.extraData,
    //         item: old.item, // { country, id, ingestionType }
    //         items: items,
    //         action: {
    //           module: old.action.module,
    //           type: old.action.type,
    //         },
    //       })
    //       console.log(data, 'align Placeholders', old)
    //     },
    //     /**
    //      * Saves all waiting Equipments
    //      * */
    //     savePlaceholders(i) {
    //       // item.id => 17638 (equipment usage id)
    //       // equipmentId => 238
    //       if (i.action.type === 'store') {
    //         const ingest = this.getForkPlaceholderClass(i.itemType, i.itemId, 0)
    //         if (ingest.get && !ingest.get.id) {
    //           i.item.id = 0
    //           ingest.add(i.item)
    //           //console.log(ingest, 'STORE FAIL NEEDS TO BE CHECKED', i)
    //         }
    //         console.log(ingest, 'Placeholder store', i.item.id, i)
    //         //ingest.set('no_broadcast', true)
    //         ingest.store()
    //             .then(response => {
    //               this.alignPlaceholders(response.data, i)
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})
    //       }
          
    //       if (i.action.type === 'delete') {
    //         const ingest = this.getForkPlaceholderClass(i.itemType, i.itemId, i.item.id)
    //         if (ingest.get && !ingest.get.id) { ingest.add(i.item) }
    //         //if (ingest.get.equipmentId !== i.item.equipmentId ) { ingest.set('equipmentId', i.item.equipmentId) }

    //         console.log(ingest.get.id, 'Placeholder delete', i.item.id)
    //         ingest.set('no_broadcast', true)
    //         ingest.deleteSilent()
    //             .then(response => {
    //               this.alignPlaceholders(response.data, i)
    //               this.clearWaiting(i)
    //             })
    //             .catch(e => { console.log('ERROR!', e)})

    //       }
    //     },
    //      getTraffic() {
    //        const traffic = new Updates(1)
    //        let status
    //        traffic.load()
    //            .then(response => {
    //              status = response.data
    //              if (status === 0) {
    //                this.saveUpdates()
    //                app.$emit('clearTimers')
    //              } else {
    //                this.$alert.set('Please wait maximum 1 minute, or at least till got updates alert', 'danger', 10)
    //                this.$alert.set('Someone else is saving.', 'danger', 10)
    //              }
    //            })
    //      },
    //     /**
    //     * Save all waiting updates at once, within different types
    //     * */
    //     saveUpdates() {
    //       if (this.waiting.total > 0) {
    //         const items = this.waiting.items
    //         Lazy(items).forEach(v => {
    //           if (v.action.module === 'ingestion') {
    //             this.saveIngestions(v)
    //           }
    //           if (v.action.name === 'HouseNumber') {
    //             this.saveHouseNumbers(v)
    //           }
    //           if (v.action.module === 'McrStatus') {
    //             this.saveMcrStatuses(v)
    //           }
    //           if (v.action.module === 'equipment') {
    //             this.saveEquipments(v)
    //           }
    //           if (v.action.module === 'feedback') {
    //             this.saveFeedback(v)
    //           }

    //           if (v.action.module === 'placeholder') {
    //             this.savePlaceholders(v)
    //           }

    //         })
    //         this.waiting.items.push([1,2,3])
    //       }
    //     },
    //     clearUpdates() {
    //       const context = this
    //      swal({
    //         title: 'Revert Back?',
    //         text: `Are you sure to revert back all changes?`,
    //         type: 'question',
    //         confirmButtonText: 'Yes',
    //         cancelButtonText: 'No',
    //         showCancelButton: true
    //       })
    //           .then(function () {
    //             context.revertBack()
    //           })
    //           .catch(function(e) {
    //             if (e === "cancel") {
    //               console.log('good, cancled');
    //             }
    //           })
    //     },
    //     /**
    //      * Clears all data of waiting updates
    //      */
    //     cleanWaitings() {
    //       const context = this
    //       context.waiting.total = 0
    //       context.waiting.items = []
    //       context.waiting.page = {}
    //       context.waiting.pageItems = {}
    //       context.waiting.json = []
    //       context.waiting.updates = false
    //       Vue.nextTick(() => {
    //         jQuery('div.book-crew-filter.book-bottom-sticky').css('flex-direction', '')
    //       })
    //     },


    //     revertBack() {
    //       const data = this.waiting.page
    //       app.$emit('revertBackChanges', data)
    //       app.$emit('clearTimers')
    //     },
        /**
         * Clear all the current filters.
         * @param boolean trigger Trigger filterBy() after the filters have been cleared.
         */
        clearFilters(trigger = true) {
            const context = this
            Lazy(context.filterItems)
                .each((v, k) => {
                    switch (v.type) {
                        case 'text':
                            v.value = ''
                            break
                        default:
                            Lazy(v.items)
                                .each(z => {
                                    Vue.set(z, 'selected', false)
                                })

                            break
                    }
                })
            context.freeText = Object.assign({}, {})
            context.searchText = ''
            if (trigger) context.filterBy()
        },

        /**
         * Handler for the clear filters button.
         */
        onClearAllFilters() {
            if (this.myFiltersSelected !== '') {
                this.clearMyFilter()
            } else {
                this.clearFilters()
            }
        },

        /**
         * Save the current selected filter into the API.
         */
        saveFilter() {
            const context = this
            const filter = new Filter(0)
            filter.set('userId', app.$data.user.get.id)
            filter.set('usageType', 'feed')
            filter.set('label', context.modalForm.save.label.value)
            filter.set('value', context.$store.state.filter.preserve)
            filter.store()
                .then(response => {
                    this.$alert.set('Filter <strong>' + response.data.label + '</strong> has been saved.', 'success', 5)
                    context.showModalForm(false)
                    context.myFiltersSelected = response.data.id
                    filter.sortBy('label')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to save the filter.')
                })
        },

        /**
         * Delete the current selected custom filter.
         */
        deleteFilter() {
            const context = this
            if (!context.myFiltersSelected) return
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete the selected filter?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function() {
                    const filter = new Filter(context.myFiltersSelected)
                    filter.delete(false)
                        .then(response => {
                            context.clearMyFilter()
                            context.$alert.set('The filter was deleted.', 'success', 3)
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to delete the filter.')
                        })
                })
        },

        /**
         * Show save filter form.
         */
        showModalForm(open = true, form = 'save') {
            const context = this
            if (!open) {
                context.modalForm[form].label.value = ''
            }
            this.modalForm[form].show = open
        },

        /**
         * Show Advanced Filters form.
         */
        showAdvancedFilters(open = true) {
            this.modalForm.advanced.show = open
        },

        /**
         * Set filters that have been preserved in LocalStorage.
         */
        setPreservedFilters() {
            if (this.$store.state.filter.preserve) {
                Lazy(this.$store.state.filter.preserve)
                    .filter((v, k) => k != 'advanced')
                    .each((v, k) => {
                        if (this.filterItems.hasOwnProperty(k)) {
                            Lazy(this.filterItems[k].items)
                                .each(z => {
                                    if (v.indexOf(z.id) > -1) {
                                        Vue.set(z, 'selected', true)
                                    }
                                })
                        }
                    })
                if (this.$store.state.filter.preserve.hasOwnProperty('advanced')) {
                    Lazy(this.$store.state.filter.preserve.advanced)
                        .each((advancedFilter, advancedType) => {
                            Lazy(advancedFilter)
                                .each((advancedFilterItem, filterName) => {
                                    Lazy(advancedFilterItem)
                                        .each((elements, advancedFilterName) => {
                                            Lazy(elements)
                                                .filter(v => v.length > 0)
                                                .each((v, k) => {

                                                    // Delay value setting to ensure
                                                    // that the value main filter
                                                    // items have been populated.
                                                    Vue.nextTick(() => {

                                                        // filterName is not available in advancedFilters,
                                                        // which means it has been removed from the filter
                                                        // rules for the current page.
                                                        if (!this.advancedFilters[advancedType] || !this.advancedFilters[advancedType][filterName]) return

                                                        const filterItem = this.advancedFilters[advancedType][filterName][advancedFilterName].items.find(z => z.id == k)
                                                        if (filterItem) {
                                                            Lazy(filterItem.items)
                                                                .each(z => {
                                                                    if (Array.isArray(v)) {
                                                                        if (v.indexOf(z.id) > -1) {
                                                                            Vue.set(z, 'selected', true)
                                                                        }
                                                                    } else {
                                                                        if (v == z.id) {
                                                                            Vue.set(z, 'selected', true)
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    })

                                                })
                                        })
                                })
                        })
                }
            }

            // Trigger the filter after the next DOM update.
            Vue.nextTick(() => {
                this.filterBy(false)
            })

        },

        /**
         * Check is the passed advanced filter has items.
         */
        advancedFilterItemHasItems(filter) {
            return Lazy(filter).every(v => v.items.length > 0)
        },

        showCrewMembers(){
            const context = this
            context.isShowMegaView = !context.isShowMegaView
            this.$emit('trigger-mega-view', context.isShowMegaView)
        },
    }
}

</script>

<template>
<div class="book-crew-filter book-bottom-sticky" ref="bookFilter" :class="{'book-filter-advanced-filter-modal-open': modalForm.advanced.show}">
    <book-loader :small="true" v-if="!loaded || loading"></book-loader>
    <template v-else>
        <div class="book-filter-summary" style="padding-left: 10px;border-left: 1px solid #DDDDDD;margin-left: 10px;" v-if="waiting.updates">
            <button class="btn btn-info" @click="clearUpdates()" title="Remove selected items"><font-awesome-icon icon="eraser"/></button>
            <button class="btn btn-primary" @click="getTraffic()" title="Save current files"><font-awesome-icon icon="save"/></button>
        </div>
        <div class="book-filter-items-wrapper" style="width: 100%;" v-else-if="filterItems">
            <div :class="isSplitPage || isProblemPage ? 'col-sm-5 col-padding-filter-left book-filter-items-wrapper' : 'col-sm-12 col-no-padding book-filter-items-wrapper'" v-if="!waiting.updates">
                <div class="book-filter-saved-wrapper" v-if="options.save && myFilters && !waiting.updates">
                    <div class="form-group" :class="{'dark-input': darkMode,  'book-selected': myFiltersSelected != ''}">
                        <select class="form-control" v-model="myFiltersSelected" @change="applyMyFilter()" :disabled="myFilters.length < 1">
                            <option value="">{{ myFiltersSelected != '' ? '- Clear -' : '- My filters -' }}</option>
                            <option v-for="filter in myFilters" :value="filter.id">{{filter.label}}</option>
                        </select>
                    </div>
                    <button class="btn btn-danger" v-if="myFiltersSelected != ''" @click="deleteFilter()" title="Delete filter">
                        <font-awesome-icon icon="trash"/>
                    </button>
                </div>
                <div class="book-filter-summary" style="float: left; margin-top: 10px;" v-if="!waiting.updates">
                    <span><span class="hidden-xs">Showing&nbsp;</span><strong>{{info.leftVisible}}</strong> of <strong>{{info.leftTotal}}</strong> item(s)</span>
                </div>
                <div class="book-filter-items collapse" style="float: right;">
                    <div class="book-filter-items-inner-wrapper">
                        <div v-for="(leftFilter, leftFilterName) in leftSideFilters" class='form-group' :class="{'book-selected': (leftFilter.type != 'text') && leftFilter.items.filter(v => v.selected).length > 0}">
                            <div class="book-filter-text-wrapper"
                                :class="{'dark-input': darkMode}"
                                v-if="leftFilter.type == 'text'">
                                <book-loader :small="true" v-if="textFiltersDelay[leftFilterName]"></book-loader>
                                <input
                                    v-model="freeText[leftFilterName]"
                                    :placeholder="leftFilter.label"
                                    @keyup.enter="updateFilterSetText(leftFilterName)"
                                    type="text"
                                    class="form-control dark-port" />
                            </div>
                            <book-selectbox-main
                                    v-else
                                    :componentId="leftFilterName"
                                    :darkMode="darkMode"
                                    direction="up"
                                    :label="leftFilter.label"
                                    :items="leftFilter.items"
                                    @selectedItems="items => updateFilterSet(leftFilterName, items)">
                            </book-selectbox-main>
                        </div>
                        <!-- <button class="btn btn-success book-filter-advanced-filters bttn-fivepx" title="Open advanced filters" v-if="!isSplitPage && hasAdvancedFilters" @click="showAdvancedFilters()"><font-awesome-icon icon="filter"/></button> -->
                        <button class="btn btn-primary bttn-fivepx" :disabled="!hasFilter || matchesCustomFilter" @click="showModalForm()" title="Save current filter"><font-awesome-icon icon="save"/></button>
                        <button class="btn btn-info bttn-fivepx" :disabled="!hasFilter" @click="onClearAllFilters()" title="Clear all filters"><font-awesome-icon icon="times"/><span class="hidden-xs">&nbsp;&nbsp;Clear</span></button>
                    </div>
                </div>
            </div>
            <div class="book-filter-items collapse" v-else>
                <div class="book-filter-items-inner-wrapper" v-if="waiting.updates">
                    <span><span class="hidden-xs"></span><strong>{{waiting.total}}</strong> item(s) are waiting to get saved!</span>
                </div>
            </div>
            <div class="col-sm-7 col-padding-filter-right book-filter-items-wrapper" v-if="(isSplitPage || isProblemPage) && !waiting.updates">
                <div class="book-filter-summary" style="float: left;  margin-top: 10px;" v-if="!waiting.updates">
                    <span><span class="hidden-xs">Showing&nbsp;</span><strong>{{info.rightVisible}}</strong> of <strong>{{info.rightTotal}}</strong> item(s)</span>
                </div>
                <div class="book-filter-items collapse" style="float: right;">
                    <div class="book-filter-items-inner-wrapper">
                        <div v-for="(filter, filterName) in rightSideFilters" class='form-group' :class="{'book-selected': (filter.type != 'text') && filter.items.filter(v => v.selected).length > 0}">
                            <div class="book-filter-text-wrapper"
                                :class="{'dark-input': darkMode}"
                                v-if="filter.type == 'text'">
                                <book-loader :small="true" v-if="textFiltersDelay[filterName]"></book-loader>
                                <input
                                    v-model="freeText[filterName]"
                                    :placeholder="filter.label"
                                    @keyup.enter="updateFilterSetText(filterName)"
                                    type="text"
                                    class="form-control dark-port" />
                            </div>
                            <book-radiobox-main
                                    v-else-if="filter.type == 'radio'"
                                    :componentId="filterName"
                                    :darkMode="darkMode"
                                    direction="up"
                                    :label="filter.rightLabel ? filter.rightLabel : filter.label"
                                    :items="filter.items"
                                    :selected="filter.value ? filter.value : ''"
                                    @selectedItem="item => updateFilterSetRadio(filterName, item)">
                            </book-radiobox-main>
                            <book-selectbox-main
                                    v-else
                                    :componentId="filterName"
                                    :darkMode="darkMode"
                                    direction="up"
                                    :label="filter.rightLabel ? filter.rightLabel : filter.label"
                                    :items="filter.items"
                                    @selectedItems="items => updateFilterSet(filterName, items)">
                            </book-selectbox-main>
                        </div>
                        <!-- <button class="btn btn-success book-filter-advanced-filters bttn-fivepx" title="Open advanced filters" v-if="isSplitPage && hasAdvancedFilters" @click="showAdvancedFilters()"><font-awesome-icon icon="filter"/></button> -->
                        <button v-if="!isSplitPage" class="btn btn-primary bttn-fivepx" :disabled="!hasFilter || matchesCustomFilter" @click="showModalForm()" title="Save current filter"><font-awesome-icon icon="save"/></button>
                        <button v-if="!isSplitPage" class="btn btn-info bttn-fivepx" :disabled="!hasFilter" @click="onClearAllFilters()" title="Clear all filters"><font-awesome-icon icon="times"/><span class="hidden-xs">&nbsp;&nbsp;Clear</span></button>
                    </div>
                </div>
            </div>
        </div>
        <book-modal :class="{'dark-port': darkMode}" maxWidth="400px" @close="showModalForm(false, 'save')" v-if="modalForm.save.show">
            <h4 slot="header" class="modal-title">Save filter</h4>
            <div slot="body">
                <div class="form-horizontal">
                    <div class="form-group" :class="{'has-error': formErrors.has('label')}">
                        <label for="name" class="control-label book-width-100">Filter name</label>
                        <div class="form-control-100">
                            <input class="form-control" name="label" v-focus v-model:trim="modalForm.save.label.value" v-validate="modalForm.save.label.validator" />
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <button class="btn btn-success" @click="saveFilter()" :disabled="isFormClean || formErrors.any()">Save</button>
            </div>
        </book-modal>
        <book-modal :class="{'dark-port': darkMode}" maxWidth="1100px" @close="showAdvancedFilters(false)" v-if="modalForm.advanced.show">
            <h4 slot="header" class="modal-title" >Advanced Filters</h4>
            <div slot="body">
                <div v-for="(filterItem, advancedType) in advancedFilters" v-if="Object.keys(advancedFilters).length > 0" class="book-filter-advanced-filter" :class="'book-filter-advanced-filter-' + advancedType">
                    <!--<h3 class="book-filter-advanced-filter-type-header">{{advancedType}}</h3>-->
                    <div class="book-filter-advanced-filter-type" v-for="(advancedFilterItem, filterName) in filterItem" v-if="advancedFilterItemHasItems(advancedFilterItem)">
                        <div class="book-filter-advanced-filter-item" v-for="(elements, advancedFilterName) in advancedFilterItem">
                            <!--<div class="book-filter-advanced-filter-item-header">{{elements.label}}&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;{{elements.label2}}</div>-->
                            <div class="book-filter-advanced-filter-item-header">{{elements.label2}}</div>
                            <div class="form-group" v-for="field in elements.items">
                                <book-select-dropdown
                                    :componentId="advancedType + '-' + filterName + '-' + field.id"
                                    :label="field.label"
                                    direction="down"
                                    :items="field.items"
                                    :inPlace="true"
                                    :alwaysVisible="true"
                                    :displayLabel="true"
                                    @selectedItems="items => updateAdvancedFilterSet({advancedType: advancedType, filterName: filterName, id: field.id, advancedFilterName: advancedFilterName}, items)">
                                </book-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </book-modal>
    </template>
</div>
</template>

<style lang="scss">

@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
// Navbar-top should not be infront of the filters (WN)
.navbar-fixed-top {
    z-index: 1003 !important;
}
.book-top-sticky {
    z-index: 1002 !important;
}
.book-crew-filter {
    display: flex;
    &.book-bottom-sticky.book-filter-advanced-filter-modal-open {
        z-index: 1050;
    }
    > * {
        display: inline-block;
    }
    .book-loader {
        flex: 1;
    }
    .btn + .btn {
        margin-left: 5px !important;
    }
    .book-filter-summary {
        align-items: center;
        border-right: 1px solid $color-grey-border;
        display: flex;
        margin-right: 10px;
        padding-right: 10px;
    }
    .book-filter-saved-wrapper,
    .book-filter-items-wrapper,
    .book-filter-items-inner-wrapper {
        align-items: center;
        display: flex;
        > * {
            margin: 2px 0 2px 10px !important;
            &:first-child {
                margin-left: 0 !important;
            }
        }
        button:disabled {
            opacity: .3;
        }
        .form-group {
            display: inline-block;
            &.book-selected {

                // .form-control {
                .dropdown-toggle {
                    border-color: $color-blue;
                }
            }
            .control-label {
                display: block;
                text-align: center;
            }
        }
    }
    .book-filter-saved-wrapper {
        flex: 1;
    }
    .book-filter-items-wrapper {
    }
    .book-filter-items {
        &.collapse:not(.in) {
            display: none;
        }
        @include breakpoint(max-width $max-tablet) {
            background: $color-white;
            border-top: 1px solid $color-grey-border !important;
            bottom: 52px;
            left: 0;
            margin-left: 0 !important;
            overflow: hidden;
            position: fixed;
            right: 0;
            z-index: 1000;
            &.collapsing,
            &.collapse.in {
            }
            .book-filter-items-inner-wrapper {
                padding: 8px 15px;
            }
        }
        @include breakpoint(min-width $min-netbook) {
            display: block !important;
        }
    }
    .book-filter-items-inner-wrapper {
        justify-content: flex-end;

    }
    .book-filter-items-toggle {
        @include breakpoint(min-width $min-netbook) {
            display: none;
        }
        &:focus {
            outline: 0;
        }
    }
    .book-select-dropdown {
        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }
    .book-filter-advanced-filter {
        //border: 2px solid $color-grey-border;
        border-radius: 6px;
        padding-bottom: 10px;
    }
    .book-filter-advanced-filter-combine {
        .form-group {
            min-width: 190px;
            position: relative;
            + .form-group {
                padding-left: 30px;
                &:before {
                    //content: "or";
                    font-size: 1.6rem;
                    font-weight: 600;
                    left: 0;
                    line-height: 20px;
                    margin-top: -10px;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    width: 20px;
                }
            }
        }
    }
    .book-filter-advanced-filter-type-header {
        background: $color-grey-border;
        border-radius: 4px 4px 0 0;
        padding: 5px;
        text-align: center;
        text-transform: capitalize;
        &:first-child {
            margin-top: 0;
        }
    }
    .book-filter-advanced-filter-type {
        position: relative;
        &:nth-child(odd) {
            .book-filter-advanced-filter-item {
                border: 1px solid $color-bg-info;
            }
            .book-filter-advanced-filter-item-header {
                background: $color-bg-info;
            }
        }
        &:nth-child(even) {
            .book-filter-advanced-filter-item {
                border: 1px solid $color-bg-success;
            }
            .book-filter-advanced-filter-item-header {
                background: $color-bg-success;
            }
        }
        + .book-filter-advanced-filter-type {
            margin-top: 10px;
            &:before {
                display: block;
                //content: "or";
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        .book-filter-advanced-filter-item {

            // border: 1px solid $color-grey-light;

            border-radius: 4px;
            display: flex;
            flex-flow: row wrap;
            margin: 0 10px;
            padding: 10px;
            + .book-filter-advanced-filter-item {
                margin-top: 15px;
                &:before {
                    display: block;
                    //content: "and";
                    flex-basis: 100%;
                    font-size: 1.6rem;
                    font-weight: 600;
                    margin-top: -45px;
                    text-align: center;
                }
            }
            .form-group {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .book-filter-advanced-filter-item-header {

            // background: $color-grey-light;

            border-radius: 4px 4px 0 0;
            flex: 1 0 100%;
            font-weight: 600;
            margin: -10px -10px 10px;
            padding: 4px;
            text-align: center;
        }
    }
    .book-filter-advanced-filter-item {
        .book-select-dropdown {
            .dropdown-menu {
                left: 0;
                right: auto;
            }
        }
    }
    .book-filter-text-wrapper {
        position: relative;
        input[type="text"] {
            padding-right: 30px;
        }
        .book-loader {
            position: absolute;
            right: 6px;
            top: 7px;
        }
    }

    .col-padding-filter-right {
        padding-left: 5px;
        padding-right: 0px;
    }

    .col-padding-filter-left {
        padding-left: 0px;
        padding-right: 14px;
    }
}
.bttn-fivepx {
  padding: 5px !important;
  height: 27px !important;
  font-size: 12px !important;
}
.blink-bg{
  animation: blinkingBackground 5s infinite;
}
@keyframes blinkingBackground{
  80%		{ background-color: #881d1d;}
  100%		{ background-color: #337ab7;}
}

</style>
