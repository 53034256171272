import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import {app} from 'app'

import BookLogin from 'components/user/BookLogin';
import BookAccessDenied from 'components/auth/BookAccessDenied'
import BookUser from 'components/user/BookUser';
import BookUserEdit from 'components/user/BookUserEdit';

import BookSettings from 'components/settings/BookSettings'

import BookNotificationLog from 'components/notification/BookNotificationLog'

import BookContracts from 'components/contract/BookContracts'
import BookContractEdit from 'components/contract/BookContractEdit'

import BookEquipmentList from 'components/equipment/EquipmentList/BookEquipmentList'
import BookEquipmentEdit from 'components/equipment/EquipmentList/BookEquipmentEdit'
import BookEquipmentUsagePage from 'components/equipment/EquipmentUsagePage/BookEquipmentUsagePage'

import BookEntities from 'components/entity/BookEntities'
import BookEntityItems from 'components/entity/BookEntityItems'

import BookFeeds from 'components/feed/Feeds/BookFeeds'
import BookFeedEdit from 'components/feed/FeedEdit/BookFeedEdit'

import BookFeedbackEdit from 'components/feedback/BookFeedbackEdit'
import BookFeedbackList from 'components/feedback/BookFeedbackList'

import BookProductions from 'components/production/Productions/BookProductions'
import BookProductionEdit from 'components/production/ProductionEdit/BookProductionEdit'
import BookProductionTaskEdit from 'components/production/ProductionTask/BookProductionTaskEdit'
import BookProductionTasks from 'components/production/ProductionTask/BookProductionTasks'

import BookProductionTemplates from 'components/production/ProductionTemplate/BookProductionTemplates'
import BookProductionTemplateEdit from 'components/production/ProductionTemplate/BookProductionTemplateEdit'

import BookFeedTemplates from 'components/feed/FeedTemplate/BookFeedTemplates'
import BookFeedTemplateEdit from 'components/feed/FeedTemplate/BookFeedTemplateEdit'

import BookMcr from 'components/mcr/BookMcr'

import BookCalendarPage from 'components/calendar/BookCalendarPage'
import BookCalendarEdit from 'components/calendar/BookCalendarEdit'

import BookUsers from 'components/user/BookUsers'
import BookRoles from 'components/user/BookRoles'
import BookPermissions from 'components/user/BookPermissions'

import BookContacts from 'components/contacts/BookContacts'

import BookRates from 'components/rate/BookRates'
import BookRateEdit from 'components/rate/BookRateEdit'

import BookUserNotifications from 'components/usernotifications/BookUserNotifications'

import BookCountry from 'components/country/BookCountry'

import BookCrewView from 'components/crewview/BookCrewView'

import BookEquipmentOverviewPage from 'components/equipment/EquipmentOverview/BookEquipmentOverviewPage'
import BookEquipmentProductionOverview from 'components/equipment/EquipmentOverview/BookEquipmentProductionOverview'

const routes = [
    {
        path: '/',
        name:'index',
        redirect: {
            name: 'calendar'
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            content: BookLogin
        },
        meta: {
            title: route => {
                return 'Login'
            },
        }
    },
    {
        path: '/login/password',
        name: 'password',
        components: {
            content: BookLogin
        },
        meta: {
            title: route => {
                return 'Password reset'
            },
        }
    },
    {
        path: '/login/reset',
        name: 'reset',
        components: {
            content: BookLogin
        },
        meta: {
            title: route => {
                return 'Password resetting'
            },
        }
    },
    {
        path: '/access-denied',
        name:'access.denied',
        components: {
            'content': BookAccessDenied
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Access denied'
            },
        }
    },


    // -------------------------------------------------------------------------
    // Feeds.
    {
        path: '/feeds',
        name:'feeds',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/feeds/list')
        }
    },
    {
        path: '/feeds/:view',
        name:'feeds.view',
        components: {
            'content': BookFeeds
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feeds'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/feed/:id(\\d+)',
        name:'feed.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookFeedEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feed ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/feed/create',
        name: 'feed.create',
        components: {
            'content': BookFeedEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create feed'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    // -------------------------------------------------------------------------
    // Productions Tasks.

    {
        path: '/task/:id(\\d+)',
        name:'task.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookProductionTaskEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Task ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/task/create',
        name: 'task.create',
        components: {
            'content': BookProductionTaskEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create Task'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/tasks',
        name:'tasks',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/tasks/overview')
        }
    },
    {
        path: '/tasks/:view',
        name:'task.view',
        components: {
            'content': BookProductionTasks
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Tasks'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Productions Edit.

    {
        path: '/edit/:id(\\d+)',
        name:'edit.edit',
        props: {
            'content': (route) => ({
                id: parseInt(route.params.id),
                type: 'edit',
            })
        },
        components: {
            'content': BookProductionEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Edit')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/edit/create',
        name: 'edit.create',
        components: {
            'content': BookProductionEdit
        },
        props: {
            'content': {
                type: 'edit',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create Edit'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Edit')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/edit',
        name:'edit',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/edit/overview')
        }
    },
    {
        path: '/edit/equipment-overview',
        name:'edit.equipmentOverview',
        components: {
            'content': BookEquipmentProductionOverview
        },
        props: {
            'content': {
                productionType: 'edit',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit equipment overview'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Edit')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/edit/:view',
        name:'edit.view',
        components: {
            'content': BookProductions
        },
        props: {
            'content': {
                type: 'edit',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Edit')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // ENG Templates.

    {
        path: '/eng/templates',
        name: 'engTemplates.view',
        components: {
            'content': BookProductionTemplates
        },
        props: {
            'content': {
                'type': 'eng',
                'routeItem': 'engTemplate',
                'routeItems': 'engTemplates',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'ENG templates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/eng/template/:id(\\d+)',
        name: 'engTemplate.edit',
        props: {
            'content': (route) => ({
                id: parseInt(route.params.id),
                type: 'eng',
                routeItem: 'engTemplate',
                routeItems: 'engTemplates',
            })
        },
        components: {
            'content': BookProductionTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'ENG template ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/eng/template/create',
        name: 'engTemplate.create',
        components: {
            'content': BookProductionTemplateEdit
        },
        props: {
            'content': {
                type: 'eng',
                routeItem: 'engTemplate',
                routeItems: 'engTemplates',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create ENG template'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_production_templates', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Productions Eng.

    {
        path: '/eng/:id(\\d+)',
        name:'eng.edit',
        props: {
            'content': (route) => ({
                id: parseInt(route.params.id),
                type: 'eng',
            })
        },
        components: {
            'content': BookProductionEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'ENG ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/eng/create',
        name: 'eng.create',
        components: {
            'content': BookProductionEdit
        },
        props: {
            'content': {
                type: 'eng',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create ENG'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/eng',
        name:'eng',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/eng/overview')
        }
    },
    {
        path: '/eng/equipment-overview',
        name:'eng.equipmentOverview',
        components: {
            'content': BookEquipmentProductionOverview
        },
        props: {
            'content': {
                productionType: 'eng',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'ENG equipment overview'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/eng/:view',
        name:'eng.view',
        components: {
            'content': BookProductions
        },
        props: {
            'content': {
                type: 'eng',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'ENG'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'ENG')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Car Templates.

    {
        path: '/car/templates',
        name: 'carTemplates.view',
        components: {
            'content': BookProductionTemplates
        },
        props: {
            'content': {
                'type': 'car',
                'routeItem': 'carTemplate',
                'routeItems': 'carTemplates',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Car templates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/car/template/:id(\\d+)',
        name: 'carTemplate.edit',
        props: {
            'content': (route) => ({
                id: parseInt(route.params.id),
                type: 'car',
                routeItem: 'carTemplate',
                routeItems: 'carTemplates',
            })
        },
        components: {
            'content': BookProductionTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Car template ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/car/template/create',
        name: 'carTemplate.create',
        components: {
            'content': BookProductionTemplateEdit
        },
        props: {
            'content': {
                type: 'car',
                routeItem: 'carTemplate',
                routeItems: 'carTemplates',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create Car template'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_production_templates', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Productions Car.

    {
        path: '/car/:id(\\d+)',
        name:'car.edit',
        props: {
            'content': (route) => ({
                id: parseInt(route.params.id),
                type: 'car',
                'routeItem': 'car',
                'routeItems': 'car',
            })
        },
        components: {
            'content': BookProductionEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Car ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/car/create',
        name: 'car.create',
        components: {
            'content': BookProductionEdit
        },
        props: {
            'content': {
                'type': 'car',
                'routeItem': 'car',
                'routeItems': 'car',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create Car'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/car',
        name:'car',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/car/overview')
        }
    },
    {
        path: '/car/equipment-overview',
        name:'car.equipmentOverview',
        components: {
            'content': BookEquipmentProductionOverview
        },
        props: {
            'content': {
                productionType: 'car',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Car equipment overview'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/car/:view',
        name:'car.view',
        components: {
            'content': BookProductions
        },
        props: {
            'content': {
                type: 'car',
            }
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Car'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Car')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Productions.
    {
        path: '/production/:id(\\d+)',
        name:'production.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookProductionEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Production ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/production/create',
        name: 'production.create',
        components: {
            'content': BookProductionEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create production'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/productions',
        name:'productions',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/productions/overview')
        }
    },
    {
        path: '/productions/:view',
        name:'productions.view',
        components: {
            'content': BookProductions
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Productions'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    

    // -------------------------------------------------------------------------
    // Production Templates.

    {
        path: '/production/templates',
        name:'productionTemplates.view',
        components: {
            'content': BookProductionTemplates
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Production templates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/production/template/:id(\\d+)',
        name:'productionTemplate.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookProductionTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Production template ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_production_templates', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/production/template/create',
        name: 'productionTemplate.create',
        components: {
            'content': BookProductionTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create production template'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_production_templates', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    // -------------------------------------------------------------------------
    // Feed Templates.

    {
        path: '/feed/templates',
        name:'feedTemplates.view',
        components: {
            'content': BookFeedTemplates
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feed templates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_feed_templates', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/feed/template/:id(\\d+)',
        name:'feedTemplate.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookFeedTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feed template ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_feed_templates', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/feed/template/create',
        name: 'feedTemplate.create',
        components: {
            'content': BookFeedTemplateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create feed template'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_feed_templates', 'Feeds')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    // -------------------------------------------------------------------------
    // Feedback
    {
        path: '/feedback/:feedbackId(\\d+)',
        name:'feedback.edit',
        props: {
            'content': (route) => ({
                //feedbackItemId: parseInt(route.params.feedbackItemId),
                feedbackId: parseInt(route.params.feedbackId),
                //feedbackItemType: route.params.feedbackItemType
            })
        },
        components: {
            'content': BookFeedbackEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feedback ' + route.params.feedbackId
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_feedbacks_reports', 'Feedbacks & Reports')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/:feedbackItemType/:feedbackItemId(\\d+)/feedback/create',
        name: 'feedback.create',
        props: {
            'content': (route) => ({
                feedbackItemId: parseInt(route.params.feedbackItemId),
                feedbackItemType: route.params.feedbackItemType
            })
        },
        components: {
            'content': BookFeedbackEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create feedback'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_feedbacks_reports', 'Feedbacks & Reports')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/feedbacks',
        name:'feedbacks',
        components: {
            'content': BookFeedbackList
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Feedback & Reports'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_feedbacks_reports', 'Feedbacks & Reports')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Calendar.
    {
        path: '/calendar',
        name:'calendar',
        components: {
            'content': BookCalendarPage
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Calendar'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_crew_calendar', 'Productions') && from.query.crewId || app.$ability.cannot('view_crew_calendar', 'Productions') && to.query.crewId) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/calendar/:id(\\d+)',
        name:'placeholder.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookCalendarEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Calendar event ' + route.params.id
            },
        }
    },

    {
        path: '/calendar/create',
        name: 'placeholder.create',
        components: {
            'content': BookCalendarEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create calendar event'
            },
        }
    },

    // -------------------------------------------------------------------------
    // Contacts.
    {
        path: '/contacts',
        name: 'contacts',
        components: {
            'content': BookContacts
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Contacts'
            },
        }
    },

    // -------------------------------------------------------------------------
    // MCR.
    {
        path: '/mcr',
        name:'mcr',
        components: {
            'content': BookMcr,
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'MCR View'
            },
        },
    },

    // -------------------------------------------------------------------------
    // Equipment usage.
    {
        path: '/equipment-usage',
        name:'equipment.usage',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/equipment-usage/timeline')
        }
    },

    {
        path: '/equipment-usage/:view',
        name: 'equipment.usage.view',
        components: {
            'content': BookEquipmentUsagePage
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Equipment usage'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_equipment_usage', 'Equipment')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // User
    {
      path: '/user',
      name:'user',
      components: {
          'content': BookUser
      },
      meta: {
          requiresAuth: true
      },
      beforeEnter: (from, to, next) => {
          next('/user/' + app.$data.user.id)
      }
    },
    {
        name: 'user.id',
        path: '/user/:id(\\d+)',
        props: {
            'action_menu': (route) => ({id: parseInt(route.params.id)}),
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookUser
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'User ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if ((app.$data.user.get.id != from.params.id) && app.$ability.cannot('view', 'Users')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        name: 'user.id.edit',
        path: '/user/:id(\\d+)/edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookUserEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit user ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if ((app.$data.user.get.id != from.params.id) && app.$ability.cannot('update', 'Users')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Admin

    {
        path: '/admin/users',
        name: 'admin.users',
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Manage users'
            },
        },
        components: {
            'content': BookUsers
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('update', 'Users')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/admin/users/create',
        name: 'user.create',
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create user'
            },
        },
        components: {
            'content': BookUserEdit
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Users')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/admin/users/roles',
        name: 'admin.users.roles',
        meta: {
            requiresAuth: true,
            permissions: [{
                action: 'manage',
                subject: 'Roles'
            }],
            title: route => {
                return 'Manage roles'
            },
        },
        components: {
            'content': BookRoles
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('manage', 'Roles')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/admin/users/permissions',
        name: 'admin.users.permissions',
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Manage permissions'
            },
        },
        components: {
            'content': BookPermissions
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('manage', 'Permissions')) {
                next({path: '/access-denied', query: to.path})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/entity',
        name:'entity',
        components: {
            'content': BookEntities
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Manage entities'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('manage', 'Entities')) {
                next({path: '/access-denied', query: to.path})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/entity/:id(\\d+)',
        name:'entity.id',
        props: {
            'action_menu': (route) => ({id: parseInt(route.params.id)}),
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookEntityItems
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Entity ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('update_entity_items', 'Entities')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/settings',
        name:'settings',
        components: {
            'content': BookSettings
        },
        meta: {
            requiresAuth: true,
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('change_settings', 'Miscellaneous')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/notificationlogs',
        name:'notificationlogs',
        components: {
            'content': BookNotificationLog
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Notification logs'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('send_notifications', 'Miscellaneous')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/contract',
        name:'contract',
        components: {
            'content': BookContracts
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Manage contracts'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Contracts')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/contract/:id(\\d+)',
        name:'contract.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookContractEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit contract ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('update', 'Contracts')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/contract/create',
        name: 'contract.create',
        components: {
            'content': BookContractEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create contract'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Contracts')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },


    // -------------------------------------------------------------------------
    // Equipments.

    {
        path: '/equipment',
        name:'equipment',
        components: {
            'content': BookEquipmentList
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Manage equipment'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Equipment')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/equipment/:id(\\d+)',
        name:'equipment.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookEquipmentEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Edit equipment' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('update', 'Equipment')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/equipment/create',
        name: 'equipment.create',
        components: {
            'content': BookEquipmentEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create equipment'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create', 'Equipment')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    //--------------------------------------------------------------------------
    // Rates
    {
        path: '/rates',
        name:'all.rates',
        components: {
            'content': BookRates
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Rates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_rates', 'Rates')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/rates/my',
        name:'own.rates',
        components: {
            'content': BookRates
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'My rates'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_own_rates', 'Rates')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/rate/:id(\\d+)',
        name:'rate.edit',
        props: {
            'content': (route) => ({id: parseInt(route.params.id)})
        },
        components: {
            'content': BookRateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Rate ' + route.params.id
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view_rates', 'Rates')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },
    {
        path: '/rate/create',
        name: 'rate.create',
        components: {
            'content': BookRateEdit
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Create rate'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_rates', 'Rates')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    //--------------------------------------------------------------------------
    // User notifications
    {
        path: '/usernotifications',
        name:'usernotifications',
        components: {
            'content': BookUserNotifications
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'User Notifications'
            },
        },
    },


    //--------------------------------------------------------------------------
    // Countries
    {
        path: '/countries',
        name:'all.countries',
        components: {
            'content': BookCountry
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Countries'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('view', 'Country')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    // -------------------------------------------------------------------------
    // Crew view.
    {
        path: '/crew',
        name:'crew',
        meta: {
            requiresAuth: true
        },
        beforeEnter: (from, to, next) => {
            next('/crew/single')
        }
    },
    {
        path: '/crew/:view',
        name:'crew.view',
        components: {
            'content': BookCrewView,
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Crew view'
            },
        },
        beforeEnter: (from, to, next) => {
            if (app.$ability.cannot('create_production_crew', 'Productions')) {
                next({path: '/access-denied', query: {path: from.path}})
            }
            else {
                next()
            }
        }
    },

    {
        path: '/equipment-overview',
        name: 'equipmentoverview',
        components: {
            'content': BookEquipmentOverviewPage,
        },
        meta: {
            requiresAuth: true,
            title: route => {
                return 'Equipment Overview'
            },
        },
    }

    // { path: '*', redirect: { name: 'feeds' } },

]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router;
