<script>
/**
 * @file
 * Book Overview component.
 * Builds a grid like overview based on the passed parameters.
 */

import Datetime from 'components/common/helpers/Datetime'

export default {
    props: {
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        intervalStart: {
            default: '',
            type: String
        },
        intervalEnd: {
            default: '',
            type: String
        },
        isSplitPage: {
            default: false,
            type: Boolean
        },
        isProblemPage: {
            default: false,
            type: Boolean
        },
        showOnlyChecked: {
            default: false,
            type: Boolean
        },
        showAssignmentChecked: {
            default: false,
            type: Boolean
        },
        showAssignmentCheckedOnly: {
            default: false,
            type: Boolean
        },
        checkedUsers: {
            default: () => {
                return []
            },
            type: Array
        },
    },
    data() {
        return {
            // overviewData: {
            //     groups: [],
            //     processed: false
            // },
            processed: false,
            loaded: false,
            // sidebar: false,
            // rawData: JSON.parse(JSON.stringify(this.items)),
            startDate: '',
            endDate: '',
            checkedItems: [],
            checkedAll: false
            //columns: [],
            //numOfColumns: 7,
        }
    },
    computed: {
        hasElements() {
            return this.items && this.items.length > 0 ? true : false
        },
        columns() {
            let startDate = this.intervalStart ? moment(this.intervalStart) : moment()//.format('YYYY-MM-DD')
            let endDate = this.intervalEnd ? moment(this.intervalEnd) :  moment().add(7,'days')//.format('YYYY-MM-DD')

            let numOfColumns = endDate.diff(startDate, 'days') + 1

            let columns = []

            for (let i=1; i <= numOfColumns; i++) {
                const column = {
                    weekday: startDate.format('ddd'),
                    date: startDate.format('YYYY-MM-DD'),
                    day: startDate.format('DD'),
                    month: startDate.format('MMM')
                }

                // Add the week number if it's the first element or the weekday
                // is monday.
                if ((i == 1) || (startDate.isoWeekday() == 1)) {
                    column.week = startDate.isoWeek()
                }

                // Set a flag if the current date is "today".
                if (column.date == Datetime.today()) {
                    column.today = true
                }

                columns.push(column)
                startDate.add(1, 'd')
            }

            return columns
        },
        visibleUsersCount() {
            return this.items && this.items.length > 0 ? (this.items.filter(v => v.visible)).length : 0
        },
        
    },
    watch: {
        visibleUsersCount: function(newVlaue) {
            if(this.checkedItems.length !== newVlaue && this.checkedAll) this.checkedAll = false
            if(this.checkedItems.length == newVlaue && !this.checkedAll) this.checkedAll = true
        },
        checkedUsers: {
            handler(newValue, oldValue) {
                if(newValue !== oldValue && (newValue === null || newValue.length === 0)) {
                    this.checkedItems = []
                }
            },
            deep: true
        }
    },
    mounted() {
        
        //this.buildOverview()
    },
    methods: {

        getAssignments(user, date) {
            let assignments = _.find(user.daily_assingments, i => i.date == date)

            return assignments != undefined && assignments.assingments.length > 0 ? assignments.assingments : []
        },

        showForm(user, column, item = null) {
            if(!this.showAssignmentChecked && (item === null || item.productionType !== 'placeholder')) {
                let assignment = {
                    user: user,
                    date: column.date,
                    item: item
                }

                this.$emit('changeAssignment', assignment)
            }
            
        },
        showNotificationModal(userId) {
            this.$emit('notifyUser', userId)
        },
        allItemsChecked() {
            this.checkedItems = []
            if(!this.checkedAll) {
                Lazy(this.items)
                .each(v => {
                    if(v.visible) {
                        this.checkedItems.push(v.user_id)
                        v.checked = true
                    }
                    else {
                        v.checked = false
                    }
                    
                })
            }
            else {
                Lazy(this.items)
                .each(v => {
                    v.checked = false
                })
            }
            this.$emit('updateChosenUsers', JSON.stringify(this.checkedItems))
        },
        itemChecked(userId) {
            let currentUser = this.items.find(i => i.user_id == userId)
            if(this.isProblemPage) {
                this.checkedItems = []
                if(!currentUser.checked) {
                    this.checkedItems.push(userId)
                }
            }
            else {
                let indx = this.checkedItems.findIndex(i => i === userId)
                if(currentUser.checked) {
                    if(this.checkedAll) this.checkedAll = false
                    
                    if(indx > -1) {
                        this.checkedItems.splice(indx, 1)
                    }
                }
                else {
                    if(indx == -1) this.checkedItems.push(userId)
                }

                
            }

            this.$emit('updateChosenUsers', JSON.stringify(this.checkedItems))
            
        },
        getTotalDuration(total_duration) {
            let hours = parseInt(total_duration / 60)
            let minutes = total_duration - hours*60
            return hours + 'h ' + minutes + 'min'
        },
        assingmentChecked(user, date, item) {
            let obj = {
                user: user.user_id,
                date: date,
                assignment: item.id
            }

            this.$emit('selectAssignments', obj)
        },
        
        
        
    }
}
</script>

<template>
<div id="crewview">
    <div class="book-crewview">
        <book-affix :relative-element-selector="isSplitPage || isProblemPage ? '.book-crewview' : '.book-crewview'" :offset="isSplitPage || isProblemPage ? { top: 115, bottom: 30 } : { top: 115, bottom: 30 }" :listenScrollOn="isSplitPage || isProblemPage ? '#crew' : ''" :class="!(isSplitPage || isProblemPage) ? 'book-crewview-day-navigation' : 'book-crewview-day-navigation-split'" v-if="hasElements">
            <div class="book-crewview-label" :style="'padding: 2px 4px; font-weight: bold; font-size: 14px;'">
                <input type="checkbox" v-model="checkedAll" @click="allItemsChecked()" style="margin: 0px; margin-left: 5px;" :disabled="isProblemPage">
                <span style="padding-left: 5px;">Select All</span>
                <span class="book-crewview-date" style="padding-left: 27px;">({{checkedItems.length}} of {{items.length}})</span>
            </div>
            <div class="book-crewview-row-content">
                <div class="book-crewview-column" v-for="column in columns" :class="[{'book-crewview-today': column.today}, {'book-crewview-with-week': column.week}]" :key="'head ' + column.date" :id="'head ' + column.date">
                    <div :class="column.week ? 'book-crewview-week' : 'book-crewview-week-empty'" :title="'W,' + column.weekday + ', ' + column.day">
                        {{column.week ? 'W' + column.week : ''}}
                    </div>
                    <div class="book-crewview-day" :title="column.weekday + ', ' + column.day">
                        
                        <span class="book-crewview-weekday">{{column.weekday}}</span>
                        <span class="book-crewview-date">{{column.day}}</span>
                        <span class="book-crewview-date">{{column.month}}</span>
                    </div>
                </div>
            </div>
        </book-affix>
        <div :class="!(isSplitPage || isProblemPage) ? 'book-crewview-content' : 'book-crewview-content-split'" v-if="hasElements">
            <div class="book-crewview-row" v-for="(user, $index) in items" :class="($index % 2 == 0) ? 'book-crewview-odd' : 'book-crewview-even'" v-show="(!showOnlyChecked && user.visible) || (showOnlyChecked && user.checked)" :key="user.user_id + '_' + $index" :id="user.user_id">
                <div class="book-crewview-label" :title="user.user_id">
                    <div class="book-crewview-row-content" style="margin-bottom: 5px;">
                         <div class="book-crewview-column-info-tools">
                            <button
                                class="btn btn-info btn-xs"
                                title="Contact user"
                                @click="showNotificationModal(user.user_id)"
                            >
                                <font-awesome-icon icon="envelope"/>
                            </button>
                        </div>
                        <div class="book-crewview-column-info" style="padding-left: 2px;">
                            <span>{{user.fullname}}</span>
                        </div>
                    </div>
                    <div class="book-crewview-row-content" style="margin-bottom: 5px;">
                        <div class="book-crewview-column-info-tools" style="padding-left: 5px;">
                            <input type="checkbox" v-model="user.checked" @click="itemChecked(user.user_id)" :key="user.user_id">
                        </div>
                        <div class="book-crewview-column-info">
                            <p style="font-weight: bold; padding-top: 4px;">{{getTotalDuration(user.total_duration)}}</p>
                        </div>
                    </div>
                </div>
                <div class="book-crewview-row-content">
                    <div class="book-crewview-column" v-for="column in columns" :class="{'book-crewview-today': column.today}" :key="user.user_id + ' ' + column.date" :id="user.user_id + ' ' + column.date" >
                        <div :title="item.productionTitle" class="book-crewview-item book-item-bkg" v-for="item in getAssignments(user, column.date)" :class="'book-item-status-' + item.itemStatusId" :style="item.conflict ? 'border-left: 3px solid red;' : ''"  @click="showForm(user, column, item)" v-show="(!showAssignmentCheckedOnly && !item.hide && item.visible) || (showAssignmentCheckedOnly && item.checked)" :key="user.user_id + ' ' + column.date + ' ' + item.id" :id="user.user_id + ' ' + column.date + ' ' + item.id">
                                <input v-if="showAssignmentChecked" type="checkbox" v-model="item.checked" @click="assingmentChecked(user, column.date, item)" :key="item.id">
                                <span class="book-crewview-item-time">{{item.startInterval}}</span>
                                <span class="book-crewview-item-time">{{item.endInterval}}</span>
                                

                            <span class="book-crewview-item-title">{{item.productionTitle}}</span>
                        </div>
                        <div class="book-crewview-item-plus" v-if="!(isSplitPage || isProblemPage) && !showAssignmentChecked">
                            <button class="btn btn-plus" @click="showForm(user, column)">
                                <font-awesome-icon icon="plus"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="book-crewview-no-results" v-if="!hasElements">
            <span>Choose a skill to get data</span>
        </div>
    </div>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewview {
    .panel > .book-crewview {
        margin-bottom: -1px;
    }
    .book-crewview {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: auto;
        .book-crewview-day-navigation,
        .book-crewview-content {
            min-width: 800px;
            width: 100%;
        }
        .book-crewview-day-navigation-split {
            // min-width: 800px;
            //width: calc(100% - 130px);
            display: flex;
            //top: 0 !important;
        }
        .book-crewview-content-split {
            //width: calc(100% - 130px);
        }
        .book-crewview-day-navigation-split > .book-crewview-row-content {
            width: calc(100% - 130px);
        }
        .book-crewview-day-navigation {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% - 30px);
                z-index: 1000;
            }
            .book-crewview-label,
            .book-crewview-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-crewview-column {
                padding: 2px 4px;
                // &.book-crewview-with-week {
                //     .book-crewview-day {
                //         padding-left: 25px;
                //     }
                // }
                &.book-crewview-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-crewview-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-crewview-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-crewview-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-crewview-week
            .book-crewview-weekday,
            .book-crewview-date {
                display: block;
            }
            .book-crewview-weekday {

            }
        }
        .book-crewview-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% / 12 * 5 - 31px);
                z-index: 1000;
                //top: 0;
            }
            .book-crewview-label {
                display: inline-block;
            }
            .book-crewview-label,
            .book-crewview-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-crewview-column {
                padding: 2px 4px;
                // &.book-crewview-with-week {
                //     .book-crewview-day {
                //         padding-left: 25px;
                //     }
                // }
                &.book-crewview-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-crewview-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-crewview-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-crewview-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-crewview-week
            .book-crewview-weekday,
            .book-crewview-date {
                display: block;
            }
            .book-crewview-weekday {

            }
        }
        .affix + .book-crewview-content {
            margin-top: 56px;
        }
        .book-crewview-content {
            .book-crewview-column {
                padding: 2px 0;
            }
        }
        .book-crewview-day-navigation,
        .book-crewview-row {
            display: flex;
        }
        .book-crewview-row {
            min-height: 38px;
            &:last-child {
                .book-crewview-label,
                .book-crewview-column:not(.book-crewview-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
                .book-crewview-column {
                    &.book-crewview-today {
                        border-bottom-width: 2px;
                    }
                }
            }
            &.book-crewview-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-crewview-label {
            // display: flex;
            display: block;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 130px;
            min-width: 130px;
            overflow: hidden;
            padding: 8px 5px 6px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 130px;
                min-width: 130px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-crewview-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-crewview-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-crewview-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                //margin-left: -1px;
                z-index: 2;
                // .book-crewview-week {
                //     bottom: -2px;
                // }
            }
        }
        .book-crewview-column-info-tools {
            display: inline-block;
            margin-right: 5px;
            width: 25px;
        }
        .book-crewview-label,
        .book-crewview-item {
            font-size: 12px;
            line-height: 1.3em;
        }
        .book-crewview-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-crewview-item-top-row {
            display: flex;
            font-size: 10px;
        }
        .book-crewview-item-flag,
        .book-crewview-item-league,
        .book-crewview-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-crewview-item-time,
        .book-crewview-item-league,
        .book-crewview-item-flag {
            display: inline-block;
        }
        .book-crewview-item-title {
            display: block;
        }
        .book-crewview-item-time {
            flex: 1 0 auto;
        }
        .book-crewview-item-flag {
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewview-item-league {
            flex: 30 1 auto;
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewview-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
        .book-crewview-item-plus {
            height: 20px;
            font-weight: bold;
            //padding: 5px 7px;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px 6px;
            text-align: center;
        }
        .btn-plus {
            // position: absolute;
            // bottom: 1px;
            padding: 0px;
            width: 100%;
        }
    }
}
</style>
