/**
 * This is a base mixin/component used by FeedEdit and ProductionEdit.
 */

import {app} from 'app'
import Lock from  'components/common/lock/Lock'

export default {
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            formData: this.defaultData(),
            loaded: false,
            submitted: false,
            asking: 0,
            now: null,
            insertForm: {
                show: false,
                loading: false,
                selection: null
            },

            // We "cache" the sport id here in order to avoid multiple loops
            // in this.entityItemIsVisible(), when checking if a sport should be
            // visible or not, agains the feed/production league. This is necessary
            // since sport id for feed/production is not stored in the database,
            // but is retrieved based on the stored league id.
            sportId: null,

        }
    },

    beforeRouteLeave(to, from, next) {
        const context = this

        // If the form has been submitted or isn't loaded yet, then we shouldn't
        // check for value changes at all.
        if (context.submitted || !context.loaded) {
            context.deleteLock()
            next()
            return
        }

        if (!context.child){
            next()
            return
        }
        
        // Check if the form has unsaved values and ask the user if the
        // changes should be saved.
        if (context[this.child.item].get && context.isChanged) {
            // No more ask more than 1 time. WN
            if (this.asking > 0) return next()
            this.asking = 1
            // WN
            swal({
                title: 'Save changes?',
                text: `You have made changes to this ${context.child.item}. Do you want to save them?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
            .then(function() {
                context.submit()
            }, function(dismiss) {
                if (dismiss == 'cancel') {
                    context.deleteLock()
                    next()
                }
            })
            .catch(() => {
                context.deleteLock()
                next()
            })
        }
        else {
            context.deleteLock()
            next()
        }

    },

    socket: {
        'private:items': {
            '.item.updated': function(payload, context) {
                if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                if (!payload.meta.type || payload.meta.type != context.child.item) return
                if (payload.data.id != context[context.child.item].get.id) return
                context[context.child.item].add(payload.data)
                context.setFormValues()
                context.$alert.set(`This ${context.child.item} has just been updated.`, 'success', 5)
            },
            '.item.deleted': function(payload, context) {
                if (!payload.meta.type || payload.meta.type != context.child.item) return
                if (payload.data.id != context[context.child.item].get.id) return
                swal({
                    title: `Deleted ${context.child.item}`,
                    text: `The current ${context.child.item} (${context.feed.get.id}) has been deleted.`,
                    type: 'error',
                    confirmButtonText: `Go to ${context.child.items} list`,
                })
                    .then(function(dismiss) {
                        context.redirect()
                    })
                    .catch(() => {
                        context.redirect()
                    })
            }
        },
        'private:updates': {
            '.updateEvents.sending': function(payload, context) {
                let data = JSON.parse(payload.data)
                //console.log(data, 'getting updates', payload, context)
                context.setUpdatesEditMixin(data)
            }
        },
        'private:signoffs': {
            '.signoff.created': function(payload, context) {
                context.signOff(payload, true)
            },
            '.signoff.deleted': function(payload, context) {
                context.signOff(payload, false)
            }
        },
        'private:locks': {
            '.lock.created': function(payload, context) {
                if ( (payload.data.itemType == context.child.item) && (payload.data.itemId == context[context.child.item].get.id) ) {
                    const lock = new Lock(payload.data.id)
                    lock.add(payload.data)
                    context[context.child.item].set('lock', Object.assign({}, lock.get))
                    context.setFormValues()
                }
            },
            '.lock.deleted': function(payload, context) {
                if ( Array.isArray(payload.data) && (payload.data[0].itemType != context.child.item) || (payload.data[0].itemId != context[context.child.item].get.id) ) return
                const previousLock = Object.assign({}, context[context.child.item].get.lock)
                const lock = new Lock()
                lock.add({
                    itemId: context[context.child.item].get.id,
                    itemType: context.child.item,
                })
                let title, text
                if (previousLock.user.id == app.$data.user.id) {
                    title = 'Continue edit?'
                    text = `You have been inactive for a long time. Do you want to continue to edit this ${context.child.item}?`
                }
                else {
                    title = `Edit this ${context.child.item}?`
                    text = `This ${context.child.item} has been unlocked and you are now able to edit it. Do you want to do it?`
                }
                swal({
                    title: title,
                    text: text,
                    type: 'question',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true
                })
                    .then(function() {
                        context.$loader.add('itemEditCreateLock')
                        lock.store()
                            .then(response => {
                                context[context.child.item].set('lock', response.data)
                                context.setFormValues()
                                if (context[context.child.item].get.lock.user.id != app.$data.user.id) {
                                    context.$alert.set(`It was not possible to assign this ${context.child.item} to you, as it has been assigned to <strong>${context[context.child.item].get.lock.user.name}</strong>.`, 'danger', 8)
                                }
                                else if ((previousLock.user.id != app.$data.user.id) && (context[context.child.item].get.lock.user.id == app.$data.user.id)) {
                                    context.$alert.set(`You are now able to edit this ${context.child.item}.`, 'success', 6)
                                }
                                context.$loader.remove('itemEditCreateLock')
                            })
                            .catch(error => {
                                context.$error.set(error)
                                context.$loader.remove('itemEditCreateLock')
                            })
                    })
            }
        },
        'private:equipment': {
            '.equipmentUsage.updated': function(payload, context) {
                console.log(payload, 'lets update see', context)
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)

                if (Array.isArray(payload.data)) {
                    app.$emit('changingEquipment', true, true)
                    app.$emit('changedEquipment', payload.data, true)
                } else {
                    // EquipmentUsage item update on the time-line page.
                    if (context.child.item === 'equipmentUsage') {
                        context.equipmentUsages.forEach(v => {
                            if (v.usageId === payload.data.equipmentUsage.usageId) {
                                app.$emit('changingEquipment', true, true)
                                app.$emit('changedEquipment', payload.data, true)
                            }
                        })
                    }

                }

            },
            '.equipmentUsage.created': function(payload, context) {
                console.log(payload, 'lets create see', context)
                if (Array.isArray(payload.data)) {
                    app.$emit('changingEquipment', true, true)
                    app.$emit('changedEquipment', payload.data, true)
                } else {
                    if (typeof payload.data.id === 'undefined'  && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)

                    // EquipmentUsage item creation on the time-line page.
                    if (context.child.item === 'equipmentUsage') {
                        context.equipmentUsages.forEach(v => {
                            if (v.usageId === payload.data.equipmentUsage.usageId) {
                                app.$emit('changingEquipment', true, true)
                                app.$emit('changedEquipment', payload.data, true)
                            }
                        })

                    }
                }

            },
            '.equipmentUsage.deleted': function(payload, context) {
                console.log(payload, 'lets see DELETE', context)
                if (Array.isArray(payload.data)) {
                    app.$emit('changingEquipment', true, true)
                    app.$emit('changedEquipment', payload.data, true)

                } else {
                    if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                    // EquipmentUsage item delete on the time-line page.
                    if (context.child.item === 'equipmentUsage') {
                        context.equipmentUsages.forEach(v => {
                            if (v.usageId === payload.data.equipmentUsage.usageId) {
                                app.$emit('changingEquipment', true, true)
                                app.$emit('changedEquipment', payload.data, true)
                            }
                        })
                    }

                }
            }
        },
        'private:feedbacks': {
            '.feedback.created': function(payload, context) {
                console.log(payload, 'lets create see', context)
                if (Array.isArray(payload.data)) {
                    //app.$emit('creatingFeedback', true, true)
                    app.$emit('creatingFeedback', payload.data)
                    //context.updateFeedback(payload.data, 'create')
                 } else {
                    if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                    app.$emit('creatingFeedback', payload.data)
                 }
            },
            '.feedback.updated': function(payload, context) {
                console.log(payload, 'lets create see', context)
                if (Array.isArray(payload.data)) {
                    //app.$emit('creatingFeedback', true, true)
                    app.$emit('updatingFeedback', payload.data)
                    //context.updateFeedback(payload.data, 'create')
                 } else {
                    if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                    app.$emit('updatingFeedback', payload.data)
                 }
            }
        }
     },

    mounted() {
        this.setNow()
        // Listen to ingestion updates.
        app.$on('changedIngestions', (data) => {
            this.updateIngestions(data)
        })
        app.$on('creatingFeedback', (data) => {
            this.updateFeedback(data, 'create')
        })
        app.$on('updatingFeedback', (data) => {
            this.updateFeedback(data, 'update')
        })
    },

    methods: {
        setUpdatesEditMixin(data) {
            app.$emit('catchUpdates', data)
        },
        /**
         * Sign off the current feed based on the passed payload.
         * @param object payload The payload returned by Laravel Echo.
         * @param boolean create Flag that tell the function to create/remove
         *                       a signoff. 'true' for create and 'false' for remove.
         */
        signOff(payload, create = true) {
            const context = this
            if (payload.data.type != 'feed' || payload.data.itemId != context.feed.get.id) return

            // Clone the current signoffs.
            const signoffs = context[this.child.item].get.signoffs.slice(0)

            let i = signoffs.findIndex(v => payload.data.typeId == v.typeId)
            if (i > -1) {
                let signed, msg
                if (create) {
                    signed = Object.assign({}, payload.data)
                    this.$alert.set(`This ${this.child.item} has just been signed off by ${payload.author}.`, 'success', 10)
                }
                else {
                    signed = null
                    this.$alert.set(`The sign off has just been removed by ${payload.author}.`, 'danger', 10)
                }
                signoffs[i].signed = signed
                context[this.child.item].set('signoffs', signoffs)
                context.setFormValues()
            }
        },

        /**
         * Reset some values for the current loaded item, because it's being
         * cloned into a new one.
         * @param integer id The id of the item to clone.
         */
        setClonedValues(id) {
            const context = this
            const content = this.$store.state.data[this.child.item].find(v => v.id == id)

            // Remove values that shouldn't be cloned.
            content.signoffs.forEach(v => {
                v.signed = null
            })
            content.equipment = []

            content.cloneOf = parseInt(context.$route.query.clone)
            context[this.child.item].add(content)
        },

        /**
         * Copy data from a chosen item and put it into the current form.
         */
        insertItemData() {
            const context = this
            context.setClonedValues(context.insertForm.selection)
            context.setFormValues()
            context.insertForm.show = false
        },

        /**
         * Try to delete the lock for the current feed.
         */
        deleteLock() {
            const context = this
            if (!context.child) return
            if (!context[context.child.item].get) return
            if (!context[context.child.item].get.lock || app.$data.user.get.id != context[context.child.item].get.lock.user.id) return
            let lockId = context[context.child.item].get ? context[context.child.item].get.lock.id : null
            const lock = new Lock(lockId)
            if (lock) {
                lock.delete()
            }
        },

        /**
         * Callback used when event "ingestionUpdated" is triggered.
         * @param object data The data (incl ingestion items) returned by the event.
         */
        updateIngestions(data) {
            if (data.itemType != this.child.item) return
            const itemClass = this.getNewClass(data.itemId)
            itemClass.set('ingestions', data.items)
            this.formData.ingestions.value = data.items
        },

        updateFeedback(data, event) {
            if(this.child.item == data.feedbackItemType && this.id == data.feedbackItemId) {
            let feedbackItem = this.getNewClass(data.feedbackItemId)
            let items = feedbackItem.get.feedbacks ? feedbackItem.get.feedbacks.slice() : []
            let item = _.find(items, i => i.id === data.id)
            if(event === 'create' || items.length === 0 || !item) {
                items.push({
                    id: data.id,
                    type: data.isErrorReport ? ( data.feedback && data.feedback !== '' ? 'Feedback & Report' : 'Report' ) : 'Feedback',
                    author: data.author,
                    readByAdmin: data.readByAdmin
                })
            }
            else {
                item.type = data.isErrorReport ? ( data.feedback && data.feedback !== '' ? 'Feedback & Report' : 'Report' ) : 'Feedback'
                item.readByAdmin = data.readByAdmin
            }
            feedbackItem.set('feedbacks', items)
            this.formData.feedbacks.value = feedbackItem.get.feedbacks
        }
            //if (notify) this.$alert.set('The feedbacks list was updated', 'success', 3)
        },

        /**
         * Lock the current form based on events from BookLock.
         * @param integer id The item id sent by BookLock.
         * @return boolean
         */
        lock(id) {
            if (id == this.id) this.formData.locked.value = true
            else this.formData.locked.value = false
        },

        // BookUpload / Dropzone.

        uploadSuccess(response) {
            if (response.id) {
                this.formData.documents.value.push(response)
            }
        },
        uploadQueueComplete() {
            this.$alert.set('All files were uploaded!', 'success', 3)
        },
        uploadRemovedFile(file, error, xhr) {
            let i = this.formData.documents.value.findIndex(v => v.id == file.id)
            if (i > -1) {
                this.formData.documents.value.splice(i, 1)
            }
            this.$alert.set('The file was removed!', 'success', 3)
        },
        uploadError(file, message, xhr) {
            this.$error.set(message)
        },
        // closing and opening new tab relations with Production Edit page
        // always query with newBar to get it
        getTabRoute() {
            return this.$route.query.newBar;
        },
        openNewTab(link) {
            const context = this

            var link_ = {
                destination: this.$route.name,
                view: this.$route.params.view,
                from: this.$route.query.from,
                to: this.$route.query.to,
                newBar: link.params.id
            }
            const toLink = Object.entries(link_).map(([key, value]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }).join('&');

            let _eventId
            let _link

            if (link.name === 'production.edit') {
                // get the opened page name so will be easier to work around
                _eventId = link.params.id
                 _link = `/production/${_eventId}?${toLink}`
                window.open(_link, "_blank");
            }
            if (link.name === 'feed.edit') {
                // get the opened page name so will be easier to work around
                _eventId = link.params.id
                _link = `/feed/${_eventId}?${toLink}`
                window.open(_link, "_blank");
            }

            if ((['production.edit', 'feed.edit']).indexOf(link.name) === -1) {
                return context.$router.push(link)
            }
        },
        closeNewTab() {
            window.top.close();
        }

    }

}
