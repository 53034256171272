
// import {app} from 'app'
// import store from 'store'

import Api from 'components/app/Api'

class Contract extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/contract',
            module: 'contract',
        })
    }


    // @TODO Remove...
    // /**
    //  * Constructor.
    //  * @param object data The contract data to create an contract instance with.
    //  */
    // constructor(data = null) {
    //     if (data) {
    //         this.id = data.id
    //         if (data.id == 0) {
    //             store.commit('contract/add', data)
    //         }
    //     }
    // }
    //
    // /**
    //  * Return data for the current instance.
    //  * @return object
    //  */
    // get get() {
    //     let i = this.getIndex()
    //     return i > -1 ? store.state.contract.data[i] : null
    // }
    //
    // /**
    //  * Save the entity model into the API.
    //  * @return promise
    //  */
    // save() {
    //     const context = this
    //     let id = this.id
    //     return new Promise((resolve, reject) => {
    //         axios.put('api/contract/' + id, store.state.contract.data[context.getIndex()])
    //         .then(response => {
    //             resolve(response)
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    // }
    //
    // /**
    //  * Create a new contract.
    //  * @return promise
    //  */
    // store() {
    //     const context = this
    //     let promise = new Promise((resolve, reject) => {
    //         axios.post('api/contract', store.state.contract.data[context.getIndex()])
    //         .then(response => {
    //             store.commit('contract/remove', 0)
    //             store.commit('contract/add', response.data)
    //             resolve(response)
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    //     return promise
    // }
    //
    // /**
    //  * Set a value to a given field on the contract model.
    //  * @param string field The name  of the user field.
    //  * @param string value The value to set.
    //  */
    // set(field, value) {
    //     store.commit('contract/setField', {id: this.id, field: field, value: value})
    // }
    //
    // /**
    //  * Get the index of the current contract instance inside the store object.
    //  * @return integer
    //  */
    // getIndex() {
    //     const context = this
    //     return store.state.contract.data.findIndex(v => v.id === context.id)
    // }
    //
    // // -------------------------------------------------------------------------
    // // Static methods.
    //
    // /**
    //  * Get all available contracts from the API.
    //  * @return promise
    //  */
    // static getAll() {
    //     return new Promise((resolve, reject) => {
    //         axios.get('api/contract')
    //         .then(response => {
    //             if (response.status === 200) {
    //                 store.commit('contract/setData', response.data)
    //             }
    //             resolve(response)
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    // }
    //
    // /**
    //  * Load a contract from the API.
    //  * @param integer id The contract id to load.
    //  * @return promise
    //  */
    // static load(id) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('api/contract/' + id)
    //         .then(response => {
    //             store.commit('contract/add', response.data)
    //             resolve(response)
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    // }
    //
    // /**
    //  * Delete a contract.
    //  * @param integer id The contract id.
    //  * @return promise
    //  */
    // static delete(id) {
    //     return new Promise((resolve, reject) => {
    //         axios.delete('api/contract/' + id)
    //         .then(response => {
    //             if (response.status === 200) {
    //                 store.commit('contract/remove', id)
    //             }
    //             resolve(response)
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    // }



}

export default Contract
