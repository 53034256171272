<script>
import {app} from 'app'
import User from 'components/user/User'
import Acl from 'components/user/Acl'
import System from 'components/system/System'
import BookUserActionMenu from 'components/user/BookUserActionMenu';
import BookToggle from 'components/common/BookToggle'
import BookLoader from 'components/common/loader/BookLoader'
import Calendar from 'components/calendar/Calendar'
import BookNotification from 'components/notification/BookNotification'

export default {
    components: {
        BookUserActionMenu,
        BookToggle,
        BookLoader,
        BookNotification
    },
    props: {
        id: {
            type: Number,
        }
    },
    mounted() {
        Acl.getRoles()
            .then(response => {
                this.loadUser(this.id)
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the available roles.')
            })
    },
    watch: {
        'id': function(newValue) {
            this.loadUser(newValue)
        }
    },
    data() {
        return {
            user: null,
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
            },
        }
    },
    computed: {
        system() {
            return System
        },
        availableRoles() {
            return this.$store.state.user.roles
        },
        currentName() {
            if (!this.user) {
                return ''
            }
            return this.user.get.firstname && this.user.get.lastname
            ? this.user.get.firstname + ' ' + this.user.get.lastname : this.user.get.name
        },
        isCurrentUser() {
            if (this.id != app.$data.user.id) return false
            return true
        },
        currentUser() {
            return app.$data.user
        },
        privateNumberIsVisible() {
            return this.user.get.phonePrivate && (this.isCurrentUser || this.$ability.can('view_private_phone', 'Users'))
        },
        extraDataIsVisible() {
            return (this.isCurrentUser || this.$ability.can('update', 'Users'))
        }
    },
    methods: {
        loadUser(uid) {
            this.user = null
            return new Promise((resolve, reject) => {
                if (this.isCurrentUser) {
                    this.user = app.$data.user
                    resolve()
                }
                else {
                  User.load(uid)
                  .then(response => {
                      this.user = new User(response.data)
                      resolve()
                  })
                  .catch(error => {
                      this.$error.set(error, 'It was not possible to load the user.')
                      reject(error)
                  })
                }
            })
        },

        /**
         * Download the current user's calendar, from now and forward.
         */
        downloadCalendar() {
            const now = moment()
            const params = {
                user: this.id,
                start: now.format('YYYY-MM-DD'),
                end: 0,
            }
            swal({
                title: 'Download calendar?',
                text: `Do you want to download ${this.user.get.fullName}'s entire calendar from ${now.format('MMM Do')} and forward?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(() => {
                    Calendar.download(params)
                        .catch(error => {
                            this.$error.set(error)
                        })
                })
        },

        submit() {
            let user = this.user
            user.set('active', !this.user.get.active)
            user.save()
                .then(response => {
                    user = response.data
                })
                .catch(error => {
                    this.$error.set(error)
                })
        },

        showNotificationModal(user) {
            this.notification.recipients = []

            this.notification.recipients.push({
                status: user.get.status,
                email: user.get.email,
                phone: user.get.phone,
                id: user.get.id,
                label: user.get.name,
            })

            this.notification.modalTitle = `Contact ${user.get.name}`

            this.notification.show = true
        },

    }
}

</script>

<template>
<div id="user-profile" class="container book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix" v-if="user">
        <h2 class="page-title">{{currentName}}</h2>
        <book-user-action-menu></book-user-action-menu>
    </div>
    <book-loader v-if="!user"></book-loader>
    <div v-if="user" class="profile-view-container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <div class="profile-photo-container">
                        <img class="img-thumbnail" src="~assets/profile-placeholder.jpg" />
                        <br/>
                        <div class="activate-button-wrapper">
                            <book-toggle :status="user.get.active" @toggle="submit()"  :toggle-disabled="$ability.cannot('update', 'Users') || isCurrentUser">
                                <span slot="on">active</span>
                                <span slot="off">inactive</span>
                            </book-toggle>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-8 col-lg-8">
                    <div class="panel panel-info book-right-column">
                        <div class="panel-body form-horizontal no-margin">
                            <div class="form-group">
                                <label class="col-sm-4 control-label">ID</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.id}}</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">Username</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.name}}</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">E-mail</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static"><a :href="'mailto:' + user.get.email">{{user.get.email}}</a></p>
                                </div>
                            </div>
                            <div class="form-group" v-if="user.get.firstname">
                                <label class="col-sm-4 control-label">Firstname</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.firstname}}</p>
                                </div>
                            </div>
                            <div class="form-group" v-if="user.get.lastname">
                                <label class="col-sm-4 control-label">Lastname</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.lastname}}</p>
                                </div>
                            </div>
                            <div class="form-group" v-if="user.get.phone">
                                <label class="col-sm-4 control-label">Public phone</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static"><a :href="'tel:' + user.get.phone">{{user.get.phone}}</a></p>
                                </div>
                            </div>
                            <div class="form-group" v-if="privateNumberIsVisible">
                                <label class="col-sm-4 control-label">Private phone</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static"><a :href="'tel:' + user.get.phonePrivate">{{user.get.phonePrivate}}</a></p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">Country</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{system.getCountryById(user.get.country)}}</p>
                                </div>
                            </div>
                            <div class="form-group" v-if="extraDataIsVisible">
                                <label class="col-sm-4 control-label">Role(s)</label>
                                <div class="col-sm-8" v-if="user.get.roles && Object.keys(user.get.roles).length > 0">
                                    <p class="form-control-static">
                                        <span v-for="(role, key, index) in user.get.roles">
                                            {{availableRoles[key] ? availableRoles[key].label : ""}}<span v-if="index+1 < user.get.roles.length">, </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group" v-if="extraDataIsVisible">
                                <label class="col-sm-4 control-label">Login count</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.loginCount}}</p>
                                </div>
                            </div>
                            <div class="form-group" v-if="extraDataIsVisible">
                                <label class="col-sm-4 control-label">Last login</label>
                                <div class="col-sm-8">
                                    <p class="form-control-static">{{user.get.lastLogin}}</p>
                                </div>
                            </div>
                            <button
                                class="btn btn-info btn-xs"
                                title="Contact"
                                @click="showNotificationModal(user)"
                                v-if="$ability.can('send_notifications', 'Miscellaneous')">
                                <font-awesome-icon icon="envelope"/>
                            </button>
                                <!-- <tr>
                                    <td class="book-label"><strong>Calendar</strong></td>
                                    <td>
                                        <button class="btn btn-primary btn-xs" @click="downloadCalendar()">
                                            <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download calendar
                                        </button>
                                    </td>
                                </tr> -->
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <book-notification
        v-if="notification.show"
        componentId="bookSidebarProduction"
        :modalTitle="notification.modalTitle"
        :recipients="notification.recipients"
        @close="notification.show = false">
    </book-notification>
</div>
</template>
