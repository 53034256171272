<template>
  <div>
    <div v-if="setLoaded" >
      <div class="row" v-if="isEdit">
        <div v-if="audioText !== 'Not Set'" class="col-lg-12">
          {{audioText}}
          <br>
          <label for="name" class="control-label control-label-100">
            <span class="label label-danger">
              <font-awesome-icon icon="exclamation-triangle" class="book-selectbox-item-icon" aria-hidden="true"/>
              Please pick the correct values instead of free text.</span>
          </label>
        </div>
        <div v-for="(row, r) in channelsArr" :key="r" class="form-group form-group-sm">
          <div v-for="(channel, c) in row" :key="c" :class="prClass ? 'col-lg-12' : (c==0 ? 'col-sm-6 channel-left': 'col-sm-6 channel-right') ">
            <div :class="prClass ? 'col-lg-6 col-sm-12 col-xs-12 audio-label' : 'col-sm-6 col-xs-12 audio-label'">
              <label for="audioLayout" class="col-sm-12 control-label pair-label">
                Audio Pair {{channel.pairNumber}} ({{channel.pair[0].key.slice(3)}} & {{channel.pair[1].key.slice(3)}})
              </label>
              <div class="radio-inline" v-for="i in ['stereo', 'mono']" :key="i">
                <label>
                  <input type="radio" v-model="channel.stereo" @change="switchStereo(r, c, channel.stereo)" :disabled="disabled" :value="i"/>
                  <span>{{i.charAt(0).toUpperCase() + i.slice(1)}}</span>
                </label>
              </div>
            </div>
            <div :class="prClass ? 'col-lg-6 col-sm-12 col-xs-12 audio-pair' : 'col-sm-6 col-xs-12 audio-pair'" name="audioLayout" >
              <div :class="channel.stereo === 'mono' ? 'col-sm-6 col-xs-6 mono-left' : 'col-sm-12'">
                <select class="form-control" name="audioLayout0" @change="onChannelChanged(channel.pair[0].key, r, c, 0, $event.target.value)" :disabled="disabled">
                  <option value="" :selected="channel.pair[0].value === ''"></option>
                  <option v-for="audioLayout in audioLayoutsItems" :value="audioLayout.id" :key="audioLayout.id" :selected="channel.pair[0].value === audioLayout.id">{{ audioLayout.label }}</option>
                </select>
              </div>
              <div v-if="channel.stereo === 'mono'" class="col-sm-6 col-xs-6 mono-right">
                <select class="form-control" name="audioLayout1" @change="onChannelChanged(channel.pair[1].key, r, c, 1, $event.target.value)" :disabled="disabled">
                  <option value="" :selected="channel.pair[1].value === ''"></option>
                  <option v-for="audioLayout in audioLayoutsItems" :value="audioLayout.id" :key="audioLayout.id" :selected="channel.pair[1].value === audioLayout.id">{{ audioLayout.label }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="printArr.length > 0 && !isPrint">
        <div :class="i === 0 ? 'book-sidebar-table-row' : 'book-sidebar-table-row border-channel'" v-for="(channel, i) in printArr" :key="channel.key">
          <div class="book-sidebar-table-row-nowrap book-width-40-percents">{{channel.key}}</div>
          <div>{{channel.value}}</div>
        </div>
      </div>
      <div v-else-if="printArr.length > 0 && isPrint">
        <table class="table">
          <tbody>
          <tr v-for="channel in printArr" :key="channel.key">
            <td>{{channel.key}}</td>
            <td class="text-right">{{channel.value}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        {{ audioText }}
      </div>
    </div>
  </div>
</template>

<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'

export default {
  props: {
    value: {
      type: String
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    audioLayoutsEntity: {
      type: Object,
      default: null
    },
    itemType: {
      type: String
    } //Either feed or production
  },
  mounted() {
    let filtervalue = '';
    switch(this.itemType) {
      case 'feed':
        filtervalue = 'feed';
        break;
      case 'production':
        filtervalue = 'production';
        break;
      default:
        filtervalue = '';
    }
    this.audioLayoutsItems = this.audioLayoutsEntity.get.items ? this.audioLayoutsEntity.get.items.filter(i => i.userfield1_value.split(',').includes(filtervalue)) : [];
    window.addEventListener('resize', this.changeClassesOnResize);
    this.changeClassesOnResize();
    this.transformProps();
  },
  data() {
    return {
      audioLayoutsItems: [],
      channels: { },
      channelsTemplate: {
        'Ch 1': '',
        'Ch 2': '',
        'Ch 3': '',
        'Ch 4': '',
        'Ch 5': '',
        'Ch 6': '',
        'Ch 7': '',
        'Ch 8': '',
        'Ch 9': '',
        'Ch 10': '',
        'Ch 11': '',
        'Ch 12': '',
        'Ch 13': '',
        'Ch 14': '',
        'Ch 15': '',
        'Ch 16': ''
      },
      channelsTemplatePrint: {
        'PAIR 1': ['Ch 1', 'Ch 2'],
        'PAIR 2': ['Ch 3', 'Ch 4'],
        'PAIR 3': ['Ch 5', 'Ch 6'],
        'PAIR 4': ['Ch 7', 'Ch 8'],
        'PAIR 5': ['Ch 9', 'Ch 10'],
        'PAIR 6': ['Ch 11', 'Ch 12'],
        'PAIR 7': ['Ch 13', 'Ch 14'],
        'PAIR 8': ['Ch 15', 'Ch 16']
      },
      channelsArr: [],
      audioText: 'Not Set',
      printArr: [],
      isLoaded: false,
      isInternalChanges: false,
      prClass: false
    }
  },
  methods: {
    onChannelChanged(key, rowIndex, columnIndex, pairIndex, eventValue){
      this.isInternalChanges = true;

      this.channels[key] = eventValue === '' ? '' : Number(eventValue);
      this.channelsArr[rowIndex][columnIndex].pair[pairIndex].value = Number(eventValue);

      if(pairIndex === 0 && this.channelsArr[rowIndex][columnIndex].stereo === 'stereo') {
        this.channels[this.channelsArr[rowIndex][columnIndex].pair[1].key] = eventValue === '' ? '' : Number(eventValue);
        this.channelsArr[rowIndex][columnIndex].pair[1].value = Number(eventValue);
      }

      let newLabel = this.getLabelById(eventValue);
      this.channelsArr[rowIndex][columnIndex].pair[pairIndex].label = newLabel;

      let tmp = _.pickBy(this.channels, _.isNumber);

      this.value = _.isEmpty(tmp) ? '' : JSON.stringify(tmp);
      this.$emit('input', this.value);
    },
    transformProps() {
      const self = this;
      if (self.itemType === 'feed' || self.itemType ==='production') {

        self.channelsArr = [];
        self.printArr = [];
        self.audioText = 'Not Set';
        self.channels = _.clone(self.channelsTemplate);
        let objProps = {};
        if( self.value ){
          try {
            objProps = JSON.parse(self.value);
          }
          catch (e) {
            console.log('[BookAudioLayoutEdit.vue]: Cannot parse prop. Will return predefined object', e);
            self.audioText = self.value;
          }
        }

        if(self.isEdit) {
          let columnIndex = 0;
          let pairIndex = 0;
          let row = [];
          let pair = [];
          let pairNumber = 1;
          for(let[key, value] of Object.entries(self.channels)) {
            let val = '';
            let label = '';
            if(!_.isEmpty(objProps) && _.has(objProps, key)) {
              val = Number(objProps[key]);
              label = self.getLabelById(val);
              self.channels[key] = val;
            }
            //row.push({'key': key, 'value': val, 'label': label});
            pair.push({'key': key, 'value': val, 'label': label});
            if(pairIndex === 1) {
              let stereo = pair[0].value === pair[1].value ? (/*pair[0].value === '' ? (self.itemType === 'feed' ? 'mono' : 'stereo') :*/ 'stereo') : 'mono'
              row.push({'stereo': stereo, 'pairNumber': pairNumber, 'pair': pair})
              pairNumber++
              pair = []
            }
            pairIndex = pairIndex === 0 ? 1 : 0

            if(columnIndex === 3) {
              self.channelsArr.push(row);
              row = []
            }
            columnIndex = columnIndex === 3 ? 0 : columnIndex + 1
          }
        }
        else if(!_.isEmpty(objProps)){
          for(let[key, value] of Object.entries(self.channelsTemplatePrint)) {
            let firstInPairVal = ''
            let firstInPair = ''
            let secondInPairVal = ''
            let secondInPair = ''

            if(_.has(objProps, value[0])) {
              firstInPairVal = _.get(objProps, value[0])
              firstInPair = self.getLabelById(firstInPairVal)
            }

            if(_.has(objProps, value[1])) {
              secondInPairVal = _.get(objProps, value[1])
              secondInPair = self.getLabelById(secondInPairVal)
            }

            if(firstInPairVal && firstInPairVal !== '' || secondInPairVal && secondInPairVal !== '') {
              if(firstInPairVal === secondInPairVal) {
                self.printArr.push({'key': `${key} (Stereo)`, 'value': firstInPair});
              }
              else {
                if(firstInPairVal !== '') {
                  self.printArr.push({'key': `${value[0]} (Mono)`, 'value': firstInPair});
                }

                if(secondInPairVal !== '') {
                  self.printArr.push({'key': `${value[1]} (Mono)`, 'value': secondInPair});
                }

              }
            }
          }

          /*_.forIn(objProps, function(value, key) {
              let label = self.getLabelById(value);
              self.printArr.push({'key': key, 'value': label});
          });
          if(self.printArr.length > 0) {
              //self.audioText = printArr.join(' ');
              _.orderBy(self.printArr, ['key'], ['asc']);
          }*/
        }

        self.isLoaded = true;
      }
    },
    getLabelById(id) {
      let label = '';
      let tmp = _.find(this.audioLayoutsItems, ['id', Number(id)]);
      if(tmp !== undefined) { label = tmp.label; }
      return label;
    },
    switchStereo(rowIndex, columnIndex, value) {
      if(value === 'stereo') {
        this.onChannelChanged(this.channelsArr[rowIndex][columnIndex].pair[1].key, rowIndex, columnIndex, 1, this.channelsArr[rowIndex][columnIndex].pair[0].value)
      }
    },
    changeClassesOnResize() {
      if(window.innerWidth >= 992 && window.innerWidth <= 1464 & this.itemType === 'production') {
        this.prClass = true
      }
      else {
        this.prClass = false
      }
    }
  },
  computed: {
    setLoaded() {
      if(this.isLoaded) {
        return true;
      }
    }
  },
  watch: {
    value(newVal, oldVal) {
      let self = this;
      if ( oldVal != newVal && !self.isInternalChanges ) {
        self.transformProps();
      }
      self.isInternalChanges = false;
    }
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.col-sm-6.channel-left {
  padding-right: 4px;
}
.col-sm-6.channel-right {
  padding-left: 4px;
}

.col-sm-6.channel-left,
.col-sm-6.channel-right {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.book-sidebar-table-row.border-channel {
  border-top: 1px solid $color-grey-border;
}
.col-sm-12.audio-label,
.col-sm-6.audio-label {
  padding-left: 0px;
  padding-right: 2px;
  font-size: 10px;
  font-weight: normal;
  .col-sm-12 {
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .control-label {
    text-align: left;
    font-size: 10px;
    padding-top: 0px;
  }

  .control-label.pair-label {
    font-size: 10px;
    padding-top: 0px;
  }

  overflow: hidden;

  .radio-inline {
    padding-left: 0px;
    padding-top: 0px;


    input[type="radio"] {
      margin-left: 0px;
      margin-top: 0px;
    }

    span {
      margin-left: 15px;
      font-weight: normal;
      font-size: 8px;
    }
  }

  .radio-inline + .radio-inline {
    margin-left: 2px;
  }

}

.col-sm-12.audio-pair,
.col-sm-6.audio-pair {
  padding-left: 0px;
  padding-right: 0px;

  .col-sm-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  select {
    font-size: 10px;
    padding: 0px;
  }

  .col-sm-6.mono-left {
    padding-left: 0px;
    padding-right: 1px;
  }
  .col-sm-6.mono-right {
    padding-left: 1px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .col-sm-6.channel-left {
    padding-right:15px;

  }
  .col-sm-6.channel-right {
    padding-top: 8px;
    padding-left:15px;


  }

  .col-sm-6.audio-label {
    .radio-inline {
      padding-top: 3px;
      span {
        font-size: 10px;
      }
    }

    .control-label.pair-label {
      padding-top: 8px;
    }

    .control-label {
      font-size: 12px;
    }

    .control-label.pair-label {
      font-size: 12px;
    }
  }

  .col-sm-6.audio-pair {
    select {
      font-size: 12px;
      padding: 0px;
    }
  }
}

@media (min-width: 1300px) {
  .col-sm-6.channel-left,
  .col-sm-6.channel-right {
    .col-sm-6.audio-label {
      width: 105px;
    }

    .col-sm-6.audio-pair {
      width: calc(100% - 105px);
    }

  }

  .col-lg-6.audio-label {
    width: 105px;
  }

  .col-lg-6.audio-pair {
    width: calc(100% - 105px);
  }

}

.book-sidebar-table-row-nowrap.book-width-40-percents {
  width: 40%;
}
.book-width-60-percents {
  width: 60%;
}
</style>