import store from 'store'
class Loader {
    static add(value) {
        store.commit('headerLoader/add', value)
    }
    static remove(value) {
        store.commit('headerLoader/remove', value)
    }
    static has(value) {
        if (Array.isArray(value)) {
            return value.some(v => {
                return store.state.headerLoader.loading.indexOf(v) > -1
            })
        }
        return store.state.headerLoader.loading.indexOf(value) > -1
    }
}
export default Loader
