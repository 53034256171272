/**
 * This mixin contains "common features" used in different components to handle
 * item flags, ex in BookTable component.
 */

import {app} from 'app'

const ItemFlagsMixin = {
    data() {
        return {
            changedItems: {},
            changedItemsClasses: {
                locked: 'label-danger',
                updated: 'label-primary',
                new: 'label-info'
            },
        }
    },
    mounted() {
        if (this.$route.path.indexOf('equipment-usage') < 0) {
            this.$root.$on('itemCreated', this.itemCreated)
            this.$root.$on('itemUpdated', this.itemUpdated)
            this.$root.$on('itemLocked', this.itemLocked)
            this.$root.$on('itemUnlocked', this.itemUnlocked)
        }
    },
    methods: {

        /**
         * Show the new marker on an item that has been created.
         * @param object item The item that has been added.
         */
        itemCreated(item) {
            item.action = 'new'
            this.addFlag(item)
            if (!item.persistent) {
                setTimeout(() => {
                    this.removeFlag(item)
                }, app.$store.state.config.ITEM_FLAG.NEW_VISIBILITY * 1000)
            }
        },

        /**
         * Show the update marker on an item that has been updated.
         * @param object item The item that has been updated.
         */
        itemUpdated(item) {
            item.action = 'updated';
            this.addFlag(item)
            setTimeout(() => {
                this.removeFlag(item)
            }, app.$store.state.config.ITEM_FLAG.UPDATE_VISIBILITY * 1000)
        },

        /**
         * Show the locked marker on an item that has been opened by another user.
         * @param object item The item that has been locked.
         */
        itemLocked(item) {
            item.action = 'locked';
            this.addFlag(item)
        },

        itemUnlocked(item) {

            // The action flag is actually called "locked" and we want to
            // remove it, why we need to change the action name here.
            item.action = 'locked'

            this.removeFlag(item)
        },

        /**
         * Add a flag to an item.
         * @param object item The item to add a flag to.
         */
        addFlag(item) {

            //log(item)

            if (!this.changedItems.hasOwnProperty(item.id)) {
                Vue.set(this.changedItems, item.id, Object.assign({flags: []}, item))
            }
            let i = this.changedItems[item.id].flags.findIndex(v => v == item.action)
            if (i == -1) {
                this.changedItems[item.id].author = item.author
                this.changedItems[item.id].flags.push(item.action)
            }
        },

        /**
         * Remove a flag from an item.
         * @param object item The item to remove a flag from.
         */
        removeFlag(item) {
            if (!this.changedItems[item.id] || !this.changedItems[item.id].hasOwnProperty('flags')) return
            let i = this.changedItems[item.id].flags.findIndex(v => v == item.action)
            if (i > -1) this.changedItems[item.id].flags.splice(i, 1)
        },

    }
}

export default ItemFlagsMixin
