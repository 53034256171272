<script>
import {app} from 'app'
import BookModal from 'components/common/BookModal'
import BookDatetime from 'components/common/BookDatetime'
import McrComment from "components/mcr/McrComment/McrComment";

export default {
  components: {
    BookModal,
    BookDatetime,
  },
  props: {
    item: {
      default: () => ({})
    },
    darkMode: {
      default: false
    }
  },
  data() {
    return {
      formData: this.defaultData(),
      addForm: {
        show: false,
        edit: false,
        commenter: null,
      },
      loaded: false,
    }
  },
  watch: {
    'formData.periodStart.value': function(newValue) {
      this.formData.periodEnd.value = newValue
    },
  },
  computed: {
    periodStartDateLimit() {
      let now = moment(this.$route.query.from, 'YYYY-MM-DD')
      return [{
        type: 'fromto',
        from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
      }]
    },

    processing() {
      return this.$loader.has('BookMCRCommentSubmitting')
    },
    isFormChanged() {
      if(this.addForm.edit) {
        if(this.addForm.commenter.country !== this.formData.country.value) return true
        if(this.addForm.commenter.comment !== this.formData.comment.value) return true
        if(this.addForm.commenter.periodStart !== this.formData.periodStart.value) return true
        if(this.addForm.commenter.periodEnd !== this.formData.periodEnd.value) return true
      }
      else {
        if(this.formData.country.value || this.formData.comment.value ||
            this.formData.periodStart.value || this.formData.periodEnd.value || this.formData.submit.value) return true
      }
      return false
    },
    isFormValid() {
      if(!this.formData.country.value || this.formData.country.value === '') return false
      if(!this.formData.comment.value || this.formData.comment.value === '') return false
      if(!this.formData.periodStart.value || this.formData.periodStart.value === '') return false
      if(!this.formData.periodEnd.value || this.formData.periodEnd.value === '') return false
      return true
    },
    countries() {
      return this.$store.state.system.countries
    },

  },

  mounted() {
    this.showForm(true)
  },
  methods: {
    userCountry() {
      return this.allCountries(app.$data.user.get.country)
    },
    allCountries(select = null) {
       let _all = [], _codes = []
       Lazy(this.countries).each(v => { _all.push(v); _codes.push(v.code) })
       _all.push({ code: _codes.join(','), label:"All Countries", group: 0 })

      if (select) {
        let ci = this.countries.findIndex(c => c.code === select)
        if (ci > -1) return this.countries[ci]
      } else return _all
    },
    submit() {
      const context = this

      let id = context.addForm.edit ? context.formData.id.value : 0
      let MCRComment = new McrComment(id)

      if (context.item && context.item.elementType !== 'mcrComment') {
        MCRComment.set('forId', context.item.id)
        MCRComment.set('forType', context.item.elementType)
      }

      let _country = context.formData.country.value
      if (Array.isArray(_country)) {
        if (_country.length > 1) _country = _country.join(',')
        if (_country.length === 1) _country = _country[0]
      }

      MCRComment.set('country', _country)
      MCRComment.set('comment', context.formData.comment.value)
      MCRComment.set('mcrCommentStart', context.formData.periodStart.value)
      MCRComment.set('mcrCommentEnd', context.formData.periodEnd.value)

      context.$loader.add('BookMCRCommentSubmitting')

      if(!context.addForm.edit) {
        MCRComment.store()
            .then(response => {
              this.sendAction('create', response.data)
              context.showForm(false)
              context.$loader.remove('BookMCRCommentSubmitting')
            })
            .catch(error => {
              console.log(error)
              context.showForm(false)
              context.$loader.remove('BookMCRCommentSubmitting')
            })
      }
      else {
        MCRComment.save()
            .then(response => {
              this.sendAction('update', response.data)
              context.showForm(false)
              context.$loader.remove('BookMCRCommentSubmitting')
            })
            .catch(error => {
              console.log(error)
              context.$loader.remove('BookMCRCommentSubmitting')
              context.showForm(false)
            })
      }

    },
    deleteItem(item) {
      const context = this
      let id = item.id
      swal({
        title: 'Delete MCR Comment?',
        text: `Are you sure you want to delete Comment?`,
        type: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        showCloseButton: true
      })
          .then(function () {
            //const MCRComment = new McrComment(item)
            const MCRComment = new McrComment(id)
            context.$loader.add('BookMCRCommentSubmitting')
            MCRComment.delete()
                .then((response) => {
                  context.sendAction('delete', item)
                  context.showForm(false)
                  context.$loader.remove('BookMCRCommentSubmitting')
                })
                .catch((error) => {
                  console.log('ERROR:', error)
                  context.$error.set(error)
                  context.$loader.remove('BookMCRCommentSubmitting')
                })
          })
    },
    /**
     * sends the action to the app listeners
     * @param actionType
     * @param item
     */
    sendAction(actionType, item = null) {
      let data = { action: actionType, item: item }
      app.$emit('mcrCommentActions', data)
    },
    /**
     * Open/close the add/edit form for Placeholder.
     * @param open
     */
    showForm(open = true) {
      if (!open) {
        this.resetForm()
        app.$emit('closeCommenter')
      }
      else {
        if (this.item && this.item.id) {
          this.setFormDefaultData()
        }
      }
    },
    /**
     * Reset the values on add form.
     */
    resetForm() {
      this.formData = this.defaultData()
      this.addForm.edit = false
    },
    setFormDefaultData() {
      if (!this.item) return
      // if its clicked on name of type
      if (this.item && !this.item.comment && this.item.newType) {
        this.formData.periodStart.value = this.item[this.item.elementType + 'Start']
        this.formData.periodEnd.value = this.item[this.item.elementType + 'End']
        this.formData.country.value = app.$data.user.get.country
        this.addForm.edit = false
        return
      }
      let _country = Array.isArray(this.item.country) ? this.item.country.join(',') : this.item.country
      this.addForm.edit = true
      this.addForm.commenter = this.item
      this.addForm.commenter.country = _country

      this.formData.id.value = this.item.id
      this.formData.country.value = _country
      this.formData.periodStart.value = this.item[this.item.elementType + 'Start']
      this.formData.periodEnd.value = this.item[this.item.elementType + 'End']
      this.formData.comment.value = this.item.comment
      //console.log(this.formData)
    },
    defaultData() {
      return {
        id: {
          value: ''
        },
        country: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
        periodStart: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
        periodEnd: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
        comment: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
      }
    }
  }
}
</script>
<template>
  <div class="book-mcr-comment">
    <book-modal maxWidth="400px" @close="showForm(false)" :class="{'dark-equipment-modal': darkMode}">
      <h4 slot="header" class="modal-title">
        <template v-if="!addForm.edit"><span class="label label-mcr-comment">MCR Comment</span></template>
        <template v-if="addForm.edit"><span class="label label-mcr-comment">MCR Comment</span></template>
      </h4>
      <div slot="body">
        <div class="form-horizontal">
          <div class="form-group">
            <label class="control-label book-width-100">
              <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
              Country
            </label>
            <div class="form-control-100" v-if="!item || item && item.comment && item.forId === null">
              <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" v-if="countries" tabindex="12">
                <option value="">None</option>
                <option v-for="country in allCountries()" :value="country.code">
                  {{country.label}}
                </option>
              </select>
            </div>
            <div v-else-if="item && item.forId && item.forType || item.elementType !== 'mcrComment'" class="form-control-100">
              <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" v-if="countries" tabindex="12">
                <option :value="userCountry().code">{{userCountry().label}}</option>
                <option v-for="country in allCountries()" :value="country.code">
                  {{country.label}}
                </option>
              </select>
          </div>
          </div>
          <div class="form-group">
            <label class="control-label book-width-100">
              <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
              Time
            </label>
            <book-datetime
                v-if="!item || item && item.comment && item.forId === null"
                component-id="book-period-start"
                class="form-control-100"
                :required="{date: true, time: true}"
                :limit="periodStartDateLimit"
                :default-value="formData.periodStart.value ? formData.periodStart.value : ''"
                @newValue="value => formData.periodStart.value = value">
            </book-datetime>
            <span v-else-if="item && item.forId && item.forType || item.elementType !== 'mcrComment'" class="form-control-100 bolder-italic">
              based on <span class="label label-primary">{{ item && item.forId ? item.forType : (item ? item.elementType : '')}}</span>
            </span>

          </div>

          <div class="form-group">
            <label for="name" class="control-label book-width-100">
              <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
              Comment
            </label>
            <div class="form-control-100" :class="{ 'dark-input': darkMode }">
              <textarea v-validate="formData.comment.validator" v-model="formData.comment.value" name="title" class="form-control" tabindex="3"/>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button v-if="addForm.edit" class="btn btn-danger" @click="deleteItem(item)" :disabled="!isFormValid || !isFormChanged || processing">Delete</button>
        <button class="btn btn-success" @click="submit()" :disabled="!isFormValid || !isFormChanged || processing">Save</button>
      </div>
    </book-modal>
  </div>
</template>
<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";
@import "../../../style/darkmode.scss";

.book-mcr-comment {
  min-height: 40px;
  position: relative;
  .modal-footer {
    .book-loader {
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .bolder-italic {
    padding-top: 8px;
    font-style: italic;
    font-weight: 700;
  }
  .book-table {
    border: none;
    margin-bottom: 0;
  }
  .table-modal {
    margin-top: 10px;
  }
  .table {
    margin-bottom: 0;
  }
  > .book-loader {
    padding: 9px 0;
  }
  .book-help-message-conflict {
    line-height: 1.4em;
    text-align: left;
  }

  .book-overview-item-title > span  {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.panel-body > .book-mcr-comment {
  margin: -15px;
}
.book-compact-panels .panel-body > .book-mcr-comment {
  margin: -8px;
}
</style>