<script>
import {app} from 'app'
import System from 'components/system/System'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
import Feed from 'components/equipment/Equipment'
import BookLoader from 'components/common/loader/BookLoader'
import BookTable from 'components/common/BookTable'

export default {
    components: {
        BookLoader,
        BookTable,
    },
    mounted() {
        const context = this
        this.$store.commit('remove', {key: 'equipment', id: 0})

        // Load data.
        const entity = new Entity()
        const equipmentTypeEntityId = context.setting.equipmenttype_entity_id
        context.equipmentTypeEntity = new Entity(equipmentTypeEntityId)
        const equipmentStatusEntityId = context.setting.equipmentstatus_entity_id
        context.equipmentStatusEntity = new Entity(equipmentStatusEntityId)
        const equipmentGroupEntityId = context.setting.equipmentgroups_entity_id
        context.equipmentGroupEntity = new Entity(equipmentGroupEntityId)
        const externalMCRsEntityId = context.setting.externalmcrs_entity_id
        context.externalMCRsEntity = new Entity(externalMCRsEntityId)

        const equipment = new Equipment()
        const dataToLoad = [
            entity.all({ids: [equipmentTypeEntityId, equipmentStatusEntityId, equipmentGroupEntityId, externalMCRsEntityId].join(',')}),
            equipment.all()
        ]

        Promise.all(dataToLoad.map(p => {

            // Return "undefined" for all rejected promises which allows
            // us to handle entities even if equipment failed.
            return p.catch(() => undefined)

        }))
            .then(response => {
                context.setLoaded()
                context.setDefaultFilters()
            })
            .catch(error => {
                this.$error.set(error)
                context.setLoaded()
            })

    },

    data() {
        return {
            loaded: false,
            equipmentTypeEntity: null,
            equipmentGroupEntity: null,
            equipmentStatusEntity: null,
            externalMCRsEntity: null,
            filter: {
                type: '',
                status: ''
            },
        }
    },

    computed: {
        setting() {
            return this.$settings.get.equipment
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        equipmentList() {
            return this.$store.state.data.equipment
        },
        system() {
            return System
        },
        preserveFilters: {
            get() {
                return this.$store.state.filter.preserveEquipmentList ? this.$store.state.filter.preserveEquipmentList : null
            },
            set(value) {
                if (value) this.$store.commit('filter/setPreserveEquipmentList', this.getCurrentFilterSet)
                else  this.$store.commit('filter/removePreserveEquipmentList')
            }
        },
        getCurrentFilterSet(){
            const output = {}
            Vue.set(output, 'equipmentType', this.filter.type)
            Vue.set(output, 'equipmentStatus', this.filter.status)
            return output
        },
        tableData() {
            const context = this
            const output = {}
            if (!context.equipmentList || context.equipmentList.length == 0) {
                return output
            }

            // Build options.
            output.options = {
                actions: ['edit', 'delete'],
                filters: {
                    type: {
                        label: 'Type',
                        selected: /* context.preserveFilters ? context.preserveFilters.equipmentType : */ context.filter.type,
                        items: []
                    },
                    status: {
                        label: 'Status',
                        selected: /* context.preserveFilters ? context.preserveFilters.equipmentStatus : */ context.filter.status,
                        items: []
                    }
                }
            }

            // Build filters.
            context.equipmentTypeEntity.get.items.forEach(v => {
                output.options.filters.type.items.push({
                    id: v.id,
                    label: v.label
                })
            })
            context.equipmentStatusEntity.get.items.forEach(v => {
                output.options.filters.status.items.push({
                    id: v.id,
                    label: v.label
                })
            })

            // Build the columns.
            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'type',
                    label: 'Type',
                    sortable: true,
                    searchable: true
                },
                {
                    value: 'group',
                    label: 'Group',
                    sortable: true,
                    searchable: true
                },
                {
                    value: 'label',
                    label: 'Label',
                    sortable: true,
                    searchable: true
                },
                {
                    value: 'description',
                    label: 'Description',
                    sortable: true,
                    searchable: true
                },
                {
                    value: 'model',
                    label: 'Model',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'status',
                    label: 'Status',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'usage',
                    label: 'Usage',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'kit',
                    label: 'Equipment Kit',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'suggestion',
                    label: 'Suggestion (id)',
                    sortable: true,
                    searchable: true
                },
                {
                    value: 'country',
                    label: 'Country/Source',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'destinationWithoutSource',
                    label: 'Destination',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'conflictCount',
                    label: 'Conflict count',
                    sortable: true,
                    searchable: false
                },
                {
                  value: 'placeHolder',
                  label: 'Placeholder',
                  sortable: true,
                  searchable: false
                },
                {
                    value: 'doNotCreateConflict',
                    label: 'Not Create Conflict',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'useGroupConflictCount',
                    label: 'Check Group Limit',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'notCountryRestricted',
                    label: 'Not restricted',
                    sortable: true,
                    searchable: false
                },
            ]
            output.rows = []
            context.equipmentList
                .filter(v => Equipment.isVisible(v))
                .forEach(v => {
                    let country = v.notCountryRestricted ? Equipment.getSourceLabelById(v.source, context.externalMCRsEntity) : System.getCountryById(v.country)
                    let destinationWithoutSource = v.notCountryRestricted ? Equipment.getDestinationsLabels(v.destinationWithoutSource, v.source, context.externalMCRsEntity): ''
                    
                    const row = {
                        id: {
                            value: v.id
                        },
                        type: {
                            rawValue: v.type,
                            value: context.getEntityLabel(v.type, 'equipmentTypeEntity')
                        },
                        group: {
                            rawValue: v.group,
                            value: context.getEntityLabel(v.group, 'equipmentGroupEntity')
                        },
                        label: {
                            value: v.label
                        },
                        description: {
                            value: v.description
                        },
                        model: {
                            value: v.model
                        },
                        status: {
                            rawValue: v.equipmentStatus,
                            value: context.getEntityLabel(v.equipmentStatus, 'equipmentStatusEntity')
                        },
                        usage: {
                            value: context.getUsage(v.usage)
                        },
                        kit: {
                            value: v.kitId ? context.getKitLabel(v.kitId) : ''
                        },
                        suggestion: {
                            value: context.getSuggestion(v.suggestions)
                        },
                        country: {
                            value: country ? country : ''
                        },
                        destinationWithoutSource: {
                            value: destinationWithoutSource ? destinationWithoutSource : ''
                        },
                        conflictCount: {
                            value: v.conflictCount
                        },
                        placeHolder: {
                          value: v.placeHolder ? 'Yes' : 'No'
                        },
                        doNotCreateConflict: {
                            value: v.doNotCreateConflict ? 'Yes' : 'No'
                        },
                        useGroupConflictCount: {
                            value: v.useGroupConflictCount ? 'Yes' : 'No'
                        },
                        notCountryRestricted: {
                            value: v.notCountryRestricted ? 'Yes' : 'No'
                        },
                    }
                    output.rows.push(row)
                })
            return output
        }
    },
    methods: {

        /**
         * Set the equipment list to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
        },

        /**
         * Get the label to be presented in the equipment list.
         * @param integer id
         * @param string entity
         * @return string
         */
        getEntityLabel(id, entity) {
            const context = this
            if (!context[entity]) {
                return ''
            }
            const item = context[entity].getItem(id)
            return item ? item.label : ''
        },

        /**
         * Get the suggestion text to be presented in the equipment list.
         * @param integer id
         * @return string
         */
        getSuggestion(suggestions) {
            if (!suggestions || suggestions.length == 0) return ''
            const context = this
            let output = ''

            const equipment = context.equipmentList.find(v => v.id == suggestions)
            if (equipment) {
                let type = context.getEntityLabel(equipment.type, 'equipmentTypeEntity')
                if (type) {
                    output = type + ', '
                }
                output = output + equipment.label + ' (' + equipment.id + ')'
            }
            return output
        },

        /**
         * Get the usage text to be presented in the equipment list.
         * @param array data
         * @return string
         */
        getUsage(data) {
            let output = [];
            if (data.indexOf('feed') > -1) {
                output.push('Feed')
            }
            if (data.indexOf('production') > -1) {
                output.push('Production')
            }
            if (data.indexOf('eng') > -1) {
                output.push('ENG')
            }
            if (data.indexOf('edit') > -1) {
                output.push('Edit')
            }
            if (data.indexOf('car') > -1) {
                output.push('Car')
            }
            return output.join(', ')
        },

        editEquipment(id) {
            this.$router.push({
                name: 'equipment.edit',
                params: {id: id}, query: {destination: 'equipment'}
            })
        },

        /**
         * Delete an equipment.
         * @param integer id
         */
        deleteEquipment(id) {
            const context = this
            const equipment = new Equipment(id)
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete equipment ' + id + '?',
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    equipment.delete()
                    .then(response => {
                        context.$alert.set('The equipment was deleted!')
                    })
                    .catch(error => {
                        context.$error.set(error, `It was not possible to delete the equipment! Ensure equipment ${id} isn\'t referenced (as suggestion) from another equipment.`)
                    })
                })
        },
        updateFilters(filters) {
            this.filter.type = filters.type.selected
            this.filter.status = filters.status.selected
            if(filters.type.selected !== "" || filters.status.selected !== "") {
                this.preserveFilters = true
            }
            else {
                this.preserveFilters = false
            }
        },
        setDefaultFilters() {
            if(this.preserveFilters) {
                this.filter.type = this.preserveFilters.equipmentType
                this.filter.status = this.preserveFilters.equipmentStatus
            }
        },

        /**
         * Gets kit label from kit ID.
         */
        getKitLabel(kitId) {
            const equipment = this.equipmentList.find(v => v.id === kitId)
            if (equipment) {
                return equipment.label
            }
            return ''
        }
    }
}
</script>

<template>
<div id="equipment-list" class="book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title">Manage equipment</h1>
        <div class="secondary-menu">
            <div class="btn-group" role="group">
                <router-link :to="{name: 'equipment.create', query: {destination: 'equipment'}}" class="btn btn-success"><font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Create</span></router-link>
            </div>
        </div>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div v-else class="panel panel-info">
        <book-table
            :component="'equipmentList'"
            :items="tableData"
            trackFilters
            @edit="item => editEquipment(item.id.value)"
            @delete="item => deleteEquipment(item.id.value)"
            @updateFilters="item => updateFilters(item)"
            notFoundMessage="No equipment found">
        </book-table>
    </div>
</div>
</template>
