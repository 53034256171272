<script>
/**
 * @file
 * Book Selectbox component.
 * Builds a "dropdown" like selectbox with search functionality.
 */

import bookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        bookLoader
    },
    props: {
        componentId: {
            default: 'field',
            type: String
        },
        loading: {
            default: false,
            type: Boolean
        },
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        selected: {
            default: '',
        },
        disabled: {
            default: false,
            type: Boolean
        },
        filterVisibilityBy: {
            default: () => {
                return {}
            },
            type: Object
        },
        tabindex: {
            default: 1,
            type: Number
        },
        darkMode: {
          default: false,
        },
        forceRender: {
          type: Number,
          default: 0
        }
    },
    watch: {
      forceRender: {
        handler: function(newValue,oldVal) {
          // Do a full reset on force render. This fixes issues like force rendering with same production leader or
          // having invalid search state after replacing the production leader.
          this.reset()
          if (this.selected !== 0) this.selectItem(this.selected)
        },
        deep: true
      },
      items: {
        handler: function(newValue) {
          if (this.selected === 0) this.reset()
          else this.selectItem(this.selected)
        },
        deep: true
      }

    },
    data() {
        return {
            selectedItem: null,
            searchTerm: '',
            showItems: false,
        }
    },
    computed: {
        listItems() {
            return this.items.filter(item => {
                let visible = true

                // If no searchTerm is provided, check if the list should be
                // filtered based on filterVisibilityBy property.
                if (this.searchTerm == '') {
                    if ( (Object.keys(this.filterVisibilityBy).length > 0) && (this.selected != item.id) ) {
                        // Should access the list when you click the icon. WN
                        //visible = false
                        Lazy(this.filterVisibilityBy)
                            .filter((v, k) => item.extraFields && item.extraFields.hasOwnProperty(k))
                            .each((v, k) => {
                                // Should access the list when you click the icon.
                                if (visible) return
                                if (item.extraFields[k].indexOf(v) > -1) visible = true
                            })
                    }
                    return visible
                }

                visible = false
                Lazy(item)
                    .each(v => {
                        if (visible) return
                        // for lower-case case sensitive operation WN
                        if (typeof v == 'string') v = v.toLowerCase()
                        // also searchTerm should be lower case
                        if ((typeof v == 'string') && (v.indexOf(this.searchTerm.toLowerCase()) > -1)) visible = true
                    })
                return visible
            })
        },
        getSelectedLabel() {
            if (this.componentId === 'housenumber-selection') {
                if (!this.selectedItem) return ''
                return this.selectedItem.label ? this.selectedItem.label : this.selectedItem
            }
            if (!this.selectedItem) return ''
            return this.selectedItem.label
        }
    },
    mounted() {
        if (this.componentId === 'housenumber-selection') {
            if (this.selected) this.selectItem(this.selected)
        } else {
            if (this.selected) this.selectItem(this.selected)
        }

        //document.addEventListener("keyup", this.nextItem);
    },
    methods: {
        selectItem(item) {
            // house number selection..
            if (this.componentId === 'housenumber-selection') {
                this.selectedItem = item
                let select = item.label ? item.label : item
                this.$emit('selectedItem', select)
                this.showItems = false
                return
            }

            if (Number.isInteger(item)) {
                item = this.listItems.find(v => v.id == item)
            }
            if (!item || (item.blocked && item.id != this.selected)) return

            if (this.selectedItem && (this.selectedItem.id == item.id)) {
                this.selectedItem = null
                this.$emit('selectedItem', '')
            }
            else {
                this.selectedItem = item
                this.$emit('selectedItem', item.id)
                this.showItems = false
            }
        },
        checkItem(item){
            this.selectedItem = item;
            this.$emit('selectedItem', item.id);
        },
        moveNext(direction){
            if (!this.selectItem && this.listItems){
                this.checkItem(this.listItems[0]);
            }

            var selectedIndex = this.listItems.indexOf(this.selectedItem);

            if (direction === -1){
                selectedIndex--;
                if (selectedIndex < 0) selectedIndex = 0;
            }else{
                selectedIndex++;
                if (selectedIndex === this.listItems.length) selectedIndex = this.listItems.length - 1; 
            }

            // FixScroll when, you arrow down, it should also scroll down
            let scrolling = document.getElementById('book-selectbox-' + this.componentId).getElementsByTagName('ul');
            scrolling = scrolling[scrolling.length -1]
            if (direction !== -1) {
                if (selectedIndex > 3) scrolling.scrollBy(0, 31)
            } else scrolling.scrollBy(0, -31)

            this.checkItem(this.listItems[selectedIndex]);
        },
        arrowUp(){
            this.moveNext(-1);
        },
        arrowDown(){
            this.moveNext(1);
        },
        enterKey() {
            if (this.componentId === 'housenumber-selection') {
                this.selectedItem = this.searchTerm
                this.selectItem(this.selectedItem)
                this.showItems = false
                return
            }

            if (this.selectedItem != null) this.showItems = false;
            this.searchTerm = ''
        },
        reset() {
            this.selectedItem = null
            this.searchTerm = ''
            this.$emit('selectedItem', '')
        }
    }
}
</script>

<template>
<div :id="'book-selectbox-' + componentId" class="book-selectbox clearfix">
    <div class="book-selectbox-selected" :class="{'dark-layout-filters': this.darkMode}">
        <div class="form-control-static book-selectbox-selected-text" @click="!disabled ? showItems = !showItems : ''" :class="{'book-selectbox-selected-text-disabled': disabled}">
            <span v-if="loading" class="book-selectbox-loading"><span class="book-selectbox-label">loading...</span></span>
            <span v-else>{{getSelectedLabel}}</span>
        </div>
        <button type="button" class="book-selectbox-clean-button" v-if="selectedItem" @click="reset()" :disabled="disabled">
            <font-awesome-icon icon="times"/> 
        </button>
        <button type="button" class="btn btn-primary btn-sm book-selectbox-selected-button"
                :class="{'dark-second-input': darkMode }"
                @click="showItems = !showItems" :disabled="disabled" :tabindex="tabindex">
            <book-loader v-if="loading" :small="true" :white="true"></book-loader>
            <font-awesome-icon icon="search"/> 
        </button>
    </div>
    <transition name="slide-down-fade" mode="in-out">
        <div class="book-selectbox-items-wrapper"
             :class="{'dark-input': darkMode}"
             v-if="showItems" @keyup.arrow-up="arrowUp" @keyup.arrow-down="arrowDown" @keyup.enter="enterKey">
            <div class="book-selectbox-arrow-up"></div>
            <input v-if="componentId === 'housenumber-selection'" class="form-control book-selectbox-search" type="text" placeholder="Type to add or search" v-focus v-model="searchTerm" :disabled="disabled" :tabindex="tabindex"/>
            <input v-else class="form-control book-selectbox-search" type="text" placeholder="Type to search" v-focus v-model="searchTerm" :disabled="disabled" :tabindex="tabindex"/>
            <ul v-if="listItems.length > 0 && !loading" class="book-selectbox-items" >
                <li v-for="item in listItems"
                    :key="item.id"
                    :title="item.hoverText"
                    :class="['book-selectbox-item', 'book-selectbox-item-' + item.id,
                    {'book-selectbox-item-selected': selectedItem && selectedItem.id == item.id},
                    {'book-selectbox-item-blocked': item.blocked && (item.id != selected)},
                    {'book-selectbox-item-warning': item.warning && (item.id != selected)},
                    {'dark-port': darkMode }
                    ]"
                    @click="selectItem(item)">
                    <font-awesome-icon icon="check" class="book-selectbox-item-icon" aria-hidden="true" v-if="selectedItem && selectedItem.id == item.id"/> 
                    <font-awesome-icon icon="ban" class="book-selectbox-item-icon" aria-hidden="true" v-else-if="item.blocked && (item.id != selected)"/> 
                    <font-awesome-icon icon="exclamation-triangle" class="book-selectbox-item-icon" aria-hidden="true" v-else-if="item.warning && (item.id != selected)"/> 
                    <span class="book-selectbox-item-text" v-html="item.text ? item.text : item.label"></span>
                    <span class="book-selectbox-item-extra" v-if="componentId === 'housenumber-selection'"><b>i:</b> {{item.userfield1_value}} - <b>s:</b> {{item.shortlabel}}</span>
                    <span class="book-selectbox-item-extra" v-html="item.extra" v-else></span>

                </li>
            </ul>
            <div v-else class="book-selectbox-nothing-found">
                <book-loader v-if="loading" :small="false"></book-loader>
                <span v-else-if="componentId !== 'housenumber-selection'">Nothing found</span>
                <span v-if="componentId === 'housenumber-selection'">To ADD press ENTER</span>
            </div>
        </div>
    </transition>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.has-error {
    .book-selectbox .book-selectbox-selected .book-selectbox-selected-text {
        border-color: $color-red;
    }
    .form-control.book-selectbox-search {
        border-color: $color-form-control !important;
    }
}

.book-selectbox .book-selectbox-selected .book-selectbox-selected-text {
    padding-left: 10px;
}

.book-selectbox {
    overflow: hidden;
    position: relative;
    .book-selectbox-arrow-up {
        border-bottom: 8px solid $color-form-control;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        height: 0;
        position: absolute;
        right: 9px;
        top: -7px;
        width: 0;
    }
    .book-selectbox-selected,
    .book-selectbox-items-wrapper {
        position: relative;
    }
    .book-selectbox-selected {
        background: $color-white;
        display: flex;
        z-index: 2;
        .book-selectbox-selected-text {
            border: 1px solid $color-form-control;
            border-right-width: 0;
            border-radius: 3px 0 0 3px;
            float: none !important;
            flex: 1;
            overflow: hidden;
            padding-right: 34px;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.book-selectbox-selected-text-disabled {
                background-color: $color-grey-light;
                cursor: not-allowed;
            }
        }
        .book-selectbox-clean-button {
            background: none;
            border: none;
            color: $color-grey;
            margin: 2px 2px 2px -30px;
            outline: none;
            &:not([disabled]):hover {
                color: $color-text;
            }
        }
        .book-selectbox-selected-button {
            border-radius: 0 3px 3px 0;
            .book-loader {
                display: inline-block;
                vertical-align: calc(-2px);
                .cssload-container {
                    height: 12px;
                    .cssload-zenith {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
    }
    .book-selectbox-selected-text {
        cursor: pointer;
    }
    .book-selectbox-loading {
        display: inline-block;
    //     .book-loader {
    //         display: inline-block;
    //         vertical-align: middle;
    //         .cssload-container .cssload-zenith {
    //             height: 12px;
    //             width: 12px;
    //         }
    //     }
        .book-selectbox-label {
            display: inline-block;
            margin-left: 5px;
        }
    }
    .book-selectbox-items-wrapper {
        margin-top: 10px;
        z-index: 1;
        .cssload-container {
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
    .book-selectbox-search {
        border-radius: 3px 3px 0 0 !important;
        &:focus {
            border-color: $color-form-control;
        }
    }
    .book-selectbox-items,
    .book-selectbox-nothing-found {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        height: 130px;
        margin-top: -1px;
    }
    .book-selectbox-nothing-found {
        font-size: .9em;
        margin-bottom: 10px;
        padding: 20px 10px 10px;
        text-align: center;
    }
    .book-selectbox-items {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        list-style: none;
        overflow-y: auto;
        padding: 0;
    }
    .book-selectbox-item {
        border-left: 5px solid transparent;
        cursor: pointer;
        margin: 2px;
        padding: 5px 35px 5px 10px;
        position: relative;
        &:nth-child(even) {
            background: $color-grey-light-transparent;
        }
        &:first-child {
            margin-top: 2px;
        }
        &:last-child {
            margin-bottom: 2px;
        }
        &:hover:not(.book-selectbox-item-blocked) {
            background: $color-blue-primary !important;
            border-left-color: $color-blue-primary !important;
            * {
                color: $color-white !important;
            }
        }
        &.book-selectbox-item-selected {
            border-left-color: $color-blue-primary;
            background: rgba($color-blue-primary, .1);
            // color: $color-white;
            .book-selectbox-item-icon {
                color: $color-blue-primary;
            }
        }
        &.book-selectbox-item-blocked {
            border-left-color: $color-red !important;
            background: rgba($color-red, .1) !important;
            cursor: not-allowed;
            .book-selectbox-item-icon {
                color: $color-red;
            }
        }
        &.book-selectbox-item-warning {
            border-left-color: $color-yellow !important;
            background: rgba($color-yellow, .1) !important;
            .book-selectbox-item-icon {
                color: $color-yellow;
            }
        }
        > * {
            display: block;
        }
        .book-selectbox-item-icon {
            font-size: 18px;
            height: 20px;
            line-height: 20px;
            margin-top: -10px;
            position: absolute;
            right: 7px;
            text-align: center;
            top: 50%;
            width: 20px;
        }
    }

    .book-selectbox-item-conflict {
        color: $color-red;
    }

    .book-selectbox-item-text {

    }
    .book-selectbox-item-extra {
        color: $color-grey;
        font-size: .9em;
    }
}
</style>
