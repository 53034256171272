import {app} from 'app'
import Mcrdata from 'components/mcr/Mcrdata'
import EquipmentUsage from 'components/equipment/EquipmentUsage';
import Entity from 'components/entity/Entity'
import Item from 'components/common/item/Item'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookSidebarFeed from 'components/feed/BookSidebarFeed'
import BookSidebarProduction from 'components/production/BookSidebarProduction'
import BookSidebarTask from 'components/production/ProductionTask/BookSidebarTask'
import BookMcrView from 'components/mcr/BookMcrView'
import BookMcrComment from 'components/mcr/McrComment/BookMcrComment'
import BookLoader from 'components/common/loader/BookLoader'
import BookModal from 'components/common/BookModal'
import BookFilter from 'components/common/filter/BookFilter'
import BookForkPlaceholder from 'components/forkplaceholder/BookForkPlaceholder'
import McrStatus from 'components/mcr/McrStatus/McrStatus'
import SocketMixin from 'components/common/item/SocketMixin'
import Updates from 'components/mcr/Updates'
import Lock from 'components/common/lock/Lock'
import Datetime from 'components/common/helpers/Datetime'
import Equipment from 'components/equipment/Equipment'
import JsonExcel from 'vue-json-excel'
import virtualList from 'vue-virtual-scroll-list'
import _ from "lodash";

export default {
    mixins: [
        SocketMixin,
    ],
    components: {
        BookLoader,
        BookFilter,
        BookDateNavigation,
        BookMcrView,
        BookSidebarFeed,
        BookSidebarProduction,
        BookSidebarTask,
        virtualList,
        JsonExcel,
        BookModal,
        BookForkPlaceholder,
        BookMcrComment
    },
    data() {
        return {
            loaded: false,
            filterReady: false,
            feed: new Mcrdata(),
            production: new Mcrdata(),
            mcrComment: new Mcrdata(),
            equipmentUsages: new EquipmentUsage(),
            allEquipmentUsages: null,
            mcrdata: new Mcrdata(),
            leagueEntity: null,
            sportEntity: null,
            deliveryTypeEntity: null,
            channelEntity: null,
            runTypeEntity: null,
            locationEntity: null,
            planTypeEntity: null,
            ingestEntity: null,
            equipmentTypeEntity: null,
            allEquipments: null,
            filters: {},
            currentNumItems: 0,
            selectedItem: null,
            selectedComment: null,
            sidebar: false,
            hideFin: true,
            asking: 0,
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            entities: {
                leagueEntity: 'league_entity_id',
                sportEntity: 'sport_entity_id',
                deliveryTypeEntity: 'deliverytype_entity_id',
                channelEntity: 'channel_entity_id',
                equipmentTypeEntity: 'equipmenttype_entity_id',
                equipmentStatusEntity: 'equipmentstatus_entity_id',
                runTypeEntity: 'runtype_entity_id',
                locationEntity: 'location_entity_id',
                planTypeEntity: 'plantype_entity_id',
                streamingTypeEntity: 'streamingtype_entity_id',
                polarizationEntity: 'polarization_entity_id',
                modulationEntity: 'modulation_entity_id',
                fecEntity: 'fec_entity_id',
                skillEntity: 'skill_entity_id',
                ingestEntity: 'ingest_entity_id',
                audioLayoutsEntity: 'audiolayouts_entity_id',
                equipmentFeedFiberType: 'fiber_source_entityitem_id',
                satelliteEntity: 'satellite_entity_id',
                fiberEntity: 'fiber_source_entityitem_id',
                externalMCRsEntity: 'externalmcrs_entity_id',
                workgroupsEntity: 'workgroups_entity_id',
                activityTypesEntity: 'activitytypes_entity_id',
                contentTypeEntity: 'contenttype_entity_id'


            },
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                country: {
                    label: 'Countries',
                    items: [],
                    advanced: {
                        combine: ['elementTypeDetailed', 'channel', 'contribution', 'runType']
                    }
                },
                sport: {
                    label: 'Sports',
                    items: [],
                    class: 'sportEntity',
                },
                league: {
                    label: 'Leagues',
                    items: [],
                    class: 'leagueEntity',
                },
                channel: {
                    label: 'Channels',
                    items: [],
                    class: 'channelEntity',
                    //display: false
                },
                contribution: {
                    label: 'Contributions',
                    items: [],
                    display: false
                },
                elementTypeDetailed: {
                    label: 'Types',
                    items: [
                        {
                            id: 'feed',
                            label: 'Feeds'
                        },
                        {
                            id: 'production',
                            label: 'Productions'
                        },
                        {
                            id: 'contribution',
                            label: 'Contributions'
                        },
                        {
                            id: 'task',
                            label: 'Tasks'
                        },
                        {
                            id: 'mcrComment',
                            label: 'Comments'
                        },
                    ]
                },
                runType: {
                    label: 'Run Type',
                    items: [],
                    class: 'runTypeEntity'
                },
                equipmentType: {
                    label: 'Equipment',
                    items: [],
                    class: 'equipmentTypeEntity'
                },
                ingestions: {
                    label: 'Ingest',
                    items: [
                        {
                            id: 'blank',
                            label: 'Null'
                        },
                        {
                            id: 'record',
                            label: 'Record'
                        },
                        {
                            id: 'notrecord',
                            label: 'Not Record'
                        },
                        {
                            id: 'repeat',
                            label: 'Repeat'
                        },
                        {
                            id: 'share',
                            label: 'Share'
                        },
                        {
                            id: 'finished',
                            label: 'Finished'
                        }
                    ]
                },
            },
            delay: 700,
            clicks: 0,
            timer: null,
            showCommenter: false,
            showFeedback: false,
            selectedFeedback: this.defaultFeedback(),
            waitingUpdates: {
                updates: false,
                total: 0,
                items: [],
                page: {},
                json: [],
                pageItems: {}
            },
            sendingUpdates: {
                items: [],
                total: 0,
                updates: false
            },
            recievingUpdates: {
                items: [],
                total: 0,
                updates: false
            },
            darkMode: false,
            placeholderForm: null,
            showPlaceholderForm: false
        }
    },
    watch: {
        '$route': function(to, from) {
            const context = this
            if (!this.loaded || ((from.query.from == to.query.from) && (from.query.to == to.query.to))) {
                return
            }
            // check if we have waiting updates
            // changes should be saved.
            if (context.waitingUpdates && context.waitingUpdates.items.length > 0) {
                // WN
                swal({
                    title: 'Have changes!',
                    text: 'You have made changes on MCR-View, please save or revert them back before you leave.',
                    type: 'question',
                    confirmButtonText: 'Save',
                    cancelButtonText: 'No',
                    showCancelButton: true,
                    showCloseButton: true
                })
                    .then(function() {
                        //save and go
                        app.$emit('saveMyChanges')
                    }, function(dismiss) {
                        if (dismiss == 'cancel') {

                            context.cleanWaitings()
                            app.$emit('clearTimers')

                            context.loadData()
                                .then(response => {
                                    context.setLoaded()
                                })
                                .catch(error => {
                                    if (error.response && error.response.status != 404) context.$error.set(error)
                                    context.setLoaded()
                                    context.noResult = true
                                })
                        }
                    })
                return
            }
            // else keep going

            context.loadData()
                .then(response => {
                    context.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) context.$error.set(error)
                    context.setLoaded()
                    context.noResult = true
                })

        },
    },
    computed: {
        hasDarkMode() {
            return this.$store.state.filter.darkMode ? true : false
        },
        preserveDarkMode: {
            get() {
                return this.$store.state.filter.darkMode ? true : false
            },
            set(value) {
                if (value) this.$store.commit('filter/setDarkMode', true)
                else  this.$store.commit('filter/removeDarkMode', true)
            }
        },
        finishDownload: function(){
            console.log('hide loading')
        },

        json_fields: function(){
            const context = this
            let excel_common_fields = {
                Week : "Week",
                Day: "Day",
                Date: "Date",
                Type: "Type",
                Start: "Start",
                End: "End",
                Channel: "Channel",
                Sport: "Sport",
                Event: "Event",
                Teams: "Teams",
                HouseNumber: "HouseNumber",
                ID : "ID",
                Info: "Info",
                Equipment: "Equipment",
                Notes: 'Notes',
                Prod: "Prod",
                Country: "Country",
                RunType: "RunType",
                Location: "Location",
            }


            let additional_fields = {}

            let globalCountrySkill = {}

            if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0 ) {
                this.$store.state.system.countries.forEach(i => {
                    let cntCom = i.code + '_com'
                    let cntExp = i.code + '_expert'

                    globalCountrySkill[cntCom] = 0
                    globalCountrySkill[cntExp] = 0

                })
                globalCountrySkill.no_country_com = 0
                globalCountrySkill.no_country_expert = 0
            }
            else {
                globalCountrySkill.se_com = 0
                globalCountrySkill.se_expert = 0
                globalCountrySkill.no_com = 0
                globalCountrySkill.no_expert = 0
                globalCountrySkill.fi_com = 0
                globalCountrySkill.fi_expert = 0
                globalCountrySkill.dk_com = 0
                globalCountrySkill.dk_expert = 0
                globalCountrySkill.no_country_com = 0
                globalCountrySkill.no_country_expert = 0
            }


            this.itemsFiltered.forEach(function(item, index ) {
                let localCountrySkill = {}
                if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0 ) {
                    context.$store.state.system.countries.forEach(i => {
                        let cntCom = 'local_' + i.code + '_com'
                        let cntExp = 'local_' + i.code + '_expert'

                        localCountrySkill[cntCom] = 0
                        localCountrySkill[cntExp] = 0

                    })
                    localCountrySkill.local_no_country_com = 0
                    localCountrySkill.local_no_country_expert = 0
                }
                else {
                    localCountrySkill.local_se_com = 0
                    localCountrySkill.local_se_expert = 0
                    localCountrySkill.local_no_com = 0
                    localCountrySkill.local_no_expert = 0
                    localCountrySkill.local_fi_com = 0
                    localCountrySkill.local_fi_expert = 0
                    localCountrySkill.local_dk_com = 0
                    localCountrySkill.local_dk_expert = 0
                    localCountrySkill.local_no_country_com = 0
                    localCountrySkill.local_no_country_expert = 0
                }


                var channelLabel = ''
                var eventlLabel = ''
                var sportLabel = ''

                if(item.channel !== null) {
                    const channel = context.channelEntity.getItem(item.channel)
                    if (channel)
                        channelLabel = channel ? channel.label : ''
                }

                if(item.league !== null){
                    const sport = context.leagueEntity.getItem(item.league)
                    if (sport){
                        eventlLabel = sport ? sport.label : ''
                        sportLabel = sport ?  context.sportEntity.getItem(sport.reference).label : ''
                    }
                }

                context.skillEntity.getItem(item.channel)

                if(item.hasOwnProperty("crew")){
                    if (item.crew){
                        item.crew.forEach(function(v2) {
                            let skill = context.skillEntity.getItem(v2.skill)

                            var skillLabel = {
                                'skill_label' : skill ? skill.label : ''
                            }
                            if (typeof skill !== 'undefined' && skill !== null && skill.userfield1_value && skill.userfield1_value) {

                                // if (skill.country === 'se') {
                                //     if (skill.userfield1_value === 'yes')
                                //         local_se_com++;
                                //     if (skill.userfield2_value === 'yes')
                                //         local_se_expert++;
                                // } else if (skill.country === 'no') {
                                //     if (skill.userfield1_value === 'yes')
                                //         local_no_com++;
                                //     if (skill.userfield2_value === 'yes')
                                //         local_no_expert++;
                                // } else if (skill.country === 'fi') {
                                //     if (skill.userfield1_value === 'yes')
                                //         local_fi_com++;
                                //     if (skill.userfield2_value === 'yes')
                                //         local_fi_expert++;
                                // } else if (skill.country === 'dk') {
                                //     if (skill.userfield1_value === 'yes')
                                //         local_dk_com++;
                                //     if (skill.userfield2_value === 'yes')
                                //         local_dk_expert++;
                                // } else if (v2.location !== null && v2.location.country === null) {
                                //     if (skill.userfield1_value === 'yes')
                                //         local_no_country_com++;
                                //     if (skill.userfield2_value === 'yes')
                                //         local_no_country_expert++;
                                // }

                                if(skill.country && skill.country !== '') {
                                    if (skill.userfield1_value === 'yes') {
                                        let localCnt = 'local_' + skill.country + '_com'
                                        localCountrySkill[localCnt]++
                                    }
                                    if (skill.userfield2_value === 'yes') {
                                        let localCnt = 'local_' + skill.country + '_expert'
                                        localCountrySkill[localCnt]++
                                    }
                                }
                                else if (v2.location !== null && v2.location.country === null) {
                                    if (skill.userfield1_value === 'yes')
                                        localCountrySkill.local_no_country_com++;
                                    if (skill.userfield2_value === 'yes')
                                        localCountrySkill.local_no_country_expert++;
                                }


                            }
                        })
                    }

                    if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                        context.$store.state.system.countries.forEach(i => {
                            let localCntCom = 'local_' + i.code + '_com'
                            let globalCntCom = i.code + '_com'
                            let localCntExp = 'local_' + i.code + '_expert'
                            let globalCntExp = i.code + '_expert'

                            if(localCountrySkill[localCntCom] > globalCountrySkill[globalCntCom])
                                globalCountrySkill[globalCntCom] = localCountrySkill[localCntCom]
                            if(localCountrySkill[localCntExp] > globalCountrySkill[globalCntExp])
                                globalCountrySkill[globalCntExp] = localCountrySkill[localCntExp]
                        })

                        if(localCountrySkill.local_no_country_com > globalCountrySkill.no_country_com)
                            globalCountrySkill.no_country_com = localCountrySkill.local_no_country_com
                        if(localCountrySkill.local_no_country_expert > globalCountrySkill.no_country_expert)
                            globalCountrySkill.no_country_expert = localCountrySkill.local_no_country_expert
                    }
                    else {
                        if(localCountrySkill.local_se_com > globalCountrySkill.se_com)
                            globalCountrySkill.se_com = localCountrySkill.local_se_com
                        if(localCountrySkill.local_se_expert > se_expert)
                            globalCountrySkill.se_expert = localCountrySkill.local_se_expert
                        if(localCountrySkill.local_no_com > no_com)
                            globalCountrySkill.no_com = localCountrySkill.local_no_com
                        if(localCountrySkill.local_no_expert > no_expert)
                            globalCountrySkill.no_expert = localCountrySkill.local_no_expert
                        if(localCountrySkill.local_fi_com > fi_com)
                            globalCountrySkill.fi_com = localCountrySkill.local_fi_com
                        if(localCountrySkill.local_fi_expert > fi_expert)
                            globalCountrySkill.fi_expert = localCountrySkill.local_fi_expert
                        if(localCountrySkill.local_dk_com > dk_com)
                            globalCountrySkill.dk_com = localCountrySkill.local_dk_com
                        if(localCountrySkill.local_dk_expert > dk_expert)
                            globalCountrySkill.dk_expert = localCountrySkill.local_dk_expert
                        if(localCountrySkill.local_no_country_com > no_country_com)
                            globalCountrySkill.no_country_com = localCountrySkill.local_no_country_com
                        if(localCountrySkill.local_no_country_expert > no_country_expert)
                            globalCountrySkill.no_country_expert = localCountrySkill.local_no_country_expert
                    }


                }

            })

            let localNote = {}
            if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                context.$store.state.system.countries.forEach(i => {
                    let keyName = 'localNote' + i.code.toUpperCase()
                    let keyValue = 'Local Notes ' + i.code.toUpperCase()
                    localNote[keyName] = keyValue
                })
            }
            else {
                localNote = {
                    LocalNotesSE: 'Local Notes SE',
                    LocalNotesNO: 'Local Notes NO',
                    LocalNotesDK: 'Local Notes DK',
                    LocalNotesFI: 'Local Notes FI'
                }
            }
            excel_common_fields = $.extend( excel_common_fields, localNote)

            if( excel_common_fields !== null) {
                if(globalCountrySkill.no_country_com > 0){

                    let dynamic_no_country_com = []
                    for(var i = 1; i <= globalCountrySkill.no_country_com; i++){
                        dynamic_no_country_com['Commentator'+i] = 'Commentator_ENG_'+i
                    }
                    excel_common_fields = $.extend( excel_common_fields, dynamic_no_country_com)
                }


                if(globalCountrySkill.no_country_expert > 0){

                    let dynamic_no_country_expert = []
                    for(var i = 1; i <= globalCountrySkill.no_country_expert; i++){
                        dynamic_no_country_expert['Expert'+i] = 'Expert_ENG_'+i
                    }
                    excel_common_fields = $.extend( excel_common_fields, dynamic_no_country_expert)
                }

                if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                    context.$store.state.system.countries.forEach(c => {
                        let countryCom = c.code + '_com'
                        let countryExp = c.code + '_expert'
                        if(globalCountrySkill[countryCom] > 0) {
                            let dynamic_com = []
                            for(var i = 1; i <= globalCountrySkill[countryCom]; i++){
                                dynamic_com['Commentator_' + c.code + '_' +i] = 'Commentator_' + c.code + '_' + i
                            }
                            excel_common_fields = $.extend( excel_common_fields, dynamic_com )
                        }

                        if(globalCountrySkill[countryExp] > 0) {
                            let dynamic_exp = []
                            for(var i = 1; i <= globalCountrySkill[countryExp]; i++){
                                dynamic_exp['Expert_' + c.code + '_' +i] = 'Expert_' + c.code + '_' + i
                            }
                            excel_common_fields = $.extend( excel_common_fields, dynamic_exp )
                        }
                    })
                }
                else {
                    if( globalCountrySkill.se_com > 0){
                        /*if(se_com < 2)
                         excel_common_fields = $.extend( excel_common_fields, {Commentator_se: 'Commentator_se'} )
                         else {*/
                        let dynamic_se_com = []
                        for(var i = 1; i <= globalCountrySkill.se_com; i++){
                            dynamic_se_com['Commentator_se_'+i] = 'Commentator_se_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_se_com )
                        //}
                    }

                    if( globalCountrySkill.se_expert > 0){
                        /*if(se_expert < 2)
                         excel_common_fields = $.extend( excel_common_fields, {Expert_se: 'Expert, SE'} )
                         else {*/
                        let dynamic_se_expert = []
                        for(var i = 1; i <= globalCountrySkill.se_expert; i++){
                            dynamic_se_expert['Expert_se_'+i] = 'Expert_se_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_se_expert )
                        //}
                    }

                    if(globalCountrySkill.no_com > 0){

                        let dynamic_no_com = []
                        for(var i = 1; i <= globalCountrySkill.no_com; i++){
                            dynamic_no_com['Commentator_no_'+i] = 'Commentator_no_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_no_com )
                    }


                    if(globalCountrySkill.no_expert > 0){
                        excel_common_fields = $.extend( excel_common_fields, {Expert_NO: 'Expert, NO'} )

                        let dynamic_no_expert = []
                        for(var i = 1; i <= globalCountrySkill.no_expert; i++){
                            dynamic_no_expert['Expert_no_'+i] = 'Expert_no_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_no_expert )
                    }


                    if( globalCountrySkill.dk_com > 0){
                        /*if(dk_com < 2)
                         excel_common_fields = $.extend( excel_common_fields, {Commentator_dk: 'Commentator, DK'} )
                         else {*/
                        let dynamic_dk_com = []
                        for(var i = 1; i <= globalCountrySkill.dk_com; i++){
                            dynamic_dk_com['Commentator_dk_'+i] = 'Commentator_dk_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_dk_com )
                        //}
                    }

                    if(globalCountrySkill.dk_expert > 0){
                        /*if(dk_expert < 2)
                         excel_common_fields = $.extend( excel_common_fields, {Expert_dk: 'Expert_dk'} )
                         else {*/
                        let dynamic_dk_expert = []
                        for(var i = 1; i <= globalCountrySkill.dk_expert; i++){
                            dynamic_dk_expert['Expert_dk_'+i] = 'Expert_dk_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_dk_expert)
                        //}
                    }
                    if(globalCountrySkill.fi_com > 0){

                        let dynamic_fi_com = []
                        for(var i = 1; i <= globalCountrySkill.fi_com; i++){
                            dynamic_fi_com['Commentator_fi_'+i] = 'Commentator_fi_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_fi_com)
                    }


                    if(globalCountrySkill.fi_expert > 0){

                        let dynamic_fi_expert = []
                        for(var i = 1; i <= globalCountrySkill.fi_expert; i++){
                            dynamic_fi_expert['Expert_fi_'+i] = 'Expert_fi_'+i
                        }
                        excel_common_fields = $.extend( excel_common_fields, dynamic_fi_expert)
                    }
                }





            }
            return excel_common_fields
        },

        json_data(){
            if (this.waitingUpdates.items.length > 0) {
                console.log('JSON DATA, waiting to load full changes')
                return this.waitingUpdates.json
            }
            console.log('json data called', this.currentNumItems);
            const context = this
            var output = []

            let itemFilteredNew = this.itemsFiltered.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.startTime) - new Date(b.startTime);
            });

            //this.itemsFiltered.forEach(function(item, index ) {
            itemFilteredNew.forEach(function(item, index ) {
                let channelLabel = ''
                let eventlLabel = ''
                let sportLabel = ''
                let itemCountry = ''

                if(item.channel !== null) {
                    const channel = context.channelEntity.getItem(item.channel)
                    if (channel) {
                        channelLabel = channel ? channel.label : ''
                        itemCountry = channel ? channel.country : ''
                        let cnt = _.find(context.countries, c => c.code == itemCountry)
                        itemCountry = cnt !== undefined ? cnt.label : itemCountry
                    }

                }

                if(item.league !== null){
                    const sport = context.leagueEntity.getItem(item.league)
                    if (sport){
                        eventlLabel = sport ? sport.label : ''
                        sportLabel = sport ? context.sportEntity.getItem(sport.reference) ? context.sportEntity.getItem(sport.reference).label : '' : ''
                    }
                }

                let prodLead = ''
                if(item.elementType === 'production' && item.productionLeader != null) {

                    prodLead = item.productionLeader.name
                }

                if(item.channel) {
                    context.skillEntity.getItem(item.channel)
                }

                let external_value = {}

                if(item.hasOwnProperty("crew")){
                    let globalCountrySkill = {}
                    if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                        context.$store.state.system.countries.forEach(i => {
                            let countryCom = i.code + '_com'
                            let countryExp = i.code + '_expert'
                            globalCountrySkill[countryCom] = 0
                            globalCountrySkill[countryExp] = 0
                        })
                        globalCountrySkill.no_country_com = 0
                        globalCountrySkill.no_country_expert = 0
                    }
                    else {
                        globalCountrySkill.se_com = 0
                        globalCountrySkill.se_expert = 0
                        globalCountrySkill.no_com = 0
                        globalCountrySkill.no_expert = 0
                        globalCountrySkill.fi_com = 0
                        globalCountrySkill.fi_expert = 0
                        globalCountrySkill.dk_com = 0
                        globalCountrySkill.dk_expert = 0
                        globalCountrySkill.no_country_com = 0
                        globalCountrySkill.no_country_expert = 0
                    }


                    if (item.crew){
                        item.crew.forEach(function(v2) {

                            let skill = context.skillEntity.getItem(v2.skill)
                            let skillLabel = {
                                'skill_label' : skill ? skill.label : ''
                            }

                            let location_label = ''
                            if (v2.location !== null){
                                location_label = ", " + context.getLocationEntityItemLabel(v2.location)[0].label;
                            }
                            //if(v2.location !== null && v2.location.hasOwnProperty('label'))
                            //location_label = ", "+v2.location.label

                            if (typeof skill !== 'undefined' && skill !== null && skill.userfield1_value && skill.userfield1_value) {

                                if (skill.userfield1_value === 'yes' && (v2.location !== null && context.getLocationEntityItemLabel(v2.location)[0].country === null)) {
                                    globalCountrySkill.no_country_com++
                                    let dynamic_no_country_com = []
                                    dynamic_no_country_com['Commentator_ENG_' + globalCountrySkill.no_country_com] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                    external_value = $.extend(external_value, dynamic_no_country_com)
                                }
                                if (skill.userfield2_value === 'yes' && (v2.location !== null && context.getLocationEntityItemLabel(v2.location)[0].country === null)) {
                                    globalCountrySkill.no_country_expert++
                                    let dynamic_no_country_expert = []
                                    dynamic_no_country_expert['Expert_ENG_' + globalCountrySkill.no_country_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                    external_value = $.extend(external_value, dynamic_no_country_expert)
                                }

                                if(skill.country && skill.country !== '') {
                                    let countryCom = skill.country + '_com'
                                    let countryExp = skill.country + '_expert'
                                    if (skill.userfield1_value === 'yes') {
                                        globalCountrySkill[countryCom]++
                                        let dynamic_com = []
                                        dynamic_com['Commentator_'+ skill.country + '_' + globalCountrySkill[countryCom]] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                        external_value = $.extend(external_value, dynamic_com)
                                    }
                                    if (skill.userfield2_value === 'yes') {
                                        globalCountrySkill[countryExp]++
                                        let dynamic_exp = []
                                        dynamic_exp['Expert_'+ skill.country + '_' + globalCountrySkill[countryExp]] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                        external_value = $.extend(external_value, dynamic_exp)
                                    }
                                }

                                // if (skill.userfield1_value === 'yes' && skill.country === 'se') {
                                //     globalCountrySkill.se_com++
                                //     let dynamic_se_com = []
                                //     //dynamic_se_com['Commentator_se_'+se_com] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_se_com['Commentator_se_' + globalCountrySkill.se_com] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_se_com)
                                // }
                                // //external_value = $.extend( external_value, {'Commentator, SE': v2.user.name+', '+v2.location.country} )

                                // if (skill.userfield2_value === 'yes' && skill.country === 'se') {
                                //     globalCountrySkill.se_expert++
                                //     let dynamic_se_expert = []
                                //     //dynamic_se_expert['Expert_se_'+se_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_se_expert['Expert_se_' + globalCountrySkill.se_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_se_expert)
                                // }


                                // if (skill.userfield1_value === 'yes' && skill.country === 'no') {
                                //     globalCountrySkill.no_com++
                                //     let dynamic_no_commentator = []
                                //     //dynamic_no_commentator['Commentator_no_' + no_com] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_no_commentator['Commentator_no_' + globalCountrySkill.no_com] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_no_commentator)
                                // }

                                // if (skill.userfield2_value === 'yes' && skill.country === 'no') {
                                //     globalCountrySkill.no_expert++
                                //     let dynamic_no_expert = []
                                //     //dynamic_no_expert['Expert_no_'+no_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_no_expert['Expert_no_' + globalCountrySkill.no_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_no_expert)
                                // }

                                // if (skill.userfield1_value === 'yes' && skill.country === 'fi') {
                                //     globalCountrySkill.fi_com++
                                //     let dynamic_fi_commentator = []
                                //     //dynamic_fi_commentator['Commentator_fi_' + fi_com] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_fi_commentator['Commentator_fi_' + globalCountrySkill.fi_com] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_fi_commentator)
                                // }

                                // if (skill.userfield2_value === 'yes' && skill.country === 'fi') {
                                //     globalCountrySkill.fi_expert++
                                //     let dynamic_fi_expert = []
                                //     //dynamic_fi_expert['Expert_fi_'+fi_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_fi_expert['Expert_fi_' + globalCountrySkill.fi_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_fi_expert)
                                // }

                                // if (skill.userfield1_value === 'yes' && skill.country === 'dk') {
                                //     globalCountrySkill.dk_com++
                                //     let dynamic_dk_com = []
                                //     //dynamic_dk_com['Commentator_dk_'+dk_com] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_dk_com['Commentator_dk_' + globalCountrySkill.dk_com] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_dk_com)
                                // }

                                // if (skill.userfield2_value === 'yes' && skill.country === 'dk') {
                                //     globalCountrySkill.dk_expert++
                                //     let dynamic_dk_expert = []
                                //     //dynamic_dk_expert['Expert_dk_'+dk_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username+', '+skill.countryUpper+location_label : ((v2.user !== null) ? v2.user.username+', '+skill.countryUpper : '')
                                //     dynamic_dk_expert['Expert_dk_' + globalCountrySkill.dk_expert] = (v2.location !== null && v2.user !== null) ? v2.user.username + location_label : ((v2.user !== null) ? v2.user.username : '')
                                //     external_value = $.extend(external_value, dynamic_dk_expert)
                                // }


                            }
                        })
                    }
                }

                const satPolarization = (item.hasOwnProperty('deliveryTypeData') && item.deliveryTypeData !== null && item.deliveryTypeData.satPolarization !== null && context.polarizationEntity.getItem(item.deliveryTypeData.satPolarization) !== null) ? context.polarizationEntity.getItem(item.deliveryTypeData.satPolarization).label :''
                const satSatellite = (item.hasOwnProperty('deliveryTypeData') && item.deliveryTypeData !== null && item.deliveryTypeData.satSatelliteId !== null && context.satelliteEntity.getItem(item.deliveryTypeData.satSatelliteId) !== null) ? context.satelliteEntity.getItem(item.deliveryTypeData.satSatelliteId).label :((item.hasOwnProperty('deliveryTypeData') && item.deliveryTypeData !== null && item.deliveryTypeData.satSatellite !== null) ? item.deliveryTypeData.satSatellite : '')
                const satFrq = (item.hasOwnProperty('deliveryTypeData') && item.deliveryTypeData !== null && item.deliveryTypeData.satFrq !== null) ? item.deliveryTypeData.satFrq :''

                const contributionInfo = (item.hasOwnProperty('info') && item.elementType === "contribution") ? item.info :''

                var contributionId = (item.hasOwnProperty('itemId') && item.elementType === "contribution") ? item.itemId :''



                const equipment = (item.hasOwnProperty('equipment') && item.equipment !== null && item.elementType !== null && typeof item.elementType !== 'undefined') ? context.getEquipmentFormatted(item.equipment, item.elementType, item.id) : ''



                let equipmentLabel = ''
                let equipmentLabelArr = []

                if(equipment.length > 0){

                    for (let i = 0; i < equipment.length; i++){

                        if( equipment[i].hasOwnProperty('items') && equipment[i].items.length > 0){

                            for (let j = 0; j < equipment[i].items.length; j++){
                                if(!equipmentLabelArr.includes(equipment[i].items[j].label))
                                    equipmentLabelArr.push(equipment[i].items[j].label)
                            }
                        }
                    }

                }

                // Add the contributions in the list
                if(item.elementTypeDetailed === "production" && item.hasOwnProperty('contributions')){

                    for (let k = 0; k < item.contributions.length; k++){
                        let equipment_filter = item.equipment.filter(function (equipmnt) {
                            if(equipmnt.equipmentUsage.id === item.contributions[k].id)
                                return equipment;
                        });


                        //if((equipment_filter.length > 0 && equipment_filter[0].hasOwnProperty('notCountryRestricted') && equipment_filter[0].notCountryRestricted === true) || (item.contributions[k].country === app.$data.user.get.country) && (!equipmentLabelArr.includes(item.contributions[k].info)))
                        if((equipment_filter.length > 0 && _.includes(equipment_filter[0].destination, app.$data.user.get.country)) || (item.contributions[k].country === app.$data.user.get.country) && (!equipmentLabelArr.includes(item.contributions[k].info)))
                            if(!equipmentLabelArr.includes(item.contributions[k].info))
                                equipmentLabelArr.push(item.contributions[k].info)
                    }
                }
                equipmentLabel = equipmentLabelArr.join(', ');

                /*if(item.id === 6913)
                    console.log('Equipment label: '+equipmentLabel)*/
                /*let info = []


                if(satSatellite !== '')
                    info['satSatellite'] = satSatellite
                if(satPolarization !== '')
                    info['satPolarization'] = satPolarization
                if(satFrq !== '')
                    info['satFrq'] = satFrq
                if(contributionInfo !== '')
                    info['contributionInfo'] = contributionInfo

                let infoArr = Object.values(info)

                if(infoArr.length > 0){
                    infoStr = infoArr.join(' | ')
                }*/



                // If the contribution has multiple feeds and contribution associated with it, the info of that associations should be displayed in the info column of that contribution
                //Get the info of the item

                let infoStr = ''
                let info = []

                if(item.elementTypeDetailed === "production") {
                    if (item.associations) {
                        let associated_feeds = item.associations.filter(function (assoc) {
                            return assoc.elementType === "feed"
                        });

                        associated_feeds.forEach(function (feed) {
                            if (context.getItemInfo(feed, feed.id) !== '')
                                info.push(context.getItemInfo(feed, feed.id));
                        });
                    }

                    if(info.length > 0){
                        infoStr = info.join(' , ')
                    }

                    if(item.hasOwnProperty('runTypeLabel')) {
                        if(infoStr !== '')
                            infoStr = infoStr+", "+item.runTypeLabel
                        else
                            infoStr = item.runTypeLabel
                    }

                } else {
                    if(item.elementTypeDetailed === "production"){
                        infoStr = item.runTypeLabel

                    } else{

                        infoStr = context.getItemInfo(item);
                    }
                }

                let itemRunType = ''
                if(item.runTypeLabel) {
                    itemRunType = item.runTypeLabel
                } else if(item.runType) {
                    const runType = context.runTypeEntity.getItem(item.chanrunTypenel)
                    if (runType) {
                        itemRunType = runType ? runType.label : ''
                        itemCountry = itemCountry == '' && runType ? runType.country : ''
                    }
                }

                let userHouseNumber = ''
                if(item.houseNumber !== null && typeof item.houseNumber !== 'undefined'
                    && item.houseNumber.indexOf("{'") > -1) {
                    let currentHouseNumber = JSON.parse(item.houseNumber)
                    if(currentHouseNumber.hasOwnProperty(app.$data.user.get.country)) {
                        userHouseNumber = currentHouseNumber[app.$data.user.get.country]
                    }
                }

                let outputitem =
                    {
                        "Week" : (item.startTime !== null) ? moment.unix(item.startTime).isoWeek() : '',
                        "Day" : (item.startTime !== null) ? moment.weekdays(moment.unix(item.startTime).weekday()) : '',
                        "Date" : (item.startTime !== null) ? moment.unix(item.startTime).format('YYYY-MM-DD') : '',
                        "Type" : (item.elementTypeDetailed !== null) ? item.elementTypeDetailed : '',
                        "Start" : (item.startTime !== null) ? moment.unix(item.startTime).format('HH:mm') : '',
                        "End" : (item.endTime !== null) ? moment.unix(item.endTime).format('HH:mm'): '',
                        "Channel" : channelLabel,
                        "Sport" : sportLabel,
                        "Event" : eventlLabel,
                        "Teams" : item.title,
                        "HouseNumber" : userHouseNumber,
                        "ID" : (item.hasOwnProperty('itemId') && item.itemId !== null) ? item.itemId : ((item.hasOwnProperty('id') && item.id !== null) ? item.id : ''),
                        "Info" :  infoStr,
                        "Equipment" : equipmentLabel,
                        'Notes': item.notes,
                        "Prod" : prodLead,
                        "Country": itemCountry,
                        "RunType": itemRunType
                    }


                let allLocalnotes = null

                if(item.hasOwnProperty('localNotes')/*  && item.elementType !== "production" */)
                    allLocalnotes = item.localNotes

                let calcLocalNote = ''

                let localNotes = {}
                if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                    context.$store.state.system.countries.forEach(i => {
                        let noteKey = 'note_' + i.code
                        localNotes[noteKey] = ''
                    })

                }
                else {
                    localNotes.note_se = ''
                    localNotes.note_no = ''
                    localNotes.note_dk = ''
                    localNotes.note_fi = ''
                }


                if(allLocalnotes !== null){
                    for (var i = 0; i < allLocalnotes.length; i++) {
                        if(allLocalnotes[i].country && allLocalnotes[i].country !== '') {
                            let countryKey = 'note_' + allLocalnotes[i].country
                            localNotes[countryKey] = allLocalnotes[i].value;
                        }
                        // if (allLocalnotes[i].country == 'se') {
                        //     note_se = allLocalnotes[i].value;
                        // }
                        // if (allLocalnotes[i].country == 'dk') {
                        //     note_dk = allLocalnotes[i].value;
                        // }
                        // if (allLocalnotes[i].country == 'no') {
                        //     note_no = allLocalnotes[i].value;
                        // }
                        // if (allLocalnotes[i].country == 'fi') {
                        //     note_fi = allLocalnotes[i].value;
                        // }
                    }
                }

                let calcNotes = {}
                if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                    context.$store.state.system.countries.forEach(i => {
                        let calcCountry = 'calcLocalNote_' + i.code
                        let noteKey = 'note_' + i.code
                        calcNotes[calcCountry] = (allLocalnotes) ? localNotes[noteKey] : ''
                    })

                }
                else {
                    calcNotes.calcLocalNote_se = (allLocalnotes) ? localNotes.note_se : ''
                    calcNotes.calcLocalNote_no = (allLocalnotes) ? localNotes.note_no  : ''
                    calcNotes.calcLocalNote_dk = (allLocalnotes) ? localNotes.note_dk : ''
                    calcNotes.calcLocalNote_fi = (allLocalnotes) ? localNotes.note_fi : ''
                }


                let localNote = {}
                if(context.$store.state.system.countries && context.$store.state.system.countries.length > 0) {
                    context.$store.state.system.countries.forEach(i => {
                        let noteKey = 'Local Notes ' + i.code.toUpperCase()
                        let noteValue = 'calcLocalNote_' + i.code
                        localNote[noteKey] = calcNotes[noteValue]
                    })
                }
                else {
                    localNote = {
                        'Local Notes SE': calcNotes.calcLocalNote_se,
                        'Local Notes NO': calcNotes.calcLocalNote_no,
                        'Local Notes DK': calcNotes.calcLocalNote_dk,
                        'Local Notes FI': calcNotes.calcLocalNote_fi
                    }
                }

                outputitem = $.extend(outputitem,  localNote)

                if( outputitem !== null){
                    outputitem = $.extend(outputitem, external_value )
                }

                output.push(outputitem)

            })
            this.waitingUpdates.json = output
            return output
        },

        processing() {
            return this.$loader.has('BookMcrProcessing')
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        setting() {

            // Return settings for equipment, feeds and productions.
            return Object.assign({},
                this.$settings.get.equipment,
                this.$settings.get.feeds,
                this.$settings.get.productions,
            )

        },
        contributions(){
            return this.$store.state.data.equipment.filter(t => t.type === 60).sort();
        },
        hasPreservedFilters() {
            return !!this.$store.state.filter.preserve
        },
        filterInfo() {
            const filterInfo = {
                visible: 0,
                total: 0
            }
            if (!this.loaded || !this.filterReady) {
                return filterInfo
            }
            filterInfo.visible = this.currentNumItems
            filterInfo.total = this.items.length
            return filterInfo
        },
        filterIsAvailable() {
            return !Object.values(this.filters).every(v => !v)
        },
        getAllItems() {
            if (!this.$store.state.data.mcrdata) {
                return []
            }
            return this.$store.state.data.mcrdata
        },
        items() {

            if (!this.loaded) return []

            let output = []

            // Retrieve all loaded feeds/production and mcrComments to parse them.
            // if it has a title or comment
            output = this.getAllItems
                .filter(f => { return f.title || this.isMCRComment(f) })
                .map(v => {
                    Item.parse(v)
                    return v
                })

            // Find contribution items and them to the items.
            let contributionItems = this.getContributionItems(output, 'main')
            if (contributionItems) {
                output = output.concat(contributionItems)
            }

            // Parse the associations and add contributions to the items.
            Lazy(output)
                .each(item => {
                    let weight
                    switch (item.elementTypeDetailed) {
                        case 'feed':
                            weight = 1
                            break
                        case 'production':
                            weight = 3
                            break
                        case 'mcrComment':
                            weight = 5
                            break
                        case 'contribution':
                            weight = (item.itemType === 'feed') ? 2 : 4
                            break
                        case 'event':
                            weight = 6
                            break;
                        case 'task':
                            weight = 7
                            break;
                    }
                    Vue.set(item, 'weight', weight)
                    // Parse the associations.
                    if (item.associations) {
                        //we should only display tasks that have workgroups and workgroup is flagged for displaying on MCR
                        item.associations = item.associations.filter(a => {
                            if (a.elementType === 'event') return true
                            if(a.productionType && a.productionType !== 'task') return true
                            if(a.productionType && a.productionType === 'task') {
                                if(a.workgroups && a.workgroups.length > 0) {
                                    let isVisible = false
                                    a.workgroups.forEach(w => {
                                        let currentW = this.workgroupsEntity.get.items.find(i => i.id === w.workgroup)
                                        if(currentW && currentW.userfield1_value === 'yes') isVisible = true
                                    })

                                    return isVisible
                                }
                                else return false
                            }

                            return true
                        })

                        Lazy(item.associations)
                            //.filter(a => { if (a.elementType === 'event') return false })
                            .each(association => {
                                // MCR-View broken association fix. (equipmentFormatted issue also here)
                                if (association.title) {
                                    Item.parse(association)
                                }
                                else {
                                    let assocIngest = new Mcrdata(association.id)
                                    assocIngest.add(Object.assign({}, association))
                                    console.log(assocIngest.get, 'yey')
                                    Item.parse(assocIngest.get)
                                    // its means that we dont have it on the list, we should add it
                                    // so page wont cause leaks
                                    //assocIngest.add(association)
                                    //Vue.set(association, 'elementType', 'production')
                                    //Vue.set(association, 'title', 'This association is broken, contact: Developer')
                                }

                                // Find all contributions for the current association.
                                // Note that this.getContributionItems() is built
                                // to handle an array of items, why we need to
                                // wrapper "association" with [].
                                let contributions = this.getContributionItems([association], 'inside')
                                if (contributions) {
                                    Vue.set(association, 'contributions', contributions)
                                }

                            })
                    }

                    // Find contributions for the current item and parse them.
                    // Here we don't need to call this.getContributionItems()
                    // because all contributions for the current item are already
                    // available in 'output', so we only need to find them.
                    let contributions = output.filter(v => (v.elementTypeDetailed === 'contribution') && (v.itemId === item.id))
                    if (contributions.length > 0) { Vue.set(item, 'contributions', contributions) }

                    const mcrComments = this.getMCRCommentsOfItem(item)
                    if (mcrComments && Array.isArray(mcrComments) && mcrComments.length > 0) {
                        Vue.set(item, 'mcrComment', mcrComments)
                    }


                })
            return output
        },

        itemsFiltered() {
            if (!this.loaded || !this.filterReady) {
                return []
            }
            const items = Lazy(this.items)
                .filter(item => {
                    let hideFin = this.hideFin ? this.filterHideFinished(item) : true
                    if (!this.filterIsAvailable) return true
                    return Item.isFiltered(this.filters, item, {league: this.leagueEntity, sport: this.sportEntity, channel: this.channelEntity, ingestions: this.ingestEntity, equipmentType: this.equipmentTypeEntity, activityTypes: this.activityTypesEntity}, true, hideFin)
                }).toArray()

            this.currentNumItems = items.length
            return items
        },
        getSelectedItemEquipment() {
            if (!this.selectedItem) return []
            return Lazy(this.selectedItem.equipment)
                .filter(v => _.includes(v.destination, app.$data.user.get.country)/*  === v.country */)
                .toArray()
        },
        getEntitiesForSidebar() {
            const output = {}
            Lazy(this.entities)
                .each((v, k) => {
                    output[k] = this[k]
                })
            return output
        },
        loadingSelectedItem() {
            return this.$loader.has('loadingSelectedItem')
        },

        /**
         * Build the items to be sent into BookMcrView.
         * @return object
         *
         * When we load BookEquipmentSelection inside a sidebar, new
         * entities are loaded which triggers reactivity on whole store.state.data.entity
         * array, causing itemsListData() to be recomputed and then all "expand"
         * are set to false, collapsing all items. We need to find a solution
         * for this...
         *
         * WN: I've fixed this issue by propping the entities from MCR to
         * directly into sidebar, and also propped other required data for
         * avoid unnecessary render-reactivity.
         */
        itemsListData() {
            //When we open sidebar, itemListData gets called cause of rendering
            //And when itemList called all MCR page re-rendering.
            if (this.waitingUpdates.items.length > 0) {
                console.log('itemsListData, waiting to load full changes')
                return this.waitingUpdates.page
            }
            if (this.recievingUpdates.items.length > 0) {
                console.log('itemsListData, waiting to UPDATE full changes')
                return this.waitingUpdates.page
            }
            // we added 1 fake for sending the data
            if (this.sendingUpdates.items.length > 0) {
                const ingest = new Updates()
                ingest.add(this.sendingUpdates)
                console.log(ingest, 'UPDATES!!')
                ingest.sendUpdates()
                    .then(response => {
                        this.$alert.set('All items has been saved!', 'success', 5)
                        this.sendingUpdates = {
                            items: [],
                            total: 0,
                            updates: false
                        }
                        console.log(response.data)
                    })
            }
            console.log("itemsListData", this.sidebar);
            let output = {}
            if (!this.loaded) return output
            output.options = {
                actions: ['edit', 'preview'],
                actionSettings: {
                    edit: {
                        label: 'Open',
                        classes: ['btn', 'btn-primary', 'btn-xs']
                    }
                },
                ingestions: ['notrecord', 'repeat', 'share'],
                ingestSettings: {
                    readonly: true
                }
            }
            output.columns = [
                {
                    value: 'type',
                    label: 'Type',
                    style: {width: '82px'}
                },
                {
                    value: 'time',
                    label: 'Time',
                    // sortable: true,
                    style: {width: '101px'}
                },
                {
                    value: 'date',
                    label: 'Date',
                    visible: false
                },
                {
                    value: 'league',
                    label: 'League',
                    // sortable: true,
                    style: {width: '120px'}
                },
                {
                    value: 'title',
                    label: 'Title',
                    style: {width: '240px'},
                    // sortable: true,
                    title: true,
                    marker: true,
                },
                {
                    value: 'info',
                    label: 'Info',
                    style: {width: '340px'},
                    title: true,
                },
                {
                    value: 'equipment'
                },
                {
                    value: 'associations',
                },
                // {
                //     value: 'placeholders',
                //     visible: false
                // }
            ]
            output.rows = []

            Lazy(this.itemsFiltered)
                .filter(item => {
                    return (item.runType !== 89 && item.runType !== 90)
                })
                .each(item => {
                    if (item.runType !== 89 && item.runType !== 90) {

                        if (!item.title && !this.isMCRComment(item)) return

                        let countries
                        const row = this.setItemRowData(item)

                        if (this.isMCRComment(item) && item.forId) {
                            row.forId = item.forId
                            row.forType = item.forType
                        }

                        // Build the contributions for the current item.
                        if (item.contributions) {
                            row.contributions = []
                            Lazy(item.contributions)
                                //.filter(contribution => {
                                //    if (!this.filterIsAvailable) return true
                                //    return Item.isFiltered(this.filters, contribution, {league: this.leagueEntity, sport: this.sportEntity, channel: this.channelEntity})
                                //})
                                .each(contribution => {
                                    row.contributions.push(this.getContributionFormatted(contribution))
                                })
                        }

                        // Build the equipment list for the current item.
                        if (item.equipment && item.equipment.length > 0) {
                            if (typeof item.elementTypeDetailed !== 'undefined' || item.elementTypeDetailed) {
                                row.equipment = this.getEquipmentFormatted(item.equipment, item.elementTypeDetailed)
                            }
                        }

                            // Equipment on a contribution is the same as the feed/production
                        // the contribution belongs to.
                        else if (item.elementTypeDetailed === 'contribution') {
                            const parentItem = item.associations.find(v => v.id === item.itemId && v.elementTypeDetailed === item.itemType)
                            if (parentItem) {
                                row.equipment = this.getEquipmentFormatted(parentItem.equipment, 'contribution')
                            }
                        }

                        // Build the associations for the current item.
                        if (item.associations) {
                            row.associations = []
                            _(item.associations)
                                //.filter(a => { if (a.elementType === 'event') return false })
                                .each(association => {
                                    //@TODO even on the SQL query we are filtering (89,90) runType but still they are appearing..
                                    if (association.runType !== 89 && association.runType !== 90) {

                                        if (association.recordRequested !== undefined && !association.recordRequested) {
                                            association.recordRequested = []
                                        }

                                        if (association.elementType === 'event') {
                                            Item.parse(association, 'event')
                                            Vue.set(association, 'elementTypeDetailed', 'event')
                                        }
                                        if (association.elementTypeDetailed === 'production') {
                                            countries = this.channelEntity.getItem(association.channel) ? this.channelEntity.getItem(association.channel) : ''
                                            countries = countries ? countries.country : 'uganda'

                                            if (countries === 'uganda') {
                                                Vue.set(association, 'title', 'This association has broken COUNTRY, contact: Developer')
                                            }

                                        }

                                        if (association.elementTypeDetailed === 'feed') {
                                            countries = association.mcr
                                        }

                                        if (association.elementTypeDetailed === 'task') {
                                            countries = this.activityTypesEntity.getItem(association.runType)
                                            countries = countries.country ? countries.country : (this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['dk','fi','no','se','uk'])
                                        }
                                        // something related with elementType cntb

                                        const league = this.leagueEntity.getItem(association.league)
                                        const userCountry = app.$data.user.get.country

                                        let typeValue = this.getTypeValue(association)
                                        let typeValueId = this.getTypeValue(association, 'id')
                                        const houseNumberValues = this.showHouseNumberField(association)

                                        let itemToAdd = {
                                            type: {
                                                rawValue: association.elementType,
                                                value: typeValue,
                                                extraValue: association.elementTypeDetailed,
                                                valueId: typeValueId
                                            },
                                            id: {
                                                value: association.id
                                            },
                                            time: {
                                                value: this.getItemTime(association),
                                                startTime: association.startTime,
                                                endTime: association.endTime
                                            },
                                            league: {
                                                rawValue: item.league ? item.league : '',
                                                value: league ? league.label : ''
                                            },
                                            title: {
                                                value: association.title
                                            },
                                            documents: association.documents && association.documents.length > 0 ? association.documents : false,
                                            equipment: null,//
                                            rowOptions: {
                                                ingestions: association.ingestions,
                                                mcrStatus: association.mcrStatus ? association.mcrStatus : (association.mcrstatus ? association.mcrstatus : null),
                                                type: association.elementType,
                                                id: association.id,
                                                country: countries,
                                                documents: association.documents && association.documents.length > 0 ? association.documents : false,
                                            },
                                            houseNumber: {
                                                value: houseNumberValues['value'],
                                                rawValue: houseNumberValues['rawValue'],
                                                forkMappedCountries: houseNumberValues['forkMappedCountries'],
                                                forkMappedCountryCount: houseNumberValues['forkMappedCountryCount']
                                            },
                                            recordRequested: {
                                                value: this.getRecordRequest(association.recordRequested),
                                                rawValue: this.getRecordRequest(association.recordRequested)
                                            },
                                            info: {
                                                value: this.getInfo(association)
                                            },
                                        }

                                        // Build the equipment list for the current item.
                                        if (association.equipment && association.equipment.length > 0) {
                                            // PARSE issue, cant find the type (if occurs) wn
                                            //if (typeof association.elementType !== 'undefined' || association.elementType) {
                                            itemToAdd.equipment = this.getEquipmentFormatted(association.equipment, association.elementTypeDetailed)
                                            //}
                                        }

                                        if (association.contributions) {
                                            itemToAdd.contributions = []
                                            _(association.contributions)
                                                .each(contribution => {
                                                    // assoc cntb
                                                    itemToAdd.contributions.push(this.getContributionFormatted(contribution))
                                                })
                                        }

                                        row.associations.push(itemToAdd)
                                    }
                                })
                        }

                        output.rows.push(row)
                    }
                })


            Lazy(this.itemsFiltered)
                .filter(item => {
                    return this.itemsFiltered.findIndex(i => item.forId && i.id === item.forId) > -1
                })
                .each(item => {
                    if (item.forId) {
                        // and delete from the main list
                        let _it = output.rows.findIndex(i => i.type.extraValue === 'mcrComment' && item.id === i.id.rawValue)
                        if (_it > -1) output.rows.splice(_it,1)
                    }
                })

            // group options
            const monthName = item => item.date.value;
            const groups = _(output.rows)
                .groupBy(monthName)
                .value()

            // this is the first of its groups
            let _g = 0
            _(groups).each(e => { if (Array.isArray(e)) { _g++ } })
            _(groups).each(e => {
                if (Array.isArray(e) && e.length > 0 && _g > 1) {
                    e[0].date.group = 1
                }
            })

            this.waitingUpdates.pageItems = this.items
            this.waitingUpdates.page = output
            return output
        },
        getEquipmentFiltered() {
            return this.$store.state.data.equipment
        },
        mcrCommentAccess() {
            return this.$ability.can('create_mcr_comment', 'McrComments')
        }
    },
    beforeRouteLeave(to, from, next) {
        const context = this

        // check if we have waiting updates
        // changes should be saved.
        if (context.waitingUpdates && context.waitingUpdates.items.length > 0) {
            // No more ask more than 1 time. WN
            if (context.asking > 0) return next()
            context.asking = 1
            // WN
            swal({
                title: 'Have changes!',
                text: 'You have made changes on MCR-View, please save or revert them back before you leave.',
                type: 'question',
                confirmButtonText: 'Save',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
                .then(function() {
                    app.$emit('saveMyChanges')
                }, function(dismiss) {
                    if (dismiss == 'cancel') {
                        app.$emit('clearTimers')
                        context.cleanWaitings()
                        next()
                    }
                })
                .catch(() => {
                    next()
                })
        }
        else {
            next()
        }

    },
    created() {
        app.$on('getUpdates', (data) => {
            console.log('Saving ....', data)
            let author
            if (data.author) {
                author = data.author
                this.$alert.set(`Got Updates! Items has been updated by ${author}`, 'success', 7)
            } else { this.$alert.set('Got Updates! Items has been updated!', 'success', 7) }
            // set 1 for sending / recieving updates
            if (this.waitingUpdates.items.length > 1) {
                // update the data for View first
                this.updateAction(data)
            }
            // and update the data at backend silently no matter what
            this.saveUpdates(data)
        })
        app.$on('revertBackChanges', (data) => {
            // just clear the waitings
            this.cleanWaitings(true)
            console.log('reloading ....', this)
        })
        // Listen to Ingestion updates.
        app.$on('changedIngestions', (data) => {
            this.updateIngestions(data)
        })

        // Listen to McrStatus updates.
        app.$on('changedMcrStatus', (data) => {
            this.updateMcrStatus(data)
        })

        app.$on('showFeedbackModal', (data) => {
            this.displayFeedbackModal(data)
        })

        // Listen to McrStatus deletes.
        app.$on('deleteMcrStatus', (data) => {
            this.updateMcrStatus(data)
        })

        // Listen to McrStatus updates from Socket
        app.$on('changedMcrStatusBySocket', (data) => {
            this.updateMcrStatusBySocket(data)
        })

        // Listen to McrStatus deletes from Socket
        app.$on('deletedMcrStatusBySocket', (data) => {
            console.log('not used area', data)
        })

        app.$on('updatedHouseNumbers', (data) => {
            this.updateHouseNumbers(data)
        })
        // equipment
        app.$on('changeOfEquipments', (data) => {
            this.changeOfEquipments(data)
        })
        // placeholders
        app.$on('changeOfPlaceholders', (data) => {
            this.changeOfPlaceholders(data)
        })
        app.$on('creatingEquipment', (data) => {
            this.updateEquipmentFinal(data, 'create')
        })
        app.$on('changingEquipment', (data) => {
            this.updateEquipmentFinal(data, 'update')
        })
        app.$on('changedEquipment', (data) => {
            this.updateEquipmentFinal(data, 'update')
        })
        app.$on('deletingEquipment', (data) => {
            this.updateEquipmentFinal(data, 'delete')
        })
        app.$on('mcrCommentActions', (data) => {
            this.commentActions(data)
        })
        app.$on('closeCommenter', () => {
            this.closeCommenter()
        })
        app.$on('updateRecPlaceholder', (data) => {
            //if (data && data.action) this.updateAction(data)
            this.updateRecPlaceholder(data)
        })
        app.$on('ResetPlaceholderForm', () => {
            this.resetPlaceholderForm()
        })
    },
    beforeMount() {
        if (this.hasDarkMode) {
            this.darkMode = this.$store.state.filter.darkMode
        }
    },
    beforeDestroy() {
        // Close all event listeners..
        app.$off()
        this.darkMode = false
        this.modeSwitcher('light')
    },
    mounted() {
        console.log('BookMCR - Mounted')

        // check if the listeners are active
        if (app._events.getUpdates === null) {
            app.$on('getUpdates', (data) => {
                let author
                if (data.author) {
                    author = data.author
                    this.$alert.set(`Got Updates! Items has been updated by ${author}`, 'success', 7)
                } else { this.$alert.set('Got Updates! Items has been updated!', 'success', 7) }
                if (this.waitingUpdates.items.length > 1) { this.updateAction(data) }
                this.saveUpdates(data)
            })
            app.$on('revertBackChanges', (data) => { this.cleanWaitings(true) })
            app.$on('changedIngestions', (data) => { this.updateIngestions(data) })
            app.$on('changedMcrStatus', (data) => { this.updateMcrStatus(data) })
            app.$on('showFeedbackModal', (data) => { this.displayFeedbackModal(data) })
            app.$on('deleteMcrStatus', (data) => { this.updateMcrStatus(data) })
            app.$on('changedMcrStatusBySocket', (data) => { this.updateMcrStatusBySocket(data) })
            app.$on('deletedMcrStatusBySocket', (data) => { console.log('not used area', data) })
            app.$on('updatedHouseNumbers', (data) => { this.updateHouseNumbers(data) })
            app.$on('changeOfEquipments', (data) => { this.changeOfEquipments(data) })
            app.$on('changeOfPlaceholders', (data) => { this.changeOfPlaceholders(data) })
            app.$on('creatingEquipment', (data) => { this.updateEquipmentFinal(data, 'create') })
            app.$on('changingEquipment', (data) => { this.updateEquipmentFinal(data, 'update') })
            app.$on('changedEquipment', (data) => { this.updateEquipmentFinal(data, 'update') })
            app.$on('deletingEquipment', (data) => { this.updateEquipmentFinal(data, 'delete') })
            app.$on('closeCommenter', () => { this.closeCommenter() })
            app.$on('mcrCommentActions', (data) => { this.commentActions(data) })
            app.$on('updateRecPlaceholder', (data) => { this.updateRecPlaceholder(data) })
            app.$on('ResetPlaceholderForm', () => { this.resetPlaceholderForm() })
        }



        if (this.darkMode) {
            this.modeSwitcher('dark')
            jQuery('#checkbox').prop('checked', true);
        } else {
            this.modeSwitcher('light')
            jQuery('#checkbox').prop('checked', false);
        }

        this.setLoaded(false)
        this.loadEntities()
            .then(response => {

                const equipment = new Equipment()
                const dataToLoad = [
                    equipment.all()
                ]

                Promise.all(dataToLoad.map(p => {
                    // Return "undefined" for all rejected promises which allows
                    // us to handle entities even if equipment failed.
                    return p.catch(() => undefined)
                }))
                    .then(response => {
                        Vue.nextTick(() => {
                            if(this.allEquipments === null) {
                                this.allEquipments = this.$store.state.data.equipment
                            }
                        })
                    })
                    .catch(error => {
                        this.$error.set(error)
                    })

                this.loadData()
                    .then(response => {
                        this.setLoaded()
                        this.createFilters()

                    })
                    .catch(error => {
                        if (error.response && error.response.status != 404) {
                            this.$error.set(error, 'It was not possible to load the MCR data.')
                        }
                        this.setLoaded()
                    })
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the entities.')
            })


    },
    methods: {
        /**
         * checks the item, if it's a mcr comment
         * @param item
         * @returns {boolean}
         */
        isMCRComment(item) {
            return item.elementType === 'mcrComment' && item.comment
        },

        setItemRowData(item) {
            let typeValue = this.getTypeValue(item)
            let typeValueId = this.getTypeValue(item, 'id')
            let league
            // country selections for Ingestions
            let countries

            if (item.recordRequested !== undefined && !item.recordRequested) {
                item.recordRequested = []
            }

            if (item.elementTypeDetailed === 'production') {
                countries = this.channelEntity.getItem(item.channel)
                countries = countries.country
            }
            if (item.elementTypeDetailed === 'feed') {
                countries = item.mcr
            }
            if (item.elementTypeDetailed === 'task') {
                countries = this.activityTypesEntity.getItem(item.runType)
                countries = countries.country ? countries.country : (this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['dk','fi','no','se','uk'])
            }
            if (this.isMCRComment(item)) {
                countries = item.country
            } else league = this.leagueEntity.getItem(item.league)

            if (!countries) countries = app.$data.user.get.country

            let id = item.elementType === 'contribution' ? item.itemId : item.id
            let date = moment.unix(item.startTime).format('YYYY-MM-DD')

            let dateTime = this.getItemDateTime(item)
            let houseNumberValues = this.showHouseNumberField(item)
            return {
                type: {
                    rawValue: item.elementType,
                    value: typeValue,
                    extraValue: item.elementTypeDetailed,
                    idValue: typeValueId
                },
                id: {
                    rawValue: item.id,
                    value: id
                },
                color: '',
                time: {
                    rawValue: item.startTime,
                    value: this.getItemTime(item),
                    startTime: item.startTime,
                    endTime: item.endTime,
                    startDatetime: dateTime.start,
                    endDatetime: dateTime.end

                },
                date: {
                    group: 0,
                    value: date + ', ' + Datetime.getWeekDay(date)
                },
                league: {
                    rawValue: item.league ? item.league : '',
                    value: league ? league.label : ''
                },
                title: {
                    value: item.title ? item.title : (this.isMCRComment(item) ? item.comment : '')
                },
                info: {
                    value: this.getInfo(item)
                },
                after: item.mcrComment ? item.mcrComment : null,
                equipment: null,
                documents: item.documents && item.documents.length > 0 ? item.documents : false,
                associations: null,
                // placeholders: item.hasOwnProperty('placeholders') ? item.placeholders : null,
                ingests: {
                    value: 'ingests'
                },
                rowOptions: {
                    classes: [],
                    visible: true,
                    expanded: false,
                    documents: item.documents && item.documents.length > 0 ? item.documents : false,
                    hasActions: (item.elementTypeDetailed !== 'contribution' && item.elementTypeDetailed !== 'task' && !this.isMCRComment(item)),
                    weight: item.weight,
                    ingestions: item.ingestions,
                    mcrStatus: item.mcrStatus ? item.mcrStatus : (item.mcrstatus ? item.mcrstatus : null),
                    type: item.elementType,
                    id: item.id,
                    country: countries,
                    key: item.elementType + '_' + item.id
                },
                houseNumber: {
                    value: houseNumberValues['value'],
                    rawValue: houseNumberValues['rawValue'],
                    forkMappedCountries: houseNumberValues['forkMappedCountries'],
                    forkMappedCountryCount: houseNumberValues['forkMappedCountryCount']
                },
                recordRequested: {
                    value: this.getRecordRequest(item.recordRequested),
                    rawValue: this.getRecordRequest(item.recordRequested)
                }
            }
        },
        getRecordRequest(codes) {
            if (!codes || !Array.isArray(codes)) return false
            return !!codes.find(v => v === app.$data.user.get.country)
        },

        /**
         * Sets the bulk of updates that comes from
         * WN Lands :>
         * @param data
         */
        saveUpdates(data) {
            if (data.items.length > 0) {
                //this.recievingUpdates = data
                //this.$loader.add(this.processingId)
                const items = data.items
                Lazy(items).forEach(v => {
                    if (v.action.module === 'ingestion') {
                        this.updateIngestions(v)
                    }
                    if (v.action.name === 'HouseNumber') {
                        this.updateHouseNumbers(v)
                    }
                    if (v.action.module === 'McrStatus') {
                        this.updateMcrStatus(v)
                    }
                    if (v.action.module === 'equipment') {
                        this.changeOfEquipments(v)
                    }
                    if (v.action.module === 'placeholder') {
                        this.changeOfPlacehodlers(v)
                    }

                })
                //this.waiting.items.push([1,2,3])
            }
        },
        saveUpdate(v) {
            v.delete = true
            if (v.action.module === 'ingestion') {
                app.$emit('AutoChangeIngestions', v)
            }
            if (v.action.name === 'HouseNumber') {
                app.$emit('AutoChangeHouseNumber', v)
            }
            if (v.action.module === 'McrStatus') {
                app.$emit('AutoChangeMcrStatus', v)
            }
            if (v.action.module === 'equipment') {
                app.$emit('AutoChangeEquipments', v)
            }
            if (v.action.module === 'placeholder') {
                app.$emit('AddPlaceholder', v)
            }
        },
        modeSwitcher(mode) {
            if (mode === 'dark') {
                jQuery('body').css('background-color', 'black')
                jQuery('body').css('color', 'white')
                jQuery('#main-content').css('background', 'black')
                jQuery('.book-header-loader-text').css('color', 'black')
            }
            if (mode === 'light') {
                jQuery('body').css('background-color', '#fff')
                jQuery('body').css('color', '#333333')
                jQuery('#main-content').css('background', '#EEEEEE')
                jQuery('.book-header-loader-text').css('color', 'black')
            }
        },
        /**
         * Clears all data of waiting updates
         */
        cleanWaitings(sending = false) {
            if (sending) {
                this.sendingUpdates = {
                    items: [],
                    total: 0,
                    updates: false
                }
            }
            this.waitingUpdates = {
                updates: false,
                total: 0,
                items: [],
                page: {},
                json: [],
                pageItems: {}
            }
            Vue.nextTick(() => {
                jQuery('div.book-filter.book-bottom-sticky').css('flex-direction', '')
            })
        },
        updateAction(data, newUpdate = true) {
            console.log(data, 'incoming data')
            const ctx = this
            let updates = ctx.waitingUpdates
            const items = ctx.waitingUpdates.items
            if (newUpdate) {
                let ni
                _(data.items).each(n => {
                    // and save this data?
                    this.saveUpdate(n)
                })

                // if no data clear all
                if (items.length < 1) {
                    app.$emit('clearTimers')
                    this.cleanWaitings()
                    this.saveUpdates(data)
                }

                return
            }

            let action = data.action.type
            let itemId = data.item.id
            let itemIdLength = itemId.toString().length

            // remove the same existence
            if (items.length > 0) {
                items.slice(0)
                let i = items.findIndex(v => v.item.id === itemId)
                if (i > -1) items.splice(i, 1)
                console.log('FOUND USAGE - DELETE', itemId, i)
            }
            // if its delete operation dont add clone id (12 digit).
            if (itemIdLength > 11 && action !== 'delete') {
                console.log('FOUND USAGE CLONE', itemId)
                items.push(data)
                ctx.waitingUpdates.updates = true
            }
            // if its not a clone accept all operations
            if (itemIdLength < 12) {
                console.log('FOUND USAGE NORMAL', itemId, itemIdLength)
                items.push(data)
                ctx.waitingUpdates.updates = true
            }

            ctx.waitingUpdates.total = items.length

            if (ctx.waitingUpdates.total <= 0) {
                ctx.waitingUpdates.updates = false
            }
            console.log(this.waitingUpdates, "waiting updates");
        },
        getLocationEntityItemLabel(id){
            return Lazy(this.$store.state.system.locations[0].items)
                .filter(v => {
                    return v.id == id
                })
                .toArray()

        },
        startDownload(){
            this.$alert.set('Excel file is downloaded.', 'success', 3)
        },
        setDarkMode() {
            if (!this.darkMode) {
                this.preserveDarkMode = true
                this.darkMode = true
                this.modeSwitcher('dark')

            } else {
                this.preserveDarkMode = false
                this.darkMode = false
                this.modeSwitcher('light')
            }
        },
        /*
     * This is more appropriate usage of hide classes in VueJS,
     * since vue is reactive, should've coded this way :)
         */
        hideFinished() {
            if (this.hideFin) {
                this.hideFin = false
                return
            }
            let x  = document.querySelectorAll('.grey')
            if (x.length !== 0) {
                if (!this.hideFin) { this.hideFin = true }
            }
        },
        filterHideFinished(item) {
            let _visible = true
            let _country = app.$data.user.get.country
            let _mcrStatus = item.mcrStatus ? item.mcrStatus : (item.mcrstatus ? item.mcrstatus : null)
            if (_mcrStatus != null) {
                if (Array.isArray(_mcrStatus)) {
                    Lazy(_mcrStatus).each(v => {
                        if (v.country === _country && v.status === 4) _visible = false
                    })
                }
            }

            return _visible
        },
        setLoaded(loaded = true) {
            this.$store.commit('remove', {key: 'feed', id: 0})
            this.$store.commit('remove', {key: 'production', id: 0})
            Vue.nextTick(() => {
                this.loaded = loaded
                if (loaded) {
                    Vue.nextTick(() => {
                        this.updateItemFlags()
                    })
                }
            })
        },
        getPlace(){
            //console.log('GetLocations')
            //console.log(this.$store.state.system.locations[0].items)
        },
        /**
         * Set a filter value.
         * @param object filter
         */
        filteredBy(filters) {
            // this.filterReady = false
            this.filters = filters
            // Vue.nextTick(() => {
            //     if (this.loaded) {
            //         this.filterReady = true
            //     }
            // })
        },

        /**
         * Load required entities.
         */
        loadEntities() {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(context.entities)
                    .each((v, k) => {
                        if (context.setting[v]) {
                            entityIds.push(context.setting[v])
                        }
                    })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(context.entities)
                            .each((v, k) => {
                                context[k] = new Entity(context.setting[v])
                            })
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        loadSetData(data = []) {
            const itemClass = new Mcrdata()
            this.waitingUpdates = {
                updates: false,
                total: 0,
                items: [],
                page: {},
                json: []
            }
            this.setLoaded()
            Vue.nextTick(() => {
                jQuery('div.book-filter.book-bottom-sticky').css('flex-direction', '')
            })
            return itemClass.allSet(data)
        },

        /**
         * Load data from API.
         */
        loadData(setLoad = false) {
            // Build request params.
            const params = {
                from: this.$route.query.from,
                to: this.$route.query.from
                //to: moment(this.$route.query.from).add(1, 'day').format('YYYY-MM-DD')
            }

            // and return as McrData
            return new Promise((resolve, reject) => {
                this.setLoaded(setLoad)

                const dataToLoad = []
                dataToLoad.push(this.mcrdata.all(params, true))
                //dataToLoad.push(this.production.all(productionsParams, true))
                Promise.all(dataToLoad.map(p => {
                    // Return "undefined" for all rejected promises which allows
                    // us to handle "feeds" if no production was found and vice-versa.
                    return p.catch(() => undefined)
                }))
                    .then(response => {

                        // load equipment usages
                        this.equipmentUsages.all(params).then(response => {
                            this.allEquipmentUsages = response.data
                            this.applyEquipments()
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /**
         * Get a time period for an item (Feed/Production).
         */
        getItemTime(item) {
            let start = moment(item[item.elementType + 'Start'])
            let end = moment(item[item.elementType + 'End'])
            switch(item.elementType) {
                case 'mcrComment':
                    if (item.forId) {
                        let _item = this.getNewClass(item.forType, item.forId)
                        start = moment(_item.get[item.forType + 'Start'])
                        return `${start.format('HH:mm')}`
                    }
                    return `${start.format('HH:mm')}`

                case 'event':
                    if (item.postponed) return 'Postponed'
                    return `${start.format('HH:mm')}`
                default:
                    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`
            }
        },

        getItemDateTime(item) {
            const start = moment(item[item.elementType + 'Start'])
            const end = moment(item[item.elementType + 'End'])
            let dates = {}
            dates.start = `${start.format('YYYY-MM-DD HH:mm')}`
            dates.end = `${end.format('YYYY-MM-DD HH:mm')}`
            return dates;
        },

        /**
         * Create the filter items.
         */
        createFilters() {
            const context = this
            if (!context.availableFilters) return
            Lazy(context.availableFilters)
                .each((v, k) => {
                    switch (true) {
                        case (v.hasOwnProperty('class')):
                            context[v.class].get.items.forEach(v2 => {
                                if (v.class === 'channelEntity' || v.class === 'runTypeEntity') {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label,
                                        type: v2.reference ? v2.reference : '',
                                        country: v2.country ? v2.country.toUpperCase() : null
                                    })
                                } else {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label,
                                        active: v2.active,
                                        country: v2.country ? v2.country : null
                                    })
                                }
                            })
                            break
                        case (k == 'mcr'):
                        case (k == 'country'):
                            context.countries.forEach(v2 => {
                                v.items.push({
                                    id: v2.code,
                                    label: v2.label
                                })
                            })
                            break
                        case (k == 'contribution'):
                            context.contributions.forEach(v2 => {
                                v.items.push({
                                    id: v2.label,
                                    label: v2.label,
                                    country: v2.country
                                })
                            })
                    }
                })

            // Set default selected country based on the current user's country.
            if (app.$data.user.get.country && context.$store.state.filter.myFiltersSelected === '') {
                switch (true) {
                    case (context.availableFilters.hasOwnProperty('mcr') && context.availableFilters.mcr.hasOwnProperty('items')):
                        Lazy(context.availableFilters.mcr.items)
                            .filter(v => {
                                return v.id == app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                    case (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items')):
                        Lazy(context.availableFilters.country.items)
                            .filter(v => {
                                return v.id == app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                }
            }
            context.filterReady = true
        },
        /**
         * Finds an MCR Comment that belong to an item and gives as an array
         * Can be attached more than one comment to an item.
         * @param item
         * @param type
         * @returns {*[]}
         */
        getMCRCommentsOfItem(item) {
            let comments = []
            Lazy(this.getAllItems)
                .each(v => {
                    if (v.elementType === 'mcrComment') {
                        let _item = this.setItemRowData(v)
                        if (item.id === v.forId && item.elementType === v.forType) comments.push(_item)
                    }
                })
            return comments
        },

        /**
         * Extract all "contribution equipment pieces" from passed items.
         * @param array items an array of feeds and productions.
         * @return array|null
         */
        getContributionItems(items, definition = '') {
            let output = []

            Lazy(items)
                .filter(item => {
                    return item.equipment && item.equipment.length > 0
                })
                .each(item => {
                    Lazy(item.equipment)
                        .filter(v => {
                            return v.type === this.setting.contributions_entityitem_id
                        })
                        .each(v => {
                            let countries
                            if (item.elementType === 'production') {
                                if (!item.channel) console.log(item, 'Channel is Null!')
                                countries = this.channelEntity.getItem(item.channel) ? this.channelEntity.getItem(item.channel) : ''
                                countries = countries ? countries.country : 'uganda'
                                if (countries === 'uganda') console.log(item, 'Uganda caught!');
                            }
                            if (item.elementType === 'feed') {
                                countries = item.mcr
                            }
                            const contribution = {
                                id: v.equipmentUsage.id,
                                itemId: item.id,
                                itemType: item.elementType,
                                elementType: 'contribution',
                                elementTypeDetailed: 'contribution',
                                title: item.title,
                                channel: item.channel,
                                info: v.label,
                                league: item.league,
                                contributionStart: v.equipmentUsage.periodStart,
                                contributionEnd: v.equipmentUsage.periodEnd,
                                country: v.country,
                                associations: [],
                                mcrStatus: v.equipmentUsage.mcrStatus,
                                houseNumber: item.houseNumber,
                                rowOptions: {
                                    id: item.id,
                                    type: item.elementType,
                                    country: countries,
                                    ingestions: item.ingestions,
                                }
                            }
                            if (definition === 'main') contribution.associations.push(item)
                            Item.parse(contribution)
                            output.push(contribution)

                        })
                })

            return output.length > 0 ? output : null
        },

        /**
         * Build a contribution object in the format accepted by BookMcrView.
         * @param object contribution
         * @return object
         */
        getContributionFormatted(contribution) {
            return {
                type: {
                    rawValue: contribution.elementType,
                    value: !this.darkMode ? '<span class="label label-default">cntb</span>' : '<span class="label label-default give-dark">cntb</span>',
                    idValue: !this.darkMode ? `<span class="label label-default">${contribution.itemId}</span>` : `<span class="label label-default give-dark">${contribution.itemId}</span>`,
                },
                id: {
                    rawValue: contribution.id,
                    value: contribution.itemId
                },
                date: {
                    group: 0,
                },
                time: {
                    value: this.getItemTime(contribution),
                    startTime: contribution.startTime,
                    endTime: contribution.endTime

                },
                league: {
                    value: ''
                },
                title: {
                    value: contribution.title
                },
                info: {
                    value: this.getInfo(contribution)
                },
                rowOptions: {
                    hasActions: false,
                    mcrStatus: contribution.mcrStatus,
                },
                //associations: [contribution.associations],
            }
        },

        /**
         * Build a equipment list grouped by equipment type, for BookMcrView.
         * @param array equipment
         * @param string elementType The elementType, "feed", "production" and
         *                           and "contribution".
         * @return array
         */
        getEquipmentFormatted(equipment, elementType, id = false) {
            let output = []
            let nonVisible = []
            let setting = this.getEquipmentSetting(elementType)

            Lazy(setting)
                .each(v => {
                    const equipmentType = this.equipmentTypeEntity.getItem(v.id)
                    if (equipmentType) {
                        const items = equipment.filter(v2 => {
                            if (!Equipment.isVisible(v2)) return false

                            // If the equipment belongs to a kit and does not have a conflict, we'll hide it. We do it
                            // here and not in Equipment.isVisible since this logic only applies to MCR right now.
                            if (v2.equipmentUsage.parentId && !v2.equipmentUsage?.conflict) return false

                            switch (v2.elementType) {
                                case 'equipmentPiece':
                                    return v2.type === v.id
                                case 'equipmentType':
                                    return v2.id === v.id
                            }
                        })

                        const nonVisible = equipment.filter(v3 => {
                            if (!Equipment.isVisible(v3)) {
                                switch (v3.elementType) {
                                    case 'equipmentPiece':
                                        return v3.type === v.id
                                    case 'equipmentType':
                                        return v3.id === v.id
                                }
                            }
                        })

                        if (nonVisible.length > 0 && items.length === 0) {
                            let label = equipmentType.shortlabel ? equipmentType.shortlabel : equipmentType.label
                            output.push({
                                id: v.id,
                                label: label,
                                nonVisible: nonVisible.length > 0 ? nonVisible : null
                            })

                        }

                        if (items.length > 0) {
                            //console.log(items, 'asd')
                            let label = equipmentType.shortlabel ? equipmentType.shortlabel : equipmentType.label
                            output.push({
                                id: v.id,
                                label: label,
                                items: items.length ? items : null,
                            })
                        }
                    }
                })

            // filter out nonVisible if output includes items
            // we only want nonVisible alone
            Lazy(output).each(v => {
                if (v.items) output = output.filter(v => v.items)
            })
            /*if(id === 4451 ) {
                console.log('getEquipmentFormatted items: '+id)
                console.log(output)
            }*/
            return output
        },

        /**
         * Get the "lowercased" label for the given delivery type id, to be used
         * in comparisons.
         * @param integer id The entity item id.
         * @return string
         */
        getDeliveryType(id) {
            const deliveryType = this.deliveryTypeEntity.getItem(id)
            return deliveryType ? deliveryType.label.toLowerCase() : ''
        },

        /**
         * Get the content to be presented in the "info" column.
         * @param object item A feed/production/contribution.
         * @return string (HTML)
         */
        getInfo(item) {
            let output = ''
            switch (item.elementTypeDetailed) {
                case 'feed':
                    return this.getFeedInfo(item)
                case 'production':
                    return this.getProductionInfo(item)
                case 'task':
                    return this.getTaskInfo(item)
                case 'contribution':
                    return this.getContributionInfo(item)
            }
        },


        getItemInfo(item, feedId = false){
            let info = []
            let infoStr = ""
            if(item.elementTypeDetailed === "contribution"){

                let infoContrib = (item.hasOwnProperty('info') && item.elementTypeDetailed === "contribution") ? item.info :''
                info.push(infoContrib)

            } else if(item.elementTypeDetailed === "task") {
                if(item.workgroups && item.workgroups.length > 0) {
                    Lazy(item.workgroups)
                        .each(workgroup => {
                            let wrkgr = this.workgroupsEntity.getItem(workgroup.workgroup)
                            if(wrkgr && wrkgr.userfield1_value == 'yes' && (!wrkgr.country || wrkgr.country === '' || wrkgr.country === app.$data.user.get.country)) {

                                info.push(wrkgr.label)

                            }
                        })
                }
            } else{
                switch (item.hasOwnProperty('deliveryType') && item.hasOwnProperty('deliveryTypeData') && item.deliveryTypeData && item.deliveryType !== null && this.getDeliveryType(item.deliveryType)) {
                    case 'download':
                        info.push(item.deliveryTypeData.downloadInfo)
                        break
                    case 'fiber':
                        info.push(item.deliveryTypeData.fiberInfo)
                        break
                    case 'streaming':
                        const streamingType = this.streamingTypeEntity.getItem(item.deliveryTypeData.streamingType)
                        if (streamingType) {
                            let streamingTypeLabel = streamingType ? streamingType.label : ''
                            // output.push('<strong>Stream.type:</strong>&nbsp;')
                            info.push(streamingTypeLabel)
                        }
                        // output.push('<strong>Stream.info:</strong>&nbsp;')
                        info.push(item.deliveryTypeData.streamingInfo)
                        break
                    case 'sat':
                        if (item.deliveryTypeData.satModulation) {
                            const satModulation = this.modulationEntity.getItem(item.deliveryTypeData.satModulation)
                            if (satModulation) {
                                let satModulationLabel = satModulation ? satModulation.label : ''
                                // output.push('<strong>Pol:</strong>&nbsp;')
                                info.push(satModulationLabel)
                            }
                        }
                        if (item.deliveryTypeData.satSatelliteId || item.deliveryTypeData.satSatellite) {
                            // output.push('<strong>Sat:</strong>&nbsp;')
                            if(item.deliveryTypeData.satSatelliteId) {
                                const satSatteliteItem = this.satelliteEntity.getItem(item.deliveryTypeData.satSatelliteId);
                                if(satSatteliteItem) {
                                    let satSatteliteItemLabel = satSatteliteItem ? satSatteliteItem.label : ''
                                    info.push(satSatteliteItemLabel)
                                }
                            }
                            else {
                                info.push(item.deliveryTypeData.satSatellite)
                            }

                        }
                        if (item.deliveryTypeData.satPolarization) {
                            const satPolarization = this.polarizationEntity.getItem(item.deliveryTypeData.satPolarization)
                            if (satPolarization) {
                                let satPolarizationLabel = satPolarization ? satPolarization.label : ''
                                // output.push('<strong>Pol:</strong>&nbsp;')
                                info.push(satPolarizationLabel)
                            }
                        }
                        if (item.deliveryTypeData.satFrq) {
                            // output.push('<strong>Frq:</strong>&nbsp;')
                            info.push(item.deliveryTypeData.satFrq)
                        }
                        if (item.deliveryTypeData.satService) {

                            info.push(item.deliveryTypeData.satService)
                        }
                        /*if (item.deliveryTypeData.satSymbolRate) {
                         // output.push('<strong>Frq:</strong>&nbsp;')
                         info.push(item.deliveryTypeData.satSymbolRate)
                         }*/

                        break
                }


            }

            if(info.length > 0){
                infoStr = info.join(' | ')
                if(feedId) {
                    return feedId+": "+infoStr;
                }
            }

            return infoStr;
        },
        /**
         * Get the info for a Feed, to presented in the "info" column.
         * @param object item A feed
         * @return string HTML
         */
        getFeedInfo(item) {
            const output = []
            // if (item.mcr.length > 0) {
            //     output.push('<span class="book-info-item">')
            //     output.push('<strong>MCR\'s:</strong>&nbsp;')
            //     const mcr = []
            //     item.mcr.forEach(v => {
            //         mcr.push(`<span class="text-uppercase">${v}</span>`)
            //     })
            //     output.push(mcr.join(', '))
            //     output.push('</span>')
            // }
            switch (item.deliveryTypeData && this.getDeliveryType(item.deliveryType)) {
                case 'download':
                    output.push('<span class="book-info-item small-font">')
                    // output.push('<strong>Download:</strong>&nbsp;')
                    output.push(item.deliveryTypeData.downloadInfo)
                    output.push('</span>')
                    break
                case 'fiber':
                    output.push('<span class="book-info-item small-font">')
                    // output.push('<strong>Fiber:</strong>&nbsp;')
                    output.push(item.deliveryTypeData.fiberInfo)
                    output.push('</span>')
                    break
                case 'streaming':
                    const streamingType = this.streamingTypeEntity.getItem(item.deliveryTypeData.streamingType)
                    if (streamingType) {
                        let streamingTypeLabel = streamingType ? streamingType.label : ''
                        // output.push('<strong>Stream.type:</strong>&nbsp;')
                        output.push(streamingTypeLabel)
                    }
                    output.push('<span class="book-info-item small-font">')
                    // output.push('<strong>Stream.info:</strong>&nbsp;')
                    output.push(item.deliveryTypeData.streamingInfo)
                    output.push('</span>')
                    break
                case 'sat':
                    if (item.deliveryTypeData.satModulation) {
                        const satModulation = this.modulationEntity.getItem(item.deliveryTypeData.satModulation)
                        if (satModulation){
                            output.push('<span class="book-info-item small-font">')
                            // output.push('<strong>Sat:</strong>&nbsp;')
                            output.push(satModulation.label)
                            output.push('</span> ')
                        }
                    }
                    if (item.deliveryTypeData.satSatelliteId || item.deliveryTypeData.satSatellite) {
                        if(item.deliveryTypeData.satSatelliteId) {
                            const satSattiliteItem = this.satelliteEntity.getItem(item.deliveryTypeData.satSatelliteId);
                            if(satSattiliteItem) {
                                output.push('<span class="book-info-item small-font">')
                                // output.push('<strong>Sat:</strong>&nbsp;')

                                output.push(satSattiliteItem.label)
                                output.push('</span> ')
                            }
                        }
                        else {
                            output.push('<span class="book-info-item small-font">')
                            // output.push('<strong>Sat:</strong>&nbsp;')

                            output.push(item.deliveryTypeData.satSatellite)
                            output.push('</span> ')
                        }
                    }
                    if (item.deliveryTypeData.satPolarization) {
                        const satPolarization = this.polarizationEntity.getItem(item.deliveryTypeData.satPolarization)
                        if (satPolarization) {
                            let satPolarizationLabel = satPolarization ? satPolarization.label : ''
                            output.push('<span class="book-info-item small-font">')
                            // output.push('<strong>Pol:</strong>&nbsp;')
                            output.push(satPolarizationLabel)
                            output.push('</span> ')
                        }
                    }
                    if (item.deliveryTypeData.satFrq) {
                        output.push('<span class="book-info-item small-font">')
                        // output.push('<strong>Frq:</strong>&nbsp;')
                        output.push(item.deliveryTypeData.satFrq)
                        output.push('</span> ')
                    }
                    if (item.deliveryTypeData.satSymbolRate) {
                        output.push('<span class="book-info-item small-font">')
                        output.push(item.deliveryTypeData.satSymbolRate)
                        output.push('</span> ')
                    }

                    break
            }
            return output.join('')
        },

        /**
         * Get the info for a Production, to presented in the "info" column.
         * @param object item A Production
         * @return string HTML
         */
        getProductionInfo(item) {
            const output = []

            // Retrieve the channel information.
            const channel = this.channelEntity.getItem(item.channel)
            if (channel) {
                let channelLabel = channel ? channel.label : ''
                output.push('<span class="book-info-item">')
                // output.push('<strong>Channel:</strong>&nbsp;')
                output.push(channelLabel)
                output.push('</span>')
            }

            return output.join('')
        },

        /**
         * Get the info for a Task, to presented in the "info" column.
         * @param object item A Task
         * @return string HTML
         */
        getTaskInfo(item) {
            const output = []
            if(item.workgroups && item.workgroups.length > 0) {
                Lazy(item.workgroups)
                    .each(workgroup => {
                        let wrkgr = this.workgroupsEntity.getItem(workgroup.workgroup)
                        if(wrkgr && wrkgr.userfield1_value == 'yes') {
                            output.push('<span class="book-info-item">')
                            output.push(wrkgr.label)
                            output.push('</span>')
                        }
                    })
            }

            return output.join('')
        },

        /**
         * Get the info for a Production, to presented in the "info" column.
         * @param object item A contribution
         * @return string HTML
         */
        getContributionInfo(item) {
            if (!item.associations) return
            const parentItem = item.associations.find(v => v.id == item.itemId && v.elementType == item.itemType)
            if (!parentItem) return ''
            if (!parentItem.channel && (parentItem.elementType == 'production')) return ''
            const output = []

            // Add the contribution title.
            output.push('<span class="book-info-item">')
            output.push(item.info)
            output.push('</span>')

            // Retrieve the channel information.
            const channel = this.channelEntity.getItem(parentItem.channel)
            if (channel) {
                let channelLabel = channel ? channel.label : ''
                output.push('<span class="book-info-item">')
                // output.push('<strong>Channel:</strong>&nbsp;')
                output.push(channelLabel)
                output.push('</span>')
            }

            return output.join('')
        },
        applyEquipments() {
            if (this.allEquipmentUsages.length === 0) {
                this.$alert.set('Something went wrong, please refresh the page', 'danger', 20);
                return;
            }
            const context = this
            this.$alert.set('Equipment conflicts checked!', 'success', 3)
            //const t0 = performance.now();
            let equipments = [], places = []
            equipments = _.filter(context.allEquipmentUsages, eq => eq.conflict)
            Lazy(equipments).each(v => {
                _.find(context.items, function(item, i) {
                    if (item.id === v.usageId) _.find(item.equipment, function(eq, ei) {
                        if (eq.equipmentUsage.id === v.id) {
                            delete v.equipment
                            delete v.usageItem
                            places.push({ i: i, e: ei, eq: v})
                        }
                    })
                })
            })

            if (places.length > 0) {
                Lazy(places).each(v => {
                    this.items[v.i].equipment[v.e].equipmentUsage = v.eq
                    let _new = new Mcrdata(context.items[v.i].id)
                    _new.set('equipment', context.items[v.i].equipment)
                    let data = {
                        itemId: context.items[v.i].id,
                        itemType: context.items[v.i].elementType,
                        items: this.items[v.i].equipment
                    }
                    app.$emit('AutoChangeEquipments', data)
                })
            }
            //const t1 = performance.now();
            //console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);
        },

        /**
         * Change actions of AutoSaved equipments for fast reaction.
         * @param data
         */
        changeOfEquipments(data) {
            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === data.itemId && this.items[i].elementType === data.itemType ) {
                    this.items[i].equipment = data.items
                }
                if (this.items[i].associations) {
                    for (let j = 0, lej = this.items[i].associations.length; j < lej; j++) {
                        if (this.items[i].associations[j].id === data.itemId
                            && this.items[i].associations[j].elementType === data.itemType) {
                            this.items[i].associations[j].equipment = data.items
                        }
                    }
                }
                if (this.items[i].contributions) {
                    for (let c = 0, lec = this.items[i].contributions.length; c < lec; c++) {
                        if (this.items[i].contributions[c].id === data.itemId) {
                            this.items[i].contributions[c].equipment = data.items
                        }
                    }
                }
            }


            switch (data.itemType) {
                case 'feed':
                    const feed = new Mcrdata(data.itemId)
                    feed.set('equipment', data.items)
                    break
                case 'production':
                    const production = new Mcrdata(data.itemId)
                    production.set('equipment', data.items)
                    break
            }

            // if we have sidebar opened at the same time while changing equipment
            // then save for sidebar info as well to get latest
            if (this.selectedItem) {
                let sidebar = this.selectedItem
                if (sidebar.id === data.itemId && sidebar.elementType === data.itemType) {
                    sidebar.equipment = data.items
                }
            }

            // prepare sending data
            if (this.waitingUpdates.items.length > 0) {
                this.sendingUpdates.items.push(data)
            }

        },
        updateEquipmentFinal(data, action = '') {

            let _usageId
            let _usageType
            let _finalItems

            // convert to array if its not
            if (!Array.isArray(data)) {
                data = [data]
            }

            // if its array or multiple equipment
            if (Array.isArray(data)) {
                Lazy(data).each(v1 => {

                    _usageId = v1.equipmentUsage.usageId
                    _usageType = v1.equipmentUsage.usageType

                    const item = this.getNewClass(_usageType, _usageId)
                    const items = item.get.equipment

                    // if no item, then it means not in the list
                    if (typeof items === 'undefined') return

                    // update / create / del
                    if (action === 'create' || action === 'update') {
                        let i = items.findIndex(v => parseInt(v.equipmentUsage.id) === parseInt(v1.equipmentUsage.id))
                        if (i > -1) items.splice(i, 1)
                        items.push(v1)
                    }
                    if (action === 'delete') {
                        let i = items.findIndex(v => v.id === v1.id)
                        if (i > -1) items.splice(i, 1)
                    }
                    _finalItems = items
                    item.set('equipment', items)
                })

            }
            else {

                _usageId = data.equipmentUsage.usageId
                _usageType = data.equipmentUsage.usageType

                const item = this.getNewClass(_usageType, _usageId)
                const items = item.get.equipment

                // if no item, then it means not in the list
                if (typeof items === 'undefined') return

                // update / create / del
                if (action === 'create' || action === 'update') {
                    let i = items.findIndex(v => parseInt(v.equipmentUsage.id) === parseInt(data.equipmentUsage.id))
                    if (i > -1) items.splice(i, 1)
                    items.push(data)
                }
                if (action === 'delete') {
                    let i = items.findIndex(v => v.id === data.id)
                    if (i > -1) items.splice(i, 1)
                }

                _finalItems = items
                //item.set('equipment', items)
            }

            //associations
            if (_finalItems)
                Lazy(this.items).each((v, k) => {
                    if (v.associations) {
                        Lazy(v.associations).each((v2, k2) => {
                            if (v2.id === _usageId) {
                                v2.equipment = _finalItems
                                switch (_usageType) {
                                    case 'feed':
                                        const feed = new Mcrdata(_usageId)
                                        feed.set('equipment', _finalItems)
                                        break
                                    case 'production':
                                        const production = new Mcrdata(_usageId)
                                        production.set('equipment', _finalItems)
                                        break
                                }
                            }
                        })
                    }
                })

            if (this.selectedItem) {
                console.log('we have sidebar', this.selectedItem)
            }

        },

        /**
         * Callback used when event "changedIngestions" is triggered.
         * @param object data The data (incl ingestion items) returned by the event.
         */
        updateIngestions(data) {

            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === data.itemId && this.items[i].elementType === data.itemType ) {
                    this.items[i].ingestions = data.items
                }
                if (this.items[i].associations) {
                    for (let j = 0, lej = this.items[i].associations.length; j < lej; j++) {
                        if (this.items[i].associations[j].id === data.itemId
                            && this.items[i].associations[j].elementType === data.itemType) {
                            this.items[i].associations[j].ingestions = data.items
                            //console.log(this.items[i].associations[j], 'assos')
                        }
                    }
                }
            }

            switch (data.itemType) {
                case 'feed':
                    const feed = new Mcrdata(data.itemId)
                    feed.set('ingestions', data.items)
                    break
                case 'production':
                    const production = new Mcrdata(data.itemId)
                    production.set('ingestions', data.items)
                    break
            }

            // prepare sending data
            if (this.waitingUpdates.items.length > 0) {
                this.sendingUpdates.items.push(data)
            }

        },

        /**
         * Callback used when event "changedMcrStatus" is triggered.
         * @param object data The data (incl mcr status items) returned by the event.
         */
        updateMcrStatus(data, extraData) {

            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === data.itemId && this.items[i].elementType === data.itemType ) {
                    this.items[i].mcrStatus = data.items
                }
                if (this.items[i].associations) {
                    for (let j = 0, lej = this.items[i].associations.length; j < lej; j++) {
                        if (this.items[i].associations[j].id === data.itemId
                            && this.items[i].associations[j].elementType === data.itemType) {
                            this.items[i].associations[j].mcrStatus = data.items
                        }
                    }
                }
                if (this.items[i].contributions) {
                    for (let c = 0, lec = this.items[i].contributions.length; c < lec; c++) {
                        if (this.items[i].contributions[c].id === data.itemId) {
                            this.items[i].contributions[c].mcrStatus = data.items
                        }
                    }
                }
            }


            switch (data.itemType) {
                case 'feed':
                    const feed = new Mcrdata(data.itemId)
                    feed.set('mcrStatus', data.items)
                    break
                case 'production':
                    const production = new Mcrdata(data.itemId)
                    production.set('mcrStatus', data.items)
                    break
                case 'equipmentUsage':
                    let extraDataId = data.extraData.itemId ? data.extraData.itemId : (data.extraData.item_id ? data.extraData.item_id : null)
                    let extraDataType = data.extraData.itemType ? data.extraData.itemType : (data.extraData.item_type ? data.extraData.item_type : null)
                    const item = this.getNewClass(extraDataType, extraDataId)
                    const equipment = item.get.equipment.find(v => v.equipmentUsage.id == data.itemId)
                    equipment.equipmentUsage.mcrStatus = data.items
                    break
            }
            // prepare sending data
            if (this.waitingUpdates.items.length > 0) {
                this.sendingUpdates.items.push(data)
            }
        },

        displayFeedbackModal(item) {
            switch (item.type) {
                case 'feed':
                case 'production':
                    this.selectedFeedback.feedbackItemId = item.id
                    this.selectedFeedback.feedbackItemType = item.type
                    break
                case 'equipmentUsage':
                    this.selectedFeedback.feedbackItemId = item.extraData.itemId ? item.extraData.itemId : (item.extraData.item_id ? item.extraData.item_id : null)
                    this.selectedFeedback.feedbackItemType = item.extraData.itemType ? item.extraData.itemType : (item.extraData.item_type ? item.extraData.item_type : null)
                    break
            }

            if(this.selectedFeedback.feedbackItemId && this.selectedFeedback.feedbackItemType) {
                this.showFeedback = true
            }


        },
        openNewFeedback(isOK=false) {
            if(isOK) {
                this.createOk()
            }
            else {
                const link = {
                    name: 'feedback.create',
                    params: {
                        feedbackItemId:  this.selectedFeedback.feedbackItemId,
                        feedbackItemType: this.selectedFeedback.feedbackItemType
                    },

                    query: {
                        destination: this.$route.name,
                        from: this.$route.query.from,
                        to: this.$route.query.to
                    }
                }



                let routeData = this.$router.resolve(link);
                window.open(routeData.href, '_blank');

                this.closeFeedbackModal()
            }

        },
        createOk() {
            const context = this

            const _clone_id = Math.floor(100000000000 + Math.random() * 900000000000) // 12 digit
            app.$emit('AddFeedback', {
                itemId: context.selectedFeedback.feedbackItemId,
                itemType: context.selectedFeedback.feedbackItemType,
                item: {
                    id: _clone_id,
                    feedback: 'Everything was OK',
                    feedbackItemId: context.selectedFeedback.feedbackItemId,
                    feedbackItemType: context.selectedFeedback.feedbackItemType,
                    isErrorReport: false,
                    isCreateTicket: false,
                    readByAdmin: false,
                    createdBy: app.$data.user.get.id,
                    status: 'ok'
                },
                action: {
                    module: 'feedback',
                    type: 'store',
                },
                items: []
            })
            context.closeFeedbackModal()
            /*
            feedback.store()
                .then(response => {
                    context.$alert.set('Feedback ' + response.data.id + ' was saved!', 'success', 3)
                    app.$emit('creatingFeedback', response.data)
                    context.$loader.remove('bookFeedbackSubmit')

                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to remove the feedback.')
                    context.$loader.remove('bookFeedbackSubmit')
                })*/


        },
        closeFeedbackModal() {
            this.showFeedback = false
            this.selectedFeedback = this.defaultFeedback()
        },
        /**
         * Opens the comment create/edit form
         */
        openCommentForm(item = null, action = null) {
            if (!this.mcrCommentAccess) {
                this.$alert.set('You do not have an access to create MCR Comment.', 'danger', 5)
                return
            }
            if (item && !action) { this.selectedComment = item }
            if (item && action === 'new') {
                item.newType = item.elementTypeDetailed
                this.selectedComment = item
            }
            this.showCommenter = true
        },
        /**
         * Closes the comment form
         */
        closeCommenter() {
            this.showCommenter = false
            this.selectedComment = null
        },
        /**
         * Creates/Updates/Deletes the Comment and updates the items list.
         * @param data
         */
        commentActions(data) {
            // let data = { action: actionType, item: item }
            // app.$emit('mcrCommentActions', data)
            const now = moment().unix()
            const items = this.items.slice(0)
            const item = this.getNewClass(data.item.elementType, data.item.id)
            switch (data.action) {
                case 'new':
                    const newItem = this.findItem(data.item.id, data.item.elementType)
                    this.openCommentForm( newItem, 'new')
                    break
                case 'open':
                    const McrComment = this.findItem(data.item.id, data.item.elementType)
                    this.openCommentForm(McrComment)
                    break
                case 'create':
                    Item.parse(data.item, data.item.elementType, now)
                    item.add(data.item)
                    break
                case 'update':
                    let update = items.findIndex(v => v.id === data.item.id)
                    if (update > -1) items.splice(update, 1)

                    let upi = items.findIndex(v => v.id === data.item.forId)
                    if (upi > -1) {
                        let _upi = items[upi].mcrComment.findIndex(v => v.id === data.item.id)
                        if (_upi > -1) {
                            let _comments = items[upi].mcrComment
                            _comments.splice(_upi, 1)
                            _comments.push(this.setItemRowData(data.item))
                            Vue.set(items[upi], 'mcrComment', _comments)
                        }
                    }

                    item.remove()
                    Item.parse(data.item, data.item.elementType, now)
                    item.add(data.item)
                    break
                case 'delete':
                    let del = items.findIndex(v => v.id === data.item.id)
                    let deli = items.findIndex(v => v.id === data.item.forId)
                    if (deli > -1) {
                        let _deli = items[deli].mcrComment.findIndex(v => v.id === data.item.id)
                        if (_deli > -1) {
                            let _comments = items[deli].mcrComment
                            _comments.splice(_deli, 1)
                            Vue.set(items[deli], 'mcrComment', _comments)
                        }
                    }
                    if (del > -1) items.splice(del, 1)
                    item.remove()
                    break
            }

        },

        updateMcrStatusBySocket(data, extraData) {

            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === data.itemId && this.items[i].elementType === data.itemType ) {
                    this.items[i].mcrStatus = data.items
                }
                if (this.items[i].associations) {
                    for (let j = 0, lej = this.items[i].associations.length; j < lej; j++) {
                        if (this.items[i].associations[j].id === data.itemId
                            && this.items[i].associations[j].elementType === data.itemType) {
                            this.items[i].associations[j].mcrStatus = data.items
                        }
                    }
                }
                if (this.items[i].contributions) {
                    for (let c = 0, lec = this.items[i].contributions.length; c < lec; c++) {
                        if (this.items[i].contributions[c].id === data.itemId) {
                            this.items[i].contributions[c].mcrStatus = data.items
                        }
                    }
                }
            }

            switch (data.itemType) {
                case 'feed':
                    const feed = new Mcrdata(data.itemId)
                    feed.set('mcrStatus', data.items)
                    break
                case 'production':
                    const production = new Mcrdata(data.itemId)
                    production.set('mcrStatus', data.items)
                    break
                case 'equipmentUsage':
                    let extraDataId = data.extraData.itemId ? data.extraData.itemId : (data.extraData.item_id ? data.extraData.item_id : null)
                    let extraDataType = data.extraData.itemType ? data.extraData.itemType : (data.extraData.item_type ? data.extraData.item_type : null)
                    const item = this.getNewClass(extraDataType, extraDataId)
                    const equipment = item.get.equipment.find(v => v.equipmentUsage.id == data.itemId)
                    equipment.equipmentUsage.mcrStatus = data.items
                    break
            }
        },

        deletesMcrStatus(mcrStatusId){
            return true
        },

        /**
         * Update house numbers, for Associations also.
         */
        updateHouseNumbers(data) {
            let itemType, itemId, houseNumber
            itemType = data.itemType
            itemId = data.itemId
            houseNumber = data.houseNumber

            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === itemId && this.items[i].elementType === itemType) {
                    this.items[i].houseNumber = houseNumber
                }
                if (this.items[i].associations) {
                    for (let j = 0, lej = this.items[i].associations.length; j < lej; j++) {
                        if (this.items[i].associations[j].id === itemId &&
                            this.items[i].associations[j].elementType === itemType) {
                            this.items[i].associations[j].houseNumber = houseNumber
                        }
                    }
                }
            }
            switch (itemType) {
                case 'feed':
                    const feed = new Mcrdata(itemId)
                    feed.set('houseNumber', houseNumber)
                    break
                case 'production':
                    const production = new Mcrdata(itemId)
                    production.set('houseNumber', houseNumber)
                    break
            }

            // prepare sending data
            if (this.waitingUpdates.items.length > 0) {
                this.sendingUpdates.items.push(data)
            }

        },

        /**
         * Change actions of AutoSaved placeholders for fast reaction.
         * @param data
         */
        changeOfPlaceholders(data) {

            for (let i = 0, len = this.items.length; i < len; i++ ) {
                if (this.items[i].id === data.itemId && this.items[i].elementType === data.itemType ) {
                    this.items[i].placeholders = data.items
                }
            }


            switch (data.itemType) {
                case 'feed':
                    const feed = new Mcrdata(data.itemId)
                    feed.set('placeholders', data.items)
                    break
                case 'production':
                    const production = new Mcrdata(data.itemId)
                    production.set('placeholders', data.items)
                    break
            }

            // if we have sidebar opened at the same time while changing equipment
            // then save for sidebar info as well to get latest
            if (this.selectedItem) {
                let sidebar = this.selectedItem
                if (sidebar.id === data.itemId && sidebar.elementType === data.itemType) {
                    sidebar.placeholders = data.items
                }
            }

            // prepare sending data
            if (this.waitingUpdates.items.length > 0) {
                this.sendingUpdates.items.push(data)
            }

        },

        /**
         * Update the item flags for all items
         */
        updateItemFlags() {
            const context = this
            const now = moment()

            // Loop through all current feeds/productions and emit events for all locked
            // and newly created items.
            context.items
                .filter(v => v.hasOwnProperty('created'))
                .forEach(v => {
                    if (v.lock && Lock.isLocked(v.lock)) {
                        app.$emit('itemLocked', {type: v.elementType, id: v.id, author: v.lock.user.name})
                    }
                    if (now.diff(v.created, 'seconds') < app.$store.state.config.ITEM_FLAG.NEW_VISIBILITY) {
                        app.$emit('itemCreated', {type: v.elementType, id: v.id, author: '', persistent: true})
                    }
                    //update should be here
                    if (now.diff(v.updated, 'seconds') < app.$store.state.config.ITEM_FLAG.UPDATE_VISIBILITY && now.diff(v.updated, 'seconds') != now.diff(v.created, 'seconds')) {
                        app.$emit('itemUpdated', {type: v.elementType, id: v.id, author: '', persistent: true})
                    }
                })

        },

        /**
         * Get a new class instance, based the child component.
         * @param string type The class to create, ex "feed".
         * @param mixed opts (optional) The parameters that the new class instance should
         *                   be created with.
         * @return class
         */
        getNewClass(type, opts = null) {
            switch (type) {
                case 'feed':
                    return new Mcrdata(opts)
                case 'production':
                    return new Mcrdata(opts)
                case 'mcrComment':
                    return new Mcrdata(opts)
                case 'productionTemplate':
                    return new productionTemplate(opts)
            }
        },

        /**
         * Get the HTML to be presented in the type column.
         * @param object item
         * @return string HTML
         */
        getTypeValue(item, type) {
            const output = []
            let label, className
            switch (item.elementTypeDetailed) {
                case 'feed':
                    label = type === 'id' ? item.id : 'feed'
                    className = 'label-primary'
                    break

                case 'production':
                    label = type === 'id' ? item.id : 'prod'
                    className = 'label-info'
                    break

                case 'event':
                    label = type === 'id' ? item.id : 'event'
                    className = 'label-success'
                    break

                case 'task':
                    label = type === 'id' ? item.id : 'task'
                    className = 'label-warning'
                    break

                case 'contribution':
                    //label = item.itemId
                    label = type === 'id' ? item.itemId : 'cntb'
                    if (!this.darkMode) className = 'label-default'
                    else className = 'label-default give-dark'
                    break

                case 'mcrComment':
                    label = type === 'id' ? item.id : 'com'
                    className = 'label-mcr-comment'
                    break
            }

            if (item.elementTypeDetailed === 'mcrComment') {
                output.push(`<span class="book-square">`)
                output.push(`<span class="label ${className}">${label}</span>`)
                output.push(`</span>`)
                return output.join('')
            }

            if (item.elementTypeDetailed === 'event') {
                output.push(`<span class="book-square">`)
                output.push(`<span class="label ${className}">${label}</span>`)
                output.push(`</span>`)
                return output.join('')
            }

            output.push(`<span class="book-item-status-${item.status}">`)
            output.push(`<span class="book-square"></span>`)
            output.push(`<span class="label ${className}">${label}</span>`)
            output.push(`</span>`)


            return output.join('')
        },

        /**
         * Retrieve the setting for which equipment types that should be visible
         * for the passed "type" (feed/production).
         * @param string type
         * @return array
         */
        getEquipmentSetting(type) {
            let setting = `mcr${type.capitalize()}sEquipmentTypes`
            return this.$settings.get.hasOwnProperty(setting) ? this.$settings.get[setting] : null
        },
        /**
         * Load an item and show it in the sidebar.
         */
        showInSidebar(item) {
            this.selectedItem = null
            //this.$loader.add('loadingSelectedItem')
            const elementType = item.elementType
            let itemClass
            switch (elementType) {
                case 'feed':
                    itemClass = new Mcrdata(item.id)
                    this.selectedItem = itemClass.get
                    break
                case 'production':
                    const preLoaded = new Mcrdata(item.id)
                    this.selectedItem = preLoaded.get
                    itemClass = new Mcrdata(item.id, elementType, 1)
                    itemClass.load(item.id)
                        .then(response => {
                            const now = moment().unix()
                            Item.parse(response.data, elementType, now)
                            this.selectedItem = itemClass.get
                        })
                        .catch(error => {
                            this.$error.set(error)
                        })

                    break
            }

        },
        // one click and double click operations
        oneClick(item){
            if (['contribution'].indexOf(item.type) > -1) {
                this.$alert.set(`${item.type} not have sidebar, nor detail.`, 'danger', 5)
                return
            }
            var context = this;
            this.clicks++
            if(this.clicks === 1) {
                console.log(item, 'one click')
                var self = this
                this.timer = setTimeout(function() {
                    context.selectItem(item.id, item.type)
                    self.clicks = 0
                }, this.delay);
            } else{
                clearTimeout(this.timer);
                //context.$router.push(link)
                context.openNewTab(item)
                this.clicks = 0;
            }
        },
        /**
         * Edit Link creation for opening bookings.
         * @param item
         * @returns {{query: {view: string, destination: string, from: string, to: string}, params: {id: *}}}
         */
        getEditLink(item) {
            const link = {
                params: {
                    id: item.id
                },
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                }
            }
            link.name = item.type + '.edit'

            return link
        },
        /**
         * Opening in new tab for MCR View
         * WN
         */
        openNewTab(link) {
            const context = this

            if (link.elementType === 'event') {
                this.$alert.set('You can not view events in booking, only at VIAPLAY Sport Calendar System.', 'warning', 5)
                return
            }

            if (link.elementType === 'mcrComment') {
                context.selectItem(link.id, link.elementType)
                return
            }

            const link_ = {
                name: link.extraType + '.edit',
                params: {
                    id: link.id
                },
                query: {
                    destination: context.$route.name,
                    view: context.$route.params.view,
                    from: context.$route.query.from,
                    to: context.$route.query.to,
                    newBar: link.id,
                }
            }

            if (link_.name === 'production.edit') {
                let routeData = context.$router.resolve(link_);
                window.open(routeData.href, '_blank');
            }
            if (link_.name === 'feed.edit') {
                let routeData = context.$router.resolve(link_);
                window.open(routeData.href, '_blank');
            }
            if (link_.name === 'task.edit') {
                let routeData = context.$router.resolve(link_);
                window.open(routeData.href, '_blank');
            }

            if ((['production.edit', 'feed.edit', 'task.edit']).indexOf(link_.name) === -1) {
                return context.$router.push(link_)
            }
        },

        findItem(id, type) {
            let _item
            const item = this.itemsFiltered.find(v => {
                // we need to check associations as well
                if (v.id === id && v.elementType === type) { return true }
                else if (v.associations) {
                    v.associations.find(v2 => {
                        if (v2.id === id && v2.elementType === type) {
                            _item = v2
                            return true
                        }
                    })
                }
            })
            return item ? item : _item
        },
        /**
         * Select an item and show it in the sidebar.
         * @param integer id The item id.
         */
        selectItem(id, type) {

            const item = this.findItem(id, type)

            if (item.elementType === 'mcrComment') {
                this.openCommentForm(item)
                return
            }
            // other items
            if (this.selectedItem && (id == this.selectedItem.id) && (type == this.selectedItem.elementType)) {
                this.selectedItem = null
            } else {
                this.showInSidebar(item)
            }
        },
        /**
         * Access control for delete or clone icons to show/hide
         * @param button
         * @returns {boolean}
         * WN
         */
        userHasAccess(type, button) {
            //This is case sensitive so, we have make first char uppercase
            const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1) + 's'
            }

            type = capitalize(type)

            //ability aliases => create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete', type)
                    break
                case 'clone':
                    _access = this.$ability.can('create', type)
                    break
            }
            return _access
        },
        /**
         * Delete a given feed/production from API by its id.
         * @param integer id The feed id.
         */
        deleteItem(type, id, typeDetailed) {
            const context = this
            const itemClass = new Mcrdata(id, type, 1)//context.getNewClass(type, id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            if (context.selectedItem && (context.selectedItem.id == id)) {
                                context.selectedItem = null
                            }
                            context.$alert.set(`The ${typeDetailed} was deleted!`)
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the ${typeDetailed}!`)
                        })
                })
        },
        /**
         * Redirect the user to production creation, cloning the passed
         */
        cloneProduction(id, type) {
            console.log("production clone");
            const production = new Mcrdata(id, 'production')
            const destination = this.getCloneLink(type)
            destination.query.clone = production.get.id
            destination.query.insertData = 1
            this.$router.push(destination)
        },
        /**
         * Redirect the user to production creation, cloning the passed
         */
        getCloneLink(type){
            const link = {
                name: `${type}.create`,
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }

            return link;
        },
        /**
         * checks ingest entity and returns if this housenumber value marked
         * for export to FORK
         * @param {*} value //housenumber value
         * @param {*} country //housenumber country
         */
        isForFork(value, country) {
            // one letter only and rest integer is requested from FORK integration team ( should be applied to 'int' )
            const determineIngestOperators = (value) => {
                if (!value) return 'blank'
                let oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
                if (oneLetterOrOnlyIntegers.test(value.trim())) return 'int'
                return value;
            }

            const findIngestEntity = (val, country) => {
                let ingestEntityItems = this.ingestEntity && this.ingestEntity.get && this.ingestEntity.get.items.length > 0 ? this.ingestEntity.get.items : null
                return ingestEntityItems ? _.find(ingestEntityItems, i => i.country === country && (i.label === val || i.shortlabel === val || i.userfield2_value === val)) : null
            }

            let val = determineIngestOperators(value)
            let ing = findIngestEntity(val, country)
            return ing ? ing.description === 'yes' : false
        },

        getCountriesTriggeredFork(houseNumbersJson) {
            const userCountry = app.$data.user.get.country
            let countriesTriggeredFork = []
            if (houseNumbersJson) {
                const countries = JSON.parse(houseNumbersJson);
                Lazy(countries).each((value, country) => {
                    if (country && userCountry.toLowerCase() !== country) {
                        if (this.isForFork(value, country)) {
                            countriesTriggeredFork.push(country)
                        }
                    }
                })
            }
            return countriesTriggeredFork
        },
        /**
         * Shows House Number with comma instead of JSON
         * @param item
         * @param parser
         * @param section
         * @returns {*}
         * wN
         */
        showHouseNumberField(item) {
            const userCountry = app.$data.user.get.country;
            let values = {
                rawValue: item.houseNumber,
                value: item.houseNumber,
                forkMappedCountries: [],
                forkMappedCountryCount: 0
            };

            if (item.houseNumber && isJsonSring(item.houseNumber)) {
                const parsedHouseNumber = JSON.parse(item.houseNumber);
                const orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk'];
                const sortedCountries = Object.entries(parsedHouseNumber)
                    .sort((a, b) => orderArr.indexOf(a[0]) - orderArr.indexOf(b[0]))
                    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

                values.rawValue = JSON.stringify(sortedCountries);
                values.value = sortedCountries[userCountry]

                const forkMappedsRaw = this.getCountriesTriggeredFork(values.rawValue);
                values.forkMappedCountries = forkMappedsRaw;
                values.forkMappedCountryCount = forkMappedsRaw.length;
            }

            return values;
        },
        defaultFeedback() {
            return {
                feedbackItemId: '',
                feedbackItemType: ''
            }
        },
        updateRecPlaceholder(item) {
            let itemId = item.itemId
            let itemType = item.itemType
            let newHNVal = item.houseNumber

            if(newHNVal) {
                const items = this.waitingUpdates.items

                const toCreate = []
                const toUpdate = []
                const toDelete = []

                let currentProduction = this.getAllItems.find(i => i.id === itemId && i.elementType === itemType)
                const placeholders = currentProduction.placeholders ? currentProduction.placeholders : []

                const waitingPlaceholders = items.length > 0 ? _.filter(items, i => i.action.module == 'placeholder' && i.itemId == itemId && i.itemType == itemType) : []
                Lazy(waitingPlaceholders)
                    .each(v => {
                        let ind = _.findIndex(items, i => i.action.module == 'placeholder' && i.itemId == itemId && i.itemType == itemType && i.item.id == v.item.id)
                        if(ind > -1) {
                            items.splice(ind, 1)
                            this.waitingUpdates.total = items.length

                            if (this.waitingUpdates.total <= 0) {
                                this.waitingUpdates.updates = false
                            }
                        }

                    })

                let currentNOFork = _.find(placeholders, p => p.country == 'no')
                let currentSEFork = _.find(placeholders, p => p.country == 'se')


                if(newHNVal['no']) {
                    if(currentSEFork !== undefined) {
                        toUpdate.push('se')
                    } else if(currentNOFork !== undefined) {
                        let indx = _.findIndex(toDelete, i => i === 'no')
                        if(indx > -1) {
                            toDelete.splice(indx, 1)
                        }
                    } else if(currentNOFork === undefined) {
                        toCreate.push('no')
                    }

                }
                else if(!newHNVal['no'] && newHNVal['se']) {
                    if(currentNOFork !== undefined) {
                        //context.updatePlaceholderCountry(currentNOFork, 'se')
                        toUpdate.push('no')
                    } else if(currentSEFork !== undefined) {
                        //context.updatePlaceholderDeleteStatus(false, 'se')
                        let indx = _.findIndex(toDelete, i => i === 'se')
                        if(indx > -1) toDelete.splice(indx, 1)
                    } else if(currentSEFork === undefined) {
                        //context.createLocalDefaultPlaceholder('se')
                        toCreate.push('se')
                    }

                }
                else if(!newHNVal['no'] && !newHNVal['se'] ) {
                    if(currentNOFork !== undefined) {
                        toDelete.push('no')

                    } else if (currentSEFork !== undefined) {
                        toDelete.push('se')

                    }
                }

                Lazy(newHNVal)
                    .each((v,k) => {
                        if(k !== 'se' && k !== 'no') {
                            let currentFork = _.find(placeholders, p => p.country == k)
                            if(newHNVal[k]) {
                                if(currentFork === undefined){
                                    toCreate.push(k)
                                }
                            }
                            else {
                                if(currentFork !== undefined) {
                                    toDelete.push(k)
                                }
                            }
                        }
                    })

                let placeholderItem = {
                    itemType: itemType,
                    itemId: itemId,
                    toCreate: toCreate,
                    toDelete: toDelete,
                    toUpdate: toUpdate
                }
                this.updateRecPlaceholderImplementation(placeholderItem)
            }
        },

        /**
         * Created or deletes placeholders when Ingest changed
         * @param {*} item
         */
        updateRecPlaceholderImplementation(item) {
            let currentProduction = this.getAllItems.find(i => i.id === item.itemId)
            if(currentProduction !== undefined) {
                if(item.hasOwnProperty('toCreate') && item.toCreate.length > 0) {
                    this.createLocalDefaultPlaceholder(currentProduction, item.toCreate)
                }
                if(item.hasOwnProperty('toDelete') && item.toDelete.length > 0) {
                    this.deleteLocalDefaultPlaceholder(currentProduction, item.toDelete, item.hasOwnProperty('toCreate') && item.toCreate.length > 0)
                }
                if(item.hasOwnProperty('toUpdate') && item.toUpdate.length > 0) {
                    this.updateLocalDefaultPlaceholder(currentProduction, item.toUpdate)
                }

            }
        },
        /**
         * Createing default placeholder if house number value match with send to fork flag
         */
        createLocalDefaultPlaceholder(production, countries) {
            const context = this

            let tempId = moment().unix()
            let fStart = production.elementType === 'production' ? production.productionStart : production.feedStart
            fStart = production.elementType === 'production' ? new Date((moment(fStart).add(-15, 'minutes')).format('YYYY-MM-DD HH:mm')) : new Date((moment(fStart)).format('YYYY-MM-DD HH:mm'));
            //fStart = (moment(fStart).add(-10, 'minutes'))
            //fStart = new Date(fStart);
            let fEnd = production.elementType === 'production' ? production.productionEnd : production.feedEnd
            fEnd = production.elementType === 'production' ? new Date((moment(fEnd).add(30, 'minutes')).format('YYYY-MM-DD HH:mm')) : new Date((moment(fEnd)).format('YYYY-MM-DD HH:mm'));
            // fEnd = (moment(fEnd).add(10, 'minutes'))
            // fEnd = new Date(fEnd);

            let defaultTitle = context.getDefaultForkTitle(production)
            let newPlaceholders = []

            Lazy(countries)
                .each((v,k) => {
                    let forkCountry =  _.find(production.placeholders, i => i.country === v)
                    if(forkCountry === undefined) {
                        forkCountry = {
                            country: v,
                            placeholders: [],
                            local: true
                        }


                        let newPlaceholder = {
                            id: tempId + k,
                            country: v,
                            periodStart: moment(fStart).format('YYYY-MM-DD HH:mm'),//(moment(fStart).add(-10, 'minutes')).format('YYYY-MM-DD HH:mm'),
                            periodEnd: moment(fEnd).format('YYYY-MM-DD HH:mm'),//(moment(fEnd).add(10, 'minutes')).format('YYYY-MM-DD HH:mm'),
                            title: defaultTitle,
                            itemId: production.id,
                            itemType: production.elementType,
                            local: true,
                            action: 'create',
                            submit: false
                        }
                        forkCountry.placeholders.push(newPlaceholder)

                        // if(production.get.placeholders && production.get.placeholders.length > 0) {
                        //     newPlaceholders = production.get.placeholders.slice()
                        // }

                        newPlaceholders.push(forkCountry)
                    }
                    else {
                        const items = context.waitingUpdates.items
                        Lazy(forkCountry.placeholders)
                            .each(v => {
                                let indx = _.findIndex(items, i => i.action.module == 'placeholder' && i.action.type == 'delete' && i.item.id == v.id)
                                if(indx > -1) {
                                    items.splice(indx, 1)
                                    context.waitingUpdates.total = items.length

                                    if (context.waitingUpdates.total <= 0) {
                                        context.waitingUpdates.updates = false
                                    }
                                }
                            })
                    }
                })


            //production.set('placeholders', newPlaceholders)

            if(newPlaceholders.length > 0) {
                let differenceTime = fEnd.getTime() - fStart.getTime();
                differenceTime = Math.ceil(differenceTime / 3600000);
                if(differenceTime > 4 || production.elementType === 'feed') {
                    swal({
                        title: 'Time recording',
                        text: 'Recording is over 4 hours long. You may want to change it. Would you like to edit it?',
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            // TO DO: open modal window in forkplaceholder
                            console.log('open modal window in forkplaceholder')
                            context.placeholderForm = Object.assign({}, context.defaultPlaceholderForm())
                            context.placeholderForm.placeholders = newPlaceholders//[forkCountry]
                            context.placeholderForm.itemId = production.id
                            context.placeholderForm.itemType = production.elementType
                            context.placeholderForm.itemTitle = defaultTitle
                            context.placeholderForm.itemStart = moment(fStart).format('YYYY-MM-DD HH:mm')//production.productionStart
                            context.placeholderForm.itemEnd = moment(fEnd).format('YYYY-MM-DD HH:mm')//production.productionEnd
                            context.placeholderForm.selected = tempId

                            context.showPlaceholderForm = true

                        }, function (dismiss) {


                            swal({
                                title: 'Send to Fork?',
                                text: `Would you like to submit schedule ingest to Fork?`,
                                type: 'question',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                                showCancelButton: true,
                                showCloseButton: true
                            })
                                .then(function () {
                                    //newPlaceholder.submit = true
                                    console.log('submit = true')
                                    context.createForkPlaceholder(newPlaceholders, production, true)

                                }, function (dismiss) {
                                    console.log('submit kept as false')
                                    context.createForkPlaceholder(newPlaceholders, production, false)
                                })
                                .catch(() => {
                                    // Close the tab if its clicked to "save & close the tab"
                                    console.log('Exception in send to fork')
                                })

                        })
                        .catch(() => {
                            // Close the tab if its clicked to "save & close the tab"
                            console.log('Exception in record over 3 hours')
                        })
                }
                else {
                    swal({
                        title: 'Send to Fork?',
                        text: `Would you like to submit schedule ingest to Fork?`,
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            //newPlaceholder.submit = true
                            console.log('submit = true')
                            context.createForkPlaceholder(newPlaceholders, production, true)

                        }, function (dismiss) {
                            console.log('submit kept as false')
                            context.createForkPlaceholder(newPlaceholders, production, false)
                        })
                        .catch(() => {
                            // Close the tab if its clicked to "save & close the tab"
                            console.log('Exception in send to fork')
                        })
                }
            }


        },
        createForkPlaceholder(items, production, submit=false){
            //let placeholders = []
            Lazy(items)
                .each((v,k) => {
                    Lazy(v.placeholders)
                        .each((v2,k2) => {
                            // v2.submit = submit
                            // placeholders.push(v2)

                            const _clone_id = Math.floor(100000000000 + Math.random() * 900000000000) // 12 digit
                            app.$emit('AddPlaceholder', {
                                itemId: v2.itemId,
                                itemType: v2.itemType,
                                item: {
                                    id: _clone_id,
                                    country: v2.country,
                                    periodStart: v2.periodStart,
                                    periodEnd: v2.periodEnd,
                                    title: v2.title,
                                    itemId:  v2.itemId,
                                    itemType: v2.itemType,
                                    local: true,
                                    action: 'create',
                                    submit: submit
                                },
                                action: {
                                    module: 'placeholder',
                                    type: 'store',
                                },
                                items: []
                            })
                        })
                })
        },
        updateLocalDefaultPlaceholder(production, country) {
            const ctx = this
            let updates = ctx.waitingUpdates
            const items = ctx.waitingUpdates.items

            let replaceToCountry = country[0] == 'se' ? 'no' : 'se'

            let noForkCountry = _.find(production.placeholders, i => i.country == 'no')
            let seForkCountry = _.find(production.placeholders, i => i.country == 'se')

            if(noForkCountry !== undefined || seForkCountry !== undefined) {
                let forkCountry = noForkCountry !== undefined ? noForkCountry : seForkCountry
                if(forkCountry.country == replaceToCountry) {
                    Lazy(forkCountry.placeholders)
                        .each(v => {
                            let indx = _.findIndex(items, i => i.action.module == 'placeholder' && i.action.type == 'update' && i.item.id == v.id)
                            if(indx > -1) {
                                items.splice(indx, 1)
                                ctx.waitingUpdates.total = items.length

                                if (ctx.waitingUpdates.total <= 0) {
                                    ctx.waitingUpdates.updates = false
                                }
                            }
                        })
                }
                else {
                    Lazy(forkCountry.placeholders)
                        .each(v => {
                            app.$emit('AddPlaceholder', {
                                itemId: v.itemId,
                                itemType: v.itemType,
                                item: {
                                    id: v.id,
                                    country: replaceToCountry,
                                    periodStart: v.periodStart,
                                    periodEnd: v.periodEnd,
                                    title: v.title,
                                    itemId:  v.itemId,
                                    itemType: v.itemType,
                                    local: false,
                                    action: 'update',
                                    submit: v.forkPlaceholder !== null && v.forkPlaceholder !== ''
                                },
                                action: {
                                    module: 'placeholder',
                                    type: 'update',
                                },
                                items: []
                            })
                        })
                }
            }

        },
        /**
         * Returns strongly formatted fix part of title for placholder in Fork
         * @returns
         */
        getDefaultForkTitle(production) {
            let leagueAbbr = ''
            if(production.league && this.leagueEntity && this.leagueEntity.get.items && this.leagueEntity.get.items.length > 0) {
                let league = _.find(this.leagueEntity.get.items, i => i.id == production.league)
                if(league) {
                    //TO DO: ask what means code and replace shortlabel to text if code means abbreviation
                    leagueAbbr = league.shortlabel ? league.shortlabel + ': ' : 'No league abbr: '
                }
            }

            let code, forkName = ''
            let eventDate = ''
            if(production.elementType === 'production') {
                if(production.channel && this.channelEntity && this.channelEntity.get.items && this.channelEntity.get.items.length > 0) {
                    let channel = _.find(this.channelEntity.get.items, i => i.id == production.channel)
                    if(channel) {
                        code = channel.text ? channel.text + ': ' : 'No channel country: '
                        forkName = channel.shortlabel ? channel.shortlabel + ': ' : null
                        if (forkName) {
                            code = forkName + code;
                        }
                    }
                }

                eventDate = production.productionStart ? moment(production.productionStart).format('YYYYMMDD') : ''
            }
            else {
                if(production.contentType && this.contentTypeEntity && this.contentTypeEntity.get.items && this.contentTypeEntity.get.items.length > 0) {
                    let contentType = _.find(this.contentTypeEntity.get.items, i => i.id == production.contentType)
                    if(contentType) {
                        code = contentType.text ? contentType.text + ': ' : 'No CT country: '
                    }
                }

                eventDate = production.feedStart ? moment(production.feedStart).format('YYYYMMDD') : ''
            }


            let title = production.title ? production.title + ' ' : 'No title '


            return code + leagueAbbr + title + eventDate
        },
        /**
         * Deletes placholders for country if it has changed to not record
         * if it was submit to fork then need to ask confirmation
         * @param {*} production
         * @param {*} k2
         */
        deleteLocalDefaultPlaceholder(production, k2, notAskAboutDeleteing=false) {
            const context = this
            if(context.waitingUpdates.items.length > 0) {
                let localPlaceholders = context.waitingUpdates.items.filter(i => i.itemId === production.id && i.itemType === production.elementType && i.action.module === 'placeholder' && i.action.type === 'store')
                if(localPlaceholders && localPlaceholders.length > 0 && k2 && k2.length > 0) {
                    Lazy(k2).each(k => {
                        let localCountryPlaceholders = localPlaceholders.filter(i => i.item.country === k)
                        if(localCountryPlaceholders && localCountryPlaceholders.length > 0) {
                            localCountryPlaceholders.forEach(i => {
                                i.delete = true
                                app.$emit('AddPlaceholder', i)
                            })
                        }
                    })
                }
            }

            if(production.placeholders && production.placeholders.length > 0) {
                let forkCountries = _.filter(production.placeholders, i => _.includes(k2, i.country))
                if(forkCountries && forkCountries.length > 0) {
                    let isForkSubmit = false
                    let idsToDelete = []
                    Lazy(forkCountries)
                        .each((v1,k1) => {
                            Lazy(v1.placeholders)
                                .each((v, k) => {
                                    if(v.hasOwnProperty('forkPlaceholder') && v.forkPlaceholder !== null && v.forkPlaceholder !== '') {
                                        isForkSubmit = true
                                    }
                                    idsToDelete.push(v.id)
                                })
                        })

                    if(isForkSubmit && !notAskAboutDeleteing) {
                        swal({
                            title: 'Placeholder was submitted to Fork',
                            text: `Are you sure you want to delete placeholder?`,
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true,
                            showCloseButton: true
                        })
                            .then(function () {
                                context.deleteForkPlaceholder(idsToDelete, production.id, production.elementType, k2)

                            }, function (dismiss) {
                                console.log('Fork placeholders deleteing was canceled')
                            })
                            .catch(() => {
                                // Close the tab if its clicked to "save & close the tab"
                                console.log('Something went wrong during placeholders deleting')
                            })
                    }
                    else {
                        context.deleteForkPlaceholder(idsToDelete, production.id, production.elementType, k2)
                    }
                }
            }

        },
        /**
         * Sends array of placeholders to the server for mass deleting
         * @param {*} idsToDelete
         * @param {*} itemId
         * @param {*} itemType
         * @param {*} countries
         */
        deleteForkPlaceholder(idsToDelete, itemId, itemType, countries) {

            Lazy(idsToDelete)
                .each((v2,k2) => {
                    // v2.submit = submit
                    // placeholders.push(v2)

                    app.$emit('AddPlaceholder', {
                        itemId: itemId,
                        itemType: itemType,
                        item: {
                            id: v2,
                            itemId:  itemId,
                            itemType: itemType,
                            action: 'delete'
                        },
                        action: {
                            module: 'placeholder',
                            type: 'delete',
                        },
                        items: []
                    })
                })
        },

        defaultPlaceholderForm() {
            return {
                placeholders: null,
                itemId: '',
                itemType: 'feed',
                itemTitle: '',
                itemStart: '',
                itemEnd: '',
                selected: ''
            }
        },

        resetPlaceholderForm() {
            this.placeholderForm = null
            this.showPlaceholderForm = false
        },

    }
}