<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
import EquipmentWithUsage from 'components/equipment/EquipmentWithUsage'
import EquipmentTypeWithUsage from 'components/equipment/EquipmentTypeWithUsage'
import Datetime from 'components/common/helpers/Datetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookDatetime from 'components/common/BookDatetime'
import BookLoader from 'components/common/loader/BookLoader'
import EntityMixin from 'components/entity/EntityMixin'

export default {
  mixins: [
    EntityMixin,
  ],
  components: {
    BookTable,
    BookModal,
    BookDatetime,
    BookLoader
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    directEquipment: {
      default: () => ({})
    },
    disabledMessage: String,
    entityList: {
      default: () => ({})
    },
    selection: {
      default: () => [],
      type: Array
    },
    nonVisible: {
      default: () => [],
      type: Array
    },
    dates: {
      default: () => {
        return {from: null, to: null}
      },
      type: Object
    },
    usageId: {
      default: 0,
      type: Number
    },
    usageType: {
      default: '',
      type: String
    },
    local: {
      default: false,
      type: Boolean
    },
    enableCheckAvailability: {
      default: true,
      type: Boolean
    },
    filterTypesBy: {
      default: () => [],
      type: Array
    },
    excludeTypesBy: {
      default: () => [],
      type: Array
    },
    hideDates: {
      default: false,
      type: Boolean
    },
    columns: {
      default: () => [],
      type:Array
    },
    actionSettings: {
      default: () => ({}),
      type: Object
    },
    print: {
      default: false,
      type: Boolean
    },
    isEngOrEdit: {
      default: false,
      type: Boolean
    },
    isEditProduction: {
      default: false,
      type: Boolean
    },
    isEngProduction: {
      default: false,
      type: Boolean
    },
    isForEquipmentUsagePage: {
      default: false,
      type: Boolean
    },
    isForMCRView: {
      default: false,
      type: Boolean
    },
    isAddForMCRView: {
      default: false,
      type: Boolean,
    },
    label: {
      default: '',
      type: String
    },
    selectedItem: {
      default: '',
      type: String
    },
    itemsType: {
      default: 'Equipment',
      type: String
    },
    mcrRow: {
      default: () => {},
      type: Object
    },
    allEquipmentProps: {
      default: () => [],
      type:Array
    },
    equipmentTypeEntityProps: {
      default: () => ({}),
      type: Object
    },
    equipmentStatusEntityProps: {
      default: () => ({}),
      type: Object
    },
    darkMode: {
      default: false
    },
    isForEquipmentOverview: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      formData: this.defaultData(),
      addForm: {
        show: false,
        edit: false,
        available: false,
        equipment: null,
      },
      loaded: false,
      addFormType: true,
      equipmentTypeEntity: null,
      equipmentStatusEntity: null,
      allEquipment: null,
      equipmentUsage: [],
      isFormChangedVar: false,
      showAll: false,
      oldEquipment: null,
      oldEquipments: null,
      excededFlag: false,
      exceededGroup: '',
      exceededGroupLimit: '',
      checkedEquipmentConflict: false,
      isResettingForm: false,

      mcrTimer: null,
    }
  },
  computed: {
    checking() {
      return this.$loader.has('bookEquipmentSelectionChecking')
    },
    processing() {
      return this.$loader.has('bookEquipmentSelectionSubmit')
    },
    getApiUrl() {
      return 'api/' + this.usageType + '/' + this.usageId + '/' + 'equipment'
    },
    getApiUrlType() {
      return this.getApiUrl + '-type'
    },
    equipmentList() {
      const context = this
      // show all equipment for all countries
      return context.allEquipment.filter(v => {
        return Equipment.isVisible(v, this.showAll)
      })
    },
    isOneRecord(){
      return this.equipmentData.rows.length == 1;
    },
    isAllowedToSetPrimary(){
      // let equipmentIsAvailable = true
      // this.equipmentData.rows.forEach(v => {
      //   // if the equipment is of type Edit suites (id = 64) and is primary, we disallow.
      //   if (v.type.rawValue === 64 && v.isPrimary.rawValue) {
      //     equipmentIsAvailable = false
      //   }
      // })
      // return equipmentIsAvailable;

      return false;
    },
    isDisplayAddButton(){
      if (this.isEngOrEdit && this.usageId == 0) return false;
      return true;
    },
    equipmentTypesList() {
      return this.equipmentTypeEntity.get.items
          .filter(v => {

            // When filterTypesBy is set, ex when creating Edit/ENG, then
            // it can filter away other equipment types that can have been
            // added to the Edit/ENG base template. This means that those
            // equipment pieces (in the template) won't be added.
            if (this.filterTypesBy.length > 0) {
              return this.filterTypesBy.indexOf(v.id) > -1
            }

            if(this.excludeTypesBy.length > 0) {
              return !(this.excludeTypesBy.indexOf(v.id) > -1)
            }

            return true
          })
    },
    hasEquipment() {
      let equipmentIsAvailable = false
      this.equipmentList.forEach(v => {
        if (v.type == this.formData.equipmentType.value) {
          equipmentIsAvailable = true
        }
      })
      return equipmentIsAvailable
    },

    isFormChanged() {
      //console.log("is form changed");
      if (this.formData.id.value) {
        let prevEquipmentId =  this.$route.name === 'equipment.usage.view' ? this.addForm.equipment.equipmentId : this.addForm.equipment.id
        let prevEquipmentStart = this.$route.name === 'equipment.usage.view' ? this.addForm.equipment.periodStart : this.addForm.equipment.equipmentUsage.periodStart
        let prevEquipmentEnd = this.$route.name === 'equipment.usage.view' ? this.addForm.equipment.periodEnd : this.addForm.equipment.equipmentUsage.periodEnd
        let prevPrimary = this.$route.name === 'equipment.usage.view' ? (this.addForm.equipment.isPrimary ? this.addForm.equipment.isPrimary : false) : this.addForm.equipment.equipmentUsage.isPrimary
        let prevNotUpdate = this.$route.name === 'equipment.usage.view' ? (this.addForm.equipment.isNotUpdatePeriod ?  this.addForm.equipment.isNotUpdatePeriod : false) : this.addForm.equipment.equipmentUsage.isNotUpdatePeriod
        switch (true) {
          case (this.formData.equipmentId.value != prevEquipmentId):
          case (!this.hideDates && this.formData.periodStart.value != prevEquipmentStart):
          case (!this.hideDates && this.formData.periodEnd.value != prevEquipmentEnd):
          case (this.formData.isPrimary.value != prevPrimary):
          case (this.formData.isNotUpdatePeriod.value != prevNotUpdate):
            return true
        }
        return false
      }

      // Check only for equipment type, if we don't have a equipment piece.
      return this.formData.equipmentType.value !== ''

    },

    isFormValid() {
      //console.log("is form valid");
      // If it's a real equipment piece, then we need to check for all
      // fields.
      if (this.formData.equipmentId.value != '') {

        // Special check for data fields.
        switch (true) {
          case (!this.hideDates && this.formData.periodStart.validator.rules.required && this.formData.periodStart.value == ''):
          case (!this.hideDates && this.formData.periodEnd.validator.rules.required && this.formData.periodEnd.value == ''):
            return false
        }
      }

      // Check only for equipment type, if we don't have a equipment piece.
      return this.formData.equipmentType.value != ''

    },

    equipmentIsDisabled() {
      return !this.hasEquipment || this.equipmentList.length < 1 || this.formData.equipmentType.value == ''
    },
    getSelection() {
      return this.selection
          .map(v => {
            this.parseItem(v)
            return v
          })
    },
    equipmentData() {
      //WN MCR Rendering dirty fix
      if (!this.loaded) return

      const context = this
      const output = {
        options: {
          actions: []
        },
        columns: [
          {
            value: 'id',
            label: 'ID',
            visible: false
          },
          {
            value: 'visibleId',
            label: 'ID',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('visibleId')
          },
          {
            value: 'type',
            label: 'Type',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('type')
          },
          {
            value: 'equipment',
            label: 'Equipment',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('equipment')
          },
          {
            value: 'kit',
            label: 'Kit',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('kit')
          },
          {
            value: 'description',
            label: 'Description',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('description')
          },
          {
            value: 'status',
            label: 'Status',
            sortable: true,
            searchable: false,
            visible: this.columnIsVisible('status')
          },
          {
            value: 'period',
            label: 'Period',
            sortable: true,
            searchable: false,
            visible: !context.hideDates,
            visible: this.columnIsVisible('period')
          },
          {
            value: 'conflict',
            label: '',
            visible: this.columnIsVisible('conflict')
          },
          {
            value: 'exceeded',
            label: '',
            visible: this.columnIsVisible('exceeded')
          },
          {
            value: 'isPrimary',
            label: 'Primary',
            sortable: false,
            searchable: false,
            visible: this.columnIsVisible('isPrimary')
          },
          {
            value: 'isNotUpdatePeriod',
            label: "Don't Update period",
            sortable: false,
            searchable: false,
            visible: this.columnIsVisible('isNotUpdatePeriod')
          }
        ],
        rows: []
      }
      //console.log("book equipment section", this.loaded);

      // Add actions buttons if the user has permissions to perform these.
      if (this.$ability.can('update', 'Equipment')) {
        output.options.actions.push('edit')
      }
      if (this.$ability.can('delete', 'Equipment')) {
        output.options.actions.push('delete')
      }
      if(this.iconIsVisible()) {
        output.options.actions.push('icon')
      }

      if (Object.keys(this.actionSettings).length > 0) {
        output.options.actionSettings = this.actionSettings
      }

      let isOneRecord = context.getEquipmentItems.length;
      let isFirstRecord = true;

      context.getEquipmentItems
          .filter(v => {
            if (Equipment.isEquipmentPiece(v)) {
              // this.showAll added for all country equipments
              return Equipment.isVisible(v, this.showAll)
            }
            return true
          })
          .forEach(v => {
            const isEquipmentPiece = Equipment.isEquipmentPiece(v)
            // Build some values to be used in the current table row.
            let usageId = context.getItemUsageId(v)
            let visibleId = context.getItemVisibleId(v)
            let typeRaw = Equipment.getItemTypeRaw(v)
            let type = Equipment.getItemType(v, this.equipmentTypeEntity)
            let status = context.getItemStatus(v)
            let conflict = isEquipmentPiece && v.equipmentUsage && v.equipmentUsage.conflict && !v.doNotCreateConflict ? '<span class="label label-danger" title="This equipment piece has a conflict. Please go to the equipment usage timeline to se the conflicting bookings.">conflict</span>' : ''
            let exceeded = isEquipmentPiece && v.equipmentUsage && v.equipmentUsage.exceedsGroupLimit && v.useGroupConflictCount ? '<span class="label label-exceeded" title="The equipment usage for the equipment group exceeds the group limit. Please go to the equipment usage timeline to se the conflicting bookings.">exceeded</span>' : ''
            //let icon = isEquipmentPiece && v.equipmentUsage && !v.equipmentUsage.id ? '<span class="form-control-required-symbol" title="The changes in the equipment usage will be saved after the save button clicked"><font-awesome-icon icon="exclamation-triangle" /></span>' : ''

            let isPrimary = context.getIsPrimaryValue(v, isOneRecord) ? '<input type="radio" name="equipment_usage_primary" disabled checked/>':'<input type="radio" disabled name="equipment_usage_primary"/>'
            let isNotUpdateTime = context.getIsNotUpdateTimeValue(v) ? '<span>true</span>' : '<span>false</span>'
            const item = {
              id: {
                value: usageId
              },
              visibleId: {
                value: visibleId
              },
              type: {
                rawValue: typeRaw,
                value: type
              },
              equipment: {
                value: isEquipmentPiece ? v.label : ''
              },
              description: {
                value: isEquipmentPiece ? v.description : '',
                classes: ['book-overview-item-title']
              },
              status: {
                rawValue: isEquipmentPiece ? v.equipmentStatus : '',
                value: status
              },
              period: {
                value: isEquipmentPiece ? context.getPeriodValue(v) : ''
              },
              conflict: {
                value: conflict
              },
              exceeded: {
                value: exceeded
              },
              isPrimary: {
                rawValue: isEquipmentPiece ? context.getIsPrimaryValue(v, isOneRecord) : '',
                value: isPrimary
              },
              isNotUpdatePeriod:{
                rawValue: isEquipmentPiece ? context.getIsNotUpdateTimeValue(v) : '',
                value: isNotUpdateTime
              },
              rowOptions: {
                classes: []
              },
              kit: {
                value: v.equipmentUsage && v.equipmentUsage.parentId ? v.kitLabel : null,
              }

            }

            if(v.equipmentUsage && v.equipmentUsage.id === null) {
              item.rowOptions.hideActions = ['edit', 'delete']
            }
            else if (v.equipmentUsage){
              item.rowOptions.hideActions = ['icon']
            }

            if (isEquipmentPiece && v.equipmentUsage && v.equipmentUsage.conflict && !v.doNotCreateConflict) {
              if (this.darkMode) { item.rowOptions.classes.push('danger-dark') }
              else item.rowOptions.classes.push('danger')
            }
            if (isEquipmentPiece && v.equipmentUsage && v.equipmentUsage.exceedsGroupLimit && v.useGroupConflictCount) {
              if (this.darkMode) { item.rowOptions.classes.push('danger-dark') }
              else item.rowOptions.classes.push('danger')
            }
            if (isEquipmentPiece && v.suggested) {
              item.rowOptions.highlighted = true
            }

            output.rows.push(item)
          })
      return output
    },
    getEquipmentItems() {
      let selection = []
      if (!this.local) {
        selection = this.getSelection
        //console.log(this.getSelection);
      }
      else {
        // Build each equipment in the current selection.
        this.getSelection
            .forEach(item => {
              // If the item is an equipment piece. (??)
              // not used section if else if stances.
              // allEquipment can be null here
              if (this.allEquipment === null) this.allEquipment = this.$store.state.data.equipment
              const equipment = this.allEquipment.find(v => v.id === item.equipmentId)
              if (equipment) {
                selection.push({
                  id: item.equipmentId,
                  label: equipment.label,
                  description: equipment.description,
                  type: equipment.type,
                  equipmentStatus: equipment.equipmentStatus,
                  suggestions: equipment.suggestions,
                  elementType: 'equipmentPiece',
                  kit: equipment.kit,
                  kitId: equipment.kitId,
                  kitLabel: equipment.kitLabel ?? null,
                  equipmentUsage: {
                    id: item.id,
                    periodStart: item.equipmentUsage.periodStart,
                    periodEnd: item.equipmentUsage.periodEnd,
                    isPrimary: item.equipmentUsage.isPrimary,
                    parentId: item && item.equipmentUsage && item.equipmentUsage.parentId ? item.equipmentUsage.parentId : null,
                  }
                })
              }
              // If the item is an equipment type.
              else if (!Equipment.isEquipmentPiece(item)) {
                if (this.equipmentTypeEntity){
                  const equipmentType = this.equipmentTypeEntity.getItem(item.type)
                  if (equipmentType) {
                    selection.push({
                      id: equipmentType.id,
                      label: equipmentType.label,
                      type: equipmentType.id,
                      elementType: 'equipmentType',
                      equipmentUsage: {
                        id: item.id
                      }
                    })
                  }
                }
              }
            })
      }
      return Lazy(selection)
          .sortBy(item => {
            if (this.local){
              return item.label
            }
            else
            {
              return item.equipmentUsage ? item.equipmentUsage.periodStart : item.equipmentId
            }
          }, false)
          .toArray()
    },

    startDateLimit() {
      let now = moment()
      return [{
        type: 'fromto',
        from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
      }]
    },
    endDateLimit() {
      let from = moment(this.formData.periodStart.value, 'YYYY-MM-DD HH:mm')
      return [{
        type: 'fromto',
        from: from.subtract(1, 'd')
      }]
    },

    getSelectedEquipmentInForm() {
        return this.allEquipment.find(v => v.id === this.formData.equipmentId.value)
    },

    getEquipmentUsageForKit() {
        return this.equipmentUsage.filter(item => item.equipmentId === this.formData.equipmentId.value);
    },

    /**
     * Gets equipment usages for equipments in a kit which has been booked separately.
     *
     * We skip usages which is already displayed through the kit.
     */
    getEquipmentUsageForEquipmentsInKit() {
      const usageIds = new Set();

      for (const item of this.equipmentUsage) {
        usageIds.add(item.equipmentUsageId)
      }

      return this.equipmentUsage.filter(item => item.equipmentId !== this.formData.equipmentId.value && !usageIds.has(item.parentId));
    },

    /**
     * Determines if the direct equipment is routed.
     */
    isRouted() {
      if (this.directEquipment.equipmentUsage && this.directEquipment.equipmentUsage.mcrStatus) {
        return this.directEquipment.equipmentUsage.mcrStatus.find(v => v.country === app.$data.user.get.country)
      } else {
        return null
      }
    }
  },

  watch: {
    'formData.equipmentType.value': function(newValue) {
      //console.log(this.formData);
      // When the form popup is shown and the equipment type is changed,
      // reset the form fields without resetting the popup itself,
      // which this.resetForm() does.
      if (!this.addForm.show || this.isResettingForm) return

      let usageId = this.formData.id.value
      let addAnother = this.formData.addAnother.value
      this.formData = this.defaultData()
      this.formData.equipmentType.value = newValue
      this.formData.id.value = usageId
      this.formData.addAnother.value = addAnother
      this.expandSelectList('#selectList');

    },
    'formData.equipmentId.value': function(newValue, oldValue) {
      if (!this.addForm.show || this.isResettingForm) return

      this.oldEquipment = oldValue

      if (this.enableCheckAvailability && this.addForm.show) {
        Vue.nextTick(() => {
          this.checkAvailability()
        })
      }

      // Since we can save both equipment type and equipment piece, we need
      // shift the local value for equipment type field so it is sent to
      // the API on submit().
      if (newValue != '') {
        //console.log('formData.equipmentId.value: ' + newValue)
        this.formData.equipmentType.local = true
        this.setFormDefaultDates()
      }
      else {
        this.formData.equipmentType.local = false
      }

    },
    'formData.periodStart.value': function(newValue) {
      const context = this
      if (this.hideDates || !this.addForm.show || this.isResettingForm) return
      //console.log('this.formData.periodEnd.value: '+this.formData.periodEnd.value)
      //console.log(Datetime.isAfter(this.formData.periodEnd.value, newValue, 'YYYY-MM-DD HH:mm'))

      if (Datetime.isAfter(this.formData.periodEnd.value, newValue, 'YYYY-MM-DD HH:mm')) {
        //console.log('New end value')
        //console.log(this.formData.periodStart.value)
        this.formData.periodEnd.value = this.formData.periodStart.value
      }
      if (this.enableCheckAvailability) {
        Vue.nextTick(() => {
          this.checkAvailability()
        })
      }

    },
    'formData.periodEnd.value': function(newValue) {
      if (this.hideDates || !this.addForm.show || this.isResettingForm) return

      // Equipment duration period for 24 hours should be checked over here
      // instead of saving to real id, ask before its too late
      let fStart = this.formData.periodStart.value
      fStart = new Date(fStart);
      let fEnd = this.formData.periodEnd.value
      fEnd = new Date(fEnd);
      let differenceTime = fEnd.getTime() - fStart.getTime();
      differenceTime = Math.floor(differenceTime / 3600000);

      if (differenceTime >= 24) {
        swal({
          title: '24 Hours Long',
          text: `This Equipment booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
          type: 'question',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCancelButton: true,
          showCloseButton: true
        })
      }

      //console.log('this.formData.periodStart.value'+this.formData.periodStart.value)
      //console.log(Datetime.isAfter(newValue, this.formData.periodStart.value, 'YYYY-MM-DD HH:mm'))

      if (Datetime.isAfter(newValue, this.formData.periodStart.value, 'YYYY-MM-DD HH:mm')) {
        //console.log('New start value')
        //console.log(this.formData.periodStart.value)
        this.formData.periodStart.value = this.formData.periodEnd.value
      }
      if (this.enableCheckAvailability) {
        Vue.nextTick(() => {
          this.checkAvailability()
        })
      }
      if(newValue != ''){
        this.formData.equipmentType.local = true
      }else {
        this.formData.equipmentType.local = false
      }
    },
    'formData.isPrimary.value': function(newValue){
      this.formData.isPrimary.value = newValue

      if (this.enableCheckAvailability) {
        Vue.nextTick(() => {
          this.checkAvailability()
        })
      }
    },
    'formData.isNotUpdatePeriod.value': function(newValue){
      this.formData.isNotUpdatePeriod.value = newValue
      //console.log(newValue);
    },
  },
  beforeMount() {
    Lazy(this.entities)
        .each((v, k) => {
          this[k] = v
        })
  },
  mounted() {
    const context = this
    const dataToLoad = []
    const entities = []

    //console.log(this, 'BEQSelectionn')
    // entities
    const equipmentStatusEntityId = this.$settings.get.equipment.equipmentstatus_entity_id
    const equipmentTypeEntityId = this.$settings.get.equipment.equipmenttype_entity_id

    if (this.equipmentStatusEntity === null) {
      this.equipmentStatusEntity = this.entityList.equipmentStatusEntity
      if (typeof this.equipmentStatusEntity === 'undefined') {
        this.equipmentStatusEntity = new Entity(equipmentStatusEntityId)
        entities.push(equipmentStatusEntityId)
      }
    }

    if (this.equipmentTypeEntity === null) {
      this.equipmentTypeEntity = this.entityList.equipmentTypeEntity
      if (typeof this.equipmentTypeEntity === 'undefined') {
        this.equipmentTypeEntity = new Entity(equipmentTypeEntityId)
        entities.push(equipmentTypeEntityId)
      }
    }

    context.fiberEquipmentType = this.$settings.get.equipment.fiber_source_entityitem_id

    if(entities.length > 0) {
      const entity = new Entity()
      dataToLoad.push(entity.all({ids: entities.join(',')}))
    }

    if(this.allEquipmentProps === null || this.allEquipmentProps.length <= 0) {
      // Load equipment pieces.
      const equipmentList = new Equipment()
      dataToLoad.push(equipmentList.all({filter: context.usageType, country: app.$data.user.get.country}))
    }
    else {
      this.allEquipment = this.$store.state.data.equipment
    }

    //console.log(dataToLoad, 'HMM?')
    // I believe this page has so many render triggering issues,
    // need to investigate more.
    if(dataToLoad.length > 0) {
      Promise.all(dataToLoad)
          .then(response => {
            Vue.nextTick(() => {
                this.allEquipment = this.$store.state.data.equipment
              context.loaded = true
            })
          })
          .catch(error => {
            if (error.response && error.response.status != 404) {
              context.$error.set(error, 'It was not possible to load the equipment.')
            }
            context.loaded = true
          })
    }
    else{
      context.loaded = true
    }

    if (context.local) {
      context.addForm.available = true
    }
    // No more double rendering WN (Performance Boost)
    context.loaded = true

  },
  methods: {
    getRowMCRStatus(row) {
      const _mcr = row.rowOptions.mcrStatus
      let _status
      if (_mcr) {
        Lazy(_mcr)
            .each((v,k) => {
              // should be country specific also darling WN.
              if (v.status && v.country === app.$data.user.get.country) {
                _status = v.status
              }
            })
      }
      return _status
    },
    /**
     * Get a new class instance, based the passed "type".
     * @param string url The
     * @param mixed id (optional) An item it to pass to the class.
     * @return class
     */
    getNewClass(type, id = null) {
      switch (type) {
        case 'equipmentPiece':
          return new EquipmentWithUsage(this.getApiUrl, id)
        case 'equipmentType':
          return new EquipmentTypeWithUsage(this.getApiUrlType, id)
      }
    },

    /**
     * Reset the values on add form.
     */
    resetForm() {
      this.isResettingForm = true

      this.formData = this.defaultData()
      this.addForm.equipment = null
      this.addForm.edit = false

      this.collapseSelectList()

      Vue.nextTick(() => {
        this.isResettingForm = false
      })

    },

    /**
     * Get the value to be shown as equipment usage period.
     * @param object equipment
     * @return string
     */
    getPeriodValue(equipment) {
      if (!this.hideDates && equipment.equipmentUsage && equipment.equipmentUsage.periodStart && equipment.equipmentUsage.periodEnd) {
        let periodStart = moment(equipment.equipmentUsage.periodStart, 'YYYY-MM-DD HH:mm')
        let periodEnd = moment(equipment.equipmentUsage.periodEnd, 'YYYY-MM-DD HH:mm')
        return periodStart.format('HH:mm') + ' - ' + periodEnd.format('HH:mm')
      }
      return ''
    },

    /**
     * This is an intermediate step to the submit process, since we need to
     * if a equipment type has become an equipment piece and vice-versa.
     */
    submitItem() {
      // old equipment of update stage and full old equipments for updating suggestions
      this.oldEquipments = this.selection

      switch (true) {

          // An equipment piece has been changed into an equipment type.
        case (this.addForm.equipment && (this.addForm.equipment.elementType == 'equipmentPiece') && this.formData.equipmentId.value == ''):

          // An equipment type has been changed into an equipment piece.
        case (this.addForm.equipment && (this.addForm.equipment.elementType == 'equipmentType') && this.formData.equipmentId.value != ''):

          // Firstly we need to remove the current equipment piece usage.
          let id = this.formData.id.value
          let type = this.addForm.equipment.elementType
            console.log('WTF man', id, type)

          if (this.local) {

            this.removeEquipment(id, type, false)
            this.formData.id.value = 0

            // We need a little "delay", otherwise "this.getSelection"
            // doesn't get updated in time.
            Vue.nextTick(() => {
              this.submit()
              this.$loader.remove('bookEquipmentSelectionSubmitItem')
            })

          }

          else {
            const equipmentUsage = this.getNewClass(type, id)
            equipmentUsage.delete()
                .then(response => {

                  // Now we can remove the equipment piece usage from
                  // the list and submit the equipment type usage
                  // as a totaly new element.
                  this.removeEquipment(id, type, false)
                  this.formData.id.value = 0
                  this.submit()
                  this.$loader.remove('bookEquipmentSelectionSubmitItem')

                })
                .catch(error => {
                  this.$loader.remove('bookEquipmentSelectionSubmitItem')
                })
          }

          break

          // The element type wasn't changed.
        default:
          this.submit()
          this.$loader.remove('bookEquipmentSelectionSubmitItem')
          break

      }

    },
    /**
     * Creates a Ghost Equipment for MCR-View AutoSave operations
     * very dangerous ... because this is not in the store data
     * since AutoSave option is going to save it under store,
     * should be fine to use it..
     * ---- Rules
     * data { id: eqUsageid, equipmentId: eqId, periodStart, periodEnd  }
     * */
    createGhostEquipment(data, suggestionId = null) {
      const context = this
      const _clone_id = Math.floor(100000000000 + Math.random() * 900000000000) // 12 digit
      const _eqUsageId = (data.id === 0 || data.id === null) ? _clone_id : parseInt(data.id)
      const _newEquipmentId = suggestionId ? suggestionId : parseInt(data.equipmentId)

      const equipment = context.allEquipment.find(v => v.id === _newEquipmentId)
      const newEquipment = {}

      Lazy(equipment)
          .each((v, k) => {
            newEquipment[k] = v
          })

      newEquipment.equipmentUsage = {
        equipmentId: _newEquipmentId,
        id: _eqUsageId,
        isPrimary: false,
        conflict: suggestionId ? false : context.checkedEquipmentConflict,
        conflictDetails: null,
        exceedsDetails: null,
        exceedsGroupLimit: false,
        isNotUpdatePeriod: false,
        mcrStatus: null,
        periodStart: data.periodStart,
        periodEnd: data.periodEnd,
        usageId: this.usageId,
        usageType: this.usageType,
      }

      return newEquipment;

    },

    sendUpdatesToMCR(equipmentUsage, action) {
      const context = this
      const equipment = context.directEquipment

      let suggestedEquipment, newSuggestedEquipment

      const newEquipment = this.createGhostEquipment(equipmentUsage) // oldusageId -> new equipment id

      const items = context.getSelection
      const newEquipments = [newEquipment];

      if (equipment.id !== equipmentUsage.equipmentId) {
          if (equipment.kit) {
              items.forEach((item, i) => {
                  if (item.equipmentUsage && item.equipmentUsage.parentId === equipmentUsage.id) {
                      items.splice(i, 1)
                  }
              })
          }
      }

      this.autoSaveUpdateEquipment(newEquipments, items, action)

      // first check if the old suggested equipment added, update it to new one
      if (equipmentUsage.includeSuggested && equipment.suggestions) {

          // only 1 suggestion
          if (Array.isArray(equipment.suggestions) && equipment.suggestions.length > 0) equipment.suggestions = equipment.suggestions[0].id

          suggestedEquipment = context.getSelection.find(v => v.id === equipment.suggestions)

          // suggested equipment exists
          if (suggestedEquipment) {
            // add values (WE NEED TO USE SUGGESTED EQS equipmentUsage data to update)
            suggestedEquipment.equipmentUsage.usageId = context.usageId
            suggestedEquipment.equipmentUsage.usageType = context.usageType
            console.log(suggestedEquipment, 'suggested already')

            //check new equipment suggestions
            if (newEquipment.suggestions) {

              // only 1 suggestion
              if (Array.isArray(newEquipment.suggestions) && newEquipment.suggestions.length > 0) newEquipment.suggestions = newEquipment.suggestions[0].id

              // create the equipment for new Suggest
              newSuggestedEquipment = context.createGhostEquipment(suggestedEquipment.equipmentUsage, newEquipment.suggestions)
              newEquipments.push(newSuggestedEquipment)
              // and change with the new one
              this.autoSaveUpdateEquipment(newEquipments, items, 'save')

            }
          }
          // if suggested not exist, then just add the new
          else {
            if (newEquipment.suggestions) {

              // only 1 suggestion
              if (Array.isArray(newEquipment.suggestions) && newEquipment.suggestions.length > 0) newEquipment.suggestions = newEquipment.suggestions[0].id

              // because this is new one
              equipmentUsage.id =  0
              // get the equipment of suggestion
              newSuggestedEquipment = context.createGhostEquipment(equipmentUsage, newEquipment.suggestions)
              newEquipments.push(newSuggestedEquipment)

              this.autoSaveUpdateEquipment(newEquipments, items, 'store')
            }

          }

        console.log(equipment, 'suggested', equipment.suggestions)
      }
      // if suggested equipment not added and required, then add as a new one
      if (equipmentUsage.includeSuggested && !equipment.suggestions) {

        if (newEquipment.suggestions) {
          // only 1 suggestion
          if (Array.isArray(newEquipment.suggestions) && newEquipment.suggestions.length > 0) newEquipment.suggestions = newEquipment.suggestions[0].id
          // because this is new one
          equipmentUsage.id =  0
          // get the equipment of suggestion
          newSuggestedEquipment = context.createGhostEquipment(equipmentUsage, newEquipment.suggestions)
          newEquipments.push(newSuggestedEquipment)

          this.autoSaveUpdateEquipment(newEquipments, items, 'store')
        }
      }



    },

    /**
     * Submit the current equipment piece/type to the API.
     */
    submit() {
      const context = this
      context.$loader.add('bookEquipmentSelectionSubmit')
      let type = (context.formData.equipmentId.value != '') ? 'equipmentPiece' : 'equipmentType'
      let id = (context.local && !context.formData.id.value) ? moment().unix() : context.formData.id.value
      let includeSuggestions = (context.formData.equipmentId.value != '') ? context.formData.includeSuggested.value : false

      const equipmentUsage = this.getNewClass(type, id)

      Lazy(context.formData)
          .filter(v => !v.local)
          .each((v, k) => {
            if (id && (k == 'id')) equipmentUsage.set('id', id)
            else equipmentUsage.set(k, v.value)
          })

      if (context.local) {
        console.log("LOCAL SAVING")
        const item = {
          id: equipmentUsage.get.id,
          equipmentId: equipmentUsage.get.equipmentId,
          equipmentType: equipmentUsage.get.equipmentType,
          type: equipmentUsage.get.equipmentType ? equipmentUsage.get.equipmentType : context.formData.equipmentType.value,
          elementType: type,
          equipmentUsage: {}
        }
        if (!context.hideDates) {
          item.equipmentUsage.periodStart = equipmentUsage.get.periodStart
          item.equipmentUsage.periodEnd = equipmentUsage.get.periodEnd
        }

        item.equipmentUsage.isPrimary = equipmentUsage.get.isPrimary
        item.equipmentUsage.isNotUpdatePeriod = equipmentUsage.get.isNotUpdatePeriod

        //console.log(item, 'updating from 3')
        context.updateEquipment(item, false, true)

        if (context.formData.addAnother.value) {
          context.resetForm()
        } else {
          context.showForm(false)
        }
        context.$loader.remove('bookEquipmentSelectionSubmit')
      }
      else {
        equipmentUsage.isPrimary = equipmentUsage.get.isPrimary
        equipmentUsage.isNotUpdatePeriod = equipmentUsage.get.isNotUpdatePeriod
        // create operation
        if (!equipmentUsage.options.id) {
          // send data to AutoSave
          if (this.isForMCRView) {
            context.$loader.remove('bookEquipmentSelectionSubmit')
            context.sendUpdatesToMCR(equipmentUsage.get, 'store')
            context.showForm(false)
            return
          }

          /**
           * Add suggestions dialog was replaced by checkbox on the form
           */
          if(includeSuggestions) equipmentUsage.set('returnEquipmentChain', true)
          console.log("NON-LOCAL NON OPTIONS ID create operation -- CREATING", equipmentUsage, includeSuggestions)
          equipmentUsage.store()
              .then(response => {
                if(response.data?.createdSuggestions) {
                  console.log(response.data, 'updating from 1')
                  context.updateEquipments(response.data, false, true)
                }
                else {
                  //console.log(response.data, 'updating from 2')
                  context.updateEquipment(response.data, false, true)
                }

                if (context.formData.addAnother.value) {
                    context.resetForm()
                } else {
                    context.showForm(false)
                }
                context.$loader.remove('bookEquipmentSelectionSubmit')
                // if (response.data.suggestions && response.data.suggestions.length > 0) {
                //   swal({
                //     title: 'Add suggestions?',
                //     text: `Do you want to add equipment related to ${response.data.label}?`,
                //     type: 'question',
                //     confirmButtonText: 'Yes',
                //     cancelButtonText: 'No',
                //     showCancelButton: true
                //   })
                //       .then(() => {
                //         context.submitSuggestions(response.data)
                //       })
                // } else {
                  if (equipmentUsage.isPrimary) window.location.reload(true);
                //}
              })
              .catch(error => {
                context.$error.set(error, 'It was not possible to save the equipment.')
                context.$loader.remove('bookEquipmentSelectionSubmit')
              })
        }
        // SAVE operation
        else {
          // send data to AutoSave
          if (this.isForMCRView) {
            context.$loader.remove('bookEquipmentSelectionSubmit')
            context.sendUpdatesToMCR(equipmentUsage.get, 'save')
            context.showForm(false)
            return
          }

          /**
           * Add suggestions dialog was replaced by checkbox on the form
           */
          if(includeSuggestions) equipmentUsage.set('returnEquipmentChain', true)
          console.log("NON-LOCAL WITH OPTIONS ID -- SAVING", equipmentUsage)
          equipmentUsage.save()
              .then(response => {
                if(response.data?.createdSuggestions) {
                  console.log(response.data, 'updating from 4')
                  context.updateEquipments(response.data, false, true)
                }
                else {
                  //console.log(response.data, 'updating from 5')
                  context.updateEquipment(response.data, false, true)
                }
                // suggest equipment for update as well
                // if (response.data.suggestions && response.data.suggestions.length > 0 && context.oldEquipment !== null) {
                //   setTimeout(() => {
                //     context.showForm(false)
                //     context.$loader.remove('bookEquipmentSelectionSubmit')
                //     swal({
                //       title: 'Add/Update suggestions?',
                //       text: `Do you want to add equipment related to ${response.data.label}?`,
                //       type: 'question',
                //       confirmButtonText: 'Yes',
                //       cancelButtonText: 'No',
                //       showCancelButton: true,
                //     })
                //         .then(() => {
                //           context.submitSuggestions(response.data)
                //         })
                //   }, 3000)
                // } else {
                  context.showForm(false)
                  context.$loader.remove('bookEquipmentSelectionSubmit')
                  if (equipmentUsage.isPrimary) window.location.reload(true);
                //}

              })
              .catch(error => {
                context.$error.set(error, 'It was not possible to save the equipment.')
                context.$loader.remove('bookEquipmentSelectionSubmit')
              })
        }
      }

    },

    /**
     * Add all the suggestions, starting from the passed equipment, to the
     * current equipment list.
     * @param object equipment
     * @return promise
     */
    submitSuggestions(equipment) {
      const context = this
      context.$loader.add('bookEquipmentSelectionSubmit')

      let suggestionId
      let _label
      // check first if its already added?
      suggestionId = equipment.suggestions
      if (Array.isArray(suggestionId) || typeof suggestionId === 'object') {
        Lazy(suggestionId).each(v1 => {
          suggestionId = v1.id
          _label = v1.label
        })
      }
      Lazy(this.getSelection).each(v2 => {
        if (!v2.equipmentId) {
          if (this.$route.name === 'equipment.usage.view') {
            if (v2.id === suggestionId && v2.equipmentUsage.usageId === equipment.equipmentUsage.usageId) {
              suggestionId = 'added'
            }
          }
          else {
            if (v2.id === suggestionId) {
              suggestionId = 'added'
            }
          }
        }
        if (v2.equipmentId) {
          if (this.$route.name === 'equipment.usage.view') {
            if (v2.equipmentId === suggestionId && v2.usageId === equipment.equipmentUsage.usageId) {
              suggestionId = 'added'
            }
          }
          else {
            if (v2.equipmentId === suggestionId) {
              suggestionId = 'added'
            }
          }
        }
      })

      console.log(equipment.suggestions, 'ilginc', suggestionId, this.getSelection)

      if (suggestionId === 'added') {
        this.$alert.set(`No equipment has been added, probably because the suggested equipment ( <b>${_label}</b> )  are already added.`, 'danger', 10)
        context.$loader.remove('bookEquipmentSelectionSubmit')
        return
      }

      return new Promise((resolve, reject) => {
        const params = {
          id: equipment.equipmentUsage.id,
        }
        axios.post(context.getApiUrl + '/suggestions', params)
            .then(response => {
              if (response.status != 200) return
              if (response.data.length < 1) {
                this.$alert.set('No equipment has been added, probably because the suggested equipment are already added by you or they aren\'t available anymore.', 'danger', 10)
              } else {
                response.data.forEach(v => {
                  v.suggested = true
                })

                if (this.$route.name !== 'equipment.usage.view') {
                  //console.log(response.data, 'updating from 6')
                  context.updateEquipment(response.data, false, true)
                }

                this.$alert.set(response.data.length + ' equipment related to ' + equipment.label + ' has/have been added and highlighted.', 'success', 5)
                console.log(context.$route, 'route?')
              }
              
              

              Vue.nextTick(()=> {
                setTimeout(() => {
                  this.removeOldSuggestion(context.oldEquipment, context.oldEquipments, equipment, response.data)
                }, 3500)
              })

              context.$loader.remove('bookEquipmentSelectionSubmit')
            })
            .catch(error => {
              context.$loader.remove('bookEquipmentSelectionSubmit')
              reject(error)
            })
      })

    },
    removeOldSuggestion(equipmentId, equipments, usage, equipment) {
      const context = this
      let suggestionId
      let localId
      let id
      let type

      console.log(usage, 'hmm', equipments);

      Lazy(equipments)
          .each(v => {
            if (!v.equipmentId) {
              console.log('we are here 1')
              if (v.id === equipmentId) {
                suggestionId = v.suggestions

                if (Array.isArray(v.suggestions) || typeof v.suggestions === 'object') {
                  Lazy(v.suggestions).each(v1 => {
                    suggestionId = v1.id
                  })
                }

                Lazy(equipments).each(v2 => {
                  if (context.$route.name === 'equipment.usage.view') {
                    if (v2.id === suggestionId && v2.equipmentUsage.usageId === usage.equipmentUsage.usageId) {
                      console.log(v2, 'equipment page uses')
                      localId = v2.equipmentUsage.id
                      type = v2.elementType
                    }
                  }
                  else {
                    if (v2.id === suggestionId) {
                      console.log(v2, 'other pages')
                      localId = v2.equipmentUsage.id
                      type = v2.elementType
                    }
                  }
                })
              }
            }
            if (v.equipmentId) {
              console.log('we are here 2')
              if (v.equipmentId === equipmentId) {
                suggestionId = v.equipment.suggestions

                if (Array.isArray(suggestionId) || typeof suggestionId === 'object') {
                  Lazy(suggestionId).each(v1 => {
                    suggestionId = v1.id
                  })
                }
                Lazy(equipments).each(v2 => {
                  if (context.$route.name === 'equipment.usage.view') {
                    if (v2.equipment.id === suggestionId && v2.usageId === usage.equipmentUsage.usageId) {
                      localId = v2.id
                      type = v2.elementType === 'period' ? 'equipmentPiece' : 'equipmentType'
                    }
                  }
                  else {
                    if (v2.equipment.id === suggestionId) {
                      localId = v2.id
                      type = v2.elementType === 'period' ? 'equipmentPiece' : 'equipmentType'
                    }
                  }
                })
              }
            }
          })

      id = localId
      const equipmentUsage = context.getNewClass(type, id)
      if (localId) {
        context.$loader.add('bookEquipmentSelectionSubmit')
        //return
          equipmentUsage.delete()
            .then(response => {
              console.log(suggestionId, localId, 'SUCCESSED', equipments)
              context.removeEquipment(id, type, false)
              context.$loader.remove('bookEquipmentSelectionSubmit')
              
            })
            .catch(error => {
              context.$error.set(error, 'It was not possible to remove the equipment.')
              context.$loader.remove('bookEquipmentSelectionSubmit')
              
            })
      
        

      } else {
        console.log(suggestionId, localId, 'FAILED', equipments, usage)
        context.updateEquipment(equipment, false, true)
        return false
      }


    },

    /**
     * Edit an equipment item.
     * @param id
     * @param elementType
     */
    editItem(id, elementType = null) {
      const context = this

      if (elementType === 'contribution') {
        context.$alert.set('You can not change equipment over contribution. You need to change it from owner of the contribution.', 'danger', 10)
        return
      }

      // If the "id" is a string, then it contains the type in it and we
      // need extract it.
      let value = Equipment.getItemIdAndType(id)
      id = value.id
      let type = value.type
      //console.log(type, value, 'Edit Item')

      context.addForm.edit = true
      context.addForm.equipment = context.getEquipmentItems
          .filter(v => {
            return v.elementType === type
          }).find(v => {
            if (v.equipmentUsage)
              return v.equipmentUsage.id === id
            else if (context.isForEquipmentUsagePage)
              return v.id === id
            else
              return false
          })

      if (!context.addForm.equipment) return
      // Populate the fields based on elementType.
      context.formData.id.value = id
      if (Equipment.isEquipmentPiece(context.addForm.equipment) || context.isForEquipmentUsagePage) {
        context.formData.equipmentType.local = true
        context.formData.equipmentType.value = context.addForm.equipment.type ? context.addForm.equipment.type :context.addForm.equipment.equipmentType
        context.formData.equipmentId.value = context.isForEquipmentUsagePage && !context.isForMCRView ? context.addForm.equipment.equipmentId : context.addForm.equipment.id
        if (!context.hideDates) {
          //console.log(context, 'hmmm')
          context.formData.periodStart.value = context.addForm.equipment.equipmentUsage ? context.addForm.equipment.equipmentUsage.periodStart : context.addForm.equipment.periodStart
          context.formData.periodEnd.value = context.addForm.equipment.equipmentUsage ? context.addForm.equipment.equipmentUsage.periodEnd : context.addForm.equipment.periodEnd
        }
        if (context.addForm.equipment.equipmentUsage)
          context.formData.isNotUpdatePeriod.value = context.addForm.equipment.equipmentUsage.isNotUpdatePeriod
      }
      else {
        context.formData.equipmentType.local = false
        context.formData.equipmentType.value = context.addForm.equipment.id
      }

      context.showForm(true)

      setTimeout(() => {
        this.expandSelectList('#selectList')
      }, 500)

    },

    /**
     * Delete an equipment item, removing the usage (connection) to EquipmentHelpeonso
     * current object in the database.
     * @param integer id The id of the equipment item to remove.
     */
    deleteItem(id) {
      const context = this
      swal({
        title: 'Remove?',
        text: 'Are you sure you want to remove this equipment?',
        type: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      })
          .then(function() {
            context.$loader.add('bookEquipmentSelectionSubmit')
            if (context.local) {
              let value = Equipment.getItemIdAndType(id)
              id = value.id
              let type = value.type
              context.removeEquipment(id, type, false)
              context.$alert.set('The item was removed.', 'success', 4)
              context.$loader.remove('bookEquipmentSelectionSubmit')
            }
            else {
              let value = Equipment.getItemIdAndType(id)
              id = value.id
              let type = "";
              if (value.type == "period"){
                type = "equipmentPiece";
              }else{
                type = value.type
              }



              const equipmentUsage = context.getNewClass(type, id)
              equipmentUsage.delete()
                  .then(response => {

                    if (response.data.deleted) {
                        context.removeEquipments(response.data.deleted)
                    } else {
                        context.removeEquipment(id, type, false)
                    }
                    context.$alert.set('The item was removed.', 'success', 4)
                    context.$loader.remove('bookEquipmentSelectionSubmit')
                  })
                  .catch(error => {
                    context.$error.set(error, 'It was not possible to remove the equipment.')
                    context.$loader.remove('bookEquipmentSelectionSubmit')
                  })
            }
          })
    },

    /**
     * Open/close the add/edit form for Equipment Usage.
     * @param boolean open Tells the function to open/close the form.
     */
    showForm(open = true) {
      if (!open) {
        this.resetForm()
        this.addForm.show = false
      }
      else {
        if (!this.formData.id.value) {
          this.setFormDefaultDates()

          // If only one equipment type is available, select that
          // element by default.
          if (this.equipmentTypesList.length == 1) {
            this.formData.equipmentType.value = this.equipmentTypesList[0].id
          }
        }
        Vue.nextTick(() => {
          this.addForm.show = true
        })
      }
    },

    expandSelectList(element) {
      if (jQuery('#selectList')[0])
        jQuery('#selectList')[0].size = 15;
    },

    collapseSelectList() {
      if (jQuery('#selectList')[0])
        jQuery('#selectList')[0].size = 0;
    },

    /**
     * Set the dates that should be default on the add/edit form.
     */
    setFormDefaultDates() {
      if (this.dates.from) this.formData.periodStart.value = moment(this.dates.from).format('YYYY-MM-DD HH:mm')
      if (this.dates.to) this.formData.periodEnd.value = moment(this.dates.to).format('YYYY-MM-DD HH:mm')
    },

    /**
     * Update the equipment in the current feed/production.
     * for autoSave operation
     */
    autoSaveUpdateEquipment(items, equipments, action) {
      const context = this
      const equipment = equipments
      if (!Array.isArray(items)) {
        items = [items]
      }
      items.forEach(item => {
        this.parseItem(item)
        let i = -1
        if (Equipment.isEquipmentPiece(item)) {
          i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && parseInt(v.equipmentUsage.id) === parseInt(item.equipmentUsage.id))
        }
        else {
          i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && parseInt(v.equipmentUsage.id) === parseInt(item.equipmentUsage.id))
        }
        if (action === 'store' || action === 'save') {
          if (i > -1) equipment.splice(i, 1)
          equipment.push(item)
        }
        if (action === 'delete') {
          if (i > -1) equipment.splice(i, 1)
        }
      })
      // and now set for all
      items.forEach(item => {
        // precaution for Equipment usage page
        app.$emit('AutoChangeEquipments', {
          autoSave: true,
          itemId: context.usageId,
          itemType: context.usageType,
          item: item.equipmentUsage,
          equipment: item, // equipment with equipmentusage
          action: {
            module: 'equipment',
            type: action,
          },
          items: equipment
        })
      })

    },

    /**
     * Update the equipment in the current feed/production.
     * @param object items The equipment to add/update.
     * @param boolean notify (optional) Show a notification/alert about the update.
     */
    updateEquipment(items, notify = true, page = true) {
      const context = this
      const equipment = context.getSelection.slice(0)
      if (!Array.isArray(items)) {
        items = [items]
      }

      items.forEach(item => {

        this.parseItem(item)
        let i = -1

        if (Equipment.isEquipmentPiece(item)) {

          // We should check for a "local" equipment, which means we
          // are in a template context.
          if (this.local) {
            i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.id == item.id)
          }

              // We are in a "normal" context and need to search for
          // equipment usage.
          else {
            i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
          }
        }

        // We search for an equipment type (not a real equipment piece).
        else {
          if (this.local) {
            i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.id == item.id)
          }
          else {
            i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
          }
        }

        if (i > -1) equipment.splice(i, 1)
        equipment.push(item)

      })
      // precaution for Equipment usage page
      if (page) {
        context.$emit('changedEquipment', equipment, notify)
        app.$emit('changingEquipment', equipment, notify)
        console.log(equipment, notify, 'UPDATE EQUIPMENT')
      }
    },

    /**
     * Update the equipment in the current feed/production.
     * @param items object items The equipment to add/update/delete.
     * @param notify boolean notify (optional) Show a notification/alert about the update.
     * @param page boolean if it's page or bg.
     */
    updateEquipments(items, notify = true, page = true) {
      const equipment = this.getSelection.slice(0)
      let createdItems = []
      let deletedItems = []
      if (typeof items === 'object' && items.hasOwnProperty('createdSuggestions')) {
        createdItems = items.createdSuggestions
      }
      if (typeof items === 'object' && items.hasOwnProperty('deletedSuggestions')) {
        deletedItems = items.deletedSuggestions
      }
      if(typeof items === 'object' && !items.hasOwnProperty('createdSuggestions') && !items.hasOwnProperty('deletedSuggestions')) {
        this.updateEquipment(items, notify, page)
      }

      if(createdItems.length > 0) {
        createdItems.forEach(item => {

          this.parseItem(item)
          let i = -1

          if (Equipment.isEquipmentPiece(item)) {

            // We should check for a "local" equipment, which means we
            // are in a template context.
            if (this.local) {
              i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.id == item.id)
            }

                // We are in a "normal" context and need to search for
            // equipment usage.
            else {
              i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
            }
          }

          // We search for an equipment type (not a real equipment piece).
          else {
            if (this.local) {
              i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.id == item.id)
            }
            else {
              i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
            }
          }

          if (i > -1) equipment.splice(i, 1)
          equipment.push(item)

        })

        if (page) {
          this.$emit('changedEquipment', equipment, notify)
          app.$emit('changingEquipment', equipment, notify)
        }

      }

      if(deletedItems.length > 0) {
        deletedItems.forEach(item => {

          this.parseItem(item)
          let i = -1

          if (Equipment.isEquipmentPiece(item)) {

            // We should check for a "local" equipment, which means we
            // are in a template context.
            if (this.local) {
              i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.id == item.id)
            }

                // We are in a "normal" context and need to search for
            // equipment usage.
            else {
              i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
            }
          }

          // We search for an equipment type (not a real equipment piece).
          else {
            if (this.local) {
              i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.id == item.id)
            }
            else {
              i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
            }
          }

          if (i > -1) equipment.splice(i, 1)

        })

        this.$emit('deletingEquipment', deletedItems)
        app.$emit('deletingEquipment', deletedItems)

        // precaution for Equipment usage page
        if (page) {
          this.$emit('changedEquipment', equipment, notify)
          app.$emit('changingEquipment', equipment, notify)
        }

      }

    },

    /**
     * Remove an equipment from the current feed/production.
     * @param integer id The id of the equipment usage.
     * @param string type The type of the equipment usage.
     * @param boolean notify (optional) Show a notification/alert about the update.
     */
    removeEquipment(id, type, notify = true) {
      const equipment = this.getSelection.slice(0)

      let i = -1

      // We should check for a "local" equipment, which means we
      // are in a template context.
      if (this.local) {
        i = equipment.findIndex(v => (v.elementType == type) && v.id == id)
      }
      // We are in a "normal" context and need to search for a real
      // equipment usage.
      else {
        i = equipment.findIndex(v => (v.elementType == type) && (v.equipmentUsage.id == id))
      }

      let removingEquipment = null

      if (i > -1) { 
        removingEquipment = Object.assign({}, equipment[i])
        equipment.splice(i, 1) 
      }



      app.$emit('changingEquipment', equipment, notify)
      this.$emit('changedEquipment', equipment, notify)

      if(removingEquipment) {
        app.$emit('deletingEquipment', removingEquipment, notify)
        this.$emit('deletingEquipment', removingEquipment, notify)
      }
    },

    removeEquipments(equipments, suggestions) {
      const equipment = this.getSelection.slice(0)

      const removingEquipments = [];

      equipments.forEach((v, k) => {
          let i = equipment.findIndex(e => e.equipmentUsage.id === v.equipmentUsage.id)

          if (i > -1) {
              removingEquipments.push(Object.assign({}, equipment[i]))
              equipment.splice(i, 1)
          }
      })

      this.$emit('changedEquipment', equipment, false)
      app.$emit('changingEquipment', equipment, false)

      app.$emit('deletingEquipment', removingEquipments, false)
      this.$emit('deletingEquipment', removingEquipments, false)
    },

    /**
     * Check if the passed equipment is already chosen in equipmentList.
     * @param object equipment
     * @return boolean
     */
    isChosen(equipment) {

      // An equipment that is going to be edited should be visible in the
      // dropdown, even though it is present in the equipment list.
      if (this.addForm.edit && (this.formData.equipmentId.value == equipment.id)) {
        return false
      }

      let i = this.equipmentData.rows.findIndex(v => v.id.value == equipment.id)
      return i > -1
    },

    /**
     * Check if a given equipment is available for a given date.
     * @return promise
     */
    checkAvailability() {
      const context = this
      return new Promise((resolve, reject) => {
        if (!context.formData.equipmentId.value || context.checking) {
          resolve()
          return
        }
        context.$loader.add('bookEquipmentSelectionChecking')
        let replacementWithinGroup = false
        if(context.addForm.edit) {
          let prevEquipmentId =  context.$route.name === 'equipment.usage.view' ? context.addForm.equipment.equipmentId : context.addForm.equipment.id
          if(context.formData.equipmentId.value != prevEquipmentId) {
            let prevEquipmentStart = context.$route.name === 'equipment.usage.view' ? context.addForm.equipment.periodStart : context.addForm.equipment.equipmentUsage.periodStart
            let prevEquipmentEnd = context.$route.name === 'equipment.usage.view' ? context.addForm.equipment.periodEnd : context.addForm.equipment.equipmentUsage.periodEnd
            if((!context.hideDates && context.formData.periodStart.value == prevEquipmentStart &&
                context.formData.periodEnd.value == prevEquipmentEnd) || context.hideDates) {
                  let prevEq = _.find(context.allEquipment, i => i.id === prevEquipmentId)
                  let newEq = _.find(context.allEquipment, i => i.id === context.formData.equipmentId.value)
                  if(prevEq !== undefined && newEq !== undefined) {
                    let prevGroup = prevEq.group
                    let newGroup = newEq.group
                    replacementWithinGroup = prevGroup === newGroup
                  }
            }
          }
        }
        
        context.equipmentUsage = []
        context.addForm.available = false
        const params = {
          start: context.formData.periodStart.value,
          end: context.formData.periodEnd.value,
          replacementWithinGroup: replacementWithinGroup
        }
        // When checking if an equipment is available, we look for any
        // usage entry and if the API returns anything (code 200), then
        // it means the equipment piece is already taken/busy.
        // If 404 is returned, nothing was found, which means the
        // equipment is available.
        axios.get(context.getApiUrl + '/' + context.formData.equipmentId.value, {params: params})
            .then(response => {

              // controller checks the conflict first, if theres not any, then checks exceeds
              if(response.data.hasOwnProperty('exceedsGroupLimit')) {
                context.excededFlag = true
                context.exceededGroup = response.data.groupName
                context.exceededGroupLimit = response.data.groupLimit
              }
              else {
                context.addForm.available = !response.data.some(v => v.equipmentUsage.id != context.formData.id.value)

                // Create the list of conflicts to present in the error message.
                if (!context.addForm.available)  {
                  Lazy(response.data)
                      .filter(v => {
                        return v.equipmentUsage.id != context.formData.id.value && !v.doNotCreateConflict //doNotCreateConflict is overkill but, without risk.
                      })
                      .each(v => {
                        context.checkedEquipmentConflict = true
                        context.equipmentUsage.push({
                          type: v.equipmentUsage.usageType,
                          id: v.equipmentUsage.usageItem ? v.equipmentUsage.usageItem.id : '',
                          title: v.equipmentUsage.usageItem.title,
                          equipmentLabel: v.label,
                          equipmentId: v.id,
                          equipmentUsageId: v.equipmentUsage.id,
                          parentId: v.equipmentUsage && v.equipmentUsage.parentId ? v.equipmentUsage.parentId : null,
                        })
                      })
                }


              }

              context.$loader.remove('bookEquipmentSelectionChecking')
              resolve(response)
            })
            .catch(error => {
              if (error.response && error.response.status != 404) context.$error.set(error)
              context.addForm.available = true
              context.excededFlag = false
              context.exceededGroup = ''
              context.exceededGroupLimit = ''
              context.$loader.remove('bookEquipmentSelectionChecking')
              reject(error)
            })

      })
    },

    /**
     * Check if the passed item(equipment piece) is added to a template or not.
     * @param object item
     * @return boolean
     */
    isFromTemplate(item) {
      return item.hasOwnProperty('equipmentId')
    },

    /**
     * Get the "item usage id" that should be used as unique id in the table.
     * @param object item
     * @return integer
     */
    getItemUsageId(item) {
      if (this.isFromTemplate(item)) return item.id
      return Equipment.isEquipmentPiece(item) ? `equipmentPiece#${item.equipmentUsage.id}` : `equipmentType#${item.equipmentUsage.id}`
    },

    /**
     * Get the "item id" that should be presented in the table.
     * @param object item
     * @return integer
     */
    getItemVisibleId(item) {
      if (this.local) {
        return item.id
      }
      else {
        if (this.isFromTemplate(item)) {
          if (Equipment.isEquipmentPiece(item)) {
            return item.equipmentId
          }
          else {
            return item.equipmentType
          }
        }
        return Equipment.isEquipmentPiece(item) ? item.id : item.equipmentUsage.id
      }
    },

    /**
     * Get the status of the given item to be presented in the table.
     * @param object item
     * @return string
     */
    getItemStatus(item) {
      if (this.equipmentStatusEntity){
        const status = this.equipmentStatusEntity.getItem(item.equipmentStatus)
        return status ? status.label : null
      }
      return null
    },

    /**
     * Get the IsPrimary field value of the given item to be presented in the table
     * @param object item
     * @return bool
     */
    getIsPrimaryValue(item, isOneRecord){
      return item && item.equipmentUsage ? item.equipmentUsage.isPrimary : false;
    },

    getIsNotUpdateTimeValue(item){
      //console.log("is not update time");
      return item && item.equipmentUsage ? item.equipmentUsage.isNotUpdatePeriod : true;
    },

    /**
     * Parse an equipment piece/type to ensure they have all required fields.
     * @param object item
     */
    parseItem(item) {
      if (!item.hasOwnProperty('elementType')) {
        let type = Equipment.isEquipmentPiece(item) ? 'equipmentPiece' : 'equipmentType'
        Vue.set(item, 'elementType', type)
      }
    },

    /**
     * Get "RouterLink" object to build the link to a item usage.
     * @param object item
     * @return object
     */
    getConflictUsageItemLink(item) {
      return {name: `${item.type}.edit`, params: {id: item.id}}
    },

    /**
     * Check if a given column should be visible or not based on the current
     * settings.
     * @param string column
     * @return boolean
     */
    columnIsVisible(column) {
      if (column == "isPrimary") return false;
      if (column == "isNotUpdatePeriod" && this.usageType != "feed")
        return false;

      return this.columns.length == 0 || (this.columns.length > 0 && this.columns.indexOf(column) > -1)
    },

    iconIsVisible() {
      return this.getEquipmentItems.filter(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage && !v.equipmentUsage.id).length > 0
    },

    defaultData() {
      return {
        id: {
          value: 0
        },
        equipmentType: {
          local: false,
          value: ''
        },
        equipmentId: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
        periodStart: {
          value: '',
          validator: {
            rules: {
              required: true,
              date_format: 'YYYY-MM-DD HH:mm'
            }
          }
        },
        periodEnd: {
          value: '',
          validator: {
            rules: {
              required: true,
              date_format: 'YYYY-MM-DD HH:mm'
            }
          }
        },
        isPrimary: {
          value: false,
          validator: {
            rules: 'required'
          }
        },
        isNotUpdatePeriod: {
          value: false,
          validator: {
            rules: 'required'
          }
        },
        includeSuggested: {
          value: true,
        },
        addAnother: {
          value: false
        }
      }
    },

    /**
     * Routes the selected item.
     */
    routeEquipmentItem(selectedId, mcrRow) {
      // Cancel the timeout to ensure the single click handler does not run.
      clearTimeout(this.mcrTimer)
      this.mcrTimer = null

      let { id, type } = Equipment.getItemIdAndType(selectedId)

      // Find the equipment from ID and type.
      const equipment = this.getEquipmentItems
        .filter(v => {
          return v.elementType === type
        }).find(v => {
          if (v.equipmentUsage)
            return v.equipmentUsage.id === id
          else
            return false
        })

      // Gets the MCR status for this country.
      const mcrStatus = equipment.equipmentUsage.mcrStatus?.find(v => v.country === app.$data.user.get.country)

      const items = equipment.equipmentUsage.mcrStatus?.slice(0) ?? []

      // If the item is already routed, we unroute it.
      if (mcrStatus) {
        const mcrStatusCopy = { ...mcrStatus }
        let i = items.findIndex(v => v.id === mcrStatusCopy.id)
        if (i > -1) items.splice(i, 1)
        app.$emit('AutoChangeMcrStatus', {
          itemId: equipment.equipmentUsage.id,
          itemType: 'equipmentUsage',
          extraData: {
            itemId: mcrRow.id.rawValue,
            itemType: mcrRow.type.rawValue,
          },
          id: mcrStatusCopy.id,
          item: mcrStatusCopy,
          action: {
            module: 'McrStatus',
            type: 'delete',
          },
          items: items
        })
      // Otherwise we route it.
      } else {

        const item = {
          id: Math.floor(100000000000 + Math.random() * 900000000000),
          country: app.$data.user.get.country,
          status: 1,
        };

        items.push(item)

        app.$emit('AutoChangeMcrStatus', {
          itemId: equipment.equipmentUsage.id,
          itemType: 'equipmentUsage',
          extraData: {
            itemId: mcrRow.id.rawValue,
            itemType: mcrRow.type.rawValue,
          },
          item: item,
          action: {
            module: 'McrStatus',
            type: 'store',
          },
          items: items,
        })
      }
    },

    /**
     * Click handler specifically for MCR.
     *
     * Here we use a timeout to allow the user to double-click the equipment to instead route it.
     */
    mcrEditItem(id, elementType = null) {
      if (this.mcrTimer) {
        return
      }
      this.mcrTimer = setTimeout(() => {
        this.editItem(id, elementType)
        this.mcrTimer = null
      }, 250)
    }
  }
}

</script>

<template>
  <div class="book-equipment-selection">
    <button
        class="btn btn-primary btn-xs book-button-add-equipment hidden-print"
        style="margin-right:55px;"
        @click="showAll = !showAll"
        :disabled="disabled"
        v-if="isDisplayAddButton && !isForEquipmentUsagePage && !isForEquipmentOverview">
      <span v-if="!showAll"><font-awesome-icon icon="plus"/>&nbsp;Show All</span>
      <span v-else><font-awesome-icon icon="minus"/>&nbsp;Close Other</span>
    </button>
    <button
        class="btn btn-success btn-xs book-button-add-equipment"
        @click="showForm(true)"
        :disabled="disabled"
        v-if="!print && $ability.can('create', 'Equipment') && isDisplayAddButton && !isForEquipmentUsagePage && !isForEquipmentOverview">
      <font-awesome-icon icon="plus"/>&nbsp;Add
    </button>
    <book-loader :small="true" v-if="!loaded  && !isForEquipmentUsagePage"></book-loader>
    <book-table  v-if="!isForEquipmentUsagePage && !isForEquipmentOverview"
                 :component="'equipmentSelection'"
                 :darkMode="darkMode"
                 :items="equipmentData"
                 :print="print"
                 :notFoundMessage="!disabled || !disabledMessage ? 'No equipment has been added' : ''"
                 :itemsType="itemsType"
                 :needRender="showAll"
                 @edit="item => editItem(item.id.value)"
                 @delete="item => deleteItem(item.id.value)">
    </book-table>
    <div class="book-box-overlay" v-if="disabled && loaded && !isForEquipmentUsagePage">
      <span class="label label-danger" v-html="disabledMessage"></span>
    </div>
    <div class="book-box-overlay" v-if="processing && !isForEquipmentUsagePage">
      <book-loader :small="true"></book-loader>
    </div>
    <div class="book-box-overlay" v-if="(isForEquipmentUsagePage || isForEquipmentOverview) && !isForMCRView">
      <div class="book-sidebar-row">
        <button type="button" aria-label="Accept" class="book-action-button book-edit btn-primary btn-sm" v-on:click="editItem(selectedItem)">
          <font-awesome-icon icon="pen"/><span> Edit</span>
        </button>
        <button type="button" aria-label="Reject" class="book-action-button book-edit btn-danger btn-sm" v-on:click="deleteItem(selectedItem)">
          <font-awesome-icon icon="trash"/><span> Delete</span>
        </button>
      </div>
    </div>
    <div class="book-box-overlay" v-if="isForEquipmentUsagePage && isForMCRView">
      <div id="modaling" v-if="!isAddForMCRView && selection !== null && getSelection.length !== 0" class="label" @click="mcrEditItem(selectedItem,mcrRow.type.rawValue)" @dblclick="routeEquipmentItem(selectedItem, mcrRow)" :class="{
                            'label-grey': getRowMCRStatus(mcrRow) === 4,
                            'label-danger': directEquipment.equipmentUsage.conflict,
                            'label-warning': directEquipment.placeHolder,
                            'label-success': !directEquipment.placeHolder && !directEquipment.equipmentUsage.conflict &&
                            getRowMCRStatus(mcrRow) !== 4,
                            'contribution-equipment': mcrRow.type.rawValue === 'contribution' && !darkMode,
                            'contribution-equipment-dm': mcrRow.type.rawValue === 'contribution' && darkMode,
                            'mcr-equipment-routed': isRouted,
                          }" :title="label">{{ label.replace('Undefined', '').replace('Unassigned', '') }}
      </div>
      <button
          v-else-if="isAddForMCRView
          && nonVisible && nonVisible.length > 0
          && mcrRow.equipment !== null
          && !print && $ability.can('create', 'Equipment')"
          class="btn btn-primary btn-xs book-button-add-equipment" style="font-size: 9px !important;"
          :class="{'dark-second-input': darkMode}"
          @click="showForm(true)">
        <font-awesome-icon icon="plus"/> Add
      </button>
      <button
          v-if="mcrRow.equipment === null
          && isAddForMCRView
          && selection.length < 1
          && !print && $ability.can('create', 'Equipment')"
          class="btn btn-primary btn-xs book-button-add-equipment" style="font-size: 9px !important;"
          :class="{'dark-second-input': darkMode}"
          @click="showForm(true)">
        <font-awesome-icon icon="plus"/> Add
      </button>

    </div>
    <book-modal :class="{'dark-equipment-modal': darkMode}" maxWidth="400px" @close="showForm(false)" v-if="this.addForm.show">
      <h4 slot="header" class="modal-title">
        <template v-if="!addForm.edit">Add</template>
        <template v-if="addForm.edit">Edit</template>
      </h4>
      <div slot="body">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="name" class="control-label book-width-100">Type</label>
            <div class="form-control-100">
              <select class="form-control" name="equipment_type" v-model="formData.equipmentType.value" v-if="equipmentTypeEntity" :disabled="equipmentList.length < 1 || this.isForEquipmentUsagePage && !this.isAddForMCRView ">
                <option value="" v-if="equipmentTypesList.length > 1">None</option>
                <option v-for="type in equipmentTypesList" :value="type.id">{{type.label}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="name" class="control-label book-width-100">Equipment</label>
            <div class="form-control-100">
              <select id="selectList" class="form-control" name="equipment" v-model="formData.equipmentId.value" :disabled="equipmentIsDisabled">
                <option value="">None</option>
                <option v-for="equipment in equipmentList" :value="equipment.id" v-if="equipment.type == formData.equipmentType.value && !isChosen(equipment)">{{equipment.label}}</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="!hideDates && (formData.equipmentId.value != '')">
            <label class="control-label book-width-100">From</label>
            <book-datetime
                class="form-control-100"
                component-id="from"
                :required="{date: true, time: true}"
                :default-value="formData.periodStart.value"
                :limit="startDateLimit"
                @newValue="value => formData.periodStart.value = value">
            </book-datetime>
          </div>
          <div class="form-group" v-if="!hideDates && (formData.equipmentId.value != '')">
            <label class="control-label book-width-100">To</label>
            <book-datetime
                class="form-control-100"
                component-id="to"
                :required="{date: true, time: true}"
                :default-value="formData.periodEnd.value"
                :limit="endDateLimit"
                @newValue="value => formData.periodEnd.value = value">
            </book-datetime>
          </div>
          <div class="form-group" v-if="isForMCRView || (!isForMCRView && formData.equipmentId.value != '' && (!isEngOrEdit || !((formData.equipmentType.value == 65 && isEngProduction) || (formData.equipmentType.value == 64 && isEditProduction))))">
            <label class="control-label book-width-200">Add or Update Suggested</label>
            <input type="checkbox" v-model="formData.includeSuggested.value" :checked="formData.includeSuggested.value" />
          </div>
          <div class="form-group" v-if="false">
            <label class="control-label book-width-100">Is Primary</label>
            <input type="checkbox" v-model="formData.isPrimary.value" :checked="formData.isPrimary.value" :disabled="!isAllowedToSetPrimary || formData.isPrimary.value"/>
          </div>
          <div class="form-group" v-if="usageType=='feed'">
            <label class="control-label book-width-200">Don't Update Period</label>
            <input type="checkbox" v-model="formData.isNotUpdatePeriod.value" :checked="formData.isNotUpdatePeriod.value" />
          </div>
          <div class="book-help-message bg-danger text-danger" v-if="!hasEquipment && formData.equipmentType.value">
            No equipment piece available for this type
          </div>
          <div class="book-help-message bg-danger text-danger" v-else-if="equipmentList.length < 1">
            No equipment piece available for your country
          </div>
          <div class="book-help-message book-help-message-conflict bg-danger text-danger" v-if="isFormValid && isFormChanged && !checking && !addForm.available && formData.equipmentId.value && equipmentUsage.length > 0">
            <div v-if="getSelectedEquipmentInForm.kit">
                <div v-if="getEquipmentUsageForKit.length > 0">
                    <div>This equipment kit is already booked on following:</div>
                    <ul class="book-equipment-selection-equipmentusage-list">
                        <li v-for="item in getEquipmentUsageForKit">
                            <router-link target="_blank" :to="getConflictUsageItemLink(item)">{{item.type.capitalize()}} - {{item.title}} ({{item.id}})</router-link>
                        </li>
                    </ul>
                </div>
                <div v-if="getEquipmentUsageForEquipmentsInKit.length > 0">
                    <div>These equipment that belong to the equipment kit is already booked on following:</div>
                    <ul class="book-equipment-selection-equipmentusage-list">
                        <li v-for="item in getEquipmentUsageForEquipmentsInKit">
                            <router-link target="_blank" :to="getConflictUsageItemLink(item)">{{ item.equipmentLabel }}, {{item.type.capitalize()}} - {{item.title}} ({{item.id}})</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div>This equipment piece is booked on following:</div>
                <ul class="book-equipment-selection-equipmentusage-list">
                  <li v-for="item in equipmentUsage">
                    <router-link target="_blank" :to="getConflictUsageItemLink(item)">{{item.type.capitalize()}} - {{item.title}} ({{item.id}})</router-link>
                  </li>
                </ul>
            </div>
            <div>However you can double book it, but it will be flagged with "conflict".</div>
          </div>
          <div class="book-help-message book-help-message-conflict bg-danger text-danger" v-if="isFormValid && isFormChanged && !checking && !addForm.available && formData.equipmentId.value && excededFlag">
            <div>The equipment usage for the group "{{exceededGroup}}" exceeds the group limit {{exceededGroupLimit}}.</div>
            <div>However you can book it, but it will be flagged with "exceeded".</div>
          </div>

          <div class="form-group" v-if="!addForm.edit">
            <div class="form-control-100 checkbox">
              <label class="control-label checkbox-inline">
                <input type="checkbox" name="add_another" v-model="formData.addAnother.value">
                Add another
              </label>
            </div>
          </div>

        </div>
      </div>
      <div slot="footer">
        <book-loader :small="true" v-if="processing || checking"></book-loader>
        <button class="btn btn-success" @click="submitItem()" :disabled="checking || !isFormValid || !isFormChanged">Save</button>
      </div>
    </book-modal>
  </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-equipment-wrapper {
  .panel-body {
    position: relative;
  }
  .book-button-add-equipment {
    position: absolute;
    right: 0;
    top: -27px;
    z-index: 10;
  }
  .panel-heading ~ .book-equipment-selection {
    .book-button-add-equipment {
      right: 3px;
      top: -25px;
    }
  }
}
.book-add-equipment-wrapper {
  border-bottom: 1px solid #DDDDDD;
  text-align: right;
  > *:not(.help-block) {
    @include breakpoint(min-width $min-tablet) {
      display: inline-block;
    }
  }
  .form-group {
    margin: 10px;
    > * {
      display: inline-block;
    }
    label {
      margin-right: 5px;
      width: calc(30% - 10px);
    }
    .form-control {
      width: 70%;
    }
    label,
    .form-control {
      @include breakpoint(min-width $min-tablet) {
        width: auto;
      }
    }
  }
}
.book-equipment-selection {
  min-height: 40px;
  position: relative;
  .modal-footer {
    .book-loader {
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .book-table {
    border: none;
    margin-bottom: 0;
  }
  .table {
    margin-bottom: 0;
  }
  > .book-loader {
    padding: 9px 0;
  }
  .book-help-message-conflict {
    line-height: 1.4em;
    text-align: left;
  }

  .book-overview-item-title > span  {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.panel-body > .book-equipment-selection {
  margin: -15px;
}
.book-compact-panels .panel-body > .book-equipment-selection {
  margin: -8px;
}
.swal2-container {
  z-index: 10000 !important;
}

.mcr-equipment-routed {
  background-color: #5bc0de;
}
</style>