<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'
import User from 'components/user/User'
import BookLoader from 'components/common/loader/BookLoader'
import BookUpload from 'components/common/BookUpload'
import BookProductionWorkgroups from 'components/production/ProductionWorkgroups/BookProductionWorkgroups'
import BookAssociations from 'components/association/BookAssociations'
import BookDatetime from 'components/common/BookDatetime'
import BookSignoff from 'components/common/signoff/BookSignoff'
import BookModal from 'components/common/BookModal'
import BookLock from  'components/common/lock/BookLock'
import BookTable from 'components/common/BookTable'
import BookProductionPlan from 'components/production/ProductionPlan/BookProductionPlan'
import BookProductionCrew from 'components/production/ProductionCrew/BookProductionCrew'
import BookSelectbox from 'components/common/BookSelectbox'
import Production from 'components/production/Production'
import Datetime from 'components/common/helpers/Datetime'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'

import Item from 'components/common/item/Item'
import Signoff from 'components/common/signoff/Signoff'

import TaskEditBaseMixin from 'components/common/item/task/TaskEditBaseMixin'
import TaskEditMixin from 'components/common/item/task/TaskEditMixin'
import BookAudioLayout from 'components/common/BookAudioLayout'
import BookLocalNotes from 'components/common/BookLocalNotes'

// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'
//import BookProductionWorkgroups from '../ProductionWorkgroups/BookProductionWorkgroups.vue'


export default {
    mixins: [
        TaskEditBaseMixin,
        TaskEditMixin,
    ],
    components: {
        BookLoader,
        BookUpload,
        BookProductionWorkgroups,
        BookAssociations,
        BookDatetime,
        BookSignoff,
        BookModal,
        BookLock,
        BookTable,
        BookProductionPlan,
        BookProductionCrew,
        BookSelectbox,
        multiselect,
        datepicker,
        maskedInput,
        BookAudioLayout,
        BookLocalNotes,
        BookEquipmentSelection
    },
     data() {
        return {
               child: {
                item: 'production',
                items: 'productions',
                settings: 'productions',
                entities: {
                    planTypeEntity: 'plantype_entity_id',
                    skillEntity: 'skill_entity_id',
                    channelEntity: 'channel_entity_id',
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    workgroupsEntity: 'workgroups_entity_id',
                    activityTypesEntity: 'activitytypes_entity_id',
                    bookingTypesEntity: 'bookingtypes_entity_id'
                }
            },
          activeTab: 'overview',
          production: null,
          renderKey: 0
        }
    },
    mounted() {
        const context = this

        this.loadForm()
            .then(response => {
                    User.getUsers()
                        .then(response => {
                            context.setLoaded(true)
                        })
                        .catch(err => {
                            context.$error.set(err, `It was not possible to get user list`)
                            context.loaded = true
                        })
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the entities.')
                context.loaded = true
            })
           
    },
    watch: {
        'formData.productionStart.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(newValue, this.formData.productionEnd.value, format)) {
                this.formData.productionEnd.value = this.formData.productionStart.value
            }
            
            Vue.nextTick(() => {
                this.$validator.validate('productionStart', newValue)
            })
        },
        'formData.productionEnd.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(this.formData.productionStart.value, newValue, format)) {
                Vue.nextTick(() => {
                    this.formData.productionEnd.value = this.formData.productionStart.value
                })
            }

            Vue.nextTick(() => {
                this.$validator.validate('productionEnd', this.formData.productionEnd.value)
            })
        }
    },
    computed: {
         getPageTitle() {
            if(this.isEditForm) {
                if (this.isClone) {
                        //if its feed clone to create production? WN
                        const production = this.$route.query.feed == 1 ? this.cloneFeed : new Production(parseInt(this.$route.query.clone))
                        return `Copying ${production.get.title}`
                    }
                    else {
                        return this.production.get.title
                    }
            }
            return 'Add task'
        },
        signoffItems() {
            return Lazy(this.formData.signoffs.value)
                .map(v => {
                    return Object.assign({
                        disabled: !this.readyForSignOff(v)
                    }, v)
                }).toArray()
        },
        // isEditProduction() {
        //     return false
        // },
        // isEngProduction() {
        //     return false
        // },
        // isTaskProduction() {
        //     return true
        // },
        isEditable() {
            return this.$ability.can('update', 'Productions')
        },
        productions() {
            return this.$store.state.data.production ? this.$store.state.data.production : null
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        processing() {
            return this.$loader.has('productionEdit_submit')
        },
        productionStartDateLimit() {
            let now = moment(this.now)
            return [{
                type: 'fromto',
                from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
            }]
        },
        productionEndDateLimit() {
            let from = moment(this.formData.productionStart.value, 'YYYY-MM-DD HH:mm')
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },
        activityTypes() {
            if(!this.activityTypesEntity || !this.activityTypesEntity.get || !this.activityTypesEntity.get.items || this.activityTypesEntity.get.items.length === 0) return null

            const context = this
            let filteredActivityTypes = null

            if(context.formData.country.value) {
                if(context.formData.country.value === 'all') {
                    filteredActivityTypes = context.activityTypesEntity.get.items.filter(f => f.country === '' || f.country === null)
                }
                else {
                    filteredActivityTypes = context.activityTypesEntity.get.items.filter(f => f.country === context.formData.country.value)
                }
            }

            return filteredActivityTypes
        },
        isChanged() {
            const context = this
            let changed = false
            if ((!this.production || !this.production.get) && !this.isClone) return changed

            Lazy(context.formData)
                .filter((v, k) => {
                    switch (k) {

                        // The following fields shouldn't be checked either because
                        // they aren't stored inside the production in the DB or because
                        // the value is stored "in realtime", when it's changed,
                        // ex "documents" whose value is stored directly when
                        // a file is uploaded.
                        case 'documents':
                        case 'signoffs':
                        case 'plan':
                        case 'crew':
                        case 'country':
                        case 'actions':
                        case 'associations':
                        case 'ingestions':
                        case 'localNotes':
                        case 'workgroups':
                        case 'equipment':
                            return false
                            break

                    }
                    if (!context.isEditForm) return !v.local && !v.onlyEdit
                    return !v.local
                })
                .each(function(v, k) {
                    if (changed) return true

                    // The form value is an array and its length is not equal to
                    // the length of the original value, then it has been changed.f
                    if (Array.isArray(v.value) && Array.isArray(context.production.get[k]) && (v.value.length != context.production.get[k].length)) {
                        changed = true
                    }
                    else {
                        switch (k) {
                            case 'productionLeader':
                                const productionLeader = context.production.get.productionLeader ? context.production.get.productionLeader.id : ''
                                if (!changed && (productionLeader != v.value)) {
                                    changed = true
                                }
                                break

                            case 'runType':
                                if (!context.isEditForm) {
                                    if (context.formFields[k] && context.formFields[k].touched) {
                                        changed = v.value != context.production.get[k]
                                    }
                                }
                                else {
                                    changed = v.value != context.production.get[k]
                                }
                                break
                            case 'houseNumber':
                                //console.log(context.production.get.houseNumber, 'test 2 prod', context.formData.houseNumber.value, v.value)
                                let hNumber = context.production.get.houseNumber
                                if (hNumber) {
                                    //json string
                                    if (isJsonSring(hNumber)) {
                                        hNumber = JSON.parse(hNumber)
                                        Lazy(context.formData.houseNumber.value)
                                            .each(function (v2, k2) {
                                                if (hNumber[k2] !== v2) changed = true

                                            })
                                    }
                                    // } else {
                                    //     let _channel = context.production.get.channel
                                    //     if (_channel) {
                                    //         context.channelEntity.get.items.forEach(v2 => {
                                    //             if (_channel === v2.id) {
                                    //                 Lazy(context.formData.houseNumber.value)
                                    //                     .each(function (v3, k3) {
                                    //                         if (k3 === v2.country && v3 === hNumber) changed = false
                                    //                         else changed = true
                                    //                     })
                                    //             }
                                    //         })
                                    //     }
                                    // }
                                }
                                else {
                                    changed = false
                                    Lazy(v.value)
                                        .each(function (v2, k2) {
                                            if (v.value[k2] !== '') changed = true

                                        })
                                }
                                break
                            case 'localNotesChanged':
                                changed = context.formData.localNotesChanged.value
                                break
                            default:

                                // If the form value and feed value are "false/null/empty"
                                // then "changed" should be false.
                                if (context.production.get && !context.production.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = v.value != context.production.get[k]
                                }

                                break
                        }

                    }

                })

            return changed
        },
        crewIsDisabled() {
            const planItems = this[this.child.item].get.plan
            return !planItems || planItems.findIndex(v => v.relativeTo == 'start') == -1 || planItems.findIndex(v => v.relativeTo == 'end') == -1
        },
        getAssociationsDisabledMessage() {
            if (this.formData.locked.value || !this.isEditable) {
                return ''
            }
            return 'Associations box is disabled'
        },
        getCrewDisabledMessage() {
            if (this.formData.locked.value || !this.isEditable) {
                return ''
            }
            else if (this.isClone || this.isTemplateBased) {
                return 'Crew is disabled until you save this production'
            }
            else {
                return 'You need to add at least <strong>one plan item</strong> relative to start<br />and <strong>one plan item</strong> relative to end.'
            }
        },
        getUploadDisabledMessage() {
            return !this.$ability.can('upload', 'Files') ? 'You don\'t have permission to upload files.' : 'Document upload is disabled'
        },
        getEquipmentDisabledMessage() {
            if (this.isTemplateBased) {
                return 'Equipment is disabled until you save this production'
            }
            else if (!this.isEditable) {
                return ''
            }
            else {
                return 'Equipment selection is disabled'
            }
        },
    },
    methods: {
        renderPlease() {
            this.renderKey += 1
        },
        houseNumberValues() {
          const context = this
          return context.formData.houseNumber.values
        },
        getFormattedProductionDate(field) {
            // let value = this.production.get[field].split(' ')
            let value = this.formData[field].value.split(' ')
            return {
                date: value[0],
                time: value[1]
            }
        },
        getTabRoutes() {
            return this.$route.query.newBar;
        },
        isActiveTab(name) {
            return this.activeTab == name
        },
        /**
         * Lock the current form based on events from BookLock.
         * @param integer id The item id sent by BookLock.
         * @return boolean
         */
        lock(id) {
            if (id == this.id) this.formData.locked.value = true
            else this.formData.locked.value = false
        },
        /**
         * Check if the passed signoff item is ready to be signed off.
         * @param object item The signoff item.
         */
        readyForSignOff(item) {

            if (!this.isFormValid) {
                return false
            }
            if (item.signed) {
                return true
            }

            // Here we check if the current signoff item can be signed off based
            // on its label. We maybe should solve this another way, centralizing
            // the list of signoff types a production can have, avoiding look
            // at the label in combination with a rules set.
            let label = item.label.replace(' ', '').toLowerCase()
            let ready = false

            switch (label) {
                case 'productionready':
                    //According to new requirements, sign offs shouldn't depend on crew list
                    if (this.formData.crew.value.length > 0) {
                        let accepted = 0
                        ready = this.formData.crew.value.every(v => {
                           if (v.status == 'accepted') {
                                accepted++
                            }
                            return ['accepted', 'not_available'].indexOf(v.status) > -1
                        })

                    // Check that at least one of the and still say ok.
                        if (accepted == 0) {
                            ready = true
                        }

                    }
                    // if theres no crew member, button can be editable.
                    else { ready = true; }
                    break
                // case 'techready':
                //     if (this.formData.equipment.value.length > 0) {
                //         ready = this.formData.equipment.value.every(v => {
                //             return v.equipmentStatus === this.equipmentSetting.ok_entityitem_id
                //         })
                //     }
                //     break
            }
            return ready
        },
        submit(open = false, redirect = true) {
            const context = this
            context.$loader.add('productionEdit_submit')
            const datesBeforeChange = {
                start: this.production.get.productionStart,
                end: this.production.get.productionEnd
            }

            Lazy(context.formData)
                .filter((v, k) => !v.local)
                .each(function(v, k) {
                    let value;
                    switch (k) {
                        case 'houseNumber':
                            value = {}
                            Lazy(v.value)
                                .each((item, k) => {
                                    // if its undefined, or empty, just make it empty instead of null. WN
                                    value[k] = item ? item : ''
                                })
                            break
                        default:
                            value = v.value
                            break
                    }
                    context.production.set(k, value)
                })
            // we need to json stringify
            if (context.production.get.houseNumber) {
                context.production.get.houseNumber = JSON.stringify(context.production.get.houseNumber)
            }

            if (context.isEditForm && !context.isClone) {

                const datesChanged = (datesBeforeChange.start !== context.production.get.productionStart) || (datesBeforeChange.end !== context.production.get.productionEnd)

                // Tell the API to update the date on the equipment pieces.
                //VR: why it was commented bebore?
                if (datesChanged && this.production.get.equipment.length > 0) {
                    context.production.set('updateEquipmentDate', 1)
                }

                context.production.save()
                    .then(response => {
                        context.$alert.set(`Task ${context.id} was saved!`, 'success', 3)
                        context.updateHouseNumbers()
                        Vue.set(context.$data, 'formData', context.defaultData())

                        // check if its more than 24 hours long
                        let fStart = context.production.get.productionStart
                        fStart = new Date(fStart);
                        let fEnd = context.production.get.productionEnd
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        // We should check first if its over than 24 hours
                        // then we should ask for changing, before asking send crew
                        // about time changing.

                        if (differenceTime >= 24 && datesChanged) {
                                swal({
                                    title: '24 Hours Long',
                                    text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function () {
                                        // if its in new tab already dont push anywhere WN
                                        if (context.getTabRoutes() && !open) { return }
                                        context.$router.push({path: `/task/${context.id}`})
                                    }, function (dismiss) {

                                        if (datesChanged && (context.production.get.crew.length > 0)) {
                                            swal({
                                                title: 'Changed dates',
                                                text: `Dates in this production have been changed. Do you want to notify the crew members about the date/time changes? `,
                                                type: 'question',
                                                confirmButtonText: 'Yes',
                                                cancelButtonText: 'No',
                                                showCancelButton: true,
                                                showCloseButton: true
                                            })
                                                .then(function () {
                                                    app.$emit('notifyCrew_changedDates')

                                                }, function (dismiss) {
                                                    // Close the tab if its clicked to "save & close the tab"
                                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                    if (redirect) context.redirect(open)
                                                })
                                                .catch(() => {
                                                    // Close the tab if its clicked to "save & close the tab"
                                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                    if (redirect) context.redirect(open)
                                                })
                                        } else {
                                            if (redirect) {
                                                context.submitted = true
                                                // Close the tab if its clicked to "save & close the tab"
                                                if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                context.redirect(open)
                                            }
                                        }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                            
                        }
                        else {
                            if (datesChanged && (context.production.get.crew.length > 0) ) {
                                swal({
                                    title: 'Changed dates',
                                    text: `Dates in this production have been changed. Do you want to notify the crew members about the date/time changes? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function() {
                                        app.$emit('notifyCrew_changedDates')
                                    }, function(dismiss) {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                            }
                            else {
                                if (redirect) {
                                    context.submitted = true
                                    // Close the tab if its clicked to "save & close the tab"
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    context.redirect(open)
                                    }
                                }

                        }
                        // Close the tab if its clicked to "save & close the tab"
                        // Idea is using the same identifiers as save and close for redirect and open options,
                        // which makes smart use in tabs as welll
                        // WN
                        if (context.getTabRoutes() && !open && !datesChanged) { return context.closeNewTab(); }

                        context.setLoaded()
                        context.$loader.remove('productionEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.$loader.remove('productionEdit_submit')
                    })

            }
            else {
                console.log("submit copy");
                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('productionEdit_submit')
                }
                
                //if there's a feed clone, do not set it as a production clone
                if (context.isClone && context.$route.query.clone && !context.$route.query.feed){
                    context.production.set('clone', context.$route.query.clone)
                }

                let params = []
                // if (context.isEditProduction || context.isEngProduction) {
                //     params.push({saveEquipment: 1})
                // }

                if (context.formData.associations){
                    context.formData.associations = [];
                }

                context.production.store(params)
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`Task ${response.data.id} has been created!`, 'success', 3)


                        // check if its more than 24 hours long
                        let fStart = this.formData.productionStart.value
                        fStart = new Date(fStart);
                        let fEnd = this.formData.productionEnd.value
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        if (differenceTime >= 24) {
                            //if (context.isEngProduction == false && context.isEditProduction == false)  {
                                swal({
                                    title: '24 Hours Long',
                                    text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function () {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        context.$router.push({path: `/task/${response.data.id}`})
                                    }, function (dismiss) {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        if (redirect) context.redirect(open)
                                    })
                            //}
                        }


                        // close the tab if its clicked to "save & close the tab"
                        //if (!open && getTabRoutes()) { return closeNewTab(); }

                        context.production = new Production(response.data.id)

                        if (redirect) {
                            context.redirect(open, response.data.id)
                                .then(response => {
                                    if (open) {
                                        context.setLoaded()
                                    }
                                })
                                .catch(error => {
                                    if (open) {
                                        context.setLoaded()
                                    }
                                })
                        }
                        
                        // Reset formData if "Save & Close" button has been clicked.
                        context.updateHouseNumbers()
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())

                        // Close the tab if its clicked to "save & close the tab"
                        // Idea is using the same identifiers as save and close for redirect and open options,
                        // which makes smart use in tabs as welll
                        // WN
                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                        context.$loader.remove('productionEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.$loader.remove('productionEdit_submit')
                        context.setLoaded()
                    })
            }
        },
        setFormValues() {
            const context = this
            const userCountry = app.$data.user.get.country

            if (context.isEditForm) {

                // Determine which feed instance to use, in order to populate
                // the fields.
                //--
                // We need to check if its feed or production that needs to be cloned WN
                let production = context.isClone ? new Production(parseInt(context.$route.query.clone)) : context.production

                let insertData = this.$route.query.insertData && parseInt(this.$route.query.insertData) === 1
                //console.log("log values");
                Lazy(context.formData)
                    .filter(v => !v.local)
                    .each(function(v, k) {

                        // If insertData from URL is false, do not set values
                        // for "plan" and "crew".
                        if (context.isClone && !insertData && (['plan', 'crew', 'documents', 'locations'].indexOf(k) > -1)) {
                            return
                        }
                        // HARDFIX for refreshing the page, missing items on cloning from feed. WN
                        //if (!production.get.league && context.$route.query.feed == 1) { production = context.cloneFeed }

                        switch (k) {
                            // if feed is cloning for production as a Template need to apply those (title, sport, league, start, end)
                            // and rest should be covered from the template
                            case 'title':
                                // if (context.isTemplateBased && context.isFeedTemplateBased) v.value = context.cloneFeed.get.title
                                // else 
                                v.value = production.get[k]
                                break
                            // case 'sport':
                            //     const league = (context.isTemplateBased && context.isFeedTemplateBased ? context.cloneFeed.get.league : production.get.league)
                            //     if (league) {
                            //         const entityItem = context.leagueEntity.getItem(league)
                            //         v.value = entityItem.reference
                            //     }
                            //     break
                            // case 'league':
                            //     if (context.isTemplateBased && context.isFeedTemplateBased) v.value = context.cloneFeed.get.league
                            //     else v.value = production.get[k]
                            //     break
                            case 'productionLeader':
                                if (production.get.productionLeader) {
                                    v.value = production.get.productionLeader.id
                                }
                                break
                            // if feed is cloning for production, need to get this data to fromData WN
                            case 'productionStart':
                                // if (production.get.feedStart || context.isTemplateBased && context.isFeedTemplateBased)
                                //     v.value = production.get.feedStart ? production.get.feedStart : context.cloneFeed.get.feedStart
                                // else 
                                v.value = production.get[k]
                                break
                            case 'productionEnd':
                                // if (production.get.feedEnd || context.isTemplateBased && context.isFeedTemplateBased)
                                //     v.value = production.get.feedEnd ? production.get.feedEnd : context.cloneFeed.get.feedEnd
                                // else 
                                v.value = production.get[k]
                                break
                            case 'houseNumber':
                                // if (context.isTemplateBased && context.isFeedTemplateBased) {
                                //     // we dont need to copy house number for cloning
                                //     //v.value = context.cloneFeed.get.houseNumber
                                // }
                                // else {
                                    // we dont need to copy house number for cloning
                                    if (!context.isClone) {
                                        let hNumber = context.production.get.houseNumber
                                        if (hNumber) {
                                            if (isJsonSring(hNumber)) {
                                                hNumber = JSON.parse(hNumber)
                                                Lazy(v.value)
                                                    .each(function (v2, k2) {
                                                        v.value[k2] = hNumber[k2]
                                                    })
                                            } 
                                            // else {
                                            //     let _channel = context.formData.channel.value ? context.formData.channel.value : production.get.channel
                                            //     if (_channel) {
                                            //         context.channelEntity.get.items.forEach(v2 => {
                                            //             if (_channel === v2.id) v.value[v2.country] = hNumber
                                            //         })
                                            //     }
                                            // }
                                        }
                                    }
                                // }
                                break
                            // if channel value is filled, then fill the country value as the channel's country WN
                            case 'country':
                                let _channel = context.formData.runType.value ? _.find(context.activityTypesEntity.get.items, a => a.id === context.formData.runType.value) : null
                                if (_channel) {
                                    if(_channel.country) {
                                        v.value = _channel.country
                                    }
                                    else {
                                        v.value = 'all'
                                    }
                                }
                                break
                            default:

                                // Don't set value for runType if we are on a
                                // Edit/ENG Production.
                                


                                    // We need to detect if the value is array/object or
                                    // another type, so we can make a real copy of the
                                    // value and not create a pointer.
                                    //Equipment -> equipmentTypeUsage                                if (typeof production.get[k] == 'object') {
                                    if (typeof production.get[k] == 'object') {
                                    
                                        if (Array.isArray(production.get[k])) {
                                            v.value = production.get[k].slice()
                                        }
                                        else if (production.get[k]) {
                                            v.value = {}
                                            Object.assign(v.value, context.production.get[k])
                                        }
                                    }
                                    else if (production.get[k]) {
                                        v.value = production.get[k]
                                    }
                                                               

                                break
                        }
                    })
                    //Force to submit form if this is Clone and Edit or Eng
                    // if (context.isClone && (context.isEditProduction || context.isEngProduction)){
                    //     this.submit(true, true);
                    // }
            }
            // else {
            //     switch (true) {
            //         default:

            //             let defaultValue = null
            //             const entities = ['runType']

            //             // Try to set default values for entity field.
            //             entities.forEach(v => {
            //                 defaultValue = context[`${v}Entity`].get.items.filter(function(item) {
            //                     return item.default
            //                 })
            //                 if (defaultValue.length > 0) {
            //                     context.formData[v].value = defaultValue[0].id
            //                 }
            //             })

            //             // Don't set value for runType if we are on a
            //             // Edit/ENG Production.
            //             // if (context.isEditProduction || context.isEngProduction) {
            //             //     switch (true) {
            //             //         case (context.isEditProduction):
            //             //             context.formData.runType.value = context.setting.edit_entityitem_id
            //             //             break
            //             //         case (context.isEngProduction):
            //             //             context.formData.runType.value = context.setting.eng_entityitem_id
            //             //             break
            //             //     }
            //             //     context.formData.runType.locked = true
            //             //     return
            //             // }

            //             break
            //     }
            // }
        },
        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                productionType: {
                    value: 'task'
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionStart: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionEnd: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionLeader: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                houseNumber: {
                    value: this.defaultHouseNumberKeys,
                    // value: {
                    //     se: '',
                    //     no: '',
                    //     dk: '',
                    //     fi: '',
                    //     uk: ''
                    // },
                    values: this.defaultHouseNumbers//['se', 'no', 'dk', 'fi', 'uk'],
                },
                runType: {
                    value: '',
                    locked: false,
                    validator: {
                        rules: 'required'
                    }
                },
                country: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                plan: {
                    // local: true,
                    value: []
                },
                crew: {
                    // local: true,
                    value: []
                },
                workgroups: {
                    // local: true,
                    value: []
                },
                audioLayout: {
                    value: '',
                    onlyEdit: true
                },
                notes: {
                    value: ''
                },
                localNotes: {
                    value: [],
                    onlyEdit: true
                },
                localNotesChanged: {
                    value: false
                },
                contacts: {
                    value: ''
                },
                signoffs: {
                    value: [],
                    onlyEdit: true
                },
                documents: {
                    value: [],
                    progress: 0,
                    onlyEdit: true
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
                ingestions: {
                    value: [],
                    onlyEdit: true
                },
                actions: {
                    value: ['clone', 'delete']
                },
                equipment: {
                    data: true,
                    value: [],
                },
            }
        },
        /**
         * Emit a action button event.
         * @param eventName string
         * @param object row
         */
        emitButtonEvent(eventName) {
            const context = this
            switch (eventName) {
                case 'clone':
                    context.cloneProduction(context.production.options.id)
                    break
                case 'delete':
                    context.deleteItem(context.production.options.id)
                    break
            }

            return
        },
        buttonIsPresent(button, byPass = false) {
            //return this.tableData.options.actions.indexOf(button) > -1
        },
        getButtonClasses(button) {
            let classes = []
            switch (button) {
                case 'clone':
                    classes.push('btn-info')
                    break
                case 'delete':
                    classes.push('btn-danger')
                    break
            }
            return classes
        },
        getButtonTitle(button, byPass = false) {

        },
        getButtonLabel(button) {
                switch (button) {
                    case 'clone':
                        return ''
                    case 'delete':
                        return ''
                }
        },
        getButtonIcon(button){
            switch (button) {
                case 'clone':
                    return 'copy'
                case 'delete':
                    return 'trash'
            }
        },
        /**
         * Check if the given button should be visible or not for the given row.
         * @param string button The button name, ex "delete".
         * @param object row
         * @return boolean
         */
        userHasAccess(button) {
            //create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete', 'Productions')
                    break
                case 'clone':
                    _access = this.$ability.can('create', 'Productions')
                    break
            }
            return _access
        }
    }

}
</script>
<style src="../ProductionEdit/ProductionEdit.scss" lang="scss"></style>

<template>
<div id="production-edit" class="admin book-compact-panels book-compact-forms book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title" :title="getPageTitle" v-html="getPageTitle"></h1>
        <div class="secondary-menu">
            <book-lock
                v-if="loaded && isEditForm && !isClone"
                :item="production.get.lock"
                @isLocked="id => lock(id)">
            </book-lock>
        </div>
        <div class="secondary-menu btn-group">
            <router-link :to="getDestination()" class="btn btn-default" title="Back to tasks" v-if="!getTabRoutes()"><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></router-link>
            <div class="btn btn-default" title="Back to tasks" @click="closeNewTab()" v-else><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></div>
            <!-- <button class="btn btn-default" title="Print this production"><span class="fa fa-print"></span><span class="item-label">&nbsp;&nbsp;Print</span></button>
            <button @click="openInsertForm()" class="btn btn-default" title="Copy data from other production" :disabled="formData.locked.value"><span class="far fa-copy"></span><span class="item-label">&nbsp;&nbsp;Insert data</span></button> -->
        </div>
    </div>

    <transition name="fade">
        <book-loader :overlay="true" v-if="processing"></book-loader>
    </transition>

    <book-loader v-if="!loaded"></book-loader>

    <div v-else class="form-horizontal row">

        <!-- Column 1 -->
        <div :class="[{'col-sm-6 col-md-3': isActiveTab('overview') && isEditForm && !isClone}, {'col-sm-12 col-md-12': !isActiveTab('overview') && isEditForm && !isClone}, {'book-creation-form': !isEditForm  || isClone}]">

            <ul class="nav nav-tabs book-form-navigation">
                <li role="presentation" :class="{'active': isActiveTab('overview')}"><a @click="activeTab = 'overview'">Overview</a></li>
                <!-- <li role="presentation" :class="{'active': isActiveTab('equipment')}" v-if="isEditForm || isTemplateBased"><a @click="activeTab = 'equipment'">Equipment</a></li> -->
                <!-- <li role="presentation" :class="{'active': isActiveTab('feedback')}" v-if="isEditForm"><a @click="activeTab = 'feedback'">Feedback</a></li> -->
            </ul>

            <!-- Overview tab -->
            <div class="panel panel-info" v-show="isActiveTab('overview')">
                <div class="panel-body">
                    <div class="book-action-buttons">
                        <button
                                v-for="button in formData.actions.value"
                                v-if="isEditForm && !isClone && userHasAccess(button)"
                                @click="emitButtonEvent(button)"
                                class="btn btn-xs"
                                :class="getButtonClasses(button)"
                                :title="getButtonTitle(button)">
                            <font-awesome-icon :icon="getButtonIcon(button)"/>
                        </button>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('title')}">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Title
                        </label>
                        <div class="form-control-100">
                            <input v-validate="formData.title.validator" v-model:trim="formData.title.value" name="title" type="text" class="form-control" :disabled="formData.locked.value || !isEditable" tabindex="3"/>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-start"
                                :required="{date: true, time: false}"
                                :default-value="formData.productionStart.value ? formData.productionStart.value : ''"
                                @newValue="value => formData.productionStart.value = value"
                                :small="true"
                                :limit="productionStartDateLimit"
                                :disabled="formData.locked.value || !isEditable"
                                :dateTabindex="4"
                                :btnOpenTabindex="5"
                                :timeTabindex="6">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            End
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-end"
                                :required="{date: true, time: false}"
                                :default-value="formData.productionEnd.value"
                                @newValue="value => formData.productionEnd.value = value"
                                :small="true"
                                :limit="productionEndDateLimit"
                                :disabled="formData.locked.value || !isEditable"
                                :dateTabindex="7"
                                :btnOpenTabindex="8"
                                :timeTabindex="9">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('productionLeader')}">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Task assigned by
                        </label>
                        <div class="form-control-100">
                            <book-selectbox
                                componentId="production-leader"
                                :items="getUsersList"
                                :selected="formData.productionLeader.value"
                                @selectedItem="id => formData.productionLeader.value = id"
                                :disabled="formData.locked.value || !isEditable"
                                :tabindex="10">
                            </book-selectbox>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100"> House Number </label>
                    </div>
                    <div class="form-group form-group-sm" v-for="(hnum, index) in houseNumberValues()">
                        <label for="name" class="control-label control-label-100">
                            {{hnum.toUpperCase()}}
                        </label>
                        <div class="form-control-100">
                            <input :id="'housenumber_' + hnum" v-model:trim="formData.houseNumber.value[hnum]" :key="index + '_' + hnum" name="house_number" type="text" class="form-control" :disabled="formData.locked.value || !isEditable" />
                        </div>
                    </div>
                    <!-- Country -->
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('country')}" >
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Country
                        </label>
                        <div class="form-control-100">

                            <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" v-if="countries" :disabled="formData.locked.value || !isEditable" tabindex="12">
                                <option value="">
                                    None
                                </option>
                                <option value="all">
                                    Global (all)
                                </option>
                                <option v-for="country in countries" :value="country.code">
                                    {{country.label}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('runType')}">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Activity type
                        </label>
                        <div class="form-control-100">
                            
                            <select class="form-control" name="runType" v-validate="formData.runType.validator" v-model="formData.runType.value" v-if="activityTypesEntity" :disabled="formData.runType.locked || formData.locked.value || !isEditable" tabindex="13">
                                <option value="">None</option>
                                <option v-for="activityType in activityTypes" :value="activityType.id" v-if="activityTypes" >{{activityType.label}}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Associations -->
            <div class="panel panel-info book-association-wrapper" v-if="isEditForm && !isClone" v-show="isActiveTab('overview')">
                <div class="panel-heading">
                    Associations
                </div>

                <book-associations
                    :items="formData.associations.value"
                    :parent="production"
                    :key="renderKey"
                    type="production"
                    :countries="countries"
                    :disabled="isClone || formData.locked.value || !isEditable"
                    :disabled-message="getAssociationsDisabledMessage"
                    :channels="channelEntity.get.items"
                    :activityTypes="activityTypesEntity.get.items"
                    @changedAssociations="items => formData.associations.value = items.slice(0)">
                </book-associations>
            </div>

            

            <!-- Documents -->
                
                    <div class="panel panel-info" v-if="isEditForm">
                        <div class="panel-heading">
                            Documents
                        </div>
                        <div class="panel-body">
                            <book-upload
                                ref="documentsUpload"
                                @success="response => uploadSuccess(response)"
                                @removedFile="file => uploadRemovedFile(file)"
                                @downloadedFile="file => downloadFile(file)"
                                @queueComplete="uploadQueueComplete()"
                                @error="(file, message, xhr) => uploadError(file, message, xhr)"
                                :files="formData.documents.value"
                                :file-usage-id="id"
                                api-endpoint="api/production"
                                :disabled="isClone || formData.locked.value || !$ability.can('upload', 'Files')"
                                :disabled-message="getUploadDisabledMessage">
                            </book-upload>
                        </div>
                    </div>
                
        </div>

        <!-- Column 2 -->
        <div :class="isEditForm ? 'col-sm-6 col-md-6' : 'book-creation-form'" v-show="isActiveTab('overview')" v-if="!isClone">

            <!-- Crew -->
            <div class="panel panel-info" v-if="isEditForm">
                <div class="panel-heading">
                    Crew
                </div>
                <book-production-crew
                    :production="production"
                    :items="formData.crew.value"
                    :planItems="isClone ? formData.plan.value : null"
                    :local="isClone"
                    :skillEntity="skillEntity"
                    :planTypeEntity="planTypeEntity"
                    :disabled="isClone || formData.locked.value || crewIsDisabled"
                    :disabled-message="getCrewDisabledMessage"
                    showBookingType
                    :bookingTypesEntity="bookingTypesEntity"
                    @changedCrew="items => formData.crew.value = items.slice(0)">
                </book-production-crew>
            </div>

            <!-- Workgroup tab -->
            <div class="panel panel-info book-equipment-wrapper" v-if="isEditForm">
                <div class="panel-heading">
                    <span>Workgroups</span>
                </div>
                <book-production-workgroups
                    :production="production"
                    :items="formData.workgroups.value"
                    :planItems="isClone ? formData.plan.value : null"
                    :local="isClone"
                    :workgroupsEntity="workgroupsEntity"
                    :planTypeEntity="planTypeEntity"
                    :disabled="isClone || formData.locked.value || crewIsDisabled"
                    :disabled-message="getCrewDisabledMessage"
                    @changedWorkgroup="items => formData.workgroups.value = items.slice(0)">
                </book-production-workgroups>
            </div>

            <!-- Equipment tab -->
            <div class="panel panel-info book-equipment-wrapper" v-if="isEditForm || isTemplateBased">
                <div class="panel-heading">
                    <span>Equipment</span>
                </div>
                <book-equipment-selection
                    :selection="formData.equipment.value"
                    :dates="{from: formData.productionStart.value, to: formData.productionEnd.value}"
                    :usage-id="id"
                    usage-type="production"
                    :disabled="isClone || formData.locked.value || !isEditable"
                    :disabled-message="getEquipmentDisabledMessage"
                    :local="!isEditForm"
                    :enableCheckAvailability="isEditForm"
                    @changedEquipment="(item, notify) => updateEquipment(item, notify)">
                </book-equipment-selection>
            </div>

            <!-- Notes -->
            
                <div class="panel panel-info" v-if="isEditForm">
                    <div class="panel-heading">
                        Task Details
                    </div>
                    <div class="panel-body">
                        <textarea rows="10" class="form-control" name="notes" v-model="formData.notes.value" :disabled="formData.locked.value || !isEditable"></textarea>
                    </div>
                </div>
            

        </div>

        <!-- Column 3 -->
        <div class="col-sm-12 col-md-3" v-show="isActiveTab('overview')"  v-if="!isClone">

            <div class="row">

                <!-- Sign Off -->
                <div class="col-sm-6 col-md-12">
                    <div class="panel panel-info" v-if="isEditForm">
                        <div class="panel-heading">
                            Sign Offs
                        </div>
                        <div class="panel-body">
                            <book-signoff
                                :itemId="id"
                                :signoffs="signoffItems"
                                isTask
                                @updated="items => formData.signoffs.value = items"
                                :disabled="formData.locked.value || !isEditable">
                            </book-signoff>
                        </div>
                    </div>
                </div>

                <!-- Audios -->
                <div class="col-sm-6 col-md-12">
                    <div class="panel panel-info" v-if="isEditForm" v-show="!isActiveTab('crew')">
                        <div class="panel-heading">
                            Audio layout
                        </div>
                        <div class="panel-body">
                            <div class="form-group form-group-sm">
                                <book-audio-layout v-model="formData.audioLayout.value" itemType="production" :audioLayoutsEntity="audioLayoutsEntity" isEdit :disabled="formData.locked.value || !isEditable"></book-audio-layout>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                

                 <!-- Local notes -->
                <div class="col-sm-6 col-md-12">
                    <book-local-notes v-if="isEditForm"
                        :itemId="isClone ? $route.query.clone : id" 
                        itemType="production" 
                        :isEdit="isEditForm"
                        :countries="countries" 
                        :notesProps="production.get.localNotes" 
                        :locked="formData.locked.value || !isEditable" 
                        @localNotesChanged="(items) => updateLocalNotes(items)">
                    </book-local-notes>
                </div>
            </div>
            <div class="row">
                <!-- Contacts -->
                <div class="col-sm-6 col-md-12">
                    <div class="panel panel-info" v-if="isEditForm">
                        <div class="panel-heading">
                            Contacts
                        </div>
                        <div class="panel-body">
                            <textarea rows="3" class="form-control" name="contacts" v-model="formData.contacts.value" :disabled="formData.locked.value || !isEditable"></textarea>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="book-form-actions book-actions-flex book-bottom-sticky" v-if="loaded">
        <div class="book-form-actions-label">
            <span class="book-form-actions-label-text book-form-actions-label-last-change" v-if="hasHistory" title="Last change">
                <font-awesome-icon icon="clock"/> 
                <div class="book-form-actions-label-last-change-inner">
                    <span>{{production.get.history[0].date}}</span>
                    <span>by <router-link :to="{name: 'user.id', params: {id: production.get.history[0].user.id}}">{{production.get.history[0].user.name}}</router-link></span>
                </div>
            </span>
            <span class="book-form-actions-label-text book-form-actions-label-datetimes" v-if="isEditForm && !isClone" title="Production start & end">
                <div>
                    <span>{{getFormattedProductionDate('productionStart').date}}</span><span class="book-form-actions-label-time">{{getFormattedProductionDate('productionStart').time}}</span><br />
                    <span>{{getFormattedProductionDate('productionEnd').date}}</span><span class="book-form-actions-label-time">{{getFormattedProductionDate('productionEnd').time}}</span>
                </div>
            </span>
            <span class="book-form-actions-label-text" v-if="isEditForm && !isClone">
                ID: {{id}}
            </span>
            <span class="book-form-actions-label-text" v-if="isClone">
                <font-awesome-icon icon="copy" v-if="isClone"/>&nbsp;{{production.get.title}}                
            </span>
            <span class="book-form-actions-label-text" v-if="isChanged">
                <span class="label label-danger">unsaved</span>
            </span>
        </div>
        <button tabindex="15" class="btn btn-info" @click="submit(true)" v-if="!isEditForm || isClone" :disabled="!isFormValid || processing">Save</button>
        <button tabindex="16" class="btn btn-info" @click="submit(true, false)" v-if="isEditForm && !isClone" :disabled="!isFormValid || processing || !isChanged">Save</button>
        <button tabindex="17" class="btn btn-primary" @click="submit()" :disabled="!isFormValid || processing || !isChanged" v-if="!getTabRoutes()">Save & close</button>
        <button tabindex="17" class="btn btn-primary" @click="submit(false, false)" :disabled="!isFormValid || processing || !isChanged" v-else>Save & Close</button>
        <router-link tabindex="18" :to="getDestination()" class="btn btn-danger" v-if="!getTabRoutes()">
            <span v-if="isChanged">Cancel</span>
            <span v-else>Close</span>
        </router-link>
        <div tabindex="18" class="btn btn-danger" @click="closeNewTab()" v-else>
            <span v-if="isChanged">Cancel and Close</span>
            <span v-else>Close</span>
        </div>

    </div>

</div>
</template>