<script>

import {app} from 'app'
import Lock from 'components/common/lock/Lock'

export default {
    props: {
        item: {
            user: '',
            id: '',
        },
    },
    computed: {
        lock() {
            return Lock
        },
    },
    watch: {
        'item': {
            handler: function(newValue) {
                this.isLocked()
            },
            deep: true
        }
    },
    mounted() {
      //console.log(this.item, 'whats')
    },
    methods: {
        isLocked() {
            let isLocked = Lock.isLocked(this.item)
            if (!isLocked) {
                this.$emit('isLocked', null)
                return false
            }
            else {
                this.$emit('isLocked', this.item.itemId)
                return true
            }
        },
        notify() {
            swal('Error!', 'This feature is not available yet...', 'error')
        }
    }
}
</script>

<template>
<div class="book-lock-wrapper" v-if="isLocked()">
    <div class="label label-danger">
        <font-awesome-icon icon="lock" aria-hidden="true"/>        
        <router-link v-if="item.user" :to="{ name: 'user.id', params: {id: item.user.id} }">{{item.user.name}}</router-link>
    </div>
    <button class="btn btn-default" @click="notify()" :title="'Send a message to ' + item.user.name"><font-awesome-icon icon="comments" aria-hidden="true"/></button>
</div>
</template>

<style lang="scss">
@import "../../../style/_variables.scss";
div.book-lock-wrapper {
    > * {
        display: inline-block;
    }
    .label {
        padding: 6px .6em;
        * + a {
            margin-left: 2px;
        }
    }
    a {
        color: $color-grey-light;
    }
}
* + div.book-lock-wrapper {
    border-left: 1px solid $color-grey-border;
    padding-left: 8px;
}
</style>
