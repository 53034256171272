import store from 'store'
import Api from 'components/app/Api'

class ProductionPlan extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null, productionId = 0) {
        super({
            id: id,
            apiUrl: 'api/production/' + productionId + '/plan',
            module: 'productionPlan',
        })
    }

    /**
     * Save multiple plan items.
     * @param array planItems
     */
    static saveMultiple(planItems, productionId) {
        return new Promise((resolve, reject) => {
            let url = `api/production/${productionId}/plan/save-multiple`
            axios.post(url, planItems)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default ProductionPlan
