import Api from 'components/app/Api'

class Updates extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = 0) {
        super({
            id: id,
            apiUrl: 'api/mcrdata/updates',
            module: 'updates',
        })
    }

    getApiUrl() {
        return 'api/mcrdata/updates'
    }
}

export default Updates