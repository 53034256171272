
// @TODO Rewrite using Api class...

import store from 'store'

class Role {

    /**
     * Constructor.
     * @param object data The role data to create a role instance with.
     */
    constructor(data = null) {
        if (data) {
            this.id = data.id
            store.commit('user/setRole', data)
        }
    }

    /**
     * Set a value to a given field on the role model.
     * @param string field The name  of the role field.
     * @param string value The value to set.
     */
    set(field, value) {
        store.commit('user/setRoleField', {id: this.id, field: field, value: value})
    }

    /**
     * Create a role user.
     * @return promise
     */
    store() {
        let id = this.id
        let promise = new Promise((resolve, reject) => {
            axios.post('api/role', store.state.user.roles[id])
                .then(response => {
                    store.commit('user/setRole', response.data)
                    store.commit('user/removeRole', 0)
                    sessionStorage.removeItem('users');
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return promise
    }

    /**
     * Save the role model into the API.
     * @return promise
     */
    save() {
        let id = this.id
        return new Promise((resolve, reject) => {
            axios.put('api/role/' + id, store.state.user.roles[id])
                .then(response => {
                    sessionStorage.removeItem('users');
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}

export default Role
