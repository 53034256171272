<script>
export default {
  props: {
    feedAudioLayout: {
      type: String,
      default: ''
    },
    audioLayoutsEntities: {
      type: Array
    },
    audioLayoutsEntity: {
      type: String,
      default: ''
    },
    printableTextMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      existingFeedAudioLayouts: this.feedAudioLayout,
      audioLayoutStructure: {"audioPairs": []},
      audioLayoutsEntityLabels: [],
      channelsToAudioLayoutsMap: Map,
      availableChannels: ['Ch 1', 'Ch 2', 'Ch 3', 'Ch 4', 'Ch 5', 'Ch 6', 'Ch 7', 'Ch 8', 'Ch 9', 'Ch 10', 'Ch 11', 'Ch 12', 'Ch 13', 'Ch 14', 'Ch 15', 'Ch 16'],
      renderComponent: true
    }
  },
  beforeMount() {
    this.buildFeedAudioLayout()
    console.log(this.audioLayoutStructure.audioPairs)
  },
  methods: {
    buildFeedAudioLayout() {
      const context = this
      if (context.existingFeedAudioLayouts && !context.isExistingDataUsable(context.existingFeedAudioLayouts)) {
        this.existingFeedAudioLayouts = ''
        context.setupFeedAudioLayoutInputValues()
      } else if (context.existingFeedAudioLayouts && context.hasObsoleteJSONFormat(context.existingFeedAudioLayouts)) {
        context.migrateFeedAudioLayoutInputValues(context.existingFeedAudioLayouts, context.audioLayoutsEntities)
      } else {
        context.setupFeedAudioLayoutInputValues()
      }
      context.generateAudioLayout(this.audioLayoutStructure)
      context.fillAudioLayoutsEntityLabelsDropDowns(context.audioLayoutsEntities)
    },
    setupFeedAudioLayoutInputValues() {
      if (this.existingFeedAudioLayouts) {
        // Create Map from the existingFeedAudioLayouts
        this.channelsToAudioLayoutsMap = new Map(Object.entries(JSON.parse(this.existingFeedAudioLayouts)))
      } else {
        // Create Map with empty keys
        this.channelsToAudioLayoutsMap = new Map
        this.availableChannels.map(channel => this.channelsToAudioLayoutsMap.set(channel.toString(), ''))
      }
    },
    generateAudioLayout(audioLayoutStructure) {
      let channelsToAudioLayoutsMap = this.channelsToAudioLayoutsMap
      let availableChannels = this.availableChannels
      let pairNumber = 1
      for (let index = 0; index < availableChannels.length; index++) {
        if (index % 2 === 0) {
          let firstChannelInPair = channelsToAudioLayoutsMap.get(availableChannels[index])
          let secondChannelInPair = channelsToAudioLayoutsMap.get(availableChannels[(index + 1)])
          const audioPair = this.createAudioPair(index, pairNumber++, firstChannelInPair, secondChannelInPair)
          audioLayoutStructure.audioPairs.push(audioPair)
        }
      }
    },
    createAudioPair(index, pairNumber, firstChannelInPair, secondChannelInPair) {
      return {
        number: pairNumber, channels: [
          {"name": "Ch " + (index + 1), "number": (index + 1), "value": firstChannelInPair},
          {"name": "Ch " + (index + 2), "number": (index + 2), "value": secondChannelInPair}
        ],
        channelType: firstChannelInPair !== secondChannelInPair ? "Mono" : "Stereo"
      }
    },
    fillAudioLayoutsEntityLabelsDropDowns(audioLayoutsEntities) {
      if (!audioLayoutsEntities) {
        console.warn("audioLayoutsEntities not received from backend, no suggestions will be loaded for Feed Audio Layouts")
      }
      audioLayoutsEntities.map(entity => this.audioLayoutsEntityLabels.push(entity.label))
    },
    /**
     * isExistingDataUsable returns true when a NON Empty JSON is provided
     * Use this to avoid any invalid Legacy String data from the backend
     * @param existingFeedAudioLayouts
     * @returns {boolean}
     */
    isExistingDataUsable(existingFeedAudioLayouts) {
      try {
        let parsedJson = JSON.parse(existingFeedAudioLayouts)
        return Object.keys(parsedJson).length > 0
      } catch (error) {
        return false
      }
    },
    hasObsoleteJSONFormat(existingFeedAudioLayouts) {
      let object = JSON.parse(existingFeedAudioLayouts)
      let existingFeedAudioLayoutsMap = new Map(Object.entries(object))
      const keysIterator = existingFeedAudioLayoutsMap.keys()
      for (let i = 0; i < existingFeedAudioLayoutsMap.size; i++) {
        let key = keysIterator.next().value
        let value = existingFeedAudioLayoutsMap.get(key)
        if (!isNaN(value) && this.audioLayoutsEntities.find(element => element.id === value)) {
          return true
        }
      }
      return false
    },
    isNotJSONString(existingFeedAudioLayouts) {
      try {
        JSON.parse(existingFeedAudioLayouts)
      } catch (e) {
        console.warn("Received existingFeedAudioLayouts in NON JSON format + " + existingFeedAudioLayouts)
        return true
      }
      return false
    },
    /**
     * Migrate from old format {"Ch 1":721,"Ch 2":721,"Ch 3":718,"Ch 4":722,"Ch 5":715,"Ch 6":715}
     * to new format
     * {"Ch 1":"Team Radio","Ch 2":"Team Radio",..."Ch 16":"HOST BROADCASTER COMMENTARY (WHEN AVAILABLE)"}
     * To be removed when all the existing feeds have been migrated
     */
    migrateFeedAudioLayoutInputValues(existingFeedAudioLayouts, audioLayoutsEntities) {
      if (existingFeedAudioLayouts && audioLayoutsEntities) {
        // Create channelsToAudioLayoutsMap from the existingFeedAudioLayouts in the obsolete JSON format
        let existingFeedAudioLayoutsMap = new Map(Object.entries(JSON.parse(this.existingFeedAudioLayouts)))
        this.channelsToAudioLayoutsMap = new Map
        this.availableChannels.map(channel => {
          if (existingFeedAudioLayoutsMap.has(channel)) {
            let channelsAudioLayoutId = existingFeedAudioLayoutsMap.get(channel)
            let foundChannelsAudioLayoutEntity = audioLayoutsEntities.find(entity => entity.id === channelsAudioLayoutId)
            if (foundChannelsAudioLayoutEntity) {
              this.channelsToAudioLayoutsMap.set(channel.toString(), foundChannelsAudioLayoutEntity.label)
            }
          } else {
            this.channelsToAudioLayoutsMap.set(channel.toString(), '')
          }
        })
      }
    },
    /**
     * @param firstChannelInAudioPair Key to the first channel in a pair of audio layouts
     * example: 'Ch 1', 'Ch 3', 'Ch 5' etc (Every other channel from the availableChannels list)
     * @param value
     */
    handleStereoInput(firstChannelInAudioPair, value) {
      this.handleMonoInput(firstChannelInAudioPair, value)

      let availableChannels = this.availableChannels
      if (availableChannels.includes(firstChannelInAudioPair)) {
        let indexOfFirstChannelInPair = availableChannels.findIndex(channel => channel === firstChannelInAudioPair)
        let indexOfSecondChannelInPair = indexOfFirstChannelInPair + 1
        this.handleMonoInput(availableChannels[indexOfSecondChannelInPair], value)
      }
    },
    handleMonoInput(feedAudioLayoutKey, updatedValueOfLayout) {
      const context = this
      let audioLayoutChanged = false
      let channelsToAudioLayoutsMap = context.channelsToAudioLayoutsMap
      if (channelsToAudioLayoutsMap.has(feedAudioLayoutKey)) {
        // Use existing value if unequal
        let originalSavedValueOfLayout = channelsToAudioLayoutsMap.get(feedAudioLayoutKey)
        if (originalSavedValueOfLayout !== updatedValueOfLayout) {
          channelsToAudioLayoutsMap.set(feedAudioLayoutKey, updatedValueOfLayout)
          audioLayoutChanged = true
        }
      } else if (context.availableChannels.includes(feedAudioLayoutKey) && updatedValueOfLayout) {
        channelsToAudioLayoutsMap.set(feedAudioLayoutKey, updatedValueOfLayout)
        audioLayoutChanged = true
      }
      //console.log("updatedValueOfLayout::: " + updatedValueOfLayout)
      //console.log("channelsToAudioLayoutsMap::: " + JSON.stringify(Object.fromEntries(channelsToAudioLayoutsMap)))
      //console.log("audioLayoutChanged::: " + audioLayoutChanged)
      if (audioLayoutChanged) {
        // Sanitize Map Before Saving Update
        let updatedAudioLayoutsData = Object.fromEntries(channelsToAudioLayoutsMap)
        let sanitizedFeedAudioLayoutsJson = {}
        Object.entries(updatedAudioLayoutsData).map(value => {
          let audioLayoutValueForChannel = value[1]
          if (audioLayoutValueForChannel) {
            let audioChannel = value[0]
            sanitizedFeedAudioLayoutsJson[audioChannel] = audioLayoutValueForChannel
          }
        })
        let updatedFeedAudioLayoutsJson = JSON.stringify(sanitizedFeedAudioLayoutsJson)
        //console.warn("updatedFeedAudioLayoutsJson => " + updatedFeedAudioLayoutsJson)
        //console.log("audioLayoutChanged::: " + audioLayoutChanged)
        this.$emit('changedAudioLayout', updatedFeedAudioLayoutsJson)
        audioLayoutChanged = false
      }
    },
    changeCurrentChannelType(currentChannelType, audioPairNumber) {
      this.audioLayoutStructure.audioPairs.map(audioPair => {
        if (audioPair.number === audioPairNumber) {
          audioPair.channels[1].value = audioPair.channels[0].value
          if (currentChannelType === 'Mono') {
            audioPair.channelType = 'Stereo'
            this.handleStereoInput(audioPair.channels[0].name, audioPair.channels[0].value)
          } else if (currentChannelType === 'Stereo') {
            audioPair.channelType = 'Mono'
            this.handleMonoInput(audioPair.channels[1].name, audioPair.channels[1].value)
          } else {
            console.warn('Invalid audioPair.channelType => ' + audioPair.channelType)
          }
        }
      })
    }
  }
  ,
  watch: {
    feedAudioLayout: function (newVal, oldVal) {
      this.existingFeedAudioLayouts = newVal
      if (newVal !== oldVal) {
        // Reset audioLayoutStructure & buildFeedAudioLayout again
        this.audioLayoutStructure = {"audioPairs": []}
        if (Array.isArray(this.audioLayoutsEntityLabels) && this.audioLayoutsEntityLabels.length > 0) {
          this.audioLayoutsEntityLabels = []
        }
        // After good reset, now rebuild it
        this.buildFeedAudioLayout()
      }
    }
  }
}
</script>

<template>
  <div v-if="!printableTextMode">
    <div class="panel-body">
      <div class="form-group form-group-sm">
        <div class="row">
          <div v-for="audioPair in audioLayoutStructure.audioPairs">
            <div class="col-sm-6 channel-left">
              <div class="col-sm-6 col-xs-12 audio-label">
                <label for="audioLayoutEntities-choice-mono"
                       class="col-sm-12 control-label pair-label">Audio Pair {{ audioPair.number }}
                  ({{ audioPair.channels[0].number }} & {{ audioPair.channels[1].number }})</label>
                <div class="radio-inline">
                  <label>
                    <input type="radio"
                           :value="audioPair.channelType"
                           @change="changeCurrentChannelType(audioPair.channelType, audioPair.number)"
                           :checked="audioPair.channelType === 'Stereo'">
                    <span>Stereo</span>
                  </label>
                </div>
                <div class="radio-inline">
                  <label>
                    <input type="radio"
                           :value="audioPair.channelType"
                           @change="changeCurrentChannelType(audioPair.channelType, audioPair.number)"
                           :checked="audioPair.channelType === 'Mono'">
                    <span>Mono</span>
                  </label>
                </div>
              </div>
              <div class="mono-wrapper">
                <div v-for="channel in audioPair.channels" v-if="audioPair.channelType === 'Mono'"
                     class="col-sm-6 col-xs-12 audio-pair">
                  <input class="form-control"
                         list="audioLayoutEntities-list-mono"
                         id="audioLayoutEntities-choice-mono"
                         placeholder="Type to add or search"
                         name="audioLayoutEntities-choice"
                         :value="channelsToAudioLayoutsMap.get(channel.name)"
                         @input="handleMonoInput(channel.name, $event.target.value)"/>
                  <datalist id="audioLayoutEntities-list-mono">
                    <option v-for="audioLayoutsEntity in audioLayoutsEntityLabels" v-bind:value="audioLayoutsEntity"
                            v-bind:label="audioLayoutsEntity"></option>
                  </datalist>
                </div>
              </div>
              <div v-for="(channel, index) in audioPair.channels" v-if="audioPair.channelType === 'Stereo'">
                <div class="col-sm-6 col-xs-12 audio-pair" v-if="index % 2 === 0">
                  <input class="form-control"
                         list="audioLayoutEntities-list-stereo"
                         id="audioLayoutEntities-choice-stereo"
                         placeholder="Type to add or search"
                         name="audioLayoutEntities-choice-stereo"
                         :value="channelsToAudioLayoutsMap.get(channel.name)"
                         @input="handleStereoInput(channel.name, $event.target.value)"/>
                  <datalist id="audioLayoutEntities-list-stereo">
                    <option v-for="audioLayoutsEntity in audioLayoutsEntityLabels" v-bind:value="audioLayoutsEntity"
                            v-bind:label="audioLayoutsEntity"></option>
                  </datalist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <table class="audio-layout-print-table" v-for="(audioPair, index) in audioLayoutStructure.audioPairs">
        <tbody>
          <tr :class="index > 0 ? 'border-channel' : ''" v-if="audioPair.channelType === 'Stereo' && audioPair.channels[0].value && audioPair.channels[0].value !== ''">
            <td class="book-sidebar-table-row-nowrap book-width-40-percents padding-top-5">PAIR {{ audioPair.number }}
              ({{ audioPair.channelType }})
            </td>
            <td class="text-right">{{ audioPair.channels[0].value }}</td>
          </tr>
          <!-- Mono Area -->
          <tr :class="index > 0 && audioPair.channels[0] ? 'border-channel' : ''" v-if="audioPair.channelType === 'Mono' && audioPair.channels[0].value && audioPair.channels[0].value !== ''">
            <td class="book-sidebar-table-row-nowrap book-width-40-percents padding-top-5">{{ audioPair.channels[0].name }} ({{ audioPair.channelType }})</td>
            <td class="text-right">{{ audioPair.channels[0].value }}</td>
          </tr>
          <tr :class="audioPair.channels[1] ? 'border-channel' : ''" v-if="audioPair.channelType === 'Mono' && audioPair.channels[1].value && audioPair.channels[1].value !== ''">
            <td class="book-sidebar-table-row-nowrap book-width-40-percents padding-top-5">{{ audioPair.channels[1].name }} ({{ audioPair.channelType }})</td>
            <td class="text-right">{{ audioPair.channels[1].value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../style/variables";

.audio-layout-print-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  .border-channel {
    border-top: 1px solid $color-grey-border;
  }
  .padding-top-5 {
    padding-top: 5px;
  }
}

.col-sm-6.channel-left {
  padding-right: 4px;
}

.col-sm-6.channel-left,
.col-sm-6.channel-right {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.book-sidebar-table-row.border-channel {
  border-top: 1px solid $color-grey-border;
}

.col-sm-12.audio-label,
.col-sm-6.audio-label {
  padding-left: 0;
  padding-right: 2px;
  font-size: 10px;
  font-weight: normal;

  .col-sm-12 {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
  }

  .control-label {
    text-align: left;
    font-size: 10px;
    padding-top: 0;
  }

  .control-label.pair-label {
    font-size: 10px;
    padding-top: 0;
  }

  overflow: hidden;

  .radio-inline {
    padding-left: 0;
    padding-top: 0;


    input[type="radio"] {
      margin-left: 0;
      margin-top: 0;
    }

    span {
      margin-left: 15px;
      font-weight: normal;
      font-size: 8px;
    }
  }

  .radio-inline + .radio-inline {
    margin-left: 2px;
  }

}

.mono-wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;

  > .audio-pair {
    flex: 1;
  }
}

.audio-pair {
  input {
    width: 100%;
    font-size: 10px;
    padding: 1px;
    height: 30px;
    line-height: 30px;
  }
}


.col-sm-12.audio-pair,
.col-sm-6.audio-pair {
  padding-left: 0;
  padding-right: 0;

  .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .col-sm-6.channel-left {
    padding-right: 15px;

  }
  .col-sm-6.channel-right {
    padding-top: 8px;
    padding-left: 15px;


  }

  .col-sm-6.audio-label {
    .radio-inline {
      padding-top: 3px;

      span {
        font-size: 10px;
      }
    }

    .control-label.pair-label {
      padding-top: 8px;
    }

    .control-label {
      font-size: 12px;
    }

    .control-label.pair-label {
      font-size: 12px;
    }
  }

  .col-sm-6.audio-pair {
    select {
      font-size: 12px;
      padding: 0;
    }
  }
}

@media (min-width: 1300px) {
  .col-sm-6.channel-left,
  .col-sm-6.channel-right {
    .col-sm-6.audio-label {
      width: 105px;
    }

    .col-sm-6.audio-pair {
      width: calc(100% - 105px);
    }

  }

  .col-lg-6.audio-label {
    width: 105px;
  }

  .col-lg-6.audio-pair {
    width: calc(100% - 105px);
  }

}

.book-sidebar-table-row-nowrap.book-width-40-percents {
  width: 40%;
}

.book-width-60-percents {
  width: 60%;
}
</style>
