import {app} from 'app'
import User from 'components/user/User'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import BookLoader from 'components/common/loader/BookLoader'
import BookTable from 'components/common/BookTable'
import BookProductionPlan from 'components/production/ProductionPlan/BookProductionPlan'
import BookProductionCrew from 'components/production/ProductionCrew/BookProductionCrew'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookSelectbox from 'components/common/BookSelectbox'
import BookAudioLayout from 'components/common/BookAudioLayout'

import ItemEditBaseMixin from 'components/common/item/ItemEditBaseMixin'

// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'
import BookNentCalendarNotes from 'components/common/BookNentCalendarNotes'

import productionTypes, { getPermissionSubjectFromProductionType } from '../productionTypes';

export default {
    mixins: [
        ItemEditBaseMixin
    ],
    components: {
        BookLoader,
        BookTable,
        BookProductionPlan,
        BookProductionCrew,
        BookEquipmentSelection,
        BookSelectbox,
        multiselect,
        datepicker,
        maskedInput,
        BookAudioLayout,
        BookNentCalendarNotes
    },
    mounted() {
        this.loadForm()
            .then(response => {
                User.getUsers()
                .then(response => {
                    this.setLoaded()
                })
                .catch(error => {
                    this.$error.set(error)
                    this.setLoaded()
                })
            })
            .catch(error => {
                this.$error.set(error)
                this.setLoaded()
            })

        // Since some fields are using components, we can't attach
        // VeeValidate directly to the HTML element, then we need to create the
        // fields "manually" here, inside the "$validator" (VeeValidate).
        this.$validator.attach('productionLeader', this.formData.productionLeader.validator.rules)

    },

    watch: {
        'formData.sport.value': function (newValue) {
            // When sport is changed, and we have a league value, we need to set league value to empty, unless the
            // league belongs to the sport.
            const league = this.formData.league.value
            if (league) {
                const entityItem = this.leagueEntity.getItem(league)

                if (entityItem.reference !== newValue) {
                    this.formData.league.value = ''
                }
            }
        },
        'formData.productionLeader.value': function(newValue) {
            this.$validator.validate('productionLeader', newValue)
        },
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: "production",
        },
        routeItem: {
            default: 'productionTemplate',
            type: String,
        },
        routeItems: {
            default: 'productionTemplates',
            type: String,
        }
    },
    data() {
        return {
            loaded: false,
            production: null,
            formData: this.defaultData(),

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'productionTemplate',
                items: 'productionTemplates',
                settings: 'productions',
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    channelEntity: 'channel_entity_id',
                    runTypeEntity: 'runtype_entity_id',
                    locationEntity: 'location_entity_id',
                    planTypeEntity: 'plantype_entity_id',
                    skillEntity: 'skill_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id'
                }
            },
            activeTab: 'overview',
            productionTypes: productionTypes,
        }
    },
    computed: {
        getPermissionSubject() {
            return getPermissionSubjectFromProductionType(this.type)
        },
        typeLabel() {
            return this.productionTypes[this.type].label
        },
        canUpdate() {
            return app.$ability.can('update_production_templates', this.getPermissionSubject)
        },
        canUpdateGlobal() {
            return app.$ability.can('update_production_global_templates', this.getPermissionSubject)
        },
        isGlobal() {
            return this.productionTemplate && this.productionTemplate.get && this.productionTemplate.get.global
        },
        calendarNotes() {
            return this.productionTemplate.get.data && this.productionTemplate.get.data.comments ? JSON.parse(this.productionTemplate.get.data.comments) : null
        },
        processing() {
            return this.$loader.has('productionTemplateEdit_submit')
        },
        crewIsDisabled() {
            const planItems = this.formData.plan.value
            return !planItems || planItems.findIndex(v => v.relativeTo == 'start') == -1 || planItems.findIndex(v => v.relativeTo == 'end') == -1
        },
        isChanged() {
            console.info("Checking ProductionTemplate isChanged")
            if (!this.productionTemplate.get) return false
            if (this.itemsChanged('equipment')) return true
            if (this.itemsChanged('plan')) return true
            if (this.itemsChanged('crew')) return true
            const context = this
            let changed = false
            Lazy(context.formData)
                .filter(v => {
                    return !v.local
                })
                .each((v, k) => {
                    //console.log('is Changed??', changed, v, k)
                    if (changed) return true
                    switch (k) {
                        case 'sport':
                        case 'data':
                        case 'plan':
                        case 'crew':
                        case 'equipment':
                        case 'user':
                        case 'comments':
                        case 'houseNumber':
                            // Placeholder since these fields don't need to be checked.
                            break
                        default:
                            const values = v.data ? context.productionTemplate.get.data : context.productionTemplate.get

                            // The form value is an array and its length is not equal to
                            // the length of the original value, then it has been changed.
                            if (Array.isArray(v.value) && Array.isArray(values[k]) && (v.value.length != values[k].length)) {
                                changed = true
                                //console.log('is Changed 2nd??', changed, v, k)
                            }
                            else if (values && (v.value || values[k])) {
                                changed = v.value != values[k]
                                //console.log('is Changed 3rd??', changed, v, k)
                            }
                            break
                    }
                })
            return changed
        },
        updateAble() {
            return  this.canUpdate && !this.isGlobal || this.canUpdateGlobal && this.isGlobal
        },
        saveAble() {
            return this.isChanged && this.updateAble
        },
        saveButtonDisabled() {
            return !this.isFormValid || this.processing || !this.saveAble
        },
        isOwnTemplate() {
            return this.productionTemplate.get.user.id === app.$data.user.get.id
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        isRunTypeDisabled() {
            return this.type === 'eng'
        }
    },
    methods: {
        /**
         * Check if the passed tab name is the current active tab.
         */
         isActiveTab(name) {
            return this.activeTab == name
        },

        itemsChanged(type) {
            let changed = false
            if (!this.productionTemplate.get || (!this.productionTemplate.get.data && this.formData[type].value.length == 0)) {
                return changed
            }
            switch (true) {
                case (!this.productionTemplate.get.data && this.formData[type].value.length > 0):
                case (this.productionTemplate.get.data[type] && this.productionTemplate.get.data[type].length != this.formData[type].value.length):
                    changed = true
                    break
                default:
                    Lazy(this.productionTemplate.get.data[type])
                    .each((v, k) => {
                        //if (type == "crew") changed = this.crewItemsChanged(type);
                        if (changed) return
                        else if (v.id != this.formData[type].value[k].id) changed = true
                    })
                    break
            }
            return changed
        },

        /**
         * Special condition for the crew as we need to track changes on the each column
         */
        crewItemsChanged(type){

            let changed = false
            Lazy(this.productionTemplate.get.data[type])
            .each((v, k) => {
                if (changed) return
                else if (v.id != this.formData[type].value[k].id) changed = true
                else if (v.country != this.formData[type].value[k].country) changed = true
                else if (v.endPlan != this.formData[type].value[k].endPlan) changed = true
                else if (v.skill != this.formData[type].value[k].skill) changed = true
                else if (v.startPlan != this.formData[type].value[k].startPlan) changed = true
                else if (v.locationId != this.formData[type].value[k].locationId) changed = true
                else if (this.formData[type].value[k].location_id && v.locationId != this.formData[type].value[k].location_id) changed = true
                else if (v.status != this.formData[type].value[k].status) changed = true
                else if (v.userId != this.formData[type].value[k].userId) changed = true
            })
            return changed
        },

        /**
         * Save the form.
         */
        submit(open = false, redirect = true) {
            const context = this
            context.$loader.add('productionTemplateEdit_submit')
            Lazy(context.formData)
                .filter(v => !v.local && !v.data)
                .each(function(v, k) {
                    if(k === 'filterType') {
                        context.productionTemplate.set(k, context.formData.runType.value)
                    }
                    else if(k === 'filterLeague'){
                        context.productionTemplate.set(k, context.formData.league.value)
                    }
                    else {
                        context.productionTemplate.set(k, v.value)
                    }
                })
            const data = {}
            Lazy(context.formData)
                .filter((v, k) => v.data && this.hasField(k))
                .each((v, k) => {
                    if (k === 'tvProduction') {
                        if (!v.value) data[k] = false
                    }
                    if (k === 'comments') {
                        let calendarNotes = context.formData[k].value;
                        if (calendarNotes) {
                            data[k] = context.cleanObjectString(calendarNotes);
                        }
                    } else {
                        data[k] = v.value
                    }
                })
            context.productionTemplate.set('data', data)
            context.productionTemplate.set('filterType', context.formData.runType.value)
            context.productionTemplate.set('filterLeague', context.formData.league.value)

            if (context.isEditForm) {
                context.productionTemplate.save()
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`${context.typeLabel} template ${context.id} was saved!`, 'success', 3)
                        context.updateHouseNumbers()
                        Vue.set(context.$data, 'formData', context.defaultData())
                        if (redirect) context.redirect(open)
                        else context.setLoaded()
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, `It was not possible to save the ${context.typeLabel} template.`)
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
            }
            else {

                // When creating a new Production Template, a redirect should
                // always be done, back to the list or to the "edit form".
                redirect = true

                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('productionTemplateEdit_submit')
                }

                context.productionTemplate.store()
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`${context.typeLabel} template ${response.data.id} has been created!`, 'success', 3)

                        // Reset formData if "Save & Close" button has been clicked.
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())
                        else context.productionTemplate = new ProductionTemplate(response.data.id)

                        if (redirect) {
                            context.redirect(open, response.data.id)
                            .then(response => {
                               if (open) {
                                  context.setLoaded()
                               }
                            })
                            .catch(error => {
                                if (open) {
                                   context.setLoaded()
                                }
                            })
                        }
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
            }
        },

        /**
         * Set default values on "Add production" and current data on "edit".
         */
        setFormValues() {
            const context = this
            if (context.isEditForm) {
                Lazy(context.formData)
                .filter(v => !v.local && !v.data)
                .each(function(v, k) {
                    switch (k) {
                        case 'sport':
                            const league = context.productionTemplate.get.data.league
                            if (league) {
                                const entityItem = context.leagueEntity.getItem(league)
                                v.value = entityItem.reference
                            }
                            break

                        default:

                            // We need to detect if the value is array/object or
                            // another type, so we can make a real copy of the
                            // value and not create a pointer.
                            if (typeof context.productionTemplate.get[k] == 'object') {
                                if (Array.isArray(context.productionTemplate.get[k])) {
                                    v.value = context.productionTemplate.get[k].slice()
                                }
                                else {
                                    Object.assign(v.value, context.productionTemplate.get[k])
                                }
                            }
                            else {
                                v.value = context.productionTemplate.get[k]
                            }

                            break
                    }
                })
                Lazy(context.productionTemplate.get.data)
                    .each((v, k) => {
                        context.formData[k].value = v || ''
                    })
            }
            else {
                let defaultValue = null
                const entities = ['runType', 'channel']

                // Try to set default values for entity field.
                entities.forEach(v => {
                    defaultValue = context[`${v}Entity`].get.items.filter(item => item.default)
                    if (defaultValue.length > 0) {
                        context.formData[v].value = defaultValue[0].id
                    }
                })

                context.formData.user.value = {
                    id: app.$data.user.get.id
                }
            }
        },

        houseNumberValues() {
            const context = this
            return context.formData.houseNumber.values
        },

        /**
         * Default form data to be used in this form.
         * @return object
         */
        defaultData() {
            return {
                user: {
                    value: {}
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                global: {
                    value: false
                },
                sport: {
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                league: {
                    data: true,
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                productionLeader: {
                    data: true,
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                country: {
                    data: true,
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                runType: {
                    data: true,
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                channel: {
                    data: true,
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                locationId: {
                    data: true,
                    value: null,
                    validator: {
                        rules: ''
                    }
                },
                data: {
                    value: ''
                },
                plan: {
                    data: true,
                    value: []
                },
                crew: {
                    data: true,
                    value: []
                },
                audioLayout: {
                    data: true,
                    value: ''
                },
                notes: {
                    data: true,
                    value: ''
                },
                contacts: {
                    data: true,
                    value: ''
                },
                equipment: {
                    data: true,
                    value: [],
                },
                productionType: {
                    data: true,
                    value: this.type,
                },
                itemType: {
                    value: this.type,
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                },
                comments: {
                    data: true,
                    value: {}
                },
                calendarNotesChanged: {
                    value: false
                },
                tvProduction: {
                    data: true,
                    value: '',
                    default: true
                },
                driverId: {
                    value: '',
                    data: true,
                },
                houseNumber: {
                    data: true,
                    value: this.defaultHouseNumberKeys,
                    values: this.defaultHouseNumbers,
                },
                isHouseNumberChanged: {
                    value: false
                }
            }
        },

        hasField(field) {
            return this.productionTypes[this.type].fields.includes(field);
        }

    }

}
