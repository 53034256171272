
// @TODO Find a better way to identify the test server.
let env = ''
if (window.location.host.indexOf('test.viaplaysportbooking.com') > -1) env = 'test';
if (window.location.host.indexOf('stage.viaplaysportbooking.com') > -1) env = 'staging';
if (env === '') env = process.env.NODE_ENV

const config = require('config/' + env)
export default Object.assign({
    ENV: env,
    PAGE_TITLE: 'VIAPLAY Sport Booking',
    GRANT_TYPE: 'password',
    FRONT_PAGE: '/calendar',
    LOGIN_PAGE: '/login',
    ROLE_ADMIN_NAME: 'admin',
    DAYS_INTERVAL: 1,
    DATEPICKER_OPTION: {
        type: 'day',
        week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        inputStyle: {},
        format: 'YYYY-MM-DD',
    },
    ITEM_STATUS: {
        PASSED: 1,
        SIGNED: 2,
        UNSIGNED_PRIORITY: 3,
        UNSIGNED: 4
    },
    ITEM_FLAG: {
        UPDATE_VISIBILITY: 28800, // seconds, 8 hours
        NEW_VISIBILITY: 86400 // seconds, 24 hours
    },
    VERSION: 'v2.9.5',
    TAG: 'a'
}, config)
