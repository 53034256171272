import {app} from 'app'
import User from 'components/user/User'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'
import System from 'components/system/System'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import BookLoader from 'components/common/loader/BookLoader'
import BookTable from 'components/common/BookTable'
import BookSelectbox from 'components/common/BookSelectbox'
import BookFeedAudioLayout from 'components/feed/BookFeedAudioLayout'
import BookLocalNotes from 'components/common/BookLocalNotes'
import BookNentCalendarNotes from 'components/common/BookNentCalendarNotes'

import ItemEditBaseMixin from 'components/common/item/ItemEditBaseMixin'

// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'

export default {
    mixins: [
        ItemEditBaseMixin
    ],
    components: {
        BookLoader,
        BookTable,
        BookSelectbox,
        multiselect,
        datepicker,
        maskedInput,
        BookFeedAudioLayout,
        BookLocalNotes,
        BookNentCalendarNotes
    },
    mounted() {
        this.loadForm()
            .then(response => {
                User.getUsers()
                .then(response => {
                    this.setLoaded()
                })
                .catch(error => {
                    this.$error.set(error)
                    this.setLoaded()
                })
            })
            .catch(error => {
                this.$error.set(error)
                this.setLoaded()
            })

    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loaded: false,
            production: null,
            formData: this.defaultData(),

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'productionTemplate',
                items: 'productionTemplates',
                settings: 'feeds',
                entities: {
                    resolutionEntity: 'resolution_entity_id',
                    frameRateEntity: 'framerate_entity_id',
                    aspectRatioEntity: 'aspectratio_entity_id',
                    systemFormatEntity: 'systemformat_entity_id',
                    deliveryTypeEntity: 'deliverytype_entity_id',
                    contentTypeEntity: 'contenttype_entity_id',
                    channelEntity: 'channel_entity_id',
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    streamingTypeEntity: 'streamingtype_entity_id',
                    polarizationEntity: 'polarization_entity_id',
                    modulationEntity: 'modulation_entity_id',
                    fecEntity: 'fec_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    fiberEntity: 'fiber_source_entityitem_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    satelliteEntity: 'satellite_entity_id',
                    // equipmentStatusEntity: 'equipmentstatus_entity_id',
                    // equipmentTypeEntity: 'equipmenttype_entity_id',
                    externalMCRsEntity: 'externalmcrs_entity_id',
                    activityTypesEntity: 'activitytypes_entity_id',
                    fiDMFieldsMappingEntity: 'fidmfieldsmapping_entity_id'
                }
            },
            activeTab: 'overview',
            type: 'feed',
        }
    },
    watch: {
        'formData.mcr.value': function(newValue, oldValue) {
            const context = this
            //let feedId = context.isClone ? context.$route.query.clone : context.id

            // If we compare with "oldValue" here, then this logic isn't triggered
            // on form load.
            if (context.formData.satEncryptionByCountry.value && newValue.length > context.formData.satEncryptionByCountry.value.length) {
                newValue.forEach(country => {
                    let i = context.formData.satEncryptionByCountry.value.findIndex(v => v.country.code == country.code)
                    if (i < 0) {
                        let countryObj = country.group == 0 ? country : 'other'
                        let notPresent = context.formData.satEncryptionByCountry.value.findIndex(v => v.country == 'other') < 0
                        if (notPresent || countryObj != 'other') context.formData.satEncryptionByCountry.value.push({valueType: 'encryption', country: countryObj, value: ''})
                    }

                    // if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {

                    // }
                })
            }

            else if (newValue.length < oldValue.length) {
                oldValue.forEach(item => {
                    let i = newValue.findIndex(v => item.code == v.code)
                    if (i < 0) {
                        let j = context.formData.satEncryptionByCountry.value.findIndex(v => {
                            return v.country.code == item.code && v.country != 'other'
                        })
                        if (j > -1) context.formData.satEncryptionByCountry.value.splice(j, 1)
                        else if (item.group > 0) {

                            // If no "group 1" country is available, remove the
                            // "other" field.
                            if (newValue.findIndex(v => v.group > 0) == -1) {
                                let a = context.formData.satEncryptionByCountry.value
                                .findIndex(v => v.country == 'other')
                                if (a > -1) context.formData.satEncryptionByCountry.value.splice(a, 1)
                            }

                        }
                    }
                })
            }

            if(context.satellites.findIndex(i => i.satItem.id === context.formData.satSatelliteId.value) === -1) {
                context.formData.satSatelliteId.value = ''
            }


        },
    },
    computed: {
        canUpdate() {
            return app.$ability.can('update_feed_templates', 'Feeds')
        },
        canUpdateGlobal() {
            return app.$ability.can('update_feed_global_templates', 'Feeds')
        },
        isGlobal() {
             return this.productionTemplate && this.productionTemplate.get && this.productionTemplate.get.global
        },
        processing() {
            return this.$loader.has('productionTemplateEdit_submit')
        },
        encryptionOtherCountriesPos() {
            return this.formData.satEncryptionByCountry.value.findIndex(v => v.country == 'other')
        },
        isChanged() {
            console.info("FeedTemplate has changed");
            if (!this.productionTemplate.get) return false
            const context = this
            let changed = false
            Lazy(context.formData)
                // .filter(v => {
                //     return !v.local
                // })
                .each((v, k) => {
                    if (changed) return true
                    switch (k) {
                        case 'sport':
                        case 'data':
                        case 'deliveryTypeData':
                        case 'user':
                        case 'localNotes':
                        case 'comments':
                            // Placeholder since these fields don't need to be checked.
                            break
                        case 'audioLayout':
                            if (context.productionTemplate.get.data && !context.productionTemplate.get.data[k] && !v.value) {
                                changed = false
                                break
                            }
                            if (context.productionTemplate.get.data && context.productionTemplate.get.data[k]) {
                                changed = v.value !== context.productionTemplate.get.data[k]
                            } else changed = false

                            break
                        case 'mcr':
                            v.value.forEach((v2, k2) => {

                                // If the MCR in the current interation isn't
                                // present in the original feed object, then
                                // the MCR field has been changed.
                                if (!changed && (!context.productionTemplate.get.data || (context.productionTemplate.get.data && context.productionTemplate.get.data[k].findIndex(item => item == v2.code) == -1))) {
                                    changed = true
                                }

                            })
                            break
                        case 'mcrExternal':
                            let newExtMCR = context.formData.mcrExternal.value.length > 0 ? context.formData.mcrExternal.value.map(i => i.id) : []
                            let oldExtMCR = context.productionTemplate.get.data && context.productionTemplate.get.data.mcrExternal ? context.productionTemplate.get.data.mcrExternal.split(',').map(c => parseInt(c)) : []
                            if(_.xor(newExtMCR, oldExtMCR).length > 0) {
                                changed = true
                            }
                            else {
                                changed = false
                            }
                            break
                        case 'deliveryType':
                        case 'contentType':
                            let cType = context.productionTemplate.get.data ? context.productionTemplate.get.data[k] : ''
                            if (!context.isEditForm) {
                                if (context.formFields[k] && context.formFields[k].touched) {

                                    changed = v.value != cType
                                }
                            }
                            else {
                                changed = v.value != cType
                            }
                            break
                        default:
                            const values = v.data ? (context.productionTemplate.get.data ? context.productionTemplate.get.data : undefined) :
                                          (v.deliveryTypeData ? (context.productionTemplate.get.data ? context.productionTemplate.get.data.deliveryTypeData : undefined) : context.productionTemplate.get)

                            if (k == 'satEncryptionByCountry') {
                                if (!context.isBissCode) {
                                    // Placeholder to prevent any manipulation
                                    // when "Biss Code Local" isn't selected.
                                }

                                else if (values) {
                                    v.value
                                        .forEach(item => {
                                            let countryCode = item.country.code ? item.country.code : item.country
                                            if (values.satEncryptionByCountry){
                                                let i = values.satEncryptionByCountry.findIndex(item => item.country === countryCode)
                                                if (i === -1) return
                                                if ((item.value || values.satEncryptionByCountry[i].value)
                                                    && (item.value !== values.satEncryptionByCountry[i].value)) {
                                                    changed = true
                                                }

                                            }

                                        })
                                }
                            }
                            else {
                                // The form value is an array and its length is not equal to
                                // the length of the original value, then it has been changed.
                                if (Array.isArray(v.value) && Array.isArray(values[k]) && (v.value.length != values[k].length)) {
                                    changed = true
                                }
                                else if (values && (v.value || values[k])) {
                                    changed = v.value != values[k]
                                }
                                else if(!values) {
                                    changed = v.value != null && v.value != ''
                                }
                            }

                            break
                    }
                })
            return changed
        },
        updateAble() {
            return this.canUpdate && !this.isGlobal || this.canUpdateGlobal && this.isGlobal
        },
        saveAble() {
            return this.isChanged && this.updateAble
        },
        saveButtonDisabled() {
            return !this.isFormValid || this.processing || !this.saveAble
        },
        isOwnTemplate() {
            return this.productionTemplate.get.user.id === app.$data.user.get.id
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        calendarNotes() {
            return this.productionTemplate.get.data && this.productionTemplate.get.data.comments ? JSON.parse(this.productionTemplate.get.data.comments) : null
        },
        currentDeliveryTypeLabel() {
            const context = this
            const deliveryType = context.deliveryTypeEntity.getItem(context.formData.deliveryType.value)
            if (!deliveryType) return null
            return deliveryType.label
        },

        satellites() {
            const context = this
            const satArr = []
            const satItems = context.satelliteEntity.get.items
            const mcrs = context.formData.mcr.value
            satItems.forEach(satItem => {
                let satCountries = satItem.description.split(',')
                let satCountriesFiltered = satCountries.length > 0 ? satCountries.filter(c => mcrs.some(m => m.code === c)) : []
                if(satCountriesFiltered.length > 0) {
                    let missedMCRs = []
                    if(mcrs.length !== satCountriesFiltered.length) {
                        missedMCRs = mcrs.filter(m => !satCountriesFiltered.some(s=> m.code === s)).map(m => m.label)
                    }
                    satArr.push({'satItem': satItem, 'warn': mcrs.length !== satCountriesFiltered.length, 'missedMCRs': missedMCRs.join(', ') })
                }
            })
            return satArr
        },
        externalMCRs() {
            return this.externalMCRsEntity && this.externalMCRsEntity.get && this.externalMCRsEntity.get.items ? this.externalMCRsEntity.get.items : []
        },
        showSatelliteWarning(){
            let warn = false
            let warnMessage = ""
            const context = this
            if(context.formData.satSatelliteId.value && context.formData.satSatelliteId.value !== '') {
                let currentSelectedSatellite = context.satellites.find(v => v.satItem.id === context.formData.satSatelliteId.value)
                if(currentSelectedSatellite !== undefined && currentSelectedSatellite.warn) {
                    warn = true
                    warnMessage = `This satellite is not available for ${currentSelectedSatellite.missedMCRs}`
                }
                //warn = currentSelectedSatellite ? currentSelectedSatellite.warn : true
                //missedMCRs = currentSelectedSatellite ? currentSelectedSatellite.missedMCRs : ""
            }

            return { 'warn': warn, 'warnMessage': warnMessage}
        },
        chosenDeliveryType() {
            const deliveryType = this.deliveryTypeEntity.getItem(this.formData.deliveryType.value)
            return deliveryType ? deliveryType.label.toLowerCase() : ''
        },
        bissCodeIsSelected() {
            let values = ['biss_code', 'biss_code_shared']
            return values.indexOf(this.formData.satEncryption.value) > -1
        },
        isBissCode() {
            return this.formData.satEncryption.value == 'biss_code'
        },
        isBissCodeShared() {
            return this.formData.satEncryption.value == 'biss_code_shared'
        },
    },
    methods: {
        /**
         * Check if the passed tab name is the current active tab.
         */
         isActiveTab(name) {
            return this.activeTab == name
        },
        /**
         * Save the form.
         */
         submit(open = false, redirect = true) {
            const context = this
            context.$loader.add('productionTemplateEdit_submit')
            Lazy(context.formData)
                .filter(v => !v.local && !v.data && !v.deliveryTypeData)
                .each(function(v, k) {
                    if(k === 'filterType') {
                        context.productionTemplate.set(k, context.formData.deliveryType.value)
                    }
                    else if(k === 'filterLeague'){
                        context.productionTemplate.set(k, context.formData.league.value)
                    }
                    else {
                        context.productionTemplate.set(k, v.value)
                    }

                })

            const deliveryTypeData = {}
            Lazy(context.formData)
                .filter(v => v.deliveryTypeData)
                .each((v, k) => {
                    if(k === 'satEncryptionByCountry') {
                        if(context.isBissCode) {
                            let enc = []
                            if(context.formData[k].value && context.formData[k].value.length > 0) {
                                context.formData[k].value.forEach(i => enc.push({
                                    country: i.country.code,
                                    value: i.value,
                                    valueType: 'encryption'
                                }))
                            }
                            deliveryTypeData[k] = enc
                        }

                    }
                    else {
                        deliveryTypeData[k] = v.value
                    }

                })
            context.formData.deliveryTypeData.value = deliveryTypeData

            const data = {}
            Lazy(context.formData)
                .filter(v => v.data)
                .each((v, k) => {
                    if(k === 'mcr') {
                        let mcrs = []
                        context.formData[k].value.forEach(i => mcrs.push(i.code))
                        data[k] = mcrs
                    }
                    else if(k === 'mcrExternal') {
                        let exts = []
                        if(context.formData[k].value && context.formData[k].value.length > 0) {
                            context.formData[k].value.forEach(i => exts.push(i.id))
                        }
                        data[k] = exts.join(',')
                    }
                    else if(k === 'localNotes') {
                        let locNotes = []
                        if(context.formData[k].value && context.formData[k].value.length > 0) {
                            context.formData[k].value.forEach(i => locNotes.push({
                                valueType: i.valueType,
                                country: i.country,
                                value: i.value
                            }))
                        }
                        data[k] = locNotes
                    } else if (k === 'comments') {
                        let calendarNotes = context.formData[k].value;
                        if (calendarNotes) {
                            data[k] = context.cleanObjectString(calendarNotes);
                        }
                    } else {
                        data[k] = v.value
                    }

                })
            context.productionTemplate.set('data', data)

            if (context.isEditForm) {
                context.productionTemplate.save()
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`Feed template ${context.id} was saved!`, 'success', 3)
                        Vue.set(context.$data, 'formData', context.defaultData())
                        if (redirect) context.redirect(open)
                        else context.setLoaded()
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the Production template.')
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
            }
            else {

                // When creating a new Production Template, a redirect should
                // always be done, back to the list or to the "edit form".
                redirect = true

                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('productionTemplateEdit_submit')
                }

                context.productionTemplate.store()
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`Feed template ${response.data.id} has been created!`, 'success', 3)

                        // Reset formData if "Save & Close" button has been clicked.
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())
                        else context.productionTemplate = new ProductionTemplate(response.data.id)

                        if (redirect) {
                            context.redirect(open, response.data.id)
                            .then(response => {
                               if (open) {
                                  context.setLoaded()
                               }
                            })
                            .catch(error => {
                                if (open) {
                                   context.setLoaded()
                                }
                            })
                        }
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the feed.')
                        context.$loader.remove('productionTemplateEdit_submit')
                    })
            }
        },

        /**
         * Set default values on "Add production" and current data on "edit".
         */
        setFormValues() {
            const context = this
            if (context.isEditForm) {
                Lazy(context.formData)
                .filter(v => !v.local && !v.data && !v.deliveryTypeData)
                .each(function(v, k) {
                    switch (k) {
                        case 'sport':
                            const league = context.productionTemplate.get.data.league
                            if (league) {
                                const entityItem = context.leagueEntity.getItem(league)
                                v.value = entityItem.reference
                            }
                            break

                        default:

                            // We need to detect if the value is array/object or
                            // another type, so we can make a real copy of the
                            // value and not create a pointer.
                            if (typeof context.productionTemplate.get[k] == 'object') {
                                if (Array.isArray(context.productionTemplate.get[k])) {
                                    v.value = context.productionTemplate.get[k].slice()
                                }
                                else {
                                    Object.assign(v.value, context.productionTemplate.get[k])
                                }
                            }
                            else {
                                v.value = context.productionTemplate.get[k]
                            }

                            break
                    }
                })
                Lazy(context.productionTemplate.get.data)
                    .each((v, k) => {
                        if(k === 'mcr') {
                            context.formData[k].value = []


                            const mcr = v
                            if (mcr) {
                                context.countries.forEach(item => {
                                    if (mcr.indexOf(item.code) > -1) {
                                        context.formData[k].value.push(item)
                                    }
                                })

                                // Since satEncryptionByCountry is populated according
                                // to the values of MCR we need to "delay" the
                                // population of satEncryptionByCountry to the
                                // next DOM update, otherwise it will be empty
                                // when trying to update its values.
                                Vue.nextTick(function() {
                                    if (context.productionTemplate.get.data.deliveryTypeData && context.productionTemplate.get.data.deliveryTypeData.satEncryptionByCountry) {
                                        //console.log(feed.get.deliveryTypeData.satEncryptionByCountry);
                                        // Set country values, without "other countries".
                                        context.productionTemplate.get.data.deliveryTypeData.satEncryptionByCountry
                                            .forEach(item => {
                                                context.formData.satEncryptionByCountry.value
                                                    .filter(item2 => item2.country != 'other')
                                                    .forEach(item2 => {
                                                        if (item2.country.code == item.country && item2.valueType == item.valueType) {
                                                            item2.value = item.value
                                                        }
                                                    })
                                            })

                                        // Set "other countries" value, if available.
                                        let i = context.productionTemplate.get.data.deliveryTypeData.satEncryptionByCountry.findIndex(v => v.country == 'other')
                                        let j = context.formData.satEncryptionByCountry.value.findIndex(v => v.country == 'other')
                                        if ((i > -1) && (j > -1)) {
                                            context.formData.satEncryptionByCountry.value[j].value = context.productionTemplate.get.data.deliveryTypeData.satEncryptionByCountry[i].value
                                        }

                                    }
                                })

                            }
                        }
                        else if(k === 'mcrExternal') {
                            context.formData[k].value = []
                            if(v) {
                                let extMCRsArr = v.split(',')
                                if(extMCRsArr && extMCRsArr.length > 0 ) {
                                    if(!isNaN(parseInt(extMCRsArr[0]))) {
                                        extMCRsArr.forEach(e => {
                                            let eMCR = _.find(context.externalMCRs, m => m.id === parseInt(e))
                                            if(eMCR) {
                                                context.formData[k].value.push(eMCR)
                                            }
                                        })
                                    }
                                }

                            }
                        }
                        else if(k != 'deliveryTypeData') {
                            context.formData[k].value = v || ''
                        }
                        else {
                            Lazy(v)
                                .each((v2, k2) => {
                                    if(k2 !== 'satEncryptionByCountry') {
                                        context.formData[k2].value = v2 || ''
                                    }

                                })
                        }

                    })
            }
            else {
                let defaultValue = null
                const entities = ['deliveryType', 'contentType']

                // Try to set default values for entity field.
                entities.forEach(v => {
                    defaultValue = context[`${v}Entity`].get.items.filter(item => item.default)
                    if (defaultValue.length > 0) {
                        context.formData[v].value = defaultValue[0].id
                    }
                })

                context.formData.user.value = {
                    id: app.$data.user.get.id
                }
            }
        },
        /**
         * Default form data to be used in this form.
         * @return object
         */
         defaultData() {
            return {
                user: {
                    value: {}
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                global: {
                    value: false
                },
                sport: {
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                league: {
                    data: true,
                    value: '',
                    validator: {
                        rules: ''
                    }
                },
                mcr: {
                    data: true,
                    value: []
                },
                mcrExternal: {
                    data: true,
                    value: []
                },
                deliveryType: {
                    data: true,
                    value:''
                },
                contentType: {
                    data: true,
                    value: ''
                },
                data: {
                    value: ''
                },
                deliveryTypeData: {
                    data: true,
                    value: ''
                },
                downloadInfo: {
                    deliveryTypeData: true,
                    value: '',
                },
                fiberInfo: {
                    deliveryTypeData: true,
                    value: '',
                },
                streamingType: {
                    deliveryTypeData: true,
                    value: '',
                },
                streamingInfo: {
                    deliveryTypeData: true,
                    value: '',
                },
                satInfo: {
                    deliveryTypeData: true,
                    value: '',
                },
                satSatelliteId: {
                    deliveryTypeData: true,
                    value: '',
                },
                satFrq: {
                    deliveryTypeData: true,
                    value: '',
                    validator: {
                        regex: /(^\d{5}$)|(^\d{5}\.[0-9]+$)/
                    },
                },
                satPolarization: {
                    deliveryTypeData: true,
                    value: '',
                },
                satModulation: {
                    deliveryTypeData: true,
                    value: '',
                },
                satTransponderChannel: {
                    deliveryTypeData: true,
                    value: '',
                },
                satService: {
                    deliveryTypeData: true,
                    value: '',
                },
                satSymbolRate: {
                    deliveryTypeData: true,
                    value: '',
                    validator: {
                        regex: /^\d(\d)?$|^\d(\d)?\.\d+$/
                    },
                },
                satFec: {
                    deliveryTypeData: true,
                    value: '',
                },
                satEncryption: {
                    deliveryTypeData: true,
                    value: '',
                },
                satEncryptionBissCodeShared: {
                    deliveryTypeData: true,
                    value: '',
                },
                satEncryptionByCountry: {
                    deliveryTypeData: true,
                    value: [],
                },
                resolution: {
                    data: true,
                    value: '',
                },
                frameRate: {
                    data: true,
                    value: '',
                },
                aspectRatio: {
                    data: true,
                    value: '',
                },
                systemFormat: {
                    data: true,
                    value: '',
                },
                audioLayout: {
                    data: true,
                    value: ''
                },
                notes: {
                    data: true,
                    value: ''
                },
                localNotes: {
                    data: true,
                    value: []
                },
                contacts: {
                    data: true,
                    value: ''
                },
                itemType: {
                    value: 'feed'
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                },
                localNotesChanged: {
                    local: true,
                    value: false
                },
                comments: {
                    data: true,
                    value: {}
                },
                calendarNotesChanged: {
                    local: true,
                    value: false
                },
            }
        },
        handleFrqInputPast(clipBoardValue) {
            if(!isNaN(parseInt(clipBoardValue))) {
                this.formData.satFrq.value = clipBoardValue
            }
            else {


                let frqExp = /\b\d{2}([,.])?\d{3}[,.][0-9]+\b/g
                //let clipBoardValue = event.clipboardData.getData('text')
                let frqValue = clipBoardValue.match(frqExp)
                if(frqValue && frqValue.length > 0) {
                    let val = frqValue[0]
                    if(val.search(/^\d{2}\,\d{3}\.\d+$/g) !== -1) {
                        val = val.replace(",", "")
                    }

                    if(val.search(/^\d{2}\.\d{3}\,\d+$/g) !== -1) {
                        val = val.replace(".", "")
                    }

                    if(val.search(/^\d{5}\,\d+$/) !== -1) {
                        val = val.replace(",", ".")
                    }

                    this.formData.satFrq.value = val
                }
                else {
                    this.formData.satFrq.value = ''
                }
            }

        },
        handleSymbolRatePast(clipBoardValue) {
            let smbExp = /\b\d(\d)?[,.][0-9]+\b/g
            //let clipBoardValue = event.clipboardData.getData('text')
            let smbValue = clipBoardValue.match(smbExp)
            if(smbValue && smbValue.length > 0) {
                let val = smbValue[0]
                val = val.replace(",", ".")
                this.formData.satSymbolRate.value = val
            }
            else {
                this.formData.satSymbolRate.value = ''
            }
        },
    }
}