import Api from 'components/app/Api'
import {app} from 'app'
import store from 'store'
class Feedback extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/feedback',
            module: 'feedback',
        })
    }

    static getFeedbackItem(itemType, itemId) {
        return new Promise((resolve, reject) => {
            axios.get(`api/${itemType}/${itemId}/feedback`)
                .then(response => {
                    store.commit('add', {key: itemType, data: response.data})

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
    }

    static isVisible(feedback, status = false) {
        // if (!equipment.country || equipment.notCountryRestricted) {
        //     return true
        // }
        if (status) return true
        return feedback.author.country == app.$data.user.get.country//(equipment.country == app.$data.user.get.country)
    }
}
export default Feedback