<script>
/**
 * @file
 * Book SelectDropdown component.
 * Builds a "dropdown" menu with checkboxes.
 */

export default {
    props: {
        componentId: {
            default: 'field',
            type: String
        },
        label: {
            default: 'Menu',
            type: String
        },
        direction: {
            default: 'down',
            type: String
        },
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        inPlace: {
            default: false,
            type: Boolean
        },
        alwaysVisible: {
            default: false,
            type: Boolean
        },
        title:{
            default: '',
            type: String
        },
        displayLabel:{
            default: false,
            type: Boolean
        },
        
    },
    watch: {
        items: {
            handler: function(newValue) {
                this.processItems()
            },
            deep: true
        }
    },
    data() {
        return {
            currentItems: null,
        }
    },
    computed: {
        getItems() {
            return this.currentItems
        },
        hasFilterItems() {
            return this.getItems && this.getItems.length > 0
        },
        hasSelectedItems() {
            return this.getItems.filter(v => v.selected).length
        }
    },
    mounted() {
        this.processItems()
    },
    methods: {

        /**
         * Stop the propagation of click event.
         * This is used to prevent the dropdown menu from closing.
         */
        stopPropagation(e) {
            e.stopPropagation()
        },

        /**
         * Emit event on item click.
         */
        clickOnItem(item) {
            if (item.selected){
                item.selected = false;
            }else{
                item.selected = true;
            }
            this.emitValue()
        },

        /**
         * Process the passed items.
         */
        processItems() {
            this.currentItems = JSON.parse(JSON.stringify(this.items))
            this.currentItems.forEach(v => {
                if (!v.selected) {
                    Vue.set(v, 'selected', false)
                }
            })
        },

        /**
         * Unselect all items.
         */
        clear() {
            this.getItems.forEach(v => {
                Vue.set(v, 'selected', false)
            })
            Vue.nextTick(() => {
                this.emitValue()
            })
        },
        selectAll() {
            //console.log('selecting all')
            this.getItems.forEach(v => {
                Vue.set(v, 'selected', true)
            })
            Vue.nextTick(() => {
                this.emitValue()
            })
        },

        /**
         * Emit the current items/filters set to the parent component.
         */
        emitValue() {
            Vue.nextTick(() => {
                this.$emit('selectedItems', this.getItems.slice(0))
            })
        }

    }
}
</script>

<template>
<div>
    <div class="row" v-if="displayLabel">
        <div class="col-md-9"><label class="control-label">{{label}}</label></div>
        <div class="col-md-3 clean-list select-all-icon-a"><font-awesome-icon icon="check" class="clean-button" @click="selectAll()"/></div>
        <div class="col-md-3 clean-list"><font-awesome-icon icon="eraser" class="clean-button" @click="clear()"/></div>
    </div>
    
    <div :id="'book-select-dropdown-' + componentId" class="book-select-dropdown clearfix" :class="['drop' + direction, {'book-select-dropdown-inplace': inPlace}, {'book-select-dropdown-always-visible': alwaysVisible}]">
        <button v-if="!alwaysVisible" class="btn btn-default dropdown-toggle" type="button" :title="!hasFilterItems ? 'No items are available' : ''" :id="'dropdown-menu-' + componentId" :disabled="!hasFilterItems" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            {{label}} <span v-if="hasFilterItems && hasSelectedItems > 0" class="badge">{{hasSelectedItems}}</span>
            <span class="caret" v-if="hasFilterItems"></span>
        </button>
        <ul class="dropdown-menu" :aria-labelledby="'dropdown-menu-' + componentId" v-if="hasFilterItems">
            <li v-if="!displayLabel" class="dropdown-item dropdown-item-clear">
                <a class="book-select-dropdown-clear text-right select-all-icon" @click="selectAll()"><font-awesome-icon icon="check" class="clean-button"/></a>
                <a class="book-select-dropdown-clear text-right" style="padding-right: 23px !important;" @click="clear()"><font-awesome-icon icon="eraser" class="clean-button"/></a>
                
                </li>
            <li v-for="item in getItems" :key="item.id" class="dropdown-item" :class="'dropdown-' + item.value" @click="stopPropagation($event)">
                <label>
                    <input type="checkbox" v-model="item.selected" @click="clickOnItem(item)">{{item.label}}
                </label>
            </li>
        </ul>
    </div>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.clean-list{
    text-align: right;
    
    .clean-button, select-all-button{
        cursor: pointer;
    }
}
.select-all-icon {
    position: absolute;
    top: 6px;
    right: 33px;
}
.select-all-icon-a {
    top: 18px;
    right: 8px;
    margin-top: -18px;
    cursor: pointer;
    position: absolute !important;
    z-index: 1000;
}

.book-select-dropdown {
    &.book-select-dropdown-inplace {
        .dropdown-menu {
            box-shadow: none;
            float: none;
            position: relative;
        }
    }
    &.book-select-dropdown-always-visible {
        .dropdown-menu {
            display: block !important;
            max-height: 214px;
        }
    }
    .dropdown-menu {
        max-height: 514px;
        overflow-y: auto;
        .divider {
           margin: 3px 0;
        }
    }
    .dropdown-toggle {
        &:disabled {
            cursor: not-allowed;
        }
    }
    .dropdown-item {
        color: $color-text;
        &:hover {
            background: $color-item-hover;
            color: $color-menu-text-hover;
        }
        label,
        .book-select-dropdown-clear,
        input[type="radio"],
        input[type="checkbox"] {
            cursor: pointer;
        }
        label,
        .book-select-dropdown-clear {
            padding: 3px 10px 4px;
        }
        .book-select-dropdown-clear {
            font-weight: 600;
        }
        label {
            font-weight: 400;
            display: block;
            margin-bottom: 0;
            padding: 3px 10px 4px;
        }
        input[type="radio"],
        input[type="checkbox"] {
            margin-right: 10px;
            vertical-align: calc(1px);
        }
    }
}
</style>
