<script>
import {app} from 'app'
import Auth from 'components/auth/Auth'
import User from 'components/user/User'
import System from 'components/system/System'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookLoader
    },
    mounted() {

        // If on "reset" page, check the passed token.
        if (this.$route.name == 'reset') {
            this.$loader.add('BookLoginLoading')
            if (!this.$route.query.token) {
                this.$alert.set('No password reset token was provided.', 'danger', 10)
                this.$router.push(context.$store.state.config.LOGIN_PAGE)
                this.$loader.remove('BookLoginLoading')
            }
            else {
                Auth.checkResetToken(this.$route.query.token)
                    .then(response => {
                        if (response.status == 200) {
                            this.passwordResetData = response.data
                        }
                        this.$loader.remove('BookLoginLoading')
                    })
                    .catch(error => {
                        this.$error.set(error)
                        this.$router.push(this.$store.state.config.LOGIN_PAGE)
                        this.$loader.remove('BookLoginLoading')
                    })
            }
        }

    },
    data() {
        return {
            credentials: {
                email: '',
                password: ''
            },
            error: '',
            passwordResetData: null,
            password: this.defaultPasswordResetData()
        }
    },
    computed: {
        isFormValid() {
            return this.formFields && Object.keys(this.formFields)
                .every(k => {
                    if (!this.formFields[k].validated)
                        return true;
                    return this.formFields[k].valid
                })
        },
        loading() {
            return this.$loader.has('BookLoginLoading')
        }
    },
    methods: {

        /**
         * Perform a log in.
         */
        submit() {
            this.$alert.clear()
            let context = this;
            this.$loader.add('BookLoginLoading')
            let credentials = {
                username: this.credentials.email.toLowerCase(),
                password: this.credentials.password,
            }

            // Make the authentication.
            Auth.login(credentials)
                .then(response => {
                    if (response.status === 200) {

                        // Now, load the application (including user data).
                        System.load()
                            .then(resources => {
                                let failed = false
                                Lazy(resources)
                                .each(function(v) {
                                    if (v.status !== 200) {
                                        failed = true
                                    }
                                })
                                if (!failed) {
                                    this.$router.push(context.$store.state.config.FRONT_PAGE)
                                }
                                else {
                                    this.$alert.set('It was not possible to log in... Please, try again later.', 'danger', 0)
                                }
                                context.$loader.remove('BookLoginLoading')
                            })
                            .catch(error => {
                                context.$error.set(error)
                                context.$loader.remove('BookLoginLoading')
                            })

                    }
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to log in. Please, contact the administrator.')
                    context.$loader.remove('BookLoginLoading')
                })

        },

        /**
         * Perform a password reset.
         */
        createPasswordReset() {
            this.$alert.clear()
            this.$loader.add('BookLoginLoading')
            Auth.createPasswordReset({email: this.credentials.email})
                .then(response => {
                    this.$loader.remove('BookLoginLoading')
                    this.$alert.set(response.data.message, 'success', 0)
                    setTimeout(() => {
                        this.$router.push(this.$store.state.config.LOGIN_PAGE)
                    }, 400)
                })
                .catch(error => {
                    this.$error.set(error)
                    this.$loader.remove('BookLoginLoading')
                })
        },

        /**
         * Create a new password on the backend, based on the current token.
         */
        resetPassword() {
            if (!this.isFormValid) return
            this.$loader.add('BookLoginLoading')
            const credentials = {
                email: this.passwordResetData.email,
                token: this.passwordResetData.token,
                password: this.password.new.value,
                passwordConfirmation: this.password.confirmation.value
            }
            Auth.resetPassword(credentials)
                .then(response => {
                    this.$loader.remove('BookLoginLoading')
                    this.$alert.set(response.data.message, 'success', 10)
                    setTimeout(() => {
                        this.$router.push(this.$store.state.config.LOGIN_PAGE)
                    }, 400)
                })
                .catch(error => {
                    this.$error.set(error)
                    this.$loader.remove('BookLoginLoading')
                })
        },

        /**
         * Default data for password reset form.
         */
        defaultPasswordResetData() {
            return {
                new: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            password: true,
                            min: 6
                        }
                    }
                },
                confirmation: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            min: 6,
                            confirmed: 'password_new'
                        }
                    }
                }
            }
        }

    }
}
</script>

<template>
<div id="login">
    <div class="login-container">
        <div class="book-logo">
            <img src="~assets/viaplay_login_logo.png" />
        </div>
        <h2 class="book-sitename">Sport Booking</h2>
        <book-loader :small="true"></book-loader>

        <!-- Login screen -->
        <template v-if="$route.name == 'login'">
            <div class="panel panel-default book-content animate" key="login" :class="{'book-loading': loading}" @keyup.enter="loading || !isFormValid ? '' : submit()" >
                <div class="panel-body form-horizontal">
                    <div class="form-group" :class="{'has-error': formErrors.has('email')}">
                        <label for="user-name" class="control-label book-width-80">
                            E-mail
                        </label>
                        <div class="form-control-80">
                            <input v-focus v-validate="'required|email'" name="email" type="email" class="form-control" placeholder="Enter your e-mail" v-model="credentials.email" :disabled="loading">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="user-name" class="control-label book-width-80">
                            Password
                        </label>
                        <div class="form-control-80">
                            <input v-validate="'required'" name="password" type="password" class="form-control" placeholder="Enter your password" v-model="credentials.password" :disabled="loading">
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" @click="submit()" :disabled="loading || !isFormValid">Log in</button>
                    </div>
                </div>
            </div>
            <div class="book-link-wrapper">
                <span v-if="$store.state.config.ENV !== 'production'" v-html="$store.state.config.VERSION + ' - ' + $store.state.config.ENV.toUpperCase()"></span>
                <span v-else v-html="$store.state.config.VERSION"></span>
                <router-link :to="{name: 'password'}">Forgot password?</router-link>
            </div>
        </template>

        <!-- Reset password screen -->
        <template v-else-if="$route.name == 'password'">
            <div class="panel panel-default book-content animate" key="password_reset" :class="{'book-loading': loading}" @keyup.enter="loading || !isFormValid ? '' : createPasswordReset()">
                <div class="panel-body form-horizontal">
                    <div class="form-group" :class="{'has-error': formErrors.has('email')}">
                        <label for="user-name" class="control-label book-width-80">
                            E-mail
                        </label>
                        <div class="form-control-80">
                            <input v-focus v-validate="'required|email'" name="email_reset" type="email" class="form-control" placeholder="Enter your e-mail" v-model="credentials.email" :disabled="loading">
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" @click="createPasswordReset()" :disabled="loading || !isFormValid">Reset</button>
                    </div>
                </div>
            </div>
            <div class="book-link-wrapper">
                <router-link :to="{name: 'login'}">Back to login</router-link>
            </div>
        </template>

        <!-- Create new password screen -->
        <template v-else-if="$route.name == 'reset'">
            <h4 class="text-center" v-if="loading && !passwordResetData">Checking reset link...</h4>
            <div v-else class="panel panel-default book-content book-token-login animate" :class="{'book-loading': loading}" @keyup.enter="loading || !isFormValid ? '' : resetPassword()">
                <div class="panel-body form-horizontal" v-if="passwordResetData">
                    <h3 class="text-center bg-success">The link is valid</h3>
                    <h4 class="text-center">Please, create a new password</h4>
                    <hr />
                    <div class="form-group">
                        <label for="user-password" class="control-label control-label-120">
                            E-mail
                        </label>
                        <div class="form-control-120">
                            <p class="form-control-static">{{passwordResetData.email}}</p>
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': formErrors.has('password_new')}">
                        <label for="user-password" class="control-label control-label-120">
                            New password
                        </label>
                        <div class="form-control-120">
                            <input v-focus v-validate="password.new.validator" id="password-new" name="password_new" type="password" class="form-control" placeholder="Enter the new password" v-model.trim="password.new.value" data-lpignore="true" ref="password_new">
                            <p class="help-block" v-if="formErrors.has('password_new')">The password should contain at least 6 characters, 1 digit and 1 special character.</p>
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': formErrors.has('password_confirmation')}">
                        <label for="user-password" class="control-label control-label-120">
                            Confirm password
                        </label>
                        <div class="form-control-120">
                            <input v-validate="password.confirmation.validator" id="password-confirmation" name="password_confirmation" type="password" class="form-control" placeholder="Enter the password again" v-model.trim="password.confirmation.value" data-lpignore="true" data-vv-as="password_new">
                            <p class="help-block" v-if="formErrors.has('password_confirmation')">The field doesn't match the password above.</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" @click="resetPassword()" :disabled="loading || !isFormValid">Create password</button>
                    </div>
                </div>
            </div>
        </template>

    </div>
</div>
</template>

<style lang="scss">
#login {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    .login-container {
        margin-top: -100px;
        max-width: 400px;
        width: 90%;
    }
    .book-logo {
        margin: 0 auto;
        text-align: center;
        width: 200px;
        img {
            display: inline-block;
        }
    }
    .book-sitename {
      text-align: center;
      margin: -7px 0 25px;
    }
    .book-loader {
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
    }
    .book-content {
        margin-bottom: 0;
        margin-top: -45px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        &.book-loading {
            margin-top: 0;
        }
    }
    .book-link-wrapper {
        display: block;
        margin-top: 10px;
        text-align: right;
        span{
            float: left;
        }
        a {
            cursor: pointer;
        }
    }
    .book-token-login {
        h3,
        h4 {
            line-height: 1.4;
            margin-bottom: 20px;
        }
        h3 {
          margin-top: 0;
        }
        h4 {
            &:first-child,
            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
