
import Api from 'components/app/Api'

class McrComment extends Api {

    /**
     * Constructor.
     * @param id
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/mcr-comment',
            module: 'mcrComment',
        })
    }

}

export default McrComment
