<script>

// Vendors.
import { Validator } from 'vee-validate';

export default {
    props: {
        componentId: {
            default: 'field',
            type: String
        },
        defaultValue: {
            default: '',
            type: String
        },
    },
    data() {
        return {
            phoneCode: {
                value: '',
                validator: 'numeric|min:1|max:3'
            },
            phonePartial: {
                value: '',
                validator: 'numeric|min:3'
            },
            phone: {
                value: ''
            }
        }
    },
    computed: {
        isValid() {
            if ((this.phoneCode.value == '') || (this.phonePartial.value == '')) {
                return false
            }
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].valid
            })
        },
        isClean() {
            return Object.keys(this.formFields).every(k => {
                return !this.formFields[k].dirty
            })
        },
    },
    watch: {
        'phoneCode.value': function(newValue) {
            this.$validator.validate('book_phone_code', newValue)
                .then(validated => {
                    this.setValue()
                    if (validated) this.$validator.validate('book_phone_partial')
                })
        },
        'phonePartial.value': function(newValue) {
            this.$validator.validate('book_phone_partial', newValue)
                .then(validated => {
                    this.setValue()
                    if (validated) this.$validator.validate('book_phone_code')
                })
        },
    },
    mounted() {
        this.setDefaultValue()
    },
    methods: {
        setDefaultValue() {
            const context = this
            if (!context.defaultValue) return
            context.phone.value = context.defaultValue
            const defaultValue = context.defaultValue.split('-')
            this.phoneCode.value = defaultValue[0].replace('+', '')
            this.phonePartial.value = defaultValue[1]
        },
        setValue() {
            const context = this
            if (context.isValid) context.phone.value = `+${context.phoneCode.value}-${context.phonePartial.value}`
            else context.phone.value = ''
            context.$emit('newValue', context.phone.value)
        }
    }
}
</script>

<template>
<div :id="'book-phone-' + componentId" class="book-phone clearfix">
    <div class="book-phone-wrapper">
        <span class="book-phone-plus-sign">+</span>
        <div class="book-phone-code" :class="{'has-error': formErrors.has('book_phone_code')}">
            <input v-validate="phoneCode.validator" name="book_phone_code" type="number" class="form-control" v-model="phoneCode.value" title="Country code" placeholder="Code" data-lpignore="true">
        </div>
        <div class="book-phone-partial" :class="{'has-error': formErrors.has('book_phone_partial')}">
            <input v-validate="phonePartial.validator" name="book_phone_partial" type="number" class="form-control" v-model="phonePartial.value" title="Phone without country code" placeholder="Phone without country code" data-lpignore="true">
        </div>
    </div>
    <p class="help-block" v-if="formFields.book_phone_code && formFields.book_phone_code.dirty && formFields.book_phone_code.invalid">The country code is invalid. Only numbers are allowed.</p>
    <p class="help-block" v-if="formFields.book_phone_partial && formFields.book_phone_partial.dirty && formFields.book_phone_partial.invalid">The phone number is invalid. Only numbers are allowed.</p>
</div>
</template>

<style lang="scss">
.book-phone {
    .book-phone-wrapper {
        align-items: center;
        display: flex;
    }
    .book-phone-plus-sign,
    .book-phone-code {
        margin-right: 5px;
    }
    .book-phone-code {
        width: 60px !important;
    }
    .book-phone-partial {
        flex: 1;
    }
}
</style>
