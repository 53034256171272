import Vue from 'vue'

// v-focus.
Vue.directive('focus', {
    inserted: function (el) {
        window.requestAnimationFrame(() => {
            el.focus()
        })
    }
})
