<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import McrStatus from 'components/mcr/McrStatus/McrStatus'
import Mcrdata from 'components/mcr/Mcrdata'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import EquipmentUsage from 'components/equipment/EquipmentUsage'
import System from 'components/system/System'
import Entity from 'components/entity/Entity'

export default {
    components: {
        BookLoader,
    },
    props: {
        items: {
            default: () => [],
            type: Array
        },
        itemId: {
            default: 0,
            type: Number
        },
        itemType: {
            default: '',
            type: String
        },
        extraData: {
            default: () => ({}),
            type: Object
        },
        hideStatus: {
            default: () => [],
            type: Array
        },
    },
    data() {
        return {
            loaded: false,
            itemClass: null,
            statusClass: null,
            channelEntity: null,
            country: [],
            expanded: false,
            processedItems: JSON.parse(JSON.stringify(this.items))
        }
    },
    watch: {
        'items': function(newValue) {
            this.processedItems = JSON.parse(JSON.stringify(newValue))
            this.parseItems()
        }
    },
    mounted() {

        //@TODO For performance updgrade, you need this operation start after click of mcrStatus change

        this.parseItems()
        // Determine the item id and type. Note that for "Contributions" we need
        // to get the id/type for its parent.
        let itemId = (this.itemType === 'equipmentUsage') ? (this.extraData.itemId ? this.extraData.itemId : this.itemType): this.itemId
        let itemType = (this.itemType === 'equipmentUsage') ? (this.extraData.itemType ? this.extraData.itemType : this.itemType) : this.itemType

        switch (itemType) {
            case 'feed':
                this.itemClass = new Mcrdata(itemId)
                this.country = this.itemClass.get.mcr.slice(0)
                this.setLoaded()
                break
            case 'production':
                this.itemClass = new Mcrdata(itemId)
                this.channelEntity = new Entity(this.setting.channel_entity_id)
                if (!this.channelEntity.get.label) {
                    this.$alert.set('Channel entity hasn\'t been loaded. The MCR Status won\'t work properly.', 'danger', 10)
                }
                else {

                    // Set the component country to the same as the production channel.
                    const channel = this.channelEntity.getItem(this.itemClass.get.channel)
                    if (channel) {
                        this.country = channel.country
                    }

                }
                this.setLoaded()
                break
            case 'equipmentUsage':
                Lazy(this.processedItems)
                    .each(v => {
                        if (v.item_id === this.itemId) {
                            //console.log(v, "country selected");
                            this.country = v.country
                        }
                    })
                this.setLoaded()
                break
        }

    },
    computed: {
        setting() {
            let type = (this.itemType == 'equipmentUsage') ? this.extraData.itemType : this.itemType

            // The settings name is "plural".
            return this.$settings.get[`${type}s`]

        },
        processingId() {
            return `BookMcrStatusProcessing_${this.itemId}_${this.itemType}`
        },
        processing() {
            return this.$loader.has(this.processingId)
        },
        isDisabled() {
            return (this.country.length == 0)
            //SMTGBD-717 each country should have their own ingestion flow so closing below
                //|| (this.country.indexOf(app.$data.user.get.country) == -1)
        },
        isLocked() {
            return this.$ability.cannot('manage_mcr_status', 'Miscellaneous')
        },
        getIconTitle() {
            if (this.isLocked && !this.isDisabled) return 'You are not allowed to change this MCR Status'
            return this.isDisabled ? 'You are not allowed to change MCR Status of this item. This item already passed.' : ''
        },

        /**
         * Get the current status, if available.
         */
        getCurrentStatus() {
            if (this.processedItems)
                return this.processedItems.find(v => v.country == app.$data.user.get.country)
            else
                return null;
        },

        /**
         * Get the current status number to be used, based o the passed items.
         */
        getCurrentStatusNumber() {

            // A status is available, return its status id.
            if (this.getCurrentStatus) {

                // Determine the color of "status 3" icon, based on item start/end.
                if (this.getCurrentStatus.status == 3) {
                    switch (true) {
                        case (this.$store.state.app.now >= this.getCurrentStatus.itemStartTimestamp && this.$store.state.app.now <= this.getCurrentStatus.itemEndTimestamp):
                            return this.getCurrentStatus.status + 'b'
                        case (this.$store.state.app.now >= this.getCurrentStatus.itemEndTimestamp):
                            return this.getCurrentStatus.status + 'c'
                    }
                }

                return this.getCurrentStatus.status
            }

            // EquipmentUsages (ex Contributions) should always start on "status 2".
            //else if (this.itemType == 'equipmentUsage') {
                //return 2
            //}

            return 1
        },

    },
    methods: {
        setLoaded(loaded = true) {
            Vue.nextTick(() => {
                this.loaded = loaded
            })
        },
        parseItems() {
            Lazy(this.processedItems)
                .each(v => {
                    if (v.itemStart) {
                        Vue.set(v, 'itemStartTimestamp', moment(v.itemStart, 'YYYY-MM-DD HH:mm').unix())
                        Vue.set(v, 'itemEndTimestamp', moment(v.itemEnd, 'YYYY-MM-DD HH:mm').unix())
                    }
                })
        },
        getIcon(status) {
            switch (status) {
                case 1:
                    return 'tasks'
                case 2:
                    return 'project-diagram'
                case 3:
                case '3b':
                case '3c':
                    return 'broadcast-tower'
                case 4:
                    return 'check'
            }
        },
        expand() {
            if (this.isLocked) return
            this.expanded = !this.expanded
        },

        /**
         * Set status for the current item in the API.
         * @param integer status
         */
        setStatus(status) {
            this.$loader.add(this.processingId)
            if (this.getCurrentStatus) {
                // then its delete operation for fck sake WN
                if (this.getCurrentStatus.status === status) {
                    this.deleteMcrStatus(this.getCurrentStatus)
                    this.$loader.remove(this.processingId)
                    return
                }
                let mcrStatus = new McrStatus(this.getCurrentStatus.id)
                mcrStatus.add(Object.assign({}, this.getCurrentStatus))
                mcrStatus.set('status', status)
                mcrStatus.set('extraData', this.extraData)

              this.updateMcrStatus(mcrStatus.get, 'save')
              this.$loader.remove(this.processingId)
              if(status === 4) {
                this.updateMcrStatusFinished()
              }
              /*
                mcrStatus.save()
                    .then(response => {
                        this.updateMcrStatus(response.data)
                        this.$loader.remove(this.processingId)
                        if(status === 4) {
                            this.updateMcrStatusFinished()
                        }
                    })
                    .catch(error => {
                        this.$error.set(error)
                        this.$loader.remove(this.processingId)
                    })*/
            }
            else {
                //SMTGBD-717 each country should have their own ingestion flow so closing below
                /*if (this.country.indexOf(app.$data.user.get.country) == -1) {
                    this.$alert.set('Your country doens\'t match the country of this item.')
                    return
                }*/
                let mcrStatus = new McrStatus()
                mcrStatus.add({
                    status: status,
                    country: app.$data.user.get.country,
                    itemId: this.itemId,
                    itemType: this.itemType,
                    extraData: this.extraData
                })
              this.updateMcrStatus(mcrStatus.get, 'store')
              this.$loader.remove(this.processingId)
              if(status === 4) {
                this.updateMcrStatusFinished()
              }
              /*
                mcrStatus.store()
                    .then(response => {
                        this.updateMcrStatus(response.data)
                        this.$loader.remove(this.processingId)
                        if(status === 4) {
                            this.updateMcrStatusFinished()
                        }
                    })
                    .catch(error => {
                        this.$error.set(error)
                        this.$loader.remove(this.processingId)
                    })
               */
            }
        },

        /**
         * Add/update an ingestion item on the current feed/production.
         * @param object item
         */
        deleteMcrStatus(item) {
            const items = this.processedItems.slice(0)
            let i = items.findIndex(v => v.id === item.id)
            if (i > -1) items.splice(i, 1)
            app.$emit('AutoChangeMcrStatus', {
              itemId: this.itemId,
              itemType: this.itemType,
              extraData: this.extraData,
              id: item.id,
              item: item,
              action: {
                module: 'McrStatus',
                type: 'delete',
              },
              items: items
            })
        },

        updateMcrStatus(item, actionType) {
            // get clone-id
            if (actionType === 'store') {
              item.id = Math.floor(100000000000 + Math.random() * 900000000000);
            }
            if (this.processedItems){
                const items = this.processedItems.slice(0)
                let i = items.findIndex(v => v.id == item.id)
                if (i > -1) {
                    if (items[i].status === item.status){
                        items.pop(item)
                    }   
                    else{
                        items.splice(i, 1)
                        items.push(item)

                        app.$emit('AutoChangeMcrStatus', {
                          itemId: this.itemId,
                          itemType: this.itemType,
                          extraData: this.extraData,
                          item: item,
                          action: {
                            module: 'McrStatus',
                            type: actionType,
                          },
                          items: items
                        })
                    }   
                }else{
                    items.push(item)
                }

                        app.$emit('AutoChangeMcrStatus', {
                          itemId: this.itemId,
                          itemType: this.itemType,
                          extraData: this.extraData,
                          item: item,
                          action: {
                            module: 'McrStatus',
                            type: actionType,
                          },
                          items: items
                    })
            }else{
                let items = [1];
                items.push(item)
                    app.$emit('AutoChangeMcrStatus', {
                      itemId: this.itemId,
                      itemType: this.itemType,
                      extraData: this.extraData,
                      item: item,
                      action: {
                        module: 'McrStatus',
                        type: actionType,
                      },
                      items: items
                    })

            
            }

        },

        updateMcrStatusFinished(){
            app.$emit('showFeedbackModal', { id: this.itemId, type: this.itemType, extraData: this.extraData })
        },
        
        /**
         * Get classes for the status button.
         */
        getClasses(item) {
            if (this.getCurrentStatus){
                if (this.getCurrentStatus.status == item)
                    return 'book-mcr-status-item-active-' + item;
                //return {'book-mcr-status-item-active': this.getCurrentStatus, 'book-mcr-status-item-disabled': this.isDisabled, 'book-mcr-status-item-readonly': this.readonly}
            }
            return null;
        },
    }
}
</script>

<template>
<div
    class="book-mcr-status"
    :id="'book-mcr-status-' + itemType + '-' + itemId"
    :class="[{'book-mcr-status-expanded': expanded}, {'book-mcr-status-processing': processing}, {'book-mcr-status-disabled': isDisabled}, {'book-mcr-status-locked': isLocked}, 'book-mcr-status-' + getCurrentStatusNumber]"
    @mouseleave="expanded = false"
    :title="getIconTitle">
    <!--
    <book-loader :small="true" v-if="processing"></book-loader>
    <div v-else class="book-mcr-status-icon" :class="'book-mcr-status-' + getCurrentStatusNumber" @click="expand()">
        <i class="fas" :class="getIcon(getCurrentStatusNumber)"></i>
    </div>
-->
    <button
    v-if="hideStatus.indexOf(1) == -1"
        :title="'Routed'"
        :class="['book-mcr-status-item', getClasses(1)]"
        class="book-mcr-status-item-1"
        @click="setStatus(1)">
    </button>

    <button
        v-if="hideStatus.indexOf(2) == -1"
        :title="'Line-up'"
        class="book-mcr-status-item-2"
        :class="['book-mcr-status-item', getClasses(2)]"
        @click="setStatus(2)">
    </button>

    <button
        v-if="hideStatus.indexOf(3) == -1"
        :title="'On AIR'"
        class="book-mcr-status-item-3"
        :class="['book-mcr-status-item', getClasses(3)]"
        @click="setStatus(3)">
    </button>

    <button
        v-if="hideStatus.indexOf(4) == -1"
        :title="'Finished'"
        class="book-mcr-status-item-4"
        :class="['book-mcr-status-item', getClasses(4)]"
        @click="setStatus(4)">
        
    </button>
<!--
    <div class="book-mcr-status-overlay" v-if="processing && !isDisabled">
        <book-loader :small="true" ></book-loader>
    </div>
    <div class="book-mcr-status-selection" v-if="!isLocked">
        <div class="book-mcr-status-icon book-mcr-status-icon-planning animation" title="Planning" v-if="hideStatus.indexOf(1) == -1" @click="setStatus(1)" >
            <i class="fas fa-tasks"></i>
        </div>
        <div class="book-mcr-status-icon book-mcr-status-icon-lineup animation" title="Line-up" v-if="hideStatus.indexOf(2) == -1" @click="setStatus(2)">
            <i class="fas fa-project-diagram"></i>
        </div>
        <div class="book-mcr-status-icon book-mcr-status-icon-standby animation" title="Ready & Standing by" v-if="hideStatus.indexOf(3) == -1" @click="setStatus(3)">
            <i class="fas fa-broadcast-tower"></i>
        </div>
        <div class="book-mcr-status-icon book-mcr-status-icon-finished animation" title="Finished" v-if="hideStatus.indexOf(4) == -1" @click="setStatus(4)">
            <i class="fas fa-check"></i>
        </div>
    </div>-->
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-mcr-contribution {
  .book-mcr-status .book-mcr-status-selection {
      width: 84px !important; // Required by IE.
  }
}
.book-mcr-status {
    align-items: center;
    display: flex;
    position: relative;
    text-align: center;
    //width: 30px;
    &.book-mcr-status-locked {
        .book-mcr-status-icon {
            cursor: default;
        }
    }
    &.book-mcr-status-disabled {
        cursor: not-allowed;
        position: relative;
        opacity: 0.3;
        &:after {
            bottom: 0;
            content: " ";
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
        > .book-mcr-status-icon {
            opacity: .4;
        }
    }
    &.book-mcr-status-expanded:not(.book-mcr-status-processing) {
        z-index: 900;
        > .book-mcr-status-icon {
            background: $color-white;
            border-color: $color-grey-border;
            border-radius: 4px 4px 0 0;
            z-index: 901;
        }
        .book-mcr-status-selection {
            display: flex;
            z-index: 900;
        }
    }
    > .book-mcr-status-icon {
        border: 1px solid transparent;
        border-bottom: none;
        padding: 3px 6px;

        &.book-mcr-status-1 {
            color: $color-red;
        }
        &.book-mcr-status-2 {
            color: $color-red;
        }
        &.book-mcr-status-3 {
            color: $color-yellow;
        }
        &.book-mcr-status-3b {
            color: $color-red;
        }
        &.book-mcr-status-3c {
            color: $color-green;
        }
        &.book-mcr-status-4 {
            color: $color-green;
        }
    }
    .book-mcr-status-icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        img {
            max-height: 20px;
            max-width: 20px;
            width: auto;
        }
    }
    .book-mcr-status-icon-planning,
    .book-mcr-status-icon-lineup {
        color: $color-red;
    }
    .book-mcr-status-icon-standby {
        color: $color-yellow;
    }
    .book-mcr-status-icon-finished {
        color: $color-green;
    }
    .book-mcr-status-selection {
        background: $color-white;
        border: 1px solid $color-grey-border;
        border-radius: 0 4px 4px 4px;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        display: none;
        left: 0;
        padding: 0 5px;
        position: absolute;
        top: 26px;
        width: 110px; // Required by IE.
        .book-mcr-status-icon {
            line-height: 30px;
            padding: 4px;
            vertical-align: middle;
            &:hover {
                img {
                    max-height: 24px;
                }
            }
        }
    }
    

    .book-mcr-status-item {
        background: none;
        border: 2px solid rgba($color-text, .5);
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        padding: 0;
        position: relative;
        user-select: none;
        vertical-align: middle;
        width: 20px;
        // z-index: 1;
        &:focus {
            outline: none;
        }
        &:before {
            display: block;
            content: " ";
            left: 50%;
            //opacity: .3;
            top: 50%;
        }
        
        // &:after {
        //     border-top: 2px solid $color-red;
        //     display: block;
        //     content: " ";
        //     left: -8px;
        //     position: absolute;
        //     top: 7px;
        //     transform: rotate(45deg);
        //     width: 32px;
        // }
        //&:hover:not(.book-mcr-status-item-disabled):not(.book-mcr-status-item-readonly),
        &.book-mcr-status-item-active-1 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: $color-blue-info;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-2 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: #22CC22;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-3 {
            border-color: rgba($color-text, 1);
            color: $color-red;
            &:before {
                background-color: $color-yellow;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-4 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: #bfbebe;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        //&:hover:not(.book-mcr-status-item-disabled):not(.book-mcr-status-item-readonly) {
          //box-shadow: 0px 1px 10px -1px $color-shadow;
        //}
        &.book-mcr-status-item-disabled {
            border: 2px solid #8b8c8b;
            opacity: 1 !important;
            cursor: not-allowed;
            &:after {
                opacity: .5;
            }
            &:after {
                display: none;
            }
            svg {
                //opacity: 1 !important;
            }
        }
        &.book-mcr-status-item-readonly {
            cursor: default !important;
        }
        &.book-mcr-status-item-1{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: $color-blue-info;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-2{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: #22CC22;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-3{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: $color-yellow;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-4{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: #bfbebe;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        + .book-mcr-status-item {
            margin-left: 4px;
        }
        
        svg {
            opacity: .0;
        }
    }
    
    .book-mcr-status-overlay {
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        .book-loader {
            position: relative;
            z-index: 3;
        }
    }
}
</style>
