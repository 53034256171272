import Api from 'components/app/Api'
import File from 'components/common/helpers/File'

class Calendar extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/calendar',
            module: 'calendar',
        })
    }

    /**
     * Retrieve the whole calendar for the given user, from now and ahead.
     * @param integer uid
     * @return promise
     */
    static download(params) {
        return new Promise((resolve, reject) => {
              axios.get('api/calendar/download', {params: params})
                  .then(response => {
                      let filename = File.getAttachmentFilename(response.headers['content-disposition'])
                      if (!filename) {
                          filename = 'calendar.ics'
                      }
                      File.download(response.data, filename, response.headers['content-type']);
                      resolve(response)
                  })
                  .catch(error => {
                      reject(error)
                  })
        })
    }

}

export default Calendar
