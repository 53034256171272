<script>
import Equipment from 'components/equipment/Equipment'
import Entity from 'components/entity/Entity'
import System from 'components/system/System'
import BookLoader from 'components/common/loader/BookLoader'

import multiselect from 'vue-multiselect'

export default {
    components: {
        BookLoader,
        multiselect
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            formData: this.defaultData(),
            loaded: false,
            equipment: null,
            equipmentTypeEntity: null,
            equipmentGroupEntity: null,
            equipmentStatusEntity: null,
            deliveryTypeEntity: null,
            equipmentDmsInfoEntity: null,
            externalMCRsEntity: null
        }
    },
    mounted() {
        const context = this

        // Load some entities.
        const entity = new Entity()
        const equipmentTypeEntityId = context.setting.equipmenttype_entity_id
        context.equipmentTypeEntity = new Entity(equipmentTypeEntityId)

        const equipmentGroupEntityId = context.setting.equipmentgroups_entity_id
        context.equipmentGroupEntity = new Entity(equipmentGroupEntityId)

        const equipmentStatusEntityId = context.setting.equipmentstatus_entity_id
        context.equipmentStatusEntity = new Entity(equipmentStatusEntityId)

        const deliveryTypeEntityId = 5 //hard coded
        context.deliveryTypeEntity = new Entity(deliveryTypeEntityId)

        const equipmentDmsInfoEntityId = context.setting.equipmentdmsinfo_entity_id
        context.equipmentDmsInfoEntity = new Entity(equipmentDmsInfoEntityId)

        const externalMCRsEntityId = context.setting.externalmcrs_entity_id
        context.externalMCRsEntity = new Entity(externalMCRsEntityId)
        
        context.equipment = new Equipment(context.id)
        const dataToLoad = [
            entity.all({ids: [equipmentTypeEntityId, equipmentStatusEntityId, deliveryTypeEntityId, equipmentDmsInfoEntityId, equipmentGroupEntityId, externalMCRsEntityId].join(',')}),
            context.equipment.all({}, true)
        ]

        Promise.all(dataToLoad.map(p => {

            // Return "undefined" for all rejected promises which allows
            // us to handle entities even if equipment failed.
            return p.catch(() => undefined)

        }))
            .then(response => {
                if ((context.equipment.get.id > 0) && (!context.equipment.get.label)) {
                    context.$router.push({name: 'equipment'})
                    this.$alert.set('The equipment you tried to access doesn\'t exist.', 'danger', 5)
                }
                else {
                    context.setLoaded()
                }
            })

    },
    computed: {
        isEditForm() {
            return this.$route.name === 'equipment.edit'
        },
        setting() {
            return this.$settings.get.equipment
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        destinationCountries() {
            let dest = null
            const context = this
            if(context.formData.extMCRInSource.value) {
                dest = Object.keys(context.$store.state.system.countries).length > 0 ? _.map(context.$store.state.system.countries, i =>  {
                    return {
                        id: i.code,
                        label: i.label,
                        type: 'country'
                    }
                }) : null
            }
            else {
                let cnt = Object.keys(context.$store.state.system.countries).length > 0 ? _.map(context.$store.state.system.countries, i =>  {
                    return {
                        id: i.code,
                        label: i.label,
                        type: 'country'
                    }
                }) : []
                let mcr = context.externalMCRsEntity.get.items && Object.keys(context.externalMCRsEntity.get.items).length > 0 ?_.map(context.externalMCRsEntity.get.items, i =>  {
                    return {
                        id: i.id,
                        label: i.label,
                        type: 'mcr'
                    }
                }) : []

                dest = _.concat(cnt, mcr)
            }
            
            return /*dest && context.formData.source.value.type === 'country' ? _.filter(dest, d => d.id !== context.formData.source.value.id) :*/ dest
        },
        sourceCountries() {
             let dest = null
            const context = this
            if(context.formData.extMCRInDestination.value) {
                dest = Object.keys(context.$store.state.system.countries).length > 0 ? _.map(context.$store.state.system.countries, i =>  {
                    return {
                        id: i.code,
                        label: i.label,
                        type: 'country'
                    }
                }) : null
            }
            else {
                let cnt = Object.keys(context.$store.state.system.countries).length > 0 ? _.map(context.$store.state.system.countries, i =>  {
                    return {
                        id: i.code,
                        label: i.label,
                        type: 'country'
                    }
                }) : []
                let mcr = context.externalMCRsEntity.get.items && Object.keys(context.externalMCRsEntity.get.items).length > 0 ?_.map(context.externalMCRsEntity.get.items, i =>  {
                    return {
                        id: i.id,
                        label: i.label,
                        type: 'mcr'
                    }
                }) : []

                dest = _.concat(cnt, mcr)
            }
            
            return dest
        },
        equipmentList() {
            return this.$store.state.data.equipment
        },
        equipmentAvailable() {
            let equipmentIsAvailable = false
            this.equipmentList.forEach(v => {
                if (v.type == this.formData.suggestionType.value) {
                    equipmentIsAvailable = true
                }
            })
            return equipmentIsAvailable
        },
        suggestionIsDisabled() {
            return !this.equipmentAvailable || this.equipmentList.length < 1 || this.formData.suggestionType.value == ''
        },
        isFormClean() {
            const context = this
            return context.formFields && Object.keys(context.formFields).every(k => {
                let val = true
                if (k === 'create_with') {
                    let value = context.equipment.get.create_with
                    let value2 = context.formData.create_with.value
                    if (context.equipment.get.create_with) {
                        value = value.split(',')
                        val = JSON.stringify(value) === JSON.stringify(value2)
                    } else {
                        val = value2.length === 0
                    }
                }
                return !val ? false : context.formFields[k].pristine
            })
        },
        isFormValid() {
            const context = this
            return context.formFields && Object.keys(context.formFields).every(k => {
                return context.formFields[k].valid
            })
        },
        groupList() {
            const context = this
            let flt = context.formData.type.value ? _.filter(context.equipmentGroupEntity.get.items, i => i.reference === context.formData.type.value) : context.equipmentGroupEntity.get.items
            return flt
        },
        isGroupRequired() {
            return this.formData.useGroupConflictCount.value && this.formData.useGroupConflictCount.value === true
        },
        isCountryRequired() {
            return this.formData.notCountryRestricted.value === false
        },
        isSourceDestRequired() {
            return this.formData.notCountryRestricted.value && this.formData.notCountryRestricted.value === true
        }

    },
    watch: {
        // 'formData.country.value': function(newCountry, oldCountry) {
        //     const context = this
        //     //context.destination = _.filter(formData.context.destination.value, i => i !== oldCountry);
        //     //context.destination.push(newCountry);
        //     if(context.formData.destination.value && context.formData.destination.value.length > 0) {
        //         context.formData.destination.value = _.filter(context.formData.destination.value, i => i.code !== newCountry);
        //     }
        // },
        'formData.type.value': function(newType, oldType) {
            if(newType !== oldType) {
                const context = this
                let groupItem = _.findIndex(context.groupList, g => g.id === context.formData.group.value)
                if(groupItem === -1) {
                    this.formData.group.value = ''
                }
                
            }
        },
        'formData.destinationWithoutSource.value': function(newValue) {
            const context = this
            if(_.some(context.formData.destinationWithoutSource.value, d => d.type === 'mcr')) {
                context.formData.extMCRInDestination.value = true
            }
            else {
                context.formData.extMCRInDestination.value = false
            }

            if(context.formData.source.value.type === 'country' && newValue.length === 0) {
                let sr = _.find(context.destinationCountries, d => d.id === context.formData.source.value.id) 
                context.formData.destinationWithoutSource.value.push(sr)
            }

            // if(context.formData.source.value.type === 'country' && !_.some(context.formData.destinationWithoutSource.value, d => d.id === context.formData.source.value.id)) {
            //     let sr = _.find(context.destinationCountries, d => d.id === context.formData.source.value.id) 
            //     context.formData.destinationWithoutSource.value.unshift(sr)
            // }
        },
        'formData.source.value': function(newValue, oldValue) {
            const context = this
            if(context.formData.source.value.type === 'mcr') {
                context.formData.extMCRInSource.value = true
            }
            else {
                context.formData.extMCRInSource.value = false
            }

            if(context.formData.source.value.type === 'country' &&
               (context.formData.destinationWithoutSource.value.length === 0 ||
                (context.formData.destinationWithoutSource.value.length === 1 && context.formData.destinationWithoutSource.value[0].id === oldValue.id))) {
                    let sr = _.find(context.destinationCountries, d => d.id === context.formData.source.value.id) 
                    context.formData.destinationWithoutSource.value = []
                    context.formData.destinationWithoutSource.value.push(sr)
            }

            // if(context.formData.source.value.type === 'country' && (context.formData.destinationWithoutSource.value.length === 0 ||context.formData.destinationWithoutSource.value[0].id !== context.formData.source.value.id)) {
            //     let sr = _.find(context.destinationCountries, d => d.id === context.formData.source.value.id) 
            //     if(_.some(context.formData.destinationWithoutSource.value, d => d.id === context.formData.source.value.id)) {
            //         let inx = _.findIndex(context.formData.destinationWithoutSource.value, i => i.id === context.formData.source.value.id)
            //         context.formData.destinationWithoutSource.value.splice(inx, 1)
            //         if(oldValue.type === 'country') {
            //             context.formData.destinationWithoutSource.value.shift()
            //         }
            //         context.formData.destinationWithoutSource.value.unshift(sr)
            //     }
            //     else {
            //         if(oldValue.type === 'country') {
            //             context.formData.destinationWithoutSource.value.shift()
            //         }
            //         context.formData.destinationWithoutSource.value.unshift(sr)
            //     }
            // }
            // else if(oldValue.type === 'country' && context.formData.source.value.type === 'mcr') {
            //     context.formData.destinationWithoutSource.value.shift()
            // }
        }
    },
    methods: {

        /**
         * Set the equipment list to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
            this.setFormValues()
        },

        setFormValues() {
            const context = this
            if (context.isEditForm) {
                Lazy(context.formData)
                .each(function(v, k) {
                    let value = null;
                    switch (k) {
                        case 'suggestionType':
                        case 'suggestions':
                        case 'extMCRInSource':
                        case 'extMCRInDestination':
                          // Empty condition because "suggestions/suggestionType"
                          // are set together with "suggestionId".
                          break
                        case 'suggestionId':

                          if (context.equipment.get.suggestions /*&& context.equipment.get.suggestions.length > 0*/) {

                              // Note that an equipment contains the whole chain of
                              // suggestions (children), but the first one is the
                              // equipment suggestion that is chosen directly under
                              // the equipment, why we retrieve Array[0].id
                              const equipmentSuggestion = new Equipment(context.equipment.get.suggestions/* [0].id */)
                              context.formData.suggestionType.value = equipmentSuggestion.get.type
                              value = context.equipment.get.suggestions/* [0].id */

                          }

                          break
                        case 'create_with':
                            context.formData.create_with.value = context.equipment.get[k]? context.equipment.get[k].split(',') : []
                            break
                        case 'usage':
                          if (context.equipment.get.usage && context.equipment.get.usage.length > 0) {
                              if (context.equipment.get.usage.indexOf("feed") > 0){
                                  context.formData.usage_feed.value = true
                              }
                              if (context.equipment.get.usage.indexOf("production") > 0){
                                  context.formData.usage_production.value = true
                              }
                              if (context.equipment.get.usage.indexOf("eng") > 0){
                                  context.formData.usage_eng.value = true
                              }
                              if (context.equipment.get.usage.indexOf("edit") > 0){
                                  context.formData.usage_edit.value = true
                              }
                              if (context.equipment.get.usage.indexOf("car") > 0){
                                  context.formData.usage_car.value = true
                              }
                          }
                            break
                        case 'source':
                            if(context.equipment.get.source && context.equipment.get.source !== '' && context.equipment.get.notCountryRestricted) {
                                try {
                                    let srcObject = context.equipment.get.source
                                    if(!isNaN(parseInt(srcObject))) {
                                        context.formData.extMCRInSource.value = true
                                    } else {
                                        context.formData.extMCRInSource.value = false
                                    }
                                    let scrObjectVal = isNaN(parseInt(srcObject)) ? srcObject : parseInt(srcObject)
                                    let src = _.find(context.sourceCountries, i => i.id === scrObjectVal)
                                    if(src) {
                                        v.value = src
                                    }
                                    
                                }
                                catch(e) {
                                    console.log(e)
                                }
                                
                            }
                            break
                        case 'destinationWithoutSource':
                            if(context.equipment.get.destinationWithoutSource && context.equipment.get.destinationWithoutSource.length > 0) {
                                if(context.equipment.get.notCountryRestricted) {
                                    
                                    // let dest = []
                                    // if(context.equipment.get.source && context.equipment.get.source !== '') {
                                    //     try {
                                    //         let srcObj = context.equipment.get.source
                                    //         let srcVal = !isNaN(parseInt(srcObj)) ? parseInt(srcObj) : srcObj
                                    //         dest = context.equipment.get.destinationWithoutSource.filter(d => (!isNaN(parseInt(d)) && parseInt(d) !== srcVal) || (isNaN(parseInt(d)) && d !== srcVal))
                                    //         if(isNaN(parseInt(srcObj))) {
                                    //             dest.unshift(srcObj.id)
                                    //         }
                                    //     } catch(e) {
                                    //         console.log(e)
                                    //     }
                                    // }

                                    //if(dest && dest.length > 0) {
                                        //dest
                                        context.equipment.get.destinationWithoutSource.forEach(d => {
                                            let dstObject = _.find(context.destinationCountries, c => c.id === d || (!isNaN(parseInt(d)) && parseInt(d) === c.id))
                                            if(dstObject) {
                                                v.value.push(dstObject)
                                                if(dstObject.type === 'mcr') {
                                                    context.formData.extMCRInDestination.value = true
                                                }
                                            }
                                        })

                                        
                                    //}

                                    

                                }
                            }
                            break
                      default:
                          value = context.equipment.get[k]
                          break
                    }
                    if (value) {
                        v.value = value
                    }
                })
                //console.log(context.formData)
            }
            else {

                const context = this
                let defaultValue = null

                // Try to set default value for "Type" field.
                defaultValue = context.equipmentTypeEntity.get.items.filter(function(item) {
                    return item.default
                })
                if (defaultValue.length > 0) {
                    context.formData.type.value = defaultValue[0].id
                }

                // Try to set default value for "Status" field.
                defaultValue = context.equipmentStatusEntity.get.items.filter(function(item) {
                    return item.default
                })
                if (defaultValue.length > 0) {
                    context.formData.equipmentStatus.value = defaultValue[0].id
                }

            }

        },

        /**
         * Submit the form.
         */
        submit() {
            const context = this
            context.$loader.add('EquipmentEditSubmit')
            let usage = [];
            Lazy(context.formData)
                .filter((v, k) => !v.local && (k != 'suggestionType'))
                .each(function(v, k) {
                    //console.log(v.value, '<v val k>', k);
                    if (k == "usage_feed" || k == "usage_production" || k == "usage_eng" || k == "usage_edit" || k == "usage_car"){
                        if (k == "usage_feed" && v.value == true){
                            usage.push("feed")
                        }
                        if (k == "usage_production" && v.value == true){
                            usage.push("production")
                        }
                        if (k == "usage_eng" && v.value == true){
                            usage.push("eng")
                        }
                        if (k == "usage_edit" && v.value == true){
                            usage.push("edit")
                        }
                        if (k == "usage_car" && v.value == true){
                            usage.push("car")
                        }
                        context.equipment.set("usage", usage)
                    }else{
                        if (k == 'create_with') {
                            let value = v.value.join(',')
                            context.equipment.set(k, value)
                        } else if (k == 'conflictCount' && (v.value === null || v.value === '')) {
                            context.equipment.set(k, 1)
                        } else if(k == 'destinationWithoutSource') {
                            if(context.formData.notCountryRestricted.value) {
                                let value = []
                                v.value.forEach(item => {
                                    value.push(item.id)
                                })
                                //let dst = context.formData.source.value.type === 'mcr' ? _.concat(value, [context.formData.source.value.id]) : value
                                context.equipment.set(k, value)
                            }
                            // else {
                            //     context.equipment.set(k, [context.formData.country.value])
                            // }
                        } else if (k == 'destination') {
                            if(context.formData.notCountryRestricted.value) {
                                 let value = []
                                 let src = context.formData.source.value.id
                                 let allDests = []
                                 context.formData.destinationWithoutSource.value.forEach(item => {
                                     allDests.push(item.id)
                                 })
                                 let indxOfSource = _.indexOf(allDests, src)
                                 let dests = indxOfSource > -1 ? allDests.filter(f => f !== src) : allDests
                                 value = _.concat(dests, [src])
                                 context.equipment.set(k, value)

                            }
                            else {
                                context.equipment.set(k, [context.formData.country.value])
                            }
                        }
                        else if(k == 'source') {
                            if(context.formData.notCountryRestricted.value) {
                                context.equipment.set(k, context.formData.source.value.id)
                            }
                            else {
                                context.equipment.set(k, null)
                            }
                        }
                        else{
                            context.equipment.set(k, v.value)
                        }
                    }   
                })

            if (context.isEditForm) {
                context.equipment.save()
                    .then(response => {
                        this.$alert.set('Equipment ' + response.data.id + ' has been updated.', 'success', 4)
                        Object.assign(context.$data.formData, context.defaultData())
                        context.$root.$emit('formSaved')
                        context.$loader.remove('EquipmentEditSubmit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to update the equipment.')
                        context.$loader.remove('EquipmentEditSubmit')
                    })
            }
            else {
                context.equipment.store()
                    .then(response => {
                        this.$alert.set('A new equipment has been created.', 'success', 3)
                        Object.assign(context.$data.formData, context.defaultData())
                        context.$root.$emit('formSaved')
                        context.$loader.remove('EquipmentEditSubmit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the equipment.')
                        context.$loader.remove('EquipmentEditSubmit')
                    })
            }
        },

        defaultData() {
            return {
                type: {
                    value: '',
                    validator: 'required'
                },
                group: {
                    value: ''
                },
                equipmentStatus: {
                    value: '',
                    validator: 'required'
                },
                country: {
                    value: '',
                    //validator: 'required'
                },
                placeHolder: {
                  value: false,
                },
                doNotCreateConflict: {
                  value: false,
                },
                conflictCount : {
                    value: 1
                },
                notCountryRestricted: {
                    value: false,
                },
                useGroupConflictCount: {
                    value: false,
                },
                label: {
                    value: '',
                    validator: 'required'
                },
                description: {
                    value: ''
                },
                model: {
                    value: ''
                },
                usage: {
                    value: []
                },
                destination: {
                    value: [],
                    //validator: 'required'
                },
                destinationWithoutSource: {
                    value: [],
                    //validator: 'required'
                },
                source: {
                    value: '',
                    //validator: 'required'
                },
                extMCRInSource: {
                    local: true,
                    value: false
                },
                extMCRInDestination: {
                    local: true,
                    value: false
                },
                create_with: {
                    value: []
                },
                suggestionType: {
                    local: true,
                    value: '',
                    validator: ''
                },
                suggestions: {
                    local: true,
                    value: [],
                    validator: ''
                },
                suggestionId: {
                    value: ''
                },
                usage_feed: {
                    value: false
                },
                usage_production: {
                    value: false
                },
                usage_eng: {
                    value: false
                },
                usage_edit: {
                    value: false
                },
                usage_car: {
                    value: false
                },
                se_dms_port_no: {
                    value: '',
                    validator: 'integer'
                },
                se_dms_type: {
                    value: ''    
                },
                se_dms_info_id: {
                    value: ''
                },
                kit: {
                    value: false
                },
                kitId: {
                    value: ''
                }
            }
        }

    }
}
</script>

<template>
<div id="equipment-edit" class="admin book-top-sticky-wrapper col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 container">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title text-center" v-if="!isEditForm">Create equipment</h1>
        <h1 class="page-title text-center" v-if="isEditForm">Edit equipment {{id}}</h1>
    </div>
    <book-loader class="panel-body" v-if="!loaded"></book-loader>
    <div class="panel panel-info" v-else>
        <div class="panel-body form-horizontal">
            <div class="col-sm-7 col-md-7">
                <div class="form-group" :class="{'has-error': formErrors.has('label')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Label
                    </label>
                    <div class="form-control-100">
                        <input class="form-control" name="label" v-validate="formData.label.validator" v-model="formData.label.value">
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Description</label>
                    <div class="form-control-100">
                        <input class="form-control" name="description" v-validate v-model="formData.description.value">
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Model</label>
                    <div class="form-control-100">
                        <input class="form-control" name="model" v-validate v-model="formData.model.value">
                    </div>
                </div>
                <div class="form-group" v-if="!formData.doNotCreateConflict.value">
                    <label for="name" class="control-label book-width-100">Conflict Count</label>
                    <div class="form-control-100">
                        <input class="form-control" name="conflictCount" v-validate v-model="formData.conflictCount.value" type="number" min="1" step="1">
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Usage</label>
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="usage_feed" v-validate v-model="formData.usage_feed.value" value="feed">
                            Feed
                        </label>
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="usage_production" v-validate v-model="formData.usage_production.value" value="production">
                            Production
                        </label>
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="usage_eng" v-validate v-model="formData.usage_eng.value" value="eng">
                            ENG
                        </label>
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="usage_edit" v-validate v-model="formData.usage_edit.value" value="edit">
                            Edit
                        </label>
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="usage_car" v-validate v-model="formData.usage_car.value" value="car">
                            Car
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Create with</label>
                    <div class="form-control-100 checkbox">
                        <label v-for="type in deliveryTypeEntity.get.items" style="padding-right: 10px">
                            <input type="checkbox" name="create_with" v-validate v-model="formData.create_with.value" :value="type.id">
                            {{ type.label }}
                        </label>
                    </div>
                </div>

            </div>
            <div class="col-sm-5 col-md-5">
                <div class="form-group" :class="{'has-error': formErrors.has('type')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Type
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="type" v-validate="formData.type.validator" v-model="formData.type.value" v-if="equipmentTypeEntity">
                            <option v-for="type in equipmentTypeEntity.get.items" :value="type.id">{{type.label}}</option>
                        </select>
                    </div>
                </div>
                 <div class="form-group" :class="{'has-error': formErrors.has('group')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon v-if="formData.useGroupConflictCount.value" icon="asterisk"/></span>
                        Group
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="group" v-validate.immediate="{ required: isGroupRequired }" v-model="formData.group.value" v-if="equipmentGroupEntity.get.items" :disabled="!formData.type.value">
                            <option value="">None</option>
                            <option v-for="group in groupList" :value="group.id" >{{group.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" :class="{'has-error': formErrors.has('status')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Status
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="status" v-validate="formData.equipmentStatus.validator" v-model="formData.equipmentStatus.value" v-if="equipmentStatusEntity">
                            <option v-for="status in equipmentStatusEntity.get.items" :value="status.id">{{status.label}}</option>
                        </select>
                    </div>
                </div>
                <div v-show="!formData.notCountryRestricted.value" class="form-group" :class="{'has-error': formErrors.has('country')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Country
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="country" v-validate.immediate="{ required: isCountryRequired && !isSourceDestRequired }" v-model="formData.country.value" v-if="countries">
                            <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                        </select>
                    </div>
                </div>
                <div v-show="formData.notCountryRestricted.value" class="form-group" :class="{'has-error': formErrors.has('source')}">
                    <label for="source" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Source
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="source" v-validate.immediate="{ required: isSourceDestRequired && !isCountryRequired }" v-model="formData.source.value" v-if="sourceCountries">
                            <option v-for="country in sourceCountries" :value="country">{{country.label}}</option>
                        </select>
                    </div>
                </div>
                <div v-show="formData.notCountryRestricted.value" class="form-group" :class="{'has-error': formErrors.has('destinationWithoutSource')}">
                        <label for="name" class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Destinations
                        </label>
                        <div class="form-control-100" v-if="destinationCountries">
                            <multiselect
                                v-validate.immediate="{ required: isSourceDestRequired && !isCountryRequired }"
                                name="destinationWithoutSource"
                                v-model="formData.destinationWithoutSource.value"
                                track-by="id"
                                label="label"
                                placeholder=""
                                :max-height="200"
                                :options="destinationCountries"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="true"
                                :hide-selected="true"
                                :show-labels="false">
                                <span slot="noResult">No countries found.</span>
                            </multiselect>
                        </div>
                </div>
                <div class="form-group">
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="not_country_restricted" v-validate v-model="formData.notCountryRestricted.value" :value="true">
                            Is shared by two MCRs
                        </label>
                    </div>
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="use_group_conflict_count" v-validate v-model="formData.useGroupConflictCount.value" :value="true">
                            Check for group limit exceeding 
                        </label>
                    </div>
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="do_not_create_conflict" v-validate v-model="formData.doNotCreateConflict.value" :value="true">
                            Don't create conflict
                        </label>
                    </div>
                    <div class="form-control-100 checkbox">
                      <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="is_placeholder" v-validate v-model="formData.placeHolder.value" :value="true">
                            Is Placeholder?
                      </label>
                    </div>
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="kit" :disabled="formData.kitId.value !== ''" v-validate v-model="formData.kit.value">
                            Equipment Kit
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-info" v-if="loaded">
        <div class="panel-body form-horizontal">
            <div class="form-group text-center">
                When the current equipment has been chosen, suggest the following equipment
            </div>
            <div class="col-sm-7 col-md-7">
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Type</label>
                    <div class="form-control-100">
                        <select class="form-control" name="suggestionType" v-validate="formData.suggestionType.validator" v-model="formData.suggestionType.value" v-if="equipmentTypeEntity" :disabled="equipmentList.length < 1">
                            <option value="">None</option>
                            <option v-for="type in equipmentTypeEntity.get.items" :value="type.id">{{type.label}}</option>
                        </select>
                        <p class="help-block" v-if="!equipmentAvailable && formData.suggestionType.value">No equipment available under this equipment type.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 col-md-5">
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Equipment</label>
                    <div class="form-control-100">
                        <select class="form-control" name="suggestion_id" v-model="formData.suggestionId.value" :disabled="suggestionIsDisabled">
                            <option value="">None</option>
                            <option v-for="equipment in equipmentList" :value="equipment.id" v-if="equipment.type == formData.suggestionType.value && equipment.id != id">{{equipment.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-info" v-if="loaded && !formData.kit.value">
        <div class="panel-body form-horizontal">
            <div class="col-sm-5 col-md-5">
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Equipment Kit</label>
                    <div class="form-control-100">
                        <select class="form-control" name="kitId" v-validate v-model="formData.kitId.value">
                            <option value="">None</option>
                            <option v-for="equipment in equipmentList" :value="equipment.id" v-if="equipment.kit && equipment.id != id">{{equipment.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="panel panel-info" v-if="loaded">
        <div class="panel-heading">
            Dataminer Properties
        </div>
        <div class="panel-body form-horizontal">
            <div class="col-sm-12 col-md-12">
                 <div class="form-group" :class="{'has-error': formErrors.has('se_dms_port_no')}">
                        <label for="se_dms_port_no" class="control-label book-width-100">SE DMS PortNo</label>
                        <div class="form-control-100">
                            <input class="form-control" name="se_dms_port_no" v-validate="formData.se_dms_port_no.validator" v-model="formData.se_dms_port_no.value">
                        </div>
                </div>
                <div class="form-group">
                        <label for="se_dms_type" class="control-label book-width-100">SE DMS Type</label>
                        <div class="form-control-100">
                            <input class="form-control" name="se_dms_type" v-validate v-model="formData.se_dms_type.value">
                        </div>
                </div>            
                <div class="form-group">
                    <label for="se_dms_info_id" class="control-label book-width-100">SE DMS Info</label>
                    <div class="form-control-100">
                        <select class="form-control" name="se_dms_info_id" v-validate v-model="formData.se_dms_info_id.value" v-if="equipmentDmsInfoEntity">
                            <option value="" :selected="formData.se_dms_info_id.value === ''">None</option>
                            <option v-for="dmsInfo in equipmentDmsInfoEntity.get.items" :value="dmsInfo.id" :key="dmsInfo.id" :selected="formData.se_dms_info_id.value === dmsInfo.id">{{dmsInfo.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="book-form-actions text-right book-bottom-sticky" v-if="loaded">
        <button class="btn btn-primary" @click="submit()" :disabled="isFormClean || !isFormValid">Save</button>
        <router-link exact :to="{name: 'equipment'}" class="btn btn-danger">Cancel</router-link>
    </div>
</div>
</template>
