import Api from 'components/app/Api'

class Production extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/production',
            module: 'production',
        })
    }

    getApiUrl() {
        return 'api/production/' + this.options.id
    }
}

export default Production