/**
 * This is a base mixin/component used by Feeds and Productions.
 */

import {app} from 'app'
import Production from 'components/production/Production'
import Entity from 'components/entity/Entity'
import TaskItem from 'components/common/item/task/TaskItem'
import Lock from 'components/common/lock/Lock'
import SocketMixin from 'components/common/item/SocketMixin'

export default {
    mixins: [
        SocketMixin
    ],
    data() {
        return {
            loaded: false,
            noResult: false,
            filterReady: false,
            selectedItem: null,
            sidebar: false,
            sidebarOpened: false,
            filters: {},
            runTypeEntity: null,
            ingestEntity: null,
            timeline: {
                startTimestamp: 0,
                endTimestamp: 0,
                groupByLeague: 'league'
            },
            delay: 700,
            clicks: 0,
            timer: null,
        }
    },
    watch: {
        '$route': function(to, from) {
            const context = this
            // @TODO Should we call the API only if the new date interval is
            // outside or bigger then the current one?
            console.log('woo changing')
            context.loadData()
                .then(response => {
                    context.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) {
                        context.$error.set(error)
                    }
                    context.setLoaded()
                    context.noResult = true
                })
        },
    },
    computed: {
        setting() {
            let settings = this.child.settings ? this.child.settings : this.child.items
            return this.$settings.get[settings]
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        hasPreservedFilters() {
            return this.$store.state.filter.preserve ? true : false
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this[this.child.items].length
            filterInfo.total = this.itemsTotal
            return filterInfo
        },
        itemsTotal() {
            const context = this
            if (!context.loaded || !this.$store.state.data[this.child.item] || !context.filterReady) return 0
            return this.$store.state.data[this.child.item]
                .filter(v => {

                    // On timeline view, only items with start/end inside the current
                    // "from" day should be visible.
                    if (context.$route.params.view == 'timeline') {
                        if (v.startTime > context.timeline.endTimestamp || v.endTime < context.timeline.startTimestamp) return false
                    }

                    return true

                }).length
        },
        loadingSelectedItem() {
            return this.$loader.has('loadingSelectedItem')
        },
    },
    mounted() {
        const context = this
        if (!context.child) return
        const dataToLoad = []
        //context.setLoaded(false)
        this.$store.commit('remove', {key: context.child.item, id: 0})
        dataToLoad.push(this.loadEntities(context.child.entities))


        Promise.all(dataToLoad)
            .then(response => {
                // Lets try creating filters first? maybe.. Since we are also filtering items after we load even
                // then we can load filtered items faster ~almost 6-8 second
                // Pages that affected: all but MCR-View. (WN)
                context.createFilters() // 24,98 (500 - 600 items) 17,56

                // Now, when all required entities are loaded, try to load the data.
                context.loadData()
                    .then(response => {
                        //context.createFilters() // 32,71 (500 - 600 items) 22,95
                        context.setLoaded()
                    })
                    .catch(error => {
                        if (error.response && error.response.status != 404) {
                            context.$error.set(error, `It was not possible to load the ${this.child.items}`)
                        }
                        context.createFilters()
                        context.setLoaded()
                        context.noResult = true
                    })

            })
            .catch(error => {
                if (error.response && error.response.status != 404) {
                    context.$error.set(error, `It was not possible to load the entities.`)
                }
                context.setLoaded()
                context.noResult = true
            })

        // Listen to ingestion updates.
        app.$on('changedIngestions', (data) => {
            this.updateIngestions(data)
        })

    },

    methods: {

        /**
         * Load data from API.
         */
        loadData() {
            const context = this

            // Set timeline parameters.
            let timelineStart = moment(context.$route.query.from)
            context.timeline.startTimestamp = timelineStart.unix()
            let timelineEnd = moment(timelineStart).add(30, 'hours').subtract(1, 'minute')
            context.timeline.endTimestamp = timelineEnd.unix()

            context.selectedItem = null
            // WN close it down
            context.setLoaded(false)

            return new Promise((resolve, reject) => {
                const itemClass = new Production(this.child.item)
                const params = context.getRequestParams ? context.getRequestParams : {}

                itemClass.all(params, true)
                    .then(response => {
                        // Parse the retrieved items and add some extra properties.
                        this.$store.state.data[context.child.item].forEach(v => {
                            TaskItem.parse(v, context.child.item, this.$store.state.app.now)
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })

        },

        /**
         * Load the passed entities from the API.
         * @param object entities
         * @return promise
         */
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    if (context.setting[v]) {
                        entityIds.push(context.setting[v])
                    }
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /**
         * Set this page to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
            if (!loaded) this.noResult = false
            else {
                Vue.nextTick(() => {
                    this.updateItemFlags()
                })
            }
        },

        /**
         * Get the event name to be presented in the list/timeline.
         * @return string
         */
        getEventName(item) {
            const context = this
            let eventName = item.title
            
            return eventName
        },

        /**
         * Select an item and show it in the sidebar.
         * @param integer id The item id.
         */
        selectItem(id) {
            if (this.selectedItem && (id == this.selectedItem.id)) this.selectedItem = null
            else {
                const item = this[this.child.items].find(v => v.id == id)
                this.showInSidebar(item)
            }
        },

        /**
         * Delete a given feed/production from API by its id.
         * @param integer id The feed id.
         */
        deleteItem(id) {
            const context = this
            const itemClass = new Production(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            if (context.selectedItem && (context.selectedItem.id == id)) {
                                context.selectedItem = null
                            }
                            context.$alert.set(`The ${context.child.item} was deleted!`)
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the ${context.child.item}!`)
                        })
                })
        },

        /**
         * Update the item flags for all items
         */
        updateItemFlags() {
            const context = this
            const now = moment()

            // Loop through all current feeds/productions and emit events for all locked
            // and newly created items.
            context[context.child.items]
                .forEach(v => {
                    if (v.lock && Lock.isLocked(v.lock)) {
                        app.$emit('itemLocked', {type: context.child.item, id: v.id, author: v.lock.user.name})
                    }
                    if (now.diff(v.created, 'seconds') < app.$store.state.config.ITEM_FLAG.NEW_VISIBILITY) {
                        app.$emit('itemCreated', {type: context.child.item, id: v.id, author: '', persistent: true})
                    }
                    //update should be here
                    if (now.diff(v.updated, 'seconds') < app.$store.state.config.ITEM_FLAG.UPDATE_VISIBILITY && now.diff(v.updated, 'seconds') != now.diff(v.created, 'seconds')) {
                        app.$emit('itemUpdated', {type: context.child.item, id: v.id, author: '', persistent: true})
                    }
                })

        },

        /**
         * Set signoff data for a given item.
         * @param object data
         * @param string type "updated" or "deleted"
         */
        setSignoff(data, type = 'created') {
            const item = new Production(data.itemId)
            const signoffs = item.get.signoffs
            signoffs
                .filter(v => v.typeId == data.typeId)
                .forEach(v => {
                    v.signed = (type == 'created') ? data : null
                })
            item.set('signoffs', signoffs)

            // Now, in order to trigger reactivity update of the current item
            // everywhere, we need to "overwrite" it, which feed.add() does.
            const newItem = Object.assign({}, item.get)
            TaskItem.parse(newItem, this.child.item, this.$store.state.app.now)
            item.add(newItem)

        },

        /**
         * Create the filter items.
         */
        createFilters() {
            const context = this
            if (!context.availableFilters) return
            Lazy(context.availableFilters)
                .each((v, k) => {
                    switch (true) {
                        case (k == 'workgroups'):
                            if(context[v.class] && context[v.class].get && context[v.class].get.items && context[v.class].get.items.length > 0) {
                                context[v.class].get.items.forEach(v2 => {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label
                                    })
                                })
                            }
                            break
                        case (k == 'activityType'):
                            if(context[v.class] && context[v.class].get && context[v.class].get.items && context[v.class].get.items.length > 0) {
                                context[v.class].get.items.forEach(v2 => {
                                    let llb = v2.country ? ' (' + v2.country + ')' : ' (Global)'
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label + llb
                                    })
                                })
                            }
                            break
                        case (k == 'country'):
                            v.items.push({
                                id: "",
                                label: 'Global (all)'
                            })
                            context.countries.forEach(v2 => {
                                v.items.push({
                                    id: v2.code,
                                    label: v2.label
                                })
                            })
                            
                            break
                    }
                })

            // Set default selected country based on the current user's country.
            if (app.$data.user.get.country && context.$store.state.filter.myFiltersSelected === '') {
                switch (true) {
                    case (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items')):
                        Lazy(context.availableFilters.country.items)
                            .filter(v => {
                                return v.id == app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                }
            }
            context.filterReady = true
        },

        /**
         * Set a filter value.
         * @param object filter
         */
        filteredBy(filters) {
            this.filters = filters
        },
        getEntityItem(name, entityId) {
            const entityItem = this[name + 'Entity'].getItem(entityId)
            return entityItem ? entityItem : null
        },
        getEntityItemLabel(name, entityId) {
            const entityItem = this.getEntityItem(name, entityId)
            return entityItem ? entityItem.label : ''
        },

        /**
         * Callback used when event "ingestionUpdated" is triggered.
         * @param object data The data (incl ingestion items) returned by the event.
         */
        updateIngestions(data) {
            if (data.itemType != this.child.item) return
            const itemClass = new Production(data.itemId)
            itemClass.set('ingestions', data.items)
        },

        /**
         * Load an item and show it in the sidebar.
         */
        showInSidebar(item) {
            //console.log("open sidebar");
            this.selectedItem = null
            //this.$loader.add('loadingSelectedItem')

            const itemClass = new Production(item.id)
            // Added quick fix. WN
            this.selectedItem = itemClass.get

            // Check if anything needs to update and update it without render,
            // Do not call anything if its feed, we have everything we need. WN
            //if (!this.selectedItem.feedStart) {
                itemClass.load(item.id)
                    .then(response => {
                        const now = moment().unix()
                        TaskItem.parse(response.data, this.child.item, now)
                        this.selectedItem = itemClass.get
                    })
                    .catch(error => {
                        this.$error.set(error)
                    })
            //}

        },

        oneClick(item, link){
            var context = this;
            this.clicks++ 
            if(this.clicks === 1) {
              var self = this
              this.timer = setTimeout(function() {
                context.showInSidebar(item)
                self.clicks = 0
              }, this.delay);
            } else{
               clearTimeout(this.timer);  
               //context.$router.push(link)
                context.openNewTab(link)
               this.clicks = 0;
            }        	
          },
        /**
         * Shows House Number with comma instead of JSON
         * @param item
         * @param parser
         * @returns {*}
         * wN
         */
        showHouseNumberField(item, parser, section = null) {
            const userCountry = app.$data.user.get.country
            let _hn = item.houseNumber
            if (_hn) {
                if (_hn === '{}') return
                if (isJsonSring(_hn)) {
                    _hn = JSON.parse(_hn)
                    let _ct = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi'], _countries = {}
                    Lazy(_ct).each((v,k) => { _countries[v] = '' })
                    Lazy(_countries).each((v, k) => { if (_hn[k] !== undefined) _countries[k] = _hn[k] })

                    const allEmpty = Object.keys(_countries).every(function(key){
                        return _countries[key].length === 0
                    })
                    if (allEmpty) return

                    if (parser === 'rawValue') return _countries = JSON.stringify(_countries)
                    if (parser === 'value') return _countries[userCountry]
                }
                else {
                    return _hn
                }
            } else {
                return _hn
            }
        },
        /*
         * Open and close new tab functions for production page and
         * possible for other pages as well.
         - WN
         */
        getTabRoute() {
            return this.$route.query.newBar;
        },
        openNewTab(link) {
            const context = this
            if (link.name === 'task.edit') {
                let routeData = context.$router.resolve(link);
                window.open(routeData.href, '_blank');
            }
            else {
                return context.$router.push(link)
            }
        },
        closeNewTab(link) {
            window.close();
        }
    }

}
