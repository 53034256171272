<script>
import UserNotifications from 'components/usernotifications/UserNotifications'
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookLoader
    },
    // socket: {
    //     'private:alerts': {
    //         '.alert.created': function(payload, context) {
    //             if(payload.data && payload.data.userId === app.$data.user.id) {
    //                 app.$emit('alertCreated', payload.data)
    //             }
               
    //         },
    //         '.alert.updated': function(payload, context) {
    //             if(payload.data && payload.data.userId === app.$data.user.id) {
    //                 app.$emit('alertUpdated', payload.data)
    //             }
    //         },
    //         '.alert.deleted': function(payload, context) {
    //             if(payload.data && payload.data.userId === app.$data.user.id) {
    //                 app.$emit('alertDeleted', payload.data)
    //             }
    //         },
    //     }
    // },
    data() {
        return {
            showNavbar: true,
            isDevelopment: false,
            loaded: false
        }
    },
    mounted() {
        const params = {
                includeNotReadNotificationsOnly: false
            }
            const context = this
            if(!context.notifications) {
                const notifications = new UserNotifications()
                notifications.getUserNotifications(params, true)
                    .then(response => {
                        console.log(context.notifications)
                        context.loaded = true
                    })
                    .catch(error => {
                        console.log(error)
                        context.loaded = true
                        context.$error.set(error, `It was not possible to load the notifications`)
                    })
            }
            else {
                context.loaded = true
            }
            
        

        app.$on('alertCreated', (data) => {
            this.addUserNotification(data)
        })
        app.$on('alertUpdated', (data) => {
            this.updateUserNotification(data)
        })
        app.$on('alertDeleted', (data) => {
            this.deleteUserNotification(data)
        })
    },
    computed: {
        notifications() {
            return this.$store.state.data.notReadNotifications ? this.$store.state.data.notReadNotifications : null
        },
        readNotifications() {
            return this.$store.state.data.readNotifications ? this.$store.state.data.readNotifications : null
        },
        hasNotReadRows() {
            return this.notifications && this.notifications.length > 0
        },
        hasReadRows() {
            return this.readNotifications && this.readNotifications.length > 0
        }
    },
    methods: {
        getDestination(link) {
            if(link) {
                const parsedLink = JSON.parse(link)
                const keys = Object.keys(parsedLink)
                const paramKeys = keys.filter(k => k !== 'name')
                const params = {}
                paramKeys.forEach(k => {
                    params[k] = parsedLink[k]
                })
                const returnLink = {
                    params: params,
                    query: {
                        destination: this.$route.name,
                        from: this.$route.query.from,
                        to: this.$route.query.to,
                    }
                }
                
                returnLink.name = parsedLink.name
                
                return returnLink
            }
            else return ''
        },
        addUserNotification(notification) {
            const context = this
            
            if(context.notifications.notReadNotifications.length > 0) {
                let prioritySeparator = _.findIndex(context.notifications.notReadNotifications, n => n.priority >= notification.priority)
                if(prioritySeparator === 0) {
                     context.notifications.notReadNotifications.unshift(notification)
                }
                else if(prioritySeparator === -1) {
                    context.notifications.notReadNotifications.push(notification)
                }
                else {
                    context.notifications.notReadNotifications.splice(prioritySeparator, 0, notification)
                }

            }
            else {
                context.notifications.notReadNotifications.push(notification)
            }
            
        },
        updateUserNotification(notification) {
            if(notification.status) {
                this.deleteUserNotification(notification)
            }
            else {
                this.addUserNotification(notification)
            }
        },
        deleteUserNotification(notification) {
            const context = this
            let  indx = _.findIndex(context.notifications.notReadNotifications, n => n.id === notification.id)
            if(indx > -1) {
                context.notifications.notReadNotifications.splice(indx, 1)
            }
        },
        markNotificationsAsRead() {
            const context = this
            if(context.notifications.notReadNotifications && context.notifications.notReadNotifications.length > 0) {
                /**
                 * We mark as read only notifications with type normal.
                 * Notifications with type special handles with individual logic
                 */
                let normalNotifications = _.filter(context.notifications.notReadNotifications, n => n.type === 'normal')
                if(normalNotifications && normalNotifications.length > 0) {
                    let ids = normalNotifications.map(i => i.id)
                    setTimeout(() => {
                        const notifications = new UserNotifications()
                        notifications.markNotificationsRead({ids: ids})
                            .then(response => {
                                console.log(ids)
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    }, 180000)
                }
                
            }
        }
    }
}
</script>
<template>
    <div id="usernotificationlist">
        <book-loader v-if="!loaded"></book-loader>
        <div class="panel panel-info" v-if="loaded && !hasNotReadRows && !hasReadRows">
            <div class="panel-body panel-body-center">
                <p>No notifications found</p>
            </div>
        </div>
        <div class="panel panel-info" v-if="loaded && hasNotReadRows" v-for="notification in notifications" :key="notification.id">
            <div class="panel-heading panel-heading-bold">
                
                   <router-link tag="div" :to="getDestination(notification.link)" class="notification-time">
                       
                        <a data-target="#navbar">{{notification.title}}</a>
                        <span>{{notification.created}} </span>
                    </router-link>
                        
            </div>
            <div class="panel-body">
                <p class="notification-message" v-if="notification.message">{{notification.message}}</p>
            </div>
        </div>
        <div class="panel panel-info" v-if="loaded && hasReadRows" v-for="notification in readNotifications" :key="notification.id">
            <div class="panel-heading">
                
                <router-link tag="div" :to="getDestination(notification.link)" class="notification-time">
                    
                    <a data-target="#navbar">{{notification.title}}</a>
                    <span>{{notification.created}} </span>
                </router-link>
                        
            </div>
            <div class="panel-body">
                <p class="notification-message" v-if="notification.message">{{notification.message}}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#usernotificationlist {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;   

    .panel-heading-bold {
        font-weight: bold;
    }
    .panel-body-center {
        text-align: center;
    }

    .notification-time{
        position: relative;
    }

    .notification-time a {
        width: calc(100% - 120px);
        display: block;
    }

    .notification-time span{
        position: absolute;
        right: 0px;
        top: 0%;
    }

}
</style>