<script>
import Acl from 'components/user/Acl'
import User from 'components/user/User'
import System from 'components/system/System'
import {app} from 'app'

import BookUsersActionMenu from 'components/user/BookUsersActionMenu';
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookUsersActionMenu,
        BookLoader
    },
    mounted() {
        this.$loader.add('usersLoad')
        const users = User.getUsers({fields: 'roles'})
        const roles = Acl.getRoles()
        Promise.all([users, roles])
            .then(response => {
                this.$loader.remove('usersLoad')
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the users list.')
                this.$loader.remove('usersLoad')
            })
    },
    computed: {
        app() {
            return app
        },
        loaded() {
            return this.availableRoles && this.users && !this.$loader.has('usersLoad')
        },
        users() {
            return this.$store.state.user.users
        },
        availableRoles() {
            return this.$store.state.user.roles
        },
        system() {
            return System
        }
    },
    methods: {
        isErasable(id) {
            return id != app.$data.user.id
        },
        deleteUser(id) {
            const context = this
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete user ' + id + '?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                User.delete(id)
                .then(response => {
                    if (response.status === 200) {
                        let i = context.users.findIndex(v => v.id === id)
                        context.users.splice(i, 1)
                        context.$alert.set(`'User ${id} has been deleted!`, 'success', 4)
                    }
                })
                .catch(error => {
                    context.$error.set(error, `It was not possible to delete user ${id}`)
                })
            })
        }
    }

}
</script>

<template>
<div id="users-list" class="admin book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
        <h2 class="page-title">Manage users</h2>
        <book-users-action-menu></book-users-action-menu>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div class="panel panel-info" v-else>
        <div class="table-responsive">
            <table class="table table-striped table-hover" >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>E-mail</th>
                        <th>Role(s)</th>
                        <th>Country</th>
                        <th>Login count</th>
                        <th>Last login</th>
                        <th>Updated</th>
                        <th>Created</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <transition-group name="slide-fade" tag="tbody" mode="out-in">
                    <tr v-for="user in users" v-bind:key="user.id" v-bind:class="{'success': $route.query.change && $route.query.change == user.id}" class="cell-vertical-align-middle">
                        <td>{{user.id}}</td>
                        <router-link tag="td" :to="{name: 'user.id', params: {id: user.id}, query: {destination: 'admin.users'}}">
                            <a>{{user.name}}</a>
                        </router-link>
                        <td>{{user.email}}</td>
                        <td>
                            <span v-if="user.roles && Object.keys(user.roles).length > 0" v-for="(role, key) in user.roles">
                                {{availableRoles[key].label}}<span v-if="key+1 < user.roles.length">, </span>
                            </span>
                        </td>
                        <td>{{system.getCountryById(user.country)}}</td>
                        <td>{{user.loginCount}}</td>
                        <td>{{user.lastLogin}}</td>
                        <td>{{user.updated}}</td>
                        <td>{{user.created}}</td>
                        <td>
                            <span class="label label-danger" v-if="!user.active">inactive</span>
                        </td>
                        <td class="action">
                            <router-link
                                v-if="$ability.can('update', 'Users')"
                                :to="{name: 'user.id.edit', params: {id: user.id}, query: {destination: 'admin.users', change: user.id}}"
                                class="btn btn-primary btn-xs">
                                <font-awesome-icon icon="pencil-alt"/>                                
                            </router-link>
                            <button
                                @click="deleteUser(user.id)"
                                v-if="$ability.can('delete', 'Users') && isErasable(user.id)"
                                class="btn btn-danger btn-xs">
                                <font-awesome-icon icon="trash"/>                                
                            </button>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>
    </div>
</div>
</template>
