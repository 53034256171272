<script>
import {app} from 'app'
//import BookLoader from 'components/common/loader/BookLoader'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookIngestion from 'components/ingestion/BookIngestion'
import BookMcrStatus from 'components/mcr/McrStatus/BookMcrStatus'
import BookModal from 'components/common/BookModal'
import BookSelectbox from 'components/common/BookSelectbox'
import BookUpload from 'components/common/BookUpload'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
import ItemFlagsMixin from 'components/common/item/ItemFlagsMixin'
//import Production from 'components/production/Production'
//import Feed from 'components/feed/Feed'
import Mcrdata from 'components/mcr/Mcrdata'
import Item from 'components/common/item/Item'
import _ from 'lodash';

export default {
  mixins: [ItemFlagsMixin],
  components: {
    BookIngestion,
    BookMcrStatus,
    BookModal,
    BookSelectbox,
    BookUpload,
    Entity,
    BookEquipmentSelection,
    Equipment,
  },
  props: {
    items: {
      default: () => {
        return {}
      },
      type: Object
    },
    selected: {
      default: null
    },
    options: {
      default: () => {
        return {}
      },
      type: Object
    },
    notFoundMessage: {
      default: 'No data found!',
      type: String
    },
    locked: {
      default: false,
      type: Boolean
    },
    ingestors: {
      default: null,
      type: Object
    },
    waiting: {
      default: () => {
        return {}
      },
      type: Object
    },
    sending: {
      default: () => {
        return {}
      },
      type: Object
    },
    entities: {
      default: () => ({})
    },
    allEquipment: {
      default: () => {
        return []
      },
      type: Array
    },
    darkMode: {
      default: false
    },
  },
  data() {
    console.log('We are loading data');
    return {
      loaded: false,
      processedItems: JSON.parse(JSON.stringify(this.items)),
      filters: {},
      sort: {
        column: '',
        reverse: false
      },
      equipmentSelectionItem: null,
      sidebar: false,
      timer: '',
      saveTimer: '',
      autoSaveTimer: '',
      autoSave: false,
      renderKey: 0,
      scrolled: false,
      scrollPos: {
        X: null,
        Y: null,
      },
      expandedRows: [],
      expandedAllRows: false,
      formData: this.defaultData(),
      showDocuments: {
        open: false,
        files: [],
        type: null,
        usageItem: {}
      },
      showColumn: {
        showId: {
          type: false,
          time: false,
          league: false,
          info: false,
          title: false,
          attachment: false,
          equipment: false,
          ingestions: false,
          txStatus: false,
          viaplay: false,
          mcrStatus: false
        },
        type: true,
        time: true,
        info: true,
        league: true,
        title: true,
        attachment: true,
        equipment: true,
        ingestions: true,
        txStatus: true,
        viaplay: true,
        mcrStatus: true
      },
    }
  },
  watch: {
    items: {
      handler: function(newValue) {
        if (!this.loaded) return

        console.log('I am MCR-View rendering..', this.$route);

        this.processedItems = JSON.parse(JSON.stringify(newValue))
        this.processItems()
      },
      //deep: true
    },
  },
  created() {
    console.log('MCR-View listeners, created.')
    app.$on('AutoChangeIngestions', (data) => {
      if (data && data.action) this.updateAction(data)
      this.autoChangeIngestions(data)
    })
    app.$on('AutoChangeMcrStatus', (data) => {
      if (data && data.action) this.updateAction(data)
      this.autoChangeMcrStatus(data)
      this.alignMcrStatus(data)
    })
    app.$on('AutoChangeHouseNumber', (data) => {
      if (data && data.action) this.updateAction(data)
      this.autoChangeHouseNumber(data)
    })
    app.$on('AutoChangeEquipments', (data) => {
      if (data && data.action) this.updateAction(data)
      this.autoChangeEquipments(data)
    })
    app.$on('AddFeedback', (data) => {
      if (data && data.action) this.updateAction(data)
    })
    app.$on('AddPlaceholder', (data) => {
      if (data && data.action) this.updateAction(data)
    })
    app.$on('clearTimers', (data) => {
      this.clearSaveTimer()
    })

  },
  beforeDestroy () {
    console.log('Disabling MCR-View listeners.')
    app.$off('AutoChangeIngestions')
    app.$off('AutoChangeMcrStatus')
    app.$off('AutoChangeHouseNumber')
    app.$off('AutoChangeEquipments')
    app.$off('AddFeedback')
    app.$off('AddPlaceholder')
    app.$off('clearTimers')

    this.clearSaveTimer()
    clearInterval(this.timer)
    // remove listener
    this.removeListener()
  },
  beforeMount() {
    this.clearSaveTimer()
    if (this.hasHidedColumns) {
      this.showColumn = this.$store.state.filter.columnOptions
    }
  },
  mounted() {
    // check if events are active
    if (app._events.AutoChangeEquipments === null) {
      console.log('Reloading MCR-View listeners..')
      app.$on('AutoChangeIngestions', (data) => {
        if (data && data.action) this.updateAction(data)
        this.autoChangeIngestions(data)
      })
      app.$on('AutoChangeMcrStatus', (data) => {
        if (data && data.action) this.updateAction(data)
        this.autoChangeMcrStatus(data)
        this.alignMcrStatus(data)
      })
      app.$on('AutoChangeHouseNumber', (data) => {
        if (data && data.action) this.updateAction(data)
        this.autoChangeHouseNumber(data)
      })
      app.$on('AutoChangeEquipments', (data) => {
        if (data && data.action) this.updateAction(data)
        this.autoChangeEquipments(data)
      })
      app.$on('AddFeedback', (data) => {
        if (data && data.action) this.updateAction(data)
      })
      app.$on('AddPlaceholder', (data) => {
        if (data && data.action) this.updateAction(data)
      })
      app.$on('clearTimers', (data) => {
        this.clearSaveTimer()
      })
    }
    // Set default sort column.
    this.sort.column = this.currentOptions.sortBy ? this.currentOptions.sortBy : ''
    console.log("MCR-View mounted");

    // set the timer for 1 minute to render events
    this.timer = setInterval(() => {
      this.showActivityColors()
    }, 60500)


    //do not try to processItems if theres no items WN
    if (this.currentNumItems !== 0) {
      this.processItems()
    }

    this.loaded = false
  },
  computed: {
    currentOptions() {
      return Object.assign({}, this.defaultOptions(), this.options)
    },
    getItems() {
      if (!this.loaded) {
        return null
      }
      console.log("getItems");
      return this.processedItems
    },
    rows() {
      console.log("rows");
      if (!this.loaded || !this.getItems) {
        return []
      }
      if (!this.sort.column) {
        return this.getItems.rows
      }
      // Sorting list items regarding their time values
      return Lazy(this.getItems.rows)
          .sortBy(row => {
            let sortValue = []
            let sortColumn = this.currentOptions[this.sort.column + 'Field'] ? this.currentOptions[this.sort.column + 'Field'] : this.sort.column
            sortValue.push(row[sortColumn].rawValue ? row[sortColumn].rawValue : row[sortColumn].value)
            sortValue.push(row.rowOptions.weight)
            return sortValue
          }, this.sort.reverse)
          .toArray()
    },
    hasActions() {
      return this.getItems && this.getItems.options && this.getItems.options.actions && this.getItems.options.actions.length > 0
    },
    hasIngestions() {
      return this.getItems && this.getItems.options && this.getItems.options.ingestions && this.getItems.options.ingestions.length > 0
    },
    ingestReadOnly() {
      return this.getItems.options.ingestSettings && this.getItems.options.ingestSettings.readonly
    },
    hasHidedColumns() {
      return !!this.$store.state.filter.columnOptions
    },
    preserveColumnOptions: {
      get() {
        return !!this.$store.state.filter.columnOptions
      },
      set(value) {
        if (value) this.$store.commit('filter/setColumnOptions', value)
        else  this.$store.commit('filter/removeColumnOptions')
      }
    },
  },
  methods: {
    mainColumnOptions(column, row) {
      if (column.value === 'info' && this.isRowMCRComment(row)) {
        return false
      }
      return this.showColumn[column.value] && column.visible && (['equipment', 'associations'].indexOf(column.value) === -1)
    },
    isRowMCRComment(row) {
      return row.type.extraValue === 'mcrComment' && row.league.value === ''
    },

    autoChangeHouseNumber(data) {
      let output = this.rows
      Lazy(output).each((v,k) => {
        if (v.id.rawValue === data.itemId) {
          console.log(v, k, 'my row')
          let prevHNVal = this.houseNumberField(v.houseNumber.rawValue, 'allValues', null, false)
          let newHNVal = this.houseNumberField(data, 'allValues')
          v.houseNumber.rawValue = this.houseNumberField(data, 'rawValue')
          v.houseNumber.value = this.houseNumberField(data, 'value')

          let newHouseNumbers = null
          if(newHNVal !== '') {
            newHouseNumbers = Object.assign({}, newHNVal)
            for(const h in newHouseNumbers) {
              newHouseNumbers[h] = newHouseNumbers[h] !== null && newHouseNumbers[h] !== '' ? this.isForFork(newHouseNumbers[h], h) : false
            }
          }


          let placeholderItem = {
            itemType: data.itemType,
            itemId: data.itemId,
            houseNumber: newHouseNumbers
          }

          app.$emit('updateRecPlaceholder', placeholderItem)
        }
        if (v.associations) {
          Lazy(v.associations).each(a => {
            if (a.id.value === data.itemId) {
              a.houseNumber.rawValue = this.houseNumberField(data, 'rawValue')
              a.houseNumber.value = this.houseNumberField(data, 'value')
            }
          })
        }
      })
      // prepare sending data
      if (this.waiting.items.length > 0) {
        this.sending.items.push(data)
      }
    },
    /**
     * checks ingest entity and returns if this housenumber value marked
     * for export to FORK
     * @param {*} value //housenumber value
     * @param {*} country //housenumber country
     */
    isForFork(value, country) {
      // one letter only and rest integer is requested from FORK integration team ( should be applied to 'int' )
      const determineIngestOperators = (value) => {
        if (!value) return 'blank'
        let oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
        if (oneLetterOrOnlyIntegers.test(value.trim())) return 'int'
        return value;
      }

      const findIngestEntity = (val, country) => {
        let ingestEntityItems = this.ingestors && this.ingestors.get && this.ingestors.get.items.length > 0 ? this.ingestors.get.items : null
        return ingestEntityItems ? _.find(ingestEntityItems, i => i.country === country && (i.label === val || i.shortlabel === val || i.userfield2_value === val)) : null
      }

      let val = determineIngestOperators(value)
      let ing = findIngestEntity(val, country)
      return ing ? ing.description === 'yes' : false
    },
    autoChangeIngestions(data) {
      const context = this
      let output = this.rows
      Lazy(output).each((v,k) => {
        if (v.id.rawValue === data.itemId) {
          console.log(v, 'gg', data.delete)
          v.rowOptions.ingestions = data.items
          v.updated = !data.delete
          if (data.delete) v.renewed = true
        }
        if ( v.associations) {
          Lazy(v.associations).each(a => {
            if (a.id.value === data.itemId) {
              a.rowOptions.ingestions = data.items
            }
          })
        }
      })
    },
    autoChangeMcrStatus(data) {
      let output = this.rows
      Lazy(output).each((v,k) => {
        if (v.id.rawValue === data.itemId) {
          console.log(v, k, 'my row')
          v.rowOptions.mcrStatus = data.items
          v.updated = !data.delete
          if (data.delete) v.renewed = true
        }
        if (v.associations) {
          Lazy(v.associations).each(a => {
            if (a.id.value === data.itemId) {
              a.rowOptions.mcrStatus = data.items
            }
          })
        }
        if (v.contributions) {
          Lazy(v.contributions).each(c => {
            if (c.id.value === data.itemId || c.id.rawValue === data.itemId) {
              c.rowOptions.mcrStatus = data.items
            }
          })
        }
        if (v.equipment) {
          Lazy(v.equipment).each(e => {
            Lazy(e.items).each(i => {
              if (i.equipmentUsage.id === data.itemId) {
                i.equipmentUsage.mcrStatus = data.items
              }
            })
          })
        }

      })
    },
    autoChangeEquipments(data) {
      let output = this.rows
      // format equipments
      let equipments = []
      Lazy(data.items).each(e => {
        equipments.push ({
          id: e.id,
          label: e.label,
          items: [e]
        })
      })

      Lazy(output).each((v,k) => {
        if (v.id.rawValue === data.itemId && v.type.rawValue === data.itemType) {
          v.equipment = equipments
          if (data.action) v.updated = !data.delete
          if (data.delete) v.renewed = true
        }
        if (v.associations && v.type.rawValue !== 'contribution') {
          Lazy(v.associations).each(a => {
            if (a.id.value === data.itemId && a.type.rawValue === data.itemType) {
              a.equipment = equipments
            }
          })
        }
      })

    },
    forgotChanges() {
      const context = this
      if (context.waiting.items.length > 0) {
        // WN
        swal({
          title: 'You have changes!',
          text: 'Been 5 minute, would you like to save your changes?',
          type: 'question',
          confirmButtonText: 'Save',
          cancelButtonText: 'No',
          showCancelButton: true,
          showCloseButton: true
        })
            .then(function () {
              app.$emit('saveMyChanges')
            }, function (dismiss) {
              if (dismiss == 'cancel') {
                // ok
              }
            })
      }
    },
    setSaveTimer() {
      const context = this
      clearTimeout(context.saveTimer)
      context.saveTimer = setTimeout(() => { context.forgotChanges() }, 300000)
    },
    clearSaveTimer() {
      const context = this
      let saveTimer = context.saveTimer
      let autoSaveTimer = context.autoSaveTimer
      clearTimeout(saveTimer)
      clearTimeout(autoSaveTimer)
    },
    updateAction(data) {
      console.log(data, 'incoming data')
      const ctx = this
      const items = ctx.waiting.items
      const module = data.action.module
      const action = data.action.type
      let itemId = data.item.id
      let itemIdLength = itemId.toString().length
      // remove the same existence
      if (items.length > 0) {
        items.slice(0)
        let i = items.findIndex(v => v.item.id === itemId)
        if (i > -1) {
          items.splice(i, 1)
          console.log('FOUND USAGE - DELETE', itemId, i)
        }
        // which is updated
        if (data.delete && data.action.type === 'store') {
          let ni = items.findIndex(v =>
              v.itemId === data.itemId && v.itemType === data.itemType &&
              v.action.module === data.action.module)
          if (ni > -1) {
            items.splice(ni, 1)
            console.log('FOUND CLONE ADDING - DELETE', itemId, ni)
          }
        }

      }
      // if its delete operation dont add clone id (12 digit).
      if (itemIdLength > 11 && action !== 'delete' && !data.delete) {
        console.log('FOUND USAGE CLONE', itemId)
        items.push(data)
        ctx.waiting.updates = true
      }
      // if its not a clone accept all operations
      if (itemIdLength < 12 && !data.delete) {
        console.log('FOUND USAGE NORMAL', itemId, itemIdLength)
        items.push(data)
        ctx.waiting.updates = true
      }

      ctx.waiting.total = items.length

      if (ctx.waiting.total <= 0) {
        ctx.waiting.updates = false
      }
      // if there is an AutoSave, then save the changes after 30 second
      if (ctx.autoSave) {
        clearTimeout(ctx.autoSaveTimer)
        ctx.autoSaveTimer = setTimeout(() => {
          app.$emit('saveMyChanges')
        }, 30000)
      }
      // save timer from last decision, after 5 minute ask for save
      ctx.setSaveTimer()
      console.log(this.waiting, "waiting updates");
    },
    /**
     * Shows House Number with comma instead of JSON
     * @param item
     * @param parser
     * @param section
     * @param isNotValue
     * @returns {*}
     * wN
     */
    houseNumberField(item, parser, section = null, isNotValue=true) {
      const userCountry = app.$data.user.get.country
      let _hn = isNotValue ? item.houseNumber : item
      if (_hn) {
        if (isJsonSring(_hn)) {
          let orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk'];
          let _gamze = JSON.parse(_hn)
          let res = Object.keys(_gamze)
              .map(a => ({[a]: _gamze[a]}))
              .sort((a, b) => (orderArr.indexOf(Object.keys(a)[0]) + 1) - (orderArr.indexOf(Object.keys(b)[0]) + 1));
          let test = {}
          Lazy(res).each((v, k) => { Lazy(v).each((v, k) => { test[k] = v }) })
          _hn = test

          if (parser === 'rawValue') return _hn = JSON.stringify(_hn)
          if (parser === 'value') {
            //let _refine = []
            //Lazy(_hn).each(function (v, k) { _refine.push(v) })
            let _country = section ? section : userCountry
            return _hn[userCountry]
          }
          if(parser === 'allValues') {
            return _hn
          }
        }
        else {
          return _hn
        }
      } else {
        return _hn
      }
    },
    hideColumn(column) {
      console.log(column, 'column', this.showColumn)
      if (['type', 'time', 'league', 'title', 'info'].indexOf(column) > -1) {
        if (column === 'type' || column === 'info') {
          let _col = this.showColumn.showId[column]
          if (_col) this.showColumn.showId[column] = false
          else this.showColumn.showId[column] = true
          if (column === 'info') {
            let _column = this.showColumn[column]
            if (_column) this.showColumn[column] = false
            else this.showColumn[column] = true
          }
          this.preserveColumnOptions = this.showColumn
          return
        }
        return
      }
      let _column = this.showColumn[column]
      if (_column) {
        this.showColumn[column] = false
        this.preserveColumnOptions = this.showColumn
      } else {
        this.showColumn[column] = true
        this.preserveColumnOptions = this.showColumn
      }
    },
    showActivityColors() {
      let column = { value: 'time' }
      let last
      Lazy(this.rows).each(v => {
        if (v.time) {
          v.color = this.getRowColors(v, column, 'all')
          if (['red', 'green', 'orange', 'grey'].indexOf(v.color) > -1) last = v.color
        }
      })
      console.log('Rendered activity, last color: ', last, this.checkMemoryUsage() + ' MB')

    },
    openMCRinNewTab() {
      const context = this
      swal({
        title: 'Page is getting slow!?',
        text: `Do you want to open MCR in new tab?`,
        type: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      })
          .then(function () {
            // that helpt better ..
            window.location.reload(true)
            /*
            // first open new tab
            window.open(window.location.href,'_blank');
            // now open the same tab like as a new tab to fake chrome
            let myWindow = window.open("", "_self");
            // now write empty to make it belongs to script
            myWindow.document.write("");
            // and close after 1 second
            setTimeout (function() {myWindow.top.close();},1000);

             */
          })
          .catch(function(e) {
            if (e === "cancel") {
              console.log('good, cancled');
            }
          })
    },
    checkMemoryUsage() {
      const browser = this.whichBrowser()
      let memory
      if (browser === 'Chrome') {
        memory = window.performance.memory.usedJSHeapSize
        // to MegaByte
        memory = parseInt(memory / Math.pow(1000, 2), 10)
        if (memory > 1800) {
          // do some warnings etc..
          this.openMCRinNewTab()
        }
        return memory
      }
    },
    whichBrowser() {
      if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) {
        return 'Opera';
      } else if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
        return 'Chrome';
      } else if(navigator.userAgent.indexOf("Safari") !== -1) {
        return 'Safari';
      } else if(navigator.userAgent.indexOf("Firefox") !== -1 ){
        return 'Firefox';
      } else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
        return 'IE';//crap
      } else {
        return 'Unknown';
      }
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },

    setLoaded(loaded = true) {
      this.loaded = loaded
    },

    /**
     * Process items (feeds/productions) to be presented.
     */
    processItems() {
      console.log("processItems");
      this.setLoaded(false)
      if (!this.processedItems.options.hasOwnProperty('actionSettings')) {
        this.processedItems.options.actionSettings = {}
      }
      Lazy(this.processedItems.columns)
          .each(item => {
            this.parseColumn(item)
          })
      Lazy(this.processedItems.rows)
          .each(item => {
            this.parseRow(item)
          })
      Vue.nextTick(() => {
        this.setLoaded()
        this.removeListener()
        Vue.nextTick(() => {
          // set the colors of time bar for activity
          this.showActivityColors()
          // we need to specify here to scroll to last position
          // p.s: this function also covers expands as well.
          this.setScroll(5)
          // add event listener for scroll position
          this.addListener()
        })
      })
    },
    /**
     * Add eventListener for scrolling operations.
     */
    addListener() {
      window.addEventListener('scroll', this.handleScroll);
    },
    /**
     * Delete eventListener for scrolling operations.
     */
    removeListener() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    /**
     * Save the current scrolling position.
     */
    handleScroll () {
      this.scrolled = window.scrollY > 0
      if (this.scrolled) {
        this.scrollPos.X = window.scrollX
        this.scrollPos.Y = window.scrollY
      }
    },
    /**
     * Set scrolling position to saved position on page.
     */
    setScroll(plus = 0) {
      // we should give a little delay to affect the page
      Vue.nextTick(() => {
        document.documentElement.scrollLeft = this.scrollPos.X + plus
        document.documentElement.scrollTop = this.scrollPos.Y + plus
        console.log(this.scrollPos.X, 'X - Y', this.scrollPos.Y)
        // after scroll expand the pages
        if (this.expandedAllRows) {
          this.toggleExpansion(true)
        } else {
          this.setRowExpands()
        }
      })
    },
    /**
     * Expand the rows already been expanded.
     */
    setRowExpands() {
      if (this.expandedRows) {
        Lazy(this.rows)
            .each(v => {
              Lazy(this.expandedRows)
                  .each(v2 => {
                    if (v.id.rawValue === v2.id.rawValue) {
                      v.rowOptions.expanded = v2.rowOptions.expanded
                    }
                  })
            })
      }
    },
    /**
     * Parse a column item and ensure that all required parameters are set.
     */
    parseColumn(item) {
      if (!item.style) {
        Vue.set(item, 'style', {})
      }

      // Set the width of each column, if not already available.
      // Exclude the "growField" from it because it will stretch and fill
      // remaining space.
      if (!item.style.width && item.label && (item.value != this.currentOptions.growField)) {
        Vue.set(item.style, 'width', (item.label.length * 10) + 'px')
      }

      if (typeof item.visible == 'undefined') {
        item.visible = true
      }

    },

    /**
     * Parse a row item and ensure that all required parameters are set.
     */
    parseRow(item) {
      this.setDefaultRowOptions(item)
      if (item.contributions) {
        //console.log(item.contributions);
        Lazy(item.contributions)
            .each(v => {
              this.setDefaultRowOptions(v)
            })
      }
      if (item.associations) {
        Lazy(item.associations)
            .each(v => {
              this.setDefaultRowOptions(v)
              if (v.contributions) {
                Lazy(v.contributions)
                    .each(v2 => {
                      this.setDefaultRowOptions(v2)
                    })
              }
            })
      }
    },

    /**
     * Toggle the expansion of all rows.
     * @param boolean expand
     */
    toggleExpansion(expand) {
      Lazy(this.rows)
          .filter(row => row.associations || row.contributions)
          .each(row => {
            row.rowOptions.expanded = expand
          })
      // if all expanded, save as true
      this.expandedAllRows = expand
    },

    /**
     * Expand/collapse the given row.
     * @param object row
     */
    expandRow(row) {
      row.rowOptions.expanded = !row.rowOptions.expanded
      if (!row.rowOptions.expanded) {
        Lazy(row.associations)
            .each(v => {
              v.rowOptions.expanded = row.rowOptions.expanded
            })
      }

      // when we expand something, save it to expanded rows so we can expand them again.
      let i = 0
      this.expandedRows = []
      Lazy(this.rows)
          .filter(row => row.associations || row.contributions)
          .each(row => {
            if (row.rowOptions.expanded === true) {
              this.expandedRows.push(row)
            }
          })
    },

    /**
     * Expand/collapse the children for the given row.
     * @param object row
     * @param boolean reset
     */
    expandRowChildren(row) {
      if (!row.rowOptions.expanded) {
        row.rowOptions.expanded = true
      }
      let expand = (row.rowOptions.expanded && this.rowChildrenExpanded(row)) ? false : true
      Lazy(row.associations)
          .each(v => {
            v.rowOptions.expanded = expand
          })
    },

    /**
     * Get the item type for the passed row.
     * @param object row
     * @return string
     */
    getRowType(row) {
      let type = row[this.currentOptions.typeField].rawValue ? row[this.currentOptions.typeField].rawValue : row[this.currentOptions.typeField].value
      return type
    },

    /**
     * Get the item type for the passed row.
     * @param object row
     * @return string
     */
    getRowExtraType(row) {
      let type = row[this.currentOptions.typeField].extraValue ? row[this.currentOptions.typeField].extraValue : row[this.currentOptions.typeField].value
      return type
    },

    /**
     * Return an unique key for the passed row, that can be used to identify
     * the single row. Here we make a mix of "type", id value and raw id value,
     * if available.
     * @param object row
     * @return string
     */
    getRowKey(row) {
      let output = []
      output.push(this.getRowType(row))
      if (row.id.rawValue) {
        output.push(row.id.rawValue)
      }
      if (row.id.value && (output.indexOf(row.id.value) == -1)) {
        output.push(row.id.value)
      }
      return output.join('_')
    },

    getRowClasses(row) {
      const output = []

      if (row.rowOptions && row.rowOptions.type) {
        output.push(`book-mcr-${row.rowOptions.type}`)
      }

      if (this.selected && (this.selected.id === row.id.rawValue) && (this.selected.elementType === row.type.rawValue)) {
        output.push('book-mcr-selected')
      }
      if (this.rowHasChildren(row)) {
        if (row.rowOptions.expanded) {
          output.push('book-mcr-expanded')
        }

        output.push('book-mcr-row-with-children')

      }
      return output
    },
    getRowMCRStatus(row) {
      const _mcr = row.rowOptions.mcrStatus
      let _status
      if (_mcr) {
        Lazy(_mcr)
            .each((v,k) => {
              // should be country specific also darling WN.
              if (v.status && v.country === app.$data.user.get.country) {
                _status = v.status
              }
            })
      }
      return _status
    },
    getRowStyle(row, column) {
      if (row.type.rawValue === 'event') {
        if (column.value === 'title') return 'width: 500px'
        if (column.value === 'time') return 'width: 101px; text-align: center'
      }
      if (row.type.rawValue === 'mcrComment') {
        if (column.value === 'title') return 'width: 80%'
        if (column.value === 'time') return 'width: 101px; text-align: center'
      }
      return column.style
    },
    getRowColors(row, column, color, text = null) {
      const context = this

      if (typeof row.type === 'undefined') {
        console.log(row, '*??', text)
        return 'comment'
      }

      if (row.type.rawValue === 'event' && column.value === 'time') {
        return 'grey'
      }

      if (row.type.rawValue === 'mcrComment' && column.value === 'time') {
        return 'comment'
      }

      //console.log(row, row.type.rawValue, 'coling', row.rowOptions.mcrStatus)
      // status: 1 -> Planning, 2 -> Line-up, 3 -> Ready & standing by, 4 -> finished
      const _status = context.getRowMCRStatus(row)
      const now = moment()

      let uNow = now.unix()

      let eventStart = row.time.startTime
      let eventEnd = row.time.endTime
      let _diff = now.diff(eventStart * 1000, 'minutes')
      let _active = moment(uNow * 1000).diff(eventEnd * 1000, 'minutes')

      if (column.value === 'time') {
        // 15 minutes before within start
        if (_diff >= -14 && _diff < 0 && color === 'all') {
          return 'orange'
        }

        // event is active based on start and end time
        if (_diff >= 0 && _active <= 0 && color === 'all') {
          return 'green'
        }

        // event passed based on the end time if its finished, should be grey else red.
        if (typeof _status !== 'undefined' && _status === 4 && color === 'grey') {
          if (_active > 0) {
            return 'grey'
          }
        } else {
          if (_active > 0 && color !== 'grey') {
            return 'red'
          }
        }


      }

      if (context.sort.column === column.value) return 'active'

    },

    /**
     * Determine if a row has associations or not. Note that when the items
     * are "ungrouped" then we handle the row as missing associations.
     * @param object row
     * @return boolean
     */
    rowHasChildren(row) {
      return this.rowHasContributions(row) || this.rowHasAssociations(row)
    },

    /**
     * Check if the passed row has children with children, i.e. grandchildren.
     * @param object row
     * @return boolean
     */
    rowHasChildrenWithContributions(row) {
      if (!this.rowHasAssociations(row)) return false
      return row.associations.filter(v => {

        // Only child contributions that aren't the same as the row
        // itself should be visible.
        return Array.isArray(v.contributions) && v.contributions.some(v2 => v2.id.rawValue !== row.id.rawValue)

      }).length > 0
    },

    rowChildrenExpanded(row) {
      if (!row.rowOptions.expanded || !row.associations) return false
      let expandedItems = row.associations.filter(v => v.rowOptions.expanded == true)
      return row.associations.length === expandedItems.length
    },

    rowHasComment(row) {
      return row.after && row.after.length > 0
    },
    rowHasContributions(row) {
      return row.contributions && row.contributions.length > 0
    },
    rowHasAssociations(row) {
      return row.associations && row.associations.length > 0
    },
    associationHasEvent(row) {
      return row.associations.filter(v => v.elementType === 'event')
    },

    /**
     * Get the number of children the passed row has.
     * @param object row
     * @return integer
     */
    rowNumberOfChildren(row) {
      let output = 0
      if (row.contributions) {
        output += row.contributions.length
      }
      if (row.associations) {
        output += row.associations.length
      }
      return output
    },
    /**
     * Get a number of documents the passed row has
     * @param object row
     * @return integer
     */
    rowNumberOfDocuments(row){
      if (row.rowOptions.documents) {
        return row.rowOptions.documents.length
      }
    },

    /**
     * Get the contributions for the passed row, sorted by the current
     * sort column.
     * @param object row
     * @return array
     */
    getContributions(row) {
      if (!this.sort.column) {
        return row.contributions
      }
      return Lazy(row.contributions)
          .sortBy(row => {
            let sortColumn = this.currentOptions[this.sort.column + 'Field'] ? this.currentOptions[this.sort.column + 'Field'] : this.sort.column
            let sortValue = row[sortColumn].rawValue ? row[sortColumn].rawValue : row[sortColumn].value
            return sortValue
          }, this.sort.reverse)
          .toArray()
    },

    /**
     * Get the associations for the passed row, sorted by the current
     * sort column.
     * @param object row
     * @return array
     */
    getAssociations(row) {
      if (!this.sort.column) {
        return row.associations
      }
      return Lazy(row.associations)
          .sortBy(row => {
            let sortColumn = this.currentOptions[this.sort.column + 'Field'] ? this.currentOptions[this.sort.column + 'Field'] : this.sort.column
            let sortValue = row[sortColumn].rawValue ? row[sortColumn].rawValue : row[sortColumn].value
            return sortValue
          }, this.sort.reverse)
          .toArray()
    },

    /**
     * Set rowOptions and some required attributes on the passed row, if not
     * already available.
     * @param object row
     * @return void THis method changes the row by reference.
     */
    setDefaultRowOptions(row, item = null) {
      if (!row.hasOwnProperty('rowOptions')) {
        Vue.set(row, 'rowOptions', {})
      }
      if (!row.rowOptions.hasOwnProperty('key')) {
        Vue.set(row.rowOptions, 'key', this.getRowKey(row))
      }
      if (!row.rowOptions.hasOwnProperty('hasActions')) {
        Vue.set(row.rowOptions, 'hasActions', true)
      }
      if (!row.rowOptions.hasOwnProperty('weight')) {
        Vue.set(row.rowOptions, 'weight', 0)
      }
      if (!row.rowOptions.hasOwnProperty('expanded')) {
        Vue.set(row.rowOptions, 'expanded', false)
      }

    },

    buttonIsPresent(button) {
      return this.getItems.options.actions.indexOf(button) > -1
    },
    getButtonClasses(button) {
      if (!this.buttonIsPresent(button)) return []
      let classes = []
      switch (button) {
        case 'edit':
          classes.push('btn-primary')
          break
        case 'preview':
          classes.push('btn-success')
          break
        case 'clone':
          classes.push('btn-info')
          break
        case 'delete':
          classes.push('btn-danger')
          break
      }
      if (this.getItems.options.actionSettings.hasOwnProperty(button) && this.getItems.options.actionSettings[button].classes) {
        classes = classes.concat(this.getItems.options.actionSettings[button].classes)
      }
      return classes
    },
    getButtonTitle(button) {
      if (!this.buttonIsPresent(button)) return ''
      if (this.getItems.options.actionSettings.hasOwnProperty(button) && this.getItems.options.actionSettings[button].title) {
        return this.getItems.options.actionSettings[button].title
      }
      else {
        switch (button) {
          case 'edit':
            return ''
          default:
            return button.capitalize()
        }
      }
    },
    getButtonLabel(button) {
      if (!this.buttonIsPresent(button)) return ''
      if (this.getItems.options.actionSettings.hasOwnProperty(button) && this.getItems.options.actionSettings[button].label) {
        return this.getItems.options.actionSettings[button].label
      }
      else {
        switch (button) {
          case 'edit':
            return '&nbsp;&nbsp;Open'
          case 'preview':
            return ''
          case 'clone':
            return ''
          case 'delete':
            return ''
        }
      }
    },
    getButtonIcon(button, byPass = false){
      //console.log(button)
      if (!byPass && !this.buttonIsPresent(button)) {
        return ''
      }
      //Vr not sure we need this
      //if (this.tableData.options.actionSettings.hasOwnProperty(button) && this.tableData.options.actionSettings[button].label) {
      //return this.tableData.options.actionSettings[button].label
      //}
      //else {
      switch (button) {
        case 'edit':
          return 'pencil-alt'
        case 'preview':
          return 'search'
        case 'clone':
          return 'copy'
        case 'delete':
          return 'trash'
        case 'contact':
          return 'envelope'
      }
      //}
    },
    /**
     * Emit a action button event.
     * @param eventName string
     * @param object row
     */
    emitButtonEvent(eventName, row) {
      let type = this.getRowType(row)
      let extraType = this.getRowExtraType(row)
      if (extraType === 'mcrComment') {
        let data = { action: 'open', item: { id: row.id.value, elementType: type } }
        app.$emit('mcrCommentActions', data)
        return
      }
      // Opening in a NewTab for Productions WN.
      if (extraType === 'production' && eventName === 'edit') {
        this.openNewTab({id: row.id.value, type: extraType})
        return
      }
      if (extraType === 'feed' && eventName === 'edit') {
        this.openNewTab({id: row.id.value, type: extraType})
        return
      }
      this.$emit(eventName, {id: row.id.value, type: type, extraType: extraType})
      return
    },
    /**
     * Opens sidebar with the delay to allow double-clicking finction
     * @param object item
     */
    openSidebar(row){
      let type = this.getRowType(row)
      if (type !== 'mcrComment') { this.sidebar = true }
      setTimeout(() => {
        this.emitButtonEvent('open', row)
      }, 200)
    },
    openForComment(row) {
      let type = this.getRowType(row)
      row.newType = 'mcrComment'
      setTimeout(() => {
        let data = { action: 'new', item: { id: row.id.value, elementType: type } }
        app.$emit('mcrCommentActions', data)
      }, 200)
    },
    /**
     * Sort the list by a given column.
     * @param object column
     */
    sortBy(column) {
      return
      console.log("sortBy");
      console.log(column);
      if (!column.sortable) return
      this.sort.reverse = (this.sort.column == column.value) ? !this.sort.reverse : false
      this.sort.column = column.value;
    },

    /**
     * Filter the list by a given key (column value)
     * @param string key
     */
    filterBy(key) {
      if (!this.getItems.options.filters[key].selected) {
        Vue.delete(this.filters, key)
      }
      else if (this.getItems.options.filters[key].selected == '_empty_') {
        Vue.set(context.filters, key, '')
      }
      else {
        Vue.set(this.filters, key, this.getItems.options.filters[key].selected)
      }
    },

    /**
     * Check if the passed child contribution should be visible or not,
     * based on the passed row. If the row is the same as the contribution
     * itself, do not show the contribution once again.
     * @param object contribution
     * @param object row
     * @return boolean
     */
    childContributionIsVisible(contribution, row) {
      return row.id.rawValue != contribution.id.rawValue
    },

    /**
     * Get the HTML "title" to be used when hovering the mouse over the
     * equipment column.
     */
    getEquipmentTypeTitle(equipmentType, key) {
      const output = []
      // output.push(equipmentType.label)
      let equipmentPieces = []
      Lazy(equipmentType.items)
          .each(item => {
            equipmentPieces.push(item.label)
          })
      output.push(equipmentPieces.join(', '))
      return output.join(': ')
    },

    getEquipmentTypes(equipment, next = null) {
      if (next) console.log(next, 'NEXT!')
      const output = []
      Lazy(equipment)
          .each(item => {
            if (item.items && item.items.length > 0) {
              item.items.forEach(v1 => { output.push(v1); })
            }
          })
      return output.sort()
    },
    getNonVisibleEquipmentTypes(equipment, next = null) {
      if (next) console.log(next, 'NEXT!')
      const output = []
      Lazy(equipment)
          .each(item => {
            if (typeof item.nonVisible !== 'undefined' && item.nonVisible !== null) {
              item.nonVisible.forEach(v1 => { output.push(v1); })
            }
          })
      return output.sort()
    },

    /**
     * Get some additional data for the passed row, to be used by BookMcrStatus.
     */
    getExtraDataForMcrStatus(row, mcr = '') {
      const output = {}
      if (mcr === 'cont') {
        output.itemId = row.id.value
        output.itemType = row.type.rawValue
        output.country = row.rowOptions.country
        return output
      }

      if (row.type.rawValue === 'contribution') {

        // To retrieve the "id" and "type" of the parent (feed/production),
        // we look at the first element in the associations, because
        // contributions don't more than its parent as an association.
        if (typeof row.associations[0] !== 'undefined'){
          output.itemId = row.associations[0].id.value
          output.itemType = row.associations[0].type.rawValue
          output.country = row.associations[0].country
        }else{
          //Check current contribution in Productions
          let result = this.getExtraDataFromProductions(row);

          if (result == null){
            result = this.getExtraDataFromFeeds(row);
          }
          console.log('calling extra data 2');
          output.itemId = result.itemId
          output.itemType = result.itemType
          output.country = result.country

        }
      }
      return output
    },

    getExtraDataFromProductions(row){
      const output = {};
      const production = new Mcrdata(row.id.value);
      if (production){
        const contributions = production.get.contributions;

        if (contributions && contributions.length > 0){
          var item = contributions.find(t => t.id == row.id.rawValue);
          if (item){
            output.itemId = item.associations[0].id
            output.itemType = item.associations[0].elementType
            output.country = item.country

            return output;
          }
        }
      }

      return null;
    },

    getExtraDataFromFeeds(row){
      const output = {};
      const feed = new Mcrdata(row.id.value);
      if (feed){
        const contributions = feed.get.contributions;

        if (contributions.length > 0){
          var item = contributions.find(t => t.id == row.id.rawValue);
          if (item){
            output.itemId = item.associations[0].id
            output.itemType = item.associations[0].elementType
            output.country = item.country

            return output;
          }
        }
      }

      return null;
    },

    /**
     * Get the item type to be sent into a child component
     * (ex BookMcrStatus).
     */
    getItemType(row) {
      switch (row.rowOptions.type) {
        case 'contribution':
          return 'equipmentUsage'
      }
      return row.rowOptions.type
    },

    /**
     * Get the group label for the given row.
     * @param object row
     * @return string
     */
    getGroupLabel(row) {
      return row[this.currentOptions.groupBy].rawValue ? row[this.currentOptions.groupBy].rawValue : row[this.currentOptions.groupBy].value
    },

    /**
     * Check if the given row is the first item in its group.
     * @param object row
     * @return boolean
     */
    isFirstGroupRow(row) {
      if (row.date && row.date.group) return row.date.group === 1;
      return false
    },

    /**
     * Get the title to be used
     */
    getColumnTitle(value) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = value
      return tmp.innerText
    },
    /**
     * Size calculations for DOM elements that should've remain in the DOM
     * or benched in the DOM instead of having thousands of items
     * @param type
     * @returns {number}
     * WN
     */
    calcItemsToShow(type, items) {
      let _total = !items ? this.items.rows.length : items
      if (type === 'remain') { return _total < 60 ? _total : _total = 25 }
      if (type === 'bench') { return _total < 60 ? _total : _total = 0 }
      if (type === 'size') { return 37; }
      if (type === 'wclass') { return "book-mcr-column"; }
    },
    /**
     * Get a new class instance, based the child component.
     * @param mixed opts (optional) The parameters that the new class instance should
     *                   be created with.
     * @return class
     */
    getNewClass(itemType, opts = null) {
      switch (itemType) {
        case 'feed':
          return new Mcrdata(opts)
        case 'production':
          return new Mcrdata(opts)
      }
    },
    getDownloads(item) {
      if (!this.showDocuments.open) {
        this.showDocuments = {
          files: item.documents,
          type: item.elementType,
          usageItem: item,
          open: true
        }
      } else {
        this.showDocuments = {
          files: [],
          type: null,
          usageItem: {},
          open: false
        }
      }
    },
    /**
     * Applies the houseNumber to the event
     */
    applyHouseNumber(itemId, itemType, country, newHouseNumber) {
      const context = this
      const itemClass = context.getNewClass(itemType,itemId)
      console.log(itemClass, itemType, itemId, 'what we got here?')

      let houseNumber

      if (itemType === 'production') {
        let pLead = itemClass.get.productionLeader.id ? itemClass.get.productionLeader.id : itemClass.get.productionLeader
        itemClass.set('productionLeader', pLead)
      }

      if (typeof newHouseNumber === 'object') {
        Lazy(newHouseNumber).each((v,k) => {
          newHouseNumber[k] = (v === 'null' || v === 'blank' || v === 'empty') ? '' : v
        })
      }

      houseNumber = JSON.stringify(newHouseNumber)

      houseNumber = (newHouseNumber === 'null' || newHouseNumber === 'blank' || newHouseNumber === 'empty') ? '' : houseNumber

      itemClass.set('houseNumber', houseNumber)
      itemClass.set('house_number_changing', 'yes')
      //itemClass.set('contributions', '')
      //itemClass.set('associations', '')

      const now = moment().unix()
      Item.parse(itemClass.get, itemType, now)
      itemClass.add(itemClass.get)

      // update for associations over MCR-View page
      app.$emit('AutoChangeHouseNumber', {
        itemType: itemType,
        itemId: itemId,
        action: {
          module: itemType.toLowerCase(),
          name: 'HouseNumber',
          type: 'save'
        },
        item: {
          id: itemId,
          houseNumber: houseNumber
        },
        houseNumber: houseNumber
      })

      context.formData.showAddItemForm = false
      context.$loader.remove(context.processingId)
    },

    /**
     * houseNumberInputDisabled
     * disables the houseNumber input box within user's country
     */
    houseNumberInputDisabled(country) {
      if (!country) return false
      if (Array.isArray(country)) return country.indexOf(app.$data.user.get.country) === -1
      if (country){
        var array = country.split(", ");
        if (Array.isArray(array)) { return array.indexOf(app.$data.user.get.country) === -1 }
      }
      return country !== app.$data.user.get.country
    },
    /**
     * Shows houseNumbers in the Book-Modal for the
     * selected event.
     * WN
     * */
    showHouseNumbers(row) {
      const context = this
      let _hn = row.houseNumber.rawValue
      let _countries = context.$store.state.system.countries ? context.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
      let _data = {}

      //reset first
      context.formData = this.defaultData()

      if (_hn) {
        if (isJsonSring(_hn)) _data = JSON.parse(_hn)
        else Lazy(_countries).each(v => { _data[v] = '' })
      }
      else {
        Lazy(_countries).each(v => { _data[v] = '' })
      }

      // open form
      context.formData.items = row
      context.formData.newItem = _data
      context.formData.showAddItemForm = true

    },
    /**
     * House Number ingestions per country for selection item
     */
    getHouseNumberPerCountry(country) {
      let data = this.ingestors.get.items
      let res = []
      Lazy(data)
          .each(function(v,k) {
            if (v.country === country) res.push(v)
          })
      return res
    },
    /**
     * Sending Ingestor Entity to BookIngestion component to adjust traffic buttons
     * by their entities.
     */
    ingestionValues(houseNumber) {
      const userCountry = app.$data.user.get.country;
      const data = this.ingestors;
      if (!data || !data.get.items || data.get.items.length === 0) return;

      // Process houseNumber
      let processedHouseNumber = houseNumber && isJsonSring(houseNumber)
          ? JSON.parse(houseNumber)[userCountry]?.toLowerCase() ?? null
          : houseNumber?.toLowerCase();

      const oneLetterOrOnlyIntegers = /^[a-zA-Z]?\d+$/;
      let value

      for (const v of data.get.items) {
        if (!v.active) continue;

        const label = v.label?.toLowerCase();
        const shortLabel = v.shortlabel?.toLowerCase();
        const userField2 = v.userfield2_value?.toLowerCase();

        // integrations: NULL, Blank, Empty, Integer or specific labels
        const isMatch = (
            (processedHouseNumber && (label === processedHouseNumber || shortLabel === processedHouseNumber || userField2 === processedHouseNumber)) ||
            (processedHouseNumber === null && ['null', 'blank', 'empty'].includes(label)) ||
            (processedHouseNumber === '' && ['null', 'blank', 'empty'].includes(label)) ||
            (oneLetterOrOnlyIntegers.test(processedHouseNumber) && label === 'int')
        ) && ((v.country === userCountry && !v.default) || v.default);

        if (isMatch) {
          value = v.userfield1_value;
          break;
        }
      }

      if (value) return value;
    },
    updateEquipmentFinal(data, action = '') {

      let _usageId
      let _usageType
      let _finalItems

      // if its array or multiple equipment
      if (Array.isArray(data)) {
        Lazy(data).each(v1 => {

          _usageId = v1.equipmentUsage.usageId
          _usageType = v1.equipmentUsage.usageType

          const item = this.getNewClass(_usageType, _usageId)
          const items = item.get.equipment
          // if no item, then it means not in the list
          if (typeof items === 'undefined') return

          // update / create / del
          if (action === 'create' || action === 'update') {
            let i = items.findIndex(v => v.equipmentUsage.id === v1.equipmentUsage.id)
            if (i > -1) items.splice(i, 1)
            items.push(v1)
          }
          if (action === 'delete') {
            let i = items.findIndex(v => v.id === v1.id)
            if (i > -1) items.splice(i, 1)
          }
          _finalItems = items
          item.set('equipment', items)
        })

      }
      else {

        _usageId = data.equipmentUsage.usageId
        _usageType = data.equipmentUsage.usageType

        const item = this.getNewClass(_usageType, _usageId)
        const items = item.get.equipment
        // if no item, then it means not in the list
        if (typeof items === 'undefined') return

        // update / create / del
        if (action === 'create' || action === 'update') {
          let i = items.findIndex(v => v.equipmentUsage.id === data.equipmentUsage.id)
          if (i > -1) items.splice(i, 1)
          items.push(data)
        }
        if (action === 'delete') {
          let i = items.findIndex(v => v.id === data.id)
          if (i > -1) items.splice(i, 1)
        }

        _finalItems = items
        item.set('equipment', items)
      }

      //associations
      if (_finalItems)
        Lazy(this.items)
            .each((v, k) => {
              if (v.associations) {
                Lazy(v.associations)
                    .each((v2, k2) => {
                      if (v2.id === _usageId) {
                        v2.equipment = _finalItems
                        switch (_usageType) {
                          case 'feed':
                            const feed = new Mcrdata(_usageId)
                            feed.set('equipment', _finalItems)
                            break
                          case 'production':
                            const production = new Mcrdata(_usageId)
                            production.set('equipment', _finalItems)
                            break
                        }
                      }
                    })
              }
            })

    },
    /**
     *  Get default options for the component.
     */
    defaultOptions() {
      return {
        offset: {
          bottom: 0,
          top: 0
        },
        sortBy: 'id',
        groupBy: null,
        growField: 'title',
        titleField: 'title',
        typeField: 'type'
      }
    },
    /**
     * Return default form data.
     */
    defaultData() {
      return {
        countries: [],
        newItem: {},
        items: null,
        userCountry: app.$data.user.get.country,
        showAddItemForm: false,
        draggedItems: 0,
        selected: []
      }
    },

    /**
     * Aligns MCR statuses.
     *
     * Based on the latest action (store, delete) we update the routing of equipment and usage.
     *
     * 1. If the usage is routed, all equipment should be routed.
     * 2. If equipment gets unrouted, we need to unroute the usage.
     * 3. If all equipment gets routed, the usage should be routed.
     */
    alignMcrStatus(data) {
      const intent = data.action.type

      // If the usage (production, feed) is being routed, we should route all of its equipment.
      if (data.itemType !== 'equipmentUsage' && data.item.status === 1 && (intent === 'store' || intent === 'save')) {
        const row = this.rows.find(v => v.id.rawValue === data.itemId)

        // Route all equipments that are not currently routed.
        Lazy(row.equipment)
          .each(item => {
            if (item.items && item.items.length > 0) {
              item.items.forEach(v1 => {
                if (!this.isEquipmentRouted(v1)) {
                  this.routeEquipment(v1, row)
                }
              })
            }
          })
      // If the equipment is unrouted, we need to unroute the usage.
      } else if (data.itemType === 'equipmentUsage' && intent === 'delete') {

        const row = this.rows.find(v => v.id.rawValue === data.extraData.itemId)

        const currentMcrStatus = row.rowOptions.mcrStatus?.find(v => v.country === app.$data.user.get.country)

        // We only remove the status if its routed.
        if (currentMcrStatus && currentMcrStatus.status === 1) {
          const items = row.rowOptions.mcrStatus?.slice(0)

          let i = items.findIndex(v => v.id === currentMcrStatus.id)
          if (i > -1) items.splice(i, 1)

          const data = {
            itemId: row.id.rawValue,
            itemType: row.type.rawValue,
            extraData: null,
            id: currentMcrStatus.id,
            item: currentMcrStatus,
            action: {
              module: 'McrStatus',
              type: 'delete',
            },
            items: items
          }

          this.updateAction(data)
          this.autoChangeMcrStatus(data)
        }
      // If all equipments are routed we need to route the usage.
      } else if (data.itemType === 'equipmentUsage' && (intent === 'store' || intent === 'save')) {

        const row = this.rows.find(v => v.id.rawValue === data.extraData.itemId)

        let allEquipmentRouted = true

        // Checks if all equipments are routed.
        Lazy(row.equipment)
          .each(item => {
            if (item.items && item.items.length > 0) {
              item.items.forEach(v1 => {
                if (!this.isEquipmentRouted(v1)) {
                  allEquipmentRouted = false
                }
              })
            }
          })

        // All equipment are routed, so we need to route the usage.
        if (allEquipmentRouted) {
          const currentMcrStatus = row.rowOptions.mcrStatus?.find(v => v.country === app.$data.user.get.country)

          // We only route if the usage does not have an MCR status already.
          if (!currentMcrStatus) {
            const items = row.rowOptions.mcrStatus?.slice(0) ?? []

            const item = {
              id: Math.floor(100000000000 + Math.random() * 900000000000),
              status: 1, // 1 = Routed
              country: app.$data.user.get.country,
              itemId: row.id.rawValue,
              itemType: row.type.rawValue,
              extraData: null,
            }

            items.push(item)

            const data = {
              itemId: row.id.rawValue,
              itemType: row.type.rawValue,
              extraData: null,
              item: item,
              action: {
                module: 'McrStatus',
                type: 'store',
              },
              items: items
            }

            this.updateAction(data)
            this.autoChangeMcrStatus(data)
          }

        }

      }

    },

    /**
     * Routes the equipment.
     */
    routeEquipment(equipment, usage) {
      const items = equipment.equipmentUsage.mcrStatus?.slice(0) ?? []

      const item = {
        id: Math.floor(100000000000 + Math.random() * 900000000000),
        country: app.$data.user.get.country,
        status: 1, // 1 = Routed
      };

      items.push(item)

      const data = {
        itemId: equipment.equipmentUsage.id,
        itemType: 'equipmentUsage',
        extraData: {
          itemId: usage.id.rawValue,
          itemType: usage.type.rawValue,
        },
        item: item,
        action: {
          module: 'McrStatus',
          type: 'store',
        },
        items: items,
      }

      this.updateAction(data)
      this.autoChangeMcrStatus(data)
    },

    /**
     * Determines if the equipment is routed.
     */
    isEquipmentRouted(equipment) {
      if (equipment.equipmentUsage?.mcrStatus) {
        const item = equipment.equipmentUsage.mcrStatus.find(v => v.country === app.$data.user.get.country)

        if (!item) {
          return false
        }

        return item.status === 1 // 1 = Routed
      }

      return false
    }

  }
}
</script>

<template>
  <div class="book-mcr-view">
    <!--<book-loader v-if="!loaded && rows.length == 0"></book-loader>
    <div v-else-if="rows.length == 0" class="panel panel-default book-mcr-no-results">
        <span v-html="notFoundMessage"></span>
    </div>-->
    <div v-if="rows.length == 0" class="panel panel-default book-mcr-no-results" :class="{'dark-layout': darkMode}">
      <span v-html="notFoundMessage"></span>
    </div>
    <template v-else>
      <book-affix :class="{'dark-layout-affix': darkMode}" relative-element-selector=".book-mcr-view" :offset="currentOptions.offset" class="panel panel-default book-mcr-labels">

        <keep-alive>
          <div class="panel-body book-mcr-columns" >
            <!-- <div class="book-mcr-column book-mcr-toggle"></div> -->

            <div class="book-mcr-column"
                 v-for="column in getItems.columns"
                 :key="column.label"
                 :style="column.style"
                 :class="{'book-mcr-column-grow': column.value == currentOptions.growField, 'book-sortable': column.sortable, 'dark-border': darkMode, 'light-border': !darkMode}"
                 v-if="column.visible && (['equipment', 'associations'].indexOf(column.value) == -1)"
                 @click="hideColumn(column.value)">
            <span class="give-pointer"
                  :class="{
              'column-passive-light': !showColumn[column.value] && !darkMode,'column-passive-dark': showColumn[column.value] && darkMode,
              'column-passive-light': showColumn.showId[column.value] && !darkMode,'column-passive-dark': showColumn.showId[column.value]  && darkMode
               }"
            >
              {{column.label}}
            </span>

            </div>
            <div class="book-mcr-column book-mcr-equipment book-mcr-column-grow" :class="{'dark-border dark-second-input': darkMode, 'light-border': !darkMode}">
            <span class="give-pointer"
                  :class="{'column-passive-light': !showColumn.equipment && !darkMode,'column-passive-dark': !showColumn.equipment && darkMode}"
                  @click="hideColumn('equipment')">
              Equipment</span>
            </div>

            <div class="book-mcr-column book-mcr-ingestions" :class="{'dark-border dark-second-input': darkMode, 'light-border': !darkMode}" v-if="hasIngestions === true">
            <span class="give-pointer"
                  :class="{'column-passive-light': !showColumn.ingestions && !darkMode,'column-passive-dark': !showColumn.ingestions && darkMode}"
                  @click="hideColumn('ingestions')">
              Ingestions</span>
            </div>

            <div class="book-mcr-column book-tx-status-column" :class="{'dark-border dark-second-input': darkMode, 'light-border': !darkMode}">
            <span class="give-pointer"
                  :class="{'column-passive-light': !showColumn.txStatus && !darkMode,'column-passive-dark': !showColumn.txStatus && darkMode}"
                  @click="hideColumn('txStatus')">
              TX Status</span>
            </div>
            <div class="book-mcr-column book-viaplay-column" :class="{'dark-border dark-second-input': darkMode, 'light-border': !darkMode}">
            <span class="give-pointer"
                  :class="{'column-passive-light': !showColumn.viaplay && !darkMode,'column-passive-dark': !showColumn.viaplay && darkMode}"
                  @click="hideColumn('viaplay')">
              VP</span>
            </div>

            <div class="book-mcr-column book-mcr-status-column" :class="{'dark-second-input': darkMode}">
            <span class="give-pointer"
                  :class="{'column-passive-light': !showColumn.mcrStatus && !darkMode,'column-passive-dark': !showColumn.mcrStatus && darkMode}"
                  @click="hideColumn('mcrStatus')">
              MCR Status</span>
            </div>
            <div class="book-mcr-column book-mcr-attachment" :class="{'dark-second-input': darkMode}">
              <button class="give-pointer"
                      :class="{'column-passive-light': !showColumn.attachment && !darkMode,'column-passive-dark': !showColumn.attachment && darkMode}"
                      @click="hideColumn('attachment')">
                <font-awesome-icon icon="paperclip" aria-hidden="true"/>
              </button>
            </div>
            <div class="book-mcr-column book-mcr-toggle btn-group" :class="{'dark-second-input': darkMode}">
              <button class="btn btn-primary btn-xs" @click="toggleExpansion(true)">
                <font-awesome-icon icon="plus" aria-hidden="true"/>
              </button>
              <button class="btn btn-default btn-xs" @click="toggleExpansion(false)">
                <font-awesome-icon icon="minus" aria-hidden="true"/>
              </button>
            </div>
          </div>
        </keep-alive>
      </book-affix>

      <div class="book-mcr-rows">
        <div>
          <virtual-list
              :size="calcItemsToShow('size')"
              :remain="calcItemsToShow('remain')"
              :bench="calcItemsToShow('bench')"
              :pagemode="true"
          >
            <!-- <transition-group name="slide-fade" mode="out-in"> -->
            <div class="book-mcr-row"
                 :class="getRowClasses(row)"
                 v-for="(row, index) in rows"
                 v-if="row.rowOptions.visible"
                 :key="row.rowOptions.key + '_' + index"
            >
              <div v-if="isFirstGroupRow(row)" class="book-mcr-group-label">
                {{getGroupLabel(row)}}
              </div>
              <div class="panel panel-default animate"
                   :class="{'dark-layout': darkMode === true}">
                <div class="panel-body book-mcr-columns"
                     :class="{'updated': row.updated, 'renewed': row.renewed && !darkMode,
                     'renewed-dark': row.renewed && darkMode,
                     'mcr-comment-light': isRowMCRComment(row) && !darkMode,
                     'mcr-comment-dark': isRowMCRComment(row) && darkMode}"
                >
                  <div
                      class="book-mcr-column"
                      v-if="mainColumnOptions(column, row)"
                      v-for="column in getItems.columns"
                      :style="getRowStyle(row, column)"
                      :title="column.title ? getColumnTitle(row[column.value].value) : ''"
                      :class="['book-mcr-column-' + column.value,
                                {'book-mcr-column-grow': column.value === currentOptions.growField},
                                {'dark-border': darkMode && !isRowMCRComment(row), 'light-border': !darkMode && !isRowMCRComment(row)},
                                column.value === 'time' ? row.color : '',
                                getRowColors(row, column, 'grey')
                                ]"
                      style="cursor: pointer;"
                  >
                    <template v-if="column.marker && changedItems[row.id.rawValue] && changedItems[row.id.rawValue].hasOwnProperty('flags')">
                      <span style="margin-right: 5px;" class="label" :class="changedItemsClasses[flag]" v-for="flag in changedItems[row.id.rawValue].flags" :title="changedItems[row.id.rawValue] && changedItems[row.id.rawValue].author ? 'by ' + changedItems[row.id.value].author : ''">{{flag}}</span>
                    </template>
                    <span v-if="column.value === 'type' && isRowMCRComment(row)"
                          class="book-mcr-column-content">
                      <div :style="darkMode ? 'color: #4a919d !important; display: inline-flex;align-content: center;position: absolute;margin-top: 3px;' : 'color: #4a919d !important; display: inline-flex;align-content: center;position: absolute;margin-top: 3px;'">
                        <font-awesome-icon icon='file-alt'/>
                      </div>
                      <span v-html="row[column.value].value"></span>
                    </span>
                    <span
                        v-else-if="column.value === 'type' && showColumn.showId[column.value] && !isRowMCRComment(row)"
                        class="book-mcr-column-content" v-html="row[column.value].idValue"
                        @click="openForComment(row)"
                    ></span>
                    <span v-else-if="column.value !== 'type'" class="book-mcr-column-content" v-html="row[column.value].value" @click="openSidebar(row)"></span>
                    <span v-else class="book-mcr-column-content" v-html="row[column.value].value" @click="openForComment(row)"></span>
                  </div>

                  <!-- Equipment -->
                  <div v-if="showColumn.equipment && !isRowMCRComment(row)" class="book-mcr-column book-mcr-equipment" :class="{'book-mcr-column-grow': currentOptions.growField === 'equipment','dark-border': darkMode, 'light-border': !darkMode}">
                    <div v-if="row.equipment === null && !isRowMCRComment(row) || getNonVisibleEquipmentTypes(row.equipment).length !== 0"
                         class="book-mcr-equipment-piece">
                      <book-equipment-selection
                          :mcrRow="row"
                          :darkMode="darkMode"
                          :isAddForMCRView="['task', 'mcrComment'].indexOf(row.type.extraValue) < -1"
                          :selection="[]"
                          :dates="{from: row.time.startDatetime, to: row.time.endDatetime}"
                          :nonVisible="getNonVisibleEquipmentTypes(row.equipment)"
                          :usage-id="row.id.rawValue"
                          :usage-type="row.type.rawValue"
                          :isEngOrEdit="false"
                          :isEditProduction ="true"
                          :isEngProduction ="false"
                          :disabled="false"
                          disabled-message="getEquipmentDisabledMessage"
                          :local="false"
                          :hideDates="false"
                          :entityList="entities"
                          :allEquipmentProps="allEquipment"
                          :isForEquipmentUsagePage="true"
                          :isForMCRView="true"
                      ></book-equipment-selection>
                    </div>
                    <div v-if="row.equipment && equipment.elementType === 'equipmentPiece'" class="book-mcr-equipment-piece" v-for="(equipment, key) in getEquipmentTypes(row.equipment) ">
                      <book-equipment-selection
                          :mcrRow="row"
                          :darkMode="darkMode"
                          :selection="getEquipmentTypes(row.equipment)"
                          :nonVisible="null"
                          :dates="{from: equipment.equipmentUsage.periodStart, to: equipment.equipmentUsage.periodEnd}"
                          :usage-id="equipment.equipmentUsage.usageId"
                          :isEngOrEdit="false"
                          :isEditProduction ="true"
                          :isEngProduction ="false"
                          :usage-type="equipment.equipmentUsage.usageType"
                          :disabled="false"
                          disabled-message="getEquipmentDisabledMessage"
                          :local="false"
                          :hideDates="false"
                          :entityList="entities"
                          :allEquipmentProps="allEquipment"
                          :enableCheckAvailability="true"
                          :filterTypesBy="[]"
                          :selectedItem="equipment.elementType + '#' +equipment.equipmentUsage.id"
                          :isForEquipmentUsagePage="true"
                          :isForMCRView="true"
                          :isAddForMCRView="false"
                          :label="equipment.label"
                          :directEquipment="equipment"
                      >
                      </book-equipment-selection>
                    </div>
                    <!-- </div> -->
                  </div>

                  <!-- Ingestions -->
                  <div class="book-mcr-column book-mcr-ingestions"
                       :class="{'dark-border': darkMode, 'light-border': !darkMode}"
                       v-if="hasIngestions && showColumn.ingestions && !isRowMCRComment(row)">
                    <book-ingestion
                        v-if="(row.type.rawValue !== 'contribution' && row.type.extraValue !== 'task')"
                        :class="{'dark-input': darkMode === true}"
                        :ingestionTypes="getItems.options.ingestions"
                        :items="row.rowOptions.ingestions"
                        :itemId="row.rowOptions.id"
                        :itemType="row.rowOptions.type"
                        :itemCountry="row.rowOptions.country"
                        :readonly="false"
                        :recordRequested="row.recordRequested.rawValue"
                        :ingestionFlow="ingestionValues(row.houseNumber.rawValue)"
                        :ingestors="ingestors"
                    >
                    </book-ingestion>
                    <!-- @input instant changes, but better with keyup.enter ****** getContributions(row) ******* -->
                    <div @click="showHouseNumbers(row)" style="width: 150px;"
                         :class="{'dark-input': darkMode }"
                         v-if="(row.type.rawValue !== 'contribution' && row.type.extraValue !== 'task')">
                      <input type="text" class="housenumber-form"
                             :title="row.houseNumber.value"
                             v-model:trim="row.houseNumber.value"
                             readonly/>
                    </div>
                    <div @click="showHouseNumbers(row)" style="width: 50px;" :class="{'dark-input': darkMode }">
                      <label class="btn btn-xs btn-mcr-toggle-associations btn-danger"
                             v-if="row.type.rawValue !== 'contribution' && row.type.extraValue !== 'task'
                             && row.houseNumber.forkMappedCountryCount > 0"
                             :title="row.houseNumber.forkMappedCountries.join(', ').toUpperCase()">
                        <font-awesome-icon  icon="plus" aria-hidden="true"/>
                        <span>{{row.houseNumber.forkMappedCountryCount}}</span>
                      </label>
                    </div>
                  </div>

                  <!-- TX Status -->
                  <div class="book-mcr-column book-tx-status-column"
                       :class="{'dark-border': darkMode, 'light-border': !darkMode}"
                       v-if="hasIngestions && showColumn.txStatus && !isRowMCRComment(row)">
                    <book-ingestion
                        v-if="(row.type.rawValue !== 'contribution' && row.type.extraValue !== 'task')"
                        :class="{'dark-input': darkMode === true}"
                        :ingestionTypes="['start', 'finish', 'air']"
                        :items="row.rowOptions.ingestions"
                        :itemId="row.rowOptions.id"
                        :itemType="row.rowOptions.type"
                        :itemCountry="row.rowOptions.country"
                        :readonly="false"
                    >
                    </book-ingestion>
                  </div>

                  <!-- Viaplay -->
                  <div class="book-mcr-column book-viaplay-column" :class="{'dark-border': darkMode, 'light-border': !darkMode}" v-if="hasIngestions && showColumn.viaplay && !isRowMCRComment(row)">
                    <book-ingestion
                        v-if="(row.type.rawValue !== 'contribution' && row.type.extraValue !== 'task')"
                        :class="{'dark-input': darkMode === true}"
                        :ingestionTypes="['routed']"
                        :items="row.rowOptions.ingestions"
                        :itemId="row.rowOptions.id"
                        :itemType="row.rowOptions.type"
                        :itemCountry="row.rowOptions.country"
                        :readonly="false"
                    >
                    </book-ingestion>
                  </div>

                  <!-- MCR Status -->
                  <div class="book-mcr-column book-mcr-status-column" v-if="showColumn.mcrStatus && !isRowMCRComment(row)">
                    <book-mcr-status
                        v-if="row.type.extraValue !== 'task'"
                        :class="{'dark-input': darkMode === true}"
                        :items="row.rowOptions.mcrStatus"
                        :itemId="row.rowOptions.id"
                        :itemType="getItemType(row)"
                        :extraData="getExtraDataForMcrStatus(row)"
                        :hideStatus="row.type.rawValue === 'contribution' ? [] : []">
                    </book-mcr-status>
                  </div>

                  <div v-if="showColumn.attachment" class="book-mcr-column book-mcr-attachment" :class="{'dark-second-input': darkMode}">
                    <button @click="getDownloads(row)" v-if="rowNumberOfDocuments(row)" class="btn btn-xs btn-mcr-toggle-associations" title="Show/hide" :class="{'btn-warning': !row.rowOptions.expanded}">
                      <font-awesome-icon v-if="row.rowOptions.documents" icon="paperclip" aria-hidden="true"/>
                      {{ rowNumberOfDocuments(row) }}
                    </button>
                  </div>

                  <div class="book-mcr-column book-mcr-toggle" :class="{'dark-second-input': darkMode}">
                    <button class="btn btn-xs btn-mcr-toggle-associations" title="Show/hide" :class="[{'btn-default': row.rowOptions.expanded}, {'btn-primary': !row.rowOptions.expanded}]" v-if="rowHasChildren(row)" @click="expandRow(row)">
                      <font-awesome-icon v-if="!row.rowOptions.expanded" icon="plus" aria-hidden="true"/>
                      <font-awesome-icon v-else icon="minus" aria-hidden="true"/>
                      <span>{{rowNumberOfChildren(row)}}</span>
                    </button>
                  </div>

                </div>
              </div>

              <!-- MCR Comment -->
              <div class="panel panel-default animate" v-if="row.after.length > 0"
                   style="margin-top: 5px;"
                   v-for="(rowz, index) in row.after"
                   :class="{'dark-layout': darkMode === true}">
                <div class="panel-body book-mcr-columns"
                     :class="{'updated': row.updated,
                     'mcr-comment-light': !darkMode,
                     'mcr-comment-dark': darkMode}"
                >
                  <div
                      class="book-mcr-column"
                      v-if="mainColumnOptions(column, rowz)"
                      v-for="column in getItems.columns"
                      :style="getRowStyle(rowz, column)"
                      :title="column.title ? getColumnTitle(rowz[column.value].value) : ''"
                      :class="['book-mcr-column-' + column.value,
                                {'book-mcr-column-grow': column.value === currentOptions.growField},
                                column.value === 'time' ? rowz.color : '',
                                getRowColors(rowz, column, 'grey')
                                ]"
                      style="cursor: pointer;"
                  >
                    <template v-if="column.marker && changedItems[rowz.id.rawValue] && changedItems[rowz.id.rawValue].hasOwnProperty('flags')">
                      <span style="margin-right: 5px;" class="label" :class="changedItemsClasses[flag]" v-for="flag in changedItems[rowz.id.rawValue].flags" :title="changedItems[rowz.id.rawValue].author ? 'by ' + changedItems[row.id.value].author : ''">{{flag}}</span>
                    </template>
                    <span v-if="column.value === 'type'"
                          class="book-mcr-column-content">
                      <div :style="darkMode ? 'color: #4a919d !important; display: inline-flex;align-content: center;position: absolute;margin-top: 3px;' : 'color: #4a919d !important; display: inline-flex;align-content: center;position: absolute;margin-top: 3px;'">
                        <font-awesome-icon icon='file-alt'/>
                      </div>
                      <span v-html="rowz[column.value].value" @click="openForComment(rowz)"></span>
                    </span>
                    <span v-else class="book-mcr-column-content" v-html="rowz[column.value].value" @click="openSidebar(rowz)"></span>
                  </div>

                </div>
              </div>



              <div v-if="rowHasContributions(row) || rowHasAssociations(row)">
                <!-- <transition-group name="slide-down-fade" mode="out-in"> -->
                <!-- Contributions-->
                <div class="list-group book-mcr-row-children book-mcr-row-contributions" key="contributions" v-if="rowHasContributions(row) && row.rowOptions.expanded">
                  <div class="book-mcr-row-child" v-for="contribution in getContributions(row)">
                    <div class="list-group-item book-mcr-columns animate" :class="{'dark-layout-expand': darkMode === true}">
                      <div class="book-mcr-column"
                           v-if="showColumn[column.value] && column.visible && (column.value != 'associations')"
                           v-for="column in getItems.columns"
                           :style="column.style"
                           :class="['book-mcr-column-' + column.value,
                           {'book-mcr-column-grow': column.value == currentOptions.growField},
                           getRowColors(contribution, column)
                           ]"
                      >
                        <span class="book-mcr-column-content" v-if="contribution[column.value]" v-html="contribution[column.value].value"></span>
                      </div>
                      <div v-if="showColumn.equipment" class="book-mcr-column book-mcr-equipment"></div>

                      <!-- MCR Status -->
                      <div v-if="showColumn.mcrStatus" class="book-mcr-column book-mcr-status-column">
                        <book-mcr-status
                            :class="{'dark-input': darkMode}"
                            :items="contribution.rowOptions.mcrStatus"
                            :itemId="contribution.id.rawValue"
                            itemType="equipmentUsage"
                            :extraData="getExtraDataForMcrStatus(row, 'cont')"
                        >
                        </book-mcr-status>
                      </div>
                      <div class="book-mcr-column book-mcr-toggle"></div>

                    </div>
                  </div>
                </div>

                <!-- Associations -->
                <div class="list-group book-mcr-row-children book-mcr-row-associations" key="associations" v-if="rowHasAssociations(row) && row.rowOptions.expanded">
                  <!-- <transition-group name="slide-fade" mode="out-in"> -->
                  <div class="book-mcr-row-child" :key="row.rowOptions.key + '_' + association.rowOptions.key" :class="getRowClasses(association)" v-for="association in getAssociations(row)">
                    <div class="list-group-item book-mcr-columns animate" :class="{'dark-layout-expand': darkMode === true}">
                      <div class="book-mcr-column"
                           v-if="showColumn[column.value] && column.visible && (['equipment', 'associations'].indexOf(column.value) === -1)"
                           v-for="column in getItems.columns"
                           :style="getRowStyle(association, column)"
                           :class="['book-mcr-column-' + column.value,
                           {'book-mcr-column-grow': column.value === currentOptions.growField},
                           {'dark-border': darkMode && association.type.extraValue !== 'event', 'light-border': !darkMode  && association.type.extraValue !== 'event'},
                           getRowColors(association, column)
                           ]"
                           @click="openSidebar(association)"
                           style="cursor: pointer;"
                      >
                        <span class="book-mcr-column-content" v-if="association[column.value]" v-html="association[column.value].value"></span>
                      </div>

                      <!-- Equipment -->
                      <div v-if="showColumn.equipment && association.type.extraValue !== 'event'" class="book-mcr-column book-mcr-equipment"
                           :class="{'dark-border': darkMode, 'light-border': !darkMode}"
                      >
                        <div class="book-mcr-equipment-piece" v-for="(equipment, key) in getEquipmentTypes(association.equipment) " v-if="equipment.elementType === 'equipmentPiece'">
                          <book-equipment-selection
                              :mcrRow="association"
                              :darkMode="darkMode"
                              :selection="getEquipmentTypes(association.equipment)"
                              :dates="{from: equipment.equipmentUsage.periodStart, to: equipment.equipmentUsage.periodEnd}"
                              :usage-id="equipment.equipmentUsage.usageId"
                              :isEngOrEdit="false"
                              :isEditProduction ="true"
                              :isEngProduction ="false"
                              :usage-type="equipment.equipmentUsage.usageType"
                              :disabled="false"
                              disabled-message="getEquipmentDisabledMessage"
                              :local="false"
                              :hideDates="false"
                              :entityList="entities"
                              :allEquipmentProps="allEquipment"
                              :enableCheckAvailability="true"
                              :filterTypesBy="[]"
                              :selectedItem="equipment.elementType + '#' +equipment.equipmentUsage.id"
                              :isForEquipmentUsagePage="true"
                              :isForMCRView="true"
                              :label="equipment.label"
                              :directEquipment="equipment"
                          >
                          </book-equipment-selection>
                        </div>
                      </div>

                      <!-- Ingestions -->
                      <div v-if="hasIngestions && showColumn.ingestions" class="book-mcr-column book-mcr-ingestions" :class="{'dark-border': darkMode === true}">
                        <book-ingestion
                            v-if="(
                                association.type.rawValue !== 'contribution' &&
                                association.type.extraValue !== 'task' &&
                                association.type.extraValue !== 'event'
                                )"
                            :class="{'dark-input': darkMode === true}"
                            :ingestionTypes="getItems.options.ingestions"
                            :items="association.rowOptions.ingestions"
                            :itemId="association.rowOptions.id"
                            :itemType="association.rowOptions.type"
                            :itemCountry="association.rowOptions.country"
                            :readonly="false"
                            :recordRequested="association.recordRequested.rawValue"
                            :ingestionFlow="ingestionValues(association.houseNumber.rawValue)"
                            :ingestors="ingestors"
                        >
                        </book-ingestion>
                        <!-- @input instant changes, but better with keyup.enter -->
                        <div @click="showHouseNumbers(association)" style="width: 150px;"
                             v-if="(
                                association.type.rawValue !== 'contribution' &&
                                association.type.extraValue !== 'task' &&
                                association.type.extraValue !== 'event'
                                )"
                             :class="{'dark-input': darkMode === true}">
                          <input type="text" class="housenumber-form"
                                 :title="association.houseNumber.value"
                                 v-model:trim="association.houseNumber.value"
                                 readyonly/>
                        </div>
                      </div>

                      <!-- TX Status -->
                      <div v-if="hasIngestions && showColumn.txStatus" class="book-mcr-column book-tx-status-column">
                        <book-ingestion
                            v-if="(
                                association.type.rawValue !== 'contribution' &&
                                association.type.extraValue !== 'task' &&
                                association.type.extraValue !== 'event'
                                )"
                            :class="{'dark-input': darkMode === true}"
                            :ingestionTypes="['start', 'finish', 'air']"
                            :items="association.rowOptions.ingestions"
                            :itemId="association.rowOptions.id"
                            :itemType="association.rowOptions.type"
                            :itemCountry="association.rowOptions.country"
                            :readonly="false"
                        >
                        </book-ingestion>
                      </div>

                      <!-- Viaplay -->
                      <div v-if="hasIngestions && showColumn.viaplay" class="book-mcr-column book-viaplay-column">
                        <book-ingestion
                            v-if="(
                                association.type.rawValue !== 'contribution' &&
                                association.type.extraValue !== 'task' &&
                                association.type.extraValue !== 'event'
                                )"
                            :class="{'dark-input': darkMode === true}"
                            :ingestionTypes="['routed']"
                            :items="association.rowOptions.ingestions"
                            :itemId="association.rowOptions.id"
                            :itemType="association.rowOptions.type"
                            :itemCountry="association.rowOptions.country"
                            :readonly="false"
                        >
                        </book-ingestion>
                      </div>

                      <!-- MCR Status -->
                      <div v-if="showColumn.mcrStatus" class="book-mcr-column book-mcr-status-column">
                        <book-mcr-status
                            v-if="(
                                association.type.extraValue !== 'task' &&
                                association.type.extraValue !== 'event'
                                )"
                            :class="{'dark-input': darkMode === true}"
                            :items="association.rowOptions.mcrStatus"
                            :itemId="association.rowOptions.id"
                            :itemType="getItemType(association)"
                            :extraData="getExtraDataForMcrStatus(association)"
                            :hideStatus="association.type.rawValue == 'contribution' ? [] : []">
                        </book-mcr-status>
                      </div>
                      <div v-if="showColumn.attachment" class="book-mcr-column book-mcr-attachment" style="min-width:55px; max-width: 55px;" :class="{'dark-second-input': darkMode}">
                        <button @click="getDownloads(association)" v-if="rowNumberOfDocuments(association)" class="btn btn-xs btn-mcr-toggle-associations" title="Show/hide">
                          <font-awesome-icon v-if="association.rowOptions.documents" icon="paperclip" aria-hidden="true"/>
                          {{ rowNumberOfDocuments(association) }}
                        </button>
                      </div>

                    </div>
                    <!-- Contributions inside each association -->
                    <div v-if="rowHasContributions(association)">
                      <!-- <transition-group name="slide-down-fade" class="book-mcr-transition-group"> -->
                      <ul class="list-group book-mcr-row-children book-mcr-row-contributions" key="contributions" v-if="rowHasContributions(association) && association.rowOptions.expanded">
                        <li class="book-mcr-row-child" v-for="(contribution, index) in getContributions(association)" :key="contribution.rowOptions.key + '_' + index" v-if="rowHasContributions(association) && association.rowOptions.expanded && childContributionIsVisible(contribution, row)">
                          <div class="list-group-item book-mcr-columns animate">
                            <div class="book-mcr-column"
                                 v-if="showColumn[column.value] && ['associations', 'id'].indexOf(column.value) === -1"
                                 v-for="column in getItems.columns"
                                 :style="column.style"
                                 :class="['book-mcr-column-' + column.value]">
                              <span class="book-mcr-column-content" v-if="contribution[column.value]" v-html="contribution[column.value].value"></span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <!-- </transition-group> -->
                    </div>

                  </div>
                  <!-- </transition-group> -->
                </div>
                <!-- </transition-group> -->
              </div>
            </div>
          </virtual-list>

          <!-- </transition-group> -->
        </div>
      </div>
      <book-modal :class="{'dark-equipment-modal': darkMode}"
                  @keyup.esc="$emit('close')"
                  maxWidth="400px"
                  @close="showDocuments.open = false"
                  v-if="showDocuments.open" tabindex="1"
      >
        <h4 slot="header" class="modal-title">Download <strong>Documents</strong></h4>
        <div slot="body">

          <div class="form-horizontal">
            <div class="form-group">
              <book-upload
                  ref="documentsUpload"
                  @success="response => uploadSuccess(response)"
                  @removedFile="file => uploadRemovedFile(file)"
                  @downloadedFile="file => downloadFile(file)"
                  @queueComplete="uploadQueueComplete()"
                  @error="(file, message, xhr) => uploadError(file, message, xhr)"
                  :isMcr="true"
                  :darkMode="darkMode"
                  :files="showDocuments.files"
                  :file-usage-id="showDocuments.usageItem.id.rawValue"
                  :api-endpoint="'api/' + showDocuments.usageItem.type.rawValue"
                  :disabled="!$ability.can('upload', 'Files')"
                  disabled-message="You dont have permission to download!">
              </book-upload>
            </div>
          </div>

        </div>
        <div slot="footer">
          <button class="btn btn-success" @click="getDownloads()">Close</button>
        </div>
      </book-modal>
      <book-modal :class="{'dark-equipment-modal': darkMode}"
                  @keyup.esc="$emit('close')"
                  maxWidth="400px"
                  @close="formData.showAddItemForm = false"
                  v-if="formData.showAddItemForm" tabindex="1"
      >
        <h4 slot="header" class="modal-title">Add or save <strong>House Number</strong></h4>
        <div slot="body">

          <div class="form-horizontal">
            <div class="form-group" v-for="(hnum, index) in formData.newItem">
              <label :for="index" class="control-label book-width-100">{{index.toUpperCase()}}</label>
              <div class="form-control-100">
                <book-selectbox
                    componentId="housenumber-selection"
                    :darkMode="darkMode"
                    :items="getHouseNumberPerCountry(index)"
                    :selected="formData.newItem[index]"
                    @selectedItem="item => formData.newItem[index] = item"
                    :tabindex="3">
                </book-selectbox>
              </div>
            </div>
          </div>

        </div>
        <div slot="footer">
          <button class="btn btn-success" @click="applyHouseNumber(formData.items.rowOptions.id, formData.items.rowOptions.type, formData.userCountry, formData.newItem)">Save</button>
        </div>
      </book-modal>
    </template>

  </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
@import "../../style/darkmode.scss";

.book-mcr-items-wrapper {
  margin-bottom: 20px;
  &.book-sidebar-opened {
    .book-mcr-view {
      .book-mcr-labels {
        &.affix {
          min-width: 1000px;
          right: 315px;
        }
      }
    }
    .book-mcr-labels {
      margin-right: 5px;
    }
    .book-mcr-rows {
      padding-right: 5px;
    }
  }
  .book-sidebar {
    border: 1px solid $color-grey-border;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba($color-black, 0.05);
    margin-bottom: 1px;
    .book-equipment-selection {
      margin: 0 -10px;
      .book-button-add-equipment {
        position: absolute;
        right: 8px;
        top: -25px;
      }
    }
  }
}
.book-mcr-view {
  flex: 1;
  flex-flow: row wrap;
  overflow-x: auto;
  padding-bottom: 44px;
  position: relative;
  width: 100%;
  > .book-loader {
    margin: 0 auto;
  }
  .book-mcr-no-results {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    min-height: 70px;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  .light-border {
    border-right: 1px solid #e2e2e2;
  }
  .panel:not(.book-mcr-no-results) {
    padding-left: 2px;
    padding-right: 2px;
  }
  .panel-body {
    min-height: 30px;
    padding: 0;
  }
  .book-mcr-transition-group {
    display: block;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .housenumber-form {
    display: block;
    font-size: 14px;
    color: #555555;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 5px;
    padding-left: 5px;
    width: 90%;
  }
  .book-mcr-row,
  .book-mcr-row-child {
    transition: all .3s ease;
  }
  .book-mcr-row {
    margin-top: 5px;
    position: relative;
    &.book-mcr-selected {
      > .dark-layout {
        background: #0D2936 !important;
      }
      > .panel {
        background: #5093ad;
        color: white;
        .book-mcr-column {
          &.active {
            background: $color-blue;
          }
        }
      }
      .btn,
      .label,
      .book-square {
        //border: 1px solid rgba($color-white, .4);
      }
      .book-ingestion {
        //background: $color-white;
        border-radius: 4px;
      }
    }
    &.book-mcr-expanded,
    &.book-mcr-highlighted {
      > .panel {
        border-left: solid $color-blue-primary !important;
        border-left-width: 6px;
        border-right: solid $color-blue-primary !important;
        border-right-width: 6px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.book-mcr-expanded {
      > .panel {
        position: relative;
        z-index: unset;
        box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.4);
      }
    }
    &:first-child {
      margin-top: 0;
    }
    &:not(.book-mcr-selected):not(.book-mcr-expanded):not(.book-mcr-row-child):hover {
      > .panel {
        background: #d3e2ef;
      }
      > .dark-layout {
        background: #0D2936 !important;
      }
    }
    &.book-mcr-expanded:not(.book-mcr-selected) {
      > .panel {
        background: #d3e2ef;
      }
      > .dark-layout {
        background: #1e2a2f !important;
      }
    }
    .panel {
      margin-bottom: 0;
    }
    .book-mcr-group-label {
      // box-shadow: 0 3px 20px -8px rgba($color-shadow, 0.4);
      font-weight: bold;
      font-size: 1.1em;
      // margin-bottom: 5px;
      padding: 4px 0 4px 25px;
    }
  }
  .book-mcr-row-contributions {
    + .book-mcr-row-children {
      box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.2);
    }
  }
  .book-mcr-row-children {
    display: block;
    margin: -1px 43px 0 17px;
    position: relative;
    z-index: unset;
    &:last-child {
      margin-bottom: 10px;
    }
    // .book-mcr-column.book-mcr-toggle {
    //     width: 55px;
    // }
    .book-mcr-row-child {
      display: flex;
      flex-flow: row wrap;
      margin-top: -1px;
      position: relative;
      z-index: unset;
      &:hover,
      &.book-mcr-expanded {
        > .dark-layout {
          background: #0D2936 !important;
          color: white !important;
        }
        > .dark-layout-expand {
          background: #0D2936 !important;
          color: white !important;
        }
        > .list-group-item {
          background: #d3e2ef;
          color: black;
        }
        .book-mcr-column {
          z-index: 1;
        }
      }
      &:first-child {
        .list-group-item {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
      &:last-child {
        &.book-mcr-row-with-children {
          .book-mcr-row-child {
            .list-group-item {
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .list-group-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.book-mcr-expanded {
        + .book-mcr-row-child {
          box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.2);
          margin-top: -1px;
          .list-group-item {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        > .list-group-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.book-mcr-row-with-children {
        overflow: hidden;
        > .list-group-item {
          box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.2);
          z-index: unset;
        }
        .book-mcr-row-child {
          .list-group-item {
            border-radius: 0;
          }
        }
        .book-mcr-columns {
          position: relative;
        }
      }
      .book-mcr-columns {
        width: 100%;
      }
    }
    .list-group-item {
      min-height: 32px;
      padding: 0;
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .book-mcr-row-contributions {
    .book-mcr-column-id {
      span {
        display: none;
      }
    }
    .list-group-item {
      background: darken($color-white, 2%);
    }
  }
  .book-mcr-row-associations {
    .book-mcr-row-contributions {
      margin: 0 70px;
    }
  }
  .book-mcr-labels {
    background: rgba($color-white, 0.95);
    font-weight: 700;
    margin-bottom: 5px;
    &.affix {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
      left: 15px;
      right: 15px;
      z-index: 1000;
      ~ .book-mcr-rows {
        margin-top: 36px;
      }
    }
    .book-mcr-column {
      align-items: center;
      display: flex;
      &.book-sortable {
        cursor: pointer;
        user-select: none;
      }
    }
    .book-mcr-toggle {
      button {
        width: 24px;
      }
    }
  }
  .book-mcr-labels,
  .book-mcr-rows {
    flex-basis: 100%;
  }
  .book-mcr-columns {
    align-items: stretch;
    display: flex;
    padding-left: 15px;
    &.updated {
      background: #b7b9454d !important;
    }
    &.renewed {
      background: #73d0bf52 !important;
    }
    &.renewed-dark {
      background: #3e654a !important;
    }
  }
  .panel > .book-mcr-columns > .book-mcr-column.book-mcr-toggle {
    max-width: 48px;
    min-width: 48px;
  }
  .panel > .book-mcr-columns > .book-mcr-column.book-mcr-attachment {
    max-width: 48px;
    min-width: 48px;
  }
  .book-mcr-column {
    align-items: center;
    display: flex;
    padding: 0 8px;
    &.book-mcr-column-grow {
      //flex: 1;
    }
    &.book-mcr-toggle {
      justify-content: flex-end;
      padding: 0 4px;
      width: 55px;
      .btn-mcr-toggle-contributions {
      }
      .btn-mcr-toggle-associations {
        margin-left: 3px;
        width: 38px;
      }
      .btn:focus {
        outline: none;
      }
    }
    &.active {
      //background: $color-item-hover;
    }
    &.grey {
      background: #949494 !important;
      color: $color-list-item-text-unsigned-priority;
    }
    &.red {
      background: $color-list-item-unsigned-priority;
      color: $color-list-item-text-unsigned-priority;
    }
    &.green {
      background: $color-list-item-signed;
      color: $color-list-item-text-unsigned-priority;
    }
    &.orange {
      background: $color-list-item-unsigned;
    }
    &.updated {
      background: $color-blue !important;
    }
    &.comment {
      background: #364a67 !important;
      color: white;
    }
    &.renewed {
      background: #62ff6047 !important;
    }
    &.renewed-dark {
      background: #3e654a !important;
    }
    &.book-mcr-equipment {
      min-width: 375px;
      width: 375px;
      flex: 1;
      white-space: normal;
      display: flex;
      overflow: hidden;
      .book-mcr-equipment-type,
      .book-mcr-equipment-piece {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 70px;
        position: relative;
        text-align: left;
        white-space: nowrap;
      }

      .book-mcr-equipment-type {
        max-width: 270px;
      }
      .book-mcr-equipment-type-label {
        font-weight: 600;
        margin-right: 3px;
        &:last-child {
          margin-right: 10px;
        }
      }
      .book-mcr-equipment-piece {
        max-width: 151px;
        vertical-align: middle;
        > .book-equipment-selection {
          min-height: 30px !important;
          max-height: 30px !important;
          > .book-box-overlay {
            cursor: pointer !important;
            z-index: unset !important;
            > .label {
              width: 95%;
              padding: 5px;
              margin-right: -3px;
            }
          }
        }
      }
    }
    &.book-mcr-status-column,
    &.book-mcr-ingestions,&.book-viaplay-column,&.book-tx-status-column {
      text-align: center;
    }
    &.book-mcr-status-column {
      width: 122px;
      margin-left: 10px;
    }
    &.book-mcr-ingestions {
      width: 230px;
    }
    &.book-viaplay-column {
      width: 37px;
    }
    &.book-tx-status-column {
      width: 88px;
    }
    &.book-mcr-actions {

      // @TODO The width whould be set dynamically...
      width: 85px;

      button {
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    > .book-mcr-column-content {
      display: block;
      overflow: hidden;
      //text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    [class*=book-item-status-] {
      > * {
        vertical-align: middle;
      }
      .book-square {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 25px;
        margin-right: 5px;
        border: 0px solid #fff;
      }
      .label {
        display: inline-block;
        padding: 3px 6px 5px;
      }
      .book-square + .label {
        margin-left: 2px;
      }
    }
    .book-column-icon-wrapper {
      display: block;
      flex: 1;
      font-size: 18px;
      text-align: center;
    }
    .small-font {
      font-size: 13px !important;
    }
    .book-info-item {
      display: inline-block;
      + .book-info-item {
        border-left: 1px solid $color-text;
        margin-left: 5px;
        padding-left: 5px;
      }
    }
  }
  .book-marker {
    margin-right: 8px;
  }
}
.contribution-equipment {
  background-color: #004779 !important;
}
.contribution-equipment-dm {
  background-color: #001e33 !important;
}
.cov-vue-date > .datepicker-overlay {
  white-space: normal !important;
}
.mcr-comment-light {
  background-color: #cce3e7 !important;
}
.mcr-comment-dark {
  background-color: #0c1d20 !important;
}
.label-mcr-comment {
  background-color: #4a919d !important;
  margin-left: 19px;
}
.label-grey {
  background-color: #949494 !important;
}



.vue-dropzone:hover {
  background: unset !important
}
</style>
