<script>
import {app} from 'app'
import ForkPlaceholder from 'components/forkplaceholder/ForkPlaceholder'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import Datetime from 'components/common/helpers/Datetime'
import BookDatetime from 'components/common/BookDatetime'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
    BookTable,
    BookModal,
    BookDatetime,
    BookLoader
  },
  props: {
    isModalMode: {
        default: false,
        type: Boolean
    },
    isMCRView: {
        default: false,
        type: Boolean
    },
    isDateChange: {
        default: false,
        type: Boolean
    },
    entityList: {
      default: () => ({})
    },
    itemPlaceholders: {
      default: () => [],
      type: Array
    },
    itemId: {
      default: 0,
      type: Number
    },
    itemType: {
      default: '',
      type: String
    },
    itemTitle: {
      default: '',
      type: String
    },
    itemStart: {
        default: '',
        type: String
    },
    itemEnd: {
        default: '',
        type: String
    },
    selected: {
        default: 0,
        type: Number
    },
    disabled: {
        default: false,
        type: Boolean
    },
    disabledMessage: String,
    darkMode: {
      default: false
    }
  },
  data() {
    return {
      formData: this.defaultData(),
      addForm: {
        show: false,
        edit: false,
        local: false,
        forkPlaceholder: null,
      },
      loaded: false,
      placeholdersToSend: [],
      placeholdersToSubmit: null,
      showPlaceholdersToSubmit: false,
      initialItemTitle: ''
    }
  },
  watch: {
       selected: function(newValue) {
          if(newValue !== 0) {
              this.editItem(newValue)
          }
      },
      itemTitle: function(newValue) {
          const context = this
          Lazy(context.itemPlaceholders)
          .each((v,k) => {
              Lazy(v.placeholders)
              .each((v2,k2) => {
                  let prevTitle = context.transformTitle(context.initialItemTitle, v2.title)
                  v2.title = context.itemTitle + ' ' + prevTitle.changedValue
                  
              })
          })

          context.initialItemTitle = context.itemTitle
      },
      'formData.periodStart.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            // else if (!Datetime.isAfter(newValue, this.formData.productionEnd.value, format)) {
            if (!Datetime.isAfter(newValue, this.formData.periodEnd.value, format)) {
                this.formData.periodEnd.value = this.formData.periodStart.value
            }
            // else {
            Vue.nextTick(() => {
                this.$validator.validate('periodStart', newValue)
            })
            // }
        },
        'formData.periodEnd.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(this.formData.periodStart.value, newValue, format)) {
                Vue.nextTick(() => {
                    this.formData.periodEnd.value = this.formData.periodStart.value
                })
            }

            Vue.nextTick(() => {
                this.$validator.validate('periodEnd', this.formData.periodEnd.value)
            })
        },
      
  },
  computed: {
    
    processing() {
      return this.$loader.has('bookForkPlaceholderSubmitting')
    },
    isRecordItem() {

    },
    getApiUrl() {
      return 'api/' + this.itemType + '/' + this.itemId + '/' + 'placeholder'
    },
    getApiUrlSubmit() {
        return 'api/' + this.itemType + '/' + this.itemId + '/' + 'placeholder/submit'
    },
    isFormChanged() {
        if(this.addForm.edit) {
            if(this.addForm.forkPlaceholder.country !== this.formData.country.value) return true
            if(this.addForm.forkPlaceholder.title !== this.formData.title.value) return true
            if(this.addForm.forkPlaceholder.periodStart !== this.formData.periodStart.value) return true
            if(this.addForm.forkPlaceholder.periodEnd !== this.formData.periodEnd.value) return true
            if(this.addForm.forkPlaceholder.submit !== this.formData.submit.value) return true
        }
        else {
            if(this.formData.country.value || this.formData.title.value || 
               this.formData.periodStart.value || this.formData.periodEnd.value || this.formData.submit.value) return true
        }
        return false
    },
    isFormValid() {
        if(!this.formData.country.value || this.formData.country.value === '') return false
        if(!this.formData.periodStart.value || this.formData.periodStart.value === '') return false
        if(!this.formData.periodEnd.value || this.formData.periodEnd.value === '') return false

        return true
    },
    periodStartDateLimit() {
        let now = moment(this.now)
        return [{
            type: 'fromto',
            from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
        }]
    },
    periodEndDateLimit() {
        let from = moment(this.formData.periodStart.value, 'YYYY-MM-DD HH:mm')
        return [{
            type: 'fromto',
            from: from.subtract(1, 'd')
        }]
    },
    forkCountries() {
        const context = this
        let allCountries = []
        if(context.isModalMode) {
            Lazy(context.placeholdersToSubmit)
            .each(function(v, k) {
                allCountries.push({
                    country: v.country,
                    local: v.local
                })
            })
        }
        else {
            Lazy(context.itemPlaceholders)
            .each(function(v, k) {
                allCountries.push({
                    country: v.country,
                    local: v.local
                })
            })
        }

        return allCountries
    },
    getAllPlaceholders() {
        const context = this
        let allPlaceholders = []
        if(context.isModalMode) {
            Lazy(context.placeholdersToSubmit)
            .each(function(v, k) {
                Lazy(v.placeholders)
                    .each(function (v2, k2) {
                        allPlaceholders.push(v2)
                    })
            })
            
        }
        else {
            Lazy(context.itemPlaceholders)
            .each(function(v, k) {
                Lazy(v.placeholders)
                    .each(function (v2, k2) {
                        allPlaceholders.push(v2)
                    })
            })
        }
        
        return allPlaceholders
    },
    countries() {
        const context = this
        if(context.$store.state.system.countries && context.forkCountries) {
            return context.$store.state.system.countries.filter(i => _.findIndex(context.forkCountries, j => j.country === i.code) > -1)
        }
        return null
    },
    dataList() {
        const context = this
        let placeholders = []

        Lazy(context.getAllPlaceholders)
            .each(function (v2, k2) {
                let countryLabel = _.find(context.countries, i => i.code === v2.country)
                let checked = _.findIndex(context.placeholdersToSend, i => i === v2.id) > -1
                let item = {
                    id: {
                        value: v2.id
                    },
                    forkPlaceholder: {
                        value: v2.forkPlaceholder
                    },
                    country: {
                        rawValue: v2.country,
                        value: countryLabel ? countryLabel.label : ''
                    },
                    periodStart: {
                        value: v2.periodStart
                    },
                    periodEnd: {
                        value: v2.periodEnd
                    },
                    status: {
                        value: v2.status
                    },
                    updatedAtFork: {
                        value: v2.updatedAtFork
                    },
                    local: {
                        value: v2.local
                    },
                    submit: {
                        value: v2.submit
                    },
                    rowOptions: {
                        type: v2.local ? 'icon' : (v2.forkAction !== 'delete' ? 'checkbox' : ''),
                        title: v2.local ? v2.action + (v2.submit ? ' & submit' : '') : '',
                        checked: checked,
                        classes: v2.action && v2.action === 'delete' || v2.forkAction === 'delete' ? ['strikeout'] : [],
                        hideActions: []
                    }
                }
                if(v2.forkAction === 'delete') {
                    if (context.$ability.can('update_fork_placeholder', 'Fork')) {
                        item.rowOptions.hideActions.push('edit')
                    }
                    if (context.$ability.can('delete_fork_placeholder', 'Fork')) {
                        item.rowOptions.hideActions.push('delete')
                    }
                }
                placeholders.push(item)
            })
        

        const output = {
                options: {
                    actions: [],
                    alternativeCheckboxes: {
                        //disabled: context.disabled,
                        eventName: 'submitPlaceholdersChecked'
                    }
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'forkPlaceholder',
                        label: 'Ingest Id',
                        visible: true
                    },
                    {
                        value: 'country',
                        label: 'Country',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'periodStart',
                        label: 'Start',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'periodEnd',
                        label: 'End',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'status',
                        label: 'Status',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'updatedAtFork',
                        label: 'Clip updated',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'local',
                        label: 'Local',
                        visible: false
                    },
                    {
                        value: 'submit',
                        label: 'Submit',
                        visible: false
                    },
                ],

                rows: placeholders
            }

            if (context.$ability.can('update_fork_placeholder', 'Fork')) {
                output.options.actions.push('edit')
            }
            if (context.$ability.can('delete_fork_placeholder', 'Fork')) {
                output.options.actions.push('delete')
            }

        return output
    },
    dataListModal() {
        const context = this
        let placeholders = []

        Lazy(context.getAllPlaceholders)
            .each(function (v2, k2) {
                let countryLabel = _.find(context.countries, i => i.code === v2.country)
                let item = {
                    id: {
                        value: v2.id
                    },
                    country: {
                        rawValue: v2.country,
                        value: countryLabel ? countryLabel.label : ''
                    },
                    periodStart: {
                        value: v2.periodStart
                    },
                    periodEnd: {
                        value: v2.periodEnd
                    },
                    local: {
                        value: v2.local
                    },
                    submit: {
                        value: v2.submit
                    },
                    rowOptions: {
                        type: v2.local ? 'icon' : (v2.forkAction !== 'delete' ? 'checkbox' : ''),
                        title: v2.local ? v2.action + (v2.submit ? ' & submit' : '') : '',
                        classes: (v2.action && v2.action === 'delete') || v2.forkAction === 'delete' ? ['strikeout'] : [],
                        hideActions: []
                    }
                }
                if(v2.forkAction === 'delete') {
                    if (context.$ability.can('update_fork_placeholder', 'Fork')) {
                        item.rowOptions.hideActions.push('edit')
                    }
                    if (context.$ability.can('delete_fork_placeholder', 'Fork')) {
                        item.rowOptions.hideActions.push('delete')
                    }
                }
                placeholders.push(item)
            })
        

        const output = {
                options: {
                    actions: [],
                    alternativeCheckboxes: {
                        //disabled: context.disabled,
                        eventName: 'submitPlaceholdersChecked'
                    }
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'country',
                        label: 'Country',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'periodStart',
                        label: 'Start',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'periodEnd',
                        label: 'End',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'local',
                        label: 'Local',
                        visible: false
                    },
                    {
                        value: 'submit',
                        label: 'Submit',
                        visible: false
                    },
                ],

                rows: placeholders
            }

            if (context.$ability.can('update_fork_placeholder', 'Fork')) {
                output.options.actions.push('edit')
            }
            if (context.$ability.can('delete_fork_placeholder', 'Fork')) {
                output.options.actions.push('delete')
            }

        return output
    },
  },
  
  mounted() {
      if(this.isModalMode) {
          this.showPlaceholdersToSubmit = true
          this.placeholdersToSubmit = this.itemPlaceholders.slice()
          this.editItem(this.selected)
      }
      this.initialItemTitle = this.itemTitle
  },
  methods: {
    submit(send = false) {
        const context = this
        if(context.formData.local.value) {
            if(context.addForm.edit) {
                context.addForm.forkPlaceholder.country = context.formData.country.value
                context.addForm.forkPlaceholder.title = context.formData.title.value
                context.addForm.forkPlaceholder.periodStart = context.formData.periodStart.value
                context.addForm.forkPlaceholder.periodEnd = context.formData.periodEnd.value
                context.addForm.forkPlaceholder.submit = context.formData.submit.value
            }
            else {
                let newItem = {
                    id: context.formData.id.value,
                    country: context.formData.country.value,
                    periodStart: context.formData.periodStart.value,
                    periodEnd: context.formData.periodEnd.value,
                    title: context.formData.title.value,
                    itemId: context.formData.itemId.value,
                    itemType: context.formData.itemType.value,
                    local: context.formData.local.value,
                    action: context.formData.action.value,
                    submit: context.formData.submit.value
                }
                if(context.isModalMode) {
                    let currentCountry = _.find(context.placeholdersToSubmit, i => i.country === newItem.country)
                    currentCountry.placeholders.push(newItem)
                }
                else {
                    if(!context.isDateChange) {
                        context.$emit('addPlaceholder', newItem)
                    }
                    else {
                        context.$emit('addPlaceholderWithDates', newItem)
                    }
                    
                }
            }
            
            
            context.showForm(false)
        }
        else {
            let id = context.addForm.edit ? context.formData.id.value : 0
            let placeholder = null
            placeholder = new ForkPlaceholder(this.getApiUrl)
            placeholder.removeAll()
            placeholder = new ForkPlaceholder(this.getApiUrl, id)
            if(send) {
                context.formData.submit.value = true
            }
            placeholder.set('country', context.formData.country.value)
            placeholder.set('title', context.formData.title.value)
            placeholder.set('periodStart', context.formData.periodStart.value)
            placeholder.set('periodEnd', context.formData.periodEnd.value)
            placeholder.set('itemId', context.formData.itemId.value)
            placeholder.set('itemType', context.formData.itemType.value)
            placeholder.set('submit', context.formData.submit.value)

            context.$loader.add('bookForkPlaceholderSubmitting')

            if(!context.addForm.edit) {
                placeholder.store()
                .then(response => {
                    console.log(response.data)
                    if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                    let item = context.findAddedPlaceholder(response.data.placeholders, context.formData.country.value)
                    if(item) {
                        item.local = false
                        item.action = 'none'
                        item.submit = false
                        context.$emit('addPlaceholder', item)
                    }
                    context.showForm(false)

                    if(response.data && response.data.error) {
                        console.log(response.data.error)
                        this.$alert.set(response.data.error,'danger', 10)
                    }

                    context.$loader.remove('bookForkPlaceholderSubmitting')
                })
                .catch(error => {
                    console.log(error)
                    context.showForm(false)
                    context.$loader.remove('bookForkPlaceholderSubmitting')
                })
            }
            else {
                placeholder.save()
                .then(response => {
                    console.log(response.data)

                    if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                    context.updatePlaceholders(response.data.placeholders, context.formData.country.value, id)
                   

                    context.showForm(false)

                    if(response.data && response.data.error) {
                        console.log(response.data.error)
                        this.$alert.set(response.data.error,'danger', 10)
                    }

                    context.$loader.remove('bookForkPlaceholderSubmitting')
                })
                .catch(error => {
                    console.log(error)
                    context.$loader.remove('bookForkPlaceholderSubmitting')
                    context.showForm(false)
                })
            }
        }
    },
    findAddedPlaceholder(data, country) {
        let currentCountry = _.find(data, i => i.country === country)
        let addedItem = null
        if(currentCountry && currentCountry.placeholders && currentCountry.placeholders.length > 0) {
            let originCountry = _.find(this.itemPlaceholders, i => i.country === country)
            Lazy(currentCountry.placeholders)
                .each((v, k) => {
                    if(originCountry && originCountry.placeholders && originCountry.placeholders.length > 0) {
                        let isPlaceholderExist = _.find(originCountry.placeholders, i => i.id === v.id)
                        if(isPlaceholderExist === undefined) {
                            addedItem = Object.assign({}, v)
                        }
                    }
                    else {
                        addedItem = Object.assign({}, v)
                    }
                })
        }

        return addedItem
    },
    updatePlaceholders(data, country, id) {
        let currentCountry = _.find(data, i => i.country === country)
        let items = this.itemPlaceholders.slice()
        if(currentCountry && currentCountry.placeholders && currentCountry.placeholders.length > 0) {
            let updatedItem = _.find(currentCountry.placeholders, i => i.id === id)

           if(updatedItem) {
                updatedItem = Object.assign({}, updatedItem)
                updatedItem.local = false
                updatedItem.action = 'none'
                updatedItem.submit = false

                let cnt = _.find(items, i => i.country === country)
                let cntItemIndex = _.findIndex(cnt.placeholders, i => i.id === id)
                if(cntItemIndex > -1) cnt.placeholders.splice(cntItemIndex, 1)
                cnt.placeholders.push(updatedItem)
                
                this.$emit('updatePlaceholders', items)
            }
            
        }

        
    },
    submitToFork() {
        const context = this
        if(context.placeholdersToSend && context.placeholdersToSend.length > 0) {
            
            const params = {
                placeholders: context.placeholdersToSend,
                itemId: context.itemId,
                itemType: context.itemType
            }

            context.$loader.add('bookForkPlaceholderSubmitting')
            axios.post(context.getApiUrlSubmit, params)
                .then(response => {
                    console.log(response.data)
                    if(response.data && response.data.error) {
                        console.log(response.data.error)
                        context.$alert.set(response.data.error, 'danger', 10)
                    }
                    
                    Lazy(context.placeholdersToSend)
                        .each((v,k) => {
                            let currentPlaceholder = _.find(context.getAllPlaceholders, i => i.id === v)
                            if(currentPlaceholder !== undefined) {
                                if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                                context.updatePlaceholders(response.data.placeholders, currentPlaceholder.country, v)
                            }
                            
                        })
                    
                    

                    context.placeholdersToSend = []

                    context.$loader.remove('bookForkPlaceholderSubmitting')
                })
                .catch(error => {
                    console.log(error)
                    context.placeholdersToSend = []
                    context.$loader.remove('bookForkPlaceholderSubmitting')
                })
        }
    },
    editItem(id) {
        const context = this
                
        context.addForm.forkPlaceholder = _.find(context.getAllPlaceholders, i => i.id === id)

        if (!context.addForm.forkPlaceholder) return

        context.addForm.edit = true
        context.addForm.local = context.addForm.forkPlaceholder.local

        let title= context.transformTitle(context.itemTitle, context.addForm.forkPlaceholder.title)

        context.formData.id.value = id
        context.formData.country.value = context.addForm.forkPlaceholder.country
        context.formData.periodStart.value = context.addForm.forkPlaceholder.periodStart
        context.formData.periodEnd.value = context.addForm.forkPlaceholder.periodEnd
        context.formData.title.value = context.addForm.forkPlaceholder.title
        context.formData.title.fixedValue = title.fixedValue
        context.formData.title.changedValue = title.changedValue
        context.formData.itemId.value = context.addForm.forkPlaceholder.itemId
        context.formData.itemType.value = context.addForm.forkPlaceholder.itemType
        context.formData.forkPlaceholder.value = context.addForm.forkPlaceholder.forkPlaceholder ? context.addForm.forkPlaceholder.forkPlaceholder : null
        context.formData.local.value = context.addForm.forkPlaceholder.local
        context.formData.action.value = context.addForm.forkPlaceholder.action
        context.formData.submit.value = context.addForm.forkPlaceholder.local ? context.addForm.forkPlaceholder.submit : false

        context.showForm(true)
    },
    transformTitle(fixedTitle, title) {
        if(fixedTitle !== title) {
            return {
                fixedValue: fixedTitle,
                changedValue: title.substring(fixedTitle.length + 1)
            }
        }
        else {
            return {
                fixedValue: title,
                changedValue: ''
            }
        }
    },
    deleteItem(item) {
        console.log(item)
        const context = this
        let id = item.id.value
        if(item.local.value) {
            if(context.isModalMode) {
                if(context.isDateChange) {
                    context.$emit('excludePlaceholderWithDates', id)
                } else {
                    let currentCountry = _.find(context.placeholdersToSubmit, i => i.country === item.country.rawValue)
                    let currentPlaceholderIndex = _.findIndex(currentCountry.placeholders, i => i.id === id)
                    if(currentPlaceholderIndex > -1) {
                        currentCountry.placeholders.splice(currentPlaceholderIndex, 1)
                    }
                }                
            }
            else {
                if(!context.isDateChange) {
                    context.$emit('excludePlaceholder', id)
                }
                else {
                    context.$emit('excludePlaceholderWithDates', id)
                }
                
            }
            
        }
        else {
            swal({
                title: 'Delete placeholder?',
                text: `Are you sure you want to delete placeholder?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
            .then(function () {
                const placeholder = new ForkPlaceholder(context.getApiUrl, id)
                context.$loader.add('bookForkPlaceholderSubmitting')
                placeholder.delete()
                    .then((response) => {
                        context.$emit('excludePlaceholder', id)
                        context.$loader.remove('bookForkPlaceholderSubmitting')
                    })
                    .catch((error) => {
                        console.log(error)
                        context.$error.set(error)
                        context.$loader.remove('bookForkPlaceholderSubmitting')
                    })
            })
            
        }
    },
    submitItem(id) {
        console.log(id)
        if(this.placeholdersToSend && this.placeholdersToSend.length > 0) {
            let idIndex = _.findIndex(this.placeholdersToSend, i => i === id)
            if(idIndex > -1) {
                this.placeholdersToSend.splice(idIndex, 1)
            }
            else {
                this.placeholdersToSend.push(id)
            }
        }
        else {
            this.placeholdersToSend.push(id)
        }
    },
    /**
     * Open/close the add/edit form for Placeholder.
     * @param boolean open Tells the function to open/close the form.
     */
    showForm(open = true) {
      if (!open) {
        this.resetForm()
        this.addForm.show = false
      }
      else {
        if (!this.formData.id.value) {
            this.setFormDefaultData()
            
        }
        else {

        }
        Vue.nextTick(() => {
          this.addForm.show = true
        })
      }
    },
    /**
     * Reset the values on add form.
     */
    resetForm() {
      this.formData = this.defaultData()
      this.addForm.forkPlaceholder = null
      this.addForm.edit = false
      this.addForm.local = false
      this.$emit('resetDefaultPlaceholder', 0)
    },
    setFormDefaultData() {
        this.formData.id.value = moment().unix()
        this.formData.country.value = app.$data.user.get.country
        this.formData.periodStart.value = this.itemStart
        this.formData.periodEnd.value = this.itemEnd
        this.formData.title.value = this.itemTitle
        this.formData.title.fixedValue = this.itemTitle
        this.formData.title.changedValue = ''
        this.formData.itemId.value = this.itemId
        this.formData.itemType.value = this.itemType
        this.formData.local.value = this.isCountryLocal(app.$data.user.get.country)
        this.formData.action.value = "create"
        this.formData.submit.value = false
    },
    isCountryLocal(country) {
        let currentCountry = _.find(this.forkCountries, f => f.country === country)

        return currentCountry ? currentCountry.local : true
    },
    closeModalMode(removePlaceholdersInParent = false) {
        const context = this
        if(removePlaceholdersInParent) {
            let countries = context.forkCountries.map(i => i.country)
            context.placeholdersToSubmit = null
            context.showPlaceholdersToSubmit = false
            context.$emit('removeDefaultPlaceholders', {
                itemId: context.itemId,
                itemType: context.itemType,
                countries: countries
            })
            
        }
        else {
            context.placeholdersToSubmit = null
            context.showPlaceholdersToSubmit = false
        }

        if(context.isMCRView) {
            app.$emit('ResetPlaceholderForm')
        }

    },
    submitMany() {
        const context = this
        if(!context.isDateChange) {
            let placeholders = []
            Lazy(context.placeholdersToSubmit)
            .each((v,k) => {
                Lazy(v.placeholders)
                .each((v1,k1) => {
                    if(context.isMCRView) {
                        const _clone_id = Math.floor(100000000000 + Math.random() * 900000000000) // 12 digit
                            app.$emit('AddPlaceholder', {
                                itemId: v1.itemId,
                                itemType: v1.itemType,
                                item: {
                                    id: _clone_id,
                                    country: v1.country,
                                    periodStart: v1.periodStart,
                                    periodEnd: v1.periodEnd,
                                    title: v1.title,
                                    itemId:  v1.itemId,
                                    itemType: v1.itemType,
                                    local: true,
                                    action: 'create',
                                    submit: v1.submit
                                },
                                action: {
                                    module: 'placeholder',
                                    type: 'store',
                                },
                                items: []
                            })

                    }
                    else {
                        placeholders.push(v1)
                    }
                    
                })
            })

            if(!context.isMCRView) {
                const params = {
                    placeholders: placeholders,
                    itemId: context.itemId,
                    itemType: context.itemType
                }

                context.$loader.add('bookForkPlaceholderSubmitting')
                axios.post(context.getApiUrl + '/masscreate', params)
                    .then(response => {
                        console.log(response.data)
                        context.closeModalMode()
                        if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                        context.$emit('addPlaceholder', {
                            itemId: context.itemId,
                            itemType: context.itemType,
                            itemPlaceholders: response.data.placeholders.slice()
                        })

                        if(response.data && response.data.error) {
                            console.log(response.data.error)
                            context.$alert.set(response.data.error, 'danger', 10)
                        }
                        context.$loader.remove('bookForkPlaceholderSubmitting')
                    })
                    .catch(error => {
                        console.log(error)
                        context.closeModalMode(true)
                        context.$loader.remove('bookForkPlaceholderSubmitting')
                    })
            }
            else {
                context.closeModalMode()
            }
            
        }
        else {
            context.$emit('updatePlaceholdersWithDates')
        }
        
    },
    defaultData() {
        return {
            id: {
                value: ''
            },
            country: {
                value: '',
                validator: {
                    rules: 'required'
                }
            },
            periodStart: {
                value: '',
                validator: {
                    rules: 'required'
                }
            },
            periodEnd: {
                value: '',
                validator: {
                    rules: 'required'
                }
            },
            title: {
                value: '',
                fixedValue: '',
                changedValue: '',
                validator: {
                    rules: 'required'
                }
            },
            itemId: {
                value: '',
                validator: {
                    rules: 'required'
                }
            },
            itemType: {
                value: '',
                validator: {
                    rules: 'required'
                }
            },
            forkPlaceholder: {
                value: ''
            },
            // updatedAtFork: {
            //     value: ''
            // },
            // updatedByFork: {
            //     value: ''
            // },
            // status: {
            //     value: ''
            // },
            local: {
                value: false
            },
            action: {
                value: ''
            },
            submit: {
                value: false
            }

        }
    }
  }
}
</script>
<template>
    <div class="book-fork-placeholder">
        <template v-if="isModalMode">
            <book-modal maxWidth="600px" @close="closeModalMode(true)" v-if="showPlaceholdersToSubmit" :class="{'dark-equipment-modal': darkMode}">
                <h4 slot="header" class="modal-title">
                    <template>Fork placeholders</template>
                </h4>
                <div slot="body">
                    <button
                        class="btn btn-success btn-xs book-button-add-placeholder-modal"
                        @click="showForm(true)"
                        :disabled="disabled || processing"
                        v-if="$ability.can('create_fork_placeholder', 'Fork')">
                        <font-awesome-icon icon="plus"/>&nbsp;Add
                    </button>
                    <book-table
                        class="table-modal"
                        :items="dataListModal"
                        :component="'forkPlaceholderModal'"
                        :darkMode="darkMode"
                        :notFoundMessage="!disabled || !disabledMessage ? 'No placeholder has been added' : ''"
                        @edit="item => editItem(item.id.value)"
                        @delete="item => deleteItem(item)">
                    </book-table>
                    
                </div>
                <div slot="footer">
                    <button tabindex="15" class="btn btn-info" @click="submitMany()" :disabled="disabled || processing">Save</button>
                </div>
            </book-modal>
        </template>
        <template v-else>
            <button
            class="btn btn-primary btn-xs book-button-add-placeholder"
            style="margin-right:55px;"
            @click="submitToFork()"
            :disabled="!placeholdersToSend || placeholdersToSend.length == 0 || processing"
            v-if="$ability.can('send_fork_placeholder', 'Fork')">
            <span><font-awesome-icon icon="arrow-circle-up"/>&nbsp;Submit to Fork</span>
            </button>
            <button
                class="btn btn-success btn-xs book-button-add-placeholder"
                @click="showForm(true)"
                :disabled="disabled || processing"
                v-if="$ability.can('create_fork_placeholder', 'Fork')">
                <font-awesome-icon icon="plus"/>&nbsp;Add
            </button>
            <book-table  
                :items="dataList"
                :component="'forkPlaceholder'"
                :notFoundMessage="!disabled || !disabledMessage ? 'No placeholder has been added' : ''"
                @submitPlaceholdersChecked="item => submitItem(item.id.value)"
                @edit="item => editItem(item.id.value)"
                @delete="item => deleteItem(item)">
            </book-table>
        </template>
        <book-modal maxWidth="400px" @close="showForm(false)" v-if="addForm.show" :class="{'dark-equipment-modal': darkMode}">
            <h4 slot="header" class="modal-title">
                <template v-if="!addForm.edit">Add</template>
                <template v-if="addForm.edit">Edit</template>
            </h4>
            <div slot="body">
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Country
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" @change="formData.local.value = isCountryLocal(formData.country.value)" v-if="countries" :disabled="formData.forkPlaceholder.value !== null && formData.forkPlaceholder.value !== ''" tabindex="12">
                                <option value="">
                                    None
                                </option>
                                <option v-for="country in countries" :value="country.code">
                                    {{country.label}}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        
                        <book-datetime
                            component-id="book-period-start"
                            class="form-control-100"
                            :required="{date: true, time: true}"
                            :default-value="formData.periodStart.value ? formData.periodStart.value : ''"
                            @newValue="value => formData.periodStart.value = value"
                            :small="true"
                            :limit="periodStartDateLimit"
                            :dateTabindex="4"
                            :btnOpenTabindex="5"
                            :timeTabindex="6">
                        </book-datetime>
                        
                    </div>
                    <div class="form-group">
                        <label class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            End
                        </label>
                        
                        <book-datetime
                            component-id="book-period-end"
                            class="form-control-100"
                            :required="{date: true, time: true}"
                            :default-value="formData.periodEnd.value ? formData.periodEnd.value : ''"
                            @newValue="value => formData.periodEnd.value = value"
                            :small="true"
                            :limit="periodEndDateLimit"
                            :dateTabindex="4"
                            :btnOpenTabindex="5"
                            :timeTabindex="6">
                        </book-datetime>
                        
                    </div>
                    <div class="form-group">
                        <label for="name" class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Title
                        </label>
                        <div class="form-control-100" :class="{ 'dark-input': darkMode }">
                            <input v-validate="formData.title.validator" v-model:trim="formData.title.value" disabled name="title" type="text" class="form-control" tabindex="3"/>
                            <input v-model:trim="formData.title.changedValue" v-on:keyup="formData.title.value = formData.title.changedValue ? formData.title.fixedValue + ' ' + formData.title.changedValue : formData.title.fixedValue" name="title" type="text" class="form-control" tabindex="3"/>
                        </div>
                    </div>
                    <div class="form-group" v-if="formData.local.value && $ability.can('send_fork_placeholder', 'Fork')">
                        <label for="name" class="control-label book-width-150">
                            <span class="form-control-required-symbol" aria-hidden="true"></span>
                            Submit to Fork
                        </label>
                        <!-- <div class="form-control-100"> -->
                            <input type="checkbox" v-model="formData.submit.value" />
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div slot="footer">
                <button class="btn btn-success" @click="submit()" :disabled="!isFormValid || !isFormChanged || processing">Save</button>
                <button v-if="!formData.local.value && $ability.can('send_fork_placeholder', 'Fork')" class="btn btn-success" @click="submit(true)" :disabled="!isFormValid || !isFormChanged || processing">Save & Submit</button>
            </div>
        </book-modal>
    </div>
</template>
<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
@import "../../style/darkmode.scss";

.book-placeholder-wrapper {
  .panel-body {
    padding: 0;
    position: relative;
  }
  .book-button-add-placeholder {
    position: absolute;
    right: 0;
    top: -27px;
    z-index: 10;
  }
  .panel-heading ~ .book-fork-placeholder {
    .book-button-add-placeholder {
      right: 3px;
      top: -25px;
    }
  }
}
.book-add-equipment-wrapper {
  border-bottom: 1px solid #DDDDDD;
  text-align: right;
  > *:not(.help-block) {
    @include breakpoint(min-width $min-tablet) {
      display: inline-block;
    }
  }
  .form-group {
    margin: 10px;
    > * {
      display: inline-block;
    }
    label {
      margin-right: 5px;
      width: calc(30% - 10px);
    }
    .form-control {
      width: 70%;
    }
    label,
    .form-control {
      @include breakpoint(min-width $min-tablet) {
        width: auto;
      }
    }
  }
}
.book-fork-placeholder {
  min-height: 40px;
  position: relative;
  .book-button-add-placeholder-modal {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 10;
  }
  .modal-footer {
    .book-loader {
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .book-table {
    border: none;
    margin-bottom: 0;
  }
  .table-modal {
      margin-top: 10px;
  }
  .table {
    margin-bottom: 0;
  }
  > .book-loader {
    padding: 9px 0;
  }
  .book-help-message-conflict {
    line-height: 1.4em;
    text-align: left;
  }

  .book-overview-item-title > span  {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.panel-body > .book-fork-placeholder {
  margin: -15px;
}
.book-compact-panels .panel-body > .book-fork-placeholder {
  margin: 0px;
}
.swal2-container {
  z-index: 10000 !important;
}
</style>