<script>
import {app} from 'app'
import NotificationLog from 'components/notification/NotificationLog'
import BookNotification from 'components/notification/BookNotification'
import BookLoader from 'components/common/loader/BookLoader'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookTable from 'components/common/BookTable'
import BookFilter from 'components/common/filter/BookFilter'
import BookModal from 'components/common/BookModal'
//import User from 'components/user/User'
//import SocketMixin from 'components/common/item/SocketMixin'

export default {
    // mixins: [
    //     SocketMixin,
    //     //McrSocketMixin
    // ],
    components: {
        BookLoader,
        BookDateNavigation,
        BookTable,
        BookFilter,
        BookModal,
        BookNotification
    },
    props: {
        ownPage: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        this.setLoaded(false)
        this.loadData()
                .then(response => {
                    this.notifications = response.data
                    this.createFilters()
                    this.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) this.$error.set(error)
                    this.setLoaded()
                    this.filterReady = true
                    //this.noResult = true
                })
    },
    data() {
        return {
            loaded: false,
            notifications: null,
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                type: {
                    label: 'Type',
                    //selected: '',
                    items: [{id: 'email', label: 'email'}, {id: 'sms', label: 'sms'}]
                },
                // sender: {
                //     label: 'Sender',
                //     //selected: '',
                //     items: []//this.senders
                // },
                recipient: {
                    label: 'Recipient',
                    //selected: '',
                    items: []//this.recipients
                }
            },
            filterReady: false,
            fiterRecepients: [],
            filterTypes: [],
            filterSearch: '',
            filterSearchChanged: false,
            selectedItem: null
        }
    },
    watch: {

        '$route': function(to, from) {
            if (!this.loaded || ((from.query.from == to.query.from) && (from.query.to == to.query.to))) {
                return
            }
            this.loadData()
                .then(response => {
                    this.notifications = response.data
                    this.createFilters()
                    this.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) this.$error.set(error)
                    this.notifications = null
                    this.setLoaded()
                    this.filterReady = true
                    //this.noResult = true
                })
        },
    },
    computed: {
        tableData() {
            const context = this
            const output = {}
            if (!context.notifications || context.notifications.length == 0) {
                return output
            }

            // Build options.
            output.options = {
                actions: ['preview'],
                // filters: {
                //     type: {
                //         label: 'Type',
                //         selected: '',
                //         items: ['email', 'sms']
                //     },
                //     // sender: {
                //     //     label: 'Sender',
                //     //     selected: '',
                //     //     items: context.senders
                //     // },
                //     recipient: {
                //         label: 'Recipient',
                //         selected: '',
                //         items: context.recipients
                //     }
                // }
            }

            // Build filters.
            // context.users.forEach(v => {
            //     // output.options.filters.sender.items.push({
            //     //     id: v.id,
            //     //     label: v.fullName
            //     // })

            //     output.options.filters.recipient.items.push({
            //         id: v.id,
            //         label: v.fullName
            //     })
            // })

            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false
                },
                {
                    value: 'type',
                    label: 'Type',
                    sortable: true,
                    //searchable: false
                },
                {
                    value: 'sent',
                    label: 'Sent',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'recipient',
                    label: 'Recipients',
                    sortable: true,
                    //searchable: false
                },
                {
                    value: 'subject',
                    label: 'Subject',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'message',
                    label: 'Message',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'status',
                    label: 'Status',
                    sortable: true,
                    //searchable: true
                },
            ]

            output.rows = []
            context.notifications
            .forEach(notification => {
                //let sender = _.find(context.users, u => u.id == notification.sender)
                //sender = sender ? `<a href="/user/${sender.id}">${sender.fullName}</a>` : ''
                let recipients = []
                let recipientsRawValues = []
                let recipientsDisplay = []
                
                notification.userdetails.forEach(r => {
                    //let rec = _.find(context.users, u => u.id == r)
                    if(r !== null && r !== undefined) {
                        recipients.push(`<a href="/user/${r.user_id}">${r.firstname + ' ' + r.lastname}</a>`)
                        recipientsRawValues.push(r.user_id)
                        recipientsDisplay.push(r.firstname + ' ' + r.lastname)
                    }
                    
                })

                const row = {
                    id: {
                        value: notification.id,
                        
                    },
                    type:{
                        value: notification.type,
                    },
                    sent: {
                        //rawValue: notification.sender,
                        value: notification.created,
                        classes: ['book-overview-item-title','book-overview-item-title-date']
                    },
                    recipient: {
                        rawValue: recipientsRawValues,
                        value: `${recipients}`,
                        displayValue: `${recipientsDisplay}`,
                        classes: ['book-overview-item-title', 'book-overview-item-title-recipient']
                    },
                    subject: {
                        value: notification.subject,
                        classes: ['book-overview-item-title', 'book-overview-item-title-subject']
                    },
                    message: {
                        value: notification.message,
                        classes: ['book-overview-item-title', 'book-overview-item-title-message']
                    },
                    status: {
                        value: notification.status
                    },
                    
                }

                output.rows.push(row)
            })
            return output;
        },
        tableDataFiltered(){
            const context = this
            if(context.fiterRecepients.length > 0 || context.filterTypes.length > 0 || context.filterSearchChanged) {
                let rows = _.filter(context.tableData.rows, r => {
                    let typeInList = context.filterTypes.length > 0 ?_.includes(context.filterTypes, r.type.value) : true
                    let anyRecipientInList = context.fiterRecepients.length > 0 ? (_.intersection(context.fiterRecepients, r.recipient.rawValue)).length > 0 : true
                    let searchMatch = context.filterSearch !== '' ? (
                        (r.sent.value && r.sent.value.includes(context.filterSearch))
                        || (r.subject.value && r.subject.value.includes(context.filterSearch))
                        || (r.message.value && r.message.value.includes(context.filterSearch))) : true
                    return typeInList && anyRecipientInList && searchMatch
                })
                let cloneTableData = Object.assign({}, context.tableData)
                cloneTableData.rows = rows
                context.filterSearchChanged = false
                return cloneTableData
            }
            else {
                context.filterSearchChanged = false
                return context.tableData
            }
        },
        users() {
            return this.$store.state.user.users
        },
        // senders() {
        //     const context = this
        //     let senders = []

        //     if(context.notifications && context.notifications.length > 0) {
        //         let extractAllSenders = _.map(context.notifications, s => s.sender)
        //         let allSenders = _.uniq(extractAllSenders)
        //         allSenders.forEach(s => {
        //             let sender = _.find(context.users, u => u.id == s)
        //             if(sender) {
        //                 senders.push({
        //                     id: s,
        //                     label: sender.fullName
        //                 })
        //             }
        //         })
        //     }

        //     return _.sortBy(senders, ['label'])
        // },
        recipients() {
            const context = this
            let recipients = []

            if(context.notifications && context.notifications.length > 0) {
                let extractAllRecepients = []
                context.notifications.forEach(n => {
                    // let receps = _.map(n.userdetails, d => {
                    //     return {
                    //         id: d.user_id,
                    //         label: d.firstname + ' ' + d.lastname
                    //     }
                    // })

                    let receps = []
                    n.userdetails.forEach(d => {
                        if(d !== null && d !== undefined) {
                            receps.push({
                                id: d.user_id,
                                label: d.firstname + ' ' + d.lastname
                            })
                        }
                    })

                    extractAllRecepients = _.unionWith(extractAllRecepients, receps, _.isEqual)
                })
                recipients = _.uniq(extractAllRecepients)
                // allRecipients.forEach(s => {
                //     let recipient = _.find(context.users, u => u.id == s)
                //     if(recipient) {
                //         recipients.push({
                //             id: s,
                //             label: recipient.fullName
                //         })
                //     }
                // })
            }

            return recipients.length > 0 ? _.sortBy(recipients, ['label']) : recipients
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this.countFilteredLogs
            filterInfo.total = this.countLogs
            return filterInfo
        },
        countFilteredLogs() {
            if(!this.loaded || !this.tableDataFiltered || !this.tableDataFiltered.rows) return 0
            return this.tableDataFiltered.rows.length
        },
        countLogs() {
            if(!this.loaded || !this.notifications) return 0
            return this.notifications.length
        }
    },
    methods: {
        loadData() {
            return new Promise((resolve, reject) => {
                this.setLoaded(false)
                this.filterReady = false

                // Build request params.
                const params = {
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    userId: app.$data.user.get.id
                }
                
                const notificationLog = new NotificationLog()
                notificationLog.all(params, true)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setLoaded(loaded = true) {
            Vue.nextTick(() => {
                this.loaded = loaded
                if (loaded) {
                    Vue.nextTick(() => {
                        //this.updateItemFlags()
                    })
                }
            })
        },
        showNotification(row) {
            this.selectedItem = row
            //this.selectedItem.recipient.displayValue = row.recipient.displayValue.split(',')
            console.log(row);
        },
        createFilters() {
            //this.availableFilters.sender.items = this.senders
            this.availableFilters.recipient.items = this.recipients
            this.filterReady = true
        },
        filteredBy(filters) {
            console.log(filters);
            this.fiterRecepients = filters.recipient
            this.filterTypes = filters.type
            this.filterSearch = filters.search.value
            this.filterSearchChanged = true
        }
    }
}
</script>
<template>
<div>
    <div id="mcr" class="book-top-sticky-wrapper book-bottom-sticky-wrapper">
        <div v-if="ownPage" class="page-header book-top-sticky clearfix">
            <h1 class="page-title">Notification Logs</h1>
            <div class="secondary-menu">
                <book-date-navigation
                    :default-interval="0">
                </book-date-navigation>
            </div>
            <book-filter
                    :loading="!filterReady"
                    :options="{save: true}"
                    :filters="availableFilters"
                    @filteredBy="filters => filteredBy(filters)"
                    :info="filterInfo">
            </book-filter>
        </div>
        <div v-if="ownPage">
            <book-loader v-if="!loaded"></book-loader>
            <div v-else class="panel panel-info">
                <book-table
                    :component="'notificationLog'"
                    :items="tableDataFiltered"
                    @preview="row => showNotification(row)"
                    notFoundMessage="No notifications found">
                </book-table>
            </div>
        </div>
        
        <div v-else class="panel panel-info">
            <div v-if="!ownPage" class="panel-heading">
                
                <div class="secondary-menu">
                    <div class="panel-header">
                        <h4>Notification Logs</h4>
                    </div>
                    <div class="panel-date">
                        <book-date-navigation 
                            :default-interval="0">
                        </book-date-navigation>
                    </div>
                    
                </div>
                
                <book-filter 
                    :loading="!filterReady"
                    :options="{save: true}"
                    :filters="availableFilters"
                    @filteredBy="filters => filteredBy(filters)"
                    :info="filterInfo">
                </book-filter>
            </div>
            <book-loader v-if="!loaded"></book-loader>
            
            <book-table
                v-if="loaded"
                :items="tableDataFiltered"
                :component="'notificationLog'"
                @preview="row => showNotification(row)"
                notFoundMessage="No notifications found">
            </book-table>
            
           
        </div>
    </div>
    <book-notification v-if="selectedItem" 
        @close="selectedItem = null"
        isLog
        :selectedItem="selectedItem"
        :modalTitle="selectedItem.type.value === 'sms' ? `SMS was sent ${selectedItem.sent.value}` : `E-mail was sent ${selectedItem.sent.value}`">
    </book-notification>
    <!-- <book-modal v-if="selectedItem" class="book-notification-modal" maxWidth="500px" @close="selectedItem = null">
        <h4 slot="header" class="modal-title">
            {{selectedItem.type.value === "sms" ? `SMS was sent ${selectedItem.sent.value}` : `E-mail was sent ${selectedItem.sent.value}` }}
        </h4>
        <div slot="body">
            <div class="book-normal-forms">
                <div class="book-radio-tabs book-notification-type form-group">
                    <div class="radio-tab" :class="{'active': selectedItem.type.value == 'email'}">
                        <label>
                            E-mail
                        </label>
                    </div>
                    <div class="radio-tab" :class="{'active': selectedItem.type.value == 'sms'}">
                        <label>
                            SMS
                        </label>
                    </div>
                </div>
                <div class="panel panel-default form-group book-notification-recipients-panel">
                    <div class="panel-heading">
                        Recipients
                        (<span v-if="selectedItem.type.value == 'sms'">SMS</span>
                        <span v-else>E-mail</span>)
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr v-for="recipient in selectedItem.recipient.displayValue">
                                    <td v-html="recipient">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group" v-if="selectedItem.type.value == 'email'">
                    <label for="name" class="control-label">
                        Subject
                    </label>
                    <input type="text" class="form-control" name="subject" v-model="selectedItem.subject.value" disabled />
                </div>
                <div class="form-group" >
                    <label for="name" class="control-label">
                        Message
                    </label>
                    <textarea rows="3" class="form-control" name="message" ref="smsMessage" v-if="selectedItem.type.value == 'sms'" v-model="selectedItem.message.value" disabled></textarea>
                    <textarea rows="12" class="form-control" name="message" ref="emailMessage" v-else v-model="selectedItem.message.value" disabled></textarea>
                </div>
            </div>
        </div>
    </book-modal> -->
</div>
</template>
<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

    .book-overview-item-title.book-overview-item-title-recipient {
        width: 18%;
    }
    .book-overview-item-title.book-overview-item-title-subject {
        width: 15%;
    }
    .book-overview-item-title.book-overview-item-title-message {
        
    }
    .book-overview-item-title.book-overview-item-title-date {
        width: 10%;
    }
    .book-overview-item-title > span  {
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .book-table-actions {
        width: 25px !important;
    }
    .book-notification-modal {
    .book-notification-recipients-panel {
        .panel-heading {
            font-weight: 600;
        }
        .table-responsive {
            max-height: 100px;
        }
        .book-square {
            display: inline-block;
            height: 15px;
            vertical-align: middle;
            width: 15px;
        }
    }
    * + .book-notification-recipients-panel {
        margin-top: 14px;
    }
    .book-notification-group {
        margin-bottom: 10px !important;
        .book-notification-group-message {
            display: block;
            font-weight: 600;
            margin-bottom: 0;
        }
        .book-notification-group-items-label {
            display: inline-block;
            font-weight: 600;
            margin: 5px 5px 0 0;
            text-transform: capitalize;
            vertical-align: middle;
        }
        .book-notification-group-all label {
            // font-weight: 600 !important;
        }
        .radio-inline {
            // padding-top: 5px;
            label {
                font-weight: 400;
                // margin-bottom: 0;
            }
            input {
                margin-top: 3px;
            }
        }
    }
    .book-notification-type {
        margin-left: -15px !important;
        margin-right: -15px !important;
        padding: 0 15px;
    }
    .book-notification-type {
        // float: right;
        // margin-bottom: 3px !important;


        border-bottom: 1px solid $color-grey-border;


    }
    * + .book-notification-type {
        margin-top: 15px !important;
    }
    // .book-notification-type-content {
    //     margin-bottom: -15px !important;
    //     padding-bottom: 15px;
    // }

    .control-label {
        margin-bottom: 5px !important;
    }
    
}
.panel.panel-info {
        .panel-heading {
            .panel-header {
                display: inline-block;
            }
            .panel-date {
                float: right;
                display: inline-block;
            }
        }
    }
</style>