
import store from 'store'
import Auth from 'components/auth/Auth'
import User from 'components/user/User'
import Settings from 'components/settings/Settings'
import Acl from 'components/user/Acl'

class System {

    /**
     * Load system important data.
     * @return promise
     */
    static load() {
        Auth.setAuthorizationToken()
        const user = User.load()
        const countries = this.getCountries()
        const entities = this.getEntities()
        const settings = Settings.load()
        return Promise.all([user, countries, settings])
    }

    /**
     * Get all available countries from the API.
     */
    static getCountries() {
        return new Promise((resolve, reject) => {
            axios.get('api/country')
            .then(response => {
                if (response.status === 200) {
                    store.commit('system/setCountries', response.data)
                }
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    static getEntities() {
        return new Promise((resolve, reject) => {
            axios.get('api/locations')
                .then(response => {
                    if (response.status === 200) {
                        store.commit('system/setLocations', response.data)
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Get a country by its id.
     */
    static getCountryById(id) {
        if (!store.state.system.countries || !id) {
            return null
        }
        let label = ''
        store.state.system.countries.forEach(function(v, k) {
            if (v.code == id) {
                label = v.label
            }
        })
        return label
    }

}

export default System
