<script>
import Acl from 'components/user/Acl'
import User from 'components/user/User'
import BookUsersActionMenu from 'components/user/BookUsersActionMenu';
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookUsersActionMenu,
        BookLoader
    },
    computed: {
        permissions() {
            const output = {}
            Lazy(this.$store.state.user.permissions)
                .each(permission => {
                    if (!output.hasOwnProperty(permission.subject)) {
                        output[permission.subject] = []
                    }
                    output[permission.subject].push(permission)
                })
            return (Object.keys(output).length > 0) ? output : null
        },
        roles() {
            return this.$store.state.user.roles
        },
        loaded() {
            return this.permissions && this.roles && !this.$loader.has('permissionsLoad')
        },
        submitting() {
            return this.$loader.has('permissionsSubmit')
        }
    },
    mounted() {
        const parent = this
        parent.$loader.add('permissionsLoad')
        const roles = Acl.getRoles()
        const permissions = Acl.getPermissions()
        Promise.all([roles, permissions])
            .then(response => {
                parent.$loader.remove('permissionsLoad')
            })
            .catch(error => {
                parent.$error.set(error, 'It was not possible to load the permissions.')
                parent.$loader.remove('permissionsLoad')
            })
    },
    methods: {
        submit() {
            this.$loader.add('permissionsSubmit')
            Acl.savePermissions()
                .then(response => {
                    this.$alert.set('The permissions were saved!', 'success', 5)
                    this.$loader.remove('permissionsSubmit')
                })
                .catch(error => {
                    this.$error.set(error, )
                    this.$loader.remove('permissionsSubmit')
                })
        }
    }
}
</script>

<template>
    <div id="users-permissions" class="admin book-top-sticky-wrapper book-bottom-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <h2 class="page-title">Manage Permissions</h2>
            <book-users-action-menu></book-users-action-menu>
        </div>
        <book-loader v-if="!loaded"></book-loader>
        <div class="panel panel-info" v-else>
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Permission</th>
                            <th v-for="role in roles">{{role.label}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td v-for="role in roles"></td>
                        </tr>
                        <template v-for="(permissionItems, subject) in permissions">
                            <tr>
                                <td><strong>{{subject}}</strong></td>
                                <td v-for="role in roles"></td>
                            </tr>
                            <tr v-for="permission in permissionItems" :key="permission.id">
                                <td>{{permission.label}}</td>
                                <td v-for="role in roles">
                                    <input type="checkbox" v-model="permission.roles[role.id].allowed" v-bind:value="role.id" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td v-for="role in roles"></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="book-form-actions book-actions-flex book-bottom-sticky" v-if="loaded">
            <button class="btn btn-primary pull-right" @click="submit()" :disabled="!loaded || submitting">Save</button>
        </div>
    </div>

</template>
