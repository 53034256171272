<template>
    <div class="book-toggle btn btn-default" :class="{'off': !on}" @click="toggleValue()" :disabled="disabled">
        <div class="toggle-group">
            <label class="btn toggle-on btn-success active" :disabled="disabled"><slot name="on">on</slot></label>
            <label class="btn toggle-off btn-danger active" :disabled="disabled"><slot name="off">off</slot></label>
            <div class="btn btn-default toggle-handle" :disabled="disabled"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['status', 'toggleDisabled'],
        data() {
            return {
                on: this.status
            }
        },
        computed: {
            disabled() {
                return this.toggleDisabled
            }
        },
        watch: {
            'status': function(newValue) {
                Vue.set(this.$data, 'on', newValue)
            }
        },
        methods: {
            toggleValue() {
                if (!this.toggleDisabled) {
                    this.on = !this.on;
                    this.$emit('toggle');
                }
            }
        }
    }
</script>

<style lang="scss">
.book-toggle {
  height: 34px;
  overflow: hidden;
  position: relative;
  user-select: none;
  white-space: nowrap;
  width: 58px;
  &[disabled] {
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
  &.off {
    .toggle-group {
      left: -100%;
    }
  }
  > * {
    display: inline-block;
  }
  .toggle-group {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: left 0.35s;
    width: 200%;
  }
  .toggle-handle {
    border: 0 1px;
    display: block;
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 0;
    z-index: 2;
  }
  .toggle-on,
  .toggle-off {
    border: none;
    border-radius: 0;
    bottom: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .toggle-on {
    left: 0;
    right: 50%;
    padding-right: 24px;
  }
  .toggle-off{
    left: 50%;
    padding-left: 24px;
    right: 0;
  }
}
</style>
