/**
 * Mixin to with a set of Production related resources.
 */

const ProductionMixin = {
    computed: {
        getRunTypeFromUrl() {
            return this.$route.name.split('.')[0]
        },
        isEditProductions() {
            return this.$route.name == 'edit.view'
        },
        isEngProductions() {
            return this.$route.name == 'eng.view'
        },
        isCarProductions() {
            return this.$route.name == 'car.view'
        },
        isTaskProductions() {
            return this.$route.name == 'task.view'
        },
        getSelectedItemCrew() {
            const output = {
                option: [],
                declined: [],
                requested: [],
                accepted: [],
                not_available: []
            }
            Lazy(this.selectedItem.crew)
                .each(v => {
                    if(!v.isHistorical) {
                        output[v.status].push(v)
                    }
                })
            return output
        },
    },
    methods: {
        getEditLink(id, elementTypeMcr = null) {
            const link = {
                params: {
                    id: id
                },
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                }
            }

            if (this.type) {
                link.name = `${this.type}.edit`
            } else if (this.type === undefined && this.isTaskProductions) {
                link.name = 'task.edit'
            }

            if(elementTypeMcr) {
                link.name = `${elementTypeMcr}.edit`
            }
            
            return link
        },
        getPlanItemTime(item) {
            let start = this.selectedItem['production' + item.relativeTo.capitalize()]
            if (start) {
                start = moment(start).add(item.offset, 's')
                return start.format('HH:mm')
            }
            const offset = Datetime.getOffsetFormatted(item.offset)
            return offset.join('')
        },
        getCrewPeriod(crew) {
            const startPlanItem = this.selectedItem.plan.find(v => v.id === crew.startPlan)
            const endPlanItem = this.selectedItem.plan.find(v => v.id === crew.endPlan)
            if (!startPlanItem || !endPlanItem) return ''
            let start = this.getPlanItemTime(startPlanItem)
            let end = this.getPlanItemTime(endPlanItem)
            return `${start}-${end}`
        },
        getCrewPeriodForTimeline(crew){
            var start = moment(crew.crewStartDate).format('HH:mm')
            var end = moment(crew.crewEndDate).format('HH:mm')
            return `${start}-${end}`
        },
        getCrewMemberClass(item) {
            const output = []
            let colorId = 0
            switch (item.status) {
                case 'option':
                    colorId = 5 //  Blue.
                    break
                case 'requested':
                    colorId = 4 // Yellow.
                    break
                case 'accepted':
                    colorId = 2 // Green.
                    break
                case 'declined':
                    colorId = 3 // Red.
                    break
                case 'not_available':
                    colorId = 1 // Grey.
                    break
            }
            output.push(`book-item-status-${colorId}`)
            return output
        },
        openNewTab(link) {
            const context = this

            var link_ = {
                destination: this.$route.name,
                view: this.$route.params.view,
                from: this.$route.query.from,
                to: this.$route.query.to,
                newBar: link.params.id
            }

            const toLink = Object.entries(link_).map(([key, value]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }).join('&');

            if (link.name === 'production.edit') {
                // get the opened page name so will be easier to work around
                let _prodid = link.params.id
                let _link = `/production/${_prodid}?${toLink}`
                window.open(_link, "_blank");
            } else if(link.name == 'task.edit') {
                let routeData = context.$router.resolve(link);
                window.open(routeData.href, '_blank');
            }
            else {
                return context.$router.push(link)
            }
        },
        closeNewTab(link) {
            window.close();
        }
    }
}

export default ProductionMixin
