import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import BookUpload from 'components/common/BookUpload'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookFiberEquipment from 'components/equipment/EquipmentSelection/BookFiberEquipment'
import BookAssociations from 'components/association/BookAssociations'
import BookDatetime from 'components/common/BookDatetime'
import BookSignoff from 'components/common/signoff/BookSignoff'
import BookModal from 'components/common/BookModal'
import BookLock from  'components/common/lock/BookLock'
import BookExitPrint from  'components/common/BookExitPrint'
import Lock from  'components/common/lock/Lock'
import ItemEditBaseMixin from 'components/common/item/ItemEditBaseMixin'
import ItemEditMixin from 'components/common/item/ItemEditMixin'
import Feed from 'components/feed/Feed'
import Datetime from 'components/common/helpers/Datetime'
import BookAudioLayout from 'components/common/BookAudioLayout'
import BookFeedAudioLayout from 'components/feed/BookFeedAudioLayout'
import BookLocalNotes from 'components/common/BookLocalNotes'
import Equipment from 'components/equipment/Equipment'
import BookFeedbackItem from 'components/feedback/BookFeedbackItem'
import BookForkPlaceholder from 'components/forkplaceholder/BookForkPlaceholder'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'

import BookNentCalendarNotes from 'components/common/BookNentCalendarNotes'
// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'

export default {
    mixins: [
        ItemEditBaseMixin,
        ItemEditMixin
    ],
    components: {
        BookLoader,
        BookUpload,
        BookEquipmentSelection,
        BookFiberEquipment,
        BookAssociations,
        BookDatetime,
        BookSignoff,
        BookModal,
        BookLock,
        BookExitPrint,
        multiselect,
        datepicker,
        maskedInput,
        BookAudioLayout,
        BookFeedAudioLayout,
        BookLocalNotes,
        BookNentCalendarNotes,
        BookFeedbackItem,
        BookForkPlaceholder
    },
    mounted() {

        const context = this
        // Load the form data.
        this.loadForm()
            .then(response => {
                const dataToLoad = []
                const equipmentList = new Equipment()
                dataToLoad.push(equipmentList.all({filter: 'feed', country: app.$data.user.get.country}))
                return Promise.all(dataToLoad)
            })
            .then(response => {
                this.allEquipmentList = response[0].data
                if (this.isTemplateBased && !this.isClone) {
                    let templateId = parseInt(this.$route.query.template)
                    this.productionTemplate = new ProductionTemplate(templateId)
                    const templateParams = {
                        type: 'feed',
                    };
                    this.productionTemplate.load(templateId, templateParams)
                        .then(response => {
                            this.applyTemplate()
                            this.setLoaded()
                        })
                        .catch(error => {
                            this.$error.set(error, `It was not possible to load template ${templateId}.`)

                            // Remove the template id from URL.
                            const query = Object.assign({}, this.$route.query)
                            delete query.template
                            this.$router.replace({query: query}, () => {
                                this.setLoaded()
                            }, () => {
                                this.setLoaded()
                            })

                        })
                } else {
                    this.setLoaded()
                }
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the form.')
            })

        // Since the date fields are using BookDatetime component, we can't attach
        // VeeValidate directly to the HTML element, then we need to create the
        // fields "manually" here, inside the "$validator" (VeeValidate).
        this.$validator.attach('feedStart', this.formData.feedStart.validator.rules)
        this.$validator.attach('feedEnd', this.formData.feedEnd.validator.rules)
        this.$validator.attach('pgmStart', this.formData.pgmStart.validator.rules)

        console.log(this.isCreatePage, 'create?', window.location)

        // if (this.isCreatePage) {
        //     setTimeout(() => {
        //         if (context.leagueEntity && context.leagueEntity.get && context.leagueEntity.get.items) {
        //             Vue.set(context.leagueEntity.get, 'items', context.leagueEntity.get.items.filter(i => i.active))
        //             console.log(context.leagueEntity.get.items.length, 'league')
        //         }
        //
        //     }, 1500)
        // }

    },

    watch: {
        'formData.feedStart.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(newValue, this.formData.feedEnd.value, format)) {
                this.formData.feedEnd.value = this.formData.feedStart.value
            }
            // else {
            Vue.nextTick(() => {
                this.$validator.validate('feedStart', newValue)
            })
            // }

        },
        'formData.feedEnd.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(this.formData.feedStart.value, newValue, format)) {
                Vue.nextTick(() => {
                    this.formData.feedEnd.value = this.formData.feedStart.value
                })
            }
            // else {
            Vue.nextTick(() => {
                this.$validator.validate('feedEnd', newValue)
            })
            // }

            console.log(!this.isFormValid, this.isPlacholdersIncorrect, this.processing , !this.isChanged , !this.isEditable)
        },
        'formData.pgmStart.value': function(newValue) {

            // Since pgmStart isn't mandatory, we need to detach/attach the
            // validator every time it's value is changed, otherwise the validator
            // treats empty as invalid because it doesn't match the date/time rule.
            if (newValue == '') {
                this.$validator.detach('pgmStart')
            }
            else {
                this.$validator.attach('pgmStart', this.formData.pgmStart.validator.rules)
                this.$validator.validate('pgmStart', newValue)
            }

        },
        'formData.audioCountries.value': function(newValue, oldValue) {
            const context = this
            let feedId = context.isClone ? context.$route.query.clone : context.id

            // A country as been selected, add it to "audio" list.
            if (newValue.length > oldValue.length) {
                newValue.forEach(country => {
                    let i = context.formData.audios.value.findIndex(v => v.country == country)
                    if (i < 0) {
                        context.formData.audios.value.push({feedId: feedId, valueType: 'audio', country: country, value: ''})
                    }
                })
            }

            // A country has been unselected, remove it from "audios" list.
            else if (newValue.length < oldValue.length) {
                oldValue.forEach(country => {
                    let i = newValue.findIndex(country2 => country == country2)
                    if (i < 0) {
                        let j = context.formData.audios.value.findIndex(v => v.country == country)
                        if (j > -1) {
                            context.formData.audios.value.splice(j, 1)
                        }
                    }
                })
            }

        },
        'formData.mcr.value': function(newValue, oldValue) {
            const context = this
            let feedId = context.isClone ? context.$route.query.clone : context.id

            // If we compare with "oldValue" here, then this logic isn't triggered
            // on form load.
            if (context.formData.deliveryTypeData.satEncryptionByCountry.value && newValue.length > context.formData.deliveryTypeData.satEncryptionByCountry.value.length) {
                newValue.forEach(country => {
                    let i = context.formData.deliveryTypeData.satEncryptionByCountry.value.findIndex(v => v.country.code == country.code)
                    if (i < 0) {
                        let countryObj = country.group == 0 ? country : 'other'
                        let notPresent = context.formData.deliveryTypeData.satEncryptionByCountry.value.findIndex(v => v.country == 'other') < 0
                        if (notPresent || countryObj != 'other') context.formData.deliveryTypeData.satEncryptionByCountry.value.push({feedId: feedId, valueType: 'encryption', country: countryObj, value: ''})
                    }

                    // if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                
                    // }
                })
            }

            else if (newValue.length < oldValue.length) {
                oldValue.forEach(item => {
                    let i = newValue.findIndex(v => item.code == v.code)
                    if (i < 0) {
                        let j = context.formData.deliveryTypeData.satEncryptionByCountry.value.findIndex(v => {
                            return v.country.code == item.code && v.country != 'other'
                        })
                        if (j > -1) context.formData.deliveryTypeData.satEncryptionByCountry.value.splice(j, 1)
                        else if (item.group > 0) {

                            // If no "group 1" country is available, remove the
                            // "other" field.
                            if (newValue.findIndex(v => v.group > 0) == -1) {
                                let a = context.formData.deliveryTypeData.satEncryptionByCountry.value
                                .findIndex(v => v.country == 'other')
                                if (a > -1) context.formData.deliveryTypeData.satEncryptionByCountry.value.splice(a, 1)
                            }

                        }
                    }
                })
            }

            if(context.satellites.findIndex(i => i.satItem.id === context.formData.deliveryTypeData.satSatelliteId.value) === -1) {
                context.formData.deliveryTypeData.satSatelliteId.value = ''
            }

            
            let currentMCRs = newValue ? newValue.map(c => c.code) : []
            let oldMCRs = context.feed.get.mcr ? context.feed.get.mcr : []
            let currentDeliveryType = context.formData.deliveryType.value
            let oldDeliveryType = context.feed.get.deliveryType

            context.reSetEquipment(oldDeliveryType, currentDeliveryType, oldMCRs, currentMCRs)

            
        },
        'formData.deliveryType.value' : function(newValue, oldValue) {
            const context = this
            let currentMCRs = context.formData.mcr.value ? context.formData.mcr.value.map(c => c.code) : []
            let oldMCRs = context.feed.get.mcr ? context.feed.get.mcr : []
            let currentDeliveryType = newValue
            let oldDeliveryType = context.feed.get.deliveryType

            context.reSetEquipment(oldDeliveryType, currentDeliveryType, oldMCRs, currentMCRs)

            
        },
        // 'formData.houseNumber.value': {
        //     handler: function(newValue, oldValue) ,
        //     deep: true
        // },
        'formData.placeholders.value': {
            handler: function(newValue, oldValue) {
                let correct = false
                //const context = this
                if(newValue && newValue.length > 0) {
                    Lazy(newValue)
                    .each((v,k) => {
                        let missed = _.filter(v.placeholders, i => !i.periodStart || !i.periodEnd || !i.country)
                        if(missed && missed.length > 0) correct = true
                    })
                }
                this.formData.placeholders.isPlacholdersIncorrect = correct
            },
            deep: true
        }
    },
    data() {
        return {

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'feed',
                items: 'feeds',
                settings: 'feeds',
                association: 'production',
                entities: {
                    resolutionEntity: 'resolution_entity_id',
                    frameRateEntity: 'framerate_entity_id',
                    aspectRatioEntity: 'aspectratio_entity_id',
                    systemFormatEntity: 'systemformat_entity_id',
                    deliveryTypeEntity: 'deliverytype_entity_id',
                    contentTypeEntity: 'contenttype_entity_id',
                    channelEntity: 'channel_entity_id',
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    streamingTypeEntity: 'streamingtype_entity_id',
                    polarizationEntity: 'polarization_entity_id',
                    modulationEntity: 'modulation_entity_id',
                    fecEntity: 'fec_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    fiberEntity: 'fiber_source_entityitem_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    satelliteEntity: 'satellite_entity_id',
                    equipmentStatusEntity: 'equipmentstatus_entity_id',
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                    externalMCRsEntity: 'externalmcrs_entity_id',
                    activityTypesEntity: 'activitytypes_entity_id',
                    fiDMFieldsMappingEntity: 'fidmfieldsmapping_entity_id'
                }
            },
            templateForm: {
                show: false,
                id: '',
                selection: ''
            },
            applyTemplateForm: {
                show: false,
                selection: '',
                deliveryType: '',
                league: ''
            },
            saveTemplateForm: {
                show: false,
                loading : false,
                name: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                id: {
                    value: 0
                },
                template: null
            },
            template: {
                user: {
                    value: ''
                },
                title: {
                    value: ''
                },
                global: {
                    value: false
                },
                data: {
                    league: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    mcr: {
                        data: true,
                        value: ''
                    },
                    mcrExternal: {
                        data: true,
                        value:''
                    },
                    deliveryType: {
                        data: true,
                        value:''
                    },
                    contentType: {
                        data: true,
                        value: ''
                    },
                    deliveryTypeData: {
                        downloadInfo: {
                            value: '',
                        },
                        fiberInfo: {
                            value: '',
                        },
                        streamingType: {
                            value: '',
                        },
                        streamingInfo: {
                            value: '',
                        },
                        satInfo: {
                            value: '',
                        },
                        satSatelliteId: {
                            value: '',
                        },
                        satFrq: {
                            value: '',
                        },
                        satPolarization: {
                            value: '',
                        },
                        satModulation: {
                            value: '',
                        },
                        satTransponderChannel: {
                            value: '',
                        },
                        satService: {
                            value: '',
                        },
                        satSymbolRate: {
                            value: '',
                        },
                        satFec: {
                            value: '',
                        },
                        satEncryption: {
                            value: '',
                        },
                        satEncryptionBissCodeShared: {
                            value: '',
                        },
                        satEncryptionByCountry: {
                            value: '',
                        },
                    },
                    resolution: {
                        data: true,
                        value: '',
                    },
                    frameRate: {
                        data: true,
                        value: '',
                    },
                    aspectRatio: {
                        data: true,
                        value: '',
                    },
                    systemFormat: {
                        data: true,
                        value: '',
                    },
                    audioLayout: {
                        data: true,
                        value: ''
                    },
                    notes: {
                        data: true,
                        value: ''
                    },
                    localNotes: {
                        data: true,
                        value: ''
                    },
                    comments: {
                        data: true,
                        value: ''
                    },
                    contacts: {
                        data: true,
                        value: ''
                    },

                },
                itemType: {
                    value: 'feed'
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                }
            },
            activeTab: {
                default: 'overview',
                notes: 'global'
            },
            feed: null,
            houseNumbers: [],
            allEquipmentList: [],
            fiberEquipment: [],
            otherEquipment: [],
            placeholderForm: null,
            showPlaceholderForm: false,
            submitOpen: false,
            submitRedirect: false, 
            // showExternalMCRWarning: {
            //     warn: false,
            //     warnMessage: '',
            //     oldExternalMCRs: ''
            // }
        }
    },
    computed: {
        feeds() {
            return this.$store.state.data.feed ? this.$store.state.data.feed : null
        },
        calendarNotes() {
            return this.feed.get && this.feed.get.comments ? JSON.parse(this.feed.get.comments): null
        },
        encryptionOtherCountriesPos() {
            return this.formData.deliveryTypeData.satEncryptionByCountry.value.findIndex(v => v.country == 'other')
        },
        processing() {
            return this.$loader.has('feedEdit_submit')
        },
        getFeedsSortedByFeedStart() {
            const context = this
            if (!context.feeds) return []
            return context.feeds
            .filter(v => v.id > 0 && v.feedStart)
            .sort((a, b) => {
                const fieldA = a.feedStart
                const fieldB = b.feedStart
                if (fieldA < fieldB) return -1
                if (fieldA > fieldB) return 1
                return 0
            })
        },
        getPgmStartValue() {
            const context = this
            return context.feed.get && context.feed.get.pgmStart ? context.feed.get.pgmStart : (context.feed.get && context.feed.get.pgm_start ? context.pgmStartFormat(context.feed.get.pgm_start) : null)
        },
        currentDeliveryTypeLabel() {
            const context = this
            const deliveryType = context.deliveryTypeEntity.getItem(context.formData.deliveryType.value)
            if (!deliveryType) return null
            return deliveryType.label
        },
        satellites() {
            const context = this
            const satArr = []
            const satItems = context.satelliteEntity.get.items
            const mcrs = context.formData.mcr.value
            satItems.forEach(satItem => {
                let satCountries = satItem.description.split(',')
                let satCountriesFiltered = satCountries.length > 0 ? satCountries.filter(c => mcrs.some(m => m.code === c)) : []
                if(satCountriesFiltered.length > 0) {
                    let missedMCRs = []
                    if(mcrs.length !== satCountriesFiltered.length) {
                        missedMCRs = mcrs.filter(m => !satCountriesFiltered.some(s=> m.code === s)).map(m => m.label)
                    }
                    satArr.push({'satItem': satItem, 'warn': mcrs.length !== satCountriesFiltered.length, 'missedMCRs': missedMCRs.join(', ') })
                }
            })
            return satArr
        },
        externalMCRs() {
            return this.externalMCRsEntity && this.externalMCRsEntity.get && this.externalMCRsEntity.get.items ? this.externalMCRsEntity.get.items : []
        },
        showSatelliteWarning(){
            let warn = false
            let warnMessage = ""
            const context = this
            if(context.formData.deliveryTypeData.satSatelliteId.value && context.formData.deliveryTypeData.satSatelliteId.value !== '') {
                let currentSelectedSatellite = context.satellites.find(v => v.satItem.id === context.formData.deliveryTypeData.satSatelliteId.value)
                if(currentSelectedSatellite !== undefined && currentSelectedSatellite.warn) {
                    warn = true
                    warnMessage = `This satellite is not available for ${currentSelectedSatellite.missedMCRs}`
                }
                //warn = currentSelectedSatellite ? currentSelectedSatellite.warn : true
                //missedMCRs = currentSelectedSatellite ? currentSelectedSatellite.missedMCRs : ""
            } else if (context.formData.deliveryTypeData.satSatellite.value && context.formData.deliveryTypeData.satSatellite.value !== '') {
                warn = true
                warnMessage = `This satellite does not exist in the satellite list. Please pick a valid satellite`
            }

            return { 'warn': warn, 'warnMessage': warnMessage}
        },
        showExternalMCRWarning(){
            const context = this
            let warn = false
            let warnMessage = ''
            let oldExternalMCRs = ''
            let feed = (context.isClone) ? new Feed(parseInt(context.$route.query.clone)) : context.feed
            if(feed && feed.get && feed.get.mcrExternal) {
                let extMCRsArr = feed.get.mcrExternal.split(',')
                if(extMCRsArr && extMCRsArr.length > 0 ) {
                    if(isNaN(parseInt(extMCRsArr[0]))) {
                        warn = true
                        warnMessage = 'Please pick the correct values instead of free text.'
                        oldExternalMCRs = feed.get.mcrExternal
                    }
                }
            }

            return { 'warn': warn, 'warnMessage': warnMessage, 'oldExternalMCRs': oldExternalMCRs}
         },

        /**
         * Check if the form has been changed.
         * @return boolean
         */
        isChanged() {
            const context = this
            let changed = false
            if (!this.feed || !this.feed.get) return changed
            Lazy(context.formData)
            .filter((v, k) => {
                switch (k) {

                    // The following fields shouldn't be checked either because
                    // they aren't stored inside the feed in the DB or because
                    // the value is stored "in realtime", when it's changed,
                    // ex "documents" whose value is stored directly when
                    // a file is uploaded.
                    case 'sport':
                    case 'documents':
                    case 'equipment':
                    case 'fiber':
                    case 'signoffs':
                    case 'associations':
                    case 'actions':
                    case 'ingestions':
                    case 'localNotes':
                    case 'comments':
                    case 'feedbacks':
                    case 'placeholders':
                    case 'mcrExternal':
                        return false

                }
                if (!context.isEditForm) return !v.local && !v.onlyEdit
                return !v.local
            })
            .each(function(v, k) {
                if (changed) {
                    context.changedLog('first',changed, 'first')
                    return true
                }

                // The form value is an array and its length is not equal to
                // the length of the original value, then it has been changed.
                if (Array.isArray(v.value) && Array.isArray(context.feed.get[k]) && (v.value.length != context.feed.get[k].length)) {
                    //console.log('isChanged: formData ->',changed, v.value, k, context.feed.get[k])
                    changed = true
                }
                else {
                    switch (k) {
                        case 'cloneOf':
                            changed = false
                            break
                        case 'audioLayout':
                            if (!context.feed.get[k] && !v.value) {
                                changed = false
                                break
                            }
                            changed = v.value !== context.feed.get[k]
                            context.changedLog(k,v.value, changed)
                            break
                        case 'mcrExternal':
                            if(context.showExternalMCRWarning.warn && context.formData.mcrExternal.value.length === 0) {
                                changed = false
                            }
                            else {
                                if(context.showExternalMCRWarning.warn && context.formData.mcrExternal.value.length > 0) {
                                    changed = true
                                    context.changedLog(k,context.formData.mcrExternal.value, changed)
                                }
                                else {
                                    let newExtMCR = context.formData.mcrExternal.value.length > 0 ? context.formData.mcrExternal.value.map(i => i.id) : []
                                    let oldExtMCR = context.feed.get.mcrExternal ? context.feed.get.mcrExternal.split(',').map(c => parseInt(c)) : []
                                    if(_.xor(newExtMCR, oldExtMCR).length > 0) {
                                        changed = true
                                        context.changedLog(k + '2',v.value, changed)
                                    }
                                    else {
                                        changed = false
                                    }
                                }
                            }
                            break
                        case 'pgmStart':
                            let _pgmStart1 = context.formData.pgmStart.value
                            let _pgmStart2 = moment(context.feed.get.pgm_start, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
                            let _pgmStart3 = context.feed.get.pgmStart

                            if (_pgmStart1 === _pgmStart2 || context.feed.get.pgm_start === null && typeof _pgmStart1 === 'undefined' || _pgmStart1 == '') { changed = false }
                            else if (typeof context.feed.get.pgm_start != 'undefined' || _pgmStart1 != _pgmStart3 && typeof context.feed.get.pgm_start === 'undefined') {
                                changed = true
                                context.changedLog(k,context.formData.pgmStart.value, changed)
                            }

                            break

                        case 'mcr':
                            v.value.forEach((v2, k2) => {

                                // If the MCR in the current interation isn't
                                // present in the original feed object, then
                                // the MCR field has been changed.
                                if (!changed && (context.feed.get && context.feed.get[k].findIndex(item => item == v2.code) == -1)) {
                                    changed = true
                                    context.changedLog(k,v.value, changed)
                                }

                            })
                            break
                        case 'deliveryType':
                        case 'contentType':
                            if (!context.isEditForm) {
                                if (context.formFields[k] && context.formFields[k].touched) {
                                    changed = v.value != context.feed.get[k]
                                    context.changedLog(k,v.value, changed)
                                }
                            }
                            else {
                                changed = v.value != context.feed.get[k]
                                context.changedLog(k,v.value, changed)
                            }
                            break

                        case 'deliveryTypeData':
                            if (context.feed.get.deliveryTypeData) {
                                Lazy(v)
                                    .filter(field => context.isEditForm && field.onlyEdit)
                                    .each((v2, k2) => {
                                        if (k2 == 'satEncryptionByCountry') {
                                            if (!context.isBissCode) {
                                                // Placeholder to prevent any manipulation
                                                // when "Biss Code Local" isn't selected.
                                            }

                                            else if (context.feed.get.deliveryTypeData) {
                                                v2.value
                                                    .forEach(item => {
                                                        let countryCode = item.country.code ? item.country.code : item.country
                                                        if (context.feed.get.deliveryTypeData.satEncryptionByCountry){
                                                            let i = context.feed.get.deliveryTypeData.satEncryptionByCountry.findIndex(item => item.country === countryCode)
                                                            if (i === -1) return
                                                            if ((item.value || context.feed.get.deliveryTypeData.satEncryptionByCountry[i].value)
                                                                && (item.value !== context.feed.get.deliveryTypeData.satEncryptionByCountry[i].value)) {
                                                                changed = true
                                                                context.changedLog(k,v.value, changed)
                                                            }

                                                        }

                                                    })
                                            }
                                        }
                                        else if ( !changed && (v2.value || context.feed.get.deliveryTypeData[k2]) && (v2.value != context.feed.get.deliveryTypeData[k2])) {
                                            changed = true
                                            context.changedLog(k + '2',v.value, changed)
                                        }
                                    })
                            }
                            break
                        case 'audios':
                            v.value.forEach((v2, k2) => {
                                if (!changed && ((v2.value != context.feed.get[k][k2].value) || (v2.country != context.feed.get[k][k2].country))) {
                                    changed = true
                                    context.changedLog(k,v.value, changed)
                                }
                            })
                            break
                        case 'localNotesChanged':
                            changed = context.formData.localNotesChanged.value
                            context.changedLog(k,v.value, changed)
                            break
                        case 'calendarNotesChanged':
                            changed = context.formData.calendarNotesChanged.value
                            context.changedLog(k,v.value, changed)
                            break
                        case 'houseNumber':
                            changed = context.formData.isHouseNumberChanged.value
                            context.changedLog(k,v.value, changed)
                            break

                        default:
                            // If the form value and feed value are "false/null/empty"
                            // then "changed" should be false.
                            if (context.feed.get && !context.feed.get[k] && !v.value) {
                                changed = false
                            }
                            else {
                                changed = context.feed.get[k] !== v.value
                                context.changedLog(k,v.value, changed)
                            }
                            break
                    }

                }

            })

            return changed
        },
        isEditable() {
            return this.$ability.can('update', 'Feeds')
        },
        isCreatable() {
            //create read update delete
            return this.$ability.can('create', 'Feeds')
        },
        chosenDeliveryType() {
            const deliveryType = this.deliveryTypeEntity.getItem(this.formData.deliveryType.value)
            return deliveryType ? deliveryType.label.toLowerCase() : ''
        },
        feedStartDateLimit() {
            let now = moment(this.now)
            return [{
                type: 'fromto',
                from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
            }]
        },
        feedEndDateLimit() {
            let from = moment(this.formData.feedStart.value, 'YYYY-MM-DD HH:mm')
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },

        // pgmStartDateLimit() {
        //     let to = moment(this.formData.feedStart.value, 'YYYY-MM-DD HH:mm')
        //     return [{
        //         type: 'fromto',
        //         to: to.add(1, 'd')
        //     }]
        // },
        bissCodeIsSelected() {
            let values = ['biss_code', 'biss_code_shared']
            return values.indexOf(this.formData.deliveryTypeData.satEncryption.value) > -1
        },
        isBissCode() {
            return this.formData.deliveryTypeData.satEncryption.value == 'biss_code'
        },
        isBissCodeShared() {
            return this.formData.deliveryTypeData.satEncryption.value == 'biss_code_shared'
        },
        signoffItems() {
            return Lazy(this.formData.signoffs.value)
                .map(v => {
                    return Object.assign({
                        disabled: !this.readyForSignOff
                    }, v)
                }).toArray()
        },
        readyForSignOff() {

            // If the current form is a clone or is locked, then Signoff should
            // be locked.
            if (this.isClone || this.formData.locked.value) {
                return false
            }

            let ready = false

            // Check fields controlled by VeeValidate.
            ready = Object.keys(this.formFields).every(k => this.formFields[k].valid)

            // Check fields that aren't controlled by VeeValidate.
            if (ready) {
                switch (true) {
                    case (this.formData.mcr.value.length < 1):
                    case (this.formData.deliveryType.value == ''):
                    case (this.formData.contentType.value == ''):
                    case (this.formData.resolution.value == ''):
                    case (this.formData.frameRate.value == ''):
                    case (this.formData.aspectRatio.value == ''):
                    case (this.formData.systemFormat.value == ''):
                        ready = false
                        break
                }
            }

            // Check conditional fields.
            if (ready && this.formData.audios.value.length > 0) {
                ready = this.formData.audios.value.every(v => v.value != '')
            }
            if (ready && this.chosenDeliveryType == 'sat') {
                switch (true) {
                    case (this.formData.deliveryTypeData.satSatelliteId.value == ''):
                    case (this.formData.deliveryTypeData.satFrq.value == ''):
                    case (this.formData.deliveryTypeData.satPolarization.value == ''):
                    case (this.formData.deliveryTypeData.satModulation.value == ''):
                    case (this.formData.deliveryTypeData.satSymbolRate.value == ''):
                        ready = false
                        break
                }
            }
            if (ready) {
                switch (this.formData.deliveryTypeData.satEncryption.value) {
                    case 'biss_code':
                        this.formData.deliveryTypeData.satEncryptionByCountry.value.forEach(v => {
                            if (ready && (!v.value || v.value == '')) ready = false
                        })
                        break
                    case 'biss_code_shared':
                        if (this.formData.deliveryTypeData.satEncryptionBissCodeShared.value == '') {
                            ready = false
                        }
                        break
                }
            }

            return ready
        },
        getUploadDisabledMessage() {
            return !this.$ability.can('upload', 'Files') ? 'You don\'t have permission to upload files.' : 'Document upload is disabled'
        },
        getFiberEquipmentList() {
            if(this.formData.deliveryType.value === 46) {
                return this.allEquipmentList.filter(e => e.type === this.fiberEntity.get.id)
            }
            return null
        },
        getEquipmentList() {
            if(this.formData.deliveryType.value === 46) {
                return this.allEquipmentList.filter(e => e.type !== this.fiberEntity.get.id)
            }
            return this.allEquipmentList
        },
        json_data() {
            if (!this.feed || !this.feed.get) return
            let json = {
                "Order": {
                    "Name": "",
                    "Start": "",
                    "End": "",
                    "Main Signal": {
                        "Source":
                        {
                            "Type": "Satellite",
                            "Satellite":
                            {
                                "Satellite": ""
                            },
                            "Demodulating":
                            {
                                "Downlink Frequency": null,
                                "Polarization": "",
                                "Modulation Standard": "",
                                "Modulation": "",
                                "Symbol Rate": null,
                                "FEC": ""
                            },
                            "Decoding":
                            {
                                "Encoding": "",
                                "Encryption Type": "",
                                "Encryption Key": "",
                                "Video Format": "",
                                "Service Selection": ""
                            },
                            "Audio" :
                            {
                            },
                            "Comments": ""
                        }
                    },
                    "Additional Information": ""
                }
            }

            let league = this.leagueEntity.getItem(this.feed.get.league)
            let sport = this.sportEntity.getItem(league.reference)

            let name = sport.label + ' ' + league.label + ' ' + this.feed.get.title
            json.Order.Name = name

            

            let start =  momentTimezone.tz(this.feed.get.feedStart, "Europe/Stockholm").format()
            let end = momentTimezone.tz(this.feed.get.feedEnd, "Europe/Stockholm").format()

            json.Order.Start = start
            json.Order.End = end

            let dms = this.fiDMFieldsMappingEntity && this.fiDMFieldsMappingEntity.get && this.fiDMFieldsMappingEntity.get.items ? this.fiDMFieldsMappingEntity.get.items : []

            if(dms.length > 0) {
                //Satellite
                if(this.feed.get.deliveryTypeData.satSatelliteId && this.feed.get.deliveryTypeData.satSatelliteId !== null && this.feed.get.deliveryTypeData.satSatelliteId !== '') {
                    let bindSatellite = _.find(dms, i => i.label === 'Satellite' && parseInt(i.description) ===  this.feed.get.deliveryTypeData.satSatelliteId)
                    if(bindSatellite !== undefined) {
                        json.Order["Main Signal"].Source.Satellite.Satellite = bindSatellite.userfield2_value
                    }
                    else {
                        let st = _.find(this.satelliteEntity.get.items, i => i.id === this.feed.get.deliveryTypeData.satSatelliteId)
                        json.Order["Main Signal"].Source.Comments += 'Unmapped satellite: ' + st.label + ' \n'
                    }
                }

                //Frequency
                json.Order["Main Signal"].Source.Demodulating["Downlink Frequency"] = this.feed.get.deliveryTypeData.satFrq

                //Polarization
                if(this.feed.get.deliveryTypeData.satPolarization && this.feed.get.deliveryTypeData.satPolarization !== null && this.feed.get.deliveryTypeData.satPolarization !== '') {
                    let bindPolarization = _.find(dms, i => i.label === 'Polarization' && parseInt(i.description) ===  this.feed.get.deliveryTypeData.satPolarization)
                    if(bindPolarization !== undefined) {
                        json.Order["Main Signal"].Source.Demodulating.Polarization = bindPolarization.userfield2_value
                    }
                    else {
                        let st = _.find(this.polarizationEntity.get.items, i => i.id === this.feed.get.deliveryTypeData.satPolarization)
                        json.Order["Main Signal"].Source.Comments += 'Unmapped polarization: ' + st.label + ' \n'
                    }
                }

                //Modulation
                if(this.feed.get.deliveryTypeData.satModulation && this.feed.get.deliveryTypeData.satModulation !== null && this.feed.get.deliveryTypeData.satModulation !== '') {
                    let bindModulation = _.filter(dms, i => i.label === 'Modulation' && parseInt(i.description) ===  this.feed.get.deliveryTypeData.satModulation)
                    if(bindModulation.length > 0) {
                        let modStandard = _.find(bindModulation, i => i.userfield1_value === 'Modulation Standard')
                        json.Order["Main Signal"].Source.Demodulating['Modulation Standard'] = modStandard !== undefined ? modStandard.userfield2_value : ''
                        let mod = _.find(bindModulation, i => i.userfield1_value === 'Modulation')
                        json.Order["Main Signal"].Source.Demodulating.Modulation = mod !== undefined ? mod.userfield2_value : ''

                        //FEC
                        if(modStandard !== undefined && modStandard.userfield2_value === 'DVB-S') {
                            let bindFec = _.find(dms, i => i.label === 'FEC' && parseInt(i.description) ===  this.feed.get.deliveryTypeData.satFec)
                            if(bindFec !== undefined) {
                                json.Order["Main Signal"].Source.Demodulating.FEC = bindFec.userfield2_value
                            }
                            else {
                                let st = _.find(this.fecEntity.get.items, i => i.id === this.feed.get.deliveryTypeData.satFec)
                                json.Order["Main Signal"].Source.Comments += 'Unmapped FEC: ' + st.label + ' \n'
                            }
                        }
                    }
                    else {
                        let st = _.find(this.modulationEntity.get.items, i => i.id === this.feed.get.deliveryTypeData.satModulation)
                        json.Order["Main Signal"].Source.Comments += 'Unmapped modultation: ' + st.label + ' \n'
                    }
                }

                //Symbol Rate
                json.Order["Main Signal"].Source.Demodulating["Symbol Rate"] = this.feed.get.deliveryTypeData.satSymbolRate

                //System Format
                if(this.feed.get.systemFormat && this.feed.get.systemFormat !== null && this.feed.get.systemFormat !== '') {
                    let bindSystemFormat = _.find(dms, i => i.label === 'System Format' && parseInt(i.description) ===  this.feed.get.systemFormat)
                    if(bindSystemFormat !== undefined) {
                        json.Order["Main Signal"].Source.Decoding.Encoding = bindSystemFormat.userfield2_value
                    }
                    else {
                        let st = _.find(this.systemFormatEntity.get.items, i => i.id === this.feed.get.systemFormat)
                        json.Order["Main Signal"].Source.Comments += 'Unmapped encoding: ' + st.label + ' \n'
                    }
                }

                //Encription type
                let encr = ""
                if(this.feed.get.deliveryTypeData.satEncryption && this.feed.get.deliveryTypeData.satEncryption !== null) {
                    encr = this.feed.get.deliveryTypeData.satEncryption
                }
                let bindEncription = _.find(dms, i => i.label === 'Encryption' && i.description ===  encr)
                if(bindEncription !== undefined) {
                    json.Order["Main Signal"].Source.Decoding["Encryption Type"] = bindEncription.userfield2_value
                }
                else {
                    json.Order["Main Signal"].Source.Comments += 'Unmapped encryption: ' + encr + ' \n'
                }
                

                // Key for Biss Code Local
                if(this.isBissCode /*this.feed.get.deliveryTypeData.satEncryption === 'biss_code'*/) {
                    if(this.feed.get.deliveryTypeData.satEncryptionByCountry && this.feed.get.deliveryTypeData.satEncryptionByCountry.length > 0) {
                        let encKey = _.find(this.feed.get.deliveryTypeData.satEncryptionByCountry, i => i.valueType === 'encryption' && i.country == 'fi')
                        if(encKey !== undefined) {
                            json.Order["Main Signal"].Source.Decoding["Encryption Key"] = encKey.value
                        }
                    }
                    
                }

                // Key for Biss Code Shared
                if(this.isBissCodeShared /*this.feed.get.deliveryTypeData.satEncryption === 'biss_code_shared'*/ ) {
                    json.Order["Main Signal"].Source.Decoding["Encryption Key"] = this.feed.get.deliveryTypeData.satEncryptionBissCodeShared
                }

                //Resolution & Frame Rate
                if(this.feed.get.resolution &&  this.feed.get.resolution !== null && this.feed.get.resolution !== '' &&
                   this.feed.get.frameRate && this.feed.get.frameRate !== null && this.feed.get.frameRate !== ''
                ) {
                    let res = _.find(dms, i => i.label === 'Resolution' && parseInt(i.description) ===  this.feed.get.resolution && i.shortlabel === 'Frame Rate' && parseInt(i.text) === this.feed.get.frameRate)
                    if(res !== undefined) {
                        json.Order["Main Signal"].Source.Decoding["Video Format"] = res.userfield2_value
                    }
                    else {
                        let r = _.find(this.resolutionEntity.get.items, i => i.id === this.feed.get.resolution)
                        let f = _.find(this.frameRateEntity.get.items, i => i.id === this.feed.get.frameRate)
                        json.Order["Main Signal"].Source.Comments += 'Unmapped video format: ' + r.label + ' ' + f.label + ' \n'
                    }
                }

                //Service
                json.Order["Main Signal"].Source.Decoding["Service Selection"] = this.feed.get.deliveryTypeData.satService

                //Transponder channel
                json.Order["Main Signal"].Source.Comments += 'Transponder channel: ' + this.feed.get.deliveryTypeData.satTransponderChannel + ' \n'

                json.Order["Main Signal"].Source.Comments = json.Order["Main Signal"].Source.Comments.replace(/\r?\n|\r/g, '--')

                //Audiolayouts
                const feedAudioLayout = this.feed.get.audioLayout
                if (feedAudioLayout && feedAudioLayout !== '' && feedAudioLayout !== '{}') {
                    const audioLayoutEntities = this.audioLayoutsEntity.get.items
                    let audioLayoutObject = JSON.parse(feedAudioLayout)
                    Object.entries(audioLayoutObject).forEach(entry => {
                        let audioChannel = entry[0]
                        let audioLayoutForChannel = entry[1]
                        const foundEntityForAudioLayout = audioLayoutEntities.find(element => element.id === audioLayoutForChannel);
                        const isAudioLayoutEntityId = !isNaN(audioLayoutForChannel)
                        if (isAudioLayoutEntityId && foundEntityForAudioLayout) {
                            audioLayoutObject[audioChannel] = foundEntityForAudioLayout.label
                        }
                    })
                    json.Order["Main Signal"].Source.Audio = audioLayoutObject
                }

                //Id, ref, mcr, notes, local notes, contacts
                json.Order["Additional Information"] += 'ID: ' + this.feed.get.id + ' \n'

                json.Order["Additional Information"] += 'Reference: ' + window.location + ' \n'

                if(this.feed.get.mcr && this.feed.get.mcr !== null && this.feed.get.mcr !== '') {
                    let m = ''
                    if(this.feed.get.mcr.length > 0) {
                        this.feed.get.mcr.forEach(j => {
                            let cnt = _.find(this.countries, k => k.code === j)
                            m += cnt !== undefined ? cnt.label + ', ' : ''
                        })
                    }

                    json.Order["Additional Information"] += 'MCR: ' + m + ' \n'
                }

                if(this.feed.get.localNotes && this.feed.get.localNotes !== null && this.feed.get.localNotes !== '') {
                    if(this.feed.get.localNotes.length > 0) {
                        let lcl = _.find(this.feed.get.localNotes, i => i.country == 'fi')
                        if(lcl !== undefined) {
                            json.Order["Additional Information"] += 'Local notes: ' + lcl.value + ' \n'
                        }
                    }
                }

                if(this.feed.get.notes && this.feed.get.notes !== null && this.feed.get.notes !== '') {
                    json.Order["Additional Information"] += 'Notes: ' + this.feed.get.notes + ' \n'
                }

                if(this.feed.get.contacts && this.feed.get.contacts !== null && this.feed.get.contacts !== '') {
                    json.Order["Additional Information"] += 'Contacts: ' + this.feed.get.contacts + ' \n'
                }

                json.Order["Additional Information"] = json.Order["Additional Information"].replace(/\r?\n|\r/g, '--')

            }

            return json
        },
        showJSONdownload() {
            if (!this.feed || !this.feed.get) return

            let fi = _.includes(this.feed.get.mcr, 'fi')
            let type = this.getDeliveryType(this.feed.get.deliveryType) == 'sat'

            return fi && type && this.isEditForm && !this.isClone
        },
        getFeedTitleToJson() {
            if (!this.feed || !this.feed.get) return

            let start = moment(this.feed.get.feedStart, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
            let league = this.leagueEntity.getItem(this.feed.get.league)
            let sport = this.sportEntity.getItem(league.reference)

            let name = sport.label + '_' + league.label + '_' + this.feed.get.title + '_' + start
            return name
        },
        templates() {
            if(this.$store.state.data.productionTemplate)
                return Lazy(this.$store.state.data.productionTemplate)
                    .sortBy(item => item.title, false)
                    .toArray()
            else return null
        },
        getApplyFeedsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.applyTemplateForm.deliveryType || this.applyTemplateForm.league) {
                        if(this.applyTemplateForm.deliveryType && this.applyTemplateForm.deliveryType != item.data.deliveryType)
                            return false
                        if(this.applyTemplateForm.league && this.applyTemplateForm.league != item.data.league)
                            return false
                    }
                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },
    },
    methods: {
        changedLog(k,v,f) {
            //console.log(k,'isChanged at:', f, v)
        },
        /**
         * Set default values on "Add feed" and current data on "edit".
         */
        setFormValues() {
            const context = this
            //console.log(this)
            if (context.isEditForm || context.isTemplateBased) {

                // Determine which feed instance to use, in order to populate
                // the fields.
                let feed = (context.isClone) ? new Feed(parseInt(context.$route.query.clone)) : context.feed
                //console.log('DATA COMES IN <-------')
                //console.log(context.formData);
                //console.log(context.feed.get);

                Lazy(context.formData)
                    .filter(v => !v.local)
                    .each(function(v, k) {
                        switch (k) {
                            case 'houseNumber':
                                let hNumber = context.feed.get.houseNumber
                                if (hNumber) {
                                    hNumber = JSON.parse(hNumber)
                                    Lazy(v.value)
                                        .each(function (v2, k2) {
                                            v.value[k2] = hNumber[k2]
                                        })
                                }

                                break
                            case 'placeholders':
                                if (!context.isClone) {
                                    if(feed.get.placeholders && feed.get.placeholders.length > 0) {
                                        let placeholders = _.cloneDeep(feed.get.placeholders)//feed.get.placeholders.slice()
                                    
                                        Lazy(placeholders)
                                            .each(function(v1, k1) {
                                                v1.local = false
                                                Lazy(v1.placeholders)
                                                    .each(function(v2, k2) {
                                                        v2.local = false
                                                        v2.action = 'none'
                                                        v2.submit = false
                                                    })
                                            })
                                        
                                            v.value = placeholders
                                            context.formData.placeholders.showPlaceholders = true
                                    }

                                    let houseNumber = context.feed.get.houseNumber
                                    if (houseNumber) {
                                        if (isJsonSring(houseNumber)) {
                                            houseNumber = JSON.parse(houseNumber)
                                            Lazy(houseNumber)
                                            .each(function (v2, k2) {
                                                if(houseNumber[k2] && houseNumber[k2] !== '') {
                                                    if(!context.formData.placeholders.showPlaceholders) {
                                                        let isRec = context.isForFork(houseNumber[k2], k2)
                                                        if(isRec) {
                                                            context.formData.placeholders.showPlaceholders = true
                                                            context.formData.placeholders.initialHasRec = true

                                                            let currentForkCountry = _.find(context.formData.placeholders.value, p => p.coutry == k2)
                                                            if(currentForkCountry === undefined) {
                                                                if(k2 !== 'se') {
                                                                    context.formData.placeholders.value.push({
                                                                        local: false,
                                                                        country: k2,
                                                                        placeholders: []
                                                                    })
                                                                }
                                                                else {
                                                                    let isNoRec = houseNumber['no'] && houseNumber['no'] !== '' ? context.isForFork(houseNumber['no'], 'no') : false
                                                                    if(!isNoRec) {
                                                                        context.formData.placeholders.value.push({
                                                                            local: false,
                                                                            country: k2,
                                                                            placeholders: []
                                                                        })
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    
                                                    
                                                }
                                            })
                                        }
                                    }
                                }
                                else {
                                    if(feed.get.placeholders && feed.get.placeholders.length > 0) {
                                        let localPlaceholders = []
                                        Lazy(feed.get.placeholders)
                                            .each((v,k) => {
                                                let placeholders = []
                                                Lazy(v.placeholders)
                                                    .each((v1,k1) => {
                                                        placeholders.push({
                                                            id: v1.id,
                                                            country: v1.country,
                                                            periodStart: v1.periodStart,
                                                            periodEnd: v1.periodEnd,
                                                            title: v1.title,
                                                            itemId: v1.itemId,
                                                            itemType: 'feed',
                                                            local: true,
                                                            action: 'create',
                                                            submit: false
                                                        })
                                                    })
                    
                                                localPlaceholders.push({
                                                    local: true,
                                                    country: v.country,
                                                    placeholders: placeholders
                                                })
                                            })
                    
                                            v.value = localPlaceholders
                                            context.formData.placeholders.showPlaceholders = true
                                    }
                                }
                                break
                            case 'sport':
                                const league = feed.get.league
                                if (league) {
                                    const entityItem = context.leagueEntity.getItem(league)
                                    v.value = entityItem.reference
                                }
                                break
                            case 'mcr':
                                v.value = []
                                const mcr = feed.get.mcr
                                if (mcr) {
                                    context.countries.forEach(item => {
                                        if (mcr.indexOf(item.code) > -1) {
                                            v.value.push(item)
                                        }
                                    })

                                    // Since satEncryptionByCountry is populated according
                                    // to the values of MCR we need to "delay" the
                                    // population of satEncryptionByCountry to the
                                    // next DOM update, otherwise it will be empty
                                    // when trying to update its values.
                                    Vue.nextTick(function() {
                                        if (feed.get.deliveryTypeData && feed.get.deliveryTypeData.satEncryptionByCountry) {
                                            //console.log(feed.get.deliveryTypeData.satEncryptionByCountry);
                                            // Set country values, without "other countries".
                                            feed.get.deliveryTypeData.satEncryptionByCountry
                                                .forEach(item => {
                                                    context.formData.deliveryTypeData.satEncryptionByCountry.value
                                                        .filter(item2 => item2.country != 'other')
                                                        .forEach(item2 => {
                                                            if (item2.country.code == item.country && item2.valueType == item.valueType) {
                                                                item2.value = item.value
                                                            }
                                                        })
                                                })

                                            // Set "other countries" value, if available.
                                            let i = feed.get.deliveryTypeData.satEncryptionByCountry.findIndex(v => v.country == 'other')
                                            let j = context.formData.deliveryTypeData.satEncryptionByCountry.value.findIndex(v => v.country == 'other')
                                            if ((i > -1) && (j > -1)) {
                                                context.formData.deliveryTypeData.satEncryptionByCountry.value[j].value = feed.get.deliveryTypeData.satEncryptionByCountry[i].value
                                            }

                                        }
                                    })

                                }
                                break
                            case 'mcrExternal':
                                v.value = []
                                if(feed.get.mcrExternal) {
                                    let extMCRsArr = feed.get.mcrExternal.split(',')
                                    if(extMCRsArr && extMCRsArr.length > 0 ) {
                                        if(!isNaN(parseInt(extMCRsArr[0]))) {
                                            extMCRsArr.forEach(e => {
                                                let eMCR = _.find(context.externalMCRs, m => m.id === parseInt(e))
                                                if(eMCR) {
                                                    v.value.push(eMCR)
                                                }
                                            })
                                        }
                                    }
                                    
                                }
                                break
                            // @TODO Something really broken with the Feeds gathering data, need to fix it.
                            // for now, action buttons as it is from defaultData
                            case 'actions':
                                const actions = []
                                actions.forEach(item => {
                                    v.value.push(item)
                                })
                                break

                            case 'audios':
                                context.formData.audios.value = []
                                if(feed.get[k] && feed.get[k].length > 0) {
                                    feed.get[k].forEach(v => {
                                        context.formData.audioCountries.value.push(v.country)
                                    })
    
                                    // Copy each audio item in order to avoid pointers.
                                    feed.get.audios.forEach(item => {
                                        if (!item.value) item.value = ''
                                        v.value.push(Object.assign({}, item))
                                    })
                                }
                                

                                break
                            case 'deliveryTypeData':
                                //console.log(feed.get.deliveryTypeData);
                                if (feed.get.deliveryTypeData) {
                                    Lazy(v)
                                        .each((v2, k2) => {
                                            // satEncryptionByCountry is set together with
                                            // MCR field. Look above...
                                            if (k2 != 'satEncryptionByCountry') {
                                                if (k2 == 'satFrq' && feed.get[k][k2]) {
                                                    context.handleFrqInputPast(feed.get[k][k2])
                                                } else if (k2 == 'satSymbolRate' && feed.get[k][k2]) {
                                                    context.handleSymbolRatePast(feed.get[k][k2])
                                                } else if(k2 == 'satSatellite' && context.isClone) {
                                                    v2.value = ''
                                                }
                                                else {
                                                    v2.value = feed.get[k][k2] ? feed.get[k][k2] : ''
                                                }
                                            }
                                        })
                                }
                                break

                            case 'equipment':
                                if(feed.get[k]) {
                                    let allEq = feed.get[k].slice()
                                    if(feed.get.deliveryType && feed.get.deliveryType === 46) {
                                        v.value = allEq.filter(e => !e.hasOwnProperty('type') || e.type !== context.fiberEntity.get.id)
                                    }
                                    else {
                                        v.value = allEq
                                    }
                                    if(feed.get.equipment_type_usages && feed.get.equipment_type_usages.length > 0) {
                                        feed.get.equipment_type_usages.forEach((el) => {
                                                let newArray = el["equipment_type"];
                                                newArray["equipmentUsage"] = [];
                                                newArray["equipmentUsage"]["id"] = el["id"];
                                                newArray["equipmentUsage"]["entityItemId"] = el["entity_item_id"];
                                                newArray["equipmentUsage"]["usageId"] = el["equipment_type_usage_id"];
                                                newArray["equipmentUsage"]["usageType"] = el["equipment_type_usage_type"];
                                                v.value.push(newArray);
                                            })
                                        
                                    }
                                }
                                    
                                
                                break
                            case 'fiber':
                                if(feed.get.deliveryType && feed.get.deliveryType === 46 && !context.isTemplateBased) {
                                    v.value = feed.get.equipment.slice().filter(e => e.type === context.fiberEntity.get.id)
                                    context.feed.set('fiberEquipment', v.value)
                                }
                            break
                            default:

                                // We need to detect if the value is array/object or
                                // another type, so we can make a real copy of the
                                // value and not create a pointer.
                                if(feed.get[k]) {
                                    if (typeof feed.get[k] == 'object') {
                                        if (Array.isArray(feed.get[k])) {
                                            v.value = feed.get[k].slice()
                                        }
                                        else {
                                            Object.assign(v.value, feed.get[k])
                                        }
                                    }
                                    else {
                                        v.value = feed.get[k]
                                    }
                                }

                                break
                        }
                    })

            }
            else {
                let defaultValue = null
                const entities = ['resolution', 'frameRate', 'aspectRatio', 'systemFormat', 'deliveryType', 'contentType']

                // Try to set default values for entity field.
                entities.forEach(v => {
                    defaultValue = context[`${v}Entity`].get.items.filter(function(item) {
                        return item.default
                    })
                    if (defaultValue.length > 0) {
                        context.formData[v].value = defaultValue[0].id
                    }
                })

            }

        },
        // fixing date format for the hard-fix into form for PGM Start data. WN
        pgmStartFormat(pgrtime) {
            let start = moment(pgrtime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
            return start;
        },

        openInsertForm() {
            const context = this
            context.setNow()
            context.insertForm.loading = true
            context.insertForm.show = true
            const params ={
                league: context.feed.get.league
            }
            context.feed.all(params)
                .then(response => {
                    context.insertForm.loading = false
                })
        },

        /**
         * Get feed title that is used inside the dropdown for feed data copy.
         */
        getFeedTitleToInsertForm(feed) {
            let start = moment(feed.feedStart, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
            return `${start}, ${feed.title} (${feed.id})`
        },

        /**
         * Check if a given feed should be visible in the "Insert data" dropdown.
         */
        isVisibleOnInsertForm(feed) {
            const context = this
            let diff = moment(feed.feedEnd, 'YYYY-MM-DD HH:mm').diff(context.now)
            return feed.league == context.feed.get.league && !isNaN(diff) && diff < 0
        },
        reSetEquipment(oldDeliveryType, currentDeliveryType, oldMCRs, currentMCRs){
            if(!this.loaded)  return
            const context = this
            let newEquipmentList = context.feed.get.equipment ? context.feed.get.equipment.slice() : []
            let createWithEquipments = context.allEquipmentList.filter(i => i.create_with)
            let mcrCountries = currentMCRs

            if(oldDeliveryType !== currentDeliveryType) {
                newEquipmentList = []
            }
            else {
                let removeCountries = _.difference(oldMCRs, currentMCRs)
                mcrCountries = _.difference(currentMCRs, oldMCRs)
                if(removeCountries && removeCountries.length > 0) {
                    newEquipmentList = context.feed.get.equipment ? context.feed.get.equipment.filter(f => {
                        let cnt = f.notCountryRestricted ? f.source : f.country
                        let inRemoveList = _.includes(removeCountries, cnt)
                        return !inRemoveList
                    }) : []
                }
            }

            if(mcrCountries && mcrCountries.length > 0) {
                createWithEquipments.forEach(createWithEquipment => {
                    let createWithEquipmentArr = _.map(createWithEquipment.create_with.split(','), i => parseInt(i))
                    let equipmentCountry = createWithEquipment.notCountryRestricted ? createWithEquipment.source : createWithEquipment.country
                    if(_.includes(createWithEquipmentArr, currentDeliveryType) && _.indexOf(mcrCountries, equipmentCountry) > -1) {
                        let newEquipment = Object.assign({}, createWithEquipment)
                        newEquipment.equipmentUsage = {
                                                        'conflict': false,
                                                        'exceedsGroupLimit': false,
                                                        'id': null,
                                                        'isNotUpdatePeriod': false,
                                                        'isPriamry': false,
                                                        'mcrStatus': null,
                                                        'periodEnd': context.formData.feedEnd.value,
                                                        'periodStart': context.formData.feedStart.value
                                                    }
                        newEquipmentList.push(newEquipment)
                    }
                   
                })
            }
            

            if(currentDeliveryType === 46) {
                context.formData.fiber.value = newEquipmentList.filter(e => e.type === context.fiberEntity.get.id)
                context.formData.equipment.value = newEquipmentList.filter(e => !e.hasOwnProperty('type') || e.type !== context.fiberEntity.get.id)
                context.feed.set('fiberEquipment', context.formData.fiber.value)
            }
            else {
                context.formData.equipment.value = newEquipmentList
                context.formData.fiber.value = []
                context.feed.set('fiberEquipment', context.formData.fiber.value)
            }
            
        },
        /**
         * Moving fork placeholders before submit if it is recording
         * @param {*} open 
         * @param {*} redirect 
         */
         submitItem(open = false, redirect = true) {
            const context = this
            //need to save open and redirect params to use them inside callbacks
            context.submitOpen = open
            context.submitRedirect = redirect

            // ProductionLeader and the Dates are a must, do not let it save without it. Seems like, when you click cancel and save changes
            // it was allowing to save without mandatory fields. WN
            let _prodStart = context.formData.feedStart.value
            let _prodEnd = context.formData.feedEnd.value
            if (['feed.create', 'feed.edit'].indexOf(this.$route.name) > -1) {
                if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                    Lazy(context.formData.placeholders.value)
                     .each((v,k) => {
                         Lazy(v.placeholders)
                            .each((v1, k1) => {
                                if(!v1.periodStart || !v1.periodEnd) {
                                    context.$alert.set(`The fork laceholder should have start and end datetime.`, 'danger', 7)
                                    context.setLoaded();
                                    return
                                }
                            })
                     })
                }
            }

            let feed = context.isClone ? new Feed(parseInt(context.$route.query.clone)) : context.feed

            const datesBeforeChange = {
                start: feed.get.feedStart,
                end: feed.get.feedEnd
            }

            // Delete placeholders for deleted MCR
            if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0 && context.countries && context.countries.length > 0) {
                Lazy(context.countries)
                    .each((v,k) => {
                        let prevMCR = _.find(context.feed.get.mcr, i => i === v.code)
                        let currentMCR = _.find(context.formData.mcr.value, i => i.code === v.code)

                        if(prevMCR !== undefined && currentMCR === undefined ) {
                            let hNumber = context.feed.get.houseNumber
                            if (hNumber) {
                                hNumber = JSON.parse(hNumber)
                                let prevHouseNumber = hNumber[v.code]
                                if(prevHouseNumber && prevHouseNumber !== '' && context.isForFork(prevHouseNumber, v.code)) {
                                    let countryFork = _.find(context.formData.placeholders.value, i => i.country === v.code)
                                    if(countryFork !== undefined) {
                                        if(countryFork.local) {
                                            let countryForkIndx = _.findIndex(context.formData.placeholders.value, i => i.country === v.code)
                                            if(countryForkIndx > -1) {
                                                context.formData.placeholders.value.splice(countryForkIndx, 1)
                                            }
                                        }
                                        else {
                                            let indxs = []
                                            Lazy(countryFork.placeholders)
                                                .each((v1,k1) => {
                                                    if(v1.local) {
                                                        indxs.push(v1.id)
                                                    }
                                                    else {
                                                        v1.action = 'delete'
                                                    }
                                                })
                                            if(indxs.length > 0) {
                                                Lazy(indxs)
                                                    .each((v2,k2) => {
                                                        let dlt = _.findIndex(countryFork.placeholders, i => i.id === v2)
                                                        if(dlt > -1) {
                                                            countryFork.placeholders.splice(dlt, 1)
                                                        }
                                                    })
                                            }
                                        }
                                    }
                                }
                            }
                            
                        }
                    })
            }

            /**
             * if feed dates have changed, and if we have fork placeholders then we need to update dates for placeholders
             * if the placeholder date start 10 minutes before feed and finishes 10 minutes after feed
             * then we can move placeholder dates automatically in case if new difference is not longer than 4 hours
             * if it is longer than 4 hours, then we suggest to edit
             * if we have a few placeholders or his dates are not within +/- 10 minutes interval then
             * we need to ask approval for new placeholders dates
            **/
            if(((context.isEditForm && ((datesBeforeChange.start !== _prodStart) || (datesBeforeChange.end !== _prodEnd))) || context.isClone) && 
               context.formData.placeholders.value && context.formData.placeholders.value.length > 0 &&
               (!context.formData.placeholders.hasOwnProperty('datesChangedAccepted') || (context.formData.placeholders.hasOwnProperty('datesChangedAccepted') && context.formData.placeholders.datesChangedAccepted === false))) {
                context.formData.placeholders.datesChangedAccepted = false
                let oldDateProductionStart = new Date((moment(datesBeforeChange.start)).format('YYYY-MM-DD HH:mm'))
                let oldDateProductionEnd = new Date((moment(datesBeforeChange.end)).format('YYYY-MM-DD HH:mm'))
                let oldProdDiff = Math.ceil((oldDateProductionEnd.getTime() - oldDateProductionStart.getTime()) / 3600000)

                let newDateProductionStart = new Date((moment(_prodStart)).format('YYYY-MM-DD HH:mm'))
                let newDateProductionEnd = new Date((moment(_prodEnd)).format('YYYY-MM-DD HH:mm'))
                let newProdDiff = Math.ceil((newDateProductionEnd.getTime() - newDateProductionStart.getTime()) / 3600000)
                if((oldDateProductionEnd.getTime() - oldDateProductionStart.getTime()) === (newDateProductionEnd.getTime() - newDateProductionStart.getTime()) ||
                   (oldProdDiff <= 4 && newProdDiff <= 4)) {
                    context.formData.placeholders.datesChangedAccepted = true
                    Lazy(context.formData.placeholders.value)
                        .each((v, k) => {
                            if(v.placeholders && v.placeholders.length === 1) {
                                let singlePlaceholder = v.placeholders[0]
                                singlePlaceholder.local = true
                                singlePlaceholder.action = !singlePlaceholder.action || (singlePlaceholder.action && singlePlaceholder.action === 'none') ? 'update' : singlePlaceholder.action
                                if(singlePlaceholder.forkPlaceholder) {
                                    singlePlaceholder.submit = true
                                }
                                singlePlaceholder.periodStart = moment(newDateProductionStart).format('YYYY-MM-DD HH:mm')
                                singlePlaceholder.periodEnd = moment(newDateProductionEnd).format('YYYY-MM-DD HH:mm')
                            }
                            else{
                                Lazy(v.placeholders)
                                .each((v2,k2) => {
                                    let oldPlaceholderStart = new Date(v2.periodStart)
                                    let oldPlaceholderEnd = new Date(v2.periodEnd)

                                    let oldStartDiff = (oldDateProductionStart.getTime() - oldPlaceholderStart.getTime()) * (-1)
                                    let newPlaceholderStart = (moment(newDateProductionStart).add(oldStartDiff, 'milliseconds')).format('YYYY-MM-DD HH:mm')

                                    let oldEndDiff = (oldDateProductionStart.getTime() - oldPlaceholderEnd.getTime()) * (-1)
                                    let newPlaceholderEnd = (moment(newDateProductionStart).add(oldEndDiff, 'milliseconds')).format('YYYY-MM-DD HH:mm')

                                    v2.local = true
                                    v2.action = !v2.action || (v2.action && v2.action === 'none') ? 'update' : v2.action
                                    if(v2.forkPlaceholder) {
                                        v2.submit = true
                                    }
                                    v2.periodStart = newPlaceholderStart
                                    v2.periodEnd = newPlaceholderEnd
                                })
                            }
                            
                        })

                        context.submit(open, redirect)
                }
                else {
                    swal({
                        title: 'Changes for placeholders are required',
                        text: `Placeholders cannot be moved automatically. You need to fix it manually. Would you like to edit it?`,
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            // TO DO: open modal window in forkplaceholder
                            console.log('open modal window in forkplaceholder')
                            let placeholders = _.cloneDeep(context.formData.placeholders.value)
                            Lazy(placeholders)
                                .each(function(v1, k1) {
                                    v1.local = true
                                    Lazy(v1.placeholders)
                                        .each(function(v2, k2) {
                                            if(!v2.local) {
                                                v2.local = true
                                                v2.action = !v2.action || (v2.action && v2.action === 'none') ? 'update' : v2.action
                                                if(v2.forkPlaceholder) {
                                                    v2.submit = true
                                                }
                                            }
                                            
                                        })
                                })
                            context.placeholderForm = placeholders
                            context.showPlaceholderForm = true
                        
                        }, function (dismiss) {
                            context.formData.placeholders.datesChangedAccepted = true
                            context.submit(open, redirect)
                        })
                }
                
            }
            else if(context.isEditForm && context.formData.placeholders.value && context.formData.placeholders.value.length > 0 &&
                    (context.feed.get.league !== context.formData.league.value ||
                     context.feed.get.contentType !== context.formData.contentType.value ||
                     context.feed.get.title !== context.formData.title.value
                    )) {

                    Lazy(context.formData.placeholders.value)
                        .each(function(v1, k1) {
                            v1.local = true
                            Lazy(v1.placeholders)
                                .each(function(v2, k2) {
                                    if(!v2.local) {
                                        v2.local = true
                                        v2.action = 'update'
                                        if(v2.forkPlaceholder) {
                                            v2.submit = true
                                        }
                                    }
                                    
                                })
                        })
                    
                    context.submit(open, redirect)
            }
            else {
                context.submit(open, redirect)
            }
        },

        /**
         * Save the form.
         */
        submit(open = false, redirect = true) {
            const context = this
            //context.$loader.add('feedEdit_submit')
            const datesBeforeChange = {
                start: this.feed.get.feedStart,
                end: this.feed.get.feedEnd
            }
            Lazy(context.formData)
                .filter((v, k) => !v.local)
                .each(function(v, k) {
                    if (!v.value && !v.time && (k != 'deliveryTypeData')) {
                        context.feed.set(k, '')
                        return true
                    }

                    let value;

                    switch (k) {
                        case 'houseNumber':
                            value = {}
                            Lazy(v.value)
                                .each((item, k) => {
                                    if (_.includes(context.houseNumbers, k)) {
                                        // if its undefined, or empty, just make it empty instead of null. WN
                                        value[k] = item ? item : ''
                                    }
                                })
                            break
                        case 'placeholders':
                            if(v.value && v.value.length > 0) {
                                let createPlacholders = []
                                let updatePlacholders = []
                                let deletePlacholders = []
                                Lazy(v.value)
                                    .each((v2, k2) => {
                                        Lazy(v2.placeholders)
                                            .each((v3, k3) => {
                                                if(v3.local) {
                                                    if(v3.action === 'create') createPlacholders.push(v3)
                                                    if(v3.action === 'update') updatePlacholders.push(v3)
                                                }
                                                if(v3.action === 'delete') deletePlacholders.push(v3)
                                            })
                                    })
                                if(createPlacholders.length > 0 || updatePlacholders.length > 0 || deletePlacholders.length > 0) {
                                    context.feed.set('handlePlaceholders', true)
                                    value = {
                                        create: createPlacholders,
                                        update: updatePlacholders,
                                        delete: deletePlacholders
                                    }
                                }
                                else {
                                    context.feed.set('handlePlaceholders', false)
                                }
                            }
                            break
                        case 'mcr':
                            value = []
                            v.value.forEach(item => {
                                value.push(item.code)
                            })
                            break
                        case 'mcrExternal': 
                            value = ''
                            if(context.showExternalMCRWarning.warn && v.value.length === 0) {
                                value = context.showExternalMCRWarning.oldExternalMCRs
                            }
                            else {
                                if(v.value.length > 0) {
                                    let extMCRIds = v.value.map(i => i.id)
                                    value = extMCRIds.join(',')
                                }
                            }
                            break
                        case 'comments':
                            if (v.value) {
                                value = context.cleanObjectString(v.value)
                            }
                            break
                        case 'deliveryTypeData':
                            value = {}
                            value.deliveryType = context.formData.deliveryType.value
                            Lazy(v)
                            .filter(b => b && b.value)
                            .each(function(b, a) {

                                // Save only data for the chosen Delivery type.
                                if (a.indexOf(context.chosenDeliveryType) > -1) {

                                    // Convert "country" object into a string, which
                                    // is required by the API.
                                    if (typeof b.value == 'object') {
                                        switch (context.formData.deliveryTypeData.satEncryption.value) {
                                            case 'biss_code_shared':
                                            case 'director':
                                            case 'director_xml':
                                                b.value = []
                                                break
                                        }

                                        // Clone the array value.
                                        const valueToSet = JSON.parse(JSON.stringify(b.value))

                                        valueToSet.forEach(item => {
                                            if (item.country && item.country.code) item.country = item.country.code
                                        })
                                        value[a] = valueToSet
                                    }

                                    else {
                                        if (!context.isBissCodeShared && a == 'satEncryptionBissCodeShared') b.value = ''
                                        value[a] = b.value
                                    }

                                }
                                else b.value = ''
                            })
                            break
                        default:
                            value = v.value
                            break
                    }
                    context.feed.set(k, value)
                })

            // we need to json stringify
            if (context.feed.get.houseNumber) {
                context.feed.get.houseNumber = JSON.stringify(context.feed.get.houseNumber)
            }

            if (context.isEditForm && !context.isClone) {

                const datesChanged = (datesBeforeChange.start !== context.feed.get.feedStart) || (datesBeforeChange.end !== context.feed.get.feedEnd)

                // Tell the API to update the date on the equipment pieces.
                if (datesChanged && context.feed.get.equipment.length > 0) {
                    context.feed.set('updateEquipmentDate', 1)
                    // Add fiber equipments into the equipment list, so they will get also updated
                    if (context.feed.get && context.feed.get.fiberEquipment && context.feed.get.fiberEquipment.length > 0) {
                        context.feed.set('equipment', context.feed.get.equipment.concat(context.feed.get.fiberEquipment))
                    }
                }

                context.feed.save()
                    .then(response => {
                        context.submitted = true
                        this.$alert.set('Feed ' + context.id + ' was saved!', 'success', 3)

                        if(datesChanged && context.formData.placeholders.hasOwnProperty('datesChangedAccepted')) delete context.formData.placeholders.datesChangedAccepted

                        // check if its more than 24 hours long
                        let fStart = context.feed.get.feedStart
                        fStart = new Date(fStart);
                        let fEnd = context.feed.get.feedEnd
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        if (differenceTime >= 24) {
                            swal({
                                title: '24 Hours Long',
                                text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                type: 'question',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                                showCancelButton: true,
                                showCloseButton: true
                            })
                                .then(function() {
                                    if (context.getTabRoutes() && !open) { return }
                                    const feedId = response.data.id
                                    context.$router.push({ path: `/feed/${feedId}` })
                                }, function(dismiss) {
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    if (redirect) context.redirect(open)
                                })
                                .catch(() => {
                                    // Close the tab if its clicked to "save & close the tab"
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    if (redirect) context.redirect(open)
                                })
                        }

                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }

                        this.updateHouseNumbers()

                        Vue.set(context.$data, 'formData', context.defaultData())

                        if (redirect) {
                            context.redirect(open)
                        }
                        else {
                            context.setLoaded()
                        }

                        context.$loader.remove('feedEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the feed.')
                        context.$loader.remove('feedEdit_submit')
                    })
            }
            else {
                context.feed.store()
                    .then(response => {
                        context.submitted = true
                        this.$alert.set(`Feed ${response.data.id} has been created!`, 'success', 3)

                        // check if its more than 24 hours long
                        let fStart = this.formData.feedStart.value
                        fStart = new Date(fStart);
                        let fEnd = this.formData.feedEnd.value
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        if (differenceTime >= 24) {
                            swal({
                                title: '24 Hours Long',
                                text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                type: 'question',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                                showCancelButton: true,
                                showCloseButton: true
                            })
                                .then(function() {
                                    // Close the tab if its clicked to "save & close the tab"
                                    if (context.getTabRoutes() && !open) { return }
                                    const feedId = response.data.id
                                    context.$router.push({ path: `/feed/${feedId}` })
                                }, function(dismiss) {
                                    // Close the tab if its clicked to "save & close the tab"
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    if (redirect) context.redirect(open)
                                })
                                .catch(() => {
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    if (redirect) context.redirect(open)
                                })
                        }


                        // Reset formData if "Save & Close" button has been clicked.
                        // Close the tab if its clicked to "save & close the tab"
                        if (context.getTabRoutes() && !open) { return }

                        this.updateHouseNumbers()
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())
                        context.feed = new Feed(response.data.id)
                        if (redirect) context.redirect(open, response.data.id)
                        context.$loader.remove('feedEdit_submit')
                    })
                    .catch(error => {

                        // @TODO Old error handling
                        // let msg
                        // if (typeof error.response == 'string') msg = error.response
                        // else if (error.response.data.message) msg = error.response.data.message
                        // else msg = 'It was not possible to save the feed.'
                        // this.$alert.set(msg, 'danger', 6)

                        context.$error.set(error, 'It was not possible to save the feed.')
                        context.$loader.remove('feedEdit_submit')
                    })
            }
        },

        /**
         * Check if the passed tab name is the current active tab.
         */
        isActiveTab(name, context = 'default') {
            return this.activeTab[context] == name
        },

        /**
         * Set the current active tab.
         */
        setActiveTab(name, context = 'default') {
            if (!this.activeTab.hasOwnProperty(context)) {
                Vue.set(this.activeTab, context, '')
            }
            this.activeTab[context] = name
        },

        print() {
            this.$router.replace({query: {print: 1}})
            //console.log(this.$router)
        },

        getDeliveryType(id) {
            const deliveryType = this.deliveryTypeEntity.getItem(id)
            return deliveryType ? deliveryType.label.toLowerCase() : ''
        },
        /**
         * houseNumber values, represents the values of MCR selection as a house number field
         * this fields also important to save and create operations.
         * @returns {[]}
         */
        houseNumberValues() {
            const context = this
            let values = context.formData.houseNumber.values
            let newValues = []
            let mcrValue = []
            mcrValue = this.$store.state.system.countries.map(i => i.code);

            if (values) {
                Lazy(values)
                    .each(function (v, k) {
                        if (_.includes(mcrValue, v)) {
                            newValues.push(v)
                        }
                    })
                context.houseNumbers = newValues
                return newValues
            }
        },
        // New Tab fix
        getTabRoutes() {
            return this.$route.query.newBar;
        },

        startDownload() {
            const data = JSON.stringify(this.json_data)

            const blob = new Blob([data], {
                type: 'application/json'
              });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.getFeedTitleToJson}.json`;
            link.click();
            //this.$alert.set('Json file is downloaded.', 'success', 3)
        },

        /**
         * Default form data to be used in this form.
         * @return object
         */
        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                resolution: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                frameRate: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                aspectRatio: {
                    value: '',
                    onlyEdit: true,
                    validator: {
                        rules: 'required'
                    }
                },
                systemFormat: {
                    value: '',
                    onlyEdit: true
                },
                contentType: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                houseNumber: {
                    value: this.defaultHouseNumberKeys,
                    // value: {
                    //     se: '',
                    //     no: '',
                    //     dk: '',
                    //     fi: '',
                    //     uk: ''
                    // },
                    values: this.defaultHouseNumbers//['se', 'no', 'dk', 'fi', 'uk']
                },
                isHouseNumberChanged: {
                    value: false,
                    local: true
                },
                sport: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                league: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                mcr: {
                    value: []
                },
                mcrExternal: {
                    value: [],
                    // validator: {
                    //     rules: ''
                    // }
                },
                bookingNo: {
                    value: '',
                },
                encryption: {
                    value: '',
                },
                feedStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                feedEnd: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                pgmStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: false,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                audioLayout: {
                    value: '',
                    onlyEdit: true
                },
                audios: {
                    value: [],
                    onlyEdit: true
                },
                audioCountries: {
                    value: [],
                    local: true
                },
                documents: {
                    value: [],
                    progress: 0,
                    onlyEdit: true
                },
                equipment: {
                    value: [],
                    onlyEdit: true
                },
                fiber: {
                    value: [],
                    onlyEdit: true
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
                feedbacks: {
                    value: [],
                    onlyEdit: true
                },
                notes: {
                    value: '',
                    onlyEdit: true
                },
                localNotes: {
                    value: [],
                    onlyEdit: true
                },
                localNotesChanged: {
                    value: false
                },
                calendarNotesChanged: {
                    value: false
                },
                comments: {
                    value: '',
                    onlyEdit: true
                },
                contacts: {
                    value: '',
                    onlyEdit: true
                },
                signoffs: {
                    value: [],
                    onlyEdit: true
                },
                ingestions: {
                    value: [],
                    onlyEdit: true
                },
                cloneOf: {
                    value: null
                },
                placeholders: {
                    value: [],
                    showPlaceholders: false,
                    initialHasRec: false,
                    openDefault: 0,
                    isPlacholdersIncorrect: false
                },
                actions: {
                    value: ['template', 'apply', 'clone', 'delete']
                },

                // Delivery type values.
                deliveryType: {
                    value: '',
                    validator: {}
                },
                deliveryTypeData: {
                    downloadInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    fiberInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    streamingType: {
                        value: '',
                        onlyEdit: true
                    },
                    streamingInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    satInfo: {
                        value: '',
                        onlyEdit: true
                    },
                    satSatellite: {
                        value: '',
                        onlyEdit: true
                    },
                    satSatelliteId: {
                        value: '',
                        onlyEdit: true
                    },
                    satFrq: {
                        value: '',
                        validator: {
                            regex: /(^\d{5}$)|(^\d{5}\.[0-9]+$)/
                        },
                        onlyEdit: true
                    },
                    satPolarization: {
                        value: '',
                        onlyEdit: true
                    },
                    satModulation: {
                        value: '',
                        onlyEdit: true
                    },
                    satTransponderChannel: {
                        value: '',
                        onlyEdit: true
                    },
                    satService: {
                        value: '',
                        onlyEdit: true
                    },
                    satSymbolRate: {
                        value: '',
                        validator: {
                            regex: /^\d(\d)?$|^\d(\d)?\.\d+$/
                        },
                        onlyEdit: true
                    },
                    satFec: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryption: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryptionBissCodeShared: {
                        value: '',
                        onlyEdit: true
                    },
                    satEncryptionByCountry: {
                        value: [],
                        onlyEdit: true
                    },
                }
            }
        },
        /**
         * Emit a action button event.
         * @param eventName string
         * @param object row
         */
        emitButtonEvent(eventName) {
            const context = this
            switch (eventName) {
                case 'clone':
                    context.$router.push({name: 'feed.create', query: {destination: 'feeds.view', view: context.$route.params.view, from: context.$route.query.from, to: context.$route.query.to, clone: context.feed.options.id}})
                    context.formData.deliveryTypeData.satSatellite = ''
                    break
                case 'cloneProd':
                    context.cloneProductionFromFeed(context.feed.options.id)
                    break
                case 'delete':
                    context.deleteItem(context.feed.options.id)
                    break
                case 'template':
                    context.saveFeedAsTemplate()
                    break
                case 'apply':
                    context.openApplyTemplateForm()
                    break
            }
        },
        buttonIsPresent(button, byPass = false) {
            //return this.tableData.options.actions.indexOf(button) > -1
        },
        getButtonClasses(button) {
            let classes = []
            switch (button) {
                case 'clone':
                    classes.push('btn-info')
                    break
                case 'cloneProd':
                    classes.push('btn-warning')
                    break
                case 'delete':
                    classes.push('btn-danger')
                    break
                case 'template':
                    classes.push('btn-warning btn-warning-template')
                    break
                case 'apply':
                    classes.push('btn-special')
                    break
            }
            return classes
        },
        getButtonTitle(button, byPass = false) {
            if (button === 'cloneProd') { return 'Clone to Production' }
            return button
        },
        getButtonLabel(button) {
            switch (button) {
                case 'clone':
                    return ''
                case 'cloneProd':
                    return ''
                case 'delete':
                    return ''
                case 'apply':
                    return ''
            }
        },
        getButtonIcon(button){
            switch (button) {
                case 'clone':
                    return 'copy'
                case 'cloneProd':
                    return 'copy'
                case 'delete':
                    return 'trash'
                case 'template':
                    return 'file-import'
                case 'apply':
                    return 'file-export'
            }
        },
        /**
         * Check if the given button should be visible or not for the given row.
         * @param string button The button name, ex "delete".
         * @param object row
         * @return boolean
         */
        buttonIsVisible(button) {
            return false
        },
        userHasAccess(button) {
            //create read update delete
            let _access = false
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete', 'Feeds')
                    break
                case 'clone':
                    _access = this.$ability.can('create', 'Feeds')
                    break
                case 'cloneProd':
                    _access = this.$ability.can('create', 'Productions')
                    break
                case 'template':
                    _access = this.$ability.can('create_feed_templates', 'Feeds') && !this.isTemplateBased && !this.isClone
                    break
                case 'apply':
                    _access = this.$ability.can('view_feed_templates', 'Feeds') && this.$ability.can('update', 'Feeds') && !this.isTemplateBased && !this.isClone
                    break
            }
            return _access
        },

        handleFrqInputPast(clipBoardValue) {
            if(!isNaN(parseInt(clipBoardValue))) {
                this.formData.deliveryTypeData.satFrq.value = clipBoardValue
            }
            else {

            
                let frqExp = /\b\d{2}([,.])?\d{3}[,.][0-9]+\b/g
                //let clipBoardValue = event.clipboardData.getData('text')
                let frqValue = clipBoardValue.match(frqExp)
                if(frqValue && frqValue.length > 0) {
                    let val = frqValue[0]
                    if(val.search(/^\d{2}\,\d{3}\.\d+$/g) !== -1) {
                        val = val.replace(",", "")
                    }

                    if(val.search(/^\d{2}\.\d{3}\,\d+$/g) !== -1) {
                        val = val.replace(".", "")
                    }

                    if(val.search(/^\d{5}\,\d+$/) !== -1) {
                        val = val.replace(",", ".")
                    }

                    this.formData.deliveryTypeData.satFrq.value = val
                }
                else {
                    this.formData.deliveryTypeData.satFrq.value = ''
                }
            }
            
        },
        handleSymbolRatePast(clipBoardValue) {
            let smbExp = /\b\d(\d)?[,.][0-9]+\b/g
            //let clipBoardValue = event.clipboardData.getData('text')
            let smbValue = clipBoardValue.match(smbExp)
            if(smbValue && smbValue.length > 0) {
                let val = smbValue[0]
                val = val.replace(",", ".")
                this.formData.deliveryTypeData.satSymbolRate.value = val
            }
            else {
                this.formData.deliveryTypeData.satSymbolRate.value = ''
            }
        },
        saveFeedAsTemplate() {
            const context = this
            context.saveTemplateForm.show = true
            context.saveTemplateForm.loading = true
            context.saveTemplateForm.template = Object.assign({}, context.template)
            // context.saveTemplateForm.template.user.value = {
            //     id: app.$data.user.get.id
            // }
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, type: 'feed'}, true)
                .then(response => {
                    console.log(context.$store.state.data.productionTemplate)
                    context.saveTemplateForm.loading = false
                })
                .catch(error => {
                    if (error.response.status != 404) {
                        context.$error.set(error)
                        
                    }

                    context.saveTemplateForm.loading = false
                })
        },

        saveTemplateAs() {
            const context = this
            let id = parseInt(context.saveTemplateForm.id.value)
            context.$loader.add('productionTemplateEdit_submit')
            const productionTemplate = new ProductionTemplate(id)
            Lazy(context.saveTemplateForm.template)
                .each(function(v, k) {
                    switch (k) {
                        case 'user':
                            let user = {
                                id: app.$data.user.get.id
                            }
                            productionTemplate.set(k, user)
                            break
                        case 'title':
                            let value = ''
                            if(id === 0) {
                                value = context.saveTemplateForm.name.value
                            }
                            else {
                                let currentTemplate = context.templates.find(i => i.id === id)
                                if(currentTemplate) value = currentTemplate.title
                            }
                            productionTemplate.set(k, value)
                            break
                        case 'data':
                            const data = {}
                            Lazy(v)
                                .each(function(v2, k2) {
                                    // if(k2 === 'equipment') {
                                    //     data[k2] = context.formData[k2].value.map(e => {
                                    //         return {
                                    //             elementType: e.elementType,
                                    //             equipmentId: e.id,
                                    //             type: e.type,
                                    //             equipmentType: e.type,
                                    //             id: e.equipmentUsage.id,
                                    //             equipmentUsage: {
                                    //                 isNotUpdatePeriod: e.equipmentUsage.isNotUpdatePeriod,
                                    //                 isPrimary: e.equipmentUsage.isPrimary
                                    //             }
                                    //         }
                                    //     })
                                    // }
                                    if(k2 === 'mcr') {
                                        let mcrs = []
                                        context.formData[k2].value.forEach(i => mcrs.push(i.code))
                                        data[k2] = mcrs
                                    }
                                    else if(k2 === 'mcrExternal') {
                                        let exts = []
                                        if(context.formData[k2].value && context.formData[k2].value.length > 0) {
                                            context.formData[k2].value.forEach(i => exts.push(i.id))
                                        }
                                        data[k2] = exts.join(',')
                                    }
                                    else if(k2 === 'deliveryTypeData') {
                                        let deliveryData = {}
                                        Lazy(v2)
                                        .each(function(v3, k3) {
                                            if(k3 === 'satEncryptionByCountry') {
                                                if(context.isBissCode) {
                                                    let enc = []
                                                    if(context.formData.deliveryTypeData[k3].value && context.formData.deliveryTypeData[k3].value.length > 0) {
                                                        context.formData.deliveryTypeData[k3].value.forEach(i => enc.push({
                                                            country: i.country.code,
                                                            value: i.value,
                                                            valueType: 'encryption'
                                                        }))
                                                    }
                                                    deliveryData[k3] = enc
                                                }
                                            }
                                            else {
                                                deliveryData[k3] = context.formData.deliveryTypeData[k3].value
                                            }
                                            
                                        })
                                        data[k2] = deliveryData
                                    }
                                    else if(k2 === 'localNotes') {
                                        let locNotes = []
                                        if(context.formData[k2].value && context.formData[k2].value.length > 0) {
                                            context.formData[k2].value.forEach(i => locNotes.push({
                                                valueType: i.valueType,
                                                country: i.country,
                                                value: i.value
                                            }))
                                        }
                                        data[k2] = locNotes
                                    }
                                    else if (k2 === 'comments') {
                                        data[k2] = context.formData.comments.value
                                    }
                                    else {
                                        data[k2] = context.formData[k2].value
                                    }
                                    
                                }) 
                            productionTemplate.set(k, data)
                            break
                        case 'itemType':
                            productionTemplate.set(k, 'feed')
                            break
                        case 'filterType':
                            productionTemplate.set(k, context.formData.deliveryType.value)
                            break
                        case 'filterLeague':
                            productionTemplate.set(k, context.formData.league.value)
                            break
                        default:
                            productionTemplate.set(k, v.value)
                            break
                    }

                })

                if(id === 0) {
                    productionTemplate.store()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Feed template ${response.data.id} has been created!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the feed.')
                            context.resetSaveTemplateForms()
                        })
                }
                else {
                    productionTemplate.save()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Feed template ${response.data.id} has been updated!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the feed.')
                            context.resetSaveTemplateForms()
                        })
                }
        },
        resetSaveTemplateForms() {
            this.saveTemplateForm.show = false
            this.saveTemplateForm.loading = false
            this.saveTemplateForm.template = null
            this.saveTemplateForm.id.value = 0
            this.saveTemplateForm.name.value = ''
        },
        handlerHouseNumberChange(newValue) {
            const context = this
            if(context.isEditForm && context.feed.get.houseNumber) {
                let hNumber = context.feed.get.houseNumber
                
                if (hNumber) {
                    //json string
                    if (isJsonSring(hNumber)) {
                        hNumber = JSON.parse(hNumber)
                        let newNO = newValue['no'] !== null && newValue['no'] !== '' ? context.isForFork(newValue['no'], 'no') : false
                        let newSE = newValue['se'] !== null && newValue['se'] !== '' ? context.isForFork(newValue['se'], 'se') : false
                        let currentNOFork = _.find(context.formData.placeholders.value, p => p.country == 'no')
                        let currentSEFork = _.find(context.formData.placeholders.value, p => p.country == 'se')

                        if(newNO) {
                            if(currentSEFork !== undefined) {
                                context.updatePlaceholderCountry(currentSEFork, 'no')
                            } else if(currentNOFork !== undefined) {
                                context.updatePlaceholderDeleteStatus(false, 'no')
                            } else if(currentNOFork === undefined) {
                                context.createLocalDefaultPlaceholder('no')
                            }

                        }
                        else if(!newNO && newSE) {
                            if(currentNOFork !== undefined) {
                                context.updatePlaceholderCountry(currentNOFork, 'se')
                            } else if(currentSEFork !== undefined) {
                                context.updatePlaceholderDeleteStatus(false, 'se')
                            } else if(currentSEFork === undefined) {
                                context.createLocalDefaultPlaceholder('se')
                            }

                        } 
                        else if(!newNO && !newSE) {
                            if(currentNOFork !== undefined) {
                                if(currentNOFork.local) {
                                    let deleteNoForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === 'no')
                                    if(deleteNoForkCountry > -1) {
                                        context.formData.placeholders.value.splice(deleteNoForkCountry, 1)
                                    }
                                }
                                else {
                                    context.updatePlaceholderDeleteStatus(true, 'no')
                                }
                                
                            } else if (currentSEFork !== undefined) {
                                if(currentSEFork.local) {
                                    let deleteSeForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === 'se')
                                    if(deleteSeForkCountry > -1) {
                                        context.formData.placeholders.value.splice(deleteSeForkCountry, 1)
                                    }
                                }
                                else {
                                    context.updatePlaceholderDeleteStatus(true, 'se')
                                }
                                
                            }
                        }

                        Lazy(newValue)
                            .each(function (v2, k2) {
                                if(k2 !== 'se' && k2 !== 'no') {
                                    if (hNumber[k2] !== v2) {
                                        let prevFork = hNumber[k2] !== null && hNumber[k2] !== '' ? context.isForFork(hNumber[k2], k2) : false
                                        let currentFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false
                                        
                                        if(prevFork && !currentFork) {
                                            //need to remove placeholder or mark for delition
                                            //ask confirmation if it was previously sent to fork
                                            context.updatePlaceholderDeleteStatus(true, k2)
                                        }
    
                                        if(!prevFork && currentFork) {
                                            //need to create placeholder with mark create
                                            //ask confirmation about submit to fork
                                            context.createLocalDefaultPlaceholder(k2)
                                        }
    
                                        if(!prevFork && !currentFork && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                            let deleteForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                            if(deleteForkCountry > -1) {
                                                context.formData.placeholders.value.splice(deleteForkCountry, 1)
                                            }
                                        }
    
                                        if(prevFork && currentFork) {
                                            //cancel delition if it was marked for deletion
                                            context.updatePlaceholderDeleteStatus(false, k2)
                                        }
                                    }
                                    else if(hNumber[k2] === v2 && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                        let tmpForkCountry = _.find(context.formData.placeholders.value, i => i.country === k2)
                                        if(tmpForkCountry !== undefined) {
                                            if(tmpForkCountry.local) {
                                                context.updatePlaceholderDeleteStatus(false, k2)
                                            }
                                            else {
                                                let tempForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                                if(tempForkCountry > -1 && context.formData.placeholders.value[tempForkCountry].local) {
                                                    context.formData.placeholders.value.splice(tempForkCountry, 1)
                                                }
                                            }
                                        }
                                    }
                                    else if(context.formData.placeholders.initialHasRec) {
                                        let initialFork = hNumber[k2] !== null && hNumber[k2] !== '' ? context.isForFork(hNumber[k2], k2) : false
                                        if(initialFork) {
                                            if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                                let isCountryInList = _.find(context.formData.placeholders.value, i => i.country === k2)
                                                if(isCountryInList === undefined) {
                                                    context.formData.placeholders.value.push({
                                                        country: k2,
                                                        placeholders: [],
                                                        local: false
                                                    })
                                                }
                                            }
                                            else {
                                                context.formData.placeholders.value.push({
                                                    country: k2,
                                                    placeholders: [],
                                                    local: false
                                                })
                                            }
                                        }
                                    }
                                }
                            })

                            context.formData.placeholders.showPlaceholders = context.formData.placeholders.initialHasRec || context.formData.placeholders.value.length > 0
                    }
                }
            } else {
                Lazy(newValue)
                    .each(function (v2, k2) {
                        let ignoreSweden = false
                        if(k2 == 'se' && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                            let noFork = _.find(context.formData.placeholders.value, i => i.country == 'no')
                            if(noFork !== undefined) {
                                ignoreSweden = true
                            }
                        }
                        let ignoreNorway = false
                        if(k2 == 'no' && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                            let seForkCountry = _.find(context.formData.placeholders.value, i => i.country == 'se')
                            let noForkCountry = _.find(context.formData.placeholders.value, i => i.country == k2)
                            let currentSeFork = newValue['se'] !== null && newValue['se'] !== '' ? context.isForFork(newValue['se'], 'se') : false

                            if(seForkCountry !== undefined || noForkCountry !== undefined) {
                                ignoreNorway = true
                                let currentNoFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false

                                if(currentNoFork) {
                                    if(seForkCountry !== undefined) {
                                        context.updatePlaceholderCountry(seForkCountry, k2)
                                    }
                                    else {
                                        context.createLocalDefaultPlaceholder(k2)
                                    }
                                    
                                }

                                if(!currentNoFork && noForkCountry !== undefined) {
                                    if(currentSeFork) {
                                        context.updatePlaceholderCountry(noForkCountry, 'se')
                                    }
                                    else {
                                        let deleteNoForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                        if(deleteNoForkCountry > -1) {
                                            context.formData.placeholders.value.splice(deleteNoForkCountry, 1)
                                        }
                                    }
                                    
                                }
                            }
                        }
                        if(!ignoreNorway && !ignoreSweden ) {
                            let currentFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false
                            if(currentFork) {
                                context.createLocalDefaultPlaceholder(k2)
                            }
                            else {
                                let deleteForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                if(deleteForkCountry > -1) {
                                    context.formData.placeholders.value.splice(deleteForkCountry, 1)
                                }
                            }
                        }
                    })

                    context.formData.placeholders.showPlaceholders = context.formData.placeholders.value.length > 0
            }
        },
        /**
         * Apply the selected template to the current production.
         */
         applyTemplate() {
            console.log("Apply template");
            const context = this
            if (!context.productionTemplate) return
            Lazy(context.productionTemplate.get.data)
            .each((v, k) => {
                switch (k) {
                    case 'league':
                        context.feed.set('league', context.productionTemplate.get.data.league)
                        break
                    case 'mcrExternal':
                        context.feed.set('mcrExternal',context.productionTemplate.get.data.mcrExternal)
                    default:
                        context.feed.set(k, v)
                }
            })
            Vue.nextTick(() => {
                context.setFormValues()
            })
        },
        openApplyTemplateForm() {
            this.$loader.add('createFromTemplateLoading')
            this.applyTemplateForm.show = true
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: 'feed'}, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Feed Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },
        templateDeliveryTypes() {
            // let tmpls = this.$store.state.data.productionTemplate && this.$store.state.data.productionTemplate.length > 0 ? 
            //             this.$store.state.data.productionTemplate.map(i => i.data.deliveryType ? i.data.deliveryType : 0) : []
            // tmpls = _.uniq(tmpls)
            return Lazy(this.deliveryTypeEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.deliveryType == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        templateLeagues() {
            return Lazy(this.leagueEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.league == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        applyTemplateToFeed(templateId) {
            this.applyTemplateToItem(templateId)
                .then(response => {
                    const context = this
                    if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] === context.id) {
                        context.$alert.set(`Feed has been updated with template!`, 'success', 3)
                        let updatedFeed = JSON.parse(response.data.applied[0])
                        // SET Production with the new values
                        Lazy(updatedFeed)
                            .each((v, k) => {
                                context.feed.set(k, v)
                            })
                        Vue.nextTick(() => {
                            this.setFormValues()
                        })
                    }
                    else {
                        this.$error.set('Something went wromg with applying template.')
                    }
                })
                .catch(error => {
                    this.$error.set(error, 'Something went wromg with applying template.')
                })
        }
    }

}
