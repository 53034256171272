<script>
import {app} from 'app'
import CrewView from 'components/crewview/CrewView'
import BookLoader from 'components/common/loader/BookLoader'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookTable from 'components/common/BookTable'
import BookCrewFilter from 'components/crewview/BookCrewFilter'
import BookModal from 'components/common/BookModal'
import Entity from 'components/entity/Entity'
import BookCrew from 'components/crewview/BookCrew'
import BookCrewProductions from 'components/crewview/BookCrewProductions'
import BookCrewProblematic from 'components/crewview/BookCrewProblematic'
import BookSelectbox from "../common/BookSelectbox";
import User from 'components/user/User'
import BookDatetime from 'components/common/BookDatetime'
import Production from '../production/Production'
import EntityItem from 'components/entity/EntityItem'
import JsonExcel from 'vue-json-excel'
import Datetime from 'components/common/helpers/Datetime'
import ProductionCrew from 'components/production/ProductionCrew/ProductionCrew'
import BookNotification from 'components/notification/BookNotification'

export default {
    socket: {
        'private:crew': {
            '.crewItem.created': function(payload, context) {
                if(payload.data) {
                    app.$emit('crewCreated', payload.data)
                }
               
            },
            '.crewItem.updated': function(payload, context) {
                if(payload.data) {
                    app.$emit('crewUpdated', payload.data)
                }
            },
            '.crewItem.deleted': function(payload, context) {
                if(payload.data) {
                    app.$emit('crewDeleted', payload.data)
                }
            },
        }
    },
    components: {
        BookLoader,
        BookDateNavigation,
        BookTable,
        BookCrewFilter,
        BookModal,
        BookCrew,
        BookSelectbox,
        BookDatetime,
        JsonExcel,
        BookCrewProductions,
        BookCrewProblematic,
        BookNotification
    },
    data() {
        return {
            child: {
                item: 'crewview',
                items: 'crewview',
                settings: 'crew',
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    channelEntity: 'channel_entity_id',
                    runTypeEntity: 'runtype_entity_id',
                    skillEntity: 'skill_entity_id',
                    bookingTypesEntity: 'bookingtypes_entity_id',
                    planTypeEntity: 'plantype_entity_id',
                }
            },
            crewviewData: null,
            productionviewData: null,
            problemviewData: null,
            users: null,
            loaded: false,
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text',
                    singlePage: true,
                    multiplePage: false,
                    problemPage: false,
                    side: 'left'
                },
                countries: {
                    label: 'Countries',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'left'
                },
                skills: {
                    label: 'Skills',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'left'
                },
                users : {
                    label: 'Users',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'left'
                },
                productionProblems: {
                    label: 'Status',
                    type: 'radio',
                    items: [
                        {id: 'empty', label: 'Empty'},
                        {id: 'option', label: 'Option'},
                        {id: 'requested', label: 'Requested'},
                        {id: 'declined', label: 'Declined'},
                        //{id: 'conflict', label: 'Conflict'}
                    ],
                    singlePage: false,
                    multiplePage: false,
                    problemPage: true,
                    side: 'right',
                    value: ''
                },
                productionSearch: {
                    label: 'Search',
                    type: 'text',
                    singlePage: false,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },
                productionCountries: {
                    label: 'Event countries',
                    rightLabel: 'Countries',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },
                runTypes: {
                    label: 'Run Types',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },
                sports: {
                    label: 'Sports',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },
                leagues: {
                    label: 'Leagues',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },
                channels: {
                    label: 'Channels',
                    items: [],
                    singlePage: true,
                    multiplePage: true,
                    problemPage: true,
                    side: 'right'
                },  
            },
            hideAssignments: false,
            showOnlyCheckedUsers: false,
            showOnlyCheckedProductions: false,
            showOnlyCheckedProblemProductions: false,
            filterReady: false,
            previousFilteredSkills: null,
            currentFilteredSkills: null,
            filters: {},
            retrievingCrew: false,
            retrievingAvailableCrew: false,
            crewItemForm: {
                show: false,
                loaded: false,
                formData: this.defaultData(),
                assignment: null,
                initialization: false
            },
            processing: false,
            dateRange: 0, // 0 - 7 days, 1 - 14 days, 2 - 31 days, 3 - custom but no more than 31 days
            defaultRangeInterval: 6,
            productionRangeStart: null,
            productionRangeEnd: null,
            chosenProductions: [],
            chosenProblemProductions: [],
            chosenUsers: [],
            scrollHeight: 0,
            waitingUpdates: {
                updates: false,
                total: 0,
                create: [],
                update: [],
                delete: [],
                originalAssignments: [],
                page: {},
                json: [],
                pageItems: {}
            },
            setCrewStatus: {
                show: false,
                showCheckedOnly: false,
                apply: '',
                items: []
            },
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
                groupMessage: '',
                groupBy: '',
                defaultEmailMessage: '',
                defaultSubject: '',
                messageTemplate: '',
                isCrewView: false
            },
        }
    },
    
    beforeCreate() {
        const context = this
        const dateFrom = context.$route.query.from ? context.$route.query.from : moment().format('YYYY-MM-DD')
        context.defaultRangeInterval = 6
        let to = moment(dateFrom).add(6, 'd')
        to = moment(to).format('YYYY-MM-DD')
        const routeTo = {
            query: {
                from: dateFrom,
                to: to,
            }
        }
        context.productionRangeStart = dateFrom
        context.productionRangeEnd = to
        context.$router.replace(routeTo)
    },
    created() {
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    mounted() {
        this.onResize()
        const context = this
        context.loadEntities(context.child.entities)
            .then(response => {
                if(context.isSplitPage) {
                    context.productionRangeStart = context.$route.query.from
                    context.productionRangeEnd = context.$route.query.to
                    return Promise.all([context.loadData(), context.loadProductions()])
                }
                else if(context.isProblemPage) {
                    context.productionRangeStart = context.$route.query.from
                    context.productionRangeEnd = context.$route.query.to
                    return Promise.all([context.loadData(), context.loadProblemProductions()])
                }
                else {
                    return context.loadData()
                }
            })
            .then(response => {
                context.createFilters()
                context.setLoaded()
                context.setCrewViewData()
                if(context.isSplitPage) {
                    context.setProductionViewData()
                }
                if(context.isProblemPage) {
                    context.setProblemViewData()
                }
            })
            .catch(error=> {
                context.createFilters()
                context.setLoaded()
            })

        app.$on('crewCreated', (data) => {
            this.addCrew(data)
        })
        app.$on('crewUpdated', (data) => {
            this.updateCrew(data)
        })
        app.$on('crewDeleted', (data) => {
            this.deleteCrew(data)
        })
    },
    watch: {
        '$route': function(to, from) {
            if (!this.loaded || ((from.query.from == to.query.from) && (from.query.to == to.query.to))) {
                if(to.params.view == 'multiple' && from.params.view != 'multiple'/*from.params.view == 'single'*/) {
                    this.resetChosenProductionsAndUsers()
                    this.resetChosenProblemProductionsAndUsers()

                    if(this.productionRangeStart == null || this.productionRangeStart == '') this.productionRangeStart = to.query.from
                    if(this.productionRangeEnd == null || this.productionRangeEnd == '') this.productionRangeEnd = moment(this.productionRangeStart).add(6, 'd').format('YYYY-MM-DD')

                    if(this.setCrewStatus.show) this.toggleCrewStatus(null, false)

                    Lazy(this.crewviewData)
                        .each(u => {
                            Lazy(u.daily_assingments)
                            .each(d => {
                                Lazy(d.assingments)
                                .each(a => {
                                    if(!a.visible) a.visible = true
                                })
                            })
                        })
                        
                    if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') && this.filters.search.value !== null && this.filters.search.value !== '') {
                        this.availableFilters.productionSearch.value = this.filters.search.value
                        this.filters.search.value = ''
                        this.availableFilters.search.value = ''
                    }

                    this.setLoaded(false)
                    this.loadProductions()
                    .then(response => {
                        this.dateRange = 0
                        this.setLoaded()
                        this.setProductionViewData()
                    })
                    .catch(error => {
                        this.setLoaded()
                    })
                }

                if(to.params.view == 'problem' && from.params.view != 'problem'/*from.params.view == 'single'*/) {
                    this.resetChosenProductionsAndUsers()
                    this.resetChosenProblemProductionsAndUsers()
                    
                    if(this.productionRangeStart == null || this.productionRangeStart == '') this.productionRangeStart = to.query.from
                    if(this.productionRangeEnd == null || this.productionRangeEnd == '') this.productionRangeEnd = moment(this.productionRangeStart).add(6, 'd').format('YYYY-MM-DD')

                    if(this.setCrewStatus.show) this.toggleCrewStatus(null, false)

                    Lazy(this.crewviewData)
                        .each(u => {
                            Lazy(u.daily_assingments)
                            .each(d => {
                                Lazy(d.assingments)
                                .each(a => {
                                    if(!a.visible) a.visible = true
                                })
                            })
                        })
                        
                    if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') && this.filters.search.value !== null && this.filters.search.value !== '') {
                        this.availableFilters.productionSearch.value = this.filters.search.value
                        this.filters.search.value = ''
                        this.availableFilters.search.value = ''
                    }

                    // if(this.filters.hasOwnProperty('productionProblems') && this.filters.productionProblems.hasOwnProperty('value') && this.filters.search.value !== null && this.filters.productionProblems.value !== '') {
                    //     this.availableFilters.productionSearch.value = this.filters.search.value
                    //     this.filters.search.value = ''
                    //     this.availableFilters.search.value = ''
                    // }

                    this.setLoaded(false)
                    this.loadProblemProductions()
                    .then(response => {
                        this.dateRange = 0
                        this.setLoaded()
                        this.setProblemViewData()
                    })
                    .catch(error => {
                        this.setLoaded()
                    })
                }

                if(to.params.view == 'single' && from.params.view != 'single'/*from.params.view == 'multiple'*/) {
                    this.resetChosenProductionsAndUsers()
                    this.resetChosenProblemProductionsAndUsers()
                    if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') && 
                       this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '') {
                        this.availableFilters.search.value = this.filters.productionSearch.value
                        this.filters.productionSearch.value = ''
                        this.availableFilters.productionSearch.value = ''
                    }
                    Lazy(this.crewviewData)
                        .each(u => {
                            Lazy(u.daily_assingments)
                            .each(d => {
                                Lazy(d.assingments)
                                .each(a => {
                                    let isVisible = true
                                    if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') && 
                                       this.filters.search.value !== null && this.filters.search.value !== '' &&
                                       !a.productionTitle.toLowerCase().includes(this.filters.search.value.toLowerCase())) {
                                       isVisible = false
                                    }

                                    if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, a.country)) {
                                        isVisible = false
                                    }

                                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, a.runType)) {
                                        isVisible = false
                                    }

                                    if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, a.sport)) {
                                        isVisible = false
                                    }

                                    if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, a.league)) {
                                        isVisible = false
                                    }

                                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, a.channel)) {
                                        isVisible = false
                                    }

                                    a.visible = isVisible
                                })
                            })
                        })
                }
                return
            }

            if((to.params.view == 'multiple' || to.params.view == 'problem') && from.params.view == 'single') {
                this.resetChosenProductionsAndUsers()
                this.resetChosenProblemProductionsAndUsers()
                if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') &&
                   this.filters.search.value !== null && this.filters.search.value !== '') {
                    this.availableFilters.productionSearch.value = this.filters.search.value
                    this.filters.search.value = ''
                    this.availableFilters.search.value = ''
                }
            }

            if(to.params.view == 'single' && (from.params.view == 'multiple'|| from.params.view == 'problem')) {
                this.resetChosenProductionsAndUsers()
                this.resetChosenProblemProductionsAndUsers()
                if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') &&
                   this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '') {
                    this.availableFilters.search.value = this.filters.productionSearch.value
                    this.filters.productionSearch.value = ''
                    this.availableFilters.productionSearch.value = ''
                }
            }

            
            
            if(this.setCrewStatus.show) this.toggleCrewStatus(null, false)
            this.setLoaded(false)
            this.loadData()
                .then(response => {
                    //this.crew = response.data
                    //this.createFilters()
                    this.setLoaded()
                    this.setCrewViewData()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) this.$error.set(error)
                    //this.notifications = null
                    //this.createFilters()
                    this.setLoaded()
                    //this.filterReady = true
                    //this.noResult = true
                })
        },
        'filters.countries': {
            handler(newValue, oldValue) {
                if((newValue !== null && oldValue === null) || (newValue === null && oldValue !== null) ||
                (newValue !== null && oldValue !== null && (_.xor(newValue, oldValue)).length > 0)) {
                    let currentCountries = []
                    if(newValue === null || newValue.length ===0) {
                        currentCountries = this.countries.map(i => i.code)
                    }
                    else {
                        currentCountries = newValue
                    }
                    let skls = _.map(_.filter(this.filteredSkills, i => _.includes(currentCountries, i.country)), k => {
                        return {
                            id: k.id,
                            label: k.label
                        }
                    })
                    
                    if(this.filters.hasOwnProperty('skills') && this.filters.skills.length > 0) {
                        let newSkillsFilters = []
                        Lazy(this.filters.skills)
                        .each(v => {
                            let currentSkill = _.find(skls, j => j.id == v)
                            if(currentSkill !== undefined) {
                                Vue.set(currentSkill, 'selected', true)
                                newSkillsFilters.push(v)
                            }
                            else {
                                
                            }
                        })

                        if((_.xor(newSkillsFilters, this.filters.skills)).length > 0) this.filters.skills = newSkillsFilters
                    }

                   
                    this.availableFilters.skills.items = skls
                    
                }
            },
            deep: true
        },
        'filters.productionCountries' : {
            handler(newValue, oldValue) {
                if((newValue !== null && oldValue === null) || (newValue === null && oldValue !== null) ||
                (newValue !== null && oldValue !== null && (_.xor(newValue, oldValue)).length > 0)) {
                    let currentCountries = []
                    if(newValue === null || newValue.length ===0) {
                        currentCountries = this.countries.map(i => i.code)
                    }
                    else {
                        currentCountries = newValue
                    }


                    let runTypes = _.map(_.filter(this.filteredRunTypes, i => _.includes(currentCountries, i.country)), k => {
                        return {
                            id: k.id,
                            label: k.label
                        }
                    })
                    
                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0) {
                        let newRunTypesFilters = []
                        Lazy(this.filters.runTypes)
                        .each(v => {
                            let currentRunType = _.find(runTypes, j => j.id == v)
                            if(currentRunType !== undefined) {
                                Vue.set(currentRunType, 'selected', true)
                                newRunTypesFilters.push(v)
                            }
                            else {
                                
                            }
                        })

                        if((_.xor(newRunTypesFilters, this.filters.runTypes)).length > 0) this.filters.runTypes = newRunTypesFilters
                    }

                   
                    this.availableFilters.runTypes.items = runTypes

                    let channels = _.map(_.filter(this.filteredChannels, i => _.includes(currentCountries, i.country)), k => {
                        return {
                            id: k.id,
                            label: k.label,
                            country: k.country.toUpperCase()
                        }
                    })
                    
                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0) {
                        let newChannelsFilters = []
                        Lazy(this.filters.channels)
                        .each(v => {
                            let currentChannel = _.find(channels, j => j.id == v)
                            if(currentChannel !== undefined) {
                                Vue.set(currentChannel, 'selected', true)
                                newChannelsFilters.push(v)
                            }
                            else {
                                
                            }
                        })

                        if((_.xor(newChannelsFilters, this.filters.channels)).length > 0) this.filters.channels = newChannelsFilters
                    }

                   
                    this.availableFilters.channels.items = channels
                }
            },
            deep: true
        },
        'filters.skills':  {
            handler(newValue, oldValue) {
                if((newValue !== null && oldValue === null) || (newValue === null && oldValue !== null) ||
                   (newValue !== null && oldValue !== null && (_.xor(newValue, oldValue)).length > 0)) {
                    this.setLoaded(false)
                    this.loadData()
                        .then(response => {
                            this.setLoaded()
                            this.setCrewViewData()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            },
            deep: true
        },
        'filters.productionProblems.value': function(newValue, oldValue){
            if(newValue !== oldValue) {
                this.setLoaded(false)
                this.loadProblemProductions()
                    .then(response => {
                        this.setLoaded()
                        this.setProblemViewData()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        'filters.users':  {
            handler(newValue, oldValue) {
                    if((newValue !== null && oldValue === null) || (newValue === null && oldValue !== null) ||
                    (newValue !== null && oldValue !== null && (_.xor(newValue, oldValue)).length > 0)) {
                        Lazy(this.crewviewData)
                        .each(v => {
                            if(newValue === null || (newValue && newValue.length == 0)) {
                                v.visible = true
                            }
                            else if(newValue && newValue.length > 0 && _.includes(newValue, v.user_id)) {
                                v.visible = true
                            }
                            else {
                                v.visible = false
                                //v.checked = false
                            }
                        })
                    }
                },
            deep: true
        },
        hideAssignments:  function(hide){
            const context = this
            Lazy(context.crewviewData)
                .each((u, k) => {
                    Lazy(u.daily_assingments)
                        .each((a, k1) => {
                            Lazy(a.assingments)
                                .each((d, k2) => {
                                    if(hide) {
                                        let isInListSkill = _.includes(context.filters.skills, d.skill)

                                        d.hide = !isInListSkill
                                    }
                                    else {
                                        d.hide = false
                                    }
                                })
                        })
                })
        },
        chosenUsers: {
            handler(newValue) {
                if(newValue === null || (newValue !== null && newValue.length === 0)) {
                    if(this.showOnlyCheckedUsers) this.showOnlyCheckedUsers = false
                }
            },
            deep: true
            
        },
        chosenProductions: {
            handler(newValue) {
                if(newValue === null || (newValue !== null && newValue.length === 0)) {
                    if(this.showOnlyCheckedProductions) this.showOnlyCheckedProductions = false
                }
            },
            deep: true
            
        },
        chosenProblemProductions: {
            handler(newValue) {
                if(newValue === null || (newValue !== null && newValue.length === 0)) {
                    if(this.showOnlyCheckedProblemProductions) this.showOnlyCheckedProblemProductions = false
                }
            },
            deep: true
            
        },
        'crewItemForm.formData.production.value': function(newProduction, oldProduction) {
            if (!this.crewItemForm.loaded) return
            const context = this

            if(newProduction !== null && newProduction !== '') {
                if(newProduction !== oldProduction) {
                    if(context.crewItemForm.assignment.item && newProduction == context.crewItemForm.assignment.item.production) {
                        context.crewItemForm.formData.startPlan.value = context.crewItemForm.assignment.item.startPlan
                        context.crewItemForm.formData.endPlan.value = context.crewItemForm.assignment.item.endPlan

                        context.crewItemForm.formData.hideAssignmentData.value = false
                    }
                    else {
                        context.crewItemForm.formData.startPlan.value = context.getStartPlans[0].id
                        context.crewItemForm.formData.endPlan.value = context.getEndPlans[0].id

                        context.crewItemForm.formData.hideAssignmentData.value = false
                    }

                    // if(this.crewItemForm.formData.skill.value && !this.crewItemForm.initialization && !this.crewItemForm.formData.userId.value) this.getAvailableCrew()
                    
                }
                
            }
            else {
                context.crewItemForm.formData.hideAssignmentData.value = true
            }
            

        },
        
        'crewItemForm.formData.skill.value': function(newValue, oldValue) {
            if (!this.crewItemForm.loaded) return
            if (this.crewItemForm.formData.userId.value) return

            if(newValue !== null && newValue !== '' && newValue !== oldValue && !this.crewItemForm.formData.userId.value &&
               !this.crewItemForm.initialization && this.crewItemForm.assignment.item !== null && 
               this.crewItemForm.formData.production.value !== null && this.crewItemForm.formData.production.value !== '' &&
               this.crewItemForm.formData.startPlan.value !== null && this.crewItemForm.formData.startPlan.value !== '' && 
               this.crewItemForm.formData.startPlan.value !== 0 &&
               this.crewItemForm.formData.endPlan.value !== null && this.crewItemForm.formData.endPlan.value !== '' && 
               this.crewItemForm.formData.endPlan.value !== 0) {
                this.getAvailableCrew()
            }

        },
        'crewItemForm.formData.startPlan.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            // if user already selected, do not search for another member. WN
            if (this.crewItemForm.formData.userId.value) return
            if(newValue !== null && newValue !== '' && newValue !== oldValue && newValue !== 0 &&
               !this.crewItemForm.initialization && this.crewItemForm.assignment.item !== null && 
               this.crewItemForm.formData.production.value !== null && this.crewItemForm.formData.production.value !== '' &&
               this.crewItemForm.formData.endPlan.value !== null && this.crewItemForm.formData.endPlan.value !== '' && 
               this.crewItemForm.formData.endPlan.value !== 0) {
                this.getAvailableCrew()
            }
        },
        'crewItemForm.formData.endPlan.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            // if user already selected, do not search for another member. WN
            if (this.crewItemForm.formData.userId.value) return
            if(newValue !== null && newValue !== '' && newValue !== oldValue && newValue !== 0 &&
               !this.crewItemForm.initialization && this.crewItemForm.assignment.item !== null && 
               this.crewItemForm.formData.production.value !== null && this.crewItemForm.formData.production.value !== '' &&
               this.crewItemForm.formData.startPlan.value !== null && this.crewItemForm.formData.startPlan.value !== '' && 
               this.crewItemForm.formData.startPlan.value !== 0) {
                this.getAvailableCrew()
            }
        },
        'crewItemForm.formData.userCountry.value': function(newValue, oldValue) {
            if (!this.crewItemForm.loaded) return
            if(newValue !== oldValue && !this.crewItemForm.initialization && this.crewItemForm.assignment.item !== null) {
                this.crewItemForm.formData.userId.value = ''
                this.crewItemForm.formData.skill.value = ''
            }
            
            //if(this.crewItemForm.formData.skill.value) this.getAvailableCrew()
        },
        dateRange: function (newRange, oldRange) {
            const context = this
            if(newRange != oldRange) {
                const dateFrom = context.$route.query.from ? context.$route.query.from : moment().format('YYYY-MM-DD')
                let to
                let routeTo = {
                    query: {

                    }
                }
                switch(newRange) {
                    case 0:
                        context.defaultRangeInterval = 6
                        to = moment(dateFrom).add(6, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to
                            
                        context.$router.replace(routeTo)
                        break
                    case 1:
                        context.defaultRangeInterval = 13
                        to = moment(dateFrom).add(13, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to

                        context.$router.replace(routeTo)
                        break
                    case 2:
                        context.defaultRangeInterval = 30
                        to = moment(dateFrom).add(30, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to

                        context.$router.replace(routeTo)
                        break
                    default:
                        context.defaultRangeInterval = 0
                        break
                }
            }
            
        }
    },
    computed: {
        isSinglePage() {
            return this.$route.params.view == 'single'
        },
        isSplitPage() {
            return this.$route.params.view == 'multiple'
        },
        isProblemPage() {
            return this.$route.params.view == 'problem'
        },
        setting() {
            let settings = this.child.settings ? this.child.settings : this.child.items
            return this.$settings.get[settings]
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        userCountries() {
            if(this.countries && this.countries.length > 0 && this.filters.hasOwnProperty('skills') && this.filters.skills.length > 0) {
                let output = []
                Lazy(this.filters.skills)
                .each(i => {
                    let skl = _.find(this.filteredSkills, j => j.id == i)
                    if(skl !== undefined) {
                        let cnt = _.find(this.countries, c => c.code == skl.country)
                        if(cnt !== undefined) {
                            let indx = _.findIndex(output, o => o.code == cnt.code)
                            if(indx === -1) output.push(cnt)
                        }
                    }
                })
            }
            return null
        },
        crewview() {
            const context = this
            if (!context.loaded || !this.$store.state.data.crewview) return []
            return this.$store.state.data.crewview
        },
        productions() {
           const context = this
            if (context.retrievingCrew || !this.$store.state.data.availableProductionsCrew) return []
            if(context.crewItemForm.formData.productionType.value !== '' || context.crewItemForm.formData.league.value !== '' || 
               context.crewItemForm.formData.itemCountry.value !== '') {
                    return _.filter(this.$store.state.data.availableProductionsCrew, i => {
                        if(context.crewItemForm.formData.productionType.value !== '') {
                           if(i.productionType !== context.crewItemForm.formData.productionType.value) return false
                        }
                        if(context.crewItemForm.formData.league.value !== '') {
                            if(i.league !== context.crewItemForm.formData.league.value) return false
                        }
                        if(context.crewItemForm.formData.itemCountry.value) {
                            if(i.country !== context.crewItemForm.formData.itemCountry.value) return false
                        }

                        return true
                    })
               }
            return this.$store.state.data.availableProductionsCrew
        },
        allProductions() {
            const context = this
            if (!context.loaded || !this.$store.state.data.production /* || !context.filterReady */) return []
            
            return this.$store.state.data.production//tmpProds
        },
        allProblemProductions() {
            const context = this
            if (!context.loaded || !this.$store.state.data.problemProductions /* || !context.filterReady */) return []
            
            return this.$store.state.data.problemProductions//tmpProds
        },
        allFilteredProductions() {
            // const context = this
            // let filtered = []
            // Lazy(this.productionviewData)
            // .each((v,k) => {
            //     // if(_.includes(context.chosenProductions, v.id.value)){
            //     //     if(!v.rowOptions.checked) v.rowOptions.checked = true
            //     // }
            //     // else if(v.rowOptions.checked) v.rowOptions.checked = false

            //     if((context.showOnlyCheckedProductions && v.rowOptions.checked) || (!context.showOnlyCheckedProductions && v.rowOptions.visible)) {
            //         filtered.push(v)
            //     }
            // })
            return _.filter(this.productionviewData, v => (this.showOnlyCheckedProductions && v.rowOptions.checked) || (!this.showOnlyCheckedProductions && v.rowOptions.visible))
            // return Lazy(this.productionviewData)
            //         .filter((v,k) => {
            //             return (this.showOnlyCheckedProductions && v.rowOptions.checked) || (!this.showOnlyCheckedProductions && v.rowOptions.visible)
            //         })
            //         .toArray()
        },
        allFilteredProblemProductions() {
            // const context = this
            // let filtered = []
            // Lazy(this.productionviewData)
            // .each((v,k) => {
            //     // if(_.includes(context.chosenProductions, v.id.value)){
            //     //     if(!v.rowOptions.checked) v.rowOptions.checked = true
            //     // }
            //     // else if(v.rowOptions.checked) v.rowOptions.checked = false

            //     if((context.showOnlyCheckedProductions && v.rowOptions.checked) || (!context.showOnlyCheckedProductions && v.rowOptions.visible)) {
            //         filtered.push(v)
            //     }
            // })
            return _.filter(this.problemviewData, v => /* (this.showOnlyCheckedProductions && v.rowOptions.checked) || */ (!this.showOnlyCheckedProductions && v.rowOptions.visible))
            // return Lazy(this.productionviewData)
            //         .filter((v,k) => {
            //             return (this.showOnlyCheckedProductions && v.rowOptions.checked) || (!this.showOnlyCheckedProductions && v.rowOptions.visible)
            //         })
            //         .toArray()
        },
        availableCrew() {
            return this.$store.state.data.availableCrew
        },
        filteredSkills() {
            return this.skillEntity && this.skillEntity.get.items ? this.skillEntity.get.items : []
        },
        filteredRunTypes() {
            return this.runTypeEntity && this.runTypeEntity.get.items ? this.runTypeEntity.get.items : []
        },
        filteredSports() {
            return this.sportEntity && this.sportEntity.get.items ? this.sportEntity.get.items : []
        },
        filteredLeagues() {
            return this.leagueEntity && this.leagueEntity.get.items ? this.leagueEntity.get.items : []
        },
        filteredChannels() {
            return this.channelEntity && this.channelEntity.get.items ? this.channelEntity.get.items : []
        },
        
        // filteredUsers() {
        //     if (!this.loaded || !this.users) return []
        //     let usrs = this.users.map(u => {
        //         return {
        //             id: u.id,
        //             label: u.name
        //         }
        //     })
        //     return usrs
        // },
        filterInfo() {
            const filterInfo = {}
            filterInfo.leftVisible = this.countFilteredLogs
            filterInfo.leftTotal = this.countLogs

            filterInfo.rightVisible = this.isSplitPage ? this.countFilteredProductions : (this.isProblemPage ? this.countFilteredProblemProductions : 0)
            filterInfo.rightTotal = this.isSplitPage ? this.countProductions : (this.isProblemPage ? this.countProblemProductions : 0)
            return filterInfo
        },
        countFilteredLogs() {
            if(!this.loaded || !this.crewview || !this.crewviewData) return 0

            let filtered = _.filter(this.crewviewData, v => (this.showOnlyCheckedUsers && v.checked) || (!this.showOnlyCheckedUsers && v.visible))
            return filtered.length
        },
        countLogs() {
            if(!this.loaded || !this.crewview) return 0
            return this.crewview.length
        },
        countFilteredProductions() {
            if(!this.loaded || !this.productionviewData || !this.allFilteredProductions) return 0
            return this.allFilteredProductions.length
        },
        countFilteredProblemProductions() {
            if(!this.loaded || !this.problemviewData || !this.allFilteredProblemProductions) return 0
            return this.allFilteredProblemProductions.length
        },
        countProductions() {
            if(!this.loaded || !this.productionviewData) return 0
            return this.productionviewData.length
        },
        countProblemProductions() {
            if(!this.loaded || !this.problemviewData) return 0
            return this.problemviewData.length
        },
        getRequestParams() {
            const output = {
                from: this.$route.query.from,
                to: this.$route.query.to,
            }
            
            if(this.filters.hasOwnProperty('skills') && this.filters.skills.length > 0) {
                output.skills = this.filters.skills.join(',')
            }
            return output
        },
        bookingTypes() {
            return this.bookingTypesEntity && this.bookingTypesEntity.get && this.bookingTypesEntity.get.items && this.bookingTypesEntity.get.items.length > 0 ? _.orderBy(this.bookingTypesEntity.get.items.filter(i => i.active), 'weight') : []
        },
        getSkillsList() {
            if(this.isProblemPage) return _.filter(this.filteredSkills, i => i.country == this.crewItemForm.formData.userCountry.value)

            if(!this.filters.hasOwnProperty('skills') || this.filters.skills.length == 0) return

            if(this.crewItemForm.assignment && this.crewItemForm.assignment.item &&
               this.crewItemForm.assignment.item.userId !== null && this.crewItemForm.assignment.item.userId !== '') {
                let currentUser = this.users.find(i => i.id === this.crewItemForm.assignment.item.userId)
                if(currentUser.country == this.crewItemForm.formData.userCountry.value) {
                    let currentUserSkills = _.union(currentUser.skills, this.filters.skills)

                    return _.filter(this.filteredSkills, i => _.includes(currentUserSkills, i.id ))
                }

                
            }
            else if(this.crewItemForm.formData.userId.value !== null && this.crewItemForm.formData.userId.value !== '') {
                let currentUser = this.users.find(i => i.id === this.crewItemForm.formData.userId.value)

                let currentUserSkills = _.union(currentUser.skills, this.filters.skills)

                return _.filter(this.filteredSkills, i => _.includes(currentUserSkills, i.id ))
            }

            // return _.filter(this.filteredSkills, i => _.includes(this.filters.skills, i.id ))
            return _.filter(this.filteredSkills, i => i.country == this.crewItemForm.formData.userCountry.value)
        },
        // getCrewList() {
        //     const context = this
        //     let users = null
        //     if(context.crewItemForm.formData.skill.value === null || context.crewItemForm.formData.skill.value === '' || context.getSkillsList.length == 0) return users = context.users

        //     users = _.filter(context.users, i => _.includes(i.skills, context.crewItemForm.formData.skill.value))
        //     const output = []
        //     Lazy(users)
        //         //.sortBy(v => v.fullName.toLowerCase())
        //         .each(v => {
        //             let wrn = false
        //             // if(v.booking) {
        //             //     wrn = _.filter(v.booking, j => j.conflict).length > 0
        //             // }
        //             const item = {
        //                 id: v.id,
        //                 label: v.name,
        //                 text: v.name,
        //                 search: `${v.name ? v.name.toLowerCase() : null}, ${v.username}, ${v.email}`, // WN lower-case search - there was a user that has no Last Name, that caused an Error, fixed the user..
        //                 //extra: v.booking ? this.getBookingLabel(v.booking) : null, // WN fn_get_available_crew -> booking is not represented if do_not_create_conflict selected for the user.
        //                 // blocked: (typeof v.booking !== 'undefined'),
        //                 // warning: (typeof v.booking !== 'undefined')
        //                 warning: wrn//v.booking !== null
        //             }
        //             output.push(item)
        //         })
        //     return output
        // },
        getCrewList() {
            if(this.retrievingAvailableCrew)  return
            if (this.crewItemForm.assignment.item !== null && (!this.availableCrew || this.availableCrew.length == 0)) return []
            const output = []
            if(this.crewItemForm.assignment.item !== null) {
                Lazy(this.availableCrew)
                //.sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    let wrn = false
                    if(v.booking) {
                        wrn = _.filter(v.booking, j => j.conflict).length > 0
                    }
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName ? v.fullName.toLowerCase() : null}, ${v.name}, ${v.email}`, // WN lower-case search - there was a user that has no Last Name, that caused an Error, fixed the user..
                        extra: v.booking ? this.getBookingLabel(v.booking) : null, // WN fn_get_available_crew -> booking is not represented if do_not_create_conflict selected for the user.
                        // blocked: (typeof v.booking !== 'undefined'),
                        // warning: (typeof v.booking !== 'undefined')
                        warning: wrn//v.booking !== null
                    }
                    output.push(item)
                })
            }
            else {
                Lazy(this.users)
                .each(v => {
                    let wrn = false
                    const item = {
                        id: v.id,
                        label: v.name,
                        text: v.name,
                        search: `${v.name ? v.name.toLowerCase() : null}, ${v.username}, ${v.email}`, // WN lower-case search - there was a user that has no Last Name, that caused an Error, fixed the user..
                        warning: wrn//v.booking !== null
                    }
                    output.push(item)
                })
            }
            
            return output
        },
        showBookingType() {
            if(this.crewItemForm.formData.productionType.value === 'task') return true

            return false
        },
        isFormChanged() {
            if(this.crewItemForm.formData.id.value == 0) return true
            let changed = false
            const context = this
            Lazy(context.crewItemForm.formData)
            .filter((v,k) => !v.local)
            .each((v,k) => {
                if (changed) return true
                switch (k) {
                    case 'bookingType':
                        const bType = context.bookingTypes.find(i => i.id === v.value)
                        let calc = bType ? bType.userfield1_value === 'yes' : true
                        let confl = bType ? bType.userfield2_value === 'yes' : true
                        if(context.crewItemForm.assignment.item['calcInCalendar'] !== calc || context.crewItemForm.assignment.item['createBookingConflict'] !== confl) changed = true
                        break
                    default:
                        if (context.crewItemForm.assignment.item[k] && v.value && (context.crewItemForm.assignment.item[k] != v.value)) changed = true
                        break
                }
                
            })

            return changed
            
        },
        json_data() {
            const context = this
            let output = []

            Lazy(context.crewviewData)
            .filter(u => {
                if (context.showOnlyCheckedUsers && !u.checked) {
                    return false
                }

                return u.visible
            })
            .each(u => {
                let assignments = []
                let userName = u.fullname
                Lazy(u.daily_assingments)
                .each(d => {
                    Lazy(d.assingments)
                    .filter(a => !a.hide && a.visible)
                    .each(a => {
                        if(!_.includes(assignments, a.id)) {
                            assignments.push(a.id)

                            let skill = _.find(context.skillEntity.get.items, i => i.id == a.skill)
                            skill = skill !== undefined ? skill.label : ''

                            let location = _.find(context.getLocations(), i => i.id == a.locationId)
                            location = location !== undefined ? location.label : ''

                            let sport = _.find(context.sportEntity.get.items, i => i.id == a.sport)
                            sport = sport !== undefined ? sport.label : ''

                            let league = _.find(context.leagueEntity.get.items, i => i.id == a.league)
                            league = league !== undefined ? league.label: ''

                            let runType = _.find(context.runTypeEntity.get.items, i => i.id == a.runType)
                            runType = runType !== undefined ? runType.label : ''

                            let channel = _.find(context.channelEntity.get.items, i => i.id == a.channel)
                            channel = channel !== undefined ? channel.label : ''

                            let assignmentStart = moment(a.assignmentStart)
                            let assignmentStartDate = assignmentStart.format('YYYY-MM-DD')
                            let assignmentStartTime = assignmentStart.format('HH:mm')

                            let assignmentEnd = moment(a.assignmentEnd)
                            let assignmentEndDate = assignmentEnd.format('YYYY-MM-DD')
                            let assignmentEndTime = assignmentEnd.format('HH:mm')

                            output.push({
                                Username : userName,
                                ProductionId: a.production,
                                ProductionType: a.productionType,
                                ProductionTitle: a.productionTitle,
                                Sport: sport,
                                League: league,
                                RunType: runType,
                                Channel: channel,
                                AssignmentStartDate: assignmentStartDate,
                                AssignmentStartTime: assignmentStartTime,
                                AssignmentEndDate: assignmentEndDate,
                                AssignmentEndTime: assignmentEndTime,
                                AssignmentSkill: skill,
                                AssignmentLocation: location,
                                AssignmentDuration:  assignmentEnd.diff(assignmentStart, 'minutes'),
                                AssignmentStatus : a.status
                            })
                        }
                    })
                })

                if(assignments.length === 0){
                    output.push({
                                Username : userName,
                                ProductionId: '',
                                ProductionType: '',
                                ProductionTitle: '',
                                Sport: '',
                                League: '',
                                RunType: '',
                                Channel: '',
                                AssignmentStartDate: '',
                                AssignmentStartTime: '',
                                AssignmentEndDate: '',
                                AssignmentEndTime: '',
                                AssignmentSkill: '',
                                AssignmentLocation: '',
                                AssignmentDuration:  '',
                                AssignmentStatus : ''
                            })
                }
            })


            return output
        },
        json_fields() {
             let excel_common_fields = {
                "User name" : "Username",
                "Production id": "ProductionId",
                "Production type": "ProductionType",
                "Production title": "ProductionTitle",
                "Sport": "Sport",
                "League": "League",
                "Run type": "RunType",
                "Channel": "Channel",
                "Assignment start date": "AssignmentStartDate",
                "Assignment start time": "AssignmentStartTime",
                "Assignment end date": "AssignmentEndDate",
                "Assignment end time": "AssignmentEndTime",
                "Skill": "AssignmentSkill",
                "Location": "AssignmentLocation",
                "Duration": "AssignmentDuration",
                "Status": "AssignmentStatus"
            }

            return excel_common_fields
        },
        getViewLabel() {
            switch(this.dateRange) {
                case 0:
                    return '7 days'
                case 1:
                    return '14 days'
                case 2:
                    return '31 days'
                default:
                    return 'Custom'
            }
        },
        isApplyEnabled() {
            return (this.isSplitPage && this.chosenUsers.length > 0 && this.chosenProductions.length > 0) ||
                   (this.isProblemPage && this.chosenUsers.length > 0 && this.chosenProblemProductions.length > 0)
        },
        isShowCheckedUsersEnabled() {
            return this.chosenUsers.length > 0
        },
        isShowCheckedProductionsEnabled() {
            return this.chosenProductions.length > 0
        },
        isShowCheckedProblemProductionsEnabled() {
            return this.chosenProblemProductions.length > 0
        },
        getStartPlans() {
            const context = this

            if(this.crewItemForm.formData.production.value === null || this.crewItemForm.formData.production.value === '') return

            let currentProduction = context.productions.find(i => i.id === context.crewItemForm.formData.production.value)

            console.log(currentProduction)

            if(currentProduction !== undefined) {
                let plans = _.filter(currentProduction.plan, i => i.relativeTo === 'start')

                if(plans && plans.length > 0) return plans
            
                return [
                    {
                        id: 0,
                        production: context.crewItemForm.formData.production.value,
                        type: null,
                        typeCustom: 'Dummy start',
                        relativeTo: 'start',
                        offset: 0,
                        description: '',
                        addedFrom: 'nsb',
                        updatedFrom: 'nsb',
                        isDefault: true
                    }
                ]
            }

            return

        },
        getEndPlans() {
            const context = this

            if(this.crewItemForm.formData.production.value === null || this.crewItemForm.formData.production.value === '') return

            let currentProduction = context.productions.find(i => i.id === context.crewItemForm.formData.production.value)
            console.log(currentProduction)

            if(currentProduction !== undefined) {
                let plans = _.filter(currentProduction.plan, i => i.relativeTo === 'end')

                if(plans && plans.length > 0) return plans
            
                return [
                    {
                        id: 0,
                        production: context.crewItemForm.formData.production.value,
                        type: null,
                        typeCustom: 'Dummy end',
                        relativeTo: 'end',
                        offset: 0,
                        description: '',
                        addedFrom: 'nsb',
                        updatedFrom: 'nsb',
                        isDefault: true
                    }
                ]
            }

            return

        },
        isApplyStatusEnabled() {
            return this.setCrewStatus.items && this.setCrewStatus.items.length > 0
        }
        
    },
    methods: {
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },
        loadData() {
            const context = this

            return new Promise((resolve, reject) => {
                const itemClass = new CrewView()
                const params = context.getRequestParams ? context.getRequestParams : {}

                itemClass.all(params, true)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        loadProductions() {
            const context = this

            return new Promise((resolve, reject) => {
                let params = {
                    startDate: context.productionRangeStart,
                    endDate: context.productionRangeEnd
                }

                CrewView.getProductions(params)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        loadProblemProductions() {
            const context = this

            return new Promise((resolve, reject) => {
                let params = {
                    startDate: context.productionRangeStart,
                    endDate: context.productionRangeEnd,
                    problem: context.filters.productionProblems.hasOwnProperty('value') ? context.filters.productionProblems.value : ''
                }

                CrewView.getProblemProductions(params)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        setCrewViewData() {
            if(this.crewview.length > 0) {
                this.crewviewData = new Array()
                this.users = new Array()
                this.availableFilters.users.items = new Array()
                let newUserFilters = new Array()
                let newChosenUsers = new Array()
                Lazy(this.crewview)
                .each((u, k) => {
                    this.users.push(u.user)
                    let currentUser = Object.assign({}, {
                        id: u.user.id,
                        label: u.user.name
                    })
                    let isUserVisible = true
                    this.availableFilters.users.items.push(currentUser)
                    if(this.filters.hasOwnProperty('users') && this.filters.users.length > 0) {
                        if(_.includes(this.filters.users, u.user.id)) {
                            newUserFilters.push(u.user.id)
                            Vue.set(currentUser, 'selected', true)
                        }
                        else {
                            isUserVisible = false
                        }
                    }
                    let user = {}
                    user.fullname = u.fullname
                    user.rows_number = u.rows_number
                    user.total_duration = u.total_duration
                    user.user_id = u.user_id
                    user.userCountry = u.user.country
                    user.daily_assingments = []
                    user.visible = isUserVisible // Re-build after roll back
                    if(isUserVisible && this.chosenUsers.length > 0 && _.includes(this.chosenUsers, u.user_id)) {
                        user.checked = true
                        newChosenUsers.push(u.user_id)
                    }
                    else {
                        user.checked =  false
                    }
                    
                    Lazy(u.daily_assingments)
                        .each((a, k1) => {
                            let daily_assingments = {}
                            daily_assingments.date = a.date
                            daily_assingments.assingments = _.cloneDeep(a.assingments)
                            Lazy(daily_assingments.assingments)
                                .each((d, k2) => {
                                    d.startIntervalTime = moment(d.startInterval)
                                    d.startInterval = d.startInterval.substring(d.startInterval.indexOf(' ') + 1)
                                    d.endInterval = d.endInterval.substring(d.endInterval.indexOf(' ') + 1)
                                    switch (d.status) {
                                        case 'option':
                                            d.itemStatusId = 5 // Blue.
                                            break
                                        case 'requested':
                                            d.itemStatusId = 4 // Yellow.
                                            break
                                        case 'accepted':
                                            d.itemStatusId = 2 // Green.
                                            break
                                        case 'declined':
                                            d.itemStatusId = 3 // Red.
                                            break
                                        case 'not_available':
                                            d.itemStatusId = 7 // Dark red.
                                            break
                                    }

                                    if(d.isHistorical) d.itemStatusId = 1 // Grey

                                    d.changed = 'none' // migth be 'created', 'updated', 'deleted'

                                    let isInListSkill = _.includes(this.filters.skills, d.skill)
                                    d.hide = this.hideAssignments ? !isInListSkill : false
                                    d.checked = false
                                    d.visible = true

                                    if(this.isSinglePage) {
                                        if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') && 
                                           this.filters.search.value !== null && this.filters.search.value !== '' &&
                                           !d.productionTitle.toLowerCase().includes(this.filters.search.value.toLowerCase())) {
                                            d.visible = false
                                        }
                                        
                                        if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, d.country)) {
                                            d.visible = false
                                        }

                                        if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, d.runType)) {
                                            d.visible = false
                                        }

                                        if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, d.sport)) {
                                            d.visible = false
                                        }

                                        if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, d.league)) {
                                            d.visible = false
                                        }

                                        if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, d.channel)) {
                                            d.visible = false
                                        }
                                    }
                                    
                                })

                            daily_assingments.assingments = _.sortBy(daily_assingments.assingments, ['startIntervalTime'])
                            // Lazy(daily_assingments.assingments)
                            //     .sortBy(i => i.start_interval_time)
                            //     .toArray()

                            user.daily_assingments.push(daily_assingments)
                        })
                    
                    user.initial_daily_assingments = _.cloneDeep(user.daily_assingments)
                    
                    this.crewviewData.push(user)
                })

                if(this.filters.hasOwnProperty('users') && this.filters.users.length > 0) {
                    if((_.xor(newUserFilters, this.filters.users)).length > 0) {
                        this.filters.users = newUserFilters
                    }
                }

                if(this.chosenUsers && this.chosenUsers.length > 0) {
                    if((_.xor(newChosenUsers, this.chosenUsers)).length > 0) {
                        this.chosenUsers = newChosenUsers
                    }
                }
            }
            else {
                this.crewviewData = null
                this.users = null
                this.availableFilters.users.items = []
                this.filters.users = []
                this.chosenUsers = []
            }
        },

        setProductionViewData() {
            if(this.allProductions.length > 0) {
                this.productionviewData = []
                let newChosenProductions = []
                Lazy(this.allProductions)
                .each((v,k) => {
                    let isProductionVisible = true
                    let productionTitle = `${v.sportLabel} | ${v.leagueLabel} | ${v.title}`
                    if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') && 
                        this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '' &&
                        !productionTitle.toLowerCase().includes(this.filters.productionSearch.value.toLowerCase())) {
                            isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, v.country)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, v.runType)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, v.sport)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, v.league)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, v.channel)) {
                        isProductionVisible = false
                    }

                    let production = {}
                    production.id = {
                        value: v.id
                    }

                    production.productionType = {
                        value: v.productionType
                    }

                    production.week = {
                        value: Datetime.getWeekNumber(v.productionStart)
                    }
                    production.day = {
                        value: Datetime.getWeekDay(v.productionStart)
                    }
                    production.date = {
                        value: Datetime.getDate(v.productionStart)
                    }
                    production.time = {
                        value: Datetime.getTime(v.productionStart, v.productionEnd)
                    }
                    production.runType = {
                        rawValue: v.runType,
                        value: v.runTypeLabel
                    }
                    production.channel = {
                        rawValue: v.channel,
                        value: v.channelLabel
                    }
                    production.sport = {
                        rawValue: v.sport,
                        value: v.sportLabel
                    }
                    production.league = {
                        rawValue: v.league,
                        value: v.leagueLabel
                    }
                    production.title = {
                        value: `${v.sportLabel} | ${v.leagueLabel} | ${v.title}`
                    }
                    production.country = {
                        value: v.country
                    }

                    production.rowOptions = {
                        visible: isProductionVisible,
                        checked: false
                    }

                    if(isProductionVisible && this.chosenProductions.length > 0 && _.includes(this.chosenProductions, v.id)) {
                        production.rowOptions.checked = true
                        newChosenProductions.push(v.id)
                    }
                    
                    this.productionviewData.push(production)
                })

                if(this.chosenProductions && this.chosenProductions.length > 0) {
                    if((_.xor(newChosenProductions, this.chosenProductions)).length > 0) {
                        this.chosenProductions = newChosenProductions
                    }
                }
            }
            else {
                this.productionviewData = null
                this.chosenProductions = []
            }
        },

        setProblemViewData() {
            if(this.allProblemProductions.length > 0) {
                this.problemviewData = []
                let newChosenProductions = []
                Lazy(this.allProblemProductions)
                .each((v,k) => {
                    let isProductionVisible = true
                    let productionTitle = `${v.sportLabel} | ${v.leagueLabel} | ${v.title}`
                    if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') && 
                        this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '' &&
                        !productionTitle.toLowerCase().includes(this.filters.productionSearch.value.toLowerCase())) {
                            isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, v.country)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, v.runType)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, v.sport)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, v.league)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, v.channel)) {
                        isProductionVisible = false
                    }

                    let production = {}
                    production.id = {
                        value: v.id
                    }
                    production.week = {
                        value: Datetime.getWeekNumber(v.productionStart)
                    }
                    production.day = {
                        value: Datetime.getWeekDay(v.productionStart)
                    }
                    production.date = {
                        value: Datetime.getDate(v.productionStart)
                    }
                    production.time = {
                        value: Datetime.getTime(v.productionStart, v.productionEnd)
                    }
                    production.runType = {
                        rawValue: v.runType,
                        value: v.runTypeLabel
                    }
                    production.channel = {
                        rawValue: v.channel,
                        value: v.channelLabel
                    }
                    production.sport = {
                        rawValue: v.sport,
                        value: v.sportLabel
                    }
                    production.league = {
                        rawValue: v.league,
                        value: v.leagueLabel
                    }
                    production.title = {
                        value: `${v.sportLabel} | ${v.leagueLabel} | ${v.title}`
                    }
                    production.country = {
                        value: v.country
                    }

                    production.productionType = {
                        value: v.productionType
                    }

                    production.rowOptions = {
                        visible: isProductionVisible,
                        // checked: false
                    }

                    production.crew = []

                    Lazy(v.crew)
                    .each((v2, k2) => {
                        let crew = Object.assign({}, v2)
                        crew.checked = false
                        crew.start = moment(v2.assignmentStart).format("DD MMM, HH:mm")
                        crew.end = moment(v2.assignmentEnd).format("DD MMM, HH:mm")
                        crew.username = `<a href="/user/${v2.userId}">${v2.user.name}</a>`
                        production.crew.push(crew)
                    })

                    // if(isProductionVisible && this.chosenProductions.length > 0 && _.includes(this.chosenProductions, v.id)) {
                    //     production.rowOptions.checked = true
                    //     newChosenProductions.push(v.id)
                    // }
                    
                    this.problemviewData.push(production)
                })

                // if(this.chosenProductions && this.chosenProductions.length > 0) {
                //     if((_.xor(newChosenProductions, this.chosenProductions)).length > 0) {
                //         this.chosenProductions = newChosenProductions
                //     }
                // }
            }
            else {
                this.problemviewData = null
                this.chosenProblemProductions = []
            }
        },
        
        setLoaded(loaded = true) {
            this.loaded = loaded
            //this.setFormValues()
        },
        createFilters() {
            const context = this
            const user = app.$data.user.get

            if(context.countries) {
                context.countries.forEach(c => {
                    context.availableFilters.countries.items.push({
                        id: c.code,
                        label: c.label
                    })

                    context.availableFilters.productionCountries.items.push({
                        id: c.code,
                        label: c.label
                    })
                })
            }

            if (context.$store.state.filter.myFiltersSelected === '') {
                Lazy(context.availableFilters.countries.items)
                    .filter(v => {
                        return v.id == user.country
                    })
                    .each(v => {
                        Vue.set(v, 'selected', true)
                    })

                Lazy(context.availableFilters.productionCountries.items)
                    .filter(v => {
                        return v.id == user.country
                    })
                    .each(v => {
                        Vue.set(v, 'selected', true)
                    })
            }
            
            context.filteredSkills.forEach(s => {
                context.availableFilters.skills.items.push({
                    id: s.id,
                    label: s.label
                }) 
            })
            
            context.availableFilters.users.items = []

            context.availableFilters.runTypes.items = context.filteredRunTypes

            context.availableFilters.sports.items = context.sportEntity && context.sportEntity.get.items ? context.sportEntity.get.items : []
            context.availableFilters.leagues.items = context.filteredLeagues
            context.availableFilters.channels.items = context.filteredChannels    

            context.filterReady = true
        },
        filteredBy(filters){
            console.log(filters)
            //this.previousFilteredSkills = this.filters.skills && this.filters.skills.length > 0 ? this.filters.skills.map(i => i.id) : null
            //this.currentFilteredSkills = filters.skills && filters.skills.length > 0 ? filters.skills.map(i => i.id) : null
            this.filters = filters
            this.applyFilters()
        },
        applyFilters() {
            if(this.isSinglePage) {
                Lazy(this.crewviewData)
                .each(u => {
                    Lazy(u.daily_assingments)
                    .each(d => {
                        Lazy(d.assingments)
                        .each(a => {
                            let isVisible = true
                            if(this.filters.hasOwnProperty('search') && this.filters.search.hasOwnProperty('value') && 
                               this.filters.search.value !== null && this.filters.search.value !== '' &&
                               !a.productionTitle.toLowerCase().includes(this.filters.search.value.toLowerCase())) {
                                   isVisible = false
                            }

                            if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, a.country)) {
                                isVisible = false
                            }

                            if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, a.runType)) {
                                isVisible = false
                            }

                            if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, a.sport)) {
                                isVisible = false
                            }

                            if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, a.league)) {
                                isVisible = false
                            }

                            if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, a.channel)) {
                                isVisible = false
                            }

                            a.visible = isVisible
                        })
                    })
                })
            }
            else if(this.isSplitPage){
                Lazy(this.productionviewData)
                .each(v => {
                    let isProductionVisible = true
                    if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') && 
                        this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '' &&
                        !v.title.value.toLowerCase().includes(this.filters.productionSearch.value.toLowerCase())) {
                            isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, v.country.value)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, v.runType.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, v.sport.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, v.league.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, v.channel.rawValue)) {
                        isProductionVisible = false
                    }

                    v.rowOptions.visible = isProductionVisible
                })
            }
            else {
                Lazy(this.problemviewData)
                .each(v => {
                    let isProductionVisible = true
                    if(this.filters.hasOwnProperty('productionSearch') && this.filters.productionSearch.hasOwnProperty('value') && 
                        this.filters.productionSearch.value !== null && this.filters.productionSearch.value !== '' &&
                        !v.title.value.toLowerCase().includes(this.filters.productionSearch.value.toLowerCase())) {
                            isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('productionCountries') && this.filters.productionCountries.length > 0 && !_.includes(this.filters.productionCountries, v.country.value)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('runTypes') && this.filters.runTypes.length > 0 && !_.includes(this.filters.runTypes, v.runType.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('sports') && this.filters.sports.length > 0 && !_.includes(this.filters.sports, v.sport.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('leagues') && this.filters.leagues.length > 0 && !_.includes(this.filters.leagues, v.league.rawValue)) {
                        isProductionVisible = false
                    }

                    if(this.filters.hasOwnProperty('channels') && this.filters.channels.length > 0 && !_.includes(this.filters.channels, v.channel.rawValue)) {
                        isProductionVisible = false
                    }

                    v.rowOptions.visible = isProductionVisible
                })
            }
        },
        /**
         * Retrieve available crew, based on the current start/end values.
         * @return promise
         */
        getAvailableCrew() {
            const context = this
            if (context.retrievingAvailableCrew) return
            context.retrievingAvailableCrew = true
            return new Promise((resolve, reject) => {
                const params = {
                    production: context.crewItemForm.formData.production.value,
                    country: context.crewItemForm.formData.userCountry.value,
                    skill: context.crewItemForm.formData.skill.value,
                    startPlan: context.crewItemForm.formData.startPlan.value,
                    endPlan: context.crewItemForm.formData.endPlan.value
                }
                
                ProductionCrew.getAvailableCrew(params)
                .then(response => {
                    context.retrievingAvailableCrew = false
                })
                .catch(error => {
                    context.retrievingAvailableCrew = false
                })
            })
        },
        openAssignmentForm(open = true, assignment) {
            const context = this

            if(assignment) {
                console.log(assignment.item)
            }
            
    
            if(open) {
                if (context.retrievingCrew) return
                context.retrievingCrew = true
                context.$loader.add('BookProductionsLoadingItems')

                let params = {
                    date: assignment.item ? moment(assignment.item.assignmentStart).format('YYYY-MM-DD') : assignment.date
                }

                CrewView.getAvailableProductions(params)
                .then(response => {
                    context.retrievingCrew = false
                    context.$loader.remove('BookProductionsLoadingItems')
                    context.resetForm()

                    this.crewItemForm.assignment = assignment

                    if(assignment.item) {
                        //context.getCurrentProduction(assignment.item.production)
                        context.crewItemForm.initialization = true
                        context.setFormValues(assignment.item)
                        // .then(response => {
                        //     context.setFormValues(assignment.item)
                        // })
                        .then(response => {
                            context.crewItemForm.formData.productionType.value = assignment.item.productionType
                            context.crewItemForm.formData.startDate.value = moment(assignment.item.assignmentStart).format('YYYY-MM-DD')
                            context.crewItemForm.formData.itemCountry.value = assignment.item.country
                            context.crewItemForm.formData.league.value = assignment.item.league
                            context.crewItemForm.formData.hideAssignmentData.value = false
                            context.crewItemForm.formData.userCountry.value = assignment.user.userCountry

                            context.getAvailableCrew()
                        })
                        .then(response => {
                            context.crewItemForm.loaded = true
                            context.crewItemForm.show = true
                            context.crewItemForm.initialization = false
                        })
                        .catch(error => {
                            console.log(error)
                            context.crewItemForm.loaded = true
                            context.crewItemForm.show = true
                            context.crewItemForm.initialization = false
                        })
                    }
                    else {
                        context.crewItemForm.formData.productionType.value = 'production'
                        context.crewItemForm.formData.startDate.value = assignment.date
                        context.crewItemForm.formData.itemCountry.value = assignment.user.userCountry
                        context.crewItemForm.formData.league.value = ''
                        context.crewItemForm.formData.hideAssignmentData.value = true
                        context.crewItemForm.formData.userCountry.value = assignment.user.userCountry
                        context.crewItemForm.formData.userId.value = assignment.user.user_id

                        let currentUser = _.find(context.users, i => i.id === assignment.user.user_id)
                        let currentSkills = _.filter(context.filters.skills, i => _.includes(currentUser.skills, i))

                        context.crewItemForm.formData.skill.value = currentSkills[0]

                        let location = _.find(context.getLocations(), i => i.country === assignment.user.userCountry && i.default === true)

                        context.crewItemForm.formData.locationId.value = location !== undefined ? location.id : ''

                        context.crewItemForm.loaded = true
                        context.crewItemForm.show = true
                    }


                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to load the items.')
                    context.$loader.remove('BookProductionsLoadingItems')
                    context.retrievingCrew = false
                })
            }
            else {
                
                context.crewItemForm.loaded = false
                context.crewItemForm.show = false
                context.crewItemForm.initialization = false
                context.resetForm()
            }
        },
        /**
         * Reset the crew member form.
         */
        resetForm() {
            this.crewItemForm.formData = this.defaultData()
            this.crewItemForm.assignment = null
            this.crewItemForm.initialization = false
        },
        getCurrentProduction(id) {
              return new Promise((resolve, reject) => {
                    let currentProduction = this.productions.find(i => i.id === id)
                    if(currentProduction === undefined) {
                        let production = new Production(id, {fields: ['plan']})
                        production.load()
                        .then(response => {
                            this.$store.state.data.availableProductionsCrew.push(response.data)
                            resolve()
                        })
                        .catch(error => {
                            reject()
                        })
                    }
                    else {
                        resolve()
                    }
              })
        },
        /**
         * Set form values based on passed item.
         * @param object item
         * @return promise
         */
        setFormValues(item) {
            const context = this
            return new Promise((resolve, reject) => {
                Lazy(context.crewItemForm.formData)
                    .filter((v, k) => !v.hasOwnProperty('local') || !v.local)
                    .each((v, k) => {
                        if (k == 'bookingType') {
                            const bType = context.bookingTypes.find(i => {
                                let calc = i.userfield1_value === 'yes'
                                let confl = i.userfield2_value === 'yes'
                                return calc === item.calcInCalendar && confl === item.createBookingConflict
                                })
                            v.value = bType.id
                        }
                        else {
                            v.value = item[k]
                        }
                    })
                resolve()
                
                
            })
        },
        getProductionLabel(production) {
            let start, title, id;
            switch(production.productionType) {
                case 'task':
                case 'edit':
                start = moment(production[`productionStart`])
                break;
                default:
                start = moment(production[`productionStart`])
                title = production.title
                id = production.id
            }

            return `${start.format('DD MMM, HH:mm')} - ${title} (${id})`
        },
        /**
         * Get the label representing a booking item. This can be used to format
         * the label for a production a crew member is booked on.
         */
        getBookingLabel(items) {

            let output = ''
            Lazy(items)
            .each(i => {
                output += `<p${i.conflict ? ' class="book-selectbox-item-conflict"' : ''}>${i.status.charAt(0).toUpperCase() + i.status.slice(1)}: ${i.title} (${i.production})<br />${moment(i.booking.start).format('DD MMM, HH:mm')} - ${moment(i.booking.end).format('DD MMM, HH:mm')}</p>`
            })

            return output
        },
        getPlanItems(relativeTo) {
            const context = this

            if(this.crewItemForm.formData.production.value === null || this.crewItemForm.formData.production.value === '') return

            let currentProduction = context.productions.find(i => i.id === context.crewItemForm.formData.production.value)

            if(currentProduction !== undefined) {
                let plans = _.filter(currentProduction.plan, i => i.relativeTo === relativeTo)

                if(plans && plans.length > 0) return plans
            
                return [
                    {
                        id: 0,
                        production: context.crewItemForm.formData.production.value,
                        type: null,
                        typeCustom: 'Dummy',
                        relativeTo: relativeTo,
                        offset: 0,
                        description: '',
                        addedFrom: 'nsb',
                        updatedFrom: 'nsb',
                        isDefault: true
                    }
                ]
            }

            return

        },
        /**
         * Get a label based on passed plan item.
         */
        getPlanItemLabel(item, field) {
            let time = this.getDateWithOffset(item)
            const output = []
            output.push(time.format('HH:mm'))
            if (item.type) {
                const planType = this.planTypeEntity.getItem(item.type)
                if (planType) output.push(planType.label)
            }
            else if (item.typeCustom) {
                output.push(item.typeCustom)
            }
            return output.join(' - ')
        },
        getLocations() {
            return this.$store.state.system.locations[0].items
        },
        setLocation(){
            // const locations = this.getLocations()
            // locations.forEach(location => {
            //     if(location.country === this.crewItemForm.formData.userCountry.value && location.default === true)
            //         this.crewItemForm.formData.locationId.value = location.id
            // })
        },
        isFormValid() {
            if(this.crewItemForm.formData.production.value === null || this.crewItemForm.formData.production.value == '') return false
            //if(this.crewItemForm.formData.startPlan.value === null || this.crewItemForm.formData.startPlan.value == '') return false
            //if(this.crewItemForm.formData.endPlan.value === null || this.crewItemForm.formData.endPlan.value == '') return false
            if(this.crewItemForm.formData.skill.value === null || this.crewItemForm.formData.skill.value == '') return false
            if(this.crewItemForm.formData.userId.value === null || this.crewItemForm.formData.userId.value == '') return false
            //if(this.crewItemForm.formData.locationId.value === null || this.crewItemForm.formData.locationId.value == '') return false
            if(this.crewItemForm.formData.status.value === null || this.crewItemForm.formData.status.value == '') return false

            return true
        },
        /**
         * Get a MomentJS instance with the start date for the passed Plan Item,
         * considering its offset.
         * @param object item A Plan Item object.
         * @return object MomentJS instance.
         */
        getDateWithOffset(item) {
            let currentProduction = this.productions.find(i => i.id === item.production)

            if(item.relativeTo === 'start') return moment(currentProduction.productionStart).add(item.offset, 's')
            return moment(currentProduction.productionEnd).add(item.offset, 's')
        },
        toggleCrewStatus(item, enable = true) {
            Lazy(this.crewviewData)
            .each(u => {
                Lazy(u.daily_assingments)
                .each(d => {
                    Lazy(d.assingments)
                    .each(a => {
                        let indx = _.findIndex(this.setCrewStatus.items, i => i.user == u.user_id && a.id == i.assignment)
                        if(!a.hide && a.visible && u.visible) {
                            if(enable && !a.isHistorical && a.status != 'not_available') {
                                switch(item) {
                                    case 'requested':
                                        if(a.status == 'option') {
                                            a.checked = true
                                        }
                                        break
                                    case 'accepted':
                                        if(a.status == 'requested') {
                                            a.checked = true
                                        }
                                        break
                                    case 'declined':
                                        if(a.status == 'requested') {
                                            a.checked = true
                                        }
                                        break
                                    default:
                                        if(a.status == 'requested') {
                                            a.checked = true
                                        }
                                        break
                                }

                                if(a.checked && indx === -1) {
                                    this.setCrewStatus.items.push({
                                        user: a.userId,
                                        assignment: a.id,
                                        item: a.productionType + ': ' + a.productionTitle,
                                        date: a.startIntervalTime.format('YYYY-MM-DD')
                                    })
                                }
                                
                            }
                        }
                        else if(a.checked) { 
                            a.checked = false
                            if(indx > -1) {
                                this.setCrewStatus.items.splice(indx, 1)
                            }
                        }

                        if(!enable) {
                            a.checked = false
                        }
                    })
                })
            })

            this.setCrewStatus.show = enable ? true : false
            if(!enable) {
                this.setCrewStatus.showCheckedOnly = false
                this.setCrewStatus.apply = ''
                this.setCrewStatus.items = []
            }
            else {
                this.setCrewStatus.apply = item
                this.setCrewStatus.showCheckedOnly = true
            }
            
        },
        selectAssignments(item) {
            let indx = _.findIndex(this.setCrewStatus.items, i => i.user == item.user && item.assignment == i.assignment)
            let currentUser = _.find(this.crewviewData, c => c.user_id == item.user)
            if(currentUser !== undefined && currentUser.daily_assingments && currentUser.daily_assingments.length > 0) {
                let currentDate = _.find(currentUser.daily_assingments, d => d.date == item.date)
                if(currentDate !== undefined && currentDate.assingments && currentDate.assingments.length > 0) {
                    let currentAssignment = _.find(currentDate.assingments, a => a.id == item.assignment)
                    if(currentAssignment !== undefined) {
                        if(!currentAssignment.checked) {
                            if(indx === -1) {
                                this.setCrewStatus.items.push({
                                    user: currentAssignment.userId,
                                    assignment: currentAssignment.id,
                                    item: currentAssignment.productionType + ': ' + currentAssignment.productionTitle,
                                    date: currentAssignment.startIntervalTime.format('YYYY-MM-DD')
                                })
                            }

                            /// Need implement checking / unchecking for whole event that more than 1 day
                        }
                        else {
                            if(indx > -1) {
                                this.setCrewStatus.items.splice(indx, 1)
                            }
                        }

                        this.selectSpreadAssignments(currentUser, currentAssignment, item.date, !currentAssignment.checked)
                    }
                }
            }

            if(this.setCrewStatus.showCheckedOnly && this.setCrewStatus.items && this.setCrewStatus.items.length === 0) {
                this.setCrewStatus.showCheckedOnly = false
            }
        },
        selectSpreadAssignments(currentUser, currentAssignment, date, value) {
            let currentStart = moment(currentAssignment.assignmentStart)
            let currentEnd = moment(currentAssignment.assignmentEnd)

            let currentStartDate = moment(currentStart.format('YYYY-MM-DD') + ' 00:00:00')
            let currentEndDate = moment(currentEnd.format('YYYY-MM-DD') + ' 23:59:59')

            let currentDiff = currentEndDate.diff(currentStartDate, 'days') + 1

            if(currentDiff > 1) {
                for(let i = 0; i < currentDiff; i++) {
                    let currentPrevDate = currentStart.add(i, 'd')
                    let currentPrevDateFormatted = currentPrevDate.format('YYYY-MM-DD')
                    if(currentPrevDateFormatted != date) {
                        let prevDailyAssignments = _.find(currentUser.daily_assingments, v => v.date === currentPrevDateFormatted)
                        if(prevDailyAssignments !== undefined) {
                            let prevDailyAssignment = _.find(prevDailyAssignments.assingments, i => i.id === currentAssignment.id)
                            if(prevDailyAssignment !== undefined) {
                                prevDailyAssignment.checked = value
                            }
                        }
                    }
                    
                }
            }

            // if(currentAssignment.spread && currentAssignment.spread.length > 1) {
            //     Lazy(currentAssignment.spread)
            //     .filter(i => i != date)
            //     .each(j => {
            //         let prevDailyAssignments = _.find(currentUser.daily_assingments, v => v.date === j)
            //         if(prevDailyAssignments !== undefined) {
            //             let prevDailyAssignment = _.find(prevDailyAssignments.assingments, i => i.id === currentAssignment.id)
            //             if(prevDailyAssignment !== undefined) {
            //                 prevDailyAssignment.checked = value
            //             }
            //         }
            //     })
            // }
        },
        applyStatus() {
            const context = this
            if(context.setCrewStatus.apply == 'requested') {
                swal({
                    title: 'Notify users?',
                    text: 'Do you want to send a request to selected users?',
                    type: 'question',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true
                })
                .then(function () {
                        context.showNotificationModal(true)
                    },
                    function (dismiss) {
                        context.applyStatusSend()
                    })
                .catch(function() {
                    console.log('Something went wrong while applying status')
                })
            }
            else {
                context.applyStatusSend()
            }
        },
        sendMultiNotifications(item) {
            // console.log(item)
            this.notification.show = false
            this.applyStatusSend(item)
        },
        applyStatusSend(withNotification = null) {
            console.log(withNotification)

            const context = this

            context.$loader.add('bookProductionCrewSubmit')
            let params = {
                assignments: this.setCrewStatus.items,
                status: this.setCrewStatus.apply
            }

            if(withNotification) {
                params.notifications = withNotification
            }

            CrewView.setStatus(params)
            .then(response => {
                console.log(response)
                context.updateStatus(response.data.successfulUpdates, context.setCrewStatus.apply)
                if(context.isProblemPage) {
                    context.chosenProblemProductions = []
                }
                context.toggleCrewStatus(null, false)
                context.$loader.remove('bookProductionCrewSubmit')
                if(response.data.failedUpdates && response.data.failedUpdates.length > 0) {
                    context.$alert.set('Some crew status was not updated', 'danger', 4)
                    console.log(response.data.failedUpdates)
                }
            })
            .catch(error => {
                console.log(error)
                context.$loader.remove('bookProductionCrewSubmit')
            })

        },
        updateStatus(updates, value) {
            const context = this
            if(updates && updates.length > 0) {
                Lazy(updates)
                .each(update => {
                    let currentUser = _.find(context.crewviewData, c =>  c.user_id == update.user)
                    if(currentUser !== undefined && currentUser.daily_assingments && currentUser.daily_assingments.length > 0) {
                        let currentDate = _.find(currentUser.daily_assingments, d => d.date == update.date)
                        if(currentDate !== undefined && currentDate.assingments && currentDate.assingments.length > 0) {
                            let currentAssignment = _.find(currentDate.assingments, a => a.id == update.assignment)
                            if(currentAssignment !== undefined) {
                                currentAssignment.status = value
                                switch (value) {
                                        case 'option':
                                            currentAssignment.itemStatusId = 5 // Blue.
                                            break
                                        case 'requested':
                                            currentAssignment.itemStatusId = 4 // Yellow.
                                            break
                                        case 'accepted':
                                            currentAssignment.itemStatusId = 2 // Green.
                                            break
                                        case 'declined':
                                            currentAssignment.itemStatusId = 3 // Red.
                                            break
                                        case 'not_available':
                                            currentAssignment.itemStatusId = 7 // Dark red.
                                            break
                                    }
                                
                                let currentStart = moment(currentAssignment.assignmentStart)
                                let currentEnd = moment(currentAssignment.assignmentEnd)

                                let currentStartDate = moment(currentStart.format('YYYY-MM-DD') + ' 00:00:00')
                                let currentEndDate = moment(currentEnd.format('YYYY-MM-DD') + ' 23:59:59')

                                let currentDiff = currentEndDate.diff(currentStartDate, 'days') + 1

                                if(currentDiff > 1) {
                                    for(let i = 0; i < currentDiff; i++) {
                                        let currentPrevDate = currentStart.add(i, 'd')
                                        let currentPrevDateFormatted = currentPrevDate.format('YYYY-MM-DD')
                                        if(currentPrevDateFormatted != update.date) {
                                            let prevDailyAssignments = _.find(currentUser.daily_assingments, v => v.date === currentPrevDateFormatted)
                                            if(prevDailyAssignments !== undefined) {
                                                let prevDailyAssignment = _.find(prevDailyAssignments.assingments, i => i.id === currentAssignment.id)
                                                if(prevDailyAssignment !== undefined) {
                                                    prevDailyAssignment.status = value
                                                    prevDailyAssignment.itemStatusId = currentAssignment.itemStatusId
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }

                    if(context.isProblemPage) {
                        let currentProduction = _.find(context.problemviewData, c =>  c.id.value == update.production)
                        if(currentProduction !== undefined) {
                            let currentCrewIndex = _.findIndex(currentProduction.crew, p => p.id == update.assignment)
                            if(currentCrewIndex > -1) {
                                currentProduction.crew.splice(currentCrewIndex, 1)
                            }

                            if(currentProduction.crew.length == 0) {
                                let productionIndex = _.findIndex(context.problemviewData, p => p.id.value == update.production)
                                if(productionIndex > -1) {
                                    context.problemviewData.splice(productionIndex, 1)
                                }
                            }
                        }
                    }
                })
            }            
        },
        startDownload(){
            this.$alert.set('Excel file is downloaded.', 'success', 3)
        },
        /**
         * Open BookNotification modal.
         * @param boolean open
         * @param string|integer If "all", all the crew members will be included,
         *                       if an user id (integer) then only that specific
         *                       user will be included.
         */
        showNotificationModal(open = true, recipient = 'all') {
            this.notification.recipients = []
            switch (recipient) {
                case 'all':
                    this.notification.modalTitle = 'Contact crew members'
                    this.notification.groupBy = 'status'
                    this.notification.groupMessage = 'Which crew members should receive this message?'
                    this.notification.isCrewView = true
                    let allUsers = _.uniq(this.setCrewStatus.items.map(i => i.user))
                    Lazy(this.users)
                        .filter(v => _.includes(allUsers, v.id))
                        .each(v => {
                            
                            this.notification.recipients.push({
                                status: this.setCrewStatus.item,
                                email: v.email,
                                phone: v.phone,
                                id: v.id,
                                label: v.name,
                            })
                        })
                    break
                default:
                    const crew = this.users.find(v => v.id == recipient)
                    if (crew !== undefined) {
                        this.notification.recipients.push({
                            status: '',
                            email: crew.email,
                            phone: crew.phone,
                            id: crew.id,
                            label: crew.name,
                        })
                        this.notification.modalTitle = `Contact ${crew.name}`
                        this.notification.groupBy = 'status'
                        this.notification.groupMessage = ''
                        this.notification.isCrewView = false
                    }
                    break
            }
            if (this.notification.recipients.length > 0) {
                this.notification.show = open
            }
            else {
                this.notification.messageTemplate = ''
                this.notification.show = false
                this.$alert.set('No recipients are available.', 'danger', 4)
            }
            if (!open) {
                this.notification.messageTemplate = ''
                this.notification.defaultEmailMessage = ''
                this.notification.defaultSubject = ''
                this.notification.isCrewView = false
            }
        },
        showNotificationModalSingle(open = true, crew) {
            this.notification.recipients = []
           
            if (crew !== undefined) {
                this.notification.recipients.push({
                    status: crew.crew.status,
                    email: crew.crew.user.email,
                    phone: crew.crew.user.phone,
                    id: crew.crew.user.id,
                    label: crew.crew.user.name,
                })
                this.notification.modalTitle = `Contact ${crew.crew.user.name}`
                this.notification.defaultEmailMessage = `Production: ${crew.production}`
                this.notification.defaultSubject = `Production: ${crew.production}`
                this.notification.groupBy = 'status'
                this.notification.groupMessage = ''
                this.notification.isCrewView = false
            }
                   
            if (this.notification.recipients.length > 0) {
                this.notification.show = open
            }
            else {
                this.notification.messageTemplate = ''
                this.notification.show = false
                this.$alert.set('No recipients are available.', 'danger', 4)
            }
            if (!open) {
                this.notification.messageTemplate = ''
                this.notification.defaultEmailMessage = ''
                this.notification.defaultSubject = ''
                this.notification.isCrewView = false
            }
        },
        /**
         * Shows the calendar of the crew member
         * @return new-tab
         * */
        openCalendar(crewId) {
            let context = this
            //<a href="/user/725">Peter Ølsted</a>
            // using regex pattern to get user-id.
            // let pattern = /\d+/g;
            // let id = crewId.match(pattern)

            var link_ = {
                view: context.$route.params.view,
                crewId: crewId
            }

            let routeData = context.$router.resolve({name: `calendar.view`, query: link_});
            window.open(routeData.href, '_blank');
        },
        dateChanged(item) {
            let from = item.from
            let to = item.to

            const context = this
            if(context.productionRangeStart !== from || context.productionRangeEnd !== to) {
                context.productionRangeStart = from
                context.productionRangeEnd = to
                context.setLoaded(false)

                if(context.isSplitPage) {
                    context.loadProductions()
                    .then(response => {
                        context.setLoaded()
                        context.setProductionViewData()
                    })
                    .catch(error => {
                        context.setLoaded()
                    })
                }

                if(context.isProblemPage) {
                    context.loadProblemProductions()
                    .then(response => {
                        context.setLoaded()
                        context.setProblemViewData()
                    })
                    .catch(error => {
                        context.setLoaded()
                    })
                }
                
            }
        },
        updateChosenProductions(value) {
            this.chosenProductions = JSON.parse(value)
            console.log(this.chosenProductions)
        },
        updateChosenProblemProductions(value) {
            this.chosenProblemProductions = JSON.parse(value)
            console.log(this.chosenProblemProductions)
        },
        updateChosenUsers(value) {
            this.chosenUsers = JSON.parse(value)
            console.log(this.chosenUsers)

            if(this.isProblemPage) {
                Lazy(this.crewviewData)
                .each(u => {
                    if(u.checked && u.user_id !== this.chosenUsers[0]) u.checked = false
                })
            }
        },
        onResize() {
            let nav = document.querySelector('#mainNav')
            let header = document.querySelector('.page-header')
            let filter = document.querySelector('.book-crew-filter')

            let crewview = document.querySelector('#crewviewmain')

            header.style.cssText ='top: ' + nav.clientHeight + 'px !important;'

            let padding = nav.clientHeight + header.clientHeight - 25
            crewview.style.cssText ='padding-top: ' + padding + 'px !important;'

            let heigth = window.innerHeight - (nav.clientHeight + header.clientHeight + filter.clientHeight) - 40

            this.scrollHeight = heigth;
        },
        submit() {
            const context = this
            context.$loader.add('bookProductionCrewSubmit')
            let id = null
            let historical = null
            let removableItem = null
            let previousDeclined = null
            let prev = null

            let productionCrew = new ProductionCrew()
            productionCrew.removeAll()

            if(context.crewItemForm.assignment.item) {
                if(context.crewItemForm.assignment.item.status == 'declined' && context.crewItemForm.assignment.item.userId !== context.crewItemForm.formData.userId.value) {
                    historical = new ProductionCrew(context.crewItemForm.assignment.item.id, context.crewItemForm.assignment.item.production)
                    historical.set('id', context.crewItemForm.assignment.item.id)
                    historical.set('production', context.crewItemForm.assignment.item.production)
                    historical.set('startPlan', context.crewItemForm.assignment.item.startPlan)
                    historical.set('endPlan', context.crewItemForm.assignment.item.endPlan)
                    historical.set('skill', context.crewItemForm.assignment.item.skill)
                    historical.set('userId', context.crewItemForm.assignment.item.userId)
                    historical.set('status', context.crewItemForm.assignment.item.status)
                    historical.set('calcInCalendar', context.crewItemForm.assignment.item.calcInCalendar)
                    historical.set('createBookingConflict', context.crewItemForm.assignment.item.createBookingConflict)
                    historical.set('locationId', context.crewItemForm.assignment.item.locationId)
                    historical.set('isHistorical', true)

                    // Lazy(context.crewItemForm.assignment.item)
                    // .each((v, k) => {
                    //     if(k == 'isHistorical') {
                    //         historical.set(k, true)
                    //     }
                    //     else {
                    //         historical.set(k, v)
                    //     }
                        
                    // })
                    // Object.assign({}, context.crewItemForm.assignment.item)
                    // historical.isHistorical = true
                }
                else {
                    id = context.crewItemForm.assignment.item.id
                }
            }
            
            productionCrew = id ? new ProductionCrew(id, context.crewItemForm.formData.production.value) : new ProductionCrew(0, context.crewItemForm.formData.production.value)

            if(!historical) {
                let currentProduction = _.find(context.productions, v => v.id === context.crewItemForm.formData.production.value)
                if(currentProduction !== undefined && currentProduction.crew && currentProduction.crew.length > 0) {
                    previousDeclined = _.find(currentProduction.crew, v => v.user.id == context.crewItemForm.formData.userId.value && v.isHistorical)
                    if(previousDeclined !== undefined) {
                        prev = {
                                    id: previousDeclined.id,
                                    assignmentStart: previousDeclined.crewStartDate,
                                    assignmentEnd: previousDeclined.crewEndDate,
                                    userId: previousDeclined.user.id,
                                    calcInCalendar: previousDeclined.calcInCalendar,
                                    status: previousDeclined.status,
                                    production: previousDeclined.production
                                }
                        removableItem = new ProductionCrew(previousDeclined.id, context.crewItemForm.formData.production.value)
                    }
                }
                
            }

            Lazy(context.crewItemForm.formData)
                .filter(v => !v.local)
                .each((v, k) => {
                    if (id && (k == 'id')) productionCrew.set('id', id)
                    else if(!id && (k == 'id')) productionCrew.set('id', 0)
                    else if(k == 'bookingType') {
                        let calcInCalendar = true
                        let createBookingConflict = true
                        if(v.value) {
                            let bType = context.bookingTypes.find(i => i.id === v.value)
                            if(bType) {
                                calcInCalendar = bType.userfield1_value === 'yes'
                                createBookingConflict = bType.userfield2_value === 'yes'
                            }
                        }
                        
                        productionCrew.set('calcInCalendar', calcInCalendar)
                        productionCrew.set('createBookingConflict', createBookingConflict)
                    }
                    else if(v.value !== "")
                        productionCrew.set(k, v.value)
                    else if(v.value === "")
                        productionCrew.set(k, null)
                    else if(v.value === null)
                        productionCrew.set(k, null)
                })

            if(!id) {
                productionCrew.set('id', 0)
            }

            let sendCrewRequest = false

            if(historical) {
                productionCrew.store()
                .then(response => {
                    //context.updateCrewViewData('create', response.data, null)
                    context.updateCrewViewData('create', response.data, null)
                    let newCrew = response.data
                    
                    historical.save()
                        .then(responseHistorical => {
                            context.$alert.set('The crew member has been updated.', 'success', 5)
                            //context.updateCrewViewData('update', response.data, context.crewItemForm.assignment.item)
                            context.updateCrewViewData('update', responseHistorical.data, context.crewItemForm.assignment.item)
                            context.openAssignmentForm(false)
                            context.$loader.remove('bookProductionCrewSubmit')
                            if (sendCrewRequest) {
                                context.sendCrewRequestToNewlyAddedUser(newCrew)
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to save the selected crew member.')
                            context.$loader.remove('bookProductionCrewSubmit')
                        })
                })
                .catch(error => {
                    let msg = 'It was not possible to save the selected crew member.'
                    if (error.response && error.response.status === 409) {
                        msg = 'This user is already booked for the selected date/time interval.'
                    }
                    context.$error.set(error, msg)
                    context.$loader.remove('bookProductionCrewSubmit')
                })
            }
            else if(removableItem){
                 
                        swal({
                            title: 'Add the user anyway?',
                            text: 'Are you sure you want to add the user who has already declined this?',
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true
                        })
                            .then(function () {
                                if (!id) {
                                    productionCrew.store()
                                        .then(response => {
                                            context.$alert.set('The crew member has been added.', 'success', 5)
                                            context.updateCrewViewData('create', response.data, null)
                                            //context.addCrew(response.data)
                                            context.openAssignmentForm(false)
                                            const responseId = response.data
                                            if(removeProductionCrew) {
                                                //const deleteCrew = context.getClass(declinedItem.id)
                                                removableItem.delete()
                                                .then(response => {
                                                    //context.removeCrew(declinedItem.id)
                                                    //context.updateCrewViewData('delete',null, response.data)
                                                    console.log('After deleting')
                                                    console.log(prev)
                                                    context.updateCrewViewData('delete', null, prev)
                                                    context.$alert.set('Previous declining has been removed', 'success', 3)
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                    if (sendCrewRequest) {
                                                        context.sendCrewRequestToNewlyAddedUser(responseId)
                                                    }
                                                })
                                                .catch(error => {
                                                    context.$error.set(error, 'It was not possible to remove the crew.')
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                })
                                            }
                                            else {
                                                context.$loader.remove('bookProductionCrewSubmit')
                                                if (sendCrewRequest) {
                                                    context.sendCrewRequestToNewlyAddedUser(response.data)
                                                }
                                            }
                                            
                                        })
                                        .catch(error => {
                                            let msg = 'It was not possible to save the selected crew member.'
                                            if (error.response && error.response.status === 409) {
                                                msg = 'This user is already booked for the selected date/time interval.'
                                            }
                                            context.$error.set(error, msg)
                                            context.$loader.remove('bookProductionCrewSubmit')
                                        })
                                }
                                else {
                                    productionCrew.save()
                                        .then(response => {
                                            context.$alert.set('The crew member has been updated.', 'success', 5)
                                            context.updateCrewViewData('update', response.data, context.crewItemForm.assignment.item)
                                            context.openAssignmentForm(false)

                                            if(removableItem) {
                                                //const deleteCrew = context.getClass(declinedItem.id)
                                                removableItem.delete()
                                                .then(response => {
                                                    console.log('After deleting')
                                                    
                                                    console.log(prev)
                                                    context.updateCrewViewData('delete', null, prev)
                                                    context.$alert.set('Previous declining has been removed', 'success', 3)
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                    if (sendCrewRequest) context.sendCrewRequest(productionCrew.get.id)
                                                })
                                                .catch(error => {
                                                    context.$error.set(error, 'It was not possible to remove the crew.')
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                })
                                            }
                                            else {
                                                context.$loader.remove('bookProductionCrewSubmit')
                                                if (sendCrewRequest) context.sendCrewRequest(productionCrew.get.id)
                                            }
                                        })
                                        .catch(error => {
                                            context.$error.set(error, 'It was not possible to save the selected crew member.')
                                            context.$loader.remove('bookProductionCrewSubmit')
                                        })
                                }
                            })
                            .catch(function() {
                                context.$loader.remove('bookProductionCrewSubmit')
                            })
                      
            } else if(id) {
                productionCrew.save()
                .then(response => {
                    context.updateCrewViewData('update', response.data, context.crewItemForm.assignment.item)
                    context.openAssignmentForm(false)
                    context.$loader.remove('bookProductionCrewSubmit')
                })
                .catch(error => {
                    let msg = 'It was not possible to save the selected crew member.'
                    if (error.response && error.response.status === 409) {
                        msg = 'This user is already booked for the selected date/time interval.'
                    }
                    context.$error.set(error, msg)
                    context.$loader.remove('bookProductionCrewSubmit')
                })

            }
            else {
                productionCrew.store()
                .then(response => {
                    context.updateCrewViewData('create', response.data, null)
                    context.openAssignmentForm(false)
                    context.$loader.remove('bookProductionCrewSubmit')
                })
                .catch(error => {
                    let msg = 'It was not possible to save the selected crew member.'
                    if (error.response && error.response.status === 409) {
                        msg = 'This user is already booked for the selected date/time interval.'
                    }
                    context.$error.set(error, msg)
                    context.$loader.remove('bookProductionCrewSubmit')
                })
            }

            //if(!context.waitingUpdates.update) context.waitingUpdates.update = true

        },
        deleteProductionCrew(crew = null) {
            const context = this
            context.$loader.add('bookProductionCrewSubmit')
            swal({
                    title: 'Delete assignment?',
                    text: 'Are you sure you want to delete the assignment ?',
                    type: 'question',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true
                })
                    .then(function () {
                        let removableItem = new ProductionCrew()
                        removableItem.removeAll()
                        let removableCrewId = crew ? crew.id : context.crewItemForm.formData.id.value
                        let removableCrewPeoduction = crew ? crew.production : context.crewItemForm.formData.production.value
                        let removableCrewItem = crew ? crew : context.crewItemForm.assignment.item
                        removableItem = new ProductionCrew(removableCrewId, removableCrewPeoduction)
                        removableItem.delete()
                        .then(response => {
                            context.updateCrewViewData('delete', null, removableCrewItem)
                            context.openAssignmentForm(false)
                            context.$alert.set('An assignment has been removed', 'success', 3)
                            context.$loader.remove('bookProductionCrewSubmit')
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to remove the crew.')
                            context.$loader.remove('bookProductionCrewSubmit')
                        })
                    })
                    .catch(function() {
                        context.$loader.remove('bookProductionCrewSubmit')
                    })
        },
        updateCrewViewData(action, item, prev) {
            if(prev) {
                let prevStart = moment(prev.assignmentStart)
                let prevStartDateOnly = prevStart.format('YYYY-MM-DD')
                let prevStartFromatted = prevStart.format('YYYY-MM-DD HH:mm')

                let prevEnd = moment(prev.assignmentEnd)
                let endFromatted = prevEnd.format('YYYY-MM-DD HH:mm')

                let prevStartDate = moment(prevStart.format('YYYY-MM-DD') + ' 00:00:00')
                let prevEndDate = moment(prevEnd.format('YYYY-MM-DD') + ' 23:59:59')

                let prevDiff = prevEndDate.diff(prevStartDate, 'days') + 1

                let prevUser = _.find(this.crewviewData, v => v.user_id === prev.userId)

                if(prevUser !== undefined) {
                    let prevAssignments = prevUser.daily_assingments

                    if(prevStartDate.isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
                       prevEndDate.isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                           if(prev.calcInCalendar && prev.status != 'declined') {
                               prevUser.total_duration = prevUser.total_duration - prevEnd.diff(prevStart, 'minutes')
                           }
                        
                    }

                    for(let i = 0; i < prevDiff; i++) {
                        let currentPrevDate = moment(prevStartDateOnly).add(i, 'd')
                        let currentPrevDateFormatted = currentPrevDate.format('YYYY-MM-DD')

                        let prevDailyAssignments = _.find(prevAssignments, v => v.date === currentPrevDateFormatted)
                        if(prevDailyAssignments !== undefined) {
                            let indx = _.findIndex(prevDailyAssignments.assingments, i => i.id === prev.id)

                            if(indx > -1) {
                                prevDailyAssignments.assingments.splice(indx, 1)
                            }
                        }
                    }
                }

                if(this.isProblemPage) {
                    let production = _.find(this.problemviewData, p => p.id.value == prev.production)
                    if(production !== undefined) {
                        let crewPrevIndex = _.findIndex(production.crew, p => p.id == prev.id)
                        if(crewPrevIndex > -1) {
                            production.crew.splice(crewPrevIndex, 1)
                        }
                    }
                    
                }
            }

            if(item) {
                let start = moment(item.crewStartDate)
                let startDateOnly = start.format('YYYY-MM-DD')
                let startFromatted = start.format('YYYY-MM-DD HH:mm')

                let end = moment(item.crewEndDate)
                let endFromatted = end.format('YYYY-MM-DD HH:mm')

                let startDate = moment(start.format('YYYY-MM-DD') + ' 00:00:00')
                let endDate = moment(end.format('YYYY-MM-DD') + ' 23:59:59')

                let diff = endDate.diff(startDate, 'days') + 1

                let currentUser = _.find(this.crewviewData, v => v.user_id === item.user.id)
                //let currentProduction = _.find(this.productions, v => v.id === item.production)

                if(currentUser !== undefined) {
                    let daily_assingments = currentUser.daily_assingments

                    if(startDate.isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
                       endDate.isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                           if(item.calcInCalendar && !item.isHistorical && item.status !== 'declined') {
                               currentUser.total_duration = currentUser.total_duration + end.diff(start, 'minutes')
                           }
                    }

                    for(let i = 0; i < diff; i++) {
                        let currentDate = moment(startDateOnly).add(i, 'd')
                        

                        let currentDateFormatted = currentDate.format('YYYY-MM-DD')
                        // need to chech if this currentDate in the range of displaying dates!!!!
                        if(moment(currentDateFormatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                            let currentDateStart = moment(currentDate.format('YYYY-MM-DD') + ' 00:00:00')
                            let currentDateEnd = moment(currentDate.format('YYYY-MM-DD') + ' 23:59:59')

                            let currentIntervalStart = currentDateStart.isBefore(start) ? start : currentDateStart
                            let currentIntervalEnd = end.isBefore(currentDateEnd) ? end : currentDateEnd

                            let currentDateAssignments = _.find(daily_assingments, v => v.date === currentDateFormatted)
                            if(currentDateAssignments === undefined) {
                                currentDateAssignments = {
                                    date: currentDateFormatted,
                                    assingments: []
                                }

                                daily_assingments.push(currentDateAssignments)
                            }
                            let startInterval = currentIntervalStart.format('HH:mm')
                            let endInterval = currentIntervalEnd.format('HH:mm')
                            let itemStatusId = null
                            switch (item.status) {
                                case 'option':
                                    itemStatusId = 5 // Blue.
                                    break
                                case 'requested':
                                    itemStatusId = 4 // Yellow.
                                    break
                                case 'accepted':
                                    itemStatusId = 2 // Green.
                                    break
                                case 'declined':
                                    itemStatusId = 3 // Red.
                                    break
                                case 'not_available':
                                    itemStatusId = 7 // Dark red.
                                    break
                            }

                            if(item.isHistorical) itemStatusId = 1 // Grey

                            let isInListSkill = _.includes(this.filters.skills, item.skill)
                            let hide = this.hideAssignments ? !isInListSkill : false

                            currentDateAssignments.assingments.push( 
                                {
                                    'startIntervalTime': currentIntervalStart,
                                    'startInterval': startInterval,
                                    'endInterval': endInterval,
                                    'itemStatusId': itemStatusId,

                                    'changed': 'none', // migth be 'created', 'updated', 'deleted'

                                    'hide': hide,
                                    'id': item.id,
                                    'production': item.production,
                                    'skill': item.skill,
                                    'userId': item.user.id,
                                    'status': item.status,
                                    'startPlan': item.startPlan,
                                    'endPlan': item.endPlan,
                                    'assignmentStart': startFromatted,
                                    'assignmentEnd': endFromatted,
                                    'locationId': item.location,
                                    // ,'startInterval', to_char(d.start_interval, 'yyyy-mm-dd hh24:mi')
                                    // ,'endInterval', to_char(d.end_interval, 'yyyy-mm-dd hh24:mi')
                                    'calcInCalendar': item.calcInCalendar,
                                    'createBookingConflict': item.createBookingConflict,
                                    'isHistorical': item.isHistorical,
                                    'addedFrom': 'nsb',
                                    'updatedFrom': 'nsb',
                                    'conflict': item.conflict,
                                    'league': item.league,
                                    'sport': item.sport,
                                    'runType': item.runType,
                                    'channel': item.channel,
                                    'country': item.productionCountry,
                                    'productionTitle': item.title,
                                    'productionType': item.productionType,
                                    'checked': false,
                                    'visible': true
                                }    
                            )

                            currentDateAssignments.assingments = _.sortBy(currentDateAssignments.assingments, ['startIntervalTime'])
                        }
                    }
                }

                if(this.isProblemPage) {
                    let updateProduction = _.find(this.problemviewData, p => p.id.value == item.production)
                    if(updateProduction !== undefined && this.filters.hasOwnProperty('productionProblems') && this.filters.productionProblems.value == item.status && !item.isHistorical) {
                        
                        // let skl = _.find(this.filteredSkills, f => f.id == item.skill)
                        // item.skillLabel = skl !== undefined ? skl.label : ''
                        // item.locationId = item.location
                        
                        // item.locationLabel = lct !== undefined ? lct.label : ''
                        

                        let newCrew = {}
                        newCrew.id =  item.id
                        newCrew.production = item.production
                        newCrew.skill = item.skill
                        newCrew.skillLabel = item.skillDetails.label
                        newCrew.userId = item.user.id
                        newCrew.status = item.status
                        newCrew.startPlan = item.startPlan
                        newCrew.endPlan = item.endPlan
                        newCrew.assignmentStart = moment(item.crewStartDate).format("YYYY-MM-DD HH:mm")
                        newCrew.assignmentEnd = moment(item.crewEndDate).format("YYYY-MM-DD HH:mm")
                        newCrew.locationId = item.location
                        let lct = _.find(this.getLocations(), l => l.id == item.location)
                        newCrew.locationLabel = lct.label
                        newCrew.calcInCalendar = item.calcInCalendar
                        newCrew.createBookingConflict = item.createBookingConflict
                        newCrew.isHistorical = item.isHistorical
                        // ,'addedFrom', b.added_from
                        // ,'updatedFrom', b.updated_from
                        newCrew.conflict = item.conflict
                        newCrew.user = item.user
                        newCrew.start = moment(item.crewStartDate).format("DD MMM, HH:mm")
                        newCrew.end = moment(item.crewEndDate).format("DD MMM, HH:mm")
                        newCrew.username = `<a href="/user/${item.user.id}">${item.user.name}</a>`
                        newCrew.checked = false



                        updateProduction.crew.push(newCrew)
                    } else if(updateProduction !== undefined && updateProduction.crew && updateProduction.crew.length == 0) {
                        let updateProductionIndex = _.find(this.problemviewData, p => p.id.value == item.production)
                        this.problemviewData.splice(updateProductionIndex, 1)
                    }
                }
            }
                
        },
        addCrew(data) {
            console.log(data)
            console.log('broadcasting add')
            let start = moment(data.crewStartDate)
            let startFromatted = start.format('YYYY-MM-DD HH:mm')

            let end = moment(data.crewEndDate)
            let endFromatted = end.format('YYYY-MM-DD HH:mm')

            if(moment(startFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
               moment(endFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                let usr = _.find(this.crewviewData, i => i.user_id === data.user.id)
                if(usr !== undefined) {
                    this.updateCrewViewData('create', data, null)
                }
            }
        },
        updateCrew(data) {
            console.log(data)
            console.log('broadcasting update')
            let start = moment(data.crewStartDate)
            let startFromatted = start.format('YYYY-MM-DD HH:mm')
            let oldStart = moment(data.old_start)
            let oldStartFromatted = oldStart.format('YYYY-MM-DD HH:mm')

            let end = moment(data.crewEndDate)
            let endFromatted = end.format('YYYY-MM-DD HH:mm')
            let oldEnd = moment(data.old_end)
            let oldEndFromatted = oldEnd.format('YYYY-MM-DD HH:mm')

            if(moment(startFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
               moment(endFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
               moment(oldStartFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
               moment(oldEndFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                let usr = _.find(this.crewviewData, i => i.user_id === data.user.id)
                let oldUsr = _.find(this.crewviewData, i => i.user_id === data.old_user)
                if(usr !== undefined || oldUsr !== undefined) {
                    let prev = {
                        id: data.id,
                        assignmentStart: data.old_start,
                        assignmentEnd: data.old_end,
                        userId: data.old_user,
                        calcInCalendar: data.old_calc_in_calendar,
                        status: data.old_status,
                        production: data.old_production
                    }
                    this.updateCrewViewData('update', data, prev)
                }
            }
        },
        deleteCrew(data) {
            console.log(data)
            console.log('broadcasting delete')
            let oldStart = moment(data.old_start)
            let oldStartFromatted = oldStart.format('YYYY-MM-DD HH:mm')

            let oldEnd = moment(data.old_end)
            let oldEndFromatted = oldEnd.format('YYYY-MM-DD HH:mm')

            if(moment(oldStartFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, []) ||
               moment(oldEndFromatted).isBetween(moment(this.$route.query.from), moment(this.$route.query.to), null, [])) {
                let oldUsr = _.find(this.crewviewData, i => i.user_id === data.old_user)
                if(oldUsr !== undefined) {
                    let prev = {
                        id: data.id,
                        assignmentStart: data.old_start,
                        assignmentEnd: data.old_end,
                        userId: data.old_user,
                        calcInCalendar: data.old_calc_in_calendar,
                        status: data.old_status,
                        production: data.old_production
                    }
                    this.updateCrewViewData('delete', null, prev)
                }
            }
        },
        getDefaultEmailSubject() {
            return this.notification.isCrewView ? 'Some assignments were requested' : 'Information is required'
        },
        getDefaultEmailMessage() {
            return this.notification.isCrewView ? 'Please accept / decline next assignments' : 'Please respond'
        },
        getDefaultSmsMessage() {
            return this.notification.isCrewView ? 'Please accept / decline next assignments' : 'Please respond'
        },
        assignMulti() {
            const context = this
            context.$loader.add('bookProductionCrewSubmit')
            
            if(context.isSplitPage) {
                let assignments = []
                Lazy(context.chosenUsers)
                .each(u => {
                    let usr = _.find(context.users, i => i.id == u)

                    if(usr !== undefined) {
                        let location = _.find(context.getLocations(), l => l.country === usr.country && l.default === true)
                        let skills = _.intersection(usr.skills, context.filters.skills)

                        if(location != undefined && skills.length > 0) {
                            Lazy(context.chosenProductions)
                            .each(p => {
                                assignments.push({
                                    production_id: p,
                                    start_plan_id: 0,
                                    end_plan_id: 0,
                                    skill_id: skills[0],
                                    user_id: u,
                                    status: 'option',
                                    calc_in_calendar: true,
                                    create_booking_conflict: true,
                                    location_id: location.id,
                                    is_historical: false
                                })
                                
                            })
                        }
                    }
                })
                CrewView.addMultiAssignments({ assignments: assignments, productions: context.chosenProductions })
                .then(response => {
                    console.log(response)
                    if(response.data.successfulCreated && response.data.successfulCreated.length > 0) {
                        Lazy(response.data.successfulCreated)
                        .each(c => {
                            context.updateCrewViewData('create', c, null)
                        })
                    }

                    if(response.data.failedCreated && response.data.failedCreated.length > 0) {
                        context.$alert.set('Some crew assignments were not created', 'danger', 4)
                    }

                    context.$loader.remove('bookProductionCrewSubmit')
                    context.resetChosenProductionsAndUsers()
                })
                .catch(error => {
                    console.log(error)
                    context.$error.set(error, 'It was not possible to create the crew.')
                    context.$loader.remove('bookProductionCrewSubmit')
                })
            }
            else {
                let needsDropStatus = context.filters.hasOwnProperty('productionProblems') && context.filters.productionProblems.value == 'declined'
                let assignments = _.map(context.chosenProblemProductions, 'assignment')
                CrewView.replaceMultiAssignments({assignments: assignments, user: context.chosenUsers[0], dropStatus: needsDropStatus})
                .then(response => {
                    console.log(response)
                    if(response.data.successfulUpdated && response.data.successfulUpdated.length > 0) {
                        Lazy(response.data.successfulUpdated)
                        .each(c => {
                            let prev = {
                                id: c.id,
                                assignmentStart: c.old_start,
                                assignmentEnd: c.old_end,
                                userId: c.old_user,
                                calcInCalendar: c.old_calc_in_calendar,
                                status: c.old_status,
                                production: c.old_production
                            }
                            context.updateCrewViewData('update', c, prev)
                        })
                    }

                    if(needsDropStatus && response.data.successfulCreated && response.data.successfulCreated.length > 0) {
                        Lazy(response.data.successfulCreated)
                        .each(c => {
                            context.updateCrewViewData('create', c, null)
                        })
                    }

                    if(response.data.failedUpdated && response.data.failedUpdated.length > 0) {
                        context.$alert.set('Some crew assignments were not updated', 'danger', 4)
                    }

                    context.$loader.remove('bookProductionCrewSubmit')
                    context.resetChosenProblemProductionsAndUsers()
                })
                .catch(error => {
                    console.log(error)
                    context.$error.set(error, 'It was not possible to update the crew.')
                    context.$loader.remove('bookProductionCrewSubmit')
                })
            }
            
        },
        resetChosenProductionsAndUsers() {
            this.showOnlyCheckedProductions = false
            this.showOnlyCheckedUsers = false
            this.chosenProductions = []
            this.chosenUsers = []
            Lazy(this.crewviewData)
            .each(u => {
                if(u.checked) u.checked = false
            })

            Lazy(this.productionviewData)
            .each(r => {
                if(r.rowOptions.checked) r.rowOptions.checked = false
            })
        },
        resetChosenProblemProductionsAndUsers() {
            this.showOnlyCheckedProblemProductions = false
            this.showOnlyCheckedUsers = false
            this.chosenProblemProductions = []
            this.chosenUsers = []
            Lazy(this.crewviewData)
            .each(u => {
                if(u.checked) u.checked = false
            })

            Lazy(this.problemviewData)
            .each(r => {
                //if(r.rowOptions.checked) r.rowOptions.checked = false
            })
        },
        setNewStatus(newStatus) {
            const context = this
            context.setCrewStatus.apply = newStatus
            Lazy(context.chosenProblemProductions)
            .each(p => {
                let production = _.find(context.problemviewData, d => d.id.value == p.production)

                if(production !== undefined) {
                    let assignment = _.find(production.crew, d => d.id == p.assignment)
                    if(assignment !== undefined) {
                         context.setCrewStatus.items.push({
                            assignment: assignment.id,
                            date: moment(assignment.assignmentStart).format('YYYY-MM-DD'),
                            user: assignment.userId,
                            production: assignment.production
                        })
                    }
                   
                }
                
            })

            context.applyStatus()
            
        },
        openProduction() {
            const link = {
                name: `${this.crewItemForm.formData.productionType.value}.edit`,
                params: {
                    id: this.crewItemForm.formData.production.value,
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }

            let routeData = this.$router.resolve(link);
            window.open(routeData.href, '_blank');
        },
        defaultData() {
            return {
                productionType: {
                    value: 'production',
                    local: true
                },
                startDate: {
                    value: '',
                    local: true
                },
                league: {
                    value: '',
                    local: true
                },
                itemCountry: {
                    value: '',
                    local: true
                },
                production: {
                    value: '',
                    displayValue: '',
                    //local: true
                },
                hideAssignmentData: {
                    value: true,
                    local: true
                },
                id: {
                    value: 0
                },
                startPlan: {
                    value: '',
                },
                endPlan: {
                    value: '',
                },
                userCountry: {
                    local: true,
                    value: ''
                },
                skill: {
                    value: '',
                },
                userId: {
                    value: ''
                },
                status: {
                    value: 'option',
                },
                bookingType: {
                    value: ''
                },
                locationId:{
                    value: ''
                },
                isHistorical: {
                    value: false
                }
            }
        }
    }
}

</script>

<template>
        <div id="crewviewmain" class="book-top-sticky-wrapper book-bottom-sticky-wrapper" :style="!isSinglePage ? 'overflow: hidden;' : ''">
            <div class="page-header book-top-sticky clearfix">
                <h1 class="page-title" v-if="isSinglePage">Crew view</h1>
                <div class="secondary-menu" :class="!isSinglePage ? 'full-size-menu' : ''">
                    <div :class="!isSinglePage ? 'col-sm-5 col-menu-1' : 'secondary-menu'">
                        <div class="btn-group">
                            <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-weight: bold;">
                                View: {{getViewLabel}} <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="#" @click="dateRange = 0">
                                        <span class="item-label">&nbsp;&nbsp;7 days</span>
                                    </a>
                                </li>
                                <li v-if="isSinglePage">
                                    <a href="#" @click="dateRange = 1">
                                        <span class="item-label">&nbsp;&nbsp;14 days</span>
                                    </a>
                                </li>
                                <li v-if="isSinglePage">
                                    <a href="#" @click="dateRange = 2">
                                        <span class="item-label">&nbsp;&nbsp;31 days</span>
                                    </a>
                                </li>
                                <li v-if="isSinglePage">
                                    <a href="#" @click="dateRange = 3">
                                        <span class="item-label">&nbsp;&nbsp;Custom</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <book-date-navigation
                            :default-interval="defaultRangeInterval"
                            :hideEndDate="dateRange != 3"
                            :useDefaultInterval="dateRange != 3">
                        </book-date-navigation>
                        <div class="btn-group" v-if="isSinglePage">
                            <button class="btn btn-default" v-on:click = "startDownload()">
                                <JsonExcel :data= "json_data" name="crewview.xls" :fields = "json_fields">Excel</JsonExcel>
                            </button>
                        </div>
                        <div class="btn-group" v-if="isSinglePage">
                            <button v-if="!setCrewStatus.show" class="btn btn-default  dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Set <span class="caret"></span>
                            </button>
                            <ul v-if="!setCrewStatus.show" class="dropdown-menu">
                                <li>
                                    <a href="#" @click="toggleCrewStatus('requested')">
                                        <!-- <font-awesome-icon icon="toggle-on"/> -->
                                        <span class="book-square" style="background: #f0ad4e;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set requested</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="toggleCrewStatus('option')">
                                        <span class="book-square" style="background: #286090;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set option</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="toggleCrewStatus('accepted')">
                                        <span class="book-square" style="background: #5cb85c;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set accepted</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="toggleCrewStatus('declined')">
                                        <span class="book-square" style="background: #d9534f;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set declined</span>
                                    </a>
                                </li>
                            </ul>
                            <button v-if="setCrewStatus.show" :disabled="!isApplyStatusEnabled" class="btn btn-default" @click="applyStatus()">
                                Apply
                            </button>
                            <button v-if="setCrewStatus.show" class="btn btn-default" @click="toggleCrewStatus(null, false)">
                                Cancel
                            </button>
                            <div v-if="setCrewStatus.show" class="theme-switch-wrapper theme-switch-wrapper-in-btn-group" title="Show/hide selected assignments">
                                Selected &nbsp;
                                <label class="theme-switch" for="checkboxStatusApply" >
                                <input type="checkbox" id="checkboxStatusApply" :disabled="!isApplyStatusEnabled" v-model="setCrewStatus.showCheckedOnly" @click="setCrewStatus.showCheckedOnly ? setCrewStatus.showCheckedOnly = false: setCrewStatus.showCheckedOnly = true"/>
                                <div class="slider round"></div>
                                </label>
                            </div>
                        </div>
                        <div class="btn-group" v-if="isSinglePage">
                            <div class="theme-switch-wrapper" title="Show/hide assignments for other skills">
                                Hide &nbsp;
                                <label class="theme-switch" for="checkbox" >
                                <input type="checkbox" id="checkbox" @click="hideAssignments ? hideAssignments = false: hideAssignments = true"/>
                                <div class="slider round"></div>
                                </label>
                            </div>
                        </div>
                        <div class="btn-group" >
                            <div class="theme-switch-wrapper" title="Show only selected users">
                                Show selected &nbsp;
                                <label class="theme-switch" for="checkboxCheckedUsers" >
                                    <input type="checkbox" id="checkboxCheckedUsers" v-model="showOnlyCheckedUsers" @click="showOnlyCheckedUsers ? showOnlyCheckedUsers = false: showOnlyCheckedUsers = true" :disabled="!isShowCheckedUsersEnabled"/>
                                    <div class="slider round"></div>
                                </label>
                            </div>
                        </div>
                        <div class="btn-group" role="group"  v-if="isSinglePage">
                            <router-link :to="{name: $route.name, params: {view: 'single'}, query: $route.query}" class="btn btn-default" title="Crew"><font-awesome-icon icon="bars"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'multiple'}, query: $route.query}" class="btn btn-default" title="With production list"><font-awesome-icon icon="columns"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'problem'}, query: $route.query}" class="btn btn-default" title="With problem production"><font-awesome-icon icon="list"/></router-link>
                        </div>
                    </div>
                    <div class="col-sm-2 col-menu-3 btn-group" v-if="!isSinglePage">
                        <button class="btn btn-primary" style="font-weight: bold;" :disabled="!isApplyEnabled" @click="assignMulti()">
                            &lt;&lt; Apply &gt;&gt;
                        </button>
                         <button class="btn btn-default" style="font-weight: bold;" :disabled="!isApplyEnabled" @click="resetChosenProductionsAndUsers()">
                            Cancel
                        </button>
                    </div>
                    <div class="col-sm-5 col-menu-2" v-if="isSplitPage">
                        <div class="btn-group">
                            <div class="theme-switch-wrapper" title="Show only selected productions">
                                Show selected &nbsp;
                                <label class="theme-switch" for="checkboxCheckedProductions" >
                                    <input type="checkbox" id="checkboxCheckedProductions" v-model="showOnlyCheckedProductions" @click="showOnlyCheckedProductions ? showOnlyCheckedProductions = false : showOnlyCheckedProductions = true" :disabled="!isShowCheckedProductionsEnabled"/>
                                    <div class="slider round"></div>
                                </label>
                            </div>
                        </div>
                        <book-date-navigation v-if="!isSinglePage"
                            keepRouteAsIs
                            @dateChanged="item => dateChanged(item)"
                        ></book-date-navigation>
                        <div class="btn-group" role="group">
                            <router-link :to="{name: $route.name, params: {view: 'single'}, query: $route.query}" class="btn btn-default" title="Crew"><font-awesome-icon icon="bars"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'multiple'}, query: $route.query}" class="btn btn-default" title="With production list"><font-awesome-icon icon="columns"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'problem'}, query: $route.query}" class="btn btn-default" title="With problem production"><font-awesome-icon icon="list"/></router-link>
                        </div>
                    </div>
                    <div class="col-sm-5 col-menu-2" v-if="isProblemPage">
                        <div class="btn-group">
                            <div class="theme-switch-wrapper" title="Show only selected assignments">
                                Show selected &nbsp;
                                <label class="theme-switch" for="checkboxCheckedProblemProductions" >
                                    <input type="checkbox" id="checkboxCheckedProblemProductions" v-model="showOnlyCheckedProblemProductions" @click="showOnlyCheckedProblemProductions ? showOnlyCheckedProblemProductions = false : showOnlyCheckedProblemProductions = true" :disabled="!isShowCheckedProblemProductionsEnabled"/>
                                    <div class="slider round"></div>
                                </label>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button :disabled="chosenProblemProductions.length == 0" class="btn btn-default  dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Set <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="#" @click="setNewStatus('requested')">
                                        <!-- <font-awesome-icon icon="toggle-on"/> -->
                                        <span class="book-square" style="background: #f0ad4e;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set requested</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="setNewStatus('option')">
                                        <span class="book-square" style="background: #286090;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set option</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="setNewStatus('accepted')">
                                        <span class="book-square" style="background: #5cb85c;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set accepted</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click="setNewStatus('declined')">
                                        <span class="book-square" style="background: #d9534f;"></span>
                                        <span class="item-label">&nbsp;&nbsp;Set declined</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <book-date-navigation v-if="!isSinglePage"
                            keepRouteAsIs
                            @dateChanged="item => dateChanged(item)"
                        ></book-date-navigation>
                        <div class="btn-group" role="group">
                            <router-link :to="{name: $route.name, params: {view: 'single'}, query: $route.query}" class="btn btn-default" title="Crew"><font-awesome-icon icon="bars"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'multiple'}, query: $route.query}" class="btn btn-default" title="With production list"><font-awesome-icon icon="columns"/></router-link>
                            <router-link :to="{name: $route.name, params: {view: 'problem'}, query: $route.query}" class="btn btn-default" title="With problem production"><font-awesome-icon icon="list"/></router-link>
                        </div>
                    </div>
                </div>
                
            </div>
            <book-crew-filter
                    :loading="!filterReady"
                    :options="{save: true}"
                    :filters="availableFilters"
                    @filteredBy="filters => filteredBy(filters)"
                    :info="filterInfo"
                    :isSplitPage="isSplitPage"
                    :isProblemPage="isProblemPage">
            </book-crew-filter>
            <book-loader v-if="!loaded"></book-loader>
            <div v-else class="panel panel-default" :class="!isSinglePage ? 'fixed-panel' : ''" id="crewviewstick">
                <template v-if="!isSinglePage">
                    <div :class="!isSinglePage ? 'col-sm-5 col-padding-right col-scroll-enabled' : 'col-sm-12 col-no-padding'" :style="isSplitPage || isProblemPage ? 'height: ' + scrollHeight + 'px;' : ''" id="crew">
                        <div class="panel panel-info">
                            <book-crew
                                :isSplitPage="isSplitPage"
                                :isProblemPage="isProblemPage"
                                :items="crewviewData"
                                :intervalStart="$route.query.from"
                                :intervalEnd="$route.query.to"
                                :showOnlyChecked="showOnlyCheckedUsers"
                                :showAssignmentChecked="setCrewStatus.show"
                                :showAssignmentCheckedOnly="setCrewStatus.showCheckedOnly"
                                :checkedUsers="chosenUsers"
                                @changeAssignment="item => openAssignmentForm(true, item)"
                                @notifyUser="userId => showNotificationModal(true, userId)"
                                @updateChosenUsers="item => updateChosenUsers(item)"
                                @selectAssignments="item => selectAssignments(item)">
                            </book-crew>
                        </div>
                    </div>
                    <div v-if="isSplitPage" class="col-sm-7 col-padding-left col-scroll-enabled" :style="isSplitPage || isProblemPage ? 'height: ' + scrollHeight + 'px;' : ''" id="prods">
                        <div class="panel panel-info">
                            <book-crew-productions 
                                    :items="allFilteredProductions"
                                    :checkedProductions="chosenProductions"
                                    :showOnlyChecked="showOnlyCheckedProductions"
                                    @updateChosenProductions="value => updateChosenProductions(value)">
                            </book-crew-productions>
                        </div>
                    </div>
                    <div v-else-if="isProblemPage" class="col-sm-7 col-padding-left col-scroll-enabled" :style="isSplitPage || isProblemPage ? 'height: ' + scrollHeight + 'px;' : ''" id="prods">
                        <div class="panel panel-info">
                            <book-crew-problematic 
                                    :items="allFilteredProblemProductions"
                                    :checkedProductions="chosenProblemProductions"
                                    :showOnlyChecked="showOnlyCheckedProblemProductions"
                                    @updateChosenProductions="value => updateChosenProblemProductions(value)"
                                    @openAssignment="item => openAssignmentForm(true, item)"
                                    @notifyUser="user => showNotificationModalSingle(true, user)"
                                    @openCalendar="userId => openCalendar(userId)"
                                    @deleteAssignment="item => deleteProductionCrew(item)">
                            </book-crew-problematic>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <book-crew
                        :isSplitPage="isSplitPage"
                        :items="crewviewData"
                        :intervalStart="$route.query.from"
                        :intervalEnd="$route.query.to"
                        :showOnlyChecked="showOnlyCheckedUsers"
                        :showAssignmentChecked="setCrewStatus.show"
                        :showAssignmentCheckedOnly="setCrewStatus.showCheckedOnly"
                        :checkedUsers="chosenUsers"
                        @changeAssignment="item => openAssignmentForm(true, item)"
                        @notifyUser="userId => showNotificationModal(true, userId)"
                        @updateChosenUsers="item => updateChosenUsers(item)"
                        @selectAssignments="item => selectAssignments(item)">
                    </book-crew>
                </template>
            </div>

            <!-- <template v-else>
                <div v-if="!isSplitPage" class="panel panel-default">
                    <book-crew
                        :items="crewviewData"
                        :intervalStart="$route.query.from"
                        :intervalEnd="$route.query.to"
                        @changeAssignment="item => openAssignmentForm(true, item)"
                        @notifyUser="userId => showNotificationModal(userId)">
                    </book-crew>
                </div>
                <div v-else class="col-sm-6">
                    <div class="panel panel-default">
                        <book-crew
                            :items="crewviewData"
                            :intervalStart="$route.query.from"
                            :intervalEnd="$route.query.to"
                            @changeAssignment="item => openAssignmentForm(true, item)"
                            @notifyUser="userId => showNotificationModal(userId)">
                        </book-crew>
                    </div>
                </div>
                <div v-if="isSplitPage" class="col-sm-6">
                    <div class="panel panel-default col-sm-6" >
                        <book-crew-productions 
                            :items="allProductions"
                            @updateChosenProductions="value => updateChosenProductions(value)"></book-crew-productions>
                    </div>
                </div>
            </template> -->
            <book-modal maxWidth="500px" @close="openAssignmentForm(false)" v-if="crewItemForm.show">
                <h4 slot="header" class="modal-title">
                    <span v-if="!crewItemForm.formData.id.value">Add</span>
                    <span v-else>Edit / Delete
                        </span> crew member 
                    <!-- <div class="book-action-buttons" v-if="crewItemForm.formData.id.value"> -->
                        <button 
                                v-if="crewItemForm.formData.id.value && $ability.can('delete_production_crew', 'Productions') && !crewItemForm.formData.isHistorical.value"
                                @click="deleteProductionCrew()"
                                class="btn btn-xs btn-danger"
                                style="margin-top: -3px;">
                            <font-awesome-icon icon="trash"/>
                        </button>
                    <!-- </div> -->
                </h4>
                <div slot="body">
                    <book-loader v-if="!crewItemForm.loaded"></book-loader>
                    <div v-else class="form-horizontal" data-vv-scope="crewItem">
                        <div class="form-group form-group-sm">
                            <label class="control-label control-label-100">
                                <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                Type
                            </label>
                            <div class="form-control-100">
                                <select tabindex="1" class="form-control" v-model="crewItemForm.formData.productionType.value" :disabled="crewItemForm.formData.isHistorical.value">
                                    <option value="production">Production</option>
                                    <option value="edit">Edit</option>
                                    <option value="task">Task</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group form-group-sm">
                            <label class="control-label control-label-100">
                                <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                Start date
                            </label>
                            <book-datetime
                                class="form-control-100"
                                component-id="date"
                                :required="{date: true}"
                                :hide-time="true"
                                :default-value="crewItemForm.formData.startDate.value"
                                @newValue="value => crewItemForm.formData.startDate.value = value"
                                disabled>
                            </book-datetime>
                        </div>
                        <div class="form-group form-group-sm">
                            <label class="control-label control-label-100">
                                League
                            </label>
                            <div class="form-control-100">
                                <book-selectbox
                                        componentId="league-id"
                                        :items="leagueEntity.get.items"
                                        :selected="crewItemForm.formData.league.value"
                                        @selectedItem="id => crewItemForm.formData.league.value = id"
                                        :disabled="crewItemForm.formData.isHistorical.value">
                                </book-selectbox>
                            </div>
                        </div>
                        <div class="form-group form-group-sm">
                            <label class="control-label control-label-100">
                                Country
                            </label>
                            <div class="form-control-100">
                                <select class="form-control" name="itemCountry" v-model="crewItemForm.formData.itemCountry.value" :disabled="crewItemForm.formData.isHistorical.value">
                                    <option value="">None</option>
                                    <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group form-group-sm">
                            <label class="control-label control-label-100">
                                <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                Item
                            </label>
                            <div class="form-control-100">
                                <select class="form-control" name="production" v-model="crewItemForm.formData.production.value" :disabled="crewItemForm.formData.isHistorical.value" style="width: calc(100% - 35px) !important; display: inline-block;">
                                    <option value="">None</option>
                                    <option v-for="production in productions" :value="production.id">{{getProductionLabel(production)}}</option>
                                </select>
                                <button style="height: 30px; width: 30px;"
                                    @click="openProduction()"
                                    class="btn btn-xs btn-primary"
                                    :disabled="!crewItemForm.formData.production.value || crewItemForm.formData.production.value == ''"
                                >
                                    <font-awesome-icon icon="pencil-alt"/>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                        <template v-if="!crewItemForm.formData.hideAssignmentData.value">
                            <div class="form-group form-group-sm">
                                <label class="control-label control-label-100">
                                    <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                    Start
                                </label>
                                <div class="form-control-100">
                                    <select tabindex="1" class="form-control" v-model="crewItemForm.formData.startPlan.value" :disabled="$ability.cannot('update_production_crew', 'Productions') || crewItemForm.formData.isHistorical.value">
                                        <option v-for="planItem in getStartPlans" :key="planItem.id" :value="planItem.id">{{getPlanItemLabel(planItem, 'start')}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm">
                                <label class="control-label control-label-100">
                                    <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                    End
                                </label>
                                <div class="form-control-100">
                                    <select tabindex="1" class="form-control" v-model="crewItemForm.formData.endPlan.value" :disabled="$ability.cannot('update_production_crew', 'Productions') || crewItemForm.formData.isHistorical.value">
                                        <option v-for="planItem in getEndPlans" :key="planItem.id" :value="planItem.id">{{getPlanItemLabel(planItem, 'end')}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm">
                                <label for="name" class="control-label control-label-100">
                                    Country
                                </label>
                                <div class="form-control-100">
                                    <select class="form-control" name="country" v-model="crewItemForm.formData.userCountry.value" :disabled="$ability.cannot('update_production_crew', 'Productions') || crewItemForm.formData.id.value == 0 || crewItemForm.formData.isHistorical.value" @change="setLocation()">
                                        <option value="">None</option>
                                        <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('crewItem.skill')}">
                                <label for="name" class="control-label control-label-100">
                                    <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                                    Skill
                                </label>
                                <div class="form-control-100">
                                    <select tabindex="1" class="form-control" name="skill" v-validate v-model="crewItemForm.formData.skill.value" :disabled="crewItemForm.formData.isHistorical.value">
                                        <option value="">None</option>
                                        <option v-for="skill in getSkillsList" :value="skill.id">{{skill.label}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm">
                                <label for="name" class="control-label control-label-100">
                                    User
                                </label>
                                <div class="form-control-100">
                                    <book-selectbox
                                        componentId="crew-view"
                                        :loading="retrievingCrew || retrievingAvailableCrew"
                                        :items="getCrewList"
                                        :selected="crewItemForm.formData.userId.value != '' ? crewItemForm.formData.userId.value : 0"
                                        :disabled="!crewItemForm.formData.skill.value || crewItemForm.formData.id.value == 0 || crewItemForm.formData.isHistorical.value"
                                        @selectedItem="id => crewItemForm.formData.userId.value = id">
                                    </book-selectbox>
                                </div>
                            </div>
                            <div class="form-group form-group-sm">
                                <label for="name" class="control-label book-width-100">
                                    Location
                                </label>
                                <div class="form-control-100">
                                    <!--<select class="form-control" name="location_id" v-model="crewItemForm.formData.location_id.value" :disabled="$ability.cannot('update_production_crew', 'Productions')">-->
                                    <select class="form-control" v-model="crewItemForm.formData.locationId.value" :disabled="$ability.cannot('update_production_crew', 'Productions') || crewItemForm.formData.isHistorical.value">
                                        <option value="">None</option>
                                        <option v-for="location in getLocations()"
                                                :selected="location.country === crewItemForm.formData.userCountry.value && location.default === true"
                                                :value="location.id">
                                            {{location.label}} ({{location.countryUpper ? location.countryUpper : '--'}})
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm">
                                <label for="name" class="control-label control-label-100">
                                    Status
                                </label>
                                <div class="form-control-100">
                                    <select tabindex="1" class="form-control" name="status" v-validate v-model="crewItemForm.formData.status.value" :disabled="crewItemForm.formData.isHistorical.value">
                                        <option v-if="!$ability.cannot('update_production_crew', 'Productions')" value="option">Option</option>
                                        <option v-if="!$ability.cannot('update_production_crew', 'Productions')" value="requested">Requested</option>
                                        <option value="accepted">Accepted</option>
                                        <option value="declined">Declined</option>
                                        <option v-if="!$ability.cannot('update_production_crew', 'Productions')" value="not_available">Not available</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-group-sm" v-if="showBookingType">
                                <label for="name" class="control-label book-width-100">
                                    Booking type
                                </label>
                                <div class="form-control-100">
                                    <!--<select class="form-control" name="location_id" v-model="crewItemForm.formData.location_id.value" :disabled="$ability.cannot('update_production_crew', 'Productions')">-->
                                    <select class="form-control" v-model="crewItemForm.formData.bookingType.value" :disabled="$ability.cannot('update_production_crew', 'Productions') || crewItemForm.formData.isHistorical.value">
                                        <option value="" v-if="bookingTypes.length === 0">None</option>
                                        <option v-for="bookingType in bookingTypes"
                                                :value="bookingType.id"
                                                :title="bookingType.description">
                                            {{bookingType.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="book-help-message bg-danger text-danger" v-if="!retrievingAvailableCrew && !availableCrew && crewItemForm.formData.skill.value != ''">
                                No user available with the selected skill
                            </div>
                            <!-- <div class="book-help-message bg-danger text-danger" v-if="invalidDate">
                                "End" time should always be greater than "start" time
                            </div> -->
                        </template>
                    </div>
                </div>
                <div slot="footer">
                    <div class="book-modal-footer-loading" v-if="processing">
                        <book-loader :small="true"></book-loader>
                        <span class="book-modal-footer-loading-text">saving</span>
                    </div>
                    <button tabindex="1" class="btn btn-success" @click="submit()" :disabled="(!isFormValid() || !isFormChanged) && !processing">Save</button>
                </div>
            </book-modal>
            <book-notification
                v-if="notification.show"
                componentId="bookCrewView"
                :modalTitle="notification.modalTitle"
                :recipients="notification.recipients"
                :groupBy="notification.groupBy"
                :groupMessage="notification.groupMessage"
                :defaultEmailSubject="notification.defaultSubject != '' ? notification.defaultSubject : getDefaultEmailSubject()"
                :defaultEmailMessage="notification.defaultEmailMessage != '' ? notification.defaultEmailMessage : getDefaultEmailMessage()"
                :defaultSmsMessage="getDefaultSmsMessage()"
                :isCrewView="notification.isCrewView"
                @close="notification.show = false"
                @send="item => sendMultiNotifications(item)">
            </book-notification>
        </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewviewmain {
    // .book-date-navigation {
    //     float: right;
    // }
    // .btn-navigation {
    //     margin-top: 5px;
    // }
    // .panel-default {
    //     margin-top: 27px;
    // }

    .book-square {
        border-radius: 3px;
        display: inline-block;
        height: 10px;
        width: 10px;
    }

    .panel-split {
        top: 0;
    }

    .full-size-menu {
        width: 100%;
    }

    .col-menu-1 {
        //padding-left: calc(100% / 12 * 4 - 234px);
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
    }

    .col-menu-2 {
        //padding-left: calc(100% / 12 * 4 - 287px);
        padding-left: 0px;
        padding-right: 0px;
        text-align: right;
    }

    .col-menu-3 {
        text-align: center;
    }

    .col-no-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-padding-right {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-padding-left {
        padding-left: 8px;
        padding-right: 0px;
    }

    .col-apply {
        //width: 121px;
        text-align: center;
    }
    .crew-apply {
        
    }

    .crew-split,
    .col-scroll-enabled {
        //position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .flex-fill {
        //flex:1;
    }

    .fixed-panel {
        //margin: 0;
        //overflow: hidden;
        // height: 100%;
    }

    .theme-switch-wrapper-in-btn-group {
        border: 1px solid #ccc;
        padding-top: 6px;
        padding-bottom: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        float: left;
        margin-left: -1px;
    }
}

</style>