<script>
import Notification from 'components/notification/Notification'
import BookLoader from 'components/common/loader/BookLoader'
import BookModal from 'components/common/BookModal'

export default {
    components: {
        BookLoader,
        BookModal
    },
    props: {
        componentId: {
            default: '',
            type: String
        },
        modalTitle: {
            default: 'Send message',
            type: String
        },
        recipients: {
            default: () => [],
            type: Array
        },
        fields: {
            default: () => {
                return {
                    email: 'email',
                    sms: 'phone'
                }
            },
            type: Object
        },
        groupMessage: {
            default: '',
            type: String
        },
        groupBy: {
            default: '',
            type: String
        },
        defaultEmailSubject: {
            default: '',
            type: String
        },
        defaultEmailMessage: {
            default: '',
            type: String
        },
        defaultSmsMessage: {
            default: '',
            type: String
        },
        selectedItem: {
            default: () => {},
            type: Object
        },
        isLog: {
            default: false,
            type: Boolean
        },
        isCrewView: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            formData: this.defaultData(),
            processedRecipients: null,
            show: false,
            loaded: false,
            hiddenRecipients: false
        }
    },
    computed: {
        submitting() {
            return this.$loader.has('bookNotificationSubmit')
        },
        isFormValid() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].valid
            })
        },
        isFormClean() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        },
        getProcessedRecipients() {

            this.hiddenRecipients = false

            if (this.formData.type.value != 'sms') {
                return this.processedRecipients
            }

            // If sending SMS, retrieve the recipients with telephone numbers
            // that the current user is allowed to access.
            if (this.groupBy) {
                const filteredGroups = {}
                Lazy(this.processedRecipients)
                    .each((group, groupName) => {
                         let filteredGroup = group.filter(v => v.phone)
                         if (filteredGroup.length > 0) {
                            filteredGroups[groupName] = filteredGroup
                            this.hiddenRecipients = filteredGroups[groupName].length != this.processedRecipients[groupName].length
                         }
                    })


                return filteredGroups
            }
            return this.processedRecipients
                .filter(v => {
                    if (!v.phone) {
                        this.hiddenRecipients = true
                    }
                    return v.phone
                })

        },
        getSendData() {
            const output = {
                type: this.formData.type.value,
                subject: this.formData.subject.value,
                body: this.formData.message.value,
                recipients: []
            }
            if (!this.groupBy) {
                output.recipients = this.getProcessedRecipients.slice()
            }
            else {
                if (this.formData.recipientGroup.value == 'all') {
                    Lazy(this.getProcessedRecipients)
                        .each(v => {
                            output.recipients = output.recipients.concat(v.slice())
                        })
                }
                else {
                    output.recipients = this.getProcessedRecipients[this.formData.recipientGroup.value].slice()
                }
            }
            output.recipients = Lazy(output.recipients).sortBy(v => v.label).toArray()
            return output
        },
    },
    watch: {
        'formData.type.value': function(newValue) {
            this.setDefaultValues()

            // Need to use a double-tick here due to portals.
            Vue.nextTick(() => {
                Vue.nextTick(() => {
                    // Set focus on right message field.
                    switch (newValue) {
                        case 'email':
                            this.$refs.emailMessage.focus()
                            break
                        case 'sms':
                            this.$refs.smsMessage.focus()
                            break
                    }

                    // We need a third Vue.nextTick() here since the values on
                    // this.setDefaultValues() are applied in "first one" and we
                    // need to clear the erros after that.
                    Vue.nextTick(() => {
                        this.formErrors.clear()
                    })
                })
            })

        }
    },
    mounted() {
        this.processRecipients()
        this.setDefaultValues()
    },
    methods: {
        resetForm() {
            this.formData = this.defaultData()
        },
        getClass(item) {
            const output = []
            if (this.groupBy && (this.groupBy == 'status')) {
                let colorId = 0
                switch (item[this.groupBy]) {
                    case 'option':
                        colorId = 5 // Blue.
                        break
                    case 'requested':
                        colorId = 4 // Yellow.
                        break
                    case 'accepted':
                        colorId = 2 // Green.
                        break
                    case 'declined':
                        colorId = 3 // Red.
                        break
                }
                output.push(`book-item-status-${colorId}`)
            }
            return output
        },
        processRecipients() {
            if (this.groupBy) {
                this.processedRecipients = {}
                this.recipients.forEach(v => {
                    let field = v[this.groupBy]
                    if (!this.processedRecipients.hasOwnProperty(field)) {
                        Vue.set(this.processedRecipients, field, [])
                    }
                    this.processedRecipients[field].push(Object.assign({}, v))
                })
            }
            else {
                this.processedRecipients = this.recipients.slice()
            }
            this.loaded = true
        },
        send() {
            if(this.isCrewView) {
                this.$emit('send', this.getSendData)
            }
            else {
              this.$loader.add('bookNotificationSubmit')

              const file = this.$refs.attachment && this.$refs.attachment.files && this.$refs.attachment.files.length > 0 ? this.$refs.attachment.files[0] : null;

              const data = {
                ...this.getSendData,
                attachment: file ?? null
              }

              Notification.send(data)
                  .then(response => {
                    this.$alert.set(response.data, 'success', 6)
                    this.$emit('close')
                    this.$loader.remove('bookNotificationSubmit')
                  })
                  .catch(error => {
                    this.$error.set(error, 'It was not possible to send a notification.')
                    this.$loader.remove('bookNotificationSubmit')
                  })
            }
           
        },

        /**
         * Set default message based on the current selected "type".
         */
        setDefaultValues() {
            if(!this.isLog) {
                switch (this.formData.type.value) {
                    case 'sms':
                        this.formData.subject.value = ''
                        this.formData.message.value = this.defaultSmsMessage ? this.defaultSmsMessage : ''
                        break
                    case 'email':
                        this.formData.subject.value = this.defaultEmailSubject ? this.defaultEmailSubject : ''
                        this.formData.message.value = this.defaultEmailMessage ? this.defaultEmailMessage : ''
                        break
                }
            }
            else {
                this.formData.type.value = this.selectedItem.type.value
                this.formData.subject.value = this.selectedItem.subject.value
                this.formData.message.value = this.selectedItem.message.value
                this.formData.recipient.value = this.selectedItem.recipient.displayValue.split(',')
            }
            
        },

        /**
         * Default form data.
         */
        defaultData() {
            return {
                recipientGroup: {
                    value: 'all'
                },
                type: {
                    value: 'email'
                },
                subject: {
                    value: '',
                    validator: 'required'
                },
                message: {
                    value: '',
                    validator: 'required'
                },
                recipient: {
                    value: []
                }
            }
        }

    }
}
</script>

<template>
<book-modal class="book-notification-modal" maxWidth="500px" @close="$emit('close')">
    <h4 slot="header" class="modal-title">
        {{modalTitle}}
    </h4>
    <div slot="body">
        <book-loader v-if="!loaded"></book-loader>
        <div v-else-if="(!getProcessedRecipients || getProcessedRecipients.length == 0) && !isLog" class="text-center">
            No recipients are available
        </div>
        <div v-else class="book-normal-forms">
            <div class="book-radio-tabs book-notification-type form-group">
                <div class="radio-tab" :class="{'active': formData.type.value == 'email'}">
                    <label>
                        <input type="radio" value="email" v-model="formData.type.value" :disabled="isLog">
                        E-mail
                    </label>
                </div>
                <div class="radio-tab" :class="{'active': formData.type.value == 'sms'}">
                    <label>
                        <input type="radio" value="sms" v-model="formData.type.value" :disabled="isLog">
                        SMS
                    </label>
                </div>
            </div>
            <div class="form-group book-notification-group" v-if="groupBy && Object.keys(getProcessedRecipients).length > 1">
                <div class="book-notification-group-message" v-if="groupMessage">
                    {{groupMessage}}
                </div>
                <label class="book-notification-group-items-label">
                    {{groupBy}}:
                </label>
                <div class="radio-inline book-notification-group-all">
                    <label>
                        <input type="radio" value="all" v-model="formData.recipientGroup.value">
                        all
                    </label>
                </div>
                <div class="radio-inline" v-for="group in Object.keys(getProcessedRecipients)">
                    <label>
                        <input type="radio" :value="group" v-model="formData.recipientGroup.value">
                        {{group}}
                    </label>
                </div>
            </div>
            <div class="panel panel-default form-group book-notification-recipients-panel">
                <div class="panel-heading">
                    Recipients
                    (<span v-if="formData.type.value == 'sms'">SMS</span>
                    <span v-else>E-mail</span>)
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <tbody v-if="!isLog" >
                            <tr v-for="recipient in getSendData.recipients">
                                <td>
                                    {{recipient.label}}
                                </td>
                                <td>
                                    <span v-if="formData.type.value == 'sms'">{{recipient[fields.sms]}}</span>
                                    <span v-else>{{recipient[fields.email]}}</span>
                                </td>
                                <td v-if="groupBy" :class="getClass(recipient)" class="text-right">
                                    <span v-if="groupBy == 'status'" :title="recipient[groupBy]"><span class="book-square"></span></span>
                                    <span v-else>{{recipient[groupBy]}}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="recipient in formData.recipient.value">
                                <td>
                                    {{recipient}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <em v-if="hiddenRecipients">
                One or more crew members don't have a public phone number and you're not allowed to contact them.
            </em>
            <div class="form-group" :class="{'has-error': formErrors.has('subject')}" v-if="formData.type.value == 'email'">
                <label for="name" class="control-label">
                    Subject
                </label>
                <input type="text" class="form-control" name="subject" v-validate="formData.subject.validator" v-model="formData.subject.value" :disabled="isLog" />
            </div>
            <div class="form-group" :class="{'has-error': formErrors.has('message')}">
                <label for="name" class="control-label">
                    Message
                </label>
                <textarea rows="3" class="form-control" name="message" ref="smsMessage" v-if="formData.type.value == 'sms'" v-validate="formData.message.validator" v-model="formData.message.value" :disabled="isLog"></textarea>
                <textarea rows="12" class="form-control" name="message" ref="emailMessage" v-else v-validate="formData.message.validator" v-model="formData.message.value" :disabled="isLog"></textarea>
            </div>

            <div class="form-group" v-if="formData.type.value === 'email'">
              <label for="name" class="control-label">
                Attachment
              </label>

              <input type="file" accept=".pdf,.doc,.docx,.csv,.xlsx,.xls" ref="attachment" />
            </div>

        </div>
    </div>
    <div slot="footer">
        <div class="book-modal-footer-loading" v-if="submitting">
            <book-loader :small="true"></book-loader>
            <span class="book-modal-footer-loading-text">sending...</span>
        </div>
        <button v-if="!isLog" tabindex="1" class="btn btn-success" @click="send()" :disabled="!getProcessedRecipients || !isFormValid || isFormClean || submitting">Send</button>
    </div>
</book-modal>
</template>

<style lang="scss">

@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-notification-modal {
    .book-notification-recipients-panel {
        .panel-heading {
            font-weight: 600;
        }
        .table-responsive {
            max-height: 100px;
        }
        .book-square {
            display: inline-block;
            height: 15px;
            vertical-align: middle;
            width: 15px;
        }
    }
    * + .book-notification-recipients-panel {
        margin-top: 14px;
    }
    .book-notification-group {
        margin-bottom: 10px !important;
        .book-notification-group-message {
            display: block;
            font-weight: 600;
            margin-bottom: 0;
        }
        .book-notification-group-items-label {
            display: inline-block;
            font-weight: 600;
            margin: 5px 5px 0 0;
            text-transform: capitalize;
            vertical-align: middle;
        }
        .book-notification-group-all label {
            // font-weight: 600 !important;
        }
        .radio-inline {
            // padding-top: 5px;
            label {
                font-weight: 400;
                // margin-bottom: 0;
            }
            input {
                margin-top: 3px;
            }
        }
    }
    .book-notification-type {
        margin-left: -15px !important;
        margin-right: -15px !important;
        padding: 0 15px;
    }
    .book-notification-type {
        // float: right;
        // margin-bottom: 3px !important;


        border-bottom: 1px solid $color-grey-border;


    }
    * + .book-notification-type {
        margin-top: 15px !important;
    }
    // .book-notification-type-content {
    //     margin-bottom: -15px !important;
    //     padding-bottom: 15px;
    // }

    .control-label {
        margin-bottom: 5px !important;
    }

}
</style>
