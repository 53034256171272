import store from 'store'
const cachios = require('cachios')

class Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(options) {


        const context = this

        context.options = options
        let index = context.getIndex()


        if ( (context.options.id == 0) || (context.options.id && index == -1) ) {                   //index=0

            context.add()
        }
    }

    /**
     * Create a new item.
     * @param array params
     * @return promise
     */
    store(params = []) {
        const context = this
        let promise = new Promise((resolve, reject) => {
            let url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl
            if (Array.isArray(params) && params.length > 0) {
                let newParams = Lazy(params)
                    .map(v => {
                        let key = Object.keys(v)[0]
                        return `${key}=${v[key]}`
                    })
                url += `?${newParams.join('&')}`
            }
            axios.post(url, store.state.data[context.options.module][context.getIndex()])
                .then(response => {
                    context.remove(false)
                    context.add(response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return promise
    }

    /**
     * Save the entity item model into the API.
     * @return promise
     */
    save() {
        const context = this
        let id = this.options.id
        let index = context.getIndex()
        let _saving = store.state.data[context.options.module][index]
        let _url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl
        return new Promise((resolve, reject) => {

            axios.put(_url + '/' + id, _saving)
            .then(response => {
                context.add(response.data)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })

        })

    }

    /**
     * Save without Contributions and Associations required for
     * House Number saving in the MCR-View page
     * @returns {Promise<unknown>}
     */
    saveIn() {
        const context = this
        let id = this.options.id
        let index = context.getIndex()
        let _saving = store.state.data[context.options.module][index]
        // contributions and associations are giving JSON circular error WN
        //console.log(_saving, 'what are you?')
        //_saving.contributions = null
        //_saving.associations = null
        let _url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl

        return new Promise((resolve, reject) => {

            axios.put(_url + '/' + id, _saving)
                .then(response => {
                    context.add(response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })

        })

    }

    /**
     * Send UPDATES to broadcast, so everyone can get
     * the bulk of updates and load it faster in one call,
     * instead of bulk of item lengths
     */
    sendUpdates() {
        const context = this
        let id = this.options.id
        let index = context.getIndex()
        let _saving = store.state.data[context.options.module][index]
        let _url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl
        return new Promise((resolve, reject) => {
            axios.put(_url + '/' + id, _saving)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })

        })

    }

    /**
     * Return data for the current instance.
     * @return object
     */
    get get() {
        let i = this.getIndex()
        return i > -1 ? store.state.data[this.options.module][i] : null
    }

    /**
     * Set a value to a given field on the data model.
     * @param string field The name  of the user field.
     * @param string value The value to set.
     */
    set(field, value) {
        store.commit('setField', {key: this.options.module, id: this.options.id, field: field, value: value})
    }

    /**
     * Add an item with all fields to the store.
     */
    add(data = {}) {
        const context = this
        if (!data.id) data.id = context.options.id
        store.commit('add', {key: context.options.module, data: data})
    }

    /**
     * Return all items.
     */
    all(params = {}, reset = false, ttl = 2) {
        const context = this
        return new Promise((resolve, reject) => {
            cachios.get(context.options.apiUrl, {params: params, ttl: ttl})
            //axios.get(context.options.apiUrl, {params: params})
                .then(response => {
                    if (response.status === 200) {
                        store.commit('setData', {key: context.options.module, data: response.data, reset: reset})
                    }
                    resolve(response)
                })
                .catch(error => {
                    store.commit('setData', {key: context.options.module, data: [], reset: reset})
                    reject(error)
                })
        })
    }

    allSet(data, reset = false) {
        const context = this
        store.commit('setData', {key: context.options.module, data: data, reset: reset})
    }

    /**
     * Load an item from the API.
     * @param integer id The item id to load.
     * @return promise
     */
    load(id = null, params = [], force = false) {
        if (!id) id = this.options.id
        const context = this
        return new Promise((resolve, reject) => {
            // axios.get(context.options.apiUrl + '/' + id, {params: params})
            cachios.get(context.options.apiUrl + '/' + id, {params: params, force: force, ttl: 10, maxContentLength: Infinity,
                maxBodyLength: Infinity})
                .then(response => {
                    if (response.data) {
                        store.commit('add', {key: context.options.module, data: response.data})
                    }
                    resolve(response)
                    reject("Not able to load data, something wrong", response.data)

                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Delete an item calling the API.
     * @return promise
     */
    delete(remove = true) {
        const context = this
        const id = context.options.id
        let url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl
        return new Promise((resolve, reject) => {
            axios.delete(url + '/' + id)
                .then(response => {
                    if (response.status === 200) {
                        context.remove(remove)
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Delete an item calling the API without triggering Broadcast
     * @return promise
     */
    deleteSilent(remove = true) {
        const context = this
        const id = context.options.id
        let url = this.options.typeUrl ? this.options.typeUrl : this.options.apiUrl
        return new Promise((resolve, reject) => {
            axios.delete(url + '/' + id, { data: { no_broadcast: true } })
                .then(response => {
                    if (response.status === 200) {
                        context.remove(remove)
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Remove an item from store.
     * The difference between this method and delete()
     * is that "delete()" calls the API and "remove()" only removes the current
     * item instance form store, but doesn't call the API. This is useful when
     * an item is deleted and the action is broadcasted through the socket.
     * @param boolean remove Remove the data container.
     * @return void
     */
    remove(remove) {
        store.commit('remove', {key: this.options.module, id: this.options.id, remove: remove})
    }

    /**
     * Remove all items from store.
     * The difference between this method and delete()
     * is that "delete()" calls the API and "remove()" only removes all
     * items form store, but doesn't call the API. This is useful when
     * we need to reload data.
     * 
     * @return void
     */
    removeAll() {
        store.commit('removeData', {key: this.options.module})
    }

    /**
     * Get the index of the current item instance inside the store object.
     * @return integer
     */
    getIndex() {
        const context = this
        if (typeof store.state.data[context.options.module] !== 'object') {
            return -1
        }
        return store.state.data[context.options.module].findIndex(v => v.id === context.options.id)
    }

    /**
     * Sort the data list by a given field.
     * @param string field The field to sort by.
     */
    sortBy(field) {
        const context = this
        store.commit('sortDataBy', {key: context.options.module, field: field})
    }

}

export default Api
