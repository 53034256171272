import {app} from 'app'
import Datetime from 'components/common/helpers/Datetime'
import Item from 'components/common/item/Item'
import Production from 'components/production/Production'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import ProductionMixin from 'components/production/ProductionMixin'
import BookLoader from 'components/common/loader/BookLoader'
import BookFilter from 'components/common/filter/BookFilter'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookModal from 'components/common/BookModal'
import BookOverview from 'components/common/BookOverview'
import BookTable from 'components/common/BookTable'
import BookTimeline from 'components/common/BookTimeline'
import BookSidebarProduction from 'components/production/BookSidebarProduction'
import ItemsMixin from 'components/common/item/ItemsMixin'
import BookForkPlaceholder from 'components/forkplaceholder/BookForkPlaceholder'
import BookProductionsMenu from 'components/production/Productions/BookProductionsMenu'

import productionTypes from '../productionTypes'

export default {
    mixins: [
        ItemsMixin,
        ProductionMixin
    ],
    components: {
        BookLoader,
        BookFilter,
        BookDateNavigation,
        BookModal,
        BookOverview,
        BookTable,
        BookTimeline,
        BookSidebarProduction,
        BookForkPlaceholder,
        BookProductionsMenu,
    },
    props: {
        type: {
            type: String,
            default: "production",
        },
    },
    data() {
        return {

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'production',
                items: 'productions',
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    channelEntity: 'channel_entity_id',
                    runTypeEntity: 'runtype_entity_id',
                    locationEntity: 'location_entity_id',
                    planTypeEntity: 'plantype_entity_id',
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id'
                }
            },

            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text',
                    active: this.filterActive('search')
                },
                country: {
                    label: 'Countries',
                    items: [],
                    active: this.filterActive('country')
                },
                sport: {
                    label: 'Sports',
                    items: [],
                    class: 'sportEntity',
                    active: this.filterActive('sport'),
                },
                league: {
                    label: 'Leagues',
                    items: [],
                    class: 'leagueEntity',
                    active: this.filterActive('league')
                },
                channel: {
                    label: 'Channels',
                    items: [],
                    class: 'channelEntity',
                    active: this.filterActive('channel'),
                },
                runType: {
                    label: 'Run Type',
                    items: [],
                    class: 'runTypeEntity',
                    active: this.filterActive('runType')
                },
            },

            dateFrom: {
                time: ''
            },
            dateTo: {
                time: ''
            },
            applyTemplateForm: {
                show: false,
                selection: '',
                item: '',
                runType: '',
                league: ''
            },
            templateForm: {
                show: false,
                selection: '',
                runType: '',
                league: ''
            },
            isShowMegaView: false,
            saveTemplateForm: {
                show: false,
                loading : false,
                saveItem: null,
                name: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                id: {
                    value: 0
                },
                template: null
            },
            template: {
                user: {
                    value: ''
                },
                title: {
                    value: ''
                },
                global: {
                    value: false
                },
                data: {
                    league: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    productionLeader: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    productionType: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    runType: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    channel: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    plan: {
                        data: true,
                        value: []
                    },
                    crew: {
                        data: true,
                        value: []
                    },
                    audioLayout: {
                        data: true,
                        value: ''
                    },
                    notes: {
                        data: true,
                        value: ''
                    },
                    comments: {
                        data: true,
                        value: ''
                    },
                    contacts: {
                        data: true,
                        value: ''
                    },
                    equipment: {
                        data: true,
                        value: [],
                    },
                },
                itemType: {
                    value: 'production'
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                }
            },
            placeholderForm: null,
            showPlaceholderForm: false
        }
    },
    computed: {
        productions() {
            const context = this
            if (!context.loaded || !this.$store.state.data.production || !context.filterReady) return []
            return this.$store.state.data.production
                .filter(v => {
                    // On timeline view, only productions with start/end inside the current
                    // "from" day should be visible.
                    if (context.$route.params.view == 'timeline') {
                        if (v.startTime > context.timeline.endTimestamp || v.endTime < context.timeline.startTimestamp) {
                            return false
                        }
                    }
                    //console.log("computed productions")
                    return Item.isFiltered(context.filters, v, {league: context.leagueEntity, sport: context.sportEntity, channel: context.channelEntity})
                })
                .map(v => {

                    // Add an extra field if on Edit section.
                    if (context.isEditProductions) {
                        const items = context.getEquipmentByType(v, this.$settings.get.equipment.editsuites_entityitem_id)
                        if (items) {
                            Vue.set(v, 'editSuites', items)
                        }
                    }

                    // Add an extra field if on ENG section.
                    if (context.isEngProductions) {
                        const items = context.getEquipmentByType(v, this.$settings.get.equipment.eng_entityitem_id)
                        if (items) {
                            Vue.set(v, 'engItems', items)
                        }
                    }

                    if(v.crew && v.crew.length > 0) {
                        let filteredCrew = v.crew.filter(i => !i.isHistorical)
                        Vue.set(v, 'crew', filteredCrew)
                    }

                    return v
                })

        },
        equipmentListData(){
            const context = this
            const output = {}
            if (!context.loaded) return output
            
            output.type = "engOrEdit";
            
            output.rows = [];

            context.productions.forEach(v => {
                v.equipment.forEach(k => {
                    // Adds the first equipment usage created for this production as group, unless the group is
                    // already added.
                    if (output.rows.filter(t => t.id == k.id).length == 0 && v.equipmentLabel === k.id)
                    output.rows.push(k);
                })
            })
//            console.log(output, '<---');
            return output
            
        },
        productionsListData() {
            const context = this
            const output = {}
            if (!context.loaded) return output

            // Build options.
            output.options = {
                actions: ['edit', 'preview', 'template', 'apply', 'clone', 'delete'],
                ingestions: ['notrecord', 'repeat', 'share'],
                ingestionActions: ['empty', 'true', 'false'],
            },

            // Build the columns.
            output.columns = [
                {
                    value: 'status',
                    label: 'Status',
                    sortable: false,
                },
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                },
                {
                    value: 'week',
                    label: 'Week',
                    sortable: true,
                    sortWith: ['date', 'time'],
                },
                {
                    value: 'day',
                    label: 'Day',
                    sortable: false,
                },
                {
                    value: 'date',
                    label: 'Date',
                    sortable: true,
                    sortWith: ['time'],
                },
                {
                    value: 'time',
                    label: 'Time',
                    sortable: false,
                },
                {
                    value: 'crew',
                    label: 'Crew',
                    sortable: true,
                },
                {
                    value: 'runType',
                    label: 'Run Type',
                    sortable: true,
                },
                {
                    value: 'channel',
                    label: 'Channel',
                    sortable: true,
                },
                {
                    value: 'title',
                    label: 'Title',
                    sortable: true,
                    marker: true,
                },
            ]

            output.rows = []

            context.productions.forEach(v => {
                const runType = context.runTypeEntity.getItem(v.runType)
                const channel = context.channelEntity.getItem(v.channel)
                const row = {
                    rowOptions: {
                        updated: moment(v.updated).unix(),
                        new: moment(v.created).unix(),
                        type: 'production',
                        id: v.id,
                        ingestions: v.ingestions,
                        country: channel?.country,
                    },
                    status: {
                        value: '<span class="book-square"></span>',
                        classes: ['book-item-status-' + v.status]
                    },
                    id: {
                        value: v.id
                    },
                    week: {
                        value: Datetime.getWeekNumber(v.productionStart)
                    },
                    day: {
                        value: Datetime.getWeekDay(v.productionStart)
                    },
                    date: {
                        value: Datetime.getDate(v.productionStart)
                    },
                    time: {
                        value: Datetime.getTime(v.productionStart, v.productionEnd)
                    },
                    crew: {
                        value: v.crew ? v.crew.length : 0
                    },
                    runType: {
                        rawValue: runType ? runType.id : '',
                        value: runType ? runType.label : ''
                    },
                    channel: {
                        rawValue: channel ? channel.id : '',
                        value: channel ? channel.label : ''
                    },
                    title: {
                        value: context.getEventName(v)
                    },
                    houseNumber: {
                        value: this.showHouseNumberField(v, 'value', channel?.country),
                        rawValue: this.showHouseNumberField(v, 'rawValue', channel?.country)
                    },
                    recordRequested: {
                        value: this.getRecordRequest(v.recordRequested),
                        rawValue: this.getRecordRequest(v.recordRequested)
                    }
                }
                output.rows.push(row)
            })

            return output
        },
        getProductionsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.templateForm.runType || this.templateForm.league) {
                        if(this.templateForm.runType && this.templateForm.runType != item.data.runType)
                            return false
                        if(this.templateForm.league && this.templateForm.league != item.data.league)
                            return false
                    }
                    if (item.data.productionType && item.data.productionType !== this.type) {
                        return false
                    }
                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },

        getGroupBy() {
            //if (this.isEditProductions) return 'editSuites'
            //else if (this.isEngProductions) return 'engItems'
            if (this.isEditProductions || this.isEngProductions || this.isCarProductions) return 'equipmentLabel'
            return 'channel'
        },

        /**
         * Get the data to group productions by, based on the current context.
         * Ex: On Edit section we need to group by Edit Suites and each Edit
         * Production can have multiple ones.
         * @return object
         */
        getGroupByData() {
            //if (!this.isEditProductions && !this.isEngProductions) {
                //return this.channelEntity
            //}
            //return {}
            if (this.isEditProductions || this.isEngProductions || this.isCarProductions) return this.equipmentListData
            return this.channelEntity
        },
        getCreateLink() {
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }

            link.name = `${this.type}.create`

            if (this.templateForm.show) {
                link.query.template = this.templateForm.selection
            }
            else if (this.isEditProductions && this.$settings.get.editTemplate) {
                link.query.template = this.$settings.get.editTemplate
            }
            else if (this.isEngProductions && this.$settings.get.engTemplate) {
                link.query.template = this.$settings.get.engTemplate
            }
            
            return link
        },
        getCloneLink(){
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }

            link.name = `${this.type}.create`

            return link;
        },
        getRequestParams() {
            const output = {
                from: this.$route.query.from,
                to: this.$route.query.to,
            }
            if (this.$route.params.view == 'overview' || this.$route.params.view == "timeline") {
                output.to = Datetime.getEndDate(output.from, 'YYYY-MM-DD', 6)
            }

            output.productionType = this.type

            if (this.requestFields) {
                output.fields = this.requestFields.join(',')
            }
            return output
        },
        getEntitiesForSidebar() {
            const output = {}
            Lazy(this.child.entities)
                .each((v, k) => {
                    output[k] = this[k]
                })
            return output
        },
        fieldProductions() {
            return this.$route.name === 'productions.view'
        },
        requestFields() {
            // productions
            if (this.fieldProductions) {
                switch (this.$route.params.view) {
                    case 'overview':
                        return [
                            'channel',
                            'runType',
                            'signoffs',
                            'crew'
                        ]
                    case 'list':
                        return [
                            'runType',
                            'crew',
                            'ingestions',
                            'signoffs',
                            'placeholders'
                        ]
                    case 'timeline':
                        return [
                            'runType',
                            'crew',
                            'channel',
                            'signoffs',
                            'plan',
                        ]
                }
                return null
            }
            // eng, edit, task?
            switch (this.$route.params.view) {
                case 'overview':
                    return [
                        'channel',
                        'runType',
                        'signoffs',
                        //'plan',
                        //'crew',
                        'equipmentList'
                    ]
                case 'list':
                    return [
                        'runType',
                        'crew',
                        //'plan',
                        'ingestions',
                        'signoffs',
                        'equipmentList',
                        //'productionLeader',
                        'placeholders'
                    ]
                case 'timeline':
                    return [
                        'runType',
                        'crew',
                        'channel',
                        'signoffs',
                        'plan',
                        'equipmentList'
                    ]
            }
            return null
        },
        getApplyProductionsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.applyTemplateForm.runType || this.applyTemplateForm.league) {
                        if(this.applyTemplateForm.runType && this.applyTemplateForm.runType != item.data.runType)
                            return false
                        if(this.applyTemplateForm.league && this.applyTemplateForm.league != item.data.league)
                            return false
                    }

                    if (item.data.productionType && item.data.productionType !== this.type) {
                        return false
                    }

                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },

        getProductionType() {
            return this.type
        }
    },

    watch: {
        'type': function(to, from) {
            // Whenever production type changes (e.g ENG -> Production) we need to change which filters that should be
            // active.
            this.changeFiltersActive()
        },
    },

    methods: {
        getRecordRequest(codes) {
            if (!codes || !Array.isArray(codes)) return false
            return !!codes.find(v => v === app.$data.user.get.country)
        },
        getTabRoute() {
            return this.$route.query.newBar;
        },
        openTemplateForm() {
            this.$loader.add('createFromTemplateLoading')
            this.templateForm.show = true
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: this.getProductionType }, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Production Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },
        getTemplateTitleToFormList(template) {
            const label = []
            label.push(template.title)
            label.push(`(${template.id})`)
            if (template.data && template.data.league) {
                const league = this.leagueEntity.getItem(template.data.league)
                const sport = this.sportEntity.getItem(league.reference)
                label.push(`(${sport.label}, ${league.label})`)
            }
            return label.join(' ')
        },

        /**
         * Redirect the user to production creation, cloning the passed
         */
        cloneProduction(id) {
            console.log("production clone");
            const production = new Production(id)
            const destination = this.getCloneLink
            destination.query.clone = production.get.id
            destination.query.insertData = 1
            this.$router.push(destination)
        },

        /**
         * Get all Edit suites present in the passed item (production).
         * @param object item A Production to extract Edit Suites from.
         * @param integer type The equipment type id, ex for "Edit Suite".
         * @return array|null
         */
        getEquipmentByType(production, type) {
            const items = production.equipment.filter(item => item.type === type)
            if (items) {
                const output = []
                Lazy(items)
                    .each(v => {
                        let periodStart = v.equipmentUsage && v.equipmentUsage.periodStart ? v.equipmentUsage.periodStart : production.productionStart
                        let periodEnd = v.equipmentUsage && v.equipmentUsage.periodEnd ? v.equipmentUsage.periodEnd : production.productionEnd
                        output.push({
                            id: v.id,
                            label: v.label,
                            start: moment(periodStart).unix(),
                            end: moment(periodEnd).unix()
                        })
                    })
                return output
            }
            return null
        },

        saveProductionAsTemplate(item, needGetItem) {
            const context = this
            context.saveTemplateForm.show = true
            context.saveTemplateForm.loading = true
            context.saveTemplateForm.template = Object.assign({}, context.template)

            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, type: this.getProductionType}, true)
                .then(response => {
                    if(needGetItem) {
                        context.getCurrentProduction(item.id.value)
                    }
                    else {
                        console.log(context.$store.state.data.productionTemplate)
                        //Item.parse(item, 'production', now)
                        context.saveTemplateForm.saveItem = item
                        context.saveTemplateForm.loading = false
                    }
                    
                })
                .catch(error => {
                    if (error.response.status != 404) {
                        context.$error.set(error)
                        
                    }
                    else if(needGetItem) {
                        context.getCurrentProduction(item.id.value)
                    }
                    else {
                        context.saveTemplateForm.loading = false
                    }
                    
                })
        },
        getCurrentProduction(id) {
            const context = this
            const currentProduction = new Production(id)
            currentProduction.load(id)
            .then(response => {
                const now = moment().unix()
                Item.parse(response.data, 'production', now)
                context.saveTemplateForm.saveItem = response.data//currentProduction.get
                context.saveTemplateForm.loading = false
            })
            .catch(error => {
                context.$error.set(error)
            })
        },

        saveTemplateAs() {
            const context = this
            let id = parseInt(context.saveTemplateForm.id.value)
            context.$loader.add('productionTemplateEdit_submit')
            const productionTemplate = new ProductionTemplate(id)
            Lazy(context.saveTemplateForm.template)
                .each(function(v, k) {
                    switch (k) {
                        case 'user':
                            let user = {
                                id: app.$data.user.get.id
                            }
                            productionTemplate.set(k, user)
                            break
                        case 'title':
                            let value = ''
                            if(id === 0) {
                                value = context.saveTemplateForm.name.value
                            }
                            else {
                                let currentTemplate = context.getProductionsTemplates.find(i => i.id === id)
                                if(currentTemplate) value = currentTemplate.title
                            }
                            productionTemplate.set(k, value)
                            break
                        case 'data':
                            const data = {}
                            Lazy(v)
                                .each(function(v2, k2) {
                                    if(k2 === 'equipment') {
                                        data[k2] = context.saveTemplateForm.saveItem[k2].filter(e => e.equipmentUsage.parentId === null).map(e => {
                                            return {
                                                elementType: e.elementType,
                                                equipmentId: e.id,
                                                type: e.type,
                                                equipmentType: e.type,
                                                id: e.equipmentUsage.id,
                                                equipmentUsage: {
                                                    isNotUpdatePeriod: e.equipmentUsage.isNotUpdatePeriod,
                                                    isPrimary: e.equipmentUsage.isPrimary
                                                }
                                            }
                                        })
                                    }
                                    else if(k2 === 'crew') {
                                        data[k2] = []
                                        Lazy(context.saveTemplateForm.saveItem[k2])
                                        .each(c => {
                                            if(!c.isHistorical) {
                                                data[k2].push({
                                                    calcInCalendar: c.calcInCalendar,
                                                    createBookingConflict: c.createBookingConflict,
                                                    endPlan: c.endPlan,
                                                    id: c.id,
                                                    location_id: c.location,
                                                    skill: c.skill,
                                                    startPlan: c.startPlan,
                                                    status: 'requested',
                                                    userId: c.user.id
                                                })
                                            }
                                        })
                                    }
                                    else if (k2 === 'comments') {
                                        data[k2] = context.saveTemplateForm.saveItem[k2]
                                    }
                                    else if(k2 === 'plan') {
                                        data[k2] = context.saveTemplateForm.saveItem[k2].map(p => {
                                            return {
                                                id: p.id,
                                                offset: p.offset,
                                                description: p.description,
                                                type_id: p.type,
                                                type_custom: p.typeCustom,
                                                relative_to: p.relativeTo
                                            }
                                        })
                                    }
                                    else if(k2 === 'audioLayout' || k2 === 'notes' || k2 === 'contacts') {
                                        data[k2] = context.saveTemplateForm.saveItem[k2]
                                    }
                                    else {
                                        if(typeof context.saveTemplateForm.saveItem[k2] == 'object' && !Array.isArray(context.saveTemplateForm.saveItem[k2])) {
                                            data[k2] = context.saveTemplateForm.saveItem[k2].id
                                        }
                                        else {
                                            data[k2] = context.saveTemplateForm.saveItem[k2]
                                        }
                                        
                                    }
                                    
                                }) 
                            productionTemplate.set(k, data)
                            break
                        case 'filterType':
                            productionTemplate.set(k, context.saveTemplateForm.saveItem.runType)
                            break
                        case 'filterLeague':
                            productionTemplate.set(k, context.saveTemplateForm.saveItem.league)
                            break
                        default:
                            productionTemplate.set(k, v.value)
                            break
                    }

                })

                if(id === 0) {
                    productionTemplate.store()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Production template ${response.data.id} has been created!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the production.')
                            context.resetSaveTemplateForms()
                        })
                }
                else {
                    productionTemplate.save()
                        .then(response => {
                            console.log(response)
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$alert.set(`Production template ${response.data.id} has been created!`, 'success', 3)
                            context.resetSaveTemplateForms()
                        })
                        .catch(error => {
                            context.$loader.remove('productionTemplateEdit_submit')
                            context.$error.set(error, 'It was not possible to save the production.')
                            context.resetSaveTemplateForms()
                        })
                }
        },
        resetSaveTemplateForms() {
            this.saveTemplateForm.show = false
            this.saveTemplateForm.loading = false
            this.saveTemplateForm.template = null
            this.saveTemplateForm.saveItem = null
            this.saveTemplateForm.id.value = 0
            this.saveTemplateForm.name.value = ''
        },

        defaultPlaceholderForm() {
            return {
                placeholders: null,
                itemId: '',
                itemType: 'production',
                itemTitle: '',
                itemStart: '',
                itemEnd: '',
                selected: ''
            }
        },

        triggerDisplayCrew: function(isDisplay){
            const context = this
            context.isShowMegaView = isDisplay;
            console.log(context.isShowMegaView);
        },
        templateRunTypes() {
            // let tmpls = this.$store.state.data.productionTemplate && this.$store.state.data.productionTemplate.length > 0 ? 
            //             this.$store.state.data.productionTemplate.map(i => i.data.deliveryType ? i.data.deliveryType : 0) : []
            // tmpls = _.uniq(tmpls)
            return Lazy(this.runTypeEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.runType == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        templateLeagues() {
            return Lazy(this.leagueEntity.get.items)
                    .filter(item => {
                        let id = item.id
                        let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.league == id)
                        return indx > -1
                    })
                    .sortBy(item => item.label, false)
                    .toArray()
        },
        openApplyTemplateForm(item) {
            this.$loader.add('createFromTemplateLoading')
            this.applyTemplateForm.show = true
            this.applyTemplateForm.item = item
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: this.getProductionType}, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Production Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },
        applyTemplateToProduction(templateId) {
            this.applyTemplateToItem(templateId, this.applyTemplateForm.item)
                .then(response => {
                    if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] == this.applyTemplateForm.item) {
                        this.$alert.set(`Production has been updated with template!`, 'success', 3)
                        let updatedProd = JSON.parse(response.data.applied[0])
                        let currentProd = this.productions.find(i => i.id == this.applyTemplateForm.item)
                        if(currentProd != undefined) {
                            currentProd.plan = updatedProd.plan
                            currentProd.crew = updatedProd.crew
                            currentProd.equipment = updatedProd.equipment
                            currentProd.audioLayout = updatedProd.audioLayout
                            currentProd.contacts = updatedProd.contacts
                            currentProd.notes = updatedProd.notes
                        }

                        //Vue.set(this.$data, 'formData', this.defaultData())
                        //this.redirect(true, this.id)
                        //this.setFormValues()
                    }
                    else {
                        this.$error.set('Something went wromg with applying template.')
                    }
                })
                .catch(error => {
                    this.$error.set(error, 'Something went wromg with applying template.')
                })
        },

        /**
         * Determines if a filter should be active.
         */
        filterActive(filter) {
            return productionTypes[this.type]?.filters?.includes(filter)
        },

        /**
         * Changes which filters should be active.
         */
        changeFiltersActive() {
            for (const filter of Object.keys(this.availableFilters)) {
                this.availableFilters[filter].active = this.filterActive(filter)
            }
        }
    }

}
