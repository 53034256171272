<script>

import Auth from 'components/auth/Auth'
import User from 'components/user/User'
import UserNotifications from 'components/usernotifications/UserNotifications'
import {app} from 'app'
import jq from 'jquery';
//import store from 'store'

export default {
    socket: {
        'private:alerts': {
            '.alert.created': function(payload, context) {
                if(payload.data && payload.data.userId === app.$data.user.id) {
                    app.$emit('alertCreated', payload.data)
                }
               
            },
            '.alert.updated': function(payload, context) {
                if(payload.data && payload.data.userId === app.$data.user.id) {
                    app.$emit('alertUpdated', payload.data)
                }
            },
            '.alert.deleted': function(payload, context) {
                if(payload.data && payload.data.userId === app.$data.user.id) {
                    app.$emit('alertDeleted', payload.data)
                }
            },
        }
    },
    data() {
        return {
            showNavbar: true,
            isDevelopment: false,
            isStaging: false,
        }
    },
    computed: {
        store() {
          return this.$store
        },
        Auth() {
          return Auth
        },
        user() {
            return app.$data.user && app.$data.user.isLoaded() ? app.$data.user : null
        },
        currentName() {
            if (!this.user) {
                return ''
            }
            return this.user.get.firstname && this.user.get.lastname
            ? this.user.get.firstname + ' ' + this.user.get.lastname : this.user.get.name
        },
        adminMenuIsVisible() {
            return this.$ability.can('update', 'Users') || this.$ability.can('manage', 'Entities')
            || this.$ability.can('view', 'Equipment') || this.$ability.can('view', 'Contracts')
            || this.$ability.can('view_production_templates', 'Productions') || this.$ability.can('change_settings', 'Miscellaneous')
        },
        notifications() {
            return this.$store.state.data.notReadNotifications ? this.$store.state.data.notReadNotifications : null
        },
        readNotifications() {
            return this.$store.state.data.readNotifications ? this.$store.state.data.readNotifications : null
        }
    },
    mounted(){
        var domain = location.hostname;
        this.isDevelopment = process.env.NODE_ENV === 'development' || domain === 'test.viaplaysportbooking.com';
        this.isStaging = process.env.NODE_ENV === 'staging' || domain === 'stage.viaplaysportbooking.com';
        jq('.menu-items a').on('click', function(e) {
            jq(".navbar-collapse").collapse('hide');
        })
        // we have user as authenticated but, not loaded data? refresh and go to routed WN.
        if (typeof app.$data.user === 'undefined' && User.isAuthenticated()) {
            Vue.nextTick(() => {
                window.location.replace('/');
            })
        }
        else {
            const params = {
                includeNotReadNotificationsOnly: false
            }
            const context = this
            const notifications = new UserNotifications()
            notifications.getUserNotifications(params, true)
                .then(response => {
                    console.log(context.notifications)
                })
                .catch(error => {
                    console.log(error)
                })
        }

        app.$on('alertCreated', (data) => {
            this.addUserNotification(data)
        })
        app.$on('alertUpdated', (data) => {
            this.updateUserNotification(data)
        })
        app.$on('alertDeleted', (data) => {
            this.deleteUserNotification(data)
        })
    },
    
    methods: {
        getDestination(link) {
            if(link) {
                const parsedLink = typeof link === 'object' ? link : JSON.parse(link)
                const keys = Object.keys(parsedLink)
                const paramKeys = keys.filter(k => k !== 'name')
                const params = {}
                paramKeys.forEach(k => {
                    params[k] = parsedLink[k]
                })
                // const returnLink = {
                //     params: params,
                //     query: {
                //         destination: this.$route.name,
                //         from: this.$route.query.from,
                //         to: this.$route.query.to,
                //     }
                // }
                
                // returnLink.name = parsedLink.name
                
                return paramKeys.length > 0 ? { name: parsedLink.name, params: params } : { name: parsedLink.name }//returnLink
            }
            else return ''
        },
        addUserNotification(notification) {
            const context = this
            
            if(!notification.status) {
                if(context.notifications.length > 0) {
                    
                    let prioritySeparator = _.findIndex(context.notifications, n => n.priority >= notification.priority)
                    if(prioritySeparator === 0) {
                        this.$store.commit('addToIndex', {key: 'notReadNotifications', data: notification, index: 0})
                    }
                    else if(prioritySeparator === -1) {
                        this.$store.commit('addToIndex', {key: 'notReadNotifications', data: notification, index: context.notifications.length})
                    }
                    else {
                        this.$store.commit('addToIndex', {key: 'notReadNotifications', data: notification, index: prioritySeparator})
                    }
                    
                    

                }
                else {
                    this.$store.commit('addToIndex', {key: 'notReadNotifications', data: notification, index: context.notifications.length})
                }
            }
            else {
                if(context.readNotifications.length > 0) {
                    
                    let prioritySeparator = _.findIndex(context.readNotifications, n => n.priority >= notification.priority)
                    if(prioritySeparator === 0) {
                        this.$store.commit('addToIndex', {key: 'readNotifications', data: notification, index: 0})
                    }
                    else if(prioritySeparator === -1) {
                        this.$store.commit('addToIndex', {key: 'readNotifications', data: notification, index: context.readNotifications.length})
                    }
                    else {
                        this.$store.commit('addToIndex', {key: 'readNotifications', data: notification, index: prioritySeparator})
                    }
                    
                    

                }
                else {
                    this.$store.commit('addToIndex', {key: 'readNotifications', data: notification, index: context.readNotifications.length})
                }
            }
            
            
        },
        updateUserNotification(notification) {
            if(notification.status) {
                this.deleteUserNotification(notification)
                this.addUserNotification(notification)
            }
            else {
                this.addUserNotification(notification)
            }
        },
        deleteUserNotification(notification) {
            
           
        this.$store.commit('remove', {key: 'notReadNotifications', id: notification.id})
    
        this.$store.commit('remove', {key: 'readNotifications', id: notification.id})
            
            
        },
        markNotificationsAsRead() {
            const context = this
            if(context.notifications && context.notifications.length > 0) {
                /**
                 * We mark as read only notifications with type normal.
                 * Notifications with type special handles with individual logic
                 */
                let normalNotifications = _.filter(context.notifications, n => n.type === 'normal')
                if(normalNotifications && normalNotifications.length > 0) {
                    let ids = normalNotifications.map(i => i.id)
                    setTimeout(() => {
                        const notifications = new UserNotifications()
                        notifications.markNotificationsRead({ids: ids})
                            .then(response => {
                                console.log(ids)
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    }, 180000)
                }
                
            }
        },
        openNewTab(link) {
            const context = this
            
            let routeData = context.$router.resolve(link);
            window.open(routeData.href, '_blank');
            
        }
    }
}

</script>

<template>
<nav id="mainNav" class="navbar navbar-default navbar-fixed-top " :class="isDevelopment ? 'test-background' : (isStaging ? 'stage-background': 'navbar-inverse')">
    <div class="container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <router-link class="navbar-brand navbar-brand-with-text" :to="{path: '/'}">
                <img class="navbar-brand-image" src="~assets/viaplay_header_logo.png" />
                <p class="navbar-brand-text">Sport Booking</p>
            </router-link>
        </div>

        <div class="collapse navbar-collapse" id="navbar">
            <ul class="nav navbar-nav menu-items">
                <router-link tag="li" :to="{name: 'feeds'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['feed.edit', 'feed.create'])}">
                    <a data-target="#navbar">Feeds</a>
                </router-link>
                <router-link tag="li" :to="{name: 'productions'}" :class="{'active': $routerTools.isActive(['production.edit', 'production.create'])}">
                    <a data-target="#navbar">Productions</a>
                </router-link>
                <router-link tag="li" :to="{name: 'crew'}" class="hidden-xs" v-if="$ability.can('create_production_crew', 'Productions')">
                    <a data-target="#navbar">Crew view</a>
                </router-link>
                <router-link tag="li" :to="{name: 'tasks'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['task.edit', 'task.create'])}">
                    <a data-target="#navbar">Tasks</a>
                </router-link>
                <router-link tag="li" :to="{name: 'eng'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['eng.edit', 'eng.create'])}">
                    <a data-target="#navbar">ENG</a>
                </router-link>
                <router-link tag="li" :to="{name: 'edit'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['edit.edit', 'edit.create'])}">
                    <a data-target="#navbar">Edit</a>
                </router-link>
                <router-link tag="li" :to="{name: 'car'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['car.edit', 'car.create'])}">
                    <a data-target="#navbar">Cars</a>
                </router-link>
                <router-link tag="li" :to="{name: 'mcr'}" class="hidden-xs">
                    <a data-target="#navbar">MCR</a>
                </router-link>
                <router-link tag="li" :to="{name: 'equipment.usage'}" :class="{'active': $routerTools.isActive(['equipment.usage', 'equipmentoverview'])}" class="hidden-xs">
                    <a data-target="#navbar">Equipment</a>
                </router-link>
                <router-link tag="li" :to="{name: 'calendar'}">
                    <a data-target="#navbar">Calendar</a>
                </router-link>
                <router-link tag="li" :to="{name: 'contacts'}">
                    <a data-target="#navbar">Contacts</a>
                </router-link>
                 <router-link tag="li" :to="{name: 'feedbacks'}" class="hidden-xs" :class="{'active': $routerTools.isActive(['feedback.edit', 'feedback.create'])}">
                    <a data-target="#navbar">Feedback & Reports</a>
                </router-link>
            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li class="dropdown" id="notifications">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" v-on:click="markNotificationsAsRead()">
                        <font-awesome-icon icon="bell"/>
                        <span class="notification-count" v-if="notifications && notifications.length > 0">{{notifications.length}}</span>
                    </a>
                    <ul class="dropdown-menu">
                        <router-link tag="li" v-if="notifications && notifications.length > 0" v-for="notification in notifications" :key="notification.id" :to="getDestination(notification.link)">
                            <a data-target="#navbar">{{notification.title}}</a>
                            <p class="notification-message" v-if="notification.message">{{notification.message}}</p>
                        </router-link>
                        <li v-if="!notifications || notifications.length === 0" class="notification-no-new">No new notifications</li>
                        <li role="separator" class="divider"></li>
                        <router-link tag="li" :to="{name: 'usernotifications'}">
                            <a>View all notifications</a>
                        </router-link>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        {{currentName}}&nbsp;
                        <font-awesome-icon icon="user"/><span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <router-link tag="li" exact :to="{name: 'user'}">
                            <a>Profile</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'notificationlogs'}" v-if="$ability.can('send_notifications', 'Miscellaneous')" >
                            <a>My sent items</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'own.rates'}" v-if="user && user.get && user.get.needRatesApproval">
                            <a>My rates</a>
                        </router-link>
                        <li role="separator" class="divider"></li>
                        <li>
                            <a href="#" v-on:click="Auth.logout();return false;">Log out</a>
                        </li>
                    </ul>                    
                </li>
                <li class="dropdown" v-if="adminMenuIsVisible">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Settings&nbsp;<font-awesome-icon icon="cog"/></span><span class="caret"></span></a>
                    <ul class="dropdown-menu menu-items">
                        <router-link tag="li" :to="{name: 'admin.users'}" v-if="$ability.can('update', 'Users')">
                            <a>Users</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'entity'}" v-if="$ability.can('manage', 'Entities')">
                            <a>Entities</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'equipment'}" v-if="$ability.can('view', 'Equipment')">
                            <a>Equipment</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'contract'}" v-if="$ability.can('view', 'Contracts')">
                            <a>Contracts</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'productionTemplates.view'}" v-if="$ability.can('view_production_templates', 'Productions')">
                            <a>Production templates</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'feedTemplates.view'}" v-if="$ability.can('view_feed_templates', 'Feeds')">
                            <a>Feed templates</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'engTemplates.view'}" v-if="$ability.can('view_production_templates', 'Productions')">
                            <a>ENG templates</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'carTemplates.view'}" v-if="$ability.can('view_production_templates', 'Productions')">
                            <a>Car templates</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'all.rates'}" v-if="$ability.can('view_rates', 'Rates')">
                            <a>Rates</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'all.countries'}" v-if="$ability.can('view', 'Country')">
                            <a>Countries</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'settings'}" v-if="$ability.can('change_settings', 'Miscellaneous')">
                            <a>Settings</a>
                        </router-link>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<style lang="scss">

.test-background{
    background: #3c0000;
    .navbar-nav > li > a {
        color: #fff;
    }
    .navbar-nav > li > a:hover{
      color: #bcbcbc;
    }
}

.stage-background{
  background: #17212d;
  .navbar-nav > li > a{
    color: #fff;
  }
  .navbar-nav > li > a:hover{
    color: #bcbcbc;
  }
}

#notifications {
    .dropdown-toggle {
        padding-top: 16px;
    }
    .notification-message {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 11px;
        font-style: italic;
    }
    .notification-count {
        position: absolute; 
        top: 9px; 
        font-size: 11px;
    }
    .notification-no-new {
        padding-left: 20px;
        padding-right: 20px;
    }
    .notification-li {
        cursor: pointer;
    }
}
</style>