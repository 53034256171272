<script>
import Production from 'components/production/Production'
import BookDatetime from 'components/common/BookDatetime'
import BookLoader from 'components/common/loader/BookLoader'
import Entity from 'components/entity/Entity'
import BookSelectbox from 'components/common/BookSelectbox'
import User from 'components/user/User'
import {app} from 'app'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'

import productionTypes from './productionTypes';

export default {
    components: {
        BookDatetime,
        BookLoader,
        BookSelectbox,
    },
    props: {
        default: {
            default: () => {
                return {}
            },
            type: Object
        },
    },
    data() {
        return {
            child: {
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    channelEntity: 'channel_entity_id',
                    runTypeEntity: 'runtype_entity_id',
                    locationEntity: 'location_entity_id',
                }
            },

            leagueEntity: null,
            sportEntity: null,
            channelEntity: null,
            runTypeEntity: null,
            locationEntity: null,

            formData: this.defaultData(),
            production: null,
            loaded: false,

            productionTypes: productionTypes,

            productionLeaderRenderKey: 0,
        }
    },
    created() {
        this.production = new Production(0)

        // Apply the default values provided as prop.
        for (const field of Object.keys(this.default)) {
            this.formData[field].value = this.default[field]
        }

        const dataToLoad = []

        // These loads every time the form is opened, consider putting them in store and/or extend caching in its API
        // class.
        dataToLoad.push(this.loadEntities(this.child.entities))
        dataToLoad.push(User.getUsers())

        const productionTemplate = new ProductionTemplate()

        dataToLoad.push(productionTemplate.all({ user: app.$data.user.get.id }, true))

        Promise.all(dataToLoad).then(() => {
            this.setLoaded()
        }).catch(error => {
            // This should rarely happen and if it does there is not much we can do since the backend is down.
            this.$error.set('Something went wrong with loading the form.')
        })
    },
    watch: {
        /**
         * Whenever production type changes we need to reset values that are not on all types.
         */
        'formData.productionType.value': function (newValue) {
            this.formData.channel.value = ''
            this.formData.runType.value = ''

            if (newValue !== '') {
                this.formData.tvProduction.value = newValue === 'production'
            }

            this.formData.template.value = ''
        },
        'formData.template.value': function (newValue) {
            // Store old form data, so we can reapply some values after resetting.
            const oldFormData = this.formData

            // Reset form data.
            this.formData = this.defaultData()

            // If we have a template, apply any changeable values from the template directly to formData.
            if (newValue) {
                const template = this.$store.state.data.productionTemplate.find(template => template.id === newValue)

                Lazy(template.data)
                    .filter((v, k) => this.formData.hasOwnProperty(k))
                    .each((v, k) => {
                        switch (k) {
                            case 'league':
                                this.formData[k].value = v

                                // If we have a league, we also set sport.
                                if (v) {
                                    const entityItem = this.leagueEntity.getItem(v)
                                    this.formData['sport'].value = entityItem.reference
                                } else {
                                    this.formData['sport'].value = ''
                                }

                                break
                            default:
                                this.formData[k].value = v
                        }
                    })
            }

            // We need to merge default equipments with what we currently have to ensure all equipments
            // gets added.
            const defaultEquipments = this.default.equipment ?? []
            this.formData['equipment'].value = [...this.formData['equipment'].value ?? [], ...defaultEquipments]

            // Finally, we set old values from formData.
            Lazy(oldFormData)
                .filter((v, k) => !v.template)
                .each((v, k) => {
                    switch (k) {
                        case 'template':
                            this.formData[k].value = newValue
                            break
                        default:
                            this.formData[k].value = v.value
                    }
                })

            // We need to re-render production leader component.
            this.forceRenderProductionLeader()
        },
        /**
         * Whenever country changes, we must reset channel and run type as they depend on country.
         */
        'formData.country.value': function (newValue) {
            if (this.formData.runType.value) {
                const entity = this.runTypeEntity.getItem(this.formData.runType.value)

                if (entity.country !== newValue) {
                    this.formData.runType.value = ''
                }
            }
            if (this.formData.channel.value) {
                const entity = this.channelEntity.getItem(this.formData.channel.value)

                if (entity.country !== newValue) {
                    this.formData.channel.value = ''
                }
            }
        },
    },
    computed: {
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        setting() {
            return this.$settings.get.productions
        },

        /**
         * Checks if the form is valid.
         */
        isFormValid() {
            return this.formFields && Object.keys(this.formFields).every(k => {
                return this.formFields[k].valid
            })
        },

        /**
         * Gets the production templates.
         */
        productionTemplates() {
            const templates = this.$store.state.data.productionTemplate ?? []
            return templates.filter(template => {
                if (template.itemType !== this.formData.productionType.value) {
                    return false
                }

                return true
            })
        },

        /**
         * Determines if a production type has been selected.
         *
         * @returns boolean
         */
        productionTypeSelected() {
            return this.formData.productionType.value !== ''
        },

        /**
         * Form is processing if the form is being submitted.
         *
         * @returns boolean
         */
        processing() {
            return this.$loader.has('productionForm_submit')
        },

        availableUsers() {
            return this.$store.state.user.users
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length === 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
    },
    methods: {
        /**
         * Force re-render production leader UI.
         */
        forceRenderProductionLeader() {
            this.productionLeaderRenderKey += 1
        },
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                    .each((v, k) => {
                        entityIds.push(context.setting[v])
                    })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                            .each((v, k) => {
                                context[k] = new Entity(context.setting[v])
                            })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        setLoaded(loaded = true) {
            this.loaded = loaded
        },

        defaultData() {
            return {
                productionType: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: false,
                },
                locationId: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                sport: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                league: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: false,
                },
                productionStart: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: false,
                },
                productionEnd: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: false,
                },
                productionLeader: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                runType: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                country: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                channel: {
                    value: '',
                    validator: {
                        rules: 'required'
                    },
                    template: true,
                },
                equipment: {
                    value: [],
                    template: true,
                },
                projectId: {
                    value: '',
                    template: false,
                },
                tvProduction: {
                    value: false,
                    template: true,
                },
                template: {
                    value: '',
                    template: false,
                }
            }
        },

        submit() {
            this.$loader.add('productionForm_submit')

            const production = this.production

            // First we set all template values from the template we used. This makes sure we apply any template data
            // that is not visible to change in the form.
            if (this.formData.template.value) {
                const template = this.$store.state.data.productionTemplate.find(template => template.id === this.formData.template.value)

                Lazy(template.data)
                    .each((v, k) => {
                        switch (k) {
                            default:
                                production.set(k, v)
                        }
                    })
            }

            // Next we set all form values.
            Lazy(this.formData)
                .each((v, k) => {
                    if(v.value !== "")
                        production.set(k, v.value)
                    else if(v.value === "")
                        production.set(k, null)
                    else if(v.value === null)
                        production.set(k, null)
                })

            const params = []

            // We want to save the equipments coming from the UI, so we pass this flag to ensure that backend does not
            // use the template equipments only.
            params.push({ saveEquipment: 1 })

            production.store(params).then(response => {
                this.formData = this.defaultData()
                this.production = null

                this.$alert.set(`Production ${response.data.id} has been created!`, 'success', 3)

                this.$loader.remove('productionForm_submit')

                this.$emit('success')
            }).catch(error => {
                this.$error.set(error, 'It was not possible to save the production.')

                this.$loader.remove('productionForm_submit')
            })
        },

        hasField(field) {
            return this.productionTypes[this.formData.productionType.value].fields.includes(field)
        },

        entityItemIsVisible(item, field) {
            return !item.country || (item.country === app.$data.user.get.country)
        },
    }
}
</script>

<template>
    <div>
        <book-loader v-if="!loaded"></book-loader>
        <form v-else @submit.prevent="submit">
            <div class="form-horizontal">
                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Type
                    </label>
                    <div class="form-control-100" :class="{'has-error': formErrors.has('productionType')}">
                        <select tabindex="3" class="form-control" name="productionType" v-validate="formData.productionType.validator" v-model="formData.productionType.value">
                            <option value="">- Select a type -</option>
                            <option value="production">Production</option>
                            <option value="edit">Edit</option>
                            <option value="eng">ENG</option>
                            <option value="car">Car</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">Template</label>
                    <div class="form-control-100">
                        <select class="form-control" name="template" v-model="formData.template.value">
                            <option value="">- Select a template -</option>
                            <option v-for="template in productionTemplates" :value="template.id">{{ template.title }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm" v-if="hasField('league')" :class="{'has-error': formErrors.has('sport')}">
                    <label for="name" class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Sport
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="sport" v-validate="formData.sport.validator" v-model="formData.sport.value" v-if="sportEntity" tabindex="4">
                            <option value="">None</option>
                            <option v-for="sport in sportEntity.get.items" :value="sport.id" v-if="entityItemIsVisible(sport, 'sport')">{{sport.label}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm" v-if="hasField('league')" :class="{'has-error': formErrors.has('league')}">
                    <label for="name" class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        League
                    </label>
                    <!-- league settings -->
                    <div class="form-control-100">
                        <select class="form-control" name="league" v-validate="formData.league.validator" v-model="formData.league.value" v-if="leagueEntity" :disabled="formData.sport.value === ''" tabindex="5">
                            <option value="">None</option>
                            <option v-for="league in leagueEntity.get.items.filter(i => i.active)" :value="league.id" v-if="league.reference === formData.sport.value && entityItemIsVisible(league, 'league')">{{league.label}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('title')}">
                    <label for="name" class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Title
                    </label>
                    <div class="form-control-100">
                        <input v-validate="formData.title.validator" v-model:trim="formData.title.value" name="title" type="text" class="form-control" tabindex="6"/>
                    </div>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Start
                    </label>
                    <div class="form-control-100">
                        <book-datetime
                            component-id="book-production-start"
                            :required="{date: true, time: false}"
                            :default-value="formData.productionStart.value"
                            @newValue="value => formData.productionStart.value = value"
                            :small="true"
                            :dateTabindex="7"
                            :btnOpenTabindex="8"
                            :timeTabindex="9">
                        </book-datetime>
                    </div>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        End
                    </label>
                    <div class="form-control-100">
                        <book-datetime
                            component-id="book-production-end"
                            :required="{date: true, time: false}"
                            :default-value="formData.productionEnd.value"
                            @newValue="value => formData.productionEnd.value = value"
                            :small="true"
                            :dateTabindex="10"
                            :btnOpenTabindex="11"
                            :timeTabindex="12">
                        </book-datetime>
                    </div>
                </div>

                <div class="form-group form-group-sm" v-if="hasField('productionLeader')" :class="{'has-error': formErrors.has('productionLeader')}">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Prod. Leader
                    </label>
                    <div class="form-control-100">
                        <book-selectbox
                            componentId="production-leader"
                            :forceRender="productionLeaderRenderKey"
                            :items="getUsersList"
                            :selected="formData.productionLeader.value"
                            :filterVisibilityBy="{roles: setting.production_leader_role_id}"
                            @selectedItem="id => formData.productionLeader.value = id"
                            :tabindex="13">
                        </book-selectbox>
                    </div>
                </div>

                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">Project ID</label>
                    <div class="form-control-100">
                        <input v-model:trim="formData.projectId.value" name="project_id" type="text" class="form-control" tabindex="14"/>
                    </div>
                </div>

                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('country')}">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Country
                    </label>
                    <div class="form-control-100">

                        <select class="form-control" name="country" v-validate="formData.country.validator" v-model="formData.country.value" v-if="countries" tabindex="15">
                            <option value="">
                                None
                            </option>
                            <option v-for="country in countries" :value="country.code">
                                {{country.label}}
                            </option>
                        </select>

                    </div>
                </div>

                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('runType')}" v-if="productionTypeSelected && hasField('runType')">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Run type
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="runType" v-validate="formData.runType.validator" v-model="formData.runType.value" v-if="runTypeEntity" tabindex="16">
                            <option value="">None</option>
                            <option v-for="runType in runTypeEntity.get.items" :value="runType.id" v-if="runType.country === formData.country.value">{{runType.label}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('channel')}" v-if="productionTypeSelected && hasField('runType')">
                    <label for="name" class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Channel
                    </label>
                    <div class="form-control-100">

                        <select class="form-control" name="channel" v-validate="formData.channel.validator" v-model="formData.channel.value" v-if="channelEntity" tabindex="17">
                            <option value="">
                                None
                            </option>
                            <option v-for="channel in channelEntity.get.items" :value="channel.id" v-if="channel.country === formData.country.value">
                                {{channel.label}}
                            </option>
                        </select>

                    </div>
                </div>

                <div class="form-group form-group-sm" v-if="hasField('locationId')" :class="{'has-error': formErrors.has('locationId')}">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Prod. Location
                    </label>
                    <div class="form-control-100">

                        <select class="form-control" name="locationId" v-validate="formData.locationId.validator" v-model="formData.locationId.value" v-if="locationEntity" tabindex="18">
                            <option value="">
                                None
                            </option>
                            <option v-for="location in locationEntity.get.items" :value="location.id" v-if="location && location.id">{{location.label}}</option>
                        </select>

                    </div>
                </div>

                <div class="form-group form-group-sm" v-if="productionTypeSelected && hasField('tvProduction')">
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline">
                            <input type="checkbox" name="tvProduction" v-model="formData.tvProduction.value" :value="true">
                            TV Production
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group form-group-end mt-8">
                <button type="submit" tabindex="19" class="btn btn-success btn-placement-end" :disabled="!isFormValid || processing">Save</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
.form-group-end {
    display: flex;
    justify-content: flex-end;
}
</style>