<template>
<div class="book-cost-center-item" :class="'book-billing-type-' + type">


    <div class="book-content-type-wrapper form-group" v-if="type == 'itemised'">
        <label for="name" class="control-label book-width-100">Content type</label>
        <div class="form-control-100">
            <select class="form-control" name="frame_rate" v-model="formData.contentType.value">
                <option value="">None</option>
                <option v-for="contentType in contentTypes" :value="contentType.id">{{contentType.label}}</option>
            </select>
        </div>


    </div>

    <div class="book-billing-wrapper form-group" v-if="type == 'itemised'">
        <label for="name" class="control-label book-width-100">Billing type</label>
        <div class="form-control-100">
            <div class="radio-inline">
                <label>
                    <input type="radio" :name="'item_billing_bulked_' + itemId" :id="'item-billing-bulked-' + itemId" value="bulked" v-model="formData.billing.value">
                    Bulked
                </label>
            </div>
            <div class="radio-inline">
                <label>
                    <input type="radio" :name="'item_billing_itemised_' + itemId" :id="'item-billing-itemised-' + itemId" value="itemised" v-model="formData.billing.value">
                    Itemised
                </label>
            </div>
        </div>
    </div>

    <div class="book-price-wrapper form-group">
        <label for="price" class="control-label book-width-100">
            <span v-if="formData.billing.value == 'bulked'">Bulked price</span>
            <span v-if="formData.billing.value == 'itemised'">Price / feed</span>
        </label>
        <div class="input-group">
            <input v-validate="formData.price.validator" v-model:trim="formData.price.value" :name="'item_price_' + itemId" type="text" class="form-control" :aria-describedby="'item-price-addon-' + itemId">
            <span class="input-group-addon text-uppercase" :id="'item-price-addon-' + itemId">{{currency}}</span>
        </div>
        <multiselect
            v-model="formData.selectedCostCenters.value"
            track-by="id"
            label="description"
            placeholder="Pick a cost center"
            :max-height="200"
            :options="costCenters"
            :multiple="true"
            :clear-on-select="false"
            :hide-selected="true"
            :show-labels="false"
            @select="onSelectCostcenter"
            @remove="onRemoveCostcenter">
            <span slot="noResult">No cost center found.</span>
        </multiselect>

    </div>

    <table class="book-selected-cost-centers table table-striped" v-if="formData.selectedCostCenters.value.length > 0">
        <thead>
            <tr>
                <th class="book-cost-center-name">
                    Cost center
                </th>
                <th class="book-cost-center-split">
                    Split (%)
                </th>
                <th class="book-cost-center-actions">
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="costCenter in formData.selectedCostCenters.value">
                <td>
                    {{costCenter.description}}
                </td>
                <td :class="{'has-error': formErrors.has('bulked_price_split_' + costCenter.id)}">
                    <input :name="'bulked_price_split_' + costCenter.id" type="text" class="form-control text-right" v-validate="formData.priceSplit.validator" v-model="formData.priceSplit.value[costCenter.id]" tabindex="0">
                </td>
                <td>
                    <button class="btn btn-danger btn-xs" @click="removeCostcenter(costCenter.id)" tabindex="1"><font-awesome-icon icon="trash"/></button>
                </td>
            </tr>
            <tr v-if="costNotDistributed != 0">
                <td class="danger">
                    Not distributed
                </td>
                <td class="danger text-right">
                    {{costNotDistributed}}%
                </td>
                <td class="danger">
                </td>
            </tr>
        </tbody>
    </table>


    <button class="btn btn-danger book-remove-button" @click="$emit('removeItem', itemId)" tabindex="1" v-if="type == 'itemised'"><font-awesome-icon icon="trash"/></button>



</div>
</template>

<script>

import multiselect from 'vue-multiselect'


function defaultFormData() {
    return {
        contentType: {
            value: ''
        },
        billing: {
            value: 'bulked'
        },
        selectedCostCenters: {
            value: []
        },
        price: {
            value: 0,
            validator: {
                rules: 'decimal:2'
            }
        },
        priceSplit: {
            value: {},
            validator: {
                rules: ''
            }
        }
    }
}
export default {
    components: {
        multiselect
    },
    props: {
      type: {
          default: 'bulked',
          type: String
      },
      itemId: {
          default: 'item_0',
          type: String
      },
      currency: {
          default: '',
          type: String
      },
      costCenters: {
          default: () => [],
          type: Array
      },
      contentTypes: {
          default: () => [],
          type: Array
      }
    },
    data() {
        return {
            formData: defaultFormData()
        }
    },
    computed: {
        costNotDistributed() {
            if (Object.keys(this.formData.priceSplit.value).length < 1) {
                return null
            }
            let output = 100
            Lazy(this.formData.priceSplit.value)
            .each((v, k) => {
                output -= v
            })
            return output
        }
    },
    mounted() {



    },
    methods: {

        onSelectCostcenter(option) {
            Vue.set(this.formData.priceSplit.value, option.id, 0)
        },

        onRemoveCostcenter(removedOption, id) {
            Vue.delete(this.formData.priceSplit.value, removedOption.id)
        },

        removeCostcenter(id) {
            let i = this.formData.selectedCostCenters.value.findIndex(v => v.id == id)
            if (i > -1) {
                this.formData.selectedCostCenters.value.splice(i, 1);
                Vue.delete(this.formData.priceSplit.value, id)
            }
        }

    }
}
</script>

<style lang="scss">

.book-cost-center-item {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.book-price-wrapper {
  clear: both;
  display: flex;
  padding-right: 15px;
  .control-label {
    margin-right: 15px;
    text-align: left;
  }
  .input-group {
    max-width: 150px;
  }
  .multiselect {
    margin-left: auto;
    max-width: 250px;
    .multiselect__tag {
      display: none;
    }
  }
}
.book-selected-cost-centers {
  margin-top: 15px;
  .book-cost-center-split {
    text-align: right;
    width: 80px;
  }
  .book-cost-center-actions {
    width: 38px;
  }
  .table {
    margin-bottom: 0;
  }
  td {
    vertical-align: middle !important;
  }
}

.book-billing-type-itemised {
  position: relative;
  &.book-cost-center-item {
    background: lighten(#000, 98.5%);
    border: 2px solid lighten(#000, 76.7%);
    border-radius: 4px;
    margin-right: 47px;
    padding: 15px;
    .book-remove-button {
      position: absolute;
      right: -47px;
      top: -2px;
    }
  }
}

</style>