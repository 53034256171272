/**
 * This plugin is a modified version of VueAffix https://github.com/eddiemf/vue-affix
 * The plugin missed possibility of custom tag on sticky element.
 */

import BookAffix from './BookAffix.vue';

const Plugin = {};

Plugin.install = (Vue) => {
  if (Plugin.install.installed) return;

  Vue.component('BookAffix', BookAffix);
};

if (typeof window !== 'undefined' && window.Vue) {
  Plugin.install(window.Vue);
}

export default Plugin;
