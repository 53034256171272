<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import Calendar from 'components/calendar/Calendar'
import Item from 'components/common/item/Item'
import Colors from 'components/calendar/Calendar.scss'

// Vendors.
import { FullCalendar } from 'vue-full-calendar'
import jq from 'jquery';

export default {
    components: {
        FullCalendar,
        BookLoader,
    },
    props: {
        defaultDate: {
            default: '',
            type: String
        },
        stickyHeader: {
            default: false,
            type: Boolean
        },
        crewName: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            calendar: new Calendar(),
            loaded: false,
            dataExpire: null,
            dataExpireMinutes: 5,
            interval: {
                start: null,
                end: null
            },
            config: this.defaultConfig(),
        }
    },
    mounted() {

        // Set the default date.
        this.config.defaultDate = this.defaultDate ? this.defaultDate : moment().format('YYYY-MM-DD')
        // This first Vue.nextTick() is necessary to set "loaded", which is
        // necessary in order to set config.defaultDate before FullCalendar is
        // rendered.
        Vue.nextTick(() => {
            this.loaded = true

            // Make adjustments to FullCalendar using jQuery.
            Vue.nextTick(() => {
                this.fixFullCalendar()
            })

        })

    },
    computed: {
        events() {
            return this.$store.state.data.calendar ? this.$store.state.data.calendar : null
        },
        coloredEvents() {
            let coloredEvents = this.events.slice()
            if(coloredEvents && coloredEvents.length > 0) {
                coloredEvents.forEach(c => {
                    c.color = Colors.production
                    if(c.elementTypeDetailed === 'task') {
                        c.color = Colors.task
                    } else if(c.elementTypeDetailed === 'placeholder') {
                        c.color = Colors.placeholder
                    }

                    Lazy(c.user)
                        .filter(v => v.type != 'leader') 
                        .each(v => {
                            if(v.status === 'declined') {
                                c.color = Colors.declined
                            }
                        })
                })
            }

            return coloredEvents
        },
        processing() {
            return this.$loader.has('BookCalendarProcessing')
        },
        isCrew() {
            return this.$route.query.crewId != null
        },
    },
    methods: {

        /**
         * Load events from API.
         * @param object params Parameters to send into the API.
         * @return promise
         */
        loadEvents(params) {
            params.user = this.$route.query.crewId ? this.$route.query.crewId : app.$data.user.id
            this.$loader.add('BookCalendarProcessing')
            return new Promise((resolve, reject) => {
                this.calendar.all({
                    user: params.user,
                    start: params.start,
                    end: params.end,
                })
                .then(response => {

                    // Parse all the new events.
                    Lazy(this.events)
                        .filter(event => !event.status &&  event.type == 'production')
                        .each(event => {
                            Item.parse(event, event.type)

                            // @TODO For now we don't want to allow changes in a
                            // production through the calendar, but maybe in the
                            // future.
                            if (event.type == 'production') {
                                event.editable = false
                            }

                        })

                    this.$loader.remove('BookCalendarProcessing')
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status != 404) {
                        this.$error.set(error)
                    }
                    this.$loader.remove('BookCalendarProcessing')
                    reject(error)
                })
            })
        },

        /**
         * Update the current date interval, loading additional calendar data,
         * if necessary.
         *
         * @TODO A nice improvement is to load more data in the background,
         * outside the current interval.
         */
        updateData(view) {
            view = view || this.fireMethod('getView')
            if (!view) return
            const now = moment()
            if (this.dataExpire && (now.unix() > this.dataExpire)) {
                this.interval.start = null
                this.interval.end = null
            }

            let startDiff = true
            if (!this.interval.start) {
                this.interval.start = view.intervalStart.clone()
                startDiff = false
            }
            let endDiff = true
            if (!this.interval.end) {
                this.interval.end = view.intervalEnd.clone()
                endDiff = false
            }

            // No diff is present, which means this is the very first loading
            // and we need to load events for the interval.
            if (!startDiff && !endDiff) {
                this.dataExpire = now.add(this.dataExpireMinutes, 'm').unix()
                this.loadEvents({
                    start: this.interval.start.format('YYYY-MM-DD'),
                    end: this.interval.end.format('YYYY-MM-DD'),
                })
            }
            else {

                // The start date can have a diff, then we need to check it and
                // load the diff data (delta) if necessary.
                if (startDiff) {
                    startDiff = view.intervalStart.diff(this.interval.start, 'd')
                    if (startDiff < 0) {
                        this.loadEvents({
                            start: view.intervalStart.format('YYYY-MM-DD'),
                            end: this.interval.start.format('YYYY-MM-DD'),
                        })
                        this.interval.start = view.intervalStart.clone()
                    }
                }

                // The end date can have a diff, then we need to check it and
                // load the diff data (delta) if necessary.
                if (endDiff) {
                    endDiff = view.intervalEnd.diff(this.interval.end, 'd')
                    if (endDiff > 0) {
                        this.loadEvents({
                            start: this.interval.end.format('YYYY-MM-DD'),
                            end: view.intervalEnd.format('YYYY-MM-DD'),
                        })
                        this.interval.end = view.intervalEnd.clone()
                    }
                }
            }
        },

        /**
         * Make layout/design adjustments on FullCalendar, using jQuery.
         */
        fixFullCalendar() {
            Vue.nextTick(() => {
                setTimeout(() => {

                    jq('#calendar .fc-today-button').removeClass('btn-default').addClass('btn-primary')

                    //fc-addEventButton-button btn btn-default 
                    jq('#calendar .fc-addEventButton-button').removeClass('btn-default').addClass('btn-success')
                    jq('.fc-addEventButton-button').html('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-plus fa-w-14"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg><span class="item-label">&nbsp;&nbsp;Create</span>')

                    // Remove Glyphicon and add FontAwesome to all icons.
                    // We need this hack since we want to use "bootstrap3" as themeSystem
                    // on FullCalendar.io, but with FontAwesome, which is used in the
                    // rest of the system.
                    let userid = this.$route.query.crewId ? this.$route.query.crewId : app.$data.user.id
                    jq('.fc-prev-button').html("<")
                    jq('.fc-next-button').html(">")
                    jq('.fc-center').html("<a href='/user/" + userid + "' target='_blank'><h4 style='color: steelblue;'>" + this.crewName + "</h4></a>")
                    // jq('#calendar .glyphicon').each(function() {
                    //     const $el = $(this)
                    //     $el.removeClass('glyphicon').removeClass('glyphicon-fa').addClass('fa')
                    // })

                    // Make the headerToolbar sticky on the top, using the same
                    // classes as the rest of the system.
                    if (this.stickyHeader) {
                        jq('#calendar .fc-header-toolbar').addClass('book-top-sticky')
                    }

                }, 10)
            })
        },

        /**
         * Fire the given method on FullCalendar.
         */
        fireMethod(method, params = null) {
            if (!this.$refs.fullCalendar) return ''
            return this.$refs.fullCalendar.fireMethod(method, params)
        },

        eventSelected(item) {
            this.$emit('eventSelected', item)
        },
        eventDrop(item) {
            console.log('eventDrop()')
            console.log(item)
        },
        eventResize(item) {
            console.log('eventResize()')
            console.log(item)
        },
        eventCreated(item) {
            console.log('eventCreated()')
            console.log(item)
        },
        dayClick(date, jsEvent, view) {
            console.log('dayClick()')
        },
        eventRender(event, element) {
            console.log('eventRender()')
            console.log(event)
        },

        /**
         * default configuration that will be sent into FullCalendar.
         */
        defaultConfig() {
          const context = this
          return {
            defaultDate: '',
            defaultView: 'month',
            nowIndicator: true,
            themeSystem: 'bootstrap3',
            eventLimit: true,
            //weekNumbersWithinDays: true,
            weekNumberCalculation: 'ISO',
            weekNumbers: true,
            firstDay: 1,
            slotLabelFormat: 'HH:mm',
            columnHeaderFormat: 'ddd',
            aspectRatio: 1.8,
            businessHours: {
              dow: [1, 2, 3, 4, 5],
              start: '08:00',
              end: '18:00'
            },
            bootstrapGlyphicons: {
              close: 'fa fas fa-times',
              prev: 'fa fas fa-chevron-left',
              next: 'fa fas fa-chevron-right',
              prevYear: 'glyphicon-backward',
              nextYear: 'glyphicon-forward'
            },
            customButtons: {
                addEventButton: {
                    text: 'add event...',
                    click: function() {
                        
                        const link = {
                            query: {
                                destination: context.$route.name,
                                view: context.$route.params.view,
                                from: context.$route.query.from,
                                to: context.$route.query.to,
                                date: context.$route.query.date,
                                crewId: context.$route.query.crewId
                                // runType: this.getRunTypeFromUrl
                            }
                        }
            
                        link.name = 'placeholder.create'

                        let routeData = context.$router.resolve(link);
                        window.open(routeData.href, '_self');
                    },
                    
                }
            },
            header: {
              left: 'title',
              center: '',
              right: 'addEventButton today prev,next month'
            },
            

            
            eventRender: function(event, element) {

              // event.editable = false

              if (event.status) {

                // event.editable = false

                // console.log(event)

                // Add classes to the calendar event.

                // Vr: Coloring removed according to task 442
                // element.addClass('book-item-status-' + event.status)
                

                //element.addClass('book-item-status-6')
                //element.addClass('book-item-bkg')
               
                // Change the event title.
                // element.find('.fc-title').text(`${event.type.capitalize()}, ${event.title}`)

              }

              //element.find('.fc-prev-button').prepend(`${<font-awesome-icon icon="' + event.icon + '"/>}`);


            },
            eventAfterRender: function(event, element) {
                if(event.elementTypeDetailed === 'placeholder' && event.description && event.description !== '') {
                        //console.log(element.children());
                        // element.tooltip({
                        //     title: event.description,//event.title,
                        //     //content: event.description,
                        //     trigger: 'hover',
                        //     placement: 'top',
                        //     container: 'body'
                        // });
                        let childEl = document.createElement('div')
                        childEl.className = 'event-with-info'
                        childEl.innerHTML = "i"
                        element.children()[0].append(childEl)
                }
                else {
                    Lazy(event.user)
                        .filter(v => v.type != 'leader') 
                        .each(v => {
                            if(v.conflict) {
                                let conflEl = document.createElement('div')
                                conflEl.className = 'event-with-conflict'
                                conflEl.innerHTML = "<div class='event-with-conflict-line'></div>"
                                element.children()[0].append(conflEl)   
                            }
                        })
                }
                
            },

            
            viewRender: function(view, element) {
              context.$emit('renderKey')
              context.$emit('viewChanged', view)
              context.$emit('dateChanged', context.fireMethod('getDate').format('YYYY-MM-DD'))
              context.updateData(view)
            },

          }

        }

    }

}
</script>

<template>
<div class="book-calendar">
    <full-calendar
        v-if="loaded"
        ref="fullCalendar"
        :events="coloredEvents"
        :config="config"
        @event-selected="item => eventSelected(item)"
        @event-drop="item => eventDrop(item)"
        @event-resize="item => eventResize(item)"
        @event-created="item => eventCreated(item)"
        @day-click="(date, jsEvent, view) => dayClick(date, jsEvent, view)">
    </full-calendar>
    <div class="book-box-overlay" v-if="processing">
        <book-loader></book-loader>
    </div>
</div>
</template>

<style src="fullcalendar/dist/fullcalendar.css"></style>
<style lang="scss">

@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-calendar {
    position: relative;
    .fc-center {
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .fc-event {
        border-color: $color-white;
        cursor: pointer;
        padding: 2px;
    }
    .fc-toolbar {
        &.fc-header-toolbar {
            //margin: 1em;
        }
        .fc-right {
            padding-top: 2px;
        }
    }
    .fc-week-number {
        background: $color-blue;
        color: $color-white;
    }
    .fa,
    .fas {
        // Fix font on icons.
        font: 900 normal normal 14px/1 "Font Awesome 5 Free" !important;
    }
    .event-with-info {
        width: 11px;
        height: 11px;
        background: white;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
        color: $color-list-item-conflict-warning;
        position: relative;
        vertical-align: middle;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        font-family: "Font Awesome 5 Free";
        font-size: 10px;
    }
    .event-with-conflict {
        width: 11px;
        height: 11px;
        background: white;
        border-radius: 50%;
        border: 2px solid $color-red;
        display: inline-block;
        margin-left: 5px;
        color: $color-red;
        position: absolute;
        vertical-align: middle;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        font-family: "Font Awesome 5 Free";
        font-size: 10px;
        top: 0;
        right: 0;
    }

    .event-with-conflict-line {
        width: 13px;
        height: 3px;
        background-color: $color-red;
        -webkit-transform:
            translateY(4px)
            translateX(-1px)
            rotate(45deg);
        position: absolute;
    }
}   
</style>
