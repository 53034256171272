<script>

import {app} from 'app'
import Equipment from 'components/equipment/Equipment'
import EquipmentUsage from 'components/equipment/EquipmentUsage'
import Entity from 'components/entity/Entity'
import Item from 'components/common/item/Item'
import System from 'components/system/System'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookFilter from 'components/common/filter/BookFilter'
import BookLoader from 'components/common/loader/BookLoader'
import BookTimeline from 'components/common/BookTimeline'
import BookSidebar from 'components/common/BookSidebar'
import BookModal from 'components/common/BookModal'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookAudioLayout from 'components/common/BookAudioLayout'
import BookFeedAudioLayout from 'components/feed/BookFeedAudioLayout'

import ItemEditBaseMixin from 'components/common/item/ItemEditBaseMixin'
import ItemEditMixin from 'components/common/item/ItemEditMixin'

import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'

export default {
  mixins: [
    ItemEditBaseMixin,
    ItemEditMixin,
  ],
  components: {
    BookDateNavigation,
    BookFilter,
    BookLoader,
    BookTimeline,
    BookSidebar,
    BookModal,
    BookEquipmentSelection,
    BookAudioLayout,
    BookFeedAudioLayout
  },
  data() {
    return {

      child: {
        item: 'equipmentUsage',
        items: 'equipmentUsages',
        entities: {
          equipmentTypeEntity: 'equipmenttype_entity_id',
          channelEntity: 'channel_entity_id',
          leagueEntity: 'league_entity_id',
          sportEntity: 'sport_entity_id',

        }
      },
      availableFilters: {
        equipmentType: {
          label: 'Type',
          items: [],
          class: 'equipmentTypeEntity'
        },
        country: {
          label: 'Countries',
          items: [],
          // advanced: {
          //     combine: ['equipmentType']
          // }
        },
        channel: {
          label: 'Channels',
          items: [],
          class: 'channelEntity',
          //display: false
        },
        sport: {
          label: 'Sports',
          items: [],
          class: 'sportEntity',
        },
        league: {
          label: 'Leagues',
          items: [],
          class: 'leagueEntity',
        },

      },
      timeline: {
        startTimestamp: 0,
        endTimestamp: 0,
      },
      selectedItem: null,
      filters: {},
      filterReady: false,
      entities: {
        equipmentStatusEntity: 'equipmentstatus_entity_id',
        equipmentTypeEntity: 'equipmenttype_entity_id',
        runTypeEntity: 'runtype_entity_id',
        deliveryTypeEntity: 'deliverytype_entity_id',
        channelEntity: 'channel_entity_id',
        leagueEntity: 'league_entity_id',
        sportEntity: 'sport_entity_id',
        resolutionEntity: 'resolution_entity_id',
        frameRateEntity: 'framerate_entity_id',
        streamingTypeEntity: 'streamingtype_entity_id',
        polarizationEntity: 'polarization_entity_id',
        modulationEntity: 'modulation_entity_id',
        fecEntity: 'fec_entity_id',
        aspectRatioEntity: 'aspectratio_entity_id',
        systemFormatEntity: 'systemformat_entity_id',
        externalMCRsEntity: 'externalmcrs_entity_id',
        satelliteEntity: 'satellite_entity_id',
        audioLayoutsEntity: 'audiolayouts_entity_id',
      },
      requiredEntities: {},
      formData: this.defaultData(),
      addForm: {
        show: false,
        edit: false,
        available: false,
        equipment: null,
      },
      loaded: false,
      addFormType: true,
      equipmentTypeEntity: null,
      equipmentStatusEntity: null,
      deliveryTypeEntity: null,
      channelEntity: null,
      leagueEntity: null,
      sportEntity: null,
      runTypeEntity: null,
      resolutionEntity: null,
      frameRateEntity: null,
      streamingTypeEntity: null,
      polarizationEntity: null,
      modulationEntity: null,
      fecEntity: null,
      aspectRatioEntity: null,
      systemFormatEntity: null,
      externalMCRsEntity: null,
      audioLayoutsEntity: null,
      equipmentUsage: [],
      equipment: [],
      isFormChangedVar: false,
      dataGroup: 'equipment',
      sortGroup: 'weight',
      selectedBox: null,
      emitRender: 0,
      emitTimeout: null,
      allEquipments: null,
      scrolled: false,
      scrollPos: {
        X: null,
        Y: null,
      },
      usageItem: null,
    }
  },
  watch: {
    selectedItem: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue && oldValue.usageId !== newValue.usageId) {
            this.usageItem = null
          }
          this.loadUsageItem()
        } else {
          this.usageItem = null
        }
      }
    },
    selectedBox: {
      handler(newValue, oldValue) {
        this.selectBoxing(newValue)
        //console.log(this.selectedBox, newValue, oldValue)
      }
    },
    '$route.query.from': function(to, from) {

      // @TODO Should we call the API only if the new date interval is
      // outside or bigger then the current one?

      this.loadData()
          .then(response => {
            this.setLoaded()
          })
          .catch(error => {
            if (error.response && error.response.status != 404) {
              this.$error.set(error)
            }
            this.setLoaded()
          })
    }
  },
  beforeMount() {
    if (this.hasPreservedSorting) {
      this.sortGroup = this.$store.state.filter.preserveSort.sortGroup
      this.dataGroup = this.$store.state.filter.preserveSort.dataGroup
      // set selected
      this.selectedBox = this.sortGroup === 'weight' ? 'id' : (this.sortGroup ? this.sortGroup : 'label')
      //console.log('Preserved sorting applied. WN ->', this.dataGroup, this.sortGroup)
    } else {
      this.dataGroup = 'equipment'
      this.sortGroup = 'label'
      this.selectedBox = 'label'
    }
  },
  beforeDestroy() {
    app.$off()
  },
  created() {
    app.$on('catchUpdates', (data) => {
      const checkUpdates = (arr, check) => {
        let _checked = false
        Lazy(arr).each(e => { if (check.find(i => i === e.action.module) || check.find(i => i === e.action.name)) { _checked = true } })
        return _checked
      }
      if (checkUpdates(data.items, ['equipment'])) {
        this.updateEquipmentUsage(data)
      }
    })
    app.$on('changingEquipment', (data, notify) => {
      this.updateEquipmentUsage(data, notify)
    })
  },
  mounted() {
    if (app._events.changingEquipment === null) {
      app.$on('catchUpdates', (data) => {
        const checkUpdates = (arr, check) => {
          let _checked = false
          Lazy(arr).each(e => { if (check.find(i => i === e.action.module) || check.find(i => i === e.action.name)) { _checked = true } })
          return _checked
        }
        if (checkUpdates(data.items, ['equipment'])) {
          this.updateEquipmentUsage(data)
        }
      })
      app.$on('changingEquipment', (data, notify) => {
        this.updateEquipmentUsage(data, notify)
      })
    }
    if (this.hasPreservedSorting) {
      this.sortGroup = this.$store.state.filter.preserveSort.sortGroup
      this.dataGroup = this.$store.state.filter.preserveSort.dataGroup
      console.log('Preserved sorting applied. WN ->', this.dataGroup, this.sortGroup, this.selectedBox)
    } else {
      this.dataGroup = 'equipment'
      this.sortGroup = 'label'
      this.selectedBox = 'label'
    }

    this.setLoaded(false)
    this.equipmentUsage = new EquipmentUsage()
    this.loadEntities(this.entities)
        .then(response => {

          this.requiredEntities = {
            equipmentStatusEntity: this.equipmentStatusEntity,
            equipmentTypeEntity: this.equipmentTypeEntity,
            audioLayoutsEntity: this.audioLayoutsEntity,
          }

          this.loadData()
              .then(response => {
                this.createFilters()
                this.setLoaded()
              })
              .catch(error => {
                if (error.response && error.response.status != 404) {
                  this.$error.set(error)
                }
                this.createFilters()
                this.setLoaded()
              })
        })
        .catch(error => {
          this.$error.set(error)
          this.setLoaded()
        })
    const equipment = new Equipment()
    const dataToLoad = [

      equipment.all()
    ]

    Promise.all(dataToLoad.map(p => {

      // Return "undefined" for all rejected promises which allows
      // us to handle entities even if equipment failed.
      return p.catch(() => undefined)

    }))
        .then(response => {
          this.allEquipments = this.$store.state.data.equipment
          this.setLoaded()
        })
        .catch(error => {
          this.$error.set(error)
          this.setLoaded()
        })
    // Start scroll operation
    this.handleScrollOperations()
  },
  computed: {
    checking() {
      return this.$loader.has('bookEquipmentSelectionChecking')
    },
    processing() {
      return this.$loader.has('bookEquipmentSelectionSubmit')
    },
    system() {
      return System
    },
    equipmentHead() {
      return Equipment
    },
    countries() {
      return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
    },
    equipmentList() {
      return this.$store.state.data.equipment.filter(v => {
        return Equipment.isVisible(v)
      })
    },
    equipmentTypesList() {
      let selected = this.selectedItem.equipmentType
      //console.log(selected);
      return this.equipmentTypeEntity.get.items
          .filter(v => {

            if (selected != null && v.id == selected) {
              return true
            }

            return false
          })
    },
    setting() {

      // Use the use the settings for "Productions".
      // @TODO In the future, it can be relevant to create an own setting
      // for Equipment Usages.
      return this.$settings.get.productions

    },

    equipmentUsages() {
      if (!this.loaded || !this.$store.state.data.equipmentUsage) return []
      return this.$store.state.data.equipmentUsage
          .filter(v => {

            // On timeline view, only items with start/end inside the current
            // "from" day should be visible.
            // if (this.$route.params.view == 'timeline') {
            //     if (v.startTime > this.timeline.endTimestamp || v.endTime < this.timeline.startTimestamp) {
            //         return false
            //     }
            // }

                return Item.isFiltered(this.filters, v, {equipmentType: this.equipmentTypeEntity, league: this.leagueEntity, sport: this.sportEntity, channel: this.channelEntity})
          })
    },
    equipmentUsagesFull() {
      if (!this.loaded || !this.$store.state.data.equipmentUsage) return []
      return this.$store.state.data.equipmentUsage
    },
    hasPreservedFilters() {
      return !!this.$store.state.filter.preserve
    },
    hasPreservedSorting() {
      return this.$store.state.filter.preserveSort ? true : false
    },
    preserveSorting: {
      get() {
        return this.$store.state.filter.preserveSort ? true : false
      },
      set(value) {
        if (value) this.$store.commit('filter/setPreserveSorting', this.getCurrentSortingSet)
        else  this.$store.commit('filter/removePreserveSorting')
      }
    },
    /**
     * Get the current filters set.
     */
    getCurrentSortingSet() {
      const output = {}
      Vue.set(output, 'sortGroup', this.sortGroup)
      Vue.set(output, 'dataGroup', this.dataGroup)
      return output
    },
    filterInfo() {
      const filterInfo = {}
      filterInfo.visible = this.equipmentUsages.length
      filterInfo.total = this.itemsTotal
      return filterInfo
    },
    itemsTotal() {
      if (!this.loaded || !this.$store.state.data.equipmentUsage || !this.filterReady) return 0
      return this.$store.state.data.equipmentUsage
          .filter(v => {

            // On timeline view, only items with start/end inside the current
            // "from" day should be visible.
            if (this.$route.params.view == 'timeline') {
              if (v.startTime > this.timeline.endTimestamp || v.endTime < this.timeline.startTimestamp) return false
            }

            return true

          }).length
    },
    getSelection() {
      return this.selection
          .map(v => {
            this.parseItem(v)
            return v
          })
    },
    getEquipmentItems() {
      let selection = []
      if (!this.local) {
        selection = this.getSelection
      }
      else {
        //console.log(this.getSelection);
        // Build each equipment in the current selection.
        this.getSelection
            .forEach(item => {

              // If the item is an equipment piece.
              const equipment = this.$store.state.data.equipment.find(v => v.id === item.equipmentId)
              if (equipment) {
                selection.push({
                  id: item.equipmentId,
                  label: equipment.label,
                  description: equipment.description,
                  type: equipment.type,
                  equipmentStatus: equipment.equipmentStatus,
                  suggestions: equipment.suggestions,
                  elementType: 'equipmentPiece',
                  equipmentUsage: {
                    id: item.id,
                    periodStart: item.equipmentUsage.periodStart,
                    periodEnd: item.equipmentUsage.periodEnd,
                    isPrimary: item.equipmentUsage.isPrimary,
                    isNotUpdatePeriod: tem.equipmentUsage.isNotUpdatePeriod,
                  }
                })
              }

              // If the item is an equipment type.
              else if (!Equipment.isEquipmentPiece(item)) {
                const equipmentType = this.equipmentTypeEntity.getItem(item.type)
                if (equipmentType) {
                  selection.push({
                    id: equipmentType.id,
                    label: equipmentType.label,
                    type: equipmentType.id,
                    elementType: 'equipmentType',
                    equipmentUsage: {
                      id: item.id
                    }
                  })
                }
              }
            })

      }
      return Lazy(selection)
          .sortBy(item => {
            if (this.local){
              return item.label
            }
            else
            {
              return item.equipmentUsage ? item.equipmentUsage.periodStart : item.equipmentId
            }
          }, false)
          .toArray()
    },
    getEquipmentSelection(){
      //console.log(this);
      return this.equipmentUsages;
    },
    getEquipmentDisabledMessage() {

      return 'Equipment selection is disabled'

    },
    getEquipmentIdForSidebar(){
      if (this.selectedItem){
        return this.selectedItem.elementType + "#" +this.selectedItem.id;
      }
      return this.selectedItem.id;
    },
    getEquipmentFilterTypesBy() {
      return []
    },
  },
  methods: {
    setLoaded(loaded = true) {
      Vue.nextTick(() => {
        this.loaded = loaded
      })
    },
    loadData() {
      return new Promise((resolve, reject) => {

        this.setLoaded(false)

        // Set timeline parameters.
        let timelineStart = moment(this.$route.query.from)
        this.timeline.startTimestamp = timelineStart.unix()
        let timelineEnd = moment(timelineStart).add(30, 'hours').subtract(1, 'minute')
        this.timeline.endTimestamp = timelineEnd.unix()

        const params = {
          from: this.$route.query.from,
          to: this.$route.query.from,
        }
        this.equipmentUsage.all(params, true)
            .then(response => {
              this.$store.state.data.equipmentUsage.forEach(v => {
                let status
                if (v.conflict && !v.equipment.doNotCreateConflict) {
                  status = 3
                }
                else if(v.exceedsGroupLimit && v.equipment.useGroupConflictCount) {
                  status = 7
                }
                else {
                  // feeds cant conflict? WN
                  if(v.usageType == 'feed') {
                    status = 5
                  }
                  else {
                    status = v.usageItem.productionType == 'task' ? 8 : 6
                  }

                }
                Item.parse(v, '', 0, {status: status})
              })

              ///
              // do we have sidebar opened?
              if (this.selectedItem) {
                // check if the selectedItem also updated?
                Lazy(this.$store.state.data.equipmentUsage)
                    .each(e => {
                      if (e.id === this.selectedItem.id) this.selectedItem = e
                    })
              }

              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },

    /**
     * Load the passed entities from the API.
     * @param object entities
     * @return promise
     */
    loadEntities(entities) {
      const context = this
      const entity = new Entity()
      return new Promise((resolve, reject) => {
        let entityIds = []
        Lazy(entities)
            .each((v, k) => {
              if (context.setting[v]) {
                entityIds.push(context.setting[v])
              }
              if (context.$store.state.settings.data.feeds[v]) {
                entityIds.push(context.$store.state.settings.data.feeds[v])
              }
              if (context.$store.state.settings.data.productions[v]) {
                entityIds.push(context.$store.state.settings.data.productions[v])
              }
            })
        entityIds = _.uniq(entityIds)
        entity.all({ids: entityIds.join(',')})
            .then(response => {
              Lazy(entities)
                  .each((v, k) => {
                    context[k] = context.setting[v] ? new Entity(context.setting[v]) :
                        (context.$store.state.settings.data.productions[v] ? new Entity(context.$store.state.settings.data.productions[v]) : new Entity(context.$store.state.settings.data.feeds[v]))
                  })
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },

    /**
     * Set a filter value.
     * @param object filter
     */
    filteredBy(filters) {
      this.filters = filters
    },

    /**
     * Create the filter items.
     */
    createFilters() {
      const context = this
      if (!context.availableFilters) return
      Lazy(context.availableFilters)
          .each((v, k) => {
            switch (true) {
              case (v.hasOwnProperty('class')):
                context[v.class].get.items.forEach(v2 => {
                  if (v.class === 'channelEntity' || v.class === 'runTypeEntity') {
                    v.items.push({
                      id: v2.id,
                      label: v2.label,
                      type: v2.reference ? v2.reference : '',
                      country: v2.country.toUpperCase()
                    })
                  } else {
                    v.items.push({
                      id: v2.id,
                      label: v2.label,
                      active: v2.active
                    })
                  }
                })
                break
              case (k == 'country'):
                context.countries.forEach(v2 => {
                  v.items.push({
                    id: v2.code,
                    label: v2.label
                  })
                })
                break
            }
          })

      // Set default selected country based on the current user's country.
      if (app.$data.user.get.country && context.$store.state.filter.myFiltersSelected === '') {
        switch (true) {
          case (context.availableFilters.hasOwnProperty('mcr') && context.availableFilters.mcr.hasOwnProperty('items')):
            Lazy(context.availableFilters.mcr.items)
                .filter(v => {
                  return v.id == app.$data.user.get.country
                })
                .each(v => {
                  Vue.set(v, 'selected', true)
                })
            break
          case (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items')):
            Lazy(context.availableFilters.country.items)
                .filter(v => {
                  return v.id == app.$data.user.get.country
                })
                .each(v => {
                  Vue.set(v, 'selected', true)
                })
            break
        }
      }
      context.filterReady = true
    },
    isObjectEmpty(obj) {
      console.log(obj, 'what we have')
      if (typeof obj === 'string') {
        try { obj = JSON.parse(obj) } catch (e) { return true }
      }
      return Object.keys(obj).every(function(key){
        return obj[key] === null || obj[key] === ''
      })
    },
    getEntityItem(name, id) {
      const entityItem = this[name + 'Entity'].getItem(id)
      return entityItem ? entityItem : null
    },
    getEntityItemLabel(name, id) {
      const entityItem = this.getEntityItem(name, id)
      return entityItem ? entityItem.label : ''
    },
    isDeliveryType(name) {
      return this.getEntityItemLabel('deliveryType', this.selectedItem.usageItem.deliveryType).toLowerCase() === name
    },
    isDeliveryTypeData() {
      const item = this.selectedItem.usageItem.deliveryTypeData
      let data = false
      const label = this.getEntityItemLabel('deliveryType', this.selectedItem.usageItem.deliveryType).toLowerCase();

      if (label.indexOf('fiber') > -1) {
        return !!item.fiberInfo || item.fiberInfo && item.fiberInfo !== ''
      }
      if (label.indexOf('download') > -1) {
        return !!item.downloadInfo || item.downloadInfo && item.downloadInfo !== ''
      }
      if (label.indexOf('streaming') > -1) {
        return !!item.streamingInfo || item.streamingInfo && item.streamingInfo !== ''
      }

      if (label.indexOf('sat') > -1) {
        Lazy(item).each((v,k) => { if (Array.isArray(v)) {
          Lazy(v).each((v1,k1) => {
            if (k1 === 'value') { if (v1) data = true }
          })
        }
        else if (k !== 'feedId' && k !== 'deliveryType') { if (v) data = true }
        })
      }
     console.log(item, '????', label, data)

      return data
    },
    getSportLabel(leagueId) {
      const league = this.getEntityItem('league', leagueId)
      if (league && league.reference) {
        const sport = this.sportEntity.getItem(league.reference)
        return sport ? sport.label : null
      }
    },
    getEditLink(item) {
      return {
        name  : `${item.usageType}.edit`,
        params: {
          id: item.usageId
        },
      }
    },
    hasEquipment() {
      let equipmentIsAvailable = false
      this.equipmentList.forEach(v => {
        if (v.type == this.formData.equipmentType.value) {
          equipmentIsAvailable = true
        }
      })
      return equipmentIsAvailable
    },
    isFormChanged() {
      //console.log("is form changed");
      //console.log(this.formData);
      //console.log(this.addForm);
      if (this.formData.id.value) {
        switch (true) {
          case (this.formData.equipmentId.value != this.addForm.equipment.id):
          case (!this.hideDates && this.formData.periodStart.value != this.addForm.equipment.equipmentUsage.periodStart):
          case (!this.hideDates && this.formData.periodEnd.value != this.addForm.equipment.equipmentUsage.periodEnd):
          case (this.formData.isPrimary.value != this.addForm.equipment.equipmentUsage.isPrimary):
          case (this.formData.isNotUpdatePeriod.value != this.addForm.equipment.equipmentUsage.isNotUpdatePeriod):
            return true
        }
        return false
      }

      // If it's a real equipment piece, then we need to check for all
      // fields.
      if (this.formData.equipmentId.value != '') {
        return this.formFields && Object.keys(this.formFields).some(k => {
          return this.formFields[k].dirty
        })
      }

      // Check only for equipment type, if we don't have a equipment piece.
      return this.formData.equipmentType.value != ''

    },
    equipmentIsDisabled() {
      return !this.hasEquipment || this.equipmentList.length < 1 || this.formData.equipmentType.value == ''
    },
    isFormValid() {
      //console.log("is form valid");
      // If it's a real equipment piece, then we need to check for all
      // fields.
      if (this.formData.equipmentId.value != '') {

        // Special check for data fields.
        switch (true) {
          case (!this.hideDates && this.formData.periodStart.validator.rules.required && this.formData.periodStart.value == ''):
          case (!this.hideDates && this.formData.periodEnd.validator.rules.required && this.formData.periodEnd.value == ''):
            return false
            break
        }

        return this.formFields && Object.keys(this.formFields).filter(t => t != "equipment").every(k => {
          //we don't need to validate equipmentid as we can access this part only if equipmentId is not null
          //if (k == "equipment") return true;
          return this.formFields[k].valid
        })
      }

      // Check only for equipment type, if we don't have a equipment piece.
      return this.formData.equipmentType.value != ''

    },

    /**
     * Edit an equipment item.
     * @param integer id The id of the equipment item to edit.
     */
    editItem(id) {
      const context = this
      //console.log(id);
      // If the "id" is a string, then it contains the type in it and we
      // need extract it.
      let value = Equipment.getItemIdAndType(id)
      id = value.id
      let type = value.type

      context.addForm.edit = true
      /*
      context.addForm.equipment = context.getEquipmentItems
          .filter(v => {
              return v.elementType === type
          }).find(v => {
              return v.equipmentUsage.id === id
          })*/
      context.addForm.equipment = context.selectedItem;
      if (!context.addForm.equipment) return
      //console.log(context);
      // Populate the fields based on elementType.
      context.formData.id.value = id
      if (Equipment.isEquipmentPiece(context.addForm.equipment)) {
        context.formData.equipmentType.local = true
        context.formData.equipmentType.value = context.addForm.equipment.type
        context.formData.equipmentId.value = context.addForm.equipment.id

        context.formData.periodStart.value = context.addForm.equipment.equipmentUsage.periodStart
        context.formData.periodEnd.value = context.addForm.equipment.equipmentUsage.periodEnd


        context.formData.isPrimary.value = context.addForm.equipment.equipmentUsage.isPrimary;
        context.formData.isNotUpdatePeriod.value = context.addForm.equipment.equipmentUsage.isNotUpdatePeriod;
      }
      else {
        context.formData.equipmentType.local = false
        context.formData.equipmentType.value = context.addForm.equipment.id
        if (context.isOneRecord && (context.formData.equipmentType.value == 64 || context.formData.equipmentType.value == 65)){
          context.formData.isPrimary.value = true;
        }
      }

      context.showForm(true)
    },

    /**
     * Delete an equipment item, removing the usage (connection) to EquipmentHelpeonso
     * current object in the database.
     * @param integer id The id of the equipment item to remove.
     */
    deleteItem(id) {
      const context = this
      swal({
        title: 'Remove?',
        text: 'Are you sure you want to remove this equipment?',
        type: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      })
          .then(function() {
            context.$loader.add('bookEquipmentSelectionSubmit')
            if (context.local) {
              let value = Equipment.getItemIdAndType(id)
              id = value.id
              let type = value.type
              context.removeEquipment(id, type, false)
              context.$alert.set('The item was removed.', 'success', 4)
              context.$loader.remove('bookEquipmentSelectionSubmit')
            }
            else {
              let value = Equipment.getItemIdAndType(id)
              id = value.id
              let type = value.type
              const equipmentUsage = context.getNewClass(type, id)
              equipmentUsage.delete()
                  .then(response => {
                    context.removeEquipment(id, type, false)
                    context.$alert.set('The item was removed.', 'success', 4)
                    context.$loader.remove('bookEquipmentSelectionSubmit')
                  })
                  .catch(error => {
                    context.$error.set(error, 'It was not possible to remove the equipment.')
                    context.$loader.remove('bookEquipmentSelectionSubmit')
                  })
            }
          })
    },
    /**
     * Open/close the add/edit form for Equipment Usage.
     * @param boolean open Tells the function to open/close the form.
     */
    showForm(open = true) {
      //console.log(this.formData);
      if (!open) {
        this.resetForm()
        this.addForm.show = false
      }
      else {
        if (this.formData.id.value) {
          // this.setFormDefaultDates()

          // If only one equipment type is available, select that
          // element by default.
          if (this.equipmentTypesList.length == 1) {
            this.formData.equipmentType.value = this.equipmentTypesList[0].id
          }
        }
        Vue.nextTick(() => {
          this.addForm.show = true
        })
      }
    },

    /**
     * Check if the passed equipment is already chosen in equipmentList.
     * @param object equipment
     * @return boolean
     */
    isChosen(equipment) {

      // An equipment that is going to be edited should be visible in the
      // dropdown, even though it is present in the equipment list.
      if (this.addForm.edit && (this.formData.equipmentId.value == equipment.id)) {
        return false
      }

      let i = this.equipmentData.rows.findIndex(v => v.id.value == equipment.id)
      return i > -1
    },
    timeGiver(name) {
      const context = this
      clearTimeout(context.emitTimeout)
      context.emitTimeout = setTimeout((emitTimeout) => {
        //console.log('I am emitted now ', context.emitRender)
        context.loadData()
        Vue.nextTick(() => {
          context.emitRender = 0
          context.setLoaded()
          // scroll to latest
          context.handleScrollOperations()
        })
      }, 1500)
    },
    updateEquipmentUsage(data, reload = false) {
      const context = this
      context.emitRender++
      this.timeGiver()

      //if (reload) window.location.reload()
    },
    /**
     * Reset the values on add form.
     */
    resetForm() {
      this.formData = this.defaultData()
      this.addForm.equipment = null
      this.addForm.edit = false
    },

    getTime(date) {
      let time = date.substring(date.indexOf(' '))
      return time
    },
    /**
     * Add eventListener for scrolling operations.
     */
    addScrollListener() {
      window.addEventListener('scroll', this.saveCurrentScrollPosition);
    },
    /**
     * Delete eventListener for scrolling operations.
     */
    removeScrollListener() {
      window.removeEventListener('scroll', this.saveCurrentScrollPosition);
    },
    /**
     * Save the current scrolling position.
     */
    saveCurrentScrollPosition() {
      this.scrolled = window.scrollY > 0
      if (this.scrolled) {
        this.scrollPos.X = window.scrollX
        this.scrollPos.Y = window.scrollY
      }
    },
    /**
     * Set scrolling position to saved position on page.
     */
    setScroll(plus = 0) {
      // we should give a little delay to affect the page
      Vue.nextTick(() => {
        document.documentElement.scrollLeft = this.scrollPos.X + plus
        document.documentElement.scrollTop = this.scrollPos.Y + plus
        console.log(this.scrollPos.X, 'X - Y', this.scrollPos.Y)
      })
    },
    /**
     * Refreshes a Listener for Scroll DOM event and sets the scroll latest pos.
     */
    handleScrollOperations() {
      Vue.nextTick(() => {
        this.setLoaded()
        this.removeScrollListener()
        Vue.nextTick(() => {
          // we need to specify here to scroll to last position
          this.setScroll(5)
          // add event listener for scroll position
          this.addScrollListener()
        })
      })
    },
    defaultData() {
      return {
        id: {
          value: 0
        },
        equipmentType: {
          local: false,
          value: ''
        },
        equipmentId: {
          value: '',
          validator: {
            rules: 'required'
          }
        },
        periodStart: {
          value: '',
          validator: {
            rules: {
              required: true,
              date_format: 'YYYY-MM-DD HH:mm'
            }
          }
        },
        periodEnd: {
          value: '',
          validator: {
            rules: {
              required: true,
              date_format: 'YYYY-MM-DD HH:mm'
            }
          }
        },
        isPrimary: {
          value: false,
          validator: {
            rules: 'required'
          }
        }
      }
    },
    selectBoxing(event) {
      if (event === 'normal') {
        this.dataGroup = 'equipment'
        this.sortGroup = 'weight'
        //this.buildTimeline(true)
      }
      if (event === 'label') {
        this.dataGroup = 'equipment'
        this.sortGroup = 'label'
        //this.buildTimeline(true)
      }
      if (event === 'type') {
        this.dataGroup = 'equipment'
        this.sortGroup = 'type'
        //this.buildTimeline(true)
      }
      if (event === 'type2') {
        this.dataGroup = 'equipment'
        this.sortGroup = 'type2'
        //this.buildTimeline(true)
      }
      if (event === 'id') {
        //this.buildTimeline(true)
        this.dataGroup = 'usageItem'
        this.sortGroup = 'weight'
        //this.buildTimeline(true)
      }
      // If the new sorting set should be preserved, then we just set
      // the value to "true", which means that the computed value
      // "preserveSorting" will make the required operation to store the
      // current sorting set into Vuex.
      const context = this
      context.preserveSorting = true
    },

    /**
     * Loads the usage item for an equipment usage, e.g. production or feed.
     */
    loadUsageItem() {
      const { usageType, usageId } = this.selectedItem

      const usageItemApiClass = this.getNewUsageItemApiClass(usageType, usageId)

      const params = {
        fields: 'equipment',
      }

      usageItemApiClass.load(usageId, params, true).then(response => {
        if (usageId === this.selectedItem.usageId) {
          this.usageItem = response.data
        }
      })
    },

    getNewUsageItemApiClass(type, opts = null) {
      switch (type) {
        case 'feed':
          return new Feed(opts)
        case 'production':
          return new Production(opts)
      }
    },
  }

}

</script>

<template>
  <div id="equipment-usage-page" class="book-top-sticky-wrapper book-bottom-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
      <h1 class="page-title">Equipment usage</h1>
      <div class="secondary-menu">
        <book-date-navigation :hideEndDate="$route.params.view != 'list'"></book-date-navigation>

        <div class="btn-group" role="group">
          <router-link :to="{name: 'equipment.usage', query: $route.query}" class="btn btn-default" title="Equipment Usage"><font-awesome-icon icon="list"/></router-link>
          <router-link :to="{name: 'equipmentoverview', query: $route.query}" class="btn btn-default" title="Equipment Overview"><font-awesome-icon icon="calendar-week"/></router-link>
        </div>
      </div>
    </div>
    <book-filter
        :loading="!filterReady"
        :options="{save: true}"
        :filters="availableFilters"
        @filteredBy="filters => filteredBy(filters)"
        :info="filterInfo">
    </book-filter>
    <book-loader v-if="!loaded"></book-loader>
    <div v-else class="panel panel-default book-content-with-sidebar" :class="{'book-sidebar-opened': selectedItem}">
      <div>
        <select class="selectbox-style" v-model="selectedBox">
          <option value="label">Label</option>
          <option value="type">Type</option>
          <!-- <option value="type2" :selected="sortGroup === 'id' ? 'selected' : ''">TYPE 2</option> -->
          <option value="id" >Event ID</option>
        </select>
      </div>
      <book-timeline
          :items="equipmentUsages"
          itemsType='equipmentUsage'
          :columns="{start: 'periodStart', end: 'periodEnd'}"
          :entityPlease="equipmentTypeEntity"
          groupBy="equipment"
          :dataGroup="dataGroup"
          :sortGroup="sortGroup"
          :showId="false"
          :selected="selectedItem ? selectedItem.id : null"
          @open="item => selectedItem = item"
          @openDbl="item => openNewTab(getEditLink(item))"
          notFoundMessage="<strong>No equipment usages were found</strong><br />Please, check your current filters, which can affect the presented result"
          class="book-content">
      </book-timeline>
      <book-sidebar
          :item="selectedItem"
          :sidebarType="selectedItem ? selectedItem.usageType : 'production'"
          :actions="['close']"
          @close="selectedItem = null">
        <div slot="header" v-if="selectedItem" class="book-sidebar-header-title-inner">
          <span class="book-sidebar-header-title-text">{{selectedItem.equipment.label}}</span>
        </div>
        <div slot="content" v-if="selectedItem">
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Type</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('equipmentType', selectedItem.equipmentType)}}</div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Start:</div>
            <div class="book-sidebar-value">{{selectedItem.periodStart}}</div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">End:</div>
            <div class="book-sidebar-value">{{selectedItem.periodEnd}}</div>
          </div>
          <div v-if="!selectedItem.equipment.notCountryRestricted" class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Country:</div>
            <div class="book-sidebar-value">{{system.getCountryById(selectedItem.country)}}</div>
          </div>
          <div v-if="selectedItem.equipment.notCountryRestricted" class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Source:</div>
            <div class="book-sidebar-value">{{equipmentHead.getSourceLabelById(selectedItem.equipment.source, externalMCRsEntity)}}</div>
          </div>
          <div v-if="selectedItem.equipment.notCountryRestricted" class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Destination:</div>
            <div class="book-sidebar-value">{{equipmentHead.getDestinationsLabels(selectedItem.equipment.destinationWithoutSource, selectedItem.equipment.source, externalMCRsEntity)}}</div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label">
              <span v-if="selectedItem.exceedsGroupLimit && selectedItem.equipment.useGroupConflictCount" class="label-conflict label label-exceeded">exceeded</span>
              <span v-if="selectedItem.conflict && !selectedItem.equipment.doNotCreateConflict" class="label-conflict label label-danger">conflict</span>
            </div>
            <div class="book-sidebar-value"></div>
          </div>
          <!-- exceeding -->
          <div class="book-sidebar-group" v-if="selectedItem.exceedsGroupLimit && selectedItem.equipment.useGroupConflictCount">
            <div class="book-sidebar-row">
              <div class="book-sidebar-table" style="width: 100%">
                <div class="book-sidebar-table-header sidebar-box-set exceed-box-color">
                  <div>Exceeding Details</div>
                  <div class="book-width-40-percents">Overlaps</div>
                </div>
                <div class="book-sidebar-table-row">
                  <div class="book-sidebar-label book-width-100"><b>Group:</b> {{selectedItem.group}}</div>
                </div>
                <div class="book-sidebar-table-row">
                  <div class="book-sidebar-label book-width-100"><b>Max occurances:</b> {{selectedItem.groupLimit}}</div>
                </div>
                <div class="book-sidebar-table-row" v-for="exceeds in selectedItem.exceedsDetails">
                  <div><b>Period:</b>{{getTime(exceeds.start)}} - {{getTime(exceeds.end)}}</div>
                  <div style="width: 15%">{{exceeds.count}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- conflict -->
          <div class="book-sidebar-group" v-if="selectedItem.conflict && !selectedItem.equipment.doNotCreateConflict">
            <div class="book-sidebar-row">
              <div class="book-sidebar-table" style="width: 100%">
                <div class="book-sidebar-table-header sidebar-box-set conflict-box-color">
                  <div>Conflict Details</div>
                  <div class="book-width-40-percents">Overlaps</div>
                </div>
                <div class="book-sidebar-table-row">
                  <div class="book-sidebar-label book-width-100"><b>Count:</b> {{selectedItem.equipment.conflictCount}}</div>
                </div>
                <div class="book-sidebar-table-row" v-for="conflict in selectedItem.conflictDetails">
                  <div><b>Period:</b> {{getTime(conflict.start)}} - {{getTime(conflict.end)}}</div>
                  <div style="width: 15%">{{conflict.count}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="book-sidebar-group">
            <div class="book-sidebar-table">
              <div class="book-sidebar-table-header">
                Event Details
              </div>
            </div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Type:</div>
            <div class="book-sidebar-value text-capitalize">
              <a style="cursor: pointer;" @click="openNewTab(getEditLink(selectedItem))">{{selectedItem.usageType}}</a>
            </div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Title:</div>
            <div class="book-sidebar-value">
              <a style="cursor: pointer;" @click="openNewTab(getEditLink(selectedItem))">{{selectedItem.usageItem.title}} - ({{selectedItem.usageId}})</a>
            </div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Sport:</div>
            <div class="book-sidebar-value">{{getSportLabel(selectedItem.usageItem.league)}}</div>
          </div>
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">League:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('league', selectedItem.usageItem.league)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.usageType === 'feed'">
            <div class="book-sidebar-label book-width-100">Delivery Type:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('deliveryType', selectedItem.usageItem.deliveryType)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.usageType === 'production'">
            <div class="book-sidebar-label book-width-100">Run Type:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('runType', selectedItem.usageItem.runType)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.usageType === 'production'">
            <div class="book-sidebar-label book-width-100">Channel:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('channel', selectedItem.usageItem.channel)}}</div>
          </div>
          <!-- Audio layout -->
<!--          <div class="book-sidebar-group"-->
<!--               v-if="selectedItem.usageType === 'feed'-->
<!--               && selectedItem.usageItem.audioLayout-->
<!--               && selectedItem.usageItem.audioLayout !== ''"-->
<!--          >-->
<!--            <div class="book-sidebar-table">-->
<!--              <div class="book-sidebar-table-header">-->
<!--                <div>Audio Layout</div>-->
<!--                <div class="book-width-50">Value</div>-->
<!--              </div>-->
<!--              <book-feed-audio-layout-->
<!--                  :printableTextMode="true"-->
<!--                  :feedAudioLayout="selectedItem.usageItem.audioLayout"-->
<!--                  :audioLayoutsEntities="audioLayoutsEntity" ></book-feed-audio-layout>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="book-sidebar-group"-->
<!--               v-if="selectedItem.usageType === 'production'-->
<!--               && selectedItem.usageItem.audioLayout-->
<!--               && selectedItem.usageItem.audioLayout !== ''-->
<!--               && !isObjectEmpty(selectedItem.usageItem.audioLayout)"-->
<!--          >-->
<!--            <div class="book-sidebar-table">-->
<!--              <div class="book-sidebar-table-header">-->
<!--                <div>Audio</div>-->
<!--                <div class="book-width-50">Value</div>-->
<!--              </div>-->
<!--              <book-audio-layout-->
<!--                  :value="selectedItem.usageItem.audioLayout"-->
<!--                  :audioLayoutsEntity="audioLayoutsEntity"-->
<!--                  itemType="production">-->
<!--              </book-audio-layout>-->
<!--            </div>-->
<!--          </div>-->
          <!-- Delivery Type -->
          <div class="book-sidebar-group" v-if="isDeliveryTypeData()">
            <div class="book-sidebar-group-title">
              Delivery type: {{getEntityItemLabel('deliveryType', selectedItem.usageItem.deliveryType)}}
            </div>
            <div class="book-sidebar-table-row" v-if="isDeliveryType('download')">
              {{selectedItem.usageItem.deliveryTypeData.downloadInfo}}
            </div>
            <div class="book-sidebar-table-row" v-if="isDeliveryType('fiber')">
              {{selectedItem.usageItem.deliveryTypeData.fiberInfo}}
            </div>
            <template v-if="isDeliveryType('streaming')">
              <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">Type:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('streamingType', selectedItem.usageItem.deliveryTypeData.streamingType)}}</div>
              </div>
              <div class="book-sidebar-row" v-if="isDeliveryType('streaming')">
                {{selectedItem.usageItem.deliveryTypeData.streamingInfo}}
              </div>
            </template>
            <template v-if="isDeliveryType('sat') && selectedItem.usageItem.deliveryTypeData">
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satSatelliteId">
                <div class="book-sidebar-label book-width-100">Satellite:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('satellite',selectedItem.usageItem.deliveryTypeData.satSatelliteId)}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satFrq">
                <div class="book-sidebar-label book-width-100">Frq:</div>
                <div class="book-sidebar-value">{{selectedItem.usageItem.deliveryTypeData.satFrq}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satPolarization">
                <div class="book-sidebar-label book-width-100">Polarization:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('polarization', selectedItem.usageItem.deliveryTypeData.satPolarization)}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satModulation">
                <div class="book-sidebar-label book-width-100">Modulation:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('modulation', selectedItem.usageItem.deliveryTypeData.satModulation)}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satSymbolRate">
                <div class="book-sidebar-label book-width-100">Symbol rate:</div>
                <div class="book-sidebar-value">{{selectedItem.usageItem.deliveryTypeData.satSymbolRate}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satTransponderChannel">
                <div class="book-sidebar-label book-width-100">Transponder channel:</div>
                <div class="book-sidebar-value">{{selectedItem.usageItem.deliveryTypeData.satTransponderChannel}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satService">
                <div class="book-sidebar-label book-width-100">Service:</div>
                <div class="book-sidebar-value">{{selectedItem.usageItem.deliveryTypeData.satService}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satFec">
                <div class="book-sidebar-label book-width-100">FEC:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('fec', selectedItem.usageItem.deliveryTypeData.satFec)}}</div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satEncryption">
                <div class="book-sidebar-label book-width-100">Encryption:</div>
                <div class="book-sidebar-value">
                  <span v-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'biss_code'">Biss Code Local</span>
                  <span v-else-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'biss_code_shared'">Biss Code Shared</span>
                  <span v-else-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'director'">Director</span>
                  <span v-else-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'director_xml'">Director XML</span>
                </div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'biss_code'">
                <div class="book-sidebar-label book-width-100"></div>
                <div class="book-sidebar-value book-sidebar-value-stacked">
                  <div class="book-sidebar-value-stacked-item" v-for="item in selectedItem.usageItem.deliveryTypeData.satEncryptionByCountry">
                    <strong>{{item.country.toUpperCase()}}:</strong>&nbsp;
                    <span>{{item.value}}</span>
                  </div>
                </div>
              </div>
              <div class="book-sidebar-row" v-if="selectedItem.usageItem.deliveryTypeData.satEncryption == 'biss_code_shared'">
                <div class="book-sidebar-label book-width-100"></div>
                <div class="book-sidebar-value">{{selectedItem.usageItem.deliveryTypeData.satEncryptionBissCodeShared}}</div>
              </div>
            </template>
          </div>
          <div class="book-sidebar-group" style="margin-bottom: 30px" v-if="usageItem && usageItem.equipment.length > 0">
            <div class="book-sidebar-table">
              <div class="book-sidebar-table-header">
                Equipment
              </div>
            </div>
            <book-equipment-selection
              :selection="usageItem.equipment"
              :dates="{from: usageItem.productionStart, to: usageItem.productionEnd}"
              :usageId="selectedItem.usageId"
              :usageType="selectedItem.usageType"
              :columns="['type', 'equipment', 'actions']"
              :entityList="requiredEntities"
              :allEquipmentProps="allEquipments"
              @changingEquipment="(item, notify) => updateEquipmentUsage(item, notify)">
            </book-equipment-selection>
          </div>
          <!-- Equipment tab -->
          <div class="book-equipment-wrapper">
            <book-equipment-selection
                :selection="equipmentUsagesFull"
                :dates="{from: selectedItem.periodStart, to: selectedItem.periodEnd}"
                :usage-id="selectedItem.usageId"
                :isEngOrEdit="false"
                :isEditProduction ="true"
                :isEngProduction ="false"
                :usage-type="selectedItem.usageType"
                :disabled="false"
                :disabled-message="getEquipmentDisabledMessage"
                :local="false"
                :hideDates="false"
                :entityList="requiredEntities"
                :allEquipmentProps="allEquipments"
                :enableCheckAvailability="true"
                :filterTypesBy="getEquipmentFilterTypesBy"
                :selectedItem="getEquipmentIdForSidebar"
                :isForEquipmentUsagePage="true"
                @changingEquipment="(item, notify) => updateEquipmentUsage(item, notify)"
            >
            </book-equipment-selection>
          </div>
        </div>


      </book-sidebar>
    </div>
  </div>

</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.selectbox-style {
  position: absolute;
  z-index: 10;
  margin-left: 20px;
  margin-top: 3px;
  margin-right: -75px;
  border: 1px solid #CCC;
  border-radius: 3px 0 0 3px;
  float: none !important;
  -ms-flex: 1;
  flex: 1;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label-conflict {
  height: 16px;
}

.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}

.pull-right {
  margin-right: 5px;
}
.sidebar-box-set {
  box-shadow: black 1px 1px 1px 0px !important;
}
.conflict-box-color {
  color: white !important;
  background-color: #d9534f !important;
}
.exceed-box-color {
  color: white !important;
  background-color: #942522 !important;
}

.event-box-color {
  color: white !important;
  background-color: #226893 !important;
}

#equipment-usage-page .book-sidebar .book-equipment-selection .book-button-add-equipment {
  position: absolute;
  right: 8px;
  top: -25px;
}

</style>
