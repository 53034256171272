<template>
  <div v-if="print">
    <div v-if="hasNotes">
      <h4>Viaplay Calendar Notes</h4>

      <div v-if="notes?.general">
        <strong>General:</strong>
        <i>{{ countriesToNotesMap.get('general') }}</i>
      </div>

      <div v-for="country in availableCountries" v-if="countriesToNotesMap.has(country.code)">
        <strong class="text-uppercase">{{country.code}}:</strong>
        <i>{{ countriesToNotesMap.get(country.code) }}</i>
      </div>
    </div>
  </div>
  <div v-else class="panel panel-info book-panel-localnotes">
    <div class="panel-heading">
      Viaplay Calendar Notes
    </div>
    <div class="panel-body">
      <div class="checkbox">
        <label class="control-label checkbox-inline">
          <input type="checkbox" :id="'general'" :ref="'general'"
                 v-model="selectedGeneralNote" :value="'General Notes'"/>
          General Notes
        </label>
      </div>
      <div class="form-group form-group-sm" v-for="generalNote in selectedGeneralNote">
        <div class="form-control-20">
          <textarea class="form-control"
                    :name="generalNote"
                    :value="countriesToNotesMap.get('general')"
                    @input="handleUpdateNote($event, 'general')"></textarea>
        </div>
      </div>
      <div class="checkbox">
        <label v-for="country in availableCountries" class="control-label checkbox-inline">
          <input type="checkbox" :id="'calendar_note_'+country.code" :ref="'calendar_note_'+country.code"
                 v-model="selectedNoteCountries" :value="country"/>
          {{ country.label }}
        </label>
      </div>
      <div class="form-group form-group-sm" v-for="country in selectedNoteCountries">
        <label class="control-label control-label-20 text-uppercase">{{ country.code }}</label>
        <div class="form-control-20">
          <textarea class="form-control"
                    :name="'calendar_note_'+country.code"
                    :value="countriesToNotesMap.get(country.code)"
                    @input="handleUpdateNote($event, country.code)"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    availableCountries: {
      type: Array,
      default: () => []
    },
    notes: {
      type: Object,
      default: () => {}
    },
    print: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      calendarNotes: null,
      countriesToNotesMap: Map,
      selectedNoteCountries: [],
      selectedGeneralNote: [],
      selectedCalendarNotes: []
    }
  },
  mounted() {
    const context = this
    /**
     * HOW TO HANDLE INCOMING DATA (Part I)
     *
     * just to be able to get updates, we need to use a new variable and pass the props
     * variable in it, we used " calendarNotes " as a new variables, and passed the notes props
     * in it.
     */
    context.calendarNotes = context.notes
    context.setupCalendarNotesComponent()
    context.selectCheckboxesForExistingCalendarNotes(context.countriesToNotesMap)
  },
  /**
   * HOW TO HANDLE INCOMING DATA (Part II)
   * if we are in the child component that has been used in parent component,
   * when something gets changed we need to watch those changes.
   *
   * To be able to watch those changes we need to send changed value from one of the props.
   * This time, we used " notes " props and we started watching it.
   *
   * When we have the new values from the props, we should pass it to a new variable,
   * because props are not mutable and should not be mutated.
   */
  watch: {
    notes(newValue) {
      this.calendarNotes = newValue
      this.handleIncomingUpdate()
    },
    deep: true
  },
  methods: {
    setupCalendarNotesComponent() {
      if (this.calendarNotes) {
        this.countriesToNotesMap = new Map(Object.entries(this.calendarNotes))
      } else {
        this.countriesToNotesMap = new Map
        this.availableCountries.map(c => this.countriesToNotesMap.set(c.code, ''))
      }
      this.addGeneralNotesToMap();
    },
    addGeneralNotesToMap() {
      if (!this.countriesToNotesMap.has('general')) {
        this.countriesToNotesMap.set('general', '')
      }
    },
    /**
     * HOW TO HANDLE INCOMING DATA (Part III)
     *
     * from the logic that is coded in the above in the template, same variables are getting set by the V-FOR
     * thus, we need to update those variables with incoming data, before to do this
     * we need to reset the variables that has been already set before.
     */
    handleIncomingUpdate() {
      this.selectedNoteCountries = []
      this.selectedGeneralNote = []
      Lazy(this.calendarNotes).each((v,k) => {
        Lazy(this.availableCountries).each((v1,k1) => {
          if (k === v1.code) { if (v) { this.selectedNoteCountries.push(v1) } }
        })
        if (k === 'general' && v) { this.selectedGeneralNote.push('General Notes') }
      })
      this.setupCalendarNotesComponent()
      this.selectCheckboxesForExistingCalendarNotes(this.selectedNoteCountries)
    },
    handleUpdateNote(event, countryCode) {
      const updatedNote = event.target.value

      let countriesToNotesMap = this.countriesToNotesMap
      countriesToNotesMap.set(countryCode, updatedNote)

      let originalSavedValueOfNote = (this.calendarNotes === null || this.calendarNotes === undefined ? '' : this.calendarNotes[countryCode])
      if (originalSavedValueOfNote === undefined) {
        originalSavedValueOfNote = ''
      }

      if (originalSavedValueOfNote.toString() !== updatedNote.toString()) {
        const updatedCalendarNotesJson = JSON.stringify(Object.fromEntries(countriesToNotesMap));
        this.$emit('calendarNotesChanged', [updatedCalendarNotesJson, true])
      } else {
        this.$emit('calendarNotesChanged', ['', false])
      }

      // Unselect checkbox if note was removed
      if (updatedNote === '') {
        if (countryCode === 'general') {
          this.clickCheckBoxIfExists('general')
        } else {
          this.clickCheckBoxIfExists('calendar_note_' + countryCode)
        }
      }
    },
    selectCheckboxesForExistingCalendarNotes(countriesToNotesMap) {
      if (countriesToNotesMap.size > 0) {
        countriesToNotesMap.forEach((note, countryCode) => {
          if (typeof note !== undefined && note !== '') {
            if (countryCode === 'general') {
              this.clickCheckBoxIfExists('general')
            } else {
              this.clickCheckBoxIfExists('calendar_note_' + countryCode)
            }
          }
        });
      }
    },
    clickCheckBoxIfExists(elementId) {
      if (document.getElementById(elementId) !== null) {
        document.getElementById(elementId).click()
      } else {
        console.warn("Clicking non-existing element with id = " + elementId)
      }
    }
  },
  computed: {
    hasNotes() {
      return Object.keys(this.notes).length > 0
    }
  }
}

</script>
