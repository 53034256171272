<script>
    /**
     * @file
     * Book Timeline component.
     * Builds a timeline based on the passed parameters.
     *
     *
     * @TODO Add documentation...
     *
     * @groupBy can be a string, integer or an object with an ID.
     * @groupByData can be an entity, ex if groupBy is am integer
     */

    import ItemFlagsMixin from 'components/common/item/ItemFlagsMixin'
    import ProductionMixin from 'components/production/ProductionMixin'
    import jq from 'jquery';

    export default {
        mixins: [ItemFlagsMixin,ProductionMixin],
        props: {
            items: {
                type: Array
            },
            itemsType: {
                type: String
            },
            columns: {
                type: Object,
            },
            dataGroup: {
                type: String
            },
            sortGroup: {
                type: String
            },
            groupBy: {
                type: String
            },
            groupByData: {
                type: Object
            },
            notFoundMessage: {
                type: String
            },
            showId: {
                type: Boolean,
                default: true
            },
            showTime: {
                type: Boolean,
                default: false
            },
            entityPlease: {

            },
            selected: {},
            expandCrewMemembers:{
                type: Boolean,
                default: false,
            },

        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
            Vue.nextTick(() => {
                this.calculateWidth();
            })
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            console.log(this.dataGroup, this.sortGroup, 'sorting')

            setTimeout(() => {
                this.calculateWidth();
            }, 500)

            this.buildTimeline()

            /*
            jq('.book-timeline-group-item').on('focusin', function(e) {

                jq(e.path[4]).width("300px");
            })

            jq('.book-timeline-group-item').on('focusout', function(e) {
                console.log(e.path);
                jq(e.path[4]).width("300px");
            })
            jq(window).on('resize', function(){

                var win = $(this); //this = window
                if (this.screenWidth != win.width()){
                    this.screenWidth = win.width()
                }

                console.log(this.screenWidth)
                this.screenHeight = win.height();
            });*/
        },
        watch: {
            selected: {
              handler(newValue, oldValue) {
                  if (newValue && !oldValue) { this.timeFixedWidth = this.timeFixedWidth - 300 }
                  if (newValue === null) { this.timeFixedWidth = this.timeFixedWidth + 300 }
              }
            },
            dataGroup: {
                handler(newValue) {
                    this.buildTimeline(true)
                }
            },
            sortGroup: {
                handler(newValue) {
                    this.buildTimeline(true)
                }
            },
            items: {
                handler(newValue, oldValue) {
                    console.log('I am TimeLine rendering..')
                    this.rawData = JSON.parse(JSON.stringify(newValue))
                    this.processData(true)

                    setTimeout(() => {
                        this.calculateWidth();
                    }, 500)

                },
                deep: true
            },
            expandCrewMemembers: {
                handler(newValue){
                    this.processData(true)
                },
                deep: true
            },
            screenWidth: {
                handler(newValue){
                    console.log(newValue)
                    setTimeout(this.processData(true), 1000)
                },
                deep: true
            },
            screenHeight: {
                handler(newValue){
                    //maybe we need only width
                    //this.processData(true)
                },
                deep: true
            }
        },
        data() {
            return {
                rawData: JSON.parse(JSON.stringify(this.items)),
                currentDate: null,
                columnWidth: 30,
                numOfColumns: 30,
                markerStyle: {
                    animationDuration: '0s',
                    width: 0
                },
                markerStyle2: {
                    animationDuration: '0s',
                    left: -10,
                    x1: -10,
                    x2: -10
                },
                timelineData: {
                    groups: [],
                    processed: false
                },
                timelineHeightInPixels: 0,
                timelineLengthInPixels: 0,
                timelineStart: null,
                timelineStartTimestamp: 0,
                timelineEnd: null,
                timelineEndTimestamp: 0,
                nowTimestamp: 0,
                timeInterval: null,
                groupSize: {
                    height: 32
                },
                params: {
                    label: {
                        default: 6,
                        locked: 39,
                        updated: 48,
                        new: 28
                    },
                    flags: {}
                },
                screenWidth: {
                    type: Number,
                    default: 1024,
                },
                screenHeight: {
                    type: Number,
                    default: 800,
                },
                timeFixedWidth: 0,
                timeFixedWidthNew: 0,
            }
        },
        computed: {
            hasElements() {
                return this.timelineData.groups && this.timelineData.groups.length > 0 ? true : false
            },

            /**
             * Generate the "hours" to be presented in the time scale/columns.
             * @return array
             */
            timeScale() {
                const output = []
                let value = ''
                for (let i=0; i <= this.numOfColumns; i++) {
                    if (i < 10) value = '0' + i + ':00'
                    else if (i > 23) value = '0' + (i-24) + ':00'
                    else value = i + ':00'
                    output.push(value)
                }
                return output
            },

            getTimeColumns() {
                const context = this
                const output = []
                for (let i=0; i < context.numOfColumns; i++) {
                    const column = {}
                    let pos = i * 3.33
                    column.pos = `${pos}%`;
                    column.text = context.timeScale[i]
                    output.push(column)
                }
                return output
            }
        },

        methods: {
            /**
             * Build the timeline.
             */
            buildTimeline(reset = false) {
                const context = this

                // The data hasn't been updated. Don't build the timeline again.
                if (!reset && context.currentDate && (context.currentDate == context.$route.query.date)) return

                context.currentDate = context.$route.query.from

                // Calculate timeline parameters.
                context.timelineLengthInPixels = context.columnWidth * context.numOfColumns
                context.timelineStart = moment(context.$route.query.from + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss')
                context.timelineStartTimestamp = context.timelineStart.unix()
                context.timelineEnd = moment(context.timelineStart).add(30, 'hours').subtract(1, 'minute')
                context.timelineEndTimestamp = context.timelineEnd.unix()

                // Process the passed data.
                context.processData(reset)

                // Update the marker.
                context.updateMaker()

            },

            /**
             * Process the structure of the passed data.
             */
            processData(reset = false) {
                const context = this
                if (!reset && context.timelineData.processed) return

                // Add extra properties to all passed items.
                context.addPropertiesToItems()

                // Create grouped data.
                context.createGroups()

                // This variable is used to increase the height of the timeline
                // on each loop interation (after eahc)
                let posY = 0

                // Loop through all groups and calculate the height of each group
                // and the top position of items inside each group.
                context.timelineData.groups.forEach(g => {
                    let newHeight = 0

                    // This variable is used to calculate the number of rows that
                    // the current group should have, based on overlapping items.
                    let overlappingNum = 0

                    // Max number of crew members
                    let maxNumberOfCrew = 0;
                    let maxNumOfEq = 0

                    // The number of overlapped items
                    let overlappedCrewItems = 0;
                    let totalOverlappingHeight = 0;
                    // Loop through the group items.
                    g.items.sort((a,b) => a.startTime - b.startTime)
                    
                    g.items.forEach((v, i) => {
                        if (v.crew && v.crew.length > maxNumberOfCrew){
                            maxNumberOfCrew = v.crew.length;
                        }

                        // Create styleTop if it's not set yet.
                        if (!v.styleTop) {

                            // Vue.set(v, 'styleTop', posY + 2)
                            Vue.set(v, 'styleTop', {})
                        }

                        Vue.set(v.styleTop, g.id, posY + 2)

                        // Try to find the index for any overlapping items.
                        // If any found, increase the row number of the current
                        // item.

                        let _booking

                        if (g.feedStart) {
                            _booking = 'feed'
                        }
                        if (g.productionStart) {
                            _booking = 'production'
                        }

                        let isOverlapping = false;
                        let overlappingHeight = 0;
                        let prev = 0;
                        for (let i2 = 0; i2 <= overlappingNum; i2++) {
                            let index = g.items.findIndex(v2 => {
                                if (v.id === v2.id && !_booking) {
                                    return -1
                                }
                                if (v.usageId === v2.usageId && _booking) {
                                    return -1
                                }
                                overlappingHeight = this.getHeight(v2);
                                // return ((v.startTime >= v2.startTime && v.startTime < v2.endTime) || (v.endTime > v2.startTime && v.endTime <= v2.endTime)) && (v.groupRow == v2.groupRow)
                                return ((v.startTime >= v2.startTime && v.startTime < v2.endTime) || (v2.startTime >= v.startTime && v2.startTime < v.endTime)) && (v.groupRow == v2.groupRow)
                            })
                            if ((index > -1) && (index < i)) {
                                v.groupRow++
                                maxNumberOfCrew++;
                                overlappedCrewItems++;
                                isOverlapping = true;
                                totalOverlappingHeight += overlappingHeight - prev;
                                prev = overlappingHeight;
                            }

                        }

                        v.styleTop[g.id] = posY + (v.groupRow * context.groupSize.height) + 38

                        if (this.expandCrewMemembers && isOverlapping) {

                            v.styleTop[g.id] = posY + (totalOverlappingHeight + v.groupRow * context.groupSize.height) + 18;
                        }

                        // If the increased row is bigger than the current
                        // overlappingNum, update it.
                        if (v.groupRow > overlappingNum) {
                            overlappingNum = v.groupRow
                        }


                    })


                    //production duration in g.items
                    //find the smallest one
                    var smallestDuration = 0;

                    for (var i = 0; i < g.items.length; i++){
                        if (g.items[i].productionDuration) {
                            if (i == 0) smallestDuration = g.items[i].productionDuration;
                            if (g.items[i].productionDuration < smallestDuration) smallestDuration = g.items[i].productionDuration;
                        }
                        if (g.items[i].startTime) {
                            let duration = (g.items[i].endTime - g.items[i].startTime) / 60
                            if (i == 0) smallestDuration = duration
                            if (duration < smallestDuration) smallestDuration = duration;
                        }
                    }


                    let maxHeight = 0;
                    for (var i = 0; i < g.items.length; i++){
                        var itemHeight = this.getHeight(g.items[i])
                        if (i == 0) maxHeight = itemHeight;
                        if (itemHeight > maxHeight) maxHeight = itemHeight;
                    }


                    //if smallest duration and crew members max
                    //screen size

                    // Calculate the new group height based on any found
                    // overlapping items.
                    newHeight = (overlappingNum + 1) * context.groupSize.height + 8

                    if (this.expandCrewMemembers){
                        if (overlappingNum > 0) {
                            if (totalOverlappingHeight < maxHeight){
                                newHeight += maxHeight
                            }else{
                                newHeight += totalOverlappingHeight
                            }
                        }
                        else newHeight += maxHeight - 28;
                    }
                    // If the new group height is bigger than the
                    // current one.
                    if (newHeight > g.height) {
                        g.height = newHeight
                    }

                    // Set the current group height to "end position" calculated
                    // in the previous loop interaction.
                    g.posY = posY + 32

                    // Increase the "global" position holder with the newly
                    // calculated height
                    posY += g.height

                })

                // Set the height of the entire timeline (SVG area).
                context.timelineHeightInPixels = posY + 34

                // Flag the timeline as processed.
                context.timelineData.processed = true

            },

            /**
             * Group the passed data and store them into this.timelineData
             */
            createGroups() {
                const context = this
                const groups = []

                // Find all unique groups available in rawData.
                context.rawData.forEach(v => {

                    let isPresent = false

                    if (Array.isArray(v[context.dataGroup])) {
                        if (v[context.dataGroup].length == 0) {
                            isPresent = groups.find(g => g.id == 0)
                        }
                        else {
                            isPresent = Lazy(v[context.dataGroup])
                                .every(item => {
                                    return context.dataGroup === 'workgroups' ? groups.find(g => item.workgroup === g.id) : groups.find(g => item.id === g.id)
                                })
                        }
                    }
                    else {
                        isPresent = groups.find(g => {
                            if (!v[context.dataGroup]) {
                                return g.id == 0
                            }
                            else if ((typeof v[context.dataGroup] === 'object') && (v[context.dataGroup].hasOwnProperty('id'))) {
                                return g.id && g.id == v[context.dataGroup].id
                            }
                            else {
                                return g.id && g.id == v[context.dataGroup]
                            }
                        })
                    }

                    if (!isPresent) {
                        let groupById, group

                        // Create the group data itself.
                        if (v[context.dataGroup] && Array.isArray(v[context.dataGroup]) && (v[context.dataGroup].length > 0)) {
                            group = []
                            Lazy(v[context.dataGroup])
                                .each(item => {

                                    // If the current "group item" (item) is already
                                    // added to "groups" then we should continue
                                    // to next interaction.
                                    if(context.dataGroup === 'workgroups') { 
                                        if(groups.find(g => g.id === item.workgroup)) {
                                            return
                                        }
                                    }
                                    else if (groups.find(g => g.id === item) || groups.find(g => g.id === item.id)) {
                                        return
                                    }

                                    if(context.dataGroup === 'workgroups') {
                                        let groupItem = context.groupByData && context.groupByData.get && context.groupByData.get.items && context.groupByData.get.items.length > 0 ? _.find(context.groupByData.get.items, i => i.id === item.workgroup) : null

                                        if(groupItem) {
                                            group.push(groupItem)
                                            // group.push({
                                            //     id: groupItem.id,
                                            //     label: groupItem.label
                                            // })
                                        }
                                    }
                                    else if (context.groupByData && context.groupByData.hasOwnProperty('options')) {
                                        group.push(context.groupByData.getItem(item))
                                    }
                                    else {
                                        group.push(item)
                                    }
                                })
                        }
                        else if (v[context.dataGroup] && !Array.isArray(v[context.dataGroup])) {
                            if ((typeof v[context.dataGroup] === 'object') && (v[context.dataGroup].hasOwnProperty('id'))) {
                                groupById = v[context.dataGroup].id
                                group = [v[context.dataGroup]]
                            }
                            else {

                                if (context.groupByData && context.groupByData.hasOwnProperty('type')){
                                    group = context.groupByData.rows;
                                }else{
                                    groupById = v[context.dataGroup]
                                    group = [context.groupByData.getItem(groupById)]
                                }
                            }
                        }
                        else {
                            group = [{
                                id: 0,
                                label: ''
                            }]
                        }

                        Lazy(group)
                            .each(item => {

                                // Make a copy of the element to brake the
                                // reference "connection".
                                const g = Object.assign({}, item)

                                const items = context.rawData.filter(v => {
                                    if ((v[context.dataGroup] === g.id) || ( (g.id === 0) && (v[context.dataGroup] == '' || v[context.dataGroup] == null) ) ) {
                                        return true
                                    }
                                    else if ((typeof v[context.dataGroup] === 'object') && v[context.dataGroup] && (v[context.dataGroup].hasOwnProperty('id')) && (g.id === v[context.dataGroup].id)) {
                                        return true
                                    }
                                    else if (Array.isArray(v[context.dataGroup])) {
                                        if ((g.id === 0) && (v[context.dataGroup].length === 0)) {
                                            return true
                                        }
                                        else if (v[context.dataGroup].indexOf(g.id) > -1) {
                                            return true
                                        }
                                        else if(context.dataGroup === 'workgroups') {
                                            if(v[context.dataGroup].find(item => item.workgroup === g.id)) {
                                                return true
                                            }
                                        }
                                        else if (v[context.dataGroup].find(item => item.id === g.id)) {
                                            return true
                                        }
                                    }
                                    return false
                                })

                                Vue.set(g, 'items', items)
                                Vue.set(g, 'height', context.groupSize.height + 4)
                                groups.push(g)
                            })

                    }
                })

                if (context.sortGroup === 'label') {
                    //groups.sort(context.sortByLabel)
                    groups.sort(context.sortByLabel)
                }
                if (context.sortGroup === 'type') {
                    //groups.sort(context.sortByLabel)
                    groups.sort(context.sortByType)
                }
                if (context.sortGroup === 'type2') {
                    //groups.sort(context.sortByLabel)
                    groups.sort(context.sortByTypeTwo)
                }
                if (context.sortGroup === 'weight') {
                    groups.sort(context.sortByWeight)
                }
                if (!context.sortGroup) groups.sort(context.sortByWeight)


                Vue.set(context.timelineData, 'groups', groups)
                //console.log(groups, 'lets see the groups?')

            },

            /**
             * Add extra properties (fields) to the items in the passed data.
             */
            addPropertiesToItems() {
                const context = this
                const now = moment().unix()


                // Add some extra properties to each passed item.
                context.rawData.forEach(v => {

                    // Create additional properties.
                    Vue.set(v, 'groupRow', 0)
                    Vue.set(v, 'label', {})
                    Vue.set(v, 'styleLeft', {})
                    Vue.set(v, 'styleWidth', {})

                    // Get the group items, ensuring that at least one group should
                    // be presented.
                    let groupItems

                    if ((typeof v[context.dataGroup] === 'object') && v[context.dataGroup] && (v[context.dataGroup].hasOwnProperty('id'))) {
                        groupItems = [{id: v[context.dataGroup].id}]
                    }
                    else {
                        groupItems = Array.isArray(v[context.dataGroup]) ? v[context.dataGroup].slice(0) : [{id: v[context.dataGroup]}]
                    }

                    if (groupItems.length == 0) {
                        groupItems.push({id: 0})
                    }

                    // Calculate timeline properties required to present the current
                    // item in one or more group(s).
                    Lazy(groupItems)
                        .each(g => {
                            let gid = context.dataGroup === 'workgroups' ? (g.workgroup ? g.workgroup : "0") : (g.id ? g.id : "0")
                            let left, width
                            let startTime = g.start ? g.start : v.startTime
                            let endTime = g.end ? g.end : v.endTime

                            // Set the item label, before the startTime is manipulated.
                            let label = []
                            if (this.showTime) label.push(`${moment.unix(startTime).format('HH:mm')} |`)
                            if (this.showId) label.push(v.usageId)
                            label.push(v.title)
                            Vue.set(v.label, gid, label.join(' '))

                            if (startTime < context.timelineStartTimestamp) {
                                startTime = context.timelineStartTimestamp
                            }
                            left = ((startTime - context.timelineStartTimestamp) / (context.timelineEndTimestamp - context.timelineStartTimestamp)) * 100
                            if (endTime > context.timelineEndTimestamp) {
                                endTime = context.timelineEndTimestamp
                            }
                            width = (endTime - startTime) / (context.timelineEndTimestamp - context.timelineStartTimestamp) * 100

                            if (left >= 0) {
                                Vue.set(v.styleLeft, gid, `${left}%`)
                            }
                            if (width >= 0) {
                                Vue.set(v.styleWidth, gid, `${width}%`)
                            }

                        })

                })

            },

            /**
             * Update the time marker position/size.
             */
            updateMaker(reset = false) {
                const context = this
                const nowTimestamp = moment().unix()
                let seconds, width, minWidth;
                if (nowTimestamp < context.timelineStartTimestamp) {
                    seconds = 0
                    width = -10
                }
                else if (nowTimestamp > context.timelineEndTimestamp) {
                    seconds = 0
                    width = '100'
                }
                else {
                    seconds = (context.timelineEndTimestamp - nowTimestamp)
                    width = (nowTimestamp - context.timelineStartTimestamp) / (context.timelineEndTimestamp - context.timelineStartTimestamp)
                    minWidth = width * context.timelineLengthInPixels
                    width = width * 100
                }
                context.markerStyle.animationDuration = seconds + 's'
                context.markerStyle.width = width + '%'
                context.markerStyle.minWidth = minWidth + 'px'
                context.markerStyle2.x = width + '%'
                context.markerStyle2.animationDuration = seconds + 's'
            },
            getEntityTypeLabel(id) {
                const entity = this.entityPlease.get.items
                let label
                Lazy(entity).each(v=> {
                    if (v.id === id) label = v.label
                })

                return label
            },
            /**
             * Sort an array by the "label" field.
             */
            sortByLabel(a, b) {
                const labelA = a.label.toUpperCase()
                const labelB = b.label.toUpperCase()
                if (labelA < labelB) return -1
                if (labelA > labelB) return 1
                return 0
            },
            sortByType(a, b) {
                const labelA = this.getEntityTypeLabel(a.type) + '-' + a.label.toUpperCase()
                const labelB = this.getEntityTypeLabel(b.type) + '-' + b.label.toUpperCase()
                if (labelA < labelB) return -1
                if (labelA > labelB) return 1
                return 0
            },
            sortByTypeTwo(a, b) {
                const labelA = this.getEntityTypeLabel(a.type).toUpperCase() + '-' + a.label.toUpperCase()
                const labelB = this.getEntityTypeLabel(b.type).toUpperCase() + '-' + b.label.toUpperCase()
                if (labelA < labelB) return -1
                if (labelA > labelB) return 1
                return 0
            },

            /**
             * Sort an array by the "label" field.
             */
            sortByWeight(a, b) {
                const weightA = a.weight
                const weightB = b.weight
                if (weightA < weightB) return -1
                if (weightA > weightB) return 1
                return 0
            },

            /**
             * Sort an array by the "startTime" field.
             */
            sortByTime(a, b) {
                if (a.startTime < b.startTime) return -1
                if (a.startTime > b.startTime) return 1
                return 0
            },

            /**
             * Sort an array by user name
             */
            sortByUserName(a, b){
                const labelA = a.user && a.user.name ? a.user.name.toUpperCase() : ''
                const labelB = b.user && b.user.name ? b.user.name.toUpperCase() : ''
                if (labelA < labelB) return -1
                if (labelA > labelB) return 1
                return 0
            },

            /**
             * Get the left position of an element label, based on all current flags.
             */
            getLabelPosition(id) {
                const context = this
                if (!context.changedItems[id] || !context.changedItems[id].flags) return context.params.label.default
                let pos = context.params.label.default
                context.changedItems[id].flags.forEach(v => {
                    pos += context.params.label[v] + context.params.label.default
                })
                return pos
            },

            /**
             * Get the left position of an element flag, based on all current flags.
             * @param integer id The id to the item that has the flag.
             * @param string flag The flag name.
             * @param boolean cached (optional) Return a cached value, if available.
             */
            getFlagPosition(id, flag, cached = false) {
                const context = this
                if (cached && context.params.flags[id] && context.params.flags[id][flag]) return context.params.flags[id][flag]
                if (!context.changedItems[id] || !context.changedItems[id].flags) return context.params.label.default
                let pos = context.params.label.default
                let done = false
                context.changedItems[id].flags.forEach(v => {
                    if (!done && (v != flag)) pos += context.params.label[v] + context.params.label.default
                    else if (v == flag) done = true
                })
                if (!context.params.flags[id]) Vue.set(context.params.flags, id, {})
                Vue.set(context.params.flags[id], flag, pos)
                return pos
            },

            getHeight(item){
                //Should be depend on screen width
                if (this.expandCrewMemembers){
                    let sw = 2000 / this.screenWidth; //screen width, the default is 2000
                    let lp = item.productionDuration / 239; //production lenght default is 3hr 59min
                    let rh = 42; //default row height

                    if (lp >= 1){
                        lp = 1
                        rh = 14;
                    }
                    if (item.crew && item.crew.length > 0 && this.expandCrewMemembers){
                        //var height = item.crew.length * (rh / sw / lp) + 28;
                        var height = item.crew.length * rh + 28
                        return parseInt(height, 10);
                    }else{
                        return 28;
                    }
                }

                return 28;
            },

            getCrewMemberClass(crew){

                let itemStatusId = 0

                // Determine the "status id", which is a number that indicates a
                // color, based on the same color system as feed/production status.
                switch (crew.status) {
                    case 'option':
                        itemStatusId = 5 // Blue.
                        break
                    case 'requested':
                        itemStatusId = 4 // Yellow.
                        break
                    case 'accepted':
                        itemStatusId = 2 // Green.
                        break
                    case 'declined':
                        itemStatusId = 3 // Red.
                        break
                    case 'not_available':
                        itemStatusId = 1 // Grey.
                        break
                }

                return `book-item-status-${itemStatusId}`;
            },
            handleResize() {
                this.screenWidth = window.innerWidth;
                this.screenHeight = window.innerHeight;
                // fixed time columns width
                this.calculateWidth();
            },
            calculateWidth() {
                if (this.$refs.content) {
                    this.timeFixedWidth = this.$refs.content.clientWidth - 2
                }
            },
            showDetailsPopup(e){
                /*
                console.log(e);
                for (var i = 2; i <5; i++){
                    var width = $(e.path[i]).first().width();
                    if (width < 300)
                        $(e.path[i]).first().width(300);
                }    */
            },

            hideDetailsPopup(e){
                /*
                 console.log(e);
                  for (var i = 0; i <e.path[0].children.length; i++){

                     var width = $(e.path[0].children[i]).width();
                     if (width >= 300 && width < 600){
                         var newWidth = width - 300;
                         $(e.path[0].children[i]).width(newWidth);
                     }

                 }   */
            },
            divideGroups(item, index, lindex = 0, group = '') {
                if (index === 'group') {
                    let labels = []
                    if (item.items !== null && typeof item.items !== 'undefined') {
                        Lazy(item.items)
                            .each(v => {
                                labels.push(v.equipment.label)
                            })
                    }
                    return labels
                }
                if (index === 'line') {
                    console.log(item.items.length, 'length', item)
                    return lindex !== 0 ? (lindex === 1 ? group.posY + (36 * 2) : group.posY + (34.3 * (lindex + 1))) : group.posY + 40
                }
            },
            addDots(str) {
                if (str.length > 13) return str.substring(0, 13) + '..'
                return str
            },
        }
    }
</script>

<template>
    <div class="book-timeline">
        <div class="book-timeline-labels" v-if="timelineData.processed && dataGroup && hasElements">
            <svg v-if="dataGroup === 'usageItem'" class="book-timeline-labels-svg" :height="timelineHeightInPixels + 'px'">
            <g v-for="(group, $index) in timelineData.groups" class="book-timeline-group" :class="[($index % 2 == 0) ? 'book-timeline-odd' : 'book-timeline-even', {'book-timeline-single-group': timelineData.groups.length == 1}]">
                <rect x="0" :y="group.posY" :height="group.height" class="book-timeline-group-bkg"></rect>
                <text v-for="(labels, lindex) in divideGroups(group, 'group')" text-anchor="start" x="10"
                      :y="lindex !== 0 ? (lindex === 1 ? group.posY + (31.3 * 2) : group.posY + (31.3 * (lindex + 1))) : group.posY + 26"
                      :height="group.height" class="book-timeline-group-text">{{addDots(labels)}}</text>
                <line v-for="(labels, lindex) in divideGroups(group, 'group')" x1="1" x2="200"
                      :y1="lindex !== 0 ? (lindex === 1 ? group.posY + (group.height / lindex) : group.items[lindex].styleTop[group.id]) : group.posY + 40"
                      :y2="lindex !== 0 ? (lindex === 1 ? group.posY + (group.height / lindex) : group.items[lindex].styleTop[group.id]) : group.posY + 40"
                      stroke="#cecece" stroke-width="%100" stroke-linecap="butt"></line>
            </g>
            </svg>
            <svg v-else class="book-timeline-labels-svg" :height="timelineHeightInPixels + 'px'">
                <g v-for="(group, $index) in timelineData.groups" class="book-timeline-group" :class="[($index % 2 == 0) ? 'book-timeline-odd' : 'book-timeline-even', {'book-timeline-single-group': timelineData.groups.length == 1}]">
                    <rect x="0" :y="group.posY" :height="group.height" class="book-timeline-group-bkg"></rect>
                    <text text-anchor="start" x="10" :y="group.posY + 25" :height="group.height" class="book-timeline-group-text">{{addDots(group.label)}}</text>
                </g>
            </svg>
        </div>
        <div class="book-timeline-content" id="timeFixed" ref="content" v-if="timelineData.processed && hasElements">
            <div class="hour-column-fixed" :style="'width: '+timeFixedWidth+'px;'">
                <svg class="book-timeline-content-svg" height="30px">
                    <g class="book-timeline-hour-columns">
                        <g v-for="column in getTimeColumns" class="book-timeline-hour-column">
                            <line class="book-timeline-hour-column-line-fixed" :x1="column.pos" y1="0" :x2="column.pos" :y2="timelineHeightInPixels" />
                            <text class="book-timeline-hour-column-text" text-anchor="start" :x="column.pos" y="19px" dx="2px">{{column.text}}</text>

                        </g>
                    </g>
                </svg>
            </div>
            <svg class="book-timeline-content-svg" :height="timelineHeightInPixels + 'px'">
                <g class="book-timeline-hour-columns">
                    <g v-for="column in getTimeColumns" class="book-timeline-hour-column">
                        <line class="book-timeline-hour-column-line" :x1="column.pos" y1="0" :x2="column.pos" :y2="timelineHeightInPixels" />
                        <text class="book-timeline-hour-column-text" text-anchor="start" :x="column.pos" y="19px" dx="2px">{{column.text}}</text>

                    </g>
                </g>
                <rect class="book-timeline-marker" x="0" y="0" :height="timelineHeightInPixels + 'px'" :style="markerStyle" />
                <g v-for="(group, $index) in timelineData.groups" class="book-timeline-group" :class="[($index % 2 == 0) ? 'book-timeline-odd' : 'book-timeline-even', {'book-timeline-single-group': timelineData.groups.length == 1}]">
                    <rect class="book-timeline-group-bkg" x="0" :y="group.posY" :height="group.height"></rect>
                    <g v-for="item in group.items" class="book-timeline-group-item" :class="['book-item-status-' + item.status, {'book-item-fadeout': selected && selected != item.id}]" @click="$emit('open', item)" @dblclick="$emit('openDbl', item)" @mouseover="(e) => showDetailsPopup(e)" @mouseleave="(e) => hideDetailsPopup(e)">
                        <rect class="book-timeline-group-item-bkg" :x="item.styleLeft[group.id]" :y="item.styleTop[group.id]" :width="item.styleWidth[group.id]" :height="getHeight(item, group.id)"/>
                        <svg :x="item.styleLeft[group.id]" :y="item.styleTop[group.id]" :width="item.styleWidth[group.id]" :height="getHeight(item, group.id)">
                            <text class="book-timeline-group-item-text" text-anchor="start" x="0" y="0" :dx="getLabelPosition(item.id, item)" dy="18" :item-id="item.id" :title="item.label[group.id]">{{item.label[group.id]}}</text>

                            <template v-if="changedItems[item.id] && changedItems[item.id].hasOwnProperty('flags')">
                                <g class="book-marker" v-for="flag in changedItems[item.id].flags">
                                    <rect :class="changedItemsClasses[flag]" :x="getFlagPosition(item.id, flag)" y="6" rx="3" ry="3" height="16" :width="params.label[flag]"></rect>
                                    <text :class="changedItemsClasses[flag]" :x="getFlagPosition(item.id, flag, true)" y="6" dx="5" dy="11" :width="params.label[flag]">{{flag}}</text>
                                </g>
                            </template>
                        </svg>
                        <svg :x="item.styleLeft[group.id]" :y="item.styleTop[group.id]" :width="item.styleWidth[group.id]" :height="getHeight(item, group.id)">
                            <text class="book-timeline-group-item-text" text-anchor="start" x="0" y="0" :dx="getLabelPosition(item.id, item)" dy="18" :item-id="item.id"  :title="item.label[group.id]">{{item.label[group.id]}}</text>
                            <foreignObject v-if="item.crew && item.crew.length > 0 && expandCrewMemembers" x="0" y="25" width="100%" height="100%" class="book-timeline-crew-list">
                                <div v-for="crew in item.crew.sort(sortByUserName)" class="crew-item-row">
                                    <span :class="getCrewMemberClass(crew)">{{crew.user.name}}</span>
                                    <br v-if="parseFloat(item.styleWidth[group.id]) <= 12"/>
                                    <span :class="parseFloat(item.styleWidth[group.id]) > 12 ? 'crew-item-skill':'crew-item-skill-no-margin'">{{crew.skillDetails.label}}</span>
                                    <br v-if="parseFloat(item.styleWidth[group.id]) <= 12"/>
                                    <span :class="parseFloat(item.styleWidth[group.id]) > 12 ? 'crew-item-skill':'crew-item-skill-no-margin'">{{getCrewPeriodForTimeline(crew)}}</span>
                                    <br v-if="parseFloat(item.styleWidth[group.id]) <= 12"/>
                                </div>
                            </foreignObject>
                            <template v-if="changedItems[item.id] && changedItems[item.id].hasOwnProperty('flags')">
                                <g class="book-marker" v-for="flag in changedItems[item.id].flags">
                                    <rect :class="changedItemsClasses[flag]" :x="getFlagPosition(item.id, flag)" y="6" rx="3" ry="3" height="16" :width="params.label[flag]"></rect>
                                    <text :class="changedItemsClasses[flag]" :x="getFlagPosition(item.id, flag, true)" y="6" dx="5" dy="11" :width="params.label[flag]">{{flag}}</text>
                                </g>
                            </template>
                        </svg>
                    </g>
                </g>
                <rect class="book-timeline-marker2" y="0" :height="timelineHeightInPixels + 'px'" :style="markerStyle2" />
            </svg>
        </div>
        <div class="book-timeline-no-results" v-if="!hasElements">
            <span v-html="notFoundMessage"></span>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../../style/_variables.scss";
    .book-timeline {
        display: flex;
        overflow: hidden;
        .book-timeline-labels {
            width: 125px;
            z-index: 3;
        }
        .book-timeline-labels-svg {
            width: 100%;
        }
        .book-timeline-content {
            flex: 1;
            overflow: scroll;
            margin: 0;
            padding: 0 2px 0 0;
            position: relative;
            z-index: 2;
        }
        .book-timeline-content-svg {
            min-width: 905px;
            position: relative;
            width: 100%;
            z-index: 3;
        }
        .book-timeline-hour-column-line-fixed {
            stroke: darken($color-grey-light, 3%);
            stroke-width: 1px;
        }
        .book-timeline-hour-column-line {
            stroke: darken($color-grey-light, 3%);
            stroke-width: 1px;
        }
        .book-timeline-hour-column-text {
            fill: rgba($color-text, .7);
            font-size: 10px;
        }
        .book-timeline-marker,
        .book-timeline-marker2 {
            transition: all .3s ease;
        }
        @keyframes marker {
            to {
                width: 100%;
            }
        }
        .book-timeline-marker {
            animation: marker linear 1;
            fill: rgba($color-blue, .1);
            z-index: 1;
        }
        @keyframes marker2 {
            to {
                x: 100%;
            }
        }
        .book-timeline-marker2 {
            animation: marker2 linear 1;
            fill: $color-blue;
            width: 2px;
            z-index: 3;
        }
        .book-timeline-group {
            cursor: pointer;
            // &.book-timeline-odd:not(.book-timeline-single-group) {
            &.book-timeline-odd {
                .book-timeline-group-bkg {
                    fill: rgba($color-blue, .1);
                }
            }
            .book-timeline-group-bkg {
                fill: transparent;
                width: 100%;
            }
        }
        .book-timeline-group-item-bkg {
            stroke: lighten($color-grey-light, 2%);
            stroke-width: 1px;
        }
        .book-timeline-group-item-text {
            font-size: 12px;
            overflow: hidden;
        }
        .book-timeline-no-results {
            align-self: center;
            flex: 1;
            padding: 10px;
            text-align: center;
        }
        .book-timeline-crew-list{
            background-color: #fff;
            margin-top: 5px;
            font-size: 10px;
        }
        .book-item-status-7{
            color:  $color-list-item-conflict-warning;
        }
        .book-item-status-5{
            color:  $color-list-item-option;
        }
        .book-item-status-4{
            color:  $color-list-item-unsigned;
        }
        .book-item-status-3{
            color:  $color-list-item-unsigned-priority;
        }
        .book-item-status-2{
            color:  $color-list-item-signed;
        }
        .book-item-status-1{
            color:  $color-list-item-passed;
        }
        .crew-item-skill{
            color: #000000;
            margin-left: 10px;
        }
        .crew-item-skill-no-margin{
            color: #000000;
        }
        .crew-item-row{
            min-width: 250px;
        }
        .selectbox-style {
            position: absolute;
            z-index: 10;
            margin-left: 20px;
            margin-top: 3px;
            margin-right: -75px;
            border: 1px solid #CCC;
            border-radius: 3px 0 0 3px;
            float: none !important;
            -ms-flex: 1;
            flex: 1;
            padding: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .hour-column-fixed {
            position: fixed;
            height: 30px;
            background-color: #eaeaea;
            z-index: 10;
        }
    }
</style>
