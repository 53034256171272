<template>
    <div v-if="isPrint" class="col-xs-7 book-print-feed-local-notes">
        <h4>Local notes</h4>
        <i v-if="!notes || notes.length == 0">Not set</i>
        <div v-else v-for="note in notes" class="book-print-feed-local-note">
            <strong class="text-uppercase book-print-feed-local-note-country">{{note.country}}:</strong>
            <i class="book-print-feed-local-note-value">{{note.value}}</i>
        </div>
    </div>
    <div class="panel panel-info book-panel-localnotes" v-else-if="isEdit">
        <div class="panel-heading">
            Local Notes
        </div>
        <div class="panel-body">
            <div class="checkbox">
                <label v-for="country in countries" class="control-label checkbox-inline" v-if="country.group == 0">
                    <input type="checkbox" v-model="noteCountries" :value="country.code" :disabled="locked" />
                    {{country.label}}
                </label>
            </div>
            <div class="form-group form-group-sm" v-for="localNote in notes">
                <label class="control-label control-label-20 text-uppercase">{{localNote.country}}</label>
                <div class="form-control-20">
                    <textarea :rows="itemType === 'feed' ? 3 : 10" class="form-control" :name="'local_note_' + localNote.country" v-model="localNote.value" :disabled="locked"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        countries: {
            type: Array,
            default: () => []
        },
        notesProps: {
            type: Array
        },
        isPrint: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        locked: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: Number,
            default: 0
        },
        itemType: {
            type: String
        }
    },
    data() {
        return {
            notes: [],
            noteCountries: [],
            fullNotes: []
        }
    },
    mounted() {
        const context = this
        context.notes = []
        context.noteCountries = []

        if(context.notesProps && context.notesProps.length >0) {
            context.notesProps.forEach(v => {
                context.noteCountries.push(v.country)
            })

            // Copy each local note item in order to avoid pointers.
            context.notesProps.forEach(item => {
                if (!item.value) item.value = ''
                context.notes.push(Object.assign({}, item))
            })  
        }
         
        
        context.countries.forEach(c => {
            let findInProps = _.find(context.notesProps, p => p.country === c.code)
            let val = findInProps ? findInProps.value : ''
            context.fullNotes.push({country: c.code, value: val})
        })
    },
    watch: {
        notesProps(newValue) {
            const context = this
            context.notes = []
            context.noteCountries = []

            if(newValue && newValue.length >0) {
                newValue.forEach(v => {
                    context.noteCountries.push(v.country)
                })

                // Copy each local note item in order to avoid pointers.
                newValue.forEach(item => {
                    if (!item.value) item.value = ''
                    context.notes.push(Object.assign({}, item))
                })  
            }
            
            
            context.countries.forEach(c => {
                let findInProps = _.find(newValue, p => p.country === c.code)
                let val = findInProps ? findInProps.value : ''
                context.fullNotes.push({country: c.code, value: val})
            })
        },
        noteCountries(newValue, oldValue) {
            const context = this

            // A country has been selected, add it to "local notes" list.
            if (newValue.length > oldValue.length) {
                newValue.forEach(country => {
                    let i = context.notes.findIndex(v => v.country === country)
                    if (i < 0) {
                        let fNote = _.find(context.fullNotes, f => f.country === country)
                        let val = fNote ? fNote.value : ''
                        if(context.itemType === 'feed') {
                            context.notes.push({feedId: context.itemId, valueType: 'note', country: country, value: val})
                        }
                        else if(context.itemType === 'production') {
                            context.notes.push({productionId: context.itemId, valueType: 'note', country: country, value: val})
                        }
                        
                    }
                })
            }

            // A country has been unselected, remove it from "local notes" list.
            else if (newValue.length < oldValue.length) {
                oldValue.forEach(country => {
                    let i = newValue.findIndex(country2 => country === country2)
                    if (i < 0) {
                        let j = context.notes.findIndex(v => v.country === country)
                        if (j > -1) {
                            context.notes.splice(j, 1)
                        }
                    }
                })
            }

            //context.notesUpdated();
        },
        notes: {
            handler(newValue, oldValue) {
                let changed = false
                let context = this

                if(newValue.length > 0) {
                    newValue.forEach(n => {
                        let fNote = _.find(context.fullNotes, f => f.country === n.country)
                        fNote.value = n.value
                    })
                }
                

                if (context.notesProps && context.notes.length != context.notesProps.length) {
                    changed = true
                }
                else {
                    Lazy(context.notes)
                        .each((note, k) => {
                            if (changed) return
                            //const currentNote = context.notesProps.find(v => v.country == note.country)
                            const currentNote = _.find(context.notesProps, v => v.country === note.country)
                            if (currentNote !== undefined) {
                                let tmp = currentNote.value === null ? '' : currentNote.value
                                if(note.value !== tmp) {
                                    changed = true
                                }
                            }
                            else {
                                changed = true
                            }
                        })
                }
            
                
                this.$emit('localNotesChanged', [context.notes, changed]);
                
            },
            deep: true
        }
    }
}
</script>

