<script>
export default {
    computed: {
        path() {
            return this.$route.query.hasOwnProperty('path') ? this.$route.query.path : ''
        }
    }
}
</script>
<template>
<div id="access-denied" class="container">
    <div>
        <div class="book-access-denied-icon text-center">
            <font-awesome-icon icon="ban" aria-hidden="true"/>
        </div>
        <h2 class="text-center">ACCESS DENIED</h2>
        <h4 class="text-center">{{path}}</h4>
    </div>
</div>
</template>
<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
#access-denied {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 160px);
    margin: 50px auto;
    max-width: 600px;
    > * {
        flex: 1 0 100%;
    }
    .book-access-denied-icon,
    h2 {
        color: $color-red;
    }
    .book-access-denied-icon {
        font-size: 10em;
        line-height: 100%;
    }
    h2 {
        margin-bottom: 20px;
    }
}
</style>
