<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import BookSidebar from 'components/common/BookSidebar'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookCalendar from 'components/calendar/BookCalendar'
import Datetime from 'components/common/helpers/Datetime'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import ProductionCrew from 'components/production/ProductionCrew/ProductionCrew'
import Calendar from 'components/calendar/Calendar'
import User from 'components/user/User'
import jq from 'jquery';

export default {
    components: {
        BookLoader,
        BookSidebar,
        BookDateNavigation,
        BookCalendar,
        datepicker,
        Calendar
    },
    data() {
        return {
            loaded: false,
            calendar: null,
            resizingWindow: false,
            interval: {
                start: null,
                end: null,
            },
            date: {
                time: ''
            },
            renderKey: 0,
            user: null,
            userCrewData: null
        }
    },
    computed: {
        processing() {
            return this.$loader.has('BookCalendarProcessing')
        },
        hasEvents() {
            return this.$store.state.data.calendar && this.$store.state.data.calendar.length > 0
        },
        isCrew() {
            return this.$route.query.crewId != null
        },
        /**
         * Return the events that should be presented in the agenda sidebar,
         * based on the current user.
         */
        getAgendaEvents() {
            const context = this
            if (!context.hasEvents) return null
            const output = []

            let startTimestamp = context.interval.start ? context.interval.start.unix() : null
            let endTimestamp = context.interval.end ? context.interval.end.unix() : null
            //let prods = Lazy(this.$store.state.data.production)
            Lazy(context.$store.state.data.calendar)
                .filter(event => {
                    // There was a moment timing issue here, timestamp unix was wrong
                    // had to fix it like this (WN)
                    let eventStart = moment(event.start).unix()
                    let eventEnd = moment(event.end).unix()
                    switch (true) {
                        case (!context.interval.start || !context.interval.end):
                        case (eventStart >= startTimestamp && eventEnd <= endTimestamp):
                        case (eventEnd >= startTimestamp && eventStart <= endTimestamp):
                            //case (event.endTime >= startTimestamp && event.endTime <= endTimestamp):
                            return true
                    }
                    return false
                })
                .each(event => {
                    Lazy(event.user)
                        .filter(v => v.type != 'leader')
                        .each(v => {
                            // fckin calendar not getting reset. WN
                            if (v.id != context.user.id) window.location.reload(true)

                            const agendaEvent = Object.assign({}, event)
                            delete agendaEvent.user
                            agendaEvent.start = v.start
                            agendaEvent.end = v.end
                            agendaEvent.startTime = moment(v.start).unix()
                            agendaEvent.endTime = moment(v.end).unix()
                            agendaEvent.duration = v.calcInCalendar ? v.duration : 0
                            agendaEvent.role = v.role
                            agendaEvent.crewId = v.crewId
                            agendaEvent.name = v.name
                            agendaEvent.crewStatus = v.status
                            agendaEvent.crewStartPlanId = v.startPlan
                            agendaEvent.crewEndPlanId = v.endPlan
                            agendaEvent.calcInCalendar = v.calcInCalendar
                            output.push(agendaEvent)
                        })
                })
            return output.length > 0 ? output : null
        },

        /**
         * Get total hours for all booked hours.
         */
        getBookedHours() {
            if (!this.hasEvents) return '00h00'
            let output = 0
            Lazy(this.getAgendaEvents)
            .each(event => {
              // Add the time interval converting it to "hours".
              // we should look only accepted events
              if (event.crewStatus && event.crewStatus.toLowerCase() === 'accepted') {
                output += event.calcInCalendar ? event.duration : 0
              }
            })
            return Datetime.convertToHour(output)
        },

        getCreateLink() {
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    date: this.$route.query.date
                    // runType: this.getRunTypeFromUrl
                }
            }
            
            link.name = 'placeholder.create'        
            
            return link
        },

        // getNavHeigth() {
        //     return this.$root.$el.firstChild.clientHeight
        // }

    },
    created() {
        let user = this.$route.query.crewId ? this.$route.query.crewId : app.$data.user.id
        this.loadUser(user)
    },
    mounted() {
        this.$nextTick(() => {
            this.fixSidebar()
            window.addEventListener('resize', this.getWindowWidth)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResized)
    },
    watch: {

    },
    methods: {

        /**
         * Method used to fix the sidebar height when the window is resized.
         */
        windowResized() {
            if (this.resizingWindow) {
                clearTimeout(this.resizingWindow)
            }
            this.resizingWindow = setTimeout(() => {
                this.fixSidebar()
                this.resizingWindow = null
            }, 100)
        },

        /**
         * Fix the sidebar height to the same as the calendar.
         */
        fixSidebar() {
            Vue.nextTick(() => {
                setTimeout(() => {
                    let height = parseInt(jq('#calendar .fc-scroller').css('height'))
                    /**
                     * If page width is less than 525 don't apply this rule, cause calendar is hidden
                     */
                    let width = parseInt(jq('#calendar-page').css('width'))
                    if (width > 525)
                        jq('#calendar-page .book-sidebar .book-sidebar-content > div').css('max-height', height - 15 + 'px')
                }, 10)
            })
        },

        /**
         * Load events from API.
         * @param object params Parameters to send into the API.
         * @return promise
         */
        loadEvents(params) {

            params.user = this.$route.query.crewId ? this.$route.query.crewId : app.$data.user.id
          console.log('loading')
            this.$loader.add('BookCalendarProcessing')
            return new Promise((resolve, reject) => {
                this.calendar.all({
                    user: params.user,
                    start: params.start,
                    end: params.end,
                })
                    .then(response => {

                        // Parse all the new events.
                        Lazy(this.events)
                            .filter(event => !event.status &&  event.type == 'production')
                            .each(event => {
                                Item.parse(event, event.type)
                                if (event.type == 'production') {
                                    event.editable = false
                                }
                            })
                        this.$loader.remove('BookCalendarProcessing')
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status != 404) {
                            this.$error.set(error)
                        }
                        this.$loader.remove('BookCalendarProcessing')
                        reject(error)
                    })
            })
        },
        /**
         * Change the date in the URL.
         * @param string date
         */
        changeDate(date) {
            const interval = this.getMonthInterval(this.$refs.bookCalendar.fireMethod('getDate'))
            this.interval.start = date.from ? moment(date.from + ' 00:00') : moment(interval.start)
            this.interval.end = date.to ? moment(date.to + ' 23:59') : moment(interval.end)
            const query = Object.assign({}, this.$route.query)
            query.date = date.from ? date.from : date
            this.$router.push({
                query: query
            })
            // date navigation WN
            if (!this.calendar) this.calendar = new Calendar()
            this.loadEvents({
                start: this.interval.start.format('YYYY-MM-DD'),
                end: this.interval.end.format('YYYY-MM-DD')
            })


        },

        getMonthInterval(date) {
            if (!moment.isMoment(date)) {
                date = moment(date)
            }
            return {
                start: date.startOf('month').format('YYYY-MM-DD') + ' 00:00',
                end: date.endOf('month').format('YYYY-MM-DD') + ' 23:59'
            }
        },

        /**
         * Get an event link parameters for VueRouter to be used when listing
         * events on sidebar (agenda).
         * @param object event
         * @return object An object with paramters to VueRouter.
         */
        getEventLink(event) {
            if (event.runType && event.runType == 89) {
                //89 EDIT 90 ENG
                event.type = 'edit'
            }
            if (event.runType && event.runType == 90) {
                event.type = 'eng'
            }
            const output = {
                name: `${event.elementTypeDetailed}.edit`,
                params: {
                    id: this.getEventId(event)
                },
                query: {
                    destination: this.$route.name,
                }
            }
            return output
        },

        /**
         * Get the id for the passed event.
         * @param object event
         * @return integer
         */
        getEventId(event) {
            let id = event.id.split('#')
            return parseInt(id[1])
        },

        /**
         * Get the date period for an event to be presented in the sidebar (agenda).
         * @param object event
         * @return string
         */
        getEventPeriod(event) {
            const start = moment(event.start)
            const end = moment(event.end)
            return `<strong>${start.format('DD MMM')}</strong> | ${start.format('HH:mm')} - ${end.format('HH:mm')} <strong class="pull-right">${Datetime.convertToHour(event.duration)}</strong>`
        },
        /**
         * GETS from and to for date-navigation
         */
        getFromTo(event) {
            return moment(event).format('YYYY-MM-DD')
        },
        /**
         * Open the passed event in a new window.
         * @param object event         
         */
        openEvent(event) {
            const context = this
            let link_ = context.getEventLink(event)
            link_.query.newBar = link_.params.id

            let routeData = context.$router.resolve(link_);
            window.open(routeData.href, '_blank');
        },

        /** 
         * Set status for current event
        */
        setEventStatus(event, status){
            const context = this
            const eventId = context.getEventId(event)
            const productionCrew = new ProductionCrew(event.crewId, eventId);
            productionCrew.set('status', status);
            productionCrew.set('calendar_crew_status_update', true);
            productionCrew.set('start_plan_id', event.crewStartPlanId)
            productionCrew.set('end_plan_id', event.crewEndPlanId)
            productionCrew.save()
            .then(function(){
                if (status == 'accepted')
                    jq('.event-buttons-'+ event.crewId).html('<span class="text-success">Accepted</span>');
                else if (status == 'declined')
                    {
                        jq('.event-buttons-'+ event.crewId).html('<span class="text-danger">Declined</span>');
                        let event = _.find(context.$store.state.data.calendar, c => context.getEventId(c) === eventId)
                        if(event) {
                            let crew = _.find(event.user, i => i.crewId === event.crewId)
                            if(crew) {
                                crew.status = 'declined'
                            }
                        }
                    }

                }
            );
        },
        /**
         * We needed to render sidebar for to retrieve true-dates to Date-Navigation
         * Simply the best way to render
         * WN
         */
        renderingKey() {
            this.renderKey++
        },
        /**
         * Get user information based on user-id
         * @return User data
         * @type Object
         * WN
         */
        loadUser(uid) {
            this.user = null
            return new Promise((resolve, reject) => {
                if (!this.$route.query.crewId) {
                    this.user = app.$data.user
                    resolve()
                }
                else {
                    User.load(uid)
                        .then(response => {
                            this.user = new User(response.data)
                            resolve()
                        })
                        .catch(error => {
                            this.$error.set(error, 'It was not possible to load the user.')
                            reject(error)
                        })
                }
            })
        },
        /**
         * Get user data to be used on production crew list.
         * @param object crewItem A crew item object.
         * @return object|null
         */
        getUser() {
            if (this.user != null) {
                return this.user.get.fullName
            }
        },
        
    }
}

</script>

<template>
<div id="calendar-page" class="book-top-sticky-wrapper">
    <div class="panel panel-default book-content-with-sidebar book-sidebar-opened">
        <div class="row no-gutter row-padding">
            <div class="col-sm-8 col-md-9">
                <book-calendar
                        v-if="this.user != null"
                        ref="bookCalendar"
                        class="book-content"
                        :stickyHeader="true"
                        :defaultDate="this.$route.query.date"
                        :crewName="getUser()"
                        @renderKey="renderingKey()"
                        @dateChanged="item => changeDate(item)"
                        @eventSelected="item => openEvent(item)">
                </book-calendar>
            </div>
            <div class="col-sm-4 col-md-3">
                <book-sidebar :item="true" class="full-width" :sidebarType="'calendar'">
            <div slot="header" class="book-sidebar-header-title-inner">
                <div style="float: right;">
                    <book-date-navigation
                            style="padding-right: 5px;padding-bottom: 1px;"
                            v-if="this.interval.start != null"
                            :key="renderKey"
                            :from="getFromTo(this.interval.start)"
                            :to="getFromTo(this.interval.end)"
                            :showToday="false"
                            @dateChanged="item => changeDate(item)"
                    />
                    <span>Bookings</span>
                </div>
                <!-- <div style="float: right; margin-left: 10px;">
                    <router-link :to="getCreateLink" class="btn btn-success" title="Create">
                        <font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Create</span>
                    </router-link>
                </div> -->
            </div>
            
            <div slot="content" v-if="!processing">
                <div class="book-sidebar-group">
                    <div class="book-sidebar-row">
                        <div class="book-sidebar-label book-width-100">Booked time:</div>
                        <div class="book-sidebar-value">{{getBookedHours}}</div>
                    </div>
                </div>
                <div class="book-sidebar-group">
                    <div class="book-sidebar-table">
                        <div class="book-sidebar-table-header">
                            <div>Events</div>
                        </div>
                    </div>
                </div>
                <div class="book-sidebar-group text-center" v-if="!getAgendaEvents">
                    You have no booked events
                </div>
                <div class="book-sidebar-group book-sidebar-event" v-else v-for="event in getAgendaEvents">
                    <div class="book-sidebar-event-wrapper" :class="'book-item-status-' + event.status">
                        <!-- vr+ 
                        <div class="book-square"></div>
                        -->
                        <div class="book-sidebar-event-content">
                            <div class="book-sidebar-event-period" v-html="getEventPeriod(event)">
                            </div>
                            <div v-if="event.role !== null && event.role !== ''">Role: {{event.role}}</div>
                            <div class="book-sidebar-event-title" title="Click to open" style="cursor: pointer; color: #1c7bb4; padding-bottom:5px;" @click="openEvent(event)">{{event.title}} ({{getEventId(event)}})</div>
                            <br/>
                            <!-- jw+ -->
                            <div v-if="event.crewStatus == 'accepted'">
                                <span class="text-success">Accepted</span>
                            </div>
                            <div v-else-if="event.crewStatus == 'declined'">
                                <span class="text-danger">Declined</span>
                            </div>
                            <div :class="'event-buttons-' + event.crewId" v-else-if="!isCrew">
                                <button type="button" aria-label="Accept" class="book-action-button book-edit btn-success btn-sm" v-on:click="setEventStatus(event, 'accepted')">
                                    <font-awesome-icon icon="check"/><span> Accept</span>
                                </button>
                                <button type="button" aria-label="Reject" class="book-action-button book-edit btn-danger btn-sm" v-on:click="setEventStatus(event, 'declined')">
                                    <font-awesome-icon icon="times"/><span> Decline</span>
                                </button>
                            </div>
                            <div v-else>
                                <div v-if="event.crewStatus == 'accepted'">
                                    <span class="text-success">Accepted</span>
                                </div>
                                <div v-else-if="event.crewStatus == 'declined'">
                                    <span class="text-danger">Declined</span>
                                </div>
                                <span v-else>{{event.name}} is not answered yet.</span>
                            </div>
                            <!-- jw- -->
                        </div>
                    </div>
                </div>
            </div>
            <div slot="content" class="book-box-overlay" v-else>
                <book-loader></book-loader>
            </div>
        </book-sidebar>
            </div>
        </div>
        
        
    </div>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
#calendar-page {
    .cov-date-body,.datepicker-overlay {
        white-space: normal !important;
    }

    @media (min-width: 752px) {
        .no-gutter > [class*='col-'] {
            padding-right:0;
            padding-left:0;
        }
    }
    
    @media (max-width: 320px) {
        .no-gutter > [class*='col-'] {
            padding-right:15;
            padding-left:15;
        }   
    }

    @media (min-width: 725px){
        .row-padding{
            padding: 0 15px;
        }
    }


    .panel {
        > .book-calendar > #calendar {
            margin: -1px;
        }
    }
    @media (max-width: 464px) {
        .panel {
            top: 65px;
            position: relative;
        }
    }
    // @media (max-width: 397px) {
    //     .panel {
    //         top: 65px;
    //         position: relative;
    //     }
    // }
    // @media (min-width: 397px) and (max-width: 464px){
    //     .panel {
    //         top: 35px;
    //         position: relative;
    //     }
    // }
    @media (min-width: 465px) and (max-width: 765px) {
        .panel {
            top: 35px;
            position: relative;
        }
    }
    // // @media (min-width: 765px) and (max-width: 767px){
    // //     .panel {
    // //         top: 0;
    // //     }
    // // }
    // @media (min-width: 765px) and (max-width: 856px){
    //     .panel {
    //         top: 35px;
    //         position: relative;
    //     }
    // }
    // @media (min-width: 856px){
    //     .panel {
    //         top: 0;
    //     }
    // }
    @media (min-width: 765px){
        .panel {
            top: 0;
        }
    }
    .book-sidebar {
        .book-date-navigation {

        }

        .book-sidebar-header {
        }
        .book-sidebar-content {
            padding: 0;
            > div {
                overflow-y: auto;
                padding: 10px;
            }
        }
        .book-sidebar-event {
            border-bottom: 1px solid $color-grey-border;
            padding-bottom: 8px;
        }
        .book-sidebar-event-wrapper {
            display: block;
            .book-square {
                float: left;
            }
            .book-sidebar-event-content,
            .book-sidebar-event-title {
                line-height: 20px;
            }
            .book-sidebar-event-content {
                overflow: hidden;
                padding-left: 10px;
            }
            .book-sidebar-event-title {
                display: inline-block;
            }
        }
    }
}
</style>
