import Api from 'components/app/Api'
import {app} from 'app'
import store from 'store'
const cachios = require('cachios')

class UserNotifications  {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        // super({
        //     id: id,
        //     apiUrl: 'api/usernotifications',
        //     module: 'notifications',
        // })
    }
    
    getUserNotifications(params = {}, reset = false, ttl = 2) {
        return new Promise((resolve, reject) => {
            cachios.get('api/usernotifications', {params: params, ttl: ttl})
                .then(response => {
                    if (response.status === 200) {
                        store.commit('setData', {key: 'notReadNotifications', data: response.data.notReadNotifications, reset: reset})
                        store.commit('setData', {key: 'readNotifications', data: response.data.readNotifications, reset: reset})
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Send read notifications to the server to mark them as read.
     * @param object data
     * @return promise
     */
    markNotificationsRead(data) {
        return new Promise((resolve, reject) => {
            axios.post('api/usernotifications', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
}
export default UserNotifications