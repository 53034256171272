import {app} from 'app'
class RouterTools {
    static isActive(paths) {
        if (typeof paths === 'string') {
            paths = [paths]
        }
        return paths.some(name => {
            return name === app.$route.name
        })
    }
}
export default RouterTools
