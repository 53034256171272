import {app} from 'app'
import Api from 'components/app/Api'

class Lock extends Api {

    /**
     * Constructor.
     * @param integer id The id to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/lock',
            module: 'lock',
        })
    }

    /**
     * Check if a given item lock tells that the item is locked or not.
     */
    static isLocked(lock) {
        return lock && (lock.user.id != app.$data.user.get.id)
    }

}

export default Lock
