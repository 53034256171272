const state = {
  data: null,
}

const getters = {}
const actions = {}

const mutations = {
    // setData(state, data) {
    //     state.data = data
    // },
    // set(state, entity) {
    //     if (!state.data) {
    //         state.data = {}
    //     }
    //     Vue.set(state.data, entity.id, entity)
    // },
    // setField() {
    //
    // },
    // delete(state, id) {
    //     Vue.delete(state.data, id)
    // },
    updateItems(state, items) {
        items.forEach(function(v, k) {
            v.weight = k
        })
        state.data[items[0].entity].items = items
    },
    // setItem(state, item) {
    //
    //
    //     let i = state.data[item.entity].items.findIndex(function(v, k) {
    //         return v.id == item.id
    //     })
    //
    //     if (i < 0) {
    //         state.data[item.entity].items.push(item)
    //     }
    //     else {
    //         state.data[item.entity].items[i] = item
    //     }
    //
    //
    // },
    // setItemField(state, {id, entity, field, value}) {
    //   let i = state.data[entity].items.findIndex(function(v, k) {
    //       return v.id == id
    //   })
    //   state.data[entity].items[i][field] = value
    // }
}

const entity = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export {entity}