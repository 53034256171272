import store from 'store'
import Api from 'components/app/Api'

class ProductionWorkgroup extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null, productionId = 0) {
        super({
            id: id,
            apiUrl: 'api/production/' + productionId + '/workgroup',
            module: 'productionWorkgroup',
        })
    }

   


}

export default ProductionWorkgroup