<script>
import BookTable from 'components/common/BookTable'
import Datetime from 'components/common/helpers/Datetime'

export default {
    components: {
        BookTable
    },
    props: {
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        checkedProductions: {
            default: () => {
                return []
            },
            type: Array
        },
        showOnlyChecked: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            checkedItems: [],
            checkedAll: false
        }
    },
    computed: {
        columns() {
            let columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false,
                    class: ''
                },
                {
                    value: 'productionType',
                    label: 'productionType',
                    visible: false,
                    class: ''
                },
                {
                    value: 'week',
                    label: 'Week',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-week'
                },
                {
                    value: 'day',
                    label: 'Day',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-day'
                },
                {
                    value: 'date',
                    label: 'Date',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-date'
                },
                {
                    value: 'time',
                    label: 'Time',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-time'
                },
                {
                    value: 'runType',
                    label: 'Run Type',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-runtype'
                },
                {
                    value: 'channel',
                    label: 'Channel',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-channel'
                },
                {
                    value: 'sport',
                    label: 'Sport',
                    visible: false,
                    class: ''
                },
                {
                    value: 'league',
                    label: 'League',
                    visible: false,
                    class: ''
                },
                {
                    value: 'title',
                    label: 'Title',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: ''
                },
                {
                    value: 'country',
                    label: 'Country',
                    visible: false,
                    class: ''
                }
            ]

            return columns
        },
        productionsData() {
            let productions = []
            const context = this
            context.checkedItems = []
            let currentChecked = []
            //context.checkedAll = false
            Lazy(context.items)
            .each((v,k) => {
                let checked = false
                if(v.checked) {
                    currentChecked.push(v.id)
                }

                productions.push({
                    id: {
                            value: v.id
                        },
                   week: {
                        value: Datetime.getWeekNumber(v.productionStart)
                    },
                    day: {
                        value: Datetime.getWeekDay(v.productionStart)
                    },
                    date: {
                        value: Datetime.getDate(v.productionStart)
                    },
                    time: {
                        value: Datetime.getTime(v.productionStart, v.productionEnd)
                    },
                    runType: {
                        rawValue: v.runType,
                        value: v.runTypeLabel
                    },
                    channel: {
                        rawValue: v.channel,
                        value: v.channelLabel
                    },
                    title: {
                        value: `${v.sportLabel} | ${v.leagueLabel} | ${v.title}`
                    },
                    country: {
                        value: v.country
                    },
                    rowOptions: {
                        visible: v.visible,
                        checked: v.checked
                        }
                })
            })

            const output = {
                options: {
                    checkboxes: {
                        eventName: 'itemChecked'
                    },
                    selectall: {
                        eventName: 'allItemsChecked'
                    }
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false,
                        class: ''
                    },
                    {
                        value: 'week',
                        label: 'Week',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-week'
                    },
                    {
                        value: 'day',
                        label: 'Day',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-day'
                    },
                    {
                        value: 'date',
                        label: 'Date',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-date'
                    },
                    {
                        value: 'time',
                        label: 'Time',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-time'
                    },
                    {
                        value: 'runType',
                        label: 'Run Type',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-runtype'
                    },
                    {
                        value: 'channel',
                        label: 'Channel',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: 'column-channel'
                    },
                    {
                        value: 'title',
                        label: 'Title',
                        sortable: true,
                        searchable: false,
                        visible: true,
                        class: ''
                    },
                    {
                        value: 'country',
                        label: 'Country',
                        visible: false,
                        class: ''
                    }
                ],
                rows: productions
            }

            //if((_.xor(context.checkedItems, currentChecked)).length > 0) {
                context.checkedItems = currentChecked
                
                //context.$emit('updateChosenProductions', JSON.stringify(context.checkedItems))
            //}


            return output
        },
        hasElements() {
            return this.items && this.items.length > 0
        },
        visibleUsersCount() {
            return this.items && this.items.length > 0 ? (this.showOnlyChecked ?  this.items.length : (this.items.filter(v => v.rowOptions.visible)).length) : 0
        }
    },
    watch: {
        visibleUsersCount: function(newVlaue) {
            if(this.checkedItems.length !== newVlaue && this.checkedAll) this.checkedAll = false
            if(this.checkedItems.length == newVlaue && !this.checkedAll) this.checkedAll = true
        }
    },
    methods: {
        // itemChecked(item) {
        //     let currentProduction = this.productionsData.rows.find(i => i.id.value == item)
        //     let indx = this.checkedItems.findIndex(i => i === item)
        //     if(currentProduction.rowOptions.checked) {
        //         if(this.checkedAll) this.checkedAll = false
                
        //         if(indx > -1) {
        //             this.checkedItems.splice(indx, 1)
        //         }
        //     }
        //     else {
        //         if(indx == -1) this.checkedItems.push(item)
        //     }

        //     this.$emit('updateChosenProductions', JSON.stringify(this.checkedItems))
        // },
        // allItemsChecked() {
        //     this.checkedItems = []
        //     if(!this.checkedAll) {
        //         Lazy(this.productionsData.rows)
        //         .each(v => {
        //             if(v.rowOptions.visible) {
        //                 this.checkedItems.push(v.id.value)
        //                 v.rowOptions.checked = true
        //             }
        //             else {
        //                 v.rowOptions.checked = false
        //             }
                    
        //         })
        //     }
        //     else {
        //         Lazy(this.productionsData.rows)
        //         .each(v => {
        //             v.rowOptions.checked = false
        //         })
        //     }
        //     this.$emit('updateChosenProductions', JSON.stringify(this.checkedItems))
        // },
        allItemsChecked() {
            this.checkedItems = []
            let checkedprods = this.checkedProductions.slice()
            if(!this.checkedAll) {
                Lazy(this.items)
                .each(v => {
                    let indx = checkedprods.findIndex(i => i === v.id.value)
                    if(v.rowOptions.visible) {
                        this.checkedItems.push(v.id.value)
                        if(indx === -1) checkedprods.push(v.id.value)
                        v.rowOptions.checked = true
                    }
                    else {
                        v.rowOptions.checked = false
                    }
                    
                })
            }
            else {
                Lazy(this.items)
                .each(v => {
                    let ind = checkedprods.findIndex(i => i === v.id.value)
                    if(ind > -1) checkedprods.splice(ind, 1)
                    v.rowOptions.checked = false
                })
            }
            this.$emit('updateChosenProductions', JSON.stringify(checkedprods))
        },
        itemChecked(row) {
            //let currentProduction = this.items.find(i => i.id.value == productionId)
            row.rowOptions.checked = !row.rowOptions.checked
            let indx = this.checkedItems.findIndex(i => i === row.id.value)
            let checkedprods = this.checkedProductions.slice()
            let ind = checkedprods.findIndex(i => i === row.id.value)
            if(!row.rowOptions.checked) {
                if(this.checkedAll) this.checkedAll = false
                
                if(indx > -1) {
                    this.checkedItems.splice(indx, 1)
                }
                if(ind > -1) {
                    checkedprods.splice(ind, 1)
                }

                //row.rowOptions.checked = false
            }
            else {
                if(indx === -1) this.checkedItems.push(row.id.value)
                if(ind === -1) checkedprods.push(row.id.value)

                //row.rowOptions.checked = true
            }

            this.$emit('updateChosenProductions', JSON.stringify(checkedprods))
        },
        isColumnVisible(name) {
            let column = this.columns.find(i => i.value == name)
            return column !== undefined ? column.visible : false
        },
        getColumnClass(name) {
            let column = this.columns.find(i => i.value == name)
            return column !== undefined ? column.class : ''
        },
        openProduction(row) {
            const link = {
                name: `${row.productionType.value}.edit`,
                params: {
                    id: row.id.value,
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }

            let routeData = this.$router.resolve(link);
            window.open(routeData.href, '_blank');
        }
    }
}
</script>
<template>
    <div id="crewproductions">
        <div class="book-crewproductions">
            <!-- <book-affix relative-element-selector=".book-crewproductions" :offset="{ top: 115, bottom: 30 }" :listenScrollOn="'#prods'" class="book-crewproductions-day-navigation-split" v-if="hasElements">
                <div class="book-crewproductions-label" style="padding: 2px 4px;">
                    <input type="checkbox" v-model="checkedAll" @click="allItemsChecked()" style="margin: 0px;">
                    <span>Select All</span>
                    <span class="book-crewview-date" style="padding-left: 17px;">({{checkedItems.length}} of {{productionsData.rows.length}})</span>
                </div>
                <div class="book-crewproductions-row-content">
                    <div class="book-crewproductions-column" v-for="column in productionsData.columns" :class="column.class" v-if="column.visible">
                        <div class="book-crewproductions-day">
                            <span class="book-crewproductions-date">{{column.label}}</span>
                        </div>
                    </div>
                </div>
            </book-affix>
            <div class="book-crewproductions-content-split" v-if="hasElements">
                <div class="book-crewproductions-row" v-for="(row, $index) in productionsData.rows" :class="($index % 2 == 0) ? 'book-crewproductions-odd' : 'book-crewproductions-even'" v-show="row.rowOptions.visible">
                    <div class="book-crewproductions-label" :title="row.id.value">
                        <input type="checkbox" v-model="row.rowOptions.checked" @click="itemChecked(row.id.value)">
                    </div>
                    <div class="book-crewproductions-row-content">
                        <div class="book-crewproductions-column" v-for="(v, k) in row" v-if="isColumnVisible(k)" :key="row.id.value + '-' + k" :class="getColumnClass(k)">
                            <div class="book-crewproductions-item book-item-bkg">
                                <span class="book-crewproductions-item-time">{{v.value}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <book-affix relative-element-selector=".book-crewproductions" :offset="{ top: 115, bottom: 30 }" :listenScrollOn="'#prods'" class="book-crewproductions-day-navigation-split" v-if="hasElements">
                <div class="book-crewproductions-label" style="padding: 2px 4px;">
                    <input type="checkbox" v-model="checkedAll" @click="allItemsChecked()" style="margin: 0px;">
                    <span>Select All</span>
                    <span class="book-crewview-date" style="padding-left: 17px;">({{checkedProductions.length}} of {{items.length}})</span>
                </div>
                <div class="book-crewproductions-row-content">
                    <div class="book-crewproductions-column" v-for="column in columns" :class="column.class" v-if="column.visible">
                        <div class="book-crewproductions-day">
                            <span class="book-crewproductions-date">{{column.label}}</span>
                        </div>
                    </div>
                </div>
            </book-affix>
            <div class="book-crewproductions-content-split" v-if="hasElements">
                <div class="book-crewproductions-row" v-for="(row, $index) in items" :class="($index % 2 == 0) ? 'book-crewproductions-odd' : 'book-crewproductions-even'" v-show="showOnlyChecked || row.rowOptions.visible">
                    <div class="book-crewproductions-label" :title="row.id.value">
                        <input type="checkbox" @click="itemChecked(row)" v-model="row.rowOptions.checked" :key="row.id.value">
                    </div>
                    <div class="book-crewproductions-row-content" @click="openProduction(row)">
                        <div class="book-crewproductions-column" v-for="(v, k) in row" v-if="isColumnVisible(k)" :key="row.id.value + '-' + k" :class="getColumnClass(k)">
                            <div class="book-crewproductions-item book-item-bkg">
                                <span class="book-crewproductions-item-time">{{v.value}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="book-productions-no-results" v-if="!hasElements">
                <span>No productions or tasks found</span>
            </div>
        </div>
    </div>
    <!-- <div>
        <book-table
            component="crew-view"
            :items="productionsData"
            @itemChecked="item => itemChecked(item)"
            @allItemsChecked="value => allItemsChecked(value)"
            notFoundMessage="No productions found"
            >
        </book-table>
    </div> -->
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewproductions {
    .panel > .book-crewproductions {
        margin-bottom: -1px;
    }
    .book-crewproductions {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: hidden;
        .book-crewproductions-day-navigation,
        .book-crewproductions-content {
            min-width: 800px;
            width: 100%;
        }
        .book-crewproductions-day-navigation-split {
            // min-width: 800px;
            //width: calc(100% - 130px);
            display: flex;
            height: 85px;
            //top: 0 !important;
        }
        .book-crewproductions-content-split {
            //width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split > .book-crewproductions-row-content {
            width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                //left: 15px;
                width: calc(100% / 12 * 7 - 48px);
                z-index: 1000;
                //top: 0;
            }
            .book-crewproductions-label {
                display: inline-block;
            }
            .book-crewproductions-label,
            .book-crewproductions-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-crewproductions-label > span {
                font-weight: bold;
                position: relative;
                text-align: left;
            }
            .book-crewproductions-column {
                padding: 2px 4px;
                // &.book-crewproductions-with-week {
                //     .book-crewproductions-day {
                //         padding-left: 25px;
                //     }
                // }
                &.book-crewproductions-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-crewproductions-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-crewproductions-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-crewproductions-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-crewproductions-week
            .book-crewproductions-weekday,
            .book-crewproductions-date {
                display: block;
            }
            .book-crewproductions-weekday {

            }
        }
        .affix + .book-crewproductions-content {
            margin-top: 56px;
        }
        .book-crewproductions-content {
            .book-crewproductions-column {
                padding: 2px 0;
            }
        }
        .book-crewproductions-day-navigation,
        .book-crewproductions-row {
            display: flex;
        }
        .book-crewproductions-row {
            min-height: 38px;
            &:last-child {
                .book-crewproductions-label,
                .book-crewproductions-column:not(.book-crewproductions-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
                .book-crewproductions-column {
                    &.book-crewproductions-today {
                        border-bottom-width: 2px;
                    }
                }
            }
            &.book-crewproductions-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-crewproductions-label {
            display: flex;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 95px;
            min-width: 95px;
            overflow: hidden;
            padding: 2px 4px 2px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 95px;
                min-width: 95px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-crewproductions-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-crewproductions-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-crewproductions-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                //margin-left: -1px;
                z-index: 2;
                // .book-crewproductions-week {
                //     bottom: -2px;
                // }
            }
        }
        .book-crewproductions-column-info-tools {
            display: inline-block;
            margin-right: 5px;
        }
        .book-crewproductions-label,
        .book-crewproductions-item {
            // font-size: 12px;
            // line-height: 1.3em;
        }
        .book-crewproductions-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-crewproductions-item-top-row {
            display: flex;
            font-size: 10px;
        }
        .book-crewproductions-item-flag,
        .book-crewproductions-item-league,
        .book-crewproductions-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-crewproductions-item-time,
        .book-crewproductions-item-league,
        .book-crewproductions-item-flag {
            display: inline-block;
        }
        .book-crewproductions-item-title {
            display: block;
        }
        .book-crewproductions-item-time {
            flex: 1 0 auto;
        }
        .book-crewproductions-item-flag {
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-item-league {
            flex: 30 1 auto;
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
        .book-crewproductions-item-plus {
            height: 20px;
            font-weight: bold;
            //padding: 5px 7px;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px;
            text-align: center;
        }
        .btn-plus {
            // position: absolute;
            // bottom: 1px;
            padding: 0px;
            width: 100%;
        }

        .column-week {
            max-width: 60px;
        }
        .column-day {
            max-width: 85px;
        }
        .column-date {
            max-width: 100px;
        }
        .column-time {
            max-width: 110px;
        }
        .column-runtype {
            max-width: 200px;
        }
        .column-channel {
            max-width: 150px;
        }

        .book-productions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
    }
}
</style>