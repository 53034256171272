import {app} from 'app'
import store from 'store'
import router from 'router'
import socket from 'socket'

class Auth {

    static setToken(token) {
        store.commit('auth/setToken', token)
    }

    static getToken() {
        if ((Object.keys(store.state.auth.token).length > 0) && (store.state.auth.token.access_token !== undefined)) {
            return store.state.auth.token.access_token
        }
        let token = JSON.parse(localStorage.getItem('token'))
        if (token && (token.access_token !== undefined)) {
            store.commit('auth/setToken', token)
            return store.state.auth.token.access_token
        }
        return null
    }

    /**
     * Autheticate a user.
     */
    static login(credentials) {
        let context = this
        let config = app.$store.state.config
        credentials.grant_type = config.GRANT_TYPE
        let promise = new Promise((resolve, reject) => {
            axios.post('oauth/token', credentials)
                .then(response => {
                    if ((response.status === 200) && (response.data.access_token !== undefined)) {
                        context.setToken(response.data)
                        context.setAuthorizationToken()
                    }
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
        return promise
    }

    /**
     * Log out.
     * @return promise
     */
    static logout() {
        let parent = this
        return new Promise((resolve, reject) => {
            axios.get('api/user/logout')
                .then(response => {
                    if ((response.status === 200)) {
                        parent.reset()
                        document.location = '/'
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    /**
     * Reset the current session.
     */
    static reset() {
        sessionStorage.clear();
        app.$ability.update([])
        store.commit('auth/setToken', {})
        store.commit('user/setData', {})
        delete app.$data.user
    }

    /**
     * Refresh the access token.
     */
    static refreshToken() {
        return new Promise((resolve, reject) => {
            const data = {
                grant_type: 'refresh_token',
                refresh_token: store.state.auth.token.refresh_token,
            }
            axios.post('oauth/token', data)
                .then(response => {
                    this.setToken(response.data)
                    this.setAuthorizationToken()                    
                    resolve(response)

                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * Set the current authorization token on the header of all Axios calls.
     */
    static setAuthorizationToken() {
        axios.defaults.headers.common['Authorization'] = store.state.auth.token.token_type + ' ' + store.state.auth.token.access_token

        // Add Socket here instead of on app.js, because we don't know how to
        // change only the authorization header in Laravel Echo.
        Vue.nextTick(function() {
            Vue.use(socket, {
                broadcaster: 'socket.io',
                connector: 'socket.io',
                host: store.state.config.SOCKET_URL,
                namespace: false,
                auth: {
                    headers: {
                        Authorization: store.state.auth.token.token_type + ' ' + store.state.auth.token.access_token
                    }
                },
            })
        })

    }

    /**
     * Autheticate a user.
     */
    static createPasswordReset(credentials) {
        let context = this
        credentials.url = store.state.config.APP_URL
        let promise = new Promise((resolve, reject) => {
            axios.post('api/user/reset/create', credentials)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return promise
    }

    /**
     * Create a new password for the given credentials.
     * @param object credentials
     * @return promise
     */
    static resetPassword(credentials) {
        credentials.url = store.state.config.APP_URL
        return new Promise((resolve, reject) => {
            axios.post('api/user/reset', credentials)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Check if the passed token is valid or not.
     */
    static checkResetToken(token) {
        let promise = new Promise((resolve, reject) => {
            axios.get('api/user/reset/' + token)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return promise
    }

}

export default Auth
