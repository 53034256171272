import store from 'store'

class Settings {

    static getUrl() {
        return 'api/setting'
    }

    static get get() {
        return store.state.settings.data || null
    }

    static set(name, value) {
        store.commit('settings/save', {name: name, value: value})
    }

    static update(settings) {
        Lazy(settings)
            .each(v => {
                this.set(v.name, v.value)
            })
    }

    static store() {
        return new Promise((resolve, reject) => {
            axios.post(this.getUrl(), this.get)
                .then(response => {
                    if (response.status === 200) {
                        response.data.forEach(v => {
                            store.commit('settings/save', v)
                        })
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Load settings from API.
     */
    static load() {
        return new Promise((resolve, reject) => {
            axios.get(this.getUrl())
                .then(response => {
                    if (response.status === 200) {
                        response.data.forEach(v => {
                            store.commit('settings/save', v)
                        })
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}
export default Settings
