<script>
import BookEquipmentOverview from 'components/equipment/EquipmentOverview/BookEquipmentOverview'
import BookProductionsMenu from 'components/production/Productions/BookProductionsMenu'

export default {
    components: {
        BookEquipmentOverview,
        BookProductionsMenu,
    },
    props: {
        productionType: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            actions: [
                {
                    action: 'create',
                    label: 'Create',
                },
                {
                    action: 'addToExisting',
                    label: 'Add to existing',
                },
            ]
        }
    }
}
</script>
<template>
    <book-equipment-overview :actions="actions" :productionType="productionType">
        <template v-slot:secondaryMenu>
            <book-productions-menu :productionType="productionType" />
        </template>
    </book-equipment-overview>
</template>