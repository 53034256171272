import Api from 'components/app/Api'
import cachios from "cachios";
class EquipmentOverview extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/equipment-overview',
            module: 'equipmentOverview',
        })
    }

    /**
     * Return all items.
     */
    all(params = {}, reset = false, ttl = 2) {
        const context = this
        return new Promise((resolve, reject) => {
            cachios.get(context.options.apiUrl, {params: params, ttl: ttl})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}
export default EquipmentOverview
