<script>
import {app} from 'app'
import ItemFlagsMixin from 'components/common/item/ItemFlagsMixin'
import BookIngestion from 'components/ingestion/BookIngestion'
import BookModal from 'components/common/BookModal'
import BookSelectbox from 'components/common/BookSelectbox'
import Production from 'components/production/Production'
import Feed from 'components/feed/Feed'
import Item from 'components/common/item/Item'
import draggable from 'vuedraggable'
import _ from 'lodash';

export default {
    mixins: [ItemFlagsMixin],
    components: {
        BookIngestion,
        BookSelectbox,
        BookModal,
        draggable,
    },
    props: {
        items: {
            default: () => {
                return {}
            },
            type: Object
        },
        itemsType: {
            default: '',
            type: String
        },
        component: {
          default: '',
          type: String
        },
        selected: {
            default: null
        },
        striped: {
            default: false,
            type: Boolean
        },
        notFoundMessage: {
            default: 'No data found!',
            type: String
        },
        draggable: {
            default: false,
            type: Boolean
        },
        print: {
            default: false,
            type: Boolean
        },
        ingestors: {
            default: null,
            type: Object
        },
        trackFilters: {
            default: false,
            type: Boolean
        },
        needRender: {
          default: false,
          type: Boolean
        },
        darkMode: {
          default: false
        },
    },
    data() {
        return {
            loaded: false,
            searchTerm: '',
            filters: {},
            sidebar: false,
            tableData: JSON.parse(JSON.stringify(this.items)),
            sort: {
                column: '',
                reverse: false
            },
            defaultActions: ['edit', 'preview', 'clone', 'delete'],
            formData: this.defaultData()
        }
    },
    watch: {
        items: {
            handler: function(newValue, oldValue) {
                /**
                 * SideBar causes rendering because it changes the DOM of all other component pages also
                 * this should fix it for all pages that depends each other and stop rendering.
                 * WN
                 * P.S.: When rows of newvalue and oldvalue changes, let it re-render...
                 */
                if (this.sidebar && oldValue.rows.length === newValue.rows.length) {
                    setTimeout(() => {
                        this.sidebar = false;
                    }, 500)
                    return
                }
                console.log('I, BookTable rendering for:', this.component, app);
                // Data needs to rendered if anything changes
                this.tableData = JSON.parse(JSON.stringify(newValue))
                // list-view should sort by date
                if (this.$route.params.view === 'list') {
                    this.sortByDate()
                }
                if (this.itemsType === "Equipment") {
                     let defaultColumn = this.tableData.columns.find(c => c.value === 'type') 
                     this.sort.column = defaultColumn !== undefined && defaultColumn.visible ? 'type' : 'equipment'
                }
                if (this.itemsType === 'Associations') {
                  this.sort.column = 'type'
                }
                
                // then prepearetabledata
                this.prepareTableData()
            },
            deep: true
        },
    },
    computed: {
        hasSearch() {
            return this.tableData.columns.find(v => v.searchable)
        },
        hasFilters() {
            return this.tableData.options && this.tableData.options.filters && Object.keys(this.tableData.options.filters).length > 0
        },
        hasIngestions() {
            return this.tableData.options && this.tableData.options.ingestions && this.tableData.options.ingestions.length > 0
        },
        hasActions() {
            return this.tableData.options && this.tableData.options.actions && this.tableData.options.actions.length > 0 && !this.print
        },
        hasCheckBox() {
            return this.tableData.options && this.tableData.options.hasOwnProperty('checkboxes')// && !this.print
        },
        hasAlternativeCheckBox() {
            return this.tableData.options && this.tableData.options.hasOwnProperty('alternativeCheckboxes')// && !this.print
        },
        hasRows() {
            return this.rows.length > 0
        },
        getRowComponent() {
            return this.draggable ? 'draggable' : 'tbody'
        },
        rows: {
            get() {
                const context = this

                // Return the rows without manipulation, because no filter/sort is set.
                if (!context.sort.column && (Object.keys(context.filters).length == 0) && context.searchTerm == '') {
                    return Lazy(context.tableData.rows)
                    .sortBy(row => context.draggable ? row.weight.value : '', false)
                    .toArray()
                }

                return Lazy(context.tableData.rows)
                .filter(row => {
                    let visible = true
                    Lazy(context.filters)
                    .each((v, k) => {
                        let field = row[k].rawValue ? 'rawValue' : 'value'
                        if (row[k][field] != v) visible = false
                    })

                    // If the current element is visible, try to hide it
                    // through search term.
                    if (visible && context.searchTerm != '') {
                        let searchHit = false
                        Lazy(row)
                        .each((v, k) => {

                            // A search hit for the current row is already available,
                            // or the column isn't searchable. Do not perform the search.
                            if (searchHit || (!context.tableData.columns.find(column => k == column.value && column.searchable))) {
                                return true
                            }

                            let value = v.value + ''
                            if (value.toLowerCase().indexOf(context.searchTerm) > -1) {
                                searchHit = true
                                visible = true
                            }
                            else {
                                visible = false
                            }
                        })
                    }

                    return visible
                })
                .sortBy(row => {
                    const sortColumns = []
                    
                    if (context.sort.column && row[context.sort.column].value) {
                        //Check for a value. If a value is the html element, then sort by rawValue
                        if (row[context.sort.column].value.indexOf("<") == 0){
                            if (row[context.sort.column].rawValue)
                                sortColumns.push(typeof row[context.sort.column].rawValue == 'string' ? row[context.sort.column].rawValue.toLowerCase() : row[context.sort.column].rawValue)
                        }else{
                            sortColumns.push(typeof row[context.sort.column].value == 'string' ? row[context.sort.column].value.toLowerCase() : row[context.sort.column].value)
                        }
                        
                    }

                    // Check if the current column should be sorted together with others.
                    let i = context.tableData.columns.findIndex(v => v.value == context.sort.column && v.sortWith)
                    if (i > -1) {
                        context.tableData.columns[i].sortWith.forEach(column => {
                            sortColumns.push(row[column].value.toLowerCase())
                        })
                    }

                    // Add "weight" as a sort column, if "draggable rows" is enabled.
                    if (context.draggable && row.weight) sortColumns.push(row.weight.value)

                    return sortColumns

                }, context.sort.reverse)
                .toArray()
            },
            set(rows) {
                rows.forEach(function(v, k) {
                    if (!v.weight) v.weight = {value: 0}
                    v.weight.value = k
                })
                this.$emit('dragEnd', rows)
            }
        },
    },
    beforeMount() {
        // Huge perf boost here on first loading
        // By doing nextTick, preventing double rendering at the same time. WN
        if (this.$parent.$options._componentTag == 'book-equipment-selection' || this.$route.query.newBar) {
            //console.log('Slow mode activated')
            this.prepareTableData()
        } else {
            Vue.nextTick(() => {
                this.prepareTableData()
            })
        }
    },
    mounted() {
        const context = this
        // list-view should sort by date
        if (this.$route.params.view === 'list') {
            this.sortByDate()
        }
        if (this.component === 'associations') {
          this.sortByType()
        }
        // Set filters.
        if (context.tableData.options.filters) {
            Lazy(context.tableData.options.filters)
            .each((v, k) => {
                if (v.selected) {
                    context.filterBy(k)
                }
            })
        }

    },
    methods: {

      sortByType() {
        if( this.tableData.columns.find(c => c.value === 'type') !== undefined ) {
          this.sort.column = 'type'
        }
      },
        /**
         * Sort columns after an update/render.
         */
        sortByDate() {
            if( this.tableData.columns.find(c => c.value === 'date') !== undefined ) {
                this.sort.column = 'date'
            }
            
        },
        /**
         * Prepare the passed data that will be used in the table.
         */
        prepareTableData() {
            //console.log('Preparing tables..')
            const context = this
            if (context.tableData.options && (typeof context.tableData.options == 'object')) {
                context.tableData.columns.forEach(v => {
                    if (typeof v.visible == 'undefined') {
                        v.visible = true
                    }
                    if (typeof v.style == 'undefined') {
                        v.style = {}
                    }
                })
            }
            else {
                context.tableData.options = {}
            }

            if (!context.tableData.options.hasOwnProperty('actionSettings')) {
                Vue.set(context.tableData.options, 'actionSettings', {})
            }

            // Add some required parameters.
            if (context.tableData.columns && (typeof context.tableData.columns == 'object')) {
                context.tableData.columns.forEach(v => {
                    if (typeof v.visible == 'undefined') {
                        v.visible = true
                    }
                })
            }
            else {
                context.tableData.columns = []
            }

            //const now = moment().unix()

            const rowOptionsDefaults = {
                classes: [],
                highlighted: false,
            }

            // Add rowOptions to each row, if not available.
            if (context.tableData.rows && (typeof context.tableData.rows == 'object')) {
                context.tableData.rows.forEach((v, k) => {
                    if (!v.rowOptions) Vue.set(v, 'rowOptions', rowOptionsDefaults)
                    if (!v.classes) v.classes = []
                    context.loaded = true
                })
            }
            else {
                this.tableData.rows = []
            }

        },

        /**
         * Filter the list by a given key (column value)
         * @param string key
         */
        filterBy(key) {
            const context = this
            if (!context.tableData.options.filters[key].selected) {
                Vue.delete(context.filters, key)
            }
            else if (context.tableData.options.filters[key].selected == '_empty_') {
                Vue.set(context.filters, key, '')
            }
            else {
                Vue.set(context.filters, key, context.tableData.options.filters[key].selected)
            }

            if(context.trackFilters) {
                this.$emit('updateFilters', context.tableData.options.filters)
            }
        },

        /**
         * Sort the list by a given column.
         * @param object column
         */
        sortBy(column) {
            console.log("sort");
            if (!column.sortable || this.print) return
            this.sort.reverse = (this.sort.column == column.value) ? !this.sort.reverse : false
            this.sort.column = column.value;
        },

        /**
         * Clear all filters.
         */
        clearFilters() {
            const context = this
            Lazy(context.tableData.options.filters)
                .each((v, k) => {
                    v.selected = ''
                })
            context.filters = {}
            context.searchTerm = ''
        },

        buttonIsPresent(button, byPass = false) {
            return this.tableData.options.actions.indexOf(button) > -1
        },
        getButtonClasses(button, byPass = false) {
            if (!byPass && !this.buttonIsPresent(button)) return []
            let classes = []
            switch (button) {
                case 'edit':
                    classes.push('btn-primary')
                    break
                case 'preview':
                    classes.push('btn-success')
                    break
                case 'clone':
                    classes.push('btn-info')
                    break
                case 'cloneProd':
                case 'icon':
                case 'template':
                    classes.push('btn-warning')
                    break
                case 'delete':
                    classes.push('btn-danger')
                    break
                case 'apply':
                    classes.push('btn-special')
                    break
            }
            if (this.tableData.options.actionSettings.hasOwnProperty(button) && this.tableData.options.actionSettings[button].classes) {
                classes = classes.concat(this.tableData.options.actionSettings[button].classes)
            }
            return classes
        },
        getButtonTitle(button, byPass = false) {
            if (!byPass && !this.buttonIsPresent(button)) return ''
            if (this.tableData.options.actionSettings.hasOwnProperty(button) && this.tableData.options.actionSettings[button].title) {
                return this.tableData.options.actionSettings[button].title
            }
            else {
                switch (button) {
                    case 'edit':
                        return ''
                    case 'icon':
                        return 'The changes in the equipment usage will be saved after the save button clicked'
                    default:
                        return button.capitalize()
                }
            }
        },
        getButtonLabel(button, byPass = false) {
            if (!byPass && !this.buttonIsPresent(button)) {
                return ''
            }
            if (this.tableData.options.actionSettings.hasOwnProperty(button) && this.tableData.options.actionSettings[button].label) {
                return this.tableData.options.actionSettings[button].label
            }
            else {
                switch (button) {
                    case 'edit':
                        return '  Open'
                    case 'preview':
                        return ''
                    case 'clone':
                        return ''
                    case 'cloneProd':
                        return ''
                    case 'delete':
                        return ''
                    case 'icon':
                        return ''
                    case 'template':
                        return ''
                    case 'apply':
                        return ''
                }
            }
        },
        getButtonIcon(button, byPass = false){
            if (!byPass && !this.buttonIsPresent(button)) {
                return ''
            }
            //Vr not sure we need this
            //if (this.tableData.options.actionSettings.hasOwnProperty(button) && this.tableData.options.actionSettings[button].label) {
                //return this.tableData.options.actionSettings[button].label
            //}
            //else {
                switch (button) {
                    case 'edit':
                        return 'pencil-alt'
                    case 'preview':
                        return 'search'
                    case 'clone':
                        return 'copy'
                    case 'cloneProd':
                        return 'copy'
                    case 'delete':
                        return 'trash'
                    case 'contact':
                        return 'envelope'
                    case 'calendar':
                        return 'calendar'
                    case 'icon':
                        return 'exclamation-triangle'
                    case 'template':
                        return 'file-import'
                    case 'apply':
                        return 'file-export'
                }
            //}
        },
        /**
         * Check if the given button should be visible or not for the given row.
         * @param string button The button name, ex "delete".
         * @param object row
         * @return boolean
         */
        buttonIsVisible(button, row) {
            // you can not delete events from booking
            //if (button === 'delete' && row && row.type && row.type.value === 'Event') return false
            if (row.rowOptions && row.rowOptions.hideActions) {
                return row.rowOptions.hideActions.indexOf(button) === -1
            }
            return true
        },
        /**
         * User permissions to access sidebar delete/clone buttons
         * @param type
         * @param button
         * @returns {boolean}
         * WN
         */
        userHasAccess(type, button) {
            // incase of avoid the access/permission issues, if no type passed thru switch it to true. WN
            if (!type) return true
            //This is case sensitive so, we have make first char uppercase
            const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1) + 's'
            }

            type = type === 'production' || type === 'feed' ? capitalize(type) : type

            //ability aliases => create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    if(type === 'productionTemplate') {
                        _access = this.$ability.can('delete_production_templates', 'Productions')
                    } else if(type === 'feedTemplate') {
                        _access = this.$ability.can('delete_feed_templates', 'Feeds')
                    }
                    else {
                        _access = this.$ability.can('delete', type)
                    }
                    break
                case 'clone':
                    _access = this.$ability.can('create', type)
                    break
                case 'cloneProd':
                    _access = this.$ability.can('create', type)
                    break
                case 'template':
                    _access = (type === 'Productions' && this.$ability.can('create_production_templates', type)) ||
                              (type === 'Feeds' && this.$ability.can('create_feed_templates', type))
                    break
                case 'apply':
                    _access = (type === 'Productions' && this.$ability.can('view_production_templates', type) && this.$ability.can('update', type)) ||
                              (type === 'Feeds' && this.$ability.can('view_feed_templates', type) && this.$ability.can('update', type))
                    break
            }
            return _access
        },

        /**
         * Save a copy of the list first time an item is moved.
         */
        onStart({relatedContext, draggedContext}) {
            // if (this.formData.oldListOrder.length == 0) {
            //     this.formData.oldListOrder = this.entity.get.items
            // }
        },

        /**
         * Add background color to moved items..
         */
        onEnd(context) {
            // context.item.classList.add('bg-warning')
        },

        /**
         * openActions for Sidebar opening option, to prevent rendering
         * Performance boost
         * @param object item
         */
        openActions(button, row){
            if (button === 'preview') { this.sidebar = true }
            if (button === 'delete') { this.sidebar = false }
            if (button !== 'icon') { this.$emit(button, row); }
        },
        /**
         * Sending Ingestor Entity to BookIngestion component to adjust traffic buttons
         * by their entities.
         */
        ingestionValues(houseNumber) {
          const userCountry = app.$data.user.get.country
          const data = this.ingestors
          if (!data) return

          if (houseNumber) {
            if (isJsonSring(houseNumber)) {
              houseNumber = JSON.parse(houseNumber)
              houseNumber = houseNumber[userCountry] ?? null
            }
          }

          houseNumber = houseNumber ? houseNumber.toLowerCase().trim() : houseNumber

          let value
          // integrations: NULL, Blank, Empty, Integer or specific labels
          Lazy(data.get.items).each(v => {
            if (!v.active) return
            const oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
            const label = v.label ? v.label.toLowerCase() : null
            const shortLabel = v.shortlabel ? v.shortlabel.toLowerCase() : null
            const userField2 = v.userfield2_value ? v.userfield2_value.toLowerCase() : null
            // select the right country and make sure its not default
            if (v.country === userCountry && !v.default) {
              switch(true) {
                case (houseNumber === null && ['null', 'blank', 'empty'].includes(label)):
                case (houseNumber === '' && ['null', 'blank', 'empty'].includes(label)):
                case (houseNumber && label === houseNumber):
                case (shortLabel === houseNumber || userField2 === houseNumber):
                case(oneLetterOrOnlyIntegers.test(houseNumber) && label === 'int'):
                  value = v.userfield1_value
              }
            }
            // if default selected, then don't compare with countries, make it able for all countries
            else if (v.default) {
              switch(true) {
                case (houseNumber === null && ['null', 'blank', 'empty'].includes(label)):
                case (houseNumber === '' && ['null', 'blank', 'empty'].includes(label)):
                case (houseNumber && label === houseNumber):
                case (shortLabel === houseNumber || userField2 === houseNumber):
                case(houseNumber && oneLetterOrOnlyIntegers.test(houseNumber) && label === 'int'):
                  value = v.userfield1_value
              }
            }
          })
          if (value) return value
        },
        /**
         * Get a new class instance, based the child component.
         * @param mixed opts (optional) The parameters that the new class instance should
         *                   be created with.
         * @return class
         */
        getNewClass(itemType, opts = null) {
            switch (itemType) {
                case 'feed':
                    return new Feed(opts)
                case 'production':
                    return new Production(opts)
            }
        },
        /**
         * Applies the houseNumber to the event
         */
        applyHouseNumber(itemId, itemType, country, newHouseNumber) {
            const context = this
            const itemClass = context.getNewClass(itemType,itemId)

            let oldHouseNumber = itemClass.get.houseNumber
            

            let houseNumber

            if (itemType === 'production') {
                let pLead = itemClass.get.productionLeader && itemClass.get.productionLeader.id ? itemClass.get.productionLeader.id : itemClass.get.productionLeader
                itemClass.set('productionLeader', pLead)
            }

            if (typeof newHouseNumber === 'object') {
                Lazy(newHouseNumber).each((v,k) => {
                    newHouseNumber[k] = (v === 'null' || v === 'blank' || v === 'empty') ? '' : v
                })
            }

            let newHouseNumbers = Object.assign({}, newHouseNumber)
            for(const h in newHouseNumbers) {
                newHouseNumbers[h] = newHouseNumbers[h] !== null && newHouseNumbers[h] !== '' ? this.isForFork(newHouseNumbers[h], h) : false
            }

            // prepare for FORK emit
            let placeholderItem = {
                itemType: itemType,
                itemId: itemId,
                houseNumber: newHouseNumbers
            }

            houseNumber = JSON.stringify(newHouseNumber)
            houseNumber = (newHouseNumber === 'null' || newHouseNumber === 'blank' || newHouseNumber === 'empty') ? '' : houseNumber

            itemClass.set('houseNumber', houseNumber)
            itemClass.set('house_number_changing', 'yes')

            // now save the event
            itemClass.saveIn()
                .then(response => {
                    const now = moment().unix()
                    Item.parse(response.data, itemType, now)
                    itemClass.add(response.data)
                    //context.$alert.set(`HouseNumber successfully added/saved.`, 'success', 5)
                    //close form
                    context.formData.showAddItemForm = false
                    app.$emit('updateRecPlaceholder', placeholderItem)
                })
                .catch(error => {
                    this.$error.set(error)
                })
        },
        /**
         * checks ingest entity and returns if this housenumber value marked
         * for export to FORK
         * @param {*} value //housenumber value
         * @param {*} country //housenumber country
         */
        isForFork(value, country) {
          // one letter only and rest integer is requested from FORK integration team ( should be applied to 'int' )
          const determineIngestOperators = (value) => {
            if (!value) return 'blank'
            let oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
            if (oneLetterOrOnlyIntegers.test(value.trim())) return 'int'
            return value;
          }

          const findIngestEntity = (val, country) => {
            let ingestEntityItems = this.ingestors && this.ingestors.get && this.ingestors.get.items.length > 0 ? this.ingestors.get.items : null
            return ingestEntityItems ? _.find(ingestEntityItems, i => i.country === country && (i.label === val || i.shortlabel === val || i.userfield2_value === val)) : null
          }

          let val = determineIngestOperators(value)
          let ing = findIngestEntity(val, country)
          return ing ? ing.description === 'yes' : false
        },
        /**
         * houseNumberInputDisabled
         * disables the houseNumber input box within user's country
         */
        houseNumberInputDisabled(country) {
            if (!country) return false
            if (Array.isArray(country)) return country.indexOf(app.$data.user.get.country) === -1
            if (country){
                var array = country.split(", ");
                if (Array.isArray(array)) { return array.indexOf(app.$data.user.get.country) === -1 }
            }
            return country !== app.$data.user.get.country
        },
        /**
         * Shows houseNumbers in the Book-Modal for the
         * selected event.
         * WN
         * */
        showHouseNumbers(row) {
          const context = this
          let _hn = row.houseNumber.rawValue
          let _countries = context.$store.state.system.countries ? context.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
          let _data = {}

          //reset first
          context.formData = this.defaultData()

          if (_hn) {
            if (isJsonSring(_hn)) _data = JSON.parse(_hn)
            else Lazy(_countries).each(v => { _data[v] = '' })
          }
          else {
            Lazy(_countries).each(v => { _data[v] = '' })
          }

          // open form
          context.formData.items = row
          context.formData.newItem = _data
          context.formData.showAddItemForm = true

        },
        /**
         * House Number ingestions per country for selection item
         */
        getHouseNumberPerCountry(country) {
            let data = this.ingestors.get.items
            let res = []
            Lazy(data)
                .each(function(v,k) {
                    if (v.country === country) res.push(v)
                })
            return res
        },
        /**
         * Return default form data.
         */
        defaultData() {
            return {
                countries: [],
                newItem: {},
                items: null,
                userCountry: app.$data.user.get.country,
                showAddItemForm: false,
                draggedItems: 0,
                selected: []
            }
        },

    }
}
</script>
<template>
<div class="book-table table-responsive" ref="bookTable">
    <div id="book-table-filters" class="book-table-filters" v-if="loaded && (hasSearch || hasFilters) && !print">
        <div class="form-group book-table-search" v-if="hasSearch">
            <input class="form-control" type="text" placeholder="Search" v-model.trim="searchTerm" >
        </div>
        <div class="form-group" v-for="(item, key) in tableData.options.filters" v-if="hasFilters">
            <label class="book-table-filter-label">{{item.label}}</label>
            <select class="book-table-filter-field form-control" :name="'filter_' + key" v-model="tableData.options.filters[key].selected" @change="filterBy(key)">
                <option value="">- No filter -</option>
                <option value="_empty_">- Empty -</option>
                <option v-for="item in tableData.options.filters[key].items" :value="item.id">{{item.label}}</option>
            </select>
        </div>
        <div class="form-group book-table-button-clear-wrapper" v-if="hasFilters && (searchTerm != '' || Object.keys(filters).length > 0)">
            <button class="btn btn-danger" @click="clearFilters()">
                <font-awesome-icon icon="times"/>

            </button>
        </div>
    </div>
    <table class="table" :class="{'table-striped': striped, 'table-hover': !print && $route.name !== 'mcr'}" v-if="loaded && hasRows">
        <thead>
            <tr>
                <th v-if="draggable" class="text-center"><font-awesome-icon icon="sort"/> </th>
                <th v-if="hasCheckBox">
                </th>
                <th v-if="hasAlternativeCheckBox">
                </th>
                <th v-for="column in tableData.columns" :key="column.label + '_' + Math.floor(Math.random() * 100)" :class="{'book-sortable': column.sortable}" :style="column.style" v-if="column.visible" @click="sortBy(column)">
                    <span class="book-column-label">{{column.label}}</span>
                    <span class="book-column-sort" v-if="column.sortable && !print">
                        &nbsp;
                        <i
                            class="fas"
                            aria-hidden="true"
                            :class="{'fa-sort': sort.column != column.value, 'fa-sort-up': sort.column == column.value && !sort.reverse, 'fa-sort-down': sort.column == column.value && sort.reverse}">
                        </i>
                    </span>
                </th>
                <th v-if="hasIngestions">
                    <span class="book-column-label">Ingest</span>
                </th>
                <th v-if="hasActions">
                </th>
            </tr>
        </thead>
        <component :is="getRowComponent" v-model="rows" element="tbody" @start="onStart" @end="onEnd">
            <tr v-for="(row, index) in rows" :class="[
                {'bg-warning': row.rowOptions.highlighted && !darkMode},
                {'bg-warning-dark': row.rowOptions.highlighted && darkMode},
                {'book-item-fadeout': selected && selected === row.id.value},
                row.rowOptions.classes
                ]"
                :key="index"
            >
                <td v-if="draggable" class="grab text-center"><span class="grab-item"><font-awesome-icon icon="align-justify"/></span></td>
                <td v-if="hasCheckBox">
                    <input type="checkbox" :disabled="tableData.options.checkboxes.disabled" v-model="row.rowOptions.checked" @click="$emit(tableData.options.checkboxes.eventName, row)">
                </td>
                <td v-if="hasAlternativeCheckBox">
                    <input v-if="row.rowOptions.type == 'checkbox'" type="checkbox" v-model="row.rowOptions.checked" :disabled="tableData.options.alternativeCheckboxes.disabled" @click="$emit(tableData.options.alternativeCheckboxes.eventName, row)">
                    <button v-else-if="row.rowOptions.type == 'icon'" class="btn btn-xs btn-warning" :title="row.rowOptions.title">
                        <font-awesome-icon  icon="exclamation-triangle"/>
                    </button>
                </td>
                <td v-for="column in tableData.columns" :key="column.label + '_' + Math.floor(Math.random() * 100)" :class="[row[column.value].classes]" v-if="column.visible">
                    <template v-if="column.marker && changedItems[row.id.value] && changedItems[row.id.value].hasOwnProperty('flags')">
                        <span class="book-marker label" :class="changedItemsClasses[flag]" v-for="flag in changedItems[row.id.value].flags" :title="changedItems[row.id.value].author ? 'by ' + changedItems[row.id.value].author : ''">{{flag}}</span>
                    </template>
                    <template v-else-if="column.icon">
                        <font-awesome-icon v-if="row[column.value].icon != ''" :icon="row[column.value].icon"/>
                    </template>
                    <span v-html="row[column.value].value" :title="row[column.value].title ? row[column.value].title : ''"></span>
                </td>
                <td v-if="hasIngestions" class="book-table-ingestions" style="width: 50px;">
                    <book-ingestion
                        :ingestionTypes="tableData.options.ingestions"
                        :ingestionActions="tableData.options.ingestionActions"
                        :items="row.rowOptions.ingestions"
                        :itemId="row.rowOptions.id"
                        :itemType="row.rowOptions.type"
                        :itemCountry="row.rowOptions.country"
                        :recordRequested="(row.recordRequested != null || row.recordRequested !== undefined) ? row.recordRequested.value : false"
                        :ingestionFlow="ingestionValues(row.houseNumber.rawValue, row.rowOptions.country)"
                        :ingestors="ingestors"
                        :readonly="false"
                    >
                    </book-ingestion>
                </td>
                <td style="width: 50px;">
                    <!-- @input instant changes, but better with keyup.enter -->
                    <div @click="showHouseNumbers(row)" style="width: 150px;" v-if="row.houseNumber">
                        <input type="text" class="housenumber-form"
                               :title="row.houseNumber.value"
                               v-model:trim="row.houseNumber.value"
                               readonly/>
                    </div>
                </td>
                <td v-if="hasActions" class="book-table-actions">
                    <button
                        v-for="button in tableData.options.actions"
                        v-if="buttonIsVisible(button, row) && userHasAccess(itemsType, button)"
                        @click="openActions(button, row)"
                        class="btn btn-xs"
                        :class="getButtonClasses(button)"
                        :title="getButtonTitle(button)"
                        >
                        <font-awesome-icon :icon="getButtonIcon(button)"/>
                        <span>{{getButtonLabel(button)}}</span>
                    </button>
                    <button
                        v-for="button in row.rowOptions.actions"
                        v-if="row.rowOptions && row.rowOptions.actions && buttonIsVisible(button, row) && userHasAccess(itemsType, button)"
                        @click="$emit(button, row)"
                        class="btn btn-xs"
                        :class="getButtonClasses(button, true)"
                        :title="getButtonTitle(button, true)"
                        v-html="getButtonLabel(button, true)">
                    </button>
                </td>
            </tr>
    </component>
        <book-modal
                @keydown.enter="$emit('access')"
                @keydown.esc="$emit('close')"
                maxWidth="400px"
                @access="applyHouseNumber(formData.items.rowOptions.id, formData.items.rowOptions.type, formData.userCountry, formData.newItem)"
                @close="formData.showAddItemForm = false"
                v-if="formData.showAddItemForm"
                tabindex="10">
            <h4 slot="header" class="modal-title">Add or save <strong>House Number</strong></h4>
            <div slot="body">

                <div class="form-horizontal">

                    <div class="form-group" v-for="(hnum, index) in formData.newItem">
                        <label :for="index" class="control-label book-width-100">{{index.toUpperCase()}}</label>
                        <div class="form-control-100">
                            <book-selectbox
                                    componentId="housenumber-selection"
                                    :items="getHouseNumberPerCountry(index)"
                                    :selected="formData.newItem[index]"
                                    @selectedItem="item => formData.newItem[index] = item"
                                    :tabindex="11">
                            </book-selectbox>
                        </div>
                    </div>
                </div>

            </div>
            <div slot="footer">
                <button class="btn btn-success" @click="applyHouseNumber(formData.items.rowOptions.id, formData.items.rowOptions.type, formData.userCountry, formData.newItem)">Save</button>
            </div>
        </book-modal>
    </table>
    <div class="book-table-no-results" v-if="!hasRows">
        <span v-html="notFoundMessage"></span>
    </div>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.book-table {
    margin-bottom: 0 !important;
    position: relative;
    width: 100%;
    .table {
        margin-bottom: 0 !important;
    }
    th {
        white-space: nowrap;
        &.book-sortable {
            cursor: pointer;
            user-select: none;
        }
    }
    tr.book-item-fadeout {
        background-color: #2991b1 !important;
        color: white;
        opacity: 1;
    }
    tr {
    }
    td {
        vertical-align: middle !important;
        padding: 4px 8px;
        line-height: 1.42857;
        vertical-align: top;
        border-top: 1px solid #ddd;
        > span {
            vertical-align: middle;
        }
        .label {
            vertical-align: calc(1px);
        }
    }
    .label {
        user-select: none;
    }
    .book-marker {
        margin-right: 8px;
        position: relative;
    }
    .book-table-actions {
        text-align: right;
        white-space: nowrap;
        width: 150px;
        button {
            margin-right: 2px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .housenumber-form {
        display: block;
        font-size: 14px;
        color: #555555;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-left: 5px;
        padding-left: 5px;
        width: 90%;
    }
    .book-table-search {
        @include breakpoint(min-width $min-tablet) {
            float: left;
        }
    }
    .book-table-button-filter {
        float: right;
        margin: 10px 10px 0 0;
        @include breakpoint(min-width $min-tablet) {
            display: none;
        }
    }
    .book-table-filters {
        border-bottom: 1px solid #DDDDDD;
        @include breakpoint(min-width $min-tablet) {
            text-align: right;
        }
        > * {
            margin: 10px 10px 10px 8px !important;
            vertical-align: middle;
            @include breakpoint(min-width $min-tablet) {
                display: inline-block;
            }
        }
    }
    .book-table-filter-label,
    .book-table-filter-field {
        display: inline-block;
    }
    .book-table-filter-label {
        margin-right: 5px;
    }
    .book-table-filter-field {
        width: auto !important;
    }
    .book-table-button-clear-wrapper {
        margin-left: 0 !important;
        text-align: right;
    }
    .book-table-no-results {
        align-self: center;
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 10px;
        text-align: center;
    }
    .book-tr {
        display: table-row !important;
        vertical-align: inherit;
        border-color: inherit;
        border-collapse: separate;
        border-spacing: 2px;
    }
    .label-exceeded {
        background-color: $color-list-item-conflict-warning;
    }
    tr.strikeout td{
        position: relative;
    }
    tr.strikeout td:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 1px solid #111;
        width: 100%;
    }
}
.book-table {
  > .dark-layout:hover {
    background: #0D2936 !important;
  }
}
</style>
