import {app} from 'app'
import Entity from 'components/entity/Entity'
import System from 'components/system/System'
import Datetime from 'components/common/helpers/Datetime'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import BookLoader from 'components/common/loader/BookLoader'
import BookTable from 'components/common/BookTable'
import itemsMixin from 'components/common/item/ItemsMixin'

import productionTypes, {getPermissionSubjectFromProductionType} from '../productionTypes';

export default {
    mixins: [
        itemsMixin,
    ],
    components: {
        BookLoader,
        BookTable,
    },
    data() {
        return {
            loaded: false,
            production: null,

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'productionTemplate',
                items: 'productionTemplates',
                settings: 'productions',
                entities: {
                    sportEntity: 'sport_entity_id',
                    leagueEntity: 'league_entity_id',
                }
            },

            productionTypes: productionTypes,
        }
    },
    props: {
        type: {
            default: 'production',
            type: String
        },
        routeItem: {
            default: 'productionTemplate',
            type: String,
        },
        routeItems: {
            default: 'productionTemplates',
            type: String,
        }
    },

    computed: {
        getPermissionSubject() {
            return getPermissionSubjectFromProductionType(this.type)
        },
        typeLabel() {
            return this.productionTypes[this.type].label
        },
        canDelete() {
            return app.$ability.can('delete_production_templates', this.getPermissionSubject)
        },
        canDeleteGlobal() {
            return app.$ability.can('delete_production_global_templates', this.getPermissionSubject)
        },
        isGlobal() {
            return this.productionTemplate && this.productionTemplate.get && this.productionTemplate.get.global
        },
        productionTemplates() {
            if (!this.loaded || !this.$store.state.data.productionTemplate) return []
            return Lazy(this.$store.state.data.productionTemplate)
                .map(template => {
                    Lazy(template.data)
                    .each((v, k) => {
                        Vue.set(template, k, v)
                    })
                    Vue.delete(template, 'data')
                    return template
                })
                .toArray()
        },
        productionTemplateListData() {
            const context = this
            const output = {}
            if (!context.loaded) return output
            output.options = {
                actions: ['edit', 'delete'],
            }
            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                    value: 'title',
                    label: 'Title',
                    sortable: true,
                },
                {
                    value: 'sport',
                    label: 'Sport',
                    sortable: true,
                },
                {
                    value: 'league',
                    label: 'League',
                    sortable: true,
                },
                {
                    value: 'global',
                    label: 'Global',
                    sortable: true,
                },
                {
                    value: 'owner',
                    label: 'Owner',
                    sortable: true,
                },
            ]
            output.rows = []
            context.productionTemplates.forEach(v => {
                let league = context.leagueEntity.getItem(v.league)
                let global = !!v.global
                let sport = null, passiveLeague
                if (league) {
                    sport = context.sportEntity.getItem(league.reference)
                    passiveLeague = `<span class="label label-danger">Passive</span> ${league.label}`;
                }
                let owner = v.user
                let ownTemplate = owner.id === app.$data.user.get.id
                let title = v.title
                if (v.locked) {
                    title = title + '&nbsp;<span class="label label-danger" title="This template is being used in some setting and is therefore locked for deletion.">locked</span>'
                }
                const row = {
                    id: {
                        value: v.id
                    },
                    title: {
                        value: title
                    },
                    sport: {
                        rawValue: sport ? sport.id : '',
                        value: sport ? sport.label : ''
                    },
                    league: {
                        rawValue: league ? league.id : '',
                        value: league ? (league.active ? league.label : passiveLeague) : ''
                    },
                    global: {
                        rawValue: global ? global : '',
                        value: global ? '<span class="label label-info">Yes</span>' : ''
                    },
                    owner: {
                        rawValue: owner ? owner.id : '',
                        value: owner ? owner.name : ''
                    },
                    rowOptions: {
                        hideActions: !this.canDeleteGlobal || ownTemplate && !this.canDelete || v.locked ? ['delete'] : []
                    }
                }
                output.rows.push(row)
            })
            return output
        },
        getRequestParams() {
            const output = {
                type: this.type,
            }
            return output
        },

    },

    
}
