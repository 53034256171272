<script>
import {app} from 'app'
import Item from 'components/common/item/Item'
import BookSidebar from 'components/common/BookSidebar'
import EntityMixin from 'components/entity/EntityMixin'
import ProductionMixin from 'components/production/ProductionMixin'
import Production from 'components/production/Production'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import Equipment from 'components/equipment/Equipment'
import BookAudioLayout from 'components/common/BookAudioLayout'
import BookAssociations from 'components/association/BookAssociations'
import BookNotification from 'components/notification/BookNotification'

export default {
  mixins: [
    EntityMixin,
    ProductionMixin,
  ],
  components: {
    BookSidebar,
    BookEquipmentSelection,
    BookAudioLayout,
    BookAssociations,
    BookNotification
  },
  props: {
    selectedItem: {
      default: null
    },
    actions: {
      default: () => {
        return []
      },
      type: Array
    },
    entities: {
      default: () => ({})
    },
    showEquipmentSelection: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    sidebarType: {
      default: 'production'
    },
    allEquipment: {
      default: () => [],
      type:Array
    },
    darkMode: {
      default: false
    },
    showAssociations: {
      default: false,
      type: Boolean
    }
  },
  data() {
      return {
          notification: {
              show: false,
              modalTitle: '',
              recipients: [],
          },
      }
  },
  mounted() {
  },
  computed: {
    countries() {
      return this.$store.state.system.countries ? this.$store.state.system.countries : null
    },
    getSelectedItemEquipment() {
      if (!this.selectedItem) { return [] }
      // for to show all function needs to be .isVisible(v, true)
      return Lazy(this.selectedItem.equipment)
          .filter(v => Equipment.isVisible(v, true))
          .toArray()
    },
    getActionSettings() {
      return {
        edit: {
          label: ''
        }
      }
    },
    getHouseNumbers() {
      if (!this.selectedItem) return
      let _hn = this.selectedItem.houseNumber
      let _c = this.selectedItem.channelDetails ? this.selectedItem.channelDetails.country : app.$data.user.get.country
      if (_hn) {
        if (isJsonSring(_hn)) {
          let orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
          let _gamze = JSON.parse(_hn)
          let res = Object.keys(_gamze)
              .map(a => ({[a]: _gamze[a]}))
              .sort((a, b) => (orderArr.indexOf(Object.keys(a)[0]) + 1) - (orderArr.indexOf(Object.keys(b)[0]) + 1));
          let test = {}
          Lazy(res)
              .each((v,k) => {
                Lazy(v).each((v,k) => {
                  test[k] = v
                })
              })
          //_hn = JSON.stringify(test)
          return test
        } else {
          let _res = {}
          _res[_c] = _hn
          return _res
        }
      }
    },
  },
  beforeMount() {
    Lazy(this.entities)
        .each((v, k) => {
          this[k] = v
        })
  },
  methods: {
    isObjectEmpty(obj) {
      if (typeof obj === 'string') {
        try { obj = JSON.parse(obj) } catch (e) { return true }
      }
      return Object.keys(obj).every(function(key){
        return obj[key] === null || obj[key] === ''
      })
    },
    getMcrLabel(id) {
      let output = this.countries.find(v => v.code == id)
      return output.label
    },
    getEquipmentPeriod(equipment) {
      if (!equipment.equipmentUsage) return ''
      let start = moment(equipment.equipmentUsage.periodStart).format('HH:mm')
      let end = moment(equipment.equipmentUsage.periodEnd).format('HH:mm')
      return `${start}-${end}`
    },

    /**
     * Update the equipment list in the current item.
     * @param object|array items A single item or a list of items.
     * @param boolean notify Show an alert message about the addition.
     */
    updateEquipment(items, notify = true) {
      const production = new Production(this.selectedItem.id)
      production.set('equipment', items)
      this.selectedItem.equipment = items
      if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
    },

    /**
     * Retrive the label for the passed plan item.
     * @param object planItem
     * @return string
     */
    getPlanItemLabel(planItem) {
      return planItem.typeCustom ? planItem.typeCustom : this.getEntityItemLabel('planType', planItem.type)
    },
    closeSidebar() {
      this.$emit('close');
    },

    /**
     * Open BookNotification modal.
     */
    showNotificationModal(crewMember) {
      this.notification.recipients = []

      this.notification.recipients.push({
          status: crewMember.status,
          email: crewMember.user.email,
          phone: crewMember.user.phone,
          id: crewMember.user.id,
          label: crewMember.user.name,
      })
      this.notification.modalTitle = `Contact ${crewMember.user.name}`

      this.notification.show = true
    },

  }
}
</script>

<template>
  <book-sidebar
      :darkMode="darkMode"
      :item="selectedItem"
      :loading="loading"
      :actions="actions"
      :isModalOpen="notification.show"
      :sidebarType="sidebarType"
      @edit="item => openNewTab(getEditLink(selectedItem.id, selectedItem.elementTypeDetailed))"
      @close="closeSidebar()"
      @delete="item => $emit('delete', selectedItem.id)"
      @clone="item => $emit('clone', selectedItem)"
      @template="item => $emit('template', selectedItem)"
      @apply="item => $emit('apply', selectedItem)">
    <div slot="header" v-if="!loading && selectedItem" class="book-sidebar-header-title-inner">
      <span class="book-sidebar-header-title-text">{{selectedItem.id}}, {{selectedItem.title}}</span>
    </div>
    <div slot="content" :class="{'dark-layout': darkMode}" v-if="!loading && selectedItem">
      <span class="book-item-status book-item-bkg" :class="'book-item-status-' + selectedItem.status"></span>

      <div class="book-sidebar-group">
        <div class="book-sidebar-row">
          <div class="book-sidebar-label book-width-100">Title:</div>
          <div class="book-sidebar-value">{{selectedItem.title}}</div>
        </div>
        <div class="book-sidebar-row">
          <div class="book-sidebar-label book-width-100">League:</div>
          <div class="book-sidebar-value">{{getEntityItemLabel('league', selectedItem.league)}}</div>
        </div>
        <div class="book-sidebar-row" v-if="selectedItem.productionLeader">
          <div class="book-sidebar-label book-width-100">Leader:</div>
          <div class="book-sidebar-value"><router-link :to="{name: 'user.id', params: {id: selectedItem.productionLeader.id}}">{{selectedItem.productionLeader.fullName}}</router-link></div>
        </div>
        <div class="book-sidebar-row">
          <div class="book-sidebar-label book-width-100">Run Type:</div>
          <div class="book-sidebar-value">{{getEntityItemLabel('runType', selectedItem.runType)}}</div>
        </div>
        <div class="book-sidebar-row" v-if="selectedItem.locationId">
          <div class="book-sidebar-label book-width-100">Location:</div>
          <div class="book-sidebar-value">{{getEntityItemLabel('location', selectedItem.locationId)}}</div>
        </div>
        <div class="book-sidebar-row">
          <div class="book-sidebar-label book-width-100">Start:</div>
          <div class="book-sidebar-value">{{selectedItem.productionStart}}</div>
        </div>
        <div class="book-sidebar-row">
          <div class="book-sidebar-label book-width-100">End:</div>
          <div class="book-sidebar-value">{{selectedItem.productionEnd}}</div>
        </div>
      </div>
      <!--- House Numbers -->
      <div class="book-sidebar-group" v-if="getHouseNumbers">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>House Numbers</div>
            <div class="book-width-50">Value</div>
          </div>
          <template v-for="(hnum, index) in getHouseNumbers">
            <div class="book-sidebar-table-row" v-if="hnum">
              <div class="book-sidebar-table-row-nowrap">{{ countries.find(i => i.code === index).label }} ({{index}}):</div>
              <div>{{hnum}}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="book-sidebar-group" v-if="selectedItem.audioLayout && selectedItem.audioLayout !== '' && !isObjectEmpty(selectedItem.audioLayout)">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Audio</div>
            <div class="book-width-50">Value</div>
          </div>
          <book-audio-layout :value="selectedItem.audioLayout" :audioLayoutsEntity="audioLayoutsEntity" itemType="production"></book-audio-layout>
        </div>
      </div>

      <div class="book-sidebar-group"  v-if="selectedItem.plan.length > 0">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Plan</div>
            <div class="book-width-50">Start</div>
          </div>
          <template v-for="planItem in selectedItem.plan">
            <div class="book-sidebar-table-row">
              <div class="book-sidebar-table-row-nowrap">{{getPlanItemLabel(planItem)}}</div>
              <div class="book-width-50">{{getPlanItemTime(planItem)}}</div>
              <div class="book-sidebar-table-row-extra" v-if="planItem.description">{{planItem.description}}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="book-sidebar-group" v-if="selectedItem.crew.length > 0">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Crew</div>
            <div class="book-width-50">Period</div>
          </div>
          <template v-for="(crewMembers, status) in getSelectedItemCrew">
            <div class="book-sidebar-table-row" v-for="crewMember in crewMembers">
              <div :class="getCrewMemberClass(crewMember)" v-if="crewMember.user">
                <span v-if="crewMember.conflict" title="This crew member is conflicting with another crew member" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
                <span :title="crewMember.status" class="book-square"></span>
                <a :href="`/user/${crewMember.user.id}`">{{crewMember.user.name}}</a>
              </div>
              <div class="book-sidebar-crew-actions" v-if="crewMember.user && crewMember.user.id !== null">
                <button
                  class="btn btn-info btn-xs"
                  title="Contact"
                  @click="showNotificationModal(crewMember)"
                  v-if="$ability.can('send_notifications', 'Miscellaneous')">
                  <font-awesome-icon icon="envelope"/>
                </button>
              </div>
              <div>{{getCrewPeriod(crewMember)}}</div>
            </div>
          </template>
        </div>
      </div>

      <!-- Equipment-->
      <div class="book-sidebar-group" v-if="!showEquipmentSelection && getSelectedItemEquipment.length > 0">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header">
            <div>Equipment</div>
            <div class="book-sidebar-table-period-column">Period</div>
          </div>
          <div class="book-sidebar-table-row" v-for="equipment in getSelectedItemEquipment">
            <div class="book-sidebar-table-row-nowrap">
              <span v-if="equipment.equipmentUsage.conflict && !equipment.doNotCreateConflict" title="This equipment piece is conflicting with another booking" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
              <span v-if="equipment.equipmentUsage.exceedsGroupLimit && equipment.useGroupConflictCount" title="The equipment group limit exceeded" class="label label-exceeded book-sidebar-equipment-conflict">exceeded</span>
              {{getEntityItemLabel('equipmentType', equipment.type)}}, {{equipment.label}}
            </div>
            <div>{{getEquipmentPeriod(equipment)}}</div>
          </div>
        </div>
      </div>
      <div class="book-sidebar-group" v-else-if="showEquipmentSelection">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Equipment</div>
          </div>
        </div>
        <book-equipment-selection
            :darkMode="darkMode"
            :selection="getSelectedItemEquipment"
            :dates="{from: selectedItem.productionStart, to: selectedItem.productionEnd}"
            :usageId="selectedItem.id"
            usageType="production"
            :columns="['type', 'equipment', 'actions']"
            :actionSettings="getActionSettings"
            :entityList="entities"
            :allEquipmentProps="allEquipment"
            @changedEquipment="(item, notify) => updateEquipment(item, notify)">
        </book-equipment-selection>
      </div>

      <!-- Associations -->
      <div class="book-sidebar-group" v-if="showAssociations && selectedItem.associations && selectedItem.associations.length > 0">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header">
            <div>Assocications</div>

          </div>
          <div class="book-sidebar-table-row">
            <book-associations
                :items="selectedItem.associations"
                :parent="selectedItem"
                type="production"
                showInSidebar>
            </book-associations>
          </div>
        </div>
      </div>

      <book-notification
        v-if="notification.show"
        componentId="bookSidebarProduction"
        :modalTitle="notification.modalTitle"
        :recipients="notification.recipients"
        @close="notification.show = false">
      </book-notification>

    </div>

  </book-sidebar>
</template>

<style lang="scss">
@import "../../style/_variables.scss";
.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}
.book-width-60-percents {
  width: 60%;
}

.book-sidebar-crew-actions {
  margin-right: 8px;
}
</style>