const state = {
    preserve: null,
    preserveSort: null,
    darkMode: null,
    columnOptions: null,
    preserveEquipmentList: null,
    myFiltersSelected: '',
}

const getters = {}
const actions = {}
const mutations = {
    setPreserve(state, { currentFilters, availableFilters }) {
        const availableFiltersSet = new Set(availableFilters)

        const newFilters = Object.assign({}, currentFilters);

        if (state.preserve) {
            for (const [k, v] of Object.entries(state.preserve)) {
                if (!availableFiltersSet.has(k)) {
                    newFilters[k] = v
                }
            }
        }

        state.preserve = newFilters
    },
    replacePreserve(state, data) {
      state.preserve = Object.assign({}, data)
    },
    removePreserve(state) {
      state.preserve = null
    },
    setPreserveSorting(state, data) {
        state.preserveSort = data
    },
    removePreserveSorting(state) {
        state.preserveSort = null
    },
    setDarkMode(state, data) {
      state.darkMode = data
    },
    removeDarkMode(state) {
      state.darkMode = null
    },
    setColumnOptions(state, data) {
      state.columnOptions = data
    },
    removeColumnOptions(state) {
      state.columnOptions = null
    },
    setPreserveEquipmentList(state, data) {
        state.preserveEquipmentList = data
    },
    removePreserveEquipmentList(state, data) {
        state.preserveEquipmentList = null
    },
    setMyFiltersSelected(state, myFilterId) {
        state.myFiltersSelected = myFilterId
    }
}
const filter = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export {filter}
