<script>
/**
 * @file
 * Book Selectbox component.
 * Builds a "dropdown" like selectbox with search functionality.
 */

import bookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        bookLoader
    },
    props: {
        componentId: {
            default: 'field',
            type: String
        },
        loading: {
            default: false,
            type: Boolean
        },
        label: {
            default: 'Menu',
            type: String
        },
        direction: {
            default: 'down',
            type: String
        },
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        selected: {
            default: '',
        },
        disabled: {
            default: false,
            type: Boolean
        },
        filterVisibilityBy: {
            default: () => {
                return {}
            },
            type: Object
        },
        tabindex: {
            default: 1,
            type: Number
        },
        selectedItems: null,
        darkMode: {
          default: false
        },
    },
    watch: {

        items: {
            handler: function(newValue) {
                console.log('processing items of list')
                this.processItems()
            },
            deep: true
        }

    },
    data() {
        return {
            searchTerm: '',
            selecting: '',
            showItems: false,
            currentItems: null,
        }
    },
    directives: {
        /**
         * @NEW Directive clickoutside
         * Needed for the new filters, when we click outside, it should close the lists
         * it was a little problematic had to use as a new directive
         * WN
         */
        clickoutside: {
            bind: function(el, binding, vnode) {
                el.clickOutsideEvent = function(event) {
                    // click outside? el and childrens?
                    if (!(el == event.target || el.contains(event.target))) {
                        // did so? then call event.
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
                document.body.addEventListener("touchstart", el.clickOutsideEvent);
            },
            unbind: function(el) {
                document.body.removeEventListener("click", el.clickOutsideEvent);
                document.body.removeEventListener("touchstart", el.clickOutsideEvent);
            },
            stopProp(event) {
                event.stopPropagation();
            }
        }
    },
    computed: {
        getItems() {
            return this.currentItems
        },
        hasFilterItems() {
            return this.getItems && this.getItems.length > 0
        },
        hasSelectedItems() {
            return this.getItems.filter(v => v.selected).length
        },
        listItems() {
            return this.items.filter(item => {
                let visible = true

                // If no searchTerm is provided, check if the list should be
                // filtered based on filterVisibilityBy property.
                if (this.searchTerm == '') {
                    if ( (Object.keys(this.filterVisibilityBy).length > 0) && (this.selected != item.id) ) {
                        // Should access the list when you click the icon. WN
                        //visible = false
                        Lazy(this.filterVisibilityBy)
                            .filter((v, k) => item.extraFields && item.extraFields.hasOwnProperty(k))
                            .each((v, k) => {
                                // Should access the list when you click the icon.
                                //if (visible) return
                                if (item.extraFields[k].indexOf(v) > -1) visible = true
                            })
                    }
                    return visible
                }

                visible = false
                Lazy(item)
                    .each(v => {
                        if (visible) return
                        // for lower-case case sensitive operation WN
                        if (typeof v == 'string') v = v.toLowerCase()
                        // also searchTerm should be lower case
                        if ((typeof v == 'string') && (v.indexOf(this.searchTerm.toLowerCase()) > -1)) visible = true
                    })
                return visible
            })
        },
    },
    mounted() {
        this.processItems()
    },
    methods: {
        /**
         * Process the passed items.
         */
        processItems() {
            this.currentItems = JSON.parse(JSON.stringify(this.items))
            this.currentItems.forEach(v => {
                if (!v.selected) {
                    Vue.set(v, 'selected', false)
                }
            })
        },
        openClose() {
            this.showItems = !this.showItems
            if (this.showItems) {
                Vue.nextTick(() => {
                    this.$el.scrollIntoView({ inline: 'center' })
                })
            }
            if (!this.showItems) this.reset()
        },
        /**
         * Emit event on item selection by arrow-keys
         */
        selectItem(item) {
            this.selecting = item
        },
        /**
         * Emit event on item click.
         */
        clickOnItem(item) {
            // if selected true else false
            item.selected = !item.selected
            this.emitValue()
        },
        /**
         * Emit the current items/filters set to the parent component.
         */
        emitValue() {
            Vue.nextTick(() => {
                this.$emit('selectedItems', this.getItems.slice(0))
            })
        },
        moveNext(direction){

            var selectedIndex = this.listItems.indexOf(this.selecting);

            if (direction === -1){
                selectedIndex--;
                if (selectedIndex < 0) selectedIndex = 0;
            }else{
                selectedIndex++;
                if (selectedIndex === this.listItems.length) selectedIndex = this.listItems.length - 1;
            }

            // FixScroll when, you arrow down, it should also scroll down
            let scrolling = document.getElementById('book-selectbox-' + this.componentId).getElementsByTagName('ul');
            scrolling = scrolling[scrolling.length -1]
            if (direction !== -1) {
                if (selectedIndex > 3) scrolling.scrollBy(0, 31)
            } else scrolling.scrollBy(0, -31)

            this.selectItem(this.listItems[selectedIndex]);
        },
        arrowUp(){
            this.moveNext(-1);
        },
        arrowDown(){
            this.moveNext(1);
        },
        escKey() {
            this.showItems = false
            this.reset()
        },
        reset() {
            this.selecting = null
            this.searchTerm = ''
            this.$emit('selecting', '')
        },
        /**
         * DE-select all items.
         */
        clear() {
            if (!this.hasSelectedItems) return
            this.getItems.forEach(v => {
                Vue.set(v, 'selected', false)
            })
            Vue.nextTick(() => {
                this.emitValue()
            })
        },
        /**
         * Select all items
         */
        selectAll() {
            this.getItems.forEach(v => {
                Vue.set(v, 'selected', true)
            })
            Vue.nextTick(() => {
                this.emitValue()
            })
        },
    }
}
</script>

<template>
<div :id="'book-selectbox-' + componentId" class="book-selectbox" :class="{'book-selectbox-open': showItems}" v-clickoutside="escKey">
    <div class="book-selectbox-selected" :class="{'dark-layout-filters': this.darkMode}">
        <div class="form-control-static book-selectbox-selected-texts" @click="openClose()" :class="{'book-selectbox-selected-text-disabled': disabled}">
            <span v-if="loading" class="book-selectbox-loading"><span class="book-selectbox-label">loading...</span></span>
            <span v-else>{{label}}</span><span v-if="hasFilterItems && hasSelectedItems > 0" class="badge">{{hasSelectedItems}}</span>
            <span class="caret" v-if="hasFilterItems"></span>
        </div>
        <button class="btn btn-primary btn-sm book-selectbox-selected-button bttn-smaller" @click="selectAll()" title="Select all items">
            <font-awesome-icon icon="check"/>
        </button>
        <button class="btn btn-info btn-sm book-selectbox-selected-button bttn-smaller come-left" @click="clear()" title="DE-Select all items">
            <font-awesome-icon icon="eraser"/>
        </button>
    </div>
    <transition name="slide-down-fade" mode="in-out">
        <div class="book-selectbox-items-wrapper"
             :class="{'dark-input': darkMode}"
             v-if="showItems" :key="componentId" @keyup.enter="clickOnItem(selecting)" @keyup.esc="escKey" @keyup.arrow-up="arrowUp" @keyup.arrow-down="arrowDown">
            <div class="book-selectbox-arrow-up"></div>
            <input class="form-control book-selectbox-search" type="text" placeholder="Type to search" v-focus v-model="searchTerm" :disabled="disabled" :tabindex="tabindex"/>
            <ul v-if="listItems.length > 0 && !loading" :class="'book-selectbox-items ' + componentId">
                <li v-for="item in listItems" :key="item.id + '_' + Math.floor(Math.random() * 100)" :title="item.label" :class="
                ['book-selectbox-item', 'book-selectbox-item-' + item.id, {'book-selectbox-item-selected': item.selected}, {'book-selectbox-item-warningz': selecting && (item.id == selecting.id)},
                {'book-selectbox-item-passive': (label === 'Leagues' && !item.active) }, {'dark-port': darkMode }
                ,]" @click="clickOnItem(item)">
                    <font-awesome-icon icon="check" class="book-selectbox-item-icon" aria-hidden="true" v-if="item.selected"/>
                  <span class="book-selectbox-item-text" v-if="label === 'Channels' || label === 'Run Type'"><span class="book-selectbox-channel-item-text">({{item.country}})</span> {{item.label}}</span>
                    <span class="book-selectbox-item-text" v-else>{{item.label}}</span>
                </li>
            </ul>
            <div v-else class="book-selectbox-nothing-found">
                <book-loader v-if="loading" :small="false"></book-loader>
                <span v-else>Nothing found</span>
            </div>
        </div>
    </transition>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

// We apply this class to basically force Safari to re-render layout when item is closed.
.book-selectbox-open {
    min-width: 0.1vw;
}

.has-error {
    .book-selectbox .book-selectbox-selected .book-selectbox-selected-text {
        border-color: $color-red;
    }
    .form-control.book-selectbox-search {
        border-color: $color-form-control !important;
    }
}

.book-selectbox {
    .bttn-smaller {
      padding: 0px 3px;
    }
    overflow: hidden;
    position: relative;
    .book-selectbox-arrow-up {
        border-bottom: 8px solid $color-form-control;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        height: 0;
        position: absolute;
        right: 9px;
        top: -7px;
        width: 0;
    }
    .book-selectbox-selected,
    .book-selectbox-items-wrapper {
        position: relative;
    }
    .book-selectbox-selected {
        background: $color-white;
        display: flex;
        z-index: 2;
        .book-selectbox-selected-texts {
            border: 1px solid $color-form-control;
            border-right-width: 0;
            border-radius: 3px 0 0 3px;
            float: none !important;
            flex: 1;
            padding:6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.book-selectbox-selected-text-disabled {
                background-color: $color-grey-light;
                cursor: not-allowed;
            }
        }
        .book-selectbox-clean-button {
            background: none;
            border: none;
            color: $color-grey;
            margin: 2px 2px 2px -30px;
            outline: none;
            &:not([disabled]):hover {
                color: $color-text;
            }
        }
        .book-selectbox-selected-button {
            border-radius: 0 3px 3px 0;
            .book-loader {
                display: inline-block;
                vertical-align: calc(-2px);
                .cssload-container {
                    height: 12px;
                    .cssload-zenith {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
        .come-left {
            margin-left: -1px !important;
        }
    }
    .book-selectbox-selected-text {
        cursor: pointer;
    }
    .book-selectbox-items-wrapper {
        margin-top: 10px;
        z-index: 1;
        .cssload-container {
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
    .book-selectbox-search {
        border-radius: 3px 3px 0 0 !important;
        &:focus {
            border-color: $color-form-control;
        }
    }
    .book-selectbox-items,
    .book-selectbox-nothing-found {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        height: 130px;
        margin-top: -1px;
    }
    .book-selectbox-nothing-found {
        font-size: .9em;
        margin-bottom: 10px;
        padding: 20px 10px 10px;
        text-align: center;
    }
    .book-selectbox-items {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        list-style: none;
        overflow-y: auto;
        padding: 0;
    }
    .dark-port {
      &:nth-child(even) {
        background: #333333 !important;
      }
    }
    .book-selectbox-item {
        border-left: 5px solid transparent;
        cursor: pointer;
        margin: 2px;
        padding: 5px 35px 5px 10px;
        position: relative;
        &:nth-child(even) {
            background: $color-grey-light-transparent;
        }
        &:first-child {
            margin-top: 2px;
        }
        &:last-child {
            margin-bottom: 2px;
        }
        &:hover:not(.book-selectbox-item-blocked) {
            background: $color-blue-primary !important;
            border-left-color: $color-blue-primary !important;
            * {
                color: $color-white !important;
            }
        }
      &:hover:not(.book-selectbox-item-passive) {
        background: $color-blue-primary !important;
        border-left-color: $color-blue-primary !important;
        * {
          color: $color-white !important;
        }
      }
        &.book-selectbox-item-selected {
            border-left-color: $color-blue-primary;
            background: rgba($color-blue-primary, .1);
            // color: $color-white;
            .book-selectbox-item-icon {
                color: $color-blue-primary;
            }
        }
        &.book-selectbox-item-blocked {
            border-left-color: $color-red !important;
            background: rgba($color-red, .1) !important;
            cursor: not-allowed;
            .book-selectbox-item-icon {
                color: $color-red;
            }
        }
        &.book-selectbox-item-warningz {
            background: $color-blue-primary !important;
            border-left-color: $color-blue-info !important;
            * {
                color: $color-white !important;
            }
        }
      &.book-selectbox-item-passive {
        border-left-color: $color-red !important;
        background: rgba($color-red, .1) !important;
        .book-selectbox-item-icon {
          color: $color-red;
        }
      }
        > * {
            display: block;
        }
        .book-selectbox-item-icon {
            font-size: 18px;
            height: 20px;
            line-height: 20px;
            margin-top: -10px;
            position: absolute;
            right: 7px;
            text-align: center;
            top: 50%;
            width: 20px;
        }
    }
  .book-selectbox-item-text {

  }
    .book-selectbox-channel-item-text {
      font-weight: bold;
    }
    .book-selectbox-item-extra {
        color: $color-grey;
        font-size: .9em;
    }
}
</style>
