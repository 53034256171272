import router from 'router'
import store from 'store'

router.beforeEach((to, from, next) => {
    if (to.name === 'mcr') {
        store.commit('alert/setAllowedTypes', ['danger', 'error'])
    } else if (from.name === 'mcr') {
        store.commit('alert/setAllowedTypes', null)
    }

    next()
})