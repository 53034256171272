<script>

import {app} from 'app'

export default {
    computed: {
        id() {
            return parseInt(this.$root.$route.params.id)
        },
        backButtonIsVisible() {
            return this.$root.$route.query.destination == 'admin.users'
        },
        editButtonIsVisible() {
            return app.$data.user.get.id === this.id || this.$ability.can('update', 'Users')
        },
    }
}

</script>

<template>
<div class="secondary-menu clearfix btn-group" role="group">
    <router-link exact :to="{name: 'admin.users'}" v-if="backButtonIsVisible" class="btn btn-default">
        <font-awesome-icon icon="chevron-left"/>                
        <span class="item-label">&nbsp;&nbsp;Back to users</span>
    </router-link>
    <router-link
        exact
        :to="{name: 'user.id', params: {id: id}}"
        :class="{'active': $routerTools.isActive('user.id')}"
        v-if="editButtonIsVisible"
        role="presentation"
        class="btn btn-default">
        <font-awesome-icon icon="user"/>        
        <span class="item-label">&nbsp;&nbsp;Profile</span></router-link>
    <router-link
        exact
        :to="{name: 'user.id.edit', params: {id: id}}"
        :class="{'active': $routerTools.isActive('user.id.edit')}"
        v-if="editButtonIsVisible"
        role="presentation"
        class="btn btn-default">
        <font-awesome-icon icon="pencil-alt"/>            
        <span class="item-label">&nbsp;&nbsp;Edit</span>
    </router-link>
</div>
</template>
