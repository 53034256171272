<script>
import Auth from 'components/auth/Auth'
import store from 'store'
import File from 'components/common/helpers/File'

// Vendors.
import dropzone from 'vue2-dropzone'

export default {
    components: {
        dropzone
    },
    props: {
        disabled: {
            default: false,
            type: Boolean
        },
        disabledMessage: String,
        files: {
            default: () => [],
            type: Array
        },
        fileUsageId: Number,
        apiEndpoint: String,
        darkMode: {
          default: false
        },
        isMcr: {
          default: false
        }
    },
    data() {
        return {
            queuedFiles: 0,
            progress: 0,
            loaded: false,
        }
    },
    computed: {
        dropzone() {
            return this.$refs.dropzoneComp.dropzone
        }
    },
    mounted() {
        const context = this
        // Add passed files into dropzone and set them as "uploaded".
        if (context.files) {
            context.files.forEach(file => {
                context.dropzone.emit('addedfile', file)
                context.dropzone.emit('success', file)
              console.log("DropZonned FILE " . file) ;



                // @TODO Fix thumbnail for images...
                // const imageUrl = context.getApiUrl()
                // context.dropzone.emit('thumbnail', file, '/image/url')

                context.bindEvents(file)

            })
        }
      if (this.isMcr) {
        Vue.nextTick(function () {
          jQuery(context.$refs.dropzoneComp.$el).find('.dz-message').remove()
        })
      }
      if (!this.isMcr) {
        // Change the text inside the droparea. Unfortunately we need to change
        // the DOM with jQuery to do that.
        Vue.nextTick(function () {
          jQuery(context.$refs.dropzoneComp.$el).find('.dz-message').html('<span>Click or drop files here to queue them for upload</span>')
        })
      }

    },
    methods: {
        getHeaders() {
            return {
                Authorization: store.state.auth.token.token_type + ' ' + store.state.auth.token.access_token
            }
        },
        getApiUrl(full = true) {
            //const endpoint = '/' + this.apiEndpoint + '/' + this.fileUsageId + '/' + 'file'
            const endpoint = this.apiEndpoint + '/' + this.fileUsageId + '/' + 'file'
            if (full) {
                return store.state.config.API_URL + '/' + endpoint
            }
            console.log(endpoint)
            return endpoint
        },
        bindEvents(file) {
            const context = this

            // Bind logic to download button.
            jQuery(file.previewElement).find('.dz-download-item').on('click', function(e) {
                context.downloadFile(file)
                return false
            })

            // Bind logic to delete button.
            jQuery(file.previewElement).find('.dz-delete-item').on('click', function(e) {
                context.deleteFile(file)
                return false
            })

        },
        upload() {
            this.$refs.dropzoneComp.setOption('headers', this.getHeaders())
            this.$refs.dropzoneComp.setOption('url', this.getApiUrl())
            this.$refs.dropzoneComp.processQueue()
        },
        uploadFileAdded(file) {
            this.queuedFiles = this.dropzone.files.length
            this.$emit('addedFile', file)
        },
        downloadFile(file) {
            const context = this
            axios.get('api/download/' + file.id, {responseType: 'blob'})
                .then(response => {
                    File.download(response.data, file.name, response.headers['content-type']);
                })
        },
        deleteFile(file) {
            const context = this
            axios.delete(context.getApiUrl(false) + '/' + file.id)
            .then(response => {
                context.dropzone.emit('removedfile', file)
            })
        },
        clearQueue() {
            this.dropzone.files
                .filter(file => file.status != 'success')
                .forEach(file => {
                    this.dropzone.removeFile(file)
                })
            this.progress = 0
            this.queuedFiles = 0
        },
        uploadSuccess(file, response) {
            if (response) {
                const context = this
                response.previewElement = file.previewElement
                context.$emit('success', response)

                // Bind logic to action buttons, on the newly created element.
                setTimeout(function() {
                    context.bindEvents(response)
                }, 0)


                // Trigger the upload function once more, in case there is more
                // queued files.
                // By default, DropzoneJS limits the number of parallel uploads
                // to 2 files. The problem is that the library doesn't fire any
                // event when the 2 files are uploaded but there is still queued
                // files. We could change parallelUploads configuration to a
                // high number, but it can result in troubles when trying to
                // upload "too many" files at the same time. Therefore we have
                // set parallelUploads to "1" and we trigger instead the upload
                // each time a file has been successfully uploaded.
                this.upload()

            }
        },
        uploadRemovedFile(file, error, xhr) {
            this.queuedFiles = this.dropzone.files.length

            // Do not emit the event if the file hasn't been uploaded.
            if (file.id) {
                this.$emit('removedFile', file)
            }
        },
        uploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
            this.progress = totaluploadprogress
        },
        uploadQueueComplete(file, xhr, formData) {
            const context = this
            Vue.nextTick(function() {
                context.progress = 0
                context.queuedFiles = 0
                context.$emit('queueComplete')
            })
        },
        uploadMounted() {
            this.loaded = true
        },
        error(file, message, xhr) {
            this.$emit('error', file, message, xhr)
        },
        getPreviewTemplate() {
            let output = ''
            output += '<div class="dz-preview dz-file-preview">'
          if (!this.isMcr) output += '<div class="dz-image">'
          if (!this.isMcr) output += '<img data-dz-thumbnail />'
          if (!this.isMcr) output += '<span class="dz-file far fa-file-alt" aria-hidden="true"></span>'
          if (!this.isMcr) output += '</div>'
          if (this.darkMode) output += '<div class="dz-details darkMode">'
          else output += '<div class="dz-details">'
            output += '<div class="dz-size"><span data-dz-size></span></div>'
            output += '<div class="dz-filename"><span data-dz-name></span></div>'
            output += '</div>'
            // output += '<div class="dz-success-mark"><span class="fa fa-check-circle" aria-hidden="true"></span></div>'
            // output += '<div class="dz-error-mark"><span class="fa fa-exclamation-circle" aria-hidden="true"></span></div>'
            // output += '<div class="dz-error-message"><span data-dz-errormessage></span></div>'
            if (!this.isMcr) output += '<button class="dz-remove-item btn btn-danger btn-xs" data-dz-remove><span class="fa fa-trash" aria-hidden="true"></span>Delete</button>'
            if (!this.disabled && this.isMcr) output += '<button class="dz-download-item btn btn-primary btn-xs"><span class="fa fa-download" aria-hidden="true"></span>Download</button>'
            if (!this.isMcr) output += '<button class="dz-download-item btn btn-primary btn-xs"><span class="fa fa-download" aria-hidden="true"></span>Download</button>'
            if (!this.isMcr) output += '<button class="dz-delete-item btn btn-danger btn-xs"><span class="fa fa-trash" aria-hidden="true"></span>Delete</button>'
            if (!this.isMcr) output += '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>'
            output += '</div>'
            return output
        }
    }
}
</script>

<template>
<div class="book-upload clearfix">
    <div class="book-upload-actions" v-if="loaded && !isMcr">
        <button class="btn btn-warning" @click="upload()" :disabled="queuedFiles == 0">Upload files</button>
        <button class="btn btn-default" @click="clearQueue()" :disabled="queuedFiles == 0">Remove all</button>
    </div>
    <dropzone
        id="book-upload"
        ref="dropzoneComp"
        @vdropzone-file-added="uploadFileAdded"
        @vdropzone-success="uploadSuccess"
        @vdropzone-removed-file="uploadRemovedFile"
        @vdropzone-total-upload-progress="uploadProgress"
        @vdropzone-queue-complete="uploadQueueComplete"
        @vdropzone-mounted="uploadMounted"
        @vdropzone-error="error"
        :useCustomDropzoneOptions="true"
        :max-number-of-files='50'
        :maxFileSizeInMB='5'
        :dropzoneOptions="{autoProcessQueue: false, parallelUploads: 1}"
        :previewTemplate="function() { return getPreviewTemplate() }"
        :headers="getHeaders()"
        :url="getApiUrl()"
        :useFontAwesome="true">
    </dropzone>
    <div class="book-box-overlay" v-if="disabled && !isMcr">
        <span class="label label-danger" v-html="disabledMessage"></span>
    </div>
    <span v-if="isMcr && disabled" class="label label-danger" style="margin-left:70px;" v-html="disabledMessage"></span>
</div>
</template>

<style lang="scss">
.book-upload {
    position: relative;
    .progress {
        height: 33px;
    }
    .book-upload-actions {
        float: right;
        min-width: 100px;
        padding: 6px 6px 0 10px;
        width: 30%;
        .btn {
            display: block;
            margin-bottom: 10px;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
body {
    .dropzone {
        min-height: 0;
        padding: 4px;
    }
    .vue-dropzone {
        border: 2px dashed rgba(#FFFFFF, 0) !important;
        border-radius: 4px;
        background: none;

    }
    .vue-dropzone.dz-drag-hover {
        border-color: #D58512 !important;
    }
    .dropzone .dz-message {
        align-items: center;
        border-radius: 4px;
        display: flex;
        border: 2px dashed #DDDDDD;
        justify-content: center;
        margin: 0 0 10px;
        max-width: 70%;
        min-height: 76px;
        padding: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .dropzone.dz-started .dz-message {
        display: flex;
    }
    .dropzone .dz-preview {
        align-items: center;
        background-color: #FCF8E3 !important;
        border-radius: 4px;
        border: 2px solid #D58512;
        display: flex;
        margin: 10px 0 0;
        min-height: 40px;
        overflow: hidden;
        padding: 10px;
    }
    .dropzone .dz-preview .dz-remove {
        display: none;
    }
    .dropzone .dz-preview .btn + .btn {
        margin-left: .2em;
    }
    .dropzone .dz-preview .dz-download-item,
    .dropzone .dz-preview .dz-remove-item,
    .dropzone .dz-preview .dz-delete-item {
        cursor: pointer;
        .fa {
          cursor: pointer;
        }
    }
    .dropzone .dz-preview .dz-remove-item {
        cursor: pointer;
        margin-left: 8px;
    }
    .dropzone .dz-preview .dz-download-item,
    .dropzone .dz-preview .dz-delete-item,
    .dropzone .dz-preview.dz-success .dz-remove-item {
        display: none;
    }
    .dropzone .dz-preview.dz-success .dz-download-item,
    .dropzone .dz-preview.dz-success .dz-delete-item {
        display: block;
    }
    .dropzone .dz-preview.dz-success {
        background-color: #F9F9F9;
        border-color: #EEEEEE;
    }
    .dropzone .dz-preview.dz-success .dz-progress {
        display: none;
    }
    .dropzone .dz-preview .dz-details {
        color: #333333;
    }
    .dropzone .dz-preview .dz-image {
        text-align: center;
    }
    .dropzone .dz-preview .dz-image .dz-file {
        color: #777777;
        display: none;
        font-size: 40px;
        line-height: 50px;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image {
        background: #FFFFFF;
        border-radius: 0;
        // height: 40px !important;
        // width: 40px !important;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image img {
        display: none;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image .dz-file {
        display: block;
    }
    .dropzone .dz-preview .dz-image {
        background-color: #FFFFFF;
        height: 50px;
        margin: -7px 10px -7px -7px;
        width: 50px;
    }
    .dropzone .dz-preview:hover .dz-image img {
        filter: none;
        //transform: none;
    }
    .dropzone .dz-preview .dz-progress {
        background: #FCF8E3;
        border-radius: 2px;
        bottom: 2px;
        height: 5px;
        left: 55px;
        margin: 0;
        position: absolute;
        right: 2px;
        top: auto;
        width: auto;
    }
    .vue-dropzone .dz-preview .dz-progress .dz-upload {
        background: #D58512;
    }
    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
        animation: none !important;
    }
    .dropzone .dz-preview .dz-success-mark,
    .dropzone .dz-preview .dz-error-mark {
        display: none;
        font-size: 28px;
        left: auto;
        margin: 0 0 0 16px !important;
        opacity: 1 !important;
        position: relative;
        text-align: center;
        top: auto;
        width: 40px !important;
    }
    .dropzone .dz-preview .dz-success-mark {
        color: #449D44;
    }
    .dropzone .dz-preview.dz-complete .dz-progress {
        display: none;
    }
    .dropzone .dz-preview.dz-success .dz-success-mark,
    .dropzone .dz-preview.dz-error .dz-error-mark {
        animation: none;
        display: block;
    }
    .vue-dropzone .dz-preview .dz-details {
        background: none;
        flex: 1;
        min-width: 0;
        opacity: 1;
        padding: 0;
        position: relative;
    }
    .dropzone .dz-preview .dz-details .dz-filename {
        font-size: 14px;
        overflow: hidden;
    }
    .dropzone .dz-preview .dz-details .dz-size {
        float: right;
        font-size: 14px;
        margin-bottom: 0;
    }
}
</style>
