import Api from 'components/app/Api'

class Feed extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/feed',
            module: 'feed',
        })
    }

}

export default Feed
