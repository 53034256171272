
class Notification {

    /**
     * Send a notification according to the passed parameters.
     * @param object data
     * @return promise
     */
    static send(data) {
        const formData = new FormData()

        buildFormData(formData, data)

        return new Promise((resolve, reject) => {
            axios.post('api/notification', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default Notification
