<script>
import {app} from 'app'
import BookSidebar from 'components/common/BookSidebar'
import EntityMixin from 'components/entity/EntityMixin'
import Feed from 'components/feed/Feed'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookFiberEquipment from 'components/equipment/EquipmentSelection/BookFiberEquipment'
import Equipment from 'components/equipment/Equipment'
import BookFeedAudioLayout from 'components/feed/BookFeedAudioLayout'
import _ from "lodash";

export default {
  mixins: [
    EntityMixin,
  ],
  components: {
    BookSidebar,
    BookEquipmentSelection,
    BookFiberEquipment,
    BookFeedAudioLayout
  },
  props: {
    selectedItem: {
      default: null
    },
    actions: {
      default: () => {
        return []
      },
      type: Array
    },
    entities: {
      default: () => ({})
    },
    showEquipmentSelection: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    sidebarType: {
      default: 'feed'
    },
    allEquipment: {
      default: () => {
        return []
      },
      type: Array
    },
    darkMode: {
      default: false,
    },
  },
  computed: {
    countries() {
      return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
    },
    getSelectedItemEquipment() {
      if (!this.selectedItem) return []
      //console.log(this.selectedItem);
      // show all function -> isVisible(v, true)
      let eqArr = Lazy(this.selectedItem.equipment)
          .filter(v => Equipment.isVisible(v, true))
          .toArray()

      return eqArr
    },
    getSelectedItemEquipmentFiberOnly() {
      if (!this.selectedItem) return []
      //console.log(this.selectedItem);
      // show all function -> isVisible(v, true)
      return this.getSelectedItemEquipment.filter(v => v.type === this.equipmentFeedFiberType.get.id);//Lazy(this.selectedItem.equipment)
      //.filter(v => v.type === this.equipmentFeedFiberType.get.id && Equipment.isVisible(v, true))
      //.toArray()
    },
    getSelectedItemEquipmentWithoutFiber() {
      if (!this.selectedItem) return []
      //console.log(this.selectedItem);
      // show all function -> isVisible(v, true)
      return this.getSelectedItemEquipment.filter(v => !v.hasOwnProperty('type') || v.type !== this.equipmentFeedFiberType.get.id);//Lazy(this.selectedItem.equipment)
      //.filter(v => v.type === this.equipmentFeedFiberType.get.id && Equipment.isVisible(v, true))
      //.toArray()
    },
    getSelectedItemEquipmentForDeliveryType(){
      if (!this.selectedItem) return []

      if(this.isDeliveryType('fiber')) {
        return this.getSelectedItemEquipmentWithoutFiber
      }
      else {
        return this.getSelectedItemEquipment
      }
    },
    getActionSettings() {
      return {
        edit: {
          label: 'Open'
        }
      }
    },
    getHouseNumbers() {
      //if (!this.selectedItem) return
      let _hn = this.selectedItem.houseNumber
      if (_hn) {
        if (isJsonSring(_hn)) {
          let orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
          let _gamze = JSON.parse(_hn)
          let res = Object.keys(_gamze)
              .map(a => ({[a]: _gamze[a]}))
              .sort((a, b) => (orderArr.indexOf(Object.keys(a)[0]) + 1) - (orderArr.indexOf(Object.keys(b)[0]) + 1));
          let test = {}
          Lazy(res)
              .each((v,k) => {
                Lazy(v).each((v,k) => {
                  test[k] = v
                })
              })
          //_hn = JSON.stringify(test)
          return test
        } else return _hn
      }
    },
  },
  beforeMount() {
    Lazy(this.entities)
        .each((v, k) => {
          this[k] = v
        })
  },
  methods: {
    getMcrLabel(id) {
      let output = this.countries.find(v => v.code === id)
      return output.label
    },
    getEquipmentPeriod(equipment) {
      if (!equipment.equipmentUsage) return ''
      let start = moment(equipment.equipmentUsage.periodStart).format('HH:mm')
      let end = moment(equipment.equipmentUsage.periodEnd).format('HH:mm')
      return `${start}-${end}`
    },
    isDeliveryType(name) {
      return this.getEntityItemLabel('deliveryType', this.selectedItem.deliveryType).toLowerCase() === name
    },
    isDeliveryTypeData() {
      const context = this
      const item = context.selectedItem.deliveryTypeData
      const label = context.getEntityItemLabel('deliveryType', context.selectedItem.deliveryType).toLowerCase();
      let data = false


      if (label.indexOf('fiber') > -1) {
        return true
      }
      if (label.indexOf('download') > -1) {
        return !!item.downloadInfo || item.downloadInfo && item.downloadInfo !== ''
      }
      if (label.indexOf('streaming') > -1) {
        return !!item.streamingInfo || item.streamingInfo && item.streamingInfo !== ''
      }

      if (label.indexOf('sat') > -1) {
        Lazy(item).each((v,k) => {
          if (Array.isArray(v)) { Lazy(v).each((v1,k1) => { if (typeof k1 !== 'number') { if (k1 === 'value' && v1 && v1 !== '') data = true } if (v1 && v1.value && v1.value !== '') data = true }) }  else { if (k !== 'feedId' && k !== 'deliveryType') { if (v && v !== '') data = true } }
        })
        //console.log(item, '????', label, data)
        return data;
      }

    },
    getSatelliteLabel(id) {
      return this.getEntityItemLabel('satellite', id)
    },

    /**
     * Update the equipment list in the current item.
     * @param object|array items A single item or a list of items.
     * @param boolean notify Show an alert message about the addition.
     */
    updateEquipment(items, notify = true) {
      let allEq = this.isDeliveryType('fiber') && this.getSelectedItemEquipmentFiberOnly.length > 0 ? items.concat(this.getSelectedItemEquipmentFiberOnly) : items
      const feed = new Feed(this.selectedItem.id)
      feed.set('equipment', allEq)
      this.selectedItem.equipment = allEq
      if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
    },
    closeSidebar() {
      this.$emit('close')
    },
    getEditLink(id) {
      const link = {
        params: {
          id: id
        },
        query: {
          destination: this.$route.name,
          view: this.$route.params.view,
          from: this.$route.query.from,
          to: this.$route.query.to,
          newBar: id,
        }
      }

      link.name = 'feed.edit'

      return link
    },
    openNewTab(link) {
      const context = this
      if (link.name === 'production.edit' && !context.isEngProduction && !context.isEditProduction) {
        let routeData = context.$router.resolve(link);
        window.open(routeData.href, '_blank');
      }
      if (link.name === 'feed.edit') {
        let routeData = context.$router.resolve(link);
        window.open(routeData.href, '_blank');
      }

      if ((['production.edit', 'feed.edit']).indexOf(link.name) === -1) {
        return context.$router.push(link)
      }
    },

    updateFiberEquipment(items, notify = true) {
      let allEq = this.isDeliveryType('fiber') && this.getSelectedItemEquipmentWithoutFiber.length > 0 ? items.concat(this.getSelectedItemEquipmentWithoutFiber) : items
      const feed = new Feed(this.selectedItem.id)
      feed.set('equipment', allEq)
      this.selectedItem.equipment = allEq
      if (notify) this.$alert.set('The fiber equipment list was updated', 'success', 3)
    },
    getSourceLabel(source) {
      return Equipment.getSourceLabelById(source, this.externalMCRsEntity)
    },
  }
}
</script>

<template>
  <book-sidebar
      :item="selectedItem"
      :loading="loading"
      :actions="actions"
      :sidebarType="sidebarType"
      :darkMode="darkMode"
      @edit="item => openNewTab(getEditLink(selectedItem.id))"
      @close="closeSidebar()"
      @clone="$emit('clone', selectedItem.id)"
      @template="item => $emit('template', selectedItem)"
      @apply="item => $emit('apply', selectedItem)"
      @delete="$emit('delete', selectedItem.id)">
    <div slot="header" v-if="selectedItem" class="book-sidebar-header-title-inner">
      <span class="book-sidebar-header-title-text">{{selectedItem.title}}</span>
    </div>
    <div slot="content" v-if="selectedItem">
      <div class="book-item-status book-item-bkg" :class="'book-item-status-' + selectedItem.status"></div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">ID:</div>
        <div class="book-sidebar-value">{{selectedItem.id}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">Sport:</div>
        <div class="book-sidebar-value">{{getSportLabel(selectedItem.league)}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">League:</div>
        <div class="book-sidebar-value">{{getEntityItemLabel('league', selectedItem.league)}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">Title:</div>
        <div class="book-sidebar-value">{{selectedItem.title}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">MCR's</div>
        <div class="book-sidebar-value w-150" v-if="selectedItem.mcr.length > 0">
          <span class="sidebar-mcr-label" v-for="mcr in selectedItem.mcr">{{getMcrLabel(mcr)}}</span>
        </div>
        <div class="book-sidebar-value" v-else>
          -
        </div>
      </div>
      <div class="book-sidebar-row" v-if="selectedItem.mcrExternal">
        <div class="book-sidebar-label book-width-100">MCR External:</div>
        <div class="book-sidebar-value">{{selectedItem.mcrExternal}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">Delivery Type:</div>
        <div class="book-sidebar-value">{{getEntityItemLabel('deliveryType', selectedItem.deliveryType)}}</div>
      </div>
      <!-- eoH -->
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">Feed start:</div>
        <div class="book-sidebar-value">{{selectedItem.feedStart}}</div>
      </div>
      <div class="book-sidebar-row">
        <div class="book-sidebar-label book-width-100">Feed end:</div>
        <div class="book-sidebar-value">{{selectedItem.feedEnd}}</div>
      </div>
      <!--- House Numbers -->
      <div class="book-sidebar-group" v-if="getHouseNumbers">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>House Numbers</div>
            <div class="book-width-50">Value</div>
          </div>
          <template v-for="(hnum, index) in getHouseNumbers" v-if="hnum">
            <div class="book-sidebar-table-row">
              <div class="book-sidebar-table-row-nowrap">{{ countries.find(i => i.code === index).label }} ({{index}}):</div>
              <div>{{hnum}}</div>
            </div>
          </template>
        </div>
      </div>
      <!-- Delivery type data -->
      <div class="book-sidebar-group" v-if="selectedItem.deliveryType && isDeliveryTypeData()">
        <div class="book-sidebar-group-title" :class="{'dark-layout-header': darkMode}">
          Delivery type: {{getEntityItemLabel('deliveryType', selectedItem.deliveryType)}}
        </div>
        <div class="book-sidebar-table-row" v-if="isDeliveryType('download')">
          {{selectedItem.deliveryTypeData.downloadInfo}}
        </div>
        <template v-if="isDeliveryType('fiber')">

          <div class="book-sidebar-table" v-if="!showEquipmentSelection && getSelectedItemEquipmentFiberOnly.length > 0">
            <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
              <div>Fiber</div>
              <div class="book-sidebar-table-period-column">Country</div>
            </div>
            <div class="book-sidebar-table-row" v-for="equipment in getSelectedItemEquipmentFiberOnly" :key="equipment.id">
              <div class="book-sidebar-table-row-nowrap">
                <span v-if="equipment.equipmentUsage.conflict" title="This fiber piece is conflicting with another booking" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
                <span v-if="equipment.equipmentUsage.exceedsGroupLimit && equipment.useGroupConflictCount" title="The equipment group limit exceeded" class="label label-exceeded book-sidebar-equipment-conflict">exceeded</span>
                {{equipment.label}}
              </div>
              <div class="book-sidebar-table-period-column">{{equipment.notCountryRestricted ? getSourceLabel(equipment.source) : getSourceLabel(equipment.country)}}</div>
            </div>
          </div>
          <div class="book-sidebar-table-row" v-else-if="showEquipmentSelection">
            <book-fiber-equipment
                :darkMode="darkMode"
                :selection="getSelectedItemEquipmentFiberOnly"
                :dates="{from: selectedItem.feedStart, to: selectedItem.feedEnd}"
                :usageId="selectedItem.id"
                usageType="feed"
                :columns="['equipment', 'country', 'actions']"
                :actionSettings="getActionSettings"
                @changedFiber="(item, notify) => updateFiberEquipment(item, notify)"
                :allEquipmentProps="allEquipment.filter(v => v.type === fiberEntity.get.id)"
                :externalMCRsEntityProps="externalMCRsEntity"
                :equipmentTypeEntityProps="equipmentTypeEntity"
                :equipmentStatusEntityProps="equipmentStatusEntity">
            </book-fiber-equipment>
          </div>
        </template>
        <template v-if="isDeliveryType('streaming')">
          <div class="book-sidebar-row">
            <div class="book-sidebar-label book-width-100">Type:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('streamingType', selectedItem.deliveryTypeData.streamingType)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="isDeliveryType('streaming')">
            {{selectedItem.deliveryTypeData.streamingInfo}}
          </div>
        </template>
        <template v-if="isDeliveryType('sat')">
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satSatelliteId">
            <div class="book-sidebar-label book-width-100">Satellite:</div>
            <div class="book-sidebar-value">{{getSatelliteLabel(selectedItem.deliveryTypeData.satSatelliteId)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satFrq">
            <div class="book-sidebar-label book-width-100">Frq:</div>
            <div class="book-sidebar-value">{{selectedItem.deliveryTypeData.satFrq}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satPolarization">
            <div class="book-sidebar-label book-width-100">Polarization:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('polarization', selectedItem.deliveryTypeData.satPolarization)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satModulation">
            <div class="book-sidebar-label book-width-100">Modulation:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('modulation', selectedItem.deliveryTypeData.satModulation)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satSymbolRate">
            <div class="book-sidebar-label book-width-100">Symbol rate:</div>
            <div class="book-sidebar-value">{{selectedItem.deliveryTypeData.satSymbolRate}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satTransponderChannel">
            <div class="book-sidebar-label book-width-100">Transponder channel:</div>
            <div class="book-sidebar-value">{{selectedItem.deliveryTypeData.satTransponderChannel}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satService">
            <div class="book-sidebar-label book-width-100">Service:</div>
            <div class="book-sidebar-value">{{selectedItem.deliveryTypeData.satService}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satFec">
            <div class="book-sidebar-label book-width-100">FEC:</div>
            <div class="book-sidebar-value">{{getEntityItemLabel('fec', selectedItem.deliveryTypeData.satFec)}}</div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satEncryption">
            <div class="book-sidebar-label book-width-100">Encryption:</div>
            <div class="book-sidebar-value">
              <span v-if="selectedItem.deliveryTypeData.satEncryption == 'biss_code'">Biss Code Local</span>
              <span v-else-if="selectedItem.deliveryTypeData.satEncryption == 'biss_code_shared'">Biss Code Shared</span>
              <span v-else-if="selectedItem.deliveryTypeData.satEncryption == 'director'">Director</span>
              <span v-else-if="selectedItem.deliveryTypeData.satEncryption == 'director_xml'">Director XML</span>
            </div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satEncryption == 'biss_code'">
            <div class="book-sidebar-label book-width-100"></div>
            <div class="book-sidebar-value book-sidebar-value-stacked">
              <div class="book-sidebar-value-stacked-item" v-for="item in selectedItem.deliveryTypeData.satEncryptionByCountry">
                <strong>{{item.country.toUpperCase()}}:</strong>&nbsp;
                <span>{{item.value}}</span>
              </div>
            </div>
          </div>
          <div class="book-sidebar-row" v-if="selectedItem.deliveryTypeData.satEncryption == 'biss_code_shared'">
            <div class="book-sidebar-label book-width-100"></div>
            <div class="book-sidebar-value">{{selectedItem.deliveryTypeData.satEncryptionBissCodeShared}}</div>
          </div>
        </template>
      </div>
      <!-- Audio layout -->
      <div class="book-sidebar-group" v-if="selectedItem.audioLayout && selectedItem.audioLayout !== ''">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Audio Layout</div>
            <div class="book-width-50">Value</div>
          </div>
          <book-feed-audio-layout
              :printableTextMode="true"
              :feedAudioLayout="selectedItem.audioLayout"
              :audioLayoutsEntities="audioLayoutsEntity.get.items" ></book-feed-audio-layout>
        </div>

      </div>
      <!-- Equipment -->
      <div class="book-sidebar-group" v-if="!showEquipmentSelection && getSelectedItemEquipmentForDeliveryType.length > 0">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Equipment</div>
            <div class="book-sidebar-table-period-column">Period</div>
          </div>
          <div class="book-sidebar-table-row" v-for="equipment in getSelectedItemEquipmentForDeliveryType">
            <div class="book-sidebar-table-row-nowrap">
              <span v-if="equipment.equipmentUsage.conflict && !equipment.doNotCreateConflict" title="This equipment piece is conflicting with another booking" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
              <span v-if="equipment.equipmentUsage.exceedsGroupLimit && equipment.useGroupConflictCount" title="The equipment group limit exceeded" class="label label-exceeded book-sidebar-equipment-conflict">exceeded</span>
              {{getEntityItemLabel('equipmentType', equipment.type)}}, {{equipment.label}}
            </div>
            <div>{{getEquipmentPeriod(equipment)}}</div>
          </div>
        </div>
      </div>
      <div class="book-sidebar-group" v-else-if="showEquipmentSelection">
        <div class="book-sidebar-table">
          <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
            <div>Equipment</div>
          </div>
        </div>
        <book-equipment-selection
            :darkMode="darkMode"
            :selection="getSelectedItemEquipmentForDeliveryType"
            :dates="{from: selectedItem.feedStart, to: selectedItem.feedEnd}"
            :usageId="selectedItem.id"
            usageType="feed"
            :columns="['type', 'equipment', 'actions']"
            :actionSettings="getActionSettings"
            @changedEquipment="(item, notify) => updateEquipment(item, notify)"
            :entityList="entities"
            :allEquipmentProps="allEquipment"
            :excludeTypesBy="isDeliveryType('fiber') ? [fiberEntity.get.id] : []">
        </book-equipment-selection>
      </div>

    </div>
  </book-sidebar>
</template>

<style lang="scss">
@import "../../style/_variables.scss";
.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}
.book-width-60-percents {
  width: 60%;
}
.w-150 {
  max-width:175px;
  display: inline-block !important;
}
.sidebar-mcr-label {
  display: inline-block;
  padding: 3px;
  margin: 2px 4px 2px 0;
  border-radius: 5px;
  color: #fff;
  background: #337ab7;
  box-shadow: 2px 2px 1px 0 black;
}
</style>