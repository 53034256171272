<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import BookSelectbox from 'components/common/BookSelectbox'
import BookDatetime from 'components/common/BookDatetime'
import Rate from 'components/rate/Rate'
import User from 'components/user/User'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'

import datepicker from 'vue-datepicker/vue-datepicker-es6'

export default {
    components: {
        BookLoader,
        BookSelectbox,
        BookDatetime
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            child: {
                item: 'rate',
                items: 'rates',
                settings: 'rates',
                entities: {
                    skillEntity: 'skill_entity_id',
                    currencyEntity: 'currency_entity_id'
                }
            },
            formData: this.defaultData(),
            loaded: false,
            rate: null,
            activeTab: 'overview',
        }
    },
    watch: {
        /*'formData.skill.value': function(newValue) {
            const context = this
            if(newValue && context.formData.userId.value) {
                let skills = (_.find(context.availableUsers, u => u.id === context.formData.userId.value)).skills
                let currentSkill = _.find(skills, s => s === newValue)
                if(!currentSkill) {
                    context.formData.userId.value = ''
                }
            }
            
        },
        'formData.userId.value': function(newValue) {
            const context = this
            if(newValue && context.formData.skill.value) {
                let skills = (_.find(context.availableUsers, u => u.id === newValue)).skills
                let currentSkill = _.find(skills, s => s === context.formData.skill.value)
                if(!currentSkill) {
                    context.formData.skill.value = ''
                }
            }
            
            
        }*/
    },

    computed: {
        setting() {
            return this.$settings.get.rates
        },
        isEditForm() {
            return this.$route.name == 'rate.edit'
        },
        isFormClean() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        },
        isFormValid() {
            // hate to do that but, need it WN.
            
            if (!this.formData.rateStart.value) return false
            if (!this.formData.skill.value) return false
            if (!this.formData.currency.value) return false
            if (this.formData.userSpecific.value && !this.formData.userId.value) return false
            
            return true/*this.formFields && Object.keys(this.formFields).every(k => {
                console.log('isFormValid: '+ k, this.formFields[k].valid)
                return this.formFields[k].valid
            })*/
        },
        isChanged() {
            const context = this
            let changed = false
            if ((!this.rate || !this.rate.get)) return changed

            Lazy(context.formData)
                .filter((v, k) => {
                    return !v.local
                })
                .each(function(v, k) {
                    if (changed) return true

                    // The form value is an array and its length is not equal to
                    // the length of the original value, then it has been changed.f
                    if (Array.isArray(v.value) && Array.isArray(context.rate.get[k]) && (v.value.length != context.rate.get[k].length)) {
                        changed = true
                    }
                    else {
                        switch (k) {
                            
                            case 'userId':
                            case 'skill':
                            case 'currency':
                                if (!context.isEditForm) {
                                    if (context.formFields[k] && context.formFields[k].touched) {
                                        changed = v.value != context.rate.get[k]
                                    }
                                }
                                else {
                                    if (context.rate.get && !context.rate.get[k] && !v.value) {
                                        changed = false
                                    }
                                    else {
                                        changed = v.value != context.rate.get[k]
                                    }
                                }
                                break
                            default:

                                // If the form value and feed value are "false/null/empty"
                                // then "changed" should be false.
                                if (context.rate.get && !context.rate.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = v.value != context.rate.get[k]
                                }

                                break
                        }

                    }

                })

            return changed
        },
        skills() {
            if(!this.skillEntity || !this.skillEntity.get || !this.skillEntity.get.items) return

            const context = this
            let filteredSkills = null
    
            filteredSkills = context.skillEntity.get.items.filter(f => f.country === app.$data.user.get.country)
            if(context.formData.userId.value && !context.isEditForm) {
                let skills = (_.find(context.availableUsers, u => u.id == context.formData.userId.value)).skills
                filteredSkills = filteredSkills.filter(s => _.includes(skills, s.id))
            }

            return filteredSkills
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        processing() {
            return this.$loader.has('rateEdit_submit')
        },
        availableUsers() {
            return this.$store.state.user.users ? this.$store.state.user.users.filter(u => u.country === app.$data.user.get.country) : null
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            const context = this
            // let skillUsers = context.formData.skill.value && !context.isEditForm ? context.availableUsers.filter(u => {
            //     return _.includes(u.skills, context.formData.skill.value)
            // }) : context.availableUsers
            Lazy(/*skillUsers*/context.availableUsers)
                .filter(u => {
                    return context.isEditForm || _.includes(u.skills, context.formData.skill.value)
                })
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
        currencies() {
            return this.currencyEntity && this.currencyEntity.get && this.currencyEntity.get.items ? this.currencyEntity.get.items : []
        },
    },

    mounted() {

        const context = this

        this.loadForm()
            .then(response => {
                    User.getUsers()
                        .then(response => {
                            //context.setFormValues()
                            context.setLoaded(true)
                        })
                        .catch(err => {
                            context.$error.set(err, `It was not possible to get user list`)
                            context.loaded = true
                        })
            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the entities.')
                context.loaded = true
            })

    },


    methods: {
        getTabRoutes() {
            return this.$route.query.newBar;
        },
        /**
         * Get the destination to be used when redirecting after form save.
         * @param object
         */
        getDestination() {
            const context = this
            const output = {
                name: context.$route.query.destination ? context.$route.query.destination : 'all.rates',
            }
            if (context.$route.query.from) {
                output.query = {
                    from: context.$route.query.from
                }
            }
            if (context.$route.query.to) output.query.to = context.$route.query.to

            if (context.$route.query.date) output.query.date = context.$route.query.date

            
            //output.name = 'task.view'
            //output.params.view = 'overview'
            
            return output
        },
        closeNewTab() {
            window.top.close();
        },
        /**
         * Emit a action button event.
         * @param eventName string
         * @param object row
         */
        emitButtonEvent(eventName) {
            const context = this
            switch (eventName) {
                case 'delete':
                    context.deleteItem(context.rate.options.id)
                    break
            }

            return
        },

        getButtonClasses(button) {
            let classes = []
            switch (button) {
                case 'delete':
                    classes.push('btn-danger')
                    break
            }
            return classes
        },
        getButtonTitle(button, byPass = false) {

        },
        getButtonLabel(button) {
                switch (button) {
                    case 'delete':
                        return ''
                }
        },
        getButtonIcon(button){
            switch (button) {
                case 'delete':
                    return 'trash'
            }
        },
        userHasAccess(button) {
            //create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete_rates', 'Rates')
                    break
                
            }
            return _access
        },
        getFormattedProductionDate(field) {
            // let value = this.production.get[field].split(' ')
            let value = this.formData[field].value
            return {
                date: value
            }
        },
        isActiveTab(name) {
            return this.activeTab == name
        },
        lock(id) {
            if (id == this.id) this.formData.locked.value = true
            else this.formData.locked.value = false
        },
        /**
         * Load the form.
         */
        loadForm() {
            const context = this
            let itemId = context.id
            context.rate = new Rate(itemId)

            return new Promise((resolve, reject) => {
                context.loadEntities(context.child.entities)
                    .then(response => {
                        if (context.isEditForm) {
                            context.rate.load(itemId)
                                .then(response => {
                                    resolve()
                                })
                                .catch(error => {
                                    context.$error.set(error, `It was not possible to load placeholder event ${context.id}.`)

                                    // @TODO Old error handling
                                    // let msg
                                    // if (error.response.data.message) msg = error.response.data.message
                                    // else if (typeof error.response.data == 'string') msg = error.response.data
                                    // else msg = `It was not possible to load ${context.child.item} ${context.id}.`
                                    // this.$alert.set(msg, 'danger', 10)

                                    if (error.response.status === 404) context.redirect(false)

                                    reject()
                                })
                        }
                        else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the form dependencies.')
                        reject()
                    })
            })
            
            
            
        },
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        redirect(open = false, id = null) {
            let location = {}
            if (open && id) {
                
                location.name = 'rate.edit'

                location.params = {id: id}
                location.query = Object.assign({}, this.$route.query)


                // If the page is redirected to "edit route", then we should set
                // the form values once more, after the DOM has been updated once.
                Vue.nextTick(() => {
                    this.setFormValues()
                    this.submitting = false
                })
            }
            else if (this.$route.query.destination) {
                location = this.getDestination()
            }
            else {
                    location.name = 'all.rates'
            }
            return new Promise((resolve, reject) => {
                this.$router.push(location, () => {
                    resolve()
                }, () => {
                    reject()
                })

            })
        },

        /**
         * Set the equipment list to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
            this.setFormValues()
        },

        setFormValues() {
            const context = this
            if (context.isEditForm) {
                Lazy(context.formData)
                .each(function(v, k) {
                    //let value = null;
                    switch (k) {
                        case 'userId':
                            if (context.rate.get.userId) {
                                v.value = context.rate.get.userId
                            }
                            break
                        case 'skill':
                            if (context.rate.get.skill) {
                                v.value = context.rate.get.skill
                            }
                            break
                        case 'locked':
                            v.value = context.$ability.cannot('update_rates', 'Rates')
                            break
                        case 'userSpecific':
                            v.value = context.rate.get.userId ? true : false
                            break
                        // case 'country':
                        //     let _channel = context.formData.runType.value ? _.find(context.calendarActivityTypesEntity.get.items, a => a.id === context.formData.runType.value) : null
                        //     if (_channel) {
                        //         v.value = _channel.country
                        //     }
                        //     break
                        default:
                            v.value = context.rate.get[k]
                            break
                    }
                    // if (value) {
                    //     v.value = value
                    // }
                })
            }
            else {

                const context = this
                let defaultValue = null

                //currency:
                let currentCurrency = _.find(context.currencies, c => c.description === app.$data.user.get.country)
                context.formData.currency.value = currentCurrency ? currentCurrency.id : ''
            }

        },

        /**
         * Submit the form.
         */
        submit(open = false, redirect = true) {
            const context = this
            context.$loader.add('rateEdit_submit')

            Lazy(context.formData)
                .filter((v, k) => !v.local)
                .each(function(v, k) {
                    let value;
                    switch (k) {
                        default:
                            value = v.value
                            break
                    }
                    context.rate.set(k, value)
                })

            if (context.isEditForm) {
                context.rate.save()
                    .then(response => {
                        context.$alert.set(`Rate ${context.id} was saved!`, 'success', 3)
                        Vue.set(context.$data, 'formData', context.defaultData())

                        if (redirect) {
                            context.submitted = true
                            // Close the tab if its clicked to "save & close the tab"
                            if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                            context.redirect(open)
                        }

                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }

                        //context.setFormValues()
                        context.setLoaded()
                        context.$loader.remove('rateEdit_submit')
                                
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the rate.')
                        context.$loader.remove('rateEdit_submit')
                        
                    })
            }
            else {
                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('rateEdit_submit')
                }

                context.rate.store()
                .then(response => {
                    context.submitted = true
                    context.$alert.set(`Rate ${response.data.id} has been created!`, 'success', 3)


                    // close the tab if its clicked to "save & close the tab"
                    //if (!open && getTabRoutes()) { return closeNewTab(); }

                    context.rate = new Rate(response.data.id)

                    if (redirect) {
                        context.redirect(open, response.data.id)
                            .then(response => {
                                if (open) {
                                    context.setLoaded()
                                }
                            })
                            .catch(error => {
                                if (open) {
                                    context.setLoaded()
                                }
                            })
                    }
                    
                    // Reset formData if "Save & Close" button has been clicked.
                    if (!open) Vue.set(context.$data, 'formData', context.defaultData())

                    // Close the tab if its clicked to "save & close the tab"
                    // Idea is using the same identifiers as save and close for redirect and open options,
                    // which makes smart use in tabs as welll
                    // WN
                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                    context.$loader.remove('rateEdit_submit')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to save the rate.')
                    context.$loader.remove('rateEdit_submit')
                    context.setLoaded()
                })
                

            }
        },
        /**
         * Delete placeholder
         */
        deleteItem(id) {
            const context = this
            const itemClass = new Rate(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete the rate?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            context.$alert.set(`The rate was deleted!`)
                            //if we are in the NewTab just, close the tab or, redirect.
                            if (context.$route.query.newBar) {
                                return context.closeNewTab()
                            }
                            else {
                                console.log('redirecting')
                                return context.redirect(true)
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the rate!`)
                        })
                })
        },

        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                
                rateStart: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                rateEnd: {
                    value: '',
                },
                skill: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                userSpecific: {
                    value: false,
                    local: true
                },
                userId: {
                    value: '',
                },
                currency: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                ratePerDay: {
                    value: '',
                    validator: {
                        regex: /^\d+(\.\d{1,2})?$/
                    },
                },
                ratePerHalfDay: {
                    value: '',
                    validator: {
                        regex: /^\d+(\.\d{1,2})?$/
                    },
                },
                ratePerHour: {
                    value: '',
                    validator: {
                        regex: /^\d+(\.\d{1,2})?$/
                    },
                },
                overRatePerHour: {
                    value: '',
                    validator: {
                        regex: /^\d+(\.\d{1,2})?$/
                    },
                },
                fixedRatePerPublicHoliday: {
                    value: '',
                    validator: {
                        regex: /^\d+(\.\d{1,2})?$/
                    },
                },
                actions: {
                    value: ['delete']
                },
            }
        }

    }
}
</script>
<template>
    <div id="production-edit" class="admin book-top-sticky-wrapper col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 container">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title text-center" v-if="!isEditForm">Create rate</h1>
        <h1 class="page-title text-center" v-if="isEditForm">Edit rate {{id}}</h1>
        <div class="secondary-menu btn-group">
            <router-link :to="getDestination()" class="btn btn-default" title="Back to tasks" v-if="!getTabRoutes()"><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></router-link>
            <div class="btn btn-default" title="Back to tasks" @click="closeNewTab()" v-else><font-awesome-icon icon="chevron-left"/><span class="item-label">&nbsp;&nbsp;Back</span></div>
            <!-- <button class="btn btn-default" title="Print this production"><span class="fa fa-print"></span><span class="item-label">&nbsp;&nbsp;Print</span></button>
            <button @click="openInsertForm()" class="btn btn-default" title="Copy data from other production" :disabled="formData.locked.value"><span class="far fa-copy"></span><span class="item-label">&nbsp;&nbsp;Insert data</span></button> -->
        </div>
    </div>
    <book-loader class="panel-body" v-if="!loaded"></book-loader>
    <div class="form-horizontal row" v-else>
       <div class="book-creation-form">
           <ul class="nav nav-tabs book-form-navigation">
                <li role="presentation" :class="{'active': isActiveTab('overview')}"><a @click="activeTab = 'overview'">Overview</a></li>
                <!-- <li role="presentation" :class="{'active': isActiveTab('equipment')}" v-if="isEditForm || isTemplateBased"><a @click="activeTab = 'equipment'">Equipment</a></li> -->
                <!-- <li role="presentation" :class="{'active': isActiveTab('feedback')}" v-if="isEditForm"><a @click="activeTab = 'feedback'">Feedback</a></li> -->
            </ul>
           <div class="panel panel-info"  v-show="isActiveTab('overview')">
                <div class="panel-body">
                    <div class="book-action-buttons">
                        <button
                                
                                v-if="isEditForm && userHasAccess('delete')"
                                @click="emitButtonEvent('delete')"
                                class="btn btn-xs"
                                :class="getButtonClasses('delete')"
                                :title="getButtonTitle('delete')">
                            <font-awesome-icon :icon="getButtonIcon('delete')"/>
                        </button>
                    </div>
                    
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        <div class="form-control-120">
                            <book-datetime
                                component-id="book-rate-start"
                                :required="{date: true, time: false}"
                                :default-value="formData.rateStart.value ? formData.rateStart.value : ''"
                                @newValue="value => formData.rateStart.value = value"
                                :small="true"
                                hideTime
                                :disabled="formData.locked.value"
                                :dateTabindex="4"
                                :btnOpenTabindex="5"
                                :timeTabindex="6">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"></span>
                            End
                        </label>
                        <div class="form-control-120">
                            <book-datetime
                                component-id="book-rate-end"
                                :required="{date: true, time: false}"
                                :default-value="formData.rateEnd.value"
                                @newValue="value => formData.rateEnd.value = value"
                                :small="true"
                                hideTime
                                disabled
                                :dateTabindex="7"
                                :btnOpenTabindex="8"
                                :timeTabindex="9">
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('skill')}">
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Skill
                        </label>
                        <div class="form-control-120">
                            
                            <select class="form-control" name="skill" v-validate="formData.skill.validator" v-model="formData.skill.value" v-if="skillEntity" :disabled="formData.skill.locked || formData.locked.value || isEditForm" tabindex="13">
                                <option value="">None</option>
                                <option v-for="skill in skills" :value="skill.id" v-if="skills" >{{skill.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('skill')}">
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"></span>
                            User specific rate
                        </label>
                        <div class="form-control-120">
                            <input type="checkbox" name="user_specific_rate" v-model="formData.userSpecific.value" :value="false" :disabled="formData.locked.value || isEditForm">
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('userId')}" v-show="formData.userSpecific.value">
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            User
                        </label>
                        <div class="form-control-120">
                            <book-selectbox
                                v-validate.immediate="{ required: formData.userSpecific.value}"
                                componentId="production-leader"
                                :items="getUsersList"
                                :selected="formData.userId.value"
                                @selectedItem="id => formData.userId.value = id"
                                :disabled="formData.locked.value || isEditForm"
                                :tabindex="10">
                            </book-selectbox>
                        </div>
                    </div>
                    <!-- Country -->
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('currency')}" >
                        <label class="control-label control-label-120">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Currency
                        </label>
                        <div class="form-control-120">

                            <select class="form-control" name="currency" v-validate="formData.currency.validator" v-model="formData.currency.value" v-if="currencies" :disabled="formData.locked.value" tabindex="12">
                                <option value="">
                                    None
                                </option>
                                <option v-for="currency in currencies" :value="currency.id">
                                    {{currency.label}}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('ratePerDay')}">
                        <label for="name" class="control-label control-label-120">
                            Rate per day
                        </label>
                        <div class="form-control-120">
                            <input v-validate="formData.ratePerDay.validator" v-model="formData.ratePerDay.value" name="ratePerDay" type="number" class="form-control" :disabled="formData.locked.value" />
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('ratePerHalfDay')}">
                        <label for="name" class="control-label control-label-120">
                            Rate per half day
                        </label>
                        <div class="form-control-120">
                            <input v-validate="formData.ratePerHalfDay.validator" v-model="formData.ratePerHalfDay.value" name="ratePerHalfDay" type="number" class="form-control" :disabled="formData.locked.value" />
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('ratePerHour')}">
                        <label for="name" class="control-label control-label-120">
                            Rate per hour
                        </label>
                        <div class="form-control-120">
                            <input v-validate="formData.ratePerHour.validator" v-model="formData.ratePerHour.value" name="ratePerHour" type="number" class="form-control" :disabled="formData.locked.value" />
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('overRatePerHour')}">
                        <label for="name" class="control-label control-label-120">
                            Overtime rate per hour
                        </label>
                        <div class="form-control-120">
                            <input v-validate="formData.overRatePerHour.validator" v-model="formData.overRatePerHour.value" name="overRatePerHour" type="number" class="form-control" :disabled="formData.locked.value" />
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('fixedRatePerPublicHoliday')}">
                        <label for="name" class="control-label control-label-120">
                            Fixed public holiday rate
                        </label>
                        <div class="form-control-120">
                            <input v-validate="formData.fixedRatePerPublicHoliday.validator" v-model="formData.fixedRatePerPublicHoliday.value" name="fixedRatePerPublicHoliday" type="number" class="form-control" :disabled="formData.locked.value" />
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
    <div class="book-form-actions book-actions-flex book-bottom-sticky" v-if="loaded">
        <!-- <button class="btn btn-primary" @click="submit()" :disabled="isFormClean || formErrors.any()">Save</button>
        <router-link exact :to="{name: 'calendar'}" class="btn btn-danger">Cancel</router-link> -->
        <div class="book-form-actions-label">
            <!-- <span class="book-form-actions-label-text book-form-actions-label-last-change" v-if="hasHistory" title="Last change">
                <font-awesome-icon icon="clock"/> 
                <div class="book-form-actions-label-last-change-inner">
                    <span>{{calendar.get.history[0].date}}</span>
                    <span>by <router-link :to="{name: 'user.id', params: {id: calendar.get.history[0].user.id}}">{{calendar.get.history[0].user.name}}</router-link></span>
                </div>
            </span> -->
            <span class="book-form-actions-label-text book-form-actions-label-datetimes" v-if="isEditForm" title="Rate start & end">
                <div>
                    <span>{{getFormattedProductionDate('rateStart').date}}</span><br />
                    <span v-if="rate.get.rateEnd">{{getFormattedProductionDate('rateEnd').date}}</span>
                </div>
            </span>
            <span class="book-form-actions-label-text" v-if="isEditForm ">
                ID: {{id}}
            </span>
            <span class="book-form-actions-label-text" v-if="isChanged">
                <span class="label label-danger">unsaved</span>
            </span>
        </div>


        <button tabindex="15" class="btn btn-info" @click="submit(true)" v-if="!isEditForm" :disabled="!isFormValid || processing">Save</button>
        <button tabindex="16" class="btn btn-info" @click="submit(true, false)" v-if="isEditForm" :disabled="!isFormValid || processing || !isChanged">Save</button>
        <button tabindex="17" class="btn btn-primary" @click="submit()" :disabled="!isFormValid || processing || !isChanged" v-if="!getTabRoutes()">Save & close</button>
        <button tabindex="17" class="btn btn-primary" @click="submit(false, false)" :disabled="!isFormValid || processing || !isChanged" v-else>Save & Close</button>
        <router-link tabindex="18" :to="getDestination()" class="btn btn-danger" v-if="!getTabRoutes()">
            <span v-if="isChanged">Cancel</span>
            <span v-else>Close</span>
        </router-link>
        <div tabindex="18" class="btn btn-danger" @click="closeNewTab()" v-else>
            <span v-if="isChanged">Cancel and Close</span>
            <span v-else>Close</span>
        </div>
    </div>
</div>
</template>