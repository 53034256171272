<script>

// Vendors.
import jq from 'jquery';
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'
import { Validator } from 'vee-validate';

export default {
    components: {
        datepicker,
        maskedInput
    },
    props: {
        focus: {
            default: false,
            type: Boolean
        },
        componentId: {
            default: 'field',
            type: String
        },
        required: {
            default: () => {
                return {date: true, time: true}
            },
            type: Object
        },
        defaultValue: {
            default: '',
            type: String
        },
        small: {
            default: false,
            type: Boolean
        },
        limit: {
            default: () => {
                return []
            },
            type: Array
        },
        disabled: {
            default: false,
            type: Boolean
        },
        hideTime: {
            default: false,
            type: Boolean
        },
        dateTabindex:{
            default: 1,
            type: Number
        },
        timeTabindex:{
            default: 1,
            type: Number
        },
        btnOpenTabindex:{
            default: 1,
            type: Number
        }
    },
    data() {
        return {
            date: {
                time: '',
                validator: {
                    required: false,
                    date_format: 'yyyy-MM-dd'
                },
                mask: '____-__-__'
            },
            time: {
                value: '',
                validator: {
                    required: false,
                    date_format: 'HH:mm'
                },
                mask: '__:__'
            },
        }
    },
    watch: {
        defaultValue: function(newValue) {
            this.setDefaultValue()
        },
        'date.time': function(newValue) {
            const context = this
            if (newValue != context.date.mask) {
                context.$validator.validate(this.getFieldName('date'), context.date.time)
                    .then(validated => {
                        if (validated || newValue == '') context.emitValue()
                        // if (!validated) {
                        //     this.date.time = ''
                        // }
                        // context.emitValue()
                    })
            }
        },
        'time.value': function(newValue) {
            const context = this
            if (newValue != context.time.mask) {
                context.$validator.validate(this.getFieldName('time'), context.time.value)
                    .then(validated => {
                        if (validated || newValue == '') context.emitValue()
                        // if (!validated) {
                        //     this.time.value = ''
                        // }
                        // context.emitValue()
                    })
            }
        }
    },
    mounted() {
        const context = this

        // Define a datetime format to use when validating the input.
        if (context.required.date) {
            context.date.validator.required = context.required.date
        }
        if (context.required.time) {
            context.time.validator.required = context.required.time
        }
        context.$validator.attach('date', context.date.validator);
        context.$validator.attach('time', context.time.validator);

        // Set default value, if available.
        if (context.defaultValue) {
            context.setDefaultValue()
        }

        // We need to watch for input value, on focusout i the date field,
        // in order to autocomplete the value, ex if "10" is entered, then the
        // value will be altered to "YYYY-MM-10" (current year and month).
        // The vue-masked-input component doesn't emit any "focusout" event (bug?),
        // why we need to use jQuery to bind that event to the field.
        jq('#datepicker-date-' + context.componentId).on('focusout', function(e) {

            // Try to parse the value with MomentJS, if it doesn't validate.
            if (context.$validator.fields.find({ name: 'date' })) {
                context.$validator.validate('date', context.date.time)
                .then(validated => {
                    if (validated) return
                    let inputDate = context.date.time.replace(/\D/g, '')
                    if (inputDate.length <= 2){
                        inputDate = moment(inputDate, 'DD')
                        if (inputDate.isValid()) {
                            context.date.time = inputDate.format('YYYY-MM-DD')
                        }
                    }
                    
                })
            }else{
                let inputDate = context.date.time.replace(/\D/g, '')
                console.log(inputDate);
                if (inputDate.length <= 2){
                    inputDate = moment(inputDate, 'DD')
                    if (inputDate.isValid()) {
                        context.date.time = inputDate.format('YYYY-MM-DD')
                    }
                }
                    
            }
            

        })

    },
    methods: {
        openDatePicker(ref) {
            const context = this
            context.$refs[ref].showCheck()
        },
        setDefaultValue() {
            const value = this.defaultValue.split(' ')
            this.date.time = value[0] ? value[0] : ''
            if (!this.hideTime) this.time.value = value[1] ? value[1] : ''
        },

        getFieldName(label) {
            return `book_datetime_${label}_${this.componentId}`
        },

        /**
         * Emit the current value to parents, if the value is validated.
         */
        emitValue() {
            //console.log("emit datetime");
            const context = this
            let timeFieldValue = ''
            let datetime = []
            let dateFieldName = context.getFieldName('date')
            let timeFieldName = context.getFieldName('time')
            let dateFieldValue = context.formFields[dateFieldName] && context.formFields[dateFieldName].valid ? context.date.time.trim() : ''
            datetime.push(dateFieldValue)
            if (!context.hideTime) {
                timeFieldValue = context.formFields[timeFieldName] && context.formFields[timeFieldName].valid ? context.time.value.trim() : ''
                datetime.push(timeFieldValue)
            }
            switch (true) {
                case (context.date.validator.required && dateFieldValue == ''):
                case (!context.hideTime && context.time.validator.required && timeFieldValue == ''):
                    datetime = []
                    break
            }
            context.$emit('newValue', datetime.join(' '))
        }

    }
}
</script>

<template>
<div :id="'book-datetime-' + componentId" class="book-datetime clearfix">
    <datepicker
        :id="'datepicker-' + componentId"
        :ref="'datepicker_' + componentId"
        :limit="limit"
        class="book-datetime-datepicker-hidden"
        :date="date" :option="$store.state.config.DATEPICKER_OPTION">
    </datepicker>
    <div class="input-group book-input-date" :class="{'has-error': formErrors.has(getFieldName('date'))}">
        <masked-input :id="'datepicker-date-' + componentId" mask="1111-11-11" :placeholder="date.mask" v-validate="date.validator" v-model:trim="date.time" :name="getFieldName('date')" :disabled="disabled" :tabindex="dateTabindex" type="text" class="form-control"  />
        <span class="input-group-btn">
            <button class="btn btn-primary" :class="{'btn-sm': small}" type="button" @click="openDatePicker('datepicker_' + componentId)" :disabled="disabled" :tabindex="btnOpenTabindex"><font-awesome-icon icon="calendar-alt"/></button>
        </span>
    </div>
    <div class="book-input-time" :class="{'has-error': formErrors.has(getFieldName('time'))}" v-if="!hideTime">
        <masked-input :id="'datepicker-time-' + componentId" mask="11:11" :placeholder="time.mask" v-validate="time.validator" v-model:trim="time.value" :name="getFieldName('time')" :disabled="disabled" :tabindex="timeTabindex" type="text" class="form-control " />
    </div>
</div>
</template>

<style lang="scss">
.book-datetime {
    .book-datetime-datepicker-hidden {
        display: block !important;
        height: 0 !important;
        overflow: hidden !important;
        width: 0 !important;
    }
    .book-input-date,
    .book-input-time {
        float: left;
    }
    .book-input-date {
        width: 140px;
    }
    .book-input-time {
        margin-left: 5px;
        width: 62px;
    }
}
.form-group-sm {
    .book-datetime {
        .book-input-date {
            width: 110px;
        }
        .book-input-time {
            width: 42px;
        }
        .book-input-date,
        .book-input-time {
            float: left;
            .form-control  {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}
</style>
