<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import Feedback from 'components/feedback/Feedback'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import BookAssociations from 'components/association/BookAssociations'
import BookDatetime from 'components/common/BookDatetime'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
//import EquipmentUsage from 'components/equipment/EquipmentUsage'
import BookFeedbackProblematicEquipment from 'components/feedback/BookFeedbackProblematicEquipment'
import BookSelectbox from 'components/common/BookSelectbox'
import BookNotification from 'components/notification/BookNotification'

// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'

export default {
    components: {
        BookLoader,
        multiselect,
        datepicker,
        BookAssociations,
        BookDatetime,
        maskedInput,
        BookFeedbackProblematicEquipment,
        BookSelectbox,
        BookNotification
    },
    props: {
        feedbackItemId: {
            type: Number,
            default: 0
        },
        feedbackItemType: {
            type: String,
            default: ''
        },
        feedbackId: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            loaded: false,
            formData: null,//this.defaultData(),
            //feedbackForItem: null,
            feedback: null,
            item: null,
            issueTypesEntity: null,
            otherIssueTypeLabel: "Other",
            isOtherBoxChecked : false,
            softwareErrorSourceLabel: "Hard / Software",
            isSoftwareBoxChecked: false,
            equipmentUsage: null,
            equipment: null,
            equipmentTypeEntity: null,
            itemType: this.feedbackItemType,
            itemId: this.feedbackItemId,
            isOk: false,
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
                defaultEmailMessage: '',
                messageTemplate: ''
            },
            defaultHouseNumberKeys: {},
            defaultHouseNumbers: []
        }
    },
    beforeCreate() {
        if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
            this.defaultHouseNumberKeys = {}
            this.defaultHouseNumbers = []
            //this.defaultData.houseNumber.value = {}
            //this.defaultData.houseNumber.values = []

            this.$store.state.system.countries.forEach(c => {
                this.defaultHouseNumberKeys[c.code] = ''
                this.defaultHouseNumbers.push(c.code)
                //this.defaultData.houseNumber.value[c.code] = ''
                //this.defaultData.houseNumber.values.push(c.code)
            })
        }
    },
    mounted() {
        const context = this
        console.log(context)
        console.log(context.isEditForm)
        context.feedback = new Feedback(context.feedbackId)
        //context.item = context.feedbackItemType == 'feed' ? new Feed(context.feedbackItemId) : new Production(context.feedbackItemId)

        if(context.isEditForm) {
            context.feedback.load()
                .then(response => {
                    context.itemType = response.data.feedbackItemType
                    context.itemId = response.data.feedbackItemId
                    
                    context.item = response.data.feedbackItemType == 'feed' ? new Feed(response.data.feedbackItemId) : new Production(response.data.feedbackItemId)
                    context.loadData(response.data.feedbackItemType, response.data.feedbackItemId)
                })
                .catch(err => {
                    context.$error.set(err, `It was not possible to load form for feedback ${context.feedbackId}`)
                    context.loaded = true
                    //reject()
                })
        }
        else {
            context.item = context.feedbackItemType == 'feed' ? new Feed(context.feedbackItemId) : new Production(context.feedbackItemId)
            context.isOk = context.$route.query.isOk
            context.loadData(context.feedbackItemType, context.feedbackItemId)
        }

        // Feedback.getFeedbackItem(context.feedbackItemType, context.feedbackItemId)
        //     .then(response => {
        //         //context.item = context.$store.state.data[context.feedbackItemType]
        //         context.loadData()
        //             .then(response => {
        //                 context.setFormValues()
        //                 context.loaded = true
        //             })
        //             .catch(err => {
        //                 //TODO: if 404 then feedback not found
        //                 console.log(err)
        //             })
        //     })
        //     .catch(err => {
        //         context.$error.set(err, `It was not possible to get ${context.feedbackItemType} ${context.feedbackItemId}`)
        //         context.loaded = true        
        //     })
        
    },
    computed: {
        isEditForm() {
            return this.$route.name.indexOf('.edit') > -1
        },
        getApiUrl() {
            return 'api/' + this.itemType + '/' + this.itemId + '/feedback'
        },
        countries() {
            return this.$store.state.system.countries.length > 0 ? this.$store.state.system.countries : null
        },
        getTitle() {
            if (!this.feedback.get || !this.item.get) return

            return this.feedback.get.feedbackItemType === 'feed' ? `Feedback on Feed '${this.item.get.title}'` : `Feedback on Production '${this.item.get.title}'`
        },
        availableUsers() {
            let users = []

            if(this.item.get.productionLeader) {
                users.push(this.item.get.productionLeader)
            }
            
            
            return users
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
        affectedAreas() {
            if(!this.issueTypesEntity) return

            return this.issueTypesEntity.get.userfield1_values ? this.issueTypesEntity.get.userfield1_values.split(',') : []

        },
        errorSources() {
            if(!this.issueTypesEntity) return

            return this.issueTypesEntity.get.userfield2_values ? this.issueTypesEntity.get.userfield2_values.split(',') : []

        },
        issueTypes() {
            if(!this.issueTypesEntity) return

            const context = this
            let filteredIssueTypes = context.formData.affectedArea.value ? context.issueTypesEntity.get.items.filter(f => _.includes(f.userfield1_value.split(','), context.formData.affectedArea.value)) : null
            if(filteredIssueTypes && filteredIssueTypes.length > 0) {
                let other =_.find(filteredIssueTypes, f => f.label === context.otherIssueTypeLabel)
                if(other) {
                    let indx = _.findIndex(filteredIssueTypes, f => f.id === other.id)
                    filteredIssueTypes.splice(indx, 1)
                    filteredIssueTypes.push(other)
                }
            }
             

            return filteredIssueTypes
        },
        isLocked() {
            return !this.isEditForm ? false : !(/* this.feedback.get.createdBy == app.$data.user.get.id && */ !this.feedback.get.locked && this.$ability.can('update_feedbacks_reports', 'Feedbacks & Reports'))
        },
        isEditable() {
            return this.isEditForm ? false : true
        },
        isReadByAdmin() {
            return this.isEditForm && this.feedback.get.readByAdmin
        },
        isOtherChecked() {
            //const context = this
            //let other =_.find(context.issueTypes, f => f.label === context.otherIssueTypeLabel)
            return this.isOtherBoxChecked//context.formData.issueTypes.value.length > 0 && other ? _.indexOf(context.formData.issueTypes.value, other.id) > -1 : false
        },
        isSoftwareChecked() {
            //const context = this
            return this.isSoftwareBoxChecked//context.formData.errorSource.value.length > 0 ? _.indexOf(context.formData.errorSource.value, context.softwareErrorSourceLabel) > -1 : false
        },
        processing() {
            return this.$loader.has('feedbackEdit_submit')
        },
        gettingEquipment(){
            return this.$loader.has('feedbackEdit_equipmentLoading')
        },
        isChanged() {
            const context = this
            let changed = false
            if (!this.feedback || !this.feedback.get) return changed
            Lazy(context.formData)
            .filter((v, k) => {
                switch (k) {

                    // The following fields shouldn't be checked either because
                    // they aren't stored inside the feed in the DB or because
                    // the value is stored "in realtime", when it's changed,
                    // ex "documents" whose value is stored directly when
                    // a file is uploaded.
                    case 'itemOverview':
                    case 'ticketUrl':
                    case 'followupUrl':
                    case 'status':
                    case 'createdBy':
                        return false
                        break

                }

                return !v.local
            })
            .each(function(v, k) {
                if (changed) return true
                if (Array.isArray(v.value) && Array.isArray(context.feedback.get[k]) && (v.value.length != context.feedback.get[k].length)) {
                    changed = true
                }
                else {
                    switch (k) {
                        case 'errorStart':
                            if(context.formData.isErrorReport.value) {
                                let _errorStart1 = context.formData.errorStart.value
                                let _errorStart2 = moment(context.feedback.get.errorStart, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
                                let _errorStart3 = context.feedback.get.errorStart

                                if (_errorStart1 === _errorStart2 || context.feedback.get.errorStart === null && typeof _errorStart1 === 'undefined' || _errorStart1 == '') { changed = false }
                                else if (typeof context.feedback.get.errorStart != 'undefined' || _errorStart1 != _errorStart3 && typeof context.feedback.get.errorStart === 'undefined') { changed = true }
                            }
                            
                            break
                        case 'errorEnd':
                            if(context.formData.isErrorReport.value) {
                                let _errorEnd1 = context.formData.errorEnd.value
                                let _errorEnd2 = moment(context.feedback.get.errorEnd, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
                                let _errorEnd3 = context.feedback.get.errorEnd

                                if (_errorEnd1 === _errorEnd2 || context.feedback.get.errorEnd === null && typeof _errorEnd1 === 'undefined' || _errorEnd1 == '') { changed = false }
                                else if (typeof context.feedback.get.errorEnd != 'undefined' || _errorEnd1 != _errorEnd3 && typeof context.feedback.get.errorEnd === 'undefined') { changed = true }
                            }

                            break
                        case 'issueTypes':
                        case 'errorSource': 
                            if(context.formData.isErrorReport.value) {
                                let h = Array.isArray(context.feedback.get[k]) ?  context.feedback.get[k] : JSON.parse(context.feedback.get[k])
                                v.value.forEach((v2, k2) => {
                                    if (!changed && (h.findIndex(item => item == v2) == -1)) {
                                        changed = true
                                    }

                                })
                            }

                            break
                        case 'affectedArea':
                        case 'problematicEquipment': 
                            if(context.formData.isErrorReport.value) {
                                if (context.feedback.get && !context.feedback.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = context.feedback.get[k] != v.value
                                }
                            }

                            break
                        case 'otherIssueType':
                            if(context.formData.isErrorReport.value && context.isOtherBoxChecked) {
                                if (context.feedback.get && !context.feedback.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = context.feedback.get[k] != v.value
                                }
                            }

                            break
                        case 'additionalInfo':
                            if(context.formData.isErrorReport.value) {
                                if (context.feedback.get && !context.feedback.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = context.feedback.get[k] != v.value
                                }
                            }

                            break
                         default:

                            // If the form value and feed value are "false/null/empty"
                            // then "changed" should be false.
                            if (context.feedback.get && !context.feedback.get[k] && !v.value) {
                                changed = false
                            }
                            else {
                                changed = context.feedback.get[k] != v.value
                            }

                            break
                    }
                }
            })
            return changed
        },
        isFormValid() {
            return true
        },
        feedbackItem() {
            return this.item.get
        },
        isTicketCreated() {
            return this.feedback.get.isCreateTicket && this.feedback.get.ticketUrl !== ''
        },
        isFollowUp() {
            return this.feedback.get.isFollowUp && this.feedback.get.followupUrl !== ''
        }

    },
    watch:{
        'formData.affectedArea.value': function(newValue, oldValue) {
            const context = this
            let oldIssueTypes = oldValue ? context.issueTypesEntity.get.items.filter(f => _.includes(f.userfield1_value.split(','), oldValue)) : []
            let newIssueTypes = newValue ? context.issueTypesEntity.get.items.filter(f => _.includes(f.userfield1_value.split(','), newValue)) : []

            if(newIssueTypes.length > 0 ){
                oldIssueTypes.forEach(i => {
                    let inNewIssue = _.find(newIssueTypes, n => n.id === i.id)
                    if( inNewIssue === undefined) {
                        let indx = _.indexOf(context.formData.issueTypes.value, i.id)
                        if(indx !== -1) {
                            context.formData.issueTypes.value.splice(indx, 1)
                        }
                    }
                
                })
            }
            else {
                context.formData.issueTypes.value = []
            }
        },
        'formData.issueTypes.value': function(newValue) {
            const context = this
            let other =_.find(context.issueTypes, f => f.label === context.otherIssueTypeLabel)
            context.isOtherBoxChecked =  newValue.length > 0 && other ? _.find(newValue, n => parseInt(n) === other.id) !== undefined : false
        },
        'formData.errorSource.value': function(newValue) {
            const context = this
            context.isSoftwareBoxChecked = newValue.length > 0 ? _.indexOf(newValue, context.softwareErrorSourceLabel) > -1 : false
            if(context.isSoftwareBoxChecked && ! context.equipment && !context.equipmentTypeEntity) {
                context.loadEquipment()
                .then(response => {
                    context.equipment = response[0].data
                    //context.equipmentUsage = response[1].data
                    context.$loader.remove('feedbackEdit_equipmentLoading')
                })
                .catch(error => {
                    context.$error.set(error, `It was not possible to get equipment list`)
                    context.$loader.remove('feedbackEdit_equipmentLoading')
                })
            }
        },
        'formData.isErrorReport.value': function(newValue) {
            if(newValue && this.formData.issueTypes.value.length === 0) {
                this.formData.affectedArea.value = this.issueTypesEntity.get.userfield1_values.split(',')[0]
                if(!this.feedback.get.isErrorReport) {
                    this.formData.errorStart.value = this.formData.itemOverview.hasOwnProperty('feedStart') ? this.formData.itemOverview.feedStart.value : this.formData.itemOverview.productionStart.value
                }
                
            }
        },
        'formData.problematicEquipment.value': function(newValue) {
            console.log(newValue)
        }
        
    },
    methods: {
        loadData(itemType, itemId) {
            const context = this

            Feedback.getFeedbackItem(itemType, itemId)
                .then(response => {
                    context.equipmentUsage = context.item.get.equipment
                    context.loadEntities()
                        .then(response => {
                            context.updateHouseNumbers()
                            context.formData = context.defaultData()
                            context.setFormValues()
                            context.loaded = true
                        })
                        .catch(err => {
                            context.$error.set(err, `It was not possible to load child entities`)
                            context.loaded = true
                        })
                })
                .catch(err => {
                    context.$error.set(err, `It was not possible to get ${context.itemType} ${context.itemId}`)
                        context.loaded = true 
                })
        },
        loadEntities() {
            const context = this
            return new Promise((resolve, reject) => {
                const issueTypeEntityId =  context.$settings.get.feedbacks.issuetypes_entity_id;
                context.issueTypesEntity = new Entity(issueTypeEntityId)

                const entity = new Entity()
                entity.all({ids: [issueTypeEntityId ].join(',')})
                    .then(response => {
                        resolve()
                    })
                    .catch(err => {
                        reject()
                    })
            })
        },
        loadEquipment() {
            const context = this
            context.$loader.add('feedbackEdit_equipmentLoading')
            const dataToLoad = []
            context.equipment = new Equipment()
            dataToLoad.push(context.equipment.all({filter: context.itemType, country: app.$data.user.get.country}))
            // context.equipmentUsage = new EquipmentUsage()
            // const params = {
            //     itemType: context.feedbackItemType,
            //     itemId: context.feedbackItemId
            // }
            // dataToLoad.push(context.equipmentUsage.all(params))
            const entity = new Entity()
            const equipmentTypeEntityId = context.$settings.get.equipment.equipmenttype_entity_id
            context.equipmentTypeEntity = new Entity(equipmentTypeEntityId)

            dataToLoad.push(entity.all({ids: [equipmentTypeEntityId].join(',')}))

            return Promise.all(dataToLoad)
        },
        setFormValues() {
            const context = this
            if(context.feedbackItem && context.itemType === 'feed') {
                 Lazy(context.formData.itemOverview)
                    .each(function(v, k) {
                        switch (k) {
                            case 'houseNumber':
                                let hNumber = context.feedbackItem.houseNumber
                                if (hNumber) {
                                    hNumber = JSON.parse(hNumber)
                                    Lazy(v.value)
                                        .each(function (v2, k2) {
                                            v.value[k2] = hNumber[k2]
                                        })
                                }

                                break
                            case 'sport':
                                const sport = context.feedbackItem.sport
                                if (sport) {
                                    v.value = sport.label
                                    v.rawValue = sport.id
                                }
                                break
                            case 'league':
                                const league = context.feedbackItem.league
                                if (league) {
                                    v.value = league.label
                                    v.rawValue = league.id
                                }
                                break
                            case 'mcr':
                                v.value = []
                                const mcr = context.feedbackItem.mcr
                                if (mcr) {
                                    context.countries.forEach(item => {
                                        if (mcr.indexOf(item.code) > -1) {
                                            v.value.push(item)
                                        }
                                    })
                                }
                                break
                            case 'deliveryType':
                                const deliveryType = context.feedbackItem.deliveryType
                                if (deliveryType) {
                                    v.value = deliveryType.label
                                    v.rawValue = deliveryType.id
                                }
                                break
                            case 'contentType':
                                const contentType = context.feedbackItem.contentType
                                if (contentType) {
                                    v.value = contentType.label
                                    v.rawValue = contentType.id
                                }
                                break
                            default:

                                // We need to detect if the value is array/object or
                                // another type, so we can make a real copy of the
                                // value and not create a pointer.
                                if (typeof context.feedbackItem[k] == 'object') {
                                    if (Array.isArray(context.feedbackItem[k])) {
                                        v.value = context.feedbackItem[k].slice()
                                    }
                                    else {
                                        Object.assign(v.value, context.feedbackItem[k])
                                    }
                                }
                                else {
                                    v.value = context.feedbackItem[k]
                                }

                                break
                        }
                    })
            }

            if(context.feedbackItem && context.itemType === 'production') {
                Lazy(context.formData.itemOverview)
                    .each(function(v, k) {
                        switch (k) {
                            case 'sport':
                                const sport = context.feedbackItem.sport
                                if (sport) {
                                    v.value = sport.label
                                    v.rawValue = sport.id
                                }
                                break
                            case 'league':
                                const league = context.feedbackItem.league
                                if (league) {
                                    v.value = league.label
                                    v.rawValue = league.id
                                }
                                break
                            case 'productionLeader':
                                const productionLeader = context.feedbackItem.productionLeader
                                if (productionLeader) {
                                    v.value = productionLeader.fullName
                                    v.rawValue = productionLeader.id
                                }
                                break
                            case 'projectId':
                                const leagueText = context.feedbackItem.league
                                if (leagueText) {
                                    v.value = leagueText.text ? leagueText.text : 'not available'
                                }
                                break
                            case 'runType':
                                const runType = context.feedbackItem.runType
                                if (runType) {
                                    v.value = runType.label
                                    v.rawValue = runType.id
                                }
                                break
                            case 'channel':
                                const channel = context.feedbackItem.channel
                                if (channel) {
                                    v.value = channel.label
                                    v.rawValue = channel.id
                                }
                                break
                            case 'houseNumber':
                                        let hNumber = context.feedbackItem.houseNumber
                                        if (hNumber) {
                                            if (isJsonSring(hNumber)) {
                                                hNumber = JSON.parse(hNumber)
                                                Lazy(v.value)
                                                    .each(function (v2, k2) {
                                                        v.value[k2] = hNumber[k2]
                                                    })
                                            } else {
                                                let _channel = context.formData.itemOverview.channel.rawValue ? context.formData.itemOverview.channel.rawValue : context.feedbackItem.channel?.id
                                                if (_channel) {
                                                    // context.channelEntity.get.items.forEach(v2 => {
                                                    //     if (_channel === v2.id) v.value[v2.country] = hNumber
                                                    // })
                                                    v.value[context.feedbackItem.channel.country] = hNumber
                                                }
                                            }
                                        }
                                    
                                
                                break
                            // if channel value is filled, then fill the country value as the channel's country WN
                            case 'country':
                                let _channel = context.formData.itemOverview.channel.rawValue ? context.formData.itemOverview.channel.rawValue : context.feedbackItem.channel?.id
                                if (_channel) {
                                    // context.channelEntity.get.items.forEach(v2 => {
                                    //  if (_channel === v2.id) v.value = v2.country
                                    // })
                                    v.rawValue = context.feedbackItem.channel.country
                                    let countryLabel = _.find(context.countries, c => c.code === context.feedbackItem.channel.country)
                                    v.value = countryLabel ? countryLabel.label : ''
                                }
                                break
                            default:
                                if (typeof context.feedbackItem[k] == 'object') {
                                    if (Array.isArray(context.feedbackItem[k])) {
                                        v.value = context.feedbackItem[k].slice()
                                    }
                                    else {
                                        Object.assign(v.value, context.feedbackItem[k])
                                    }
                                }
                                else {
                                    v.value = context.feedbackItem[k]
                                }
                        }
                    })
            }

            if (context.isEditForm) {
                Lazy(context.formData)
                    .filter(function(v, k) {
                        return k !== 'itemOverview' && !v.local
                    } )
                    .each(function(v, k) {
                        switch(k) {
                            case 'errorDuration':
                                v.value = context.feedback.get[k]
                                break
                            case 'issueTypes':
                            case 'errorSource':
                                if(context.feedback.get[k]) {
                                    v.value = context.feedback.get[k].slice()
                                }
                                break
                            case 'problematicEquipment':
                                v.value = context.feedback.get[k]
                                break
                            default:
                                if (typeof context.feedback.get[k] == 'object') {
                                    if (Array.isArray(context.feedback.get[k])) {
                                        v.value = context.feedback.get[k].slice()
                                    }
                                    else {
                                        Object.assign(v.value, context.feedback.get[k])
                                    }
                                }
                                else {
                                    v.value = context.feedback.get[k]
                                }

                                break
                        }
                    })
            }
            else {
                context.formData.locked.value = false
            }
           
        },
        houseNumberValues() {
            const context = this
            let values = context.formData.itemOverview.houseNumber.values
            let newValues = []
            let mcrValue = []
            if (context.formData.itemOverview.mcr.value) {
                context.formData.itemOverview.mcr.value.forEach((v, k) => {
                    mcrValue.push(v.code)

                })
            }
            if (values) {
                Lazy(values)
                    .each(function (v, k) {
                        if (_.includes(mcrValue, v)) {
                            newValues.push(v)
                        }
                    })
                context.houseNumbers = newValues
                return newValues
            }
        },
        productionHouseNumberValues() {
            const context = this
            return context.formData.itemOverview.houseNumber.values
        },
        labelUppercase(lbl) {
            let lblArr = lbl.split(' ').map(l => l.charAt(0).toUpperCase() + l.slice(1));

            return lblArr.join(' ');
        },
        closeNewTab() {
            window.top.close();
        },
        showNotificationModal(open = true, recipient = 'all') {
            this.notification.recipients = []
           
            
                    const author = this.feedback.get.author
                    if (author) {
                        this.notification.recipients.push({
                            //status: author.status,
                            email: author.email,
                            phone: author.phone,
                            id: author.id,
                            label: author.fullName,
                        })
                        this.notification.modalTitle = `Contact ${author.fullName}`
                        //this.notification.groupBy = 'status'
                        //this.notification.groupMessage = ''
                    }
                    
            
            if (this.notification.recipients.length > 0) {
                this.notification.show = open
            }
            else {
                this.notification.messageTemplate = ''
                this.notification.show = false
                this.$alert.set('No recipients are available.', 'danger', 4)
            }
            if (!open) {
                this.notification.messageTemplate = ''
            }
        },
        getDefaultEmailSubject(){
            return `About your feedback on ${this.itemType} ${this.feedbackItem.title}`
        },
        getDefaultEmailMessage() {
            return `Feedback ${this.feedbackId}: \n /${app.$data.user.get.fullName} \n`
        },
        getDefaultSmsMessage() {
            return `About your feedback on ${this.itemType} ${this.feedbackItem.title}: \n /${app.$data.user.get.fullName} \n`
        },
        submit(open = false, redirect = false) {
            const context = this

            context.$loader.add('feedbackEdit_submit')
            
            Lazy(context.formData)
                .filter((v, k) => !v.local && k != 'itemOverview' && k != 'ticketUrl' && k != 'followupUrl')
                .each(function(v, k) {
                    let value;
                    switch (k) {
                        case 'errorStart':
                        case 'errorEnd':
                        case 'affectedArea':
                        case 'problematicEquipment':
                            value = null
                            if(context.formData.isErrorReport.value) {
                                value =  v.value
                            }
                            break
                        case 'otherIssueType':
                            value = null
                            if(context.formData.isErrorReport.value && context.isOtherBoxChecked) {
                                value =  v.value
                            }
                            break
                        case 'additionalInfo':
                            value = null
                            if(context.formData.isErrorReport.value) {
                                value =  v.value
                            }
                            break
                        case 'errorSource':
                        case 'issueTypes': 
                            value = []
                            if(context.formData.isErrorReport.value) {
                                v.value.forEach(el => value.push(el))
                            }
                            value = JSON.stringify(value)
                            break 
                        case 'createdBy':
                            if(!context.isEditForm) {
                                value = app.$data.user.get.id
                            }
                            break
                        case 'serviceTicketSummary':
                            value = ''
                            if(context.formData.isCreateTicket.value || context.formData.isFollowUp.value) {
                                value = context.formData.itemOverview.sport.value + ' | ' + context.formData.itemOverview.league.value + ' | ' + context.formData.itemOverview.title.value
                            }
                            
                            break
                        case 'serviceTicketDescription':
                            value = ``
                            if(context.formData.isCreateTicket.value || context.formData.isFollowUp.value) {
                                let it = []
                                context.formData.issueTypes.value.forEach(v => {
                                    let itName = _.find(context.issueTypes, i => i.id == v)
                                    if(itName) {
                                        it.push(itName.label)
                                    }
                                })
                                let bEq = []
                                let notBEq = []
                                let otherEq = []
                                if(context.formData.problematicEquipment.value && context.formData.problematicEquipment.value !== '') {
                                    try {
                                        let allPrEq = JSON.parse(context.formData.problematicEquipment.value)
                                        allPrEq.usageIds.forEach(u => {
                                            let eq1 = _.find(context.equipment, i => i.id == u)
                                            if(eq1) {
                                                bEq.push(eq1.label)
                                            }
                                        })

                                        allPrEq.ids.forEach(u => {
                                            let eq2 = _.find(context.equipment, i => i.id == u)
                                            if(eq2) {
                                                notBEq.push(eq2.label)
                                            }
                                        })

                                        allPrEq.names.forEach(u => {
                                            otherEq.push(u)
                                        })
                                    }
                                    catch(ex) {
                                        console.log(ex);
                                    }
                                }

                                value = `*Initiated by:* ${app.$data.user.get.fullName} \n
                                *Feedback:* ${context.formData.feedback.value} \n
                                *Error start:* ${context.formData.errorStart.value} \n
                                *Error end:* ${context.formData.errorEnd.value} \n
                                *Error duration:* ${context.formData.errorDuration.value} \n
                                *Affected area:* ${context.formData.affectedArea.value} \n
                                *Issue types:* ${it.join(', ')} \n
                                *Other issue type:* ${context.formData.otherIssueType.value} \n
                                *Error source:* ${context.formData.errorSource.value.join(', ')} \n
                                *Booked equipment:* ${bEq.join(', ')} \n
                                *Not booked equipment:* ${notBEq.join(', ')} \n
                                *Other equipment:* ${otherEq.join(', ')} \n
                                *Additional information:* ${context.formData.additionalInfo.value} \n
                                `
                            }
                            break
                        default:
                            value = v.value
                            break
                    }

                    context.feedback.set(k, value)
                })

            if (context.isEditForm) {
                context.feedback.save()
                    .then(response => {
                        this.$alert.set('Feedback ' + context.feedback.get.id + ' was saved!', 'success', 3)
                        this.updateHouseNumbers()
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())
                        if (!open) { return context.closeNewTab(); }

                        Vue.set(context.$data, 'formData', context.defaultData())
                        context.setFormValues()

                        context.$loader.remove('feedbackEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the feedback.')
                        context.$loader.remove('feedbackEdit_submit')
                    })
            }
            else {
                context.feedback.store()
                .then(response => {
                    this.$alert.set('Feedback ' + response.data.id + ' was saved!', 'success', 3)
                        app.$emit('creatingFeedback', response.data)
                        //this.$emit('createdFeedback', response.data)

                        this.updateHouseNumbers()

                    if (!open) Vue.set(context.$data, 'formData', context.defaultData())
                        
                        

                        if (!open) { return context.closeNewTab(); }

                        //Vue.set(context.$data, 'formData', context.defaultData())
                        context.feedback = new Feedback(response.data.id)
                       
                        context.redirect(response.data.id)
                        
                        context.$loader.remove('feedbackEdit_submit')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to save the feedback.')
                    context.$loader.remove('feedbackEdit_submit')
                })
            }
            
        },
        redirect(id ) {
            let location = {
                name: 'feedback.edit',
                params: {
                    feedbackId: id,
                    //feedbackItemId: this.feedbackItemId,
                    //feedbackItemType: this.feedbackItemType
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }

            Vue.nextTick(() => {
                    this.setFormValues()
                })

            return new Promise((resolve, reject) => {
                this.$router.push(location, () => {
                    resolve()
                }, () => {
                    reject()
                })

            })
        },
        getTicketLabel(url) {
            if(url) {
                let lbl = url.substring(url.lastIndexOf('/') + 1)
                return lbl
            }
            return ''
        },
        emitButtonEvent() {
            let route = this.$router.resolve({path: `/${this.formData.feedbackItemType.value}/${this.formData.feedbackItemId.value}`});
            window.open(route.href, '_blank')
        },
        updateHouseNumbers() {
            if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
                this.defaultHouseNumberKeys = {}
                this.defaultHouseNumbers = []
                //this.defaultData.houseNumber.value = {}
                //this.defaultData.houseNumber.values = []
    
                this.$store.state.system.countries.forEach(c => {
                    this.defaultHouseNumberKeys[c.code] = ''
                    this.defaultHouseNumbers.push(c.code)
                    //this.defaultData.houseNumber.value[c.code] = ''
                    //this.defaultData.houseNumber.values.push(c.code)
                })
            }
        },
        defaultData() {
            let feedOverview = {
                contentType: {
                    value: '',
                    rawValue: ''
                },
                houseNumber: {
                    value: this.defaultHouseNumberKeys,
                    // value: {
                    //     se: '',
                    //     no: '',
                    //     dk: '',
                    //     fi: '',
                    //     uk: ''
                    // },
                    values: this.defaultHouseNumbers//['se', 'no', 'dk', 'fi', 'uk']
                },
                sport: {
                    value: '',
                    rawValue: ''
                },
                league: {
                    value: '',
                    rawValue: ''
                },
                title: {
                    value: '',
                },
                mcr: {
                    value: []
                },
                mcrExternal: {
                    value: '',
                },
                feedStart: {
                    value: '',
                },
                feedEnd: {
                    value: '',
                },
                pgmStart: {
                    value: '',
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
                deliveryType: {
                    value: '',
                    rawValue: ''
                },
            }
            let productionOverview = {
                sport: {
                    value: '',
                    rawValue: ''
                },
                league: {
                    value: '',
                    rawValue: ''
                },
                title: {
                    value: '',
                    
                },
                productionStart: {
                    value: '',
                    
                },
                productionEnd: {
                    value: '',
                    
                },
                productionLeader: {
                    value: '',
                    rawValue: ''
                },
                projectId: {
                    value: '',
                },
                houseNumber: {
                    value: this.defaultHouseNumberKeys,
                    // value: {
                    //     se: '',
                    //     no: '',
                    //     dk: '',
                    //     fi: '',
                    //     uk: ''
                    // },
                    values: this.defaultHouseNumbers//['se', 'no', 'dk', 'fi', 'uk'],
                },
                runType: {
                    value: '',
                    rawValue: ''
                },
                country: {
                    value: '',
                    rawValue: ''
                },
                channel: {
                    value: '',
                    rawValue: ''
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
            }
            let item = {
                itemOverview: this.itemType === 'feed' ? Object.assign({}, feedOverview) : Object.assign({}, productionOverview),
                locked: {
                    value: true,
                    local: true
                },
                feedbackItemId: {
                    value: this.itemId
                },
                feedbackItemType: {
                    value: this.itemType
                },
                isErrorReport: {
                    value: false
                },
                errorStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: false,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    } 
                },
                errorEnd: {
                    value: '',
                    validator: {
                        rules: {
                            required: false,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                errorDuration: {
                    value: '',
                    validator: {
                        rules: {
                            required: false,
                            date_format: 'HH:mm:ss'
                        }
                    },
                    mask: '__:__:__'
                },
                affectedArea: {
                    value: ''
                },
                issueTypes: {
                    value: []
                },
                otherIssueType: {
                    value: ''
                },
                errorSource: {
                    value: []
                },
                problematicEquipment: {
                    value: ''
                },
                additionalInfo: {
                    value: ''
                },
                feedback: {
                    value: this.isOk ? 'Everything was OK' : '',
                    validator: 'required'
                },
                isCreateTicket: {
                    value: false
                },
                ticketUrl: {
                    value: ''
                },
                isFollowUp: {
                    value: false
                },
                followupUrl: {
                    value: ''
                },
                createdBy: {
                    value: ''
                },
                status: {
                    value: 'ok'
                },
                readByAdmin: {
                    value: false
                },
                serviceTicketSummary: {
                    value: ''
                },
                serviceTicketDescription: {
                    value: ''
                }
            }
            return item
        },
    }
    
}
</script>
<template>
<div id="feedback-edit" class="admin book-compact-panels book-compact-forms book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix" v-if="loaded">
        <h1 class="page-title" v-if="!isEditForm">Add feedback</h1>
        <h1 class="page-title" v-else>{{getTitle}}</h1>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div v-else class="form-horizontal row">
        <div  class="col-sm-4 col-md-4">
             <ul class="nav nav-tabs book-form-navigation">
                <li role="presentation" class="active"><a>{{formData.feedbackItemType.value == 'feed' ? 'Feed Overview' : 'Production Overview'}}</a></li>
                <div class="book-action-buttons" style="top: 5px">
                    <button
                            @click="emitButtonEvent()"
                            class="btn btn-xs btn-primary"
                            >
                        <font-awesome-icon icon="pencil-alt"/>
                    </button>
                </div>
            </ul>
            <div class="panel panel-info" v-if="formData.feedbackItemType.value == 'feed'">
                <div class="panel-body">
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Sport
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="sport" v-model="formData.itemOverview.sport.rawValue" disabled>
                                <option :value="formData.itemOverview.sport.rawValue">{{formData.itemOverview.sport.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            League
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="league" v-model="formData.itemOverview.league.rawValue" disabled>
                                <option :value="formData.itemOverview.league.rawValue">{{formData.itemOverview.league.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Title
                        </label>
                        <div class="form-control-100">
                            <input v-model:trim="formData.itemOverview.title.value" name="title" type="text" class="form-control" disabled />
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('mcr')}">
                        <label for="name" class="control-label control-label-100">MCR's</label>
                        <div class="form-control-100" v-if="countries">
                            <multiselect
                                disabled
                                v-validate
                                name="mcr"
                                v-model="formData.itemOverview.mcr.value"
                                track-by="code"
                                label="label"
                                placeholder=""
                                :max-height="200"
                                :options="countries"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="true"
                                :hide-selected="true"
                                :show-labels="false">
                                <span slot="noResult">No countries found.</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('mcrExternal')}">
                        <label for="name" class="control-label control-label-100">External MCR's</label>
                        <div class="form-control-100">
                            <input v-model:trim="formData.itemOverview.mcrExternal.value" disabled name="label" type="text" class="form-control" >
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">Delivery Type</label>
                        <div class="form-control-100">
                            <select class="form-control" name="deliveryType" v-model="formData.itemOverview.deliveryType.rawValue" disabled>
                                <option :value="formData.itemOverview.deliveryType.rawValue">{{formData.itemOverview.deliveryType.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Content Type
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="contentType" v-model="formData.itemOverview.contentType.rawValue" disabled>
                                <option :value="formData.itemOverview.contentType.rawValue">{{formData.itemOverview.contentType.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100"> House Number </label>
                    </div>
                    <div class="form-group form-group-sm" v-for="(hnum, index) in houseNumberValues()">
                        <label for="name" class="control-label control-label-100">
                            {{hnum.toUpperCase()}}
                        </label>
                        <div class="form-control-100">
                            <input :id="'housenumber_' + hnum" v-model:trim="formData.itemOverview.houseNumber.value[hnum]" :key="index + '_' + hnum" name="house_number" type="text" class="form-control" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-info" v-else>
                <div class="panel-body">
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Sport
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="sport" v-model="formData.itemOverview.sport.rawValue" disabled>
                                <option :value="formData.itemOverview.sport.rawValue">{{formData.itemOverview.sport.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            League
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="league" v-model="formData.itemOverview.league.rawValue" disabled>
                                <option :value="formData.itemOverview.league.rawValue">{{formData.itemOverview.league.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Title
                        </label>
                        <div class="form-control-100">
                            <input v-model:trim="formData.itemOverview.title.value" name="title" type="text" class="form-control" disabled />
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-start"
                                :default-value="formData.itemOverview.productionStart.value"
                                :small="true"
                                disabled>
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            End
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-production-end"
                                :default-value="formData.itemOverview.productionEnd.value"
                                :small="true"
                                disabled>
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Production leader
                        </label>
                        <div class="form-control-100">
                            <book-selectbox
                                componentId="production-leader"
                                :items="getUsersList"
                                :selected="formData.itemOverview.productionLeader.rawValue"                               
                                disabled
                                :tabindex="10">
                            </book-selectbox>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Project ID
                        </label>
                        <div class="form-control-100">
                            <p class="form-control-static">{{formData.itemOverview.projectId.value}}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100"> House Number </label>
                    </div>
                    <div class="form-group form-group-sm" v-for="(hnum, index) in productionHouseNumberValues()">
                        <label for="name" class="control-label control-label-100">
                            {{hnum.toUpperCase()}}
                        </label>
                        <div class="form-control-100">
                            <input :id="'housenumber_' + hnum" v-model:trim="formData.itemOverview.houseNumber.value[hnum]" :key="index + '_' + hnum" name="house_number" type="text" class="form-control" disabled />
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Country
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="league" v-model="formData.itemOverview.country.rawValue" disabled>
                                <option :value="formData.itemOverview.country.rawValue">{{formData.itemOverview.country.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Run type
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="league" v-model="formData.itemOverview.runType.rawValue" disabled>
                                <option :value="formData.itemOverview.runType.rawValue">{{formData.itemOverview.runType.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Channel
                        </label>
                        <div class="form-control-100">
                            <select class="form-control" name="league" v-model="formData.itemOverview.channel.rawValue" disabled>
                                <option :value="formData.itemOverview.channel.rawValue">{{formData.itemOverview.channel.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-info book-association-wrapper">
                <div class="panel-heading">
                    Associations
                </div>
                <book-associations
                    :items="formData.itemOverview.associations.value"
                    
                    :countries="countries"
                    disabled
                    disabled-message="Associations box is disabled"
                   >
                </book-associations>
            </div>
            <div class="panel panel-info book-box-schedule" v-if="formData.feedbackItemType.value == 'feed'">
                <div class="panel-heading">
                    Schedule
                </div>
                <div class="panel-body">
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Feed start
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-feed-start"
                                :default-value="formData.itemOverview.feedStart.value"
                                :small="true"
                                disabled>
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Feed end
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-feed-end"
                                :default-value="formData.itemOverview.feedEnd.value"
                                :small="true"
                                disabled>
                            </book-datetime>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            PGM Start
                        </label>
                        <div class="form-control-100">
                            <book-datetime
                                component-id="book-pgm-start"
                                :default-value="formData.itemOverview.pgmStart.value"
                                :small="true"
                                disabled>
                            </book-datetime>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-8">
            <div class="panel panel-info">
                 <div class="panel-heading">
                    Feedback
                </div>
                <div class="panel-body">
                    <textarea rows="3" class="form-control" name="feedback" v-model="formData.feedback.value" :disabled="isLocked"></textarea>
                </div>
            </div>
            <div class="panel panel-info">
                <div class="panel-heading">
                    Error Report
                    <div class="checkbox-in-header">
                        <input v-model="formData.isErrorReport.value" :disabled="isLocked" name="label" type="checkbox" >
                    </div>
                </div>
                <div class="panel-body" v-if="formData.isErrorReport.value">
                    
                        <div class="row error-report">
                            <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Error Start (if relevant)
                                </div>
                                <div class="panel-body">
                                    
                                        <div class="form-group form-group-sm">
                                            <book-datetime
                                                component-id="book-feedback-error-start"
                                                :default-value="formData.errorStart.value"
                                                @newValue="value => formData.errorStart.value = value"
                                                :small="true"
                                                :disabled="isLocked">
                                            </book-datetime>
                                        </div>
                                    
                                </div>
                            </div>
                            <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Error End (if relevant)
                                </div>
                                <div class="panel-body">
                                    
                                        <div class="form-group form-group-sm">
                                            <book-datetime
                                                component-id="book-feedback-error-end"
                                                :default-value="formData.errorEnd.value"
                                                @newValue="value => formData.errorEnd.value = value"
                                                :small="true"
                                                :disabled="isLocked">
                                            </book-datetime>
                                        </div>
                                    
                                </div>
                            </div>
                            <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Duration of Error (if relevant)
                                </div>
                                <div class="panel-body">
                                     <div class="form-group form-group-sm">
                                         <div class="book-datetime clearfix">
                                             <div class="book-datetime-datepicker-hidden"></div>
                                             <div class="input-group book-input-date-only">
                                                <span>Total duration of error</span>
                                                <masked-input mask="11:11:11" :placeholder="formData.errorDuration.mask" v-validate="formData.errorDuration.validator" v-model:trim="formData.errorDuration.value" type="text" class="form-control" :disabled="isLocked" />
                                            </div>
                                         </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Duration of Error (if relevant)
                                </div>
                                <div class="panel-body">
                                    
                                        <div class="form-group form-group-sm">
                                            <div class="book-datetime clearfix">
                                                <div class="book-input-time">
                                                    <masked-input mask="11:11:11" :placeholder="formData.errorDuration.mask" v-validate="formData.errorDuration.validator" v-model:trim="formData.errorDuration.value" type="text" class="form-control" />
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    
                                </div>
                            </div> -->
                        </div>
                        <div class="row error-report" >
                            <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Select Affected Area
                                </div>
                                <div class="panel-body" v-if="affectedAreas">
                                    <div class="radio">
                                        <label v-for="i in affectedAreas" :key="i" class="control-label radio-inline radio-inline-area">
                                            <input type="radio" v-model="formData.affectedArea.value" :disabled="isLocked" :value="i" />
                                            <span>{{labelUppercase(i)}}</span>
                                        </label>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="row error-report" v-if="issueTypes">
                            <div class="panel panel-info panel-info-wrapper-width">
                                <div class="panel-heading">
                                    Select Issue Type
                                </div>
                                <div class="panel-body" >
                                    <div class="checkbox">
                                        <label v-for="i in issueTypes" class="control-label checkbox-inline checkbox-inline-issue" :key="'issue_type_' + i.id">
                                            <input type="checkbox" v-model="formData.issueTypes.value" :value="i.id" :disabled="isLocked"  />
                                            <span>{{i.label}}</span>
                                        </label>
                                    </div>
                                    <input type="text" v-if="isOtherChecked" v-model="formData.otherIssueType.value" class="form-control" :disabled="isLocked" />
                                </div>
                            </div>
                        </div>
                        <div class="row error-report" >
                            <div class="panel panel-info panel-info-wrapper">
                                <div class="panel-heading">
                                    Select Error Source
                                </div>
                                <div class="panel-body" v-if="errorSources">
                                    <div class="checkbox">
                                        <label v-for="i in errorSources" class="control-label checkbox-inline checkbox-inline-issue" :key="i">
                                            <input type="checkbox" v-model="formData.errorSource.value" :value="i" :disabled="isLocked"  />
                                            <span>{{i}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row error-report" v-if="isSoftwareChecked">
                            <div class="panel panel-info panel-info-wrapper-width">
                                <div class="panel-heading">
                                    Select Equipment
                                </div>
                                <div class="panel-body" v-if="!gettingEquipment">
                                    <div class="row error-report">
                                        <book-feedback-problematic-equipment
                                            :equipment="equipment"
                                            :equipmentUsage="equipmentUsage"
                                            :equipmentTypes="equipmentTypeEntity.get.items"
                                            :problematicEquipmentProp="formData.problematicEquipment.value"
                                            :disabled="isLocked"
                                            v-model="formData.problematicEquipment.value"
                                        >
                                        </book-feedback-problematic-equipment>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row error-report">
                            <div class="panel panel-info panel-info-wrapper-width">
                                <div class="panel-heading">
                                    Additional Information
                                </div>
                                <div class="panel-body">
                                    <textarea rows="3" class="form-control" placeholder="Add your comment here..." name="feedback" v-model="formData.additionalInfo.value" :disabled="isLocked"></textarea>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="panel panel-info">
                 <div class="panel-heading">
                   Service Ticket
                   <div class="checkbox-in-header">
                       <input v-model="formData.isCreateTicket.value" :disabled="isTicketCreated" name="label" type="checkbox" >
                   </div>
                </div>
                <div class="panel-body" v-if="formData.isCreateTicket.value && formData.ticketUrl.value !== null && formData.ticketUrl.value !== ''">
                     <div class="form-group form-group-sm additional-info">
                        <a :href="formData.ticketUrl.value" target="_blank">{{getTicketLabel(formData.ticketUrl.value)}}</a>
                    </div>
                </div>
            </div>
            <div class="panel panel-info">
                 <div class="panel-heading">
                   Follow up
                   <div class="checkbox-in-header">
                       <input v-model="formData.isFollowUp.value" :disabled="isFollowUp" name="label" type="checkbox" >
                   </div>
                </div>
                <div class="panel-body" v-if="formData.isFollowUp.value && formData.followupUrl.value !== null && formData.followupUrl.value !== ''">
                     <div class="form-group form-group-sm additional-info">
                        <a :href="formData.followupUrl.value" target="_blank">{{getTicketLabel(formData.followupUrl.value)}}</a>
                    </div>
                </div>
            </div>
            <div class="panel panel-info">
                 <div class="panel-heading">
                   Read by Admin/Production leader
                   <div class="checkbox-in-header">
                       <input v-model="formData.readByAdmin.value" :disabled="isReadByAdmin" name="label" type="checkbox" >
                   </div>
                </div>
            </div>
        </div>
    </div>
     <book-notification
            v-if="notification.show"
            componentId="bookProductionCrew"
            :modalTitle="notification.modalTitle"
            :recipients="notification.recipients"
            :defaultEmailSubject="getDefaultEmailSubject()"
            :defaultEmailMessage="getDefaultEmailMessage()"
            :defaultSmsMessage="getDefaultSmsMessage()"
            @close="notification.show = false">
        </book-notification>
    <div class="book-form-actions book-actions-flex book-bottom-sticky" v-if="loaded">
        <div class="book-form-actions-label">
            <span class="book-form-actions-label-text book-form-actions-label-last-change" v-if="isEditForm" title="Last change">
                <font-awesome-icon icon="clock"/>   
                <div class="book-form-actions-label-last-change-inner">
                    <span>{{feedback.get.updated}}</span>
                    <span>by <router-link :to="{name: 'user.id', params: {id: feedback.get.createdBy}}">{{feedback.get.fullName}}</router-link>
                    
                    </span>
                </div>
                 <button
                        class="btn btn-info btn-xs"
                        title="Contact to feedback author"
                        @click="showNotificationModal(true)"
                        :style="{'margin-left': '5px'}">
                        <font-awesome-icon icon="envelope"/>
                    </button>
            </span>
            <span class="book-form-actions-label-text" v-if="isEditForm">
                ID: {{feedbackId}}
            </span>
            <span class="book-form-actions-label-text" v-if="isChanged">
                <span class="label label-danger">unsaved</span>
            </span>
        </div>
        <button tabindex="10" class="btn btn-info" @click="submit(true)" v-if="!isEditForm" :disabled="!isFormValid || processing || isLocked">Save</button>
        <button tabindex="10" class="btn btn-info" @click="submit(true, false)" v-if="isEditForm" :disabled="!isFormValid || processing || !isChanged">Save</button>
        <!-- <button tabindex="10" class="btn btn-primary" @click="submit()" :disabled="!isFormValid || processing || !isChanged || !isEditable" v-if="!getTabRoutes()">Save & close</button> -->
        <button tabindex="10" class="btn btn-primary" @click="submit()" :disabled="!isFormValid || processing || !isChanged " >Save & close</button>
        <!-- <router-link tabindex="18" exact :to="getDestination()" class="btn btn-danger" v-if="!getTabRoutes()">
            <span v-if="isChanged">Cancel</span>
            <span v-else>Close</span>
        </router-link> -->
        <div tabindex="18" class="btn btn-danger" @click="closeNewTab()" >
            <span v-if="isChanged">Cancel and Close</span>
            <span v-else>Close</span>
        </div>
    </div>
</div>
</template>
<style lang="scss">
#feedback-edit {
    padding-bottom: 50px;

    .panel {
        position: relative;
    }
    
    .book-copyform-loading-text {
        margin-bottom: 10px;
        text-align: center;
    }

    .book-box-audio-layout {
        .control-label-100 {
            width: 60px;
        }
        .form-control-100 {
            width: calc(100% - 60px)
        }
    }
    .book-box-schedule {
        .control-label-100 {
            width: 75px;
        }
        .form-control-100 {
            width: calc(100% - 75px);
        }
    }
    .book-box-specifications {
        .control-label-100 {
            width: 90px;
        }
        .form-control-100 {
            width: calc(100% - 90px);
        }
    }

    .book-box-delivery-type {
        .book-delivery-type-sat {
            .control-label-100 {
                width: 70px;
            }
            .form-control-100 {
                width: calc(100% - 70px);
            }
            .row {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .book-panel-localnotes {
        .checkbox {
            margin-bottom: 10px;
        }
    }
    .book-ingestion {
        position: absolute;
        right: 86px;
        top: -24px;
        z-index: 5;
    }
    .book-action-buttons {
        position: absolute;
        right: 0;
        top: -25px;
        z-index: 5;
        .btn-danger, .btn-warning {
            margin-left: 5px;
        }
    }

    .panel.panel-info.panel-info-wrapper,
    .panel.panel-info.panel-info-wrapper-width {
         margin-right: 5px;
    }

    .panel.panel-info.panel-info-wrapper {
        display: inline-block;
    }

    .panel.panel-info.panel-info-wrapper-width {

    }

    .control-label.control-label-100.duration {
        display: inline;
    }

    .book-input-date-only{
        padding-top: 5px;
        height: 30.4px;
        input {
            border-radius: 3px;
            float: right;
            width: 72px !important;
            margin-left: 8px;
            margin-top: -5px;
        }

        span {
            font-size: 12px;
            //font-weight: 700;
            margin-top: 8px;
        }
    }

    .radio-inline-area,
    .checkbox-inline-issue {
        span {
            font-size: 12px;
            font-weight: 0 !important;
        }

        
    }

    .additional-info {
        margin-top: 8px;
        .control-label-100 {
            width: 70px;
            
        }

        .control-label.control-label-100.control-label-no-bold {
            display: inline-block;
            span {
                font-size: 12px;
                font-weight: 0 !important;
            }
        }

        .form-control-100 {
            width: calc(100% - 70px);
        }
    }

    // span.checkbox-inline-issue {
    //     font-size: 12px;
    // }

    // .book-datetime {
    //     .book-datetime-datepicker-hidden {
    //         display: block !important;
    //         height: 0 !important;
    //         overflow: hidden !important;
    //         width: 0 !important;
    //     }
    //     .book-input-date-only,
    //     .book-input-time-only {
    //         //float: left;
    //         display: block;
    //         input {
    //             display: block;
    //         }
    //     }
    //     .book-input-date {
    //         width: 140px;
    //     }
    //     .book-input-time {
    //         margin-left: 5px;
    //         width: 62px;
    //     }
    // }

    .row.error-report {
        margin-left: 0px;
        margin-right: -5px;
        padding-left: 0px;

        .col-no-padding {
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 5px;
            select {
                font-size: 12px !important;
            }

            .input-other-equipment {
                border-radius: 4px;
                float: right;
                width: 100% !important;
                font-size: 12px !important;
                border: 1px solid #ccc;
                height: 33px;
                //margin-left: 8px;
                //margin-top: -5px;
            }
            
        }
        
        button {
            //margin-top: 5px;
        }
    
    }

    .checkbox-in-header {
        display: inline-block;
        text-align: center;
        position: absolute;
        right: 3px;
    }
    .table,
    .book-table-no-results {
        font-size: 12px;
    }

    
}
</style>