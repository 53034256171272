/**
 * Help functions related to date and time.
 */

import store from 'store'

class Datetime {

    static getWeekNumber(date, format = 'YYYY-MM-DD') {
        return moment(date, format).isoWeek()
    }
    static getWeekDay(date, format = 'YYYY-MM-DD') {
        return moment.weekdays(moment(date, format).weekday())
    }
    static getDate(date, format = 'YYYY-MM-DD') {
        return moment(date).format(format)
    }
    static getTime(dateStart, dateEnd, format = 'HH:mm') {
        return moment(dateStart).format(format) + ' - ' + moment(dateEnd).format(format)
    }
    static today() {
        return moment().format('YYYY-MM-DD')
    }
    static todayPlusInterval(interval = null) {
        interval = interval !== null ? interval : store.state.config.DAYS_INTERVAL
        return moment().add(interval, 'd').format('YYYY-MM-DD')
    }
    static daybefore(date) {
        return moment(date).add(-1, 'd').format('YYYY-MM-DD')
    }
    static nextday(date) {
        return moment(date).add(1, 'd').format('YYYY-MM-DD')
    }
    static yesterday() {
        return moment().add(-1, 'd').format('YYYY-MM-DD')
    }
    static yesterdayPlusInterval(interval = null) {
        interval = interval !== null || store.state.config.DAYS_INTERVAL
        return moment().add(interval - 1, 'd').format('YYYY-MM-DD')
    }

    /**
     * Get a relative date based on the current selection.
     * @param integer relativeDate An integer represeting the number of days
     *                             to count from current selection.
     * @param string start The date to start from, in format YYYY-MM-DD
     * @return string
     *
     * @TODO This method is pretty much the same as this.getEndDate()... Remove this one?
     */
    static getRelativeDate(relativeDate, start) {
        return moment(start, 'YYYY-MM-DD').add(relativeDate, 'day').format('YYYY-MM-DD')
    }

    /**
     * Get the "end date", based on given date and a default interval, if no
     * custom interval is passed.
     * @param string date The date to calculate from, ex '2017-11-04'.
     * @param string format (optional)
     * @param integer interval (optional) The number of days to count from "date".
     */
    static getEndDate(date, format = 'YYYY-MM-DD', interval = null) {
        interval = interval !== null ? interval : store.state.config.DAYS_INTERVAL
        return moment(date).add(interval, 'd').format(format)
    }

    /**
     * Get a formatted offset, based on passed timestamp.
     * @param integer offset A timestamp, negative or positive.
     * @return array ["sign", "offset converted to HH:mm"]
     */
    static getOffsetFormatted(offset) {
        const output = []
        output.push(offset < 0 ? '-' : '+')
        let offsetAbs = Math.abs(offset)
        output.push(moment.unix(offsetAbs).utc().format('YYYY-MM-DD HH:mm').split(' ')[1])
        return output
    }

    /**
     * Check if "end" is after or equal to "start".
     * @param string start A date string.
     * @param string end A date string.
     * @return boolean
     */
    static isAfter(start, end, format = 'YYYY-MM-DD') {
        return moment(end, format).diff(moment(start, format), 'm') >= 0
    }

    /**
     * Convert the passed minutes to HH:mm.
     * @param integer minutes
     * @return string
     */
    static convertToHour(minutes) {
        let hour = (minutes / 60)
        let minute = Math.round(60 * (hour % 1))
        hour = parseInt(hour)
        hour = (hour + '').padStart(2, '0')
        minute = (minute + '').padStart(2, '0')
        return `${hour}h${minute}`
    }

}

export default Datetime
