/**
 * This is a base mixin/component used by FeedEdit and ProductionEdit.
 */

import {app} from 'app'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'
import store from 'store'
import Item from 'components/common/item/Item'
import Signoff from 'components/common/signoff/Signoff'

export default {
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            formData: this.defaultData(),
            loaded: false,
            submitted: false,
            now: null,
            insertForm: {
                show: false,
                loading: false,
                selection: null
            },

            // We "cache" the sport id here in order to avoid multiple loops
            // in this.entityItemIsVisible(), when checking if a sport should be
            // visible or not, agains the feed/production league. This is necessary
            // since sport id for feed/production is not stored in the database,
            // but is retrieved based on the stored league id.
            locationId: null,
            sportId: null,
            channelId: null,
            runTypeId: null,
            defaultHouseNumberKeys: {},
            defaultHouseNumbers: []
        }
    },
    beforeCreate() {
        if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
            this.defaultHouseNumberKeys = {}
            this.defaultHouseNumbers = []

            this.$store.state.system.countries.forEach(c => {
                this.defaultHouseNumberKeys[c.code] = ''
                this.defaultHouseNumbers.push(c.code)
            })
        }
    },
    computed: {
        setting() {
            return this.$settings.get[this.child.settings]
        },
        equipmentSetting() {
            return this.$settings.get.equipment
        },
        countries() {
            return this.$store.state.system.countries.length > 0 ? this.$store.state.system.countries : null
        },
        hasOtherCountries() {
            return this.countries.findIndex(v => v.group > 0) > -1
        },
        isFeedClone(){
          return (typeof this.$route.query.feed !== 'undefined') && !isNaN(this.$route.query.clone)
        },
        isClone() {
            return (typeof this.$route.query.clone !== 'undefined') && !isNaN(this.$route.query.clone)
        },
        isTemplatePage() {
            return (typeof this.$route.path !== 'undefined' && this.$route.path.indexOf('template') > -1);
        },
        isTemplateBased() {
            return (typeof this.$route.query.template !== 'undefined') && !isNaN(this.$route.query.template)
        },
        isFeedTemplateBased() {
            return (typeof this.$route.query.feedTemplate !== 'undefined') && !isNaN(this.$route.query.feedTemplate)
        },
        isCreatePage() {
          return window.location.pathname.indexOf('create') > -1
        },
        isEditForm() {
            if (this.$route.query && this.isClone) return true
            return this.$route.name.indexOf('.edit') > -1
        },
        isPlacholdersIncorrect() {
            return this.formData.placeholders.isPlacholdersIncorrect ? this.formData.placeholders.isPlacholdersIncorrect : false
        },
        equipmentList() {
            return store.state.data.equipment
        },
        isFormValid() {
            // hate to do that but, need it WN.
            if (['production.create', 'production.edit','edit.create', 'edit.edit', 'eng.create', 'eng.edit'].indexOf(this.$route.name) > -1) {
                if (!this.formData.productionStart.value) return false
                if (!this.formData.productionEnd.value) return false
                if (!this.formData.productionLeader.value) return false
            }
            return this.formFields && Object.keys(this.formFields).every(k => {
                // When validation is reset, valid & invalid flag is set to null, since we know that the field is valid
                // when coming from backend we can skip revalidating. When we upgrade vee-validate, we can replace this
                // logic with silent validation.
                return this.formFields[k].valid || (this.formFields[k].valid === null && this.formFields[k].invalid === null)
            })
        },
        getLeagueCode() {
            if (this.leagueEntity){
                const league = this.leagueEntity.getItem(this.formData.league.value)
                if (league) return league.text
            }            
            return null
        },
        hasHistory() {
            return this.isEditForm && this[this.child.item] && this[this.child.item].get.history && this[this.child.item].get.history.length > 0
        },
        availableUsers() {
            return this.$store.state.user.users
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
        getTitle() {
            return this[this.child.item] && this[this.child.item].get && this[this.child.item].get.title ? this[this.child.item].get.title : ''
        },
        getPrintTitle() {
            if (!this[this.child.item]) return
            let start = moment(this[this.child.item].get[`${this.child.item}Start`])
            let end = moment(this[this.child.item].get[`${this.child.item}End`])
            return {
                title: this[this.child.item].get.title,
                start: start.format('HH:mm'),
                end: end.format('HH:mm')
            }
        },
        /**
         * Returns strongly formatted fix part of title for placholder in Fork
         * @returns 
         */
         getDefaultForkTitle() {
            let leagueAbbr = ''
            if(this.formData.league.value && this.leagueEntity && this.leagueEntity.get.items && this.leagueEntity.get.items.length > 0) {
                let league = _.find(this.leagueEntity.get.items, i => i.id == this.formData.league.value)
                if(league) {
                    //TO DO: ask what means code and replace shortlabel to text if code means abbreviation
                    leagueAbbr = league.shortlabel ? league.shortlabel + ': ' : 'No league abbr: '
                }
            }

            let code, forkName = ''
            let eventDate = ''
            if(this.child.item === 'production') {
                if(this.formData.channel.value && this.channelEntity && this.channelEntity.get.items && this.channelEntity.get.items.length > 0) {
                    let channel = _.find(this.channelEntity.get.items, i => i.id == this.formData.channel.value)
                    if(channel) {
                        code = channel.text ? channel.text + ': ' : 'No channel country: '
                        forkName = channel.shortlabel ? channel.shortlabel + ': ' : null
                        if (forkName) {
                            code = forkName + code;
                        }
                    }
                }

                eventDate = this.formData.productionStart.value ? moment(this.formData.productionStart.value).format('YYYYMMDD') : ''
            }
            else {
                if(this.formData.contentType.value && this.contentTypeEntity && this.contentTypeEntity.get.items && this.contentTypeEntity.get.items.length > 0) {
                    let contentType = _.find(this.contentTypeEntity.get.items, i => i.id == this.formData.contentType.value)
                    if(contentType) {
                        code = contentType.text ? contentType.text + ': ' : 'No CT country: '
                    }
                }

                eventDate = this.formData.feedStart.value ? moment(this.formData.feedStart.value).format('YYYYMMDD') : ''
            }
            

            let title = this.formData.title.value ? this.formData.title.value + ' ' : 'No title '
            

            return code + leagueAbbr + title + eventDate
        },
        getApplyTemplateApiUrl() {
            return 'api/' + this.child.item + '/apply-template'
        },
    },

    methods: {

        /**
         * Set the now property to the current timestamp.
         */
        setNow() {
            this.now = moment()
        },

        /**
         * Set the form as loaded.
         */
        setLoaded(loaded = true) {
            if (loaded) {
                this.setFormValues()

                // "Delay" before setting loaded to true.
                Vue.nextTick(() => {
                    this.loaded = true
                })

            }
            else {
                this.loaded = false
            }
        },
        /**
         * Get a new class instance, based the child component.
         * @param mixed opts (optional) The parameters that the new class instance should
         *                   be created with.
         * @return class
         */
        getNewClass(opts = null) {
            switch (this.child.item) {
                case 'feed':
                    return new Feed(opts)
                case 'production':
                    return new Production(opts)
                case 'productionTemplate':
                    return new ProductionTemplate(opts)
            }
        },

        /**
         * Delete a given feed/production from API by its id.
         * @param integer id The feed id.
         */
        deleteItem(id) {
            const context = this
            const itemClass = context.getNewClass(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            context.$alert.set(`The ${context.child.item} was deleted!`)
                            //if we are in the NewTab just, close the tab or, redirect.
                            if (context.$route.query.newBar) {
                                return context.closeNewTab()
                            }
                            else {
                                console.log('redirecting')
                                return context.redirect(true)
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the ${context.child.item}!`)
                        })
                })
        },
        getCloneLink(){
            const link = {
                // name: 'production.create',
                query: {
                    //destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            if (this.$route.query.newBar) {
                link.query.newBar = this.$route.query.newBar
            }

            if (typeof this.routeItem !== 'undefined') {
                link.name = `${this.routeItem}.create`
            } else if (this.isEditProduction) {
                link.name = 'edit.create'
            }
            else if (this.isEngProduction) {
                link.name = 'eng.create'
            }
            else {
                link.name = 'production.create'
            }
            return link;
        },
        getCloneLinkFeed(){
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }

            link.name = 'production.create'

            return link;
        },
        /**
         * Redirect the user to production creation, cloning the passed
         */
        cloneProduction(id) {
            const production = new Production(id)
            const destination = this.getCloneLink()
            destination.query.clone = production.get.id
            destination.query.insertData = 1
            this.$router.push(destination)
            if (this.isEngProduction || this.isEditProduction) {
                Vue.nextTick(() => {
                  this.$router.go()
                })
            }
        },
        /**
         * Redirect the user from Feed to Production Cloning
         * WN
         */
        cloneProductionFromFeed(id) {
            const context = this
            console.log("production cloning");
            swal({
                title: 'Create from Template?',
                text: `You can create from Template by clicking yes or you can just create normally with Feed details `,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
                .then(function () {
                    // open from Template
                    context.openTemplateForm(id)

                }, function (dismiss) {
                    // open Normally
                    const feed = new Feed(id)
                    const destination = context.getCloneLink()
                    destination.query.clone = feed.get.id
                    destination.query.feed = 1
                    destination.query.insertData = 1
                    context.$router.push(destination)
                })
                .catch(() => {

                })


        },
        /**
         * We need to use template list from Feeds as well.
         */
        getCreateLink(id) {
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            if (this.isEditProductions) {
                link.name = 'edit.create'
            }
            else if (this.isEngProductions) {
                link.name = 'eng.create'
            }
            else {
                link.name = 'production.create'
            }

            if (this.templateForm.show) {
                link.query.template = this.templateForm.selection
                link.query.feedTemplate = id
            }
            else if (this.isEditProductions && this.$settings.get.editTemplate) {
                link.query.template = this.$settings.get.editTemplate
            }
            else if (this.isEngProductions && this.$settings.get.engTemplate) {
                link.query.template = this.$settings.get.engTemplate
            }

            return link
        },
        openTemplateForm(id) {
            this.$loader.add('createFromTemplateLoading')
            this.templateForm.show = true
            this.templateForm.id = id
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id})
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Production Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },

        getTemplateTitleToFormList(template) {
            const label = []
            label.push(template.title)
            label.push(`(${template.id})`)
            if (template.data && template.data.league) {
                const league = this.leagueEntity.getItem(template.data.league)
                const sport = this.sportEntity.getItem(league.reference)
                label.push(`(${sport.label}, ${league.label})`)
            }
            return label.join(' ')
        },
        getProductionsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.isEditProductions && (item.data.runType != this.setting.edit_entityitem_id)) {
                        return false
                    }
                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },
        /**
         * Redirect the user. This is used when a item is saved, to determine
         * which page the user should be sent to after a item is saved.
         * @param boolean open Tell the method that a feed/production is going
         *                     yo be opened.
         * @param integer id The id of the item that is going to be opened.
         * @return promise
         */
        redirect(open = false, id = null) {
            let location = {}
            if (open && id) {

                // @todo Until each component use routeItem we need to implement some special conditions.
                if (typeof this.routeItem !== 'undefined') {
                    location.name = `${this.routeItem}.edit`
                } else if (this.isEditProduction) {
                    location.name = 'edit.edit'
                }
                else if (this.isEngProduction) {
                    location.name = 'eng.edit'
                }
                else if(this.child.item == 'productionTemplate' && this.child.settings == 'feeds') {
                    location.name =  'feedTemplate.edit'
                }
                else {
                    location.name = `${this.child.item}.edit`
                }

                location.params = {id: id}
                location.query = Object.assign({}, this.$route.query)

                // Do not include "template" in location to redirect to.
                if (location.query.template) {
                    delete location.query.template
                }

                // Do not include "clone" in location to redirect to.
                if (location.query.clone) {
                    delete location.query.clone
                }
                // Do not include 'feed and insertData' in location to redirect to also WN
                if (location.query.feed) {
                    delete location.query.feed
                    delete location.query.insertData
                }

                // If the page is redirected to "edit route", then we should set
                // the form values once more, after the DOM has been updated once.
                Vue.nextTick(() => {
                    this.setFormValues()
                    this.submitting = false
                })
            }
            else if (this.$route.query.destination) {
                location = this.getDestination()
            }
            else {
                // @todo Until each component use routeItems we need to implement some special conditions.
                if (typeof this.routeItems !== 'undefined') {
                    location.name = `${this.routeItems}.view`
                }
                if (this.isEditProduction) {
                    location.name = 'edit.view'
                    location.params = {view: 'overview'}
                }
                else if (this.isEngProduction) {
                    location.name = 'eng.view'
                    location.params = {view: 'overview'}
                } else {
                    location.name = `${this.child.items}.view`
                    location.params = {view: 'list'}
                }
            }
            return new Promise((resolve, reject) => {
                this.$router.push(location, () => {
                    resolve()
                }, () => {
                    reject()
                })

            })
        },

        /**
         * Get the destination to be used when redirecting after form save.
         * @param object
         */
        getDestination() {
            const context = this
            const output = {
                name: context.$route.query.destination ? context.$route.query.destination : context.child.items + '.view',
                params: {
                    view: context.$route.query.view ? context.$route.query.view : 'list'
                },
            }
            if (context.$route.query.from) {
                output.query = {
                    from: context.$route.query.from
                }
            }
            if (context.$route.query.to) output.query.to = context.$route.query.to

            // Cloning from inside of production and clone from feed & cancel click, redirection fix WN
            if (output.name == 'production.edit') {
                output.name = 'production.view'
                output.params.view = 'list'
            }
            if (output.name == 'feed.edit') {
                output.name = 'feeds.view'
                output.params.view = 'list'
            }
            if (context.$route.name == 'eng.edit') {
                output.name = 'eng.view'
                output.params.view = 'overview'
            }
            if (context.$route.name == 'edit.edit') {
                output.name = 'edit.view'
                output.params.view = 'overview'
            }
            // fix END WN
            return output
        },

        /**
         * Load the form.
         */
        loadForm() {
            const context = this
            let itemId = context.id
            if (context.isClone) {
                itemId = parseInt(context.$route.query.clone)
                context[context.child.item] = context.getNewClass(0)
            }
            else {
                context[context.child.item] = context.getNewClass(itemId)
                //if its cloning feed template get the id from the route
                if (this.isFeedTemplateBased) itemId = context.$route.query.feedTemplate
            }


            return new Promise((resolve, reject) => {

                // If the form is already loaded, then we don't need to reload all
                // the entities once more.
                if (context.loaded) {
                    resolve()
                    return
                }

                context.loadEntities(context.child.entities)
                    .then(response => {

                        if (context.$route.query.feed == 1 || context.isFeedTemplateBased) {
                            //console.log('I have accessed')
                            context.cloneFeed = new Feed(itemId)
                            context.cloneFeed.load(itemId)
                                .then(response => {
                                    //console.log(response.data, 'cloneFeed')
                                    resolve()
                                })
                        }

                        if (context.isEditForm) {
                            const params = {
                                crewConflict: true,
                                type: this.type,
                            }
                            //console.log('hmw2?', context.child, itemId, params)
                            context[context.child.item].load(itemId, params)
                                .then(response => {
                                    // Parse associations, if any.
                                    if (!response.data) {
                                        context.$alert.set(`ERROR: Production not found with this id: ${context.child.item} ${itemId}. Redirecting to Production list view.`, 'danger', 10)
                                        setTimeout( () => {
                                            context.redirect(false)
                                        }, 5000);
                                        reject()
                                        return;
                                    }
                                    if (response.data.associations) {
                                        const now = moment().unix()
                                        const associations = response.data.associations.slice(0)
                                        associations.forEach(item => {
                                            if (typeof item.elementType === 'undefined' && !item.elementType) {
                                                let field_one = Object.keys(item).find(v => v.indexOf('event_') > -1)
                                                let field_two = Object.keys(item).find(v => v.indexOf('Start') > -1)
                                                if (field_one) item.elementType = 'event'
                                                else if (field_two) {
                                                    let i = field_two.indexOf('Start')
                                                    item.elementType = field_two.substring(0, i).toLowerCase()
                                                }
                                            }
                                            Item.parse(item, item.elementType, now)
                                            context.getSignoffs(item)
                                        })
                                        context[context.child.item].set('associations', associations)
                                    }

                                    // If the current item is a clone of another, reset
                                    // some values.
                                    if (context.isClone) {
                                        context.setClonedValues(itemId)
                                    }

                                    resolve()

                                })
                                .catch(error => {
                                    context.$error.set(error, `It was not possible to load ${context.child.item} ${itemId}.`)
                                    //if (error && error.response && error.response.status && error.response.status === 404) context.redirect(false)
                                    reject()
                                })

                        }
                        else {
                            resolve()
                        }

                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the form dependencies.')
                        reject()
                    })

            })

        },

        /**
         * Load the passed entities from the API.
         * @param object entities
         * @return promise
         */
       loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        /**
         * Update the equipment list in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateEquipment(items, notify = true) {
            this[this.child.item].set('equipment', items)
            this.formData.equipment.value = this[this.child.item].get.equipment
            if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
        },

        /**
         * Update the fiber equipment list in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateFiberEquipment(items, notify = true) {
            this[this.child.item].set('fiber', items)
            this.formData.fiber.value = this[this.child.item].get.fiber
            if (notify) this.$alert.set('The fiber equipment list was updated', 'success', 3)
        },

        /**
         * Update the equipment list in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateFeedbacks(items, notify = true) {
            this[this.child.item].set('feedbacks', items)
            this.formData.feedbacks.value = this[this.child.item].get.feedbacks
            if (notify) this.$alert.set('The feedbacks list was updated', 'success', 3)
        },

        /**
         * Update local notes in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateLocalNotes(items) {
            //this[this.child.item].set('localNotes', items)
            this.formData.localNotes.value = items[0]//this[this.child.item].get.localNotes
            this.formData.localNotesChanged.value = items[1]
            //if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
        },
        updateCalendarNotes(items) {
            this.formData.comments.value = items[0]
            this.formData.calendarNotesChanged.value = items[1]
        },
        /**
         * Check id an EntityItem should be visible or not.
         * @param object item
         * @return boolean
         */
        entityItemIsVisible(item, field) {
            if ((field == 'sport') && this[this.child.item].get && this[this.child.item].get.league) {
                if (!this.sportId) {
                    const league = this.leagueEntity.getItem(this[this.child.item].get.league)
                    this.sportId = league.reference
                }
                return EntityItem.isVisible(item, this.sportId == item.id)
            }
            // Show channel & runType entity items belongs to selected country value. WN
            if (field == 'channel' || field == 'runType') {
                if (this.formData.country.value && (item.country == this.formData.country.value)) {
                    return EntityItem.isVisible(item, 'No', item.country == this.formData.country.value)
                }
            }
            return EntityItem.isVisible(item, 'No', this.formData[field].value == item.id)
        },

        getEntityItem(name, id) {
            const entityItem = this[name + 'Entity'].getItem(id)
            return entityItem ? entityItem : null
        },
        getEntityItemLabel(name, id) {
            const entityItem = this.getEntityItem(name, id)
            return entityItem ? entityItem.label : ''
        },
        getSportLabel(leagueId) {
            const league = this.getEntityItem('league', leagueId)
            if (league && league.reference) {
                const sport = this.sportEntity.getItem(league.reference)
                return sport ? sport.label : null
            }
        },

        getSignoffs(n) {
            let item = null
            if(!n.signoffs || n.signoffs.length === 0) {
                item = n
            }

            if(item) {
                Signoff.getByItemId(item.id, item.elementType)
                .then(response => {
                    if (response.status != 200) {
                        item.signed = null
                        return
                    }

                    let signedArr = response.data
                    item.signoffs = signedArr.slice()
                    item.status = Item.getStatus(item)
                })
                .catch(error => {
                    console.log(error);
                })
            }
        },
        
        /**
         * checks ingest entity and returns if this housenumber value marked
         * for export to FORK
         * @param {*} value //housenumber value
         * @param {*} country //housenumber country
         */
         isForFork(value, country) {
             // one letter only and rest integer is requested from FORK integration team ( should be applied to 'int' )
             const determineIngestOperators = (value) => {
                 if (!value) return 'blank'
                 let oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
                 if (oneLetterOrOnlyIntegers.test(value.trim())) return 'int'
                 return value;
             }

             const findIngestEntity = (val, country) => {
                 let ingestEntityItems = this.ingestEntity && this.ingestEntity.get && this.ingestEntity.get.items.length > 0 ? this.ingestEntity.get.items : null
                 return ingestEntityItems ? _.find(ingestEntityItems, i => i.country === country && (i.label === val || i.shortlabel === val || i.userfield2_value === val)) : null
             }

            let val = determineIngestOperators(value)
            let ing = findIngestEntity(val, country)
            return ing ? ing.description === 'yes' : false
        },

        /**
         * Removes local placeholder from the list
         * @param object item 
         */
         excludePlaceholder(id) {
            Lazy(this.formData.placeholders.value)
                .each(function(v, k) {
                    let index = _.findIndex(v.placeholders, i => i.id === id)
                    v.placeholders.splice(index, 1)
                    console.log(k)
                })

            //_.remove(this.formData.placeholders.value, i => !i.placeholders || i.placeholders.length === 0)
            //this.formData.placeholders.showPlaceholders = this.formData.placeholders.value && this.formData.placeholders.value.length > 0
        },
        /**
         * Removes placeholder in a copy placeholderForm if placeholder is local
         * @param {*} id 
         */
        excludePlaceholderWithDates(id) {
            const context = this
            let index = null
            Lazy(context.formData.placeholders.value)
                .each(function(v, k) {
                    let ind = _.find(v.placeholders, i => i.id === id)
                    if(ind !== undefined) {
                        index = ind
                        let formCountry = _.find(context.placeholderForm, i => i.country === ind.country)
                        let formPlaceholder = _.findIndex(formCountry.placeholders, i => i.id === id)
                        if(ind.local) {
                            formCountry.placeholders.splice(formPlaceholder, 1)
                        }
                        else {
                            formCountry.placeholders[formPlaceholder].action = 'delete'
                        }
                    }
                })

            if(!index) {
                Lazy(context.placeholderForm)
                    .each((v,k) => {
                        let ind = _.findIndex(v.placeholders, i => i.id === id)
                        if(ind > -1) {
                            v.placeholders.splice(ind, 1)
                        }
                    })
            }

        },
        /**
         * Adds new local placeholder to formData placeholders
         * @param {*} item 
         */
        addPlaceholder(item) {
            console.log('update placeholder')
            let currentCountry = _.find(this.formData.placeholders.value, i => i.country === item.country)
            currentCountry.placeholders.push(item)
            console.log(this.formData.placeholders.value)
        },
        /**
         * Adds new local placeholder to a copy placeholderForm
         * @param {*} item 
         */
        addPlaceholderWithDates(item) {
            console.log('update placeholder')
            let currentCountry = _.find(this.placeholderForm, i => i.country === item.country)
            currentCountry.placeholders.push(item)
            console.log(this.placeholderForm)
        },
        /**
         * Update parent component after non-local placeholders submited
         * @param {*} items 
         */
        updatePlaceholders(items) {
            this[this.child.item].set('placeholders', items)
            this.formData.placeholders.value = items
        },
        /**
         * Update parent component with array of local placeholders
         * and submit it through parent component
         * @param {*} items 
         */
        updatePlaceholdersWithDates(items) {
            this.formData.placeholders.value = _.cloneDeep(this.placeholderForm)
            this.placeholderForm = null
            this.showPlaceholderForm = false
            this.formData.placeholders.datesChangedAccepted = true
            this.submit(this.submitOpen, this.submitRedirect)

        },
        /**
         * openDefault is a parameter for ForkPlaceholders component
         * that responsible for open specific placeholder in modal
         * This function resets it
         * @param {*} id 
         */
        resetDefaultPlaceholder(id) {
            this.formData.placeholders.openDefault = 0
        },
        /**
         * When dates changed and system cannot move placeholder dates automatically
         * it opens a copy of placeholders in modal
         * After it saved or canceled it drops copy off
         * @param {*} items 
         */
        removeDefaultPlaceholders(items) {
            this.placeholderForm = null
            this.showPlaceholderForm = false
        },
        /**
         * Createing default placeholder if house number value match with send to fork flag
         */
         createLocalDefaultPlaceholder(k2) {
            const context = this
            let forkCountry =  _.find(context.formData.placeholders.value, i => i.country === k2)
            if(forkCountry === undefined) {
                forkCountry = {
                    country: k2,
                    placeholders: [],
                    local: true
                }
                let tempId = moment().unix()
                let fStart = ''
                let fEnd = ''
                let defaultStart = ''
                let defaultEnd = ''
                if(context.child.item === 'production') {
                    fStart = context.formData.productionStart.value ? context.formData.productionStart.value : ''
                    fStart = fStart ? new Date(fStart) : '';
                    fEnd = context.formData.productionEnd.value ? context.formData.productionEnd.value : ''
                    fEnd = fEnd ? new Date(fEnd) : '';
                    defaultStart = fStart ? (moment(fStart).add(-15, 'minutes')).format('YYYY-MM-DD HH:mm') : ''
                    defaultEnd = fEnd ? (moment(fEnd).add(30, 'minutes')).format('YYYY-MM-DD HH:mm') : ''
                }
                else {
                    fStart = context.formData.feedStart.value ? context.formData.feedStart.value : ''
                    fStart = fStart ? new Date(fStart) : '';
                    fEnd = context.formData.feedEnd.value ? context.formData.feedEnd.value : ''
                    fEnd = fEnd ? new Date(fEnd) : ''
                    defaultStart = fStart ? (moment(fStart)).format('YYYY-MM-DD HH:mm') : ''
                    defaultEnd = fEnd ? (moment(fEnd)).format('YYYY-MM-DD HH:mm') : ''
                }
                

                let newPlaceholder = {
                    id: tempId,
                    country: k2,
                    periodStart: defaultStart,
                    periodEnd: defaultEnd,
                    title: context.getDefaultForkTitle,
                    itemId: tempId,
                    itemType: context.child.item === 'production' ? 'production' : 'feed',
                    local: true,
                    action: 'create',
                    submit: false
                }
                forkCountry.placeholders.push(newPlaceholder)
                context.formData.placeholders.value.push(forkCountry)

                
                let differenceTime = fStart && fEnd ? fEnd.getTime() - fStart.getTime() : 0;
                differenceTime = Math.ceil(differenceTime / 3600000);
                if(differenceTime > 4) {
                    swal({
                        title: 'Time recording',
                        text: 'Recording is over 4 hours long. You may want to change it. Would you like to edit it?',
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            // TO DO: open modal window in forkplaceholder
                            console.log('open modal window in forkplaceholder')
                            context.formData.placeholders.openDefault = tempId
                            
                        }, function (dismiss) {

                            
                                swal({
                                    title: 'Send to Fork?',
                                    text: `Would you like to submit schedule ingest to Fork?`,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function () {
                                        newPlaceholder.submit = true
                                        console.log('submit = true')

                                    }, function (dismiss) {
                                        console.log('submit kept as false')
                                    })
                                    .catch(() => {
                                        // Close the tab if its clicked to "save & close the tab"
                                        console.log('Exception in send to fork')
                                    })
                            
                        })
                        .catch(() => {
                            // Close the tab if its clicked to "save & close the tab"
                            console.log('Exception in record over 4 hours')
                        })
                }
                else {
                    swal({
                        title: 'Send to Fork?',
                        text: `Would you like to submit schedule ingest to Fork?`,
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            newPlaceholder.submit = true
                            console.log('submit = true')

                        }, function (dismiss) {
                            console.log('submit kept as false')
                        })
                        .catch(() => {
                            // Close the tab if its clicked to "save & close the tab"
                            console.log('Exception in send to fork')
                        })
                }
            }
        },
        /**
         * Exclude local placeholders or change action status for non-local placeholders
         * @param {*} needToDelete 
         * @param {*} k2 
         */
        updatePlaceholderDeleteStatus(needToDelete, k2) {
            const context = this
            let isActionChanged = false
            let renewPlaceholders = null
            let forkCountry =  _.find(context.formData.placeholders.value, i => i.country === k2)
            let toExclude = []
            if(forkCountry && forkCountry.placeholders && forkCountry.placeholders.length > 0) {
                Lazy(forkCountry.placeholders)
                    .each((v, k) => {
                        if(v.local) {
                            //context.excludePlaceholder(v.id)
                            toExclude.push(v.id)
                        }
                        else {
                            v.action = needToDelete ? 'delete' : 'none'
                            isActionChanged = true
                        }
                    })

                if(toExclude.length > 0) {
                    Lazy(toExclude)
                        .each((v,k) => {
                            context.excludePlaceholder(v)
                        })
                }
                if(isActionChanged) {
                    renewPlaceholders = context.formData.placeholders.value.slice()
                    context.formData.placeholders.value = renewPlaceholders
                }
                
            }
        },

        updatePlaceholderCountry(forkCountry, newCountry) {
            const context = this
            let renewPlaceholders = null
             
            if(forkCountry.local) {
                forkCountry.country = newCountry
                Lazy(forkCountry.placeholders)
                .each((v, k) => {
                    v.country = newCountry
                })
            }
            else {
                forkCountry.country = newCountry
                Lazy(forkCountry.placeholders)
                .each((v, k) => {
                    v.country = newCountry
                    v.action = 'update'
                })

                renewPlaceholders = context.formData.placeholders.value.slice()
                context.formData.placeholders.value = renewPlaceholders
            }
        },

        updateHouseNumbers() {
            if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
                this.defaultHouseNumberKeys = {}
                this.defaultHouseNumbers = []
    
                this.$store.state.system.countries.forEach(c => {
                    this.defaultHouseNumberKeys[c.code] = ''
                    this.defaultHouseNumbers.push(c.code)
                })
            }
        },
        checkHouseNumberChange() {
            const context = this
            console.log('checking...', context)
            let hNumber = context[context.child.item].get.houseNumber
            //context.formData.isHouseNumberChanged.value = false
            let changed = false
            if (hNumber) {
                //json string
                if (isJsonSring(hNumber)) {
                    hNumber = JSON.parse(hNumber)
                    Lazy(context.formData.houseNumber.value)
                        .each(function (v2, k2) {
                            if (hNumber[k2] !== v2) changed = true
                        })
                } else {
                    let _channel = context.production.get.channel
                    if (_channel) {
                        context.channelEntity.get.items.forEach(v2 => {
                            if (_channel === v2.id) {
                                Lazy(context.formData.houseNumber.value)
                                    .each(function (v3, k3) {
                                        if (k3 === v2.country && v3 === hNumber) changed = false
                                        else changed = true
                                    })
                            }
                        })
                    }
                }
            }
            else {
                
                Lazy(context.formData.houseNumber.value)
                    .each(function (v2, k2) {
                        if (context.formData.houseNumber.value[k2] !== '') changed = true

                    })
            }

            if(context.formData.isHouseNumberChanged.value !== changed) context.formData.isHouseNumberChanged.value = changed

            if (!this.isTemplatePage) {
                context.handlerHouseNumberChange(context.formData.houseNumber.value)
            }
            
        },
        applyTemplateToItem(templateId) {
            let params = {}

            params[this.child.items] = [this.id]
            params['template'] = templateId
            
            return new Promise((resolve, reject) => {
                axios.post(this.getApplyTemplateApiUrl, params)
                .then(response => {
                    this.applyTemplateForm.show = false
                    // if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] == this.id) {
                    //     if(response.data.applied && response.data.applied.length > 0) {
                    //         Vue.set(this[this.child.item], JSON.parse(response.data.applied[0]));
                    //         this.redirect(true, this.id)
                    //     }
                        
                    // }
                    // else {
                    //     this.$error.set('Something went wromg with applying template.')
                    // }
                    resolve(response)
                })
                .catch(error => {
                    this.applyTemplateForm.show = false
                    //this.$error.set(error, 'Something went wromg with applying template.')
                    reject(error)
                })
            })
            
        },
        /**
         * This function, removes the empty object fields and gives as a clean result as a string.
         * @returns {string}
         * @param object
         */
        cleanObjectString(object = null) {
            if (object) {
                if (typeof object === 'object') {
                    if (object.length > 0) {
                        return JSON.stringify(Object.fromEntries(Object.entries(JSON.parse(object)).filter(([_, i]) => i && i !== '')));
                    }
                    return '{}';
                }
                if (object.indexOf('{"') > -1 || object.indexOf("{'") > -1) {
                    return JSON.stringify(Object.fromEntries(Object.entries(JSON.parse(object)).filter(([_, i]) => i && i !== '')));
                }
                return '{}';
            }
            return '{}';
        },
    }

}
