<script>
import {app} from 'app'
import Feedback from 'components/feedback/Feedback'
import BookLoader from 'components/common/loader/BookLoader'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookTable from 'components/common/BookTable'
import BookFilter from 'components/common/filter/BookFilter'
import BookModal from 'components/common/BookModal'
import Entity from 'components/entity/Entity'
//import User from 'components/user/User'
//import SocketMixin from 'components/common/item/SocketMixin'

export default {
    // mixins: [
    //     SocketMixin,
    //     //McrSocketMixin
    // ],
    components: {
        BookLoader,
        BookDateNavigation,
        BookTable,
        BookFilter,
        BookModal
    },
    socket: {
        'private:feedbacks': {
            // '.feedback.created': function(payload, context) {
            //     console.log(payload, 'lets create see', context)
            //     if (Array.isArray(payload.data)) {
            //         //app.$emit('creatingFeedback', true, true)
            //         app.$emit('creatingFeedback', payload.data)
            //         //context.updateFeedback(payload.data, 'create')
            //      } else {
            //         if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
            //         app.$emit('creatingFeedback', payload.data)
            //      }
            // },
            '.feedback.updated': function(payload, context) {
                console.log(payload, 'lets create see', context)
                if (Array.isArray(payload.data)) {
                    //app.$emit('creatingFeedback', true, true)
                    app.$emit('updatingFeedback', payload.data)
                    //context.updateFeedback(payload.data, 'create')
                 } else {
                    if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                    app.$emit('updatingFeedback', payload.data)
                 }
            }
        }
    },
    mounted() {
        this.setLoaded(false)

        const context = this
        this.$store.commit('remove', {key: 'feedback', id: 0})
        context.feedback = new Feedback()

        const issueTypeEntityId =  context.$settings.get.feedbacks.issuetypes_entity_id;
        context.issueTypesEntity = new Entity(issueTypeEntityId)

        const entity = new Entity()
        entity.all({ids: [issueTypeEntityId ].join(',')})
            .then(response => {
                context.loadData()
                    .then(response => {
                        //context.notifications = response.data
                        context.createFilters()
                        context.setLoaded()
                    })
                    .catch(error => {
                        if (error.response && error.response.status != 404) context.$error.set(error)
                        context.setLoaded()
                        context.filterReady = true
                        //this.noResult = true
                    })
            })
            .catch(err => {
                if (error.response && error.response.status != 404) context.$error.set(error)
                context.setLoaded()
                context.filterReady = true
            })

        // app.$on('creatingFeedback', (data) => {
        //     this.updateFeedback(data, 'create')
        // })

        app.$on('updatingFeedback', (data) => {
            this.updateFeedback(data, 'update')
        })
        
    },
    data() {
        return {
            loaded: false,
            feedback: null,
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                type: {
                    label: 'Type',
                    //selected: '',
                    items: [{id: 'Feedback', label: 'Feedback'}, 
                            {id: 'Feedback & Report', label: 'Feedback & Report'}, 
                            {id: 'Report', label: 'Report'}, 
                            {id: 'Has service ticket', label: 'Has service ticket'}, 
                            {id: 'Is followed up', label: 'Is followed up'},
                            {id: 'Not read by admin', label: 'Not read by admin'}]
                },
                itemType: {
                    label: 'Item type',
                    //selected: '',
                    items: [{id: 'feed', label: 'feed'}, {id: 'production', label: 'production'}]
                },
                author: {
                    label: 'Author',
                    //selected: '',
                    items: []//this.authors
                },
                // serviceTicket: {
                //     label: 'Service ticket',
                //     items: []
                // },
                country: {
                    label: 'Country',
                    items: []
                },
                affectedArea: {
                    label: 'Affected area',
                    items: []//this.filterAffectedAreas
                },
                issueType: {
                    label: 'Issue type',
                    items: []//this.filterIssueTypes
                },
                errorSource: {
                    label: 'Error source',
                    items: []//this.filterErrorRecources
                }
            },
            filterReady: false,
            filterAuthors: [],
            filterTypes: [],
            filterItemTypes: [],
            filterCountries: [],
            filterAffectedArea: [],
            filterIssueType: [],
            filterErrorSource: [],
            filterHasServiceTicket: false,
            filterIsFollowedUp: false,
            filterNotReadByAdmin: false,
            filterSearch: '',
            filterSearchChanged: false,
            selectedItem: null
        }
    },
    watch: {

        '$route': function(to, from) {
            if (!this.loaded || ((from.query.from == to.query.from) && (from.query.to == to.query.to))) {
                return
            }
            this.filterReady = false
            this.loadData()
                .then(response => {
                    //this.notifications = response.data
                    this.createFilters()
                    this.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status != 404) this.$error.set(error)
                    this.setLoaded()
                    this.filterReady = true
                    //this.noResult = true
                })
        },
    },
    computed: {
        tableData() {
            const context = this
            const output = {}
            if (!context.feedbacks || context.feedbacks.length == 0) {
                return output
            }

            // Build options.
            output.options = {
                actions: []
            }

            if (context.$ability.can('update_feedbacks_reports', 'Feedbacks & Reports')) {
                output.options.actions.push('edit')
            }
            if (context.$ability.can('delete_feedbacks_reports', 'Feedbacks & Reports')) {
                output.options.actions.push('delete')
            }

            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false
                },
                {
                    value: 'type',
                    label: 'Type',
                    sortable: true,
                    //searchable: false
                },
                {
                    value: 'country',
                    label: 'Country',
                    sortable: true
                },
                {
                    value: 'itemType',
                    label: 'Item type',
                    sortable: true
                },
                {
                    value: 'itemId',
                    label: 'Item id',
                    sortable: true
                },
                {
                    value: 'itemTitle',
                    label: 'Item title',
                    sortable: true
                },
                {
                    value: 'created',
                    label: 'Created',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'role',
                    label: 'Role',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'createdBy',
                    label: 'Author',
                    sortable: true,
                    //searchable: false
                },
                {
                    value: 'feedback',
                    label: 'Feedback',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'affectedArea',
                    label: 'Affected area',
                    visible: false
                },
                {
                    value: 'issueType',
                    label: 'Issue type',
                    visible: false
                },
                {
                    value: 'errorSource',
                    label: 'Error source',
                    visible: false
                },
                {
                    value: 'isCreateTicket',
                    label: 'Create ticket',
                    visible: false
                },
                {
                    value: 'ticketUrl',
                    label: 'Service ticket',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'isFollowUp',
                    label: 'Follow up',
                    visible: false
                },
                {
                    value: 'followupUrl',
                    label: 'Followed up',
                    sortable: true,
                    //searchable: true
                },
                {
                    value: 'readByAdmin',
                    label: 'Is read by admin',
                    sortable: true,
                    //searchable: true
                },
            ]

            output.rows = []
            context.feedbacks
            .forEach(feedback => {
                //let sender = _.find(context.users, u => u.id == notification.sender)
                //sender = sender ? `<a href="/user/${sender.id}">${sender.fullName}</a>` : ''
                let roles = []
                
                if(feedback.author && feedback.author.roles && feedback.author.roles.length > 0) {
                    feedback.author.roles.forEach(r => {
                        roles.push(context.labelUppercase(r.name))
                    })
                }

                let authorCountry = ''

                if(context.countries) {
                    let cnt = _.find(context.countries, i => i.id === feedback.author.country)
                    if(cnt) {
                        authorCountry = cnt.label
                    }
                }
                

                let feedbackIssueTypes = []
                let feedbackIssueTypesIds = []
                if(feedback.issueTypes && feedback.issueTypes.length > 0) {
                    feedback.issueTypes.forEach( i => {
                        feedbackIssueTypesIds.push(parseInt(i))
                        let label = context.issueTypes.length > 0 ? _.find(context.issueTypes, t => t.id === parseInt(i)) : undefined
                        if(label) {
                            feedbackIssueTypes.push(label.label)
                        }
                    })
                }
                

                const row = {
                    id: {
                        value: feedback.id,
                    },
                    type:{
                        value: feedback.type,
                    },
                    country: {
                        rawValue: feedback.author.country,
                        value: authorCountry
                    },
                    itemType:{
                        value: feedback.feedbackItemType,
                    },
                    itemId:{
                        rawValue: feedback.feedbackItemId,
                        value: `<a href="/${feedback.feedbackItemType}/${feedback.feedbackItemId}" target="_blank">${feedback.feedbackItemId}</a>`,
                    },
                    itemTitle: {
                        value: context.getItemTitle(feedback.feedbackItem),
                        classes: ['book-overview-item-title','book-overview-item-title-date']
                    },
                    created: {
                        value: feedback.created,
                        classes: ['book-overview-item-title','book-overview-item-title-date']
                    },
                    role: {
                        value: roles.join(', ')
                    },
                    createdBy: {
                        rawValue: feedback.author.id,
                        value: `<a href="/user/${feedback.author.id}">${feedback.author.fullName}</a>`
                    },
                    feedback: {
                        value: feedback.feedback,
                        classes: ['book-overview-item-title', 'book-overview-item-title-message']
                    },
                    affectedArea: {
                        rawValue: feedback.affectedArea ? feedback.affectedArea : '',
                        value: feedback.affectedArea ? context.labelUppercase(feedback.affectedArea) : ''
                    },
                    issueType: {
                        rawValue: feedbackIssueTypesIds,
                        value: feedbackIssueTypes.join(', ')
                    },
                    errorSource: {
                        rawValue: feedback.errorSource ? feedback.errorSource : [],
                        value: feedback.errorSource ? feedback.errorSource : []
                    },
                    isCreateTicket: {
                        value: feedback.isCreateTicket
                    },
                    ticketUrl: {
                        value: feedback.ticketUrl ? `<a href="${feedback.ticketUrl}" target="_blank">${feedback.ticketUrl.substring(feedback.ticketUrl.lastIndexOf('/') + 1)}</a>` : ''
                    },
                    isFollowUp: {
                        value: feedback.isFollowUp
                    },
                    followupUrl: {
                        value: feedback.followupUrl ? `<a href="${feedback.followupUrl}" target="_blank">${feedback.followupUrl.substring(feedback.followupUrl.lastIndexOf('/') + 1)}</a>` : ''
                    },
                    readByAdmin: {
                        rawValue: feedback.readByAdmin,
                        value: feedback.readByAdmin ? 'Yes' : 'No'
                    }
                    
                }

                output.rows.push(row)
            })
            return output;
        },
        tableDataFiltered(){
            const context = this
            if(context.filterAuthors.length > 0 || context.filterTypes.length > 0 || context.filterCountries.length > 0
               || context.filterItemTypes.length > 0 || context.filterAffectedArea.length > 0
               || context.filterIssueType.length > 0 || context.filterErrorSource.length > 0
               || context.filterHasServiceTicket ||context.filterNotReadByAdmin
               || context.filterIsFollowedUp || context.filterSearchChanged) {
                let rows = _.filter(context.tableData.rows, r => {
                    let typeInList = context.filterTypes.length > 0 ?_.includes(context.filterTypes, r.type.value) : true
                    let anyRecipientInList = context.filterAuthors.length > 0 ? _.includes(context.filterAuthors, r.createdBy.rawValue) : true
                    let itemTypeInList = context.filterItemTypes.length > 0 ? _.includes(context.filterItemTypes, r.itemType.value) : true
                    let countryInList = context.filterCountries.length > 0 ? _.includes(context.filterCountries, r.country.rawValue) : true
                    let affectedAreaInList = context.filterAffectedArea.length > 0 ? _.includes(context.filterAffectedArea, r.affectedArea.rawValue) : true
                    let issueTypeInList = context.filterIssueType.length > 0 ? (_.intersection(context.filterIssueType, r.issueType.rawValue)).length > 0 : true
                    let errorSourceInList = context.filterErrorSource.length > 0 ? (_.intersection(context.filterErrorSource, r.errorSource.rawValue)).length > 0 : true
                    let hasServiceTicket = context.filterHasServiceTicket ? r.isCreateTicket.value : true
                    let hasFollowupTicket = context.filterIsFollowedUp ? r.isFollowUp.value : true
                    let notReadByAdmin = context.filterNotReadByAdmin ? !r.readByAdmin.rawValue : true
                    let searchMatch = context.filterSearch !== '' ? (
                        (r.created.value && r.created.value.includes(context.filterSearch))
                        || (r.feedback.value && r.feedback.value.includes(context.filterSearch))
                        || (r.role.value && r.role.value.includes(context.filterSearch))
                        || (r.ticketUrl.value && r.ticketUrl.value.includes(context.filterSearch))
                        || (r.itemTitle.value && r.itemTitle.value.includes(context.filterSearch))) : true
                    return typeInList && itemTypeInList && anyRecipientInList && countryInList && affectedAreaInList && issueTypeInList
                           && errorSourceInList && hasServiceTicket && hasFollowupTicket && notReadByAdmin && searchMatch
                })
                let cloneTableData = Object.assign({}, context.tableData)
                cloneTableData.rows = rows
                context.filterSearchChanged = false
                return cloneTableData
            }
            else {
                context.filterSearchChanged = false
                return context.tableData
            }
        },
        users() {
            return this.$store.state.user.users
        },
        feedbacks() {
            return this.$store.state.data.feedback//this.feedback.get
        },
        issueTypes() {
            return this.issueTypesEntity && this.issueTypesEntity.get && this.issueTypesEntity.get.items && this.issueTypesEntity.get.items.length > 0 ? this.issueTypesEntity.get.items : []
        },
        filterAffectedAreas() {
            let areas = this.issueTypesEntity.get.userfield1_values ? this.issueTypesEntity.get.userfield1_values.split(',') : []
            const context = this
            let filters = []
            areas.forEach(a => {
                filters.push({
                    id: a,
                    label: context.labelUppercase(a)
                })
            })

            return filters
        },
        filterIssueTypes() {
            const context = this
            let filters = []
            context.issueTypes.forEach(i => {
                filters.push({
                    id: i.id,
                    label: i.label
                })
            })

            return filters
        },
        filterErrorRecources() {
            let resources = this.issueTypesEntity.get.userfield2_values ? this.issueTypesEntity.get.userfield2_values.split(',') : []
            const context = this
            let filters = []
            resources.forEach(a => {
                filters.push({
                    id: a,
                    label: context.labelUppercase(a)
                })
            })

            return filters
        },
        countries() {
            let filters = []
            const _uCountry = app.$data.user.get.country
            if(Object.keys(this.$store.state.system.countries).length > 0) {
                this.$store.state.system.countries.forEach(c => {
                    filters.push({
                      id: c.code,
                      label: c.label,
                      selected: c.code == _uCountry
                    })
                })
            }

            return filters
        },
        authors() {
            const context = this
            let authors = []

            if(context.feedbacks && context.feedbacks.length > 0) {
                let extractAllAuthors = []
                context.feedbacks.forEach(n => {
                    extractAllAuthors.push({
                        id: n.author.id,
                        label: n.author.fullName
                    })
                })
                authors = _.uniqBy(extractAllAuthors, 'id')
            }

            return authors.length > 0 ? _.sortBy(authors, ['label']) :authors
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this.countFilteredLogs
            filterInfo.total = this.countLogs
            return filterInfo
        },
        countFilteredLogs() {
            if(!this.loaded || !this.tableDataFiltered || !this.tableDataFiltered.rows) return 0
            return this.tableDataFiltered.rows.length
        },
        countLogs() {
            if(!this.loaded || !this.feedbacks) return 0
            return this.feedbacks.length
        }
    },
    methods: {
        loadData() {
            return new Promise((resolve, reject) => {
                this.setLoaded(false)
                this.filterReady = false

                // Build request params.
                const params = {
                    from: this.$route.query.from,
                    to: this.$route.query.to
                }

                if(!this.$ability.can('view_all_feedbacks_reports', 'Feedbacks & Reports')) {
                    params.country = app.$data.user.get.country
                }
                
                
                this.feedback.all(params, true)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setLoaded(loaded = true) {
            Vue.nextTick(() => {
                this.loaded = loaded
                if (loaded) {
                    Vue.nextTick(() => {
                        //this.updateItemFlags()
                    })
                }
            })
        },
        showNotification(row) {
            this.selectedItem = row
            //this.selectedItem.recipient.displayValue = row.recipient.displayValue.split(',')
            console.log(row);
        },
        createFilters() {
            //this.availableFilters.sender.items = this.senders
            this.availableFilters.author.items = this.authors
            this.availableFilters.country.items = this.countries
            let defaultCountry = _.find(this.countries, i => i.selected)
            if(defaultCountry) {
                this.filterCountries.push(defaultCountry)
            }
            this.availableFilters.affectedArea.items = this.filterAffectedAreas
            this.availableFilters.issueType.items = this.filterIssueTypes
            this.availableFilters.errorSource.items = this.filterErrorRecources
            this.filterReady = true
        },
        filteredBy(filters) {
            console.log(filters);
            this.filterAuthors = filters.author
            if(filters.type && filters.type.length > 0) {
                let svTicket = _.find(filters.type, i => i === 'Has service ticket')
                let notRead = _.find(filters.type, i => i === 'Not read by admin')
                let followedUp = _.find(filters.type, i => i === 'Is followed up')
                if(svTicket || notRead || followedUp) {
                    this.filterTypes = filters.type.filter(f => f !== 'Has service ticket' && f !== 'Not read by admin' && f !== 'Is followed up')

                    if(svTicket) {
                        this.filterHasServiceTicket = true
                    }
                    else {
                        this.filterHasServiceTicket = false
                    }
                    if(followedUp) {
                        this.filterIsFollowedUp = true
                    }
                    else {
                        this.filterIsFollowedUp = false
                    }
                    if(notRead) {
                        this.filterNotReadByAdmin = true
                    }
                    else {
                        this.filterNotReadByAdmin = false
                    }
                   
                }
                else {
                    this.filterTypes = filters.type
                    this.filterHasServiceTicket = false
                    this.filterIsFollowedUp = false
                    this.filterNotReadByAdmin = false
                }
            }
            else {
                this.filterTypes = filters.type
                this.filterHasServiceTicket = false
                this.filterIsFollowedUp = false
                this.filterNotReadByAdmin = false
            }
            
            this.filterItemTypes = filters.itemType
            this.filterAffectedArea = filters.affectedArea
            this.filterCountries = filters.country
            this.filterIssueType = filters.issueType
            this.filterErrorSource = filters.errorSource
            //this.filterHasServiceTicket = filters.type && filters.type//filters.serviceTicket && filters.serviceTicket.length > 0
            this.filterSearch = filters.search.value
            this.filterSearchChanged = true
        },
        labelUppercase(lbl) {
            let lblArr = lbl.split(' ').map(l => l.charAt(0).toUpperCase() + l.slice(1));

            return lblArr.join(' ');
        },
        getEditLink(id) {
            const link = {
                name: 'feedback.edit',
                params: {
                    feedbackId: id,
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }
            
            return link;
        },
        openNewTab(link) {
            const context = this
            
            let routeData = context.$router.resolve(link);
            window.open(routeData.href, '_blank');
            
        },
        deleteFeedback(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this feedback?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function() {
                    context.$loader.add('bookFeedbackSubmit')
                    
                        const removingFeedback = new Feedback(id)
                        removingFeedback.delete()
                            .then(response => {
                                //context.removeFeedbak(id, false)
                                context.$alert.set('The item was removed.', 'success', 4)
                                context.$loader.remove('bookFeedbackSubmit')
                            })
                            .catch(error => {
                                context.$error.set(error, 'It was not possible to remove the feedback.')
                                context.$loader.remove('bookFeedbackSubmit')
                            })
                    
                })
        },
        removeFeedbak(id, notify) {
            const feedbacks = this.items.slice(0)
            let i = -1

            
            i = feedbacks.findIndex(v => v.id == id)
            

            if (i > -1) { feedbacks.splice(i, 1) }

            //app.$emit('changingEquipment', feedbacks, notify)
            //this.$emit('changingEquipment', equipment, notify)
            this.$emit('changedFeedbacks', feedbacks, notify)
        },
        updateFeedback(data, event){
            const context = this
            const currentFeedback = _.find(context.feedbacks, i => i.id === data.id)
            if(currentFeedback) {
                currentFeedback.type = data.isErrorReport ? ( data.feedback && data.feedback !== '' ? 'Feedback & Report' : 'Report' ) : 'Feedback'
                currentFeedback.feedback = data.feedback
                currentFeedback.isCreateTicket = data.isCreateTicket
                currentFeedback.ticketUrl = data.ticketUrl
                currentFeedback.isFollowUp = data.isFollowUp
                currentFeedback.followupUrl = data.followupUrl
                currentFeedback.affectedArea = data.affectedArea
                currentFeedback.issueTypes = data.issueTypes
                currentFeedback.errorSource = data.errorSource
                currentFeedback.readByAdmin = data.readByAdmin
            }
        },
        getItemTitle(item) {
          let label = ''
          if (item.sport?.label) {
            label += item.sport?.label + ' | '
          }
          if (item.league?.label) {
            label += item.league?.label + ' | '
          }

          label += item.title

          return label
        }
    }
}
</script>
<template>
<div>
    <div id="mcr" class="book-top-sticky-wrapper book-bottom-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <h1 class="page-title">Feedback & Reports</h1>
            <div class="secondary-menu">
                <book-date-navigation
                    :default-interval="0"
                    showYesterday>
                </book-date-navigation>
            </div>
            <book-filter
                    :loading="!filterReady"
                    :options="{save: true}"
                    :filters="availableFilters"
                    @filteredBy="filters => filteredBy(filters)"
                    :info="filterInfo">
            </book-filter>
        </div>
       
        <book-loader v-if="!loaded"></book-loader>
        <div v-else class="panel panel-info">
            <book-table
                :component="'feedbackList'"
                :items="tableDataFiltered"
                @edit="row => openNewTab(getEditLink(row.id.value))"
                @delete="row => deleteFeedback(row.id.value)"
                notFoundMessage="No feedback found">
            </book-table>
        </div>
        
        
        
    </div>
</div>
</template>
<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

    .book-overview-item-title.book-overview-item-title-recipient {
       
    }
    .book-overview-item-title.book-overview-item-title-subject {
        
    }
    .book-overview-item-title.book-overview-item-title-message {
        width: 20%;
    }
    .book-overview-item-title.book-overview-item-title-date {
        width: 10%;
    }
    .book-overview-item-title > span  {
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .book-table-actions {
        width: 25px !important;
    }
    
    .panel.panel-info {
        .panel-heading {
            .panel-header {
                display: inline-block;
            }
            .panel-date {
                float: right;
                display: inline-block;
            }
        }
    }
</style>