import store from 'store'

class Alert {

    /**
     * Set a message.
     * @param string message The message to set.
     * @param string type (optional) The messate type: (default is "success")
     *                               "success", "info", "warning" or "danger".
     * @param integer timer (optional) The number of seconds to display the
     *                                 message. Set it to "0" to show it permanently.
     *                                 Default is "2 seconds".
     */
    static set(message, type, timer) {
        if (type === undefined) {
            type = 'success'
        }

        const allowedTypes = store.getters['alert/getAllowedTypes']

        if (allowedTypes && !allowedTypes.includes(type)) {
            return
        }

        if (timer === undefined) {
            timer = 2000
        }
        else {
            // Convert the passed parameter into milliseconds
            timer = timer * 1000
        }
        let id = moment().unix()

        // Get the 6 last numbers in the timestamp
        id = id.toString().substr((id.toString().length - 6), id.toString().length);

        // Add a random number to the end of the id
        id = parseInt(id + '' + Math.floor(Math.random() * 100));

        store.commit('alert/addMessage', {id: id, message: message, type: type})
        let parent = this
        if (timer > 0) {
            setTimeout(function() {
                parent.remove({id, type})
            }, timer);
        }
    }

    /**
     * Remove a message based on its id.
     */
    static remove(message) {
        store.commit('alert/removeMessage', message)
    }

    /**
     * Clear all messages.
     */
    static clear() {
        store.commit('alert/clearMessages')
    }

}

export default Alert
