
const state = {
  loading: false,
  token: {},
  userData: {}
}

const getters = {
  isAuthenticated: state => {
    return (state.token != '') && (Object.keys(state.userData).length > 0)
  },
  getData: state => Object.keys(state.userData).length > 0 ? state.userData : null
}

const actions = {}

const mutations = {
    setToken(state, token) {
        state.token = token
        if (Object.keys(token).length > 0) {
            localStorage.setItem('token', JSON.stringify(token))
        }
        else {
            localStorage.removeItem('token')
        }
    },
}

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export {auth}
