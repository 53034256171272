<script>
import {app} from 'app'
import Datetime from 'components/common/helpers/Datetime'
import TaskItem from 'components/common/item/task/TaskItem'
import Production from 'components/production/Production'
import ProductionMixin from 'components/production/ProductionMixin'
import BookLoader from 'components/common/loader/BookLoader'
import BookFilter from 'components/common/filter/BookFilter'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookModal from 'components/common/BookModal'
import BookOverview from 'components/common/BookOverview'
import BookTable from 'components/common/BookTable'
import BookTimeline from 'components/common/BookTimeline'
import BookSidebarTask from 'components/production/ProductionTask/BookSidebarTask'
import TasksMixin from 'components/common/item/task/TasksMixin'

export default {
    mixins: [
        TasksMixin,
        ProductionMixin
    ],
    components: {
        BookLoader,
        BookFilter,
        BookDateNavigation,
        BookModal,
        BookOverview,
        BookTable,
        BookTimeline,
        BookSidebarTask
    },
    data() {
        return {
            child: {
                item: 'production',
                items: 'productions',
                entities: {
                    planTypeEntity: 'plantype_entity_id',
                    ingestEntity: 'ingest_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    workgroupsEntity: 'workgroups_entity_id',
                    activityTypesEntity: 'activitytypes_entity_id',
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                }
            },
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                country: {
                    label: 'Countries',
                    items: []
                },
                activityType: {
                    label: 'Activities',
                    items: [],
                    class: 'activityTypesEntity'
                },
                workgroups: {
                    label: 'Workgroups',
                    items: [],
                    class: 'workgroupsEntity'
                }
            },
            dateFrom: {
                time: ''
            },
            dateTo: {
                time: ''
            },
            isShowMegaView: false
        }
    },
    computed: {
        productions() {
            const context = this
            if (!context.loaded || !this.$store.state.data.production || !context.filterReady) return []
            return this.$store.state.data.production
                .filter(v => {
                    // On timeline view, only productions with start/end inside the current
                    // "from" day should be visible.
                    if (context.$route.params.view == 'timeline') {
                        if (v.startTime > context.timeline.endTimestamp || v.endTime < context.timeline.startTimestamp) {
                            return false
                        }
                    }
                    //console.log("computed productions")
                    return TaskItem.isFiltered(context.filters, v, {activityTypes: context.activityTypesEntity, workgroups: context.workgroupsEntity})
                })

        },
        productionsListData() {
            const context = this
            const output = {}
            if (!context.loaded) return output

            // Build options.
            output.options = {
                actions: ['edit', 'preview', 'clone', 'delete'],
                //ingestions: ['notrecord', 'repeat', 'share'],
                //ingestionActions: ['empty', 'true', 'false'],
            },

            // Build the columns.
            output.columns = [
                {
                    value: 'status',
                    label: 'Status',
                    sortable: false,
                },
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                },
                {
                    value: 'week',
                    label: 'Week',
                    sortable: true,
                    sortWith: ['date', 'time'],
                },
                {
                    value: 'day',
                    label: 'Day',
                    sortable: false,
                },
                {
                    value: 'date',
                    label: 'Date',
                    sortable: true,
                    sortWith: ['time'],
                },
                {
                    value: 'time',
                    label: 'Time',
                    sortable: false,
                },
                {
                    value: 'crew',
                    label: 'Crew',
                    sortable: true,
                },
                {
                    value: 'runType',
                    label: 'Activity Type',
                    sortable: true,
                },
                {
                    value: 'title',
                    label: 'Title',
                    sortable: true,
                    marker: true,
                },
            ]

            output.rows = []

            context.productions.forEach(v => {
                const runType = context.activityTypesEntity.getItem(v.runType)
                //const channel = context.channelEntity.getItem(v.channel)
                const row = {
                    rowOptions: {
                        updated: moment(v.updated).unix(),
                        new: moment(v.created).unix(),
                        type: 'production',
                        id: v.id,
                        //ingestions: v.ingestions,
                        country: runType.country ? runType.country : 'Global (all)',
                    },
                    status: {
                        value: '<span class="book-square"></span>',
                        classes: ['book-item-status-' + v.status]
                    },
                    id: {
                        value: v.id
                    },
                    week: {
                        value: Datetime.getWeekNumber(v.productionStart)
                    },
                    day: {
                        value: Datetime.getWeekDay(v.productionStart)
                    },
                    date: {
                        value: Datetime.getDate(v.productionStart)
                    },
                    time: {
                        value: Datetime.getTime(v.productionStart, v.productionEnd)
                    },
                    crew: {
                        value: v.crew ? v.crew.length : 0
                    },
                    runType: {
                        rawValue: runType ? runType.id : '',
                        value: runType ? runType.label : ''
                    },
                    title: {
                        value: context.getEventName(v)
                    },
                    // houseNumber: {
                    //     value: this.showHouseNumberField(v, 'value', runType.country),
                    //     rawValue: this.showHouseNumberField(v, 'rawValue', runType.country)
                    // },
                }
                output.rows.push(row)
            })

            return output
        },
        getGroupBy() {
            //if (this.isEditProductions) return 'editSuites'
            //else if (this.isEngProductions) return 'engItems'
            //if (this.isEditProductions || this.isEngProductions) return 'equipmentLabel'
            return 'workgroups'
        },

        /**
         * Get the data to group productions by, based on the current context.
         * Ex: On Edit section we need to group by Edit Suites and each Edit
         * Production can have multiple ones.
         * @return object
         */
        getGroupByData() {
            //if (!this.isEditProductions && !this.isEngProductions) {
                //return this.channelEntity
            //}
            //return {}
            //if (this.isEditProductions || this.isEngProductions) return this.equipmentListData
            return this.workgroupsEntity
        },
        getCreateLink() {
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            
            link.name = 'task.create'        
            
            return link
        },
        getCloneLink(){
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            
            link.name = 'task.create'
            
            return link;
        },
        getRequestParams() {
            const output = {
                from: this.$route.query.from,
                to: this.$route.query.to,
            }
            if (this.$route.params.view == 'overview' || this.$route.params.view == "timeline") {
                output.to = Datetime.getEndDate(output.from, 'YYYY-MM-DD', 6)
            }
            
            output.productionType = 'task'

            if (this.requestFields) {
                output.fields = this.requestFields.join(',')
            }
            return output
        },
        getEntitiesForSidebar() {
            const output = {}
            Lazy(this.child.entities)
                .each((v, k) => {
                    output[k] = this[k]
                })
            return output
        },
        requestFields() {
            switch (this.$route.params.view) {
                // overview -> plan, crew added, list -> plan added, timeline -> plan added WN (performance boost)
                case 'overview':
                    return [
                        'runType',
                        'signoffs',
                        'plan',
                        'crew',
                        'workgroupsList'
                    ]
                case 'list':
                    return [
                        'runType',
                        'crew',
                        'plan',
                        'ingestions',
                        'signoffs',
                        'workgroupsList',
                        'productionLeader'
                    ]
                case 'timeline':
                    return [
                        'runType',
                        'crew',
                        'signoffs',
                        'plan',
                        'workgroupsList'
                    ]
            }
            return null
        }
    },
    methods: {
        /**
         * Redirect the user to production creation, cloning the passed
         */
        cloneProduction(id) {
            const production = new Production(id)
            const destination = this.getCloneLink
            destination.query.clone = production.get.id
            destination.query.insertData = 1
            this.$router.push(destination)
            
        },
        triggerDisplayCrew: function(isDisplay){
            const context = this
            context.isShowMegaView = isDisplay;
            console.log(context.isShowMegaView);
        },
    }
}
</script>

<style src="../Productions/Productions.scss" lang="scss"></style>
<template>
    <div id="productions" class="book-top-sticky-wrapper book-bottom-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <div class="primary-menu">
                <div class="btn-group" role="group">
                    
                        <router-link :to="getCreateLink" class="btn btn-success" title="Create">
                            
                                <font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Create</span>
                            
                        </router-link>
                </div>
            </div>
            <div class="secondary-menu">
                <book-date-navigation :hideEndDate="$route.params.view != 'list'"></book-date-navigation>
            </div>
            <div class="secondary-menu">
                <div class="btn-group" role="group">
                    <router-link :to="{name: $route.name, params: {view: 'overview'}, query: $route.query}" class="btn btn-default" title="Overview"><font-awesome-icon icon="columns"/></router-link>
                    <router-link :to="{name: $route.name, params: {view: 'list'}, query: $route.query}" class="btn btn-default" title="List"><font-awesome-icon icon="bars"/></router-link>
                    <router-link :to="{name: $route.name, params: {view: 'timeline'}, query: $route.query}" class="btn btn-default" title="Timeline"><font-awesome-icon icon="list"/></router-link>
                </div>
            </div>
        </div>
        <book-filter
            :loading="!filterReady"
            :options="{save: true}"
            :filters="availableFilters"
            
            @filteredBy="filters => filteredBy(filters)"
            
            :info="filterInfo">
        </book-filter>

        <book-loader v-if="(!loaded && !noResult) || !filterReady"></book-loader>

        <div v-else class="panel panel-default book-content-with-sidebar" :class="{'book-sidebar-opened': loadingSelectedItem || selectedItem}">
            <book-overview
                v-if="$route.params.view == 'overview'"
                :items="productions"
                itemsType='production'
                notFoundMessage="<strong>No tasks were found</strong><br />Please, check your current filters, which can affect the presented result"
                :fields="{start: 'productionStart', end: 'productionEnd'}"
                :groupBy="getGroupBy"
                :groupByData="getGroupByData"
                :flagEntity="activityTypesEntity"
                :selected="selectedItem ? selectedItem.id : null"
                @open="item => oneClick(item, getEditLink(item.id))"
                class="book-content">
            </book-overview>
            <book-table
                v-if="$route.params.view == 'list'"
                :component="'productionTasks'"
                :items="productionsListData"
                itemsType='production'
                :selected="selectedItem ? selectedItem.id : null"
                notFoundMessage="<strong>No tasks were found</strong><br />Please, check your current filters, which can affect the presented result"
                @preview="item => selectItem(item.id.value)"
                @edit="item => openNewTab(getEditLink(item.id.value))"
                @clone="item => cloneProduction(item.id.value)"
                @delete="item => deleteItem(item.id.value)"
                
                class="book-content">
            </book-table>
            <book-timeline
                v-if="$route.params.view == 'timeline'"
                :items="productions"
                itemsType='production'
                :columns="{start: 'productionStart', end: 'productionEnd'}"
                :dataGroup="getGroupBy"
                :groupByData="getGroupByData"
                :selected="selectedItem ? selectedItem.id : null"
                :expandCrewMemembers="isShowMegaView"
                notFoundMessage="<strong>No tasks were found</strong><br />Please, check your current filters, which can affect the presented result"
                @open="item => oneClick(item, getEditLink(item.id))"
                class="book-content">
            </book-timeline>
            <book-sidebar-task
                :selectedItem="loadingSelectedItem || selectedItem"
                :loading="loadingSelectedItem"
                :actions="['close', 'edit', 'clone', 'delete']"
                :entities="getEntitiesForSidebar"
                @delete="item => deleteItem(item)"
                @clone="item => cloneProduction(item.id)"
                @close="selectedItem = null">
            </book-sidebar-task>
        </div>
    </div>
    
</template>