<script>
import BookEquipmentOverview from 'components/equipment/EquipmentOverview/BookEquipmentOverview'
import BookProductionsMenu from 'components/production/Productions/BookProductionsMenu'

export default {
    components: {
        BookEquipmentOverview,
        BookProductionsMenu,
    },

    data() {
        return {
            actions: [
                {
                    action: 'addToExisting',
                    label: 'Add to existing',
                }
            ]
        }
    }
}
</script>
<template>
    <book-equipment-overview :actions="actions">
        <template v-slot:secondaryMenu>
            <div class="btn-group" role="group">
                <router-link :to="{name: 'equipment.usage', query: $route.query}" class="btn btn-default" title="Equipment Usage"><font-awesome-icon icon="list"/></router-link>
                <router-link :to="{name: 'equipmentoverview', query: $route.query}" class="btn btn-default" title="Equipment Overview"><font-awesome-icon icon="calendar-week"/></router-link>
            </div>
        </template>
    </book-equipment-overview>
</template>