import store from 'store'

class Acl {

    // -------------------------------------------------------------------------
    // Roles.

    /**
     * Get all available roles.
     * @return promise
     */
    static getRoles() {
        return new Promise((resolve, reject) => {
            axios.get('api/role')
                .then(response => {
                    store.commit('user/setRoles', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Delete an user.
     * @return promise
     */
    static deleteRole(id) {
        return new Promise((resolve, reject) => {
            axios.delete('api/role/' + id)
                .then(response => {
                    sessionStorage.removeItem('users');
                    store.commit('user/removeRole', id)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }


    // -------------------------------------------------------------------------
    // Permissions.

    static getPermissions() {
        return new Promise((resolve, reject) => {
            axios.get('api/permission')
                .then(response => {
                    store.commit('user/setPermissions', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    static savePermissions() {
        return new Promise((resolve, reject) => {
            axios.post('api/permission', store.state.user.permissions)
                .then(response => {
                    sessionStorage.removeItem('users');
                    store.commit('user/setPermissions', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}

export default Acl
