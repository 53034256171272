<script>
import BookLoader from 'components/common/loader/BookLoader'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import Entity from 'components/entity/Entity'
import System from 'components/system/System'
import store from 'store'
import multiselect from 'vue-multiselect'
import Acl from 'components/user/Acl'

export default {
    components: {
        BookLoader,
        multiselect
    },
    mounted() {
        this.loadData()
            .then(response => {
                this.setFormValues()
                this.setLoaded()
            })
            .catch(error => {
                this.$error.set(error)
                this.setLoaded()
            })
    },
    data() {
        return {
            loaded: false,
            editableSettings: ['engTemplate', 'editTemplate', 'mcrFeedsEquipmentTypes', 'mcrProductionsEquipmentTypes', 'mcrContributionsEquipmentTypes'],
            formData: this.defaultData(),
            equipmentTypeEntity: null,
            items: {
                productionTemplates: null,
            }
        }
    },
    computed: {
        system() {
            return System
        },
        isFormClean() {
            return this.formFields && Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        },
        hasTemplates() {
            return this.getTemplates.length > 0
        },
        getTemplates() {
            const output = []
            Lazy(this.$store.state.data.productionTemplate)
                .each(v => {
                    output.push({
                        id: v.id,
                        label: `${v.title} (by ${v.user.name})`
                    })
                })
            return Lazy(output).sortBy(v => v.label, false).toArray()
        },
        getEquipmentTypes() {
            if (!this.equipmentTypeEntity) return []
            return this.equipmentTypeEntity.get.items.map(v => {
                return {
                    id: v.id,
                    label: v.label,
                }
            })
        },
        getRoles() {
            return this.$store.state.user.roles
        },
    },
    methods: {
        setLoaded(loaded = true) {
            Vue.nextTick(() => {
                this.loaded = loaded
            })
        },
        loadData() {
            return new Promise((resolve, reject) => {
                const dataToLoad = []

                // Productions templates.
                this.items.productionTemplates = new ProductionTemplate()
                dataToLoad.push(this.items.productionTemplates.all({global: 1}))

                // Entities.
                const entities = []
                const equipmentTypeEntityId = this.$settings.get.equipment.equipmenttype_entity_id
                this.equipmentTypeEntity = new Entity(equipmentTypeEntityId)
                entities.push(equipmentTypeEntityId)
                const entity = new Entity()
                dataToLoad.push(entity.all({ids: entities.join(',')}))

                // Roles.
                dataToLoad.push(Acl.getRoles())

                Promise.all(dataToLoad.map(p => {

                    // Return "undefined" for all rejected promises which allows
                    // us to handle the promise as resolved, even if no data was
                    // found.
                    return p.catch(() => undefined)

                }))
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })

            })
        },
        setFormValues() {
            Lazy(this.$settings.get)
                .filter((v, k) => this.editableSettings.indexOf(k) > -1)
                .each((v, k) => {
                    this.formData[k].value = v
                })
        },
        submit() {
            this.$loader.add('BookSettingsSubmit')
            Lazy(this.formData)
                .each((v, k) => {
                    this.$settings.set(k, v.value)
                })
            this.$settings.store()
                .then(response => {
                    this.$settings.update(response.data)
                    this.$alert.set('The settings were saved!', 'success', 3)
                    this.$loader.remove('BookSettingsSubmit')
                })
                .catch(error => {
                    this.$error.set(error)
                    this.$loader.remove('BookSettingsSubmit')
                })
        },
        defaultData() {
            return {
                engTemplate: {
                    value: ''
                },
                editTemplate: {
                    value: ''
                },
                mcrFeedsEquipmentTypes: {
                    value: []
                },
                mcrProductionsEquipmentTypes: {
                    value: []
                },
                mcrContributionsEquipmentTypes: {
                    value: []
                },
            }
        }
    }

}
</script>

<template>
<div id="settings" class="admin col-sm-10 col-lg-6 col-sm-offset-1 col-lg-offset-3 container book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
        <h1 class="page-title">System Settings</h1>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div v-else class="panel panel-info">
        <div class="panel-body">
            <legend>ENG Productions</legend>
            <fieldset>
                <div class="form-group">
                    <label class="control-label">Template</label>
                    <select class="form-control" name="eng_template" v-validate v-model="formData.engTemplate.value" :disabled="!hasTemplates">
                        <option value="" v-if="hasTemplates">None</option>
                        <option value="" v-else>No template is available</option>
                        <option v-for="template in getTemplates" :value="template.id">{{template.label}}</option>
                    </select>
                    <p class="help-block">
                        This is the template to be used when an user clicks on "Create > New", inside the ENG section.
                    </p>
                </div>
            </fieldset>
            <legend>Edit Productions</legend>
            <fieldset>
                <div class="form-group">
                    <label class="control-label">Template</label>
                    <select class="form-control" name="edit_template" v-validate v-model="formData.editTemplate.value" :disabled="!hasTemplates">
                        <option value="" v-if="hasTemplates">None</option>
                        <option value="" v-else>No template is available</option>
                        <option v-for="template in getTemplates" :value="template.id">{{template.label}}</option>
                    </select>
                    <p class="help-block">
                        This is the template to be used when an user clicks on "Create > New", inside the Edit section.
                    </p>
                </div>
            </fieldset>
            <legend>MCR View</legend>
            <fieldset>
                <div class="form-group">
                    <label class="control-label">Feeds</label>
                    <multiselect
                        :disabled="false"
                        v-validate
                        name="mcr_feeds_equipment_types"
                        v-model="formData.mcrFeedsEquipmentTypes.value"
                        track-by="id"
                        label="label"
                        placeholder=""
                        :max-height="200"
                        :options="getEquipmentTypes"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :hide-selected="true"
                        :show-labels="false">
                        <span slot="noResult">No equipment types found.</span>
                    </multiselect>
                    <p class="help-block">
                        Determine which equipment types that should be visible for <strong>Feeds</strong> on the MCR View.
                    </p>
                </div>
                <div class="form-group">
                    <label class="control-label">Productions</label>
                    <multiselect
                        :disabled="false"
                        v-validate
                        name="mcr_productions_equipment_types"
                        v-model="formData.mcrProductionsEquipmentTypes.value"
                        track-by="id"
                        label="label"
                        placeholder=""
                        :max-height="200"
                        :options="getEquipmentTypes"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :hide-selected="true"
                        :show-labels="false">
                        <span slot="noResult">No equipment types found.</span>
                    </multiselect>
                    <p class="help-block">
                        Determine which equipment types that should be visible for <strong>Productions</strong> on the MCR View.
                    </p>
                </div>
                <div class="form-group">
                    <label class="control-label">Contributions</label>
                    <multiselect
                        :disabled="false"
                        v-validate
                        name="mcr_contributions_equipment_types"
                        v-model="formData.mcrContributionsEquipmentTypes.value"
                        track-by="id"
                        label="label"
                        placeholder=""
                        :max-height="200"
                        :options="getEquipmentTypes"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :hide-selected="true"
                        :show-labels="false">
                        <span slot="noResult">No equipment types found.</span>
                    </multiselect>
                    <p class="help-block">
                        Determine which equipment types that should be visible for <strong>Contributions</strong> on the MCR View.
                    </p>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="book-form-actions book-bottom-sticky">
        <button class="btn btn-primary pull-right" @click="submit()" :disabled="isFormClean || $loader.has('BookSettingsSubmit')">Save</button>
    </div>
</div>
</template>
