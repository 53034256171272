// Add global/vendor styles.
import 'vue-multiselect/dist/vue-multiselect.min.css';
import "sweetalert2/dist/sweetalert2.css";
import './style/style.scss';

// -----------------------------------------------------------------------------
// Custom functions.
function gettok(s, n, c) {
    c = String.fromCharCode(c)
    let sa = s.split(c);
    if (!/-/.test(n)) {
        n = (n - 1);
        return sa[n] || 'NaN';
    } else {
        n = n.split('-')
        let o = [];
        let x = n[1] || s.length;
        for (let i = (n[0] - 1); i < x; i++) o.push(sa[i]);
        return (o.length > 0) ? o.join(c) : 'NaN';
    }
}
function isJsonString(str) {
    return str && str.indexOf('{"') > -1;
}
window.gettok = gettok
window.isJsonSring = isJsonString



/**
 * Deep freeze an object.
 *
 * This is useful when you need to enforce and object to be non-reactive by Vue.
 */
function deepFreeze(object) {
    // Retrieve the property names defined on object.
    const propNames = Reflect.ownKeys(object);

    // Freeze properties before freezing self.
    for (const name of propNames) {
        const value = object[name];

        if ((value && typeof value === "object") || typeof value === "function") {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
}

window.deepFreeze = deepFreeze

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
}

window.log = function(myVar, ref = false) {
    if (ref) console.log(myVar)
    else console.log(JSON.parse(JSON.stringify(myVar)))
}

/**
 * Maps data object into FormData object.
 *
 * @param formData
 * @param data
 * @param parentKey
 */
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === null ? '' : data;

        formData.append(parentKey, value);
    }
}

window.buildFormData = buildFormData

// -----------------------------------------------------------------------------
// Vue.

import Vue from 'vue'
window.Vue = Vue

// -----------------------------------------------------------------------------
// Portal Vue.
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// -----------------------------------------------------------------------------
// Vue Worker.

// import VueWorker from 'vue-worker'
// Vue.use(VueWorker)

// -----------------------------------------------------------------------------
// Axios.

import axios from 'axios'
window.axios = axios
import 'components/auth/interceptor'

// -----------------------------------------------------------------------------
// VueAffix.

// var Affix = require('vue-affix')
// Vue.use(Affix)

var BookAffix = require('components/common/affix/index')
Vue.use(BookAffix)

// -----------------------------------------------------------------------------
// SweetAlert2.

import swal from 'sweetalert2'
swal.setDefaults({
    buttonsStyling: false,
    cancelButtonClass: 'btn btn-danger btn-lg',
    confirmButtonClass: 'btn btn-primary btn-lg',
    showLoaderOnConfirm: true,
})
window.swal = swal

// -----------------------------------------------------------------------------
// VeeValidate.

import VeeValidate from 'vee-validate';
import 'validators'
const veeValidateConfig = {
    errorBagName: 'formErrors', // change if property conflicts.
    fieldsBagName: 'formFields',
    delay: 0,
    locale: 'en',
    dictionary: null,
    strict: true,
    enableAutoClasses: true,
    classNames: {
        touched: 'touched', // the control has been blurred
        untouched: 'untouched', // the control hasn't been blurred
        valid: 'valid', // model is valid
        invalid: 'invalid', // model is invalid
        pristine: 'pristine', // control has not been interacted with
        dirty: 'dirty' // control has been interacted with
    }
}
Vue.use(VeeValidate, veeValidateConfig)

// -----------------------------------------------------------------------------
// Abilities.

import { abilitiesPlugin } from '@casl/vue'
Vue.use(abilitiesPlugin)

// -----------------------------------------------------------------------------
// Router.

import router from 'router'
import 'components/auth/router'
import 'components/common/alert/router'

// -----------------------------------------------------------------------------
// Vuex.

import store from 'store'

// -----------------------------------------------------------------------------
// Custom directievs.

import directives from 'directives'

// -----------------------------------------------------------------------------
// Config.

import config from 'config/global'
store.commit('setConfig', config)

// -----------------------------------------------------------------------------
// Settings.

import settings from 'components/settings/Settings'
Object.defineProperty(Vue.prototype, '$settings', {value: settings})

// -----------------------------------------------------------------------------
// Loader.

import loader from 'components/common/loader/Loader'
Object.defineProperty(Vue.prototype, '$loader', {value: loader})

// -----------------------------------------------------------------------------
// ErrorHandler.
import errorHandler from 'components/system/ErrorHandler'
Object.defineProperty(Vue.prototype, '$error', {value: errorHandler})

// -----------------------------------------------------------------------------
// RouterTools.

import RouterTools from 'components/system/RouterTools'
Object.defineProperty(Vue.prototype, '$routerTools', {value: RouterTools})

// -----------------------------------------------------------------------------
// Alert.

import Alert from 'components/common/alert/Alert'
Object.defineProperty(Vue.prototype, '$alert', {value: Alert})

// -----------------------------------------------------------------------------
// Fontawesome.
import '@fortawesome/fontawesome-svg-core/styles.css';
import { library, config as fontConfig } from '@fortawesome/fontawesome-svg-core'
import { faAlignJustify, faArrowCircleUp, faAsterisk, faBan, faBars, faBell, faBroadcastTower, faCalendarAlt, faCheck, faChevronLeft, faChevronRight, faClock, faColumns, faComment, faComments, faCog, faCopy,
    faEnvelope, faEraser, faExclamationTriangle, faFileAlt, faFileImport, faFileCode, faFileExport, faFilter, faList, faLock, faMinus, faPen, faPencilAlt,
    faPlus, faPrint, faProjectDiagram, faSave, faSearch, faShare, faSignOutAlt, faSort, faTags, faTasks, faThList, faTimes, faTrash,
    faToggleOn, faUnlockAlt, faUser, faUserPlus, faUsers, faUserSecret, faCalendar, faPaperclip, faCalendarWeek}
    from '@fortawesome/free-solid-svg-icons'

fontConfig.autoAddCss = false

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAlignJustify, faArrowCircleUp, faAsterisk, faBan, faBars, faBell, faBroadcastTower, faCalendarAlt, faCheck, faChevronLeft, faChevronRight, faClock, faColumns, faComments, faComment, faCog, faCopy,
    faEnvelope, faEraser, faExclamationTriangle, faFileAlt, faFileImport, faFileCode, faFileExport, faFilter, faList, faLock, faMinus, faPen, faPencilAlt, 
    faPlus, faPrint, faProjectDiagram, faSave, faSearch, faShare, faSignOutAlt, faSort, faTags, faTasks, faThList, faTimes, faTrash, 
    faToggleOn, faUnlockAlt, faUser, faUserPlus, faUsers, faUserSecret, faCalendar, faPaperclip, faCalendarWeek);

Vue.component('font-awesome-icon', FontAwesomeIcon)



// -----------------------------------------------------------------------------
// Components and app.
import bookApp from 'components/app/BookApp'
const app = new Vue(Object.assign({
    router,
    store
}, bookApp))


export { app, router, store }
