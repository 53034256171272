<script>
/**
 * @file
 * Book Overview component.
 * Builds a grid like overview based on the passed parameters.
 */

import Datetime from 'components/common/helpers/Datetime'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import jq from 'jquery'
import BookModal from 'components/common/BookModal'

export default {
    components: {
        DynamicScroller,
        DynamicScrollerItem,
        BookModal,
    },
    data() {
        return {
            windowHeight: window.innerHeight,
            actionModal: false,
        }
    },
    props: {
        equipments: {
            default: () => {
                return []
            },
            type: Array
        },
        equipmentUsages: {
            default: () => {
                return []
            },
            type: Array
        },
        intervalStart: {
            default: '',
            type: String
        },
        intervalEnd: {
            default: '',
            type: String
        },
        actions: {
            default: () => {
                return []
            },
            type: Array
        },
    },
    onResize() {
        this.windowHeight = window.innerHeight
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })

        // 275 = Height for fixed top bar, affix, fixed bottom bar
        jq('.book-equipment-content').css('max-height', window.innerHeight - 275 + 'px')
    },
    watch: {
        windowHeight(newHeight, oldHeight) {
            // // 275 = Height for fixed top bar, affix, fixed bottom bar
            jq('.book-equipment-content').css('max-height', newHeight - 275 + 'px')
        },
        equipmentUsages() {
            // Whenever equipment usages changes, we apply a force update. This is slightly less efficient, but still
            // runs really fast. Calculating the size individually, doesn't seem to work that well when multiple items
            // gets updated at once.
            this.$refs.scroller.forceUpdate()
        }
    },
    computed: {
        /**
         * Group equipment usages by equipment and date.
         *
         * We end up with the following data structure:
         *
         * {
         *     "equipmentId_1": {
         *         "2022-12-02": [EquipmentUsage, EquipmentUsage],
         *         "2022-12-03": [EquipmentUsage, EquipmentUsage],
         *     },
         *     "equipmentId_2": ...
         * }
         *
         * This results in fast lookups O(1) when rendering the columns for each equipment.
         */
        equipmentUsageGroupByEquipmentAndDate() {
            const result = {}

            for (const equipmentUsage of this.equipmentUsages) {
                if (!result.hasOwnProperty(equipmentUsage.equipmentId)) {
                    result[equipmentUsage.equipmentId] = {}
                }
                if (!result[equipmentUsage.equipmentId].hasOwnProperty(equipmentUsage.periodStartDate)) {
                    result[equipmentUsage.equipmentId][equipmentUsage.periodStartDate] = []
                }
                result[equipmentUsage.equipmentId][equipmentUsage.periodStartDate].push(equipmentUsage)
            }
            return result
        },
        hasEquipments() {
            return this.equipments && this.equipments.length > 0
        },
        columns() {
            let startDate = this.intervalStart ? moment(this.intervalStart) : moment()//.format('YYYY-MM-DD')
            let endDate = this.intervalEnd ? moment(this.intervalEnd) :  moment().add(7,'days')//.format('YYYY-MM-DD')

            let numOfColumns = endDate.diff(startDate, 'days') + 1

            let columns = []

            for (let i=1; i <= numOfColumns; i++) {
                const column = {
                    weekday: startDate.format('ddd'),
                    date: startDate.format('YYYY-MM-DD'),
                    day: startDate.format('DD'),
                    month: startDate.format('MMM')
                }

                // Add the week number if it's the first element or the weekday
                // is monday.
                if ((i === 1) || (startDate.isoWeekday() === 1)) {
                    column.week = startDate.isoWeek()
                }

                // Set a flag if the current date is "today".
                if (column.date === Datetime.today()) {
                    column.today = true
                }

                columns.push(column)
                startDate.add(1, 'd')
            }

            return columns
        },
    },
    methods: {
        /**
         * Gets usages for a equipment on a specific date.
         */
        getUsagesByEquipmentAndDate(equipment, date) {
            return this.equipmentUsageGroupByEquipmentAndDate[equipment.id]?.[date] ?? []
        },

        /**
         * Gets the status class for each booked item.
         */
        getItemStatusClass(item) {
            if (item.conflict && !item.doNotCreateConflict) {
                return 'book-item-status-3'
            }

            if (item.exceedsGroupLimit) {
                return 'book-item-status-7'
            }

            if (item.usageType === 'feed') {
                return 'book-item-status-5'
            }

            return 'book-item-status-6'
        },

        onResize() {
            this.windowHeight = window.innerHeight
        },

        /**
         * Opens the link in a new tab.
         */
        openNewTab(link) {
            clearTimeout(this.clickTimer)
            this.clickTimer = null

            const context = this

            let routeData = context.$router.resolve(link);
            window.open(routeData.href, '_blank');
        },

        /**
         * Gets the edit link for an item.
         */
        getEditLink(item) {
            const usageType = item.usageType === 'production' ? item.usageItem.productionType : item.usageType

            return {
                name  : `${usageType}.edit`,
                params: {
                    id: item.usageId
                },
            }
        },

        /**
         * Opens action modal.
         *
         * If we only have one action, we handle that action directly to save the user a click.
         */
        openActionModal(id, equipment, column) {
            if (this.actions.length === 1) {
                this.handleAction(this.actions[0], equipment, column)
            } else {
                this.actionModal = id
            }
        },

        closeActionModal() {
            this.actionModal = false
        },

        handleAction(action, equipment, column) {
            const data = {
                action: action.action,
                equipment,
                date: column.date,
            }

            this.$emit('handleAction', data)

            this.closeActionModal()
        },

        editEquipmentUsage(equipmentUsage) {
            if (this.clickTimer) {
                return
            }
            this.clickTimer = setTimeout(() => {
                this.$emit('editEquipmentUsage', equipmentUsage)
                this.clickTimer = null
            }, 250)
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<template>
    <div id="book-equipment">
        <div class="book-equipment">
            <book-affix relative-element-selector=".book-equipment" :offset="{ top: 115, bottom: 30 }" :listenScrollOn="''" class="book-equipment-day-navigation" v-if="hasEquipments">
                <div class="book-equipment-label" :style="'padding: 2px 4px; font-weight: bold; font-size: 14px;'">
                </div>
                <div class="book-equipment-row-content">
                    <div class="book-equipment-column" v-for="column in columns" :class="[{'book-equipment-today': column.today}, {'book-equipment-with-week': column.week}]" :key="'head ' + column.date" :id="'head ' + column.date">
                        <div :class="column.week ? 'book-equipment-week' : 'book-equipment-week-empty'" :title="'W,' + column.weekday + ', ' + column.day">
                            {{column.week ? 'W' + column.week : ''}}
                        </div>
                        <div class="book-equipment-day" :title="column.weekday + ', ' + column.day">

                            <span class="book-equipment-weekday">{{column.weekday}}</span>
                            <span class="book-equipment-date">{{column.day}}</span>
                            <span class="book-equipment-date">{{column.month}}</span>
                        </div>
                    </div>
                </div>
            </book-affix>

            <dynamic-scroller
                ref="scroller"
                :items="equipments"
                :min-item-size="38"
                :buffer="1000"
                class="book-equipment-content"
            >
                <template v-slot="{ item: equipment, index, active }">
                    <DynamicScrollerItem
                        :item="equipment"
                        :active="active"
                        :data-index="index"
                    >
                        <div class="book-equipment-row" :class="(index % 2 == 0) ? 'book-equipment-odd' : 'book-equipment-even'">
                            <div class="book-equipment-label" :title="equipment.id">
                                <div class="book-equipment-row-content" style="margin-bottom: 5px;">
                                    <div class="book-equipment-column-info" style="padding-left: 2px;">
                                        <span>{{ equipment.label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="book-equipment-row-content">
                                <div class="book-equipment-column" v-for="column in columns" :class="{'book-equipment-today': column.today}">
                                    <div :title="usage.usageItem.title" class="book-equipment-item book-item-bkg" v-for="usage in getUsagesByEquipmentAndDate(equipment, column.date)" @click="editEquipmentUsage(usage)" @dblclick="openNewTab(getEditLink(usage))" :class="getItemStatusClass(usage)">
                                        <span class="book-equipment-item-time">{{usage.periodStartTime}}</span>
                                        <span class="book-equipment-item-time">{{usage.periodEndTime}}</span>

                                        <span class="book-equipment-item-title">{{usage.usageItem.title}}</span>
                                    </div>

                                    <div class="book-equipment-item-plus dropup" :class="{ 'open': actionModal === equipment.id + ' ' + column.date }">
                                        <button class="btn btn-plus" @click="openActionModal(equipment.id + ' ' + column.date, equipment, column)">
                                            <span>+</span>
                                        </button>
                                    </div>

                                    <book-modal maxWidth="400px" @close="closeActionModal" v-if="actionModal === equipment.id + ' ' + column.date">
                                        <div slot="body">
                                            <div class="book-equipment-action-modal-buttons">
                                                <template v-for="(action, index) in actions">
                                                    <button :tabindex="2 + (index + 1)" v-if="index === 0" v-focus class="btn mr-8" @click.prevent="handleAction(action, equipment, column)">
                                                        <span>{{ action.label }}</span>
                                                    </button>
                                                    <button :tabindex="2 + (index + 1)" v-else class="btn mr-8" @click.prevent="handleAction(action, equipment, column)">
                                                        <span>{{ action.label }}</span>
                                                    </button>
                                                </template>
                                            </div>
                                        </div>
                                    </book-modal>

                                </div>
                            </div>
                        </div>
                    </DynamicScrollerItem>
                </template>
            </dynamic-scroller>

            <div class="book-equipment-no-results" v-if="!hasEquipments">
                <span>No results</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "../../../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/variables";

#book-equipment {
    .book-equipment {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: auto;
        .book-equipment-day-navigation,
        .book-equipment-content {
            min-width: 800px;
            width: 100%;
        }
        .book-equipment-day-navigation {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% - 30px);
                z-index: 1000;
            }
            .book-equipment-label,
            .book-equipment-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-equipment-column {
                padding: 2px 4px;
                &.book-equipment-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-equipment-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-equipment-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-equipment-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-equipment-week
            .book-equipment-weekday,
            .book-equipment-date {
                display: block;
            }
        }
        .book-equipment-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% / 12 * 5 - 31px);
                z-index: 1000;
            }
            .book-equipment-label {
                display: inline-block;
            }
            .book-equipment-label,
            .book-equipment-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-equipment-column {
                padding: 2px 4px;
                &.book-equipment-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-equipment-day {
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-equipment-week {
                background: $color-blue;
                color: $color-white;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-equipment-week-empty {
                height: 20px;
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            .book-equipment-weekday,
            .book-equipment-date {
                display: block;
            }
        }
        .affix + .book-equipment-content {
            margin-top: 56px;
        }
        .book-equipment-content {
            .book-equipment-column {
                padding: 2px 0;
            }
        }
        .book-equipment-day-navigation,
        .book-equipment-row {
            display: flex;
        }
        .book-equipment-row {
            min-height: 38px;
            &:last-child {
                .book-equipment-label,
                .book-equipment-column:not(.book-equipment-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
            }
            &.book-equipment-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-equipment-label {
            display: block;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 130px;
            min-width: 130px;
            overflow: hidden;
            padding: 8px 5px 6px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 130px;
                min-width: 130px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-equipment-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-equipment-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-equipment-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                z-index: 2;
            }
        }

        .book-equipment-label,
        .book-equipment-item {
            font-size: 12px;
            line-height: 1.3em;
        }
        .book-equipment-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-equipment-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-equipment-item-title {
            display: block;
        }
        .book-equipment-item-time {
            flex: 1 0 auto;
            display: inline-block;
        }
        .book-equipment-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
        }
        .book-equipment-item-plus {
            height: 20px;
            font-weight: bold;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px 6px;
            text-align: center;
        }
        .btn-plus {
            padding: 0px;
            width: 100%;
        }

        .book-equipment-item-actions {
            position: relative;
        }
    }
}

.book-equipment-action-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
