<script>
import {app} from 'app'
import Datetime from 'components/common/helpers/Datetime'
import BookLoader from 'components/common/loader/BookLoader'
import BookDatetime from 'components/common/BookDatetime'
import BookToggle from 'components/common/BookToggle'     
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookSelectbox from 'components/common/BookSelectbox'
import User from 'components/user/User'
import ProductionCrew from 'components/production/ProductionCrew/ProductionCrew'
import BookNotification from 'components/notification/BookNotification'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'
import System from 'components/system/System'

import { getPermissionSubjectFromProductionType } from '../productionTypes'

export default {
    components: {
        BookLoader,
        BookDatetime,
        BookTable,
        BookModal,
        BookSelectbox,
        BookNotification,
    },
    props: {
        'production': {
            default: () => ({}),
            type: Object
        }, 
        'skillEntity': {
            default: () => ({}),
            type: Object
        }, 
        'planTypeEntity': {
            default: () => ({}),
            type: Object
        }, 
        'items': {
            default: () => [],
            type:Array
        }, 
        'planItems': {
            default: () => [],
            type:Array
        }, 
        'disabled': {
            default: false,
            type: Boolean
        }, 
        'disabledMessage': String, 
        'local': {
            default: false,
            type: Boolean
        }, 
        'hideContact': {
            default: false,
            type: Boolean
        },
        'showBookingType': {
            default: false,
            type: Boolean
        },
        'bookingTypesEntity': {
            default: () => ({}),
            type: Object
        },
        'print': {
          default: false,
          type: Boolean,
        }
    },
    data() {
        return {
            user: null,
            loaded: false,
            retrievingCrew: false,
            invalidDate: false,
            crewItemForm: {
                show: false,
                loaded: false,
                formData: this.defaultData()
            },
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
                groupMessage: '',
                groupBy: '',
                defaultEmailMessage: '',
                messageTemplate: ''
            },
            locationsEntity: null

        }
    },
    watch: {
        'crewItemForm.formData.startPlan.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            // if user already selected, do not search for another member. WN
            if (this.crewItemForm.formData.userId.value) return
            if(this.crewItemForm.formData.skill.value) this.getAvailableCrew()
        },
        'crewItemForm.formData.endPlan.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            // if user already selected, do not search for another member. WN
            if (this.crewItemForm.formData.userId.value) return
            if(this.crewItemForm.formData.skill.value) this.getAvailableCrew()
        },

        'crewItemForm.formData.country.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            if(this.crewItemForm.formData.skill.value) this.getAvailableCrew()
        },

        'crewItemForm.formData.userId.value': function(newValue) {

            // Reset status value if no user is selected.
            if (newValue == '') {
                this.crewItemForm.formData.status.value = 'option'
            }

        },
        'crewItemForm.formData.skill.value': function(newValue) {
            if (!this.crewItemForm.loaded) return
            //Why we check here for a user?
            //if (this.isFormValid(['skill']) && (this.crewItemForm.formData.userId.value == 0)) {
                //this.getAvailableCrew()
            //}
            if (this.isFormValid(['skill'])) return this.getAvailableCrew();
        },
    },
    mounted() {
        // Event emitted when a production is saved and the dates have been changed.
        this.$root.$on('notifyCrew_changedDates', () => {
            this.notification.messageTemplate = 'changedDates'
            this.showNotificationModal()
        })

        Vue.nextTick(() => {
            this.loaded = true
        })
    },
    computed: {
        getPermissionSubject() {
            return getPermissionSubjectFromProductionType(this.production.get.productionType)
        },
        processing() {
            return this.$loader.has('bookProductionCrewSubmit') || this.$loader.has('bookProductionCrewDeleteItem')
        },
        getCrewItems() {
            //VR change sorting
            return Lazy(this.items).filter(item => !item.isHistorical).sortBy(item => item.user ? item.user.name : this.getStart(item).raw).toArray()
            //return Lazy(this.items).sortBy(item => this.getStart(item).raw, false).toArray()
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        bookingTypes() {
            return this.bookingTypesEntity && this.bookingTypesEntity.get && this.bookingTypesEntity.get.items && this.bookingTypesEntity.get.items.length > 0 ? _.orderBy(this.bookingTypesEntity.get.items.filter(i => i.active), 'weight') : []
        },
        productionCrewData() {
            const context = this
            const output = {}
            output.options = {
                actions: [],
                actionSettings: {
                    edit: {
                        label: 'Open',
                    }
                },
                filters: {
                    role: {
                        label: 'Role',
                        selected: '',
                        items: []
                    },
                    status: {
                        label: 'Status',
                        selected: '',
                        items: [
                            {id: 'accepted', label: 'Accepted'},
                            {id: 'declined', label: 'Declined'},
                            {id: 'not_available', label: 'Not available'},
                            {id: 'option', label: 'Option'},
                            {id: 'requested', label: 'Requested'},
                        ]
                    }
                }
            }

            // Add actions buttons if the user has permissions to perform these.
            if (this.$ability.can('view_crew_calendar', 'Productions')) {
                output.options.actions.unshift('calendar')
                output.options.actionSettings.calendar = {
                    label: '',
                    classes: ['btn', 'btn-warning', 'btn-xs']
                }
            }
            if (this.$ability.can('update_production_crew', this.getPermissionSubject)) {
                output.options.actions.push('edit')
            }
            if (this.$ability.can('delete_production_crew', this.getPermissionSubject)) {
                output.options.actions.push('delete')
            }

            if (!context.hideContact && this.$ability.can('send_notifications', 'Miscellaneous')) {
                output.options.actions.unshift('contact')
                output.options.actionSettings.contact = {
                    label: '',
                    classes: ['btn', 'btn-info', 'btn-xs']
                }
            }

            if (context.skillEntity){
                context.skillEntity.get.items.forEach(v => {
                    output.options.filters.role.items.push({
                        id: v.id,
                        label: v.label
                    })
                })
            }
            

            // Build the columns.
            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                    visible: false,
                },
                {
                    value: 'start',
                    label: 'Start',
                    sortable: true,
                },
                {
                    value: 'end',
                    label: 'End',
                    sortable: true,
                },
                {
                    value: 'role',
                    label: 'Role',
                    sortable: true,
                },
                {
                    value: 'name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    value: 'conflict',
                    label: '',
                },
                {
                    value: 'location',
                    label: 'Location',
                    sortable: false,
                },
                {
                    value: 'bookingType',
                    label: 'Booking type',
                    sortable: true,
                    visible: context.showBookingType
                },
            ]

            if (context.hasRealDate) {
                output.columns.unshift({
                    value: 'status',
                    label: 'Status',
                    sortable: false,
                },)
            }

            output.rows = []

            context.getCrewItems.forEach(v => {
                const skill = context.skillEntity.getItem(v.skill)
                const user = context.getCrewUser(v)
                const bType = context.bookingTypes.find(i => {
                    let calc = i.userfield1_value === 'yes'
                    let confl = i.userfield2_value === 'yes'
                    return calc === v.calcInCalendar && confl === v.createBookingConflict
                    })

                //const locations = context.locationEntity.getItem(v.location)

                let nameValue = user && user.name ? `<a href="/user/${user.id}">${user.name}</a>` : ''
                if (print) {
                  nameValue = user && user.name ? user.name : ''
                }

                const row = {
                    id: {
                        value: v.id
                    },
                    start: {
                        rawValue: context.getStart(v, 'start').raw,
                        value: context.getStart(v, 'start').value
                    },
                    end: {
                        rawValue: context.getStart(v, 'end').raw,
                        value: context.getStart(v, 'end').value
                    },
                    role: {
                        rawValue: v.skill,
                        value: skill ? skill.label : ''
                    },
                    name: {
                        rawValue: user && user.name ? user.name : '',
                        value: nameValue,
                    },
                    conflict: {
                        value: v.conflict ? '<span class="label label-danger" title="This crew member is already booked">conflict</span>' : ''
                    },
                    location: {
                        value: (v.hasOwnProperty('location') && v.location !== null && (context.getLocationEntityItemLabel(v.location)).length > 0 ) 
                        ? (context.getLocationEntityItemLabel(v.location))[0].label  : (v.hasOwnProperty('locationId') && v.locationId !== null && (context.getLocationEntityItemLabel(v.locationId)).length > 0 ) 
                        ? (context.getLocationEntityItemLabel(v.locationId))[0].label  : ''
                    },
                    bookingType: {
                        rawValue: bType ? bType.id : null,
                        value: bType ? bType.label : ''
                    },
                    country: {
                        value: user ? user.country  : ''
                    },
                    rowOptions: {
                        classes: [],
                        actions: [],
                        hideActions: []
                    }
                }
                if (v.conflict) {
                    row.rowOptions.classes.push('danger')
                }
                if (context.hasRealDate) {
                    const status = context.getStatus(v.status)
                    row.status = context.print ? { rawValue: status.title, value: status.title } : status
                }

                if ( v.user && this.$ability.cannot('update_production_crew', this.getPermissionSubject) && (app.$data.user.id == v.user.id) && this.$ability.can('update_own_crew_status', 'Productions')) {
                    row.rowOptions.actions.push('edit')
                }

                if(v.isHistorical) {
                    row.rowOptions.hideActions.push('edit')
                    row.rowOptions.hideActions.push('contact')
                    row.rowOptions.hideActions.push('calendar')
                    row.rowOptions.hideActions.push('delete')
                }

                output.rows.push(row)
            })

            if (output.rows.length > 0)
                return output
            return {
                rows: []
            }
        },
        availableCrew() {
            return this.$store.state.data.availableCrew
        },
        hasPlan() {
            return this.getPlanItems && this.getPlanItems.length > 0
        },
        entityItem() {
            return EntityItem
        },
        isFormChanged() {
            const context = this
            let changed = false
            if (this.local || (context.crewItemForm.formData.id.value == 0)) {
                Lazy(context.crewItemForm.formData)
                .filter(v => !v.local)
                .each((v, k) => {
                    if (changed) return
                    switch (k) {
                        case 'startPlan':
                        case 'endPlan':
                            // Placeholder - The value of startPlan/endPlan
                            // shouldn't be checked.
                            break
                        // case 'start':
                        //     if (context.crewItemForm.formData.startOption.value == 'custom' && v.value != '') {
                        //         changed = true
                        //     }
                        //     break
                        // case 'end':
                        //     if (context.crewItemForm.formData.endOption.value == 'custom' && v.value != '') {
                        //         changed = true
                        //     }
                        //     break
                        case 'skill':
                        case 'status':
                            if (context.formFields[k] && context.formFields[k].dirty) changed = true
                            break
                        case 'id':
                        case 'userId':
                            if (v.value != 0) changed = true
                            break
                        default:
                            if (v.value != '') changed = true
                            break
                    }
                })
            }
            else {
                const currentSavedItem = context.items.find(v => v.id == context.crewItemForm.formData.id.value)
                Lazy(context.crewItemForm.formData)
                    .filter(v => !v.local)
                    .each((v, k) => {
                        if (changed) return
                        switch (k) {
                            case 'userId':
                                if ( (!currentSavedItem.user && v.value > 0) || (currentSavedItem.user && currentSavedItem.user.id != v.value) ) changed = true
                                break
                            case 'location_id':
                                if ( (!currentSavedItem.location && v.value > 0) || (currentSavedItem.location && currentSavedItem.location != v.value) ) changed = true
                                break
                            case 'bookingType':
                                const bType = context.bookingTypes.find(i => i.id === v.value)
                                let calc = bType ? bType.userfield1_value === 'yes' : true
                                let confl = bType ? bType.userfield2_value === 'yes' : true
                                if(currentSavedItem['calcInCalendar'] !== calc || currentSavedItem['createBookingConflict'] !== confl) changed = true
                                break
                            default:
                                if (currentSavedItem[k] && v.value && (currentSavedItem[k] != v.value)) changed = true
                                break
                        }
                    })
            }
            return changed
        },
        getCrewList() {
            if (!this.availableCrew || this.availableCrew.length == 0) return []
            const output = []
            Lazy(this.availableCrew)
                //.sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    let wrn = false
                    if(v.booking) {
                        wrn = _.filter(v.booking, j => j.conflict).length > 0
                    }
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName ? v.fullName.toLowerCase() : null}, ${v.name}, ${v.email}`, // WN lower-case search - there was a user that has no Last Name, that caused an Error, fixed the user..
                        extra: v.booking ? this.getBookingLabel(v.booking) : null, // WN fn_get_available_crew -> booking is not represented if do_not_create_conflict selected for the user.
                        // blocked: (typeof v.booking !== 'undefined'),
                        // warning: (typeof v.booking !== 'undefined')
                        warning: wrn//v.booking !== null
                    }
                    output.push(item)
                })
            return output
        },
        hasRealDate() {
            return this.production.get && this.production.get[this.getDateField('start')] ? true : false
        },
        getSkillsList() {
            if (this.crewItemForm.formData.country.value != '') {
                return Lazy(this.skillEntity.get.items)
                    .filter(v => {
                        return v.country == this.crewItemForm.formData.country.value
                    })
                    .toArray()
            }
            return this.skillEntity.get.items
        },
        statusIsDisabled() {
            if (!this.crewItemForm.formData.userId.value) return true
            if (this.$ability.can('update_production_crew', this.getPermissionSubject)) return false
            return (app.$data.user.get.id == this.crewItemForm.formData.id.value) && this.$ability.cannot('update_own_crew_status', 'Productions')
        },
        isCouldBeRequested(){
            if (this.productionCrewData && this.productionCrewData.rows){
                let isRequested = this.productionCrewData.rows.find(t => {
                    if (t.status && t.status.rawValue === "option" && t.name.rawValue !== '') return true                
                })
                return isRequested ? true : false
            }
            return false;
        },
        isCouldBeOptioned(){
            if (this.productionCrewData && this.productionCrewData.rows){
                let isRequested = this.productionCrewData.rows.find(t => {
                    if (t.status && t.status.rawValue === "requested" && t.name.rawValue !== '') return true                
                })
                return isRequested ? true : false
            }
            return false;            
        },
        isCouldBeAccepted(){
            if (this.productionCrewData && this.productionCrewData.rows){
                let isRequested = this.productionCrewData.rows.find(t => {
                    if (t.status && t.status.rawValue !== "accepted" && t.name.rawValue !== '') return true                
                })
                return isRequested ? true : false
            }
            return false;            
        },
    },
    methods: {
        getLocations(){
            return this.$store.state.system.locations[0].items
        },

        setLocation(){
            const locations = this.getLocations()
            locations.forEach(location => {
                if(location.country === this.crewItemForm.formData.country.value && location.default === true)
                    this.crewItemForm.formData.location_id.value = location.id
            })
        },

        isFormValid(byPass = []) {
            // if ((this.crewItemForm.formData.startOption.value == 'custom') && (this.crewItemForm.formData.start.value == '')) {
            //     return false
            // }
            // if ((this.crewItemForm.formData.endOption.value == 'custom') && (this.crewItemForm.formData.end.value == '')) {
            //     return false
            // }
            if ((byPass.indexOf('skill') == -1) && (this.crewItemForm.formData.skill.value == '')) {
                return false
            }
            return true
        },

        /**
         * Retrieve available crew, based on the current start/end values.
         * @return promise
         */
        getAvailableCrew() {
            const context = this
            if (context.retrievingCrew) return
            context.retrievingCrew = true
            return new Promise((resolve, reject) => {
                const params = {
                    production: context.production.get.id,
                    country: context.crewItemForm.formData.country.value
                }
                if (!this.local) {
                    params.startPlan = context.crewItemForm.formData.startPlan.value
                    params.endPlan = context.crewItemForm.formData.endPlan.value
                }else{
                    params.startPlan = context.crewItemForm.formData.startPlan.value
                    params.endPlan = context.crewItemForm.formData.endPlan.value
                    params.ignoreBooking = true
                }
                if (context.crewItemForm.formData.id.value > 0) {
                    params.id = context.crewItemForm.formData.id.value
                }
                if (context.crewItemForm.formData.id.value > 0 && (context.crewItemForm.formData.country.value != '')) {
                    params.country = context.crewItemForm.formData.country.value
                }
                if ((context.crewItemForm.formData.userId.value == 0) && (context.crewItemForm.formData.skill.value != '')) {
                    params.skill = context.crewItemForm.formData.skill.value
                }else if (context.crewItemForm.formData.skill.value != ''){
                    params.skill = context.crewItemForm.formData.skill.value
                }

                const productionCrew = context.getClass()
                ProductionCrew.getAvailableCrew(params)
                .then(response => {
                    context.retrievingCrew = false
                })
                .catch(error => {
                    context.retrievingCrew = false
                })
            })
        },

        /**
         * Set form values based on passed item.
         * @param object item
         * @return promise
         */
        setFormValues(item) {
            const context = this
            return new Promise((resolve, reject) => {
                Lazy(item)
                    .filter(v => v)
                    .each((v, k) => {
                        if (k == 'user') {
                            context.crewItemForm.formData.userId.value = v.id
                        }
                        else if (context.crewItemForm.formData[k]) {
                            context.crewItemForm.formData[k].value = v
                        }
                    })
                resolve()
                // if(context.crewItemForm.formData.startPlan.value && context.crewItemForm.formData.startPlan.value &&
                //    context.crewItemForm.formData.country.value != "" && context.crewItemForm.formData.skill.value != "") {
                //        context.getAvailableCrew()
                //             .catch(error => {
                //                 context.$error.set(error, 'It was not possible to load the crew list.')
                //             })
                //    }
                
            })
        },

        /**
         * Open BookNotification modal.
         * @param boolean open
         * @param string|integer If "all", all the crew members will be included,
         *                       if an user id (integer) then only that specific
         *                       user will be included.
         */
        showNotificationModal(open = true, recipient = 'all') {
            this.notification.recipients = []
            switch (recipient) {
                case 'all':
                    this.notification.modalTitle = 'Contact crew members'
                    this.notification.groupBy = 'status'
                    this.notification.groupMessage = 'Which crew members should receive this message?'
                    Lazy(this.items)
                        .filter(v => v.user)
                        .each(v => {
                            this.notification.recipients.push({
                                status: v.status,
                                email: v.user.email,
                                phone: v.user.phone,
                                id: v.user.id,
                                label: v.user.name,
                            })
                        })
                    break
                default:
                    const crew = this.items.find(v => v.id == recipient)
                    if (crew.user) {
                        this.notification.recipients.push({
                            status: crew.status,
                            email: crew.user.email,
                            phone: crew.user.phone,
                            id: crew.user.id,
                            label: crew.user.name,
                        })
                        this.notification.modalTitle = `Contact ${crew.user.name}`
                        this.notification.groupBy = 'status'
                        this.notification.groupMessage = ''
                    }
                    break
            }
            if (this.notification.recipients.length > 0) {
                this.notification.show = open
            }
            else {
                this.notification.messageTemplate = ''
                this.notification.show = false
                this.$alert.set('No recipients are available.', 'danger', 4)
            }
            if (!open) {
                this.notification.messageTemplate = ''
            }
        },

        /**
         * Show the crew member form.
         */
        showCrewItemForm(open = true, id = null) {
            const context = this

            const locations = context.getLocations()

            if (!context.production) return
            context.resetForm()
            if (open) {
                if (id) {
                    const item = context.getCrewItems.find(v => v.id == id)
                    if (item) {
                        context.setFormValues(item)
                            .then(() => {

                                // Reset the country value in case the current
                                // selected skills doesn't match the current
                                // logged in user's country.

                                //context.crewItemForm.formData.country.value = ''

                                //context.crewItemForm.formData.country.value = (item.user) ? item.user.country : ''
                                //context.crewItemForm.formData.country.value = (item.location !== null) ? ((item.location.country !== null)? item.location.country: '') : ((item.user.country) ? item.user.country : '')
                                 
                                if (context.crewItemForm.formData.country.value == ''){
                                    let user = context.getCrewUser(item);
                                    if (item.country){
                                        context.crewItemForm.formData.country.value = item.country;
                                    }else if (user && user.country){
                                        context.crewItemForm.formData.country.value = user ? user.country : '';
                                    }else if (item.skillDetails){
                                        context.crewItemForm.formData.country.value = item.skillDetails.country;
                                    } 
                                }

                                if(item.location)
                                    context.crewItemForm.formData.location_id.value = item.location
                                else if (item.locationId){
                                    context.crewItemForm.formData.location_id.value = item.locationId
                                }
                                else {
                                    locations.forEach(location => {
                                        if(location.country === context.crewItemForm.formData.country.value && location.default === true)
                                            context.crewItemForm.formData.location_id.value = location.id
                                    })
                                }

                                
                                const bType = context.bookingTypes.find(i => {
                                        let calc = i.userfield1_value === 'yes'
                                        let confl = i.userfield2_value === 'yes'
                                        return calc === item.calcInCalendar && confl === item.createBookingConflict
                                        })

                                context.crewItemForm.formData.bookingType.value = bType ? bType.id : ''
                        
                                context.crewItemForm.loaded = true
                            })
                    }
                    else {
                        context.crewItemForm.loaded = true
                    }
                }
                else {
                    context.crewItemForm.formData.startPlan.value = context.getPlanItems('start')[0].id
                    context.crewItemForm.formData.endPlan.value = context.getPlanItems('end')[0].id
                    context.crewItemForm.formData.country.value = app.$data.user.get.country

                    locations.forEach(location => {
                        if(location.country === context.crewItemForm.formData.country.value && location.default === true)
                            context.crewItemForm.formData.location_id.value = location.id
                    })

                     const bType = context.bookingTypes.find(i => {
                                        let calc = i.userfield1_value === 'yes'
                                        let confl = i.userfield2_value === 'yes'
                                        return calc && confl
                                        })

                    context.crewItemForm.formData.bookingType.value = bType ? bType.id : ''

                    context.crewItemForm.loaded = true
                }

                //context.crewItemForm.formData.country.value = app.$data.user.get.country

                Vue.nextTick(() => {
                    context.crewItemForm.show = true
                })
            }
            else {
                context.crewItemForm.loaded = false
                context.crewItemForm.show = false
            }
        },

        /**
         * Reset the crew member form.
         */
        resetForm() {
            this.crewItemForm.formData = this.defaultData()
        },

        /**
         * Delete a given crew item.
         * @param integer id
         */
        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this crew?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    context.$loader.add('bookProductionCrewDeleteItem')
                    const productionCrew = context.getClass(id)
                    let successMsg = 'The crew member has been removed.'
                    if (context.local) {
                        context.removeCrew(id)
                        context.$alert.set(successMsg, 'success', 3)
                        context.$loader.remove('bookProductionCrewDeleteItem')
                    }
                    else {
                        productionCrew.delete()
                        .then(response => {
                            context.removeCrew(id)
                            context.$alert.set(successMsg, 'success', 3)
                            context.$loader.remove('bookProductionCrewDeleteItem')
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to remove the crew.')
                            context.$loader.remove('bookProductionCrewDeleteItem')
                        })
                    }
                })
        },

        /**
         * Check if users are available based on the chosen skill.
         * @param object user
         * @return boolean
         */
        usersAvailable(user) {
            return user.skills.indexOf(this.crewItemForm.formData.skill.value) > -1
        },

        /**
         * Get user data to be used on production crew list.
         * @param object crewItem A crew item object.
         * @return object|null
         */
        getCrewUser(crewItem) {
            let output = null
            if (crewItem.user) {
                output = crewItem.user
            }
            else if (crewItem.userId) {
                
                let user = this.$store.state.user.users.find(v => v.id == crewItem.userId)
                if (!user){
                    user = new User(crewItem.userId)
                }
                if (user.name) {
                    output = {
                        id: user.id,
                        name: user.fullName,
                        country: user.country
                    }
                }
            }
            return output
        },

        /**
         * Get a label based on passed plan item.
         */
        getPlanItemLabel(item, field) {
            let time = this.getDateWithOffset(item)
            const output = []
            output.push(time.format('HH:mm'))
            if (item.type) {
                const planType = this.planTypeEntity.getItem(item.type)
                if (planType) output.push(planType.label)
            }
            else if (item.typeCustom) {
                output.push(item.typeCustom)
            }
            return output.join(' - ')
        },

        /**
         * Get a MomentJS instance with the start date for the passed Plan Item,
         * considering its offset.
         * @param object item A Plan Item object.
         * @return object MomentJS instance.
         */
        getDateWithOffset(item) {
            return moment(this.production.get[this.getDateField(item.relativeTo)]).add(item.offset, 's')
        },
        getDateField(relativeTo) {
            return 'production' + relativeTo.capitalize()
        },

        /**
         * Get a ProductionCrew class, based on the current form data.
         * @return class|null
         */
        getClass(id = null) {
            id = id || this.crewItemForm.formData.id.value
            const productionCrew = new ProductionCrew(id, this.production.get.id)
            // Since we have our Toggle button, we have to check first our formData and then use the ID, that we sent to complete
            // all of changes at the same time (WN)
            const item = this.items.find(v => v.id == (this.crewItemForm.formData.id.value ? this.crewItemForm.formData.id.value : id) )
            if (item) {
                productionCrew.add(item)
            }
            return productionCrew
        },

        /**
         * Get the label representing a booking item. This can be used to format
         * the label for a production a crew member is booked on.
         */
        getBookingLabel(items) {
            // const output = {}
            // let title
            // let i = items.findIndex(v => v.production == this.production.get.id)
            // if (i == -1) {
            //     i = 0
            //     title = items[i].title
            // }
            // else {
            //     title = '<strong>current production</strong>'
            // }
            // let itemStart = this.getStart(items[i], 'start').value
            // let itemEnd = this.getStart(items[i], 'end').value

            let output = ''
            Lazy(items)
            .each(i => {
                output += `<p${i.conflict ? ' class="book-selectbox-item-conflict"' : ''}>${i.status.charAt(0).toUpperCase() + i.status.slice(1)}: ${i.title} (${i.production})<br />${moment(i.booking.start).format('DD MMM, HH:mm')} - ${moment(i.booking.end).format('DD MMM, HH:mm')}</p>`
            })

            return output//`Booked: ${title} (${items[i].production})<br />${itemStart} - ${itemEnd}`
        },

        /**
         * Submit the crew item form.
         */
        submit() {
            const context = this
            // context.processing = true
            context.$loader.add('bookProductionCrewSubmit')
            let id = (context.local && (!context.crewItemForm.formData.id.value || context.crewItemForm.formData.id.value == 0)) ? moment().unix() : null
            let productionCrew = null
            let historicalProductionCrew = null
            let declinedItem = null

            let cloneProductionCrew = false
            let removeProductionCrew = false
            if(context.crewItemForm.formData.id.value) {
                let item = context.getCrewItems.find(v => v.id == context.crewItemForm.formData.id.value)
                if(item && item.status == 'declined' && item.user.id !== context.crewItemForm.formData.userId.value) {
                    cloneProductionCrew = true
                    historicalProductionCrew = context.getClass(id)

                    // historicalProductionCrew.set('startPlan', item.startPlan)
                    // historicalProductionCrew.set('endPlan', item.endPlan)
                    // historicalProductionCrew.set('skill', item.skill)
                    historicalProductionCrew.set('userId', item.user.id)
                    // historicalProductionCrew.set('status', item.status)
                    // historicalProductionCrew.set('location_id', item.location)
                    historicalProductionCrew.set('isHistorical', true)
                    // historicalProductionCrew.set('calcInCalendar', item.calcInCalendar)
                    // historicalProductionCrew.set('createBookingConflict', item.createBookingConflict)
                }
            }

            if(!cloneProductionCrew) {
                productionCrew = context.getClass(id)
                declinedItem = !context.local ? context.items.find(v => v.user.id == context.crewItemForm.formData.userId.value && v.isHistorical) : null
                if(declinedItem !== undefined && declinedItem !== null) removeProductionCrew = true
            }
            else {
                context.crewItemForm.formData.id.value = 0
                productionCrew = context.getClass(0)
            }

            if (!productionCrew) return

            if(cloneProductionCrew && !historicalProductionCrew) return

            // Check if the notification dialog should be shown.
            const status = context.crewItemForm.formData.status.value
            const sendCrewRequest = (app.$data.user.get.id != context.crewItemForm.formData.userId.value) && (status == 'requested') && (productionCrew.get.status != status)
            Lazy(context.crewItemForm.formData)
                //.filter(v => !v.local)
                .each((v, k) => {
                    if (id && (k == 'id')) productionCrew.set('id', id)
                    else if(k == 'bookingType') {
                        let calcInCalendar = true
                        let createBookingConflict = true
                        if(v.value) {
                            let bType = context.bookingTypes.find(i => i.id === v.value)
                            if(bType) {
                                calcInCalendar = bType.userfield1_value === 'yes'
                                createBookingConflict = bType.userfield2_value === 'yes'
                            }
                        }
                        
                        productionCrew.set('calcInCalendar', calcInCalendar)
                        productionCrew.set('createBookingConflict', createBookingConflict)
                    }
                    else if(v.value !== "")
                        productionCrew.set(k, v.value)
                    else if(v.value === "")
                        productionCrew.set(k, null)
                    else if(v.value === null)
                        productionCrew.set(k, null)
                })

            if (context.local) {
                context.updateCrew(productionCrew.get)
                context.showCrewItemForm(false)
                context.$loader.remove('bookProductionCrewSubmit')
            }
            else {
                if(!cloneProductionCrew) {
                    if(removeProductionCrew) {
                        swal({
                            title: 'Add the user anyway?',
                            text: 'Are you sure you want to add the user who has already declined this?',
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true
                        })
                            .then(function () {
                                if (!productionCrew.options.id) {
                                    productionCrew.store()
                                        .then(response => {
                                            context.$alert.set('The crew member has been added.', 'success', 5)
                                            context.updateCrew(response.data)
                                            context.showCrewItemForm(false)
                                            const responseId = response.data
                                            if(removeProductionCrew) {
                                                const deleteCrew = context.getClass(declinedItem.id)
                                                deleteCrew.delete()
                                                .then(response => {
                                                    context.removeCrew(declinedItem.id)
                                                    context.$alert.set('Previous declining has been removed', 'success', 3)
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                    if (sendCrewRequest) {
                                                        context.sendCrewRequestToNewlyAddedUser(responseId)
                                                    }
                                                })
                                                .catch(error => {
                                                    context.$error.set(error, 'It was not possible to remove the crew.')
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                })
                                            }
                                            else {
                                                context.$loader.remove('bookProductionCrewSubmit')
                                                if (sendCrewRequest) {
                                                    context.sendCrewRequestToNewlyAddedUser(response.data)
                                                }
                                            }
                                            
                                        })
                                        .catch(error => {
                                            let msg = 'It was not possible to save the selected crew member.'
                                            if (error.response && error.response.status === 409) {
                                                msg = 'This user is already booked for the selected date/time interval.'
                                            }
                                            context.$error.set(error, msg)
                                            context.$loader.remove('bookProductionCrewSubmit')
                                        })
                                }
                                else {
                                    productionCrew.save()
                                        .then(response => {
                                            context.$alert.set('The crew member has been updated.', 'success', 5)
                                            context.updateCrew(response.data)
                                            context.showCrewItemForm(false)

                                            if(removeProductionCrew) {
                                                const deleteCrew = context.getClass(declinedItem.id)
                                                deleteCrew.delete()
                                                .then(response => {
                                                    context.removeCrew(declinedItem.id)
                                                    context.$alert.set('Previous declining has been removed', 'success', 3)
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                    if (sendCrewRequest) context.sendCrewRequest(productionCrew.get.id)
                                                })
                                                .catch(error => {
                                                    context.$error.set(error, 'It was not possible to remove the crew.')
                                                    context.$loader.remove('bookProductionCrewSubmit')
                                                })
                                            }
                                            else {
                                                context.$loader.remove('bookProductionCrewSubmit')
                                                if (sendCrewRequest) context.sendCrewRequest(productionCrew.get.id)
                                            }
                                        })
                                        .catch(error => {
                                            context.$error.set(error, 'It was not possible to save the selected crew member.')
                                            context.$loader.remove('bookProductionCrewSubmit')
                                        })
                                }
                            })
                            .catch(function() {
                                context.$loader.remove('bookProductionCrewSubmit')
                            })
                    }
                    else {
                        if (!productionCrew.options.id) {
                            productionCrew.store()
                                .then(response => {
                                    context.$alert.set('The crew member has been added.', 'success', 5)
                                    context.updateCrew(response.data)
                                    context.showCrewItemForm(false)                                   
                                    context.$loader.remove('bookProductionCrewSubmit')
                                    if (sendCrewRequest) {
                                        context.sendCrewRequestToNewlyAddedUser(response.data)
                                    }                                
                                })
                                .catch(error => {
                                    let msg = 'It was not possible to save the selected crew member.'
                                    if (error.response && error.response.status === 409) {
                                        msg = 'This user is already booked for the selected date/time interval.'
                                    }
                                    context.$error.set(error, msg)
                                    context.$loader.remove('bookProductionCrewSubmit')
                                })
                        }
                        else {
                            productionCrew.save()
                                .then(response => {
                                    context.$alert.set('The crew member has been updated.', 'success', 5)
                                    context.updateCrew(response.data)
                                    context.showCrewItemForm(false)
                                    context.$loader.remove('bookProductionCrewSubmit')
                                    if (sendCrewRequest) context.sendCrewRequest(productionCrew.get.id)                                   
                                })
                                .catch(error => {
                                    context.$error.set(error, 'It was not possible to save the selected crew member.')
                                    context.$loader.remove('bookProductionCrewSubmit')
                                })
                        }
                    }                    
                }
                else {
                    productionCrew.store()
                            .then(response => {
                                context.updateCrew(response.data)

                                let newCrew = response.data
                                
                                historicalProductionCrew.save()
                                    .then(response => {
                                        context.$alert.set('The crew member has been updated.', 'success', 5)
                                        context.updateCrew(response.data)
                                        context.showCrewItemForm(false)
                                        context.$loader.remove('bookProductionCrewSubmit')
                                        if (sendCrewRequest) {
                                            context.sendCrewRequestToNewlyAddedUser(newCrew)
                                        }
                                    })
                                    .catch(error => {
                                        context.$error.set(error, 'It was not possible to save the selected crew member.')
                                        context.$loader.remove('bookProductionCrewSubmit')
                                    })
                            })
                            .catch(error => {
                                let msg = 'It was not possible to save the selected crew member.'
                                if (error.response && error.response.status === 409) {
                                    msg = 'This user is already booked for the selected date/time interval.'
                                }
                                context.$error.set(error, msg)
                                context.$loader.remove('bookProductionCrewSubmit')
                            })
                }
            }
        },

        /**
         * Add/update a crew item to the current production.
         * @param object item
         */
        updateCrew(item) {
            const crew = this.items.slice(0)
            let i = crew.findIndex(v => v.id == item.id)
            if (i > -1) crew.splice(i, 1)
            crew.push(item)
            this.$emit('changedCrew', crew)
            if (!this.local) this.production.set('crew', crew)
        },

        /**
         * Remove a crew from the crew list.
         * @param integer id The crew id
         */
        removeCrew(id) {
            const crew = this.items.slice(0)
            let i = crew.findIndex(v => v.id == id)
            if (i > -1) {
                crew.splice(i, 1)
                this.$emit('changedCrew', crew)
                if (!this.local) this.production.set('crew', crew)
            }
        },

        /**
         * Get the status values to be sent into BookTable.
         */
        getStatus(status) {
            const output = {
                rawValue: status,
                value: '<span class="book-square"></span>',
                title: status.capitalize().replace('_', ' '),
                classes: []
            }
            let itemStatusId = 0

            // Determine the "status id", which is a number that indicates a
            // color, based on the same color system as feed/production status.
            switch (status) {
                case 'option':
                    itemStatusId = 5 // Blue.
                    break
                case 'requested':
                    itemStatusId = 4 // Yellow.
                    break
                case 'accepted':
                    itemStatusId = 2 // Green.
                    break
                case 'declined':
                    itemStatusId = 3 // Red.
                    break
                case 'not_available':
                    itemStatusId = 1 // Grey.
                    break
            }

            output.classes.push(`book-item-status-${itemStatusId}`)
            return output
        },

        /**
         * Get a start datetime for a given item (crew member).
         * @param object crewMember
         * @param string field The name of the field to retrieve: "start" or "end"
         * @return obejct
         */
        getStart(crewMember, field = 'start') {
            const output = {raw: '', value: ''}
            let planItem = this.getPlanItem(crewMember[field + 'Plan'])

            // Return a real date including the calculated offset or just the offset.
            if (this.hasRealDate) {
                let referenceTime = null
                if (crewMember.booking) {
                    referenceTime = moment(crewMember.booking[field])
                }
                else {
                    referenceTime = planItem ? this.getDateWithOffset(planItem) : null
                }
                if (referenceTime) {
                    output.raw = referenceTime.format('YYYY-MM-DD HH:mm:ss')
                    output.value = referenceTime.format('DD MMM, HH:mm')
                }
            }

            // Return only a offset representation.
            else {
                output.raw = planItem.offset
                output.value = Datetime.getOffsetFormatted(planItem.offset).join('')
            }

            return output
        },

        getLocationEntityItemLabel(id){
            return Lazy(this.$store.state.system.locations[0].items)
                .filter(v => {
                    return v.id == id
                })
                .toArray()

            },

        /**
         * Get the plan items to be presented in a selectbox, based on the
         * passed field.
         * @param string field
         * @return array
         */
        getPlanItems(field) {
            const planItems = this.local ? this.planItems : this.production.get.plan
            return Lazy(planItems)
            .filter(item => item.relativeTo == field)
            .sortBy(item => item.offset)
            .toArray()
        },

        /**
         * Retrieve a plan item from the current production, by its id.
         * @param integer id
         * @return object
         */
        getPlanItem(id) {
            const planItems = this.local ? this.planItems : this.production.get.plan
            return planItems.find(v => v.id == id)
        },

        /**
         * Default subject to be used when sending e-mail to crew members.
         * @return string
         */
        getDefaultEmailSubject() {
            return `Production: ${this.production.get.title}`
        },

        /**
         * Default message to be used when sending e-mail to crew members.
         * @return string
         *
         * @TODO Should the default messages be "editable" through the admin UI?
         */
        getDefaultEmailMessage() {
            const output = []
            switch (this.notification.messageTemplate) {
                case 'changedDates':
                    output.push('This production has been changed.\n')
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`Start: ${this.production.get.productionStart}`)
                    output.push(`End: ${this.production.get.productionEnd}`)
                    break
                case 'crewRequest':
                    output.push('You have been requested to work in the following production.\n')
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`Start: ${this.production.get.productionStart}`)
                    output.push(`End: ${this.production.get.productionEnd}`)
                    break
                default:
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`/${app.$data.user.get.fullName}`)
                    break
            }
            return output.join('\n')
        },

        /**
         * Just duplicates get email text function. Maybe we will need here another text later
         */
        getDefaultSmsMessage(){
            const output = []
            switch (this.notification.messageTemplate) {
                case 'changedDates':
                    output.push('This production has been changed.\n')
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`Start: ${this.production.get.productionStart}`)
                    output.push(`End: ${this.production.get.productionEnd}`)
                    break
                case 'crewRequest':
                    output.push('You have been requested to work in the following production.\n')
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`Start: ${this.production.get.productionStart}`)
                    output.push(`End: ${this.production.get.productionEnd}`)
                    break
                default:
                    output.push(`Production: ${this.production.get.title}`)
                    output.push(`/${app.$data.user.get.fullName}`)
                    break
            }
            return output.join('\n')
        },

        /**
         * Open the notification dialog to send a message to the requested user.
         * @param interger userId
         */
        sendCrewRequest(crewId) {
            const context = this
            const crew = context.items.find(v => v.id == crewId)
            if (!crew) return
            swal({
                title: 'Notify user?',
                text: `Do you want to send a request to ${crew.user.name}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
                .then(function() {
                    context.notification.messageTemplate = 'crewRequest'
                    context.showNotificationModal(true, crewId)
                })
        },

        /**
         * Open the notification dialog to send a message to the requested user.
         * @param interger userId
         */
        sendCrewRequestToNewlyAddedUser(crew) {
            const context = this
            if (!crew) return
            swal({
                title: 'Notify user?',
                text: `Do you want to send a request to ${crew.user.name}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true
            })
                .then(function() {
                    context.notification.messageTemplate = 'crewRequest'
                    context.showNotificationModal(true, crew.id)
                })
        },
        
        toggleCrewStatus(status){
            let prevStatus = 'requested';
            let context = this
            if (status == 'requested') prevStatus = 'option'
            let crewToNotify = this.productionCrewData.rows.filter(v => v.status.rawValue == prevStatus && v.name.rawValue !== '')
            if (status === 'accepted'){
                crewToNotify = this.productionCrewData.rows.filter(v => v.status.rawValue != 'accepted' && v.name.rawValue !== '');
            }
            if (crewToNotify){
                crewToNotify.map(t => {
                    if (t.id && t.id.value){
                        const productionCrew = context.getClass(t.id.value)
                        if (productionCrew){
                            productionCrew.set('status', status)
                            productionCrew.save()
                                .then(response => {
                                    // We dont need to send alert, only processing would be enough.
                                    //context.$alert.set('The crew member has been updated.', 'success', 5)
                                    context.updateCrew(response.data)
                                    context.$loader.remove('bookProductionCrewSubmit')
                                    //if (status == 'requested')
                                    //context.sendCrewRequest(t.id)
                                })
                                .catch(error => {
                                    context.$error.set(error, 'It was not possible to save the selected crew member.')
                                    context.$loader.remove('bookProductionCrewSubmit')
                                })
                        }
                    }
                });
            }
        },
        /**
         * Shows the calendar of the crew member
         * @return new-tab
         * */
        showCalendar(userId) {
            let context = this
            let link_ = {
                view: context.$route.params.view,
                crewId: userId
            }

            let routeData = context.$router.resolve({name: `calendar.view`, query: link_});
            window.open(routeData.href, '_blank');
        },
        /**
         * Default form data.
         * @return object
         */
        defaultData() {

            // Note that we can't use this.hasPlan in this context because this
            // method is called when the component is being rendering and
            // computed values don't seem to be available yet.
            // let option = (this.getPlanItems && this.getPlanItems.length > 0) ? 'planItem' : 'custom'

            // @TODO Remove everything that has to do with startOption logic...

            return {
                id: {
                    value: 0
                },
                // startOption: {
                //     value: option,
                //     local: true
                // },
                startPlan: {
                    value: '',
                },
                // start: {
                //     value: '',
                // },
                // endOption: {
                //     value: option,
                //     local: true
                // },
                endPlan: {
                    value: '',
                },
                // end: {
                //     value: '',
                // },
                country: {
                    local: true,
                    value: ''
                },
                skill: {
                    value: '',
                },
                userId: {
                    value: ''
                },
                status: {
                    value: 'requested',
                },
                bookingType: {
                    value: ''
                },
                location_id:{
                    value: ''
                },
                isHistorical: {
                    value: false
                }

            }
        }

    }
}
</script>

<template>
<div class="book-production-crew">
      <div class="book-production-crew-contact-buttons" v-if="loaded && !print">
        <button
            v-if="isCouldBeRequested"
            class="btn btn-warning btn-xs"
            title="Contact all crew members"
            @click="toggleCrewStatus('requested')"
            :disabled="!items || items.length == 0 || disabled">
            <font-awesome-icon icon="toggle-on"/>&nbsp;&nbsp;Set requested
        </button>       
        <button
            v-if="isCouldBeOptioned"
            class="btn btn-primary btn-xs"
            title="Contact all crew members"
            @click="toggleCrewStatus('option')"
            :disabled="!items || items.length == 0 || disabled">
            <font-awesome-icon icon="toggle-on"/>&nbsp;&nbsp;Set option
        </button>  
        <button
            v-if="isCouldBeAccepted"
            class="btn btn-success btn-xs"
            title="Contact all crew members"
            @click="toggleCrewStatus('accepted')"
            :disabled="!items || items.length == 0 || disabled">
            <font-awesome-icon icon="toggle-on"/>&nbsp;&nbsp;Set accepted
        </button>  
        <button
            class="btn btn-info btn-xs"
            title="Contact all crew members"
            @click="showNotificationModal(true)"
            v-if="!hideContact && $ability.can('send_notifications', 'Miscellaneous')"
            :disabled="!items || items.length == 0 || disabled">
            <font-awesome-icon icon="envelope"/>&nbsp;&nbsp;Crew
        </button>
        <button
            v-if="$ability.can('create_production_crew', this.getPermissionSubject)"
            class="btn btn-success btn-xs"
            title="Add item"
            @click="showCrewItemForm(true)"
            :disabled="disabled">
            <font-awesome-icon icon="plus"/>&nbsp;&nbsp;Add
        </button>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <book-table
        v-else
        :items="productionCrewData"
        :print="print"
        :component="'productionCrew'"
        itemsType='production'
        :notFoundMessage="!disabled || !disabledMessage ? 'No crew member has been added' : ''"
        @calendar="item => showCalendar(item.id.value)"
        @contact="item => showNotificationModal(true, item.id.value)"
        @edit="item => showCrewItemForm(true, item.id.value)"
        @delete="item => deleteItem(item.id.value)">
    </book-table>
    <div class="book-box-overlay" v-if="processing">
        <book-loader :small="true"></book-loader>
    </div>
    <div class="book-box-overlay" v-if="disabled && loaded">
        <span class="label label-danger" v-html="disabledMessage"></span>
    </div>

    <book-modal maxWidth="500px" @close="showCrewItemForm(false)" v-if="crewItemForm.show">
        <h4 slot="header" class="modal-title">
            <span v-if="!crewItemForm.formData.id.value">Add</span><span v-else>Edit</span> crew member
        </h4>
        <div slot="body">
            <book-loader v-if="!crewItemForm.loaded"></book-loader>
            <div v-else class="form-horizontal" data-vv-scope="crewItem">
                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Start
                    </label>
                    <div class="form-control-100">
                        <select tabindex="1" class="form-control" v-model="crewItemForm.formData.startPlan.value" :disabled="!hasPlan || $ability.cannot('update_production_crew', getPermissionSubject)">
                            <option v-for="planItem in getPlanItems('start')" :value="planItem.id">{{getPlanItemLabel(planItem, 'start')}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label class="control-label control-label-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        End
                    </label>
                    <div class="form-control-100">
                        <select tabindex="1" class="form-control" v-model="crewItemForm.formData.endPlan.value" :disabled="!hasPlan || $ability.cannot('update_production_crew', getPermissionSubject)">
                            <option v-for="planItem in getPlanItems('end')" :value="planItem.id">{{getPlanItemLabel(planItem, 'end')}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label for="name" class="control-label book-width-100">
                        Country
                    </label>
                    <div class="form-control-100">
                        <select class="form-control" name="country" v-model="crewItemForm.formData.country.value" :disabled="$ability.cannot('update_production_crew', getPermissionSubject)" @change="setLocation">
                            <option value="">None</option>
                            <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('crewItem.skill')}">
                    <label for="name" class="control-label book-width-100">
                        <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                        Role
                    </label>
                    <div class="form-control-100">
                        <select tabindex="1" class="form-control" name="skill" v-validate v-model="crewItemForm.formData.skill.value" v-if="skillEntity" :disabled="$ability.cannot('update_production_crew', this.getPermissionSubject)">
                            <option value="">None</option>
                            <option v-for="skill in getSkillsList" :value="skill.id">{{skill.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label for="name" class="control-label control-label-100">
                        User
                    </label>
                    <div class="form-control-100">
                        <book-selectbox
                            componentId="crew-member"
                            :loading="retrievingCrew"
                            :items="getCrewList"
                            :selected="crewItemForm.formData.userId.value"
                            :disabled="!this.availableCrew || $ability.cannot('update_production_crew', this.getPermissionSubject) || !crewItemForm.formData.skill.value"
                            @selectedItem="id => crewItemForm.formData.userId.value = id">
                        </book-selectbox>
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label for="name" class="control-label book-width-100">
                        Location
                    </label>
                    <div class="form-control-100">
                        <!--<select class="form-control" name="location_id" v-model="crewItemForm.formData.location_id.value" :disabled="$ability.cannot('update_production_crew', this.getPermissionSubject)">-->
                        <select class="form-control" v-model="crewItemForm.formData.location_id.value" :disabled="$ability.cannot('update_production_crew', this.getPermissionSubject)">
                            <option value="">None</option>
                            <option v-for="location in getLocations()"
                                    :selected="location.country === crewItemForm.formData.country.value && location.default === true"
                                    :value="location.id">
                                {{location.label}} ({{location.countryUpper ? location.countryUpper : '--'}})
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm" v-if="!local">
                    <label for="name" class="control-label control-label-100">
                        Status
                    </label>
                    <div class="form-control-100">
                        <select tabindex="1" class="form-control" name="status" v-validate v-model="crewItemForm.formData.status.value" :disabled="statusIsDisabled">
                            <option v-if="!$ability.cannot('update_production_crew', this.getPermissionSubject)" value="option">Option</option>
                            <option v-if="!$ability.cannot('update_production_crew', this.getPermissionSubject)" value="requested">Requested</option>
                            <option value="accepted">Accepted</option>
                            <option value="declined">Declined</option>
                            <option v-if="!$ability.cannot('update_production_crew', this.getPermissionSubject)" value="not_available">Not available</option>
                        </select>
                    </div>
                </div>
                <div class="form-group form-group-sm" v-if="showBookingType">
                    <label for="name" class="control-label book-width-100">
                        Booking type
                    </label>
                    <div class="form-control-100">
                        <!--<select class="form-control" name="location_id" v-model="crewItemForm.formData.location_id.value" :disabled="$ability.cannot('update_production_crew', this.getPermissionSubject)">-->
                        <select class="form-control" v-model="crewItemForm.formData.bookingType.value" :disabled="$ability.cannot('update_production_crew', this.getPermissionSubject)">
                            <option value="" v-if="bookingTypes.length === 0">None</option>
                            <option v-for="bookingType in bookingTypes"
                                    :value="bookingType.id"
                                    :title="bookingType.description">
                                {{bookingType.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="book-help-message bg-danger text-danger" v-if="!retrievingCrew && !availableCrew && crewItemForm.formData.skill.value != ''">
                    No user available with the selected skill
                </div>
                <div class="book-help-message bg-danger text-danger" v-if="invalidDate">
                    "End" time should always be greater than "start" time
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="book-modal-footer-loading" v-if="processing">
                <book-loader :small="true"></book-loader>
                <span class="book-modal-footer-loading-text">saving</span>
            </div>
            <button tabindex="1" class="btn btn-success" @click="submit()" :disabled="(!isFormValid() || !isFormChanged || invalidDate) && !processing">Save</button>
        </div>
    </book-modal>
    <book-notification
        v-if="notification.show"
        componentId="bookProductionCrew"
        :modalTitle="notification.modalTitle"
        :recipients="notification.recipients"
        :groupBy="notification.groupBy"
        :groupMessage="notification.groupMessage"
        :defaultEmailSubject="getDefaultEmailSubject()"
        :defaultEmailMessage="getDefaultEmailMessage()"
        :defaultSmsMessage="getDefaultSmsMessage()"
        @close="notification.show = false">
    </book-notification>
</div>
</template>

<style lang="scss">
.book-production-crew {
    min-height: 55px;
    position: relative;
    .book-production-crew-contact-buttons {
        position: absolute;
        right: 3px;
        top: -25px;
    }
    .book-table {
        border: none;
    }
    .table {
        margin-bottom: 0;
    }
    .book-table-no-results {
        min-height: 55px;
    }
}
</style>
