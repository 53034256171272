
import Api from 'components/app/Api'
import {app} from 'app'
import store from 'store'
import _ from "lodash";

class Equipment extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/equipment',
            module: 'equipment',
        })
    }

    /**
     * Determine if an equipment should be visible or not, based
     * on the current user's country.
     * @param object equipment An equipment piece.
     * @return boolean
     */
    static isVisible(equipment, status = false) {
         if (!equipment.country || equipment.notCountryRestricted) {
             return true
         }
        if (status) return true
        return _.includes(equipment.country, app.$data.user.get.country) ||
            _.intersection(app.$data.user.get.country, equipment.source).length > 0 ||
            _.intersection(app.$data.user.get.country, equipment.destination).length > 0
    }

    /**
     * Check if the passed item is an equipment piece or just a equipment
     * type, e.g. a entity item.
     * @param object item
     * @return boolean
     */
    static isEquipmentPiece(item) {
        let value = this.getItemIdAndType(item.id)
        //console.log(value, 'isEquipmentPiece);
        if (value.type) {
            return value.type === 'equipmentPiece'
        }
        return (item.hasOwnProperty('elementType') && item.elementType == 'equipmentPiece') || item.hasOwnProperty('equipmentStatus')
    }

    /**
     * Get an equipment by its id.
     * @param integer id The id of the entity item.
     * @return object
     */
    static getItem(id) {
        //console.log(this.get, 'getItem Equip');
        return this.get ? this.get.items.find(v => v.id === id) : null
    }

    /**
     * Extract the id and type for an equipment element from the passed id.
     * @param string|integer id
     * @return object
     */
    static getItemIdAndType(id) {
        const output = {
            id: 0,
            type: ''
        }
        if (typeof id === 'string' && id.indexOf('#') > -1) {
            let value = id.split('#')
            output.id = parseInt(value[1].trim())
            output.type = value[0].trim()
        }
        else {
            output.id = parseInt(id)
        }
        return output
    }

    /**
     * Get the raw type of the given item, in order to make table work work.
     * @param object item
     * @return string
     */
    static getItemTypeRaw(item) {
        return this.isEquipmentPiece(item) ? item.type : item.label
    }

    /**
     * Get the type of the given item to be presented in the table.
     * @param object item
     * @return string
     */
    static getItemType(item, entity) {
        if (this.isEquipmentPiece(item)) {
            if (entity != null){
                const type = entity.getItem(item.type)
                return type ? type.label : null
            }else{
                return null
            }
            
        }
        return item.label
    }

    /**
     * Get label for equipmet source.
     * @param string source
     * @param object entity
     * @return string
     */
    static getSourceLabelById(source, entity) {
        let val = isNaN(parseInt(source)) ? source : parseInt(source)

        if( Object.keys(store.state.system.countries).length > 0 ) {
            let cnt = _.find(store.state.system.countries, c => c.code === val)
            if(cnt !== undefined) {
                return cnt.label
            }
        }

        if(Object.keys(entity.get.items).length > 0) {
            let mcr = _.find(entity.get.items, m => m.id === val)
            if(mcr !== undefined) {
                return mcr.label
            }
        }

        return ''
    }

    /**
     * Get labels for equipmet destination.
     * @param array destination
     * @param string source
     * @param object entity
     * @return string
     */
    static getDestinationsLabels(destinations, source, entity) {
        const context = this
        //let val = isNaN(parseInt(source)) ? source : parseInt(source)
        //let scrIsMcr = !isNaN(parseInt(source))
        let destArr = []
        destinations.forEach(d => {
            //let dVal = isNaN(parseInt(d)) ? d : parseInt(d)

            //if(!scrIsMcr || (scrIsMcr && dVal !== val)) {
                destArr.push(context.getSourceLabelById(d, entity))
            //}
        })
        
        return destArr.join(', ')
    }
}

export default Equipment