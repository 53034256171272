<script>
import Acl from 'components/user/Acl'
import Role from 'components/user/Role'
import User from 'components/user/User'
import BookUsersActionMenu from 'components/user/BookUsersActionMenu';
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookUsersActionMenu,
        BookLoader
    },
    data() {
        return this.defaultData()
    },
    computed: {
        roles() {
            return this.$store.state.user.roles
        },
        loaded() {
            return this.roles && !this.$loader.has('rolesLoad')
        },
        processing() {
            return this.$loader.has('BookRolesProcessing')
        },
        formIsValid() {
            const parent = this
            let valid = true
            Lazy(parent.newRole)
            .each(function(v, k) {
                if (!v.valid) {
                    valid = false
                }
            })
            return valid
        },
    },
    mounted() {
        const parent = this
        parent.$loader.add('rolesLoad')
        Acl.getRoles()
            .then(response => {
                parent.resetRoleEditStatus()
                parent.$loader.remove('rolesLoad')
            })
            .catch(error => {
                parent.$error.set(error, 'It was not possible to load the roles.')
                parent.$loader.remove('rolesLoad')
            })
    },
    methods: {
        addRole() {
            const parent = this
            parent.$loader.add('BookRolesProcessing')
            const role = new Role({
                id: 0,
                name: parent.newRole.name.value,
                label: parent.newRole.label.value
            })
            role.store()
            .then(response => {
                parent.resetForm('role_new')
                parent.resetRoleEditStatus()
                parent.$loader.remove('BookRolesProcessing')
            })
            .catch(error => {
                parent.$error.set(error, 'It was not possible to add the role.')
                parent.$loader.remove('BookRolesProcessing')
            })
        },
        saveRole(id) {
            const parent = this
            parent.$loader.add('BookRolesProcessing')
            const role = new Role(this.$store.state.user.roles[id])
            role.save()
            .then(response => {
                this.$alert.set(`Role ${response.data.id} was updated.`, 'success', 4)
                parent.resetForm('role_' + id)
                parent.editRole(id, false, true)
                parent.$loader.remove('BookRolesProcessing')
            })
            .catch(error => {
                parent.$error.set(error, 'It was not possible to update this role.')
                parent.$loader.remove('BookRolesProcessing')
            })
        },

        /**
         * Edit a current role enabling the input field in the form.
         *
         * @param id integer
         * @param status boolean The status to set the edit form to. "False" means
         *                       that the form should be hidden.
         * @param reset boolean (optional) If "true" the old value won't be set
         *                      back to the roles model, when the edit form is
         *                      disabled.
         */
        editRole(id, status, reset = false) {
            Lazy(this.edit)
            .each((v, k) => {
                this.edit[k] = false
            })
            this.edit[id] = status

            // Make a copy of the original value in order to set it back in case
            // the user cancel the editing.
            if (status) {
                this.oldValues[id] = {}
                Vue.util.extend(this.oldValues[id], this.roles[id])
            }
            else if (!reset) {
                this.$store.commit('user/setRole', this.oldValues[id])
                delete this.oldValues[id]
            }

        },

        /**
         * Delete a role.
         */
        deleteRole(id) {
            const context = this
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete role ' + id + '?',
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                context.$loader.add('BookRolesProcessing')
                Acl.deleteRole(id)
                .then(response => {
                    delete context.roles[id]
                    this.$alert.set(response.data, 'success', 4)
                    context.$loader.remove('BookRolesProcessing')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to delete the role')
                    context.$loader.remove('BookRolesProcessing')
                })
            })
        },
        resetForm(scope) {
            if (scope == 'role_new') {
                Object.assign(this.$data, this.defaultData())
            }
            const context = this
            setTimeout(function() {
                context.formErrors.clear(scope)
                context.resetDirty()
            }, 1)
        },

        /**
         * VeeValidate misses a function to set all "dirty" flags at once, why
         * we need a function to reset all "dirty" flags.
         */
        resetDirty() {
            const context = this
            Lazy(context.formFields.fields)
            .each(function(v, k) {
                v.dirty = false
            })
        },

        /**
         * Reset the edit status of the roles.
         */
        resetRoleEditStatus() {
            Lazy(this.roles)
            .each((v, k) => {
                Vue.set(this.edit, v.id, false)
            })
        },

        isFormValid(scope) {
            scope = '$' + scope
            return this.formFields[scope] &&  Object.keys(this.formFields[scope]).every(k => {
                return this.formFields[scope][k].valid
            });
        },
        isFormClean(scope) {
            scope = '$' + scope
            return this.formFields[scope] &&  Object.keys(this.formFields[scope]).every(k => {
                return this.formFields[scope][k].pristine
            });
        },
        isFormDirty(scope) {
            scope = '$' + scope
            return this.formFields[scope] &&  Object.keys(this.formFields[scope]).some(k => {
                return this.formFields[scope][k].dirty
            });
        },
        defaultData() {
            return {
                edit: {},
                oldValues: {},
                newRole: {
                  name: {
                      value: '',
                      validator: {
                          rules: {
                              required: true,
                              alpha_dash: true,
                              min: 3,
                              max: 30
                          }
                      }
                  },
                  label: {
                      value: '',
                      validator: {
                          rules: {
                              required: true,
                              min: 3,
                              max: 50
                          }
                      }
                  }
                }
            }
        }
    }
}
</script>

<template>
<div id="users-roles" class="admin book-top-sticky-wrapper col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 container">
    <div class="page-header book-top-sticky clearfix">
        <h2 class="page-title">Manage roles</h2>
        <book-users-action-menu></book-users-action-menu>
    </div>
    <book-loader v-if="!roles"></book-loader>
    <div class="panel panel-info" v-else>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Label</th>
                        <th></th>
                    </tr>
                </thead>
                <transition-group name="slide-down-right-fade" tag="tbody" mode="out-in">
                    <tr v-bind:key="'newRole'" class="cell-vertical-align-middle active">
                        <td>
                          New
                        </td>
                        <td>
                            <div :class="{'has-error': formErrors.has('role_new.name')}">
                                <input v-validate="newRole.name.validator" data-vv-scope="role_new" name="name" type="text" class="form-control" v-model:trim="newRole.name.value">
                            </div>
                        </td>
                        <td>
                            <div :class="{'has-error': formErrors.has('role_new.label')}">
                                <input v-validate="newRole.label.validator" data-vv-scope="role_new" name="label" type="text" class="form-control" v-model:trim="newRole.label.value">
                            </div>
                        </td>
                        <td class="action">
                            <button class="btn btn-success btn-xs" @click="addRole()" :disabled="processing || !isFormValid('role_new') || isFormClean('role_new') || formErrors.any('role_new')"><font-awesome-icon icon="plus"/></button>
                        </td>
                    </tr>
                    <tr v-for="role in roles" v-if="role.id > 0" v-bind:key="role.id" class="cell-vertical-align-middle" >
                        <td>
                            {{role.id}}
                        </td>
                        <td>
                            <p class="form-control-static" v-if="!edit[role.id]">{{role.name}}</p>
                            <div :class="{'has-error': formErrors.has('role_' + role.id + '.name_' + role.id)}">
                                <input v-if="edit[role.id]" v-validate="newRole.name.validator" :data-vv-scope="'role_' + role.id" :name="'name_' + role.id" type="text" class="form-control" v-model:trim="role.name">
                            </div>
                        </td>
                        <td>
                            <p class="form-control-static" v-if="!edit[role.id]">{{role.label}}</p>
                            <div :class="{'has-error': formErrors.has('role_' + role.id + '.label_' + role.id)}">
                                <input v-if="edit[role.id]" v-validate="newRole.label.validator" :data-vv-scope="'role_' + role.id" :name="'label_' + role.id" type="text" class="form-control" v-model:trim="role.label">
                            </div>
                        </td>
                        <td class="action">
                            <button class="btn btn-success btn-xs" v-if="edit[role.id]" @click="saveRole(role.id)" :disabled="processing || !isFormDirty('role_' + role.id) || formErrors.any('role_' + role.id)"><font-awesome-icon icon="save"/></button>
                            <button class="btn btn-default btn-xs" v-if="edit[role.id]" @click="editRole(role.id, false)" :disabled="processing"><font-awesome-icon icon="times"/></button>
                            <button class="btn btn-primary btn-xs" v-if="!edit[role.id] && $ability.can('manage', 'Roles')" @click="editRole(role.id, true)" :disabled="processing"><font-awesome-icon icon="pencil-alt"/></button>
                            <button class="btn btn-danger btn-xs" v-if="!edit[role.id] &&$ability.can('manage', 'Roles')" @click="deleteRole(role.id)" :disabled="processing"><font-awesome-icon icon="trash"/></button>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>
    </div>
</div>
</template>
