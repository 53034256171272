
import Api from 'components/app/Api'

class Signoff extends Api {

    /**
     * Constructor.
     * @param integer id The id to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/signoff',
            module: 'signoff',
        })
    }

     /**
     * Send a notification according to the passed parameters.
     * @param int itemId
     * @param string itemType
     * @return promise
     */
    static getByItemId(itemId, itemType) {
        let params = {
            itemId: itemId,
            itemType: itemType
        }
        return new Promise((resolve, reject) => {
            axios.get('api/signoffbyitem', { params: params })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default Signoff
