<script>
import {app} from 'app'
import Datetime from 'components/common/helpers/Datetime'
import BookLoader from 'components/common/loader/BookLoader'
import BookDatetime from 'components/common/BookDatetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookSelectbox from 'components/common/BookSelectbox'
import User from 'components/user/User'
import ProductionWorkgroup from 'components/production/ProductionWorkgroups/ProductionWorkgroup'
import BookNotification from 'components/notification/BookNotification'
import multiselect from 'vue-multiselect'
export default {
     components: {
        BookLoader,
        BookDatetime,
        BookTable,
        BookModal,
        BookSelectbox,
        BookNotification,
        multiselect
    },
    props: [
        'production', 
        'workgroupsEntity', 
        'planTypeEntity', 
        'items', 
        'planItems',
        'disabled',
        'disabledMessage', 
        'local', 
        'hideContact'
    ],
    data() {
        return {
            loaded: false,
            workgroupItemForm: {
                show: false,
                loaded: false,
                formData: this.defaultData()
            },
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
                groupMessage: '',
                groupBy: '',
                defaultEmailMessage: '',
                messageTemplate: ''
            },
        }
    },
    mounted() {

        // Event emitted when a production is saved and the dates have been changed.
        this.$root.$on('notifyWorkgroupMembers_changedDates', () => {
            this.notification.messageTemplate = 'changedDates'
            this.showNotificationModal()
        })

        Vue.nextTick(() => {
            this.loaded = true
        })
    },
    watch: {
        'workgroupItemForm.formData.country.value': function(newValue){
            const context = this
            if(context.getWorkgroupList.findIndex(i => i.workgroupItem.id === context.workgroupItemForm.formData.workgroup.value) === -1) {
                context.workgroupItemForm.formData.workgroup.value = ''
            }
        }
    },
    computed: {
        processing() {
            return this.$loader.has('bookProductionWorkgroupSubmit') || this.$loader.has('bookProductionWorkgroupDeleteItem')
        },
        isFormValid() {
            return this.workgroupItemForm.formData.startPlan.value !== "" && 
                   this.workgroupItemForm.formData.endPlan.value !== "" &&
                   this.workgroupItemForm.formData.country.value.length > 0 &&
                   this.workgroupItemForm.formData.workgroup.value !== ""
        },
        isFormChanged() {
            const context = this
            let changed = false
            let currentItem = null
            if (context.workgroupItemForm.formData.id.value && context.workgroupItemForm.formData.id.value !== 0) {
                currentItem = context.getWorkgroupItems.find(i => i.id === context.workgroupItemForm.formData.id.value)
            }

            Lazy(context.workgroupItemForm.formData)
            .each(function(v, k) {
                    if (changed) return true
                    if(currentItem) {
                        if(k === 'country') {
                            let formCountry = v.value && v.value.length > 0 ? v.value.map(i => i.code) : []
                            let originCountry = currentItem[k] ? currentItem[k].split(",") : []
                            changed = formCountry.length !== originCountry.length || (_.xor(formCountry, originCountry)).length > 0
                        }
                        else {
                            changed = v.value && v.value !== currentItem[k]
                        }
                    }
                    else {
                        if(k === 'country') {
                            changed = v.value && v.value.length > 0
                        }
                        else {
                            changed = v.value && v.value !== ""
                        }
                        
                    }
                    
            })

            return changed
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        getWorkgroupList() {
            // if (this.workgroupItemForm.formData.country.value != '') {
            //     return Lazy(this.workgroupsEntity.get.items)
            //         .filter(v => {
            //             return v.country == this.workgroupItemForm.formData.country.value
            //         })
            //         .toArray()
            // }
            // return this.workgroupsEntity.get.items

            const context = this
            const workgroupsArr = []
            const workgroupsItems = context.workgroupsEntity.get.items
            const cnt = context.workgroupItemForm.formData.country.value ? context.workgroupItemForm.formData.country.value : []
            workgroupsItems.forEach(workgroupsItem => {
                let wgrCountries = workgroupsItem.description.split(',')
                let wgrCountriesFiltered = wgrCountries.length > 0 && cnt.length > 0 ? wgrCountries.filter(c => cnt.some(m => m.code === c)) : []
                if(wgrCountriesFiltered.length > 0) {
                    let missedMCRs = []
                    if(cnt.length !== wgrCountriesFiltered.length) {
                        missedMCRs = cnt.filter(m => !wgrCountriesFiltered.some(s=> m.code === s)).map(m => m.label)
                    }
                    workgroupsArr.push({'workgroupItem': workgroupsItem, 'warn': cnt.length !== wgrCountriesFiltered.length, 'missedMCRs': missedMCRs.join(', ') })
                }
            })
            return workgroupsArr
        },
        getWorkgroupItems() {
            return Lazy(this.items).sortBy(item => item.workgroupDetails ? item.workgroupDetails.label : item.workgroup).toArray()
        },
        showWorkgroupWarning(){
            let warn = false
            let warnMessage = ""
            const context = this
            if(context.workgroupItemForm.formData.workgroup.value && context.workgroupItemForm.formData.workgroup.value !== '') {
                let currentSelectedWorkgroup = context.getWorkgroupList.find(v => v.workgroupItem.id === context.workgroupItemForm.formData.workgroup.value)
                if(currentSelectedWorkgroup !== undefined && currentSelectedWorkgroup.warn) {
                    warn = true
                    warnMessage = `This workgroup is not available for ${currentSelectedWorkgroup.missedMCRs}`
                }
                //warn = currentSelectedSatellite ? currentSelectedSatellite.warn : true
                //missedMCRs = currentSelectedSatellite ? currentSelectedSatellite.missedMCRs : ""
            } else if (context.workgroupItemForm.formData.workgroup.value && context.workgroupItemForm.formData.workgroup.value !== '') {
                warn = true
                warnMessage = `This workgroup does not exist in the workgroups list. Please pick a valid workgroup`
            }

            return { 'warn': warn, 'warnMessage': warnMessage}
        },
        productionWorkgroupData() {
            const context = this
            const output = {}
            output.options = {
                actions: [],
                actionSettings: {
                    edit: {
                        label: 'Open',
                    }
                },
            }

            if (this.$ability.can('update_production_workgroup', 'Productions')) {
                output.options.actions.push('edit')
            }
            if (this.$ability.can('delete_production_workgroup', 'Productions')) {
                output.options.actions.push('delete')
            }

            if (!context.hideContact && this.$ability.can('send_notifications', 'Miscellaneous')) {
                output.options.actions.unshift('contact')
                output.options.actionSettings.contact = {
                    label: '',
                    classes: ['btn', 'btn-info', 'btn-xs']
                }
            }

             output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    sortable: false,
                    visible: false,
                },
                {
                    value: 'start',
                    label: 'Start',
                    sortable: true,
                },
                {
                    value: 'end',
                    label: 'End',
                    sortable: true,
                },
                {
                    value: 'workgroup',
                    label: 'Workgroup',
                    sortable: true,
                },
                {
                    value: 'country',
                    label: 'Country',
                    sortable: true,
                }
             ]

             output.rows = []

            context.getWorkgroupItems.forEach(v => {
                let countryLabels = []
                if(v.country) {
                    v.country.split(',').forEach(c => {
                        let cc = context.countries.find(i => i.code === c)
                        if(cc) {
                            countryLabels.push(cc.label)
                        }
                    })
                }
                const row = {
                    id: {
                        value: v.id
                    },
                    start: {
                        rawValue: context.getStart(v, 'start').raw,
                        value: context.getStart(v, 'start').value
                    },
                    end: {
                        rawValue: context.getStart(v, 'end').raw,
                        value: context.getStart(v, 'end').value
                    },
                    workgroup: {
                        rawValue: v.workgroup,
                        value: v.workgroupDetails ? v.workgroupDetails.label : ''
                    },
                    country: {
                        rawValue: v.country,
                        value: countryLabels.length > 0 ? countryLabels.join(', ') : ''
                    },
                    rowOptions: {
                        classes: [],
                        actions: []
                    }
                }

                output.rows.push(row)
            })

            if (output.rows.length > 0)
                return output
            return {
                rows: []
            }
        },
        hasPlan() {
            return this.getPlanItems && this.getPlanItems.length > 0
        },
        // isFormvalid() {
        //     return true
        // },
        // isFormChanged() {
        //     return true
        // }
    },
    methods: {
        /**
         * Get a label based on passed plan item.
         */
        getPlanItemLabel(item, field) {
            let time = this.getDateWithOffset(item)
            const output = []
            output.push(time.format('HH:mm'))
            if (item.type) {
                const planType = this.planTypeEntity.getItem(item.type)
                if (planType) output.push(planType.label)
            }
            else if (item.typeCustom) {
                output.push(item.typeCustom)
            }
            return output.join(' - ')
        },

        /**
         * Get a MomentJS instance with the start date for the passed Plan Item,
         * considering its offset.
         * @param object item A Plan Item object.
         * @return object MomentJS instance.
         */
        getDateWithOffset(item) {
            return moment(this.production.get[this.getDateField(item.relativeTo)]).add(item.offset, 's')
        },
        getDateField(relativeTo) {
            return 'production' + relativeTo.capitalize()
        },
        /**
         * Get the plan items to be presented in a selectbox, based on the
         * passed field.
         * @param string field
         * @return array
         */
        getPlanItems(field) {
            const planItems = this.local ? this.planItems : this.production.get.plan
            return Lazy(planItems)
            .filter(item => item.relativeTo == field)
            .sortBy(item => item.offset)
            .toArray()
        },

        /**
         * Retrieve a plan item from the current production, by its id.
         * @param integer id
         * @return object
         */
        getPlanItem(id) {
            const planItems = this.local ? this.planItems : this.production.get.plan
            return planItems.find(v => v.id == id)
        },
        /**
         * Get a start datetime for a given item (crew member).
         * @param object crewMember
         * @param string field The name of the field to retrieve: "start" or "end"
         * @return obejct
         */
        getStart(workgroup, field = 'start') {
            const output = {raw: '', value: ''}
            let planItem = this.getPlanItem(workgroup[field + 'Plan'])

            // Return a real date including the calculated offset or just the offset.
            
                let referenceTime = planItem ? this.getDateWithOffset(planItem) : null
                
                if (referenceTime) {
                    output.raw = referenceTime.format('YYYY-MM-DD HH:mm:ss')
                    output.value = referenceTime.format('DD MMM, HH:mm')
                }
            

            return output
        },
        
        showNotificationModal(open = true, recipient = 'all') {
            this.notification.recipients = []
            switch (recipient) {
                case 'all':
                    this.notification.modalTitle = 'Contact members for all workgroups'
                    // this.notification.groupBy = 'status'
                    // this.notification.groupMessage = 'Which crew members should receive this message?'
                    Lazy(this.items)
                        .each(v => {
                            if(v.users) {
                                Lazy(v.users).each(v2 => {
                                    this.notification.recipients.push({
                                        email: v2.email,
                                        phone: v2.phone,
                                        id: v2.user_id,
                                        label: v2.name,
                                    })
                                })
                            }
                        })
                    break
                default:
                    const workgroup = this.items.find(v => v.id == recipient)
                    if (workgroup && workgroup.users) {
                        Lazy(workgroup.users).each(v2 => {
                                this.notification.recipients.push({
                                    email: v2.email,
                                    phone: v2.phone,
                                    id: v2.user_id,
                                    label: v2.name,
                                })
                            })
                        
                        this.notification.modalTitle = `Contact  members for ${workgroup.workgroupDetails.label}`
                        // this.notification.groupBy = 'status'
                        // this.notification.groupMessage = ''
                    }
                    break
            }
            if (this.notification.recipients.length > 0) {
                this.notification.show = open
            }
            else {
                this.notification.messageTemplate = ''
                this.notification.show = false
                this.$alert.set('No recipients are available.', 'danger', 4)
            }
            if (!open) {
                this.notification.messageTemplate = ''
            }
        },
        showWorkgroupItemForm(open = true, id = null) {
            const context = this
            if (!context.production) return
            context.resetForm()
            if (open) {
                 if (id) {
                    const item = context.getWorkgroupItems.find(v => v.id == id)
                    if (item) {
                        context.workgroupItemForm.formData.id.value = item.id
                        context.workgroupItemForm.formData.startPlan.value = item.startPlan
                        context.workgroupItemForm.formData.endPlan.value = item.endPlan
                        let itemCountries = item.country ? item.country.split(',') : []
                        itemCountries.forEach(c => {
                            let currentCnt = context.countries.find(i => i.code === c)
                            if(currentCnt) {
                                context.workgroupItemForm.formData.country.value.push(currentCnt)
                            }
                        })
                        context.workgroupItemForm.formData.workgroup.value = item.workgroup
                        context.workgroupItemForm.loaded = true
                    }
                    else {
                        context.workgroupItemForm.loaded = true
                    }
                 }
                 else {
                    context.workgroupItemForm.formData.startPlan.value = context.getPlanItems('start')[0].id
                    context.workgroupItemForm.formData.endPlan.value = context.getPlanItems('end')[0].id

                    let currentCnt = context.countries.find(i => i.code === app.$data.user.get.country)
                    if(currentCnt) {
                        context.workgroupItemForm.formData.country.value.push(currentCnt)
                    }
                    
                    context.workgroupItemForm.loaded = true
                 }

                 context.workgroupItemForm.show = true
            }
            else {
                context.workgroupItemForm.loaded = false
                context.workgroupItemForm.show = false
            }
        },
        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this workgroup?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    context.$loader.add('bookProductionWorkgroupDeleteItem')
                    const productionWorkgroup = context.getClass(id)
                    let successMsg = 'The workgroup has been removed.'
                    if (context.local) {
                        context.removeWorkgroup(id)
                        context.$alert.set(successMsg, 'success', 3)
                        context.$loader.remove('bookProductionWorkgroupDeleteItem')
                    }
                    else {
                        productionWorkgroup.delete()
                        .then(response => {
                            context.removeWorkgroup(id)
                            context.$alert.set(successMsg, 'success', 3)
                            context.$loader.remove('bookProductionWorkgroupDeleteItem')
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to remove the workgroup.')
                            context.$loader.remove('bookProductionWorkgroupDeleteItem')
                        })
                    }
                })
        },
        removeWorkgroup(id) {
            const workgroups = this.items.slice(0)
            let i = workgroups.findIndex(v => v.id == id)
            if (i > -1) {
                workgroups.splice(i, 1)
                this.$emit('changedWorkgroup', workgroups)
                if (!this.local) this.production.set('workgroups', workgroups)
            }
        },
        /**
         * Reset the crew member form.
         */
        resetForm() {
            this.workgroupItemForm.formData = this.defaultData()
        },
         /**
         * Default subject to be used when sending e-mail to crew members.
         * @return string
         */
        getDefaultEmailSubject() {
            return `Task: ${this.production.get.title}`
        },

        /**
         * Default message to be used when sending e-mail to crew members.
         * @return string
         *
         * @TODO Should the default messages be "editable" through the admin UI?
         */
        getDefaultEmailMessage() {
            const output = []
            // switch (this.notification.messageTemplate) {
            //     case 'changedDates':
            //         output.push('This production has been changed.\n')
            //         output.push(`Production: ${this.production.get.title}`)
            //         output.push(`Start: ${this.production.get.productionStart}`)
            //         output.push(`End: ${this.production.get.productionEnd}`)
            //         break
            //     case 'crewRequest':
            //         output.push('You have been requested to work in the following production.\n')
            //         output.push(`Production: ${this.production.get.title}`)
            //         output.push(`Start: ${this.production.get.productionStart}`)
            //         output.push(`End: ${this.production.get.productionEnd}`)
            //         break
            //     default:
                    
            //         break
            // }

            output.push(`Task: ${this.production.get.title}`)
            output.push(`/${app.$data.user.get.fullName}`)
            return output.join('\n')
        },

        /**
         * Just duplicates get email text function. Maybe we will need here another text later
         */
        getDefaultSmsMessage(){
            const output = []
            // switch (this.notification.messageTemplate) {
            //     case 'changedDates':
            //         output.push('This production has been changed.\n')
            //         output.push(`Production: ${this.production.get.title}`)
            //         output.push(`Start: ${this.production.get.productionStart}`)
            //         output.push(`End: ${this.production.get.productionEnd}`)
            //         break
            //     case 'crewRequest':
            //         output.push('You have been requested to work in the following production.\n')
            //         output.push(`Production: ${this.production.get.title}`)
            //         output.push(`Start: ${this.production.get.productionStart}`)
            //         output.push(`End: ${this.production.get.productionEnd}`)
            //         break
            //     default:
                   
            //         break
            // }

            output.push(`Task: ${this.production.get.title}`)
            output.push(`/${app.$data.user.get.fullName}`)
            return output.join('\n')
        },
        /**
         * Get a ProductionCrew class, based on the current form data.
         * @return class|null
         */
        getClass(id = null) {
            id = id || this.workgroupItemForm.formData.id.value
            const productionWorkgroup = new ProductionWorkgroup(id, this.production.get.id)
            // Since we have our Toggle button, we have to check first our formData and then use the ID, that we sent to complete
            // all of changes at the same time (WN)
            const item = this.items.find(v => v.id == (this.workgroupItemForm.formData.id.value ? this.workgroupItemForm.formData.id.value : id) )
            if (item) {
                productionWorkgroup.add(item)
            }
            return productionWorkgroup
        },
        submit() {
             const context = this
            // context.processing = true
            context.$loader.add('bookProductionWorkgroupSubmit')
            let id = (context.local && !context.workgroupItemForm.formData.id.value) ? moment().unix() : null
            const productionWorkgroup = context.getClass(id)

            if (!productionWorkgroup) return

            Lazy(context.workgroupItemForm.formData)
                //.filter(v => !v.local)
                .each((v, k) => {
                    if (id && (k == 'id')) productionWorkgroup.set('id', id)
                    else if(k == 'country') {
                        if(v.value && v.value.length > 0) {
                            let value = []
                            v.value.forEach(c => {
                                value.push(c.code)
                            })
                            productionWorkgroup.set(k, value.join(','))
                        }
                        else {
                            productionWorkgroup.set(k, null)
                        }
                    }
                    else if(v.value !== "")
                        productionWorkgroup.set(k, v.value)
                    else if(v.value === null)
                        productionWorkgroup.set(k, null)
                })

            if (context.local) {
                context.updateWorkgroup(productionWorkgroup.get)
                context.showWorkgroupItemForm(false)
                context.$loader.remove('bookProductionWorkgroupSubmit')
            }
            else {
                if (!productionWorkgroup.options.id) {
                    productionWorkgroup.store()
                        .then(response => {
                            context.$alert.set('The workgroup has been added.', 'success', 5)
                            context.updateWorkgroup(response.data)
                            context.showWorkgroupItemForm(false)
                            context.$loader.remove('bookProductionWorkgroupSubmit')
                            // if (sendCrewRequest) {
                            //     context.sendCrewRequest(response.id)
                            // }
                        })
                        .catch(error => {
                            let msg = 'It was not possible to save the selected workgroup.'
                            // if (error.response && error.response.status === 409) {
                            //     msg = 'This user is already booked for the selected date/time interval.'
                            // }
                            context.$error.set(error, msg)
                            context.$loader.remove('bookProductionWorkgroupSubmit')
                        })
                }
                else {
                    productionWorkgroup.save()
                        .then(response => {
                            context.$alert.set('The workgroup has been updated.', 'success', 5)
                            context.updateWorkgroup(response.data)
                            context.showWorkgroupItemForm(false)
                            context.$loader.remove('bookProductionWorkgroupSubmit')
                            //if (sendCrewRequest) context.sendCrewRequest(productionWorkgroup.get.id)
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to save the selected workgroup.')
                            context.$loader.remove('bookProductionWorkgroupSubmit')
                        })
                }
            }
        },
         /**
         * Add/update a crew item to the current production.
         * @param object item
         */
        updateWorkgroup(item) {
            const workgroups = this.items.slice(0)
            let i = workgroups.findIndex(v => v.id == item.id)
            if (i > -1) workgroups.splice(i, 1)
            workgroups.push(item)
            this.$emit('changedWorkgroup', workgroups)
            if (!this.local) this.production.set('workgroups', workgroups)
        },
        defaultData() {
            return {
                id: {
                    value: 0
                },
                startPlan: {
                    value: '',
                     validator: {
                        rules: 'required'
                    }
                },
                endPlan: {
                    value: '',
                     validator: {
                        rules: 'required'
                    }
                },
                country: {
                    local: true,
                    value: [],
                     validator: {
                        rules: 'required'
                    }
                },
                workgroup: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                

            }
        }
    }
}
</script>
<template>
    <div class="book-production-workgroup">
        <div class="book-production-workgroup-contact-buttons" v-if="loaded">
            <button
                class="btn btn-info btn-xs"
                title="Contact all workgroups members"
                @click="showNotificationModal(true)"
                v-if="!hideContact && $ability.can('send_notifications', 'Miscellaneous')"
                :disabled="!items || items.length == 0 || disabled">
                <font-awesome-icon icon="envelope"/>&nbsp;&nbsp;Workgroups
            </button>
            <button
                v-if="$ability.can('create_production_workgroup', 'Productions')"
                class="btn btn-success btn-xs"
                title="Add item"
                @click="showWorkgroupItemForm(true)"
                :disabled="disabled">
                <font-awesome-icon icon="plus"/>&nbsp;&nbsp;Add
            </button>
        </div>
        <book-loader v-if="!loaded"></book-loader>
        <book-table
            v-else
            :items="productionWorkgroupData"
            :component="'productionWorkgroups'"
            itemsType='production'
            :notFoundMessage="!disabled || !disabledMessage ? 'No workgroup has been added' : ''"
            @contact="item => showNotificationModal(true, item.id.value)"
            @edit="item => showWorkgroupItemForm(true, item.id.value)"
            @delete="item => deleteItem(item.id.value)">
        </book-table>
        <div class="book-box-overlay" v-if="processing">
            <book-loader :small="true"></book-loader>
        </div>
        <div class="book-box-overlay" v-if="disabled && loaded">
            <span class="label label-danger" v-html="disabledMessage"></span>
        </div>

        <book-modal maxWidth="500px" @close="showWorkgroupItemForm(false)" v-if="workgroupItemForm.show">
            <h4 slot="header" class="modal-title">
                <span v-if="!workgroupItemForm.formData.id.value">Add</span><span v-else>Edit</span> workgroup
            </h4>
            <div slot="body">
                <div class="form-horizontal" data-vv-scope="workgroupItem">
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Start
                        </label>
                        <div class="form-control-100">
                            <select tabindex="1" class="form-control" v-model="workgroupItemForm.formData.startPlan.value" :disabled="!hasPlan || $ability.cannot('update_production_workgroup', 'Productions')">
                                <option v-for="planItem in getPlanItems('start')" :value="planItem.id">{{getPlanItemLabel(planItem, 'start')}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label class="control-label control-label-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            End
                        </label>
                        <div class="form-control-100">
                            <select tabindex="1" class="form-control" v-model="workgroupItemForm.formData.endPlan.value" :disabled="!hasPlan || $ability.cannot('update_production_workgroup', 'Productions')">
                                <option v-for="planItem in getPlanItems('end')" :value="planItem.id">{{getPlanItemLabel(planItem, 'end')}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="name" class="control-label book-width-100">
                            Country
                        </label>
                        <div class="form-control-100" v-if="countries">
                            <multiselect
                                :disabled="$ability.cannot('update_production_workgroup', 'Productions')"
                                v-validate
                                name="mcr"
                                v-model="workgroupItemForm.formData.country.value"
                                track-by="code"
                                label="label"
                                placeholder=""
                                :max-height="200"
                                :options="countries"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="true"
                                :hide-selected="true"
                                :show-labels="false">
                                <span slot="noResult">No countries found.</span>
                            </multiselect>
                        </div>
                        <!-- <div class="form-control-100">
                            <select class="form-control" name="country" v-model="workgroupItemForm.formData.country.value" :disabled="$ability.cannot('update_production_workgroup', 'Productions')">
                                <option value="">None</option>
                                <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="form-group form-group-sm" :class="{'has-error': formErrors.has('workgroupItem.workgroup')}">
                        <label for="name" class="control-label book-width-100">
                            <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                            Workgroup
                            <font-awesome-icon icon="exclamation-triangle" class="book-selectbox-item-icon" aria-hidden="true" v-if="showWorkgroupWarning.warn"/>
                        </label>
                        <div class="form-control-100">
                            <select tabindex="1" class="form-control" name="workgroup" v-validate v-model="workgroupItemForm.formData.workgroup.value" v-if="workgroupsEntity" :disabled="$ability.cannot('update_production_workgroup', 'Productions')">
                                <option value="">None</option>
                                <option v-for="workgroup in getWorkgroupList" :value="workgroup.workgroupItem.id">{{workgroup.workgroupItem.label}}</option>
                            </select>
                            <span class="label label-danger" v-if="showWorkgroupWarning.warn">{{showWorkgroupWarning.warnMessage}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="book-modal-footer-loading" v-if="processing">
                    <book-loader :small="true"></book-loader>
                    <span class="book-modal-footer-loading-text">saving</span>
                </div>
                <button tabindex="1" class="btn btn-success" @click="submit()" :disabled="processing || !isFormValid || !isFormChanged">Save</button>
            </div>
        </book-modal>

        <book-notification
            v-if="notification.show"
            componentId="bookProductionWorkgroup"
            :modalTitle="notification.modalTitle"
            :recipients="notification.recipients"
            :defaultEmailSubject="getDefaultEmailSubject()"
            :defaultEmailMessage="getDefaultEmailMessage()"
            :defaultSmsMessage="getDefaultSmsMessage()"
            @close="notification.show = false">
        </book-notification>
    </div>    
</template>

<style lang="scss">
.book-production-workgroup {
    min-height: 55px;
    position: relative;
    .book-production-workgroup-contact-buttons {
        position: absolute;
        right: 3px;
        top: -25px;
    }
    .book-table {
        border: none;
    }
    .table {
        margin-bottom: 0;
    }
    .book-table-no-results {
        min-height: 55px;
    }
}
</style>