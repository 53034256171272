import Alert from 'components/common/alert/Alert'

class ErrorHandler {
    static set(error, defaultMsg = null) {
        let msg = []
        if (error.response) {
            switch (true) {
                case (typeof error.response.data == 'object' && error.response.data.hasOwnProperty('errors')):
                    Lazy(error.response.data.errors)
                    .each((v, k) => {
                        msg.push(`<strong>${k.capitalize()}</strong>: ${v}<br />`)
                    })
                    break
                case (typeof error.response.data == 'object' && error.response.data.hasOwnProperty('message')):
                    msg.push(error.response.data.message)
                    break
                case (typeof error.response.data == 'string'):
                    msg.push(error.response.data)
                    break
            }
        }
        else {
            console.log(error)
            if (error.message) {
                msg.push(error.message)
            }
            else {
                msg.push(error)
            }
        }
        if (msg.length > 0) {
            msg = msg.join('')
        }
        else {
            msg = defaultMsg
        }
        if (msg) {
            Alert.set(msg, 'danger', this.getDuration(msg))
        }
    }
    static getDuration(value) {
        if (typeof value !== 'string') return 5
        let duration = Math.round(value.length / 8)
        if (duration > 15) duration = 10
        if (duration < 4) duration = 4
        return duration
    }
}

export default ErrorHandler
