/**
 * Production types description.
 */
const productionTypes = {
    production: {
        label: 'Production',
        fields: [
            'league',
            'productionLeader',
            'country',
            'runType',
            'channel',
            'locationId',
            'plan',
            'crew',
            'audioLayout',
            'notes',
            'localNotes',
            'comments',
            'contacts',
            'equipment',
            'productionType',
            'comments',
            'tvProduction',
            'houseNumber',
            'signoffs',
        ],
        filters: ['search', 'country', 'sport', 'league', 'channel', 'runType'],
        views: ['overview', 'list', 'timeline'],
        features: ['print'],
    },
    eng: {
        label: 'ENG',
        fields: [
            'league',
            'productionLeader',
            'country',
            'plan',
            'crew',
            'notes',
            'localNotes',
            'comments',
            'contacts',
            'equipment',
            'productionType',
            'houseNumber',
            'signoffs',
        ],
        filters: ['search', 'country', 'sport', 'league'],
        views: ['overview', 'timeline', 'equipmentOverview'],
        features: ['print'],
    },
    edit: {
        label: 'Edit',
        fields: [
            'league',
            'productionLeader',
            'country',
            'plan',
            'crew',
            'notes',
            'localNotes',
            'comments',
            'contacts',
            'equipment',
            'productionType',
            'houseNumber',
            'signoffs',
        ],
        filters: ['search', 'country', 'sport', 'league'],
        views: ['overview', 'timeline', 'equipmentOverview'],
        features: ['print'],
    },
    car: {
        label: 'Car',
        fields: [
            'country',
            'productionType',
            'driverId',
            'equipment',
            'bookedBy',
        ],
        filters: ['search', 'country'],
        views: ['overview', 'timeline', 'equipmentOverview'],
        features: [],
    },
}

/**
 * Gets permission subject from production type.
 */
function getPermissionSubjectFromProductionType(type) {
    const map = {
        'production': 'Productions',
        'eng': 'ENG',
        'car': 'Car',
        'edit': 'Edit',
    }

    // Fallback to Production subject if the type does not have its own permission set.
    return map[type] ?? 'Productions'
}

export { getPermissionSubjectFromProductionType }

export default productionTypes;