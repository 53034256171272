/**
 * This class contains common functions for feed and production items.
 */

import {app} from 'app'
import store from 'store'
import _ from "lodash";

class TaskItem {

    /**
     * Parse an item and add some extra fields.
     * @return void This doesn't return anything, but "item" is changed by reference.
     */
    static parse(item, type = '', now = 0, props = {}) {

        // The item doesn't need to be parsed.
        if (item.status && item.startTime && item.endTime) {
            return
        }

        const context = this

        this.parsingFix(item) // parsing fix WN

        if (now == 0) now = store.state.app.now

        // Set the element type, through its "Start" date field.
        if (!type) {
            let field = Object.keys(item).find(v => v.indexOf('Start') > -1)

            if (field) {
                let i = field.indexOf('Start')
                type = field.substring(0, i).toLowerCase()
            }
        }


        if (!item.elementType) {
            Vue.set(item, 'elementType', type)          //JW type is blank ?
        }

        if(!item.elementTypeDetailed) {
            if(item.elementType === 'feed') {
                Vue.set(item, 'elementTypeDetailed', type)
            }
            else if(item.productionType) {
                Vue.set(item, 'elementTypeDetailed', item.productionType)
            }
            else {
                Vue.set(item, 'elementTypeDetailed', 'production')
            }
        }


        // Start value.
        let start
        let startField = item[`${type}Start`] ? `${type}Start` : 'start'
        if (!moment.isMoment(item[startField])) {
            start = moment(item[startField], 'yyyy-MM-DD HH:mm:ss')
        }
        else {
            start = item[startField]
        }

        if (!item[`${type}Start`]){
            //console.log("item start time");
            //console.log(type);
            //console.log(start, 'START');
            //console.log(startField);
            //console.log(item);
            //console.log(item[startField]);
        }

        // There was UNIX TimeStamp issue for 2020+ strange, had to fix it
        // by re-momenting times. (WN)

        //Vue.set(item, 'startTime', start.unix())
        Vue.set(item, 'startTime', moment(item[startField]).unix())

        // End value.
        let end
        let endField = item[`${type}End`] ? `${type}End` : 'end'
        if (!moment.isMoment(item[endField])) {
            end = moment(item[endField], 'yyyy-MM-DD HH:mm:ss')
        }
        else {
            end = item[endField]
        }
        Vue.set(item, 'endTime', moment(item[endField]).unix())
        //Vue.set(item, 'endTime', end.unix())

        // Set item status.
        let status = !props.hasOwnProperty('status') ? context.getStatus(item, now) : props.status
        Vue.set(item, 'status', status)

    }


    static parsingFix(item) {
        if (typeof item.id === 'undefined') {
            if (item.associationId === 'undefined') {
                try {
                    item = JSON.parse(item)
                } catch {
                    console.log(item, "item can not be parsed", (item && item.associationId ? item.associationId : item))
                }
            } else {
                item.title = "PLEASE DELETE THIS, BOOKING NOT FOUND OR DELETED"
                item.elementType = 'Unknown'
                item.elementTypeDetailed = 'Unknown'
            }
        }
        return item;
    }

    /**
     * Get the status for a feed/production, which can be used to set the
     * right color in the list/timeline.
     * @param object item The item to retrieve status for.
     * @param integer now (optional) The timestamp for "now".
     * @return string
     */
    static getStatus(item, now = 0) {
        if (!item.signoffs) return app.$store.state.config.ITEM_STATUS.PASSED
        if (now == 0) now = store.state.app.now
        //console.log(item);
        // The "safe interval" for an item is 24 hours (86400 seconds).
        const interval = now + 86400

        // Check if all signoffs in the item are signed.
        let signed = item.signoffs.every(v => v.signed)

        switch (true) {
            case (now > item.endTime):
                return app.$store.state.config.ITEM_STATUS.PASSED
            case (!signed && item.startTime < interval):
                return app.$store.state.config.ITEM_STATUS.UNSIGNED_PRIORITY
            case (!signed && item.startTime >= interval):
                return app.$store.state.config.ITEM_STATUS.UNSIGNED
            default:
                return app.$store.state.config.ITEM_STATUS.SIGNED
        }
    }

    /**
     * Check if a passed item is filtered according to the passed filters.
     * @param object filters
     * @param object item
     * @param object entities League and sport entities that are used in this function.
     * @return boolean
     */
    static isFiltered(filters, item, entities = {}) {

        if(item.productionType != 'task') return false
        // If no filter is provided, just show everything.
        if (Object.keys(filters).length == 0) {
            return true
        }

        // Get all active filters, e.g. all filter items with selected = true.
        let activeFilters = Lazy(filters).filter((v, k) => {
            if (k == 'advanced') {
                return false
            }
            if (Array.isArray(v)) {
                return v.length > 0
            }
            else {
                return v
            }
        }).toObject()

        // If no selected filter items was found, just show everything.
        if (Object.keys(activeFilters).length == 0) {
            return true
        }

        let visible = true

        if(filters.workgroups.length > 0 || filters.activityType.length > 0 || filters.country.length > 0 || filters.search.value) {
            if(filters.country.length > 0) {
                let currentCountry = item.runType &&  entities.activityTypes && entities.activityTypes.get && entities.activityTypes.get.items ? _.find(entities.activityTypes.get.items, i => i.id === item.runType) : null
                if(!currentCountry) return false
                if(!(_.includes(filters.country, "") && (currentCountry.country === null || currentCountry.country === '')) && !_.includes(filters.country, currentCountry.country)) return false
            }

            if(filters.workgroups.length > 0) {
                let currentWorkgroups = item.workgroups && item.workgroups.length > 0 ? item.workgroups.map(i => i.workgroup) : null
                if(!currentWorkgroups) return false
                //console.log(filters.workgroups)
                //console.log(_.intersection(currentWorkgroups, filters.workgroups));
                if((_.intersection(currentWorkgroups, filters.workgroups)).length === 0) return false
            }

            if(filters.activityType.length > 0) {
                let currentActivityType = item.runType ? item.runType : null
                if(!currentActivityType) return false
                if(!_.includes(filters.activityType, currentActivityType)) return false
            }

            if(filters.search.value) {
                if(!item.title.includes(filters.search.value)) return false
            }
        }

        return visible
    }

    static isMcrFiltered(filters, item, entities = {}) {

        if(item.productionType != 'task') return false

        if(!item.workgroups || (item.workgroups && item.workgroups.length === 0)) return false

        let isDisplayed = false

        item.workgroups.forEach(w => {
            if(entities.workgroups && entities.workgroups.get && entities.workgroups.get.items) {
                let currentWorkgroup = entities.workgroups.get.items.find(i => i.id === w.workgroup)
                if(currentWorkgroup && currentWorkgroup.userfield1_value === 'yes') {
                    isDisplayed = true
                }
            }
        });

        if(!isDisplayed) return false

        // If no filter is provided, just show everything.
        if (Object.keys(filters).length == 0) {
            return true
        }

        // Get all active filters, e.g. all filter items with selected = true.
        let activeFilters = Lazy(filters).filter((v, k) => {
            if (k == 'advanced') {
                return false
            }
            if (Array.isArray(v)) {
                return v.length > 0
            }
            else {
                return v
            }
        }).toObject()

        // If no selected filter items was found, just show everything.
        if (Object.keys(activeFilters).length == 0) {
            return true
        }

        let visible = true

        if(filters.country.length > 0 || filters.search.value || filters.elementType.length > 0) {
            if(filters.elementType.length > 0 && !_.includes(filters.elementType, 'task')) return false

            if(filters.country.length > 0) {
                let currentCountry = item.runType &&  entities.activityTypes && entities.activityTypes.get && entities.activityTypes.get.items ? _.find(entities.activityTypes.get.items, i => i.id === item.runType) : null
                if(!currentCountry) return false
                if(!(currentCountry.country === null || currentCountry.country === '') && !_.includes(filters.country, currentCountry.country)) return false
            }

            
            if(filters.search.value) {
                if(!item.title.includes(filters.search.value)) return false
            }
        }

        return visible
    }
}

export default TaskItem
