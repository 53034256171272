/**
 * This is a base mixin/component used by Feeds and Productions.
 */

import {app} from 'app'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import Entity from 'components/entity/Entity'
import Item from 'components/common/item/Item'
import Lock from 'components/common/lock/Lock'
import SocketMixin from 'components/common/item/SocketMixin'
import _ from "lodash";

export default {
    mixins: [
        SocketMixin
    ],
    data() {
        return {
            loaded: false,
            noResult: false,
            filterReady: false,
            selectedItem: null,
            sidebar: false,
            sidebarOpened: false,
            filters: {},
            leagueEntity: null,
            sportEntity: null,
            deliveryTypeEntity: null,
            resolutionEntity: null,
            channelEntity: null,
            runTypeEntity: null,
            ingestEntity: null,
            timeline: {
                startTimestamp: 0,
                endTimestamp: 0,
                groupByLeague: 'league'
            },
            delay: 700,
            clicks: 0,
            timer: null,
        }
    },
    watch: {
        '$route': function(to, from) {
            const context = this
            // @TODO Should we call the API only if the new date interval is
            // outside or bigger then the current one?
            console.log('woo changing')
            context.loadData()
                .then(response => {
                    context.setLoaded()
                })
                .catch(error => {
                    if (error.response && error.response.status !== 404) {
                        context.$error.set(error)
                    }
                    context.setLoaded()
                    context.noResult = true
                })
        },
        'timeline.groupByLeague': function(newValue) {
            this.loaded = false
            Vue.nextTick(() => {
                this.loaded = true
            })
        }
    },
    computed: {
        setting() {
            let settings = this.child.settings ? this.child.settings : this.child.items
            return this.$settings.get[settings]
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        getSelectedItemEquipment() {
            if (!this.selectedItem) return []
            return Lazy(this.selectedItem.equipment)
                .filter(v => _.includes(v.destination, app.$data.user.get.country)/*app.$data.user.get.country === v.country*/)
                .toArray()
        },
        hasPreservedFilters() {
            return !!this.$store.state.filter.preserve
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this[this.child.items].length
            filterInfo.total = this.itemsTotal
            return filterInfo
        },
        itemsTotal() {
            const context = this
            if (!context.loaded || !this.$store.state.data[this.child.item] || !context.filterReady) return 0
            return this.$store.state.data[this.child.item]
                .filter(v => {

                    // On timeline view, only items with start/end inside the current
                    // "from" day should be visible.
                    if (context.$route.params.view == 'timeline') {
                        if (v.startTime > context.timeline.endTimestamp || v.endTime < context.timeline.startTimestamp) return false
                    }

                    return true

                }).length
        },
        loadingSelectedItem() {
            return this.$loader.has('loadingSelectedItem')
        },
        //for new tab
        isEditProduction() {
            return ['edit.view'].indexOf(this.$route.name) > -1
        },
        isEngProduction() {
            return ['eng.view'].indexOf(this.$route.name) > -1
        },
        getApplyTemplateApiUrl() {
            return 'api/' + this.child.item + '/apply-template'
        },
    },
    beforeDestroy() {
        app.$off();
    },
    mounted() {
        const context = this
        if (!context.child) return
        const dataToLoad = []
        //context.setLoaded(false)
        this.$store.commit('remove', {key: context.child.item, id: 0})
        dataToLoad.push(this.loadEntities(context.child.entities))


        Promise.all(dataToLoad)
            .then(response => {
                // Lets try creating filters first? maybe.. Since we are also filtering items after we load even
                // then we can load filtered items faster ~almost 6-8 second
                // Pages that affected: all but MCR-View. (WN)
                context.createFilters() // 24,98 (500 - 600 items) 17,56

                // Now, when all required entities are loaded, try to load the data.
                context.loadData()
                    .then(response => {
                        //context.createFilters() // 32,71 (500 - 600 items) 22,95
                        context.setLoaded()
                    })
                    .catch(error => {
                        if (error.response && error.response.status != 404) {
                            context.$error.set(error, `It was not possible to load the ${this.child.items}`)
                        }
                        context.createFilters()
                        context.setLoaded()
                        context.noResult = true
                    })

            })
            .catch(error => {
                if (error.response && error.response.status != 404) {
                    context.$error.set(error, `It was not possible to load the entities.`)
                }
                context.setLoaded()
                context.noResult = true
            })

        // Listen to ingestion updates.
        app.$on('changedIngestions', (data) => {
            this.updateIngestions(data)
        })
        app.$on('getUpdates', (data) => {
            const ct = this.$route;
            const checkUpdates = (arr, check) => {
                let _checked = false
                Lazy(arr).each(e => { if (check.find(i => i === e.action.module) || check.find(i => i === e.action.name)) { _checked = true } })
                return _checked
            }
            if (ct.name === 'productions.view' && ct.params.view === 'list') {
                if (data.items.find(i => i.itemType === 'production')) {
                    if (checkUpdates(data.items, ['HouseNumber', 'placeholder'])) {
                        this.loadData(true)
                    }
                }
            }
            if (ct.name === 'feeds.view' && ct.params.view === 'list') {
                if (data.items.find(i => i.itemType === 'feed')) {
                    if (checkUpdates(data.items, ['HouseNumber', 'placeholder'])) {
                        this.loadData(true)
                    }
                }
            }

        })

        app.$on('updateRecPlaceholder', (data) => {
            //if (data && data.action) this.updateAction(data)
            this.updateRecPlaceholder(data)
          })


    },

    methods: {
        /**
         * Load data from API.
         */
        loadData(action = false) {
            const context = this

            // used to load data for productions and feeds for all views.

            // Set timeline parameters.
            let timelineStart = moment(context.$route.query.from)
            context.timeline.startTimestamp = timelineStart.unix()
            let timelineEnd = moment(timelineStart).add(30, 'hours').subtract(1, 'minute')
            context.timeline.endTimestamp = timelineEnd.unix()

            context.selectedItem = null
            // WN close it down
            context.setLoaded(action)

            return new Promise((resolve, reject) => {
                const itemClass = context.getNewClass(this.child.item)
                const params = context.getRequestParams ? context.getRequestParams : {}

                itemClass.all(params, true)
                    .then(response => {
                        // Parse the retrieved items and add some extra properties.
                        this.$store.state.data[context.child.item].forEach(v => {
                            Item.parse(v, context.child.item, this.$store.state.app.now)
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })

        },

        /**
         * Load the passed entities from the API.
         * @param object entities
         * @return promise
         */
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    if (context.setting[v]) {
                        entityIds.push(context.setting[v])
                    }
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /**
         * Set this page to loaded/unloaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
            if (!loaded) this.noResult = false
            else {
                Vue.nextTick(() => {
                    this.updateItemFlags()
                })
            }
        },

        /**
         * Get the event name to be presented in the list/timeline.
         * @return string
         */
        getEventName(item) {
            const context = this
            let eventName = item.title
            if (item.league && context.leagueEntity && context.sportEntity) {
                const league = context.getEntityItem('league', item.league)
                if (league) {
                    // eventName = league.label + ': ' + eventName
                    eventName = `<span class="book-event-name-part">${league.label}</span><span class="book-event-name-part">${eventName}</span>`
                    const sport = context.getEntityItem('sport', league.reference)
                    if (sport) {
                        // eventName = sport.label + ', ' + eventName
                        eventName = `<span class="book-event-name-part">${sport.label}</span>${eventName}`
                    }
                }
            }
            return eventName
        },

        /**
         * Select an item and show it in the sidebar.
         * @param integer id The item id.
         */
        selectItem(id) {
            if (this.selectedItem && (id == this.selectedItem.id)) this.selectedItem = null
            else {
                const item = this[this.child.items].find(v => v.id == id)
                this.showInSidebar(item)
            }
        },

        /**
         * Delete a given feed/production from API by its id.
         * @param integer id The feed id.
         */
        deleteItem(id) {
            const context = this
            const itemClass = context.getNewClass(this.child.item, id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            if (context.selectedItem && (context.selectedItem.id == id)) {
                                context.selectedItem = null
                            }
                            context.$alert.set(`The ${context.child.item} was deleted!`)
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the ${context.child.item}!`)
                        })
                })
        },

        /**
         * Get a new class instance, based the child component.
         * @param string type The class to create, ex "feed".
         * @param mixed opts (optional) The parameters that the new class instance should
         *                   be created with.
         * @return class
         */
        getNewClass(type, opts = null) {
            switch (type) {
                case 'feed':
                    return new Feed(opts)
                case 'production':
                    return new Production(opts)
                case 'productionTemplate':
                    return new ProductionTemplate(opts)
            }
        },

        /**
         * Update the item flags for all items
         */
        updateItemFlags() {
            const context = this
            const now = moment()

            // Loop through all current feeds/productions and emit events for all locked
            // and newly created items.
            context[context.child.items]
                .forEach(v => {
                    if (v.lock && Lock.isLocked(v.lock)) {
                        app.$emit('itemLocked', {type: context.child.item, id: v.id, author: v.lock.user.name})
                    }
                    if (now.diff(v.created, 'seconds') < app.$store.state.config.ITEM_FLAG.NEW_VISIBILITY) {
                        app.$emit('itemCreated', {type: context.child.item, id: v.id, author: '', persistent: true})
                    }
                    //update should be here
                    if (now.diff(v.updated, 'seconds') < app.$store.state.config.ITEM_FLAG.UPDATE_VISIBILITY && now.diff(v.updated, 'seconds') != now.diff(v.created, 'seconds')) {
                        app.$emit('itemUpdated', {type: context.child.item, id: v.id, author: '', persistent: true})
                    }
                })

        },

        /**
         * Set signoff data for a given item.
         * @param data
         * @param type
         */
        setSignoff(data, type = 'created') {
            const item = this.getNewClass(this.child.item, data.itemId)
            const signoffs = item.get.signoffs
            signoffs
                .filter(v => v.typeId == data.typeId)
                .forEach(v => {
                    v.signed = (type == 'created') ? data : null
                })
            item.set('signoffs', signoffs)

            // Now, in order to trigger reactivity update of the current item
            // everywhere, we need to "overwrite" it, which feed.add() does.
            const newItem = Object.assign({}, item.get)
            Item.parse(newItem, this.child.item, this.$store.state.app.now)
            item.add(newItem)

        },

        /**
         * Create the filter items.
         */
        createFilters() {
            const context = this
            if (!context.availableFilters) return
            Lazy(context.availableFilters)
                .each((v, k) => {
                    switch (true) {
                        case (v.hasOwnProperty('class')):
                            context[v.class].get.items.forEach(v2 => {
                                if (v.class === 'channelEntity' || v.class === 'runTypeEntity') {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label,
                                        type: v2.reference ? v2.reference : '',
                                        country: v2.country.toUpperCase()
                                    })
                                } else {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label,
                                        active: v2.active
                                    })
                                }
                            })
                            break
                        case (k == 'mcr'):
                        case (k == 'country'):
                            context.countries.forEach(v2 => {
                                v.items.push({
                                    id: v2.code,
                                    label: v2.label
                                })
                            })
                            break
                    }
                })

            // Set default selected country based on the current user's country.
            if (app.$data.user.get.country && context.$store.state.filter.myFiltersSelected === '') {
                switch (true) {
                    case (context.availableFilters.hasOwnProperty('mcr') && context.availableFilters.mcr.hasOwnProperty('items')):
                        Lazy(context.availableFilters.mcr.items)
                            .filter(v => {
                                return v.id == app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                    case (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items')):
                        Lazy(context.availableFilters.country.items)
                            .filter(v => {
                                return v.id == app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                }
            }
            context.filterReady = true
        },

        /**
         * Set a filter value.
         * @param object filter
         */
        filteredBy(filters) {
            this.filters = filters
        },
        getEntityItem(name, entityId) {
            const entityItem = this[name + 'Entity'].getItem(entityId)
            return entityItem ? entityItem : null
        },
        getEntityItemLabel(name, entityId) {
            const entityItem = this.getEntityItem(name, entityId)
            return entityItem ? entityItem.label : ''
        },

        getSportLabel(leagueId) {
            const league = this.getEntityItem('league', leagueId)
            if (league && league.reference) {
                const sport = this.sportEntity.getItem(league.reference)
                return sport ? sport.label : null
            }
        },
        getEquipmentPeriod(equipment) {
            if (!equipment.equipmentUsage) return ''
            let start = moment(equipment.equipmentUsage.periodStart).format('HH:mm')
            let end = moment(equipment.equipmentUsage.periodEnd).format('HH:mm')
            return `${start}-${end}`
        },

        /**
         * Callback used when event "ingestionUpdated" is triggered.
         * @param object data The data (incl ingestion items) returned by the event.
         */
        updateIngestions(data) {
            if (data.itemType != this.child.item) return
            const itemClass = this.getNewClass(this.child.item, data.itemId)
            itemClass.set('ingestions', data.items)
        },

        /**
         * Load an item and show it in the sidebar.
         */
        showInSidebar(item) {
            //console.log("open sidebar");
            this.selectedItem = null
            //this.$loader.add('loadingSelectedItem')

            const itemClass = this.getNewClass(this.child.item, item.id)
            // Added quick fix. WN
            this.selectedItem = itemClass.get

            // Check if anything needs to update and update it without render,
            // Do not call anything if its feed, we have everything we need. WN
            //if (!this.selectedItem.feedStart) {
                itemClass.load(item.id)
                    .then(response => {
                        const now = moment().unix()
                        Item.parse(response.data, this.child.item, now)
                        this.selectedItem = itemClass.get
                    })
                    .catch(error => {
                        this.$error.set(error)
                    })
            //}

        },

        oneClick(item, link){
            var context = this;
            this.clicks++ 
            if(this.clicks === 1) {
              var self = this
              this.timer = setTimeout(function() {
                context.showInSidebar(item)
                self.clicks = 0
              }, this.delay);
            } else{
               clearTimeout(this.timer);  
               //context.$router.push(link)
                context.openNewTab(link)
               this.clicks = 0;
            }        	
          },
        /**
         * Shows House Number with comma instead of JSON
         * @param item
         * @param parser
         * @param section string
         * @returns {*}
         * wN
         */
        showHouseNumberField(item, parser, section = null) {
            const userCountry = app.$data.user.get.country
            let _hn = item.houseNumber
            if (_hn) {
                if (isJsonSring(_hn)) {
                    let orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk'];
                    let _gamze = JSON.parse(_hn)
                    let res = Object.keys(_gamze)
                        .map(a => ({[a]: _gamze[a]}))
                        .sort((a, b) => (orderArr.indexOf(Object.keys(a)[0]) + 1) - (orderArr.indexOf(Object.keys(b)[0]) + 1));
                    let test = {}
                    Lazy(res).each((v, k) => { Lazy(v).each((v, k) => { test[k] = v }) })
                    _hn = test

                    if (parser === 'rawValue') return _hn = JSON.stringify(_hn)
                    if (parser === 'value') {
                        //let _refine = []
                        //Lazy(_hn).each(function (v, k) { _refine.push(v) })
                        let _country = section ? section : userCountry
                        return _hn[userCountry]
                    }
                }
                else {
                    return _hn
                }
            } else {
                return _hn
            }
        },
        /*
         * Open and close new tab functions for production page and
         * possible for other pages as well.
         - WN
         */
        getTabRoute() {
            return this.$route.query.newBar;
        },
        openNewTab(link) {
            const context = this
            if (link.name === 'production.edit' && !context.isEngProduction && !context.isEditProduction) {
                let routeData = context.$router.resolve(link);
                window.open(routeData.href, '_blank');
            }
            if (link.name === 'feed.edit') {
                let routeData = context.$router.resolve(link);
                window.open(routeData.href, '_blank');
            }

            if ((['production.edit', 'feed.edit']).indexOf(link.name) === -1) {
                return context.$router.push(link)
            }
        },
        closeNewTab(link) {
            window.close();
        },
        updateRecPlaceholder(item) {
            let itemId = item.itemId
            let itemType = item.itemType
            let newHNVal = item.houseNumber

            if(newHNVal) {
                const toCreate = []
                const toUpdate = []
                const toDelete = []

                let currentProduction = this[this.child.items].find(i => i.id === item.itemId)
                const placeholders = currentProduction.placeholders ? currentProduction.placeholders : []

                let currentNOFork = _.find(placeholders, p => p.country == 'no')
                let currentSEFork = _.find(placeholders, p => p.country == 'se')

            
                if(newHNVal['no']) {
                    if(currentSEFork !== undefined) {
                        toUpdate.push('se')
                    } else if(currentNOFork !== undefined) {
                        let indx = _.findIndex(toDelete, i => i === 'no')
                        if(indx > -1) {
                            toDelete.splice(indx, 1)
                        }
                    } else if(currentNOFork === undefined) {
                        toCreate.push('no')
                    }

                }
                else if(!newHNVal['no'] && newHNVal['se']) {
                    if(currentNOFork !== undefined) {
                        //context.updatePlaceholderCountry(currentNOFork, 'se')
                        toUpdate.push('no')
                    } else if(currentSEFork !== undefined) {
                        //context.updatePlaceholderDeleteStatus(false, 'se')
                        let indx = _.findIndex(toDelete, i => i === 'se')
                        if(indx > -1) toDelete.splice(indx, 1)
                    } else if(currentSEFork === undefined) {
                        //context.createLocalDefaultPlaceholder('se')
                        toCreate.push('se')
                    }

                } 
                else if(!newHNVal['no'] && !newHNVal['se'] ) {
                    if(currentNOFork !== undefined) {
                        toDelete.push('no')
                        
                    } else if (currentSEFork !== undefined) {
                        toDelete.push('se')
                        
                    }
                }

            Lazy(newHNVal)
                .each((v,k) => {
                if(k !== 'se' && k !== 'no') {
                    let currentFork = _.find(placeholders, p => p.country == k)
                    if(newHNVal[k]) {
                        if(currentFork === undefined){
                            toCreate.push(k)
                        }
                    }
                    else {
                        if(currentFork !== undefined) {
                            toDelete.push(k)
                        }
                    }
                }
                })

                let placeholderItem = {
                    itemType: itemType,
                    itemId: itemId,
                    toCreate: toCreate,
                    toDelete: toDelete,
                    toUpdate: toUpdate
                }
                this.updateRecPlaceholderImplementation(placeholderItem)
            }
        },
        /**
         * Created or deletes placeholders when Ingest changed
         * @param {*} item 
         */
         updateRecPlaceholderImplementation(item) {
            let currentProduction = this[this.child.items].find(i => i.id === item.itemId)
            if(currentProduction !== undefined) {
                if(item.hasOwnProperty('toCreate') && item.toCreate.length > 0) {
                    this.createLocalDefaultPlaceholder(currentProduction, item.toCreate)
                }
                if(item.hasOwnProperty('toDelete') && item.toDelete.length > 0) {
                    this.deleteLocalDefaultPlaceholder(currentProduction, item.toDelete, item.hasOwnProperty('toCreate') && item.toCreate.length > 0)
                }
                if(item.hasOwnProperty('toUpdate') && item.toUpdate.length > 0) {
                    this.updateLocalDefaultPlaceholder(currentProduction, item.toUpdate)
                }
                
            }
        },
        /**
         * Createing default placeholder if house number value match with send to fork flag
         */
         createLocalDefaultPlaceholder(item, countries) {
            const context = this
            const childItem = context.child.item

            let tempId = moment().unix()
            let fStart = childItem === 'production' ? item.productionStart : item.feedStart
            fStart = childItem === 'production' ? new Date((moment(fStart).add(-15, 'minutes')).format('YYYY-MM-DD HH:mm')) : new Date((moment(fStart)).format('YYYY-MM-DD HH:mm'));
            //fStart = (moment(fStart).add(-10, 'minutes'))
            //fStart = new Date(fStart);
            let fEnd = childItem === 'production' ? item.productionEnd : item.feedEnd
            fEnd = childItem === 'production' ? new Date((moment(fEnd).add(30, 'minutes')).format('YYYY-MM-DD HH:mm')) : new Date((moment(fEnd)).format('YYYY-MM-DD HH:mm'));
            // fEnd = (moment(fEnd).add(10, 'minutes'))
            // fEnd = new Date(fEnd);

            let defaultTitle = context.getDefaultForkTitle(item)
            let newPlaceholders = []

            Lazy(countries)
                .each((v,k) => {
                    let forkCountry =  _.find(item.placeholders, i => i.country === v)
                    if(forkCountry === undefined) {
                        forkCountry = {
                            country: v,
                            placeholders: [],
                            local: true
                        }
                        
        
                        let newPlaceholder = {
                            id: tempId + k,
                            country: v,
                            periodStart: moment(fStart).format('YYYY-MM-DD HH:mm'),//(moment(fStart).add(-10, 'minutes')).format('YYYY-MM-DD HH:mm'),
                            periodEnd: moment(fEnd).format('YYYY-MM-DD HH:mm'),//(moment(fEnd).add(10, 'minutes')).format('YYYY-MM-DD HH:mm'),
                            title: defaultTitle,
                            itemId: item.id,
                            itemType: childItem,
                            local: true,
                            action: 'create',
                            submit: false
                        }
                        forkCountry.placeholders.push(newPlaceholder)
                        
                        // if(production.get.placeholders && production.get.placeholders.length > 0) {
                        //     newPlaceholders = production.get.placeholders.slice()
                        // }
                        
                        newPlaceholders.push(forkCountry)
                    }
                })
            
           
                //production.set('placeholders', newPlaceholders)
                
                if(newPlaceholders.length > 0) {
                    let differenceTime = fEnd.getTime() - fStart.getTime();
                    differenceTime = Math.ceil(differenceTime / 3600000);
                    if(differenceTime > 4) {
                        swal({
                            title: 'Time recording',
                            text: 'Recording is over 4 hours long. You may want to change it. Would you like to edit it?',
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true,
                            showCloseButton: true
                        })
                            .then(function () {
                                // TO DO: open modal window in forkplaceholder
                                console.log('open modal window in forkplaceholder')
                                context.placeholderForm = Object.assign({}, context.defaultPlaceholderForm())
                                context.placeholderForm.placeholders = newPlaceholders//[forkCountry]
                                context.placeholderForm.itemId = item.id
                                context.placeholderForm.itemType = childItem
                                context.placeholderForm.itemTitle = defaultTitle
                                context.placeholderForm.itemStart = moment(fStart).format('YYYY-MM-DD HH:mm')//production.productionStart
                                context.placeholderForm.itemEnd = moment(fEnd).format('YYYY-MM-DD HH:mm')//production.productionEnd
                                context.placeholderForm.selected = tempId
                                
                                context.showPlaceholderForm = true
                                
                            }, function (dismiss) {

                                
                                    swal({
                                        title: 'Send to Fork?',
                                        text: `Would you like to submit schedule ingest to Fork?`,
                                        type: 'question',
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'No',
                                        showCancelButton: true,
                                        showCloseButton: true
                                    })
                                        .then(function () {
                                            //newPlaceholder.submit = true
                                            console.log('submit = true')
                                            context.createForkPlaceholder(newPlaceholders, item, true)

                                        }, function (dismiss) {
                                            console.log('submit kept as false')
                                            context.createForkPlaceholder(newPlaceholders, item, false)
                                        })
                                        .catch(() => {
                                            // Close the tab if its clicked to "save & close the tab"
                                            console.log('Exception in send to fork')
                                        })
                                
                            })
                            .catch(() => {
                                // Close the tab if its clicked to "save & close the tab"
                                console.log('Exception in record over 3 hours')
                            })
                    }
                    else {
                        swal({
                            title: 'Send to Fork?',
                            text: `Would you like to submit schedule ingest to Fork?`,
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true,
                            showCloseButton: true
                        })
                            .then(function () {
                                //newPlaceholder.submit = true
                                console.log('submit = true')
                                context.createForkPlaceholder(newPlaceholders, item, true)

                            }, function (dismiss) {
                                console.log('submit kept as false')
                                context.createForkPlaceholder(newPlaceholders, item, false)
                            })
                            .catch(() => {
                                // Close the tab if its clicked to "save & close the tab"
                                console.log('Exception in send to fork')
                            })
                    }
                }
                
            
        },
        updateLocalDefaultPlaceholder(production, countries) {
            let ids = []
            let country = countries[0]
            
            Lazy(production.placeholders)
                .each((v,k) => {
                    if(v.country == country) {
                        Lazy(v.placeholders)
                        .each((v2,k2) => {
                            ids.push(v2.id)
                        })
                    }
                    
                })


            const params = {
                ids: ids,
                country: country == 'se' ? 'no' : 'se',
                itemId: production.id,
                itemType: this.child.item
            }
    
            axios.post(this.getForkApiUrl(this.child.item, production.id) + '/massupdate', params)
                .then(response => {
                    console.log(response.data)
                    
                    if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                    let newPlaceholders = response.data.placeholders.slice()
                    if(response.data && response.data.error) {
                        console.log(response.data.error)
                        this.$alert.set(response.data.error, 'danger', 10)
                    }
                    production.placeholders = newPlaceholders
                })
                .catch(error => {
                    console.log(error)
                    
                })
        },
        getForkApiUrl(itemType, itemId) {
            return 'api/' + itemType + '/' + itemId + '/' + 'placeholder'
        },
        addPlaceholder(item) {
            const context = this
            let production = _.find(context[context.child.items], p => p.id === item.itemId)
            production.placeholders = item.itemPlaceholders
            context.showPlaceholderForm = false
        },
        resetPlaceholderForm() {
            this.placeholderForm = null
            this.showPlaceholderForm = false
        },
        /**
         * Sends array of newly created placeholders to the server for mass creating
         * @param {*} items 
         * @param {*} production 
         * @param {*} submit 
         */
        createForkPlaceholder(items, production, submit=false) {
            let placeholders = []
            Lazy(items)
                .each((v,k) => {
                    Lazy(v.placeholders)
                        .each((v2,k2) => {
                            v2.submit = submit
                            placeholders.push(v2)
                        })
                })


            const params = {
                placeholders: placeholders,
                itemId: production.id,
                itemType: this.child.item
            }
    
            axios.post(this.getForkApiUrl(this.child.item, production.id) + '/masscreate', params)
                .then(response => {
                    console.log(response.data)
                    
                    if (typeof response.data.placeholders === 'string') response.data.placeholders = JSON.parse(response.data.placeholders)
                    let newPlaceholders = response.data.placeholders.slice()
                    if(response.data && response.data.error) {
                        console.log(response.data.error)
                        this.$alert.set(response.data.error, 'danger', 10)
                    }
                    production.placeholders = newPlaceholders
                })
                .catch(error => {
                    console.log(error)
                    
                })
        },
        /**
         * Returns strongly formatted fix part of title for placholder in Fork
         * @returns 
         */
         getDefaultForkTitle(production) {
            let leagueAbbr = ''
            if(production.league && this.leagueEntity && this.leagueEntity.get.items && this.leagueEntity.get.items.length > 0) {
                let league = _.find(this.leagueEntity.get.items, i => i.id == production.league)
                if(league) {
                    //TO DO: ask what means code and replace shortlabel to text if code means abbreviation
                    leagueAbbr = league.shortlabel ? league.shortlabel + ': ' : 'No league abbr: '
                }
            }

            let code, forkName = ''
            let eventDate = ''
            if(this.child.item === 'production') {
                if(production.channel && this.channelEntity && this.channelEntity.get.items && this.channelEntity.get.items.length > 0) {
                    let channel = _.find(this.channelEntity.get.items, i => i.id == production.channel)
                    if(channel) {
                        code = channel.text ? channel.text + ': ' : 'No channel country'
                        forkName = channel.shortlabel ? channel.shortlabel + ': ' : null
                        if (forkName) {
                            code = forkName + code;
                        }
                    }
                }

                eventDate = production.productionStart ? moment(production.productionStart).format('YYYYMMDD') : ''
            }
            else {
                if(production.contentType && this.contentTypeEntity && this.contentTypeEntity.get.items && this.contentTypeEntity.get.items.length > 0) {
                    let contentType = _.find(this.contentTypeEntity.get.items, i => i.id == production.contentType)
                    if(contentType) {
                        code = contentType.text ? contentType.text + ': ' : 'No CT country: '
                    }
                }

                eventDate = production.feedStart ? moment(production.feedStart).format('YYYYMMDD') : ''
            }
            

            let title = production.title ? production.title + ' ' : 'No title '
            

            return code + leagueAbbr + title + eventDate
        },
        /**
         * Deletes placholders for country if it has changed to not record
         * if it was submit to fork then need to ask confirmation
         * @param {*} production 
         * @param {*} k2 
         */
         deleteLocalDefaultPlaceholder(production, k2, notAskAboutDeleteing=false) {
            const context = this
            if(production.placeholders && production.placeholders.length > 0) {
                let forkCountries = _.filter(production.placeholders, i => _.includes(k2, i.country))
                if(forkCountries && forkCountries.length > 0) {
                    let isForkSubmit = false
                    let idsToDelete = []
                    Lazy(forkCountries)
                    .each((v1,k1) => {
                        Lazy(v1.placeholders)
                        .each((v, k) => {
                            if(v.hasOwnProperty('forkPlaceholder') && v.forkPlaceholder !== null && v.forkPlaceholder !== '') {
                                isForkSubmit = true
                            }
                            idsToDelete.push(v.id)
                        })
                    })
                   
                    if(isForkSubmit && !notAskAboutDeleteing) {
                        swal({
                            title: 'Placeholder was submitted to Fork',
                            text: `Are you sure you want to delete placeholder?`,
                            type: 'question',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true,
                            showCloseButton: true
                        })
                            .then(function () {
                                context.deleteForkPlaceholder(idsToDelete, production.id, context.child.item, k2)
    
                            }, function (dismiss) {
                                console.log('Fork placeholders deleteing was canceled')
                            })
                            .catch(() => {
                                // Close the tab if its clicked to "save & close the tab"
                                console.log('Something went wrong during placeholders deleting')
                            })
                    }
                    else {
                        context.deleteForkPlaceholder(idsToDelete, production.id, context.child.item, k2)
                    }
                }
            }
            
        },
        /**
         * Sends array of placeholders to the server for mass deleting
         * @param {*} idsToDelete 
         * @param {*} itemId 
         * @param {*} itemType 
         * @param {*} countries 
         */
        deleteForkPlaceholder(idsToDelete, itemId, itemType, countries) {
            const context = this
            //let ids = forkCountry.placeholders.map(i => i.id)
            const params = {
                ids: idsToDelete,
            }

            axios.post(context.getForkApiUrl(itemType, itemId)+ '/massdelete', params)
                .then(response => {
                    let production = _.find(context[context.child.items], i => i.id === itemId)
                    Lazy(countries)
                    .each((v,k) => {
                        let forkCountryIndex = _.findIndex(production.placeholders, i => i.country === v)
                        if(forkCountryIndex > -1) {
                            production.placeholders.splice(forkCountryIndex, 1)
                        }
                    })
                    
                })
                .catch(error => {
                   console.log(error)
                })
        },
        /**
         * Removes local placeholders if modal window was closed without saving
         * @param {*} item 
         */
        removeDefaultPlaceholders(item) {
            const context = this
            let production = _.find(context[context.child.items], i => i.id === item.itemId)
            Lazy(item.countries)
                .each((v,k) => {
                    let forkCountryIndex = _.findIndex(production.placeholders, i => i.country === v)
                    if(forkCountryIndex > -1) {
                        production.placeholders.splice(forkCountryIndex, 1)
                    }
                })
            // let forkCountryIndex = _.findIndex(production.placeholders, i => i.country === item.country)
            // if(forkCountryIndex > -1) {
            //     production.placeholders.splice(forkCountryIndex, 1)
            // }
            context.showPlaceholderForm = false
        },

        applyTemplateToItem(templateId, id) {
            let params = {}

            params[this.child.items] = [id]
            params['template'] = templateId
            
            return new Promise((resolve, reject) => {
                axios.post(this.getApplyTemplateApiUrl, params)
                .then(response => {
                    this.applyTemplateForm.show = false
                    // if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] == this.id) {
                    //     if(response.data.applied && response.data.applied.length > 0) {
                    //         Vue.set(this[this.child.item], JSON.parse(response.data.applied[0]));
                    //         this.redirect(true, this.id)
                    //     }
                        
                    // }
                    // else {
                    //     this.$error.set('Something went wromg with applying template.')
                    // }
                    resolve(response)
                })
                .catch(error => {
                    this.applyTemplateForm.show = false
                    //this.$error.set(error, 'Something went wromg with applying template.')
                    reject(error)
                })
            })
            
        }
    }

}
