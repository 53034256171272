/**
 * This is a base mixin/component used by FeedEdit and ProductionEdit.
 */

import {app} from 'app'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import Entity from 'components/entity/Entity'
import EntityItem from 'components/entity/EntityItem'
import TaskItem from 'components/common/item/task/TaskItem'
import Signoff from 'components/common/signoff/Signoff'

export default {
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            formData: this.defaultData(),
            loaded: false,
            submitted: false,
            now: null,
            insertForm: {
                show: false,
                loading: false,
                selection: null
            },

            runTypeId: null,
            defaultHouseNumberKeys: {},
            defaultHouseNumbers: []
        }
    },
    beforeCreate() {
        if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
            this.defaultHouseNumberKeys = {}
            this.defaultHouseNumbers = []
            //this.defaultData.houseNumber.value = {}
            //this.defaultData.houseNumber.values = []

            this.$store.state.system.countries.forEach(c => {
                this.defaultHouseNumberKeys[c.code] = ''
                this.defaultHouseNumbers.push(c.code)
                //this.defaultData.houseNumber.value[c.code] = ''
                //this.defaultData.houseNumber.values.push(c.code)
            })
        }
    },
    computed: {
        setting() {
            return this.$settings.get[this.child.settings]
        },
        countries() {
            return this.$store.state.system.countries.length > 0 ? this.$store.state.system.countries : null
        },
        hasOtherCountries() {
            return this.countries.findIndex(v => v.group > 0) > -1
        },
        isClone() {
            return (typeof this.$route.query.clone !== 'undefined') && !isNaN(this.$route.query.clone)
        },
        isEditForm() {
            if (this.$route.query && this.isClone) return true
            return this.$route.name.indexOf('.edit') > -1
        },
        isFormValid() {
            // hate to do that but, need it WN.
            if (['task.create', 'task.edit'].indexOf(this.$route.name) > -1) {
                if (!this.formData.productionStart.value) return false
                if (!this.formData.productionEnd.value) return false
                if (!this.formData.productionLeader.value) return false
            }
            return this.formFields && Object.keys(this.formFields).every(k => {
                console.log('isFormValid: '+ k, this.formFields[k].valid)
                return this.formFields[k].valid
            })
        },
        hasHistory() {
            return this.isEditForm && this[this.child.item] && this[this.child.item].get.history && this[this.child.item].get.history.length > 0
        },
        availableUsers() {
            return this.$store.state.user.users
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
        getTitle() {
            return this[this.child.item] && this[this.child.item].get && this[this.child.item].get.title ? this[this.child.item].get.title : ''
        },
        getPrintTitle() {
            if (!this[this.child.item]) return
            let start = moment(this[this.child.item].get[`${this.child.item}Start`])
            let end = moment(this[this.child.item].get[`${this.child.item}End`])
            return {
                title: this[this.child.item].get.title,
                start: start.format('HH:mm'),
                end: end.format('HH:mm')
            }
        }
    },

    methods: {

        /**
         * Set the now property to the current timestamp.
         */
        setNow() {
            this.now = moment()
        },

        /**
         * Set the form as loaded.
         */
        setLoaded(loaded = true) {
            if (loaded) {
                this.setFormValues()

                // "Delay" before setting loaded to true.
                Vue.nextTick(() => {
                    this.loaded = true
                })

            }
            else {
                this.loaded = false
            }
        },
        /**
         * Delete a given feed/production from API by its id.
         * @param integer id The feed id.
         */
        deleteItem(id) {
            const context = this
            const itemClass = new Production(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete ${itemClass.get.title}?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function () {
                    itemClass.delete()
                        .then(response => {
                            context.$alert.set(`The ${context.child.item} was deleted!`)
                            //if we are in the NewTab just, close the tab or, redirect.
                            if (context.$route.query.newBar) {
                                return context.closeNewTab()
                            }
                            else {
                                console.log('redirecting')
                                return context.redirect(true)
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to delete the ${context.child.item}!`)
                        })
                })
        },
        getCloneLink(){
            const link = {
                // name: 'production.create',
                query: {
                    //destination: this.$route.name,
                    view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            if (this.$route.query.newBar) {
                link.query.newBar = this.$route.query.newBar
            }
            
            link.name = 'task.create'
            
            return link;
        },
        /**
         * Redirect the user to production creation, cloning the passed
         */
        cloneProduction(id) {
            const production = new Production(id)
            const destination = this.getCloneLink()
            destination.query.clone = production.get.id
            destination.query.insertData = 1
            this.$router.push(destination)
            
        },
        /**
         * Redirect the user. This is used when a item is saved, to determine
         * which page the user should be sent to after a item is saved.
         * @param boolean open Tell the method that a feed/production is going
         *                     yo be opened.
         * @param integer id The id of the item that is going to be opened.
         * @return promise
         */
        redirect(open = false, id = null) {
            let location = {}
            if (open && id) {
                
                location.name = 'task.edit'

                location.params = {id: id}
                location.query = Object.assign({}, this.$route.query)

                // Do not include "clone" in location to redirect to.
                if (location.query.clone) {
                    delete location.query.clone
                }

                // If the page is redirected to "edit route", then we should set
                // the form values once more, after the DOM has been updated once.
                Vue.nextTick(() => {
                    this.setFormValues()
                    this.submitting = false
                })
            }
            else if (this.$route.query.destination) {
                location = this.getDestination()
            }
            else {
                    location.name = 'task.view'
                    location.params = {view: 'overview'}
            }
            return new Promise((resolve, reject) => {
                this.$router.push(location, () => {
                    resolve()
                }, () => {
                    reject()
                })

            })
        },

        /**
         * Get the destination to be used when redirecting after form save.
         * @param object
         */
        getDestination() {
            const context = this
            const output = {
                name: context.$route.query.destination ? context.$route.query.destination : 'task.view',
                params: {
                    view: context.$route.query.view ? context.$route.query.view : 'overview'
                },
            }
            if (context.$route.query.from) {
                output.query = {
                    from: context.$route.query.from
                }
            }
            if (context.$route.query.to) output.query.to = context.$route.query.to

            
            //output.name = 'task.view'
            //output.params.view = 'overview'
            
            return output
        },

        /**
         * Load the form.
         */
        loadForm() {
            const context = this
            let itemId = context.id
            if (context.isClone) {
                itemId = parseInt(context.$route.query.clone)
                context[context.child.item] = new Production(0)
            }
            else {
                context[context.child.item] = new Production(itemId)
                //if its cloning feed template get the id from the route
                //if (this.isFeedTemplateBased) itemId = context.$route.query.feedTemplate
            }


            return new Promise((resolve, reject) => {

                // If the form is already loaded, then we don't need to reload all
                // the entities once more.
                if (context.loaded) {
                    resolve()
                    return
                }

                //context.loaded = false

                context.loadEntities(context.child.entities)
                    .then(response => {

                        if (context.$route.query.feed == 1 || context.isFeedTemplateBased) {
                            //console.log('I have accessed')
                            context.cloneFeed = new Feed(itemId)
                            context.cloneFeed.load(itemId)
                                .then(response => {
                                    //console.log(response.data, 'cloneFeed')
                                    resolve()
                                })
                        }

                        if (context.isEditForm) {
                            const params = {
                                crewConflict: true,
                            }

                            context[context.child.item].load(itemId, params)
                                .then(response => {
                                    // Parse associations, if any.
                                    if (response.data.associations) {
                                        const now = moment().unix()
                                        const associations = response.data.associations.slice(0)
                                        associations.forEach(item => {
                                            TaskItem.parse(item, context.child.association, now)
                                            context.getSignoffs(item)
                                        })
                                        context[context.child.item].set('associations', associations)
                                    }

                                    // If the current item is a clone of another, reset
                                    // some values.
                                    if (context.isClone) {
                                        context.setClonedValues(itemId)
                                    }

                                    resolve()

                                })
                                .catch(error => {
                                    context.$error.set(error, `It was not possible to load ${context.child.item} ${context.id}.`)

                                    // @TODO Old error handling
                                    // let msg
                                    // if (error.response.data.message) msg = error.response.data.message
                                    // else if (typeof error.response.data == 'string') msg = error.response.data
                                    // else msg = `It was not possible to load ${context.child.item} ${context.id}.`
                                    // this.$alert.set(msg, 'danger', 10)

                                    if (error.response.status === 404) context.redirect(false)

                                    reject()
                                })

                        }
                        else {
                            resolve()
                        }

                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the form dependencies.')
                        reject()
                    })

            })

        },

        /**
         * Load the passed entities from the API.
         * @param object entities
         * @return promise
         */
       loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        /**
         * Update local notes in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateLocalNotes(items) {
            //this[this.child.item].set('localNotes', items)
            this.formData.localNotes.value = items[0]//this[this.child.item].get.localNotes
            this.formData.localNotesChanged.value = items[1]
            //if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
        },

        /**
         * Check id an EntityItem should be visible or not.
         * @param object item
         * @return boolean
         */
        entityItemIsVisible(item, field) {
            // Show channel & runType entity items belongs to selected country value. WN
            if (field == 'runType') {
                if (this.formData.country.value && (item.country == this.formData.country.value)) {
                    return EntityItem.isVisible(item, 'No', item.country == this.formData.country.value)
                }
            }
            return EntityItem.isVisible(item, 'No', this.formData[field].value == item.id)
        },

        getEntityItem(name, id) {
            const entityItem = this[name + 'Entity'].getItem(id)
            return entityItem ? entityItem : null
        },
        getEntityItemLabel(name, id) {
            const entityItem = this.getEntityItem(name, id)
            return entityItem ? entityItem.label : ''
        },

        getSignoffs(n) {
            let item = null
            if(!n.signoffs || n.signoffs.length === 0) {
                item = n
            }

            if(item) {
                Signoff.getByItemId(item.id, item.elementType)
                .then(response => {
                    if (response.status != 200) {
                        item.signed = null
                        return
                    }

                    let signedArr = response.data
                    item.signoffs = signedArr.slice()
                    item.status = TaskItem.getStatus(item)
                })
                .catch(error => {
                    console.log(error);
                })
            }
        },
        updateHouseNumbers() {
            if(this.$store.state.system.countries && this.$store.state.system.countries.length > 0) {
                this.defaultHouseNumberKeys = {}
                this.defaultHouseNumbers = []
                //this.defaultData.houseNumber.value = {}
                //this.defaultData.houseNumber.values = []
    
                this.$store.state.system.countries.forEach(c => {
                    this.defaultHouseNumberKeys[c.code] = ''
                    this.defaultHouseNumbers.push(c.code)
                    //this.defaultData.houseNumber.value[c.code] = ''
                    //this.defaultData.houseNumber.values.push(c.code)
                })
            }
        },

        /**
         * Update the equipment list in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
         updateEquipment(items, notify = true) {
            this[this.child.item].set('equipment', items)
            this.formData.equipment.value = this[this.child.item].get.equipment
            if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
        },

    }

}
