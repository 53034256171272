<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
import EquipmentWithUsage from 'components/equipment/EquipmentWithUsage'
import EquipmentTypeWithUsage from 'components/equipment/EquipmentTypeWithUsage'
import Feedback from 'components/feedback/Feedback'
import System from 'components/system/System'
import Datetime from 'components/common/helpers/Datetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookDatetime from 'components/common/BookDatetime'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookTable,
        BookModal,
        BookDatetime,
        BookLoader
    },
    props: {
        disabled: {
            default: false,
            type: Boolean
        },
        disabledMessage: String,
        feedbackItemId: {
            default: 0,
            type: Number
        },
        feedbackItemType: {
            default: '',
            type: String
        },
        items: {
            default: [],
            type: Array
        }
    },
    data() {
        return {
            showAll: false,
            loaded: false,
            allFeedbacks: []
        }
    },
    mounted() {
        this.loaded = true
    },
    computed: {
        filteredFeedbacks() {
            return this.allFeedbacks
        },
        feedbacks() {
            if (!this.loaded) return

            const context = this
            const output = {
                options: {
                    actions: []
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'role',
                        label: 'Role',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'name',
                        label: 'Name',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'read',
                        label: 'Read',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    
                ],
                rows: []
            }

            if (this.$ability.can('update_feedbacks_reports', 'Feedbacks & Reports')) {
                output.options.actions.push('edit')
            }
            if (this.$ability.can('delete_feedbacks_reports', 'Feedbacks & Reports')) {
                output.options.actions.push('delete')
            }
            
            context.items
                .filter(i => Feedback.isVisible(i, context.showAll))
                .forEach(item => {
                    let roles = []
                    item.author.roles.forEach(r => roles.push(r.name))
                    let role = roles.join(', ')

                    const row = {
                        id: {
                            value: item.id
                        },
                        type: {
                            value: item.type
                        },
                        role: {
                            value: role
                        },
                        name: {
                            value: `<a href="/user/${item.author.id}">${item.author.fullName}</a> `
                        },
                        read: {
                            value: item.readByAdmin ? 'Yes' : 'No'
                        },
                        rowOptions: {

                        }

                    }

                    // if(item.author.id !== app.$data.user.get.id) {
                    //     row.rowOptions.hideActions = ['delete']
                    // }

                    output.rows.push(row)
                    
            });

            return output
        }
    },
    methods:{
        getCreateLink(isOk = false) {
            const link = {
                name: 'feedback.create',
                params: {
                    feedbackItemId: this.feedbackItemId,
                    feedbackItemType: this.feedbackItemType
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }

            if(isOk) {
                link.query.isOk = true
            }
            
            return link;
        },
        getEditLink(id) {
            const link = {
                name: 'feedback.edit',
                params: {
                    feedbackId: id,
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }
            
            return link;
        },
        openNewTab(link) {
            const context = this
            
            let routeData = context.$router.resolve(link);
            window.open(routeData.href, '_blank');
            
        },
        createOk() {
            const context = this
            
            swal({
                title: 'Everything was OK?',
                text: 'Are you sure you want to add feedback that everything was OK?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function() {
                    context.$loader.add('bookFeedbackSubmit')
                    
                    const feedback = new Feedback(0)
                    feedback.set('feedback', 'Everything was OK')
                    feedback.set('feedbackItemId', context.feedbackItemId)
                    feedback.set('feedbackItemType', context.feedbackItemType)
                    feedback.set('isErrorReport', false)
                    feedback.set('isCreateTicket', false)
                    feedback.set('readByAdmin', false)
                    feedback.set('createdBy', app.$data.user.get.id)
                    feedback.set('status', 'ok')
                    feedback.store()
                        .then(response => {
                            context.$alert.set('Feedback ' + response.data.id + ' was saved!', 'success', 3)
                            app.$emit('creatingFeedback', response.data)
                            context.$loader.remove('bookFeedbackSubmit')
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to remove the feedback.')
                            context.$loader.remove('bookFeedbackSubmit')
                        })
                    
                })
        },
        
        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this feedback?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function() {
                    context.$loader.add('bookFeedbackSubmit')
                    
                        const feedback = new Feedback(id)
                        feedback.delete()
                            .then(response => {
                                context.removeFeedbak(id, false)
                                context.$alert.set('The item was removed.', 'success', 4)
                                context.$loader.remove('bookFeedbackSubmit')
                            })
                            .catch(error => {
                                context.$error.set(error, 'It was not possible to remove the feedback.')
                                context.$loader.remove('bookFeedbackSubmit')
                            })
                    
                })
        },
        removeFeedbak(id, notify) {
            const feedbacks = this.items.slice(0)
            let i = -1

            
            i = feedbacks.findIndex(v => v.id == id)
            

            if (i > -1) { feedbacks.splice(i, 1) }

            //app.$emit('changingEquipment', feedbacks, notify)
            //this.$emit('changingEquipment', equipment, notify)
            this.$emit('changedFeedbacks', feedbacks, notify)
        }

    }
}
</script>
<template>
    <div>
        <button
            class="btn btn-primary btn-xs book-button-add-feedback"
            style="margin-right:120px;"
            @click="showAll = showAll ? false : true"
            :disabled="disabled"
            v-if="$ability.can('view_all_feedbacks_reports', 'Feedbacks & Reports')">
            <span v-if="!showAll"><font-awesome-icon icon="plus"/>&nbsp;Show All</span>
            <span v-else><font-awesome-icon icon="minus"/>&nbsp;Close Other</span>
        </button>
        <button
            class="btn btn-success btn-xs book-button-add-feedback"
            style="margin-right:70px;"
            @click="openNewTab(getCreateLink())"
            :disabled="disabled"
            v-if="$ability.can('create_feedbacks_reports', 'Feedbacks & Reports')">
            <font-awesome-icon icon="plus"/>&nbsp;Add
        </button>
         <button
            class="btn btn-success btn-xs book-button-add-feedback"
            @click="createOk()"
            :disabled="disabled"
            v-if="$ability.can('create_feedbacks_reports', 'Feedbacks & Reports')">
            <font-awesome-icon icon="plus"/>&nbsp;Add OK
        </button>
        <book-loader :small="true" v-if="!loaded"></book-loader>
        <book-table  v-else
                     :items="feedbacks"
                     :component="'feedbackItem'"
                     notFoundMessage="No Feedback found"
                     :render="showAll"
                     @edit="item => openNewTab(getEditLink(item.id.value))"
                     @delete="item => deleteItem(item.id.value)">
        </book-table>
    </div>
</template>
<style lang="scss">
    .book-button-add-feedback {
        position: absolute;
        right: 0;
        top: 2px;
        z-index: 10;
    }
</style>