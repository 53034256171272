<template>
<div class="book-exit-print btn-group">
    <button @click="print()" class="btn btn-default book-print-dialog" title="Print this page">
        <font-awesome-icon icon="print"/>        
    </button>
    <button @click="exit()" class="btn btn-default book-print-close" type="button" aria-label="Close" title="Exit print view">
        <font-awesome-icon icon="sign-out-alt"/>   

        <!-- <span aria-hidden="true">&times;</span> -->
        <!-- <span class="fas fa-chevron-left"></span><span class="item-label">&nbsp;Back</span> -->
    </button>
</div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit('setAppClass', 'book-print')
    },
    beforeDestroy() {
        this.$store.commit('removeAppClass', 'book-print')
    },
    methods: {
        print() {
            window.print()
        },
        exit() {
            this.$store.commit('removeAppClass', 'book-print')
            this.$emit('close')
            const query = Object.assign({}, this.$route.query)
            delete query.print
            this.$router.replace(query)
        }
    }
}
</script>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.book-exit-print {
    // background: rgba(#FFFFFF, .95);
    // border: 1px solid $color-grey-border !important;
    border-radius: 0 0 0 4px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
    position: fixed !important;
    right: 10px;
    text-align: center;
    top: 10px;
    z-index: 1000;
    button {
        font-size: 1.3em;
    }
    .book-print-close {
    }
}
</style>
