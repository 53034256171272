import Api from 'components/app/Api'
import {app} from 'app'
import store from 'store'

class Rate extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/rate',
            module: 'rate',
        })
    }

    static getUserOwnRates() {
        return new Promise((resolve, reject) => {
            axios.get(`api/rate/my`)
                .then(response => {
                    store.commit('setData', {key: 'rate', data: response.data, reset: true})

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
    }
}
export default Rate