const state = {
  data: {},
  users: null,
  roles: null,
  permissions: null,
  permissionRole: null
}

const getters = {
}

const actions = {
}

const mutations = {
    setData(state, data) {
        Vue.set(state.data, data.id, data)
    },
    setField(state, {id, field, value}) {
        state.data[id][field] = value
    },
    removeField(state, {id, field}) {
        delete state.data[id][field]
    },
    setUsers(state, data) {
        state.users = data
    },
    removeUsers(state, data) {
        state.users = null
    },
    setRoles(state, roles) {
        state.roles = roles
    },
    setRole(state, data) {
        Vue.set(state.roles, data.id, data)
    },
    removeRole(state, id) {
        Vue.delete(state.roles, id)
    },
    setRoleField(state, {id, field, value}) {
        state.roles[id][field] = value
    },
    removeRoleField(state, {id, field}) {
        delete state.roles[id][field]
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    }
}

const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export {user}
