/**
 * Mixin to with a set of Entity related resources.
 */

const EntityMixin = {
    methods: {
        getEntityItem(name, id) {
            const entityItem = this[name + 'Entity'].getItem(id)
            return entityItem ? entityItem : null
        },
        getEntityItemLabel(name, id) {
            const entityItem = this.getEntityItem(name, id)
            return entityItem ? entityItem.label : ''
        },
        getSportLabel(leagueId) {
            const league = this.getEntityItem('league', leagueId)
            if (league && league.reference) {
                const sport = this.sportEntity.getItem(league.reference)
                return sport ? sport.label : null
            }
        }
    }
}

export default EntityMixin
