

const state = {
    messages: null,
    allowedTypes: null,
}
const getters = {
    getMessages: state => state.messages,
    getAllowedTypes: state => state.allowedTypes,
}
const mutations = {
      addMessage(state, {id, message, type}) {
          if (!state.messages) {
            state.messages = {}
            Vue.set(state, 'messages', {})
          }
          Vue.set(state.messages, id, {type: type, text: message})
      },
      removeMessage(state, {id, type}) {
          Vue.delete(state.messages, id)
          if (Object.keys(state.messages).length < 1) {
              state.messages = null
          }
      },
      clearMessages(state) {
        state.messages = null
      },
      setAllowedTypes(state, types) {
          state.allowedTypes = types
      }
}

const alert = {
  namespaced: true,
  state,
  getters,
  mutations
}

export {alert}




