
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

import {auth} from 'components/auth/store'
import {alert} from 'components/common/alert/store'
import {entity} from 'components/entity/store'
import {filter} from 'components/common/filter/store'
import {settings} from 'components/settings/store'
import {system} from 'components/system/store'
//import {contract} from 'components/contract/store'
import {user} from 'components/user/store'
import {headerLoader} from 'components/common/loader/store'

const state = {
    app: {
        title: '',
        classes: [],
        now: 0,
    },
    config: null,
    data: {
        entity: [],
        feed: [],
        production: [],
        problemProductions: [],
        mcrdata: [],
        updates: [],
        productionTemplate: [],
        ingestion: [],
        filter: [],
        equipment: [],
        equipmentUsage: [],
        calendar: [],
        availableCrew: [],
        association: [],
        contract: [],
        mcrStatus: [],
        feedback: [],
        rate: [],
        notReadNotifications: [],
        readNotifications: [],
        crewview: [],
        availableProductionsCrew: []
    }
}
const mutations = {
    setConfig(state, config) {
        state.config = config
    },
    setData(state, {key, data, reset}) {
        let currentData = null
        let unsavedItem = state.data[key] ? state.data[key].find(v => v.id === 0) : null
        if (state.data[key]) {
            if (reset) {
                state.data[key] = data
            }
            else {
                data.forEach(item => {
                    let i = state.data[key].findIndex(v => v.id === item.id)
                    if (i > -1) state.data[key].splice(i, 1)
                    state.data[key].push(item)
                })
            }
        }

        // An unsaved item (id===0) was available before the whole data object
        // was overwritten. Put it back.
        if (unsavedItem) {
            state.data[key].push(unsavedItem)
        }

    },
    removeData(state, {key}) {
        state.data[key] = []
    },
    add(state, {key, data}) {
        if (!state.data.hasOwnProperty(key)) Vue.set(state.data, key, [])
        let i = state.data[key].findIndex(v => v.id === data.id)
        if (i > -1) Vue.set(state.data[key], i, data)
        else state.data[key].push(data)
    },
    addToIndex(state, {key, data, index}) {
        if (!state.data.hasOwnProperty(key)) Vue.set(state.data, key, [])
        let i = state.data[key].findIndex(v => v.id === data.id)
        if (i > -1) Vue.set(state.data[key], i, data)
        else {
            if(state.data[key].length === 0 ||  index >= state.data[key].length)state.data[key].push(data)
            else if(index === 0 ) state.data[key].unshift(data)
            else state.data[key].splice(index, 0, data)
        }
    },
    remove(state, {key, id, remove}) {
        if (state.data[key]) {
            let i = state.data[key].findIndex(v => v.id === id)
            if (i > -1) {
                state.data[key].splice(i, 1)
            }
            if (remove && state.data[key].length == 0) {
                state.data[key] = []
            }
        }
    },
    setField(state, {key, id, field, value}) {
        if (state.data[key]) {
            let i = state.data[key].findIndex(v => v.id === id)
            if (i > -1) {
                Vue.set(state.data[key][i], field, value)
            }
        }
    },
    sortDataBy(state, {key, field}) {
        state.data[key].sort(function(a, b) {
            var nameA = a[field]
            var nameB = b[field]
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    },
    setAppClass(state, className) {
        let i = state.app.classes.findIndex(v => v == className)
        if (i < 0) {
            state.app.classes.push(className)
        }
    },
    removeAppClass(state, className) {
        let i = state.app.classes.findIndex(v => v == className)
        if (i > -1) {
            state.app.classes.splice(i, 1)
        }
    },
    setAppNow(state, now) {
        Vue.set(state.app, 'now', now)
    }
}

const getters = {
    items: state => {
        if (state.data.mcrdata) return state.data.mcrdata

        if (!state.data.feed && state.data.production) {
            return []
        }
        if (!state.data.feed) {
            return state.data.production
        }
        if (!state.data.production) {
            return state.data.feed
        }
        return state.data.feed.concat(state.data.production)
    }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    auth,
    alert,
    //contract,
    entity,
    // feed,
    filter,
    settings,
    system,
    user,
    headerLoader
  },
  plugins: [createPersistedState({
      paths: ['config', 'filter', 'system'],
  })]
})
