const state = {
    data: {},
}
const mutations = {
    save(state, {name, value}) {
        if (!state.data.hasOwnProperty(name)) {
            Vue.set(state.data, name, '')
        }
        state.data[name] = value
    },
}
const settings = {
    namespaced: true,
    state,
    mutations
}
export {settings}
