<script>
import {app} from 'app'
import Rate from 'components/rate/Rate'
import BookLoader from 'components/common/loader/BookLoader'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookTable from 'components/common/BookTable'
import BookFilter from 'components/common/filter/BookFilter'
import BookModal from 'components/common/BookModal'
import Entity from 'components/entity/Entity'
import User from 'components/user/User'

export default {
    components: {
        BookLoader,
        BookDateNavigation,
        BookTable,
        BookFilter,
        BookModal
    },
    data() {
        return {
            child: {
                item: 'rate',
                items: 'rates',
                settings: 'rates',
                entities: {
                    skillEntity: 'skill_entity_id',
                    currencyEntity: 'currency_entity_id'
                }
            },
            rate: null,
            loaded: false,
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                current: {
                    label: 'Rates',
                    items: [{id: 'current', label: 'Show current rates'}, {id: 'previous', label: 'Show previous rates'}]
                },
                skills: {
                    label: 'Skills',
                    items: [],
                },
                users: {
                    label: 'Users',
                    items: [],
                }
            },
            availableOwnFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                current: {
                    label: 'Rates',
                    items: [{id: 'current', label: 'Show current rates'}, {id: 'previous', label: 'Show previous rates'}]
                },
                skills: {
                    label: 'Skills',
                    items: [],
                },
                
            },
            filterReady: false,
            filters: {},
            ownFilters: {}
        }
    },
    mounted() {
        const context = this
        if (!context.child) return
        this.$store.commit('remove', {key: context.child.item, id: 0})

        context.loadData()
            .then(response => {
                context.createFilters()
                context.setLoaded()
            })
            .catch(error => {
                // if (error.response && error.response.status != 404) {
                //     context.$error.set(error, `It was not possible to load the ${this.child.items}`)
                // }
                context.createFilters()
                context.setLoaded()
                //context.noResult = true
            })

    },
    watch: {
        '$route': function(to, from) {
            
            // console.log(to)
            // console.log(from)
            const context = this
            context.loaded = false
            if(to.name !== from.name) {
                
                context.filterReady = false
                if (context.isOwnRates) {
                    Rate.getUserOwnRates()
                        .then(response => {
                            context.loaded = true

                            Lazy(context.availableOwnFilters.current.items)
                                .filter(v => {
                                    return v.id == 'current'
                                })
                                .each(v => {
                                    Vue.set(v, 'selected', true)
                                })
                            Lazy(context.availableOwnFilters.skills.items)
                                .each(v => {
                                    Vue.set(v, 'selected', false)
                                })
                            context.availableOwnFilters.search.value = ''

                            context.filters = {
                                current: ['current'],
                                search: {
                                    type: 'text',
                                    value: ''
                                },
                                skills: []
                            }
                            context.filterReady = true
                
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to load rates.`)
                            context.loaded = true
                            context.filterReady = true
                        })
                }
                else {
                    const rate = new Rate()
                    const params = {}
                    rate.all(params, true)
                        .then(response => {
                            context.loaded = true
                            Lazy(context.availableFilters.current.items)
                                .filter(v => {
                                    return v.id == 'current'
                                })
                                .each(v => {
                                    Vue.set(v, 'selected', true)
                                })  
                            Lazy(context.availableFilters.skills.items)
                                .each(v => {
                                    Vue.set(v, 'selected', false)
                                })
                            Lazy(context.availableFilters.users.items)
                                .each(v => {
                                    Vue.set(v, 'selected', false)
                                })
                            context.availableFilters.search.value = ''
                            context.filters = {
                                current: ['current'],
                                search: {
                                    type: 'text',
                                    value: ''
                                },
                                skills: [],
                                users: []
                            }
                            context.filterReady = true
                        })
                        .catch(error => {
                            context.$error.set(error, `It was not possible to load rates.`)
                            context.loaded = true
                            context.filterReady = true
                        })  
                }
            }    
        }
    },
    computed: {
        isOwnRates() {
            return ['own.rates'].indexOf(this.$route.name) > -1
        },
        setting() {
            let settings = this.child.settings ? this.child.settings : this.child.items
            return this.$settings.get[settings]
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        rates() {
            const context = this
            if (!context.loaded || !this.$store.state.data.rate) return []
            return this.$store.state.data.rate.filter(r => context.isRateVisible(r))
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this.rates.length
            filterInfo.total = this.$store.state.data.rate ? this.$store.state.data.rate.length : 0
            return filterInfo
        },
        getCreateLink() {
            const link = {
                // name: 'production.create',
                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    // runType: this.getRunTypeFromUrl
                }
            }
            
            link.name = 'rate.create'        
            
            return link
        },
        availableUsers() {
            return this.$store.state.user.users ? this.$store.state.user.users.filter(u => u.country === app.$data.user.get.country) : null
        },
        getUsersList() {
            if (!this.availableUsers || this.availableUsers.length == 0) return []
            const output = []
            Lazy(this.availableUsers)
                .sortBy(v => v.fullName.toLowerCase())
                .each(v => {
                    const item = {
                        id: v.id,
                        label: v.fullName,
                        text: v.fullName,
                        search: `${v.fullName.toLowerCase()}, ${v.name}, ${v.email}`, // WN to lower-case search
                        extraFields: {
                            roles: []
                        }
                    }
                    Lazy(v.roles)
                        .each((role, roleId) => {
                            item.extraFields.roles.push(parseInt(roleId))
                        })
                    output.push(item)
                })
            return output
        },
        currencies() {
            return this.currencyEntity && this.currencyEntity.get && this.currencyEntity.get.items ? this.currencyEntity.get.items : []
        },
        ratesList() {
            const context = this
            const output = {}
            if (!context.rates || context.rates.length == 0) {
                return output
            }

            output.options = {
                actions: [],
            }

            if(this.$ability.can('view_rates', 'Rates')) {
                output.options.actions.push('edit')
            }

            if(this.$ability.can('delete_rates', 'Rates')) {
                output.options.actions.push('delete')
            }

            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false
                },
                {
                    value: 'rateStart',
                    label: 'Start',
                    sortable: true,
                },
                {
                    value: 'rateEnd',
                    label: 'End',
                    sortable: true,
                },
                {
                    value: 'skill',
                    label: 'Skill',
                    sortable: true,
                },
            ]

            if(!context.isOwnRates) {
                output.columns.push({
                    value: 'user',
                    label: 'User',
                    sortable: true,
                })
            }

            output.columns.push({
                    value: 'currency',
                    label: 'Currency',
                    sortable: true,
                })

            output.columns.push({
                    value: 'ratePerDay',
                    label: 'Day rate',
                    sortable: true,
                })

            output.columns.push({
                    value: 'ratePerHalfDay',
                    label: 'Half day rate',
                    sortable: true,
                })
            
            output.columns.push({
                    value: 'ratePerHour',
                    label: 'Hour rate',
                    sortable: true,
                })
            
            output.columns.push({
                    value: 'overRatePerHour',
                    label: 'Overtime hour rate',
                    sortable: true,
                })

            output.columns.push({
                value: 'fixedRatePerPublicHoliday',
                label: 'Fixed public holiday rate',
                sortable: true
            })

            output.rows = []

            context.rates.forEach(rate => {
                const skill = context.skillEntity && context.skillEntity.get && context.skillEntity.get.items ? _.find(context.skillEntity.get.items, i => i.id === rate.skill) : null
                const row = {
                    id: {
                        value: rate.id,
                    },
                    rateStart: {
                        value: rate.rateStart
                    },
                    rateEnd: {
                        value: rate.rateEnd ? rate.rateEnd : ''
                    },
                    skill: {
                        rawValue: skill ? skill.id : '',
                        value: skill ? skill.label : ''
                    }
                }

                if(!context.isOwnRates) {
                    const user = rate.userId ? _.find(context.getUsersList, u => u.id === rate.userId) : null
                    row.user = {
                        rawValue: user ? user.id : '',
                        value: user ? user.label : ''
                    }
                }

                const currency = rate.currency ? _.find(context.currencies, c => c.id === rate.currency) : null
                row.currency = {
                    rawValue: currency ? currency.id : '',
                    value: currency ? currency.label : ''
                }

                row.ratePerDay = {
                    value: rate.ratePerDay
                }

                row.ratePerHalfDay = {
                    value: rate.ratePerHalfDay
                }

                row.ratePerHour = {
                    value: rate.ratePerHour
                }

                row.overRatePerHour = {
                    value: rate.overRatePerHour
                }

                row.fixedRatePerPublicHoliday = {
                    value: rate.fixedRatePerPublicHoliday
                }

                output.rows.push(row)
            })

            return output

        }
    },
    methods: {
        loadData() {
            const context = this

            return new Promise((resolve, reject) => {
                context.loadEntities(context.child.entities)
                    .then(response => {
                        User.getUsers()
                            .then(response => {

                            if (context.isOwnRates) {
                                Rate.getUserOwnRates()
                                    .then(response => {
                                        resolve()
                                    })
                                    .catch(error => {
                                        context.$error.set(error, `It was not possible to load rates.`)

                                        reject()
                                    })
                            }
                            else {
                                
                                    const rate = new Rate()
                                    const params = {}
                                    rate.all(params, true)
                                        .then(response => {
                                            resolve(response)
                                        })
                                        .catch(error => {
                                            context.$error.set(error, `It was not possible to load rates.`)
                                            context.loaded = true
                                            reject(error)
                                        })  
                                    
                                
                            }
                        })
                            .catch(err => {
                                context.$error.set(err, `It was not possible to get user list.`)
                                context.loaded = true
                                reject()
                            })
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the form dependencies.')
                        reject()
                    })
            })
            
        },
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                .each((v, k) => {
                    entityIds.push(context.setting[v])
                })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                        .each((v, k) => {
                            context[k] = new Entity(context.setting[v])
                        })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },
        setLoaded(loaded = true) {
            this.loaded = loaded
            //this.setFormValues()
        },
        createFilters() {
            const context = this
            const user = app.$data.user.get

            if(context.skillEntity && context.skillEntity.get.items) {
                context.skillEntity.get.items.filter(i => i.country === user.country).forEach(s => {
                    context.availableFilters.skills.items.push({
                        id: s.id,
                        label: s.label
                    })
                })
            }

            context.availableFilters.users.items = context.getUsersList
            
            if(user.skills && context.skillEntity && context.skillEntity.get.items) {
                 user.skills.forEach(s => {
                    const skill = _.find(context.skillEntity.get.items, i => i.id === s)
                    if(skill) {
                        context.availableOwnFilters.skills.items.push({
                            id: skill.id,
                            label: skill.label
                        })
                    }
                 })
            }
            else {
                context.availableOwnFilters.skills.items.push({
                    id: '',
                    label: 'No skills'
                })
            }

            if (context.$store.state.filter.myFiltersSelected === '') {
                Lazy(context.availableFilters.current.items)
                    .filter(v => {
                        return v.id == 'current'
                    })
                    .each(v => {
                        Vue.set(v, 'selected', true)
                    })

                Lazy(context.availableOwnFilters.current.items)
                    .filter(v => {
                        return v.id == 'current'
                    })
                    .each(v => {
                        Vue.set(v, 'selected', true)
                    })
            }

            context.filterReady = true
        },
        filteredBy(filters){
            console.log(filters)
            this.filters = filters
        },
        filteredOwnBy(filters){
            console.log(filters)
            this.ownFilters = filters
        },
        selectItem(id) {
            console.log(id)
        },
       getEditLink(id) {
            const link = {
                params: {
                    id: id
                },
                query: {
                    destination: this.$route.name,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    newBar: id
                }
            }
            
            link.name = 'rate.edit'
            
            return link
        },
        openNewTab(link) {
            const context = this

            var link_ = {
                destination: this.$route.name,
                from: this.$route.query.from,
                to: this.$route.query.to,
                newBar: link.params.id
            }
            
            let routeData = context.$router.resolve(link);
            window.open(routeData.href, '_blank');
            
        },
        deleteItem(id) {
            const context = this
            const itemClass = new Rate(id)
            swal({
                title: 'Delete?',
                text: `Are you sure you want to delete this rate?`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                itemClass.delete()
                    .then(response => {
                        
                        context.$alert.set(`The rate was deleted!`)
                    })
                    .catch(error => {
                        context.$error.set(error, `It was not possible to delete the rate!`)
                    })
            })
        },
        isRateVisible(item) {
            const context = this

            if (Object.keys(context.filters).length == 0) {
                return true
            }

            if(context.filters.hasOwnProperty('users') && context.filters.users.length > 0) {
                let filteredUser = _.includes(context.filters.users, item.userId)
                if(!filteredUser) return false
            }

            if(context.filters.hasOwnProperty('current') && context.filters.current.length > 0 && context.filters.current.length == 1) {
                if(context.filters.current[0] == 'current' && item.rateEnd) {
                    return false
                }

                if(context.filters.current[0] == 'previous' && !item.rateEnd) {
                    return false
                }
            }

            if(context.filters.hasOwnProperty('skills') && context.filters.skills.length > 0) {
                let filteredSkill = _.includes(context.filters.skills, item.skill)
                if(!filteredSkill) return false
            }

            if(context.filters.hasOwnProperty('search') && context.filters.search.value !== null && context.filters.search.value !== '') {
                let filterSearch =  (item.rateStart && item.rateStart.includes(context.filters.search.value)) ||
                                    (item.rateEnd && item.rateEnd.includes(context.filters.search.value))
                if(!filterSearch) return false

            }

            return true
        }
    }
}
</script>

<template>
    <div id="rates-list" class="book-top-sticky-wrapper book-bottom-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <h1 class="page-title">Rates</h1>
            <div class="secondary-menu" v-if="$ability.can('create_rates', 'Rates')">
                <div class="btn-group" role="group">
                    
                        <router-link :to="getCreateLink" class="btn btn-success" title="Create" >
                            
                                <font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Create</span>
                            
                        </router-link>
                </div>
            </div>
            
        </div>
        <book-filter v-show="isOwnRates"
            :loading="!filterReady"
            :options="{save: true}"
            :filters="availableOwnFilters"
            @filteredBy="filters => filteredBy(filters)"
            :info="filterInfo">
        </book-filter>
        <book-filter v-show="!isOwnRates"
            :loading="!filterReady"
            :options="{save: true}"
            :filters="availableFilters"
            @filteredBy="filters => filteredBy(filters)"
            :info="filterInfo">
        </book-filter>

        <book-loader v-if="!loaded"></book-loader>
        <div v-else class="panel panel-default">
            <book-table
                :items="ratesList"
                :component="'rates'"
                notFoundMessage="<strong>No rates were found</strong><br />Please, check your current filters, which can affect the presented result"
                @preview="item => selectItem(item.id.value)"
                @edit="item => openNewTab(getEditLink(item.id.value))"
                @delete="item => deleteItem(item.id.value)"
                class="book-content">
            </book-table>
        </div>
    </div>
</template>