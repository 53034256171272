<script>
/**
 * @file
 * Book Overview component.
 * Builds a grid like overview based on the passed parameters.
 */

import ItemFlagsMixin from 'components/common/item/ItemFlagsMixin'
import Datetime from 'components/common/helpers/Datetime'
import {app} from 'app'
import jq from 'jquery'
import User from 'components/user/User'

export default {
    mixins: [ItemFlagsMixin],
    props: {
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        itemsType: {
            type: String
        },
        fields: {
            type: Object
        },
        groupBy: {
            type: String
        },
        groupByData: {
            type: Object
        },
        flagEntity: {
            default: null,
            type: Object
        },
        flagLeague: {
            default: null,
            type: Object
        },
        flagSport: {
            default: null,
            type: Object
        },
        flagLocation: {
            default: null,
            type: Object
        },
        selected: {
            default: null
        },
        notFoundMessage: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            overviewData: {
                groups: [],
                processed: false
            },
            loaded: false,
            sidebar: false,
            rawData: JSON.parse(JSON.stringify(this.items)),
            startDate: null,
            columns: [],
            numOfColumns: 7,
            windowHeight: window.innerHeight
        }
    },
    computed: {
        hasElements() {
            return this.overviewData.groups && this.overviewData.groups.length > 0 ? true : false
        },
        availableUsers() {
            return this.$store.state.user.users ? this.$store.state.user.users : null
        }
    },
    watch: {
        items: {
            handler(newValue, oldValue) {

                if (!this.loaded) return
                // SideBar caused double render, because it changes the DOM, this should fix it. WN
                if (this.sidebar && newValue.length === oldValue.length) {
                    setTimeout(() => {
                        this.sidebar = false;
                    }, 200)
                    return
                }
                console.log('I am Overview and rendering');
                this.rawData = JSON.parse(JSON.stringify(newValue))
                this.processData(true)
            },
            deep: true
        },
        windowHeight(newHeight, oldHeight) {
            jq('.book-overview').css('max-height', newHeight - 190 + 'px')
        }
    },
    mounted() {
        if (this.$route.name.includes("car")) {
            console.log("loading users for cars page..")
            this.loadUsers()
        }

        this.buildOverview()

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })

        jq('.book-overview').css('max-height', window.innerHeight - 190 + 'px')
    },
    methods: {
        loadUsers() {
            User.getUsers()
                .catch(err => {
                    this.$error.set(err, `It was not possible to get driver`)
                })
        },
        /**
         * Build the overview component.
         */
        buildOverview(reset = false) {
            const context = this

            // The data hasn't been updated. Don't build the overview again.
            if (!reset && context.startDate && (context.startDate == context.$route.query.date)) return

            context.startDate = context.$route.query.from
            context.createFilters()
            context.createColumns()
            context.processData()
        },
        /**
         * Create the filter items.
         */
        createFilters() {
            const context = this
            if (!context.availableFilters) return
            if (context.$route.name !== 'productions.view') return
            Lazy(context.availableFilters)
                .each((v, k) => {
                    if (v.hasOwnProperty('class')) {
                        context[v.class].get.items.forEach(v2 => {
                            v.items.push({
                                id: v2.id,
                                label: v2.label,
                                country: v2.country ? v2.country : null
                            })
                        })
                    }
                })
            // Set default selected country based on the current user's country.
            if (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items') && context.$store.state.filter.myFiltersSelected === '') {
                Lazy(context.availableFilters.country.items)
                    .filter(v => {
                        return v.id === app.$data.user.get.country
                    })
                    .each(v => {
                        Vue.set(v, 'selected', true)
                    })
            }

            context.filterReady = true
        },
        /**
         * Create the columns based on the selected date.
         */
        createColumns() {
            const context = this
            if (!context.startDate) {
                context.startDate = moment().format('YYYY-MM-DD')
            }
            const startDate = moment(context.startDate)
            for (let i=1; i <= context.numOfColumns; i++) {
                const column = {
                    weekday: startDate.format('dddd'),
                    date: startDate.format('YYYY-MM-DD'),
                    day: startDate.format('DD/MM')
                }

                // Add the week number if it's the first element or the weekday
                // is monday.
                if ((i == 1) || (startDate.isoWeekday() == 1)) {
                    column.week = startDate.isoWeek()
                }

                // Set a flag if the current date is "today".
                if (column.date == Datetime.today()) {
                    column.today = true
                }

                context.columns.push(column)
                startDate.add(1, 'd')
            }
        },

        /**
         * Process the passed data, adding extra fields to each item and
         * grouping them.
         */
        processData(reset = false) {
            const context = this
            if (!reset && context.overviewData.processed) return

            this.loaded = false

            // Add extra properties to all passed items.
            context.processItems()

            // Create grouped data.
            context.groupData()

            // Flag the overview as processed.
            context.overviewData.processed = true
            this.loaded = true
        },


        /**
         * Group the data based on "groupBy" property.
         */
        groupData() {
            const context = this
            const groups = []

            //console.log(context.groupByData);
            //console.log(context.rawData);

            console.log(context.groupBy)

            if (context.groupBy === 'channel') {
                let distinctChannels = [...new Set(context.rawData.map(t => t.channel))]

                distinctChannels.forEach(t => {
                    let groupById, group

                    //The case for the eng or edit
                    if (context.groupByData && context.groupByData.hasOwnProperty('type')) {
                        group = context.groupByData.rows;
                    } else {
                        groupById = t
                        group = [context.groupByData.getItem(groupById)]
                    }

                    // Loop through each group and its columns, placing elements
                    // into the right group/column.
                    Lazy(group)
                        .each(item => {

                            // Make a copy of the element to brake the
                            // reference "connection".
                            const g = Object.assign({}, item)

                            Vue.set(g, 'items', {})
                            context.columns.forEach((column, k) => {

                                // Find all items belonging the current group
                                // and date column and place them into it.
                                const items = context.rawData.filter(v => {

                                    const currentGroupData = Array.isArray(v[context.groupBy]) ? v[context.groupBy].find(a => a.id === g.id && a.start && a.end) : null;
                                    //console.log(v, 'group data')
                                    const { start, end } = currentGroupData || { start: v.startDate, end: v.endDate };
                                    const startTime = typeof start === 'string' ? v.start : start.format('HH:mm');
                                    const endTime = typeof end === 'string' ? v.end : end.format('HH:mm');

                                    // Generate the "timeLabel" based on the
                                    // current group.
                                    if (!v.timeLabel) {
                                        Vue.set(v, 'timeLabel', {})
                                        v.timeLabel = {}
                                        v.timeLabel.start = start
                                        v.timeLabel.startTime = startTime
                                        v.timeLabel.end = end
                                        v.timeLabel.endTime = endTime
                                    } else if (v.timeLabel && !v.timeLabel[g.id]) {
                                        Vue.set(v.timeLabel, g.id, `${startTime} - ${endTime}`)
                                    }

                                    // The current item doesn't match the current
                                    // date column. On the first day/column (k == 0),
                                    // we want to include items with "end date" equal
                                    // to "column date".
                                    if (start !== column.date && end !== column.date) {
                                        // if the item between start and end column date
                                        if (column.date < end && column.date > start) {
                                            // and if its needs to be presented in the list
                                            if (context.groupDataHelper(v, context, g)) {
                                                Vue.set(v.timeLabel, column.weekday, column.date)
                                                return true
                                            }
                                        }
                                        return false
                                    }

                                    // if returns false, not going to be presented in the list
                                    return context.groupDataHelper(v, context, g);
                                })

                                Vue.set(g.items, column.date, items)
                                g.items[column.date].sort(context.sortByTime);
                            })

                            groups.push(g)
                        })
                });
            }
            // Tasks page
            else if (context.groupBy == 'workgroups') {
                context.rawData.forEach(v => {

                    if (!this.isGroupPresentForWorkGroups(v, groups, context)) {

                        let group = this.buildGroupDataForWorkGroups(v, context)

                        // Loop through each group and its columns, placing elements
                        // into the right group/column.
                        Lazy(group)
                            .each(item => {

                                // Make a copy of the element to brake the
                                // reference "connection".
                                const g = Object.assign({}, item)

                                Vue.set(g, 'items', {})
                                context.columns.forEach((column, k) => {

                                    // Find all items belonging the current group
                                    // and date column and place them into it.
                                    const items = context.rawData.filter(v => {

                                        let itemStartDate, itemEndDate, currentGroupData

                                        if (Array.isArray(v[context.groupBy])) {
                                            currentGroupData = v[context.groupBy].find(a => a.workgroup === g.id && a.pwStartDate && a.pwEndDate)
                                        }

                                        if (currentGroupData) {
                                            itemStartDate = (typeof currentGroupData.pwStartDate === 'string') ? moment(currentGroupData.pwStartDate) : moment.unix(currentGroupData.pwStartDate)
                                            itemEndDate = (typeof currentGroupData.pwEndDate === 'string') ? moment(currentGroupData.pwEndDate) : moment.unix(currentGroupData.pwEndDate)
                                        }
                                        else {
                                            itemStartDate = moment(v[context.fields.start])
                                            itemEndDate = moment(v[context.fields.end])
                                        }

                                        let start = itemStartDate.format('YYYY-MM-DD')
                                        let end = itemEndDate.format('YYYY-MM-DD')
                                        let startTime = itemStartDate.format('HH:mm')
                                        let endTime = itemEndDate.format('HH:mm')

                                        // Generate the "timeLabel" based on the
                                        // current group.
                                        if (!v.timeLabel) {
                                            Vue.set(v, 'timeLabel', {})
                                            v.timeLabel = {}
                                            v.timeLabel.start = start
                                            v.timeLabel.startTime = startTime
                                            v.timeLabel.end = end
                                            v.timeLabel.endTime = endTime
                                        } else if (v.timeLabel && !v.timeLabel[g.id]) {
                                            Vue.set(v.timeLabel, g.id, `${startTime} - ${endTime}`)
                                        }

                                        // The current item doesn't match the current
                                        // date column. On the first day/column (k == 0),
                                        // we want to include items with "end date" equal
                                        // to "column date".
                                        //if (k === 0) {}
                                        if (start !== column.date && end !== column.date) {
                                            if (column.date < end && column.date > start) {
                                                // if the item between start and end column date
                                                if (context.groupDataHelper(v, context, g)) {
                                                    Vue.set(v.timeLabel, column.weekday, column.date)
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                        // if returns false, not going to be presented in the list
                                        return context.groupDataHelper(v, context, g);


                                    })

                                    Vue.set(g.items, column.date, items)
                                    g.items[column.date].sort(context.sortByTime);
                                })

                                groups.push(g)
                            })

                    }

                })
            }
            // ENG, Edit, Cars page (groupBy = 'equipmentLabel'
            else{
                // Find all unique groups available in rawData.
                context.rawData.forEach(v => {

                    if (!this.isGroupDataPresent(v, groups, context)) {

                        // Create the group data itself.
                        let group = this.buildGroupData(v, context)

                        // Loop through each group and its columns, placing elements
                        // into the right group/column.
                        Lazy(group)
                            .each(item => {

                                // Make a copy of the element to brake the
                                // reference "connection".
                                const g = Object.assign({}, item)

                                Vue.set(g, 'items', {})
                                context.columns.forEach((column, k) => {

                                    // Find all items belonging the current group
                                    // and date column and place them into it.
                                    const items = context.rawData.filter(v => {

                                        let itemStartDate, itemEndDate, currentGroupData

                                        if (Array.isArray(v[context.groupBy])) {
                                            currentGroupData = v[context.groupBy].find(a => a.id === g.id && a.start && a.end)
                                        }

                                        if (currentGroupData) {
                                            itemStartDate = (typeof currentGroupData.start === 'string') ? moment(currentGroupData.start) : moment.unix(currentGroupData.start)
                                            itemEndDate = (typeof currentGroupData.end === 'string') ? moment(currentGroupData.end) : moment.unix(currentGroupData.end)
                                        }
                                        else {
                                            itemStartDate = moment(v[context.fields.start])
                                            itemEndDate = moment(v[context.fields.end])
                                        }

                                        let start = itemStartDate.format('YYYY-MM-DD')
                                        let end = itemEndDate.format('YYYY-MM-DD')
                                        let startTime = itemStartDate.format('HH:mm')
                                        let endTime = itemEndDate.format('HH:mm')

                                        // Generate the "timeLabel" based on the
                                        // current group.
                                        if (!v.timeLabel) {
                                            Vue.set(v, 'timeLabel', {})
                                            v.timeLabel = {}
                                            v.timeLabel.start = start
                                            v.timeLabel.startTime = startTime
                                            v.timeLabel.end = end
                                            v.timeLabel.endTime = endTime
                                        } else if (v.timeLabel && !v.timeLabel[g.id]) {
                                            Vue.set(v.timeLabel, g.id, `${startTime} - ${endTime}`)
                                        }

                                        // The current item doesn't match the current
                                        // date column. On the first day/column (k == 0),
                                        // we want to include items with "end date" equal
                                        // to "column date".
                                        //if (k === 0) {}
                                        if (start !== column.date && end !== column.date) {
                                            if (column.date < end && column.date > start) {
                                                // if the item between start and end column date
                                                if (context.groupDataHelper(v, context, g)) {
                                                    Vue.set(v.timeLabel, column.weekday, column.date)
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                        // if returns false, not going to be presented in the list
                                        return context.groupDataHelper(v, context, g);
                                    })

                                    Vue.set(g.items, column.date, items)
                                    g.items[column.date].sort(context.sortByTime);
                                })

                                groups.push(g)
                            })

                    }

                })

            }
            // groups.sort(context.sortByLabel)
            groups.sort(context.sortByWeight)

            Vue.set(context.overviewData, 'groups', groups)
        },

        /**
         * This is helper for groupData, regarding the grouping items that should be present more than one day.
         * @param v item
         * @param context this
         * @param g group
         * @returns {boolean}
         */
        groupDataHelper(v, context, g) {
            if ((v[context.groupBy] === g.id) || ((g.id === 0) && (!v[context.groupBy]))) {
                return true;
            }
            else if (Array.isArray(v[context.groupBy])) {
                const { groupBy } = context;
                const { id } = g;
                const groupArray = v[groupBy];
                if (id === 0 && groupArray.length === 0) return true;
                if (groupArray.includes(id)) return true;
                const isMatch = groupArray.some(item => (groupBy === 'workgroups' ? item.workgroup : item.id) === id);
                if (isMatch) return true;
            }

            return false
        },

        isGroupPresentForWorkGroups(v, groups, context) {
            let isPresent = false;
            if (Array.isArray(v[context.groupBy])) {
                if (v[context.groupBy].length == 0) {
                    isPresent = groups.some(g => g.id == 0);
                } else {
                    isPresent = Lazy(v[context.groupBy]).every(item => groups.some(g => item.workgroup === g.id));
                }
            } else {
                isPresent = groups.some(g => (!v[context.groupBy] && g.id == 0) || (g.id && g.id == v[context.groupBy]));
            }

            return isPresent;
        },

        isGroupDataPresent(v, groups, context) {
            let isPresent = false

            if (Array.isArray(v[context.groupBy])) {
                if (v[context.groupBy].length == 0) {
                    isPresent = groups.find(g => g.id == 0)
                }
                else {
                    isPresent = Lazy(v[context.groupBy])
                        .every(item => {
                            return groups.find(g => item.id === g.id)
                        })
                }
            }
            else {
                isPresent = groups.find(g => {
                    if (!v[context.groupBy]) {
                        return g.id == 0
                    }
                    return g.id && g.id == v[context.groupBy]
                })
            }
            return isPresent
        },

        buildGroupDataForWorkGroups(v, context) {
            let group;

            if (Array.isArray(v[context.groupBy]) && v[context.groupBy].length > 0) {
                group = v[context.groupBy].map(item => {
                    let groupItem = (context.groupByData?.get?.items?.length > 0) ? context.groupByData.get.items.find(i => i.id === item.workgroup) : null;
                    return {
                        id: groupItem?.id || item.id,
                        label: groupItem?.label || item.label
                    };
                });
            } else if (!Array.isArray(v[context.groupBy])) {
                if (context.groupByData?.hasOwnProperty('type')) {
                    group = context.groupByData.rows;
                } else {
                    let groupById = v[context.groupBy];
                    group = [context.groupByData.getItem(groupById)];
                }
            } else {
                group = [{ id: 0, label: '' }];
            }

            return group;
        },

        buildGroupData(v, context) {
            let group;

            if (Array.isArray(v[context.groupBy]) && v[context.groupBy].length > 0) {
                group = v[context.groupBy].reduce((result, item) => {
                    if (result.find(g => g.id === item) || result.find(g => g.id === item.id)) {
                        return result;
                    }
                    if (context.groupByData?.hasOwnProperty('options')) {
                        result.push(context.groupByData.getItem(item));
                    } else {
                        result.push({ id: item.id, label: item.label });
                    }
                    return result;
                }, []);
            } else if (!Array.isArray(v[context.groupBy])) {
                group = context.groupByData?.hasOwnProperty('type') ? context.groupByData.rows : [context.groupByData.getItem(v[context.groupBy])];
            } else {
                group = [{ id: 0, label: '' }];
            }

            return group
        },

        /**
         * Add extra properties (fields) to the items in the passed data.
         */
        processItems() {
            const context = this
            const now = moment().unix()

            // Add some extra properties to each passed item.
            context.rawData.forEach(v => {
                v.start = v[context.fields.start] ? v[context.fields.start].split(' ')[1] : ''
                v.end = v[context.fields.end] ? v[context.fields.end].split(' ')[1] : ''
                v.flag = context.getFlagName(v.runType)


                v.startDate = v[context.fields.start] ? v[context.fields.start].split(' ')[0] : ''
                v.endDate = v[context.fields.end] ? v[context.fields.end].split(' ')[0] : ''


            })

        },

        /**
         * Sort an array by the "label" field.
         */
        sortByLabel(a, b) {
            const labelA = a.label.toUpperCase()
            const labelB = b.label.toUpperCase()
            if (labelA < labelB) return -1
            if (labelA > labelB) return 1
            return 0
        },

        /**
         * Sort an array by the "label" field.
         */
        sortByWeight(a, b) {
            const weightA = a.weight
            const weightB = b.weight
            if (weightA < weightB) return -1
            if (weightA > weightB) return 1
            return 0
        },

        /**
         * Sort an array by the "startTime" field.
         */
        sortByTime(a, b) {
            if (a.startTime < b.startTime) return -1
            if (a.startTime > b.startTime) return 1
            return 0
        },

        /**
         * Get the name of a flag.
         * @param integer id THe flag id
         * @return string
         */
        getFlagName(id) {
            if (!this.flagEntity) return
            const flag = this.flagEntity.getItem(id)
            if (flag && flag.label) return flag.label
            return null
        },
        getLeagueLabel(id) {
            if (!this.flagLeague) return
            const league = this.flagLeague.getItem(id)
            if (league) {
                return league ? league.shortlabel : null
            }
        },
        /**
         * Changes the time labels for created extra data of bookings.
         * Bookings extra data will be only created when time range is more than 2 days.
         * @param item
         * @param group
         * @param column
         * @returns {*|string}
         */
        getTimeLabel(item, group, column) {
            if (item.timeLabel[column.weekday]  === column.date) {
                return '<<< - >>>'
            }

            if (item.timeLabel.start === column.date && item.timeLabel.end > column.date) {
                return item.timeLabel.startTime + ' - >>>'
            }

            if (item.timeLabel.end === column.date && item.timeLabel.start < column.date) {
                return '<<< - ' + item.timeLabel.endTime
            }


            return item.timeLabel[group.id]
        },

        getLocationLabel(id) {
            if (!this.flagLocation) return
            const location = this.flagLocation.getItem(id)
            if (location) {
                return location ? location.label : null
            }
        },
        getDriverLabel(item) {
            if (this.availableUsers) {
                if (item && item.driverId) {
                    let driver = this.availableUsers.find(user => user.id === item.driverId)
                    if (driver && driver.fullName) {
                        return driver.fullName
                    }
                }
            }
            return null
        },

        /**
         * Get the html title that an item should have.
         * @param object item
         * @return string
         */
        getItemTitle(item) {
            var startDate = new Date(item.productionStart);
            let title = "";
            if (new Date(item.productionStart) < new Date(this.startDate)){
                title = `${item.productionStart}-${item.end}, ${item.title}`
            }else{
                title = `${item.start}-${item.end}, ${item.title}`
            }


            if (item.flag) title += ` (${item.flag})`
            return title
        },

        /**
         * Opens sidebar with the delay to allow double-clicking function
         * @param item object
         */
        openSidebar(item){
            setTimeout(() => {
                this.$emit('open', item);
            }, 200)
        },

        onResize() {
            this.windowHeight = window.innerHeight
        },

    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<template>
    <div class="book-overview">
        <div class="book-overview-sticky" v-if="overviewData.processed && hasElements">
            <div class="book-overview-day-navigation">
                <div class="book-overview-label"></div>
                <div class="book-overview-row-content">
                    <div class="book-overview-column" v-for="column in columns" :class="[{'book-overview-today': column.today}, {'book-overview-with-week': column.week}]">
                        <div class="book-overview-day" :title="column.weekday + ', ' + column.day">
              <span class="book-overview-week" v-if="column.week">
                <span>W</span>
                <span>{{column.week}}</span>
              </span>
                            <span class="book-overview-weekday">{{column.weekday}}</span>
                            <span class="book-overview-date">{{column.day}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="book-overview-content" v-if="overviewData.processed && hasElements">
            <div class="book-overview-row" v-for="(group, $index) in overviewData.groups" :class="($index % 2 == 0) ? 'book-overview-odd' : 'book-overview-even'">
                <div class="book-overview-label" :title="group.label">
                    <span>{{group.label}}</span>
                </div>
                <div class="book-overview-row-content">
                    <div class="book-overview-column" v-for="column in columns" :class="{'book-overview-today': column.today}">
                        <div class="book-overview-item book-item-bkg" v-for="item in group.items[column.date]" @click="openSidebar(item)" @dblclick="$emit('openDbl', item)" :title="getItemTitle(item)" :class="['book-item-status-' + item.status, {'book-item-fadeout': selected && selected === item.id}]">
                        <span class="book-overview-item-top-row">
                            <!-- <span class="book-overview-item-time">{{item.start}}-{{item.end}}</span> -->
                            <span class="book-overview-item-time">{{getTimeLabel(item, group, column)}}</span>

                            <span class="book-overview-item-league" v-if="groupBy != 'workgroups' && item.league">{{getLeagueLabel(item.league)}}</span>
                            <span class="book-overview-item-driver" v-else-if="groupBy != 'workgroups' && $route.name == 'car.view'">{{getDriverLabel(item)}}</span>

                            <span class="book-overview-item-location" v-if="item.locationId">{{getLocationLabel(item.locationId)}}</span>
                            <span class="book-overview-item-flag" v-if="item.flag">{{item.flag}}</span>
                        </span>
                            <span class="book-overview-item-title">{{item.title}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="book-overview-no-results" v-if="!hasElements">
            <span v-html="notFoundMessage"></span>
        </div>
    </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.panel > .book-overview {
  margin-bottom: -1px;
}

.book-overview-sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
  min-width: 800px;
  width: 100%;
}

.book-overview {
  align-items: center;
  flex-flow: column;
  max-width: 100%;
  overflow-x: auto;
  .book-overview-day-navigation,
  .book-overview-content {
    min-width: 800px;
    width: 100%;
  }
  .book-overview-day-navigation {

    .book-overview-label,
    .book-overview-column {
      border-bottom: 1px solid $color-grey-border;
    }
    .book-overview-column {
      padding: 2px 4px;
      &.book-overview-with-week {
        .book-overview-day {
          padding-left: 25px;
        }
      }
      &.book-overview-today {
        border-bottom-width: 1px;
        border-top-width: 2px;
      }
    }
    .book-overview-day {
      font-weight: bold;
      padding: 5px 7px;
      position: relative;
      text-align: center;
      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .book-overview-week {
      background: $color-blue;
      bottom: -4px;
      color: $color-white;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      left: -4px;
      line-height: 1.1em;
      position: absolute;
      top: -2px;
      width: 25px;
      > * {
        display: block;
      }
    }
    .book-overview-weekday,
    .book-overview-date {
      display: block;
    }
    .book-overview-weekday {

    }
  }
  .affix + .book-overview-content {
    margin-top: 56px;
  }
  .book-overview-content {
    .book-overview-column {
      padding: 2px 0;
    }
  }
  .book-overview-day-navigation,
  .book-overview-row {
    display: flex;
  }
  .book-overview-row {
    min-height: 38px;
    &:last-child {
      .book-overview-label,
      .book-overview-column:not(.book-overview-today) {
        border-bottom: 1px solid darken($color-grey-light, 3%);
      }
      .book-overview-column {
        &.book-overview-today {
          border-bottom-width: 2px;
        }
      }
    }
    &.book-overview-odd {
      background: rgba($color-blue, .1);
    }
  }
  .book-overview-label {
    background: rgba($color-row-odd, .1);
    border-right: 1px solid darken($color-grey-light, 3%);
    max-width: 70px;
    min-width: 70px;
    overflow: hidden;
    padding: 8px 6px 6px;
    text-overflow: ellipsis;
    @include breakpoint(min-width $min-desktop) {
      max-width: 100px;
      min-width: 100px;
      padding-left: 12px;
      padding-right: 12px;
    }
    > span {
      user-select: none;
    }
  }
  .book-overview-row-content {
    display: flex;
    flex: 1;
    min-width: 0;
  }
  .book-overview-column {
    border-right: 1px solid darken($color-grey-light, 3%);
    flex: 1;
    min-width: 0;
    position: relative;
    z-index: 1;
    &:last-child {
      border-right: none;
    }
    &.book-overview-today {
      border: 0 solid $color-blue;
      border-width: 0 2px;
      margin-left: -1px;
      z-index: 2;
      .book-overview-week {
        bottom: -2px;
      }
    }
  }
  .book-overview-label,
  .book-overview-item {
    font-size: 12px;
    line-height: 1.3em;
  }
  .book-overview-item {
    cursor: pointer;
    margin: 2px 4px;
    padding: 4px 5px 3px;
    user-select: none;
  }
  .book-overview-item-top-row {
    display: flex;
    font-size: 10px;
  }
  .book-overview-item-flag,
  .book-overview-item-league,
  .book-overview-item-driver,
  .book-overview-item-location,
  .book-overview-item-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .book-overview-item-time,
  .book-overview-item-league,
  .book-overview-item-driver,
  .book-overview-item-location,
  .book-overview-item-flag {
    display: inline-block;
  }
  .book-overview-item-title {
    display: block;
  }
  .book-overview-item-time {
    flex: 1 0 auto;
  }
  .book-overview-item-flag {
    flex-shrink: 1;
    &:before {
      display: inline-block;
      content: "|";
      margin: 0 3px;
    }
  }
  .book-overview-item-location {
    flex-shrink: 1;
    //&:before {
    //  display: inline-block;
    //  content: "|";
    //  margin: 0 3px;
    //}
  }
  .book-overview-item-league, .book-overview-item-driver {
    flex: 30 1 auto;
    flex-shrink: 1;
    &:before {
      display: inline-block;
      content: "|";
      margin: 0 3px;
    }
  }
  .book-overview-no-results {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1;
    padding: 10px;
    text-align: center;
    // > * {
    //     display: flex;
    // }
  }
}
</style>