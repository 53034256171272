<script>
/**
 * @file
 * Book Autocomplete component.
 * Builds a "dropdown" with search functionality.
 */
import bookLoader from 'components/common/loader/BookLoader'
import jq from 'jquery';

export default{
   components: {
        bookLoader
    },
    props: {
        defaultValue: {
            type: String,
            default: ""
        },
        defaultValueInt: {
            type: Number,
            default: 0
        },
        disabled: {
            default: false,
            type: Boolean
        },
        fieldLabel: {
            type: String,
            required: false,
            default: "label"
        },
        fieldValue: {
            type: String,
            required: false,
            default: "code"
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false
        },
        isEntity:{
            type: Boolean,
            required: false,
            default:false
        },
        isUseNoneValue:{
            type: Boolean,
            required: false,
            default:false
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        itemsToDisplay:{
            type: Number,
            default: 5
        },
        name: {
            type: String,
            required: false,
            default: ""
        },
        tabindex:{
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            isOpen: false,
            results: [],
            search: "",
            isLoading: false,
            arrowCounter: 0,
            value: "",
        };
    },
    computed:{
        processedItems(){
            let processed = {};
            if (this.isEntity){
                processed = this.items.filter(item => {
                    return entityItemIsVisible(item, this.name) == true;
                });
            }else{
                processed = this.items;
            }
            return processed;
        },
        noneValue(){
            return {}
        }
    },
    methods: {
        onChange() {
            // Let's warn the parent that a change was made
            this.$emit("input", this.search);

            // Is the data given by an outside ajax request?
            if (this.isAsync) {
                this.isLoading = true;
            } else {
                // Let's search our flat array
                this.filterResults();
                this.isOpen = true;
            }
        },
        onClick(){
            if (this.search || this.defaultValue || this.defaultValueInt){
                //First look by default value
                let selectedItem = {};
                if (this.defaultValue)
                    selectedItem = this.processedItems.find(item => item[this.fieldValue] == this.defaultValue);
                else if(this.defaultValueInt)
                    selectedItem = this.processedItems.find(item => item[this.fieldValue] == this.defaultValueInt);
                
                //If nothing found look for search value
                if (!selectedItem)
                    selectedItem = this.processedItems.find(item => {
                        return item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                    });
                if (selectedItem){
                    this.arrowCounter  = this.processedItems.indexOf(selectedItem);
                }   
            }
            this.results = this.processedItems;
            this.isOpen = true;
        },
        filterResults() {
            this.arrowCounter = -1;
            // first uncapitalize all the things
            this.results = this.items.filter(item => {
                return item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        /**
         * Fix the sidebar height to the same as the calendar.
         */
        fixResultsHeight() {
            Vue.nextTick(() => {
                setTimeout(() => {
                    let height = 27*this.itemsToDisplay;
                    jq('.autocomplete .autocomplete-results').css('min-height', height + 'px')
                }, 10)
            })
        },
        setResult(result) {
            this.search = result[this.fieldLabel];
            this.value = result[this.fieldValue];;
            this.updateValue();
            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEnter() {
            let selectedItem = this.results[this.arrowCounter];
            this.search = selectedItem[this.fieldLabel];
            this.value = selectedItem[this.fieldValue];
            this.updateValue();
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        onEsc(){
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        setNoneValue(){
            this.search = "None";
            this.$emit('input', null)
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        updateValue(){
            this.$emit('input', this.value)
        },
    },
    watch: {
        items: function(val, oldValue) {
        // actually compare them
            if (val.length !== oldValue.length) {
                this.results = val;
                this.isLoading = false;
            }
        }
    },
    mounted() {
        if (this.items) {
          if (this.defaultValue) {
            this.search = this.items.find(item => item[this.fieldValue] == this.defaultValue)[this.fieldLabel];
          } else if (this.defaultValueInt) {
            this.search = this.items.find(item => item[this.fieldValue] == this.defaultValueInt)[this.fieldLabel];
          } else if (this.isUseNoneValue) {
            this.search = "None";
          }
          this.$nextTick(() => {
            this.fixResultsHeight()
            document.addEventListener("click", this.handleClickOutside);
            document.addEventListener("focusout", this.handleClickOutside);
          })
        }
    },
    destroyed() {
        document.removeEventListener("click", this.handleClickOutside);
        document.removeEventListener("focusout", this.handleClickOutside);
    }
}

</script>

<template>
    <div class="autocomplete">
        <input type="text" class="form-control" :name="this.name" @click="onClick" @input="onChange" v-model="search" @keyup.down="onArrowDown" @keyup.up="onArrowUp" 
        @keyup.enter="onEnter" @keydown.esc="onEsc" autocomplete="off" @focus="onClick" :disabled="disabled" :tabindex="tabindex"/>
        <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
            <li v-if="isUseNoneValue" :key="-1" @click="setNoneValue()" class="autocomplete-result" 
               :class="{ 'is-active': -1 === arrowCounter }">
                {{ "None" }}
            </li>
            <li class="loading" v-if="isLoading">
                Loading results...
            </li>
            <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result" 
               :class="{ 'is-active': i === arrowCounter }">
                {{ result.label }}
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.autocomplete{
    position: relative;
    width: 100%;

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 100%;
        min-height: 120px;
        max-height: 240px;
        overflow: auto;
        width: 100%;
        position: absolute;
        z-index: 9999;
        background-color: #ffffff;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active {
        background-color: #1c7BB4;
        color: white;
    }
    .autocomplete-result:hover {
        background-color: #eeeeee;
        color: #333333;
    }
}
</style>