import io from 'socket.io-client'
window.io = io
import Echo from 'laravel-echo';
export default {
    install(Vue, options) {
        if (!options) throw new Error("[Socket] cannot locate options")
        if (typeof options !== 'object') throw new Error("[Socket] cannot initiate options")
        if(typeof options.socketId == 'function') Vue.prototype.$echo = options
        else Vue.prototype.$echo = new Echo(options)
        Vue.mixin({
            created() {
                this.socketChannels = []
                let socket = this.$options['socket']
                if (socket) {
                    Object.keys(socket).forEach(k => {
                        let channel
                        if (k.startsWith('private:')) channel = this.$echo.private(k.replace('private:', ''))
                        else if (k.startsWith('presence:')) channel = this.$echo.join(k.replace('presence:', ''))
                        else channel = this.$echo.channel(k)
                        let events = socket[k]
                        Object.keys(events).forEach(function(key) {
                            // Bind the VM as second parameter.
                            channel.listen(key, (payload) => {
                                events[key](payload, this)
                            });
                        }, this);
                        this.socketChannels.push(channel)
                    })
                }
            },
            beforeDestroy() {
                if (this.$options['socket']) {
                    this.socketChannels.forEach(channel => {
                        this.$echo.leave(channel.name)
                    })
                }
            }
        })
    }
}