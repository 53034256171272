
import Api from 'components/app/Api'

class Ingestion extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null, itemId = 0, itemType = '') {
        super({
            id: id,
            apiUrl: `api/${itemType}/${itemId}/ingestion`,
            module: 'ingestion',
        })
    }

}

export default Ingestion
