const state = {
    loading: []
}
const getters = {
    visible(state, getters, rootState) {
        return state.loading && state.loading.length > 0
    }
}
const mutations = {
    add(state, value)  {
        const route = window.location.href
        if (value.indexOf('equipment-usage') > -1 && route.indexOf('/mcr') > -1) {
            //console.log('Add INC', state, value)
            return
        }
        let i = state.loading.indexOf(value)
        if (i == -1) state.loading.push(value)
    },
    remove(state, value)  {
        let i = state.loading.indexOf(value)
        if (i > -1) state.loading.splice(i, 1)
    }
}
const actions = {}
const headerLoader = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
export {headerLoader}
