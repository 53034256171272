<script>
import {app} from 'app'
import BookLoader from 'components/common/loader/BookLoader'
import Ingestion from 'components/ingestion/Ingestion'
import Item from 'components/common/item/Item'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import Mcrdata from 'components/mcr/Mcrdata'

import _ from 'lodash';

export default {
  components: {
    BookLoader,
  },
  props: {
    ingestionTypes: {
      default: () => [],
      type: Array
    },
    ingestionActions: {
      default: () => [],
      type: Array
    },
    items: {
      default: () => [],
      type: Array
    },
    itemId: {
      default: 0,
      type: Number
    },
    itemType: {
      default: '',
      //type: String // causing type error -> Object with value.
    },
    itemCountry: {
      default: '',
    },
    readonly: {
      default: false,
      type: Boolean
    },
    active: {
      default: false,
      type: Boolean
    },
    recordRequested: {
      default: false,
      type: Boolean
    },
    ingestionFlow: {
      default: '',
    },
    ingestors: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      itemClass: null,
      channelEntity: null,
      country: 'se, no, dk, fi, uk',
      // for record button WN.
      recordRequest: this.recordRequested,
      ingestNumber: 0,
      ingestStatus: 0,
      ingestFromEntity: {},
      manualReadonly: {
        default: false,
        type: Boolean
      },
    }
  },
  watch: {
    'items': {
      //
      handler: function(newValue) {
        this.country = this.itemCountry
      },
      deep: true
    }
  },
  beforeMount() {
    // we need to add channel ownership functionality here maybe.
    // Create an instance for the current country of feed/production
    this.country = this.itemCountry
    // create an ingestion factory
    let ingests = this.ingestionFlow.split(',')
    this.ingestFromEntity = {
      element: this.itemType,
      elementId: this.itemId,
      ingestHouse: ingests,
      newIngest: []
    }
  },
  computed: {
    setting() {
      // The settings name is "plural".
      return this.$settings.get[`${this.itemType}s`]
    },
    processingId() {
      return `BookIngestionProcessing_${this.itemId}`
    },
    processing() {
      return this.$loader.has(this.processingId)
    },
    hasIngestions() {
      return this.ingestionTypes.length > 0
    },
    isMCRView() {
      return this.$route.name === 'mcr'
    }

  },
  methods: {
    isDisabled() {
      // const context = this
      // if (!context.country) return false
      return false
    },

    isIngested(type) {
      const context = this

      let status = false
      // Settings for Automatic ingestions
      let ingests = context.ingestionFlow.split(',')
      let tempType = type
      // but as I can see, we need to implement country restrictions as well.
      let ingestCountry = app.$data.user.get.country

      // Automatic ingestion options should be here.
      if (this.items && this.items.length > 0) {

        this.items.find(v => {
          if (v.ingestionType === 'notick' && v.country === ingestCountry) {
            // avoid re-converting. WN
            if (this.ingestNumber === 0) {
              //v.ingestionType = 'notrecord'
              //context.ingestNumber = 1
              context.ingestStatus = 1
            }
          }
        })

        if (type === 'notrecord' && context.ingestNumber === 1) type = 'notick'

        status = this.items.find(v => {
          if (v.ingestionType === type && v.country === ingestCountry) return v
        })
      }

      //
      if (status) {
        // if its ingested, please activate ingest status, so we'll understand its ingested.
        // 1 for all ingestion buttons, type for only same type of ingestions
        context.ingestStatus = 1
        // also handle recordRequest here, if its already ingested, means request already handled.
        // otherwise, recordRequest should be represented as " blank " in the backend.
        context.recordRequest = false
        return status
      }
      else {
        // @TODO is this condition actually correct
        if (context.recordRequest && (context.ingestNumber === null || context.ingestNumber < 1)) return 'blank'

        // if its ingested to blank ingestNumber becomes null, then we need to show it as blank
        if (context.ingestNumber === null && context.ingestStatus === 1) return 'blank'


        // ingestNumber needs to set it 0 to gather correct ingestionFlow, because
        // after you filter items ingestNumber somehow changing, because of A2.
        if (_.includes(ingests, 'record') && tempType === 'notrecord') {
          context.ingestNumber = 0
          return 'record'
        }

        // A2
        if (_.includes(ingests, 'notrecord')) {
          // its changing to notick to check difference so we need this as well.
          if (tempType === 'notick' && this.ingestNumber === 1) return 'notrecord'

          if (context.ingestNumber === 0 || context.ingestNumber === null) {
            //tempType = 'notrecord'
            context.ingestNumber = 1
            return 'notrecord'
          }

        }
        // A3
        if (_.includes(ingests, 'blank') && tempType === 'notrecord') {
          context.ingestNumber = null
          return 'blank'
        }

        // other ingestions share, repeat
        if (_.includes(ingests, tempType)) {
          if (context.ingestNumber === 1 && tempType === 'repeat') context.ingestNumber = null
          if (context.ingestNumber === 1 && tempType === 'share') context.ingestNumber = null
          return true
        }

      }



    },

    isTraffic(type) {
      let status = false
      // but as I can see, we need to implement country restrictions as well.
      let ingestCountry = app.$data.user.get.country
      // we need tx status types
      let txStatuses = ['start', 'finish', 'air']
      console.log('activated')

      // Automatic ingestion options should be here.
      if (this.items && this.items.length > 0) {
        this.items.find(v => {
          // we need manual interaction for updating the existed id. (including in ingestionType and the requested Type)
          if (_.includes(txStatuses, v.ingestionType) && v.country === ingestCountry && _.includes(txStatuses, type)) {
            status = v
          }
        })
      }
      return status
    },

    /**
     * ingest(@param)
     * Ingests the target as True / False (bool) stances
     *
     * --  Description:
     * We have originally 2 stance ingestions (true,false) but, it has been requested to make
     * record button to have 3 stances. Because of this request, i had to create the instance
     * with the "ingestNumbers". Those numbers represents the stages of the ingestion.
     * notick -> do-not-record, notrecord -> record (Sorry..)
     *
     * All ingestions are bound to front-end and back-end structure is
     * totally depends of their names, that structure didn't work for our need so i changed into
     * a messy way, but smart-way.
     *
     * This way of usage, wont cause any performance issues.
     * WN
     */

    ingest(type) {
      const context = this
      if (context.isDisabled() || context.readonly || type === 'share' || type === 'repeat') return

      // this options required for to seperate ingestions between standard and tx/viaplay operations.
      let types = ['record', 'notrecord', 'blank', 'repeat', 'share']
      let trafficTypes = ['start', 'finish', 'air']
      let clone = false

      trafficTypes = _.includes(trafficTypes, type)
      types = _.includes(types, type)

      // pff
      context.ingestFromEntity.newIngest = []

      // Just because we dont have notick ingestion originally, when we turn something into notick
      // we need to create a type with the true "ingestNumber", otherwise it wont find notick's ID
      // WN
      let fType
      if (type === 'notrecord' && context.ingestNumber === 1) { fType = 'notick' }

      let currentIngestion

      // if its traffic button that ingesting, then go for traffic.
      if (trafficTypes) {
        currentIngestion = context.isTraffic(type)
      }

      // lets use viaplay here also for routing ingest.
      if (types || type === 'routed') {
        currentIngestion = fType ? context.isIngested(fType) : context.isIngested(type)
      }

      const inge = typeof currentIngestion === 'object' ? currentIngestion.ingestionType : null
      console.log(currentIngestion, context.ingestNumber, type, 'off', trafficTypes)

      //@TODO We are not allowing manual ingests if its not inside of the Ingestors.
      if (typeof currentIngestion === 'string' && types || typeof currentIngestion === 'undefined' && types) {

        // if its not defined means, house number ingest didnt occur, so shall we start from record? :)
        if (typeof currentIngestion === 'undefined') currentIngestion = 'blank'

        if (!context.isMCRView) context.$loader.add(context.processingId)

        // blank to record
        if (type === 'notrecord' && currentIngestion === 'blank') {
          console.log('STAGE BLANK -> RECORD', context.ingestNumber);
          // pull record and push notrecord
          _.pull(context.ingestFromEntity.newIngest,'blank')
          context.ingestFromEntity.newIngest.push('record')
          // handle recordRequest when you click for FORK
          context.recordRequest = false
          // check triggers with house number relations
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })
          return
        }
        // record to notrecord
        if (type === 'notrecord' && currentIngestion === 'record') {
          console.log('STAGE RECORD -> NOT-RECORD', context.ingestNumber);
          // pull record and push notrecord
          _.pull(context.ingestFromEntity.newIngest,'record')
          context.ingestFromEntity.newIngest.push('notrecord')
          // alert
          //context.$alert.set(`The ${context.itemType} is trying to find <b>NOT RECORD</b> type of ingest in Ingestors.`, 'warning', 5)
          // check triggers with house number relations
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })
          return
        }
        // special notrecord/notick to blank
        if (type === 'notrecord' && currentIngestion === 'notrecord') {
          console.log('STAGE NOTICK -> BLANK', context.ingestNumber);
          // pull notick/notrecord push blank

          context.ingestNumber = null

          context.ingestFromEntity.newIngest.push('blank')
          _.pull(context.ingestFromEntity.newIngest,'notick')
          _.pull(context.ingestFromEntity.newIngest,'notrecord')
          //alert
          //context.$alert.set(`The ${context.itemType} is trying to find <b>BLANK</b> type of ingest in Ingestors.`, 'warning', 5)
          // check triggers with house number relations
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })
          return
        }
        // if its string just stop here.
        return
      }

      //return
      /**
       if currentIngestion has a type
       definitions:
       *******
       not record && ingestNumber === 0 => record (notrecord in structure)
       not record && ingestNumber === 1 => not record (notick in structure)
       ingestNumber === null => blank (no record in db)
       *****
       */

      //@TODO Manual ingestions are below here, coded already for any in case of request..

      let id = typeof currentIngestion === 'object' && currentIngestion ? currentIngestion.id : 0

      if (id === 0 && context.isMCRView) {
        clone = true
        // 8 digit clone number..
        id = Math.floor(100000000000 + Math.random() * 900000000000);
      }
      const ingestion = new Ingestion(id, context.itemId, context.itemType)

      if (inge) {
        console.log(currentIngestion, type, trafficTypes, 'hmm')
        // TX Status start
        if (trafficTypes && type !== inge) {
          console.log('TX STAGE UPDATE', type);
          ingestion.set('ingestionType', type)
          ingestion.set('country', app.$data.user.get.country)
          if (context.isMCRView) {
            context.updateIngestions(ingestion.get, 'save')
          } else {
            ingestion.save()
                .then(response => {
                  context.updateIngestions(response.data)
                  //context.$alert.set(context.getMessage(response.data, 'enable'), 'success', 6)
                  context.$loader.remove(context.processingId)
                })
                .catch(error => {
                  context.$error.set(error)
                  context.$loader.remove(context.processingId)
                })
          }
          return
        }
        // TX Status end

        //-----------------------------------
        // notick to blank ? specially converted fType
        if (fType === 'notick' && context.ingestNumber === 1) {
          console.log('STAGE 2 DELETE', context.ingestNumber, fType, type, currentIngestion);
          if (_.includes(context.ingestFromEntity.newIngest, 'blank')) _.pull(context.ingestFromEntity.newIngest,'blank')
          context.ingestFromEntity.newIngest.push('blank')
          _.pull(context.ingestFromEntity.newIngest,'notick')
          _.pull(context.ingestFromEntity.newIngest,'notrecord')

          // since we are manually ingesting to blank, we should give ingestStatus
          context.ingestNumber = null
          context.ingestStatus = 1

          ingestion.delete()
              .then(response => {
                context.removeIngestion(id)
                //context.$alert.set(context.getMessage(currentIngestion, 'disable'), 'success', 6)
                context.$loader.remove(context.processingId)
              })
              .catch(error => {
                context.$error.set(error)
                context.$loader.remove(context.processingId)
              })

          // check triggers with house number relations, since RETURN here, we have to add check
          // here as well wn
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })

          return
        }

        // record to notick
        if (inge === 'notrecord' && context.ingestNumber === 0) {
          console.log('STAGE 1 SAVE', context.ingestNumber);

          // pull record and push notrecord
          _.pull(context.ingestFromEntity.newIngest,'record')
          context.ingestFromEntity.newIngest.push('notrecord')

          context.ingestNumber = 1;

          ingestion.set('ingestionType', 'notick')
          ingestion.set('country', app.$data.user.get.country)
          ingestion.save()
              .then(response => {
                context.updateIngestions(response.data)
                //context.$alert.set(context.getMessage(response.data, 'notick'), 'success', 6)
                context.$loader.remove(context.processingId)
              })
              .catch(error => {
                context.$error.set(error)
                context.$loader.remove(context.processingId)
              })
          // @TODO if its needed we need blank operation to choose manually
          // for now we dont have storing blank operation thus, we cant specify blank ingest
          // it will read from house number for blank operation.
        } else {
          // notick to blank (remove)
          console.log(context.ingestNumber, type, ingestion, currentIngestion, 'everything, type, ing, curing')
          // remove other ingestion if its not in our type
          if (types) {
            _.pull(context.ingestFromEntity.newIngest, currentIngestion.ingestionType)
            context.ingestFromEntity.newIngest.push('blank')

            context.ingestNumber = null
          }
          if (context.isMCRView) {
            context.removeIngestion(id)
          } else {
            ingestion.delete()
                .then(response => {
                  context.removeIngestion(id)
                  //context.$alert.set(context.getMessage(currentIngestion, 'disable'), 'success', 6)
                  context.$loader.remove(context.processingId)
                })
                .catch(error => {
                  context.$error.set(error)
                  context.$loader.remove(context.processingId)
                })
          }
        }
        if (types) {
          // check triggers with house number related triggers with delay
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })
        }
      }
      else {
        // blank to record // or other ingestions blank to repeat or blank to share
        console.log('STAGE 3 STORE', context.ingestNumber);
        // store other ingestion if its in the types
        if (types) {
          if (type === 'notrecord') {
            context.ingestNumber = 0
            // record store
            context.ingestFromEntity.newIngest.push('record')
          } else {
            context.ingestFromEntity.newIngest.push(type)
          }
        }

        ingestion.set('ingestionType', type)
        ingestion.set('country', app.$data.user.get.country)
        if (context.isMCRView) {
          context.updateIngestions(ingestion.get, 'store')
        } else {
          ingestion.store()
              .then(response => {
                context.updateIngestions(response.data)
                //context.$alert.set(context.getMessage(response.data, 'enable'), 'success', 6)
                context.$loader.remove(context.processingId)
              })
              .catch(error => {
                context.$error.set(error)
                context.$loader.remove(context.processingId)
              })
        }

        // if its in the types range, then check for housenumber
        if (types) {
          // check triggers with house number related triggers with delay
          Vue.nextTick(() => {
            context.checkIngestHouse(context.ingestFromEntity.newIngest)
          })
        }

      }

    },
    checkIngestHouse(newIngest) {

      const context = this
      // ingestors click functionality is here.
      const data = context.ingestors
      let _uc = app.$data.user.get.country
      let _s = false
      Lazy(data.get.items)
          .each(function(v,k) {
            if (_.includes(_uc, v.country) && v.country === _uc) {
              let _f = v.userfield1_value.split(',')
              if (_.isEqual(_f, newIngest)) {
                // applying houseNumber
                let _label = v.userfield2_value ? v.userfield2_value : v.label // if its click operation apply click-housenumber function field if its not defined, apply original hnum.
                context.applyHouseNumber(context.itemId, context.itemType, _uc, _label)
                _s = true
              }
            }
          })
      if (!_s) {
        context.$alert.set('Not able to find ' + newIngest + ' inside of the Ingestors', 'danger', 3)
        context.$loader.remove(context.processingId)
      }
      // we need to delete all recorded ingestions when house number applies
    },

    applyHouseNumber(itemId, itemType, country, newHouseNumber) {
      const context = this
      let itemClass
      if (context.isMCRView) { itemClass = new Mcrdata(itemId, itemType) }
      else { itemClass = context.getNewClass(itemId) }

      let houseNumber = itemClass.get.houseNumber
      let isPrevHouseNumberRec = false
      let isCurrentHouseNumberRec = context.isForFork(newHouseNumber, country)

      if (houseNumber) {
        // if its json string turn into JSON and then re-string it after apply of houseNumber
        if (isJsonSring(houseNumber)) {
          houseNumber = JSON.parse(houseNumber)
          isPrevHouseNumberRec = context.isForFork(houseNumber[country], country)
          houseNumber[country] = newHouseNumber.toString()
          houseNumber = JSON.stringify(houseNumber)
        } else houseNumber = newHouseNumber.toString()
      } // if it's null or empty or undefined create new
      else {
        // if its feed get MCR and turn into json with fields of houseNumber
        if (itemType === 'feed') {
          let _mcr = itemClass.get.mcr
          let _hn = {}
          Lazy(_mcr)
              .each(function (v, k) {
                if (v === country) _hn[v] = newHouseNumber.toString()
                else _hn[v] = ''
              })

          houseNumber = JSON.stringify(_hn)
        }
        // if its production, get country list
        if (itemType === 'production') {
          let _mcr2 = context.$store.state.system.countries ? context.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
          let _hn2 = {}
          Lazy(_mcr2)
              .each(function (v, k) {
                if (v === country) _hn2[v] = newHouseNumber.toString()
                else _hn2[v] = ''
              })

          houseNumber = JSON.stringify(_hn2)
        }
      }

      houseNumber = (newHouseNumber === 'null' || newHouseNumber === 'blank' || newHouseNumber === 'empty') ? '' : houseNumber
      itemClass.set('houseNumber', houseNumber)
      itemClass.set('house_number_changing', 'yes')

      let newHouseNumbers = null
      if(houseNumber !== '') {
        newHouseNumbers = Object.assign({}, JSON.parse(houseNumber))
        for(const h in newHouseNumbers) {
          newHouseNumbers[h] = newHouseNumbers[h] !== null && newHouseNumbers[h] !== '' ? this.isForFork(newHouseNumbers[h], h) : false
        }
      }


      let placeholderItem = {
        itemType: itemType,
        itemId: itemId,
        houseNumber: newHouseNumbers,
        ingestion: true
      }
      //itemClass.set('contributions', '')
      //itemClass.set('associations', '')
      // some delay pls
      if (context.isMCRView) {
        // update for associations over MCR-View page

        //TO DO: need to pass forkPlaceholders array to MCR
        app.$emit('AutoChangeHouseNumber', {
          itemType: itemType,
          itemId: itemId,
          action: {
            module: itemType.toLowerCase(),
            name: 'HouseNumber',
            type: 'save'
          },
          item: {
            id: itemId,
            houseNumber: houseNumber
          },
          houseNumber: houseNumber
        })

      } else {
        setTimeout(() => {
          // now save the event
          // TO DO: add forkPlaceholders for saving in item
          itemClass.saveIn()
              .then(response => {
                const now = moment().unix()
                Item.parse(response.data, itemType, now)
                itemClass.add(response.data)
                //context.$alert.set(`HouseNumber successfully added/saved.`, 'success', 10)
                context.$loader.remove(context.processingId)
                app.$emit('updateRecPlaceholder', placeholderItem)
              })
              .catch(error => {
                this.$error.set(error)
                context.$loader.remove(context.processingId)
              })
        }, 100)
      }
    },

    /**
     * checks ingest entity and returns if this housenumber value marked
     * for export to FORK
     * @param {*} value //housenumber value
     * @param {*} country //housenumber country
     */
    isForFork(value, country) {
      // one letter only and rest integer is requested from FORK integration team ( should be applied to 'int' )
      const determineIngestOperators = (value) => {
        if (!value) return 'blank'
        let oneLetterOrOnlyIntegers = /^[a-zA-Z]\d+$|^\d+$/
        if (oneLetterOrOnlyIntegers.test(value.trim())) return 'int'
        return value;
      }

      const findIngestEntity = (val, country) => {
        let ingestEntityItems = this.ingestors && this.ingestors.get && this.ingestors.get.items.length > 0 ? this.ingestors.get.items : null
        return ingestEntityItems ? _.find(ingestEntityItems, i => i.country === country && (i.label === val || i.shortlabel === val || i.userfield2_value === val)) : null
      }

      let val = determineIngestOperators(value)
      let ing = findIngestEntity(val, country)
      return ing ? ing.description === 'yes' : false
    },

    /**
     * Add/update an ingestion item on the current feed/production.
     * @param item
     * @param actionType
     */
    updateIngestions(item, actionType) {
      const items = this.items.slice(0)
      let i = items.findIndex(v => v.id === item.id)
      if (i > -1) items.splice(i, 1)
      items.push(item)
      let emitName = this.isMCRView ? 'AutoChangeIngestions' : 'changeIngestions'
      // send items to save from emit
      app.$emit(emitName, {
        itemId: this.itemId,
        itemType: this.itemType,
        item: item, // { country, id, ingestionType }
        action: {
          module: 'ingestion',
          type: actionType,
        },
        items: items
      })
    },

    /**
     * Remove an ingestion from the current feed/production.
     * @param integer id The id of the ingestion.
     */
    removeIngestion(id) {
      const items = this.items.slice(0)
      let i = items.findIndex(v => v.id == id)
      if (i > -1) items.splice(i, 1)
      let emitName = this.isMCRView ? 'AutoChangeIngestions' : 'changeIngestions'
      app.$emit(emitName, {
        itemId: this.itemId,
        itemType: this.itemType,
        item: {id: id}, // { country, id, ingestionType }
        action: {
          module: 'ingestion',
          type: 'delete',
        },
        items: items
      })
    },

    /**
     * Get a new class instance, based the child component.
     * @param mixed opts (optional) The parameters that the new class instance should
     *                   be created with.
     * @return class
     */
    getNewClass(opts = null) {
      switch (this.itemType) {
        case 'feed':
          return new Feed(opts)
        case 'production':
          return new Production(opts)
      }
    },

    /**
     * Get the string to be used in the button title attribute.
     */
    getButtonTitle(type) {
      if (this.isDisabled()) {
        if (type === 'notrecord') return `You're not allowed to change the record flag of this ${this.itemType}, because your country doesn't match the ${this.itemType} country`
        return `You're not allowed to ${type} this ${this.itemType}, because your country doesn't match the ${this.itemType} country`
      }
      if (type === 'blank' || type === 'empty' || type === 'null') {
        if (this.recordRequest) return 'Record Requested from Calendar'
        return 'do nothing'
      }
      if (type === 'notrecord') return 'record'
      if (type === 'notick') return 'notrecord'
      if (type === 'start') return 'Planned'
      if (type === 'finish') return 'Rec Started'
      if (type === 'air') return 'Ready to Air'
      if (type === 'routed') return 'Routed'
      return type
    },

    /**
     * Get classes for the ingest button.
     */
    getClasses(type) {
      // for entity page preview settings, make them all active if active is true. WN
      if (this.active) {
        return {
          'book-ingestion-item-active': true,
          'book-ingestion-item-readonly': true
        }
      }

      let cField = false
      if (type === 'notrecord' && this.ingestNumber === 1) { cField = true }
      let status = this.isIngested(type)

      // only record button is should be active, other buttons should be activated by houseNumber ingest Entity
      return {
        'book-ingestion-item-requested': status === 'blank' && this.recordRequest,
        'book-ingestion-item-notick': cField || status === 'notrecord',
        'book-ingestion-item-active': status === 'record' || status !== 'blank' && status && !cField,
        'book-ingestion-item-disabled': this.isDisabled(),
        'book-ingestion-item-readonly': this.readonly ? this.readonly : (type === 'repeat' || type === 'share')
      }

    },

    /**
     * Get a message to be presented ingest is enabled or disabled.
     */
    getMessage(data, status) {
      console.log(status, data, 'GM')

      switch (data.ingestionType) {
        case 'notick':
          return (status == 'notick') ? `The ${data.itemType} won\'t be recorded.` : `The ${data.itemType} do nothing.`
        case 'notrecord':
          return (status == 'enable') ? `The ${data.itemType} has been flagged to be recorded.` : ''
        case 'repeat':
          return (status == 'enable') ? `The ${data.itemType} has been flagged to be repeated.` : `The ${data.itemType} won\'t be repeated.`
        case 'share':
          return (status == 'enable') ? `The ${data.itemType} has been flagged to be shared.` : `The ${data.itemType} won\'t be shared.`
        case 'start':
          return (status == 'enable') ? `The ${data.itemType} record planned.` : `The ${data.itemType} record not planned.`
        case 'finish':
          return (status == 'enable') ? `The ${data.itemType} record started.` : `The ${data.itemType} record not started.`
        case 'air':
          return (status == 'enable') ? `The ${data.itemType} ready for air.` : `The ${data.itemType} not ready for air.`
        case 'routed':
          return (status == 'enable') ? `The ${data.itemType} has been routed.` : `The ${data.itemType} has not been routed.`
      }
    },
    ingestionData() {
      let data = this.ingestionTypes

      // preview button options and adjustments for database from Entity Ingestor records. WN
      if (this.active) {
        if (this.$route.name === 'entity.id') {

          if (_.includes(data, 'notrecord')) {
            _.pull(data,'notrecord')
            _.pull(data,'blank')
            data.push('notick')
          }
          if (_.includes(data, 'record')) {
            _.pull(data,'record')
            _.pull(data,'blank')
            data.push('notrecord')
          }
          // if its blank, leave an empty block
          if (_.includes(data, 'blank')) {
            _.pull(data,'record')
            _.pull(data,'notick')
            _.pull(data,'notrecord')
          }

          data = _.sortBy(_.reverse(data))
        }
      }

      return data
    }

  }
}
</script>

<template>
  <div class="book-ingestion" :id="'book-ingestion-' + itemType + '-' + itemId" :class="{'book-ingestion-processing': processing && !isDisabled()}" v-if="hasIngestions">
    <button
        v-for="type in ingestionData()"
        :title="getButtonTitle(type)"
        class="book-ingestion-item"
        :class="['book-ingestion-item-' + type, getClasses(type)]"
        @click="ingest(type)">
      <font-awesome-icon icon="share" aria-hidden="true" v-if="type === 'share'"/>
    </button>
    <div class="book-ingestion-overlay" v-if="processing && !isDisabled()">
      <book-loader :small="true" ></book-loader>
    </div>
  </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-ingestion {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  &.book-ingestion-processing {
    .book-ingestion-item {
      opacity: 0 !important;
    }
  }
  .book-ingestion-item {
    background: none;
    border: 2px solid rgba($color-text, .5);
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    padding: 0;
    position: relative;
    user-select: none;
    vertical-align: middle;
    width: 20px;
    // z-index: 1;
    &:focus {
      outline: none;
    }
    &:before {
      display: block;
      content: " ";
      left: 50%;
      opacity: .5;
      top: 50%;
    }
    // &:after {
    //     border-top: 2px solid $color-red;
    //     display: block;
    //     content: " ";
    //     left: -8px;
    //     position: absolute;
    //     top: 7px;
    //     transform: rotate(45deg);
    //     width: 32px;
    // }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly),
    &.book-ingestion-item-active {
      border-color: rgba($color-text, 1);
      &:before {
        opacity: 1;
      }
      // &:after {
      //     display: none;
      // }
      svg {
        opacity: 1;
      }
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      box-shadow: 0px 1px 10px -1px $color-shadow;
    }
    &.book-ingestion-item-disabled {
      border-color: $color-grey-border;
      cursor: not-allowed;
      opacity: .6 !important;
      &:before {
        //opacity: 1;
      }
      &:after {
        //display: none;
      }
      svg {
        opacity: 1 !important;
      }
    }
    &.book-ingestion-item-readonly {
      cursor: default !important;
    }
    + .book-ingestion-item {
      margin-left: 4px;
    }
    svg {
      opacity: .5;
    }
  }
  .book-ingestion-item-notrecord {
    //border-color: rgba($color-text, 1);
    &:before {
      svg {
        opacity: 1;
        display: none;
      }
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-active {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-requested {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-yellow;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-yellow;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-disabled {
      //border: 2px solid rgba($color-text, 1);
      //opacity: 1 !important;
      &:before {
        // background: $color-grey-border;
      }
    }
  }

  .book-ingestion-item-notick {
    border-color: rgba($color-text, 1);
    &:before {
      background: $color-red;
      border-radius: 100%;
      position: absolute;
      height: 10px;
      margin: -5px 0 0 -5px;
      opacity: 0.5;
      width: 10px;
    }
    &:after {
      border-top: 2px solid $color-red;
      content: " ";
      left: -8px;
      position: absolute;
      top: 7px;
      display: block;
      transform: rotate(45deg);
      width: 32px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 0.5;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        display: block;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-active {
      border-color: rgba($color-text, .5);
      &:before {
        opacity: .5;
      }
      &:after {
        display: block;
      }
    }
    &.book-ingestion-item-disabled {
      border: 2px solid #8b8c8b;
      opacity: 1 !important;
      &:after {
        opacity: .5;
      }
    }
  }


  .book-ingestion-item-repeat,
  .book-ingestion-item-start,
  .book-ingestion-item-finish,
  .book-ingestion-item-air,
  .book-ingestion-item-routed,
  .book-ingestion-item-share {
    &.book-ingestion-item-disabled:not(.book-ingestion-item-active),
    &.book-ingestion-item-readonly:not(.book-ingestion-item-active),
    &:not(.book-ingestion-item-active):not(:hover) {
      &:before,
      svg {
        display: none;
      }
    }
  }

  .book-ingestion-item-repeat {
    &:before {
      border-style: solid;
      border-width: 6px 0 6px 10px;
      border-color: transparent transparent transparent $color-red;
      height: 0;
      position: absolute;
      margin: -6px 0 0 -4px;
      width: 0;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-share {
    color: $color-red;
    &:hover:not(.book-ingestion-item-active) {
      &:before {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }
    svg {
      display: inline-block;
    }
  }
  // tx Status coloring
  .book-ingestion-item-start {
    &:before {
      background-color: $color-red;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-finish {
    &:before {
      background-color: #bfbebe;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-air {
    &:before {
      background-color: $color-green;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  // tx Off
  // viaplay routed
  .book-ingestion-item-routed {
    &:before {
      background-color: $color-yellow;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  //off

  .book-ingestion-overlay {
    bottom: 0;
    cursor: not-allowed;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    .book-loader {
      position: relative;
      z-index: 3;
    }
  }
}

</style>
