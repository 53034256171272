/**
 * Help functions related to file/download manipulation.
 */

class File {

    /**
     * Make the browser download the passed file/content, through a fake link.
     * @param string|blob file
     * @param string filename
     * @param string type The MIME type of the file/content.
     */
    static download(file, filename, type) {

        // Create a new Blob object using the response data of the
        // onload object.
        let blob = (file instanceof Blob) ? file : new Blob([file], {type: type})

        // Create a link element, hide it, direct it towards the blob,
        // and then 'click' it programatically.
        let a = document.createElement("a")
        a.style = "display: none"
        document.body.appendChild(a)

        // Create a DOMString representing the blob and point the link
        // element towards it.
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename

        // Programatically click the link to trigger the download.
        a.click()

        // Release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url)

    }

    /**
     * Extract the filename from a Content-Disposition header string.
     * @param string disposition
     * @return string
     */
    static getAttachmentFilename(disposition) {
        let filename = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename
    }

}

export default File
