<script>
import {app} from 'app'
import BookSidebar from 'components/common/BookSidebar'
import EntityMixin from 'components/entity/EntityMixin'
import ProductionMixin from 'components/production/ProductionMixin'
import Production from 'components/production/Production'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import Equipment from 'components/equipment/Equipment'
import BookAudioLayout from 'components/common/BookAudioLayout'

export default {
    mixins: [
        EntityMixin,
        ProductionMixin,
    ],
    components: {
        BookSidebar,
        BookEquipmentSelection,
        BookAudioLayout
    },
    props: {
        selectedItem: {
            default: null
        },
        actions: {
            default: () => {
                return []
            },
            type: Array
        },
        entities: {
            default: () => ({})
        },
        showEquipmentSelection: {
            default: false,
            type: Boolean
        },
        loading: {
            default: false,
            type: Boolean
        },
        sidebarType: {
            default: 'production'
        },
        allEquipment: {
          default: () => [],
          type:Array
        },
        darkMode: {
          default: false
        },
    },
    computed: {
        getSelectedItemEquipment() {
            if (!this.selectedItem) return []
            // for to show all function needs to be .isVisible(v, true)
            return Lazy(this.selectedItem.equipment)
                .filter(v => Equipment.isVisible(v, true))
                .toArray()
        },
        getActionSettings() {
            return {
                edit: {
                    label: ''
                }
            }
        },
        getHouseNumbers() {
            if (!this.selectedItem) return
            let _hn = this.selectedItem.houseNumber
            let _c = this.selectedItem.channelDetails ? this.selectedItem.channelDetails.country : app.$data.user.get.country
            if (_hn) {
                if (isJsonSring(_hn)) {
                    let orderArr = this.$store.state.system.countries ? this.$store.state.system.countries.map(i => i.code) : ['se', 'no', 'dk', 'fi', 'uk']
                    let _gamze = JSON.parse(_hn)
                    let res = Object.keys(_gamze)
                        .map(a => ({[a]: _gamze[a]}))
                        .sort((a, b) => (orderArr.indexOf(Object.keys(a)[0]) + 1) - (orderArr.indexOf(Object.keys(b)[0]) + 1));
                    let test = {}
                    Lazy(res)
                        .each((v,k) => {
                            Lazy(v).each((v,k) => {
                                test[k] = v
                            })
                        })
                    //_hn = JSON.stringify(test)
                    return test
                } else {
                    let _res = {}
                    _res[_c] = _hn
                    return _res
                }
            }
        },
        getSelectedItemCrew() {
            const output = {
                option: [],
                declined: [],
                requested: [],
                accepted: [],
                not_available: []
            }
            Lazy(this.selectedItem.crew)
                .each(v => {
                    output[v.status].push(v)
                })
            return output
        },
    },
    beforeMount() {
        Lazy(this.entities)
            .each((v, k) => {
                this[k] = v
            })
    },
    methods: {
        getMcrLabel(id) {
            let output = this.countries.find(v => v.code == id)
            return output.label
        },
        getEquipmentPeriod(equipment) {
            if (!equipment.equipmentUsage) return ''
            let start = moment(equipment.equipmentUsage.periodStart).format('HH:mm')
            let end = moment(equipment.equipmentUsage.periodEnd).format('HH:mm')
            return `${start}-${end}`
        },

        /**
         * Update the equipment list in the current item.
         * @param object|array items A single item or a list of items.
         * @param boolean notify Show an alert message about the addition.
         */
        updateEquipment(items, notify = true) {
            const production = new Production(this.selectedItem.id)
            production.set('equipment', items)
            this.selectedItem.equipment = items
            if (notify) this.$alert.set('The equipment list was updated', 'success', 3)
        },

        /**
         * Retrive the label for the passed plan item.
         * @param object planItem
         * @return string
         */
        getPlanItemLabel(planItem) {
            return planItem.typeCustom ? planItem.typeCustom : this.getEntityItemLabel('planType', planItem.type)
        },
        closeSidebar() {
            this.$emit('close');
        },
        

    }
}
</script>

<template>
<book-sidebar
    :darkMode="darkMode"
    :item="selectedItem"
    :loading="loading"
    :actions="actions"
    :sidebarType="sidebarType"
    @edit="item => openNewTab(getEditLink(selectedItem.id, selectedItem.elementTypeDetailed))"
    @close="closeSidebar()"
    @delete="item => $emit('delete', selectedItem.id)"
    @clone="item => $emit('clone', selectedItem)">
    <div slot="header" v-if="!loading && selectedItem" class="book-sidebar-header-title-inner">
        <span class="book-sidebar-header-title-text">{{selectedItem.id}}, {{selectedItem.title}}</span>
    </div>   
    <div slot="content" v-if="!loading && selectedItem">
        <span class="book-item-status book-item-bkg" :class="'book-item-status-' + selectedItem.status"></span>
        
        <div class="book-sidebar-group">
            <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">Title:</div>
                <div class="book-sidebar-value">{{selectedItem.title}}</div>
            </div>
            <div class="book-sidebar-row" v-if="selectedItem.productionLeader">
                <div class="book-sidebar-label book-width-100">Task assigned by:</div>
                <div class="book-sidebar-value"><router-link :to="{name: 'user.id', params: {id: selectedItem.productionLeader.id}}">{{selectedItem.productionLeader.fullName}}</router-link></div>
            </div>
            <!--- House Numbers -->
            <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">House number:</div>
            </div>
            <div class="book-sidebar-row" v-for="(hnum, index) in getHouseNumbers">
                <div class="book-sidebar-label book-width-100">{{index.length > 0 ? index.toUpperCase() : '-'}}:</div>
                <div class="book-sidebar-value">{{hnum}}</div>
            </div>
            <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">Activity Type:</div>
                <div class="book-sidebar-value">{{getEntityItemLabel('activityTypes', selectedItem.runType)}}</div>
            </div>
            <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">Start:</div>
                <div class="book-sidebar-value">{{selectedItem.productionStart}}</div>
            </div>
            <div class="book-sidebar-row">
                <div class="book-sidebar-label book-width-100">End:</div>
                <div class="book-sidebar-value">{{selectedItem.productionEnd}}</div>
            </div>
            
        </div>
        <div class="book-sidebar-group" v-if="selectedItem.audioLayout && selectedItem.audioLayout !== ''">
            <div class="book-sidebar-table">
                <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
                    <div>Audio</div>
                    <div class="book-width-60-percents">Value</div>
                </div>
                <book-audio-layout :value="selectedItem.audioLayout" :audioLayoutsEntity="audioLayoutsEntity" itemType="production"></book-audio-layout>
            </div>
        </div>
        
        <div class="book-sidebar-group" v-if="selectedItem.crew && selectedItem.crew.length > 0">
            <div class="book-sidebar-table">
                <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
                    <div>Crew</div>
                    <div class="book-sidebar-table-period-column">Period</div>
                </div>
                <template v-for="(crewMembers, status) in getSelectedItemCrew">
                    <div class="book-sidebar-table-row" v-for="crewMember in crewMembers">
                        <div :class="getCrewMemberClass(crewMember)" v-if="crewMember.user">
                            <span v-if="crewMember.conflict" title="This crew member is conflicting with another crew member" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
                            <span :title="crewMember.status" class="book-square"></span>
                            <span class="book-sidebar-table-row-text">{{crewMember.user.name}}</span>
                        </div>
                        <div>{{getCrewPeriod(crewMember)}}</div>
                    </div>
                </template>
            </div>
        </div>

        <!-- Workgroups-->
        <div class="book-sidebar-group" v-if="selectedItem.workgroups && selectedItem.workgroups.length > 0">
            <div class="book-sidebar-table">
                <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
                    <div>Workgroups</div>
                    <div class="book-sidebar-table-period-column">Period</div>
                </div>
                <div class="book-sidebar-table-row" v-for="workgroup in selectedItem.workgroups">
                    <div class="book-sidebar-table-row-nowrap">
                        {{getEntityItemLabel('workgroups', workgroup.workgroup)}}
                    </div>
                    <div>{{getCrewPeriod(workgroup)}}</div>
                </div>
            </div>
        </div>

        <!-- Equipment-->
        <div class="book-sidebar-group" v-if="!showEquipmentSelection && getSelectedItemEquipment.length > 0">
            <div class="book-sidebar-table">
                <div class="book-sidebar-table-header">
                    <div>Equipment</div>
                    <div class="book-sidebar-table-period-column">Period</div>
                </div>
                <div class="book-sidebar-table-row" v-for="equipment in getSelectedItemEquipment">
                    <div class="book-sidebar-table-row-nowrap">
                        <span v-if="equipment.equipmentUsage.conflict && !equipment.doNotCreateConflict" title="This equipment piece is conflicting with another booking" class="label label-danger book-sidebar-equipment-conflict">conflict</span>
                        <span v-if="equipment.equipmentUsage.exceedsGroupLimit && equipment.useGroupConflictCount" title="The equipment group limit exceeded" class="label label-exceeded book-sidebar-equipment-conflict">exceeded</span>
                        {{getEntityItemLabel('equipmentType', equipment.type)}}, {{equipment.label}}
                    </div>
                    <div>{{getEquipmentPeriod(equipment)}}</div>
                </div>
            </div>
        </div>
        <div class="book-sidebar-group" v-else-if="showEquipmentSelection">
            <div class="book-sidebar-table">
                <div class="book-sidebar-table-header" :class="{'dark-layout-header': darkMode}">
                    <div>Equipment</div>
                </div>
            </div>
            <book-equipment-selection
                :darkMode="darkMode"
                :selection="getSelectedItemEquipment"
                :dates="{from: selectedItem.productionStart, to: selectedItem.productionEnd}"
                :usageId="selectedItem.id"
                usageType="production"
                :columns="['type', 'equipment', 'actions']"
                :actionSettings="getActionSettings"
                :entityList="entities"
                :allEquipmentProps="allEquipment"
                @changedEquipment="(item, notify) => updateEquipment(item, notify)">
            </book-equipment-selection>
        </div>
        

    </div>
</book-sidebar>
</template>

<style lang="scss">
@import "../../../style/_variables.scss";
    .label-exceeded {
        background-color: $color-list-item-conflict-warning;
    }
    .book-width-60-percents {
        width: 60%;
    }
</style>