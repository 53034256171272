import store from 'store'
import Api from 'components/app/Api'

class Entity extends Api {

    /**
     * Constructor.
     * @param integer id The id to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/entity',
            module: 'entity',
        })
    }

    /**
     * Get an entity item by its id.
     * @param integer id The id of the entity item.
     * @return object
     */
    getItem(id) {
        return this.get && this.get.items ? this.get.items.find(v => v.id === id) : null
    }

    /**
     * Store an entity item inside the current entity.
     * @param object item The item to store.
     */
    setItem(item, multiple_default = false) {
        const items = this.get.items

        // Ensure that only one item per country is set as default.
        if (item.default) {
            items.filter(v => {
                return (v.id != item.id) && (v.default) && (v.country == item.country)
            }).forEach((v, k, items) => {
                //console.log(multiple_default)
                if(multiple_default === false) //If the entity has multiple default
                    items[k].default = false
            })
        }

        // Add new item or update a current one.
        const i = items.findIndex(v => v.id == item.id)
        if (i > -1) {
            items[i] = item
        }
        else {
            items.push(item)
        }

        store.commit('setField', {key: this.options.module, field: 'items', data: items})
    }

    /**
     * Delete an item from the storage, but do not call the API.
     * This is useful to use when an entity item has been removed in the API,
     * for ex after EntityItem.delete() has been used.
     * @param integer id
     */
    deleteItem(id) {
        const context = this
        let i = context.get.items.findIndex(v => v.id === id)
        if (i > -1) {
            context.get.items.splice(i, 1)
        }
    }

}

export default Entity
