<script>
import BookTable from 'components/common/BookTable'
import Datetime from 'components/common/helpers/Datetime'

export default {
    components: {
        BookTable
    },
    props: {
        items: {
            default: () => {
                return []
            },
            type: Array
        },
        checkedProductions: {
            default: () => {
                return []
            },
            type: Array
        },
        showOnlyChecked: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            checkedItems: [],
            checkedAll: false
        }
    },
    computed: {
        columns() {
            let columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false,
                    class: ''
                },
                {
                    value: 'week',
                    label: 'Week',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-week'
                },
                {
                    value: 'day',
                    label: 'Day',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-day'
                },
                {
                    value: 'date',
                    label: 'Date',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-date'
                },
                {
                    value: 'time',
                    label: 'Time',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-time'
                },
                {
                    value: 'runType',
                    label: 'Run Type',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-runtype'
                },
                {
                    value: 'channel',
                    label: 'Channel',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-channel'
                },
                {
                    value: 'sport',
                    label: 'Sport',
                    visible: false,
                    class: ''
                },
                {
                    value: 'productionType',
                    label: 'productionType',
                    visible: false,
                    class: ''
                },
                {
                    value: 'league',
                    label: 'League',
                    visible: false,
                    class: ''
                },
                {
                    value: 'title',
                    label: 'Title',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: ''
                },
                {
                    value: 'country',
                    label: 'Country',
                    visible: false,
                    class: ''
                }
            ]

            return columns
        },
        subColumns() {
            let subColumns = [
                // {
                //     value: 'id',
                //     label: 'ID',
                //     visible: false,
                //     class: ''
                // },
                {
                    value: 'start',
                    label: 'Start',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-time'
                },
                {
                    value: 'end',
                    label: 'End',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    class: 'column-time'
                },
                {
                    value: 'skillLabel',
                    label: 'Skill',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    //class: 'column-day'
                },
                {
                    value: 'username',
                    label: 'User',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    //class: 'column-day'
                },
                {
                    value: 'locationLabel',
                    label: 'Location',
                    sortable: true,
                    searchable: false,
                    visible: true,
                    //class: 'column-day'
                },
            ]
            return subColumns
        },
        hasElements() {
            return this.items && this.items.length > 0
        },
        totalItems() {
            let count = 0

            if(this.items && this.items.length > 0 && this.items[0].crew && this.items[0].crew.length > 0) {
                Lazy(this.items)
                .each(v => {
                    count += v.crew.length
                })
            }
            else {
                count = this.items && this.items.length > 0 ? this.items.length : 0
            }

            return count
        }
    },
    watch: {
        visibleUsersCount: function(newVlaue) {
            if(this.checkedItems.length !== newVlaue && this.checkedAll) this.checkedAll = false
            if(this.checkedItems.length == newVlaue && !this.checkedAll) this.checkedAll = true
        }
    },
    methods: {
        isColumnVisible(name) {
            let column = this.columns.find(i => i.value == name)
            return column !== undefined ? column.visible : false
        },
        getColumnClass(name) {
            let column = this.columns.find(i => i.value == name)
            return column !== undefined ? column.class : ''
        },
        getAssignment(crew) {
            let res = {
                user: crew.user,
                date: moment(crew.assignmentStart).format("YYYY-MM-DD"),
                item: crew
            }

            res.user.userCountry = crew.user.country

            let row = _.find(this.items, i => i.id.value === crew.production)

            if(row !== undefined) {
                res.item.productionType = row.productionType.value
                res.item.country = row.country.value
                res.item.league = row.league.rawValue
            }

            return res
        },
        allItemsChecked() {
            this.checkedItems = []
            let checkedprods = this.checkedProductions.slice()
            if(!this.checkedAll) {
                Lazy(this.items)
                .each(v => {
                    
                    if(v.rowOptions.visible) {
                        Lazy(v.crew)
                        .each(v2 => {
                            let indx = checkedprods.findIndex(i => i.assignment === v2.id)
                            this.checkedItems.push(v2.id)
                            if(indx === -1) checkedprods.push({assignment: v2.id, production: v2.production})
                            v2.checked = true
                        })
                        
                    }
                    else {
                        Lazy(v.crew)
                        .each(v2 => {
                            v2.checked = false
                        })
                        
                    }
                    
                })
            }
            else {
                Lazy(this.items)
                .each(v => {
                    Lazy(v.crew)
                    .each(v2 => {
                        let ind = checkedprods.findIndex(i => i === v2.id)
                        if(ind > -1) checkedprods.splice(ind, 1)
                        v2.checked = false
                    })
                    
                })
            }
            this.$emit('updateChosenProductions', JSON.stringify(checkedprods))
        },
        itemChecked(crew) {
            //let currentProduction = this.items.find(i => i.id.value == productionId)
            crew.checked = !crew.checked
            let indx = this.checkedItems.findIndex(i => i === crew.id)
            let checkedprods = this.checkedProductions.slice()
            let ind = checkedprods.findIndex(i => i.assignment === crew.id)
            if(!crew.checked) {
                if(this.checkedAll) this.checkedAll = false
                
                if(indx > -1) {
                    this.checkedItems.splice(indx, 1)
                }
                if(ind > -1) {
                    checkedprods.splice(ind, 1)
                }

                //row.rowOptions.checked = false
            }
            else {
                if(indx === -1) this.checkedItems.push(crew.id)
                if(ind === -1) checkedprods.push({assignment: crew.id, production: crew.production})

                //row.rowOptions.checked = true
            }

            this.$emit('updateChosenProductions', JSON.stringify(checkedprods))
        },
        isRowVisible(row) {
            return row.crew && row.crew.length > 0 ? _.some(row.crew, ['checked', true]) : row.rowOptions.visible
        },
        openProduction(row) {
            const link = {
                name: `${row.productionType.value}.edit`,
                params: {
                    id: row.id.value,
                },

                query: {
                    destination: this.$route.name,
                    //view: this.$route.params.view,
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    //newBar: this.feedbackItemId
                }
            }

            let routeData = this.$router.resolve(link);
            window.open(routeData.href, '_blank');
        }
    },
}
</script>
<template>
    <div id="crewproblems">
        <div class="book-crewproblems">
            <book-affix relative-element-selector=".book-crewproblems" :offset="{ top: 115, bottom: 30 }" :listenScrollOn="'#prods'" class="book-crewproductions-day-navigation-split" v-if="hasElements">
                <div class="book-crewproductions-label" style="padding: 2px 4px;">
                    <input type="checkbox" v-model="checkedAll" @click="allItemsChecked()" style="margin: 0px;">
                    <span>Select All</span>
                    <span class="book-crewview-date" style="padding-left: 17px;">({{checkedProductions.length}} of {{totalItems}})</span>
                </div>
                <div class="book-crewproductions-row-content">
                    <div class="book-crewproductions-column" v-for="column in columns" :class="column.class" v-if="column.visible">
                        <div class="book-crewproductions-day">
                            <span class="book-crewproductions-date">{{column.label}}</span>
                        </div>
                    </div>
                </div>
            </book-affix>
            <div class="book-crewproductions-content-split" v-if="hasElements">
                <div class="book-crewproductions-row" v-for="(row, $index) in items" :class="($index % 2 == 0) ? 'book-crewproductions-odd' : 'book-crewproductions-even'" v-show="(showOnlyChecked && isRowVisible(row)) || (!showOnlyChecked && row.rowOptions.visible)" >
                    <div class="book-crewproductions-label" :title="row.id.value">
                        <!-- <input type="checkbox" @click="itemChecked(row)" v-model="row.rowOptions.checked" :key="row.id.value"> -->
                    </div>
                    <div class="book-crewproductions-row-content" style="flex-wrap: wrap;">
                        <div class="book-crewproductions-row" style="flex-basis: 100%" @click="openProduction(row)">
                            <div class="book-crewproductions-column" v-for="(v, k) in row" v-if="isColumnVisible(k)" :key="row.id.value + '-' + k" :class="getColumnClass(k)">
                                <div class="book-crewproductions-item book-item-bkg">
                                    <span class="book-crewproductions-item-time">{{v.value}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="book-crewproductions-row" v-for="(crew, k2) in row.crew" :key="crew.id + '_' + k2" style="flex-basis: 100%" v-if="!showOnlyChecked || (showOnlyChecked && crew.checked)">
                            <div class="book-crewproductions-column column-week"></div>
                            <div class="book-crewproductions-column column-week book-crewproductions-child-left" style="display: inline-block;">
                                <input type="checkbox" @click="itemChecked(crew)" v-model="crew.checked" :key="crew.id" style="margin-left: 25px; margin-top: 10px;">
                            </div>
                            <div class="book-crewproductions-column book-crewproductions-child-middle" v-for="(col, k) in subColumns" :key="crew.id + '-' + col.value" :class="col.class ? col.class : ''">
                                <div class="book-crewproductions-item book-item-bkg">
                                    <span class="book-crewproductions-item-time" v-html="crew[col.value]">{{}}</span>
                                </div>
                            </div>
                            <div class="book-crewproductions-column book-crewproductions-child-right" style="padding-top: 7px;">
                                <button
                                    @click="$emit('notifyUser', {crew: crew, production: row.title.value})"
                                    class="btn btn-xs btn-info"
                                >
                                    <font-awesome-icon icon="envelope"/>
                                    <span></span>
                                </button>
                                 <button
                                    @click="$emit('openCalendar', crew.userId)"
                                    class="btn btn-xs btn-warning"
                                >
                                    <font-awesome-icon icon="calendar"/>
                                    <span></span>
                                </button>
                                <button
                                    @click="$emit('openAssignment', getAssignment(crew))"
                                    class="btn btn-xs btn-primary"
                                >
                                    <font-awesome-icon icon="pencil-alt"/>
                                    <span>Open</span>
                                </button>
                                <button
                                    @click="$emit('deleteAssignment', crew)"
                                    class="btn btn-xs btn-danger"
                                >
                                    <font-awesome-icon icon="trash"/>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div >
                        <div class="book-crewproductions-label"></div>
                        <div class="book-crewproductions-label">
                            <input type="checkbox" @click="itemChecked(crew)" v-model="crew.checked" :key="crew.id">
                        </div>
                        <div class="book-crewproductions-row-content">
                            <div class="book-crewproductions-column" v-for="(col, k) in subColumns" :key="crew.id + '-' + col.value" :class="col.class ? col.class : ''">
                                <div class="book-crewproductions-item book-item-bkg">
                                    <span class="book-crewproductions-item-time">{{crew[col.value]}}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="book-productions-no-results" v-if="!hasElements">
                <span>Choose a status first to get result</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewproblems {
    .panel > .book-crewproblems {
        margin-bottom: -1px;
    }
    .book-crewproblems {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: hidden;
        .book-crewproductions-day-navigation,
        .book-crewproductions-content {
            min-width: 800px;
            width: 100%;
        }
        .book-crewproductions-day-navigation-split {
            // min-width: 800px;
            //width: calc(100% - 130px);
            display: flex;
            height: 85px;
            //top: 0 !important;
        }
        .book-crewproductions-content-split {
            //width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split > .book-crewproductions-row-content {
            width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                //left: 15px;
                width: calc(100% / 12 * 7 - 48px);
                z-index: 1000;
                //top: 0;
            }
            .book-crewproductions-label {
                display: inline-block;
            }
            .book-crewproductions-label,
            .book-crewproductions-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-crewproductions-label > span {
                font-weight: bold;
                position: relative;
                text-align: left;
            }
            .book-crewproductions-column {
                padding: 2px 4px;
                // &.book-crewproductions-with-week {
                //     .book-crewproductions-day {
                //         padding-left: 25px;
                //     }
                // }
                &.book-crewproductions-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-crewproductions-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-crewproductions-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-crewproductions-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-crewproductions-week
            .book-crewproductions-weekday,
            .book-crewproductions-date {
                display: block;
            }
            .book-crewproductions-weekday {

            }
        }
        .affix + .book-crewproductions-content {
            margin-top: 56px;
        }
        .book-crewproductions-content {
            .book-crewproductions-column {
                padding: 2px 0;
            }
        }
        .book-crewproductions-day-navigation,
        .book-crewproductions-row {
            display: flex;
        }
        .book-crewproductions-row {
            min-height: 38px;
            &:last-child {
                .book-crewproductions-label,
                .book-crewproductions-column:not(.book-crewproductions-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
                .book-crewproductions-column {
                    &.book-crewproductions-today {
                        border-bottom-width: 2px;
                    }
                }
            }
            &.book-crewproductions-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-crewproductions-label {
            display: flex;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 95px;
            min-width: 95px;
            overflow: hidden;
            padding: 2px 4px 2px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 95px;
                min-width: 95px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-crewproductions-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-crewproductions-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-crewproductions-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                //margin-left: -1px;
                z-index: 2;
                // .book-crewproductions-week {
                //     bottom: -2px;
                // }
            }
        }
        .book-crewproductions-column-info-tools {
            display: inline-block;
            margin-right: 5px;
        }
        .book-crewproductions-label,
        .book-crewproductions-item {
            // font-size: 12px;
            // line-height: 1.3em;
        }
        .book-crewproductions-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-crewproductions-item-top-row {
            display: flex;
            font-size: 10px;
        }
        .book-crewproductions-item-flag,
        .book-crewproductions-item-league,
        .book-crewproductions-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-crewproductions-item-time,
        .book-crewproductions-item-league,
        .book-crewproductions-item-flag {
            display: inline-block;
        }
        .book-crewproductions-item-title {
            display: block;
        }
        .book-crewproductions-item-time {
            flex: 1 0 auto;
        }
        .book-crewproductions-item-flag {
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-item-league {
            flex: 30 1 auto;
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
        .book-crewproductions-item-plus {
            height: 20px;
            font-weight: bold;
            //padding: 5px 7px;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px;
            text-align: center;
        }
        .btn-plus {
            // position: absolute;
            // bottom: 1px;
            padding: 0px;
            width: 100%;
        }

        .column-week {
            max-width: 60px;
        }
        .column-day {
            max-width: 85px;
        }
        .column-date {
            max-width: 100px;
        }
        .column-time {
            max-width: 110px;
        }
        .column-runtype {
            max-width: 200px;
        }
        .column-channel {
            max-width: 150px;
        }

        .book-productions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }

        .book-crewproductions-child-left {
            border-left: 1.5px solid  darken($color-grey-light, 3%);
            border-top: 1.5px solid  darken($color-grey-light, 3%);
            border-bottom: 1.5px solid  darken($color-grey-light, 3%);
            border-right: none;
        }

        .book-crewproductions-child-middle {
            border-top: 1.5px solid  darken($color-grey-light, 3%);
            border-bottom: 1.5px solid  darken($color-grey-light, 3%);
            border-left: none;
            border-right: none;
        }
         .book-crewproductions-child-right {
            border-top: 1.5px solid  darken($color-grey-light, 3%);
            border-bottom: 1.5px solid  darken($color-grey-light, 3%);
            border-right: 1.5px solid  darken($color-grey-light, 3%);
            border-left: none;
        }
    }
}
</style>