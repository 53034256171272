<script>
import Contract from 'components/contract/Contract'
import Entity from 'components/entity/Entity'
import EntityIetm from 'components/entity/EntityItem'
import System from 'components/system/System'
import store from 'store'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookLoader
    },

    mounted() {
        const context = this

        // Load some entities.
        const leagueEntityId = context.setting.league_entity_id
        context.leagueEntity = new Entity(leagueEntityId)
        const costCentersEntityId = context.setting.costcenters_entity_id
        context.costCentersEntity = new Entity(costCentersEntityId)
        const dataToLoad = [
            context.leagueEntity.load(leagueEntityId),
            context.costCentersEntity.load(costCentersEntityId),
        ]
        Promise.all(dataToLoad)
        .then(([league, costCenters]) => {
            if (league.data.fields.settings.reference) {
                const sportEntityId = league.data.fields.settings.reference.id
                context.sportEntity = new Entity(sportEntityId)
                context.sportEntity.load(sportEntityId)
                .catch(error => {
                    context.$error.set(error, 'It was not possible to load the entity.')
                })
            }
            const contract = new Contract()
            contract.all()
        })
        .catch(error => {
            context.$error.set(error, 'It was not possible to load the league entity.')
        })
    },
    data() {
        return {
            leagueEntity: null,
            sportEntity: null,
            costCentersEntity: null
        }
    },
    computed: {
        contracts() {
            return store.state.data.contract
        },
        // sportEntity() {
        //     if (!this.leagueEntity) {
        //         return null
        //     }
        //     const sportEntity = new Entity(this.leagueEntity.get.fields.settings.reference.id)
        //     return sportEntity.get ? sportEntity : null
        // },
        // leagueEntity() {
        //     if (!store.state.entity.data) {
        //         return null
        //     }
        //     const leagueEntity = new Entity(this.setting.league_entity_id)
        //     return leagueEntity.get ? leagueEntity : null
        // },
        currencies() {
            return this.$settings.get.currencies
        },
        setting() {
            return this.$settings.get.contracts
        },
        system() {
            return System
        },
        entity() {
            return Entity
        }
    },

    methods: {

        /**
         * Get the value to present for a league field.
         * @param integer id The id of the entity item representing the league.
         * @return string
         */
        getLeagueField(id) {
            const context = this
            if (!context.leagueEntity.get) {
                return null
            }
            const leagueItem = context.leagueEntity.getItem(id)
            if (leagueItem) {
                const sportItem = context.sportEntity.getItem(leagueItem.reference)
                if (sportItem) {
                    return sportItem.label + ' / ' + leagueItem.label
                }
            }
            return null
        },

        /**
         * Delete an entity item.
         */
        deleteContract(id) {
            const context = this
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete contract ' + id + '?',
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                Contract.delete(id)
                .then(response => {
                    swal(
                      'Deleted!',
                      response.data,
                      'success'
                    )
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to delete the contract.')
                })
            })
        },

        getCostCenterById(id) {
            const context = this
            if (!context.costCentersEntity) {
                return null
            }
            const item = context.costCentersEntity.getItem(id)
            return item ? item.description : null
        }

    }
}

</script>

<template>
<div id="contracts" class="admin book-top-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
        <h2 class="page-title">Contracts</h2>
        <div class="secondary-menu btn-group" role="group">
            <router-link :to="{name: 'contract.create', query: {destination: 'equipment'}}" class="btn btn-success"><font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Create</span></router-link>
        </div>
    </div>
    <div class="panel panel-info">
        <book-loader v-if="!contracts"></book-loader>
        <div class="table-responsive" v-if="contracts">
            <table class="table table-striped table-hover">
                  <thead>
                      <tr>
                          <th>ID</th>
                          <th>Name (nr)</th>
                          <th>Sport / League</th>
                          <th>Cost Centres</th>
                          <th>Updated</th>
                          <th>Created</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="contract in contracts">
                          <td>{{contract.id}}</td>
                          <td>{{contract.name}} ({{contract.number}})</td>
                          <td>{{getLeagueField(contract.league)}}</td>
                          <td>
                              <span v-for="(costCenter, index) in contract.costCenters">
                                  {{getCostCenterById(costCenter)}}<span v-if="index+1 < contract.costCenters.length">, </span>
                              </span>
                          </td>
                          <td>{{contract.updated}}</td>
                          <td>{{contract.created}}</td>
                          <td class="action">
                              <router-link :to="{name: 'contract.edit', params: {id: contract.id}, query: {destination: 'contract'}}" class="btn btn-primary btn-xs"><font-awesome-icon icon="pencil-alt"/></router-link>
                              <a href="#" @click="deleteContract(contract.id)" class="btn btn-danger btn-xs"><font-awesome-icon icon="trash"/></a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
    </div>
</div>
</template>
