<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookLoader
    },
    mounted() {
        const entity = new Entity()
        entity.all()
        .then(response => {
            this.loaded = true
        })
        .catch(error => {
            context.$error.set(error, 'It was not possible to load the entities')
        })
    },
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        entities() {
            return this.$store.state.data.entity
        },
    }
}
</script>

<template>
<div id="entities" class="admin book-top-sticky-wrapper col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3 container">
    <div class="page-header book-top-sticky clearfix">
        <h1 class="page-title">Entities</h1>
    </div>
    <book-loader v-if="!loaded"></book-loader>
    <div class="panel panel-info" v-else>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Label</th>
                        <th>Updated</th>
                        <th>Mltpl. Defaults</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="entity in entities" v-bind:key="entity.id" class="cell-vertical-align-middle">
                        <td>{{entity.id}}</td>
                        <router-link tag="td" :to="{name: 'entity.id', params: {id: entity.id}}"><a>{{entity.label}}</a></router-link>
                        <td>{{entity.updated}}</td>
                        <td>{{entity.has_multiple_defaults}}</td>
                        <td class="action">
                            <router-link :to="{name: 'entity.id', params: {id: entity.id}}" class="btn btn-primary btn-xs"><font-awesome-icon icon="pencil-alt"/></router-link>
                            <!-- <a href="#" v-on:click="deleteUser(user.id)" v-if="isErasable(user.id)" class="btn btn-danger btn-sm"><span class="fa fa-trash"></span></a> -->
                        </td>
                    </tr>
                </tbody>
              </table>
          </div>
    </div>
</div>
</template>
