const state = {
  countries: {},
  settings: {},
  locations: {},
}
const mutations = {
    setCountries(state, data) {
        state.countries = data
    },
    setLocations(state, data) {
        state.locations = data
    },
}
const system = {
  namespaced: true,
  state,
  mutations
}
export {system}
