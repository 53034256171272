<script>
import {app} from 'app'
import Acl from 'components/user/Acl'
import User from 'components/user/User'
import Entity from 'components/entity/Entity'
import router from 'router'
import store from 'store'
import BookUsersActionMenu from 'components/user/BookUsersActionMenu';
import BookUserActionMenu from 'components/user/BookUserActionMenu';
import System from 'components/system/System'
import BookLoader from 'components/common/loader/BookLoader'
import BookPhone from 'components/common/BookPhone'
import BookToggle from 'components/common/BookToggle'
import BookAutocomplete from 'components/common/BookAutocomplete'
import Auth from 'components/auth/Auth'

// Vendors.
import multiselect from 'vue-multiselect'
import maskedInput from 'vue-masked-input'

var finishCount=0                   //SMTGBD-594

export default {
  components: {
    BookUsersActionMenu,
    BookUserActionMenu,
    BookLoader,
    BookPhone,
    BookToggle,
    BookAutocomplete,
    multiselect,
    maskedInput
  },
  props: {
    id: {
      type: Number,
    }
  },
  created() {
    this.formData = this.getDefaultData()
  },
  beforeMount() {
    const context = this
    if (!this.isUserEditForm) {
      this.setFormValues()
      this.user = {}
      context.setLoaded()
    }
    else if (this.isCurrentUser) {
      this.user = app.$data.user
      this.setFormValues()
      context.setLoaded()
    }
    else {
      User.load(this.id)
          .then(response => {
            context.user = new User(response.data)
            context.setFormValues()
            context.setLoaded()
            context.setCountry()
            context.setSkills()
            context.setWorkgroups()
          })
          .catch(error => {
            context.$error.set(error, 'It was not possible to load the user.')
            context.setLoaded()
          })
    }

    // Load the available roles from backend.
    Acl.getRoles()
        .catch(error => {
          context.$error.set(error)
        })

    // Load the skills.
    const entity = new Entity()
    entity.all({ ids: [context.setting.skill_entity_id, context.setting.workgroups_entity_id].join(',')})
        .then(response => {
          Vue.nextTick(() => {
            context.setCountry()
            context.setSkills() //SMTGBD-594 -> needs to set it here or above WN.
            context.setWorkgroups()
          })
        })
        .catch(error => {
          context.$error.set(error, 'It was not possible to load the skills and workgroups.')
        })
  },
  mounted() {
    console.log(this.isFormValid, this.isFormClean, 'valid, clean')
  },
  watch: {
    'formData.userData.phone.value': function(newValue) {
      if (!this.loaded) return
      this.$validator.validate('phone', newValue)
          .then(validated => {
            if (validated) this.formFields.phone.changed = true
          })
    },
    'formData.userData.phonePrivate.value': function(newValue) {
      if (!this.loaded) return
      this.$validator.validate('phone', newValue)
          .then(validated => {
            if (validated) this.formFields.phonePrivate.changed = true
          })
    },
    'formData.userData.approval.value': function(newValue) {
      if (!this.loaded) return
      this.$validator.validate('approval', newValue)
          .then(validated => {
            this.formFields.approval.changed = !this.formFields.approval.changed;
          })
    },
  },
  data() {
    return {
      loaded: false,
      user: null,
      formData: null
    }
  },
  computed: {
    availableRoles() {
      return this.$store.state.user.roles
    },
    currentUser() {
      return app.$data.user
    },
    isCurrentUser() {
      return this.id === this.currentUser.id
    },
    isUserEditForm() {
      return this.$route.name === 'user.id.edit'
    },
    countries() {
      return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
    },
    auth() {
      return Auth
    },
    setting() {
      return this.$settings.get.profiles
    },
    skillEntity() {
      const skillEntity = new Entity(this.setting.skill_entity_id)
      return skillEntity.get ? skillEntity : null
    },

    skillEntityItems() {
      const context = this
      if (!context.skillEntity.get.items) return []
      return context.skillEntity.get.items.filter(item => {
        if (!context.formData.userData.country.value) return true
        return !item.country || (item.country === context.formData.userData.country.value) || (item.country === context.formData.userData.country.value.code)
      })
    },
    workgroupsEntity() {
      const workgroupsEntity = new Entity(this.setting.workgroups_entity_id)
      return workgroupsEntity.get ? workgroupsEntity : null
    },

    workgroupsEntityItems() {
      const context = this
      if (!context.workgroupsEntity.get.items) return []
      return context.workgroupsEntity.get.items.filter(item => {
        if (!context.formData.userData.country.value) return true
        let allLocations = item.description.split(',')
        return !allLocations || allLocations.length === 0 || _.includes(allLocations, context.formData.userData.country.value)//!item.country || (item.country == this.formData.userData.country.value)
      })
    },

    profileIsLoaded() {
      return this.user && this.skillEntity && this.workgroupsEntity
    },
    getUserRoles() {
      return this.user.get && this.user.get.roles ? Lazy(Object.keys(this.user.get.roles)).map(v => parseInt(v)).toArray() : []
    },
    getUserWorkgroups() {
      return this.user.get && this.user.get.workgroups ? this.user.get.workgroups : []
    },
    getUserActive(){
      return this.user.get && this.user.get.active ? this.user.get.active : null;
    },
    getUserApproval(){
      return this.user.get && this.user.get.approval ? this.user.get.approval : null;
    },
    hasSubscribeAbility(){
      return this.isCurrentUser && this.isUserEditForm &&
          (this.$ability.can('get_daily_feed_feedbacks', 'Feedbacks & Reports') ||
              this.$ability.can('get_daily_production_feedbacks', 'Feedbacks & Reports') ||
              this.$ability.can('get_daily_service_tickets', 'Feedbacks & Reports'))
    },
    isFormClean() {

      // Check for changes in roles.
      if (this.user) {
        let rolesChanged = !Lazy(this.getUserRoles).equals(Lazy(this.formData.roles))
        if (rolesChanged) return false
      }

      if (this.user) {
        let workgroupsChanged = false
        let formWorkgroups = this.formData.userData.workgroups.value.length > 0 ? this.formData.userData.workgroups.value.map(i => i.id) : []
        let currentWorkgroups = this.getUserWorkgroups

        if(formWorkgroups.length !== currentWorkgroups.length) {
          workgroupsChanged = true
        }
        else if(formWorkgroups.length > 0 && (_.xor(formWorkgroups, currentWorkgroups)).length > 0){
          workgroupsChanged = true
        }

        if (workgroupsChanged) return false
      }

      if (this.user){
        let activeChanged = !(this.getUserActive === this.formData.userData.active.value);
        if (activeChanged) return false;
      }

      if (this.user) {
        let needRateApprovalChanged = !(this.user.get.needRatesApproval === this.formData.userData.needRatesApproval.value)
        if (needRateApprovalChanged) return false;

        let ratesApprovalChanged = !(this.user.get.ratesApproval === this.formData.userData.ratesApproval.value)
        if (ratesApprovalChanged) return false;
      }

      return this.formFields && Object.keys(this.formFields).every(k => {
        return k !== "role" && k !== "workgroups" && k !== "active" ? !this.formFields[k].changed : true;
      })
    },
    isFormValid() {
      // Some special logic for required fields.
      switch (true) {
          // At least 1 role should be selected.
        case (this.formData.roles.length < 1):
          // At least 1 phone number should be filled. WN
        case (!this.isCurrentUser && !this.isUserEditForm && (!this.formData.userData.phone.value || this.formData.userData.phone.value === '') && (!this.formData.userData.phonePrivate.value || this.formData.userData.phonePrivate.value === '')):
          // skills and country
        case (this.formData.userData.skills.value.length < 1):
        case (!this.isCurrentUser && !this.isUserEditForm && (this.formData.userData.country.value === '' || !this.formData.userData.country.value)):
        case (!this.isCurrentUser && !this.isUserEditForm && (this.formData.userData.password.value && !this.formData.password.repeat.value)):
        case (!this.isCurrentUser && !this.isUserEditForm && (this.formData.userData.password.value !== this.formData.password.repeat.value)):
        case (!this.isCurrentUser && !this.isUserEditForm && (!this.formData.userData.password.value || this.formData.userData.password.value === '')):
          return false
      }

      return this.formFields && Object.keys(this.formFields)
          .filter(k => {

            // Since "Skills" field is disabled for users that aren't
            // allowed to create/update other users, we need to exclude
            // it from the VeeValidate check, since it remains invalid
            // (for some reason) when the field is disabled.
            if (this.$ability.cannot('update', 'Users') && ((k === 'skills') || (k === 'workgroups'))) return false

          })
          .every(k => {
            return this.formFields[k].valid
          })

    },
    requiredFields() {
      let fields = ['user_email', 'name', 'country', 'skills', 'roles']
      if (this.isCurrentUser) {
        fields = fields.concat(['firstname', 'lastname', 'phone'])
      }
      return fields
    },
  },
  methods: {
    getLocations(){
      //console.log('user')
      //console.log(this.user)
    },
    setLoaded(loaded = true) {
      Vue.nextTick(() => {
        this.loaded = loaded
      })
    },
    roleIsDisabled(roleId) {

      // Prevent admnistrators from removing their own admin role.
      if (this.isCurrentUser && (roleId == this.$store.state.config.ROLE_ADMIN_NAME)) {
        return true
      }

      // Non admins shouldn't be able to change the roles.
      else if (this.$ability.cannot('update', 'Users')) {
        return true
      }

      return false
    },

    submit() {
      let parent = this
      parent.$loader.add('userEditSubmit')
      if (this.isUserEditForm) {
        let user = this.user
        let userData = this.formData.userData
        let roles = this.formData.roles
        let password = this.formData.password

        // Loop through the form data and set it into the user model.
        Lazy(userData)
            .each(function(v, k) {
              let value = v.value
              if (k === 'country') {
                if (v.value.code) {
                  value = v.value.code
                }
              }
              if (k == 'skills' || k == 'workgroups') {
                value = []
                v.value.forEach(function(skill) {
                  value.push(skill.id)
                })
              }
              user.set(k, value)
            })

        // Set selected roles.
        user.set('roles', roles)

        // The password is going to be changed. Set the value for
        // "current password" on the user object.
        if (userData.password.value != '') {
          user.set('password_current', password.current.value)
        }

        // Save the user into the backend.
        user.save()
            .then(response => {
              this.$alert.set('User ' + user.id + ' was updated!', 'success', 3)
              parent.resetForm()
              if (parent.$route.query.destination) parent.$root.$emit('formSaved')
              else parent.$router.push({name: 'user.id', params: {id: response.data.id}})
              parent.$loader.remove('userEditSubmit')
            })
            .catch(error => {
              parent.$error.set(error, 'You aren\'t authorized to perform this action!')
              parent.$loader.remove('userEditSubmit')
            })
      }
      else {
        let userData = {}
        Lazy(this.formData.userData)
            .each(function(v, k) {
              let value = v.value
              if (k === 'country') {
                if (v.value.code) {
                  value = v.value.code
                }
              }
              if (k == 'skills' || k == 'workgroups') {
                value = []
                v.value.forEach(function(skill) {
                  value.push(skill.id)
                })
              }
              userData[k] = value
            })

        userData.roles = this.formData.roles

        let user = new User(userData)

        // Set additional values to create the user.
        user.set('id', 0)

        // Save the user into the backend.
        user.store()
            .then(response => {
              this.$alert.set('The user was created!', 'success', 3)
              parent.resetForm()
              if (parent.$route.query.destination) {
                parent.$root.$emit('formSaved')
              }
              else {
                parent.notifyUser(response.data)
                parent.$router.push({name: 'admin.users'})
              }
              parent.$loader.remove('userEditSubmit')
            })
            .catch(error => {
              parent.$error.set(error, 'It was not possible to create the user.')
              parent.$loader.remove('userEditSubmit')
            })

      }
    },

    /**
     * Set the form values, when editing a current user.
     */
    setFormValues() {
      if (this.isUserEditForm) {
        Lazy(this.formData.userData)
            .filter((v, k) => {
              // Exclude some fields.
              return ['skills', 'workgroups', 'country'].indexOf(k) == -1
            })
            .each((v, k) => {
              v.value = this.user.get[k]
            })
        if (this.user.get.roles) {
          let roles = []
          Lazy(this.user.get.roles)
              .each((role, roleId) => {
                roles.push(parseInt(roleId))
              })
          Vue.set(this.formData, 'roles', roles)
        }
      }
    },

    setCountry() {
      finishCount++
      if (this.user && this.isUserEditForm) {
        finishCount=0
        let context = this
        let user = this.user
        if (user.get.country && context.formData.userData.country.value.length <= 0) {
          Lazy(this.countries).each(v => {
            if (v.code === user.get.country) {
              context.formData.userData.country.value = v
            }
          })
        }
      }
    },
    /**
     * Set the skill items on the form.
     */
    setSkills() {
      finishCount++           //SMTGBD-594
      //console.log(this.user, 'test')

      //         if (finishCount==2 && this.user && this.isUserEditForm) {       //SMTGBD-594
      if (this.user && this.isUserEditForm) {
        finishCount=0                                           //SMTGBD-594
        let context = this
        let user = this.user

        if (user.get.skills && context.formData.userData.skills.value.length <= 0) {
          //console.log(user.get.skills, 'setSkills');
          user.get.skills.forEach(function(v,k) {
            if (context.skillEntity.get.items) {
              context.skillEntity.get.items.forEach(function (s) {
                if (s.id === v) {
                  context.formData.userData.skills.value.push(s)
                }
              })
            }
          })
        }
      }
    },

    setWorkgroups() {
      finishCount++
      if (this.user && this.isUserEditForm) {
        finishCount=0
        let context = this
        let user = this.user

        if (user.get.workgroups && context.formData.userData.workgroups.value.length <= 0) {
          //console.log(user.get.skills, 'setSkills');
          user.get.workgroups.forEach(function(v,k) {
            if (context.workgroupsEntity.get.items) {
              context.workgroupsEntity.get.items.forEach(function (s) {
                if (s.id === v) {
                  context.formData.userData.workgroups.value.push(s)
                }
              })
            }
          })
        }
      }
    },

    /**
     * Reset the form to its default values.
     */
    resetForm() {
      let newData = this.getDefaultData()
      newData.user = this.$data.user
      this.formData = newData
      this.setFormValues()

      finishCount=1          //SMTGBD-594
      this.setCountry()
      this.setSkills()
      this.setWorkgroups()
    },

    /**
     * Get the label to be used on the channel field.
     */
    getSkillLabel(item) {
      return item.country ? item.label + ' ('+item.country.toUpperCase()+')' : item.label
    },

    getWorkgroupsLabel(item) {
      return item.label ? item.label : ''
    },

    /**
     * Remove the spaces from the value of an input field.
     * @param string The name of the field to parse.
     */
    removeSpaces(field) {
      this.formData.userData[field].value = this.formData.userData[field].value.replace(' ', '')
    },

    /**
     * Get the default data to be used in the form fields, determining which
     * fields that should be required based on the current context.
     *
     */
    getDefaultData() {
      const defaultData = this.defaultData()
      const requiredFields = this.requiredFields
      Lazy(requiredFields)
          .filter(field => defaultData.userData.hasOwnProperty(field))
          .each(field => {
            defaultData.userData[field].validator.rules.required = true
          })
      if (requiredFields.indexOf('password_user') > -1) {
        defaultData.userData.password.validator.rules.required = true
        defaultData.password.repeat.validator.rules.required = true
      }
      return defaultData
    },

    /**
     * Ask the current user if a notification about the newly created account
     * should be sent.
     * @param object user
     */
    notifyUser(user) {
      const context = this
      swal({
        title: 'Notify user?',
        text: `Do you want to send a login link by e-mail?`,
        type: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        showCloseButton: true
      })
          .then(function() {
            const credentials = {
              email: user.email,
              new: true,
            }
            Auth.createPasswordReset(credentials)
                .then(response => {
                  context.$alert.set(`A welcome e-mail has been sent to ${user.email}.`, 'success', 6)
                })
                .catch(error => {
                  context.$error.set(error)
                })
          })
    },

    /**
     * The default data for this component.
     * @return object
     */
    defaultData() {
      return {
        roles: [],
        userData: {
          active: {
            value: true
          },
          approval: {
            value: false,
            validator: {
              rules: {
                required: false
              }
            }
          },
          name: {
            value: '',
            validator: {
              rules: {
                required: true,
                alpha_num: true,
                min: 5
              }
            }
          },
          email: {
            value: '',
            validator: {
              rules: {
                required: true,
                email: true
              }
            }
          },
          password: {
            value: '',
            validator: {
              rules: {
                required: false,
                password: true,
                min: 6
              }
            }
          },
          firstname: {
            value: '',
            validator: {
              rules: {
                regex: /^[\p{L}\s]+$/ui,
              }
            }
          },
          lastname: {
            value: '',
            validator: {
              rules: {
                  regex: /^[\p{L}\s]+$/ui,
              }
            }
          },
          phone: {
            value: '',
            validator: {
              rules: {
                phone: true
              }
            },
          },
          phonePrivate: {
            value: '',
            validator: {
              rules: {
                phone: true
              }
            },
          },
          country: {
            value: '',
            values: [],
            validator: {
              rules: {
                required: true,
              }
            }
          },
          skills: {
            value: [],
            validator: {
              rules: {
                required: false,
              }
            }
          },
          workgroups: {
            value: [],
            validator: {
              rules: {
                required: false,
              }
            }
          },
          doNotCreateConflict: {
            value: false,
          },
          dailyFeedFeedbacks: {
            value: 0
          },
          dailyProductionFeedbacks: {
            value: 0
          },
          dailyServiceTickets: {
            value: 0
          },
          needRatesApproval: {
            value: false
          },
          ratesApproval: {
            value: false
          }
        },
        password: {
          current: {
            value: '',
            validator: {
              rules: {
                required: false,
                password: true,
                min: 6
              }
            }
          },
          repeat: {
            value: '',
            validator: {
              rules: {
                required: false,
                min: 6,
                confirmed: 'password_user'
              }
            }
          }
        },
        error: ''
      }
    },
  },

}

</script>

<template>
  <div id="user-edit" class="col-sm-10 col-lg-8 col-sm-offset-1 col-lg-offset-2 container book-top-sticky-wrapper book-bottom-sticky-wrapper">
    <div class="page-header book-top-sticky clearfix">
      <h2 class="page-title">
        <span v-if="isUserEditForm && user">Edit user {{user.get.id}}</span>
        <span v-if="!isUserEditForm">Create user</span>
      </h2>
      <h4 class="label label-danger" v-if="!formData.userData.active.value && isUserEditForm && user">inactive</h4>
      <book-users-action-menu v-if="!isUserEditForm"></book-users-action-menu>
      <book-user-action-menu v-else></book-user-action-menu>
    </div>

    <div class="user-edit-content">
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-info">
            <div class="panel-heading">
              Profile
            </div>
            <book-loader class="panel-body" v-if="!profileIsLoaded"></book-loader>
            <div class="panel-body form-horizontal" v-else>
              <div class="form-group" :class="{'has-error': formErrors.has('user_email')}">
                <label for="user-email" class="control-label book-width-100">
                  <span v-if="formData.userData.email.validator.rules.required" class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  E-mail
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.userData.email.validator" id="user-email" name="user_email" type="email" class="form-control" placeholder="Enter your e-mail" v-model.trim="formData.userData.email.value" data-lpignore="true" />
                </div>
              </div>
              <div class="form-group" :class="{'has-error': formErrors.has('username')}">
                <label for="user-name" class="control-label book-width-100">
                  <span v-if="formData.userData.name.validator.rules.required" class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  Username
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.userData.name.validator" id="user-name" name="username" type="text" class="form-control" placeholder="Enter your username" v-model.trim="formData.userData.name.value" data-lpignore="true">
                  <p class="help-block" v-if="formErrors.has('username')">At least 5 alpha numeric characters.</p>
                </div>
              </div>
              <div class="form-group" :class="{'has-error': formErrors.has('firstname')}">
                <label for="user-firstname" class="control-label book-width-100">
                  <span v-if="formData.userData.firstname.validator.rules.required" class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  Firstname
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.userData.firstname.validator" name="firstname" id="user-firstname" type="text" class="form-control" placeholder="Enter your firstname" v-model="formData.userData.firstname.value" data-lpignore="true">
                  <p class="help-block" v-if="formErrors.has('firstname')">Only alpha characters allowed.</p>
                </div>
              </div>
              <div class="form-group" :class="{'has-error': formErrors.has('lastname')}">
                <label for="user-lastname" class="control-label book-width-100">
                  Lastname
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.userData.lastname.validator" name="lastname" id="user-lastname" type="text" class="form-control" placeholder="Enter your lastname" v-model="formData.userData.lastname.value" data-lpignore="true">
                  <p class="help-block" v-if="formErrors.has('lastname')">Only alpha characters allowed.</p>
                </div>
              </div>
              <p class="text-danger">
                <span v-if="formData.userData.phone.validator.rules.required" class="form-control-required-symbol pull-left" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                Please, select either a Public or Private
              </p>
              <div class="form-group">
                <label for="phone" class="control-label book-width-100">
                  Public phone
                </label>
                <div class="form-control-100">
                  <book-phone
                      v-validate="formData.userData.phone.validator"
                      name="phone"
                      componentId="profile_phone"
                      :default-value="formData.userData.phone.value"
                      @newValue="value => formData.userData.phone.value = value">
                  </book-phone>
                  <p class="help-block">This will be visible for all users of VIAPLAY Sport Booking.</p>
                </div>
              </div>
              <div class="form-group">
                <label for="phone" class="control-label book-width-100">Private phone</label>
                <div class="form-control-100">
                  <book-phone
                      v-validate="formData.userData.phonePrivate.validator"
                      name="phonePrivate"
                      componentId="profile_phone_private"
                      :default-value="formData.userData.phonePrivate.value"
                      @newValue="value => formData.userData.phonePrivate.value = value">
                  </book-phone>
                  <p class="help-block">This will be visible only to Production leaders and administrators.</p>
                </div>
              </div>
              <div class="form-group" v-if="isUserEditForm && isCurrentUser" :class="{'has-error': formErrors.has('password_current')}">
                <label for="user-password-current" class="control-label book-width-100">
                  Password
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.password.current.validator" id="user-password-current" name="password_current" type="password" class="form-control" placeholder="Enter your current password" v-model.trim="formData.password.current.value" data-lpignore="true">
                </div>
              </div>
              <div class="form-group" :class="{'has-error': formErrors.has('password_user')}">
                <label for="user-password" class="control-label book-width-100">
                  <span v-if="formData.userData.password.validator.rules.required" class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  New
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.userData.password.validator" id="user-password" name="password_user" type="password" class="form-control" placeholder="Enter the new password" v-model.trim="formData.userData.password.value" data-lpignore="true" ref="password_user">
                  <p class="help-block" v-if="formErrors.has('password_user')">The password should contain at least 6 characters, 1 digit and 1 special character.</p>
                </div>
              </div>
              <div class="form-group" :class="{'has-error': formErrors.has('password_repeat')}">
                <label for="user-password-repeat" class="control-label book-width-100">
                  <span v-if="formData.password.repeat.validator.rules.required" class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  Repeat
                </label>
                <div class="form-control-100">
                  <input v-validate="formData.password.repeat.validator" id="user-password-repeat" name="password_repeat" type="password" class="form-control" placeholder="Enter the password again" v-model.trim="formData.password.repeat.value" data-lpignore="true" data-vv-as="password_user">
                  <p class="help-block" v-if="formErrors.has('password_repeat')">The field doesn't match the password above.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-info" v-if="hasSubscribeAbility">
            <div class="panel-heading">
              Subscriptions
            </div>
            <book-loader class="panel-body" v-if="!profileIsLoaded"></book-loader>
            <div class="panel-body form-horizontal" v-else>
              <div class="form-group" v-if="$ability.can('get_daily_feed_feedbacks', 'Feedbacks & Reports')">
                <label for="dailyFeedFeedbacks" class="control-label book-width-100">Getting daily feedback for feeds</label>
                <div class="form-control-100">
                  <select class="form-control" v-validate name="dailyFeedFeedbacks" v-model="formData.userData.dailyFeedFeedbacks.value">
                    <option value="0" >None</option>
                    <option value="1" >All</option>
                    <option value="2" >My country only</option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="$ability.can('get_daily_production_feedbacks', 'Feedbacks & Reports')">
                <label for="dailyProductionFeedbacks" class="control-label book-width-100">Getting daily feedback for productions</label>
                <div class="form-control-100">
                  <select class="form-control" v-validate name="dailyProductionFeedbacks" v-model="formData.userData.dailyProductionFeedbacks.value">
                    <option value="0" >None</option>
                    <option value="1" >All</option>
                    <option value="2" >My country only</option>
                    <option value="3" >Where I'm production leader only</option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="$ability.can('get_daily_service_tickets', 'Feedbacks & Reports')">
                <label for="dailyServiceTickets" class="control-label book-width-100">Getting daily service tickets</label>
                <div class="form-control-100">
                  <select class="form-control" v-validate name="dailyServiceTickets" v-model="formData.userData.dailyServiceTickets.value">
                    <option value="0" >None</option>
                    <option value="1" >All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-info">
            <div class="panel-heading">
              Access
            </div>
            <book-loader class="panel-body" v-if="!user || !skillEntity || !workgroupsEntity"></book-loader>
            <div class="panel-body form-horizontal" v-else>
              <p v-if="$ability.cannot('update', 'Users')" class="text-danger">Only administrators can change country and skills.</p>
              <!--
               <div class="form-group" :class="{'has-error': formErrors.has('country')}">
                   <label for="user-country" class="control-label book-width-100">
                       <span class="fa fa-asterisk form-control-required-symbol" aria-hidden="true"></span>
                       Country
                   </label>
                   <div class="form-control-100">
                       <select v-validate="formData.userData.country.validator" class="form-control" name="country" v-model="formData.userData.country.value" :disabled="$ability.cannot('update', 'Users')">
                           <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                       </select>
                   </div>
               </div>-->
              <div class="form-group" :class="{'has-error': formErrors.has('country')}">
                <label for="user-country" class="control-label book-width-100">
                  <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  Country
                </label>
                <div class="form-control-100">
                  <multiselect
                      name="country"
                      :options="countries"
                      :multiple="false"
                      track-by="code"
                      label="label"
                      placeholder=""
                      :max-height="200"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :show-labels="false"
                      :disabled="$ability.cannot('update', 'Users')"
                      v-model="formData.userData.country.value"
                      v-validate="formData.userData.country.validator"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="user-country" class="control-label book-width-100">
                  <span class="form-control-required-symbol" aria-hidden="true"><font-awesome-icon icon="asterisk"/></span>
                  Skills
                </label>
                <div class="form-control-100" v-if="skillEntity">
                  <multiselect
                      :disabled="$ability.cannot('update', 'Users')"
                      v-validate="formData.userData.skills.validator"
                      name="skills"
                      v-model="formData.userData.skills.value"
                      track-by="id"
                      label="label"
                      placeholder=""
                      :max-height="200"
                      :options="skillEntityItems"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :show-labels="false"
                      :custom-label="getSkillLabel">
                    <span slot="noResult">No skills found.</span>
                  </multiselect>
                  <p class="help-block" v-if="$ability.can('update', 'Users')">Availble skills are affected by the chosen country.</p>
                  <!-- <label class="control-label checkbox-inline" v-if="$ability.can('update', 'Users')">
                      <input type="checkbox" name="do_not_create_conflict" v-validate v-model="formData.userData.doNotCreateConflict.value" :value="true">
                      Don't create conflict
                  </label> -->
                </div>
              </div>
              <div class="form-group">
                <label for="user-country" class="control-label book-width-100">
                  <span class="form-control-required-symbol" aria-hidden="true"></span>
                  Workgroups
                </label>
                <div class="form-control-100" v-if="workgroupsEntity">
                  <multiselect
                      :disabled="$ability.cannot('update', 'Users')"
                      v-validate="formData.userData.workgroups.validator"
                      name="workgroups"
                      v-model="formData.userData.workgroups.value"
                      track-by="id"
                      label="label"
                      placeholder=""
                      :max-height="200"
                      :options="workgroupsEntityItems"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :show-labels="false"
                      :custom-label="getWorkgroupsLabel">
                    <span slot="noResult">No workgroups found.</span>
                  </multiselect>
                  <p class="help-block" v-if="$ability.can('update', 'Users')">Availble workgroups are affected by the chosen country.</p>
                  <label class="control-label checkbox-inline" v-if="$ability.can('update', 'Users')">
                    <input type="checkbox" name="do_not_create_conflict" v-validate v-model="formData.userData.doNotCreateConflict.value" :value="true">
                    Don't create conflict
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="panel" :class="!user || formData.userData.approval.value ? 'panel-info' : 'panel-danger'">
            <div class="panel-heading">
              Approval
            </div>
            <book-loader class="panel-body" v-if="!user"></book-loader>
            <div class="panel-body" v-else>
              <book-toggle
                  v-validate
                  name="approval"
                  :status="formData.userData.approval.value"
                  :toggleDisabled="!isCurrentUser"
                  @toggle="formData.userData.approval.value = !formData.userData.approval.value">
                <span slot="on">yes</span>
                <span slot="off">no</span>
              </book-toggle>
              <span class="book-user-edit-approval-text">
                <p>
                  The Nordic Entertainment Group (NENT Group) will use the personal data you provide in this form to either book your services or provide you with information related to a booking.
                </p>
                <p>
                  Please note;
                </p>
                <p>
                  None of the personal data provided in this form will be shared to a third party without your explicit consent.
                </p>
                <p>
                  Your personal data will be retained a maximum of 1 year from the date of your last login after which the data and your login will be deleted from the system. Therefore, we suggest that you log-in a minimum of once a year if you wish us to retain your data.
                </p>
                <p>
                  Continued use of the system is an accept that we may continue to retain your personal data.
                </p>
                <p>
                  If you accept that we may hold your personal data, please slide the “no” button to the right.
                </p>
                <p>
                  If at any time, you no longer wish for us to retain or use your personal data please revoke this permission by sliding the “yes” button to the left.
                </p>
              </span>
            </div>
          </div>
          <div class="panel panel-info" v-if="$ability.can('update', 'Users')">
            <div class="panel-heading">
              Require user's rates approval
              <label class="pull-right">
                <input name="active" v-validate="formData.userData.needRatesApproval.validator" type="checkbox" v-model="formData.userData.needRatesApproval.value" >
              </label>
            </div>
          </div>
          <div class="panel" :class="!user || formData.userData.ratesApproval.value ? 'panel-info' : 'panel-danger'" v-if="formData.userData.needRatesApproval.value">
            <div class="panel-heading">
              Rates approval
            </div>
            <book-loader class="panel-body" v-if="!user"></book-loader>
            <div class="panel-body" v-else>
              <book-toggle
                  v-validate="formData.userData.ratesApproval.validator"
                  name="approval"
                  :status="formData.userData.ratesApproval.value"
                  :toggleDisabled="!isCurrentUser"
                  @toggle="formData.userData.ratesApproval.value = !formData.userData.ratesApproval.value">
                <span slot="on">yes</span>
                <span slot="off">no</span>
              </book-toggle>
              <span class="book-user-edit-approval-text">
                            <p>
                                The Nordic Entertainment Group (NENT Group) needs you to confirm that you accept the rates.
                            </p>
                            <p>
                                Please note;
                            </p>
                            <p>
                                You can find your rates any time under Profile | <a href="/rates/my" target="_blank">My rates</a>
                            </p>
                        </span>
            </div>
          </div>
          <div class="panel panel-info">
            <div class="panel-heading">
              Roles
              <label class="pull-right">
                <input name="active" type="checkbox" v-model="formData.userData.active.value" :disabled="$ability.cannot('update', 'Users') || isCurrentUser">
                active
              </label>
            </div>
            <book-loader class="panel-body" v-if="!availableRoles || !user"></book-loader>
            <div class="panel-body" v-else>
              <p v-if="$ability.cannot('update', 'Users')" class="text-danger">Only administrators can change the roles.</p>
              <p v-if="formData.roles.length < 1" class="text-danger">Select at least 1 role.</p>
              <div class="checkbox" v-for="role in availableRoles">
                <label>
                  <input type="checkbox" name="role" v-validate v-model="formData.roles" :value="role.id" :disabled="roleIsDisabled(role.id)">{{role.label}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="book-form-actions book-bottom-sticky book-actions-flex">
        <button class="btn btn-primary" @click="submit()" :disabled="!isFormValid || isFormClean || formErrors.has('password_user')">Save</button>
        <button class="btn btn-default" @click="notifyUser(user.get)" v-if="!isCurrentUser && user && $ability.can('update', 'Users')">Reset password</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#user-edit {
  .book-toggle {
    float: left;
  }
  .book-user-edit-approval-text {
    display: block;
    overflow: hidden;
    padding-left: 10px;
    // margin-top: 10px;
  }

  @media (max-width: 552px) {
    .user-edit-content{
      margin-top: 30px;
    }
  }
}
</style>