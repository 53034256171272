<script>

import router from 'router'
import store from 'store'
import Contract from 'components/contract/Contract'
import Entity from 'components/entity/Entity'
import System from 'components/system/System'
import BookLoader from 'components/common/loader/BookLoader'
import BookCostCenters from 'components/contract/BookCostCenters'

// Vendors.
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import { Validator } from 'vee-validate';
import multiselect from 'vue-multiselect'

export default {

    components: {
        datepicker,
        BookLoader,
        BookCostCenters,
        multiselect
    },
    props: {
        id: {
            type: Number,
        }
    },

    mounted() {

        const context = this

        // Define a date format to use when validating a period date.
        this.$validator.attach('periodDate', 'date_format:YYYY-MM-DD');

        // Load some entities.
        const leagueEntityId = context.setting.league_entity_id
        context.leagueEntity = new Entity(leagueEntityId)
        const costCentersEntityId = context.setting.costcenters_entity_id
        context.costCentersEntity = new Entity(costCentersEntityId)
        const contentTypeEntityId = context.setting.contenttype_entity_id
        context.contentTypeEntity = new Entity(contentTypeEntityId)
        const dataToLoad = [
            context.leagueEntity.load(leagueEntityId),
            context.costCentersEntity.load(costCentersEntityId),
            context.contentTypeEntity.load(contentTypeEntityId)
        ]

        Promise.all(dataToLoad)
        .then(([league, constCenters]) => {
            if (league.data.fields.settings.reference) {
                const sportEntityId = league.data.fields.settings.reference.id
                context.sportEntity = new Entity(sportEntityId)
                context.sportEntity.load(sportEntityId)
                .then(response => {
                    if (context.isEditForm) {
                        context.contract = new Contract(context.id)
                        context.contract.load(context.id)
                        .then(response => {
                            context.setFormValues()
                            context.setLoaded()
                        })
                    }
                    else {
                        context.setLoaded()
                    }
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to load the sport entity.')
                })
            }
        })
        .catch(error => {
            context.$error.set(error, 'It was not possible to load the entity.')
        })
    },

    data() {
        return {
            defaultData: this.defaultData(),
            activeTab: 'general',
            // datepicker: {
            //     option: {
            //         type: 'day',
            //         week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            //         month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            //         inputStyle: {},
            //         format: 'YYYY-MM-DD',
            //     },
            // },
            isLoaded: false,
            contract: null,
            leagueEntity: null,
            sportEntity: null,
            costCentersEntity: null,
            contentTypeEntity: null
        }
    },
    watch: {

        // Since we can't use "v-validate" on a datePicker directive, we need
        // to validate the date values this way.
        // We need this in order to make the form "dirty" and unlock the
        // "save" button.
        'defaultData.formData.periodStart.time': function (newValue) {
            const context = this
            if (context.contract && (context.contract.get.periodStart != newValue)) {
                this.$validator.validate('periodDate', newValue)
            }
        },
        'defaultData.formData.periodEnd.time': function (newValue) {
            const context = this
            if (context.contract && (context.contract.get.periodEnd != newValue)) {
                this.$validator.validate('periodDate', newValue)
            }
        }

    },
    computed: {
        contracts() {
            return store.state.data.contract
        },
        costCentersItems() {
            return this.costCentersEntity ? this.costCentersEntity.get.items : null
        },
        contentTypesItems() {
            return this.contentTypeEntity ? this.contentTypeEntity.get.items : null
        },
        currencies() {
            return this.$settings.get.currencies
        },
        countries() {
            return Object.keys(store.state.system.countries).length > 0 ? store.state.system.countries : null
        },
        setting() {
            return this.$settings.get.contracts
        },
        isEditForm() {
            return this.$route.name === 'contract.edit'
        },
        isFormClean() {
            return Object.keys(this.formFields).every(k => {
                return this.formFields[k].pristine
            })
        }
    },

    methods: {
        submit() {
            const context = this
            if (!context.contract) {
                context.contract = new Contract(0)
            }
            Lazy(context.defaultData.formData)
            .each(function(v, k) {
                let value = v.time ? v.time : v.value
                switch (k) {
                  case 'costCenters':
                      value = []
                      v.value.forEach(function(item) {
                          value.push(item.id)
                      })
                      break;
                  case 'rightsHolders':
                      value = []
                      v.value.forEach(function(item) {
                          value.push(item.code)
                      })
                      break;
                }
                context.contract.set(k, value)
            })

            if (context.isEditForm) {
                context.contract.save()
                .then(response => {
                    this.$alert.set('Contract ' + response.data.id + ' has been updated.', 'success', 4)
                    Object.assign(context.$data.defaultData, this.defaultData())
                    context.$root.$emit('formSaved')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to update the contract.')
                })
            }
            else {
                context.contract.store()
                .then(response => {
                    this.$alert.set('A new contract has been created.', 'success', 3)
                    Object.assign(context.$data.defaultData, defaultData())
                    context.$root.$emit('formSaved')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to save the contract.')
                })
            }
        },

        /**
         * Set the current form as "loaded" and make some last adjustments.
         */
        setLoaded() {

            this.isLoaded = true

            // Postfixes.
            setTimeout(function() {

                // Add some classes to all datepicker elements.
                // @TODO Replace the loop by $refs
                const datePickerEl = document.querySelectorAll('input.cov-datepicker')
                datePickerEl.forEach(el => {
                    el.classList.add('form-control')
                    el.classList.add('book-form-control-inline')
                    el.classList.add('book-width-100')
                })

            }, 0)

        },

        /**
          * Set the form values, when editing a contract.
         */
        setFormValues() {
            const context = this
            const entityItem = context.leagueEntity.getItem(context.contract.get.league)
            Lazy(context.defaultData.formData)
            .each(function(v, k) {
                if (entityItem && (k == 'sport')) {
                    v.value = entityItem.reference
                }
                else if (typeof v.time != 'undefined') {
                    v.time = context.contract.get[k]
                }
                else if (k == 'costCenters') {
                    const values = context.costCentersEntity.get.items.filter(function(item) {
                        return context.contract.get[k].indexOf(item.id.toString()) > -1
                    })
                    v.value = values
                }
                else if (k == 'rightsHolders') {
                    const values = context.countries.filter(function(item) {
                        return context.contract.get[k].indexOf(item.code) > -1
                    })
                    v.value = values
                }
                else {
                    v.value = context.contract.get[k]
                }
            })
        },


        /**
         * Check if the passed tab name is the current active tab.
         */
        isActiveTab(name) {
            return this.activeTab == name
        },


        generateCostItemId() {
            let key = '' + moment().valueOf()
            return 'item_' + key.substr(6)
        },

        addNewCostCenterItem() {
            const item = {
                id: this.generateCostItemId(),
                price: 0,
                costCenters: []
            }
            this.defaultData.formData.costCenters2.value.push(item)
        },

        removeCostCenterItem(id) {

            let i = this.defaultData.formData.costCenters2.value.findIndex(v => v.id == id)

            //console.log(i)
            //console.log(this.defaultData.formData.costCenters2.value)

            if (i > -1) {
                this.defaultData.formData.costCenters2.value.splice(i, 1);
            }

        },

        defaultData() {
            return {
                formData: {
                    currency: {
                        value: '',
                        validator: {
                            rules: 'required'
                        }
                    },
                    sport: {
                        value: '',
                        validator: {
                            rules: 'required'
                        }
                    },
                    league: {
                        value: '',
                        validator: {
                            rules: 'required'
                        }
                    },
                    name: {
                        value: '',
                        validator: {
                            rules: 'required'
                        }
                    },
                    number: {
                        value: '',
                        validator: {
                            rules: 'required|decimal:0'
                        }
                    },
                    periodStart: {
                        time: '',
                    },
                    periodEnd: {
                        time: '',
                    },
                    invoiceable: {
                        value: true,
                    },
                    billing: {
                        value: 'bulked'
                    },
                    bulkedPrice: {
                        value: 0,
                        validator: {
                            rules: 'decimal:2'
                        }
                    },
                    bulkedEvents: {
                        value: 0,
                        validator: {
                            rules: 'decimal:2'
                        }
                    },
                    bulkedExtra: {
                        value: 0,
                        validator: {
                            rules: 'decimal:2'
                        }
                    },
                    price: {
                        value: 0,
                        validator: {
                            rules: 'decimal:2'
                        }
                    },
                    priceChannel: {
                        value: 0,
                        validator: {
                            rules: 'decimal:2'
                        }
                    },
                    costCenters: {
                        value: [],
                    },
                    costCenters2: {
                        value: [],
                    },
                    rightsHolders: {
                        value: [],
                    },
                }
            }
        }
    }
}

</script>

<template>
<div id="contract-edit" class="admin book-top-sticky-wrapper center-block">
    <div class="page-header book-top-sticky clearfix" v-if="isLoaded">
        <h1 class="page-title text-center">
            <span v-if="!isEditForm">Create contract</span>
            <span v-if="isEditForm">Edit contract {{this.id}}</span>
        </h1>
    </div>

    <ul class="nav nav-tabs book-form-navigation">
        <li role="presentation" :class="{'active': isActiveTab('general')}"><a @click="activeTab = 'general'">General</a></li>
        <li role="presentation" :class="{'active': isActiveTab('costs')}"><a @click="activeTab = 'costs'">Costs</a></li>
    </ul>


    <div class="panel panel-info">
        <book-loader class="panel-body" v-if="!isLoaded"></book-loader>
        <div class="panel-body form-horizontal" v-if="isLoaded" v-show="isActiveTab('general')">

            <div class="col-sm-12 col-md-12 book-form-item-wrapper">
                <div class="form-group">
                    <label class="control-label book-width-100">Rights holders</label>
                    <div class="form-control-100" v-if="countries">
                        <multiselect
                            v-validate
                            v-model="defaultData.formData.rightsHolders.value"
                            track-by="code"
                            label="label"
                            placeholder=""
                            :max-height="200"
                            :options="countries"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :show-labels="false">
                            <span slot="noResult">No countries found.</span>
                        </multiselect>
                    </div>
                </div>
            </div>

            <div class="col-sm-7 col-md-7">
                <div class="form-group" :class="{'has-error': formErrors.has('currency')}">
                    <label for="name" class="control-label book-width-100">Currency</label>
                    <div class="form-control-100">
                        <select class="form-control" name="currency" v-validate="defaultData.formData.currency.validator" v-model="defaultData.formData.currency.value">
                            <option value="">None</option>
                            <option v-for="(label, code) in currencies" :value="code">{{code}} - {{label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" :class="{'has-error': formErrors.has('sport')}">
                    <label for="name" class="control-label book-width-100">Sport</label>
                    <div class="form-control-100">
                        <select class="form-control" name="sport" v-validate="defaultData.formData.sport.validator" v-model="defaultData.formData.sport.value" v-if="sportEntity">
                            <option value="">None</option>
                            <option v-for="sport in sportEntity.get.items" :value="sport.id">{{sport.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" :class="{'has-error': formErrors.has('league')}">
                    <label for="name" class="control-label book-width-100">League</label>
                    <div class="form-control-100">
                        <select class="form-control" name="league" v-validate="defaultData.formData.league.validator" v-model="defaultData.formData.league.value" v-if="leagueEntity" :disabled="defaultData.formData.sport.value == ''">
                            <option value="">None</option>
                            <option v-for="league in leagueEntity.get.items" :value="league.id" v-if="league.reference == defaultData.formData.sport.value">{{league.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" :class="{'has-error': formErrors.has('name')}">
                    <label for="name" class="control-label book-width-100">Name</label>
                    <div class="form-control-100">
                        <input v-validate="defaultData.formData.name.validator" v-model:trim="defaultData.formData.name.value" name="name" type="text" class="form-control" >
                    </div>
                </div>
                <div class="form-group" :class="{'has-error': formErrors.has('number')}">
                    <label for="number" class="control-label book-width-100">Number</label>
                    <div class="form-control-100">
                        <input v-validate="defaultData.formData.number.validator" v-model:trim="defaultData.formData.number.value" name="number" type="text" class="form-control" >
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label book-width-100">Period</label>
                    <div class="form-control-100">
                        <datepicker :date="defaultData.formData.periodStart" :option="$store.state.config.DATEPICKER_OPTION"></datepicker>
                        <span>to</span>
                        <datepicker :date="defaultData.formData.periodEnd" :option="$store.state.config.DATEPICKER_OPTION"></datepicker>
                    </div>
                </div>
            </div>

            <div class="col-sm-5 col-md-5">
                <!-- <div class="form-group" :class="{'has-error': formErrors.has('bulked_price')}">
                    <label for="number" class="control-label book-width-100">Bulked price</label>
                    <div class="form-control-100 input-group">
                        <input v-validate="defaultData.formData.bulkedPrice.validator" v-model:trim="defaultData.formData.bulkedPrice.value" name="bulked_price" type="text" class="form-control"  aria-describedby="bulkedprice-addon1">
                        <span class="input-group-addon text-uppercase" id="bulkedprice-addon1">{{defaultData.formData.currency.value}}</span>
                    </div>
                </div> -->
                <div class="form-group">
                  <div class="form-control-100">
                    <div class="checkbox">
                      <label class="control-label checkbox-inline">
                          <input name="invoiceable" type="checkbox" v-model="defaultData.formData.invoiceable.value">
                          invoiceable
                      </label>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group" :class="{'has-error': formErrors.has('bulked_events')}">
                    <label for="bulked_events" class="control-label book-width-100">Bulked events</label>
                    <div class="form-control-100">
                        <input v-validate="defaultData.formData.bulkedEvents.validator" v-model:trim="defaultData.formData.bulkedEvents.value" name="bulked_events" type="text" class="form-control" >
                    </div>
                </div> -->
                <!-- <div class="form-group" :class="{'has-error': formErrors.has('bulked_extra')}">
                    <label for="bulked_extra" class="control-label book-width-100">Bulked extra</label>
                    <div class="form-control-100">
                        <input v-validate="defaultData.formData.bulkedExtra.validator" v-model:trim="defaultData.formData.bulkedExtra.value" name="bulked_extra" type="text" class="form-control" >
                    </div>
                </div> -->
                <!-- <div class="form-group" :class="{'has-error': formErrors.has('price_channel')}">
                    <label for="price_channel" class="control-label book-width-100">Price/channel</label>
                    <div class="form-control-100 input-group">
                        <input v-validate="defaultData.formData.priceChannel.validator" v-model:trim="defaultData.formData.priceChannel.value" name="price_channel" type="text" class="form-control" aria-describedby="pricechannel-addon1">
                        <span class="input-group-addon text-uppercase" id="pricechannel-addon1">{{defaultData.formData.currency.value}}</span>
                    </div>
                </div> -->
            </div>





        </div>

        <div class="panel-body form-horizontal" v-if="isLoaded" v-show="isActiveTab('costs')">



            <div class="col-sm-12 col-md-12 book-contract-billing-type">

                <div class="book-label">
                    How is this contract billed?
                </div>

                <div class="form-group">
                    <div class="radio-inline">
                        <label>
                            <input type="radio" name="billing_bulked" id="billing-bulked" value="bulked" v-model="defaultData.formData.billing.value">
                            Bulked
                        </label>
                    </div>
                    <div class="radio-inline">
                        <label>
                            <input type="radio" name="billing_itemised" id="billing-itemised" value="itemised" v-model="defaultData.formData.billing.value">
                            Itemised
                        </label>
                    </div>
                </div>

                <div class="book-actions" v-show="defaultData.formData.billing.value == 'itemised'">
                    <button class="btn btn-success pull-right" @click="addNewCostCenterItem()">Add new item</button>
                </div>



            </div>


            <div class="col-sm-12 col-md-12 book-contract-billing-bulked" v-show="defaultData.formData.billing.value == 'bulked'">

                <book-cost-centers
                    type="bulked"
                    :currency="defaultData.formData.currency.value"
                    :cost-centers="costCentersItems"
                    :content-types="contentTypesItems">
                </book-cost-centers>

            </div>

            <div class="col-sm-12 col-md-12 book-contract-billing-itemised" v-show="defaultData.formData.billing.value == 'itemised'">


                <book-cost-centers
                    v-for="(costCenter2, index) in defaultData.formData.costCenters2.value"
                    type="itemised"
                    :item-id="costCenter2.id"
                    :currency="defaultData.formData.currency.value"
                    :cost-centers="costCentersItems"
                    :content-types="contentTypesItems"
                    @removeItem="key => removeCostCenterItem(key)">
                </book-cost-centers>


            </div>



        </div>


    </div>
    <div class="book-form-actions" v-if="isLoaded">
        <button class="btn btn-primary" @click="submit()" :disabled="isFormClean || formErrors.any()">Save</button>
        <router-link exact :to="{name: 'contract'}" class="btn btn-danger">Cancel</router-link>
    </div>
</div>
</template>
