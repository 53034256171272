<script>
import Country from 'components/country/Country'
import System from 'components/system/System'
import store from 'store'
import BookModal from 'components/common/BookModal'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookLoader,
        BookModal
    },
    data() {
        return {
            formData: this.defaultData(),
            loaded: true
        }
    },
    computed: {
        items: {
            get() {
               return this.$store.state.system.countries ? this.$store.state.system.countries : null
            },
            set(items) {
                store.commit('system/setCountries',items)
            }
        },
    },
    methods: {
        /**
         * Add/create a new country.
         */
        addItem() {
            const context = this

            context.$loader.add('entityItemsSaveItem')
            let newItem = {
                code: context.formData.newItem.code.value,
                label: context.formData.newItem.label.value
            }

            axios.post('api/country', newItem)
                .then(response => {
                    context.$alert.set('<b>' + response.data.label + '</b> has been added to <b>Countries</b>', 'success', 3)
                    context.resetForm()
                    context.resetEditStatus(false)
                    // Add new item or update a current one.'
                    let items = context.$store.state.system.countries
                    const i = items.findIndex(v => v.code == response.data.code)
                    if (i > -1) {
                        items[i] = response.data
                    }
                    else {
                        items.push(response.data)
                    }
                    store.commit('system/setCountries', items)
                    context.$loader.remove('entityItemsSaveItem')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to update this entity item.')
                    context.$loader.remove('entityItemsSaveItem')
                })
        },
        saveItem(id) {
            const context = this
            context.clickedSave = true

            let item = context.$store.state.system.countries.find(v => v.code === id)

            if(item) {
                context.$loader.add('entityItemsSaveItem')
                axios.put('api/country/' + id, {
                    code: id,
                    label: item.label
                })
                    .then(response => {
                        if (response.status === 200) {
                            let items = context.$store.state.system.countries
                            const i = items.findIndex(v => v.code == response.data.code)
                            if (i > -1) {
                                items[i] = response.data
                            }
                            else {
                                items.push(response.data)
                            }
                            store.commit('system/setCountries', items)
                            context.$alert.set('<strong>' + response.data.label + '</strong> (id ' + response.data.code  + ') was saved!', 'success', 3)
                            context.editItem(id, false, true)
                            context.$loader.remove('entityItemsSaveItem')
                            context.clickedSave = false
                        }
                        
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to update this country.')
                        context.$loader.remove('entityItemsSaveItem')
                        context.clickedSave = false
                        
                    })
            }
        },
        /**
         * Edit a current entity item enabling the input fields in the form.
         *
         * @param id integer
         * @param status boolean The status to set the edit form to. "False" means
         *                       that the form should be hidden.
         * @param reset boolean (optional) If "true" the old value won't be set
         *                      back to the items model, when the edit form is
         *                      disabled.
         */
        editItem(id, status, reset = false) {
            const context = this

            context.resetEditStatus(!reset)
            context.formData.edit[id] = status

            // Make a copy of the original value in order to set it back in case
            // the user cancel the editing.
            if (status) {
                context.formData.oldValues[id] = {}
                let country = context.$store.state.system.countries.find(v => v.code === id)
                Vue.util.extend(context.formData.oldValues[id], country)
            }
            else if (!reset) {
                context.formErrors.clear()
            }
        },
        /**
         * Delete an country item.
         */
        deleteItem(id) {
            const context = this
            swal({
                title: 'Delete?',
                text: 'Are you sure you want to delete country ' + id + '?',
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function () {
                context.$loader.add('entityItemsDeleteItem')
                axios.delete('api/country/' + id)
                .then(response => {
                    if (response.status === 200) {
                        let i = context.$store.state.system.countries.findIndex(v => v.code === id)
                        if (i > -1) {
                            context.$store.state.system.countries.splice(i, 1)
                        }

                        context.$alert.set('Item deleted!', 'success', 3)
                        context.$loader.remove('entityItemsDeleteItem')
                    }
                    
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to delete the Entity Item!')
                    context.$loader.remove('entityItemsDeleteItem')
                })
            })
        },
        resetForm() {
            Object.assign(this.$data.formData, this.defaultData())
        },
        /**
         * Reset the edit status of the items.
         */
        resetEditStatus(drop = false) {
            const context = this
            context.$store.state.system.countries.forEach((v,k) => {
                Vue.set(context.formData.edit, v.code, false)
                if (context.formData.oldValues[v.code]) {
                    if(drop) {
                        const items = context.$store.state.system.countries
                        // Add new item or update a current one.
                        const i = items.findIndex(v1 => v1.code == v.code)
                        if (i > -1) {
                            items[i] = context.formData.oldValues[v.code]
                        }
                        else {
                            items.push(context.formData.oldValues[v.code])
                        }

                        store.commit('system/setCountries', items)
                    }
                    

                    delete context.formData.oldValues[v.code]
                }

            })

        },
        isFormClean(scope) {
            return this.formFields[scope] &&  Object.keys(this.formFields[scope]).every(k => {
                return this.formFields[scope][k].pristine
            });
        },
        /** 
         *  Return default form data.
         */
        defaultData() {
            return {
                edit: {},
                oldValues: {},
                newItem: {
                    code: {
                        value: '',
                        validator: {
                            rules: {
                                required: true,
                                regex: /^[a-z]{2}$/
                            }
                        },
                    },
                    label: {
                        value: '',
                        validator: {
                            rules: 'required'
                        },
                    }
                },
                showAddItemForm: false,
                draggedItems: 0,
                selected: [],
            }
        },
        
    }
}

</script>

<template>
    <div id="countries" class="admin book-top-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <h2 class="page-title"><span v-if="loaded">Countries</span></h2>
            <div class="secondary-menu btn-group" role="group">
                <button class="btn btn-default" @click="formData.showAddItemForm = true"><font-awesome-icon icon="plus"/><span class="item-label">&nbsp;&nbsp;Add</span></button>
            </div>
        </div>
        <book-loader v-if="!loaded"></book-loader>
        <div class="panel panel-info" v-else>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <th class="text-center"><font-awesome-icon icon="sort"/></th>
                        <th>Code</th>
                        <th>Label</th>
                        <!-- <th>Created</th>
                        <th>Updated</th> -->
                        <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.code">
                            <td class="grab text-center"><span class="grab-item"><font-awesome-icon icon="align-justify"/></span></td>
                            <td>
                                <div :class="{'has-error': formErrors.has('item_' + item.code + '.code')}">
                                    <p class="form-control-static" >{{item.code}}</p>
                                    <!-- <input v-validate="'required'" :data-vv-scope="'item_' + item.code" v-if="formData.edit[item.code]" v-model:trim="item.code" name="label" type="text" class="form-control"> -->
                                </div>
                            </td>
                            <td>
                                <div :class="{'has-error': formErrors.has('item_' + item.code + '.label')}">
                                    <p class="form-control-static" v-if="!formData.edit[item.code]">{{item.label}}</p>
                                    <input v-validate="'required'" :data-vv-scope="'item_' + item.code" v-if="formData.edit[item.code]" v-model:trim="item.label" name="label" type="text" class="form-control">
                                </div>
                            </td>
                            <!-- <td>{{item.created}}</td>
                            <td>{{item.updated}}</td> -->
                            <td class="action">
                                <button class="btn btn-success btn-xs" v-if="formData.edit[item.code]" @click="saveItem(item.code)" :disabled="formErrors.any('item_' +item.code) || isFormClean('item_' + item.code)"><font-awesome-icon icon="save"/></button>
                                <button class="btn btn-default btn-xs" v-if="formData.edit[item.code]" @click="editItem(item.code, false)"><font-awesome-icon icon="times"/></button>
                                <button class="btn btn-primary btn-xs" v-if="!formData.edit[item.code]" @click="editItem(item.code, true)"><font-awesome-icon icon="pencil-alt"/></button>
                                <button class="btn btn-danger btn-xs" v-if="!formData.edit[item.code]" @click="deleteItem(item.code)"><font-awesome-icon icon="trash"/></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <book-modal maxWidth="400px" @close="formData.showAddItemForm = false" v-if="formData.showAddItemForm">
            <h4 slot="header" class="modal-title">Add country</h4>
            <div slot="body">
                <div class="form-horizontal">
                    <div class="form-group" :class="{'has-error': formErrors.has('item_new.code')}">
                        <label class="control-label book-width-100">Code</label>
                        <div class="form-control-100">
                            <input v-validate="formData.newItem.code.validator" data-vv-scope="item_new" v-model:trim="formData.newItem.code.value" name="code" type="text" class="form-control" >
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': formErrors.has('item_new.label')}">
                        <label class="control-label book-width-100">Label</label>
                        <div class="form-control-100">
                            <input v-validate="formData.newItem.label.validator" data-vv-scope="item_new" v-model:trim="formData.newItem.label.value" name="label" type="text" class="form-control" >
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <button class="btn btn-success" @click="addItem()" :disabled="isFormClean('item_new') || formErrors.any('item_new')">Save</button>
            </div>
        </book-modal>
    </div>
</template>