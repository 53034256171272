/**
 * This is a base mixin/component used by Feeds and Productions.
 * IMPORTANT --
 * all emitting labels should be used only once to trigger once in the called page
 * for ex: itemCreated should be used only one time for app.$emit
 */

import {app} from 'app'
import Item from 'components/common/item/Item'
//import Lock from 'components/common/lock/Lock'
import Datetime from 'components/common/helpers/Datetime'

export default {
    socket: {
        'private:items': {
            '.item.created': function(payload, context) {
                if (!payload.meta.type || (context.child && payload.meta.type != context.child.item)) return
                const now = moment().unix()

                payload.data = JSON.parse(payload.data) // JSON data fix to proper parse WN

                // Feeds are not sending elementType so we have to push it to avoid error. WN
                if (typeof payload.data.elementType == 'undefined' && payload.data.feedStart != null) {
                    payload.data.elementType = 'feed'
                }

                // We need to emit data if the data date is same as query date WN
                let _iDate = payload.data[`${payload.data.elementType}Start`].slice(0,10)
                let _iDateEnd = payload.data[`${payload.data.elementType}End`].slice(0,10)

                if (context.timeDifference(_iDate, _iDateEnd)) {
                    Item.parse(payload.data, payload.meta.type, now)
                    const item = context.getNewClass(payload.meta.type, payload.data.id)
                    item.add(payload.data)

                    let author = payload.author ? payload.author : null
                    if (payload.data.elementType === 'mcrComment') return
                    app.$emit('itemCreated', {type: item.get.elementType, id: item.get.id, author: author, persistent: true })
                }
            },
            '.item.updated': function(payload, context) {
                if (!payload.meta.type || (context.child && payload.meta.type != context.child.item)) return

                payload.data = JSON.parse(payload.data) // JSON data fix to proper parse WN
                const item = context.getNewClass(payload.meta.type, payload.data.id)

                // Feeds are not sending elementType so we have to push it to avoid error. WN
                if (typeof payload.data.elementType == 'undefined' && payload.data.feedStart != null) {
                    payload.data.elementType = 'feed'
                }

                // We need to emit data if the data date is same as query date WN
                let _iDate = payload.data[`${payload.data.elementType}Start`].slice(0,10)
                let _iDateEnd = payload.data[`${payload.data.elementType}End`].slice(0,10)

                const now = moment().unix()
                if (context.timeDifference(_iDate, _iDateEnd)) {
                    item.remove()
                    Item.parse(payload.data, payload.meta.type, now)
                    item.add(payload.data)
                    let author = payload.author ? payload.author : null
                    if (payload.data.elementType === 'mcrComment') return
                    app.$emit('itemUpdated', {type: item.get.elementType, id: item.get.id, author: author})
                } else {
                    // if its not in the range remove the item from the list if its exist WN
                    context.$alert.set(`${payload.meta.type.capitalize()} ${payload.data.id} has been moved to another date range.`, 'success', 3)
                    item.remove()
                }
            },
            '.item.deleted': function(payload, context) {
                if (!payload.meta.type || (context.child && payload.meta.type != context.child.item)) return
                const item = context.getNewClass(payload.meta.type, payload.data.id)
                item.remove()
                context.$alert.set(`${payload.meta.type.capitalize()} ${payload.data.id} has been deleted by you from the tab or another user.`, 'success', 3)
            },
        },
        'private:updates': {
            '.updateEvents.sending': function(payload, context) {
                let data = JSON.parse(payload.data)
                if (payload.author) context.setUpdates(data, payload.author)
                else context.setUpdates(data)
            }
        },
        'private:ingests': {
            '.ingest.updated': function(payload, context) {
                console.log(payload.data, 'ingestUpdated', payload, context)
                context.setIngest(context.items, payload.data, 1)
            },
            '.ingest.deleted': function(payload, context) {
                console.log(payload.data, 'ingestDeleted', payload, context)
                context.setIngest(context.items, payload.data, 0)
            },
            '.ingest.mcrStatusUpdated': function(payload, context) {
                console.log(payload.data, 'mcrStatusUpdated', payload)
                context.setMcrStatus(context.items, payload.data, payload.meta.extraData, 1)
            },
            '.ingest.mcrStatusDeleted': function(payload, context) {
                console.log(payload.data, 'mcrStatusDeleted', context)
                context.setMcrStatus(context.items, payload.data, payload.meta.extraData, 0)
            },
        },
        'private:signoffs': {
            '.signoff.created': function(payload, context) {
                if (context.child && payload.data.type != context.child.item) return
                context.setSignoff(payload.data, 'created')
                let author = payload.author ? payload.author : null
                app.$emit('itemUpdated', {type: payload.data.itemType, id: payload.data.itemId, author: author})
            },
            '.signoff.deleted': function(payload, context) {
                if (context.child && payload.data.type != context.child.item) return
                context.setSignoff(payload.data, 'deleted')
                let author = payload.author ? payload.author : null
                app.$emit('itemUpdated', {type: payload.data.itemType, id: payload.data.itemId, author: author})
            }
        },
        'private:equipment': {
            '.equipmentUsage.updated': function(payload, context) {
                if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                app.$emit('changingEquipment', payload.data)

            },
            '.equipmentUsage.created': function(payload, context) {
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                app.$emit('creatingEquipment', payload.data)

            },
            '.equipmentUsage.deleted': function(payload, context) {
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                app.$emit('deletingEquipment', payload.data)
            }
        },
        'private:feedbacks': {
            // '.feedback.updated': function(payload, context) {
            //     if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
            //     app.$emit('changingFeedback', payload.data)

            // },
            '.feedback.created': function(payload, context) {
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                app.$emit('creatingFeedback', payload.data)

            },
            // '.feedback.deleted': function(payload, context) {
            //     if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
            //     app.$emit('deletingFeedback', payload.data)
            // }
        },
        'private:locks': {
            '.lock.created': function(payload, context) {

                // Do not add "locked" flag if the item is locked but the
                // current user
                if (payload.data.user.id == app.$data.user.get.id) return

                let author = payload.author ? payload.author : null
                let locks = []
                if (Array.isArray(payload.data)) locks = payload.data
                else locks.push(payload.data)
                locks
                    .filter(lock => {
                        if (!context.child) return true
                        return lock.itemType == context.child.item
                    })
                    .forEach(lock => {
                        const item = context.getNewClass(lock.itemType, lock.itemId)
                        item.set('lock', lock)
                        app.$emit('itemLocked', {type: lock.itemType, id: lock.itemId, author: author})
                    })
            },
            '.lock.deleted': function(payload, context) {
                // let author = payload.author ? payload.author : null
                let locks = []
                if (Array.isArray(payload.data)) locks = payload.data
                else locks.push(payload.data)
                locks
                    .filter(lock => {
                        if (!context.child) return true
                        return lock.itemType == context.child.item
                    })
                    .forEach(lock => {
                        const item = context.getNewClass(lock.itemType, lock.itemId)
                        item.set('lock', lock)
                        app.$emit('itemUnlocked', {type: lock.itemType, id: lock.itemId, author: ''})
                    })
            }
        }
    },
    methods: {
        timeDifference(idate, idateEnd) {
            idate = moment(idate).unix()
            idateEnd = moment(idateEnd).unix()

            let condition = false
            //console.log(app.$route, 'testing route')
            const _date = {
                from: app.$route.query.from ? app.$route.query.from : null,
                to: app.$route.query.to ? app.$route.query.to : null,
                view: app.$route.params.view ? app.$route.params.view : null,
                name: app.$route.name ? app.$route.name : null
            }
            if (_date.view === 'overview') { _date.to = Datetime.getEndDate(_date.from, 'YYYY-MM-DD', 6) }

            // we need to add 1 day back for from because of endTime of items listing in pages
            _date.from = Datetime.daybefore(_date.from)
           // _date.to = Datetime.nextday(_date.to)

            if (idate >= moment(_date.from).unix() && idate <= moment(_date.to).unix() &&
                idateEnd >= moment(_date.from).unix())
            { condition = true }
            if (idate === moment(_date.to).unix() && idateEnd === moment(_date.to).unix() ||
                idate === moment(_date.from).unix() && idateEnd === moment(_date.from).unix())
            { if (!condition) condition = true }

            console.log(moment(_date.from).unix(), '<- from to ->', moment(_date.to).unix(), idate, condition)

            return condition
        },

        setUpdates(data, author = '') {
            if (author !== '') data.author = author
            app.$emit('getUpdates', data)
        },

        /**
         * Update Ingest from broadcast
         * @param itemlist
         * @param data
         * @param status
         */
        setIngest(itemlist, data, status = 1) {

            let items = []
            const item = itemlist
            Lazy(item).each(v => {
                if (v.id === data.itemId) {
                    items = v.ingestions
                    //console.log(v)
                }
            })
            // update
            let i = items.findIndex(v => v.id === data.id)
            if (i > -1) items.splice(i, 1)
            if (status === 1) items.push(data)

            app.$emit('changedIngestions', {
                itemId: data.itemId,
                itemType: data.itemType,
                items: items
            })
        },

        setMcrStatus(itemlist, data, extraData, status = 0) {
            let items = []
            //return
            Lazy(itemlist).each(v => {
                if (v.id === data.itemId) {
                    items = v.mcrStatus ? v.mcrStatus : (v.mcrstatus ? v.mcrstatus : null)
                }
            })

            let i = items.findIndex(v => v.id === data.id)
            if (i > -1) items.splice(i, 1)
            if (status === 1) items.push(data)

            app.$emit('changedMcrStatusBySocket', {
                itemId: data.itemId,
                itemType: data.itemType,
                items: items,
                extraData: extraData
            })

        },
    },
}
