<script>

import {app} from 'app'

export default {
    computed: {
        rolesButtonIsVisible() {
            return this.$ability.can('manage', 'Roles')
        },
    }
}

</script>

<template>
<div class="secondary-menu clearfix btn-group" role="group">
    <router-link exact :to="{name: 'user.create'}" :class="{'active': $routerTools.isActive('user.create')}" v-if="$ability.can('create', 'Users')" class="btn btn-default">
        <font-awesome-icon icon="user-plus"/> 
        <span class="item-label">&nbsp;&nbsp;Create user</span>
    </router-link>
    <router-link exact :to="{name: 'admin.users'}" :class="{'active': $routerTools.isActive('admin.users')}" class="btn btn-default">
        <font-awesome-icon icon="users"/> 
        <span class="item-label">&nbsp;&nbsp;Users</span>
    </router-link>
    <router-link exact :to="{name: 'admin.users.roles'}" :class="{'active': $routerTools.isActive('admin.users.roles')}" v-if="$ability.can('manage', 'Roles')" class="btn btn-default">
        <font-awesome-icon icon="tags"/> 
        <span class="item-label">&nbsp;&nbsp;Roles</span>
    </router-link>
    <router-link exact :to="{name: 'admin.users.permissions'}" :class="{'active': $routerTools.isActive('admin.users.permissions')}" v-if="$ability.can('manage', 'Permissions')" class="btn btn-default">
        <font-awesome-icon icon="unlock-alt"/> 
        <span class="item-label">&nbsp;&nbsp;Permissions</span>
    </router-link>
</div>
</template>
