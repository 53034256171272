<script>
import {app} from 'app'
import Entity from 'components/entity/Entity'
import Equipment from 'components/equipment/Equipment'
import EquipmentWithUsage from 'components/equipment/EquipmentWithUsage'
import EquipmentTypeWithUsage from 'components/equipment/EquipmentTypeWithUsage'
import System from 'components/system/System'
import Datetime from 'components/common/helpers/Datetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookDatetime from 'components/common/BookDatetime'
import BookLoader from 'components/common/loader/BookLoader'

export default {
    components: {
        BookTable,
        BookModal,
        BookDatetime,
        BookLoader
    },
    props: {
        disabled: {
            default: false,
            type: Boolean
        },
        disabledMessage: String,
        selection: {
            default: () => [],
            type: Array
        },
        dates: {
            default: () => {
                return {from: null, to: null}
            },
            type: Object
        },
        usageId: {
            default: 0,
            type: Number
        },
        usageType: {
            default: '',
            type: String
        },
        local: {
            default: false,
            type: Boolean
        },
        enableCheckAvailability: {
            default: true,
            type: Boolean
        },
        filterTypesBy: {
            default: () => [],
            type: Array
        },
        hideDates: {
            default: false,
            type: Boolean
        },
        columns: {
            default: () => [],
            type:Array
        },
        actionSettings: {
            default: () => ({}),
            type: Object
        },
        print: {
            default: false,
            type: Boolean
        },
        selectedItem: {
            default: '',
            type: String
        },
        itemsType: {
            default: 'Equipment',
            type: String
        },
        allEquipmentProps: {
            default: () => [],
            type:Array
        },
        equipmentTypeEntityProps: {
            default: () => ({}),
            type: Object
        },
        equipmentStatusEntityProps: {
            default: () => ({}),
            type: Object
        },
        externalMCRsEntityProps: {
            default: () => ({}),
            type: Object
        },
        darkMode: {
          default: false
        },
    },
    data() {
        return {
            formData: this.defaultData(),
            addForm: {
                show: false,
                edit: false,
                available: false,
                equipment: null,
            },
            loaded: false,
            addFormType: true,
            equipmentUsage: [],
            equipmentTypeEntity: null,
            equipmentStatusEntity: null,
            fiberEquipmentType: null,
            externalMCRsEntity: null,
            allEquipment: null,
            isFormChangedVar: false,
            showAll: false,
            oldEquipment: null,
            oldEquipments: null,
            exceededFlag: false,
            exceededGroup: '',
            exceededGroupLimit: ''
        }
    },
    computed: {
        checking() {
            return this.$loader.has('bookFiberEquipmentSelectionChecking')
        },
        processing() {
            return this.$loader.has('bookFiberEquipmentSelectionSubmit')
        },
        getApiUrl() {
            return 'api/' + this.usageType + '/' + this.usageId + '/' + 'equipment'
        },
        getApiUrlType() {
            return this.getApiUrl + '-type'
        },
        equipmentList() {
            // show all equipment for all countries
            return this.allEquipment.filter(v => {
                return Equipment.isVisible(v, this.showAll)
            })
        },
        isOneRecord(){
            return this.equipmentData.rows.length == 1;
        },
        // isAllowedToSetPrimary(){
        //     let equipmentIsAvailable = true
        //     this.equipmentData.rows.forEach(v => {
        //         if ((v.type.value == "ENG" || v.type.value == "Edit") && v.isPrimary.rawValue) {
        //             equipmentIsAvailable = false
        //         }
        //     })
        //     return equipmentIsAvailable;
        // },
        isDisplayAddButton(){
            if (!this.isCloneBased /*&& this.isEngOrEdit*/ && this.usageId == 0) return false;
            return true;
        },
        // equipmentTypesList() {
        //     return this.equipmentTypeEntity.get.items
        //         .filter(v => {

        //             // When filterTypesBy is set, ex when creating Edit/ENG, then
        //             // it can filter away other equipment types that can have been
        //             // added to the Edit/ENG base template. This means that those
        //             // equipment pieces (in the template) won't be added.
        //             if (this.filterTypesBy.length > 0) {
        //                 return this.filterTypesBy.indexOf(v.id) > -1
        //             }

        //             return true
        //         })
        // },
        // hasEquipment() {
        //     let equipmentIsAvailable = false
        //     this.equipmentList.forEach(v => {
        //         if (v.type == this.formData.equipmentType.value) {
        //             equipmentIsAvailable = true
        //         }
        //     })
        //     return equipmentIsAvailable
        // },

        isFormChanged() {
            if (this.formData.id.value) {
                switch (true) {
                    case (this.formData.equipmentId.value != this.addForm.equipment.id):
                    case (!this.hideDates && this.formData.periodStart.value != this.addForm.equipment.equipmentUsage.periodStart):
                    case (!this.hideDates && this.formData.periodEnd.value != this.addForm.equipment.equipmentUsage.periodEnd):
                    case (this.formData.isPrimary.value != this.addForm.equipment.equipmentUsage.isPrimary):
                    case (this.formData.isNotUpdatePeriod.value != this.addForm.equipment.equipmentUsage.isNotUpdatePeriod):
                        return true
                }
                return false
            }
            else {
                if (this.formData.equipmentId.value !== '') {
                    return true
                }
            }

            // Check only for equipment type, if we don't have a equipment piece.
            return false

        },

        isFormValid() {
            //console.log("is form valid");
            // If it's a real equipment piece, then we need to check for all
            // fields.
            const context = this
            if (context.formData.equipmentId.value !== '') {

                // Special check for data fields.
                switch (true) {
                    case (!context.hideDates && context.formData.periodStart.validator.rules.required && context.formData.periodStart.value == ''):
                    case (!context.hideDates && context.formData.periodEnd.validator.rules.required && context.formData.periodEnd.value == ''):
                        return false
                }
            }

            // Check only for equipment type, if we don't have a equipment piece.
            return this.formData.equipmentType.value !== ''

        },

        equipmentIsDisabled() {
            return /*!this.hasEquipment ||*/ this.equipmentList.length < 1 /*|| this.formData.equipmentType.value == ''*/
        },
        getSelection() {
            return this.selection
                .map(v => {
                    this.parseItem(v)
                    return v
                })
        },
        equipmentData() {
            //WN MCR Rendering dirty fix
            //if (!this.loaded) return

            const context = this
            const output = {
                options: {
                    actions: []
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'visibleId',
                        label: 'ID',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('visibleId')
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('type')
                    },
                    {
                        value: 'equipment',
                        label: 'Source',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('equipment')
                    },
                    {
                        value: 'country',
                        label: 'Country',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('country')
                    },
                    {
                        value: 'description',
                        label: 'Description',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('description')
                    },
                    {
                        value: 'status',
                        label: 'Status',
                        sortable: true,
                        searchable: false,
                        visible: this.columnIsVisible('status')
                    },
                    {
                        value: 'period',
                        label: 'Period',
                        sortable: true,
                        searchable: false,
                        visible: !context.hideDates,
                    },
                    {
                        value: 'conflict',
                        label: '',
                        visible: this.columnIsVisible('conflict')
                    },
                    {
                        value: 'exceeded',
                        label: '',
                        visible: this.columnIsVisible('exceeded')
                    },
                    // {
                    //     value: 'isPrimary',
                    //     label: 'Primary',
                    //     sortable: false,
                    //     searchable: false,
                    //     visible: this.columnIsVisible('isPrimary')
                    // },
                    {
                        value: 'isNotUpdatePeriod',
                        label: "Don't Update period",
                        sortable: false,
                        searchable: false,
                        visible: this.columnIsVisible('isNotUpdatePeriod')
                    }
                ],
                rows: []
            }
            console.log("book fiber equipment section", this.loaded);

            // Add actions buttons if the user has permissions to perform these.
            if (this.$ability.can('update', 'Equipment')) {
                output.options.actions.push('edit')
            }
            if (this.$ability.can('delete', 'Equipment')) {
                output.options.actions.push('delete')
            }
            if(this.iconIsVisible()) {
                output.options.actions.push('icon')
            }

            if (Object.keys(this.actionSettings).length > 0) {
                output.options.actionSettings = this.actionSettings
            }

            let isOneRecord = context.getEquipmentItems.length;
            let isFirstRecord = true;

            context.getEquipmentItems
                .filter(v => {
                    // if (Equipment.isEquipmentPiece(v)) {
                    //     // this.showAll added for all country equipments
                    //     return Equipment.isVisible(v, this.showAll)
                    // }
                    // return true
                    return Equipment.isVisible(v, this.showAll)
                })
                .forEach(v => {
                    //const isEquipmentPiece = Equipment.isEquipmentPiece(v)
                    // Build some values to be used in the current table row.
                    let usageId = context.getItemUsageId(v)
                    let visibleId = context.getItemVisibleId(v)
                    let typeRaw = context.fiberEquipmentType//Equipment.getItemTypeRaw(v)
                    let type = context.fiberEquipmentType//Equipment.getItemType(v, this.equipmentTypeEntity)
                    let status = context.getItemStatus(v)
                    let conflict = /*isEquipmentPiece && */v.equipmentUsage && v.equipmentUsage.conflict && !v.doNotCreateConflict ? '<span class="label label-danger" title="This equipment piece has a conflict. Please go to the equipment usage timeline to se the conflicting bookings.">conflict</span>' : ''
                    let exceeded = /*isEquipmentPiece && */v.equipmentUsage && v.equipmentUsage.exceedsGroupLimit && v.useGroupConflictCount ? '<span class="label label-exceeded" title="The fiber usage for the fiber group exceeds group limit. Please go to the equipment usage timeline to se the conflicting bookings.">exceeded</span>' : ''
                    
                    //let isPrimary = context.getIsPrimaryValue(v, isOneRecord) ? '<input type="radio" name="equipment_usage_primary" disabled checked/>':'<input type="radio" disabled name="equipment_usage_primary"/>'
                    let isNotUpdateTime = context.getIsNotUpdateTimeValue(v) ? '<span>true</span>' : '<span>false</span>'
                    const item = {
                        id: {
                            value: usageId
                        },
                        visibleId: {
                            value: visibleId
                        },
                        type: {
                            rawValue: typeRaw,
                            value: type
                        },
                        equipment: {
                            value: v.label//isEquipmentPiece ? v.label : ''
                        },
                        country: {
                            value: v.notCountryRestricted ? Equipment.getSourceLabelById(v.source, context.externalMCRsEntity) : Equipment.getSourceLabelById(v.country, context.externalMCRsEntity)//isEquipmentPiece ? v.country : ''
                        },
                        description: {
                            value: v.description,//isEquipmentPiece ? v.description : '',
                            classes: ['book-overview-item-title']
                        },
                        status: {
                            rawValue: v.equipmentStatus,//isEquipmentPiece ? v.equipmentStatus : '',
                            value: status
                        },
                        period: {
                            value: context.getPeriodValue(v)//isEquipmentPiece ? context.getPeriodValue(v) : ''
                        },
                        conflict: {
                            value: conflict
                        },
                        exceeded: {
                            value: exceeded
                        },
                        // isPrimary: {
                        //     rawValue: isEquipmentPiece ? context.getIsPrimaryValue(v, isOneRecord) : '',
                        //     value: isPrimary
                        // },
                        isNotUpdatePeriod:{
                            rawValue: context.getIsNotUpdateTimeValue(v),//isEquipmentPiece ? context.getIsNotUpdateTimeValue(v) : '',
                            value: isNotUpdateTime
                        },
                        rowOptions: {
                            classes: []
                        },
                        
                    }

                    if(v.equipmentUsage && v.equipmentUsage.id === null) {
                        item.rowOptions.hideActions = ['edit', 'delete']
                    }
                    else if (v.equipmentUsage){
                        item.rowOptions.hideActions = ['icon']
                    }

                    if (v.equipmentUsage && v.equipmentUsage.conflict && !v.doNotCreateConflict) {
                      if (this.darkMode) { item.rowOptions.classes.push('danger-dark') }
                      else item.rowOptions.classes.push('danger')
                    }
                    if (v.equipmentUsage && v.equipmentUsage.exceedsGroupLimit && v.useGroupConflictCount) {
                      if (this.darkMode) { item.rowOptions.classes.push('danger-dark') }
                      else item.rowOptions.classes.push('danger')
                    }
                    if (v.suggested) {
                        item.rowOptions.highlighted = true
                    }

                    output.rows.push(item)
                })
            return output
        },
        getEquipmentItems() {
            let selection = []
            if (!this.local) {
                selection = this.getSelection
                //console.log(this.getSelection);
            }
            else {
                // Build each equipment in the current selection.
                this.getSelection
                    .forEach(item => {
                        // If the item is an equipment piece. (??)
                        // not used section if else if stances.
                        const equipment = this.allEquipment.find(v => v.id === item.equipmentId)//this.$store.state.data.equipment.find(v => v.id === item.equipmentId)
                        if (equipment) {
                            selection.push({
                                id: item.equipmentId,
                                label: equipment.label,
                                description: equipment.description,
                                type: equipment.type,
                                equipmentStatus: equipment.equipmentStatus,
                                suggestions: equipment.suggestions,
                                elementType: 'equipmentPiece',
                                equipmentUsage: {
                                    id: item.id,
                                    periodStart: item.equipmentUsage.periodStart,
                                    periodEnd: item.equipmentUsage.periodEnd,
                                    isPrimary: item.equipmentUsage.isPrimary
                                }
                            })
                        }
                        // If the item is an equipment type.
                        // else if (!Equipment.isEquipmentPiece(item)) {
                        //     if (this.equipmentTypeEntity){
                        //         const equipmentType = this.equipmentTypeEntity.getItem(item.type)
                        //         if (equipmentType) {
                        //             selection.push({
                        //                 id: equipmentType.id,
                        //                 label: equipmentType.label,
                        //                 type: equipmentType.id,
                        //                 elementType: 'equipmentType',
                        //                 equipmentUsage: {
                        //                     id: item.id
                        //                 }
                        //             })
                        //         }
                        //     }
                        // }
                    })
            }
            return Lazy(selection)
                .sortBy(item => {
                    if (this.local){
                        return item.label
                    }
                    else
                    {
                        return item.equipmentUsage ? item.equipmentUsage.periodStart : item.equipmentId
                    }
                }, false)
                .toArray()
        },

        startDateLimit() {
            let now = moment()
            return [{
                type: 'fromto',
                from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
            }]
        },
        endDateLimit() {
            let from = moment(this.formData.periodStart.value, 'YYYY-MM-DD HH:mm')
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },
    },

    watch: {
        'formData.equipmentId.value': function(newValue, oldValue) {
            if (!this.addForm.show) return

            this.oldEquipment = oldValue

            if (this.enableCheckAvailability && this.addForm.show) {
                Vue.nextTick(() => {
                    this.checkAvailability()
                })
            }

            // Since we can save both equipment type and equipment piece, we need
            // shift the local value for equipment type field so it is sent to
            // the API on submit().
            if (newValue != '') {
                //console.log('formData.equipmentId.value: ' + newValue)
                this.formData.equipmentType.local = true
                this.setFormDefaultDates()
            }
            else {
                this.formData.equipmentType.local = false
            }

        },
        'formData.periodStart.value': function(newValue) {
            const context = this
            if (this.hideDates || !this.addForm.show) return
            //console.log('this.formData.periodEnd.value: '+this.formData.periodEnd.value)
            //console.log(Datetime.isAfter(this.formData.periodEnd.value, newValue, 'YYYY-MM-DD HH:mm'))

            if (Datetime.isAfter(this.formData.periodEnd.value, newValue, 'YYYY-MM-DD HH:mm')) {
                //console.log('New end value')
                //console.log(this.formData.periodStart.value)
                this.formData.periodEnd.value = this.formData.periodStart.value
            }
            if (this.enableCheckAvailability) {
                Vue.nextTick(() => {
                    this.checkAvailability()
                })
            }

        },
        'formData.periodEnd.value': function(newValue) {
            if (this.hideDates || !this.addForm.show) return

            // Equipment duration period for 24 hours should be checked over here
            // instead of saving to real id, ask before its too late
            let fStart = this.formData.periodStart.value
            fStart = new Date(fStart);
            let fEnd = this.formData.periodEnd.value
            fEnd = new Date(fEnd);
            let differenceTime = fEnd.getTime() - fStart.getTime();
            differenceTime = Math.floor(differenceTime / 3600000);

            if (differenceTime >= 24) {
                swal({
                    title: '24 Hours Long',
                    text: `This Equipment booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                    type: 'question',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true,
                    showCloseButton: true
                })
            }

            //console.log('this.formData.periodStart.value'+this.formData.periodStart.value)
            //console.log(Datetime.isAfter(newValue, this.formData.periodStart.value, 'YYYY-MM-DD HH:mm'))

            if (Datetime.isAfter(newValue, this.formData.periodStart.value, 'YYYY-MM-DD HH:mm')) {
                //console.log('New start value')
                //console.log(this.formData.periodStart.value)
                this.formData.periodStart.value = this.formData.periodEnd.value
            }
            if (this.enableCheckAvailability) {
                Vue.nextTick(() => {
                    this.checkAvailability()
                })
            }
            if(newValue != ''){
                this.formData.equipmentType.local = true
            }else {
                this.formData.equipmentType.local = false
            }
        },
        // 'formData.isPrimary.value': function(newValue){
        //     this.formData.isPrimary.value = newValue

        //     if (this.enableCheckAvailability) {
        //         Vue.nextTick(() => {
        //             this.checkAvailability()
        //         })
        //     }
        // },
        'formData.isNotUpdatePeriod.value': function(newValue){
            this.formData.isNotUpdatePeriod.value = newValue
            //console.log(newValue);
        },
    },
    mounted() {
        const context = this
        const dataToLoad = []
        const entities = []

        if(Object.keys(this.equipmentStatusEntityProps).length === 0) {
            const equipmentStatusEntityId = this.$settings.get.equipment.equipmentstatus_entity_id
            context.equipmentStatusEntity = new Entity(equipmentStatusEntityId)
            entities.push(equipmentStatusEntityId)
        }
        else {
            context.equipmentStatusEntity = this.equipmentStatusEntityProps
        }

        if(Object.keys(this.equipmentTypeEntityProps).length === 0 ){
            const equipmentTypeEntityId = this.$settings.get.equipment.equipmenttype_entity_id
            context.equipmentTypeEntity = new Entity(equipmentTypeEntityId)
             entities.push(equipmentTypeEntityId)
        }
        else {
            context.equipmentTypeEntity = this.equipmentTypeEntityProps
        }

        context.fiberEquipmentType = this.$settings.get.equipment.fiber_source_entityitem_id

        if(Object.keys(this.externalMCRsEntityProps).length === 0)  {
            const externalMCRsEntityId = this.$settings.get.equipment.externalmcrs_entity_id
            context.externalMCRsEntity = new Entity(externalMCRsEntityId)
            entities.push(externalMCRsEntityId)
        }
        else {
            context.externalMCRsEntity = this.externalMCRsEntityProps
        }

        if(entities.length > 0) {
            const entity = new Entity()
            dataToLoad.push(entity.all({ids: entities.join(',')}))
        }
        
        if(this.allEquipmentProps.length === 0) {
            // Load equipment pieces.
            const equipmentList = new Equipment()
            dataToLoad.push(equipmentList.all({filter: context.usageType, country: app.$data.user.get.country}))
        }
        else {
            context.allEquipment = this.allEquipmentProps
        }

        // I believe this page has so many render triggering issues,
        // need to investigate more.
        if(dataToLoad.length >= 0) {
            Promise.all(dataToLoad)
            .then(response => {
                Vue.nextTick(() => {
                    if(context.allEquipmentProps.length === 0) {
                        context.allEquipment = context.$store.state.data.equipment
                    }
                })
              context.loaded = true
            })
            .catch(error => {
                if (error.response && error.response.status != 404) {
                    context.$error.set(error, 'It was not possible to load the equipment.')
                }
                context.loaded = true
            })
        }
        else{
            context.loaded = true
        }

        if (context.local) {
            context.addForm.available = true
        }
        // No more double rendering WN (Performance Boost)
        context.loaded = true

    },
    methods: {

        /**
         * Get a new class instance, based the passed "type".
         * @param string url The
         * @param mixed id (optional) An item it to pass to the class.
         * @return class
         */
        getNewClass(type, id = null) {
            switch (type) {
                case 'equipmentPiece':
                    return new EquipmentWithUsage(this.getApiUrl, id)
                case 'equipmentType':
                    return new EquipmentTypeWithUsage(this.getApiUrlType, id)
            }
        },

        /**
         * Reset the values on add form.
         */
        resetForm() {
            this.formData = this.defaultData()
            this.addForm.equipment = null
            this.addForm.edit = false
        },

        /**
         * Get the value to be shown as equipment usage period.
         * @param object equipment
         * @return string
         */
        getPeriodValue(equipment) {
            if (!this.hideDates && equipment.equipmentUsage && equipment.equipmentUsage.periodStart && equipment.equipmentUsage.periodEnd) {
                let periodStart = moment(equipment.equipmentUsage.periodStart, 'YYYY-MM-DD HH:mm')
                let periodEnd = moment(equipment.equipmentUsage.periodEnd, 'YYYY-MM-DD HH:mm')
                return periodStart.format('HH:mm') + ' - ' + periodEnd.format('HH:mm')
            }
            return ''
        },

        /**
         * This is an intermediate step to the submit process, since we need to
         * if a equipment type has become an equipment piece and vice-versa.
         */
        submitItem() {
            // old equipment of update stage and full old equipments for updating suggestions
            this.oldEquipments = this.selection

            switch (true) {

                // An equipment piece has been changed into an equipment type.
                //case (this.addForm.equipment && (this.addForm.equipment.elementType == 'equipmentPiece') && this.formData.equipmentId.value == ''):

                // An equipment type has been changed into an equipment piece.
                // case (this.addForm.equipment && (this.addForm.equipment.elementType == 'equipmentType') && this.formData.equipmentId.value != ''):

                //     // Firstly we need to remove the current equipment piece usage.
                //     let id = this.formData.id.value
                //     let type = this.addForm.equipment.elementType

                //     if (this.local) {

                //         this.removeEquipment(id, type, false)
                //         this.formData.id.value = 0

                //         // We need a little "delay", otherwise "this.getSelection"
                //         // doesn't get updated in time.
                //         Vue.nextTick(() => {
                //             this.submit()
                //             this.$loader.remove('bookEquipmentSelectionSubmitItem')
                //         })

                //     }

                //     else {
                //         const equipmentUsage = this.getNewClass(type, id)
                //         equipmentUsage.delete()
                //             .then(response => {

                //                 // Now we can remove the equipment piece usage from
                //                 // the list and submit the equipment type usage
                //                 // as a totaly new element.
                //                 this.removeEquipment(id, type, false)
                //                 this.formData.id.value = 0
                //                 this.submit()
                //                 this.$loader.remove('bookEquipmentSelectionSubmitItem')

                //             })
                //             .catch(error => {
                //                 this.$loader.remove('bookEquipmentSelectionSubmitItem')
                //             })
                //     }

                //     break

                // The element type wasn't changed.
                default:
                    this.submit()
                    //this.$loader.remove('bookEquipmentSelectionSubmitItem')
                    break

            }

        },

        /**
         * Submit the current equipment piece/type to the API.
         */
        submit() {
            const context = this
            context.$loader.add('bookFiberEquipmentSelectionSubmit')
            let type = (context.formData.equipmentId.value != '') ? 'equipmentPiece' : 'equipmentType'
            let id = (context.local && !context.formData.id.value) ? moment().unix() : context.formData.id.value
            const equipmentUsage = this.getNewClass(type, id)
            
            console.log("submit equipment");

            Lazy(context.formData)
                .filter(v => !v.local)
                .each((v, k) => {
                    if (id && (k == 'id')) equipmentUsage.set('id', id)
                    else equipmentUsage.set(k, v.value)
                })
            if (context.local) {
                const item = {
                    id: equipmentUsage.get.id,
                    equipmentId: equipmentUsage.get.equipmentId,
                    equipmentType: equipmentUsage.get.equipmentType,
                    type: equipmentUsage.get.equipmentType ? equipmentUsage.get.equipmentType : context.formData.equipmentType.value,
                    elementType: type,
                    equipmentUsage: {}
                }
                if (!context.hideDates) {
                    item.equipmentUsage.periodStart = equipmentUsage.get.periodStart
                    item.equipmentUsage.periodEnd = equipmentUsage.get.periodEnd
                }
                
                item.equipmentUsage.isPrimary = equipmentUsage.get.isPrimary
                item.equipmentUsage.isNotUpdatePeriod = equipmentUsage.get.isNotUpdatePeriod

                context.updateEquipment(item)
                context.showForm(false)
                context.$loader.remove('bookFiberEquipmentSelectionSubmit')
            }
            else {
                equipmentUsage.isPrimary = equipmentUsage.get.isPrimary
                equipmentUsage.isNotUpdatePeriod = equipmentUsage.get.isNotUpdatePeriod
                if (!equipmentUsage.options.id) {
                    equipmentUsage.store()
                        .then(response => {
                            context.updateEquipment(response.data, false, true)
                            context.showForm(false)
                            context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                            if (response.data.suggestions && response.data.suggestions.length > 0) {
                                swal({
                                    title: 'Add suggestions?',
                                    text: `Do you want to add equipment related to ${response.data.label}?`,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true
                                })
                                    .then(() => {
                                        context.submitSuggestions(response.data)
                                    })
                            } else {
                                if (equipmentUsage.isPrimary) window.location.reload(true);
                            }
                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to save the equipment.')
                            context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                        })
                }
                else {

                    equipmentUsage.save()
                        .then(response => {
                            context.updateEquipment(response.data, false, true)
                            // suggest equipment for update as well
                            if (response.data.suggestions && response.data.suggestions.length > 0 && context.oldEquipment !== null) {
                                setTimeout(() => {
                                    context.showForm(false)
                                    context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                                    swal({
                                        title: 'Add/Update suggestions?',
                                        text: `Do you want to add equipment related to ${response.data.label}?`,
                                        type: 'question',
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'No',
                                        showCancelButton: true,
                                    })
                                        .then(() => {
                                            context.submitSuggestions(response.data)
                                        })
                                }, 2000)
                            } else {
                                context.showForm(false)
                                context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                                if (equipmentUsage.isPrimary) window.location.reload(true);
                            }

                        })
                        .catch(error => {
                            context.$error.set(error, 'It was not possible to save the equipment.')
                            context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                        })
                }
            }
            
        },
        /**
         * Add all the suggestions, starting from the passed equipment, to the
         * current equipment list.
         * @param object equipment
         * @return promise
         */
        submitSuggestions(equipment) {
            const context = this
            context.$loader.add('bookFiberEquipmentSelectionSubmit')

            let suggestionId
            let _label
            // check first if its already added?
            suggestionId = equipment.suggestions
            if (Array.isArray(suggestionId) || typeof suggestionId === 'object') {
                Lazy(suggestionId).each(v1 => {
                    suggestionId = v1.id
                    _label = v1.label
                })
            }
            Lazy(this.getSelection).each(v2 => {
                if (!v2.equipmentId) {
                    if (this.$route.name === 'equipment.usage.view') {
                        if (v2.id === suggestionId && v2.equipmentUsage.usageId === equipment.equipmentUsage.usageId) {
                            suggestionId = 'added'
                        }
                    }
                    else {
                        if (v2.id === suggestionId) {
                            suggestionId = 'added'
                        }
                    }
                }
                if (v2.equipmentId) {
                    if (this.$route.name === 'equipment.usage.view') {
                        if (v2.equipmentId === suggestionId && v2.usageId === equipment.equipmentUsage.usageId) {
                            suggestionId = 'added'
                        }
                    }
                    else {
                        if (v2.equipmentId === suggestionId) {
                            suggestionId = 'added'
                        }
                    }
                }
            })

            console.log(equipment.suggestions, 'ilginc', suggestionId, this.getSelection)

            if (suggestionId === 'added') {
                this.$alert.set(`No equipment has been added, probably because the suggested equipment ( <b>${_label}</b> )  are already added.`, 'danger', 10)
                context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                return
            }

            return new Promise((resolve, reject) => {
                const params = {
                    id: equipment.equipmentUsage.id,
                }
                axios.post(context.getApiUrl + '/suggestions', params)
                    .then(response => {
                        if (response.status != 200) return
                        if (response.data.length < 1) {
                            this.$alert.set('No equipment has been added, probably because the suggested equipment are already added by you or they aren\'t available anymore.', 'danger', 10)
                        } else {
                            response.data.forEach(v => {
                                v.suggested = true
                            })

                            if (this.$route.name !== 'equipment.usage.view') {
                                context.updateEquipment(response.data, false, true)
                            }

                            this.$alert.set(response.data.length + ' equipment related to ' + equipment.label + ' has/have been added and highlighted.', 'success', 5)
                            console.log(context.$route, 'route?')
                        }
                        context.$loader.remove('bookFiberEquipmentSelectionSubmit')

                       Vue.nextTick(async ()=> {
                            await this.removeOldSuggestion(context.oldEquipment, context.oldEquipments, equipment, response.data)
                        })
                    })
                    .catch(error => {
                        context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                        reject(error)
                    })
            })

        },
        removeOldSuggestion(equipmentId, equipments, usage, equipment) {
            const context = this
            let suggestionId
            let localId
            let id
            let type

            console.log(usage, 'hmm', equipments);

            Lazy(equipments)
                .each(v => {
                    if (!v.equipmentId) {
                        console.log('we are here 1')
                        if (v.id === equipmentId) {
                            suggestionId = v.suggestions

                            if (Array.isArray(v.suggestions) || typeof v.suggestions === 'object') {
                                Lazy(v.suggestions).each(v1 => {
                                    suggestionId = v1.id
                                })
                            }

                            Lazy(equipments).each(v2 => {
                                if (context.$route.name === 'equipment.usage.view') {
                                    if (v2.id === suggestionId && v2.equipmentUsage.usageId === usage.equipmentUsage.usageId) {
                                        console.log(v2, 'equipment page uses')
                                        localId = v2.equipmentUsage.id
                                        type = v2.elementType
                                    }
                                }
                                else {
                                    if (v2.id === suggestionId) {
                                        console.log(v2, 'other pages')
                                        localId = v2.equipmentUsage.id
                                        type = v2.elementType
                                    }
                                }
                            })
                        }
                    }
                    if (v.equipmentId) {
                        console.log('we are here 2')
                        if (v.equipmentId === equipmentId) {
                            suggestionId = v.equipment.suggestions

                            if (Array.isArray(suggestionId) || typeof suggestionId === 'object') {
                                Lazy(suggestionId).each(v1 => {
                                    suggestionId = v1.id
                                })
                            }
                            Lazy(equipments).each(v2 => {
                                if (context.$route.name === 'equipment.usage.view') {
                                    if (v2.equipment.id === suggestionId && v2.usageId === usage.equipmentUsage.usageId) {
                                        localId = v2.id
                                        type = v2.elementType === 'period' ? 'equipmentPiece' : 'equipmentType'
                                    }
                                }
                                else {
                                    if (v2.equipment.id === suggestionId) {
                                        localId = v2.id
                                        type = v2.elementType === 'period' ? 'equipmentPiece' : 'equipmentType'
                                    }
                                }
                            })
                        }
                    }
                })

            id = localId
            const equipmentUsage = context.getNewClass(type, id)
            if (localId) {
                //return
                equipmentUsage.delete()
                    .then(response => {
                        console.log(suggestionId, localId, 'SUCCESSED', equipments)
                        context.removeEquipment(id, type, false)
                        context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to remove the equipment.')
                        context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                    })

            } else {
                console.log(suggestionId, localId, 'FAILED', equipments, usage)
                context.updateEquipment(equipment, false, true)
                return false
            }


        },

        /**
         * Edit an equipment item.
         * @param integer id The id of the equipment item to edit.
         */
        editItem(id) {
            const context = this

            // If the "id" is a string, then it contains the type in it and we
            // need extract it.
            let value = Equipment.getItemIdAndType(id)
            id = value.id
            let type = value.type

            context.addForm.edit = true
            context.addForm.equipment = context.getEquipmentItems
                .filter(v => {
                    return v.elementType === type
                }).find(v => {
                    if (v.equipmentUsage)
                        return v.equipmentUsage.id === id
                    // else if (context.isForEquipmentUsagePage)
                    //     return v.id === id
                    else
                        return false
                })

            if (!context.addForm.equipment) return
            // Populate the fields based on elementType.
            context.formData.id.value = id
            //if (Equipment.isEquipmentPiece(context.addForm.equipment) /*|| context.isForEquipmentUsagePage*/) {
                context.formData.equipmentType.local = true
                context.formData.equipmentType.value = context.addForm.equipment.type ? context.addForm.equipment.type :context.addForm.equipment.equipmentType
                context.formData.equipmentId.value = /*context.isForEquipmentUsagePage ? context.addForm.equipment.equipmentId :*/ context.addForm.equipment.id
                if (!context.hideDates) {
                    //console.log(context, 'hmmm')
                    context.formData.periodStart.value = context.addForm.equipment.equipmentUsage ? context.addForm.equipment.equipmentUsage.periodStart : context.addForm.equipment.periodStart
                    context.formData.periodEnd.value = context.addForm.equipment.equipmentUsage ? context.addForm.equipment.equipmentUsage.periodEnd : context.addForm.equipment.periodEnd
                }
                if (context.addForm.equipment.equipmentUsage)
                    context.formData.isNotUpdatePeriod.value = context.addForm.equipment.equipmentUsage.isNotUpdatePeriod
            //}
            // else {
            //     context.formData.equipmentType.local = false
            //     context.formData.equipmentType.value = context.addForm.equipment.id
            //     // if (context.isOneRecord && (context.formData.equipmentType.value == 64 || context.formData.equipmentType.value == 65)){
            //     //     context.formData.isPrimary.value = true;
            //     // }
            // }
            
            context.showForm(true)
        },

        /**
         * Delete an equipment item, removing the usage (connection) to EquipmentHelpeonso
         * current object in the database.
         * @param integer id The id of the equipment item to remove.
         */
        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: 'Are you sure you want to remove this equipment?',
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
                .then(function() {
                    context.$loader.add('bookFiberEquipmentSelectionSubmit')
                    if (context.local) {
                        let value = Equipment.getItemIdAndType(id)
                        id = value.id
                        let type = value.type
                        context.removeEquipment(id, type, false)
                        context.$alert.set('The item was removed.', 'success', 4)
                        context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                    }
                    else {
                        let value = Equipment.getItemIdAndType(id)
                        id = value.id
                        let type = "";
                        if (value.type == "period"){
                            type = "equipmentPiece";
                                                    }else{
                                                        type = value.type
                                                    }
                        
                        
                        
                        const equipmentUsage = context.getNewClass(type, id)
                        equipmentUsage.delete()
                            .then(response => {
                                context.removeEquipment(id, type, false)
                                context.$alert.set('The item was removed.', 'success', 4)
                                context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                            })
                            .catch(error => {
                                context.$error.set(error, 'It was not possible to remove the equipment.')
                                context.$loader.remove('bookFiberEquipmentSelectionSubmit')
                            })
                    }
                })
        },

        /**
         * Open/close the add/edit form for Equipment Usage.
         * @param boolean open Tells the function to open/close the form.
         */
        showForm(open = true) {
            if (!open) {
                this.resetForm()
                this.addForm.show = false
            }
            else {
                if (!this.formData.id.value) {
                    this.setFormDefaultDates()

                    // If only one equipment type is available, select that
                    // element by default.
                    //if (this.equipmentTypesList.length == 1) {
                        this.formData.equipmentType.value = this.fiberEquipmentType//this.equipmentTypesList[0].id                        
                    //}
                }
                Vue.nextTick(() => {
                    this.addForm.show = true
                })
            }
        },

        /**
         * Set the dates that should be default on the add/edit form.
         */
        setFormDefaultDates() {
            if (this.dates.from) this.formData.periodStart.value = moment(this.dates.from).format('YYYY-MM-DD HH:mm')
            if (this.dates.to) this.formData.periodEnd.value = moment(this.dates.to).format('YYYY-MM-DD HH:mm')
        },

        /**
         * Update the equipment in the current feed/production.
         * @param items object items The equipment to add/update.
         * @param notify boolean notify (optional) Show a notification/alert about the update.
         * @param page boolean to the page or bg.
         */
        updateEquipment(items, notify = true, page = true) {
            const equipment = this.getSelection.slice(0)
            if (!Array.isArray(items)) {
                items = [items]
            }

            items.forEach(item => {

                this.parseItem(item)
                let i = -1

                //if (Equipment.isEquipmentPiece(item)) {

                    // We should check for a "local" equipment, which means we
                    // are in a template context.
                    if (this.local) {
                        i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.id == item.id)
                    }

                    // We are in a "normal" context and need to search for
                    // equipment usage.
                    else {
                        i = equipment.findIndex(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
                    }
                //}

                // We search for an equipment type (not a real equipment piece).
                // else {
                //     if (this.local) {
                //         i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.id == item.id)
                //     }
                //     else {
                //         i = equipment.findIndex(v => !Equipment.isEquipmentPiece(v) && v.equipmentUsage.id == item.equipmentUsage.id)
                //     }
                // }

                if (i > -1) equipment.splice(i, 1)
                equipment.push(item)

            })
            // precaution for Equipment usage page
            if (page) {
                this.$emit('changedFiber', equipment, notify)
            }
        },

        /**
         * Remove an equipment from the current feed/production.
         * @param integer id The id of the equipment usage.
         * @param string type The type of the equipment usage.
         * @param boolean notify (optional) Show a notification/alert about the update.
         */
        removeEquipment(id, type, notify = true) {
            const equipment = this.getSelection.slice(0)
            let i = -1

            // We should check for a "local" equipment, which means we
            // are in a template context.
            if (this.local) {
                i = equipment.findIndex(v => (v.elementType == type) && v.id == id)
            }

            // We are in a "normal" context and need to search for a real
            // equipment usage.
            else {
                i = equipment.findIndex(v => (v.elementType == type) && (v.equipmentUsage.id == id))
            }

            if (i > -1) { equipment.splice(i, 1) }
            this.$emit('changedFiber', equipment, notify)
            
        },

        removeEquipments(equipments, suggestions) {
            const equipment = this.getSelection.slice(0)

            let i = -1
            equipment.forEach((v,k) => {
                i = k
                if (_.includes(equipments, v.equipmentUsage.id)) {
                    console.log(v.equipmentUsage.id, 'hmm??')
                    if (i > -1) equipment.splice(i, 1)
                }
            })

            console.log(equipment, 'removed them', equipments)
            this.$emit('changedFiber', equipment, false)
            
        },

        /**
         * Check if the passed equipment is already chosen in equipmentList.
         * @param object equipment
         * @return boolean
         */
        isChosen(equipment) {

            // An equipment that is going to be edited should be visible in the
            // dropdown, even though it is present in the equipment list.
            if (this.addForm.edit && (this.formData.equipmentId.value == equipment.id)) {
                return false
            }

            let i = this.equipmentData.rows.findIndex(v => v.id.value == equipment.id)
            return i > -1
        },

        /**
         * Check if a given equipment is available for a given date.
         * @return promise
         */
        checkAvailability() {
            const context = this
            return new Promise((resolve, reject) => {
                if (!context.formData.equipmentId.value || context.checking) {
                    resolve()
                    return
                }
                context.$loader.add('bookFiberEquipmentSelectionChecking')
                context.equipmentUsage = []
                context.addForm.available = false
                const params = {
                    start: context.formData.periodStart.value,
                    end: context.formData.periodEnd.value
                }
                // When checking if an equipment is available, we look for any
                // usage entry and if the API returns anything (code 200), then
                // it means the equipment piece is already taken/busy.
                // If 404 is returned, nothing was found, which means the
                // equipment is available.
                axios.get(context.getApiUrl + '/' + context.formData.equipmentId.value, {params: params})
                    .then(response => {
                        if(response.data.hasOwnProperty('exceedsGroupLimit')) {
                            context.exceededFlag = true
                            context.exceededGroup = response.data.groupName
                            context.exceedsGroupLimit = response.data.groupLimit
                        }
                        else {
                            context.addForm.available = !response.data.some(v => v.equipmentUsage.id != context.formData.id.value)

                            // Create the list of conflicts to present in the error message.
                            if (!context.addForm.available)  {
                                Lazy(response.data)
                                    .filter(v => {
                                        return v.equipmentUsage.id != context.formData.id.value && !v.doNotCreateConflict //doNotCreateConflict is overkill but, without risk.
                                    })
                                    .each(v => {
                                        context.equipmentUsage.push({
                                            type: v.equipmentUsage.usageType,
                                            id: v.equipmentUsage.usageItem ? v.equipmentUsage.usageItem.id : '',
                                            title: v.equipmentUsage.usageItem.title,
                                        })
                                    })
                            }   
                        }
                        
                        context.$loader.remove('bookFiberEquipmentSelectionChecking')
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response && error.response.status != 404) context.$error.set(error)
                        context.addForm.available = true
                        context.exceededFlag = false
                        context.exceededGroup = ''
                        context.exceedsGroupLimit = ''
                        context.$loader.remove('bookFiberEquipmentSelectionChecking')
                        reject(error)
                    })

            })
        },

        /**
         * Check if the passed item(equipment piece) is added to a template or not.
         * @param object item
         * @return boolean
         */
        isFromTemplate(item) {
            return item.hasOwnProperty('equipmentId')
        },

        /**
         * Get the "item usage id" that should be used as unique id in the table.
         * @param object item
         * @return integer
         */
        getItemUsageId(item) {
            if (this.isFromTemplate(item)) return item.id
            return Equipment.isEquipmentPiece(item) ? `equipmentPiece#${item.equipmentUsage.id}` : `equipmentType#${item.equipmentUsage.id}`
        },

        /**
         * Get the "item id" that should be presented in the table.
         * @param object item
         * @return integer
         */
        getItemVisibleId(item) {
            if (this.local) {
                return item.id
            }
            else {
                if (this.isFromTemplate(item)) {
                    if (Equipment.isEquipmentPiece(item)) {
                        return item.equipmentId
                    }
                    else {
                        return item.equipmentType
                    }
                }
                return Equipment.isEquipmentPiece(item) ? item.id : item.equipmentUsage.id
            }
        },

        /**
         * Get the status of the given item to be presented in the table.
         * @param object item
         * @return string
         */
        getItemStatus(item) {
            if (this.equipmentStatusEntity){
                const status = this.equipmentStatusEntity.getItem(item.equipmentStatus)
                return status ? status.label : null
            }
            return null
        },

        
        /**
         * Get the IsPrimary field value of the given item to be presented in the table
         * @param object item
         * @return bool
         */
        // getIsPrimaryValue(item, isOneRecord){
        //     //if this is the new equipment
        //     //console.log(item);
        //     if (isOneRecord && (item.type == 64 || item.type == 65)) {
        //         this.formData.isPrimary.value = true;
        //         return true;}

        //     return item && item.equipmentUsage ? item.equipmentUsage.isPrimary : false;
        // },
        
        getIsNotUpdateTimeValue(item){
            //console.log("is not update time");
            return item && item.equipmentUsage ? item.equipmentUsage.isNotUpdatePeriod : true;
        },

        /**
         * Parse an equipment piece/type to ensure they have all required fields.
         * @param object item
         */
        parseItem(item) {
            if (!item.hasOwnProperty('elementType')) {
                let type = Equipment.isEquipmentPiece(item) ? 'equipmentPiece' : 'equipmentType'
                Vue.set(item, 'elementType', type)
            }
        },

        /**
         * Get "RouterLink" object to build the link to a item usage.
         * @param object item
         * @return object
         */
        getConflictUsageItemLink(item) {
            return {name: `${item.type}.edit`, params: {id: item.id}}
        },

        /**
         * Check if a given column should be visible or not based on the current
         * settings.
         * @param string column
         * @return boolean
         */
        columnIsVisible(column) {
            if (column == "isPrimary" /*&& !this.isEngOrEdit*/) return false;
            //if (column == "isNotUpdatePeriod" && this.usageType != "feed")
            //    return false;
            if(this.columns.length == 0 && (column === 'type' || column === 'period' || column == "isNotUpdatePeriod")) return false;
            
            return this.columns.length == 0 || (this.columns.length > 0 && this.columns.indexOf(column) > -1)
        },

         iconIsVisible() {
            return this.getEquipmentItems.filter(v => Equipment.isEquipmentPiece(v) && v.equipmentUsage && !v.equipmentUsage.id).length > 0
        },

        defaultData() {
            return {
                id: {
                    value: 0
                },
                equipmentType: {
                    local: false,
                    value: ''
                },
                equipmentId: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                periodStart: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                periodEnd: {
                    value: '',
                    validator: {
                        rules: {
                            required: true,
                            date_format: 'YYYY-MM-DD HH:mm'
                        }
                    }
                },
                isPrimary: {
                    value: false,
                    validator: {
                        rules: 'required'
                    }
                },
                isNotUpdatePeriod: {
                    value: false,
                    validator: {
                        rules: 'required'
                    }
                }
            }
        }
    }
}

</script>

<template>
<div class="book-equipment-selection">
    <button
            class="btn btn-primary btn-xs book-button-add-equipment"
            style="margin-right:55px;"
            @click="showAll = !showAll"
            :disabled="disabled"
            v-if="isDisplayAddButton">
        <span v-if="!showAll"><font-awesome-icon icon="plus"/>&nbsp;Show All</span>
        <span v-else><font-awesome-icon icon="minus"/>&nbsp;Close Other</span>
    </button>
    <button
        class="btn btn-success btn-xs book-button-add-equipment"
        @click="showForm(true)"
        :disabled="disabled"
        v-if="!print && $ability.can('create', 'Equipment') && isDisplayAddButton">
        <font-awesome-icon icon="plus"/>&nbsp;Add
    </button>
    <book-loader :small="true" v-if="!loaded"></book-loader>
    <book-table  v-else
                 :darkMode="darkMode"
                 :component="'fiberEquipment'"
                 :items="equipmentData"
                 :print="print"
                 :notFoundMessage="!disabled || !disabledMessage ? 'No fiber equipment has been added' : ''"
                 :itemsType="itemsType"
                 :needRender="showAll"
                 @edit="item => editItem(item.id.value)"
                 @delete="item => deleteItem(item.id.value)">
    </book-table>
    <div class="book-box-overlay" v-if="disabled && loaded /*&& !isForEquipmentUsagePage*/">
        <span class="label label-danger" v-html="disabledMessage"></span>
    </div>
    <div class="book-box-overlay" v-if="processing">
        <book-loader :small="true"></book-loader>
    </div>
    <!-- <div class="book-box-overlay" v-if="isForEquipmentUsagePage">
                <div class="book-sidebar-row">
                    <button type="button" aria-label="Accept" class="book-action-button book-edit btn-primary btn-sm" v-on:click="editItem(selectedItem)">
                        <font-awesome-icon icon="pen"/><span> Edit</span>
                    </button>
                    <button type="button" aria-label="Reject" class="book-action-button book-edit btn-danger btn-sm" v-on:click="deleteItem(selectedItem)">
                        <font-awesome-icon icon="trash"/><span> Delete</span>
                    </button>                                
                </div>
    </div> -->
    <book-modal :class="{'dark-equipment-modal': darkMode}" maxWidth="400px" @close="showForm(false)" v-if="this.addForm.show">
        <h4 slot="header" class="modal-title">
           <template v-if="!addForm.edit">Add</template>
           <template v-if="addForm.edit">Edit</template>
        </h4>
        <div slot="body">
            <div class="form-horizontal">
                <!-- <div class="form-group">
                    <label for="name" class="control-label book-width-100">Type</label>
                    <div class="form-control-100">
                        <select class="form-control" name="equipment_type" v-model="formData.equipmentType.value" v-if="equipmentTypeEntity" :disabled="equipmentList.length < 1 /*|| this.isForEquipmentUsagePage */">
                            <option value="" v-if="equipmentTypesList.length > 1">None</option>
                            <option v-for="type in equipmentTypesList" :value="type.id">{{type.label}}</option>
                        </select>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Equipment</label>
                    <div class="form-control-100">
                      <select class="form-control" name="equipment" v-model="formData.equipmentId.value" :disabled="equipmentIsDisabled">
                          <option value="">None</option>
                          <option v-for="equipment in equipmentList" :value="equipment.id" v-if="/*equipment.type == formData.equipmentType.value &&*/ !isChosen(equipment)">{{equipment.label}}</option>
                      </select>
                    </div>
                </div>
                <div class="form-group" v-if="!hideDates && (formData.equipmentId.value != '')">
                    <label class="control-label book-width-100">From</label>
                    <book-datetime
                        class="form-control-100"
                        component-id="from"
                        :required="{date: true, time: true}"
                        :default-value="formData.periodStart.value"
                        :limit="startDateLimit"
                        @newValue="value => formData.periodStart.value = value">
                    </book-datetime>
                </div>
                <div class="form-group" v-if="!hideDates && (formData.equipmentId.value != '')">
                    <label class="control-label book-width-100">To</label>
                    <book-datetime
                        class="form-control-100"
                        component-id="to"
                        :required="{date: true, time: true}"
                        :default-value="formData.periodEnd.value"
                        :limit="endDateLimit"
                        @newValue="value => formData.periodEnd.value = value">
                    </book-datetime>
                </div>
                <!-- <div class="form-group" v-if="isEngOrEdit && ((formData.equipmentType.value == 65 && isEngProduction) || (formData.equipmentType.value == 64 && isEditProduction))">
                    <label class="control-label book-width-100">Is Primary</label>
                    <input type="checkbox" v-model="formData.isPrimary.value" :checked="formData.isPrimary.value" :disabled="!isAllowedToSetPrimary || formData.isPrimary.value"/>
                    
                </div> -->
                <div class="form-group" v-if="usageType=='feed'">
                    <label class="control-label book-width-200">Don't Update Period</label>
                    <input type="checkbox" v-model="formData.isNotUpdatePeriod.value" :checked="formData.isNotUpdatePeriod.value" />
                    
                </div>
                <!-- <div class="book-help-message bg-danger text-danger" v-if="!hasEquipment && formData.equipmentType.value">
                    No equipment piece available for this type
                </div> -->
                <div class="book-help-message bg-danger text-danger" v-else-if="equipmentList.length < 1">
                    No fiber equipment piece available for your country
                </div>
                <div class="book-help-message book-help-message-conflict bg-danger text-danger" v-if="isFormValid && isFormChanged && !checking && !addForm.available && formData.equipmentId.value && equipmentUsage.length > 0">
                    <div>This fiber equipment piece is booked on following:</div>
                    <ul class="book-equipment-selection-equipmentusage-list">
                        <li v-for="item in equipmentUsage">
                            <router-link target="_blank" :to="getConflictUsageItemLink(item)">{{item.type.capitalize()}} - {{item.title}} ({{item.id}})</router-link>
                        </li>
                    </ul>
                    <div>However you can double book it, but it will be flagged with "conflict".</div>
                </div>
                <div class="book-help-message book-help-message-conflict bg-danger text-danger" v-if="isFormValid && isFormChanged && !checking && !addForm.available && formData.equipmentId.value && exceededFlag">
                    <div>The fiber usage for the group "{{exceededGroup}}" exceeds the group limit {{exceededGroupLimit}}.</div>
                    <div>However you can book it, but it will be flagged with "exceeded".</div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <book-loader :small="true" v-if="processing || checking"></book-loader>
            <button class="btn btn-success" @click="submitItem()" :disabled="checking || !isFormValid || !isFormChanged">Save</button>
        </div>
    </book-modal>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-equipment-wrapper {
    .panel-body {
        position: relative;
    }
    .book-button-add-equipment {
        position: absolute;
        right: 0;
        top: -27px;
        z-index: 10;
    }
    .panel-heading ~ .book-equipment-selection {
        .book-button-add-equipment {
            right: 3px;
            top: -25px;
        }
    }
}
.book-add-equipment-wrapper {
    border-bottom: 1px solid #DDDDDD;
    text-align: right;
    > *:not(.help-block) {
        @include breakpoint(min-width $min-tablet) {
            display: inline-block;
        }
    }
    .form-group {
        margin: 10px;
        > * {
            display: inline-block;
        }
        label {
            margin-right: 5px;
            width: calc(30% - 10px);
        }
        .form-control {
            width: 70%;
        }
        label,
        .form-control {
            @include breakpoint(min-width $min-tablet) {
                width: auto;
            }
        }
    }
}
.book-equipment-selection {
    min-height: 40px;
    position: relative;
    .modal-footer {
        .book-loader {
          float: left;
          margin-left: 5px;
          margin-top: 5px;
        }
    }
    .book-table {
        border: none;
        margin-bottom: 0;
    }
    .table {
        margin-bottom: 0;
    }
    > .book-loader {
        padding: 9px 0;
    }
    .book-help-message-conflict {
        line-height: 1.4em;
        text-align: left;
    }

    
}
.panel-body > .book-equipment-selection {
    margin: -15px;
}
.book-compact-panels .panel-body > .book-equipment-selection {
    margin: -8px;
}
.swal2-container {
    z-index: 10000 !important;
}
</style>