import Api from 'components/app/Api'
import {app} from 'app'
class EntityItem extends Api {

    /**
     * Constructor.
     * @param integer id The id to create an instance with.
     */
    constructor(id = null) {
        super({
            id: id,
            apiUrl: 'api/entityitem',
            module: 'entityitem',
        })
    }

    /**
     * Determine if an entity item should be visible or not based on the
     * current user's country.
     * @param object item
     * @return boolean
     */
    static isVisible(item, has_multiple_defaults = 'No', selected = false) {

        if (selected) return true
        if(has_multiple_defaults === 'Yes') return true
        // item.country = item.country ? true : false
        return !item.country || (item.country == app.$data.user.get.country)
    }

}
export default EntityItem
