
import {app} from 'app'
import BookFilter from 'components/common/filter/BookFilter'
import BookLoader from 'components/common/loader/BookLoader'
import BookSidebar from 'components/common/BookSidebar'
import BookTable from 'components/common/BookTable'
import User from 'components/user/User'
import Entity from 'components/entity/Entity'
import System from 'components/system/System'
import Item from 'components/common/item/Item'
import BookNotification from 'components/notification/BookNotification'

export default {
    components: {
        BookFilter,
        BookLoader,
        BookSidebar,
        BookTable,
        BookNotification
    },
    mounted() {
        this.$loader.add('contactsLoad')

        // Load the users.
        const users = User.getUsers({fields: 'skills'})

        // Load the skills.
        this.skillsEntity = new Entity(this.setting.skill_entity_id)
        const skills = this.skillsEntity.load(this.setting.skill_entity_id)

        Promise.all([users, skills])
            .then(response => {
                this.createFilters()
                this.$loader.remove('contactsLoad')
            })
            .catch(error => {
                this.$error.set(error)
                this.$loader.remove('contactsLoad')
            })
    },
    data() {
        return {
            selectedItem: null,
            skillsEntity: null,
            filterReady: false,
            filters: {},
            availableFilters: {
                search: {
                    label: 'Search',
                    type: 'text'
                },
                skills: {
                    label: 'Skills',
                    items: [],
                    class: 'skillsEntity'
                },
                country: {
                    label: 'Countries',
                    items: []
                },
            },
            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
            },
        }
    },
    computed: {
        setting() {
            return this.$settings.get.profiles
        },
        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },
        system() {
            return System
        },
        loaded() {
            return this.users && this.skillsEntity && !this.$loader.has('contactsLoad')
        },
        users() {
            if (!this.$store.state.user.users) return []
            return Lazy(this.$store.state.user.users)
                .filter(v => {
                    return Item.isFiltered(this.filters, v, {skills: this.skillsEntity})
                })
                .sortBy(row => row.fullName.toLowerCase(), false)
                .toArray()
        },
        hasPreservedFilters() {
            return this.$store.state.filter.preserve ? true : false
        },
        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this.users.length
            filterInfo.total = this.itemsTotal
            return filterInfo
        },
        itemsTotal() {
            if (!this.loaded || !this.$store.state.user.users || !this.filterReady) return 0
            return this.$store.state.user.users.length
        },
        contactsListData() {
            const output = {}

            // Build options.
            output.options = {
                actions: ['preview'],
                actionSettings: {
                    edit: {
                        label: ' Open',
                        classes: ['btn', 'btn-primary', 'btn-xs']
                    }
                },
            }
            // calendar button for contacts page WN
            if (this.$ability.can('view_crew_calendar', 'Productions')) {
                output.options.actions.unshift('calendar')
                output.options.actionSettings.calendar = {
                    label: '',
                    classes: ['btn', 'btn-warning', 'btn-xs']
                }
            }
            if (this.$ability.can('update', 'Users')) {
                output.options.actions.push('edit')
            }

            // Build the columns.
            output.columns = [
                {
                    value: 'id',
                    label: 'ID',
                    visible: false,
                },
                {
                    value: 'name',
                    label: 'Name',
                    sortable: true,
                    searchable: false
                },
                {
                    value: 'email',
                    label: 'E-mail',
                    visible: false,
                },
                {
                    value: 'phone',
                    label: 'Phone',
                    visible: false,
                },
                {
                    value: 'skills',
                    label: 'Skills',
                    visible: false,
                },
                {
                    value: 'country',
                    label: 'Country',
                    sortable: true,
                    searchable: false,
                    style: {
                        'width': '15%'
                    }
                },
            ]

            output.rows = []
            this.users.forEach(v => {
                const row = {
                    id: {
                        value: v.id,
                    },
                    name: {
                        value: v.fullName,
                    },
                    email: {
                        value: v.email,
                    },
                    phone: {
                        value: v.phone
                    },
                    skills: {
                        value: v.skills ? v.skills.join(' | ') : ''
                    },
                    country: {
                        rawValue: v.country,
                        value: v.country ? System.getCountryById(v.country) : '',
                    },
                }
                output.rows.push(row)
            })

            return output
        },
    },

    methods: {

        /**
         * Create the filter items.
         */
        createFilters() {
            if (!this.availableFilters) return
            Lazy(this.availableFilters)
                .each((v, k) => {
                    switch (true) {
                        case (v.hasOwnProperty('class')):
                            if (this[v.class].get.items){
                                this[v.class].get.items.forEach(v2 => {
                                    v.items.push({
                                        id: v2.id,
                                        label: v2.label
                                    })
                                })
                            } 
                        
                            break
                        case (k == 'country'):
                            this.countries.forEach(v2 => {
                                v.items.push({
                                    id: v2.code,
                                    label: v2.label
                                })
                            })
                            break
                    }
                })

            // Set default selected country based on the current user's country.
            if (app.$data.user.get.country && this.$store.state.filter.myFiltersSelected === '') {
                if (this.availableFilters.hasOwnProperty('country') && this.availableFilters.country.hasOwnProperty('items')) {
                    Lazy(this.availableFilters.country.items)
                        .filter(v => {
                            return v.id == app.$data.user.get.country
                        })
                        .each(v => {
                            Vue.set(v, 'selected', true)
                        })
                }
            }

            this.filterReady = true
        },

        /**
         * Set a filter value.
         * @param object filter
         */
        filteredBy(filters) {
            this.filters = filters
        },

        /**
         * Select an item and show it in the sidebar.
         * @param integer id The item id.
         */
        selectItem(id) {
            if (this.selectedItem && (id == this.selectedItem.id)) this.selectedItem = null
            else this.selectedItem = this.users.find(v => v.id == id)
        },

        /**
         * Get the label of the given skill.
         * @param integer skillId
         * @return string
         */
        getSkillName(skillId) {
            if (!this.skillsEntity) return ''
            const skill = this.skillsEntity.getItem(skillId)
            if (!skill) return ''
            return skill.label
        },

        /**
         * Get a comma separated list of skill names, based on an array of
         * skill ids.
         * @param array skills
         * @return string
         */
        getSkills(skills) {
            const output = []
            skills.forEach(v => {
                output.push(this.getSkillName(v))
            })
            return output.join(', ')
        },
        /**
         * Shows the calendar of the crew member
         * @return new-tab
         * */
        showCalendar(userId) {
            let context = this
            var link_ = {
                view: context.$route.params.view,
                crewId: userId
            }

            let routeData = context.$router.resolve({name: `calendar.view`, query: link_});
            window.open(routeData.href, '_blank');
        },

        showNotificationModal(user) {
            this.notification.recipients = []

            this.notification.recipients.push({
                status: user.status,
                email: user.email,
                phone: user.phone,
                id: user.id,
                label: user.name,
            })

            this.notification.modalTitle = `Contact ${user.name}`

            this.notification.show = open
        },

    }

}
