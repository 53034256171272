import Api from 'components/app/Api'
class Association extends Api {

  /**
   * Constructor.
   * @param object data The data to create an instance with.
   */
  constructor(apiUrl, id = null) {
      super({
          id: id,
          apiUrl: apiUrl,
          module: 'association',
      })
  }

}
export default Association
