// -----------------------------------------------------------------------------
// Dependencies

import {app} from 'app'
import router from 'router'
import store from 'store'
import axios from 'axios'
import User from 'components/user/User'
import System from 'components/system/System'
import Auth from 'components/auth/Auth'
import errorHandler from 'components/system/ErrorHandler'

// -----------------------------------------------------------------------------
// Router logic.

router.beforeEach((to, from, next) => {

    // Keep the data parameters (from and to) in the URL.
    switch (true) {
        case (from.query.from && !to.query.from):
        case (from.query.to && !to.query.to):
            const query = Object.assign({}, to.query)
            query.from = from.query.from ? from.query.from : ''
            query.to = from.query.to ? from.query.to : ''
            next(Object.assign({}, to, {query: query}))
            return
    }

    // Set page title.
    // @TODO Should we move this somewhere else?
    let pageTitle = store.state.config.PAGE_TITLE
    if (to.meta.hasOwnProperty('title')) {
        pageTitle = to.meta.title(to) + ' - ' + pageTitle
    }
    document.title = pageTitle

    let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    // 1 - The route requires authentication and a token is available, but
    // no user data. Load the user data from API.
    if (requiresAuth && Auth.getToken() && !User.isAuthenticated()) {
        System.load()
            .then(resources => {
                let failed = false
                Lazy(resources)
                    .each(function(v) {
                        if (v.status !== 200) {
                            failed = true
                        }
                    })
                if (!failed) {
                    next()
                }
                else {
                    Auth.setToken('')
                    router.push('/login')
                }
            })
            .catch(error => {
                errorHandler.set(error)
                Auth.setToken('')
                router.push('/login')
            })
    }

    // 2 - The route requires authentication and the user is authenticated.
    else if (requiresAuth && User.isAuthenticated()) {
        next()
    }

    // 3 - The route requires authentication, but since the previous conditions
    // wasn't met it means that the user is not authenticated.
    else if (requiresAuth) {
        router.push('/login')
    }

    // 4 - The route doesn't require authentication, but a token is available,
    // which means that the user shouldn't be allowed to access the route and
    // should be redirected "into" the application.
    else if (!requiresAuth && Auth.getToken()) {
        router.push('/user')
    }

    // 5 - The route doesn't require authentication, let the user access the route.
    else {
        next()
    }

})
