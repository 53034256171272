import {app} from 'app'
import User from 'components/user/User'
import BookLoader from 'components/common/loader/BookLoader'
import BookUpload from 'components/common/BookUpload'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'
import BookAssociations from 'components/association/BookAssociations'
import BookDatetime from 'components/common/BookDatetime'
import BookSignoff from 'components/common/signoff/BookSignoff'
import BookModal from 'components/common/BookModal'
import BookLock from  'components/common/lock/BookLock'
import BookTable from 'components/common/BookTable'
import BookProductionPlan from 'components/production/ProductionPlan/BookProductionPlan'
import BookProductionCrew from 'components/production/ProductionCrew/BookProductionCrew'
import BookSelectbox from 'components/common/BookSelectbox'
import Production from 'components/production/Production'
import ProductionTemplate from 'components/production/ProductionTemplate/ProductionTemplate'
import Association from 'components/association/Association'
import Datetime from 'components/common/helpers/Datetime'
import BookAutocomplete from 'components/common/BookAutocomplete'

import ItemEditBaseMixin from 'components/common/item/ItemEditBaseMixin'
import ItemEditMixin from 'components/common/item/ItemEditMixin'
import BookAudioLayout from 'components/common/BookAudioLayout'
import BookLocalNotes from 'components/common/BookLocalNotes'
import BookNentCalendarNotes from 'components/common/BookNentCalendarNotes'
import BookFeedbackItem from 'components/feedback/BookFeedbackItem'
import BookForkPlaceholder from 'components/forkplaceholder/BookForkPlaceholder'
import BookNotification from 'components/notification/BookNotification'
import BookExitPrint from  'components/common/BookExitPrint'

// Vendors.
import multiselect from 'vue-multiselect'
import datepicker from 'vue-datepicker/vue-datepicker-es6'
import maskedInput from 'vue-masked-input'

import productionTypes from '../productionTypes';

/**
 * Productions have different types, and these types are described in the productionTypes.js file.
 */
export default {
    mixins: [
        ItemEditBaseMixin,
        ItemEditMixin,
    ],
    components: {
        BookAutocomplete,
        BookLoader,
        BookUpload,
        BookEquipmentSelection,
        BookAssociations,
        BookDatetime,
        BookSignoff,
        BookModal,
        BookLock,
        BookTable,
        BookProductionPlan,
        BookProductionCrew,
        BookSelectbox,
        multiselect,
        datepicker,
        maskedInput,
        BookAudioLayout,
        BookLocalNotes,
        BookNentCalendarNotes,
        BookFeedbackItem,
        BookForkPlaceholder,
        BookNotification,
        BookExitPrint,
    },
    props: {
        type: {
            type: String,
            default: "production",
        },
        routeItem: {
            type: String,
        },
        routeItems: {
            type: String,
        }
    },
    mounted() {

        // Since some fields are using components, we can't attach
        // VeeValidate directly to the HTML element, then we need to create the
        // fields "manually" here, inside the "$validator" (VeeValidate).
        this.$validator.attach('productionStart', this.formData.productionStart.validator.rules)
        this.$validator.attach('productionEnd', this.formData.productionEnd.validator.rules)
        this.$validator.attach('productionLeader', this.formData.productionLeader.validator.rules)

        // Load the form data.
        this.loadForm()
            .then(response => {

                /**
                 * Load users which will be used for "Production leader".
                 * Great call , seems like you have 100 users. WN
                 * --
                 * Now we have more than 500 users, and can be thousands, let's just call for production leader
                 * shall we? WN
                 */
                const params = {
                    roles: 'production_leader',
                }
                // Set the page as loaded, since we only need to fill production leader here,
                // just load it after open the page (performance boost for edit page)
                //  WN.
                //this.setLoaded()

                if (typeof this.getTabRoutes() === 'undefined') {
                    User.getUsers()
                        .then(response => {
                            // If a template id has been passed, try to load and apply it.
                            if (this.isTemplateBased && !this.isClone) {
                                let templateId = parseInt(this.$route.query.template)
                                this.productionTemplate = new ProductionTemplate(templateId)
                                const templateParams = {
                                  type: this.type,
                                };
                                this.productionTemplate.load(templateId, templateParams)
                                    .then(response => {
                                        this.applyTemplate()
                                        this.setLoaded()
                                    })
                                    .catch(error => {
                                        this.$error.set(error, `It was not possible to load template ${templateId}.`)

                                        // Remove the template id from URL.
                                        const query = Object.assign({}, this.$route.query)
                                        delete query.template
                                        this.$router.replace({query: query}, () => {
                                            this.setLoaded()
                                        }, () => {
                                            this.setLoaded()
                                        })

                                    })
                            } else {
                                this.setLoaded()
                            }

                        })
                        .catch(error => {
                            this.$error.set(error, 'It was not possible to load the users.')
                            this.setLoaded()
                        })
                } // if its a new tab then just call only prod leaders
                else {
                    User.getUsersByRole(params)
                        .then(response => {

                            // If a template id has been passed, try to load and apply it.
                            if (this.isTemplateBased && !this.isClone) {
                                let templateId = parseInt(this.$route.query.template)
                                this.productionTemplate = new ProductionTemplate(templateId)
                                const templateParams = {
                                    type: this.type,
                                };
                                this.productionTemplate.load(templateId, templateParams)
                                    .then(response => {
                                        this.applyTemplate()
                                        this.setLoaded()
                                    })
                                    .catch(error => {
                                        this.$error.set(error, `It was not possible to load template ${templateId}.`)

                                        // Remove the template id from URL.
                                        const query = Object.assign({}, this.$route.query)
                                        delete query.template
                                        this.$router.replace({query: query}, () => {
                                            this.setLoaded()
                                        }, () => {
                                            this.setLoaded()
                                        })

                                    })
                            } else {
                                this.setLoaded()
                            }

                        })
                        .catch(error => {
                            this.$error.set(error, 'It was not possible to load the users.')
                            this.setLoaded()
                        })
                }

            })
            .catch(error => {
                this.$error.set(error, 'It was not possible to load the form.')
            })
    },

    watch: {
        loaded: function(newValue) {

            if (!newValue) return

            // Attach "editSuite" validator.
            // Note that we can't do this on mounted() because this.isEditProduction
            // is not ready to be ran in that context, when editing a edit production.
            if (this.isEditProduction && !this.formFields.editSuite) {
                this.$validator.attach('editSuite', 'required')
            }

            // Attach "engItems" validator.
            // Note that we can't do this on mounted() because this.isEngProduction
            // is not ready to be ran in that context, when editing a eng production.
            if (this.isEngProduction && !this.formFields.engItem) {
                console.log("eng");
                this.$validator.attach('engItem', 'required')
            }

        },
        'formData.productionLeader.value': function(newValue) {
            if (this.$validator.fields.find({ name: 'productionLeader' })) {
                this.$validator.validate('productionLeader', newValue)
            }

        },
        'formData.productionStart.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            // let now = this.now.format(format)
            // if (!this.isEditForm && !Datetime.isAfter(now, newValue, format)) {
            //     this.formData.productionStart.value = now
            // }
            // else if (!Datetime.isAfter(newValue, this.formData.productionEnd.value, format)) {
            if (!Datetime.isAfter(newValue, this.formData.productionEnd.value, format)) {
                this.formData.productionEnd.value = this.formData.productionStart.value
            }
            // else {
            Vue.nextTick(() => {
                this.$validator.validate('productionStart', newValue)
            })
            // }
        },
        'formData.productionEnd.value': function(newValue) {
            let format = 'YYYY-MM-DD HH:mm'
            if (!Datetime.isAfter(this.formData.productionStart.value, newValue, format)) {
                Vue.nextTick(() => {
                    this.formData.productionEnd.value = this.formData.productionStart.value
                })
            }

            Vue.nextTick(() => {
                this.$validator.validate('productionEnd', this.formData.productionEnd.value)
            })
        },
        // 'formData.isHouseNumberChanged.value': {
        //     handler: function(newValue, oldValue) {
        //         this.handlerHouseNumberChange(this.formData.houseNumber.value)
        //     }
        // },
        'formData.country.value': function (newValue) {
            if (this.formData.runType.value) {
                const entity = this.runTypeEntity.getItem(this.formData.runType.value)

                if (entity.country !== newValue) {
                    this.formData.runType.value = null
                }
            }
            if (this.formData.channel.value) {
                const entity = this.channelEntity.getItem(this.formData.channel.value)

                if (entity.country !== newValue) {
                    this.formData.channel.value = null
                }
            }
        },
        'formData.placeholders.value': {
            handler: function(newValue, oldValue) {
                let correct = false
                //const context = this
                if(newValue && newValue.length > 0) {
                    Lazy(newValue)
                        .each((v,k) => {
                            let missed = _.filter(v.placeholders, i => !i.periodStart || !i.periodEnd || !i.country)
                            if(missed && missed.length > 0) correct = true
                        })
                }
                this.formData.placeholders.isPlacholdersIncorrect = correct
            },
            deep: true
        },
        '$route': function(to, from) {
            if (!this.loaded || (to.name === from.name)) {
                return
            }
            if(to.name === 'production.create' && from.name === 'production.edit' && to.query.clone && to.query.clone !== '' && to.query.insertData) {
                if(this.formData.placeholders.value && this.formData.placeholders.value.length > 0) {
                    let localPlaceholders = []
                    Lazy(this.formData.placeholders.value)
                        .each((v,k) => {
                            let placeholders = []
                            Lazy(v.placeholders)
                                .each((v1,k1) => {
                                    placeholders.push({
                                        id: v1.id,
                                        country: v1.country,
                                        periodStart: v1.periodStart,
                                        periodEnd: v1.periodEnd,
                                        title: v1.title,
                                        itemId: v1.itemId,
                                        itemType: 'production',
                                        local: true,
                                        action: 'create',
                                        submit: false
                                    })
                                })

                            localPlaceholders.push({
                                local: true,
                                country: v.country,
                                placeholders: placeholders
                            })
                        })

                    this.formData.placeholders.value = localPlaceholders
                }
            }
        }
    },
    data() {
        return {

            // Determine values for this child component that will be used by
            // the parent component that has been extend.
            child: {
                item: 'production',
                items: 'productions',
                settings: 'productions',
                association: 'feed',
                entities: {
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                    channelEntity: 'channel_entity_id',
                    runTypeEntity: 'runtype_entity_id',
                    locationEntity: 'location_entity_id',
                    planTypeEntity: 'plantype_entity_id',
                    skillEntity: 'skill_entity_id',
                    audioLayoutsEntity: 'audiolayouts_entity_id',
                    activityTypesEntity: 'activitytypes_entity_id',
                    ingestEntity: 'ingest_entity_id'
                }
            },
            applyTemplateForm: {
                show: false,
                selection: '',
                runType: '',
                league: ''
            },
            saveTemplateForm: {
                show: false,
                loading : false,
                name: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                id: {
                    value: 0
                },
                template: null
            },
            template: {
                user: {
                    value: ''
                },
                title: {
                    value: ''
                },
                global: {
                    value: false
                },
                data: {
                    league: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    productionLeader: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    productionType: {
                        data: true,
                        value: '',
                        validator: {
                            rules: ''
                        }
                    },
                    country: {
                        data: true,
                        value: null,
                        validator: {
                            rules: ''
                        }
                    },
                    runType: {
                        data: true,
                        value: null,
                        validator: {
                            rules: ''
                        }
                    },
                    channel: {
                        data: true,
                        value: null,
                        validator: {
                            rules: ''
                        }
                    },
                    locationId: {
                        data: true,
                        value: null,
                        validator: {
                            rules: ''
                        }
                    },
                    plan: {
                        data: true,
                        value: []
                    },
                    crew: {
                        data: true,
                        value: []
                    },
                    audioLayout: {
                        data: true,
                        value: ''
                    },
                    notes: {
                        data: true,
                        value: ''
                    },
                    contacts: {
                        data: true,
                        value: ''
                    },
                    equipment: {
                        data: true,
                        value: [],
                    },
                    comments: {
                        data: true,
                        value: {}
                    },
                    tvProduction: {
                        data: true,
                        value: '',
                        default: true
                    },
                    driverId: {
                        data: true,
                        value: '',
                    },
                    houseNumber: {
                        data: true,
                        value: this.defaultHouseNumberKeys,
                        values: this.defaultHouseNumbers,
                    }
                },
                itemType: {
                    value: 'production'
                },
                filterType: {
                    value: ''
                },
                filterLeague: {
                    value: ''
                }
            },

            activeTab: 'overview',
            production: null,
            cloneFeed: null,
            productionTemplate: null,
            renderKey: 0,
            placeholderForm: null,
            showPlaceholderForm: false,
            submitOpen: false,
            submitRedirect: false,

            productionTypes: productionTypes,

            notification: {
                show: false,
                modalTitle: '',
                recipients: [],
            },
        }
    },
    computed: {
        calendarNotes() {
            return this.production.get && this.production.get.comments ? JSON.parse(this.production.get.comments) : null
        }
        ,
        isProductionCreate() {
            return ['production.create'].indexOf(this.$route.name) > -1
        },
        isEditProduction() {
            return ['edit.create', 'edit.edit'].indexOf(this.$route.name) > -1
        },
        isEngProduction() {
            return ['eng.create', 'eng.edit'].indexOf(this.$route.name) > -1
        },
        isCarProduction() {
            return ['car.create', 'car.edit'].indexOf(this.$route.name) > -1
        },
        isMasterEngTemplate() {
            return parseInt(this.$route.query?.template) === this.$settings.get.engTemplate
        },
        isMasterEditTemplate() {
            return parseInt(this.$route.query?.template) === this.$settings.get.editTemplate
        },
        isEditable() {
            return this.$ability.can('update', 'Productions')
        },
        productions() {
            return this.$store.state.data.production ? this.$store.state.data.production : null
        },
        countries() {
            return this.$store.state.system.countries ? this.$store.state.system.countries : null
        },
        processing() {
            return this.$loader.has('productionEdit_submit')
        },
        /**
         * Check if the form has been changed.
         * @return boolean
         */
        isChanged() {
            const context = this
            let changed = false
            if ((!this.production || !this.production.get) && !this.isClone) return changed

            Lazy(context.formData)
                .filter((v, k) => {
                    switch (k) {

                        // The following fields shouldn't be checked either because
                        // they aren't stored inside the production in the DB or because
                        // the value is stored "in realtime", when it's changed,
                        // ex "documents" whose value is stored directly when
                        // a file is uploaded.
                        case 'sport':
                        case 'documents':
                        case 'equipment':
                        case 'signoffs':
                        case 'plan':
                        case 'crew':
                        case 'actions':
                        case 'associations':
                        case 'ingestions':
                        case 'localNotes':
                        case 'comments':
                        case 'feedbacks':
                        case 'placeholders':
                        case 'productionType':
                            return false
                    }
                    if (!context.isEditForm) return !v.local && !v.onlyEdit
                    return !v.local
                })
                .each(function(v, k) {

                    if (changed) return true

                    // The form value is an array and its length is not equal to
                    // the length of the original value, then it has been changed.f
                    if (Array.isArray(v.value) && Array.isArray(context.production.get[k]) && (v.value.length != context.production.get[k].length)) {
                        changed = true
                    }
                    else {
                        //console.log(changed, v, k)
                        switch (k) {
                            case 'productionLeader':
                                const productionLeader = context.production.get.productionLeader ? context.production.get.productionLeader.id : ''
                                if (!changed && (productionLeader != v.value)) {
                                    changed = true
                                }
                                break

                            case 'runType':
                            case 'channel':
                            case 'tvProduction':
                            case 'locationId':
                                if (!context.isEditForm) {
                                    if (context.formFields[k] && context.formFields[k].touched) {
                                        changed = v.value != context.production.get[k]
                                    }
                                }
                                else {
                                    changed = v.value != context.production.get[k]
                                }
                                break
                            case 'houseNumber':
                                changed = context.formData.isHouseNumberChanged.value
                                break
                            case 'localNotesChanged':
                                changed = context.formData.localNotesChanged.value
                                break
                            case 'calendarNotesChanged':
                                changed = context.formData.calendarNotesChanged.value
                                break
                            case 'country':
                                if (context.formFields[k]) {
                                    changed = context.formFields[k].changed
                                }
                                break
                            default:

                                // If the form value and feed value are "false/null/empty"
                                // then "changed" should be false.
                                if (context.production.get && !context.production.get[k] && !v.value) {
                                    changed = false
                                }
                                else {
                                    changed = v.value != context.production.get[k]
                                }

                                break
                        }

                    }

                })

            return changed
        },
        crewIsDisabled() {
            const planItems = this[this.child.item].get.plan
            return !planItems || planItems.findIndex(v => v.relativeTo == 'start') == -1 || planItems.findIndex(v => v.relativeTo == 'end') == -1
        },
        getAssociationsDisabledMessage() {
            if (this.formData.locked.value || !this.isEditable) {
                return ''
            }
            return 'Associations box is disabled'
        },
        getPlanDisabledMessage() {
            if (this.formData.locked.value || !this.isEditable) {
                return ''
            }
            return 'Plan is disabled until you save this production'
        },
        getCrewDisabledMessage() {
            if (this.formData.locked.value || !this.isEditable) {
                return ''
            }
            else if (this.isClone || this.isTemplateBased) {
                return 'Crew is disabled until you save this production'
            }
            else {
                return 'You need to add at least <strong>one plan item</strong> relative to start<br />and <strong>one plan item</strong> relative to end.'
            }
        },
        getUploadDisabledMessage() {
            return !this.$ability.can('upload', 'Files') ? 'You don\'t have permission to upload files.' : 'Document upload is disabled'
        },
        getEquipmentDisabledMessage() {
            if (this.isTemplateBased) {
                return 'Equipment is disabled until you save this production'
            }
            else if (!this.isEditable) {
                return ''
            }
            else {
                return 'Equipment selection is disabled'
            }
        },
        productionStartDateLimit() {
            let now = moment(this.now)
            return [{
                type: 'fromto',
                from: now.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
            }]
        },
        productionEndDateLimit() {
            let from = moment(this.formData.productionStart.value, 'YYYY-MM-DD HH:mm')
            return [{
                type: 'fromto',
                from: from.subtract(1, 'd')
            }]
        },
        signoffItems() {
            return Lazy(this.formData.signoffs.value)
                .map(v => {
                    return Object.assign({
                        disabled: !this.readyForSignOff(v)
                    }, v)
                }).toArray()
        },
        getEquipmentFilterTypesBy() {
            switch (true) {
                case (!this.isEditForm && this.isEditProduction):
                    return [this.$settings.get.equipment.editsuites_entityitem_id]
                case (!this.isEditForm && this.isEngProduction):
                    return [this.$settings.get.equipment.eng_entityitem_id]
            }
            return []
        },
        getPageTitle() {
            switch (true) {
                case (this.isEditForm):
                    if (this.isClone) {
                        //if its feed clone to create production? WN
                        const production = this.$route.query.feed == 1 ? this.cloneFeed : new Production(parseInt(this.$route.query.clone))
                        return `Copying ${production.get.title}`
                    }
                    else {
                        return this.production.get.title
                    }
            }

            const typeLabel = this.productionTypes[this.type]?.label

            if (typeLabel) {
                return `Add ${typeLabel}`
            }

            return 'Add production'
        },
        templates() {
            if(this.$store.state.data.productionTemplate)
                return Lazy(this.$store.state.data.productionTemplate)
                    .filter(item => {
                        if (item.data.productionType && item.data.productionType !== this.type) {
                            return false
                        }

                        return true
                    })
                    .sortBy(item => item.title, false)
                    .toArray()
            else return null
        },
        getApplyProductionsTemplates() {
            return Lazy(this.$store.state.data.productionTemplate)
                .filter(item => {
                    if (this.applyTemplateForm.runType || this.applyTemplateForm.league) {
                        if(this.applyTemplateForm.runType && this.applyTemplateForm.runType != item.data.runType)
                            return false
                        if(this.applyTemplateForm.league && this.applyTemplateForm.league != item.data.league)
                            return false
                    }

                    if (item.data.productionType && item.data.productionType !== this.type) {
                        return false
                    }

                    return true
                })
                .sortBy(item => item.title, false)
                .toArray()
        },
        getBookedByUser() {
            const bookedBy = this.production.get?.bookedBy

            if (!bookedBy) {
                return null
            }

            return this.availableUsers.find(user => user.id === bookedBy)
        },
        hasBookedByUser() {
            return this.production.get?.bookedBy ?? null
        },
        getDriverUser() {
            const driverId = this.formData.driverId.value

            if (!driverId) {
                return null
            }

            return this.availableUsers.find(user => user.id === driverId)
        },
    },
    methods: {
        handlePrint() {
            this.$router.replace({query: {print: '1'}})
        },
        /**
         * Need to render component for cloning feed to production to get Associations
         * applied over the template.
         */
        renderPlease() {
            this.renderKey += 1
        },
        /**
         * Set default values on "Add production" and current data on "edit".
         */
        setFormValues(applyTemplate = false) {
            const context = this
            const userCountry = app.$data.user.get.country

            if (context.isEditForm || context.isTemplateBased) {

                // Determine which feed instance to use, in order to populate
                // the fields.
                //--
                // We need to check if its feed or production that needs to be cloned WN
                let production = context.isClone ? (context.cloneFeed && context.$route.query.feed === 1 ? context.cloneFeed : new Production(parseInt(context.$route.query.clone))) : context.production

                let insertData = this.$route.query.insertData && parseInt(this.$route.query.insertData) === 1
                //console.log("log values");
                Lazy(context.formData)
                    .filter(v => !v.local)
                    .each(function(v, k) {

                        // If insertData from URL is false, do not set values
                        // for "plan" and "crew".
                        if (context.isClone && !insertData && (['plan', 'crew', 'documents', 'locations'].indexOf(k) > -1)) {
                            return
                        }
                        // HARDFIX for refreshing the page, missing items on cloning from feed. WN
                        if (!production.get.league && context.$route.query.feed === 1) { production = context.cloneFeed }

                        switch (k) {
                            // if feed is cloning for production as a Template need to apply those (title, sport, league, start, end)
                            // and rest should be covered from the template
                            case 'title':
                                if (context.isTemplateBased && context.isFeedTemplateBased) v.value = context.cloneFeed.get.title
                                else v.value = production.get[k]
                                break
                            case 'sport':
                                const league = (context.isTemplateBased && context.isFeedTemplateBased ? context.cloneFeed.get.league : production.get.league)
                                if (league) {
                                    const entityItem = context.leagueEntity.getItem(league)
                                    v.value = entityItem.reference
                                }
                                break
                            case 'league':
                                if (context.isTemplateBased && context.isFeedTemplateBased) v.value = context.cloneFeed.get.league
                                else v.value = production.get[k]
                                break
                            case 'productionLeader':
                                if (production.get.productionLeader) {
                                    v.value = production.get.productionLeader.id
                                }
                                break
                            case 'productionType':
                                if(production.get.productionType) {
                                    v.value = production.get.productionType
                                }
                                else {
                                    v.value = this.type
                                }
                                break
                            // if feed is cloning for production, need to get this data to fromData WN
                            case 'productionStart':
                                if (production.get.feedStart || context.isTemplateBased && context.isFeedTemplateBased)
                                    v.value = production.get.feedStart ? production.get.feedStart : context.cloneFeed.get.feedStart
                                else v.value = production.get[k]
                                break
                            case 'productionEnd':
                                if (production.get.feedEnd || context.isTemplateBased && context.isFeedTemplateBased)
                                    v.value = production.get.feedEnd ? production.get.feedEnd : context.cloneFeed.get.feedEnd
                                else v.value = production.get[k]
                                break
                            case 'houseNumber':
                                if (context.isTemplateBased && context.isFeedTemplateBased) {

                                }
                                else {
                                    // we dont need to copy house number for cloning
                                    if (!context.isClone) {
                                        //console.log(context.production.get, 'production?')
                                        let hNumber = context.production.get.houseNumber
                                        if (hNumber && typeof hNumber !== "object" ) {
                                            if (isJsonSring(hNumber)) {
                                                hNumber = JSON.parse(hNumber)
                                                Lazy(v.value)
                                                    .each(function (v2, k2) {
                                                        v.value[k2] = hNumber[k2]
                                                    })
                                            } else {
                                                let _channel = context.formData.channel.value ? context.formData.channel.value : production.get.channel
                                                if (_channel) {
                                                    context.channelEntity.get.items.forEach(v2 => {
                                                        if (_channel === v2.id) v.value[v2.country] = hNumber
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        // for template use this
                                        let hNumber = production.get.houseNumber
                                        if (hNumber && typeof hNumber !== "object" ) {
                                            if (isJsonSring(hNumber)) {
                                                hNumber = JSON.parse(hNumber)
                                                Lazy(v.value)
                                                    .each(function (v2, k2) {
                                                        if(hNumber[k2] && hNumber[k2] !== '') {
                                                            let isRec = context.isForFork(hNumber[k2], k2)
                                                            if(isRec) {
                                                                v.value[k2] = hNumber[k2]
                                                                if(!context.formData.placeholders.showPlaceholders) {
                                                                    context.formData.placeholders.showPlaceholders = true
                                                                    context.formData.placeholders.initialHasRec = true
                                                                }
                                                            }
                                                        }
                                                    })
                                            }
                                        }
                                    }
                                }
                                break
                            case 'placeholders':
                                if (context.isTemplateBased && context.isFeedTemplateBased) {
                                    // we dont need to copy house number for cloning
                                    //v.value = context.cloneFeed.get.houseNumber
                                }
                                else {
                                    // we dont need to copy house number for cloning
                                    if (!context.isClone) {
                                        if(production.get.placeholders && production.get.placeholders.length > 0) {
                                            let placeholders = _.cloneDeep(production.get.placeholders)//production.get.placeholders.slice()

                                            Lazy(placeholders)
                                                .each(function(v1, k1) {
                                                    v1.local = false
                                                    Lazy(v1.placeholders)
                                                        .each(function(v2, k2) {
                                                            v2.local = false
                                                            v2.action = 'none'
                                                            v2.submit = false
                                                        })
                                                })

                                            v.value = placeholders
                                            context.formData.placeholders.showPlaceholders = true
                                        }

                                        let houseNumber = context.production.get.houseNumber
                                        if (houseNumber && typeof houseNumber !== "object" ) {
                                            if (isJsonSring(houseNumber)) {
                                                houseNumber = JSON.parse(houseNumber)
                                                Lazy(houseNumber)
                                                    .each(function (v2, k2) {
                                                        if(houseNumber[k2] && houseNumber[k2] !== '') {
                                                            if(!context.formData.placeholders.showPlaceholders) {
                                                                let isRec = context.isForFork(houseNumber[k2], k2)
                                                                if(isRec) {
                                                                    context.formData.placeholders.showPlaceholders = true
                                                                    context.formData.placeholders.initialHasRec = true

                                                                    let currentForkCountry = _.find(context.formData.placeholders.value, p => p.coutry == k2)
                                                                    if(currentForkCountry === undefined) {
                                                                        if(k2 !== 'se') {
                                                                            context.formData.placeholders.value.push({
                                                                                local: false,
                                                                                country: k2,
                                                                                placeholders: []
                                                                            })
                                                                        }
                                                                        else {
                                                                            let isNoRec = houseNumber['no'] !== null && houseNumber['no'] !== '' ? context.isForFork(houseNumber['no'], 'no') : false
                                                                            if(!isNoRec) {
                                                                                context.formData.placeholders.value.push({
                                                                                    local: false,
                                                                                    country: k2,
                                                                                    placeholders: []
                                                                                })
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }


                                                        }
                                                    })
                                            }
                                        }
                                    }
                                    else {
                                        if(production.get.placeholders && production.get.placeholders.length > 0) {
                                            let localPlaceholders = []
                                            Lazy(production.get.placeholders)
                                                .each((v,k) => {
                                                    let placeholders = []
                                                    Lazy(v.placeholders)
                                                        .each((v1,k1) => {
                                                            placeholders.push({
                                                                id: v1.id,
                                                                country: v1.country,
                                                                periodStart: v1.periodStart,
                                                                periodEnd: v1.periodEnd,
                                                                title: v1.title,
                                                                itemId: v1.itemId,
                                                                itemType: 'production',
                                                                local: true,
                                                                action: 'create',
                                                                submit: false
                                                            })
                                                        })

                                                    localPlaceholders.push({
                                                        local: true,
                                                        country: v.country,
                                                        placeholders: placeholders
                                                    })
                                                })

                                            v.value = localPlaceholders
                                            context.formData.placeholders.showPlaceholders = true
                                        }
                                    }
                                }
                                break

                            // First we look for production or template country value, if that doesn't exist, for ENG
                            // and Edit creation we use user's country, and for production we try to use the channel country.
                            case 'country':
                                if (production.get.country) {
                                    v.value = production.get.country
                                } else if (!context.isEditForm && (context.isEngProduction || context.isEditProduction)) {
                                    v.value = userCountry
                                } else {
                                    let _p_channel = production && production.get && production.get.channel ? production.get.channel : null
                                    let _channel = context.formData.channel.value ? context.formData.channel.value : _p_channel

                                    if (applyTemplate) _channel = _p_channel
                                    if (_channel) {
                                        context.channelEntity.get.items.forEach(v2 => {
                                            if (_channel === v2.id) {
                                                v.value = v2.country
                                            }
                                        })
                                    }
                                }

                                break
                            default:
                                // We need to detect if the value is array/object or
                                // another type, so we can make a real copy of the
                                // value and not create a pointer.
                                //Equipment -> equipmentTypeUsage                                if (typeof production.get[k] == 'object') {
                                if (typeof production.get[k] == 'object') {

                                    if (Array.isArray(production.get[k])) {
                                        v.value = production.get[k].slice()
                                    }
                                    else if (production.get[k]) {
                                        v.value = {}
                                        Object.assign(v.value, context.production.get[k])
                                    }
                                }
                                else if (production.get[k]) {
                                    v.value = production.get[k]
                                }

                                break
                        }
                    })
                //Force to submit form if this is Clone and Edit or Eng
                if (context.isClone && (context.isEditProduction || context.isEngProduction)){
                    this.submit(true, true);
                }
            }
            else {
                switch (true) {
                    default:

                        //let userCountry = app.$data.user.get.country;

                        if (context.hasField('tvProduction')) {
                            context.formData.tvProduction.value = true
                        }

                        let defaultValue = null
                        const entities = ['runType', 'channel', 'location']

                        // Try to set default values for entity field.
                        entities.forEach(v => {
                            defaultValue = context[`${v}Entity`].get.items.filter(function(item) {
                                if (v === 'location') {
                                    if (userCountry && item.country && item.country === userCountry) {
                                        return item.default
                                    }
                                } else return item.default
                            })
                            if (defaultValue.length > 0) {
                                if (v === 'location' && context.hasField('locationId')) context.formData.locationId.value = defaultValue[0].id
                                else if (context.hasField(v)) context.formData[v].value = defaultValue[0].id
                            }
                        })

                        if (context.hasField('driverId')) {
                            context.formData.driverId.value = app.$data.user.get.id
                        }

                        if (this.type === 'car') {
                            context.formData.equipment.value = [
                                {
                                    elementType: "equipmentType",
                                    equipmentId: "",
                                    equipmentType: context.$settings.get.equipment.equipmenttype_car_entity_id,
                                    type: context.$settings.get.equipment.equipmenttype_car_entity_id,
                                    id: moment().unix(),
                                    equipmentUsage: {
                                        isNotUpdatePeriod: false,
                                        isPrimary: false,
                                    }
                                }
                            ]
                        }

                        context.formData.productionType.value = this.type

                        break
                }
            }

        },
        openInsertForm() {
            const context = this
            context.setNow()
            context.insertForm.loading = true
            context.insertForm.show = true
            const params ={
                league: context.production.get.league
            }
            context.production.all(params)
                .then(response => {
                    context.insertForm.loading = false
                })
        },

        /**
         * Get production title that is used inside the dropdown for production data copy.
         */
        getproductionTitleToInsertForm(production) {
            let start = moment(production.productionStart).format('YYYY-MM-DD')
            return `${start}, ${production.title} (${production.id})`
        },

        /**
         * Check if a given production should be visible in the "Insert data" dropdown.
         */
        isVisibleOnInsertForm(production) {
            const context = this
            let diff = moment(production.productionEnd).diff(context.now)
            return production.league == context.production.get.league && !isNaN(diff) && diff < 0
        },
        /**
         * Check if we are at the NewTab
         */
        // New Tab fix
        getTabRoutes() {
            return this.$route.query.newBar;
        },
        /**
         * Moving fork placeholders before submit if it is recording
         * @param {*} open
         * @param {*} redirect
         */
        submitItem(open = false, redirect = true) {
            const context = this
            //need to save open and redirect params to use them inside callbacks
            context.submitOpen = open
            context.submitRedirect = redirect

            // ProductionLeader and the Dates are a must, do not let it save without it. Seems like, when you click cancel and save changes
            // it was allowing to save without mandatory fields. WN
            let _prodStart = context.formData.productionStart.value
            let _prodEnd = context.formData.productionEnd.value
            if (['production.create', 'production.edit','edit.create', 'edit.edit', 'eng.create', 'eng.edit'].indexOf(this.$route.name) > -1) {
                let _prodLead = context.formData.productionLeader.value

                if (!_prodLead || !_prodStart || !_prodEnd) {
                    context.$alert.set(`Missing Mandatory information, START, END dates and Production Leader can not be empty.`, 'danger', 7)
                    context.setLoaded();
                    return
                }

                if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                    Lazy(context.formData.placeholders.value)
                        .each((v,k) => {
                            Lazy(v.placeholders)
                                .each((v1, k1) => {
                                    if(!v1.periodStart || !v1.periodEnd) {
                                        context.$alert.set(`The fork laceholder should have start and end datetime.`, 'danger', 7)
                                        context.setLoaded();
                                        return
                                    }
                                })
                        })
                }
            }

            let production = context.isClone ? (context.cloneFeed && context.$route.query.feed == 1 ? context.cloneFeed : new Production(parseInt(context.$route.query.clone))) : context.production

            const datesBeforeChange = {
                start: production.get.productionStart,
                end: production.get.productionEnd
            }

            // if we the production dates have changed and we have fork placeholders
            // then we need to update dates for placeholders
            // if the placeholder date start 15 minutes before production and finishes 30 minutes after production
            // then we can move placeholder dates automatically in case if new difference is not longer than 4 hours
            // if it is longer then we suggest to edit
            // if we have a few placeholders or his dates are not within +15/- 30 minutes interval then
            // we need to ask approval for new placeholders dates
            if(((context.isEditForm && ((datesBeforeChange.start !== _prodStart) || (datesBeforeChange.end !== _prodEnd))) || context.isClone) &&
                context.formData.placeholders.value && context.formData.placeholders.value.length > 0 &&
                (!context.formData.placeholders.hasOwnProperty('datesChangedAccepted') || (context.formData.placeholders.hasOwnProperty('datesChangedAccepted') && context.formData.placeholders.datesChangedAccepted === false))) {
                context.formData.placeholders.datesChangedAccepted = false
                let oldDateProductionStart = new Date((moment(datesBeforeChange.start).add(-15, 'minutes')).format('YYYY-MM-DD HH:mm'))
                let oldDateProductionEnd = new Date((moment(datesBeforeChange.end).add(30, 'minutes')).format('YYYY-MM-DD HH:mm'))
                let oldProdDiff = Math.ceil((oldDateProductionEnd.getTime() - oldDateProductionStart.getTime()) / 3600000)

                let newDateProductionStart = new Date((moment(_prodStart).add(-15, 'minutes')).format('YYYY-MM-DD HH:mm'))
                let newDateProductionEnd = new Date((moment(_prodEnd).add(30, 'minutes')).format('YYYY-MM-DD HH:mm'))
                let newProdDiff = Math.ceil((newDateProductionEnd.getTime() - newDateProductionStart.getTime()) / 3600000)
                if((oldDateProductionEnd.getTime() - oldDateProductionStart.getTime()) === (newDateProductionEnd.getTime() - newDateProductionStart.getTime()) ||
                    (oldProdDiff <= 4 && newProdDiff <= 4)) {
                    context.formData.placeholders.datesChangedAccepted = true
                    Lazy(context.formData.placeholders.value)
                        .each((v, k) => {
                            if(v.placeholders && v.placeholders.length === 1) {
                                let singlePlaceholder = v.placeholders[0]
                                singlePlaceholder.local = true
                                singlePlaceholder.action = !singlePlaceholder.action || (singlePlaceholder.action && singlePlaceholder.action === 'none') ? 'update' : singlePlaceholder.action
                                if(singlePlaceholder.forkPlaceholder) {
                                    singlePlaceholder.submit = true
                                }
                                singlePlaceholder.periodStart = moment(newDateProductionStart).format('YYYY-MM-DD HH:mm')
                                singlePlaceholder.periodEnd = moment(newDateProductionEnd).format('YYYY-MM-DD HH:mm')
                            }
                            else{
                                Lazy(v.placeholders)
                                    .each((v2,k2) => {
                                        let oldPlaceholderStart = new Date(v2.periodStart)
                                        let oldPlaceholderEnd = new Date(v2.periodEnd)

                                        let oldStartDiff = (oldDateProductionStart.getTime() - oldPlaceholderStart.getTime()) * (-1)
                                        let newPlaceholderStart = (moment(newDateProductionStart).add(oldStartDiff, 'milliseconds')).format('YYYY-MM-DD HH:mm')

                                        let oldEndDiff = (oldDateProductionStart.getTime() - oldPlaceholderEnd.getTime()) * (-1)
                                        let newPlaceholderEnd = (moment(newDateProductionStart).add(oldEndDiff, 'milliseconds')).format('YYYY-MM-DD HH:mm')

                                        v2.local = true
                                        v2.action = !v2.action || (v2.action && v2.action === 'none') ? 'update' : v2.action
                                        if(v2.forkPlaceholder) {
                                            v2.submit = true
                                        }
                                        v2.periodStart = newPlaceholderStart
                                        v2.periodEnd = newPlaceholderEnd
                                    })
                            }

                        })

                    context.submit(open, redirect)
                }
                else {
                    swal({
                        title: 'Changes for placeholders are required',
                        text: `Placeholders cannot be moved automatically. You need to fix it manually. Would you like to edit it?`,
                        type: 'question',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                        .then(function () {
                            // TO DO: open modal window in forkplaceholder
                            console.log('open modal window in forkplaceholder')
                            let placeholders = _.cloneDeep(context.formData.placeholders.value)
                            Lazy(placeholders)
                                .each(function(v1, k1) {
                                    v1.local = true
                                    Lazy(v1.placeholders)
                                        .each(function(v2, k2) {
                                            if(!v2.local) {
                                                v2.local = true
                                                v2.action = !v2.action || (v2.action && v2.action === 'none') ? 'update' : v2.action
                                                if(v2.forkPlaceholder) {
                                                    v2.submit = true
                                                }
                                            }

                                        })
                                })
                            context.placeholderForm = placeholders
                            context.showPlaceholderForm = true

                        }, function (dismiss) {
                            context.formData.placeholders.datesChangedAccepted = true
                            context.submit(open, redirect)
                        })
                }

            }
            else if(context.isEditForm && context.formData.placeholders.value && context.formData.placeholders.value.length > 0 &&
                (context.production.get.league !== context.formData.league.value ||
                    context.production.get.channel !== context.formData.channel.value ||
                    context.production.get.title !== context.formData.title.value
                )) {

                Lazy(context.formData.placeholders.value)
                    .each(function(v1, k1) {
                        v1.local = true
                        Lazy(v1.placeholders)
                            .each(function(v2, k2) {
                                if(!v2.local) {
                                    v2.local = true
                                    v2.action = 'update'
                                    if(v2.forkPlaceholder) {
                                        v2.submit = true
                                    }
                                }

                            })
                    })

                context.submit(open, redirect)
            }
            else {
                context.submit(open, redirect)
            }
        },
        /**
         * Save the form.
         * @TODO Need to clean the code abit from duplications.
         */
        submit(open = false, redirect = true) {
            const context = this

            const datesBeforeChange = {
                start: this.production.get.productionStart,
                end: this.production.get.productionEnd
            }

            context.$loader.add('productionEdit_submit')

            Lazy(context.formData)
                .filter((v, k) => !v.local)
                .each(function(v, k) {
                    let value;
                    switch (k) {
                        case 'comments':
                            if (v.value) {
                                // remove the empty fields/values
                                value = context.cleanObjectString(v.value)
                            }
                            break
                        case 'tvProduction':
                            value = v.value
                            break
                        case 'houseNumber':
                            value = {}
                            Lazy(v.value)
                                .each((item, k) => {
                                    // if its undefined, or empty, just make it empty instead of null. WN
                                    value[k] = item ? item : ''
                                })
                            break
                        case 'placeholders':
                            if(v.value && v.value.length > 0) {
                                let createPlacholders = []
                                let updatePlacholders = []
                                let deletePlacholders = []
                                Lazy(v.value)
                                    .each((v2, k2) => {
                                        Lazy(v2.placeholders)
                                            .each((v3, k3) => {
                                                if(v3.local) {
                                                    if(v3.action === 'create') createPlacholders.push(v3)
                                                    if(v3.action === 'update') updatePlacholders.push(v3)
                                                }
                                                if(v3.action === 'delete') deletePlacholders.push(v3)
                                            })
                                    })
                                if(createPlacholders.length > 0 || updatePlacholders.length > 0 || deletePlacholders.length > 0) {
                                    context.production.set('handlePlaceholders', true)
                                    value = {
                                        create: createPlacholders,
                                        update: updatePlacholders,
                                        delete: deletePlacholders
                                    }
                                }
                                else {
                                    context.production.set('handlePlaceholders', false)
                                }
                            }
                            break
                        default:
                            value = v.value
                            break
                    }
                    context.production.set(k, value)
                })

            // we need to json stringify
            if (context.production.get.houseNumber) {
                context.production.get.houseNumber = JSON.stringify(context.production.get.houseNumber)
            }


            if (context.isEditForm && !context.isClone) {

                const datesChanged = (datesBeforeChange.start !== context.production.get.productionStart) || (datesBeforeChange.end !== context.production.get.productionEnd)

                // Tell the API to update the date on the equipment pieces.
                //VR: why it was commented bebore?
                if (datesChanged && this.production.get.equipment.length > 0) {
                    context.production.set('updateEquipmentDate', 1)
                }

                context.production.save()
                    .then(response => {
                        context.$alert.set(`Production ${context.id} was saved!`, 'success', 3)
                        context.updateHouseNumbers()
                        Vue.set(context.$data, 'formData', context.defaultData())

                        if(datesChanged && context.formData.placeholders.hasOwnProperty('datesChangedAccepted')) delete context.formData.placeholders.datesChangedAccepted

                        // check if its more than 24 hours long
                        let fStart = context.production.get.productionStart
                        fStart = new Date(fStart);
                        let fEnd = context.production.get.productionEnd
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        // We should check first if its over than 24 hours
                        // then we should ask for changing, before asking send crew
                        // about time changing.

                        if (differenceTime >= 24 && datesChanged) {
                            if (this.type === 'production')  {
                                swal({
                                    title: '24 Hours Long',
                                    text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function () {
                                        // if its in new tab already dont push anywhere WN
                                        if (context.getTabRoutes() && !open) { return }
                                        context.$router.push({path: `/production/${context.id}`})
                                    }, function (dismiss) {

                                        if (datesChanged && (context.production.get.crew.length > 0)) {
                                            swal({
                                                title: 'Changed dates',
                                                text: `Dates in this production have been changed. Do you want to notify the crew members about the date/time changes? `,
                                                type: 'question',
                                                confirmButtonText: 'Yes',
                                                cancelButtonText: 'No',
                                                showCancelButton: true,
                                                showCloseButton: true
                                            })
                                                .then(function () {
                                                    app.$emit('notifyCrew_changedDates')

                                                }, function (dismiss) {
                                                    // Close the tab if its clicked to "save & close the tab"
                                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                    if (redirect) context.redirect(open)
                                                })
                                                .catch(() => {
                                                    // Close the tab if its clicked to "save & close the tab"
                                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                    if (redirect) context.redirect(open)
                                                })
                                        } else {
                                            if (redirect) {
                                                context.submitted = true
                                                // Close the tab if its clicked to "save & close the tab"
                                                if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                                context.redirect(open)
                                            }
                                        }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                            }
                        }
                        else {
                            if (datesChanged && (context.production.get.crew.length > 0) ) {
                                swal({
                                    title: 'Changed dates',
                                    text: `Dates in this production have been changed. Do you want to notify the crew members about the date/time changes? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function() {
                                        app.$emit('notifyCrew_changedDates')
                                    }, function(dismiss) {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                            }
                            else {
                                if (redirect) {
                                    context.submitted = true
                                    // Close the tab if its clicked to "save & close the tab"
                                    if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                    context.redirect(open)
                                }
                            }

                        }
                        // Close the tab if its clicked to "save & close the tab"
                        // Idea is using the same identifiers as save and close for redirect and open options,
                        // which makes smart use in tabs as welll
                        // WN
                        if (context.getTabRoutes() && !open && !datesChanged) { return context.closeNewTab(); }

                        context.setLoaded()
                        context.$validator.reset().then(() => {
                            context.$loader.remove('productionEdit_submit')
                        })
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.$loader.remove('productionEdit_submit')
                    })

            }
            else {
                console.log("submit copy");
                if (open) {
                    context.setLoaded(false)
                    context.$loader.remove('productionEdit_submit')
                }
                if (context.isTemplateBased) {
                    context.production.set('template', context.productionTemplate.get.id)
                }
                //if there's a feed clone, do not set it as a production clone
                if (context.isClone && context.$route.query.clone && !context.$route.query.feed){
                    context.production.set('clone', context.$route.query.clone)
                }

                let params = []

                if (context.formData.associations){
                    context.formData.associations = [];
                }

                // For master templates we allow the user to select/change equipment during create.
                if (this.isMasterEngTemplate || this.isMasterEditTemplate) {
                    params.push({ saveEquipment: 1 })
                }

                context.production.store(params)
                    .then(response => {
                        context.submitted = true
                        context.$alert.set(`Production ${response.data.id} has been created!`, 'success', 3)


                        // check if its more than 24 hours long
                        let fStart = this.formData.productionStart.value
                        fStart = new Date(fStart);
                        let fEnd = this.formData.productionEnd.value
                        fEnd = new Date(fEnd);
                        let differenceTime = fEnd.getTime() - fStart.getTime();
                        differenceTime = Math.floor(differenceTime / 3600000);

                        if (differenceTime >= 24) {
                            if (this.type === 'production')  {
                                swal({
                                    title: '24 Hours Long',
                                    text: `This booking is over 24 hours long. You may want to consider changing this, would you like to do it now? `,
                                    type: 'question',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    showCancelButton: true,
                                    showCloseButton: true
                                })
                                    .then(function () {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        context.$router.push({path: `/production/${response.data.id}`})
                                    }, function (dismiss) {
                                        // Close the tab if its clicked to "save & close the tab"
                                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                                        if (redirect) context.redirect(open)
                                    })
                                    .catch(() => {
                                        if (redirect) context.redirect(open)
                                    })
                            }
                        }


                        // close the tab if its clicked to "save & close the tab"
                        //if (!open && getTabRoutes()) { return closeNewTab(); }

                        context.production = new Production(response.data.id)

                        // If clone is a feed for Production, lets make it association for new created production WN
                        if (this.isFeedClone || this.isFeedTemplateBased) {
                            const association = new Association('api/production/' + response.data.id + '/' + 'association', 0)
                            association.add({
                                association: this.$route.query.clone ? this.$route.query.clone : this.$route.query.feedTemplate,
                                type: 'feed'
                            })
                            association.store()
                                .then(response => {
                                    if (this.isFeedClone) {
                                        context.redirect(open, context.production.get.id)
                                        this.$router.go()
                                    }
                                    if (this.isFeedTemplateBased) {
                                        const query = Object.assign({}, this.$route.query)
                                        delete query.feedTemplate
                                        this.$router.replace({query: query}, () => {
                                            context.redirect(open, context.production.get.id)
                                            this.$router.go()
                                        }, () => {
                                            // not need abort
                                        })
                                    }
                                })
                                .catch(error => {
                                    this.$error.set(error, 'It was not possible to save the association.')
                                })
                        } else {

                            if (redirect) {
                                context.redirect(open, response.data.id)
                                    .then(response => {
                                        if (open) {
                                            context.setLoaded()
                                        }
                                    })
                                    .catch(error => {
                                        if (open) {
                                            context.setLoaded()
                                        }
                                    })
                            }
                        }
                        // Reset formData if "Save & Close" button has been clicked.
                        context.updateHouseNumbers()
                        if (!open) Vue.set(context.$data, 'formData', context.defaultData())

                        // Close the tab if its clicked to "save & close the tab"
                        // Idea is using the same identifiers as save and close for redirect and open options,
                        // which makes smart use in tabs as welll
                        // WN
                        if (context.getTabRoutes() && !open) { return context.closeNewTab(); }
                        context.$loader.remove('productionEdit_submit')
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.$loader.remove('productionEdit_submit')
                        context.setLoaded()
                    })
            }
        },

        /**
         * Check if the passed tab name is the current active tab.
         */
        isActiveTab(name) {
            return this.activeTab == name
        },

        /**
         * Get the channel label to display.
         */
        // getChannelLabel(item) {
        //     return item.country ? item.label + ' (' + item.country.toUpperCase() + ')' : item.label
        // },

        /**
         * Lock the current form based on events from BookLock.
         * @param integer id The item id sent by BookLock.
         * @return boolean
         */
        lock(id) {
            if (id == this.id) this.formData.locked.value = true
            else this.formData.locked.value = false
        },

        getFormattedProductionDate(field) {
            // let value = this.production.get[field].split(' ')
            let value = this.formData[field].value.split(' ')
            return {
                date: value[0],
                time: value[1]
            }
        },

        /**
         * Apply the selected template to the current production.
         */
        applyTemplate() {
            console.log("Apply template");
            const context = this
            if (!context.productionTemplate) return
            Lazy(context.productionTemplate.get.data)
                .each((v, k) => {
                    switch (k) {
                        case 'productionLeader':
                            const productionLeader = this.$store.state.user.users.find(user => user.id == v)
                            context.production.set('productionLeader', productionLeader)
                            break
                        case 'league':
                            context.production.set('league', context.productionTemplate.get.data.league)
                            break
                        default:
                            context.production.set(k, v)
                    }
                })
            Vue.nextTick(() => {
                context.setFormValues()
            })
        },
        /**
         * Check if the given RunType item should be visible or not, based on
         * the current context.
         * @param object runType
         * @return boolean
         */
        runTypeItemIsVisible(runType) {
            switch (true) {
                case (this.isEditProduction):
                    return runType.id === this.setting.edit_entityitem_id
                case (this.isEngProduction):
                    return runType.id === this.setting.eng_entityitem_id
                default:

                    // If the runType id is EDIT or ENG, don't show it for
                    // "common productions".
                    return [this.setting.edit_entityitem_id, this.setting.eng_entityitem_id].indexOf(runType.id) == -1

            }
        },

        /**
         * Check if the passed signoff item is ready to be signed off.
         * @param object item The signoff item.
         */
        readyForSignOff(item) {

            if (!this.isFormValid) {
                return false
            }
            if (item.signed) {
                return true
            }

            // Here we check if the current signoff item can be signed off based
            // on its label. We maybe should solve this another way, centralizing
            // the list of signoff types a production can have, avoiding look
            // at the label in combination with a rules set.
            let label = item.label.replace(' ', '').toLowerCase()
            let ready = false

            switch (label) {
                case 'productionready':
                    //According to new requirements, sign offs shouldn't depend on crew list
                    if (this.formData.crew.value.length > 0) {
                        let accepted = 0
                        ready = this.formData.crew.value.every(v => {
                            if (v.status == 'accepted') {
                                accepted++
                            }
                            return v.isHistorical || ['accepted', 'not_available'].indexOf(v.status) > -1
                        })

                        // Check that at least one of the and still say ok.
                        if (accepted == 0) {
                            ready = true
                        }

                    }
                    // if theres no crew member, button can be editable.
                    else { ready = true; }
                    break
            }
            return ready
        },
        houseNumberValues() {
            const context = this
            return context.formData.houseNumber.values
        },

        /**
         * Default form data to be used in this form.
         * @return object
         */
        defaultData() {
            return {
                locked: {
                    value: false,
                    local: true
                },
                productionType: {
                    value: ''
                },
                tvProduction: {
                    value: false,
                    default: true,
                },
                locationId: {
                    value: null,
                    locked: false,
                    validator: {
                        rules: 'required'
                    }
                },
                sport: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                league: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                title: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionStart: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionEnd: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                productionLeader: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
                projectId: {
                    value: ''
                },
                houseNumber: {
                    value: this.defaultHouseNumberKeys,
                    values: this.defaultHouseNumbers//['se', 'no', 'dk', 'fi', 'uk'],
                },
                isHouseNumberChanged: {
                    value: false,
                    local: true
                },
                runType: {
                    value: null,
                    locked: false,
                    validator: {
                        rules: 'required'
                    }
                },
                country: {
                    value: null,
                    validator: {
                        rules: 'required'
                    }
                },
                channel: {
                    value: null,
                    validator: {
                        rules: 'required'
                    }
                },
                plan: {
                    // local: true,
                    value: []
                },
                crew: {
                    // local: true,
                    value: []
                },
                audioLayout: {
                    value: '',
                    onlyEdit: true
                },
                notes: {
                    value: ''
                },
                localNotes: {
                    value: [],
                    onlyEdit: true
                },
                localNotesChanged: {
                    value: false
                },
                comments: {
                    value: '',
                    onlyEdit: true
                },
                calendarNotesChanged: {
                    value: false
                },
                contacts: {
                    value: ''
                },
                signoffs: {
                    value: [],
                    onlyEdit: true
                },
                documents: {
                    value: [],
                    progress: 0,
                    onlyEdit: true
                },
                equipment: {
                    value: [],
                    onlyEdit: true
                },
                feedbacks: {
                    value: [],
                    onlyEdit: true
                },
                associations: {
                    value: [],
                    onlyEdit: true
                },
                ingestions: {
                    value: [],
                    onlyEdit: true
                },
                placeholders: {
                    value: [],
                    showPlaceholders: false,
                    initialHasRec: false,
                    openDefault: 0,
                    isPlacholdersIncorrect: false
                },
                actions: {
                    value: ['template', 'apply', 'clone', 'delete']
                },
                driverId: {
                    value: '',
                    validator: {
                        rules: 'required'
                    }
                },
            }
        },

        /**
         * Emit a action button event.
         * @param eventName string
         * @param object row
         */
        emitButtonEvent(eventName) {
            const context = this
            switch (eventName) {
                case 'clone':
                    context.cloneProduction(context.production.options.id)
                    break
                case 'delete':
                    context.deleteItem(context.production.options.id)
                    break
                case 'template':
                    context.saveProductionAsTemplate()
                    break
                case 'apply':
                    context.openApplyTemplateForm()
                    break
            }
        },
        buttonIsPresent(button, byPass = false) {
            //return this.tableData.options.actions.indexOf(button) > -1
        },
        getButtonClasses(button) {
            let classes = []
            switch (button) {
                case 'clone':
                    classes.push('btn-info')
                    break
                case 'delete':
                    classes.push('btn-danger')
                    break
                case 'template':
                    classes.push('btn-warning')
                    break
                case 'apply':
                    classes.push('btn-special')
                    break

            }
            return classes
        },
        getButtonTitle(button, byPass = false) {

        },
        getButtonLabel(button) {
            switch (button) {
                case 'clone':
                    return ''
                case 'delete':
                    return ''
                case 'template':
                    return ''
                case 'apply':
                    return ''
            }
        },
        getButtonIcon(button){
            switch (button) {
                case 'clone':
                    return 'copy'
                case 'delete':
                    return 'trash'
                case 'template':
                    return 'file-import'
                case 'apply':
                    return 'file-export'
            }
        },
        /**
         * Check if the given button should be visible or not for the given row.
         * @param string button The button name, ex "delete".
         * @param object row
         * @return boolean
         */
        userHasAccess(button) {
            //create read update delete
            let _access = true
            switch(button) {
                case 'delete':
                    _access = this.$ability.can('delete', 'Productions')
                    break
                case 'clone':
                    _access = this.$ability.can('create', 'Productions')
                    break
                case 'template':
                    _access = this.$ability.can('create_production_templates', 'Productions') && !this.isTemplateBased && !this.isClone
                    break
                case 'apply':
                    _access = this.$ability.can('view_production_templates', 'Productions') && this.$ability.can('update', 'Productions') && !this.isTemplateBased && !this.isClone
                    break
            }
            return _access
        },

        getTemplateTitleToFormList(template) {
            const label = []
            label.push(template.title)
            label.push(`(${template.id})`)
            if (template.data && template.data.league) {
                const league = this.leagueEntity.getItem(template.data.league)
                const sport = this.sportEntity.getItem(league.reference)
                label.push(`(${sport.label}, ${league.label})`)
            }
            return label.join(' ')
        },

        saveProductionAsTemplate() {
            const context = this
            context.saveTemplateForm.show = true
            context.saveTemplateForm.loading = true
            context.saveTemplateForm.template = Object.assign({}, context.template)
            // context.saveTemplateForm.template.user.value = {
            //     id: app.$data.user.get.id
            // }
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, type: 'production'}, true)
                .then(response => {
                    console.log(context.$store.state.data.productionTemplate)
                    context.saveTemplateForm.loading = false
                })
                .catch(error => {
                    if (error.response.status != 404) {
                        context.$error.set(error)
                    }
                    context.saveTemplateForm.loading = false
                })
        },

        saveTemplateAs() {
            const context = this
            let id = parseInt(context.saveTemplateForm.id.value)
            context.$loader.add('productionTemplateEdit_submit')
            const productionTemplate = new ProductionTemplate(id)
            Lazy(context.saveTemplateForm.template)
                .each(function(v, k) {
                    switch (k) {
                        case 'user':
                            let user = {
                                id: app.$data.user.get.id
                            }
                            productionTemplate.set(k, user)
                            break
                        case 'title':
                            let value = ''
                            if(id === 0) {
                                value = context.saveTemplateForm.name.value
                            }
                            else {
                                let currentTemplate = context.templates.find(i => i.id === id)
                                if(currentTemplate) value = currentTemplate.title
                            }
                            productionTemplate.set(k, value)
                            break
                        case 'data':
                            const data = {}
                            Lazy(v)
                                .filter((v2, k2) => context.hasField(k2))
                                .each(function(v2, k2) {
                                    if(k2 === 'equipment') {
                                        data[k2] = context.formData[k2].value.filter(e => e.equipmentUsage.parentId === null).map(e => {
                                            return {
                                                elementType: e.elementType,
                                                equipmentId: e.id,
                                                type: e.type,
                                                equipmentType: e.type,
                                                id: e.equipmentUsage.id,
                                                equipmentUsage: {
                                                    isNotUpdatePeriod: e.equipmentUsage.isNotUpdatePeriod,
                                                    isPrimary: e.equipmentUsage.isPrimary
                                                }
                                            }
                                        })
                                    }
                                    else if(k2 === 'crew') {
                                        data[k2] = []
                                        Lazy(context.formData[k2].value)
                                            .each(c => {
                                                if(!c.isHistorical) {
                                                    data[k2].push({
                                                        calcInCalendar: c.calcInCalendar,
                                                        createBookingConflict: c.createBookingConflict,
                                                        endPlan: c.endPlan,
                                                        id: c.id,
                                                        location_id: c.location,
                                                        skill: c.skill,
                                                        startPlan: c.startPlan,
                                                        status: 'requested',
                                                        userId: c.user.id
                                                    })
                                                }
                                            })
                                    }
                                    else {
                                        data[k2] = context.formData[k2].value
                                    }

                                })
                            productionTemplate.set(k, data)
                            break
                        case 'itemType':
                            let itemType = 'production'
                            if(context.production.get.productionType) {
                                itemType = context.production.get.productionType
                            }
                            else {
                                itemType = this.type
                            }
                            productionTemplate.set(k, itemType)
                            break
                        case 'filterType':
                            productionTemplate.set(k, context.formData.runType.value)
                            break
                        case 'filterLeague':
                            productionTemplate.set(k, context.formData.league.value)
                            break
                        default:
                            productionTemplate.set(k, v.value)
                            break
                    }

                })

            if(id === 0) {
                productionTemplate.store()
                    .then(response => {
                        console.log(response)
                        context.$loader.remove('productionTemplateEdit_submit')
                        context.$alert.set(`Production template ${response.data.id} has been created!`, 'success', 3)
                        context.resetSaveTemplateForms()
                    })
                    .catch(error => {
                        context.$loader.remove('productionTemplateEdit_submit')
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.resetSaveTemplateForms()
                    })
            }
            else {
                productionTemplate.save()
                    .then(response => {
                        console.log(response)
                        context.$loader.remove('productionTemplateEdit_submit')
                        context.$alert.set(`Production template ${response.data.id} has been updated!`, 'success', 3)
                        context.resetSaveTemplateForms()
                    })
                    .catch(error => {
                        context.$loader.remove('productionTemplateEdit_submit')
                        context.$error.set(error, 'It was not possible to save the production.')
                        context.resetSaveTemplateForms()
                    })
            }
        },
        resetSaveTemplateForms() {
            this.saveTemplateForm.show = false
            this.saveTemplateForm.loading = false
            this.saveTemplateForm.template = null
            this.saveTemplateForm.id.value = 0
            this.saveTemplateForm.name.value = ''
        },

        handlerHouseNumberChange(newValue) {
            const context = this
            if(context.isEditForm && context.production.get.houseNumber) {
                let hNumberOrigin = context.production.get.houseNumber

                if (hNumberOrigin) {
                    //json string
                    if (isJsonSring(hNumberOrigin)) {
                        let hNumber = JSON.parse(hNumberOrigin)
                        let newNO = newValue['no'] !== null && newValue['no'] !== '' ? context.isForFork(newValue['no'], 'no') : false
                        let newSE = newValue['se'] !== null && newValue['se'] !== '' ? context.isForFork(newValue['se'], 'se') : false
                        let currentNOFork = _.find(context.formData.placeholders.value, p => p.country == 'no')
                        let currentSEFork = _.find(context.formData.placeholders.value, p => p.country == 'se')


                        if(newNO) {
                            if(currentSEFork !== undefined) {
                                context.updatePlaceholderCountry(currentSEFork, 'no')
                            } else if(currentNOFork !== undefined) {
                                context.updatePlaceholderDeleteStatus(false, 'no')
                            } else if(currentNOFork === undefined) {
                                context.createLocalDefaultPlaceholder('no')
                            }

                        }
                        else if(!newNO && newSE) {
                            if(currentNOFork !== undefined) {
                                context.updatePlaceholderCountry(currentNOFork, 'se')
                            } else if(currentSEFork !== undefined) {
                                context.updatePlaceholderDeleteStatus(false, 'se')
                            } else if(currentSEFork === undefined) {
                                context.createLocalDefaultPlaceholder('se')
                            }

                        }
                        else if(!newNO && !newSE) {
                            if(currentNOFork !== undefined) {
                                if(currentNOFork.local) {
                                    let deleteNoForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === 'no')
                                    if(deleteNoForkCountry > -1) {
                                        context.formData.placeholders.value.splice(deleteNoForkCountry, 1)
                                    }
                                }
                                else {
                                    context.updatePlaceholderDeleteStatus(true, 'no')
                                }

                            } else if (currentSEFork !== undefined) {
                                if(currentSEFork.local) {
                                    let deleteSeForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === 'se')
                                    if(deleteSeForkCountry > -1) {
                                        context.formData.placeholders.value.splice(deleteSeForkCountry, 1)
                                    }
                                }
                                else {
                                    context.updatePlaceholderDeleteStatus(true, 'se')
                                }

                            }
                        }

                        Lazy(newValue)
                            .each(function (v2, k2) {
                                if(k2 !== 'se' && k2 !== 'no') {
                                    if (hNumber[k2] !== v2) {
                                        let prevFork = hNumber[k2] !== null && hNumber[k2] !== '' ? context.isForFork(hNumber[k2], k2) : false
                                        let currentFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false

                                        if(prevFork && !currentFork) {
                                            //need to remove placeholder or mark for delition
                                            //ask confirmation if it was previously sent to fork
                                            context.updatePlaceholderDeleteStatus(true, k2)
                                        }

                                        if(!prevFork && currentFork) {
                                            //need to create placeholder with mark create
                                            //ask confirmation about submit to fork
                                            context.createLocalDefaultPlaceholder(k2)
                                        }

                                        if(!prevFork && !currentFork && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                            let deleteForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                            if(deleteForkCountry > -1) {
                                                context.formData.placeholders.value.splice(deleteForkCountry, 1)
                                            }
                                        }

                                        if(prevFork && currentFork) {
                                            //cancel delition if it was marked for deletion
                                            context.updatePlaceholderDeleteStatus(false, k2)
                                        }
                                    }
                                    else if(hNumber[k2] === v2 && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                        //let oldFork = hNumber[k2] !== null && hNumber[k2] !== '' ? context.isForFork(hNumber[k2], k2) : false
                                        let tmpForkCountry = _.find(context.formData.placeholders.value, i => i.country === k2)
                                        if(tmpForkCountry !== undefined) {
                                            if(tmpForkCountry.local) {
                                                context.updatePlaceholderDeleteStatus(false, k2)
                                            }
                                            else {
                                                let tempForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                                if(tempForkCountry > -1 && context.formData.placeholders.value[tempForkCountry].local) {
                                                    context.formData.placeholders.value.splice(tempForkCountry, 1)
                                                }
                                            }
                                        }
                                    }
                                    else if(context.formData.placeholders.initialHasRec) {
                                        let initialFork = hNumber[k2] !== null && hNumber[k2] !== '' ? context.isForFork(hNumber[k2], k2) : false
                                        if(initialFork) {
                                            if(context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                                                let isCountryInList = _.find(context.formData.placeholders.value, i => i.country === k2)
                                                if(isCountryInList === undefined) {
                                                    context.formData.placeholders.value.push({
                                                        country: k2,
                                                        placeholders: [],
                                                        local: false
                                                    })
                                                }
                                            }
                                            else {
                                                context.formData.placeholders.value.push({
                                                    country: k2,
                                                    placeholders: [],
                                                    local: false
                                                })
                                            }
                                        }
                                    }
                                }
                            })

                        context.formData.placeholders.showPlaceholders = context.formData.placeholders.initialHasRec || context.formData.placeholders.value.length > 0
                    }
                }
            } else {
                Lazy(newValue)
                    .each(function (v2, k2) {
                        let ignoreSweden = false
                        if(k2 == 'se' && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                            let noFork = _.find(context.formData.placeholders.value, i => i.country == 'no')
                            if(noFork !== undefined) {
                                ignoreSweden = true
                            }
                        }
                        let ignoreNorway = false
                        if(k2 == 'no' && context.formData.placeholders.value && context.formData.placeholders.value.length > 0) {
                            let seForkCountry = _.find(context.formData.placeholders.value, i => i.country == 'se')
                            let noForkCountry = _.find(context.formData.placeholders.value, i => i.country == k2)
                            let currentSeFork = newValue['se'] !== null && newValue['se'] !== '' ? context.isForFork(newValue['se'], 'se') : false

                            if(seForkCountry !== undefined || noForkCountry !== undefined) {
                                ignoreNorway = true
                                let currentNoFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false

                                if(currentNoFork) {
                                    if(seForkCountry !== undefined) {
                                        context.updatePlaceholderCountry(seForkCountry, k2)
                                    }
                                    else {
                                        context.createLocalDefaultPlaceholder(k2)
                                    }

                                }

                                if(!currentNoFork && noForkCountry !== undefined) {
                                    if(currentSeFork) {
                                        context.updatePlaceholderCountry(noForkCountry, 'se')
                                    }
                                    else {
                                        let deleteNoForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                        if(deleteNoForkCountry > -1) {
                                            context.formData.placeholders.value.splice(deleteNoForkCountry, 1)
                                        }
                                    }

                                }
                            }
                        }
                        if(!ignoreNorway && !ignoreSweden ) {
                            let currentFork = v2 !== null && v2 !== '' ? context.isForFork(v2, k2) : false
                            if(currentFork) {
                                context.createLocalDefaultPlaceholder(k2)
                            }
                            else {
                                let deleteForkCountry =  _.findIndex(context.formData.placeholders.value, i => i.country === k2)
                                if(deleteForkCountry > -1) {
                                    context.formData.placeholders.value.splice(deleteForkCountry, 1)
                                }
                            }
                        }

                    })

                context.formData.placeholders.showPlaceholders = context.formData.placeholders.value.length > 0
            }
        },
        openApplyTemplateForm() {
            this.$loader.add('createFromTemplateLoading')
            this.applyTemplateForm.show = true
            const productionTemplate = new ProductionTemplate()
            productionTemplate.all({user: app.$data.user.get.id, global: true, type: this.type }, true)
                .then(response => {
                    this.$loader.remove('createFromTemplateLoading')
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the Production Templates.')
                    this.$loader.remove('createFromTemplateLoading')
                })
        },
        templateRunTypes() {
            // let tmpls = this.$store.state.data.productionTemplate && this.$store.state.data.productionTemplate.length > 0 ?
            //             this.$store.state.data.productionTemplate.map(i => i.data.deliveryType ? i.data.deliveryType : 0) : []
            // tmpls = _.uniq(tmpls)
            return Lazy(this.runTypeEntity.get.items)
                .filter(item => {
                    let id = item.id
                    let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.runType == id)
                    return indx > -1
                })
                .sortBy(item => item.label, false)
                .toArray()
        },
        templateLeagues() {
            return Lazy(this.leagueEntity.get.items)
                .filter(item => {
                    let id = item.id
                    let indx = _.findIndex(this.$store.state.data.productionTemplate, i => i.data.league == id)
                    return indx > -1
                })
                .sortBy(item => item.label, false)
                .toArray()
        },
        applyTemplateToProduction(templateId) {
            this.applyTemplateToItem(templateId)
                .then(response => {
                    const context = this
                    if(response.data.appliedIds && response.data.appliedIds.length > 0 && response.data.appliedIds[0] == context.id) {
                        context.$alert.set(`Production has been updated with template!`, 'success', 3)
                        let updatedProd = JSON.parse(response.data.applied[0])
                        // SET Production with the new values
                        Lazy(updatedProd)
                            .each((v, k) => {
                                context.production.set(k, v)
                            })
                        Vue.nextTick(() => {
                            context.setFormValues(true)
                            context.renderPlease() //render for production leader component
                        })
                    }
                    else {
                        this.$error.set('Something went wrong with applying template.')
                    }
                })
                .catch(error => {
                    this.$error.set(error, 'Something went wrong with applying template.')
                })
        },

        hasField(field) {
            return this.productionTypes[this.type].fields.includes(field);
        },

        showNotificationModal(user) {
            this.notification.recipients = []

            this.notification.recipients.push({
                email: user.email,
                phone: user.phone,
                id: user.id,
                label: user.fullName,
            })

            this.notification.modalTitle = `Contact ${user.fullName}`

            this.notification.show = true
        },

        getCountryLabel(code) {
            return this.countries.find(country => country.code === code)?.label
        },

        getProductionLeaderLabel(id) {
            return this.getUsersList.find(user => user.id === id)?.label
        },

        hasFeature(feature) {
            return this.productionTypes[this.type].features.includes(feature);
        }
    }

}