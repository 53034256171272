<script>
import {app} from 'app'
import BookTable from 'components/common/BookTable'


export default {
    components: {
        BookTable
    },
    props: {
        equipment: {
            default: [],
            type: Array
        },
        equipmentUsage: {
            default: [],
            type: Array
        },
        equipmentTypes: {
            default: [],
            type: Array
        },
        problematicEquipmentProp: {
            type: String
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            problematicEquipment: this.problematicEquipmentProp ? JSON.parse(this.problematicEquipmentProp) : this.defaultProblematic(),
            notBookedFormData: Object.assign({}, this.defaultNotBooked()),
            availableEquipment: null,
            otherFormData: Object.assign({}, this.defaultOther()),
            showAll: false
        }
    },
    watch: {
        'notBookedFormData.equipmentType.value': function(newValue, oldValue) {
            if(newValue && newValue !== oldValue) {
                const context = this
                //let allEq =  _.concat(context.problematicEquipment.usageIds, context.problematicEquipment.ids)
                context.availableEquipment = this.equipment.filter(e => {
                    return _.indexOf(context.problematicEquipment.ids, e.id) === -1 &&
                           _.findIndex(context.equipmentUsage, e1 => e1.id === e.id) === -1 && 
                           e.country === app.$data.user.get.country && 
                           e.type === newValue
                })
            }
        }
    },
    computed: {
        bookedEquipment() {
            let bookedEq = []
            const context = this
            let isUsageIdsEmpty = context.problematicEquipment.usageIds.length == 0
            context.equipmentUsage.forEach(eq => {
                let checked = false
                if(!isUsageIdsEmpty) {
                    let inProblemList = _.indexOf(context.problematicEquipment.usageIds, eq.id)
                    if(inProblemList !== -1) checked = true
                }

                let type = _.find(context.equipmentTypes, e => e.id == eq.type)

                bookedEq.push({
                    id: {
                            value: eq.id
                        },
                    type: {
                            rawValue: eq.type,
                            value: type ? type.label : ''
                        },
                    equipment: {
                            value: eq.label
                        },
                    country: {
                        value: eq.destination
                    },
                    rowOptions: {
                        checked: checked
                        }
                })
                
            });

            const output = {
                options: {
                    checkboxes: {
                        disabled: context.disabled,
                        eventName: 'bookedEquipmentChecked'
                    }
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'equipment',
                        label: 'Booked Equipment',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'country',
                        label: 'Country',
                        visible: false
                    }
                ],
                rows: bookedEq
            }


            return output

        },
        notBookedEquipment() {
            const context = this

            const output = {
                options: {
                    actions: []
                },
                columns: [
                    {
                        value: 'id',
                        label: 'ID',
                        visible: false
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                        searchable: false,
                        visible: true
                    },
                    {
                        value: 'equipment',
                        label: 'Not Booked Equipment',
                        sortable: true,
                        searchable: false,
                        visible: true
                    }
                ],
                rows: []
            }

            if(!context.disabled) {
                output.options.actions.push('delete')
            }

            context.problematicEquipment.ids.forEach(p => {
                let eq = _.find(context.equipment, e => e.id === p)
                let type = _.find(context.equipmentTypes, e => e.id == eq.type)
                
                let row = {
                    id: {
                            value: eq.id
                        },
                    type: {
                            rawValue: eq.type,
                            value: type ? type.label : ''
                        },
                    equipment: {
                            value: eq.label
                        },
                }
                output.rows.push(row)
            })

            return output

        },
        otherEquipment() {
            const context = this

            const output = {
                options: {
                    actions: []
                },
                columns: [
                    {
                        value: 'equipment',
                        label: 'Other Equipment',
                        sortable: true,
                        searchable: false,
                        visible: true
                    }
                ],
                rows: []
            }

            if(!context.disabled) {
                output.options.actions.push('delete')
            }

            context.problematicEquipment.names.forEach(p => {
                let row = {
                    equipment: {
                            value: p
                        },
                }
                output.rows.push(row)
            })

            return output
        }
    },
    methods: {
        defaultProblematic() {
            return {
                usageIds: [],
                ids: [],
                names: []
            }
        },
        defaultNotBooked(){
            return {
                equipmentType: {
                    value: ''
                },
                equipmentId: {
                    value:''
                }
            }
        },
        defaultOther() {
            return {
                name: {
                    value: ''
                }
            }
        },
        bookedEquipmentChecked(item) {
            
            let checkedItem = item.id.value
            let checkedItemChecked = !item.rowOptions.checked

            const context = this
            let itemInBookedEquipment = _.indexOf(context.problematicEquipment.usageIds, checkedItem)
            if(checkedItemChecked && itemInBookedEquipment === -1) {
                context.problematicEquipment.usageIds.push(checkedItem)
            }
            else if(!checkedItemChecked && itemInBookedEquipment > -1){
                context.problematicEquipment.usageIds.splice(itemInBookedEquipment, 1)
            }

            context.updateProblematicEquipment()
            
        },
        notBookedEquipmentDeleted(item) {
            console.log(item)
            let checkedItem = item.id.value
            const context = this
            let itemInNotBookedEquipment = _.indexOf(context.problematicEquipment.ids, checkedItem)
            if(itemInNotBookedEquipment > -1) {
                context.problematicEquipment.ids.splice(itemInNotBookedEquipment, 1)
            }

            context.updateProblematicEquipment()
        },
        addNotBookedEquipment(item) {
            const context = this
            let checkedItem = context.notBookedFormData.equipmentId.value
            let itemInNotBookedEquipment = _.indexOf(context.problematicEquipment.ids, checkedItem)

            if(itemInNotBookedEquipment === -1) {
                context.problematicEquipment.ids.push(checkedItem)
            }

            context.notBookedFormData = Object.assign({}, context.defaultNotBooked())

            context.updateProblematicEquipment()
        },
        otherEquipmentDeleted(item) {
            console.log(item)
            let checkedItem = item.equipment.value
            const context = this
            let itemInOtherEquipment = _.indexOf(context.problematicEquipment.names, checkedItem)
            if(itemInOtherEquipment > -1) {
                context.problematicEquipment.names.splice(itemInOtherEquipment, 1)
            }

            context.updateProblematicEquipment()
        },
        addOtherEquipment(item) {
            const context = this
            let checkedItem = context.otherFormData.name.value
            let itemInOtherEquipment = _.indexOf(context.problematicEquipment.names, checkedItem)

            if(itemInOtherEquipment === -1) {
                context.problematicEquipment.names.push(checkedItem)
            }

            context.otherFormData = Object.assign({}, context.defaultOther())

            context.updateProblematicEquipment()
        },
        updateProblematicEquipment(){
            const context = this
            let updatedProblematicequipment = null
            if(context.problematicEquipment.usageIds.length > 0 ||  
               context.problematicEquipment.ids.length > 0 ||
               context.problematicEquipment.names.length > 0) {
                   updatedProblematicequipment = JSON.stringify(context.problematicEquipment)
               }
            this.$emit('input', updatedProblematicequipment)
        },
    }
}
</script>
<template>
    <div id="problematicEquipment" class="row error-report">
        <div class="col-sm-12 col-md-4 col-no-padding" v-if="bookedEquipment.rows.length > 0">
            <book-table
                :component="'feedbackProblematicEquipment'"
                :items="bookedEquipment"
                @bookedEquipmentChecked="item => bookedEquipmentChecked(item)"
                notFoundMessage="No booked equipment has been found"
                >
            </book-table>
        </div>
        <div class="col-sm-12 col-md-4 col-no-padding">
            <div class="row error-report" v-if="notBookedEquipment.rows.length > 0">
                <book-table
                    :component="'feedbackProblematicEquipment'"
                    :items="notBookedEquipment"
                    @delete="item => notBookedEquipmentDeleted(item)"
                    notFoundMessage="No not booked equipment has been found"
                    >
                </book-table>
            </div>
            
            <div class="row error-report">
                <div class="col-sm-5 col-md-5 col-no-padding" :style="{'padding-left': '0px', 'padding-right': '5px'}">
                    <select class="form-control" name="equipment_type" v-model="notBookedFormData.equipmentType.value" v-if="equipment" :disabled="equipment.length < 1 || disabled ">
                        <option value="" v-if="equipmentTypes.length > 1">Type</option>
                        <option v-for="type in equipmentTypes" :value="type.id">{{type.label}}</option>
                    </select>
                </div>
                <div class="col-sm-5 col-md-5 col-no-padding" :style="{'padding-left': '0px', 'padding-right': '5px'}">
                    <select class="form-control" name="equipment" v-model="notBookedFormData.equipmentId.value" :disabled="disabled || !notBookedFormData.equipmentType.value">
                          <option value="">Equipment</option>
                          <option v-for="eq in availableEquipment" :value="eq.id" :key="eq.id">{{eq.label}}</option>
                      </select>
                </div>
                <div class="col-sm-2 col-md-2 col-no-padding">
                     <button
                        class="btn btn-success btn-xs book-button-add-equipment"
                        @click="addNotBookedEquipment(true)"
                        :disabled="disabled || !notBookedFormData.equipmentId.value"
                        >
                        <font-awesome-icon icon="plus"/>&nbsp;Add
                    </button>
                </div>
            </div>
            
        </div>
        <div class="col-sm-12 col-md-4 col-no-padding">
            <div class="row error-report" v-if="otherEquipment.rows.length > 0">
                <book-table
                    :component="'feedbackProblematicEquipment'"
                    :items="otherEquipment"
                    @delete="item => otherEquipmentDeleted(item)"
                    notFoundMessage="No other equipment has been found"
                    >
                </book-table>
            </div>
            <div class="row error-report">
                <div class="col-sm-10 col-md-10 col-no-padding" :style="{'padding-left': '0px', 'padding-right': '5px'}">
                    <input class="input-other-equipment" v-model="otherFormData.name.value" :disabled="disabled" name="label" type="text" placeholder="Add the name of other equipment here...">
                </div>
                <div class="col-sm-2 col-md-2 col-no-padding">
                     <button
                        class="btn btn-success btn-xs book-button-add-equipment"
                        @click="addOtherEquipment(true)"
                        :disabled="disabled || !otherFormData.name.value"
                        >
                        <font-awesome-icon icon="plus"/>&nbsp;Add
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>