import { Validator } from 'vee-validate';

// Password validator.
const passwordValidator = {
    getMessage: (field) => `The ${field} is not a valid password.`,
    validate: (value) => {
        let re = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[$@$!%*#?&])([a-zA-Z0-9$@$!%*#?&]+)$/
        if (!re.test(value)) {
            return false
        }
        return true;
    }
}
Validator.extend('password', passwordValidator);

// @TODO Remove this validator?
// Phone validator.
const phoneValidator = {
    getMessage: (field) => `The ${field} is not a valid phone number.`,
    validate: (value) => {
        let re = /\+([0-9]){1,3}\-([0-9]){3,20}/
        if (!re.test(value)) return false
        return true
    }
}
Validator.extend('phone', phoneValidator);

// Number offset validator.
const offsetValidator = {
    getMessage: (field) => `The ${field} is not a valid offset number.`,
    validate: (value) => {
        value = parseInt(value)
        return typeof value === 'number'
    }
}
Validator.extend('offset', offsetValidator);
