
import {app} from 'app'
import store from 'store'
import Auth from 'components/auth/Auth'

// @TODO We should rewrite this to the "common" API structure used in other classes.

class User {

    /**
     * Constructor.
     * @param object data The user data to create an user instance with.
     */
    constructor(data = null) {
        if (data) {
          this.id = data.id
          store.commit('user/setData', data)
        }
    }

    /**
     * Check if the data for the current user instance is loaded.
     * @return boolean
     */
    isLoaded() {
        return typeof store.state.user.data[this.id] !== 'undefined'
    }

    /**
     * Return user data for the current instance.
     * @return object
     */
    get get() {
        return store.state.user.data[this.id]
    }

    /**
     * Set a value to a given field on the user model.
     * @param string field The name  of the user field.
     * @param string value The value to set.
     */
    set(field, value) {
        store.commit('user/setField', {id: this.id, field: field, value: value})
    }

    /**
     * Save the user model into the API.
     * @return promise
     */
    save() {
        let uid = this.id

        // If the password is empty, then we shouldn't send it to the API,
        // otherwise an error will be returned.
        if (store.state.user.data[uid].password == '') {
          store.commit('user/removeField', {id: this.id, field: 'password'})
        }

        let promise = new Promise((resolve, reject) => {
            axios.put('api/user/' + uid, store.state.user.data[uid])
            .then(response => {
                if (parseInt(response.data.id) == app.$data.user.id) {
                    store.commit('user/setData', response.data)
                }
                sessionStorage.removeItem('users');
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
        return promise
    }

    /**
     * Create a new user.
     * @return promise
     */
    store() {
        let uid = this.id
        let promise = new Promise((resolve, reject) => {
            axios.post('api/user', store.state.user.data[uid])
            .then(response => {
                sessionStorage.removeItem('users');
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
        return promise
    }

    /**
     * Check if the user has the given role.
     */
    hasRole(role) {
        if (!this.get.roles) return false
        if (typeof role === 'string') {
            return Object.values(this.get.roles).indexOf(role) > -1
        }
        return !! Lazy(this.get.roles).intersection(role).toArray().length
    }

    /**
     * Check if the user is admin or not.
     *
     * @TODO Is this still used? Remove then...
     */
    isAdmin() {
        return this.hasRole(store.state.config.ROLE_ADMIN_NAME)
    }

    // -------------------------------------------------------------------------
    // Static methods.

    /**
     * Check if the user autheticated.
     */
    static isAuthenticated() {
        if (Auth.getToken() && (Object.keys(store.state.user.data).length > 0)) {
            return true
        }
        return false
    }

    /**
     * Load user data from backend based on the current access token.
     * The token is set when the login is done.
     */
    static load(id = null) {

        // @TODO Merge the call below with the one inside "else".

        if (!id) {
            return new Promise((resolve, reject) => {
                const fields = ['permissions', 'skills', 'roles']
                //axios.get('api/user/load', {params: {fields: fields.join(',')}})
                axios.get('api/user/load', { params: { getPermissions: 1 }} )
                    .then(response => {
                        if ((response.status === 200) && (response.data.id !== undefined)) {
                            app.$data.user = new User(response.data)
                            store.commit('setAppClass', 'book-authenticated')
                        }

                        // Set permissions (abilities).
                        app.$ability.update([])
                        app.$ability.update(app.$data.user.get.permissions)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
        else {
          return new Promise((resolve, reject) => {
              axios.get('api/user/' + id)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(error => {
                      reject(error)
                  })
          })
        }

    }

    /**
     * Get a list of users from backend.
     */
    static getUsers(params = []) {
        //console.log(params.length, 'length <- -> params', params);
        if(params.length === 0) {
            if(!sessionStorage.users) {
                return new Promise((resolve, reject) => {
                    axios.get('api/user', {params: params})
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.users = JSON.stringify(response.data);
                                store.commit('user/setUsers', response.data)
                            }
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            }else {
                return new Promise((resolve, reject) => {
                    store.commit('user/setUsers', JSON.parse(sessionStorage.users));
                    var response = new Response();
                    response.data = JSON.parse(sessionStorage.users);
                    resolve(response);
                })
            }
        }else {
            return new Promise((resolve, reject) => {
                axios.get('api/user', {params: params})
                    .then(response => {
                        if (response.status === 200) {
                            store.commit('user/setUsers', response.data)
                        }
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
    // get production leaders WN
    static getUsersByRole(params = []) {
        if(params.length === 0) {
            if(!sessionStorage.users) {
                return new Promise((resolve, reject) => {
                    axios.get('api/usersbyrole', {params: params})
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.users = JSON.stringify(response.data);
                                store.commit('user/setUsers', response.data)
                            }
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            }else {
                return new Promise((resolve, reject) => {
                    store.commit('user/setUsers', JSON.parse(sessionStorage.users));
                    var response = new Response();
                    response.data = JSON.parse(sessionStorage.users);
                    resolve(response);
                })
            }
        }else {
            return new Promise((resolve, reject) => {
                axios.get('api/usersbyrole', {params: params})
                    .then(response => {
                        if (response.status === 200) {
                            store.commit('user/setUsers', response.data)
                        }
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }

    }

    /**
     * Delete an user.
     * @return promise
     */
    static delete(id) {
        return new Promise((resolve, reject) => {
            axios.delete('api/user/' + id)
            .then(response => {
                if (response.status === 200) {
                    sessionStorage.removeItem('users');
                    resolve(response)
                }
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default User
