import store from 'store'
import Api from 'components/app/Api'

class ProductionCrew extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null, productionId = 0) {
        super({
            id: id,
            apiUrl: 'api/production/' + productionId + '/crew',
            module: 'productionCrew',
        })
    }

    /**
     * Retrieve users that can be added as crew members on a Production.
     * @param object params
     * @return promise
     */
    static getAvailableCrew(params = {}) {
        const context = this
        return new Promise((resolve, reject) => {
            store.commit('removeData', {key: 'availableCrew'})
            axios.get('api/production/available-crew', {params: params})
                .then(response => {
                    store.commit('setData', {key: 'availableCrew', data: response.data, reset: true})
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }


}

export default ProductionCrew
