<script>

import {app} from 'app'
import store from 'store'
import BookToggle from 'components/common/BookToggle'
import BookLoader from 'components/common/loader/BookLoader'
import Signoff from 'components/common/signoff/Signoff'

export default {
    components: {
        BookToggle,
        BookLoader
    },
    props: {
        signoffs: {
            default: () => [],
            type: Array
        },
        itemId: {
            default: 0,
            type: Number
        },
        disabled: {
            default: false,
            type: Boolean
        },
        isTask: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            items: this.signoffs,
            loading: {}
        }
    },
    watch: {
        'signoffs': {
            handler: function(newValue) {
                Vue.set(this.$data, 'items', newValue)
                this.processData()
            },
            deep: true
        }
    },
    mounted() {
        this.processData()
    },
    methods: {
        processData() {
            const context = this
            context.items.forEach(v => {
                Vue.set(context.loading, 'item_' + v.type, false)
            })
        },
        setLoading(id, value) {
            this.loading['item_' + id] = value
        },

        /**
         * Sign off an item.
         */
        signOff(item) {
            const context = this
            context.setLoading(item.type, true)
            const newItems = this.items.slice(0)
            if (item.signed) {
                const signoffItem = new Signoff(item.signed.signoffId)

                // Delete the signoff.
                signoffItem.delete()
                .then(response => {
                    if (response.status != 200) {
                        this.$alert.set('It was not possible to remove this signoff.', 'danger', 5)
                        item.signed = null
                        context.setLoading(item.type, false)
                        return
                    }
                    let i = newItems.findIndex(v => v.signed.signoffId == item.signed.signoffId)
                    newItems[i].signed = null
                    context.setLoading(item.type, false)
                    context.$emit('updated', newItems)
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to delete the sign off.')
                    context.setLoading(item.type, false)
                })

            }
            else {

                // Create a Signoff item instance and set the values to save.
                const signoffItem = new Signoff(0)
                Lazy(item)
                    .each((v, k) => {
                        signoffItem.set(k, v)
                    })
                const signed = {
                    itemId: context.itemId,
                    userId: app.$data.user.id,
                    userName: app.$data.user.name,
                    updated: moment().format('YYYY-MM-DD HH:mm:ss'),
                }
                signoffItem.set('signed', signed)

                // Store the item into API.
                signoffItem.store()
                    .then(response => {
                        if (response.status != 200) {
                            context.$alert.set('It was not possible to save this signoff.', 'danger', 5)
                            item.signed = null
                            context.setLoading(item.type, false)
                            return
                        }
                        let i = newItems.findIndex(v => v.type == response.data.type)
                        newItems[i].signed = response.data
                        context.setLoading(item.type, false)
                        context.$emit('updated', newItems)
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to save the sign off.')
                        context.setLoading(item.type, false)
                    })

            }
        }

    }

}
</script>

<template>
<div class="book-signoff clearfix">
    <div v-for="item in items" class="book-signoff-row">
        <div class="book-signoff-label-wrapper">
            <span class="book-signoff-label">{{isTask ? 'Task Complete' : item.label}}</span>
            <span  class="book-signoff-user" v-if="item.signed">
                {{item.signed.updated}}, by <router-link exact :to="{name: 'user.id', params: {id: item.signed.userId}}">{{item.signed.userName}}</router-link>
            </span>
        </div>
        <div class="book-signoff-overlay" v-if="disabled || item.disabled || loading['item_' + item.type]"></div>
        <book-loader :small="true" v-if="loading['item_' + item.type]"></book-loader>
        <book-toggle :status="item.signed" @toggle="signOff(item)">
            <span slot="on">yes</span>
            <span slot="off">no</span>
        </book-toggle>
    </div>
</div>
</template>

<style lang="scss">
.book-signoff {
    .book-signoff-row {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        min-height: 38px;
        position: relative;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .book-signoff-overlay {
        background: rgba(#FFF, 0.6);
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
    .book-toggle,
    .book-loader,
    .book-signoff-label-wrapper {
        position: relative;
    }
    .book-toggle,
    .book-signoff-label-wrapper {
        z-index: 1;
    }
    .book-loader {
        margin-right: 10px;
        z-index: 3;
    }
    .book-signoff-label-wrapper {
        align-items: center;
        display: flex;
        flex: 1;
        flex-flow: row wrap;
    }
    .book-signoff-label,
    .book-signoff-user {
        display: block;
        flex-basis: 100%;
    }
    .book-signoff-label {
        font-weight: 600;
    }
    .book-signoff-user {
        font-size: 0.95rem;
    }
}
</style>
