<script>
import {app} from 'app'
import BookEquipment from 'components/equipment/EquipmentOverview/BookEquipment'
import Equipment from 'components/equipment/Equipment'
import EquipmentOverview from 'components/equipment/EquipmentOverview'
import BookDateNavigation from 'components/common/BookDateNavigation'
import BookLoader from 'components/common/loader/BookLoader'
import BookFilter from 'components/common/filter/BookFilter'
import Entity from 'components/entity/Entity'
import BookModal from 'components/common/BookModal'
import BookDatetime from 'components/common/BookDatetime'
import BookProductionForm from 'components/production/BookProductionForm'

import productionTypes from 'components/production/productionTypes'
import BookEquipmentAddToExistingForm from 'components/equipment/EquipmentOverview/BookEquipmentAddToExistingForm'
import BookEquipmentSelection from 'components/equipment/EquipmentSelection/BookEquipmentSelection'

export default {
    socket: {
        'private:equipment': {
            '.equipmentUsage.updated': function(payload, context) {
                if (typeof payload.data.id === 'undefined') payload.data = JSON.parse(payload.data)
                app.$emit('equipmentUsage.updated', payload.data)
            },
            '.equipmentUsage.created': function(payload, context) {
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                app.$emit('equipmentUsage.created', payload.data)
            },
            '.equipmentUsage.deleted': function(payload, context) {
                if (typeof payload.data.id === 'undefined' && !Array.isArray(payload.data)) payload.data = JSON.parse(payload.data)
                app.$emit('equipmentUsage.deleted', payload.data)
            }
        },
    },
    components: {
        BookEquipmentSelection,
        BookEquipmentAddToExistingForm,
        BookEquipment,
        BookDateNavigation,
        BookLoader,
        BookFilter,
        BookModal,
        BookDatetime,
        BookProductionForm,
    },
    props: {
        productionType: String,
        actions: Array,
    },
    data() {
        return {
            child: {
                entities: {
                    equipmentTypeEntity: 'equipmenttype_entity_id',
                    equipmentGroupEntity: 'equipmentgroups_entity_id',
                    channelEntity: 'channel_entity_id',
                    leagueEntity: 'league_entity_id',
                    sportEntity: 'sport_entity_id',
                }
            },
            loaded: false,
            dateRange: 0, // 0 - 7 days, 1 - 14 days, 2 - 31 days, 3 - custom but no more than 31 days
            defaultRangeInterval: 6,

            equipmentUsages: [],

            equipmentTypeEntity: null,
            equipmentGroupEntity: null,
            channelEntity: null,
            leagueEntity: null,
            sportEntity: null,

            filters: {},
            filterReady: false,
            availableFilters: {
                country: {
                    label: 'Countries',
                    items: [],
                },
                equipmentType: {
                    label: 'Type',
                    items: [],
                    class: 'equipmentTypeEntity'
                },
                equipmentGroup: {
                    label: 'Group',
                    items: [],
                    class: 'equipmentGroupEntity'
                },
                sport: {
                    label: 'Sports',
                    items: [],
                    class: 'sportEntity',
                },
                league: {
                    label: 'Leagues',
                    items: [],
                    class: 'leagueEntity',
                },
            },

            createProductionForm: {
                show: false,
                default: {},
                equipment: null,
            },

            addToExistingForm: {
                show: false,
                equipment: null,
                date: null,
                label: null,
            },

            editEquipmentUsageForm: {
                show: false,
                equipmentUsageId: null,
            }
        }
    },
    beforeCreate() {
        // Sets date from and to, to the default range interval.
        const context = this
        const dateFrom = context.$route.query.from ? context.$route.query.from : moment().format('YYYY-MM-DD')
        context.defaultRangeInterval = 6
        let to = moment(dateFrom).add(6, 'd')
        to = moment(to).format('YYYY-MM-DD')
        const routeTo = {
            query: {
                from: dateFrom,
                to: to,
            }
        }
        context.$router.replace(routeTo)
    },
    beforeDestroy() {
        app.$off()
    },
    created() {
        app.$on('equipmentUsage.created', (data) => {
            this.updateEquipmentUsages(data, 'create')
        })

        app.$on('equipmentUsage.updated', (data) => {
            this.updateEquipmentUsages(data, 'update')
        })

        app.$on('equipmentUsage.deleted', (data) => {
            this.updateEquipmentUsages(data, 'delete')
        })
    },
    mounted() {
        const dataToLoad = [];

        dataToLoad.push(this.loadEntities(this.child.entities))

        const equipment = new Equipment()

        dataToLoad.push(equipment.all())
        dataToLoad.push(this.loadEquipmentUsages())

        Promise.all(dataToLoad).then(response => {
            // We set equipment usages directly as state, since we need to change the data if we get any updated data
            // from backend via sockets. Additionally, we make the objects immutable with Object.freeze since
            // having them reactive is too slow for 5000+ objects.
            this.equipmentUsages = [].concat(...response[2].map(r => r.data.map(item => deepFreeze(item))))

            this.createFilters()
            this.setLoaded()
        })
    },
    computed: {
        setting() {
            return this.$settings.get.equipmentOverview
        },
        equipments() {
            return [...this.$store.state.data.equipment].filter(equipment => {
                // Remove equipment if we have a production type and the equipment do not have that type as usage.
                if (this.productionType && equipment.usage.indexOf(this.productionType) === -1) {
                    return false
                }

                return true
            })
        },
        equipmentsFiltered() {
            const data = [...this.equipments]

            return data.filter(equipment => {
                if (this.filters?.equipmentType?.length > 0 && !_.includes(this.filters.equipmentType, equipment.type)) {
                    return false
                }

                if (this.filters?.equipmentGroup?.length > 0 && !_.includes(this.filters.equipmentGroup, equipment.group)) {
                    return false
                }

                if (this.filters?.country?.length > 0 && !this.filters.country.includes(equipment.country)) {
                    return false
                }

                return true
            })
        },
        equipmentUsagesFiltered() {
            return this.equipmentUsages.filter(equipmentUsage => {
                const equipment = this.equipmentsFiltered.find(equipment => equipmentUsage.equipmentId === equipment.id)

                if (!equipment) {
                    return false
                }

                if (this.filters.hasOwnProperty('sport') && this.filters.sport.length > 0) {
                    const league = this.leagueEntity.getItem(equipmentUsage.usageItem.league)
                    const sport = this.sportEntity.getItem(league.reference)

                    if (!this.filters.sport.includes(sport.id)) {
                        return false
                    }
                }

                if (this.filters.hasOwnProperty('league') && this.filters.league.length > 0 && !_.includes(this.filters.league, equipmentUsage.usageItem.league)) {
                    return false
                }

                return true
            })
        },
        getViewLabel() {
            switch(this.dateRange) {
                case 0:
                    return '7 days'
                case 1:
                    return '14 days'
                case 2:
                    return '31 days'
                default:
                    return 'Custom'
            }
        },

        getProductionTypeLabel() {
            return this.productionType ? productionTypes[this.productionType].label : ''
        },

        filterInfo() {
            const filterInfo = {}
            filterInfo.visible = this.equipmentsFiltered.length
            filterInfo.total = this.equipments.length
            return filterInfo
        },

        countries() {
            return Object.keys(this.$store.state.system.countries).length > 0 ? this.$store.state.system.countries : null
        },

        /**
         * The transformed equipment usage currently being edited.
         */
        selectedEquipmentUsageForEdit() {
            if (!this.editEquipmentUsageForm.equipmentUsageId) {
                return null
            }

            const equipmentUsage = this.equipmentUsages.find(equipmentUsage => equipmentUsage.id === this.editEquipmentUsageForm.equipmentUsageId)

            return this.transformEquipmentUsage(equipmentUsage)
        },

        /**
         * Gets the equipment usages belonging to the same usage as the currently selected equipment usage.
         *
         * This data is required by BookEquipmentSelection to properly update data after save.
         */
        getEquipmentSelection() {
            const equipmentUsage = this.selectedEquipmentUsageForEdit

            if (!equipmentUsage) {
                return []
            }

            return this.equipmentUsages.filter(e => e.usageId === equipmentUsage.equipmentUsage.usageId && e.usageType === equipmentUsage.equipmentUsage.usageType)
                .map(e => this.transformEquipmentUsage(e))
        }
    },
    methods: {
        /**
         * Transforms the equipment usage model Equipment overview uses into the one used by equipment selection.
         *
         * We have the following structure:
         *
         * type EquipmentUsage = {
         *     id: Number,
         *     equipment: Equipment
         * }
         *
         * ...while BookEquipmentSelection have the relation inverted and looks like:
         *
         * type Equipment = {
         *     id: Number,
         *     equipmentUsage: EquipmentUsage
         * }
         */
        transformEquipmentUsage(equipmentUsage) {
            const equipment = this.equipments.find(equipment => equipment.id === equipmentUsage.equipmentId)

            const newEquipment = _.cloneDeep(equipment)
            newEquipment.equipmentUsage = _.cloneDeep(equipmentUsage)

            return newEquipment
        },
        setLoaded(loaded = true) {
            this.loaded = loaded
        },
        /**
         * Loads entities.
         */
        loadEntities(entities) {
            const context = this
            const entity = new Entity()
            return new Promise((resolve, reject) => {
                let entityIds = []
                Lazy(entities)
                    .each((v, k) => {
                        entityIds.push(context.setting[v])
                    })
                entity.all({ids: entityIds.join(',')})
                    .then(response => {
                        Lazy(entities)
                            .each((v, k) => {
                                context[k] = new Entity(context.setting[v])
                            })
                        resolve()
                    })
                    .catch(error => {
                        context.$error.set(error, 'It was not possible to load the entities.')
                        reject()
                    })
            })
        },

        /**
         * Load equipment usages.
         */
        loadEquipmentUsages() {
            // The number of days we chunk the date interval into.
            const daysApart = 4

            const startDate = moment(this.$route.query.from)
            const endDate = moment(this.$route.query.to)

            let currentDate = startDate

            const promises = []

            // Since there can be many usages, we chunk date interval into intervals of 4 days and load them in parallel.
            while (currentDate <= endDate) {
                let currentEndDate = moment(currentDate).add(daysApart, 'days')

                if (currentEndDate >= endDate) {
                    currentEndDate = endDate
                }

                const params = {
                    from: currentDate.format('YYYY-MM-DD'),
                    to: currentEndDate.format('YYYY-MM-DD'),
                }

                const equipmentOverview = new EquipmentOverview()

                promises.push(equipmentOverview.all(params, true))

                // We add 1 additional day since we don't want to load for the same day twice in each interval.
                currentDate = currentDate.add(daysApart + 1, 'days')
            }

            return Promise.all(promises)
        },
        createFilters() {
            const context = this

            Lazy(context.availableFilters)
                .each((v, k) => {
                    switch (true) {
                        case (v.hasOwnProperty('class')):
                            context[v.class].get.items.forEach(v2 => {
                                v.items.push({
                                    id: v2.id,
                                    label: v2.label,
                                    active: v2.active
                                })
                            })
                        break
                        case (k === 'country'):
                            context.countries.forEach(v2 => {
                                v.items.push({
                                    id: v2.code,
                                    label: v2.label
                                })
                            })
                        break
                    }
                })

            // Set default selected country based on the current user's country.
            if (app.$data.user.get.country && context.$store.state.filter.myFiltersSelected === '') {
                switch (true) {
                    case (context.availableFilters.hasOwnProperty('country') && context.availableFilters.country.hasOwnProperty('items')):
                        Lazy(context.availableFilters.country.items)
                            .filter(v => {
                                return v.id === app.$data.user.get.country
                            })
                            .each(v => {
                                Vue.set(v, 'selected', true)
                            })
                        break
                }
            }

            this.filterReady = true
        },
        filteredBy(filters) {
            this.filters = filters
        },
        /**
         * Create, update or delete equipment usages with data coming from socket.
         */
        updateEquipmentUsages(data, action) {
            data = _.cloneDeep(data)

            if (!Array.isArray(data)) {
                data = [data]
            }

            const nextEquipmentUsages = _.cloneDeep(this.equipmentUsages)

            Lazy(data).each(v1 => {
                let i = nextEquipmentUsages.findIndex(v => v.id === v1.equipmentUsage.id)

                if (action === 'create' || action === 'update') {
                    if (i > -1) {
                        nextEquipmentUsages.splice(i, 1)
                    }
                    nextEquipmentUsages.push(deepFreeze({ ...v1.equipmentUsage }))
                }

                if (action === 'delete') {
                    if (i > -1) {
                        nextEquipmentUsages.splice(i, 1)
                    }
                }
            })

            this.equipmentUsages = nextEquipmentUsages
        },

        openCreateProductionForm(data) {
            this.createProductionForm.show = true
            this.createProductionForm.default = {
                productionStart: moment(data.date).format('YYYY-MM-DD'),
                productionEnd: moment(data.date).format('YYYY-MM-DD'),
                equipment: [
                    {
                        elementType: "equipmentPiece",
                        equipmentId: data.equipment.id
                    }
                ]
            }

            // If we constrain to a specific production type, we can provide this as a default value to the form.
            if (this.productionType) {
                this.createProductionForm.default.productionType = this.productionType
            }

            this.createProductionForm.equipment = data.equipment
        },
        closeCreateProductionForm() {
            this.createProductionForm.show = false
            this.createProductionForm.default = {}
            this.createProductionForm.equipment = null
        },
        openAddToExistingForm(data) {
            this.addToExistingForm.show = true
            this.addToExistingForm.equipment = {
                elementType: "equipmentPiece",
                equipmentId: data.equipment.id
            }
            this.addToExistingForm.label = `Add equipment ${data.equipment.label}`
            this.addToExistingForm.date = moment(data.date).format('YYYY-MM-DD')
        },
        closeAddToExistingForm() {
            this.addToExistingForm.show = false
            this.addToExistingForm.equipment = null
            this.addToExistingForm.date = null
            this.addToExistingForm.label = null
        },
        handleAction(data) {
            switch (data.action) {
                case 'create':
                    this.openCreateProductionForm(data)
                    break
                case 'addToExisting':
                    this.openAddToExistingForm(data)
                    break
                default:
                    console.error('Invalid action')
            }
        },
        handleAddToExistingSuccess(data) {
            if (data?.createdSuggestions) {
                this.updateEquipmentUsages(data.createdSuggestions, 'create')
            } else {
                this.updateEquipmentUsages(data, 'create')
            }

            this.closeAddToExistingForm()
        },
        openEditEquipmentUsageForm(equipmentUsage) {
            this.editEquipmentUsageForm.show = true
            this.editEquipmentUsageForm.equipmentUsageId = equipmentUsage.id
        },
        closeEditEquipmentUsageForm() {
            this.editEquipmentUsageForm.show = false
            this.editEquipmentUsageForm.equipmentUsageId = null
        },

        /**
         * Handles equipment changes from BookEquipmentSelection.
         */
        handleEditEquipmentChange(data, action) {
            this.updateEquipmentUsages(data, action)
            this.closeEditEquipmentUsageForm()
        }
    },
    watch: {
        '$route': function(to, from) {
            // Whenever route from or to changes, we need to load equipment usages for that date interval.
            if ((from.query.from !== to.query.from) || (from.query.to !== to.query.to)) {
                this.setLoaded(false)
                this.loadEquipmentUsages().then((response) => {
                    this.equipmentUsages = [].concat(...response.map(r => r.data.map(item => deepFreeze(item))))
                    this.setLoaded()
                })
            }
        },
        dateRange: function (newRange, oldRange) {
            const context = this
            if (newRange !== oldRange) {
                const dateFrom = context.$route.query.from ? context.$route.query.from : moment().format('YYYY-MM-DD')
                let to
                let routeTo = {
                    query: {

                    }
                }
                switch(newRange) {
                    case 0:
                        context.defaultRangeInterval = 6
                        to = moment(dateFrom).add(6, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to

                        context.$router.replace(routeTo)
                        break
                    case 1:
                        context.defaultRangeInterval = 13
                        to = moment(dateFrom).add(13, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to

                        context.$router.replace(routeTo)
                        break
                    case 2:
                        context.defaultRangeInterval = 30
                        to = moment(dateFrom).add(30, 'd').format('YYYY-MM-DD')
                        routeTo.query.from = dateFrom
                        routeTo.query.to = to

                        context.$router.replace(routeTo)
                        break
                    default:
                        context.defaultRangeInterval = 0
                        break
                }
            }
        }
    }
}

</script>

<template>
    <div id="book-equipment-overview-view" class="book-top-sticky-wrapper">
        <div class="page-header book-top-sticky clearfix">
            <h1 class="page-title">{{ getProductionTypeLabel }} Equipment overview</h1>
            <div class="secondary-menu">
                <div class="secondary-menu">
                    <div class="btn-group">
                        <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-weight: bold;">
                            View: {{getViewLabel}} <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a href="#" @click="dateRange = 0">
                                    <span class="item-label">&nbsp;&nbsp;7 days</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" @click="dateRange = 1">
                                    <span class="item-label">&nbsp;&nbsp;14 days</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" @click="dateRange = 2">
                                    <span class="item-label">&nbsp;&nbsp;31 days</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" @click="dateRange = 3">
                                    <span class="item-label">&nbsp;&nbsp;Custom</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <book-date-navigation
                        :default-interval="defaultRangeInterval"
                        :hideEndDate="dateRange != 3"
                        :useDefaultInterval="dateRange != 3">
                    </book-date-navigation>
                    <slot name="secondaryMenu"></slot>
                </div>
            </div>

        </div>
        <book-filter
            :loading="!filterReady"
            :options="{save: true}"
            :filters="availableFilters"
            @filteredBy="filters => filteredBy(filters)"
            :info="filterInfo"
        />
        <book-loader v-if="!loaded"></book-loader>
        <div v-else class="panel panel-default" id="crewviewstick">
            <book-equipment
                :equipments="equipmentsFiltered"
                :equipmentUsages="equipmentUsagesFiltered"
                :intervalStart="$route.query.from"
                :intervalEnd="$route.query.to"
                @handleAction="data => handleAction(data)"
                @editEquipmentUsage="equipmentUsage => openEditEquipmentUsageForm(equipmentUsage)"
                :actions="actions"
            />
        </div>

        <book-modal maxWidth="500px" @close="closeCreateProductionForm" v-if="createProductionForm.show">
            <h4 slot="header" class="modal-title">
                <span>Create a {{ getProductionTypeLabel }} with equipment {{ createProductionForm.equipment.label }}</span>
            </h4>
            <div slot="body">
                <book-production-form :default="createProductionForm.default" @success="closeCreateProductionForm" />
            </div>
        </book-modal>

        <book-modal maxWidth="500px" @close="closeAddToExistingForm" v-if="addToExistingForm.show">
            <h4 slot="header" class="modal-title">
                <span>{{ addToExistingForm.label }}</span>
            </h4>
            <div slot="body">
                <book-equipment-add-to-existing-form :date="addToExistingForm.date" :type="this.productionType" :equipment="addToExistingForm.equipment" @success="handleAddToExistingSuccess" />
            </div>
        </book-modal>

        <book-modal maxWidth="250px" @close="closeEditEquipmentUsageForm" v-if="editEquipmentUsageForm.show">
            <div slot="body">
                <book-equipment-selection
                    :selection="getEquipmentSelection"
                    :dates="{from: selectedEquipmentUsageForEdit.equipmentUsage.periodStart, to: selectedEquipmentUsageForEdit.equipmentUsage.periodEnd}"
                    :usage-id="selectedEquipmentUsageForEdit.equipmentUsage.usageId"
                    :usage-type="selectedEquipmentUsageForEdit.equipmentUsage.usageType"
                    :disabled="false"
                    disabled-message=""
                    :local="false"
                    :hideDates="false"
                    :enableCheckAvailability="true"
                    :isForEquipmentOverview="true"
                    :selectedItem="selectedEquipmentUsageForEdit.elementType + '#' + selectedEquipmentUsageForEdit.equipmentUsage.id"
                    @changedEquipment="(item) => handleEditEquipmentChange(item, 'update')"
                    @deletingEquipment="(item) => handleEditEquipmentChange(item, 'delete')"
                >
                </book-equipment-selection>
            </div>
        </book-modal>
    </div>
</template>