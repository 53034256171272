import Api from 'components/app/Api'

class Mcrdata extends Api {

    /**
     * Constructor.
     * @param object data The data to create an instance with.
     */
    constructor(id = null, type = null, next = null) {
        if (next) {
            type = type.toLowerCase();
            super({
                id: id,
                apiUrl: 'api/' + type,
                module: type,
            })
        }
        else {
            let _module
            if (type) {
                type = type.toLowerCase();
                if (type === 'equipmentusage') {
                    type = 'equipment-usage'
                    _module = 'equipmentUsage'
                }
            }
            super({
                id: id,
                typeUrl: type ? `api/${type}` : 'api/mcrdata',
                apiUrl: 'api/mcrdata',
                module: _module ? _module : 'mcrdata',
            })
        }
    }

    getApiUrl() {
        if (this.options.type) return 'api/' + this.options.type + '/' + this.options.id
        return 'api/mcrdata/' + this.options.id
    }
}

export default Mcrdata